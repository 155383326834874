
import {catchError, map} from 'rxjs/operators';

import {throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';

@Injectable()
export class FormgroupsService {

  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService) { }

  // Get all form groups
  public getAllFormGroups() {
    return this.http.get(`${this.settings.getBaseUrl()}/formgroups`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  // Create a form group
  public createFormGroup(data: any) {
    return this.http.post(`${this.settings.getBaseUrl()}/formgroups`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  // Update a form group
  public update(data: any, id: any) {
    return this.http.patch(`${this.settings.getBaseUrl()}/formgroups/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  // Get all forms of a form group
  public getAllForms(id: any) {
    return this.http.get(`${this.settings.getBaseUrl()}/formgroups/${id}/forms`).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  // Add form to a form group
  public addFormToFormGroup(data: any) {
    return this.http.post(`${this.settings.getBaseUrl()}/formgroupsreports`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  // Remove form from a form group
  public removeFormFromFormGroup(data: any) {
    return this.http.post(`${this.settings.getBaseUrl()}/formgroupsreports/removeform`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  // Delete form group
  public deleteFormGroup(id: any) {
    return this.http.delete(`${this.settings.getBaseUrl()}/formgroups/${id}`).pipe(
        map((res: any) => res),
        catchError(error => throwError(error.error || 'Server Error')),);
  }
}
