import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromDefendants from './defendants.reducer';
import { DefendantState } from './defendants.reducer';
import * as fromReportItems from './report-items.reducer';
import { ReportItemState } from './report-items.reducer';
import * as fromQuickLinks from './quick-links.reducer';
import { QuickLinksState } from './quick-links.reducer';
import * as fromMessages from './message.reducer';
import { MessageState } from './message.reducer';
import * as fromComapny from './company.reducer';
import { CompanyState } from './company.reducer';

export interface State {
  defendants: DefendantState;
  reportItems: ReportItemState;
  quickLinks: QuickLinksState;
  messages: MessageState;
  company: CompanyState;
}

export const reducers: ActionReducerMap<State, Action> = {
  defendants: fromDefendants.reducer,
  reportItems: fromReportItems.reducer,
  quickLinks: fromQuickLinks.reducer,
  messages: fromMessages.reducer,
  company: fromComapny.reducer,
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    // console.log('state', state);
    // console.log('action', action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug] : [];
