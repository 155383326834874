import { isObject } from 'rxjs/internal-compatibility';

export interface Resource {
  resourceName: string;
}

export class BaseModel {
  static readonly STATE_VALID: string = '.valid';
  static readonly STATE_DIRTY: string = '.dirty';
  static readonly STATE_SELECTED: string = '.selected';
  static readonly STATE_ADDED: string = '.added';
  static readonly STATE_DELETED: string = '.deleted';
  currentState: string;
  protected childrenNames = [];
  protected propertiesToIgnoreForCheckChanges = ['childrenNames', 'routeRoot', 'currentState', 'propertiesToIgnoreForCheckChanges', 'isAdded', 'isDeleted', 'isDirty', 'isValid', 'isSelected', 'cacheObjects', 'wasCopied'];
  public routeRoot = '';
  cacheObjects: any = [];

  constructor(obj?: Object) {
    // initialize as valid
    this.currentState = '';
    this.addState(BaseModel.STATE_VALID);

    if (obj != null) {
      this.fillFromObject(obj);

    } else {
      // new instance with no initial state is also clean
      // this.addState(Model.STATE_DIRTY);
    }

  }

  public checkForChanges() {
    let dirty = false;

    for (const propName in this) {
      const continueProcessing = ((typeof this[propName] !== 'function') &&
        this.propertiesToIgnoreForCheckChanges.indexOf(propName) < 0 &&
        this.childrenNames.indexOf(propName) < 0 && propName.indexOf('orig_') < 0);

      if (continueProcessing) {
        let origProp = null;
        const currentProp = this[propName];

        if ('orig_' + propName in this) {
          origProp = this['orig_' + propName];
        }
        if (origProp !== null && origProp !== '') {
          if (currentProp == null || currentProp !== origProp) {
            dirty = true;
          }
        } else {
          if (!!currentProp) {
            dirty = true;
          }
        }
        if (dirty) {
          break;
        }
      }
    }

    this.isDirty = dirty && !this.isDeleted;
    return dirty || this.isDeleted || this.isAdded;
  }

  public haveChangesIncludingChildren() {
    // todo: in a future version flesh this out so we have an easy way to tell for hierarchical objects if there are changes anywhere in the chain
  }

  public fillFromObject(obj: Object, excludeChildren = false) {

    if (excludeChildren) {
      this.fillFromObject(this.getObjectWithoutChildren(obj));
    } else {
      for (const propName in obj) {
        this[propName] = obj[propName];
        if (!propName.includes('orig_')) {
          this['orig_' + propName] = obj[propName];
        }
      }
    }
  }


  public loadData(data) {
  }

  public getObjectWithoutChildren(obj: Object) {
    const fillDataObject = new Object();
    for (const propName in obj) {
      if (this.childrenNames.indexOf(propName) < 0) {
        fillDataObject[propName] = obj[propName];
      }
    }
    return fillDataObject;
  }

  private addState(state: string) {
    if (!this.currentState.includes(state))
      this.currentState = this.currentState.concat(state);
  }

  private removeState(state: string) {
    if (this.currentState.includes(state))
      this.currentState = this.currentState.replace(state, '');
  }

  private hasState(state: string): boolean {
    return this.currentState.includes(state);
  }


  get isValid(): boolean {
    return this.hasState(BaseModel.STATE_VALID);
  }

  set isValid(valid: boolean) {
    if (valid) {
      this.addState(BaseModel.STATE_VALID);
    } else {
      this.removeState(BaseModel.STATE_VALID);
    }
  }

  get isDirty(): boolean {
    return this.hasState(BaseModel.STATE_DIRTY);
  }

  set isDirty(dirty: boolean) {
    if (dirty) {
      this.addState(BaseModel.STATE_DIRTY)
    } else {
      this.removeState(BaseModel.STATE_DIRTY);
    }
  }

  get isAdded(): boolean {
    return this.hasState(BaseModel.STATE_ADDED);
  }

  set isAdded(added: boolean) {
    if (added) {
      this.addState(BaseModel.STATE_ADDED)
    } else {
      this.removeState(BaseModel.STATE_ADDED);
    }
  }

  get isDeleted(): boolean {
    return this.hasState(BaseModel.STATE_DELETED);
  }

  set isDeleted(added: boolean) {
    if (added) {
      this.addState(BaseModel.STATE_DELETED)
    } else {
      this.removeState(BaseModel.STATE_DELETED);
    }
  }

  get isSelected(): boolean {
    return this.hasState(BaseModel.STATE_SELECTED);
  }

  set isSelected(selected: boolean) {
    if (selected) {
      this.addState(BaseModel.STATE_SELECTED)
    } else {
      this.removeState(BaseModel.STATE_SELECTED);
    }
  }

  public addCache(key: string, data: any) {
    //the purpose of this is to create a place where each model can carry its lookup data so we dont have to fetch it from the api again.
    //usefull when the ui screen is being re-rendered and you simply need the list that was applicable at that point
    //em let stry and remove from cache if it already exists and then add new data to cache
    let idx = this.cacheObjects.findIndex(item => item.key === key);//find index in your array
    if (idx >= 0) {
      this.cacheObjects.splice(idx, 1);
    }
    this.cacheObjects.push({'key': key, 'data': data});
  }


  public getCache(key: string) {
    let dataObject = this.cacheObjects.find(item => item.key === key);
    if (dataObject !== undefined) {
      return dataObject.data;
    }
    else {
      return undefined;
    }
  }

  public mergeValues(target: any, source: any) {
    if (Array.isArray(target) && Array.isArray(source)) {
      return this.mergeArrays(target, source);
    }
    if (isObject(target) && isObject(source)) {
      return this.mergeObjects(target, source);
    }
    if (source === undefined) {
      return target;
    }
    return source;
  }

  public mergeObjects(target: object, source: object) {
    Object.keys(source).forEach((key) => {
      const sourceValue = source[key];
      const targetValue = target[key];
      target[key] = this.mergeValues(targetValue, sourceValue);
    });

    return target;
  }

  public mergeArrays(target: any[], source: any[]) {
    target.length = source.length;
    source.forEach((value, index) => {
      target[index] = this.mergeValues(target[index], value);
    });

    return target;
  }
}
