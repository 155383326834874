import { fade } from '../../../app.animations';
import { filter, map, mergeMap } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter, HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { EventService } from '../../../services/event.service';
import { BillingService, CapAccount } from '../../../services/billing.service';
import { AppConstants } from '../../../shared/AppConstants';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { AuthService } from '../../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from '../../controls/warning-dialog/warning-dialog.component';
import { ESignCheckMessageComponent } from '../../../views/e-sign/esign-check-message/esign-check-message.component';
import { NotificationDialogComponent } from '../../controls/notification-dialog/notification-dialog.component';

@Component({
  selector: 'app-basic',
  templateUrl: 'basic.component.html',
  styleUrls: ['basic.component.scss'],
  animations: [
    fade,
  ],
})
export class BasicComponent extends UnsubscribeOnDestroyAdapter implements OnDestroy {
  @ViewChild('nav', { static: true }) sideNav: MatSidenav;
  @ViewChild('content', { static: true }) content: any;
  @ViewChild('slideOut', { static: true }) slideOut: MatSidenav;
  @Input() pageActions: ComponentRef<any>;
  @Output() onLoad: EventEmitter<boolean> = new EventEmitter<boolean>();

  mobileQuery: MediaQueryList;
  showLoading = false;
  topSpace: number;
  showpreview= false;
  adviceDisplay = false;
  displayAdviceBanners: any = [];
  defendantId: string;
  pageTitle: string;
  pageSubtext: string;
  accounts: CapAccount[];
  account: CapAccount;
  isFullProfile = false;

  private _mobileQueryListener: () => void;
  private activeCustomerId: string;
  private currentCustomerId: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private eventService: EventService,
    public viewContainerRef: ViewContainerRef,
    private billingService: BillingService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {
    super();
    const main = this;
    this.mobileQuery = media.matchMedia('(max-width: 960px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.activeCustomerId = this.authService.getActiveCustomerId();
    this.currentCustomerId = this.authService.getActiveCustomerId();
    this.accounts = this.authService.getValidAccounts();
    this.account = this.accounts.find(a => a.CustomerId === this.activeCustomerId);
    this.subs.sink = this.eventService.onAccountSwitch$.subscribe(() => {
      this.activeCustomerId = this.authService.getActiveCustomerId();
      this.currentCustomerId = this.authService.getActiveCustomerId();
      this.accounts = this.authService.getValidAccounts();
      this.account = this.accounts.find(a => a.CustomerId === this.activeCustomerId);
    });
    this.subs.sink = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data))
      .subscribe((event) => {
        if (this.slideOut.opened && event.pageTitle !== 'Reports & Forms' ) {
          this.showpreview = false;
          this.slideOut.close();
        }

        if (event.pageTitle === 'Defendant Profile') {
          this.isFullProfile = true;
        } else {
          this.isFullProfile = false;
        }

        this.pageTitle = event.pageTitle;
        this.pageSubtext = event.pageSubtext;
        this.pageActions = event.pageActions;

        if (this.mobileQuery.matches) {
          this.sideNav.close();
        }
      });

      this.subs.sink = this.eventService.pageTitleChangedEvent$.subscribe(item => {
        this.pageTitle = item;
      });
      this.subs.sink = this.eventService.customerAccount$.subscribe(customerId => {
        this.currentCustomerId = customerId;
      });

    this.subs.sink = this.eventService.pageSubtextChangedEvent$.subscribe(item => {
      this.pageSubtext = item;
    });
    this.subs.sink = this.eventService.closeSlideEvent$.subscribe(() => {
      this.showpreview = false;
      this.slideOut.close();
    });

    // Check customer subscribed products
    this.subs.sink = this.billingService.GetSubscribedProducts().subscribe(products => {
      // Defendant manager, setup reminders
      if (!!products && products[AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER] && !products[AppConstants.PRODUCT_NAME_TEXT_MESSAGING]) {
        main.displayAdviceBanners.push({
          render: 'defendantmanager-reminder-advice',
          display: true,
        });
      }
    });

    this.subs.sink = this.eventService.isBusy$.subscribe(item => {
      this.showLoading = item;
      // console.log('Show Loading = ' + item);
    });
    this.subs.sink = this.eventService.slideOutEvent$.subscribe(defendantId => {
      this.defendantId = defendantId;
      this.showpreview = false;
      setTimeout(() => {
        this.showpreview = true;
        this.slideOut.open();
        this.eventService.defendantloadData();

      }, 500);
    });
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.checkHiddenDocument();
  }

  checkHiddenDocument() {
    if (document.hidden) {
     // Todo: Handle tab hidden
    } else {
     if (this.activeCustomerId !== this.currentCustomerId) {
       this.dialog.open(NotificationDialogComponent, {
         data: {
           message: 'You witched accounts. Close this tab!',
         },
         maxWidth: '300px',
         closeOnNavigation: false,
         hasBackdrop: true,
         disableClose: true,
       });
     }
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  onContentLoaded(event: boolean) {
    this.onLoad.emit(true);
  }
}
