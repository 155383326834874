<h2 mat-dialog-title>Document History</h2>

<mat-dialog-content>
  <mat-action-list>
    <div mat-subheader>{{data.name}}</div>
    <button mat-list-item *ngFor='let version of data.versions' [mat-dialog-close]='version'>
      <i mat-list-icon class="fal fa-file-pdf"></i>
      <div mat-line *ngIf='data.type === "wasabi"'>
        {{version.LastModified | friendlyDate}}
      </div>
      <div mat-line *ngIf='data.type === "captira"'>
        {{version.Created_At | friendlyDate}}
      </div>
      <div mat-line *ngIf='data.type === "wasabi"'>
        {{version.Size | fileSize}}
      </div>
      <div mat-line *ngIf='data.type === "captira"'>
        {{version.ChangeDescription}}
      </div>
      <span class='spacer'></span>
      <i class="fal fa-download fa-lg"></i>
    </button>
  </mat-action-list>
</mat-dialog-content>
<mat-dialog-actions align='end'>
  <button mat-raised-button color='primary' [mat-dialog-close]="false">Okay</button>
</mat-dialog-actions>
