import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartaddressComponent } from './smartaddress.component';
import {
  DxAccordionModule,
  DxBoxModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTemplateModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { GoogleMapsModule } from '@angular/google-maps';
import { AngularGooglePlaceModule } from '../../../components/common/angular-google-place';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    SmartaddressComponent,
  ],
  imports: [
    CommonModule,
    DxAccordionModule,
    DxTextBoxModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxCheckBoxModule,
    MatIconModule,
    MatButtonModule,
    DxButtonModule,
    GoogleMapsModule,
    AngularGooglePlaceModule,
    DxScrollViewModule,
    FlexLayoutModule,
    MatTooltipModule,
  ],
  exports: [
    SmartaddressComponent,
  ],
})
export class SmartaddressModule { }
