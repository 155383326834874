export module AppConstants {

  export const CHECK_IN_PERIODS = [{name: 'Weekly'},
    {name: 'Bi-Weekly'}, {name: 'Monthly'}];

  export const PAYMENT_SCHEDULE_FREQUENCIES = [{name: 'Weekly'}, {name: 'Bi-Weekly'},
    {name: 'Monthly'}, {name: 'Custom'}];

  export const CHECK_IN_INTERVALS = [{name: 'Days'},
    {name: 'Weeks'}, {name: 'Months'}];

  export const CHECK_IN_SCHEDULE_INTERVALS = [{name: 'Weeks'}, {name: 'Months'}, {name: 'Years'}];

  export const CALCULATE_SATISFY_BY_DAYS = [{id: true, name: 'Number of Days'}, {id: false, name: 'Enter Date'}];

  export const DAYS_OF_THE_WEEK = [{id: 1, name: 'Sunday'}, {id: 2, name: 'Monday'}, {id: 3, name: 'Tuesday'},
    {id: 4, name: 'Wednesday'}, {id: 5, name: 'Thursday'}, {id: 6, name: 'Friday'}, {id: 7, name: 'Saturday'}];

  // collection subscription plans

  export const COLLECTION_SUBSCRIPTION_PLANS = [
    {type: 'No Subscription', id: 'STD', text: '$0 / month*', cost: 0, qty: 1},
    {type: 'Standard', id: 'PRO', text: '$49 / month*', cost: 49, qty: 2 },
    {type: 'Pro', id: 'ENT', text: '$199 / month*', cost: 199, qty: 101},
  ];

  export const DEFENDANTWATCH_SUBSCRIPTION_PLANS = [
    {type: 'Defendant Watch Subscription', id: 'DW', text: '$5 / month*', cost: 5, qty: 99999},
  ];

  // BEGIN for navigation routing variables
  export const DEFENDANT_ID_KEY = 'DefendantId';
  export const DEFENDANT_KEY = 'CurrentDefendant';
  export const TRANSACTION_ID_KEY = 'TransactionId';
  export const INVOICE_ID_KEY = 'InvoiceId';
  export const PAYMENT_ID_KEY = 'PaymentId';
  export const BOND_ID_KEY = 'BondId';
  export const POWER_ID_KEY = 'PowerId';
  export const DEFENDANT_NAME_KEY = 'DefendantName';
  export const CHECK_IN_SCHEDULE = 'CheckInSchedule';
  export const CHECK_IN_SCHEDULE_KEY = 'CheckInScheduleId';
  export const PAYMENT_SCHEDULE_KEY = 'PaymentScheduleId';
  export const PDF_VIEWER_ITEM_ID_KEY = 'PdfViewerItemId';

  export const TRANSACTIONS = 'transactions';
  export const ACCOUNTING_DETAILS = 'accounting_details';

  export const CC_AUTH_TRANSACTION_TYPE = 'Auth';
  export const CC_VOID_TRANSACTION_TYPE = 'Void';
  export const CC_REFUND_TRANSACTION_TYPE = 'Refund';

  export const INVENTORY_CREATE_MODE = 'Create';
  export const INVENTORY_ASSIGN_MODE = 'Assign';
  export const INVENTORY_DELETE_MODE = 'Delete';

  export const BILLING_STATUS_KEY = 'BillingStatus';
  export const BILLING_LAST_STATUS_CHECK_TIME = 'BillingLastStatusCheckTime';

  export const PHONE_MASK = '(000) 000-0000';
  export const PHONE_PATTERN = /^(\s*|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4}))$/;
  export const TRACERS_PER_RECORD_COST = 0.15;

  export const PHONE_RULES = {'X': /[0-9]/};

  export const HEADER_TOP_MARGIN = '68px';
  export const HEADER_TOP_MARGIN_IN_POPUP = '-8px';

  export const NO_INFORMATION = 'No Information';
  export const TOAST_DISPLAY_TIME = 3000;


  export const REPORT_TYPE_REPORTS_DEFAULT = 'ReportsDefault';
  export const REPORT_TYPE_REPORTS_CUSTOM = 'CustomReports';
  export const REPORT_TYPE_REPORTS_SURETY = 'SuretyReports';
  export const REPORT_TYPE_REPORTS_COMMUNITY = 'CommunityReports';
  export const REPORT_TYPE_REPORTS_STATE = 'StateReports';
  export const REPORT_TYPE_LETTERS_COMMUNITY = 'CommunityLetters';
  export const REPORT_TYPE_LETTERS_DEFAULT = 'LettersDefault';
  export const REPORT_TYPE_LETTERS_CUSTOM = 'CustomLetters';
  export const REPORT_TYPE_FORMS_STATE = 'StateForms';
  export const REPORT_TYPE_FORMS_SURETY = 'SuretyForms';
  export const REPORT_TYPE_FORMS_CUSTOM = 'CustomForms';
  export const REPORT_TYPE_SYSTEM = 'System';
  export const REPORT_TYPE_SYSTEM_CUSTOM = 'CustomSystem';

  export const ADMIN_EMPLOYEE_DEFAULT = 'ADMINEMPLOYEE';
  // check api IsCustomerSubscribedToServiceSubAction for related server logic
  export const PRODUCT_NAME_DATAMART_ACCESS = 'DATAMARTACCESS';
  export const PRODUCT_NAME_CREDIT_REPORTS = 'CREDITREPORTS';
  export const PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM = 'BAILMANAGEMENT';
  export const PRODUCT_NAME_DEFENDANT_MANAGER = 'DEFENDANTMANAGER';
  export const PRODUCT_NAME_DEFENDANT_WATCH = 'DEFENDANTWATCH';
  export const PRODUCT_NAME_MVS_NETWORK_ACCESS = 'MVSNETWORKACCESS';
  export const PRODUCT_NAME_WEBSITE_BONDING = 'WEBSITEBONDING';
  export const PRODUCT_NAME_TEXT_MESSAGING = 'TEXTMESSAGING';
  export const PRODUCT_NAME_VEHICLE_WATCH = 'VEHICLEWATCHMONITORING';
  export const PRODUCT_NAME_DEFENDANT_MANAGER_PLUS = 'DEFENDANTMANAGERPLUS';
  export const PRODUCT_NAME_COLLECTIONS = 'COLLECTIONS';
  export const PRODUCT_NAME_INSTANTPOWERS = 'INSTANTPOWERS';
  export const PRODUCT_NAME_GOOGLE_REVIEW = 'GOOGLEREVIEW';
  export const PRODUCT_NAME_ESIGN = 'ESIGN';
  export const PRODUCT_NAME_BANKERS = 'BANKERS';
  export const GOOGLE_REVIEW_LEARNMORE = 'https://www.captira.com/google-review/';
  // Defeault mugshot images
  export const DEFAULT_FEMALE_MUGSHOT = '/assets/img/defaultuser-flat-female.teal.svg';

  export const DEFAULT_MALE_MUGSHOT = '/assets/img/defaultuser-flat.teal.svg';
  export const DEFAULT_DL_IMAGE = '/assets/img/id-card-duotone.svg';

  export const MASTER_CALL_SHEET_URL = 'https://docs.google.com/document/d/1GkYQpC68XEB61NjeRYuGLXclNtm60nSmjJBGs5vOkPk';

  // Add new defendant preferences
  export const ADD_DEFENDANT_PREFERENCE_CACHE_KEY = 'default:DefendantDataEntryPreference';
  export const ADD_DEFENDANT_PREFERENCE_CACHE_CAPTURE_VALUE = 'default:DataCapture';
  export const ADD_DEFENDANT_PREFERENCE_CACHE_WIZARD_VALUE = 'default:Wizard';

  export const WHITE_LABEL_PALMETTO = 'PALMETTO';
  export const WHITE_LABEL_NONE = '';

  export const GPS_CHECKIN_URL = 'https://www.captira.com/pages/gps-check-in-help';
  export const FACIAL_RECOGNITION_URL = 'https://www.captira.com/pages/identity-verification';

  export const COLLECTION_ACCOUNT_STATUS_CLOSED = 'Closed';
  export const COLLECTION_ACCOUNT_STATUS_UNCOOPERATIVE = 'Uncooperative';
  export const COLLECTION_ACCOUNT_STATUS_COUNTER_OFFER = 'Settlement Offer';
  export const COLLECTION_ACCOUNT_STATUS_INVOICE_CLAIMED = 'Dispute';
  export const COLLECTION_COUNTEROFFER_STATUS_APPROVED = 'Approved';
  export const COLLECTION_COUNTEROFFER_STATUS_REJECTED = 'Rejected';
  export const COLLECTION_COUNTEROFFER_STATUS_EXPIRED = 'Expired';
  export const COLLECTION_COUNTEROFFER_STATUS_REQUESTED = 'Requested';
  export const COLLECTION_INVOICECLAIM_STATUS_EXPIRED = 'Expired';
  export const COLLECTION_INVOICECLAIM_STATUS_REQUESTED = 'Requested';
  export const COLLECTION_INVOICECLAIM_STATUS_APPROVED = 'Approved';
  export const COLLECTION_INVOICECLAIM_STATUS_REJECTED = 'Rejected';
  export const COLLECTION_CLOSE_REASONS: any = [
    'Alternative Arrangements Made',
    'Amount Too Low',
    'Bankruptcy',
    'Deceased',
    'Do Not Contact',
    'Incorrectly Identified',
    'Invoice Dispute Accepted',
    'Payment Offer Fully Paid',
    'Unresponsive - Refer to Legal',
    'Replied Stop on Text',
  ];

  export const COLLECTION_PAYMENT_STATUS_SUCCESS = 'Success';
  export const COLLECTION_PAYMENT_STATUS_FAIL = 'Fail';
  export const COLLECTION_PAYMENT_STATUS_PENDING = 'Pending';
  export const COLLECTION_NO_GATEWAY_MESSAGE = 'Records saved successfully, We recommend you activate a payment gateway to get paid online. If you want to continue without a gateway select Skip. Debtors will need to make manual payments to you.';
  export const COLLECTION_NO_GATEWAY_COMMISSION_MESSAGE = 'Records saved successfully, but you need to add payment processing before Carol can send first reminders.';

  export const SKIP_COLLECTIONS_ONBOARDING = 'SkipOnboardingWarning';
  export const PUBLIC_PAGE_URLS = [
    'collection-details',
    'collection-counter-offer',
    'collection-claim-invoice',
    'location',
    'bail',
    'securedocs',
    'collection-friends-link',
  ];

  export const COLLECTION_PUBLIC_PAGE_TYPE_FIRM = 'Firm';
  export const COLLECTION_PUBLIC_PAGE_TYPE_LESSFIRM = 'Less Firm';

  export const COLLECTION_LEGAL_TEXT = 'Discounts are only available if paid immediately and do not apply to payment plans' +
    ' (if available). Settlement offers accepted are to be paid immediately without delay. The Creditor reserves the absolute' +
    ' right to revoke without recourse including but not limited to, any element of, discounts, settlement or settlement offers' +
    ' accepted or otherwise, any aspects of payment plans and for the avoidance of doubt, retain the right to accelerate all' +
    ' such monies without notice due to them under payment plans or otherwise. Making settlement offers, establishing payment plans' +
    ' and all other actions are at the revocable pleasure of the Creditor and confers no legal entitlement upon the person or entity' +
    ' owing monies. By clicking a confirmation payment consent button to a schedule of payments plan, you positively authorize that' +
    ' monies be deducted from your authorized methods of payment in the future in accordance with the plan. You can unsubscribe or' +
    ' opt out by calling the Creditor to halt email and other communications - or for co-signers, to opt out, call and ask the' +
    ' creditor to uncheck the cosigner box on the case file to halt further communications from the system, or use the Dispute' +
    ' and include instructions feature. You can opt out of receiving sms texts by replying Stop to the actual sms text. All references' +
    ' to ‘Carol’, if applicable, are to that of software avatar nomenclature. This page provides the required information of limited' +
    ' content messages that include reference hereto by a customizable link included in such message. This contact is for the purpose' +
    ' of collecting a debt owed to the Creditor and information provided may be used for that purpose. Debts generally are subject to' +
    ' prescribed period statute of limitations and while still due, cannot be enforced in court. By clicking on links or use of' +
    ' communications, website of captira.com or its sub-domains, the user agrees to be bound by' +
    ' the';

  export const WIZARD_DEFAULT_STATE = 'NewDefendantState';
  export const WIZARD_DEFAULT_COUNTY = 'NewDefendantCounty';

  export function getDemoText(url: string) {
    return `Hi John Smith (Demo), seems you may have forgotten about an account with us at Demo Company for $1,234.00 that was due on ` +
      `1/13/2020. This link will help you with quick, easy payment options. Thanks, Carol ${url}`;
  }

  export const GATEWAY_REGISTRATION_LINK = '/collections/companies/3';

  export const NUVEI_APPLICATION = 'https://webapp.nuvei.com/app/HTkdMhNV';
  export const CLOVER_APPLICATION = 'https://connect.clover.com/get-started';
  export const ELAVON_APPLICATION = 'https://www.elavon.com/apply-now.html';

  export let publicPageLoadCount = 0;
  export const MIN_PASSWORD_LENGTH = 6;
  export const MIN_PASSWORD_LENGTH_MESSAGE = 'Password should be at least 6 characters';
  export function passwordContainsLetters({ value }): boolean {
    const regex = RegExp(/[A-Za-z]/);
    return regex.test(value);
  }

  export const DEFENDANT_WATCH_NOW_URL = 'https://www.captira.com/pages/defendant-watch-now';
  export const CONTACT_US_URL = 'https://www.captira.com/pages/contact-us';
  export const PAYMENTGATEWAY = {
    NUVEI: 'Nuvei',
    PAYEEZY: 'Payeezy',
    ELAVON: 'Elavon',
    AUTHORIZEDOTNET: 'Authorize.net',
    CLOVER: 'Clover',
    ASP: 'American Spirit',
  };
  export const TRIAL_DAYS_REMAINING = 'TrialDaysRemaining';
  export const PROMO_CODE = {
    key: '1st MONTH FREE',
    value: '1STMONTH',
  };
  export const ALLOWED_EMAIL_DOMAINS = ['gmail.com', 'yahoo.com', 'outlook.com'];
  export const TIMESLOTS = [
    {Id: '09:00:00', Text: "9AM"},
    {Id: '10:00:00', Text: "10AM"},
    {Id: '11:00:00', Text: "11AM"},
    {Id: '12:00:00', Text: "12PM"},
    {Id: '13:00:00', Text: "1PM"},
    {Id: '14:00:00', Text: "2PM"},
    {Id: '15:00:00', Text: "3PM"},
    {Id: '16:00:00', Text: "4PM"},
    {Id: '17:00:00', Text: "5PM"},
  ];

  export const ADDNOTEPOPUPCONFIG = {
    DRAGENABLED: true,
    MAXWIDTH: '50%',
    MAXHEIGHT: '45%',
  };

  export const DEFAULTPOPUPCONFIG = {
    DRAGENABLED: false,
    MAXWIDTH: '80%',
    MAXHEIGHT: '80%',
  };
  export interface StorageRange {
    limit: number;
    message: string;
    next: string;
  }

  export const STORAGEFEERANGE = {
    FREE: {
      limit: 5000,
      message: 'You\'ve reached your account limit for 5,000 bonds.',
      next: 'The next tier to 20,000 bonds is $75 per month.',
    },
    MID: {
      limit: 20000,
      message: 'You\'ve reached your account limit for 20,000 bonds.',
      next: 'The next tier to 50,000 bonds is $150 per month.',
    },
    HIGH: {
      limit: Infinity,
    },
  };
}
