import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Person } from '../../models/person';
import { PersonsService } from '../../services/persons.service';

export interface PersonUpdateData {
  type: PersonUpdateType;
  person: Person;
}

export enum PersonUpdateType {
  EMAIL = 'email',
  MOBILE = 'mobile',
  ADDRESS = 'address',
}

@Component({
  selector: 'app-person-update-dialog',
  templateUrl: './person-update-dialog.component.html',
  styleUrls: ['./person-update-dialog.component.scss'],
})
export class PersonUpdateDialogComponent implements OnInit {

  public readonly personUpdateType = PersonUpdateType;

  constructor(
    public dialogRef: MatDialogRef<PersonUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PersonUpdateData,
    private personService: PersonsService,
  ) { }

  ngOnInit(): void {
  }

  saveInfo(person: Person): void {
    this.personService.updatePersonInfo(person, 'phones,addresses,emails')
      .subscribe(updatedPerson => {
        this.dialogRef.close(updatedPerson);
      }, error => this.dialogRef.close(false));
  }

}
