import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-zoom-config',
  templateUrl: './zoom-config.component.html',
  styleUrls: ['./zoom-config.component.scss'],
})
export class ZoomConfigComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ZoomConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    //
  }

  // Zoom offers the option to customize User IDs. Will need new regex if this happens
  // There may be a possibility of someone customizing an ID with a name.
  public sanitizeMeetingId(input: string) {
    if (input) {
      // console.log(input, this.data);
      const meetingIdParts = input.split('?pwd=');
      const password = meetingIdParts[1];
      input = meetingIdParts[0].replace(/\D/g, '');
      this.data.zoomLink = `${input}${!!password ? ('?pwd=' + password) : ''}`;
      this.data.zoomId = input;
      this.data.zoomPassword = password;
    }
  }

}
