import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
  ) { }

  validateCode(id: string, code: string) {
    return this.http.post(`${this.settings.getBaseUrl()}/identities/validate`, {id, code}).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  resendCode(id: string) {
    return this.http.get(`${this.settings.getBaseUrl()}/identities/resend/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }
}
