import {of, throwError, Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {isNullOrUndefined} from "util";
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class GenericsaverService {
  routeRoot = '';

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  public save(id, endPoint, data): Observable<any> | undefined {
    if (!isNullOrUndefined(data)) {
      this.routeRoot = endPoint;
      // console.log('saver service endpoint', this.routeRoot);
      if (!isNullOrUndefined(data.isAdded) && data.isAdded) {
        // console.log('saver service creating', data);
        return this.create(data);

      } else if (!isNullOrUndefined(data.isDirty) && data.isDirty) {
        // console.log('saver service updating', data);
        return this.update(id, data);
      } else if (!isNullOrUndefined(data.isDeleted) && data.isDeleted) {
        if (!isNullOrUndefined(id) && id !== '') {
          // console.log('saver service deleting', data);
          return this.delete(id)
        }
      } else {
        return of(data);
      }
    }
  }

  update(id, data): Observable<any> {

    // console.log('update route', `${this.settings.getBaseUrl()}/${this.routeRoot}/${id}`);
    // console.log('update data', data);

    return this.http.put(`${this.settings.getBaseUrl()}/${this.routeRoot}/${id}`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  create(data): Observable<any> {

    // console.log('create route', `${this.settings.getBaseUrl()}/${this.routeRoot}`);
    // console.log('create data', data);

    return this.http.post(`${this.settings.getBaseUrl()}/${this.routeRoot}`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  delete(id): Observable<any> {

    // console.log('delete route', `${this.settings.getBaseUrl()}/${this.routeRoot}/${id}`);

    return this.http.delete(`${this.settings.getBaseUrl()}/${this.routeRoot}/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

}
