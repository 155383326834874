import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchresultsComponent } from './searchresults.component';
import { CollectionSearchresultsComponent } from './collections-searchresults.component';
import { DynamicPopupModule } from '../dynamic-popup/dynamic-popup.module';
import {
  DxAutocompleteModule,
  DxDateBoxModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxSelectBoxModule,
  DxTextBoxModule, DxValidatorModule,
} from 'devextreme-angular';

import { MatButtonModule } from '@angular/material/button';
import { SearchboxComponent } from '../searchbox/searchbox.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { AdvancedSearchComponent } from '../advanced-search/advanced-search.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    DxListModule,
    DxAutocompleteModule,
    DxLoadIndicatorModule,
    MatButtonModule,
    DynamicPopupModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    MatCardModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    FlexLayoutModule,
    DxDateBoxModule,
    DxValidatorModule,
    DxiValidationRuleModule,
  ],
  declarations: [
    SearchresultsComponent,
    SearchboxComponent,
    CollectionSearchresultsComponent,
    AdvancedSearchComponent,
  ],
  exports: [
    SearchresultsComponent,
    SearchboxComponent,
    CollectionSearchresultsComponent,
    AdvancedSearchComponent,
  ],
})
export class SearchResultsModule { }
