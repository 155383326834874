import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { AppConstants } from '../../../shared/AppConstants';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { BillingService } from '../../../services/billing.service';
import { DefendantwatchComponent } from '../../../views/common/defendantwatch/defendantwatch.component';
import { DefendantWatchDialogComponent } from '../defendantwatch/defendant-watch-dialog/defendant-watch-dialog.component';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-defendantwatch-prompt',
  templateUrl: './defendantwatch-prompt.component.html',
  styleUrls: ['./defendantwatch-prompt.component.scss'],
})
export class DefendantwatchPromptComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() promptSource: 'BondStatus' | 'DefendantWatch';

  user: any;
  showPrompt$: Observable<boolean>;
  isExtraSmall: boolean;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private billingService: BillingService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isExtraSmall = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
    this.shouldShowPrompt();
  }

  shouldShowPrompt() {
    const user$: Observable<boolean> = this.userService.current().pipe(
      map(res => {
        this.user = res.data;
        return res.data.HideDefendantWatchPrompt;
      }),
    );
    const reminders$: Observable<boolean> = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_DEFENDANT_WATCH);
    this.showPrompt$ = combineLatest([
      user$,
      reminders$,
    ]).pipe(
      map(([hidden, dwEnabled]) => {
        if (this.promptSource === 'DefendantWatch') {
          return false;
        } else {
          if (hidden) {
            return false;
          } else {
            // If defendant watch is enabled we don't need to show the prompt.
            return !dwEnabled;
          }
        }
      }),
    );
  }

  openDefendantWatch() {
    const dialogConfig: MatDialogConfig = {
      width: '85%',
      height: '85%',
      hasBackdrop: true,
      disableClose: true,
    };
    if (this.isExtraSmall) {
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
    }
    const dialogRef = this.dialog.open(DefendantWatchDialogComponent, dialogConfig);

    dialogRef.beforeClosed().toPromise().then(async () => {
      await this.shouldShowPrompt();
    });
  }

  dontShowDefendantWatchPopUp() {
    this.user.HideDefendantWatchPrompt = 1;
    this.userService.updateUser(this.user.Id, this.user)
      .then(() => {
        this.showPrompt$ = of(false);
      });
  }

}
