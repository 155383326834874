<div style="margin: 16px auto; width: 95%;">
  <div class="vertical-timeline-block">
    <div class="vertical-timeline-content">
      <form class="form-horizontal">
        <div class="ibox-content no-border">
          <!-- Message type hidden because we need fix the message templates with specific defendants selected -->
          <div class="form-group" hidden="true">
            <label class="col-sm-3 control-label">SMS Message</label>
            <div class="col-sm-6">
              <dx-select-box
                  #ddlMessageType
                  id="ddlMessageType"
                  text="Message Type"
                  [dataSource]="messageTypeOptions"
                  displayExpr="Text"
                  valueExpr="Id"
                  value="ALL"
                  (onValueChanged)="onMessageTypeChanged($event)"
                  [searchEnabled]="false"
                  [(value)]="selectedMessageTypeOption">
              </dx-select-box>
            </div>
          </div>

          <div class="form-group"
               *ngIf="ddlMessageType.value === '3' ||
              (ddlTemplateType !== undefined && ddlTemplateType.value === '1')">
            <label class="col-sm-3 control-label">
              Select a message template
            </label>
            <div class="col-sm-6">
              <dx-select-box
                  #ddlMessageTemplate
                  [dataSource]="notificationTemplates"
                  displayExpr="TemplateName"
                  valueExpr="Id"
                  placeholder="Select Template"
                  [searchEnabled]="false"
                  (onValueChanged)="onMessageTemplateChanged($event)">
              </dx-select-box>
            </div>
          </div>

          <div class="form-group" *ngIf="ddlMessageType.value !== '3' && selectedAudienceOption !== '7'">
            <label class="col-sm-3 control-label">Message Text</label>
            <div class="col-sm-6">
              <dx-text-area
                  #txMessage
                  id="txMessage"
                  placeholder="Enter message text"
                  [height]="100"
                  [maxLength]="1000"
                  (onFocusOut)="onBlurMessageText($event)"
                  [(value)]="messageText"
                  (onKeyUp)="onTemplateKeyUp($event)">
              </dx-text-area>
              <small style="color: gray;">{{ txMessage.text?.length }} characters</small>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label">Send To</label>
            <div class="col-sm-6">
              <dx-select-box
                  #ddlAudience
                  id="ddlAudience"
                  [dataSource]="messageAudienceOptions"
                  displayExpr="Text"
                  valueExpr="Id"
                  [searchEnabled]="false"
                  [showClearButton]="false"
                  [(value)]="selectedAudienceOption">
              </dx-select-box>
            </div>
          </div>

          <div class="form-group" *ngIf="selectedAudienceOption == '6'">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <dx-text-box
                  #txNumber
                  id="txNumber"
                  [mask]="phoneMask"
                  maxLength="20"
                  mode="tel"
                  maskInvalidMessage="Please enter a valid phone number"
                  [useMaskedValue]="false"
                  [(value)]="customNumber"
                  maskChar="_"
                  class="masked-input"
                  [required]="selectedAudienceOption == '6'"
              ></dx-text-box>
            </div>
          </div>

          <div class="form-group" *ngIf="selectedAudienceOption != '6'">
            <label class="col-sm-3 control-label">Text All Defendant's Numbers</label>
            <div class="col-sm-1">
              <dx-check-box
                  #chkAllNumbers
                  id="chkAllNumbers"
                  [(value)]="includeAllNumbers">
              </dx-check-box>
            </div>
          </div>
          <div class="form-group" *ngIf="selectedAudienceOption === '7'">
            <label class="col-sm-3 control-label">Include Indemnitors</label>
            <div class="col-sm-1">
              <dx-check-box
                id="chkSendIndemnitors"
                [(value)]="includeIndemnitors">
              </dx-check-box>
            </div>
          </div>
        </div>
      </form>

      <div class="row" *ngIf="ddlAudience.value == '3' || ddlAudience.value == '4'">
        <div [class.col-lg-3]="!quickLinksExpanded"
             [class.col-lg-12]="quickLinksExpanded">
          <app-quick-links
              #quickLinks
              id="quickLinks"
              [loadCounts]="false"
              [horizonallyCollapsible]="true"
              horizontallyCollapsed="false"
              (resizeRequest)="quickLinksResize($event)"
              allowSelection="true"
              caption="Select defendants to send messages to:"
              sourceView="reminders"
              presetCategory="dashboardactivedefendants">
          </app-quick-links>
        </div>
      </div>

      <div class="row" *ngIf="ddlAudience.value == '7'">
        <div [class.col-lg-3]="!quickLinksExpanded"
             [class.col-lg-12]="quickLinksExpanded">
          <app-quick-links
              #quickLinks
              [loadCounts]="false"
              [horizonallyCollapsible]="true"
              horizontallyCollapsed="false"
              (resizeRequest)="quickLinksResize($event)"
              allowSelection="true"
              caption="Select defendants to send messages to:"
              sourceView="reminders"
              presetCategory="dashboardpastdue">
          </app-quick-links>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
    <dx-button
      text="Send Reminders"
      (onClick)="startCampaign($event)"
      type="default">
    </dx-button>
  </div>
</div>

<dx-popup
    #paramPopup
    id="paramPopup"
    [showTitle]="true"
    title="Enter Reminder Filter Options"
    [closeOnOutsideClick]="false"
    [(visible)]="paramPopupVisible"
    [width]="550"
    [height]="250">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
        #scrlPreview
        id="scrlPreview"
        [scrollByContent]="scrollByContent"
        [scrollByThumb]="scrollByThumb"
        [showScrollbar]="'always'"
        [bounceEnabled]="pullDown"
        [useNative]="false">
      <div id="dvCourtDateParams"
           *ngIf="selectedMessageType.Code === 'CourtDate'">
        <form class="form-group">
          <label class="col-sm-5 control-label"
                 style="padding-top:8px;padding-left:0px;">
            Court Dates Due In The Next
          </label>
          <div class="col-sm-3" style="padding-left:0px;padding-right:0px;">
            <dx-number-box
                #dxCourtDays
                id="dxCourtDays"
                [showSpinButtons]="true"
                [showClearButton]="true"
                min="0"
                [(value)]="paramCourtMinDays">
            </dx-number-box>
          </div>
          <label class="col-sm-1 control-label"
                 style="padding-top:8px;padding-left:0px;padding-right:0px;">
            Days
          </label>
        </form>
      </div>

      <div id="dvCheckinParams"
           *ngIf="selectedMessageType.Code === 'StdCheckin' ||
          selectedMessageType.Code === 'GpsCheckin'">
        <form class="form-group">
          <label class="col-sm-5 control-label"
                 style="padding-top:8px;padding-left:0px;">
            Checkins Due In The Next
          </label>
          <div class="col-sm-3" style="padding-left:0px;padding-right:0px;">
            <dx-number-box
                #dxCheckinDays
                id="dxCheckinDays"
                [showSpinButtons]="true"
                [showClearButton]="true"
                min="0"
                [(value)]="paramCheckinMinDays">
            </dx-number-box>
          </div>
          <label
            class="col-sm-1 control-label"
            style="padding-top:8px;padding-left:0px;padding-right:0px;">
            Days
          </label>
        </form>
      </div>

      <div id="dvCheckinPaymentDueParams"
           *ngIf="selectedMessageType.Code === 'PaymentReminder'">
        <form class="form-group">
          <label class="col-sm-5 control-label"
                 style="padding-top:8px;padding-left:0px;">
            Payments Due In The Next
          </label>
          <div class="col-sm-3" style="padding-left:0px;padding-right:0px;">
            <dx-number-box
                #dxPaymmentDueDays
                id="dxPaymmentDueDays"
                [showSpinButtons]="true"
                [showClearButton]="true"
                min="0"
                [(value)]="paramPaymentReminderMinDays">
            </dx-number-box>
          </div>
          <label class="col-sm-1 control-label"
                 style="padding-top:8px;padding-left:0px;padding-right:0px;">
            Days
          </label>
        </form>
      </div>

      <div id="dvMissedCheckinParams"
           *ngIf="selectedMessageType.Code === 'MissedCheckin'">
        <form class="form-group">
          <label class="col-sm-5 control-label"
                 style="padding-top:8px;padding-left:0px;">
            Checkins missed in the last
          </label>
          <div class="col-sm-3" style="padding-left:0px;padding-right:0px;">
            <dx-number-box
                #dxMissedCheckinDays
                id="dxMissedCheckinDays"
                [showSpinButtons]="true"
                [showClearButton]="true"
                min="0"
                [(value)]="paramMissedCheckinMinDays">
            </dx-number-box>
          </div>
          <label class="col-sm-1 control-label"
                 style="padding-top:8px;padding-left:0px;padding-right:0px;">
            Days
          </label>
        </form>
      </div>

      <div id="dvPastDueParams"
           *ngIf="selectedMessageType.Code === 'PastDue'">
        <form class="form-group">
          <label
            class="col-sm-5 control-label"
            style="padding-top:8px;padding-left:0px;">
            Amount overdue more than
          </label>
          <div class="col-sm-3" style="padding-left:0px;padding-right:0px;">
            <dx-number-box
                #dxMinBalance
                id="dxMinBalance"
                [showSpinButtons]="true"
                [showClearButton]="true"
                [format]="{ type: 'currency', precision: 2 }"
                [(value)]="paramPastDueMinBalance">
            </dx-number-box>
          </div>
          <label class="col-sm-1 control-label"
                 style="padding-top:8px;padding-left:0px;padding-right:0px;">
            $
          </label>
        </form>
        <form class="form-group">
          <label
            class="col-sm-5 control-label"
            style="padding-top:8px;padding-left:0px;">
            & days overdue more than
          </label>
          <div class="col-sm-3" style="padding-left:0px;padding-right:0px;">
            <dx-number-box
                #dxMissedCheckinDays
                id="dxMissedCheckinDays"
                [showSpinButtons]="true"
                [showClearButton]="true"
                min="0"
                [(value)]="paramPastDueMinDays">
            </dx-number-box>
          </div>
          <label class="col-sm-1 control-label" style="padding-top:8px;padding-left:0px;padding-right:0px;">
            Days
          </label>
        </form>
      </div>

      <div class="row">
        <div class="col-md-12 text-center">
          <dx-button
            id="btnParamOk"
            text="Ok"
            type="default"
            icon="fal fa-check"
            (onClick)="setReminderParams()">
          </dx-button>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [showTitle]="'true'"
  [title]="'Subscribe to Reminder Service'"
  [closeOnOutsideClick]="'false'"
  [(visible)]="showSubscribeConfirmation"
  maxWidth="600px"
  maxHeight="550px"
  [showCloseButton]="'false'"
  (onHidden)="subscribePopupHidden()">
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12" *ngIf="!showBuyNumber">
        <label class="control-label" style="text-align: center;">
          You are enabling automated reminders. There is a
          per message cost depending on the type of reminder. Click subscribe to
          continue.
        </label>
      </div>
      <br>
      <div class="col-md-12" *ngIf="showBuyNumber">
        <app-reminder-number-buyer
          (onSuccess)="numberBought()"
        ></app-reminder-number-buyer>
      </div>
      <br>
    </div>
    <div class="ibox-content text-center p-md" *ngIf="!showBuyNumber">
      <dx-button
        id="cancel"
        type="normal"
        [text]="'Never Mind'"
        icon=""
        style="margin-right: 8px;"
        (onClick)="YesNoConfirmResult(false)"
      ></dx-button>
      <dx-button
        id="save"
        type="default"
        [width]="'200px'"
        [text]="'Subscribe Now'"
        [disabled]="showBuyNumber && !numberPurchased"
        icon="fal fa-check"
        (onClick)="YesNoConfirmResult(true)"
      ></dx-button>
    </div>
  </div>
</dx-popup>
