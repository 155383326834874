import {Component, Input, OnInit, OnChanges, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import { ObjectUtilities } from '../../../shared/ObjectUtilities';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;

@Component({
  selector: 'app-errorpopup',
  templateUrl: './errorpopup.component.html',
  styleUrls: ['./errorpopup.component.scss'],
})
export class ErrorPopupComponent implements OnChanges {

  @Input() visible: boolean;
  @Input() data: any;
  validationMessages: any[];
  errorMessages: any[];
  authorizationMessages: any[];
  isAuthorizationError = false;

  @Input() routeToGoToAfterDisplay = '';

  @Input() width = '300';
  @Input() height = '300';
  @Input() dragEnabled = true;
  @Input() class = 'popup';
  @Input() showTitle = true;
  @Input() title = '';
  @Input() closeOnOutsideClick = true;
  @Input() shading = true;
  @Input() hasRows = false;
  popupClass = 'popup';
  @Input() resizeEnabled = false;
  @Input() helpLink = '';
  constructor(private router: Router, private elRef: ElementRef) {
  }

  getWidth() {
    return '300';
  }

  closeClick(e: any) {
    if (this.routeToGoToAfterDisplay !== '') {
      this.router.navigateByUrl(this.routeToGoToAfterDisplay);
    }
    this.visible = false;
    this.data = [];
  }

  processAuthorizationError() {
    // console.log(this.data.details);
    const details = this.data.details.toString().split('::');
    // console.log('auth details', details, details.length);
    if (details && details.length > 0) {
      // console.log('inside if');
      const myThis = this;
      details.forEach(function (detail) {
        // console.log('inside loop', detail);
        const newAuthMessage = {detail: detail};
        // console.log('newauthmessage', newAuthMessage, myThis.authorizationMessages);
        myThis.authorizationMessages.push(newAuthMessage);
        // console.log('new auth message', newAuthMessage, myThis.authorizationMessages);
      });
    }

    // console.log('authorization messages', this.authorizationMessages);
  }

  processErrorArray() {

    // console.log('processErrorArray:status_code', this.data.status_code);

    if (this.data.status_code === 403) {
      this.processAuthorizationError();
    } else if (this.data.status_code === 422) {
      const errorArray = this.data.errors;
      let errorCount = 0;
      let i: any;
      const localThis = this;
      for (i in this.data.errors) {
        if (this.data.errors.hasOwnProperty(i)) {
          // console.log('error detail', i);
          const errorRowData = i.split('.');
          const errorDetails = errorArray[i];

          errorDetails.forEach(function (e: any) {
            let validationMessage = '';
            let rowNumber = -1;
            errorRowData.forEach(function (val: any) {
              if (!isNaN(val)) {
                rowNumber = parseInt(val.toString(), 10);
              }
            });
            if (rowNumber >= 0) {
              validationMessage = e + ' (Row  ' + (rowNumber + 1).toString() + ')';
            } else {
              validationMessage = e;
            }
            // console.log(validationMessage);
            localThis.validationMessages.push({'detail': validationMessage});
            // console.log(e);
          });
          errorCount++;
        }
      }
      this.visible = true;
    } else {
      const errorArray = this.data.errors;
      let errorCount = 0;
      let i: any;
      const localThis = this;
      for (i in this.data.errors) {
        if (this.data.errors.hasOwnProperty(i)) {
          // console.log('error detail', i);
          const errorRowData = i.split('.');
          const errorDetails = errorArray[i];

          errorDetails.forEach(function (e: any) {
            let errorMessage = '';
            if (errorRowData.length > 1 && typeof errorRowData[1] === 'number') {
              errorMessage = e + ' on row ' + (+errorRowData[1] + 1).toString();
            } else {
              errorMessage = e;
            }
            // console.log(errorMessage);
            localThis.errorMessages.push({'detail': errorMessage});
            // console.log(e);
          });
          errorCount++;
        }
      }
      this.visible = true;
    }
  }

  processErrorMessage(err, localThis) {
    if (err.status_code === 422) {
      if (err.detail && err.detail !== undefined && err.detail !== '') {
        localThis.validationMessages.push({'detail': err.detail});
      } else {
        localThis.validationMessages.push({'detail': err.message});
      }
    } else {
      if (err.detail && err.detail !== undefined && err.detail !== '') {
        localThis.errorMessages.push({'detail': err.detail});
      } else if (err.message) {
        localThis.errorMessages.push({'detail': err.message});
      }
    }

  }

  processData() {
    if (this.data.errors && this.data.errors !== undefined) {
      this.processErrorArray();
    } else {
      if (this.data.status_code && this.data.status_code === 403) {
        this.processAuthorizationError();
      } else {
        const localThis = this;
        if (Array.isArray(this.data)) {
          this.data.forEach(function (err) {
            localThis.processErrorMessage(err, localThis);
          });
        } else {
          localThis.processErrorMessage(this.data, this);
        }
      }
    }
  }

  setData() {
    if (!isNullUndefinedOrEmpty(this.data)) {
      this.validationMessages = [];
      this.errorMessages = [];
      this.authorizationMessages = [];
      this.processData();

      // console.log('data error');
      // console.log(this.data);

      if (this.errorMessages && this.errorMessages) {
        this.popupClass = 'popup';
      } else if (this.validationMessages && this.validationMessages.length > 0) {
        this.popupClass = 'popup';
      }

      this.errorMessages.forEach(function (e) {
        if (!isNullOrUndefined(e.message)) {
          e.detail = e.message;
        }
      });
      if (this.validationMessages.length > 0 || this.authorizationMessages.length > 0 || this.errorMessages.length > 0) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    } else {
      this.validationMessages = [];
      this.errorMessages = [];
      this.authorizationMessages = [];
      this.visible = false;
    }
  }

  ngOnChanges() {
    this.setData();
  }

}
