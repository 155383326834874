import { take } from 'rxjs/operators';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { LookupService } from '../../../services/app-lookup.service';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { ObjectUtilities } from '../../../shared/ObjectUtilities';
import { UserService } from '../../../services/user.service';
import { BaseList } from '../../system/lists/base-list';
import { MatDialog } from '@angular/material/dialog';
import { ListspopupComponent } from './../../system/lists/listspopup/listspopup.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;
import Guid from 'devextreme/core/guid';


@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
})
export class LookupComponent extends BaseList implements OnInit, AfterViewInit, OnChanges {
  @Input() lookupData: any;
  @Input() lookupType: any;
  @Input() lookupTypeUserFriendly = '';
  @Input() lookupSource: any;
  @Input() selectedValue: string;
  @Output() selectedValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() source: any;
  @Input() placeHolder: string;
  @Input() displayExpr = 'Text';
  @Input() valueExpr = 'Id';
  @Input() required = false;
  @Input() viewIndex: any;
  @Input() isLimitedUser = false;
  @Input() fieldsDisabled = false;

  @Input() setIdForSmartForms: any;
  @Input() addNoneValue = false;
  @Input() itemsToExclude: any = [];
  @Input() allowAddCustomValue = true;
  @Input() filter?: string;
  @Input() filterKey?: string;
  @Input() allowDuplicates = true;
  @Input() fromExternal = false;
  @Input() hideIcon: boolean;
  @Input() customerId: string = '';
  @Input() isValid = true;

  @Output() singleAgencySelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() onLookupDataReady: EventEmitter<any> = new EventEmitter<any>();
  @Output() addOrRemoveFromNotes: EventEmitter<any> = new EventEmitter<any>();

  // prompt user to add entered value to the source list.
  // if false, will allow any text to be entered into the dropdown
  @Input() forceCustomValueToList = true;

  // allow a default value to be set inside of a form element
  @Input() useDefaultValue = false;
  @Input() fillSingleValue = true;
  @Input() placeholderVisible = true;

  @ViewChild('lookup', {static: true}) lookup: DxSelectBoxComponent;
  isLoading = false;
  newData: any;
  type: any;
  randomId =  new Guid().toString();

  confirmationMessage = '';
  errorData: any;
  showConfirmation = false;
  allLookupData: any;
  public selectedObject: any;
  isExtraSmall: boolean;

  constructor(
    private lookupService: LookupService,
    private userService: UserService,
    public dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    super(dialog);
  }

  validateLookup = (options: any) => {
    if (!this.required) {
      return true;
    } else {
      return !isNullUndefinedOrEmpty(this.selectedValue);
    }
  }

  ngOnInit() {
    if (this.placeHolder == null) {
     this.placeHolder = (this.allowAddCustomValue ? 'Select or type  ' : 'Select') + this.lookupTypeUserFriendly;
    }
    if (this.lookupData == null) {
      if(!this.fromExternal) {
        this.loadLookup();
      } else {
        this.loadLookupExternal();
      }
    } else {
      this.allLookupData = [...this.lookupData];
    }

    this.isExtraSmall = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
  }

  ngOnChanges(changes) {
    if (!!changes.isLimitedUser) {
      this.fillLimitedUserItem();
    }

    if (changes.itemsToExclude && this.allLookupData) {
      this.excludeItems();
    }

    if (!!changes.filter && !changes.filter.firstChange) {
      this.filterItems();
    }

    if (!!changes.selectedValue && !!changes.selectedValue.currentValue) {
      this.selectedValue = changes.selectedValue.currentValue;
      if (!this.fromExternal) {
        this.loadLookup();
      } else {
        this.loadLookupExternal();
      }
    }
  }

  addCustomItem(data) {
    if (!!data.text) {
      if(this.fromExternal) {
        this.subs.sink = this.isDuplicateInList(this.lookupTypeUserFriendly, data.text, this.lookupData, true).subscribe(res => {
          if(!res) {
            this.lookupData.push({Id: data.text, Text: data.text});
            setTimeout(() => {
              this.selectedValue = data.text;
              this.addOrRemoveFromNotes.emit({Text: data.text, LookupName: this.lookupTypeUserFriendly, Should: 'add'});
            }, 100)
          } else {
            let existingItem = this.lookupData.filter((lookupElement: any) => lookupElement.Text === data.text)[0];
            setTimeout(() => {
              this.selectedValue = existingItem.Id;
              if(this.isAlphanumeric(existingItem.Id)) {
                this.addOrRemoveFromNotes.emit({Text: data.text, Should: 'remove', LookupName: this.lookupTypeUserFriendly});
              } else {
                this.addOrRemoveFromNotes.emit({Text: data.text, LookupName: this.lookupTypeUserFriendly, Should: 'add'});
              }
            })
          }
        });
        this.showConfirmation = false;
      } else {
        this.updateCustomValue(this.lookupType, data, this.lookupSource);
      }
    }
  }

  fillSingleAgency() {
    if (this.lookupType === 'agencies') {
      if (!!this.lookupData && this.lookupData.length === 1 && this.fillSingleValue) {
        this.selectedValue = this.lookupData[0][this.valueExpr];
        this.singleAgencySelected.emit(this.selectedValue);
      }
    }
  }

  fillLimitedUserItem() {
    if (this.isLimitedUser) {
      if (this.lookupType === 'agencies' || this.lookupType === 'agents') {
        this.subs.sink = this.userService.current().subscribe(response => {
          if (!!response.data) {
            const user = response.data;
            if (!!this.lookupData && !this.selectedValue) {
              if (this.lookupType === 'agencies' && !!user.LimitAgencyIds) {
                const limitAgencyIds = user.LimitAgencyIds.split(',');
                this.selectedValue = limitAgencyIds[0];
                this.selectedValueChange.emit(this.selectedValue);
              }
              if (this.lookupType === 'agents' && !!user.LimitAgentIds) {
                const limitAgentIds = user.LimitAgentIds.split(',');
                this.selectedValue = limitAgentIds[0];
                this.selectedValueChange.emit(this.selectedValue);
              }
            }
          }
        });
      }
    }
  }

  excludeItems() {
    if (this.itemsToExclude && this.itemsToExclude.length) {
      this.lookupData = this.lookupData.filter(data => !this.itemsToExclude.includes(data.Id));
    } else {
      this.lookupData = [...this.allLookupData];
    }
  }


  loadLookupExternal() {
    this.isLoading = true;
    const source = (this.lookupSource.toLowerCase() === 'lookups') ? this.lookupSource : null;
    this.subs.sink = this.lookupService.getLookup(this.lookupType, [this.selectedValue], source, undefined, undefined, this.customerId, true).pipe(take(1)).subscribe(l => {
      if (l.data != null) {
        this.allLookupData = l.data;
        this.lookupData = l.data;
        if (this.addNoneValue) {
          const noneItem = {};
          noneItem[this.valueExpr] = 'none';
          noneItem[this.displayExpr] = '(None)';
          this.lookupData.unshift(noneItem);
        }

        this.excludeItems();
        this.filterItems();
        this.fillSingleAgency();
        this.fillLimitedUserItem();
        if (this.selectedValue) {
          this.selectedObject = this.lookupData.filter(item => item.Id === this.selectedValue)[0];
        } else if (this.useDefaultValue) {
          const found = this.lookupData.find(itm => itm.Id === localStorage.getItem('default-' + this.lookupType));
          if (found != null) {
            this.selectedValue = found.Id;
            this.selectedObject = found;
            this.selectedValueChange.emit(found.Id);
          } else {
            localStorage.removeItem('default-' + this.lookupType);
          }
        }
      } else {
        this.lookupData = null;
        this.allLookupData = [...this.lookupData];
      }
      this.onLookupDataReady.emit({state: true, type: this.lookupType});
      this.isLoading = false;
    }, error => {
      console.error('loadLookup error ', error);
      this.isLoading = false;
    });
  }

  loadLookup() {
    this.isLoading = true;
    const source = (this.lookupSource.toLowerCase() === 'lookups') ? this.lookupSource : null;
    this.subs.sink = this.lookupService.getLookup(this.lookupType, [this.selectedValue], source).pipe(take(1)).subscribe(l => {
      if (l.data != null) {
        this.allLookupData = l.data;
        this.lookupData = l.data;
        if (this.addNoneValue) {
          const noneItem = {};
          noneItem[this.valueExpr] = 'none';
          noneItem[this.displayExpr] = '(None)';
          this.lookupData.unshift(noneItem);
        }

        this.excludeItems();
        this.filterItems();
        this.fillSingleAgency();
        this.fillLimitedUserItem();
        if (this.selectedValue) {
          this.selectedObject = this.lookupData.filter(item => item.Id === this.selectedValue)[0];
        } else if (this.useDefaultValue) {
          const found = this.lookupData.find(itm => itm.Id === localStorage.getItem('default-' + this.lookupType));
          if (found != null) {
            this.selectedValue = found.Id;
            this.selectedObject = found;
            this.selectedValueChange.emit(found.Id);
          } else {
            localStorage.removeItem('default-' + this.lookupType);
          }
        }
      } else {
        this.lookupData = null;
        this.allLookupData = [...this.lookupData];
      }
      this.onLookupDataReady.emit({state: true, type: this.lookupType});
      this.isLoading = false;
    }, error => {
      console.error('loadLookup error ', error);
      this.isLoading = false;
    });
  }

  filterItems() {
    if (this.filter && this.filterKey) {
      let filtered = []

      if(!!this.allLookupData) {
        filtered = this.allLookupData.filter(l => {
          return String(l[this.filterKey]).toLowerCase() === this.filter.toLowerCase() ||
            String(l[this.valueExpr])?.toLowerCase() === this.selectedValue?.toLowerCase();
        });
      }

      // Only filter if we have something. County is not required on Court list and used to by a typed value.
      if (!!this.lookupSource && this.lookupSource === 'Courts') {
        if (filtered.length > 0) {
          this.lookupData = filtered;
        }
      } else {
        this.lookupData = filtered;
      }
    }
  }

  public addItemToList(itemText = '') {
    if(this.lookupSource=='MasterSureties'){
      this.oldAddItemToList(itemText);
    }else{
      const self = this;
      if (!this.newData && itemText !== '') {
        this.newData = { 'Text': itemText, 'Id': '' };
      } else if (itemText === '' && this.newData.Text !== '') {
        itemText = this.newData.Text;
      }
      if (itemText === '') {
        return;
      }
      this.showConfirmation = false;

      if(this.forceCustomValueToList){
        const listPopupDialog = this.dialog.open(ListspopupComponent, {
          data: { inputSource: this.lookupSource, fromLookup: true, text: this.newData.Text, lookupType: this.lookupType},
          width: this.isExtraSmall ? '100%': '85%',
          height: this.isExtraSmall ? '100%': '85%',
          panelClass: 'custom-dialog-container'
        });
        this.subs.sink = listPopupDialog.afterClosed().subscribe(resp => {
          if(resp && resp.Id) {
            self.newData.Id = resp[self.valueExpr];
            self.newData.Text = resp[self.displayExpr];
            self.selectedValue = resp[self.valueExpr];
            self.selectedObject = resp;
            self.selectedValueChange.emit(resp[self.valueExpr]);
            if (self.lookupData === null) {
              self.lookupData = [];
            }
            self.lookupData.push(self.newData);
            self.allLookupData.push(self.newData);
            this.showConfirmation = false;
          }
        });
      }
    }
  }

  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  updateCustomValue(type, data, source) {
    // console.log('lookup updateCustomeValue data', data);
    this.type = type;

    this.newData = {'Text': data.text, 'Id': ''};
    this.confirmationMessage = this.newData.Text + ' will be added to your list. Continue?';
    this.subs.sink = this.isDuplicateInList(
      this.lookupTypeUserFriendly,
      this.newData.Text,
      this.lookupData,
      this.allowDuplicates,
      'Duplicate Found',
      `Duplicate ${this.lookupTypeUserFriendly} not allowed.`,
      )
      .subscribe(duplicate => {
        if (!duplicate) {
          if (this.forceCustomValueToList) {
            this.showConfirmation = true;
          } else {
            this.addItemToList(data.text);
          }
        }
      });
  }

  valueChanged(e) {
    if (!!e.value) {
      this.selectedValue = e.value;
      if (!!this.lookupData) {
        this.selectedObject = this.lookupData.filter(item => item.Id === e.value)[0];
        if (!!this.selectedObject) {
          localStorage.setItem('default-' + this.lookupType, this.selectedObject.Id);
        } else {
          localStorage.removeItem('default-' + this.lookupType);
        }
      }
    } else {
      if (this.useDefaultValue && !!localStorage.getItem('default-' + this.lookupType)) {
        this.selectedValue = localStorage.getItem('default-' + this.lookupType);
      }
    }
    this.selectedValueChange.emit(this.selectedValue);

    // We want to remove the notes if they select or remove item
    if (this.fromExternal) {
      // How can it not have a value and be alpha numeric? This seems off
      if (!e.value) {
        this.addOrRemoveFromNotes.emit({Text: e.value, Should: 'remove', LookupName: this.lookupTypeUserFriendly});
      } else {
        if (!this.selectedObject) {
          this.addOrRemoveFromNotes.emit({ Text: e.value, LookupName: this.lookupTypeUserFriendly, Should: 'add' });
        }
      }
    }
  }

  cancel() {
    this.showConfirmation = false;
  }

  ngAfterViewInit() {
    if (this.viewIndex == null) {
      this.viewIndex = 0;
    }
  }

  google() {
    if (!!this.selectedObject && this.lookupType === 'jails') {
      const googleUrl = 'https://www.google.com/search?q=' +
        (this.selectedObject.State ? this.selectedObject.State : '') +
        '+' + this.selectedObject.Text;
      this.clickLink(googleUrl);
    }
  }

  clickLink(data) {
    if (data.indexOf('http') < 0) {
      data = `http://${data}`;
    }
    window.open(data, '_blank');
  }

  navigateToList() {
    this.showConfirmation = false;
    this.dialog.open(ListspopupComponent, {
      data: { inputSource: this.lookupSource },
      width: this.isExtraSmall ? '100%': '85%',
      height: this.isExtraSmall ? '100%': '85%',
      panelClass: 'custom-dialog-container'
    });
  }

  public oldAddItemToList(itemText = ''){
    const self = this;
    if (!this.newData && itemText !== '') {
      this.newData = { 'Text': itemText, 'Id': '' };
    } else if (itemText === '' && this.newData.Text !== '') {
      itemText = this.newData.Text;
    }
    if (itemText === '') {
      return;
    }
    if (this.forceCustomValueToList) {
      this.subs.sink = this.lookupService.updateLookupNewValue(this.type, this.newData, this.lookupSource)
        .pipe(
          take(1)).subscribe(resp => {
          self.newData.Id = resp.data.Id;
          self.selectedValue = resp.data[self.valueExpr];
          self.selectedObject = resp.data;
          self.selectedValueChange.emit(resp.data[self.valueExpr]);
          if (self.lookupData === null) {
            self.lookupData = [];
          }
          self.lookupData.push(self.newData);
          self.allLookupData.push(self.newData);
          this.showConfirmation = false;
        }, error => {
          self.errorData = error;
          this.showConfirmation = false;
        });
    } else {
      const tempId = this.uuidv4();
      this.newData.Id = tempId; // = {'Text': itemText, 'Id': tempId};
      this.lookupData.push(this.newData);
      this.allLookupData.push(this.newData);
      this.selectedValue = tempId;
      this.selectedObject = this.newData;
      self.selectedValueChange.emit(this.selectedValue);
      setTimeout(() => {
        this.selectedValue = tempId;
      });
    }
  }

  isAlphanumeric(str : string) {
    if(str === undefined || str === null || str === '')
      return false;

    return /^[a-zA-Z0-9]+$/.test(str);
  }
  focusIn(event) {
    setTimeout(function() {
      if (
        !(event.component.option('opened'))) {
        event.component.open();
      }
    }, 200);
  }
}
