import { BaseModel } from './BaseModel';

export class Share extends BaseModel {

  // tslint:disable:variable-name
  Id: string;
  Name: string;
  Email: string;
  Mobile: string;
  Role: string;
  EmailOrMobile: string; // bound to email or mobile with preference to email
  AccessCode: string;
  SourceType: string;
  SourceId: string;
  Status: ShareStatus;
  contactInfo: string[];

  constructor(obj?: object) {
    super(obj);
    this.EmailOrMobile = this.Email || this.Mobile;
    this.Id = '';
  }

  public loadData(data) {
    this.fillFromObject(data, true);
  }
}

/**
 * The ShareStatus represents the current status of an individual Share. This is more detailed than the status on
 * the Document itself and allows us to distinguish between a user that has already signed a document and one that hasn't.
 * pending: The document is yet to be signed.
 * complete: The document has been signed by the user.
 */
export enum ShareStatus {
  pending = 'PENDING',
  complete = 'COMPLETE',
  new = 'NEW',
}
