import {BaseModel} from './BaseModel';
import {isNullOrUndefined} from 'util';

export class SocialNetwork extends BaseModel {
  Id: any;
  SourceId: any;
  SourceType: any;
  NetworkType: any;
  NetworkId: any;
  IsDefault: boolean;
  rowId: any ; // used by gx grids ,ignore for other purposes
  constructor(obj?: object) {

    super(obj);
    this.routeRoot = 'socialnetworks';
    if (isNullOrUndefined(obj)) {
      this.IsDefault = false;
    }
  }
}
