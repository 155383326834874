import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class DevCacheInterceptor implements HttpInterceptor {

  useDevCache(): boolean {
    const useCache = localStorage.getItem('useDeveloperCache');
    if (useCache && useCache === 'true') {
      return true;
    }
    return false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.useDevCache()) {
      const cachedUrl: HttpResponse<any> = JSON.parse(localStorage.getItem(`devcache:${req.url}`));
      return cachedUrl ? of(new HttpResponse<any>(cachedUrl)) : this.sendRequestWithCache(req, next);
    } else {
      return next.handle(req);
    }
  }

  sendRequestWithCache(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            localStorage.setItem(`devcache:${req.url}`, JSON.stringify(event));
          }
        }));
  }
}
