import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DxSelectBoxComponent, DxTextAreaComponent } from 'devextreme-angular';
import { DefendantService } from '../../../../../services/defendant.service';
import { ReminderService } from '../../../../../services/reminder.service';
import { BillingService } from '../../../../../services/billing.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AppConstants } from '../../../../../shared/AppConstants';
import notify from 'devextreme/ui/notify';
import { ApiUtilities } from '../../../../../shared/ApiUtilities';
import { GridUtilities } from '../../../../../shared/GridUtilities';
import { LookupService } from '../../../../../services/app-lookup.service';
import { CheckInScheduleService } from '../../../../../services/checkinschedule.service';
import { UnsubscribeOnDestroyAdapter } from '../../../../../common/UnsubscribeOnDestroy';
import { Checkin } from '../../../../../models/checkin';
import { AdiSettingsService } from '../../../../../services/adisettings.service';

@Component({
  selector: 'app-sms-history',
  templateUrl: './sms-history.component.html',
  styleUrls: ['./sms-history.component.scss'],
})
export class SmsHistoryComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {

  @ViewChild('ddlContact') contactDropdown: DxSelectBoxComponent;
  @ViewChild('txMessage') txMessage: DxTextAreaComponent;
  @Input() model: any;

  previousId: string;
  showButtons = false;
  reminderHistory: any;
  reminderHistoryFiltered: any;
  canSendMessage = false;
  busySending = false;
  contacts: any;
  selectedContact: any;
  disabledReason = 'No contact has been selected';
  errorTitle = 'There were errors';
  errorData: any[];
  errorVisible = false;
  messagePopupVisible = false;
  senderModule: 'v2' | 'defmngr' = 'v2';
  showSubscribeConfirmation = false;
  showBuyNumber = false;
  numberPurchased = false;

  loadingMessages = true;

  defMgrLink = '/adiv2/src/bail';

  messageOptions: any[] = [
    { Id: 1, Value: 'Custom Message' },
    { Id: 2, Value: 'Unscheduled Check-in' },
  ];

  selectedTemplate: any;
  showManualGpsMessageOption = false;
  isManualGpsMessage = false;

  checkin = new Checkin();

  constructor(
    private defendantService: DefendantService,
    private reminderService: ReminderService,
    private billingService: BillingService,
    public router: Router,
    private route: ActivatedRoute,
    private lookupService: LookupService,
    private checkInService: CheckInScheduleService,
    private adi: AdiSettingsService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.route.paramMap.subscribe((params: any) => {
      if (this.previousId !== params.params?.id) {
        this.getMessages(params.params?.id);
      }
    });
  }

  getMessages(id: string) {
    setTimeout(() => {
      this.previousId = id;
      this.subs.sink = this.defendantService.getReminderHistory(id).subscribe(resp => {
        const history = resp.data;
        this.reminderHistory = history.sort(function(a: any, b: any) {
          a = new Date(a.Created_At);
          b = new Date(b.Created_At);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        this.reminderHistoryFiltered = history.sort(function(a: any, b: any) {
          a = new Date(a.Created_At);
          b = new Date(b.Created_At);
          return a > b ? -1 : a < b ? 1 : 0;
        });

        this.showButtons = this.reminderHistoryFiltered.length === 0;

        this.loadingMessages = false;
      }, () => {
        this.showButtons = true;
        this.loadingMessages = false;
      });
    }, 500);
  }

  onContactChanged(e) {
    this.selectedContact = e.value;
    if (e.value) {
      this.filterMessages(e.value.Id, e.value.MessageFilterType);
    }
  }

  ngAfterViewInit() {
    this.getContacts();
  }

  getContacts() {
    this.contacts = this.model.getContactsAndSelf();
  }

  filterMessages(id: string, type: string) {
    if (this.senderModule === 'defmngr') {

      if (type === 'contact') {
        this.selectedContact = this.contacts.find(x => x.Id === id);
        if ((this.selectedContact.phones == null ||
            typeof this.selectedContact.phones === 'undefined') ||
          this.selectedContact.phones.filter(x => x.PhoneType === 'M').length === 0) {
          if (this.selectedContact?.phones.filter(x => x.PhoneType === 'M').length === 0) {
            this.disabledReason = 'This contact has no mobile numbers defined';
            this.canSendMessage = false;
          }
        } else {
          this.canSendMessage = true;
          this.disabledReason = 'Click to start sending a message';
        }
      } else if (type === 'defendant') {
        this.selectedContact = undefined;
        if ((this.model.data.person.data.phones.data == null ||
            typeof this.model.data.person.data.phones.data === 'undefined') ||
          (this.model.data.person.data.phones.data.length === 0)) {
          this.disabledReason = 'This defendant has no mobile numbers defined';
          this.canSendMessage = false;
        } else {
          this.disabledReason = 'Click to start sending a message';
          this.canSendMessage = true;
        }

      }

    } else {

      if (type === 'contact') {
        this.selectedContact = this.contacts.find(x => x.Id === id);
        if ((this.selectedContact.phones == null ||
            typeof this.selectedContact.phones === 'undefined') ||
          this.selectedContact.phones.filter(x => x.PhoneType === 'M').length === 0) {
          if (this.selectedContact?.phones.filter(x => x.PhoneType === 'M').length === 0) {
            this.disabledReason = 'This contact has no mobile numbers defined';
            this.canSendMessage = false;
          }
        } else {
          this.canSendMessage = true;
          this.disabledReason = 'Click to start sending a message';
        }
      } else if (type === 'defendant') {
        if(this.model.checkinschedule.Id){
          this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_TEXT_MESSAGING).subscribe(isSubbed => {
            if (isSubbed) {
              this.adi.get().subscribe(config => {
                if(config.data[0].automaticnotificationsettings.data.CheckinGpsEnabled){
                  this.showManualGpsMessageOption = true;
                }
              });
            }
          });
        }
        this.selectedContact = undefined;
        if ((this.model.person.phones == null || typeof this.model.person.phones === 'undefined') ||
          this.model.person.phones.filter(x => x.PhoneType === 'M').length === 0) {
          this.disabledReason = 'This defendant has no mobile numbers defined';
          this.canSendMessage = false;
        } else {
          this.disabledReason = 'Click to start sending a message';
          this.canSendMessage = true;
        }

      }
    }
    if (id === '') {
      this.reminderHistoryFiltered = this.reminderHistory;
      this.disabledReason = 'No contact has been selected';
      this.canSendMessage = false;
    } else {
      if (!(this.reminderHistory == null || typeof this.reminderHistory === 'undefined')) {
        this.reminderHistoryFiltered = this.reminderHistory.filter(x => x.PersonId === id).sort(function(a: any, b: any) {
          a = new Date(a.Created_At);
          b = new Date(b.Created_At);
          return a > b ? -1 : a < b ? 1 : 0;
        });
      }
    }

  }

  showSendMessage() {
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_TEXT_MESSAGING).subscribe(isSubbed => {
      if (isSubbed) {
        this.messagePopupVisible = true;
      } else {
        this.showSubscribeConfirmation = true;
      }
    });
  }

  sendMessageFromDefManager() {
    const msgText = this.txMessage.value;

    if (this.selectedContact) {
      let phone = this.selectedContact.phones.find(x => x.IsDefault && x.PhoneType === 'M');
      if (phone == null || typeof phone === 'undefined') {
        phone = this.selectedContact.phones.find(x => x.PhoneType === 'M');
      }
      this.busySending = true;
      this.subs.sink = this.reminderService.sendSms(
        this.selectedContact.Id,
        'DirectMessage',
        msgText,
        phone.Number,
        this.model.data.Id,
      ).subscribe(resp => {
        // console.log('reminderHistory from sendSms', resp);
        this.reminderHistory = resp.history.data.sort(function(a: any, b: any) {
          a = new Date(a.Created_At);
          b = new Date(b.Created_At);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        this.filterMessages(this.selectedContact.Id, 'contact');
        this.showResult('success', resp.result);
        this.busySending = false;
        this.txMessage.value = '';
        this.messagePopupVisible = false;
      }, smsErr => {
        if (smsErr.data != null || smsErr.message != null) {
          this.showResult('error', smsErr.data == null ? smsErr.message : smsErr.data.message);
        }
        this.showError(smsErr);
        this.busySending = false;

      });
    } else {
      let phone = this.model.data.person.data.phones.data.find(x => x.IsDefault && x.PhoneType === 'M');
      if (phone == null || typeof phone === 'undefined') {
        phone = this.model.data.person.data.phones.data.find(x => x.PhoneType === 'M');
      }
      this.busySending = true;
      this.subs.sink = this.reminderService.sendSms(
        this.model.data.person.data.Id,
        'DirectMessage',
        msgText,
        phone.Number,
        this.model.data.Id,
      ).subscribe(resp => {
        this.reminderHistory = resp.history.data.sort(function(a: any, b: any) {
          a = new Date(a.Created_At);
          b = new Date(b.Created_At);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        this.filterMessages(this.model.data.person.data.Id, 'defendant');
        this.showResult('success', resp.result);
        this.busySending = false;
        this.txMessage.value = '';
        this.messagePopupVisible = false;
      }, smsErr => {
        if (smsErr.data != null || smsErr.message != null) {
          this.showResult('error', smsErr.data == null ? smsErr.message : smsErr.data.message);
        }
        this.showError(smsErr);
        this.busySending = false;
      });
    }
  }

  sendMessageFromV2() {
    const msgText = this.txMessage.value;

    if (this.selectedContact) {
      let phone = this.selectedContact.phones.find(x => x.IsDefault && x.PhoneType === 'M');
      if (phone == null || typeof phone === 'undefined') {
        phone = this.selectedContact.phones.find(x => x.PhoneType === 'M');
      }
      this.busySending = true;
      this.subs.sink = this.reminderService.sendSms(
        this.selectedContact.Id,
        'DirectMessage',
        msgText,
        phone.Number,
        this.model.Id,
      ).subscribe(resp => {
        this.reminderHistory = resp.history.data.sort(function(a: any, b: any) {
          a = new Date(a.Created_At);
          b = new Date(b.Created_At);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        this.filterMessages(this.selectedContact.Id, 'contact');
        this.showResult('success', resp.result);
        this.busySending = false;
        this.txMessage.value = '';
        this.messagePopupVisible = false;
      }, smsErr => {
        if (smsErr.data != null || smsErr.message != null) {
          this.showResult('error', smsErr.data == null ? smsErr.message : smsErr.data.message);
        }
        this.showError(smsErr);
        this.busySending = false;

      });
    } else {
      let phone = this.model.person.phones.find(x => x.IsDefault && x.PhoneType === 'M');
      if (phone == null || typeof phone === 'undefined') {
        phone = this.model.person.phones.find(x => x.PhoneType === 'M');
      }
      this.busySending = true;
      this.subs.sink = this.reminderService.sendSms(
        this.model.person.Id,
        'DirectMessage',
        msgText,
        phone.Number,
        this.model.Id,
      ).subscribe(resp => {
        this.reminderHistory = resp.history.data.sort(function(a: any, b: any) {
          a = new Date(a.Created_At);
          b = new Date(b.Created_At);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        this.filterMessages(this.model.person.Id, 'defendant');
        this.showResult('success', resp.result);
        this.busySending = false;
        this.txMessage.value = '';
        this.messagePopupVisible = false;
      }, smsErr => {
        if (smsErr.data != null || smsErr.message != null) {
          this.showResult('error', smsErr.data == null ? smsErr.message : smsErr.data.message);
        }
        this.showError(smsErr);
        this.busySending = false;
      });
    }
  }

  sendMessage() {
    if(this.isManualGpsMessage){
      this.checkin.ScheduledDate = new Date().toISOString().slice(0, 10).concat(' 00:00:00');
      this.checkin.ScheduleId = this.model.checkinschedule.Id;
      this.checkin.DefendantId = this.model.Id;
      this.subs.sink = this.checkInService.createCheckin(this.checkin).subscribe(res => {
        if(res){
          this.reminderService.sendManualGpSReminder({defendantId: this.model.Id}).subscribe(res => {
            if(res){
              this.showResult('success', 'GPS checkin reminder sent.');
            }else{
              notify('An error occurred while sending, please try again.');
            }
          });
        }else{
          notify('An error occurred while sending, please try again.');
        }
      });
    }else{
      if (this.senderModule === 'defmngr') {
        this.sendMessageFromDefManager();
      } else {
        this.sendMessageFromV2();
      }
      this.showButtons = false;
    }
  }

  showResult(status: string, message: string) {
    const type = status === 'error' ? 'error' : 'success';
    notify(message, type);
  }

  showError(errorInfo: any) {
    if (this.errorData == null || typeof this.errorData === 'undefined') {
      this.errorData = [];
    } else {
      this.errorData.length = 0;
    }
    if (errorInfo == null || typeof errorInfo === 'undefined') {
      this.errorData = [];
    } else {
      if (!(errorInfo.message == null || typeof errorInfo.message === 'undefined')) {
        this.errorData = this.errorData.concat(errorInfo);
      } else if ((errorInfo.error == null || typeof errorInfo.error === 'undefined')) {
        this.errorData = this.errorData.concat(ApiUtilities.getErrorMessage(errorInfo, true));
      } else {
        if ((errorInfo.error.errors == null || typeof errorInfo.error.errors === 'undefined')) {
          this.errorData = this.errorData.concat(ApiUtilities.getErrorMessage(errorInfo, true));
        } else {
          this.errorData = this.errorData.concat(GridUtilities.getErrorItems(errorInfo.error.errors));
        }
      }
    }

    this.errorVisible = true;

  }

  public getContactsAndSelf() {
    const contacts: any = [];
    contacts.push({
      'Id': this.model.data.person.data.Id,
      'FullName': this.model.data.person.data.FullName + ' (Defendant)',
      'Type': 'Defendant',
      'phones': this.model.data.person.data.phones.data,
      'MessageFilterType': 'defendant',
    });

    if (this.model.data.transactions.data.length > 0) {
      this.model.data.transactions.data.forEach(trn => {
        trn.contacts.data.forEach(ct => {
          if (!contacts.some(check => check.Id === ct.Id)) {
            if (ct.person.data.FullName.trim() !== ',') {
              contacts.push({
                'Id': ct.person.data.Id,
                'FullName': ct.person.data.FullName,
                'Type': ct.Type,
                'phones': ct.person.data.phones.data,
                'MessageFilterType': 'contact',
              });
            }
          }
        });
      });
    }
    return contacts;
  }

  YesNoConfirmResult(e) {
    if (!this.showBuyNumber) {
      this.showBuyNumber = true;
      return;
    }
    if (e) {
      if (this.showBuyNumber && this.numberPurchased) {
        // console.log('subscribing to text messaging');
        notify('Subscribing to Reminder Service, please wait...');
        this.subs.sink = this.billingService.SubscribeCustomerToService(AppConstants.PRODUCT_NAME_TEXT_MESSAGING).subscribe(() => {
          this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_TEXT_MESSAGING, true)
            .subscribe(isSubbed => {
              if (isSubbed) {
                notify('You are now subscribed to Reminder Services!  Continuing...', 'success');
                this.showSubscribeConfirmation = false;
                this.messagePopupVisible = true;
              } else {
                notify('An error occurred while subscribing, please try again.');
              }
            });
        });
      } else {
        this.showSubscribeConfirmation = false;
      }
    }
  }

  numberBought() {
    this.numberPurchased = true;
    this.YesNoConfirmResult(true);
  }

  onMessageOptionChanged(e){
    if (e.value == '2') {
      this.busySending = true;
      this.selectedTemplate = {};
      this.subs.sink = this.lookupService
      .getFilteredLookup(
        'notificationtemplates',
        'MessageTypeSource',
        'bail',
        null,
        false,
      )
      .subscribe((resp: any) => {
        console.log(resp);
        this.selectedTemplate = resp.data.find(
          (x: any) => x.TemplateName == 'GPS Check In',
          );
        this.txMessage.value = this.selectedTemplate.Message;
        this.txMessage.disabled = true;
        this.busySending = false;
      });
      this.isManualGpsMessage = true;
    } else {
      this.txMessage.value = '';
      this.txMessage.disabled = false;
      this.isManualGpsMessage = false;
    }
  }

}
