import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import { Injectable } from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()
export class DefendantManagerService {

  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  key: string = 'defendantmanager';

  constructor(private http: HttpClient, private settings: SettingsService) {}

  caseLoadByUser() {
    const url = `${this.settings.getBaseUrl()}/${this.key}/caseloadbyuser?limit=0`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

  getStats() {
    const url = `${this.settings.getBaseUrl()}/${this.key}/stats?limit=0`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

  caseStates() {
    const url = `${this.settings.getBaseUrl()}/${this.key}/casestates?limit=0`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

  checkinRate() {
    const url = `${this.settings.getBaseUrl()}/${this.key}/checkinrate?limit=0`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

  getDefendantsByRisk() {
    const url = `${this.settings.getBaseUrl()}/${this.key}/defendantbyrisk?limit=0`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

  getDefendantsWithNextEvent() {
    const url = `${this.settings.getBaseUrl()}/${this.key}/defendantswithnextevent?limit=0`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }


}
