import {BaseModel} from './BaseModel';
import { PortoResponse } from './porto-response';

export interface Exclusions {
  Created_At: Date;
  Id: string;
  Number: string;
  RequestedBy: string;
  object: 'ReminderExclusion';
}

export class Phone extends BaseModel {
  // tslint:disable:variable-name
  public Id: string;
  public Number: string;
  public PhoneType: string;
  public IsDefault: boolean;
  public SourceId: any;
  public SourceType: string;
  public IsBad: boolean;
  public CustomerId: any;
  public rowId: any ; // used by phonesgrid for internal use
  public wasCopied = false;
  public DoNotContact: boolean;
  public exclusions: PortoResponse<Exclusions[]>;

  constructor(obj?: object) {
    super(obj);
    this.childrenNames = ['exclusions'];
    this.routeRoot = 'phones';
    this.propertiesToIgnoreForCheckChanges.push('exclusions', 'DoNotContact');

    if (obj == null) {
      this.IsDefault = false;
      this.Number = '';
      this.PhoneType = '';
      this.IsDefault = false;
    }
  }


  public singleLinePhone() {
    let formattedPhone = '';

    if (this.PhoneType !== '') {
      formattedPhone = this.PhoneType.substr(0, 1);
    }

    if (this.Number.length === 10) {
      formattedPhone += ': (' + this.Number.substr(0, 3) + ')' +
        this.Number.substr(3, 3) + '-' + this.Number.substr(6, 4);
    }

    return formattedPhone;
  }
}
