<h1 mat-dialog-title>Exonerate to Remove</h1>
<hr>
<div mat-dialog-content id="bondStatusUpdater">
  <dx-date-box
    [(value)]="exonerationDate"
    type="date"
    dateSerializationFormat="yyyy-MM-dd"
    [displayFormat]="dateFormatter"
    placeholder="Exoneration Date"
  >
    <dx-validator #validator>
      <dxi-validation-rule
        type="required"
        message="Please enter a Date">
      </dxi-validation-rule>
      <dxi-validation-rule
        type="custom"
        [validationCallback]="checkDate"
        [reevaluate]="true"
        message="Exoneration date can not be in the future">
      </dxi-validation-rule>
    </dx-validator>
  </dx-date-box>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button
          [color]="saveButtonColor"
          (click)="saveAndClose()"
          [disabled]="busySaving"
          type="submit">
    Update Bond
  </button>
</div>
