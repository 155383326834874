import { Action, createReducer, on } from '@ngrx/store';
import { QuickLinkStateItem, selectQuickLinks } from '../actions/quick-links.actions';


export const quickLinksFeatureKey = 'quickLinks';

export interface QuickLinksState {
  items: QuickLinkStateItem[];
}

export const initialState: QuickLinksState = {
  items: [],
};

const quickLinkReducer = createReducer(
  initialState,
  on(selectQuickLinks, (state, {items}) => ({ ...state, items })),
);

export function reducer(state: QuickLinksState | undefined, action: Action) {
  return quickLinkReducer(state, action);
}

