import { map } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { DxMultiViewComponent, DxSelectBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { DatamartRequest } from '../../models/datamartrequest';
import { LookupService } from '../../services/app-lookup.service';
import ArrayStore from 'devextreme/data/array_store';
import { DatamartService, ReportType } from '../../services/datamart.service';
import { AppConstants } from '../../shared/AppConstants';
import { DefendantService } from '../../services/defendant.service';
import { Defendant } from '../../models/defendant';
import { RouteDataService } from '../../services/routedata.service';
import { Observable, Subject, Subscriber } from 'rxjs';
import { IDynamicPopupComponent } from '../../components/common/dynamic-popup/dynamic-popup.component';
import { PdfViewerComponent } from '../../components/common/pdf-viewer/pdf-viewer.component';
import { Router } from '@angular/router';
import { BillingService, Products } from '../../services/billing.service';
import { StringUtilities } from '../../shared/StringUtilities';
import { IncarcerationHistoryReportComponent } from '../incarceration-history-report/incarceration-history-report.component';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../common/notify/notify.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationComponent } from '../../components/controls/confirmation/confirmation.component';
import { Person } from '../../models/person';
import { CreditSignUpComponent } from '../../datamart/credit-checks/credit-sign-up/credit-sign-up.component';
import { BackgroundSignUpComponent } from '../../datamart/background-checks/background-sign-up/background-sign-up.component';
import { Address } from '../../models/address';
import PRODUCT_NAME_CREDIT_REPORTS = AppConstants.PRODUCT_NAME_CREDIT_REPORTS;
import PRODUCT_NAME_DATAMART_ACCESS = AppConstants.PRODUCT_NAME_DATAMART_ACCESS;

export interface PersonSelect {
  type: 'defendant' | 'contact';
  first: string;
  person: Person;
  text: string;
}

export enum SsnAlternative {
  DriverLicense = 'DriverLicense',
  Address = 'Address',
  ZipCode = 'ZipCode',
}

export interface NoSsnAlternativeType {
  Id: SsnAlternative;
  Text: String;
}

@Component({
  selector: 'app-datamart',
  templateUrl: './datamart.component.html',
  styleUrls: ['./datamart.component.scss'],
  entryComponents: [PdfViewerComponent],
})
export class DatamartComponent extends UnsubscribeOnDestroyAdapter implements OnInit, IDynamicPopupComponent, AfterViewInit {
  @ViewChild(DxMultiViewComponent) reportWizard: DxMultiViewComponent;
  @ViewChild('reportType') reportType: DxSelectBoxComponent;
  @ViewChild(IncarcerationHistoryReportComponent) incarcerationHistory: IncarcerationHistoryReportComponent;
  @Input() lockedToReportType: ReportType;
  @Input() submitButtonText = 'Search';
  @Input() selectedReportType: ReportType;
  @Input() holdDropDown = false;

  public readonly SsnAlternative = SsnAlternative;

  defendant: Defendant;
  defendantId: any;
  defendantName: any;
  transactionId: any;

  transactionList = [];
  dynamicData: any;

  onClose = new Subject<boolean>();
  states: any;
  stateList: any;
  datamartRequest: DatamartRequest;
  gridData: any;
  reportTypeList: any;
  stepsForm: any;
  plateNumberPopup: any;
  searchResultsDatasource: CustomStore;

  noSSNisChecked = false;
  noSSNAlternativeIsChecked = false;

  ssnAlternativeName: SsnAlternative;
  public noSSNAlternatives: NoSsnAlternativeType[];

  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData = [];
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  inDynamicPopup = false;
  userInTrial = false;

  dateFormatter = StringUtilities.dateFormatter;
  doNotPopulateFromRecord = false;

  incarcerationHistoryVisible = false;
  incarcerationHistoryFirst = '';
  incarcerationHistoryLast = '';
  incarcerationHistoryDob = '';
  incarcerationHistorySsn = '';
  incarcerationHistoryDriverLicense = '';
  incarcerationHistoryAddress: Address;
  incarcerationHistoryZipCode = '';
  persons: PersonSelect[] = [];
  searchDisabled = false;
// This seemingly absurd statement allows the html template to reference
  // the ReportType enum because it is now defined in the component.
  public readonly ReportType = ReportType;

  private products: Products;

  constructor(
    private lookupService: LookupService,
    private datamartService: DatamartService,
    private defendantService: DefendantService,
    private router: Router,
    private billingService: BillingService,
    private routeDataService: RouteDataService,
    private notifyService: NotifyService,
    private dialog: MatDialog,
  ) {
    super();
    this.datamartRequest = new DatamartRequest();

    this.loadlookups();

    this.stepsForm = [{title: 'Step1'}, {title: 'Step2'}];
  }

  ngOnInit() {
    if (this.dynamicData) {
      this.lockedToReportType = this.dynamicData['lockedToReportType'];
      this.doNotPopulateFromRecord = this.dynamicData['doNotPopulateFromRecord'];
      this.submitButtonText = this.dynamicData['submitButtonText'];
    }
    this.reportTypeList = this.datamartService.getReportTypeList();
    if (this.lockedToReportType) {
      this.selectedReportType = this.lockedToReportType;
    } else {
      // this.selectedReportType = ReportType.General;
    }

    this.subs.sink = this.billingService.GetCustomerBillingStatusString().subscribe(status => {
      if (status === 'TRIALING') {
        this.userInTrial = true;
      } else {
        this.subs.sink = this.billingService.GetSubscribedProducts().subscribe(response => {
          this.products = response;
        });
      }
    });

    this.defendantId = this.routeDataService.getValue(AppConstants.DEFENDANT_ID_KEY);
    this.defendantName = this.routeDataService.getValue(AppConstants.DEFENDANT_NAME_KEY);
    this.transactionId = this.routeDataService.getValue(AppConstants.TRANSACTION_ID_KEY);

    this.subs.sink = this.loadStates().subscribe(() => {
      this.fillRequestFromRecord();
    });
  }

  ngAfterViewInit() {
    if (!this.lockedToReportType && !this.holdDropDown) {
      this.triggerDropDown();
    }
  }

  triggerDropDown() {
    this.reportType.instance.option('opened', true);
  }

  public loadlookups() {
    this.noSSNAlternatives = this.lookupService.getLookupStatic('NoSSNAlternates');
  }

  fillRequestFromRecord() {
    if (this.defendant && this.defendant.person && !this.doNotPopulateFromRecord) {
      // this.datamartRequest.FullName = `${this.defendant.person.Last}, ${this.defendant.person.First} ${this.defendant.person.Middle ? this.defendant.person.Middle : ''}`;
      this.buildDataMartRequest(this.defendant.person);
      this.buildPersonList();
    }
  }

  buildDataMartRequest(person: Person) {
    this.datamartRequest = new DatamartRequest();
    this.datamartRequest.FirstName = person.First;
    this.datamartRequest.LastName = person.Last;

    this.datamartRequest.DateOfBirth = person.Dob;
    this.datamartRequest.SocialSecurityNumber = person.Ssn;

    if (person.addresses && person.addresses.length > 0) {
      const defaultAddresses = person.addresses.filter(address => address.IsDefault);

      if (defaultAddresses.length > 0) {
        const defaultAddress = defaultAddresses[0];
        this.datamartRequest.StreetAddress = defaultAddress.AddressLine1;
        this.datamartRequest.City = defaultAddress.City;
        const matchingStates = this.states.filter(state => state.Id === defaultAddress.StateCode);
        this.datamartRequest.State = matchingStates[0].Text;
        this.datamartRequest.ZipCode = defaultAddress.Zip;
      } else {
        const firstAddress = person.addresses[0];
        this.datamartRequest.StreetAddress = firstAddress.AddressLine1;
        this.datamartRequest.City = firstAddress.City;
        const matchingStates = this.states.filter(state => state.Id === firstAddress.StateCode);
        this.datamartRequest.State = matchingStates[0].Text;
        this.datamartRequest.ZipCode = firstAddress.Zip;
      }
    }

    if (person.phones && person.phones.length > 0) {
      const defaultPhones = person.phones.filter(phone => phone.IsDefault);

      if (defaultPhones.length > 0) {
        this.datamartRequest.PhoneNumber = defaultPhones[0].Number;
      } else {
        this.datamartRequest.PhoneNumber = person.phones[0].Number;
      }
    }
  }

  buildPersonList() {
    this.persons.push({
      type: 'defendant',
      first: this.defendant.person.First,
      person: this.defendant.person,
      text: `${this.defendant.person.First} ${this.defendant.person.Last}`,
    });
    for (const transaction of this.defendant.transactions) {
      for (const contact of transaction.contacts) {
        this.persons.push({
          type: 'contact',
          first: contact.person.First,
          person: contact.person,
          text: `${contact.person.First} ${contact.person.Last}`,
        });
      }
    }
  }

  changeDefendant({itemData}) {
    this.buildDataMartRequest(itemData.person);
  }

  onValueChanged({ component, value }) {
    component.instance().close();
    if (!value) {
      this.datamartRequest = new DatamartRequest();
    }
  }

  noSSNChanged(e) {
    if (e.value === true) {
      this.noSSNisChecked = true;
      this.noSSNAlternativeIsChecked = true;
    } else {
      this.noSSNisChecked = false;
      this.noSSNAlternativeIsChecked = false;
    }
  }

  onOptionChanged(e: any) {
    this.noSSNAlternativeIsChecked = true;
    this.ssnAlternativeName = e.selectedItem?.Id;
    this.wipeFields();
  }

  wipeFields(): void {
    this.datamartRequest.Address = [];
    this.datamartRequest.ZipCode = null;
    this.datamartRequest.DriverLicense = null;
  }

  loadStates() {
    return this.lookupService.getLookup('states')
      .pipe(
        map(
          st => {
            this.states = st.data;
            this.stateList = new ArrayStore({
              data: st['data'],
              key: 'Id',
            });
          },
          error => {
            console.error(error);
          },
        ));
  }

  onDynamicPopupHidden() {
    this.dynamicPopupClass = null;
    this.searchDisabled = false;
  }

  onFormSubmit(event) {
    this.searchDisabled = true;
    this.checkPermissions(this.selectedReportType).subscribe(permission => {
      if (permission) {
        switch (this.selectedReportType) {
          case ReportType.Incarceration:
            this.incarcerationHistoryFirst = this.datamartRequest.FirstName;
            this.incarcerationHistoryLast = this.datamartRequest.LastName;
            this.incarcerationHistoryDob = this.datamartRequest.DateOfBirth;
            this.incarcerationHistorySsn = this.datamartRequest.SocialSecurityNumber;
            this.incarcerationHistoryDriverLicense = this.datamartRequest.DriverLicense;
            this.incarcerationHistoryZipCode = this.datamartRequest.ZipCode;
            this.incarcerationHistoryAddress = this.datamartRequest.Address[0];
            this.incarcerationHistoryVisible = true;
            setTimeout(() => {
              if (this.incarcerationHistory) {
                this.incarcerationHistory.initialize();
              }
            }, 1000);
            break;
          case ReportType.General:
            this.searchResultsDatasource = null;
            this.searchResultsDatasource = this.createSearchResultStore();

            const curIndex: number = this.reportWizard.instance.option('selectedIndex');
            this.reportWizard.instance.option('selectedIndex', curIndex + 1);
            break;
          default:
            this.dynamicPopupData = [];
            this.dynamicPopupData['pdfObservable'] = this.datamartService.postReportInquiry(
              this.selectedReportType,
              this.datamartRequest,
            );
            this.dynamicPopupData['esignable'] = false;
            this.dynamicPopupClass = PdfViewerComponent;
        }
      } else {
        this.searchDisabled = false;
      }
    });
  }

  /**
   * This checks users permissions for a given product. The only product that returns true
   * for everyone is Arrest History Search since this is billed ala carte. This works under the
   * assumption that we have the product list already. If this isn't available this will blow up.
   * @param reportType
   */
  checkPermissions(reportType: ReportType): Observable<boolean> {
    return new Observable<boolean>((sub: Subscriber<boolean>): void => {
      if (!this.products) {
        sub.error('Products not set');
      }
      let permission: boolean, config: MatDialogConfig;
      switch (reportType) {
        case ReportType.Incarceration:
          permission = true;
          break;
        case ReportType.Credit:
          permission = this.products[PRODUCT_NAME_CREDIT_REPORTS];
          config = {
            data: {
              title: 'Credit Checks',
              yesText: 'Confirm',
              noText: 'Close',
              component: CreditSignUpComponent,
            },
          };
          break;
        case ReportType.General:
          permission = this.products[PRODUCT_NAME_DATAMART_ACCESS];
          config = {
            data: {
              title: 'Background Checks',
              component: BackgroundSignUpComponent,
              yesText: 'Agree & Continue',
              noText: 'Close',
            },
          };
          break;
      }
      if (!permission) {
        const dialogRef = this.dialog.open(ConfirmationComponent, config);
        dialogRef.afterClosed().subscribe(confirmation => {
          if (confirmation) {
            this.subs.sink = this.billingService.CustomerHasPaymentOnRecord().subscribe(paymentOnRecord => {
              if (!paymentOnRecord) {
                this.subs.sink = this.showConfirmation(
                  'Enter Payment Information',
                  `This is a premium service and it looks like we don't have a credit card on file for you. Enter payment information on the following screen and you'll be redirected back here.  Thanks!`,
                ).subscribe(confirm => {
                  this.router.navigate(['/getstarted/checkout'],
                    {
                      queryParams: {
                        'returnUrl': this.router.routerState.snapshot.url,
                        'productids': AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
                      },
                    });
                });
              } else {
                switch (reportType) {
                  case ReportType.General:
                    this.subs.sink = this.billingService.SubscribeCustomerToService(AppConstants.PRODUCT_NAME_DATAMART_ACCESS)
                      .subscribe(() => {
                        this.notifyService.toast(`You are now subscribed to Background Checks`, 'success');
                        sub.next(true);
                      }, (error) => {
                        this.notifyService.toast(`Error subscribing to Background Checks`, 'error');
                        sub.next(false);
                      });
                    break;
                  case ReportType.Credit:
                    sub.next(false);
                    break;
                }
              }
            });
          } else {
            sub.next(confirmation);
          }
        });
      } else {
       sub.next(permission);
      }
    });
  }

  showConfirmation(title: string, message: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        message: message,
        title: title,
      },
      maxWidth: '500px',
    });
    return dialogRef.afterClosed().pipe();
  }

  createSearchResultStore() {
    return new CustomStore({
      load: (loadOptions: any) => {

        const firstRecord = loadOptions.skip;
        const recordCount = loadOptions.take;

        this.datamartRequest.FirstRecord = firstRecord;
        this.datamartRequest.RecordCount = recordCount;

        // uncomment/comment to switch in and out of live/mock data
        return this.datamartService
          .postGeneralInitialInquiry(this.datamartRequest)
          .toPromise()
          .then(response => {
            if (
              !response.BpsSearchResponse ||
              !response.BpsSearchResponse.Records
            ) {
              // TODO: Set error flag somewhere
              console.error('Error in datamart search response.', response);
              if (!response.OnDemandResult || !response.OnDemandResult.Error) {
                this.notifyService.toast( 'Error in datamart search response', 'error');
              } else {
                this.notifyService.toast(response.OnDemandResult.Error.Description, 'error');
              }

              return {data: [], totalCount: 0};
            } else {
              const totalCount = response.BpsSearchResponse.SubjectTotalCount;

              const records = response.BpsSearchResponse.Records;
              const record = records.Record;

              const searchResults: any[] = [];
              if (Array.isArray(record) ) {
               for (const r of record) {
                 searchResults.push({
                   id: r.UniqueId,
                   name: r.Name ? [r.Name.First, r.Name.Middle, r.Name.Last].join(' ') : null,
                   age: r.Age,
                   gender: r.Gender,
                   dateOfBirth: (typeof r.DOB.Day === 'string') ? [r.DOB.Month, r.DOB.Day, r.DOB.Year].join('/') : '',
                   ssn: r.SSNInfo ? r.SSNInfo.SSN : null,
                   address: !!r.Address ? [
                     [[r.Address.StreetNumber, r.Address.StreetName, r.Address.StreetSuffix].join(' '), r.Address.State].join(', '), r.Address.Zip5,
                   ].join(' ') : null,
                   phone: r.PhoneInfo ? `${r.PhoneInfo.Phone10}` : null,
                 });
               }
              } else {
                searchResults.push({
                  id: record.UniqueId,
                  name: record.Name ? [record.Name.First, record.Name.Middle, record.Name.Last].join(' ') : null,
                  age: record.Age,
                  gender: record.Gender,
                  dateOfBirth: (typeof record.DOB.Day === 'string') ? `${record.DOB.Month}/${record.DOB.Day}/${record.DOB.Year}` : '',
                  ssn: record.SSNInfo ? record.SSNInfo.SSN : null,
                  address: record.Address
                    ? `${record.Address.StreetNumber} ${record.Address.StreetName} ${record.Address.StreetSuffix}, ${
                      record.Address.City
                    }, ${record.Address.State} ${record.Address.Zip5}` : null,
                  phone: record.PhoneInfo ? `${record.PhoneInfo.Phone10}` : null,
                });
              }
              return {data: searchResults, totalCount: totalCount};
            }
          }, error => {
            this.notifyService.toast('Unsuccessful. Please check that you are subscribed to Background Checks.', 'error');
          });
      },
    });
  }

  getGeneralIndividualReport(data) {
    // console.log('datamart getFullReport', data);

    const request = new DatamartRequest();
    request.ReferenceAlias = data.data.id;

    this.dynamicPopupData = [];
    this.dynamicPopupData[
      'pdfObservable'
      ] = this.datamartService.postGeneralIndividualInquiry(request);
    this.dynamicPopupData['esignable'] = false;
    this.dynamicPopupClass = PdfViewerComponent;
  }

  backToSearch() {
    this.searchResultsDatasource = null;
    this.searchDisabled = false;
    const curIndex: number = this.reportWizard.instance.option('selectedIndex');
    this.reportWizard.instance.option('selectedIndex', curIndex - 1);
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach(function (item) {
      if (item.name === 'saveButton') {
        item.visible = false;
      }

      item.location = 'center';
      if (item.name === 'addRowButton') {
        item.options.icon = 'fal fa-plus';
      }
    });
  }

  getStartedNow() {
    this.router.navigateByUrl('/getstarted/checkout');
  }

  arrestHistoryResultHidden(event) {
    if (!!this.incarcerationHistory) {
      this.incarcerationHistory.clearResult();
    }
    this.searchDisabled = false;
  }

}
