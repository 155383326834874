import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '../models/person';
import { Phone } from '../models/phones';
import { Employer } from '../models/employer';

@Pipe({
  name: 'defaultPhone',
})
export class DefaultPhonePipe implements PipeTransform {

  transform(person: Person | Employer, type?: 'F' | 'H' | 'M' | 'W', isDefault: boolean = true): string {
    return person.phones.reduce((previous: Phone, current: Phone) =>
      (type ? current.PhoneType === type : current.IsDefault) ? current : previous
    , new Phone())?.Number;
  }

}
