import { BaseModel } from './BaseModel';

import { Bail } from './bail';
import { Lawsuit } from './lawsuit';
import { Note } from './note';
import { DefendantAccountingDetails } from './defendant-accounting-details';
import { Person } from './person';
import { Bond } from './bond';
import { CalendarItem } from './calendaritem';
import { CalendarUtilities } from '../shared/calendar-utilities';
import { CheckInSchedule } from './checkinschedule';
import { Checkin } from './checkin';

// tslint:disable:variable-name
export class Defendant extends BaseModel {
  public Id: string;
  public CustomerId: string;
  public RiskAssessmentId: string | number;
  public Deleted_At: string;
  public PersonId: any;
  public person: Person;
  public checkinschedule: CheckInSchedule;
  public checkinhistory: Checkin[];
  public Heightft: string;
  public Heightin: string;

  public OnProbation: boolean;
  public ParoleOfficerName: any;

  public InJail: boolean;
  public ExpectedReleaseDate: string;
  public InJailFacility: string;
  public InJailComment: string;

  public BankName: string;
  public BankAcctType: string;
  public BankAcctNo: string;

  public PriorArrests: string;
  public PriorCharges: string;
  public PriorStatus: string;
  public PriorArrestBailedCompany: string;

  public AssignedTo: any;
  public IncludeInDefendantWatch: boolean;

  public transactions: Bail[];
  public lawsuits: Lawsuit[];
  public accountingdetails: DefendantAccountingDetails[];
  public calendaritems: CalendarItem[];
  public notes: Note[];

  public messages: any;
  public CreatedByUserId: any;

  public UrgentNotice: any;
  public OptOutFacialRecognition: any;

  constructor(obj?: object) {
    super(obj);
    this.childrenNames = ['person', 'transactions', 'lawsuits', 'notes', 'accountingdetails', 'calendaritems', 'checkinschedule', 'checkinhistory'];
    this.routeRoot = 'defendants';
    this.propertiesToIgnoreForCheckChanges.push('HeightFt', 'HeightIn', 'checkinschedule', 'messages');
    if (obj == null) {
      this.Id = '';
      this.OnProbation = false;
      this.InJail = false;
      this.person = new Person();
      this.IncludeInDefendantWatch = false;
      this.transactions = [];
      this.lawsuits = [];
      this.calendaritems = [];
      this.notes = [];
      this.checkinschedule = new CheckInSchedule();
      this.checkinhistory = [];


      this.accountingdetails = [];
    }
  }

  public loadFromStore(data: Defendant) {
    if (data) {
      this.fillFromObject(data, true);
      this.person = data.person;
      this.transactions = data.transactions;
      this.lawsuits = data.lawsuits;
      this.notes = data.notes;
      this.accountingdetails = data.accountingdetails;
      this.calendaritems = data.calendaritems;
      this.checkinschedule = data.checkinschedule;
      this.checkinhistory = data.checkinhistory;
    }
  }

  public loadAccountingData(data) {
    if (data != null) {
      this.accountingdetails.length = 0;
      if (data.accountingdetails.data !== null && data.accountingdetails.data.length > 0) {
        data.accountingdetails.data.forEach(ad => {
          const detail = new DefendantAccountingDetails();
          detail.fillFromObject(ad);
          this.accountingdetails.push(detail);
        });
      }
    }
  }

  public loadFromServer(data) {
    if (data) {
      this.fillFromObject(data, true);
      // Update Person info
      this.person.loadData(data.person.data);

      // Handle Checkinschedule
      if (data.checkinschedule && !!data.checkinschedule.data) {
        if (data.checkinschedule.data instanceof Array) {
          this.checkinschedule = new CheckInSchedule();
        } else {
          this.checkinschedule.loadData(data.checkinschedule.data);
        }
      } else {
        this.checkinschedule = new CheckInSchedule();
      }

      // Handle Transactions
      if (data.transactions && data.transactions.data && data.transactions.data.length > 0) {
        const transactions = [];
        data.transactions.data.map((trans: Bail) => {
          const newTrans = new Bail();
          newTrans.loadData(trans);
          transactions.push(newTrans);
          newTrans.canEditData = true;
        });
        this.transactions = this.mergeValues(this.transactions, transactions);
      }

      // Handle Lawsuits
      if (data.lawsuits && data.lawsuits.data) {
        const lawsuits = [];
        data.lawsuits.data.map((lawsuit: Lawsuit) => {
          const newLawSuite = new Lawsuit();
          newLawSuite.loadData(lawsuit);
          lawsuits.push(newLawSuite);
        });
        this.lawsuits = this.mergeValues(this.lawsuits, lawsuits);
      }

      // Handle calendar items
      if (data.calendaritems && data.calendaritems.data && data.calendaritems.data.length > 0) {

        const calendarItemTypes = CalendarUtilities.getCalendarEntryTypes();
        const calendarItems = [];
        data.calendaritems.data.forEach(item => {
          const calendarItem = new CalendarItem();
          calendarItem.startDate = new Date(item.EventDate);
          calendarItem.endDate = new Date(item.EventDate);
          calendarItem.allDay = (calendarItem.startDate.getHours() < 7);
          calendarItem.text = item.EventSubject;
          calendarItem.itemType = item.EventType;
          calendarItems.push(calendarItem);
        });
        this.calendaritems = this.mergeValues(this.calendaritems, calendarItems);
      }

      // Handle accounting details
      if (data.accountingdetails && data.accountingdetails.data && data.accountingdetails.data.length > 0) {
        const accountingdetails = [];
        data.accountingdetails.data.forEach(ad => {
          const detail = new DefendantAccountingDetails();
          detail.fillFromObject(ad);
          accountingdetails.push(detail);
        });
        this.accountingdetails = this.mergeValues(this.accountingdetails, accountingdetails);
      }

      // Handle Messages
      if ('messages' in data && data.messages) {
        this.messages = data.messages.data;
      }

      // Handle Check-in History
      if (data.checkinhistory && data.checkinhistory.data) {
        const checkinhistory = [];
        data.checkinhistory.data.forEach(hist => {
          const checkin = new Checkin();
          checkin.loadData(hist);
          checkinhistory.push(checkin);
        });
        this.checkinhistory = this.mergeValues(this.checkinhistory, checkinhistory);
      }
    }
  }

  // loads all data from api retrieval into correct parts
  public LoadData(data) {
    // console.log('defendant.loaddata sourcedata', data);
    if (data != null) {
      const mainThis = this;

      // get just defendant data for filling
      // calling fillFromObject with all of the data overwrites the tranaction property, etc.
      this.fillFromObject(data, true);
      this.person.loadData(data.person.data);
      if (data.checkinschedule && !!data.checkinschedule.data) {
        if (data.checkinschedule.data instanceof Array) {
          this.checkinschedule = new CheckInSchedule();
        } else {
          this.checkinschedule.loadData(data.checkinschedule.data);
        }
      } else {
        this.checkinschedule = new CheckInSchedule();
      }

      if (data.transactions && data.transactions.data !== null && data.transactions.data.length > 0) {
        const transactions = [];
        data.transactions.data.forEach(
          (transaction) => {

            const newTransaction = new Bail();
            newTransaction.loadData(transaction);
            transactions.push(newTransaction);
            newTransaction.canEditData = true;
          });
        this.transactions = this.mergeValues(this.transactions, transactions);
      }

      if (data.lawsuits && data.lawsuits.data) {
        this.lawsuits.length = 0;
        data.lawsuits.data.forEach(
          (lawsuit) => {
            const newLawsuit = new Lawsuit();
            newLawsuit.loadData(lawsuit);
            this.lawsuits.push(newLawsuit);
          });
      }

      this.calendaritems.length = 0;
      if (data.calendaritems && data.calendaritems.data !== null && data.calendaritems.data.length > 0) {

        const calendarItemTypes = CalendarUtilities.getCalendarEntryTypes();

        data.calendaritems.data.forEach(item => {
          const calendarItem = new CalendarItem();
          calendarItem.startDate = new Date(item['EventDate']);
          calendarItem.endDate = new Date(item['EventDate']);
          calendarItem.allDay = (calendarItem.startDate.getHours() < 7);
          calendarItem.text = item['EventSubject'];
          calendarItem.itemType = item['EventType'];
          mainThis.calendaritems.push(calendarItem);
        });
      }

      this.accountingdetails.length = 0;
      if (data.accountingdetails && data.accountingdetails.data !== null && data.accountingdetails.data.length > 0) {
        data.accountingdetails.data.forEach(ad => {
          const detail = new DefendantAccountingDetails();
          detail.fillFromObject(ad);
          this.accountingdetails.push(detail);
        });
      }

      if (data.hasOwnProperty('messages') && data.messages !== null) {
        this.messages = data.messages.data;
      }

     if (data.checkinhistory && data.checkinhistory.data) {
        this.checkinhistory.length = 0;
        data.checkinhistory.data.forEach(hist => {
          const checkin = new Checkin();
          checkin.loadData(hist);
          this.checkinhistory.push(checkin);
        });
      }
    }
  }

  getAssignedPowers(currentBond: Bond) {
    const rwPowers = [];
    this.transactions.forEach((transaction) => {
      let filterBond = transaction.bonds.filter(bond => bond != currentBond);
      filterBond.forEach((bond) => {
        if (!!bond.Id && !!bond.PowerNumber) {
          rwPowers.push({'Id': bond.Id, 'Text': bond.PowerNumber});
        }
      });
    });
    return rwPowers;
  }

  getRewriteBonds(currentBond: Bond) {
    const rwBonds = [];
    this.transactions.forEach((item) => {
      let fltBonds = item.bonds.filter(item => item != currentBond);
      fltBonds.forEach((item2) => {
        if (item2.PowerId && currentBond.PowerId) {
          const rwBond = {'Id': item2.Id, 'Text': item2.PowerNumber};
          rwBonds.push((rwBond));
        }
      });
    });
    return rwBonds;
  }

  setBondAsExonerated(currentBond: Bond) {
    this.transactions.forEach((transaction) => {
      transaction.bonds.forEach((bond) => {
        if (!!bond.Id && bond.Id == currentBond.RewrittenBondId) {
          bond.DischargeOccurrenceDate = (new Date()).toISOString().split('T')[0];
        }
      });
    });
  }

  public getUsedPowerNumbers() {
    let powerNumbers: any = [];
    if (this.transactions.length > 0) {
      this.transactions.forEach(trn => {
        if (trn.bonds.length > 0) {
          trn.bonds.forEach(bnd => {
            if (bnd.PowerNumber !== undefined && bnd.PowerNumber !== '') {
              powerNumbers.push(bnd.PowerNumber)
            }
          })
        }
      });
    }
    return powerNumbers;
  }

  public getContactsAndSelf() {
    let contacts: any = [];
    contacts.push({
      'Id': this.person.Id,
      'FullName': this.person.FullName + ' (Defendant)',
      'Type': 'Defendant',
      'phones': this.person.phones,
      'MessageFilterType': 'defendant'
    });

    if (this.transactions.length > 0) {
      this.transactions.forEach(trn => {
        trn.contacts.forEach(ct => {
          if (!contacts.some(check => check.Id === ct.Id)) {
            contacts.push({
              'Id': ct.person.Id,
              'FullName': ct.person.FullName + ' (' + ct.Type + ')',
              'Type': ct.Type,
              'phones': ct.person.phones,
              'MessageFilterType': 'contact'
            });
          }

        });
      });
    }
    return contacts;
  }

}


