import {BaseModel} from './BaseModel';

export class Reminderconfiguration extends BaseModel {
  // tslint:disable:variable-name
  Id: string;
  NotificationTemplateId: string;
  NotificationTypeId: string;
  DeliveryFrequency: string;
  DeliveryInterval: number;
  CustomText: string;
  AcceptReplies: any;
  CustomerId: string;
  Deleted_At: any;
  Created_At: any;
  Updated_At: any;
  CreatedByUserId: string;
  UpdatedByUserId: string;
  DeletedByUserId: string;
  PaymentLinkEnabled: boolean;
  PaymentLinkCustomUrl: string;
  PaymentLinkCustomEnabled: boolean;
  PaymentLinkGateway: string;

  constructor(obj?: object ) {
    super(obj);

    if (obj == null) {
      this.Id = '';

    }
  }

  public loadData(data) {
    // console.log('adi settings data in Reminderconfiguration model', data);
    if (data != null) {

      //   this.fillFromObject(data, true);
      //   //this.AutomaticNotificationSetting.length = 0;
      //   if (data.automaticnotificationsettings.data !== null)
      //   {
      //     this.AutomaticNotificationSetting = Object.assign(new AutomaticNotificationSetting(), data.automaticnotificationsettings.data);
      //   }
      //   // if (data.numberassignment.data !== null)
      //   // {
      //   //   this.NumberAssignment = Object.assign(new AdiNumberAssignment(), data.numberassignment.data);
      //   // }
      //   if (data.smstemplates.data !== null)
      //   {
      //     this.SmsTemplates = data.smstemplates.data.map(x => Object.assign(new SmsTemplate(), x));
      //
      //   }
      //
      //   // console.log(this);
      //
      // }


//    // console.log('defendant.loaddata loaded', this);

    }
  }
}



