import { fade, fadeInFromBottomOutFromTop } from './app.animations';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';
import { BillingService } from './services/billing.service';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SystemConfigService } from './services/systemconfig.service';
import { AppConstants } from './shared/AppConstants';
import { SystemConfig } from './models/systemconfig';
import { RouteDataService } from './services/routedata.service';
import { EventService } from './services/event.service';
import { environment } from '../environments/environment';
import { VersionCheckService } from './services/version-check.service';
// import * as Chatra from '@chatra/chatra';
// These should also go in the DevExtreme Module I pulled out of the main component.
import DataGrid from 'devextreme/ui/data_grid';
import DateBox from 'devextreme/ui/date_box';
import NumberBox from 'devextreme/ui/number_box';
import TextBox from 'devextreme/ui/text_box';
import Toast from 'devextreme/ui/toast';
import Popup from 'devextreme/ui/popup';
import Lookup from 'devextreme/ui/lookup';
import SelectBox from 'devextreme/ui/select_box';
import Button from 'devextreme/ui/button';
import CheckBox from 'devextreme/ui/check_box';
import MultiView from 'devextreme/ui/multi_view';
import Scheduler from 'devextreme/ui/scheduler';

import { RoutingStateService } from './services/routing-state.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifyService } from './common/notify/notify.service';
import { BasicComponent } from './components/common/layouts/basic.component';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { DialogService } from './services/dialog.service';
import * as localforage from 'localforage';
import { UnsubscribeOnDestroyAdapter } from './common/UnsubscribeOnDestroy';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fade,
    fadeInFromBottomOutFromTop,
  ],
})
export class AppComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  YesNoConfirmationTitle = '';
  YesNoConfirmationShow = false;
  YesNoConfirmationShowClose = false;
  YesNoConfirmMessage = '';
  YesNoYesText = 'Yes';
  YesNoNoText = 'No';
  YesNoCloseOnOutsideClick = false;
  YesNoYesButtonWidth = 175;
  YesNoMaxWidth = '450px';
  showWelcome: boolean = false;

  toastType: any;
  toastMessage: any;
  toastVisible = false;
  toastWindow: any;
  displayTime: 4000;
  preventDismiss: boolean;

  public constructor(
    private titleService: Title,
    private authService: AuthService,
    private billingService: BillingService,
    private router: Router,
    private systemConfigService: SystemConfigService,
    private sessionService: RouteDataService,
    private eventService: EventService,
    private versionCheckService: VersionCheckService,
    private activatedRoute: ActivatedRoute,
    private routingStateService: RoutingStateService,
    private snackBar: MatSnackBar,
    private notify: NotifyService,
    private location: Location,
    private dialogService: DialogService,
  ) {
    super();
    // config for using indexed DB
    localforage.config({
      driver      : localforage.INDEXEDDB,
      name        : 'Captira',
      version     : 1.0,
      storeName   : 'captira', // Should be alphanumeric, with underscores.
    });

    switch (this.location.path()) {
      case '/login':
      case '/logout':
        this.showWelcome = false;
        break;
      default:
        this.showWelcome = true;
        break;
    }
    routingStateService.loadRouting();
    this.toastWindow = window;
    this.subs.sink = this.eventService.showToast$.subscribe(item => {
      this.notify.toast(item.toastMessage, item.toastType);
    });

    // Event listener to fire snackbar notifications.
    this.subs.sink = this.eventService.showSnack$.subscribe(item => {
        const actionLabel = (!!item.actionLabel ? item.actionLabel : 'X');
        const classes = (!!item.panelClass ? item.panelClass : []);

        const newVersionSnack = this.snackBar.open(item.message, actionLabel, {
          duration: item.duration,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
          panelClass: item.panelClass,
        });

        if (!!item.onAction) {
          this.subs.sink = newVersionSnack.onAction().subscribe(item.onAction);
        }
    });

    this.subs.sink = this.eventService.guidedSetupViewSaveStep$.subscribe(item => {
      // console.log('save guided setup step-' + item);
      switch (item.product) {
        case 'collections' : {
          this.systemConfigService.saveGuidedStepStatus({CollectionGuidedSetupStep: item.step});
            break;
          }
          case 'defendantwatch' : {
            this.systemConfigService.saveGuidedStepStatus({DefendantWatchGuidedSetupStep: item.step});
              break;
          }
      }
    });


    MultiView.defaultOptions({ options: { swipeEnabled: false} });

    DataGrid.defaultOptions({
      device: {deviceType: 'phone'},
      options: {
        searchPanel: {
          visible: true,
        },
        columnChooser: {
          mode: 'select',
        },
        editing: {
          popup: {
            fullScreen: true,
          },
        },
        onToolbarPreparing: (e: any) => {

          const items = e.toolbarOptions.items;
          const searchPanel = items.filter((item: any) => item.name === 'searchPanel')[0];
          if (!!searchPanel) {
            searchPanel.locateInMenu = 'always';
          }
        },
        onOptionChanged: (e: any) => {
          if (e.name === 'searchPanel' && (!!e.value || !!e.previousValue)) {
            setTimeout(() => {
              let element: any = document.getElementsByClassName('dx-datagrid-search-panel')[0];
              while (element && !element.classList.contains('dx-overlay-content')) {
                element = element.parentElement;
              }
              if (!!element) {
                element.style.left = '-50px';
              }
            }, 100);
          }
        },
      },
    });

    DataGrid.defaultOptions({
      device: {deviceType: 'desktop'},
      options: {
        columnChooser: {
          mode: 'dragAndDrop',
        },
      },
    });

    DataGrid.defaultOptions({
      device: [{deviceType: 'desktop'}, {deviceType: 'phone'}],
      options: {
        minHeight: '500px',
        scrolling: {
          useNative: false,
          scrollByContent: true,
          scrollByThumb: true,
          showScrollbar: 'always',
          bounceEnabled: false,
        },
        noDataText: 'No Information',
      },
    });

    Toast.defaultOptions({
      device: {deviceType: 'desktop'},
      options: {
        displayTime: AppConstants.TOAST_DISPLAY_TIME,
        position: {
          my: 'top center',
          at: 'top right',
          of: window,
          offset: '-150 80',
        },
        width: '280',
        maxWidth: '350',
      },
    });

    Popup.defaultOptions({
      device: [{deviceType: 'desktop'}],
      options: {
        dragEnabled: true,
      },
    });

    Popup.defaultOptions({
      device: {deviceType: 'phone'},
      options: {
        fullScreen: true,
        showCloseButton: true,
      },
    });

    Popup.defaultOptions({
      device: [{deviceType: 'phone'}, {deviceType: 'desktop'}],
      options: {
        onShowing: (component) => {
          this.dialogService.add(component);
          const toolbarItems = component.component.option('toolbarItems');
          if (!!toolbarItems && toolbarItems.length === 2) {
            toolbarItems[0].options.stylingMode = 'contained';
            component.component.option('toolbarItems', [toolbarItems[1], toolbarItems[0]]);
          }
        },
        onHidden: () => {
          this.dialogService.remove();
        },
      },
    });

    DateBox.defaultOptions({
      options: {
        openOnFieldClick: true,
        placeholder: 'Select Date',
        width: '200px',
        // min: new Date().setFullYear(new Date().getFullYear() - 99),
        // max: new Date().setFullYear(new Date().getFullYear() + 10),
        pickerType: 'calendar',
        onFocusOut: (e: any) => {
          const newValue = e.component.field().value;
          if (newValue.length > 0) {
            if (newValue.split('-').length > 2 && newValue.split('-')[2].length > 1) {
              if (e.component._changedValue !== newValue) {
                e.component.reset();
              }
              e.component.option('value', moment(newValue, 'MM-DD-YYYY').format('YYYY-MM-DD'));
            }
            if (newValue.length > 6 && newValue.length < 9) {
              if (newValue.split('-').length == 1 && newValue.split('/').length == 1) {
                if (e.component._changedValue !== newValue) {
                  e.component.reset();
                }
                e.component.option('value', moment(newValue, 'MMDDYYYY').format('YYYY-MM-DD'));
              }
            }
            if (newValue.length == 6) {
              if (newValue.split('-').length == 1 && newValue.split('/').length == 1) {
                if (e.component._changedValue !== newValue) {
                  e.component.reset();
                }
                e.component.option('value', moment(newValue, 'MMDDYYYY').format('YYYY-MM-DD'));
              }
            }
            if (newValue.length == 5 && newValue[0] != 0) {
              if (newValue.split('-').length == 1 && newValue.split('/').length == 1) {
                if (e.component._changedValue !== newValue) {
                  e.component.reset();
                }
                let data = newValue;
                data = '0' + newValue;
                e.component.option('value', moment(data, 'MMDDYYYY').format('YYYY-MM-DD'));
              }
            }
          }
        },
      },
    });

    NumberBox.defaultOptions({
      options: {
        inputAttr: {
          onwheel: 'this.blur()',
        },
      },
    });

    TextBox.defaultOptions({
      options: {
        inputAttr: {
          autocomplete: 'new-text',
        },
        onOptionChanged: (e: any) => {
          if(e && e.name == "validationError"){
            if(e.value && e.value.type == "email"){
              e.component.option('value', e.value.value.trim());
            }
          }
        },
      },
    });

    Button.defaultOptions({
      options: {
        tabIndex: -1,
      },
    });

    SelectBox.defaultOptions({
      options: {
        searchMode: 'StartsWith',
        showClearButton: true,
        searchTimeout: 0,
        noDataText: 'Nothing to select',
        inputAttr: {
          autocomplete: 'new-text',
        },
      },
    });

    SelectBox.defaultOptions({
      device: [{deviceType: 'desktop'}],
      options: {
        searchEnabled: true,
        acceptCustomValue: true,

      },
    });

    SelectBox.defaultOptions({
      device: {deviceType: 'phone'},
      options: {
        searchEnabled: false,
        acceptCustomValue: false,
      },
    });

    Lookup.defaultOptions({
      device: [{deviceType: 'desktop'}],
      options: {
        searchEnabled: true,
        acceptCustomValue: true,
      },
    });


    Lookup.defaultOptions({
      device: {deviceType: 'phone'},
      options: {
        searchEnabled: false,
        acceptCustomValue: false,
      },
    });

    CheckBox.defaultOptions({
      options: {
        elementAttr: {
          class: 'checkbox',
        },
      },
    });

    Scheduler.defaultOptions({
      options: {
        currentView: 'agenda',
      },
    });
    // if (environment.environmentName === 'prod') {
    //   const config = {
    //     ID: environment.chatraId,
    //   };
    //   Chatra('init', config);
    // }
    this.subs.sink = authService.userLoggedIn$.subscribe(loginMsg => {
      this.appStartupOnceLoggedIn(loginMsg);
    });
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  appStartupOnceLoggedIn(loginMsg) {

    this.authService.BootstrapSentry();
    this.authService.BootstrapLogRocket();
    this.authService.bootstrapChatraUser();

    // If the user is logged in with Client credentials (such as for public-facing pages
    // like location checkin or collections payments page, do not check account status.
    if (this.authService.userLoggedInAsClientAuth()) {
      return;
    }

    const diffDate =
      new Date().getTime() - this.billingService.refreshMinutes * 60 * 1000; // milliseconds
    if (
      !this.billingService.GetLastResultTime() ||
      this.billingService.GetLastResultTime().getTime() < diffDate
    ) {
      this.subs.sink = this.billingService
        .GetCustomerBillingStatusString(true)
        .subscribe(status => {
          // // console.log(`Billing status from app.component ngAfterViewInit: ${status}`);
          if (status === 'PAST_DUE') {
            this.YesNoYesText = 'Update Now';
            this.YesNoNoText = 'Cancel';
            this.YesNoConfirmationTitle = 'Please Update Billing Details';
            this.YesNoConfirmMessage =
              'To avoid service interruptions, please provide new credit card information.';
            this.YesNoConfirmationShow = true;
          }
        });
    }
    this.loadSystemConfig();
  }

  // check if this is mobile or tablet device
  isMobileOrTablet() {
    let mobileOrTablet = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
        mobileOrTablet = true;
      }
    })(navigator.userAgent || navigator.vendor);
    return mobileOrTablet;
  }

  // check if the browser is Chrome
  isChrome() {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }

  ngAfterViewInit() {
    // MP - turning off for now (3/26/2020)
    // MP - turning back on after tickets (5/19/2020)
    // Redirect to support-chrome page if: desktop AND not chrome AND not continue without chrome selected
    if (!this.isMobileOrTablet()
      && !this.isChrome()
      && localStorage.getItem('continueWithoutChrome') == null
      && !this.authService.userLoggedInAsClientAuth()) {
      // mp - changing to a simple notification
      // em added a env setting to turn this on or off so justin can bypass this for quickbooks testing
      if (environment.checkForChrome) {
        this.notify.toast('You may be using an unsupported browser.  Captira requires Chrome.', 'error');
      }
      localStorage.setItem('continueWithoutChrome', 'true');
      // this.router.navigate(['/support-chrome']);
    }
  }

  ngOnInit() {
    const isReload = localStorage.getItem('reload');
    if (!!isReload) {
      this.authService.clearCache();
    }
    this.subs.sink = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data))
      .subscribe((event) => this.titleService.setTitle('Captira - ' + event['pageTitle']));

    this.subs.sink = this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
  }

  YesNoConfirmResult(e) {
    if (e) {
      sessionStorage.setItem('paymentupdate', 'true');
      this.router.navigate(['/getstarted/checkout']);
    }
  }

  loadSystemConfig() {
    // get system config and save to session storage for use through the app
    this.subs.sink = this.systemConfigService.get().subscribe(
      sc => {
        if (sc && sc['data'] && sc['data'].length > 0) {
          const systemConfig = new SystemConfig(sc['data'][0]);
          this.sessionService.setObject('systemConfig', systemConfig);
        } else {
          const systemConfig = new SystemConfig();
          this.sessionService.setObject('systemConfig', systemConfig);
        }
      },
      error => {
        const systemConfig = new SystemConfig();
        this.sessionService.setObject('systemConfig', systemConfig);
      },
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (
      localStorage.getItem('securityTokenType') !== undefined &&
      localStorage.getItem('securityTokenType') === 'client'
    ) {
      // em user is using location checkin and closes the tab or browser we need to clear local storage to wipe out the client api token
      // localStorage.clear();
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  onActiveEvent(componentReference: BasicComponent) {
    if (componentReference.onLoad) {
      this.subs.sink = componentReference.onLoad.subscribe((event: any) => {
        this.showWelcome = false;
      });
    } else {
      this.showWelcome = false;
    }
  }

}
