

<dx-select-box
  id="ussatelu"
  #ussatelu
  (onFocusIn)='focusIn($event)'
  [dataSource]="states"
  displayExpr="Id"
  valueExpr="Id"
  [placeholder]="placeholderVisible ? 'Select State' : ''"
  [(value)]="stateCode"
  (onValueChanged)="onValueChanged($event)"
  [readOnly]="fieldsDisabled"
>
  <div *dxTemplate="let data of 'dropDownButton'">
    <dx-load-indicator [visible]="isLoading"></dx-load-indicator>
    <div class="dx-dropdowneditor-icon" [hidden]="isLoading"></div>
  </div>
</dx-select-box>

