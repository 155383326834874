<ng-container *ngIf="!zoomId; else zoomButton">
  <p>Zoom Video Calls</p>
  <button mat-button color="primary" (click)="launchZoomDialog()" type="button">
    Setup Zoom ID
  </button>
</ng-container>

<ng-template #zoomButton>
  <button [matMenuTriggerFor]="zoomOptions"
          mat-button
          color="primary"
          [disabled]="!defendant?.person?.phones[0] || sendingSms"
          type="button">
    <span>
      <ng-container *ngIf="defendant?.person?.phones[0]; else needsPhoneText">
        Zoom Meeting
      </ng-container>
      <ng-template #needsPhoneText>
        Provide Mobile Number
      </ng-template>
    </span>
    <i [class]="defendant?.person?.phones[0] ?
      'fas fa-video icon-spacer' : 'fas fa-video-slash icon-spacer'">
    </i>
  </button>
  <mat-menu #zoomOptions="matMenu">
    <button mat-menu-item (click)="sendSms()" type="button">
      <mat-icon>textsms</mat-icon>
      <span>Send Text Message</span>
    </button>
    <button mat-menu-item (click)="openZoom()" type="button">
      <mat-icon>group</mat-icon>
      <span>Begin Zoom Meeting</span>
    </button>
    <button mat-menu-item (click)="launchZoomDialog()" type="button">
      <mat-icon>settings_applications</mat-icon>
      <span>Zoom Settings</span>
    </button>
  </mat-menu>
</ng-template>
