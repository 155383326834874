import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { TwoFactorService } from '../services/two-factor.service';

export interface TwoFactorData {
  id: string;
}

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss'],
})
export class TwoFactorComponent implements OnInit {

  public verificationCode = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
  public isLoading = false;
  public error: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TwoFactorData,
    private dialogRef: MatDialogRef<TwoFactorComponent>,
    private twoFactorService: TwoFactorService,
  ) { }

  ngOnInit(): void {
  }

  verifyCode() {
    this.isLoading = true;
    this.error = '';
    const code = this.verificationCode.value;
    if (!code) {
      this.isLoading = false;
      return;
    }
    this.twoFactorService.validateCode(this.data.id, code).subscribe((res: any) => {
      this.isLoading = false;
      this.dialogRef.close(true);
    }, (error) => {
      this.isLoading = false;
      this.error = error.message;
    });
  }

  resendCode() {
    this.error = '';
    this.isLoading = true;
    this.twoFactorService.resendCode(this.data.id).subscribe((res: any) => {
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.error = 'Error sending verification code';
    });
  }

}
