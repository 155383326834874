import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import * as EXIF from 'exif-js/exif';
import { FileUtilities } from '../../../shared/FileUtilities';
import { AppConstants } from '../../../shared/AppConstants';
import { PersonsService } from '../../../services/persons.service';
import { NotifyService } from '../../../common/notify/notify.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-mugshot',
  templateUrl: './mugshot.component.html',
  styleUrls: ['./mugshot.component.scss'],
})
export class MugshotComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('imagePreview', { static: true }) imagePreview: ElementRef;

  @Input() public defendantId: string;
  @Input() public dataUrl: string;
  @Input() public gender: string;
  @Input() public cameraMode: 'user' | 'environment' = 'user';
  @Input() public preview = false;
  @Input() public canDeleteMugshot: boolean = true;  // use this input variable to control delete mugshot functionality.

  @Output() public onChanged = new EventEmitter<any>();
  @Output() public onDeleted = new EventEmitter<any>();

  tmpFile: any;
  imageExif: any;
  imageFileName: string;
  imageFileSize: string;
  imageDataUrl: string;
  imageFallback: string;
  imageOrientation: number;
  showDeleteButton = false;
  showMugShowDeleteYesNo = false;
  private fileInput: HTMLInputElement;

  constructor(
    private elRef: ElementRef,
    private personService: PersonsService,
    private notifyService: NotifyService,
  ) {
    super();
  }

  ngOnInit() {
    this.setPlaceholder();
  }

  ngAfterViewInit(): void {
    this.fileInput = this.elRef.nativeElement.querySelector('#picFile');
  }

  reInit() {
    // console.log('Mugshot component reInit');
    this.tmpFile = undefined;
    this.imageExif = undefined;
    this.imageFileName = undefined;
    this.imageFileSize = undefined;
    this.imageDataUrl = undefined;
    this.imageFallback = undefined;
    this.defendantId = '';
    this.dataUrl = '';
    this.gender = '';
    this.setPlaceholder();
  }

  ngOnChanges(changes) {
    // console.log(changes);
    if (changes.gender && changes.gender.currentValue !== changes.gender.previousValue) {
      this.gender = changes.gender.currentValue;
      this.setPlaceholder();
    }

    if (changes.dataUrl && !!changes.dataUrl.currentValue) {
      this.imageDataUrl = this.dataUrl;
      this.imagePreview.nativeElement.src = this.dataUrl + '?q=123';

      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = this.dataUrl + '?q=123';
      img.onload = this.loadSavedImage;
    }
  }

  imageChange(event: any) {

    const input: any = event.target;
    if (!input.files ) {

      // console.log('browser does not support files');
    }

    if (!input.files[0]) {
      // console.log('no file selected');
      return;
    }

    this.tmpFile = input.files[0];

    this.imageFileName = this.tmpFile.name;
    this.imageFileSize = this.tmpFile.size;
    // console.log('mugshot image changed', this.tmpFile, this.imageFileName, this.imageFileSize);

    const fileReader = new FileReader();
    fileReader.onload = this.onFileLoaded;
    fileReader.readAsDataURL(this.tmpFile);
  }

  // private onFileLoaded = (event: any) => {
  //   this.imageOrientation = this.getImageOrientation(event);
  //   // console.log('image orientation: ', this.imageOrientation);
  //   //TODO: Error Handling, eh
  //   const exif = EXIF.readFromBinaryFile(
  //     FileUtilities.dataUrlToArrayBuffer(event.target.result)
  //   );
  //   // console.log(`${JSON.stringify(exif)}`);
  //   this.imageExif = exif;
  //
  //   //off-screen image
  //   let img = new Image();
  //   img.src = event.target.result;
  //   img.onload = this.onImageLoaded;
  // };
  //
  // private onImageLoaded = (event: any) => {
  //   // console.log('img loaded event', event);
  //   let img = event.target;
  //
  //   // console.log(`mugshot original image is ${img.width}x${img.height}`);
  //
  //   const MAX_WIDTH = 800;
  //   const MAX_HEIGHT = 600;
  //   let width = img.width;
  //   let height = img.height;
  //
  //   if (width > height) {
  //     if (width > MAX_WIDTH) {
  //       height *= MAX_WIDTH / width;
  //       width = MAX_WIDTH;
  //     }
  //   } else {
  //     if (height > MAX_HEIGHT) {
  //       width *= MAX_HEIGHT / height;
  //       height = MAX_HEIGHT;
  //     }
  //   }
  //
  //   let canvas = document.createElement('canvas');
  //   canvas.width = width;
  //   canvas.height = height;
  //
  //   // console.log(`mugshot new image is ${width}x${height}`, this.imageExif, this.imageOrientation);
  //
  //   if (this.imageOrientation > 0) {
  //     FileUtilities.transformCoordinates(canvas, this.imageExif['Orientation']);
  //   }
  //
  //   let ctx = canvas.getContext('2d');
  //
  //   ctx.drawImage(img, 0, 0, width, height);
  //
  //   let dataUrl = canvas.toDataURL('image/png');
  //
  //   this.imageDataUrl = dataUrl;
  //   this.onChanged.emit({dataUrl: this.imageDataUrl, file: this.tmpFile});
  // }

  autoClick() {
    if (this.fileInput) {
      this.fileInput.click();
    }
  }

  hasFile() {
    return this.tmpFile !== undefined;
  }

  setPlaceholder() {
    this.imageFallback = (this.gender !== 'F') ?
      AppConstants.DEFAULT_MALE_MUGSHOT : AppConstants.DEFAULT_FEMALE_MUGSHOT;
  }

  getImageOrientation(e) {
    const view = new DataView(FileUtilities.dataUrlToArrayBuffer(e.target.result));
    if (view.getUint16(0, false) !== 0xFFD8) {
      return -1;
    }

    const length = view.byteLength;
    let offset = 2;

    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) {
        return -1;
      }
      const marker = view.getUint16(offset, false);
      offset += 2;
      if (marker === 0xFFE1) {
        if (view.getUint32(offset += 2, false) !== 0x45786966) {
          return -1;
        }

        const little = view.getUint16(offset += 6, false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + (i * 12), little) === 0x0112) {
            return view.getUint16(offset + (i * 12) + 8, little);
          }
        }
        // tslint:disable-next-line:no-bitwise
      } else if ((marker & 0xFF00) !== 0xFF00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return -1;
  }

  onMouseOver() {
    if (this.canDeleteMugshot) {
      this.showDeleteButton = true;
    }
  }

  onMouseLeave() {
    if (this.canDeleteMugshot) {
      this.showDeleteButton = false;
    }
  }

  onMugshotDelete(shouldRemove: boolean) {
    if (shouldRemove) {
      this.subs.sink = this.personService.removeMugshot(this.defendantId)
        .subscribe((res) => {
          this.imageDataUrl = undefined;
          this.fileInput.value = '';
          this.notifyService.toast('Mugshot removed successfully', 'success', undefined, 6000);
          this.onDeleted.emit();
        });
    }
  }

  private onFileLoaded = (event: any) => {
    this.imageOrientation = this.getImageOrientation(event);
    // console.log('image orientation: ', this.imageOrientation);
    let exif;
    try {
      exif = EXIF.readFromBinaryFile(
        FileUtilities.dataUrlToArrayBuffer(event.target.result),
      );
    } catch (e) {
      exif = false;
    }
    // console.log(`${JSON.stringify(exif)}`);
    this.imageExif = exif;

    // off-screen image
    const img = new Image();
    img.src = event.target.result;
    img.onload = this.onImageLoaded;
  }

  private onImageLoaded = (event: any) => {
    // console.log('img loaded event', event);
    const img = event.target;

    // console.log(`mugshot original image is ${img.width}x${img.height}`);

    const MAX_WIDTH = 800;
    const MAX_HEIGHT = 600;
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    console.log(`mugshot new image is ${width}x${height}`, this.imageExif, this.imageOrientation);

   // if (!!this.imageExif) {
     // FileUtilities.transformCoordinates(canvas, this.imageExif['Orientation']);
   // } else if (!!this.imageOrientation) {
    //   FileUtilities.transformCoordinates(canvas, this.imageOrientation);
    // }
    if (!this.tmpFile) {
      this.imageDataUrl = null;
      this.onChanged.emit({ dataUrl: null, file: null });
      return;
    }

    const ctx = canvas.getContext('2d');

    ctx.drawImage(img, 0, 0, width, height);

    const dataUrl = canvas.toDataURL('image/png');

    this.imageDataUrl = dataUrl;
    this.onChanged.emit({ dataUrl: this.imageDataUrl, file: this.tmpFile });
  }

  private loadSavedImage = (event: any) => {
    // console.log('img loaded event', event);
    const img = event.target;

    // console.log(`mugshot original image is ${img.width}x${img.height}`);

    const MAX_WIDTH = 800;
    const MAX_HEIGHT = 600;
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    // console.log(`mugshot new image is ${width}x${height}`, this.imageExif, this.imageOrientation);

    const ctx = canvas.getContext('2d');

    ctx.drawImage(img, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/png');
    this.imageDataUrl = dataUrl;
  }

}
