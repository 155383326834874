<dx-text-box
  [(value)]="Ssn"
  id="txSSN"
  [placeholder]="placeholderVisible ? 'Social Security Number' : ''"
  mask="000-00-0000"
  class="_lr-hide"
  [maskRules]="ssnRules"
  [maskInvalidMessage]="maskInvalidMessage"
  (onFocusOut)="onFocusOut($event)"
  (onValueChanged)="onValueChanged($event)"
  (onInitialized)="onInitialized($event)"
  [readOnly]="readOnlyField"
  [disabled]="inputDisabled"
>
  <dx-validator>
    <dxi-validation-rule
    type='pattern'
    [pattern]='ssnPattern'
    [message]="patternInvalidMessage">
    </dxi-validation-rule>
  </dx-validator>
</dx-text-box>
