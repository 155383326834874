<h1 mat-dialog-title>Change Bond Status</h1>
<hr>
<div mat-dialog-content id='bondStatusUpdater'>

  <dx-date-box
    #bondDate
    [(value)]='boundField'
    type='date'
    dateSerializationFormat='yyyy-MM-dd'
    [displayFormat]='dateFormatter'
    [placeholder]='inputLabel'
    (valueChange)='showSatisfyByDatePopup($event)'
  >
    <dx-validator #validator>
      <dxi-validation-rule
        type='required'
        message='Please enter a Date'>
      </dxi-validation-rule>
      <dxi-validation-rule
        type='custom'
        [validationCallback]='checkDate'
        [reevaluate]='true'
        message='Exoneration date can not be in the future'>
      </dxi-validation-rule>
    </dx-validator>
  </dx-date-box>
  <br />
  <div *ngIf='showDisposition'>
    <app-lookup
      [(selectedValue)]='selectedBond.DispositionId'
      lookupSource='Dispositions'
      lookupTypeUserFriendly='Disposition'
      lookupType='dispositions'
    ></app-lookup>
  </div>

  <app-defendantwatch-prompt promptSource="BondStatus"></app-defendantwatch-prompt>

</div>

<div mat-dialog-actions align='end'>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-raised-button
          [color]='saveButtonColor'
          (click)='saveAndClose()'
          [disabled]='busySaving'
          type='submit'>
    Update Bond
  </button>
</div>
<app-errorpopup
  [routeToGoToAfterDisplay]=''
  [height]="'300'"
  [width]="'400'"
  [resizeEnabled]='true'
  [closeOnOutsideClick]='false'
  [title]="'Error, please try again'"
  [shading]='false'
  [data]='errorData'
  [visible]='errorVisible'>
</app-errorpopup>

<!-- popup for calculating satisfy date -->

<dx-popup
  class='popup'
  [showTitle]='true'
  title='Calculate Satisfy By Date'
  [dragEnabled]='false'
  [showCloseButton]='false'
  [height]="'20%'"
  [width]="'60%'"
  [closeOnOutsideClick]='false'
  [(visible)]='calculateSatisfyByDatePopupVisible'
>
  <div *dxTemplate="let data of 'content'">
    <app-satisfy-by-date
      [model]="selectedBond"
      [forfeitDate]="bondDate.value"
      (closeSetSatisfyByDatePopup)="closeSatisfyByDatePopup()"
    >
    </app-satisfy-by-date>
  </div>
</dx-popup>
