import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {ObjectUtilities} from '../shared/ObjectUtilities';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;

@Injectable()
export class PowerService {

  constructor(private http: HttpClient, private settings: SettingsService) {
  }


  searchPower(search: string, agencyId?: string, agentId?: string): Observable<any> {

    if (isNullUndefinedOrEmpty(agencyId) || agencyId === '') {
      agencyId = 'null';
    }

    if (isNullUndefinedOrEmpty(agentId) || agentId === '') {
      agentId = 'null';
    }
    return this.http.get(`${this.settings.getBaseUrl()}/power/available/${search}/${agencyId}/${agentId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.json().error || 'Server Error')),);
  }

  getGrossNetBufForBond(data: any): Observable<any> {

    return this.http.post(`${this.settings.getBaseUrl()}/bond/calculategrossnetbuf`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getPowersForVoidUnvoid(action: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/powers/get/voidunvoid`, action).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  voidUnvoidPowers(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/powers/voidunvoid`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getPowerView(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/powers/view/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/powers/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/powers`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  update(id: string, data: any): Observable<any> {
    return this.http.patch(`${this.settings.getBaseUrl()}/powers/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getAllAvailablePowers(page: number): Promise<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/powers/all/available?page=${page}&limit=0`)
    .toPromise<any>()
    .then((res: any) => res)
      .catch(error => throwError(error.json().error || 'Server Error'));
  }

  searchPowerPromise(search: string, page: number): Promise<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/power/available/${search}/null/null?limit=100&page=${page}`)
    .toPromise<any>()
    .then((res: any) => res)
      .catch(error => throwError(error.json().error || 'Server Error'));
  }
}
