import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Login } from '../models/login';
import { SettingsService } from './settings.service';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { ApiUtilities } from '../shared/ApiUtilities';
import * as LogRocket from 'logrocket';
import * as localforage from 'localforage';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UnsubscribeOnDestroyAdapter } from '../common/UnsubscribeOnDestroy';
import { AppConstants } from '../shared/AppConstants';

import { setContext, setUser } from '@sentry/angular';
import { NotifyService } from '../common/notify/notify.service';
import * as Chatra from '@chatra/chatra';
import { CacheService } from './cache.service';
import { CapAccount } from './billing.service';
import { NotificationDialogComponent } from '../components/controls/notification-dialog/notification-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from './dialog.service';
import { TwoFactorComponent } from '../two-factor/two-factor.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventService } from './event.service';
import { DocumentService } from './document.service';

export interface AuthResponse {
  status: 'OK' | 'NeedCustomer' | 'False';
  FirstName: string;
  LastName: string;
  activeCustomerId: string;
  userId: string;
  identityId: string;
  rootUserAccessKeyId: string;
  rootUserSecretAccessKey: string;
  MobileNumber: string;
  Enable2FA: boolean;
  validCustomers: CapAccount [];
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

@Injectable()
export class AuthService extends UnsubscribeOnDestroyAdapter {

  public static otherData: any = {};
  public loggingOut = false;

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  private userLoggedInSource = new Subject<string>();
  private userLoggedinFired = false;
  private closedSessions = false;
  private needsCustomer = false;
  private isMobile: boolean;
  userLoggedIn$ = this.userLoggedInSource.asObservable();

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private router: Router,
    private notifyService: NotifyService,
    private cache: CacheService,
    private dialog: MatDialog,
    public eventService: EventService,
    private dialogService: DialogService,
    private documentService: DocumentService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.isMobile = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
  }

  ClientLogin() {
    // used for non username password logins - eg location checkin or collections public pages
    // MP - log in every time - prevent issues for expired tokens..
    // if (this.userLoggedInAsClientAuth()) {
    //   return of(true); // don't hit the API for a new token if we already have one
    // }
    const postData = {
      'client_id': environment.client_id,
      'client_secret': environment.client_key,
      'grant_type': 'client_credentials',
      'scope': '*',
    };

    const options = {headers: this.headers};
    // console.log('login options', options);

    return this.http.post(this.settings.getPassportUrl(), postData, options).pipe(
      map((res: any) => {
        // console.log('login json', res);
        const token = res.access_token;

        if (token) {
          const respObject = res;
          AuthService.otherData.token = token;
          AuthService.otherData.tokenType = 'client';

          localStorage.setItem('clientAuthToken', token);
          localStorage.setItem('securityTokenType', 'client');
          return true;
        } else {
          return false;
        }
      }),
      catchError(error => throwError(error)));
  }

  showTwoFactorAuth(id: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const config: MatDialogConfig = {
        width: '25%',
        height: '45%',
        hasBackdrop: true,
        disableClose: true,
        data: { id },
      };
      if (this.isMobile) {
        config.width = '100%';
        config.height = '100%';
      }
      const dialogRef = this.dialog.open(TwoFactorComponent, {
        data: { id },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  Login(login: Login, selectedCustomerId: any): Observable<AuthResponse> {
    return new Observable(subscriber => {
      const postData = {
        email: login.email,
        password: login.password,
      };
      // console.log('login post data', postData);
      // console.log(`selectedCustomerId at login: ${selectedCustomerId}`);
      if (selectedCustomerId) {
        if (this.headers.has('X-CAPTIRA-CUSTOMERID')) {
          this.headers = this.headers.delete('X-CAPTIRA-CUSTOMERID');
        }
        this.headers = this.headers.set('X-CAPTIRA-CUSTOMERID', selectedCustomerId.toString());
      }

      const options = { headers: this.headers };
      // console.log('login options', options);

      this.http.post<AuthResponse>(this.settings.getLoginUrl(), postData, options).pipe(
        map((res: AuthResponse) => {
          // console.log('login json', res);
          const token = res.access_token;
          if (token) {
            // If no activeCustomerId comes back with the login, this means that the identity has users in more
            //  than one customer. Need to get information from the user about what customer to log into.
            if (!res.activeCustomerId) {
              // this.needsCustomer = true;
              // if (!!res.closed_sessions && res.closed_sessions > 0) {
              //   this.closedSessions = true;
              // }
              localStorage.setItem('validCustomers', JSON.stringify(res.validCustomers));
              res.status = 'NeedCustomer';
              subscriber.next(res);
            } else {

              // Need to do this to check if user has multiple accounts. If not we always end up showing toast
              // because this method gets run twice and logs them in twice.
              // if (this.closedSessions) {
              //   this.notifyService.toast('Logging out previous sessions');
              //   this.closedSessions = false;
              //   this.needsCustomer = false;
              // } else if (!this.needsCustomer && !!res.closed_sessions && res.closed_sessions > 0) {
              //   this.notifyService.toast('Logging out previous sessions');
              // }
              // If 2FA is enabled, show the 2FA dialog
              if (res.Enable2FA) {
                this.showTwoFactorAuth(res.identityId).subscribe((success) => {
                  if (success) {
                    this.cacheAuth(res, login.email);

                    this.FireUserLoggedIn('new login');
                    this.createLoginHistoryEntry(); // #323
                    res.status = 'OK';
                    subscriber.next(res);
                  } else {
                    res.status = 'False';
                    subscriber.next(res);
                  }
                });
              } else {
                this.cacheAuth(res, login.email);

                this.FireUserLoggedIn('new login');
                this.createLoginHistoryEntry(); // #323
                res.status = 'OK';
                subscriber.next(res);
              }
            }
          } else {
            res.status = 'False';
            subscriber.next(res);
          }
        }),
        catchError(error => {
            if (error.status === 429) {
              const errorMessages = error.error?.errors?.email;
              if (errorMessages) {
                this.notifyService.toast(errorMessages[0], 'error');
              }
            }
            const response: AuthResponse = {
              status: 'False',
              FirstName: '',
              LastName: '',
              activeCustomerId: '',
              userId: '',
              identityId: '',
              rootUserAccessKeyId: '',
              rootUserSecretAccessKey: '',
              MobileNumber: '',
              Enable2FA: false,
              validCustomers: [],
              token_type: '',
              expires_in: 1,
              access_token: '',
              refresh_token: '',
            };
            subscriber.next(response);
            return of(response);
          },
        )).subscribe();
    });
  }

  cacheAuth(response: AuthResponse, email: string) {
    localStorage.setItem('securityToken', response.access_token);
    localStorage.setItem('refreshToken', response.refresh_token);
    localStorage.setItem('userEmail', email);
    localStorage.setItem('userName', response.FirstName + ' ' + response.LastName);
    localStorage.setItem('userId', response.userId);
    localStorage.setItem('identityId', response.identityId);
    localStorage.setItem('mobileNumber', response.MobileNumber);

    const activeCustomer = response.validCustomers.find(item => item.CustomerId === response.activeCustomerId);
    localStorage.setItem('companyName', activeCustomer.Name);
    localStorage.setItem('documentRoot', activeCustomer.DocumentStoreBucketName);
    localStorage.setItem('documentKeyId', activeCustomer.DocumentStoreAccessKeyId);
    localStorage.setItem('activeCustomerId', response.activeCustomerId);
    this.setDocumentSettings(activeCustomer.DocumentStoreAccessKeyId, activeCustomer.DocumentStoreBucketName);
  }

  // Handle events after login action
  createLoginHistoryEntry() {
    this.subs.sink = this.createLoginHistory().subscribe(response => {
      // console.log('Response login history: ', response);
    });
  }

  BootstrapSentry() {
    // https://docs.sentry.io/platforms/javascript/guides/angular/enriching-events/identify-user/

    setUser({

      email: localStorage.getItem('userEmail'),
      username: localStorage.getItem('userName'),
      id: localStorage.getItem('identityId'),
      ip_address: '{{auto}}',
    });
    // https://docs.sentry.io/platforms/javascript/guides/angular/enriching-events/context/
    // ... Can't use LogRocket.sessionURL bc it might not be available yet, see
    // https://docs.logrocket.com/docs/sentry
    // Sentry.setContext('Additional Details', {
    //   LogRocketSessionUrl: LogRocket.sessionURL,
    //   CustomerId: localStorage.getItem('activeCustomerId'),
    // });
    LogRocket.getSessionURL(sessionURL => {

      setContext('Additional Details', {

        LogRocketSessionUrl: sessionURL,
        CustomerId: localStorage.getItem('activeCustomerId'),
      });
    });
  }

  GetCustomerId() {
    return localStorage.getItem('activeCustomerId');
  }

  BootstrapLogRocket() {
    try {
      switch (environment.environmentName) {
        case 'dev':
        case 'test':
        case 'prod':
          if (!this.router.url.startsWith('/location/checkin')) {
            LogRocket.init(environment.logrocket);
            this.IdentifyBootStrapUser();
          }
          break;
      }
    } catch (e) {
      console.error('error bootstrapping LogRocket', e);
    }
    // https://docs.logrocket.com/docs/sentry

  }

  bootstrapChatraUser() {
    // if (environment.environmentName === 'prod') {
    //   const email = localStorage.getItem('userEmail');
    //   if (email) {
    //     const config = {
    //       ID: environment.chatraId,
    //       integration: {
    //         name: localStorage.getItem('userName'),
    //         email,
    //         company: localStorage.getItem('companyName'),
    //         BillingStatus: sessionStorage.getItem('BillingStatus'),
    //       },
    //     };
    //     Chatra('init', config);
    //   }
    // }
  }

  IdentifyBootStrapUser() {
    const email = localStorage.getItem('userEmail');
    if (email) {
      const userId = localStorage.getItem('userId');
      const activeCustomerId = localStorage.getItem('activeCustomerId');
      const userName = localStorage.getItem('userName');
      LogRocket.identify(userId, {
        name: userName,
        email: email,
        customerId: activeCustomerId,
      });
    }
  }

  RefreshToken(): Observable<any> {
    // console.log('in authService.RefreshToken');
    if (localStorage.getItem('refreshToken')) {
      // need to clear security token before doing refresh, as that is an unauthenticated endpoint..
      localStorage.removeItem('securityToken');
      const postData = {
        refresh_token: localStorage.getItem('refreshToken'),
      };
      this.headers = this.headers.set('X-CAPTIRA-CUSTOMERID', localStorage.getItem('activeCustomerId'));
      // const options = new RequestOptions({headers: this.headers});
      return this.http.post(this.settings.getRefreshUrl(), postData, {headers: this.headers}).pipe(
        map((res: any) => {
          // console.log('refresh json', res);
          const token = res.access_token;
          if (token) {
            // TODO: THere's gonna be some trouble here with multi-customer identities...
            localStorage.setItem('securityToken', token);
            localStorage.setItem('refreshToken', res.refresh_token);
            return of(token);
          }
          return throwError('Error when refreshing token');
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
    } else {
      return throwError('No Refresh token available');
    }
  }

  getToken(fireUserLoggedIn = true) {
    let currentUrl = this.router.url.split('/')[1];
    let securityToken = null;
    if (currentUrl.includes('?')) {
      currentUrl = currentUrl.split('?')[0];
    }
    if (AppConstants.PUBLIC_PAGE_URLS.includes(currentUrl)) {
      securityToken = localStorage.getItem('clientAuthToken');
      if (!securityToken || securityToken === '') {
        if (AuthService.otherData.token) {
          securityToken = AuthService.otherData.token;
          localStorage.setItem('clientAuthToken', securityToken);
          localStorage.setItem('securityTokenType', AuthService.otherData.tokenType);
        }
      }
    } else {
      securityToken = localStorage.getItem('securityToken');
    }
    if (securityToken && fireUserLoggedIn) {
      this.FireUserLoggedIn('existing token');
    }
    return securityToken;
  }

  Logout(skipRedirect = false, skipRefresh = false, returnUrl = '') {
    // never redirect or refresh if the user is at the register page.
    // TODO: HACK
    // console.log('in logout, url', this.router.url);
    if (this.router.url.split('?')[0] === '/register') {
      skipRedirect = true;
      skipRefresh = true;
    }

    if (this.router.url.split('/')[1] === 'collection-details') {
      skipRedirect = true;
      skipRefresh = true;
    }
    this.loggingOut = true;
    if (!this.getToken()) {
      if (!skipRedirect) {
        if (!!returnUrl) {
          this.router.navigate(['/login'], {queryParams: {returnUrl: returnUrl}});
        } else {
          this.router.navigateByUrl('/login');
        }
        if (!skipRefresh) {
          // console.log('token not found, refreshing app');
          window.location.reload(true);
        }
      }
      return;
    }

    if (!skipRedirect) {
      this.router.navigateByUrl('/logout');
    }
    const postData = null;
    const myHeaders = this.headers;
    myHeaders.append('Authorization', `Bearer ${this.getToken(false)}`);
    const options = {headers: myHeaders};
    this.subs.sink = this.http.post(this.settings.getLogoutUrl(), postData, options).pipe(
      map((res: any) => {
        // console.log(res);
      }),
      catchError((error: any) => {
        this.loggingOut = false;
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),
      finalize(async () => {
        this.loggingOut = false;
        this.userLoggedinFired = false;

        // Saving customer admin data grid to persist after user logout
        let tempAdminCustomers: any, tempAdminProducts: any, tempshowCarolTour: any, tempCalendarView: any, tempShowAllAccouting: any;
        tempAdminCustomers = await this.cache.getFromIDB('customersDataGrid');

        if (localStorage.getItem('customersSubscribedProducts') != null) {
          tempAdminProducts = localStorage.getItem('customersSubscribedProducts');
        }
        if (localStorage.getItem('showCarolTour') != null) {
          tempshowCarolTour = localStorage.getItem('showCarolTour');
        }

        if (localStorage.getItem('calendarView') != null) {
          tempCalendarView = localStorage.getItem('calendarView');
        }

        if (localStorage.getItem('accoutingToggle') != null) {
          tempShowAllAccouting = localStorage.getItem('accoutingToggle');
        }

        const tempSatisfyByDateDays = localStorage.getItem('satisfyByDateDays');
        const tempNewDefendantMode = localStorage.getItem('NewDefendantMode');
        const tempSignupProduct = sessionStorage.getItem('signupProduct');

        localStorage.clear();
        sessionStorage.clear();
        localforage.clear();

        sessionStorage.setItem('signupProduct', tempSignupProduct);
        // Restoring customer admin data grid after clear
        if (tempAdminCustomers) {
          await this.cache.setInIDB('customersDataGrid', tempAdminCustomers, false);
        }

        localStorage.setItem('customersSubscribedProducts', tempAdminProducts);
        localStorage.setItem('showCarolTour', tempshowCarolTour);
        localStorage.setItem('accoutingToggle', tempShowAllAccouting);

        // Need to check if undefined because users that didn't have it set are now getting errors
        if (!!tempSatisfyByDateDays && tempSatisfyByDateDays !== 'undefined') {
          localStorage.setItem('satisfyByDateDays', tempSatisfyByDateDays);
        }
        if (!!tempNewDefendantMode && tempNewDefendantMode !== 'undefined') {
          localStorage.setItem('NewDefendantMode', tempNewDefendantMode);
        }

        // Set the calendar view here so that we don't lose this after logout
        if (!!tempCalendarView && tempCalendarView !== 'undefined') {
          localStorage.setItem('calendarView', tempCalendarView);
        }

        if (!skipRedirect && !skipRefresh) {
          // need to reload the app to prevent services from hanging around with stale information after logout
          // console.log('refreshing app in authService.logout');
          window.location.reload(true);
        }
      }))
      .subscribe();

  }

  ForgotPassword(email): Observable<any> {

    const resetUrl = this.GetPasswordResetUrl();
    const forgotPasswordPostData = {email: email, reseturl: resetUrl};
    return this.http.post(this.settings.getPasswordForgotUrl(), forgotPasswordPostData, {headers: this.headers}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  GetPasswordResetUrl(): any {
    const internalUrl = '/password/reset';
    // Resolve the base url as the full absolute url subtract the relative url.
    const currentAbsoluteUrl = window.location.href;
    const currentRelativeUrl = this.router.url;
    const index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    const baseUrl = currentAbsoluteUrl.substring(0, index);

    // Concatenate the urls to construct the desired absolute url.
    return baseUrl + internalUrl;
  }

  ResetPassword(email, token, password): Observable<any> {
    const resetPasswordPostData = {email: email, token: token, password: password};
    return this.http.post(this.settings.getPasswordResetUrl(), resetPasswordPostData, {headers: this.headers}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  VerifyEmail(email, token) {
    const verifyEmailPostData = {email: email, token: token};
    return this.http.post(`${environment.baseApi}/identities/verifyemailaddress`, verifyEmailPostData, {headers: this.headers}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  FireUserLoggedIn(logInMsg: string) {
    // only fire this once per application startup
    if (!this.userLoggedinFired) {
      this.userLoggedinFired = true;
      this.userLoggedInSource.next(logInMsg);
    }
  }

  createLoginHistory() {
    // console.log(this.settings.getBaseUrl() + '/loginhistories');

    return this.http.post(this.settings.getBaseUrl() + '/loginhistories', {
      'UserId': localStorage.getItem('userId'),
      'Browser': navigator.userAgent,
    }, {headers: this.headers}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  userLoggedInAsClientAuth(): boolean {
    return localStorage.getItem('securityTokenType') != null && localStorage.getItem('securityTokenType') === 'client';
  }

  getValidAccounts(): CapAccount[] {
    const data = localStorage.getItem('validCustomers');
    if (data) {
      return JSON.parse(data) as CapAccount[];
    } else {
      return [];
    }
  }

  setActiveCustomerId(customerId: string): void {
    localStorage.setItem('activeCustomerId', customerId);
  }

  getActiveCustomerId(): string {
    return localStorage.getItem('activeCustomerId');
  }

  setUser(userId: string): void {
    localStorage.setItem('userId', userId);
  }

  setDocumentSettings(accessKey: string, documentRoot: string) {
    localStorage.setItem('documentKeyId', accessKey);
    localStorage.setItem('documentRoot', documentRoot);
    this.documentService.init(accessKey, documentRoot);
  }

  async switchAccount(account: CapAccount, redirect = false): Promise<MatDialogRef<NotificationDialogComponent>> {
    return new Promise<MatDialogRef<NotificationDialogComponent>>(async (resolve, reject) => {
      const dialogRef = this.dialog.open(NotificationDialogComponent, {
        data: {
          message: 'Switching account...',
        },
        hasBackdrop: true,
        disableClose: true,
        minWidth: '250px',
      });
      this.setActiveCustomerId(account.CustomerId);
      this.setDocumentSettings(account.DocumentStoreAccessKeyId, account.DocumentStoreBucketName);
      this.setUser(account.UserId);
      await this.clearCache();
      this.RefreshToken().subscribe(() => {
        if (redirect) {
          this.dialogService.remove();
          resolve(dialogRef);
        } else {
          this.cache.set('reload', 'true');
          resolve(dialogRef);
        //   window.location.reload();
        }
        this.eventService.accountSwitch();
      }, () => {
        if (redirect) {
          this.dialogService.remove();
        } else {
          this.cache.set('reload', 'true');
         //  window.location.reload();
        }
        reject(dialogRef);
      });
    });
  }
  async clearCache(): Promise<void> {
    await this.cache.removeDb();
    await this.cache.clearAllExcept().toPromise();
    this.cache.clearSessionStorage();
  }

}
