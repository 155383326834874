import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPopupComponent } from './errorpopup.component';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLineModule } from '@angular/material/core';



@NgModule({
  declarations: [ErrorPopupComponent],
  imports: [
    CommonModule,
    DxPopupModule,
    DxButtonModule,
    FlexLayoutModule,
    MatLineModule,
  ],
  exports: [ErrorPopupComponent],
})
export class ErrorpopupModule { }
