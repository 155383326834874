import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { PortoResponse } from '../models/porto-response';
import { TransactionHistory } from '../models/creditcardtransactionhistory';

@Injectable()
export class CreditCardTransactionHistoryService {
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getWithPaging(skip: number, take: number): Promise<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/cc/transactionhistories`).pipe(
      map((res: any) => res)).toPromise()
      .catch(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error'));
  }

  getById(id: any): Observable<TransactionHistory> {
    return this.http.get<PortoResponse<TransactionHistory>>(`${this.settings.getBaseUrl()}/cc/transactionhistories/${id}`).pipe(
      map((res) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getByIdInternal(id: any): Observable<TransactionHistory> {
    return this.http.get<PortoResponse<TransactionHistory>>(`${this.settings.getBaseUrl()}/ccinternal/transactionhistories/${id}`).pipe(
      map((res) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/cc/transactionhistory`, data).pipe(
    map((res: any) => res),
    catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  createInternal(data: any): Observable<PortoResponse<TransactionHistory>> {
    return this.http.post(`${this.settings.getBaseUrl()}/ccinternal/transactionhistory`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  updateInternal(id: any, data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/ccinternal/transactionhistory/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/cc/transactionhistory/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

}
