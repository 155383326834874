import { concat as observableConcat, Observable } from 'rxjs';

import { filter, map, take } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AdiSettings } from '../../../models/adi-settings';
import { LookupService } from '../../../services/app-lookup.service';
import { CustomerService } from '../../../services/customer.service';
import { AdiSettingsService } from '../../../services/adisettings.service';
import { SmsTemplateMappingService } from '../../../services/smstemplatemapping.service';
import { GridUtilities } from '../../../shared/GridUtilities';
import {
  DxDataGridComponent,
  DxRadioGroupComponent,
  DxSelectBoxComponent,
  DxTextBoxComponent,
  DxValidationGroupComponent,
} from 'devextreme-angular';
import { TimePipe } from '../../../shared/time.pipe';
import { Reminderconfiguration } from '../../../models/reminderconfiguration';
import { ReminderService } from '../../../services/reminder.service';
import { ApiUtilities } from '../../../shared/ApiUtilities';
import { AppConstants } from '../../../shared/AppConstants';
import { EventService } from '../../../services/event.service';
import { CalendarService } from '../../../services/calendar-service.service';
import { BillingService } from '../../../services/billing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { YesNoConfirmationComponent } from '../../../components/controls/yes-no-confirmation/yes-no-confirmation.component';
import { NotifyService } from '../../../common/notify/notify.service';
import { CreditCardGatewayService } from '../../../services/creditcardgateways.service';
import { MatDialog } from '@angular/material/dialog';
import { GatewayDetails } from '../../../models/gateway-details';
import {ConfigureReminderComponent} from './configure-reminder/configure-reminder.component';

@Component({
  selector: 'app-adi-automated',
  templateUrl: './adi-automated.component.html',
  styleUrls: ['./adi-automated.component.scss'],
  providers: [TimePipe],
  encapsulation: ViewEncapsulation.None,
})
export class AdiAutomatedComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges {
  @ViewChild('smsTemplateGrid') smsTemplateGrid: DxDataGridComponent;
  @ViewChild('ddlReminderTemplate') ddlReminderTemplate: DxSelectBoxComponent;
  @ViewChild('dvPreview1') dvPreview1: HTMLDivElement;
  @ViewChild('dvPreview2') dvPreview2: HTMLDivElement;
  @ViewChild('txNumberPreview') txNumberPreview: DxTextBoxComponent;
  @ViewChild('txMessagePreview') txMessagePreview: DxTextBoxComponent;
  @ViewChild('previewOption') previewOption: DxRadioGroupComponent;
  @ViewChild('previewValGroup') previewValGroup: DxValidationGroupComponent;
  @ViewChild('disableConfirm') disableConfirm: YesNoConfirmationComponent;
  @ViewChild('matSlideToggle') matSlideToggle: MatSlideToggle;


  @Input() model: AdiSettings;
  @Input() appSource: 'bail' | 'defmngr' | 'collections' = 'bail';
  @Input() inDialog = false;
  @Output() onSave = new EventEmitter<boolean>();

  phoneMask = AppConstants.PHONE_MASK;
  phonePattern: any = AppConstants.PHONE_PATTERN;
  phoneRules: any = AppConstants.PHONE_RULES;

  // models and data sources
  adiSetting: AdiSettings;
  remindersChannels: any;
  reminderIntervalFrequencies: any;
  selectedNotificationTypeConfigurations = [];
  formData: any;
  authenticationTypes: any;
  errorData: any;
  timeList = [];
  callData: any = {};
  smsData: any = {};
  emailData: any = {};
  automaticNotificationData: any;
  notificationTemplates: any;
  notificationTypes: any;
  smsNotificationTypes: any;
  smsTemplates: any;
  otherActions = [];
  reminderCountOptions = [];

  // booleans and flags
  callPopupVisible = false;
  emailPopupVisible = false;
  previewPopupVisible = false;
  editReminderPopupVisible = false;
  saveButtonDisabled = false;
  isBusy = false;
  errorVisible = false;
  currentRowReady = false;
  popupTestMessageVisible = false;
  busySending = false;
  defaultNumber = '';
  isGpsCheckinDemo = false;

  // strings and captions
  errorTitle = '';
  selectedNavRoute = '';
  busyMessage = '';
  msg1Preview = '';
  msg2Preview = '';
  msgPreview = '';
  reminder2Caption = '2nd Reminder Options';
  reminder1Caption = '1st Reminder Options';
  reminderDaysCaption = 'Days before';
  editSettingsPopupCaption = 'Settings';
  // data variables
  now: Date = new Date();
  selectedReminderType: any;
  selectedNotificationType: any;
  selectedReminderTemplate: any;
  deliveryTimeIncrement: any;

  showSubscribeConfirmation = false;
  showBuyNumber = false;
  popupHeight = '250px';
  numberPurchased = false;

  showEnterCreditCardPopup = false;

  newReminderName = '';
  newReminderText = '';
  newReminderFields = [
    {
      FriendlyFieldName: 'Defendant',
      SystemFieldName: 'DefendantFullName',
    },
    {
      FriendlyFieldName: 'Location',
      SystemFieldName: 'Location',
    },
    {
      FriendlyFieldName: 'Date',
      SystemFieldName: 'EventDateMDY',
    },
    {
      FriendlyFieldName: 'Event Type',
      SystemFieldName: 'EventTypeName',
    },
  ];
  newReminderVisible = false;
  newReminderCaretPos: number;
  newReminderSaveAfterLoad = false;

  onShowChargeClasses: any;
  chargeClassDropdownVisible = false;
  chargeClasses: any;
  courtdatereasons: any;
  filterChargeClasses: any = [];
  filterCourtDateReasons: any = [];
  _chargeClassIds: any = [];
  filterChargeClassList: any = [];
  enabled = false;
  showDisableConfirmation = false;
  toggle: MatSlideToggleChange;
  reminderNumbers: any = [];
  paymentLinkSettingsVisible = false;
  paymentLinkSettings: {
    PaymentLinkEnabled: boolean;
    PaymentLinkCustomUrl: string;
    PaymentLinkCustomEnabled: string;
    PaymentLinkGateway: string;
  };
  gatewayEnabled = false;
  showPaymentGatewayAlert = false;
  gateways: GatewayDetails[];
  confirming = false;

  constructor(
    private lookupService: LookupService,
    private adiSettingsService: AdiSettingsService,
    private smsTemplateService: SmsTemplateMappingService,
    private customerService: CustomerService,
    private reminderService: ReminderService,
    private eventService: EventService,
    private calendarService: CalendarService,
    private cdr: ChangeDetectorRef,
    private billingService: BillingService,
    private router: Router,
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private creditCardGatewayService: CreditCardGatewayService,
    private dialog: MatDialog,
  ) {
    super();
    this.automaticNotificationData = [];
    this.selectedReminderTemplate = {};
    this.selectedReminderType = 'SO'; // SMS only
    this.selectedNotificationType = 'StdCheckin';
    this.save = this.save.bind(this);
    this.toggleReminder = this.toggleReminder.bind(this);
    this.onEnablePaymentLink = this.onEnablePaymentLink.bind(this);
    this.closePaymentLinkPopup = this.closePaymentLinkPopup.bind(this);
    this.openPaymentLinkSettings = this.openPaymentLinkSettings.bind(this);
    this.validateUrlRequired = this.validateUrlRequired.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.onSaveError = this.onSaveError.bind(this);

    this.subs.sink = this.eventService.dataChanged$.subscribe(resp => {
      this.subs.sink = this.loadLookups().subscribe(loadResp => {
        this.loadSettings();
      });
    });

    this.onShowChargeClasses = this.showChargeClasses.bind(this);
  }

  ngOnInit() {
    this.subs.sink = this.route.queryParams.pipe(
      filter(params => params.popup))
      .subscribe(params => {
        if (params.popup === 'testmessage') {
          this.popupTestMessageVisible = true;
          const port = (location.port) ? ':' + location.port : '';
          this.msg1Preview = `Hi, John Doe, click below to check in ${location.protocol}//${location.hostname}${port}/location/checkin/1/1/1.`;
          this.showSendTestMessage(false, { ReminderForCode: 'GpsCheckin', ReminderConfigurations: [] });
        }
      });
    if (!this.appSource) {
      this.appSource = 'bail';
    }

    this.adiSetting = new AdiSettings();
    this.subs.sink = this.creditCardGatewayService.getClientDefaultGatewayExists().subscribe(res => {
      if (res.data && res.data.length > 0) {
        this.gatewayEnabled = true;
        this.gateways = res.data;
      }
    });
    this.subs.sink = this.loadLookups().subscribe(loadResp => {
      this.loadSettings();
    });

    this.subs.sink = this.lookupService.getLookup('chargeclasses').subscribe((result) => {
      this.chargeClasses = result.data;
    });

    this.subs.sink = this.lookupService.getLookup('courtdatereasons').subscribe((result) => {
      this.courtdatereasons = result.data;
    });
  }

  ngOnChanges() {
    if (this.model !== undefined) {
      this.adiSetting = this.model;
    }
  }

  save(reminderForCode='') {
    this.showBusy('Saving, please wait');
    const reminderSetting = this.automaticNotificationData.find((data: any) => data.ReminderForCode === 'GpsCheckin').ReminderSettings;
    const courtDateSetting = this.automaticNotificationData.find((data: any) => data.ReminderForCode === 'CourtDate');
    if (!reminderForCode || reminderForCode === 'GpsCheckin') {
      reminderSetting.FilterChargeClassIds = this.filterChargeClasses && this.filterChargeClasses.length > 0 ? this.filterChargeClasses.join(',') : null;
    }
    
    courtDateSetting.CourtDateFilterReasons = this.filterCourtDateReasons && this.filterCourtDateReasons.length > 0 ? this.filterCourtDateReasons.join(',') : null;
    if (reminderSetting.Id == null) {
      this.subs.sink = this.reminderService.createReminderSetting(reminderSetting).subscribe();
    } else {
      this.subs.sink = this.reminderService.updateReminderSetting(reminderSetting.Id, reminderSetting).subscribe();
    }

    const stdReminderSetting = this.automaticNotificationData.find((data: any) => data.ReminderForCode === 'StdCheckin').ReminderSettings;
    if (stdReminderSetting.Id == null) {
      this.subs.sink = this.reminderService.createReminderSetting(stdReminderSetting).subscribe();
    } else {
      this.subs.sink = this.reminderService.updateReminderSetting(stdReminderSetting.Id, stdReminderSetting).subscribe();
    }

    if (this.appSource === 'defmngr') {
      let settingEnabled = false;
      const reminderSettings = this.getDefMngrReminderSettingsToSave();
      for (const setting of reminderSettings.data) {
        if (setting.Enabled) {
          settingEnabled = true;
        }
      }
      if (settingEnabled) {
        this.subs.sink = this.calendarService.SaveReminderSetup(reminderSettings).subscribe(resp => {
          this.loadSettings();
        });
      } else {
        this.hideBusy();
      }
    } else {
      this.getAutomaticReminderSettingsToSave();
      this.adiSetting.checkForChanges();

      if ((this.adiSetting.Id == null) || this.adiSetting.Id === '') {
        this.subs.sink = this.adiSettingsService.create(this.adiSetting).pipe(take(1)).subscribe(this.onSaveSuccess, this.onSaveError);
      } else {
        this.subs.sink = this.adiSettingsService.save(this.adiSetting).pipe(take(1)).subscribe(this.onSaveSuccess, this.onSaveError);
      }
    }
    this.editReminderPopupVisible = false;
  }

  onSaveSuccess(save) {
    this.notifyService.toast('Settings saved!');
    this.fillAdiSettingsCommon();
    this.fillAdiSettingsBailMngr(save);
    this.hideBusy();
    if (this.inDialog) {
      this.onSave.emit(true);
    }
  }

  onSaveError(error) {
    this.errorData = error;
    this.hideBusy();
  }

  initReminderConfig(code, id?, maxNumberOfReminders?) {

    let maxLen = 2;

    let interval = 2;


    switch (code) {
      case 'GpsCheckin': {
        interval = 0;
        maxLen = 1;
        break;
      }
      case 'StdCheckin': {
        interval = 0;
        maxLen = 1;
        break;
      }
      case 'CourtDate': {
        interval = 2;
        maxLen = 2;
        break;
      }
      case 'PaymentReminder': {
        maxLen = 2;
        break;
      }
      case 'MissedCheckin': {
        interval = 0;
        maxLen = 1;
        break;
      }
      case 'PastDue': {
        maxLen = 2;
        break;
      }
      case 'Event': {
        maxLen = maxNumberOfReminders;
        interval = 0;
        break;
      }
      default: {
        maxLen = 2;
      }
    }
    if (id !== undefined) {
      const configs = this.adiSetting.ReminderConfigurations.filter(x => x.NotificationTypeId === id);

      while (configs.length < maxLen) {
        const config = new Reminderconfiguration();
        config.isAdded = true;
        config.DeliveryInterval = interval;
        interval += interval;
        config.DeliveryFrequency = 'days';
        config.NotificationTypeId = id;
        configs.push(config);
        this.adiSetting.ReminderConfigurations.push(config);
      }
    } else {
      const notificationTypeId = this.notificationTypes.find(item => item.Code === code).Id;
      const configs = this.adiSetting.ReminderConfigurations
        .filter(x => x.NotificationTypeId === notificationTypeId);

      while (configs.length < maxLen) {
        const config = new Reminderconfiguration();
        config.isAdded = true;
        config.DeliveryInterval = interval;
        interval += interval;
        config.DeliveryFrequency = 'days';
        config.NotificationTypeId = this.notificationTypes.find(item => item.Code === code).Id;
        configs.push(config);
        this.adiSetting.ReminderConfigurations.push(config);
      }
    }
  }

  onEnable(toggle: MatSlideToggleChange) {
    this.toggle = toggle;
    this.isBusy = true;
    this.eventService.componentBusy(true);
    if (!this.enabled) {
      this.subs.sink = this.billingService.CustomerHasPaymentOnRecord().subscribe(paymentOnRecord => {
        if (!paymentOnRecord) {
          this.showEnterCreditCardPopup = true;
        } else {
          this.showSubscribeConfirmation = true;
        }
      });
    } else {
      this.subs.sink = this.disableConfirm.onResult
        .pipe(take(1))
        .subscribe(response => {
          this.showDisableConfirmation = false;
          if (response) {
            this.subs.sink = this.billingService.UnsubscribeCustomerFromService(AppConstants.PRODUCT_NAME_TEXT_MESSAGING)
              .pipe(take(1))
              .subscribe(() => {
                this.notifyService.toast('Reminders have been disabled');
                this.hideBusy();
                this.enabled = false;
              });
          } else {
            this.toggle.source.checked = true;
            this.enabled = true;
            this.hideBusy();
          }
        });
      this.showDisableConfirmation = true;
    }
  }

  loadLookups(): Observable<any> {
    this.remindersChannels = this.lookupService.getLookupStatic('reminderChannels');
    this.reminderIntervalFrequencies = this.lookupService.getLookupStatic('reminderIntervalFrequencies');

    const lu1 = this.lookupService.getFilteredLookup('notificationtemplates', 'MessageTypeSource', this.appSource, null, true)
      .pipe(map(resp => {
        resp.src = 'lu1';
        return resp;
      }));
    const lu2 = this.lookupService.getFilteredLookup('notificationtypes', 'NotificationTypeSource', this.appSource, 'fields', true)
      .pipe(map(resp => {
        resp.src = 'lu2';
        return resp;
      }));

    return new Observable<any>(obs => {
      this.subs.sink = observableConcat(lu2, lu1).subscribe(resp => {
        if (resp.src === 'lu1') {
          this.notificationTemplates = resp.data;
          obs.next(true);
          obs.complete();
        }
        if (resp.src === 'lu2') {
          this.notificationTypes = resp.data;
        }
      });
    });

    // this.remindersChannels = this.lookupService.getLookupStatic('reminderChannels');
    // this.reminderIntervalFrequencies = this.lookupService.getLookupStatic('reminderIntervalFrequencies');

  }

  setOtherActionsForButtonBarForDefMgr() {
    this.otherActions = [
      {
        text: 'Add New Reminder Type',
        icon: 'fal fa-plus',
        value: this.addNewReminderType.bind(this),
      },
    ];
  }

  addNewReminderType() {
    this.newReminderName = 'New Reminder';
    this.newReminderText = 'Hi {Defendant}, this is a reminder of {Event Type} on {Date}.';
    this.newReminderVisible = true;
  }

  saveReminderType() {
    this.showBusy('Saving');
    this.subs.sink = this.reminderService.saveNewEventReminderType(this.newReminderName, this.newReminderText).subscribe(() => {
        this.newReminderVisible = false;
        this.hideBusy();
        this.subs.sink = this.billingService.CustomerHasPaymentOnRecord().subscribe(paymentOnRecord => {
          if (!paymentOnRecord) {
            // this will be used in the loadSettings function
            // to trigger a save and therefore a prompt for credit card details
            // yes, I know this is a race condition, but I'm betting this one is faster
            // worst case scenario is we have a customer accruing charge w no card on file
            this.newReminderSaveAfterLoad = true;
          }
        });
        this.subs.sink = this.loadLookups().subscribe(loadResp => {
          this.loadSettings();
        });
      }, error => {
        this.hideBusy();
        this.errorData = error;
      },
    );
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      this.newReminderCaretPos = oField.selectionStart;
    }
  }

  appendMessageTermForNewReminder(term: string) {
    if (this.isBusy) {
      return false;
    }

    if (this.newReminderCaretPos === undefined) {
      this.newReminderText = this.newReminderText.trim() + ' ' + term;
    } else {
      this.newReminderText = [
        this.newReminderText.slice(0, this.newReminderCaretPos),
        term,
        this.newReminderText.slice(this.newReminderCaretPos)]
        .join('');
      this.newReminderCaretPos = this.newReminderCaretPos + term.length;
    }
  }

  showAlert() {
    if (
      this.enabled && this.adiSetting.AutomaticNotificationSetting.PaymentLinkEnabled
      && !this.gatewayEnabled && !this.adiSetting.AutomaticNotificationSetting.PaymentLinkCustomUrl
    ) {
      this.showPaymentGatewayAlert = true;
    }
  }

  loadSettings() {
    this.showBusy('Loading - Please Wait');
    this.subs.sink = this.adiSettingsService.get().pipe(
      take(1)).subscribe(data => {
      // console.log('loaded adi settings', data);
      this.fillAdiSettingsCommon();
      if (this.appSource === 'defmngr') {
        this.fillAdiSettingsDefMngr();
        this.setOtherActionsForButtonBarForDefMgr();
      } else {
        this.fillAdiSettingsBailMngr(data);
        if (this.inDialog) {
          this.otherActions = [
            {
              text: 'CLOSE',
              value: () => {
                this.onSave.emit(false);
              },
            },
          ];
        }
      }

      this.cdr.detectChanges();
      // this.adiSetting = Object.assign(new AdiSettings(), data);

      if ((this.adiSetting.CompanyName == null) || this.adiSetting.CompanyName === '') {

        this.loadCustomer();
      } else {
        this.hideBusy();
      }
      // need this hack to prompt users without credit card on file after saving new reminder..
      if (this.newReminderSaveAfterLoad) {
        this.save();
      }
    }, error => {
      this.hideBusy();

    });
  }

  initMessagePreview(data: any) {
    let tmp1 = this.notificationTemplates.find((x: any) => x.Id === data.ReminderConfigurations[0].NotificationTemplateId);
    if (!tmp1) {
      // console.log('No suitable notification template found for data.ReminderConfigurations[0].NotificationTemplateId',
      //   data.ReminderConfigurations[0].NotificationTemplateId);
      this.notifyService.toast('Error initializing message preview.  Please try again.', 'error');
    } else {
      tmp1 = tmp1.Message;
      let tmp1Custom = data.ReminderConfigurations[0].CustomText;
      if (tmp1.charAt(tmp1.length - 1) !== '.') {
        tmp1 += '.';
      }

      if (tmp1Custom == null) {
        tmp1Custom = '';
      }

      this.msg1Preview = tmp1 + ' ' + tmp1Custom;
    }

    if (data.ReminderConfigurations.filter((x: any) => x.NotificationTypeId === data.Id).length > 1) {
      let tmp2 = this.notificationTemplates.find((x: any) => x.Id === data.ReminderConfigurations[1].NotificationTemplateId).Message;
      if (!tmp2) {
        // console.log('No suitable notification template found for data.ReminderConfigurations[1].NotificationTemplateId',
        //   data.ReminderConfigurations[0].NotificationTemplateId);
        this.notifyService.toast('Error initializing message preview.  Please try again.', 'error');
      }
      let tmp2Custom = data.ReminderConfigurations[1].CustomText;

      if (tmp2.charAt(tmp2.length - 1) !== '.') {
        tmp2 += '.';
      }

      if (tmp2Custom == null) {
        tmp2Custom = '';
      }

      this.msg2Preview = tmp2 + ' ' + tmp2Custom;
    } else {
      this.msg2Preview = null;
    }
  }

  previewMessage(e, data) {
    this.formData = data;
    this.initMessagePreview(data);
    this.previewPopupVisible = true;
  }

  getDefMngrReminderSettingsToSave() {
    this.automaticNotificationData.forEach(x => {
      // just in case the lower detail have not been populated, lets re-check them
      this.initReminderConfig(x.ReminderForCode, x.Id, x.maxNumberOfReminders);
    });
    return { 'data': this.automaticNotificationData };
  }

  getAutomaticReminderSettingsToSave() {

    this.initReminderConfig('PaymentReminder');
    this.initReminderConfig('PastDue');
    this.initReminderConfig('MissedCheckin');
    this.initReminderConfig('StdCheckin');
    this.initReminderConfig('GpsCheckin');

    let notificationTypeId = this.notificationTypes.find(item => item.Code === 'CourtDate').Id;
    let tmpSetting = this.automaticNotificationData.find(x => x.Id === notificationTypeId);
    this.adiSetting.AutomaticNotificationSetting.CourtDateReminderDeliveryChannels = tmpSetting.Channels;
    this.adiSetting.AutomaticNotificationSetting.CourtDateEnabled = tmpSetting.Enabled;
    this.adiSetting.AutomaticNotificationSetting.CourtDateIncludeAllNumbers = tmpSetting.IncludeAllNumbers;
    this.adiSetting.AutomaticNotificationSetting.CourtDateIncludeIndemnitors = tmpSetting.IncludeIndemnitors;
    this.adiSetting.AutomaticNotificationSetting.CourtDateFilterReasons = tmpSetting.CourtDateFilterReasons;

    notificationTypeId = this.notificationTypes.find(item => item.Code === 'PaymentReminder').Id;
    tmpSetting = this.automaticNotificationData.find(x => x.Id === notificationTypeId);
    this.adiSetting.AutomaticNotificationSetting.PaymentReminderDeliveryChannels = tmpSetting.Channels;
    this.adiSetting.AutomaticNotificationSetting.PaymentReminderEnabled = tmpSetting.Enabled;
    this.adiSetting.AutomaticNotificationSetting.PaymentReminderIncludeAllNumbers = tmpSetting.IncludeAllNumbers;
    this.adiSetting.AutomaticNotificationSetting.PaymentReminderIncludeIndemnitors = tmpSetting.IncludeIndemnitors;
    this.adiSetting.AutomaticNotificationSetting.PaymentLinkEnabled = tmpSetting.ReminderSettings.PaymentLinkEnabled;
    this.adiSetting.AutomaticNotificationSetting.PaymentLinkCustomEnabled = tmpSetting.ReminderSettings.PaymentLinkCustomEnabled;
    this.adiSetting.AutomaticNotificationSetting.PaymentLinkCustomUrl = tmpSetting.ReminderSettings.PaymentLinkCustomUrl;
    this.adiSetting.AutomaticNotificationSetting.PaymentLinkGateway = tmpSetting.ReminderSettings.PaymentLinkGateway;

    notificationTypeId = this.notificationTypes.find(item => item.Code === 'PastDue').Id;
    tmpSetting = this.automaticNotificationData.find(x => x.Id === notificationTypeId);
    this.adiSetting.AutomaticNotificationSetting.PastDueReminderDeliveryChannels = tmpSetting.Channels;
    this.adiSetting.AutomaticNotificationSetting.PastDueEnabled = tmpSetting.Enabled;
    this.adiSetting.AutomaticNotificationSetting.PastDueIncludeAllNumbers = tmpSetting.IncludeAllNumbers;
    this.adiSetting.AutomaticNotificationSetting.PastDueIncludeIndemnitors = tmpSetting.IncludeIndemnitors;

    for (const config of this.adiSetting.ReminderConfigurations.filter(x => x.NotificationTypeId === notificationTypeId)) {
      config.PaymentLinkEnabled = tmpSetting.ReminderSettings.PaymentLinkEnabled;
      config.PaymentLinkCustomEnabled = tmpSetting.ReminderSettings.PaymentLinkCustomEnabled;
      config.PaymentLinkCustomUrl = tmpSetting.ReminderSettings.PaymentLinkCustomUrl;
      config.PaymentLinkGateway = tmpSetting.ReminderSettings.PaymentLinkGateway;
    }

    this.adiSetting.AutomaticNotificationSetting.PastDueMinimumAmount = tmpSetting.MinBalance;
    this.adiSetting.AutomaticNotificationSetting.PastDueMaximumAmount = tmpSetting.MaxBalance;
    this.adiSetting.AutomaticNotificationSetting.PastDueMinimumDaysPastDue = tmpSetting.MinDays;
    this.adiSetting.AutomaticNotificationSetting.PastDueMaximumDaysPastDue = tmpSetting.MaxDays;

    notificationTypeId = this.notificationTypes.find(item => item.Code === 'MissedCheckin').Id;
    tmpSetting = this.automaticNotificationData.find(x => x.Id === notificationTypeId);
    this.adiSetting.AutomaticNotificationSetting.MissedCheckinReminderDeliveryChannels = tmpSetting.Channels;
    this.adiSetting.AutomaticNotificationSetting.MissedCheckinEnabled = tmpSetting.Enabled;
    this.adiSetting.AutomaticNotificationSetting.MissedCheckinIncludeAllNumbers = tmpSetting.IncludeAllNumbers;
    this.adiSetting.AutomaticNotificationSetting.MissedCheckinIncludeIndemnitors = tmpSetting.IncludeIndemnitors;

    notificationTypeId = this.notificationTypes.find(item => item.Code === 'StdCheckin').Id;
    tmpSetting = this.automaticNotificationData.find(x => x.Id === notificationTypeId);
    this.adiSetting.AutomaticNotificationSetting.CheckinReminderDeliveryChannels = tmpSetting.Channels;
    this.adiSetting.AutomaticNotificationSetting.CheckinEnabled = tmpSetting.Enabled;
    this.adiSetting.AutomaticNotificationSetting.CheckinIncludeAllNumbers = tmpSetting.IncludeAllNumbers;

    notificationTypeId = this.notificationTypes.find(item => item.Code === 'GpsCheckin').Id;
    tmpSetting = this.automaticNotificationData.find(x => x.Id === notificationTypeId);
    this.adiSetting.AutomaticNotificationSetting.CheckinReminderGpsDeliveryChannels = tmpSetting.Channels;
    this.adiSetting.AutomaticNotificationSetting.CheckinGpsEnabled = tmpSetting.Enabled;
    this.adiSetting.AutomaticNotificationSetting.CheckinGpsIncludeAllNumbers = tmpSetting.IncludeAllNumbers;
    this.adiSetting.AutomaticNotificationSetting.CheckInScheduleShowCourtInfo = tmpSetting.ShowCourtInfo;
    this.adiSetting.AutomaticNotificationSetting.CheckInScheduleShowAccounting = tmpSetting.ShowAccounting;

  }

  fillAdiSettingsCommon() {
    this.automaticNotificationData = [];
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_TEXT_MESSAGING, true).subscribe(subbed => {
      this.enabled = subbed;
      // If they are subscribed we need to check if they have a number.
      if (subbed) {
        this.subs.sink = this.getReminderNumber()
          .pipe(take(1))
          .subscribe(hasNumber => {
            if (!hasNumber) {
              this.showBuyNumber = true;
              this.showSubscribeConfirmation = true;
            }
          });
      }
      if (this.appSource === 'bail') {
        this.showAlert();
      }
    });
  }

  fillAdiSettingsDefMngr() {

    this.subs.sink = this.calendarService.GetEventTypesWithReminderSettings().subscribe(resp => {
      const eventTypes = resp.data;


      // now lets add the required client sod model date to make it work with reminder settings screen
      eventTypes.forEach(el => {
        this.initReminderConfig(el.ReminderForCode, el.Id, el.MaxReminderCount);
        if (el.reminderSettings.data.length === 0) {
          el.reminderSettings.data = [{
            'Id': '',
            'Enabled': false,
            'IncludeAllNumbers': false,
            'IncludeIndemnitors': false,
            'ExcludeForfeitures': false,
            'EventTypeId': el.Id,
            'ReminderConfigurations': { data: this.adiSetting.ReminderConfigurations.filter(x => x.NotificationTypeId === el.Id) },

          }];
        }

        if (el.ReminderForCode === 'GpsCheckin') {
          let reminderSetting = el.reminderSettings.data[0];
          // console.log('found reminder settings: ', reminderSetting);
          if (!reminderSetting) {
            reminderSetting = {
              'Id': null,
              'FacialRecognition': false,
              'FilterChargeClassIds': null,
              'EventTypeId': el.Id,
            };
          }
          this.automaticNotificationData.push({
            'Id': el.reminderSettings.data[0].Id,
            'EventTypeId': el.Id,
            'ReminderFor': el.Name,
            'ReminderForCode': el.ReminderForCode,
            'Channels': 'SMS', // todo later on we add other delivery channels
            'Enabled': el.reminderSettings.data[0].Enabled,
            'IncludeAllNumbers': el.reminderSettings.data.length > 0 ? el.reminderSettings.data[0].IncludeAllNumbers : false,
            'IncludeIndemnitors': el.reminderSettings.data.length > 0 ? el.reminderSettings.data[0].IncludeIndemnitors : false,
            'ExcludeForfeitures': el.reminderSettings.data.length > 0 ? el.reminderSettings.data[0].ExcludeForfeitures : false,
            'ReminderConfigurations': el.reminderSettings.data.length > 0 ? el.reminderSettings.data[0].reminderConfigurations.data : [],
            'ChannelsArray': ['SMS'],
            'MaxSmsLength': 500,
            'AllowCustomText': true,
            'CanScheduleReminder': el.CanScheduleReminder,
            'maxNumberOfReminders': el.MaxReminderCount,
            'showIndemnitorOption': el.AllowIndemnitors,
            'ReminderSettings': reminderSetting,
          });
        } else {
          this.automaticNotificationData.push({
            'Id': el.reminderSettings.data[0].Id,
            'EventTypeId': el.Id,
            'ReminderFor': el.Name,
            'ReminderForCode': el.ReminderForCode,
            'Channels': 'SMS', // todo later on we add other delivery channels
            'Enabled': el.reminderSettings.data[0].Enabled,
            'IncludeAllNumbers': el.reminderSettings.data.length > 0 ? el.reminderSettings.data[0].IncludeAllNumbers : false,
            'IncludeIndemnitors': el.reminderSettings.data.length > 0 ? el.reminderSettings.data[0].IncludeIndemnitors : false,
            'ExcludeForfeitures': el.reminderSettings.data.length > 0 ? el.reminderSettings.data[0].ExcludeForfeitures : false,
            'ReminderConfigurations': el.reminderSettings.data.length > 0 ? el.reminderSettings.data[0].reminderConfigurations.data : [],
            'ChannelsArray': ['SMS'],
            'MaxSmsLength': 500,
            'AllowCustomText': true,
            'CanScheduleReminder': el.CanScheduleReminder,
            'maxNumberOfReminders': el.MaxReminderCount,
            'showIndemnitorOption': el.AllowIndemnitors,
          });
        }
      });

      // console.log('fillAdiSettingsDefMngr: automaticNotificationData: ', this.automaticNotificationData);
    });
  }

  fillAdiSettingsBailMngr(data) {
    if (data != null && data.data != null && data.data.length > 0) {
      this.adiSetting = new AdiSettings(data.data[0]);


      this.initReminderConfig('PaymentReminder');
      this.initReminderConfig('PastDue');
      this.initReminderConfig('CourtDate');
      this.initReminderConfig('MissedCheckin');
      this.initReminderConfig('StdCheckin');
      this.initReminderConfig('GpsCheckin');

      this.automaticNotificationData.push({
        'Id': this.notificationTypes.find(item => item.Code === 'CourtDate').Id,
        'ReminderFor': 'Court Date',
        'ReminderForCode': 'CourtDate',
        'Channels': this.adiSetting.AutomaticNotificationSetting.CourtDateReminderDeliveryChannels,
        'Enabled': this.adiSetting.AutomaticNotificationSetting.CourtDateEnabled,
        'IncludeAllNumbers': this.adiSetting.AutomaticNotificationSetting.CourtDateIncludeAllNumbers,
        'IncludeIndemnitors': this.adiSetting.AutomaticNotificationSetting.CourtDateIncludeIndemnitors,
        'ReminderConfigurations': this.adiSetting.ReminderConfigurations
        .filter(x => x.NotificationTypeId === this.notificationTypes.find(item => item.Code === 'CourtDate').Id),
        'ChannelsArray': (this.adiSetting.AutomaticNotificationSetting.CourtDateReminderDeliveryChannels == null) ? [] :
        this.adiSetting.AutomaticNotificationSetting.CourtDateReminderDeliveryChannels.split(','),
        'CourtDateFilterReasons': (this.adiSetting.AutomaticNotificationSetting.CourtDateFilterReasons == null) ? [] :
        this.adiSetting.AutomaticNotificationSetting.CourtDateFilterReasons.split(','),
        'MaxSmsLength': 500,
        'AllowCustomText': true,
        'CanScheduleReminder': true,
        'maxNumberOfReminders': 2,
        'showIndemnitorOption': true,
      });

      this.automaticNotificationData.push({
        'Id': this.notificationTypes.find(item => item.Code === 'MissedCheckin').Id, // 'MissedCheckinReminder',
        'ReminderFor': 'Missed Check In',
        'ReminderForCode': 'MissedCheckin',
        'Channels': this.adiSetting.AutomaticNotificationSetting.MissedCheckinReminderDeliveryChannels,
        'Enabled': this.adiSetting.AutomaticNotificationSetting.MissedCheckinEnabled,
        'IncludeAllNumbers': this.adiSetting.AutomaticNotificationSetting.MissedCheckinIncludeAllNumbers,
        'IncludeIndemnitors': this.adiSetting.AutomaticNotificationSetting.MissedCheckinIncludeIndemnitors,
        'ReminderConfigurations': this.adiSetting.ReminderConfigurations
          .filter(x => x.NotificationTypeId === this.notificationTypes.find(item => item.Code === 'MissedCheckin').Id),
        'ChannelsArray': (this.adiSetting.AutomaticNotificationSetting.MissedCheckinReminderDeliveryChannels == null) ? [] :
          this.adiSetting.AutomaticNotificationSetting.MissedCheckinReminderDeliveryChannels.split(','),
        'MaxSmsLength': 500,
        'AllowCustomText': true,
        'CanScheduleReminder': true,
        'maxNumberOfReminders': 1,
        'showIndemnitorOption': true,
      });
      this.subs.sink = this.calendarService.GetEventTypesWithReminderSettings().subscribe((res) => {
        let stdCheckinSetting = res.data.find(type => type.ReminderForCode === 'StdCheckin').reminderSettings.data[0];
        this.automaticNotificationData.push({
          'Id': this.notificationTypes.find(item => item.Code === 'StdCheckin').Id, // 'ReminderCheckin',
          'ReminderFor': 'Basic Check In Reminder',
          'ReminderForCode': 'StdCheckin',
          'Channels': this.adiSetting.AutomaticNotificationSetting.CheckinReminderDeliveryChannels,
          'Enabled': this.adiSetting.AutomaticNotificationSetting.CheckinEnabled,
          'IncludeAllNumbers': this.adiSetting.AutomaticNotificationSetting.CheckinIncludeAllNumbers,
          'ReminderConfigurations': this.adiSetting.ReminderConfigurations
            .filter(x => x.NotificationTypeId === this.notificationTypes.find(item => item.Code === 'StdCheckin').Id),
          'ChannelsArray': (this.adiSetting.AutomaticNotificationSetting.CheckinReminderDeliveryChannels == null) ? [] :
            this.adiSetting.AutomaticNotificationSetting.CheckinReminderDeliveryChannels.split(','),
          'MaxSmsLength': 160,
          'AllowCustomText': false,
          'CanScheduleReminder': false,
          'maxNumberOfReminders': 1,
          'showIndemnitorOption': false,
          'ReminderSettings': stdCheckinSetting,
        });

        let reminderSetting = res.data.find(type => type.ReminderForCode === 'GpsCheckin').reminderSettings.data[0];
        // console.log('found reminder settings: ', reminderSetting);
        if (!reminderSetting) {
          reminderSetting = {
            Id: null,
            FacialRecognition: false,
            FilterChargeClassIds: null,
            EventTypeId: res.data.find(type => type.ReminderForCode === 'GpsCheckin').Id,
          };
        }

        this.automaticNotificationData.push({
          'Id': this.notificationTypes.find(item => item.Code === 'GpsCheckin').Id, // 'ReminderCheckin',
          'ReminderFor': 'GPS Check In With Identity Verification',
          'ReminderForCode': 'GpsCheckin',
          'Channels': this.adiSetting.AutomaticNotificationSetting.CheckinReminderGpsDeliveryChannels,
          'Enabled': this.adiSetting.AutomaticNotificationSetting.CheckinGpsEnabled,
          'IncludeAllNumbers': this.adiSetting.AutomaticNotificationSetting.CheckinGpsIncludeAllNumbers,
          'ShowCourtInfo': this.adiSetting.AutomaticNotificationSetting.CheckInScheduleShowCourtInfo,
          'ShowAccounting': this.adiSetting.AutomaticNotificationSetting.CheckInScheduleShowAccounting,
          'ReminderConfigurations': this.adiSetting.ReminderConfigurations
            .filter(x => x.NotificationTypeId === this.notificationTypes.find(item => item.Code === 'GpsCheckin').Id),
          'ChannelsArray': (this.adiSetting.AutomaticNotificationSetting.CheckinReminderGpsDeliveryChannels == null) ? [] :
            this.adiSetting.AutomaticNotificationSetting.CheckinReminderGpsDeliveryChannels.split(','),
          'MaxSmsLength': 160,
          'AllowCustomText': false,
          'CanScheduleReminder': false,
          'maxNumberOfReminders': 1,
          'showIndemnitorOption': false,
          'ReminderSettings': reminderSetting,
        });

      });
      this.automaticNotificationData.push({
        'Id': this.notificationTypes.find(item => item.Code === 'PaymentReminder').Id, // 'PaymentReminder',
        'ReminderFor': 'Upcoming Payment',
        'ReminderForCode': 'PaymentReminder',
        'Channels': this.adiSetting.AutomaticNotificationSetting.PaymentReminderDeliveryChannels,
        'Enabled': this.adiSetting.AutomaticNotificationSetting.PaymentReminderEnabled,
        'IncludeAllNumbers': this.adiSetting.AutomaticNotificationSetting.PaymentReminderIncludeAllNumbers,
        'IncludeIndemnitors': this.adiSetting.AutomaticNotificationSetting.PaymentReminderIncludeIndemnitors,
        'ReminderConfigurations': this.adiSetting.ReminderConfigurations
          .filter(x => x.NotificationTypeId === this.notificationTypes.find(item => item.Code === 'PaymentReminder').Id),
        'ChannelsArray': (this.adiSetting.AutomaticNotificationSetting.PaymentReminderDeliveryChannels == null) ? [] :
          this.adiSetting.AutomaticNotificationSetting.PaymentReminderDeliveryChannels.split(','),
        'MaxSmsLength': 500,
        'AllowCustomText': true,
        'CanScheduleReminder': true,
        'maxNumberOfReminders': 2,
        'showIndemnitorOption': true,
        ReminderSettings: {
          PaymentLinkEnabled: this.adiSetting.AutomaticNotificationSetting.PaymentLinkEnabled,
          PaymentLinkCustomUrl: this.adiSetting.AutomaticNotificationSetting.PaymentLinkCustomUrl,
          PaymentLinkCustomEnabled: this.adiSetting.AutomaticNotificationSetting.PaymentLinkCustomEnabled,
          PaymentLinkGateway: this.adiSetting.AutomaticNotificationSetting.PaymentLinkGateway,
        },
      });
      const pastDueConfig = this.adiSetting.ReminderConfigurations
        .filter(x => x.NotificationTypeId === this.notificationTypes.find(item => item.Code === 'PastDue').Id);
      this.automaticNotificationData.push({
        'Id': this.notificationTypes.find(item => item.Code === 'PastDue').Id, // 'PastDueReminder',
        'ReminderFor': 'Overdue Balance',
        'ReminderForCode': 'PastDue',
        'Channels': this.adiSetting.AutomaticNotificationSetting.PastDueReminderDeliveryChannels,
        'Enabled': this.adiSetting.AutomaticNotificationSetting.PastDueEnabled,
        'IncludeAllNumbers': this.adiSetting.AutomaticNotificationSetting.PastDueIncludeAllNumbers,
        'IncludeIndemnitors': this.adiSetting.AutomaticNotificationSetting.PastDueIncludeIndemnitors,
        'MinBalance': this.adiSetting.AutomaticNotificationSetting.PastDueMinimumAmount,
        'MaxBalance': this.adiSetting.AutomaticNotificationSetting.PastDueMaximumAmount,
        'MinDays': this.adiSetting.AutomaticNotificationSetting.PastDueMinimumDaysPastDue,
        'MaxDays': this.adiSetting.AutomaticNotificationSetting.PastDueMaximumDaysPastDue,
        'ReminderConfigurations': pastDueConfig,
        'ChannelsArray': (this.adiSetting.AutomaticNotificationSetting.PastDueReminderDeliveryChannels == null) ? [] :
          this.adiSetting.AutomaticNotificationSetting.PastDueReminderDeliveryChannels.split(','),
        'MaxSmsLength': 500,
        'AllowCustomText': true,
        'CanScheduleReminder': true,
        'maxNumberOfReminders': 2,
        'showIndemnitorOption': true,
        ReminderSettings: {
          PaymentLinkEnabled: pastDueConfig[0].PaymentLinkEnabled,
          PaymentLinkCustomUrl: pastDueConfig[0].PaymentLinkCustomUrl,
          PaymentLinkCustomEnabled: pastDueConfig[0].PaymentLinkCustomEnabled,
          PaymentLinkGateway: pastDueConfig[0].PaymentLinkGateway,
        },
      });
    }
  }

  onfieldDataChanged(e, pFormData) {
    if (e.dataField === 'ChannelsArray') {
      const tmpChannels = [];

      e.value.forEach(el => {
        tmpChannels.push(this.remindersChannels.find(x => x.Id === el).Text);
      });
      pFormData.Channels = tmpChannels.join(',');
    } else if (e.dataField === 'Enabled') {
      if (pFormData.ReminderForCode === 'StdCheckin' || pFormData.ReminderForCode === 'GpsCheckin') {
        this.toggleReminder(e, pFormData.ReminderForCode);
      }
    }
  }

  loadCustomer() {
    this.subs.sink = this.customerService.getCurrent().pipe(take(1)).subscribe(c => {
      if ((c != null) && (c.data != null) && c.data.length > 0 && (c.data[0] != null) &&
        (c.data[0].Name != null)) {
        this.adiSetting.CompanyName = c.data[0].Name;
        this.hideBusy();
      }
    });
  }

  onEnablePaymentLink({ value, previousValue }) {
    if (value) {
      const setting = this.automaticNotificationData.find(x => x.ReminderForCode === this.formData.ReminderForCode);
      this.paymentLinkSettings = { ... setting.ReminderSettings };
      this.paymentLinkSettingsVisible = true;
    }
  }

  openPaymentLinkSettings(): void {
    const setting = this.automaticNotificationData.find(x => x.ReminderForCode === this.formData.ReminderForCode);
    this.paymentLinkSettings = { ... setting.ReminderSettings };
    this.paymentLinkSettingsVisible = true;
  }

  closePaymentLinkPopup(event) {
    const result = event.validationGroup.validate();
    if (result.isValid) {
      this.paymentLinkSettingsVisible = false;
    }
  }

  validateUrlRequired({ value }) {
    // The URL is only required if the Custom Payment Link is enabled
    if (this.paymentLinkSettings.PaymentLinkCustomEnabled) {
      if (value != null) {
        try {
          const url = new URL(value);
          return true;
        } catch (e) {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  paymentLinkSettingsOptionSelected(event: any) {
    const setting = this.automaticNotificationData.find(x => x.ReminderForCode === this.formData.ReminderForCode);
    setting.ReminderSettings = this.paymentLinkSettings;
  }

  showBusy(message) {
    this.busyMessage = message;
    this.isBusy = true;
    this.eventService.componentBusy(true);
  }

  hideBusy() {
    this.busyMessage = '';
    this.isBusy = false;
    this.eventService.componentBusy(false);
    this.confirming = false;
  }

  activateCCProcessing() {
    this.eventService.showSnackbar('Open payment processing application?', false,
      'START', ['open-nuvei-snack'], this.openPaymentProcessing);
  }

  openPaymentProcessing() {
    window.open(AppConstants.NUVEI_APPLICATION, '_blank');
  }

  editSettings(data) {
    this.formData = {};
    this.formData = data;
    switch (this.formData.ReminderForCode) {
      case 'CourtDate' : {
        this.editSettingsPopupCaption = 'Court Date Reminder Settings';
        this.reminder1Caption = '1st Reminder';
        this.reminder2Caption = '2nd Reminder';
        this.reminderDaysCaption = 'Send before';
        this.filterCourtDateReasons = this.formData.CourtDateFilterReasons;
        break;
      }
      case 'PaymentReminder' : {
        this.editSettingsPopupCaption = 'Upcoming Payment Reminder Settings';
        this.reminder1Caption = '1st Reminder';
        this.reminder2Caption = '2nd Reminder';
        this.reminderDaysCaption = 'Send before';
        break;
      }
      case 'PastDue' : {
        this.editSettingsPopupCaption = 'Overdue Amounts Reminder Settings';
        this.reminder1Caption = '1st Reminder After Min. Days';
        this.reminder2Caption = '2nd Reminder After Min. Days';
        this.reminderDaysCaption = 'Send after';
        break;
      }
      case 'MissedCheckin' : {
        this.editSettingsPopupCaption = 'Missed Check In Reminder Settings';
        this.reminder1Caption = '1st Reminder';
        this.reminder2Caption = '2nd Reminder';
        this.reminderDaysCaption = 'Send after';
        break;
      }
      case 'StdCheckin' : {
        this.editSettingsPopupCaption = 'Basic Check In Reminder Settings';
        this.reminder1Caption = '';
        this.reminder2Caption = '';
        this.reminderDaysCaption = 'Send on';
        break;
      }
      case 'GpsCheckin' : {
        this.editSettingsPopupCaption = 'GPS Check In Reminder Settings';
        this.reminder1Caption = '';
        this.reminder2Caption = '';
        this.reminderDaysCaption = 'Send on';
        this.filterChargeClasses = this.formData.ReminderSettings.FilterChargeClassIds ?
          this.formData.ReminderSettings.FilterChargeClassIds.split(',') : [];
        this.formData.sendToChargeClasses = this.filterChargeClasses.length > 0;
        this.chargeClassDropdownVisible = this.formData.sendToChargeClasses;
        break;
      }
      default: {
        this.editSettingsPopupCaption = this.formData.ReminderFor + ' Settings';
        if (this.formData.ReminderConfigurations.length > 0) {
          this.reminder1Caption = '1st Reminder';
        }

        if (this.formData.ReminderConfigurations.length > 1 || this.formData.maxNumberOfReminders > 1) {
          this.reminder2Caption = '2nd Reminder';
        }

        this.reminderDaysCaption = 'Send before';
        break;
      }
    }
    // for some reason when we open other reminders it sometimes result ins checkings having > 1 reminderconfigurations
    // so here we just make sure if that happened, we trim the array back to the correct length
    while (this.formData.ReminderConfigurations.length > this.formData.maxNumberOfReminders) {
      this.formData.ReminderConfigurations.pop();
    }

    const notificationData = this.automaticNotificationData.find(x => x.ReminderForCode === this.formData.ReminderForCode);
    while (notificationData.ReminderConfigurations.length > this.formData.maxNumberOfReminders) {
      this.automaticNotificationData.find(x => x.ReminderForCode === this.formData.ReminderForCode).ReminderConfigurations.pop();
    }

    this.currentRowReady = true;
    this.editReminderPopupVisible = true;
  }

  cancelReminderConfig() {
    this.editReminderPopupVisible = false;
  }

  checkNumberValid = (options: any) => {
    if (options.value == null || options.value === '') {
      options.rule.message = 'Enter a number';
      return false;
    }
    return true;
  }

  showSendTestMessage(e, data) {
    // console.log(data);
    this.isGpsCheckinDemo = data.ReminderForCode === 'GpsCheckin';
    if (e !== false) {
      this.initMessagePreview(data);
    }
    this.reminderCountOptions = [];
    this.reminderCountOptions.push('1st Reminder');

    if (data.ReminderConfigurations.length > 1) {
      this.reminderCountOptions.push('2nd Reminder');
    }

    this.msgPreview = this.msg1Preview;


    this.popupTestMessageVisible = true;
    this.formData = data;
    if (this.previewOption) {
      this.previewOption.value = this.reminderCountOptions[0];
    }

  }

  TestMessageOptionChanged(e) {
    if (e.value === '1st Reminder') {
      this.msgPreview = this.msg1Preview;
    } else if (e.value === '2nd Reminder') {
      this.msgPreview = this.msg2Preview;
    }
  }

  sendTestMessage() {
    if (this.previewValGroup.instance.validate().isValid) {
      this.busySending = true;
      const tmpNumber = this.txNumberPreview.value;
      this.subs.sink = this.reminderService.sendTestSms(tmpNumber, this.msgPreview).subscribe(resp => {
        this.notifyService.toast('Test message sent');
        this.busySending = false;

        this.popupTestMessageVisible = false;
      }, smsErr => {
        if (smsErr.data != null || smsErr.message != null) {
          this.notifyService.toast(smsErr.data == null ? smsErr.message : smsErr.data.message, 'error');
        }
        this.showError(smsErr);
        this.busySending = false;
        this.popupTestMessageVisible = false;
      });
    }
  }

  toggleReminder(e, whichOne) {
    if (whichOne === 'GpsCheckin' && e.value) {
      const setting = this.automaticNotificationData.find(x => x.ReminderForCode === 'StdCheckin');
      if (setting.Enabled) {
        this.notifyService.toast('Normal Check-In replaced with enhanced GPS Check-In', 'success');
        setting.Enabled = false;
      }
    } else if (whichOne === 'StdCheckin' && e.value) {
      const setting = this.automaticNotificationData.find(x => x.ReminderForCode === 'GpsCheckin');
      if (setting.Enabled) {
        this.notifyService.toast('Enhanced GPS Check-In replaced with Normal Check-In', 'success');
        setting.Enabled = false;
      }
    } else if (whichOne === 'PaymentReminder' && e.value) {
      // If they enable Upcoming payment & have a gateway then enable Payment Links automatically
      const setting = this.automaticNotificationData.find(x => x.ReminderForCode === 'PaymentReminder');
      if (this.gatewayEnabled) {
        setting.ReminderSettings.PaymentLinkEnabled = true;
        this.openPaymentLinkSettings();
      }
    }
  }

  showError(errorInfo: any) {
    if (this.errorData == null) {
      this.errorData = [];
    } else {
      this.errorData.length = 0;
    }

    if (errorInfo == null) {
      this.errorData = [];
    } else {
      if (errorInfo.message != null) {
        this.errorData = this.errorData.concat(errorInfo);
      } else if (errorInfo.error == null) {
        this.errorData = this.errorData.concat(ApiUtilities.getErrorMessage(errorInfo, true));
      } else {
        if (errorInfo.error.errors == null) {
          this.errorData = this.errorData.concat(ApiUtilities.getErrorMessage(errorInfo, true));
        } else {
          this.errorData = this.errorData.concat(GridUtilities.getErrorItems(errorInfo.error.errors));
        }
      }
    }
    this.errorVisible = true;
  }

  subscribeToReminders(): Observable<boolean> {
    return new Observable(sub => {
      this.notifyService.toast('Subscribing to Reminder Service, please wait...');
      this.subs.sink = this.billingService.SubscribeCustomerToService(AppConstants.PRODUCT_NAME_TEXT_MESSAGING)
        .subscribe(
          () => {
             this.notifyService.toast('You are now subscribed to Reminder Services!  Saving...', 'success');
             this.eventService.emitAdviceClose(true);
            sub.next(true);
          },
          (error) => {
            this.notifyService.toast('An error occurred while subscribing, please try again.', 'error');
            console.error('error subscribing to reminder', error);
            sub.error(false);
          });
    });
  }

  /**
   * getReminderNumber checks if the user has a number assigned to their account.
   * It returns true if they do have a number and if they don't it returns false
   */
  getReminderNumber(): Observable<boolean> {
    return new Observable(sub => {
      this.subs.sink = this.reminderService.getReminderNumbers().subscribe(res => {
        this.reminderNumbers = res.data;
        if (this.reminderNumbers && this.reminderNumbers.length === 0) {
          sub.next(false);
        } else {
          sub.next(true);
        }
      });
    });
  }

  YesNoConfirmResult(result: boolean) {
    this.confirming = true;
    if (result) {
      this.subs.sink = this.subscribeToReminders()
        .pipe(take(1))
        .subscribe(
          (subscribed: boolean) => {
            if (subscribed) {
              // The same two tines of code is used on line
              this.notifyService.toast('You are now subscribed to Reminder Services!  Saving...', 'success');
              this.eventService.emitAdviceClose(true);
              this.subs.sink = this.getReminderNumber()
                .pipe(take(1))
                .subscribe(hasNumber => {
                  if (hasNumber) {
                    this.notifyService.toast('Reminders enabled successfully');
                    this.save();
                    this.hideBusy();
                    this.showEnterCreditCardPopup = false;
                    this.showSubscribeConfirmation = false;
                  } else {
                    this.showBuyNumber = true;
                    this.save();
                  }
                });
            }
          },
          () => {
            this.notifyService.toast('An error occurred while subscribing, please try again.', 'error');
          });
    } else {
      this.toggle.source.checked = false;
      this.showEnterCreditCardPopup = false;
      this.showSubscribeConfirmation = false;
      this.hideBusy();
    }
    this.enabled = result;
  }

  numberBought() {
    this.numberPurchased = true;
    this.showSubscribeConfirmation = false;
  }

  subscribePopupHidden() {
    this.showBuyNumber = false;
  }

  createDemoMessage() {
    const port = (location.port) ? ':' + location.port : '';
    const demoFields = [{
      'fieldName': '{Defendant}',
      'fieldValue': 'John Doe',
    }, {
      'fieldName': '{CheckinUrl}',
      'fieldValue': `${location.protocol}//${location.hostname}${port}/location/checkin/1/1/1`,
    },
    ];

    demoFields.forEach(field => {
      this.msgPreview = this.msgPreview.replace(field.fieldName, field.fieldValue);
    });
  }

  sendUserToSignupForDefMgrAndAutomatedReminders(e) {
    if (e) {
      this.showEnterCreditCardPopup = false;
      let product = AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM;
      if (this.appSource === 'defmngr') {
        product = AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER;
      }
      sessionStorage.setItem('AddNotifications', 'true');
      this.router.navigate(['/getstarted/checkout'],
        {
          queryParams: {
            'returnUrl': this.router.routerState.snapshot.url,
            'productids': product,
          },
        });
    } else {
      // Remove popup and busy if user cancels
      this.toggle.source.checked = false;
      this.showEnterCreditCardPopup = false;
      this.showSubscribeConfirmation = false;
      this.hideBusy();
    }
  }

  showChargeClasses(e) {
    this.chargeClassDropdownVisible = e.value;
    if (!this.chargeClassDropdownVisible) {
      this.filterChargeClasses = [];
      this.filterChargeClassIds = [];
    }
    this.cdr.detectChanges();
  }

  get filterChargeClassIds(): any[] {
    return this.filterChargeClassList;
  }

  set filterChargeClassIds(value: any[]) {
    const self = this;
    let selectedCharge;
    if (!!value && !!this.formData.FilterChargeClassIds) {
      this.formData.FilterChargeClassIds.split(',').forEach(function (id) {
        selectedCharge = self.chargeClasses.filter(charge => charge.Id === id)[0];
        if (!!selectedCharge) {
          value.push(selectedCharge);
        }
      });
    }
    this.filterChargeClassList = value || [];
    if (!!value) {
      this.filterChargeClasses = value.map(v => v.Id);
    }
  }

  onChargeClassSelectionChanged(e) {
    if (e.selectedRowKeys.length === 0) {
      // this.currentRow.LimitAgentIds = '';
      this.filterChargeClasses = [];
      this.filterChargeClassIds = [];
    }
  }

  onCourtDateReasonsSelectionChanged(e) {
    if (e.selectedRowKeys.length === 0) {
      this.filterCourtDateReasons = [];
    } else {
      this.filterCourtDateReasons = e.selectedRowKeys;
    }
  }

  getPopupHeight() {
    if (this.showBuyNumber) {
      this.popupHeight = '550px';
    } else {
      this.popupHeight = '250px';
    }
    return this.popupHeight;
  }


  formatSliderLabel(sliderValue) {
    return `${sliderValue}%`;
  }

  getSelectedRowKeys(): string[] {
    return this.filterCourtDateReasons;
  }

  openTimeSlotDialog() {
    this.dialog.open(ConfigureReminderComponent, {
      width: '450px',
      height: '220px',
      disableClose: false,
    });
  }
}
