<div cdkDrag cdkDragRootElement=".draggable-dialog">
<h2 mat-dialog-title>Notes</h2>
<div mat-dialog-content>
  <dx-data-grid
    #noteGrid
    [dataSource]='notes'
    [columnAutoWidth]='true'
    [columnHidingEnabled]='true'
    (onToolbarPreparing)='onToolbarPreparing($event)'
    (onCellPrepared)='onCellPrepared($event)'
    (onInitNewRow)='handleInitNewRow()'
    (onEditingStart)='handleEditingStart()'
    (onRowInserted)='handleRowInserted($event)'
    (onRowUpdated)='handleRowUpdated($event)'
    (onRowRemoved)='handleRowRemoved($event)'
    keyExpr='Id'
    rowAlternationEnabled='true'
    noDataText='No records. Click + above to add'
    wordWrapEnabled='true'>

    <dxo-scrolling mode='infinite'></dxo-scrolling>

    <dxi-column
      [visible]='true'
      [allowEditing]='false'
      [hidingPriority]='0'
      caption='Date'
      [tabIndex]='0'
      dataField='NoteStamp'
      dataType='date'
      format='M/d/yyyy h:mm a'
      sortOrder='desc'>
    </dxi-column>

    <dxi-column
      [hidingPriority]='0'
      [tabIndex]='-1'
      [visible]='true'
      [allowEditing]='false'
      caption='User'
      dataField='User'
      cellTemplate="userNameTemplate"
      dataType='string'>
      <div *dxTemplate="let data of 'userNameTemplate'">
        {{data?.data?.user?.data?.identity?.data?.FirstName}} {{data?.data?.user?.data?.identity?.data?.LastName}}
      </div>
    </dxi-column>
    <dxi-column
      [hidingPriority]='3'
      [tabIndex]='0'
      [visible]='true'
      caption='Subject'
      dataField='Subject'
      dataType='string'>
      <dxi-validation-rule type='stringLength'
                           min='0'
                           max='50'
                           message='Maximum Length Is 50'>
      </dxi-validation-rule>
    </dxi-column>
    <dxi-column
      [hidingPriority]='2'
      tabIndex='0'
      [visible]='true'
      caption='Note Information'
      dataField='Text'
      dataType='string'>
      <dxi-validation-rule type='stringLength'
                           min='0'
                           max='2000'
                           message='Maximum Length Is 2000'>
      </dxi-validation-rule>
    </dxi-column>

    <dxo-editing [allowUpdating]='!isDisabled'
                 [allowAdding]='true'
                 [allowDeleting]='!isDisabled'
                 [useIcons]='true'
                 mode='popup'>
      <dxo-popup [showTitle]='true'
                 [width]='700'
                 [height]='525'
                 [title]='popupTitle'>
      </dxo-popup>
      <dxo-form>
        <dxi-item [colCount]="1"
                  [colSpan]="2"
                  itemType="group">
          <dxi-item [colSpan]="2"
                    [editorOptions]="{ maxLength: 50 }"
                    dataField="Subject"
                    editorType="dxTextBox">
          </dxi-item>
          <dxi-item [colSpan]="2"
                    [editorOptions]="{ height: 100 }"
                    dataField="Text"
                    editorType="dxTextArea">
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>
  </dx-data-grid>
</div>
<div mat-dialog-actions align='end' fxLayout='row' fxLayout.xs='column' fxLayoutAlign='end end'
     fxLayoutAlign.xs='end stretch' fxLayoutGap='8px'>
  <button [disabled]='isDisabled' mat-raised-button color='primary' (click)='handleComplete(true)'>Ok</button>
</div>
</div>
