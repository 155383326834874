import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { AppConstants } from '../../../shared/AppConstants';
import { UserService } from '../../../services/user.service';
import { CapAccount, BillingService } from '../../../services/billing.service';
import { SystemConfigService } from '../../../services/systemconfig.service';
import { PermissionService } from '../../../services/permission.service';
import { StorageService } from '../../../services/storage.service';
import { environment } from '../../../../environments/environment';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { EventService } from '../../../services/event.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {SystemConfig} from '../../../models/systemconfig';
import { AuthService } from '../../../services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningDialogComponent } from '../../controls/warning-dialog/warning-dialog.component';
import { NotificationDialogComponent } from '../../controls/notification-dialog/notification-dialog.component';
import { DefendantComponent } from '../../../views/defendant/defendant.component';
import { take } from 'rxjs/operators';

type DefendantLink = '/defendant/data-capture' | '/defendant/wizard';

export interface NavTopLink {
  heading: string;
  icon: string;
  link: string;
  subscribed: boolean;
  conditions: boolean;
  pages: NavPagesLink[];
}

export interface NavPagesLink {
  title: string;
  icon: string;
  link: string;
  queryParam?: Params;
  beta: boolean;
  subscribed: boolean;
  conditions?: any;
}

@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  defendantLink!: DefendantLink;
  subscribedProducts: any;
  appConstants = AppConstants;
  limitedUserMenuVisible = false;
  systemconfig: SystemConfig;
  navList: NavTopLink[] = [];
  isUserLoaded = false;
  isAdmin = false;
  isDeveloper = false;
  scrollViewHeight: number;
  subscribedToBailAndDW: boolean;
  isBail: boolean;
  isV1Customer: boolean = false;
  isV1ArchiveEnabled: boolean = false;
  isV1UserArchiveEnabled: boolean = false;
  accounts: CapAccount[];
  account: CapAccount;
  activeCustomerId: string;

  constructor(
    private storageService: StorageService,
    private billingService: BillingService,
    private userService: UserService,
    private systemConfigService: SystemConfigService,
    private permissionsService: PermissionService,
    public eventService: EventService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.setScrollHeight();
    this.subs.sink = this.eventService.onSubscribedProductChange$.subscribe(() => {
      this.subs.sink = this.getSubscribedProducts(true).subscribe();
    });
    this.subs.sink = this.eventService.onAccountSwitch$.subscribe(() => {
      this.activeCustomerId = this.authService.getActiveCustomerId();
      this.accounts = this.authService.getValidAccounts();
      this.account = this.accounts.find(a => a.CustomerId === this.activeCustomerId);
    });
    this.activeCustomerId = this.authService.getActiveCustomerId();
    this.accounts = this.authService.getValidAccounts();
    this.account = this.accounts.find(a => a.CustomerId === this.activeCustomerId);
    this.subs.sink = this.eventService.customerV1ArchiveToggle$.subscribe((res) => {
      if (res.type === 'global') {
        this.isV1ArchiveEnabled = res.value;
      } else if (res.type === 'user') {
        this.isV1UserArchiveEnabled = res.value;
      }
      this.setNav();
    });

    if (environment.environmentName.toUpperCase() === 'LOCAL' ||
      environment.environmentName.toUpperCase() === 'DEV') {
      this.isDeveloper = true;
    }
    this.subs.sink = this.storageService.watchStorage().subscribe((data: string) => {
      this.defLink();
    });
    this.subs.sink = this.getSubscribedProducts().subscribe(() => {
      this.subs.sink = this.userService
        .current()
        .subscribe(resp => {
          if (!!resp.data) {
            this.limitedUserMenuVisible = resp.data.LimitedVisibility;
            this.isUserLoaded = true;
            this.isAdmin = resp.data.Type === 'Administrator';

            if (resp.data.ToggleV1Archive) {
              this.isV1UserArchiveEnabled = true;
            } else {
              this.isV1UserArchiveEnabled = false;
            }
          }
          this.defLink();
          this.subs.sink = this.systemConfigService.get().subscribe(response => {
            if (!!response.data) {
              this.systemconfig = new SystemConfig();
              this.systemconfig.loadData(response.data[0]);
              if (this.systemconfig.ToggleV1Archive) {
                this.isV1ArchiveEnabled = true;
              } else {
                this.isV1ArchiveEnabled = false;
              }

              if (this.systemconfig && this.systemconfig.customer && this.systemconfig.customer.V1Id) {
                this.isV1Customer = true;
              } else {
                this.isV1Customer = false;
              }
            }
            this.setNav();
          });
        });
    });
  }

  @HostListener('window:resize')
  setScrollHeight() {
    try {
      const mainDrawer = <HTMLElement>document.querySelector('.mat-drawer-inner-container');
      this.scrollViewHeight = mainDrawer.offsetHeight;
    } catch (e) {
      this.scrollViewHeight = 400;
    }
  }

  getSubscribedProducts(forceRefresh: boolean = false): Observable<boolean | any> {
    return new Observable(obs => {
      this.subs.sink = this.billingService.GetSubscribedProducts(forceRefresh).subscribe(prod => {
        this.subscribedProducts = prod;
        if (this.subscribedProducts[this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM] &&
          this.subscribedProducts[this.appConstants.PRODUCT_NAME_DEFENDANT_WATCH]) {
          this.subscribedToBailAndDW = true;
        } else {
          this.subscribedToBailAndDW = false;
        }
        this.changeDetector.detectChanges();
        obs.next();
        obs.complete();
      });
    });
  }

  defLink() {
    if (localStorage.getItem('NewDefendantMode') === '/defendant/wizard') {
      this.defendantLink = '/defendant/wizard';
    } else {
      this.defendantLink = '/defendant/data-capture';
    }
  }

  setNav() {
    this.isBail = this.menuMatchesSubscription([
      this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
    ]);

    this.navList = [
      {
        heading: 'Dashboard',
        icon: 'far fa-home',
        link: '/dashboard',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
        ]),
        conditions: true,
        pages: [],
      },
      {
        heading: 'Admin',
        icon: 'far fa-user-circle',
        link: '/',
        subscribed: true,
        conditions: this.isUserLoaded && this.isAdmin,
        pages: [
          {
            title: 'Customers',
            link: '/admin/customers',
            icon: '',
            beta: false,
            subscribed: true,
          },
          {
            title: 'What\'s New',
            link: '/admin/whats-new',
            icon: '',
            beta: false,
            subscribed: true,
          },
          {
            title: 'Users',
            link: '/admin/users',
            icon: '',
            beta: false,
            subscribed: true,
          },
        ],
      },
      {
        heading: 'Developer',
        icon: 'far fa-brackets-curly',
        link: '/',
        subscribed: true,
        conditions: this.isDeveloper,
        pages: [
          {
            title: 'Settings',
            link: '/developer/settings',
            icon: '',
            beta: false,
            subscribed: true,
          },
          {
            title: 'Templates',
            link: '/developer/templates',
            icon: '',
            beta: false,
            subscribed: true,
          },
        ],
      },
      {
        heading: 'Defendant',
        icon: 'far fa-user-circle',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
        ]),
        conditions: this.isUserLoaded && !this.limitedUserMenuVisible,
        pages: [
          {
            title: 'Active List',
            link: '/defendant/active',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Add Defendant',
            link: this.defendantLink,
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Prospect',
            link: '/defendant/prospects',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Merge & Undelete',
            link: '/defendant/merge-undelete',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Check In',
            link: '/defendant/checkin',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Reminders',
            link: '/adiv2/src/bail',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_TEXT_MESSAGING,
            ]),
          },
        ],
      },
      {
        heading: 'Defendant Manager',
        icon: 'far fa-user-circle',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_DEFENDANT_MANAGER,
        ]),
        conditions: true,
        pages: [
          {
            title: 'Clients',
            link: '/defendantmanager/defendants',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Calendar',
            link: '/defendantmanager/calendars',
            icon: '',
            beta: false,
            subscribed: true,
          },
        ],
      },
      {
        heading: 'Agent Menu',
        icon: 'far fa-user-circle',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
        ]),
        conditions: this.isUserLoaded && this.limitedUserMenuVisible,
        pages: [
          {
            title: 'Active List',
            link: '/defendant/active',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Add Defendant',
            link: this.defendantLink,
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Prospects',
            link: '/defendant/prospects',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Take Payment',
            link: '/payment',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Record Check In',
            link: '/defendant/checkin',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Available Powers',
            link: '/available_powers',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Add Powers',
            link: '/inventoryintake',
            icon: '',
            beta: false,
            subscribed: true,
            conditions: this.systemconfig && !this.systemconfig.AllowLimitedUserToManageInventory,
          }, {
            title: 'Assignment',
            link: '/assignpower',
            icon: '',
            beta: false,
            subscribed: true,
            conditions: this.systemconfig && !this.systemconfig.AllowLimitedUserToManageInventory,
          }, {
            title: 'Report Sales',
            link: '/report/sales',
            icon: '',
            beta: false,
            subscribed: true,
            conditions: this.systemconfig && !this.systemconfig.AllowLimitedUserToReportSales,
          }, {
            title: 'Report Exoneration',
            link: '/report/exoneration',
            icon: '',
            beta: false,
            subscribed: true,
            conditions: this.systemconfig && !this.systemconfig.AllowLimitedUserToReportSales,
          }, {
            title: 'Void/Unvoid',
            link: '/voidunvoid',
            icon: '',
            beta: false,
            subscribed: true,
            conditions: this.systemconfig && !this.systemconfig.AllowLimitedUserToManageInventory,
          },
        ],
      },
      {
        heading: 'Power Inventory',
        icon: 'far fa-file-alt',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
        ]),
        conditions: this.isUserLoaded && !this.limitedUserMenuVisible,
        pages: [
          {
            title: 'Available',
            link: '/inventory/available',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Add New',
            link: '/inventory/intake',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Assign',
            link: '/inventory/assign',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Exonerate',
            link: '/inventory/exonerate',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'History',
            link: '/inventory/history',
            icon: '',
            beta: false,
            subscribed: true,
          },
          {
            title: 'Delete',
            link: '/inventory/delete',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Void',
            link: '/inventory/void',
            icon: '',
            beta: false,
            subscribed: true,
          },
        ],
      },
      {
        heading: 'Surety Reporting',
        link: '',
        icon: 'far fa-landmark-alt',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
        ]),
        conditions: true,
        pages: [
          {
            title: 'Sales Reports',
            link: '/report/sales',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Exoneration Reports',
            link: '/report/exoneration',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Bankers',
            link: '/report/bankers',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BANKERS,
            ]),
          },
        ],
      },
      {
        heading: 'Instant Powers',
        icon: 'far fa-file-alt',
        link: '/instant-powers',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_INSTANTPOWERS,
        ]),
        conditions: true,
        pages: [],
      },
      {
        heading: 'Accounting',
        icon: 'far fa-dollar-sign',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
        ]),
        conditions: this.isUserLoaded && !this.limitedUserMenuVisible,
        pages: [
          {
            title: 'Accounts Receivable',
            link: '/accounting/accounts_receivable',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Take Payment',
            link: '/accounting/payment',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Write Off',
            link: '/accounting/adjustment',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Refund',
            link: '/accounting/refund',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Fees Setup',
            link: '/accounting/fees',
            icon: '',
            beta: false,
            subscribed: true,
          },
        ],
      },
      {
        heading: 'Reporting',
        icon: 'far fa-chart-bar',
        link: '/defendantmanager/reporting',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_DEFENDANT_MANAGER,
        ]),
        conditions: true,
        pages: [],
      },
      {
        heading: 'Reports & Forms',
        icon: 'far fa-chart-bar',
        link: '/reporting',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
          this.appConstants.PRODUCT_NAME_DEFENDANT_MANAGER_PLUS,
        ]),
        conditions: true,
        pages: [],
      },
      {
        heading: 'Settings',
        icon: 'far fa-cogs',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_DEFENDANT_MANAGER,
        ]),
        conditions: true,
        pages: [
          {
            title: 'Agency',
            link: '/defendantmanager/companies',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Users',
            link: '/defendantmanager/users',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Text Reminders',
            link: '/defendantmanager/reminder-setting/defmngr',
            icon: '',
            beta: false,
            subscribed: true,
          },
        ],
      },
      {
        heading: 'Defendant Watch',
        icon: 'far fa-cubes',
        link: '/defendantwatch',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_DEFENDANT_WATCH,
        ]),
        conditions: this.isUserLoaded && !this.limitedUserMenuVisible && !this.subscribedToBailAndDW,
        pages: [],
      },
      {
        heading: 'Services',
        icon: 'far fa-cubes',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
          this.appConstants.PRODUCT_NAME_DEFENDANT_WATCH,
        ]),
        conditions: this.isUserLoaded && !this.limitedUserMenuVisible,
        pages: [
          {
            title: 'All Services',
            link: '/serviceshome',
            icon: '',
            beta: false,
            subscribed: true,
          },
          {
            title: 'Background Checks',
            link: '/background-checks',
            icon: '',
            beta: false,
            subscribed: true,
          },
          {
            title: 'Defendant Watch',
            link: '/defendantwatch',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_DEFENDANT_WATCH,
            ]),
          }, {
            title: 'eSign',
            link: '/e-sign',
            icon: '',
            beta: true,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
          }, {
            title: 'Reminders',
            link: '/adiv2/src/bail',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_TEXT_MESSAGING,
            ]),
          }, {
            title: 'Google Reviews',
            link: '/google-review',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_GOOGLE_REVIEW,
            ]),
          }, {
            title: 'Docusign Settings',
            link: '/admin_docusign',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_WEBSITE_BONDING,
            ]),
          }, {
            title: 'Website Bonding',
            link: '/websitebondingsettings',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_WEBSITE_BONDING,
            ]),
          }, {
            title: 'Mobile Verification',
            link: '/mobile-verification',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_MVS_NETWORK_ACCESS,
            ]),
          },
        ],
      },
      {
        heading: 'System Manager',
        icon: 'far fa-cogs',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
          this.appConstants.PRODUCT_NAME_DEFENDANT_WATCH,
        ]),
        conditions: this.isUserLoaded && !this.limitedUserMenuVisible,
        pages: [
          {
            title: 'Company',
            link: '/system/company',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Agencies',
            link: '/system/agencies',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
          }, {
            title: 'Agents',
            link: '/system/agents',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
          }, {
            title: 'Surety',
            link: '/system/surety',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
          }, {
            title: 'Users',
            link: '/system/users',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Groups',
            link: '/system/groups',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
          }, {
            title: 'Report Permissions',
            link: '/system/reportpermissions',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
          }, {
            title: 'System Config',
            link: '/system/config',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
          }, {
            title: 'Dropdown Lists',
            link: '/system/commonlists',
            icon: '',
            beta: false,
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
          },
        ],
      },
      {
        heading: 'Version 1',
        icon: '',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
        ]),
        // customer level + is a v1 customer + user level
        conditions: this.isV1Customer,
        pages: [
          {
            title: 'V1 Archive',
            icon: 'far fa-file',
            link: '/v1-archive',
            subscribed: this.menuMatchesSubscription([
              this.appConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
            ]),
            beta: false,
            // customer level + is a v1 customer + user level
            conditions: this.isV1ArchiveEnabled && this.isV1Customer && this.isV1UserArchiveEnabled,
          },
        ],
      },
      {
        heading: 'Collect',
        icon: 'far fa-headset',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_COLLECTIONS,
        ]),
        conditions: true,
        pages: [
          {
            title: 'Carol\'s Desk',
            link: '/collections/summary',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Debtor Accounts',
            link: '/collections/accounts',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Settlement Offers',
            link: '/collections/requests/counteroffers',
            icon: '',
            beta: false,
            subscribed: true,
          }, {
            title: 'Invoice Disputes',
            link: '/collections/requests/invoiceclaims',
            icon: '',
            beta: false,
            subscribed: true,
          },
          {
            title: 'Bad Contact Info',
            link: '/collections/undeliverable',
            icon: '',
            beta: false,
            subscribed: true,
          },
        ],
      },
      {
        heading: 'Collect Settings',
        icon: 'far fa-cogs',
        link: '',
        subscribed: this.menuMatchesSubscription([
          this.appConstants.PRODUCT_NAME_COLLECTIONS,
        ]),
        conditions: true,
        pages: [
          {
            title: 'Company',
            link: '/collections/companies',
            icon: '',
            beta: false,
            subscribed: true,
          },
          {
            title: 'Setup',
            link: '/collections/reminder-setting/collections',
            icon: '',
            beta: false,
            subscribed: true,
          },
        ],
      },
    ];
  }

  menuMatchesSubscription(subscriptionItems: Array<string>): boolean {
    if (this.subscribedProducts === undefined || !this.subscribedProducts) {
      return false;
    }
    if (subscriptionItems.length < 1) {
      return true;
    }
    for (const allowed of subscriptionItems) {
      if (this.subscribedProducts[allowed] === true) {
        return true;
      }
    }
    return false;
  }

  switchAccount(account: CapAccount) {
    let activeSnapshot = this.route.snapshot;
    while (activeSnapshot.firstChild) {
      activeSnapshot = activeSnapshot.firstChild;
    }

    if (activeSnapshot.component === DefendantComponent) {
      this.account = account;

      this.eventService.switchAccount$.pipe(take(1)).subscribe(() => {
        this.performAccountSwitch(this.account);
      });

      this.eventService.emitSwitchAccountCalled();
    } else {
      this.performAccountSwitch(account);
    }
  }

  performAccountSwitch(account: CapAccount){
    this.activeCustomerId = account.CustomerId;
    this.account = account;
    this.authService.switchAccount(account, true)
      .then((dialogRef) => {
        this.router.navigate(['/']).finally(() => {
          dialogRef.close();
        });
      })
      .catch((dialogRef) => {
        this.router.navigate(['/']).finally(() => {
          window.location.reload();
        });
      });
  }

  }
