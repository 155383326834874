
  <div *ngIf="userInTrial"
       class="text-center"
       fxLayout="column"
       fxLayoutAlign="center center"
       fxLayoutGap="15px">
    <h4>This feature is not available on a trial account</h4>
    <button mat-raised-button color="primary" (click)="getStartedNow()">
      Get started now!
    </button>
  </div>

  <div *ngIf="!userInTrial">
    <dx-tab-panel
      *ngIf="showTabs"
      #tabPanel
      [dataSource]="this.tabs"
      [selectedIndex]="activeTabIndex"
      [animationEnabled]="true"
      [swipeEnabled]="false"
      [showNavButtons]="true">
      <div *dxTemplate="let tab of 'title'">
        <i [ngClass]="tab.icon" style="margin-right: 1rem;"></i>{{tab.text}}
      </div>

      <div *dxTemplate="let data of 'tabAutomated'">
        <app-adi-automated (onSave)='handleOnSave($event)' [inDialog]='inDialog' [appSource]="appSource"></app-adi-automated>
      </div>

      <hr>
      <div style="padding: 50px;" *dxTemplate="let data of 'tabIntro'">
        <p class="intro-section">
          <i class="fas fa-tools text-primary fa-3x"></i>
          <span>
            Enabling a reminder uses a pre-configured text with no additional setup required. Optionally, you can further customize the frequency or the messages, by selecting the setting icon or selecting ‘Message Templates’ under the ‘More’ tab. Reminders are automatically generated to event items entered in a defendant's calendar.
          </span>
        </p>
        <p class="intro-section">
          <i class="fas fa-alarm-clock text-primary fa-3x"></i>
          <span>
            Automated reminders are sent to the networks at approximately midday daily (12pm EST in winter and 1pm EST in summer).
          </span>
        </p>
        <p class="intro-section hidden">
          <i class="fas fa-sms text-primary fa-3x"></i>
          <span>
            Text recipients first receive a "Reply STOP to opt out of receiving messages from this service. Reply “UNSTOP” to resume if stopped."
          </span>
        </p>
        <p class="intro-section">
          <i class="fas fa-tag text-primary fa-3x"></i>
          <span>
            5¢/message for Automated Check-In and other reminders, including once off Manual Texts sent by a user. Automated Reminders for GPS Check-Ins including Identity Verification are 30¢/message. To explore pricing,
            <a href="//www.captira.com/pages/gps-check-in-help" target="_blank" rel="noopener noreferrer">click here</a>. Volume discounts are available for large programs.
          </span>
        </p>
      </div>


      <div *dxTemplate="let data of 'tabOneTime'">
        <app-adi-onceoff [appSource]="appSource"></app-adi-onceoff>
      </div>
      <div *dxTemplate="let data of 'tabOther'">
        <mat-tab-group [selectedIndex]="selectedOtherIndex">
          <mat-tab label="Message Templates">
            <app-adi-templates
              (templateChanged)="onTemplateChanges($event)"
              [appSource]="appSource">
            </app-adi-templates>
          </mat-tab>
          <mat-tab label="Do Not Call List">
            <app-donotcontact></app-donotcontact>
          </mat-tab>
          <mat-tab label="Reminder History">
            <app-reminder-logs [currentSourceType]="reminderType"></app-reminder-logs>
          </mat-tab>
        </mat-tab-group>
      </div>
    </dx-tab-panel>
  </div>
