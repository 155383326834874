import { Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

export interface ConfirmationDataInterface {
  message: string;
  title: string;
  yesText?: string;
  noText?: string;
  showCloseIcon?: boolean;
  component?: ComponentType<Component>;
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {

  selectedPortal: ComponentPortal<Component>;
  yesText = 'OK';
  noText = 'CANCEL';
  showCloseIcon = false;

  private element: HTMLElement;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDataInterface,
    private viewContainerRef: ViewContainerRef,
  ) {
    if (data.component) {
      this.selectedPortal = new ComponentPortal(data.component);
    }
    if (data.yesText) {
      this.yesText = data.yesText;
    }
    if (data.noText) {
      this.noText = data.noText;
    }

    if(!!data.showCloseIcon) {
      this.showCloseIcon = data.showCloseIcon;
    }
  }

  ngOnInit(): void {
    this.element = this.viewContainerRef.element.nativeElement;
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1600';
  }

  ngOnDestroy(): void {
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1000';
  }

}
