import { BaseModel } from './BaseModel';

export class DefendantWatchSettings extends BaseModel {
  public SettingsID: string;
  public NotificationEmails: string;
  public WatchForfeitures: boolean;
  public WatchLiability: boolean;
  public WatchLiabilityValue: number;
  public ClientAgreementDate;
  public ClientAgreementUserId: string;
  public FilterByAgency: boolean;
  public FilterAgencyIDs: string;
  public Source: string;
  public SourceUniqueId: string;
  public RedirectURI: string;
  public ExternalCompanyId: string;


  constructor(obj?: object) {
    super(obj);

    if (obj == null) {
      this.FilterByAgency = false;
      this.WatchLiabilityValue = 5000;
      this.WatchLiability = false;
      this.WatchForfeitures = false;
    }


  }
}
