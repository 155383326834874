import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers';
import { defendantsFeatureKey, DefendantState } from '../reducers/defendants.reducer';

export const selectDefendantsFeature = createFeatureSelector<DefendantState>(defendantsFeatureKey);

export const selectDefendant = createSelector(
  selectDefendantsFeature,
  (state: DefendantState) => state.defendant,
);

export const selectSection = createSelector(
  selectDefendantsFeature,
  (state: DefendantState) => state.section,
);
