import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AdminService} from '../services/admin.service';
import {IDynamicPopupComponent} from '../../../components/common/dynamic-popup/dynamic-popup.component';
import {SubscriptionComponent} from '../subscription/subscription.component';
import {GridService} from '../../../services/grid.service';
import {DxDataGridComponent} from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import {UnsubscribeOnDestroyAdapter} from '../../../common/UnsubscribeOnDestroy';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {BillingService} from '../../../services/billing.service';
import {NotifyService} from '../../../common/notify/notify.service';
import {concatMap, delay, filter, take} from 'rxjs/operators';
import * as moment from 'moment';
import { from, of } from 'rxjs';
import { CacheService } from '../../../services/cache.service';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  entryComponents: [SubscriptionComponent],
})
export class CustomersComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild(DxDataGridComponent, {static: true}) dataGrid: DxDataGridComponent;

  gridData: any;
  dynamicPopupClass: Type<IDynamicPopupComponent> | undefined;
  dynamicPopupData = [];
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  loginLoading = false;
  buttonsDisabled = false;
  loggedOnUser = 'n/a';
  productsLoading = false;
  productsLoadingValue = 0;
  accountAgeToLoadForSubscription = 90;

  moreDetailsFlag = false;

  subLabels: any = this.adminService.getAllProductLabels();

  currentLoggedCustomerId: any;

  currentCustomer = {
    details: {
      Name: 'n/a',
      CustomerId: null,
    },
    subscription: {},
  };

  clientCreditCard = {
    Name: '',
    GatewayCredential: 'n/a',
    GatewayKey: 'n/a',
  };

  ClientCreditCardGatewaysVisiblePopup = false;

  constructor(
    private adminService: AdminService,
    private gridService: GridService,
    private authService: AuthService,
    private billingService: BillingService,
    private notifyService: NotifyService,
    private cache: CacheService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.preparedGridData();

    this.subs.sink = this.adminService.getCustomerDetails(localStorage.getItem('activeCustomerId'))
        .subscribe((response) => {
          response.details = response.customer;
          delete response.customer;

          this.currentCustomer = response;
          this.loggedOnUser = this.currentCustomer.details.Name;
        }, (err) => {
          console.error(err);
        });
  }

  clearCachedProducts() {
    localStorage.removeItem('customersSubscribedProducts');
    this.gridData.forEach((data: any) => {
      data.products = null;
    });
  }

  proceduralCustomersProducts(clearCache = false) {
    if (clearCache) {
      this.clearCachedProducts();
    }
    const main = this;

    const customersLength = this.gridData.filter((data: any) => {
      return moment(data.Created_At).add(this.accountAgeToLoadForSubscription, 'day') >= moment();
    }).length;

    let currentCount = 0;
    const productsCached = main.productsCached();
    let cachedList: any = [];

    this.productsLoadingValue = 0;
    this.productsLoading = true;

    if (productsCached) {
      cachedList = JSON.parse(localStorage.getItem('customersSubscribedProducts'));
      this.productsLoading = false;
      this.productsLoadingValue = 100;
    }

    if (productsCached) {
      this.gridData.forEach(data => {
        const auxItem = cachedList.filter((product: any) => {
            return product.chargifyId === data.ChargifySubscriptionId;
        });
        if (auxItem.length) {
            data.products = auxItem[0].text;
            data.ChargifySubscriptionState = main.formatSubscriptionState(data.ChargifySubscriptionState, auxItem[0].payment_profile);
        }
      });
    } else {

      const gridData$ = from(this.gridData);
      // need to delay here to avoid hitting rate limiter at the API
      this.subs.sink = gridData$.pipe(
        filter((data: any) => moment(data.Created_At).add(this.accountAgeToLoadForSubscription, 'day') >= moment()),
        concatMap(item => of(item).pipe(delay(500))),
      ).subscribe((data: any) => {
        main.subs.sink = main.adminService
          .getCustomerSubscribedProducts(data.Id, data.ChargifySubscriptionId, data.ChargifySubscriptionState)
          .pipe(
            take(1),
          )
          .subscribe((response) => {
            data.products = main.getValidProductsString(response);
            data.ChargifySubscriptionState = main.formatSubscriptionState(data.ChargifySubscriptionState, response.payment_profile);
            main.saveProductOnCache({
                                      chargifyId: data.ChargifySubscriptionId,
                                      text: data.products,
                                      payment_profile: response.payment_profile,
                                    });
            currentCount++;
            main.productsLoadingValue = this.progressCount(currentCount, customersLength);

          }, (error) => {
            main.productsLoading = false;
            main.productsLoadingValue = 100;
            // console.log(error);
          });
      });
    }
  }

  progressCount(currentCount: number, innerLength: any) {
    return Math.ceil((currentCount / innerLength) * 100);
  }

  getValidProductsString(list: any) {
    const main = this;
    let auxProductList: any = [];

    for (const product in list) {
      if (list[product] && product !== 'price_level_name' && product !== 'payment_profile') {
        auxProductList.push(main.subLabels[product]);
      }
    }

    auxProductList = auxProductList.join(', ');
    auxProductList = auxProductList.trim();
    auxProductList = auxProductList.replace(/,\s*$/, '');
    return auxProductList;
  }

  formatSubscriptionState(state: string, paymentProfile: any = []) {
    let auxState: any = state;
    try {
      if (auxState.toLocaleLowerCase() == 'active' && Object.keys(paymentProfile).length === 0) {
          auxState = auxState.trim();
          auxState += ' - No CC';
      }

      state = auxState;
      return state;
    } catch (e) {
      return state;
    }
  }

  capsLetter(data: any) {
    let text = data.value;

    try {
      text = text.charAt(0).toUpperCase() + text.slice(1);
      return text;
    } catch (e) {
      return '';
    }
  }

  formatLoad(value: any) {
    return `${Math.round(value * 100)}%`;
  }

  saveProductOnCache(item: any) {
    let cacheList: any = localStorage.getItem('customersSubscribedProducts');
    if (cacheList !== null) {
      cacheList = JSON.parse(cacheList);
    } else {
      cacheList = [];
    }
    cacheList.push(item);
    localStorage.setItem('customersSubscribedProducts', JSON.stringify(cacheList));
  }

  openCustomerDetails(customerId: any) {
    const url = `/admin/customer-details/${customerId}`;
    this.router.navigateByUrl(url);
  }

  redirectToChargify(id: any) {
    let url = '';
    if (environment.environmentName.toUpperCase() === 'PROD') {
      url = 'https://captira.chargify.com/subscriptions/' + id.toString();
    } else {
      url = 'https://captira-test.chargify.com/subscriptions/' + id.toString();
    }

    window.open(url, '_blank');
  }

  updateEmployeeCustomerId(data: any) {
    const main = this;

    this.loginLoading = true;
    this.subs.sink = this.adminService.getCustomerSubscribedProducts(data.Id).subscribe((response) => {
        const subscribedProducts = response;
        delete subscribedProducts.price_level_name;
        for (const key in subscribedProducts) {
          if (subscribedProducts[key]) {
            main.directlyLoginAsCustomer(data.Id);
            return true;
          }
        }
        main.loginLoading = false;
        this.notifyService.toast('Cannot log in as customer with no valid subscriptions', 'error');
      },
      (err) => {
        main.loginLoading = false;
        console.error(err);
      });
  }

  directlyLoginAsCustomer(customerId: any, staffFlag = false) {
    const main = this;
    main.subs.sink = this.adminService.loginAsCustomer(customerId, staffFlag).subscribe(() => {
        main.authService.Logout();
      },
      (err) => {
        main.loginLoading = false;
        console.error(err);
      });
    return true;
  }

  productsCached() {
    const productsFlag = localStorage.getItem('customersSubscribedProducts');
    return (productsFlag != null && productsFlag != 'undefined');
  }

  reloadProductsButtonText() {
    if (this.productsCached()) {
      return 'Reload Product Subscriptions';
    }
    return 'Load Product Subscriptions';
  }

  async customersCached() {
    return (!!await this.cache.getFromIDB('customersDataGrid'));
  }

  validateCurrentId(data: any) {
    const user = data;
    let loggedFlag = false;
    if (this.currentCustomer.details.CustomerId == user.CustomerId) {
      loggedFlag = true;
    }

    return loggedFlag || this.loginLoading;
  }

  validateLogOutCustomer() {
    if (environment.environmentName.toUpperCase() === 'PROD' && this.currentCustomer.details.CustomerId != 85) {
      this.currentLoggedCustomerId = localStorage.getItem('activeCustomerId');
      return true;
    } else {
      return false;
    }
  }

  logoutStaffFromCustomer() {
    if (this.validateLogOutCustomer()) {
      this.directlyLoginAsCustomer('', true);
    }
  }

  async preparedGridData(clearCache = false) {
    if (clearCache) {
      await this.cache.removeFromIDB('customersDataGrid');
    }
    this.gridData = [];
    if (clearCache) {
      this.loadGridDataFromAPI();
    } else {
      const customersCache = await this.cache.getFromIDB('customersDataGrid');

      if (!!customersCache) {
        try {
          this.gridData = JSON.parse(customersCache);
          if (this.productsCached()) {
            this.proceduralCustomersProducts();
          }
        } catch (e) {
          // console.log(e);
          // If cache data was set but was invalid, we clear cache and trigger load data grid but from API
          this.cache.removeFromIDB('customersDataGrid');
          this.loadGridDataFromAPI();
        }
      } else {
        this.loadGridDataFromAPI();
      }
    }
  }

  loadGridDataFromAPI() {
    const main = this;
    this.subs.sink = this.adminService
        .getAllCustomers()
        .subscribe(async (response) => {
            // this.gridData = response.data.filter(f => !!f.owner );
            this.gridData = response.data;
            this.gridData.forEach(function (data: any) {
              data.products = '';
              if (data.owner != null) {
                if (data.owner.identity == null) {
                  data.owner.identity = {FirstName: '', LastName: '', Email: ''};
                }
                data.OwnerName = data.owner.identity.FirstName + ' ' + data.owner.identity.LastName;
              } else if (data.DefaultUserId === localStorage.getItem('identityId')) {
                data.owner = {
                  identity: {},
                };
                data.owner.identity = {FirstName: '', LastName: '', Email: localStorage.getItem('userEmail')};
              }

            });
            await this.cache.setInIDB('customersDataGrid', JSON.stringify(this.gridData), false);
          },
          (error) => {
            // console.log('All customers list error: ', error);
          },
          () => {
            if (main.productsCached()) {
              main.proceduralCustomersProducts();
            }
          });
  }

  onToolbarPreparing(e: any) {
    const toolbarItems = e.toolbarOptions.items;
    const main = this;

    toolbarItems.unshift(
      {
        location: 'left',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          icon: 'refresh',
          onClick: () => {
            main.preparedGridData(true);
          },
        },
      },
      {
        location: 'left',
        widget: 'dxCheckBox',
        locateInMenu: 'auto',
        options: {
          hint: 'Details',
          text: 'Details',
          onValueChanged: () => {
            main.toggleDetailsGrid();
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            main.exportGrid();
          },
        },
      },
    );
  }

  toggleDetailsGrid() {
    this.moreDetailsFlag = !this.moreDetailsFlag;
  }

  exportGrid(showPrintMessage = false) {
    const name = 'GridExport';
    this.gridService.exportGrid(this.dataGrid, name);
    if (showPrintMessage) {
      notify('To print this report, open downloaded file in Excel or Google Sheets!');
    }
  }

  openClientCreditCardGatewaysPopup(data: any) {
    this.clientCreditCard = data;
    this.ClientCreditCardGatewaysVisiblePopup = true;
  }

  onPopupHidden() {
    this.dynamicPopupClass = null;
    this.dynamicPopupData = [];
    this.dynamicPopupVisible = false;
  }

}

