import { ConnectableObservable, Observable, of, throwError } from 'rxjs';
import { catchError, map, publishReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiUtilities } from '../shared/ApiUtilities';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { PortoResponse } from '../models/porto-response';
import { UserAudit } from '../models/user-audit';
import { UserMessage } from '../models/user-message';
import { IUser, User } from '../models/user';

@Injectable()
export class UserService {

  private findUser$: any;
  private cacheKey = 'currentUser';

  constructor(private http: HttpClient,
    private settings: SettingsService,
    private authService: AuthService,
    private cache: CacheService) {
  }

  getAllUsersPromise(): Promise<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/users/grid/all?limit=0`)
      .toPromise<any>().then(response => {
        return {
          data: response.data,
          totalCount: response.meta.pagination === undefined ? response.data.length : response.meta.pagination.total,
        };
      })
      .catch(error => throwError(error.error || 'Server Error'));
  }

  updateUser(key: any, data: any): Promise<IUser> {
    return this.http.patch<PortoResponse<IUser>>(`${this.settings.getBaseUrl()}/users/${key}`, data).pipe(
      map((res: any) => {
        this.cache.set(this.cacheKey, JSON.stringify(res), true);
        return res.data;
      })).toPromise<IUser>();
  }

  createUser(data: any): Promise<any> {
    try {
      return this.http.post(`${this.settings.getBaseUrl()}/user`, data).pipe(
        map((res: any) => res)).toPromise();
    } catch (e) {
      return throwError(e).toPromise();
    }
  }

  getMessages(pageNum: number): Observable<UserMessage[]> {
    return this.http.get<PortoResponse<UserMessage[]>>(`${this.settings.getBaseUrl()}/user/messages/unread?limit=50&page=${pageNum}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getCarolMessages(pageNum: number): Observable<UserMessage[]> {
    return this.http.get<PortoResponse<UserMessage[]>>(`${this.settings.getBaseUrl()}/user/carol/messages/unread?limit=50&page=${pageNum}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  markMessagesAsRead(ids: any): Observable<any> {
    const postData = {
      ids: ids,
    };

    return this.http.post(`${this.settings.getBaseUrl()}/user/messages/read`, postData).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  findUser(id: string): Observable<any> {
    if (this.findUser$) {
      return this.findUser$;
    } else {
      this.findUser$ = this.http.get(`${this.settings.getBaseUrl()}/users/${id}`)
        .pipe(
          map((res: any) => {
            this.findUser$ = null;
            return res;
          }),
          catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),
          publishReplay()) as ConnectableObservable<any>;
      this.findUser$.connect();
      return this.findUser$;
    }
  }

  current(expiry = true): Observable<any> {
    const data = this.cache.get(this.cacheKey);

    if (!!data) {
      return of(JSON.parse(data)); // Return cache data as observables
    } else {
      return this.http.get(`${this.settings.getBaseUrl()}/users/${localStorage.getItem('userId')}`).pipe(
        map((res: any) => {
          this.cache.set(this.cacheKey, JSON.stringify(res), expiry);
          return res;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
    }
  }

  getUserAuditHistory(userId: string, params: HttpParams): Observable<{ data: UserAudit[]; totalCount: number }> {
    return this.http.get<PortoResponse<UserAudit[]>>(`${this.settings.getBaseUrl()}/audits/user/${userId}`, { params })
      .pipe(
        map(resp => {
          return { data: resp.data, totalCount: resp.meta.pagination.total };
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),
      );
  }

  exportUserAudit(userId: string, params: HttpParams): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/audits/user/${userId}/export`, { params, responseType: 'arraybuffer' });
  }
}
