import {ComponentPortal, DomPortalOutlet} from '@angular/cdk/portal';
import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {REPORT_POPOUT_DATA, ReportPopoutData, PopoutModalName} from './popout.tokens';
import {PdfViewerComponent} from '../components/common/pdf-viewer/pdf-viewer.component';
import {Observable} from 'rxjs';
import {Report} from '../models/report';

// from https://medium.com/@saranya.thangaraj/open-angular-component-in-a-new-tab-without-bootstrapping-the-whole-app-again-e329af460e92
@Injectable()
export class PopoutService {
  styleSheetElement;

  public openPopouts: Array<ReportPopoutData> = [];
  private _mainWindowRef: any;

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
  ) {
    this._mainWindowRef = window.self;
  }

  focusMainWindow() {
    this._mainWindowRef.focus();
  }

  openPopoutModal(data) {
    const windowInstance = window.open('pdfviewer.html');
    // Wait for window instance to be created

    setTimeout(() => {
      this.createCDKPortal(data, windowInstance);
      setTimeout(() => {
        this.focusMainWindow();
      });
    }, 1000);
  }

  createCDKPortal(data: ReportPopoutData, windowInstance) {
    if (windowInstance) {
      // Create a PortalOutlet with the body of the new window document
      const outlet = new DomPortalOutlet(windowInstance.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);
      const injector = this.createInjector(data);
      const componentInstance = this.attachReportContainer(outlet, injector);
      // Copy styles from parent window
      setTimeout(() => {
        document.querySelectorAll('style').forEach(htmlElement => {
          windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
        });
        // Copy stylesheet link from parent window
        this.styleSheetElement = this.getStyleSheetElement();
        windowInstance.document.head.appendChild(this.styleSheetElement);

        this.styleSheetElement.onload = () => {
          if (data.modalName === PopoutModalName.pdfViewer) {
            windowInstance.document.title = 'PDF Viewer';
          }

          data.windowInstance = windowInstance;
          data.outlet = outlet;
          data.componentInstance = componentInstance;
          data.parentWindowInstance = window;
          this.openPopouts.push(data);
        };
      });
    }
  }

  isPopoutWindowOpen(modal: ReportPopoutData) {
    return modal.windowInstance && !modal.windowInstance.closed;
  }

  focusPopoutWindow(modal: ReportPopoutData) {
    modal.windowInstance.focus();
  }

  attachReportContainer(outlet, injector) {
    const containerPortal = new ComponentPortal(PdfViewerComponent, null, injector);
    const containerRef: ComponentRef<PdfViewerComponent> = outlet.attach(containerPortal);
    return containerRef.instance;
  }

  createInjector(data: ReportPopoutData) {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: REPORT_POPOUT_DATA,
          useValue: data,
        },
      ],
    });
  }

  getStyleSheetElement() {
    const styleSheetElement = document.createElement('link');
    document.querySelectorAll('link').forEach(htmlElement => {
      if (htmlElement.rel === 'stylesheet') {
        const absoluteUrl = new URL(htmlElement.href).href;
        styleSheetElement.rel = 'stylesheet';
        styleSheetElement.href = absoluteUrl;
      }
    });
    return styleSheetElement;
  }

  openReportPopout(reportObservable: Observable<any>, reportName: string, options?: ReportPopoutData) {
    let modalData: ReportPopoutData = {id: null};
    if (!!options) {
      modalData = options;
    }
    modalData.modalName = PopoutModalName.pdfViewer;
    modalData.reportName = reportName;
    modalData.reportObservable = reportObservable;

    for (const modal of this.openPopouts) {
      if (this.isPopoutWindowOpen(modal)) {
        // this modal exists and isn't closed, switch to it
        if (modal.reportName === reportName) {
          this.focusPopoutWindow(modal);
          return;
        }
      }
    }
    this.openPopoutModal(modalData);
  }
}
