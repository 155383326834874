import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppConstants } from '../../../shared/AppConstants';
import { isNullOrUndefined } from 'util';
import { ReminderService } from '../../../services/reminder.service';
import { GridUtilities } from '../../../shared/GridUtilities';

import CustomStore from 'devextreme/data/custom_store';

import { DxDataGridModule, DxDataGridComponent, DxTemplateModule } from 'devextreme-angular';
import { GridService } from '../../../services/grid.service';
import { ApiUtilities } from '../../../shared/ApiUtilities';
import notify from 'devextreme/ui/notify';
import { formatNumber, parsePhoneNumber } from 'libphonenumber-js';
import { HttpParams } from '@angular/common/http';
import { PaginationService } from '../../../services/pagination.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../../common/notify/notify.service';

@Component({
  selector: 'app-donotcontact',
  templateUrl: './donotcontact.component.html',
  styleUrls: ['./donotcontact.component.css'],
})
export class DonotcontactComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;

  // models and datasources
  gridData: any = {};

  errorData: any[];


  // bools and flags
  errorVisible = false;


  // strings and labels
  phoneMask = AppConstants.PHONE_MASK;
  pattern: any = /^\(\d{3}\)\ \d{3}-\d{4}$/i;
  errorTitle = '';

  popupWindow: any;
  params: HttpParams;

  constructor(
    private gridService: GridService,
    private paginationService: PaginationService,
    private notifyService: NotifyService,
  ) {
    super();
    this.popupWindow = window;
  }

  ngOnInit() {
    this.loadDataStore('reminderexclusions', this.gridService);
  }

  validateNumber = (data: any) => {
//    // console.log('validating lookup', this.required, this.selectedValue);
    if (isNullOrUndefined(data.data.Number)) {
      return false;
    }

    if (data.data.Number !== '' && data.data.Number.length >= 10) {
      data.rule.isValid = true;
      return true;
    } else {
      data.rule.isValid = false;
      data.rule.message = 'Please enter a valid number';
      return false;
    }
  }

  onCellPrepared(e: any) {
    this.gridService.updateGridIcons(e);
  }

  loadDataStore(storeName = 'reminderexclusions', gridService: GridService) {
    this.gridData.store = new CustomStore(
      {
        load: (loadOptions: any) => {
          this.params = this.paginationService.buildParams(loadOptions);
          return gridService.getPaginatedGrid(storeName, this.params).toPromise().then(resp => {
            return {
              data: resp.data,
              totalCount: resp.meta.pagination.total,
            };
          }).catch(gridErr => {
            this.showResult('error', 'Error Loading Data');
            this.showError(gridErr);
          });

        },
        update: (key: any, values: any) => {
          return gridService.updateGridRow(storeName, key.Id, values).then(resp => {
            this.showResult('success', 'Record successfully saved');
          }).catch(gridErr => {
            this.showResult('error', 'Error Saving Data');
            this.showError(gridErr);
          });
        },
        insert: (values: any) => {
          return gridService.insertGridRow(storeName, values).then(resp => {
            this.showResult('success', 'Record successfully saved');
          }).catch(gridErr => {
            this.showResult('error', 'Error Saving Data');
            this.showError(gridErr);
          });
        },
        remove: (key: any) => {
          return gridService.deleteGridRow(storeName, key.Id).then(resp => {
            this.showResult('success', 'Record successfully deleted');
          }).catch(gridErr => {
            this.showResult('error', 'Error Deleting Data');
            this.showError(gridErr);
          });
        },
      });
  }

  // formatPhone(data: number | string): any {
  //   if (!data) {
  //     return '';
  //   }
  //   // em lets rather use known library to handle this for us
  //   const phoneNumber = parsePhoneNumber(data, 'US');
  //   // formatNumber(Data.Number, 'US', 'National').replace(' ', '');
  //   return phoneNumber.formatNational();
  // }

  showResult(type: 'error' | 'success', message: string) {
    this.notifyService.toast(message, type);
  }

  showError(errorInfo: any) {
    if (!this.errorData) {
      this.errorData = [];
    } else {
      this.errorData.length = 0;
    }

    if (!errorInfo) {
      this.errorData = [];
    } else {
      if (!errorInfo.error) {
        this.errorData = this.errorData.concat(ApiUtilities.getErrorMessage(errorInfo, true));
      } else {
        if (!errorInfo.error?.errors) {
          this.errorData = this.errorData.concat(ApiUtilities.getErrorMessage(errorInfo, true));
        } else {
          this.errorData = this.errorData.concat(GridUtilities.getErrorItems(errorInfo.error?.errors));
        }
      }
    }

    this.errorVisible = true;
  }

  onDataErrorOccurred() {
    this.errorTitle = 'Error';
    this.errorVisible = true;
  }

  onEditorPreparing(e: any) {
    const fields = [
      { 'name': 'Number', 'maxLength': 15 },
    ];

    const masks = [
      { 'name': 'Number', 'mask': AppConstants.PHONE_MASK },
    ];

    if (e.parentType === 'dataRow') {
      const result = fields.find(x => x.name === e.dataField);
      if (result !== undefined) {
        e.editorOptions.inputAttr.maxLength = result.maxLength;
        e.editorOptions.maxLength = result.maxLength;
      }

      const mask = masks.find(x => x.name === e.dataField);
      if (mask !== undefined) {
        e.editorOptions.mask = mask.mask;

      }
    }

  }

  onToolbarPreparing(e: any) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach(function(item: any) {
        item.location = 'after';
        item.showText = 'always';
        if (item.name === 'addRowButton') {
          item.options.text = 'Add To \'Do Not Call\' List';
          item.options.icon = 'fal fa-plus';
          item.location = 'before';
        }
      },
    );

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.subs.sink = this.gridService.exportPaginatedGrid('reminderexclusions', this.params).subscribe(data => {
              PaginationService.downloadFile(data, 'DoNotCallList.csv');
            });
          },
        },
      },
    );
  }

}
