import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare global {
  interface Window { AuthorizeNetIFrame: any; }
}

@Component({
  selector: 'app-card-response',
  templateUrl: './card-response.component.html',
  styleUrls: ['./card-response.component.scss'],
})
export class CardResponseComponent implements OnInit {

  constructor(private router: Router) {
    if (window.addEventListener) {
      window.addEventListener('message', receiveMessage, false);
    }
  }

  ngOnInit(): void {
  }
}
function receiveMessage(event) {
  if (event && event.data) {
    callParentFunction(event.data);
  }
}
function callParentFunction(str) {
  if (str && str.length > 0 && window.parent && window.parent.parent
    && window.parent.parent.AuthorizeNetIFrame && window.parent.parent.AuthorizeNetIFrame.onReceiveCommunication) {
    const referrer = document.referrer;
    window.parent.parent.AuthorizeNetIFrame.onReceiveCommunication({qstr : str , parent : referrer});
  }
}
