import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewContainerRef, Output, EventEmitter } from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../../services/event.service';
import { LookupService } from '../../../services/app-lookup.service';
import { Defendant } from '../../../models/defendant';
import { StringUtilities } from '../../../shared/StringUtilities';
import { fader } from '../../../app.animations';
import devices from 'devextreme/core/devices';
import { AppConstants } from '../../../shared/AppConstants';
import AppEditorLengths from '../../../shared/AppEditorLengths';
import { BillingService } from '../../../services/billing.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../../common/notify/notify.service';
import { Bail } from '../../../models/bail';
import { DefendantNotesPopupComponent } from '../defendantnotes/defendantnotespopup/defendantnotespopup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DocumentDialogComponent } from '../document/document-dialog/document-dialog.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { DefendantNotesDialogComponent } from '../defendantnotes/defendant-notes-dialog/defendant-notes-dialog.component';
import {
  GoogleReviewSettingDialogComponent
} from '../../../google-review/google-review-setting-dialog/google-review-setting-dialog.component';
import { ConfirmationComponent } from '../../../components/controls/confirmation/confirmation.component';
import {Note} from '../../../models/note';

@Component({
  selector: 'app-defendant-personal',
  templateUrl: './defendantpersonal.component.html',
  styleUrls: ['./defendantpersonal.component.scss'],
  animations: [fader],
})
export class DefendantPersonalComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
  @Input() model: Defendant;
  @Input() fieldsDisabled = true;

  @Output() public profilePicDeleted = new EventEmitter<any>();

  panels: any;
  moreVisible: boolean;
  showMoreText: string;

  popupVisible: any;

  phonePattern: any = AppConstants.PHONE_PATTERN;
  phoneRules: any = AppConstants.PHONE_RULES;

  // ssnRules: any = { X: /[02-9]/ };
  code: any;
  buttonDisabled: any;
  // defaultMugShot: any;

  genders: any;
  races: any;
  heightsFt: any;
  heightsIn: any;
  eyeColors: any;
  hairColors: any;
  residenceTypes: any;
  defendant: Defendant;

  dateFormatter = StringUtilities.dateFormatter;

  showPossibleEmail = false;
  possibleEmailLink: string;
  openDobPickerOnFocus = true;
  googleTextEnabled: boolean;
  firstNameLength = AppEditorLengths.PersonFirstName;
  lastNameLength = AppEditorLengths.PersonLastName;
  middleNameLength = AppEditorLengths.PersonMiddleName;
  isMobile: boolean;
  isExtraSmall: Observable<BreakpointState>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private chRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private eventService: EventService,
    private lookupService: LookupService,
    private billingService: BillingService,
    private notifyService: NotifyService,
    private readonly breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
  ) {
    super();
    this.defendant = new Defendant();
    this.panels = [' '];

    this.moreVisible = false;
    this.showMoreText = 'Show more (+)';

    this.buttonDisabled = true;

    this.loadLookups();

    const currentDevice = devices.current();
    this.openDobPickerOnFocus = !currentDevice.phone;
  }

  ngOnInit() {
    this.isExtraSmall = this.breakpointObserver.observe([Breakpoints.XSmall]);
    this.isMobile = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
    this.eventService.menuChanged('def');
    // // console.log('def model input', this.model);
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_GOOGLE_REVIEW, false)
      .subscribe((subbed: boolean) => {
        this.googleTextEnabled = subbed;
        this.defendant.person.IncludeInGoogleReview = subbed;
      });
    this.defendant = this.model;
    this.showMore();
  }

  ngOnDestroy() {
    this.eventService.menuChanged('nodef');
  }

  showMore(e = undefined) {
    if (!!e) {
      e.preventDefault();
    }
    this.moreVisible = !this.moreVisible;
    if (this.moreVisible) {
      this.showMoreText = 'Show Less (-)';
    } else {
      this.showMoreText = 'Show More (+)';
    }

    // this.chRef.detach();
    this.chRef.detectChanges();
  }

  loadLookups() {
    this.genders = this.lookupService.getLookupStatic('Genders');
    this.races = this.lookupService.getLookupStatic('Races');
    this.heightsFt = this.lookupService.getLookupStatic('HeightsFt');
    this.heightsIn = this.lookupService.getLookupStatic('HeightsIn');
    this.eyeColors = this.lookupService.getLookupStatic('EyeColors');
    this.hairColors = this.lookupService.getLookupStatic('HairColors');
    this.residenceTypes = this.lookupService.getLookupStatic('ResidenceTypes');
  }

  profilePicChanged(e) {
    this.defendant.person.ProfilePicture = e.dataUrl;
    this.defendant.person.ProfilePictureFile = e.file;
  }

  onPhoneChanged(event: any): void {
    if (this.defendant.person.phones.length > 0) {
      this.possibleEmailLink = `https://www.truepeoplesearch.com/details?phoneno=${this.defendant.person.phones[0].Number}&rid=0x0`;
      this.showPossibleEmail = true;
    }
    // const isNew = this.defendant.transactions.reduce<boolean>((added: boolean, transaction: Bail) => transaction.isAdded || added, false);
    // if (this.defendant.person.phones.length > 0 && this.googleTextEnabled && isNew === true) {
    //   this.defendant.person.IncludeInGoogleReview = true;
    // }
  }

  onProfilePicDeleted(){
    this.profilePicDeleted.emit();
  }

  addNote() {
    const config: MatDialogConfig = {
      data: {
        defendant: this.model,
      },
      width: 'calc(100% - 50%)',
      hasBackdrop: false,
      panelClass: 'draggable-dialog',
      disableClose: true,
    };
    if (this.isMobile) {
      config.width = '100%';
      config.height = '100%';
    }
    const dialog = this.dialog.open(DefendantNotesDialogComponent, config);
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        dialog.updateSize('100vw', '100vh');
      } else {
        dialog.updateSize('calc(100% - 50%)', '');
      }
    });


    const closeSub = dialog.afterClosed().subscribe((notes: any[]) => {
      const updatedNotesArr : Note[] = [];
      notes.forEach(note => {
        const updatedNote = new Note();
        updatedNote.loadData(note);
        updatedNote.User = note?.user?.data?.identity?.data?.FirstName + " " + note?.user?.data?.identity?.data?.LastName;
        updatedNotesArr.push(updatedNote);
      });

      this.defendant.transactions[this.defendant.transactions.length - 1].notes = updatedNotesArr;
      this.model.transactions[this.model.transactions.length -1].notes = updatedNotesArr;
      this.eventService.emitNotesWereUpdated();

      smallDialogSubscription.unsubscribe();
      closeSub.unsubscribe();
    });
  }

  addDocuments() {
    const config: MatDialogConfig = {
      data: {
        defendant: this.model,
      },
      width: 'calc(100% - 50px)',
    };
    if (this.isMobile) {
      config.width = '100%';
      config.height = '100%';
    }
    const dialog = this.dialog.open(DocumentDialogComponent, config);
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        dialog.updateSize('100vw', '100vh');
      } else {
        dialog.updateSize('calc(100% - 50px)', '');
      }
    });
    const closeSub = dialog.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
      closeSub.unsubscribe();
    });
  }

  handleChangeReview(enable: boolean) {
    this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_GOOGLE_REVIEW).subscribe(result => {
      if (!result) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
          data: {
            title: 'Enable Google Reviews',
            message: `In order to use this feature you need to subscribe to Google Reviews.`,
          },
          maxWidth: '500px',
        });
        this.subs.sink = dialogRef.afterClosed().subscribe(accept => {
          if (accept) {
            this.openGoogleReview();
          }
        });
      } else {
        this.defendant.person.IncludeInGoogleReview = enable;
      }
    });
  }

  openGoogleReview() {
      const dialogConfig: MatDialogConfig = {
        width: '85%',
        height: '52%',
        hasBackdrop: true,
        disableClose: true,
        data: {
          showCloseButton: true,
        },
      };
      if (this.isExtraSmall) {
        dialogConfig.width = '100%';
        dialogConfig.height = '100%';
      }
    const dialogRef = this.dialog.open(GoogleReviewSettingDialogComponent, dialogConfig);

    dialogRef.beforeClosed().toPromise().then(async(enabled) => {
      if (enabled) {
        this.defendant.person.IncludeInGoogleReview = enabled;
      }
    });
    }

  onDobChange($event: any) {
    if (!!$event.value) {
      this.defendant.person.getAge();
    }
  }
}

