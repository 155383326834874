<div *ngIf='alertData'>
  <div class='row' *ngFor='let personMatch of alertData.personMatches'>
    <h4 class='col-md-12'>Details</h4>
    <div class='col-md-12'>
      <div class='row'>
        <div class='col-md-3'>
          <label class='control-label'>Confidence:</label>
        </div>
        <div class='col-md-9'>{{ personMatch.confidence }}</div>
      </div>
      <div class='row'>
        <div class='col-md-3'>
          <label class='control-label'>Match Rule:</label>
        </div>
        <div class='col-md-9'>{{ personMatch.matchRule.join(', ') }}</div>
      </div>
    </div>

    <h5 class='col-md-12'>Match Criteria</h5>
    <div class='col-md-12'>
      <div class='row' *ngFor='let identifier of personMatch.matchCriteria.identifiers'>
        <div class='col-md-3'>
          <label class='control-label'>{{ (identifier | watchIdentifier).type }}:</label>
        </div>
        <div class='col-md-9'>{{ (identifier | watchIdentifier).value }}</div>
      </div>
    </div>
    <div class='col-md-12' *ngFor='let document of personMatch.documents'>
      <h5>{{ document.type }}</h5>
      <div class='row'>
        <div class='col-md-3'>
          <label class='control-label'>Booking Number:</label>
        </div>
        <div class='col-md-9'>{{ document.bookingNbr }}</div>
      </div>
      <div class='row'>
        <div class='col-md-3'>
          <label class='control-label'>Booking Date:</label>
        </div>
        <div class='col-md-9'>{{ document.bookingDate | date:'MM/d/yyyy' }}</div>
      </div>
      <div class='row'>
        <div class='col-md-3'>
          <label class='control-label'>Inmate Number:</label>
        </div>
        <div class='col-md-9'>{{ document.inmateNbr }}</div>
      </div>
      <div class='row'>
        <div class='col-md-3'>
          <label class='control-label'>Released:</label>
        </div>
        <div class='col-md-9'>{{ document.released }}</div>
      </div>
      <div class='row' *ngIf='document.released === "Y"'>
        <div class='col-md-3'>
          <label class='control-label'>Release Date:</label>
        </div>
        <div class='col-md-9'>{{ document.releaseDate | date:'MM/d/yyyy' }}</div>
      </div>
      <div class='row' *ngIf='document.released === "Y"'>
        <div class='col-md-3'>
          <label class='control-label'>Release Reason:</label>
        </div>
        <div class='col-md-9'>{{ document.releaseReason }}</div>
      </div>
      <div class='row'>
        <div class='col-md-3'>
          <label class='control-label'>Source Agency:</label>
        </div>
        <div class='col-md-9'>{{ document.source.description }}</div>
      </div>
      <h5 class='col-md-12'>Charges</h5>
      <div class='col-md-12' *ngFor='let charge of document.charges'>
        <p>Code: {{ charge.code }}, Description: {{ charge.description }}</p>
      </div>
    </div>
  </div>
</div>
