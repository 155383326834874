import {isNullOrUndefined} from "util";

export module GridUtilities {

  export function getErrorItems(errors: any, title?: any): any {
    let result = [];

    if(typeof(errors) === "string") { // If the error is only string type
      result.push({status_code: -1, detail: errors});
      return result;
    }

    for (let key in errors) {
      let value = errors[key];

      for (let key2 in value) {
        let value2 = value[key2];
        if (title !== undefined) {
          value2 = title + '-' + value2;
        }
        result.push({status_code: -1, detail: value2});
      }
    }
    return result;
  }

  export function getColumnsFromData(data) {
    if (isNullOrUndefined(data) || data.length === 0) {
      return [];
    } else {
      const columns = [];
      const dataToCheck = data[0];
      for (const prop in dataToCheck) {
        const propDataType = (prop.toString()).toLowerCase().indexOf('date') >= 0 ? 'date' : 'string';
        columns.push({dataField: prop.toString(), caption: prop.toString(), dataType: propDataType});
      }
      // console.log('columns', columns);
      return columns;
    }
  }
}
