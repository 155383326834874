export class DashboardCache {
  dateSelectionValue;
  startDateValue;
  endDateValue;
  suretyFilterValue;
  agencyFilterValue;
  agentFilterValue;
  categorySelection;
  quickLinksCollapsed;
  sureties;
  agencies;
  agents;
  sortByDateFilterValue;
}
