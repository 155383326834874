import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullLastnameName',
})
export class FullLastnameNamePipe implements PipeTransform {

  transform(fullname: string): string {
    const tempArray = fullname.split(' ');
    fullname = `${tempArray[tempArray.length - 1]}, `;
    tempArray.splice(tempArray.length - 1, 1);
    fullname += tempArray.join(' ');

    return fullname;
  }

}
