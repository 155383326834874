<div class="message-buttons" *ngIf="showButtons" >
  <a [routerLink]="defMgrLink"
     [queryParams]="{ popup: 'testmessage' }"
     target="_blank"
     rel="noopener noreferrer"
     mat-raised-button
     style="margin-right: 8px" >
    Test Message
  </a>
  <a [routerLink]="defMgrLink" target="_blank" rel="noopener noreferrer" mat-raised-button color="primary">
    Reminder Settings
  </a>
</div>
<div class="row">
  <mat-spinner class="spinner-messages" *ngIf="loadingMessages"></mat-spinner>
  <label [class]="!reminderHistoryFiltered ? 'empty-messages col-xs-12' : 'col-xs-12'">
    Messages with
    <dx-select-box
      #ddlContact
      id="ddlContact"
      text="Message Type"
      [dataSource]="contacts"
      displayExpr="FullName"
      (onValueChanged)="onContactChanged($event)"
      [searchEnabled]="false">
    </dx-select-box>
  </label>
  <div [class]="!reminderHistoryFiltered ? 'empty-messages' : ''">
    <div id="btnSendInfo">
      <button
        mat-raised-button
        color="primary"
        (click)="showSendMessage()"
        [disabled]="!canSendMessage || busySending">
        <i class="fal fa-share-square icon-spacer"></i>
        Send Message
      </button>
    </div>
  </div>
  <div [class]="!reminderHistoryFiltered || reminderHistoryFiltered?.length === 0 ?
  'empty-messages' : ''">
    <mat-list
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="15px"
      class="list-container-messages">
      <ng-container *ngIf="!reminderHistoryFiltered || reminderHistoryFiltered?.length === 0">
        <mat-list-item *ngFor="let message of [1,2,3]" class="message-container reply-message-container">
          <div class="item-message">
            <h3 matLine class="title-message">John Doe (DirectMessage)</h3>
            <p matLine class="message-text">Hello, thank you for signing up for Captira.</p>
            <small class="text-muted text-right">
              1/1/1970 12:00 AM <span class="separator">&middot;</span> Delivered
            </small>
          </div>
        </mat-list-item>
      </ng-container>
      <mat-list-item *ngFor="let message of reminderHistoryFiltered" [ngClass]="message.MessageType !== 'Reply' ? 'sent-message-container' : 'reply-message-container'" class="message-container">
        <div class="item-message"[ngClass]="message.MessageType !== 'Reply' ? 'sent-message' : 'reply-message'">
          <small matLine class="title-message">{{ message.FullName }} ({{ message.MessageType }})</small>
          <p matLine class="message-text">{{ message.Message }}</p>
        </div>
        <small class="text-muted text-right">
          <span class="time-message">{{ message.Created_At | friendlyDate }}<span class="separator">&middot;</span><span>{{message.Status | titlecase}}</span></span>
        </small>
      </mat-list-item>
    </mat-list>
  </div>
</div>

<dx-popup
  #smsPopup
  id="messagePopup"
  [showTitle]="true"
  title="Send Message"
  [closeOnOutsideClick]="false"
  [(visible)]="messagePopupVisible"
  [width]="500"
  [height]="400">
  <div *dxTemplate="let data of 'content'">
    <dx-select-box
      #ddlMessageOption
      id="ddlMessageOption"
      text="Message Option Type"
      [dataSource]="messageOptions"
      displayExpr="Value"
      valueExpr="Id"
      [(visible)]="showManualGpsMessageOption"
      (onValueChanged)="onMessageOptionChanged($event)"
      [searchEnabled]="false"
      [value]="1">
    </dx-select-box>
    <dx-text-area
      #txMessage
      id="txMessage"
      placeholder="Enter message text"
      [disabled]="!canSendMessage"
      maxLength="250"
      height="100px"
      width="100%"
      stylingMode="outlined"
      style="margin-bottom: 16px;">
    </dx-text-area>
    <button
      style="width: 100%"
      mat-raised-button
      color="primary"
      (click)="sendMessage()"
      [disabled]="!canSendMessage || busySending">
      <i class="fal fa-share-square icon-spacer"></i>
      Send Message
    </button>
    <span *ngIf="!canSendMessage">{{ disabledReason }}</span>
  </div>
</dx-popup>

<dx-popup
  [showTitle]="'true'"
  [title]="'Subscribe to Reminder Service'"
  [closeOnOutsideClick]="'false'"
  [(visible)]="showSubscribeConfirmation"
  [maxWidth]="'650px'"
  maxHeight="300px"
  [showCloseButton]="'false'">
  <div *dxTemplate="let data of 'content'">
    <p class="text-center" *ngIf="!showBuyNumber">
      You are enabling automated reminders.
      There is a per message cost depending on the type of reminder.
      Click subscribe to continue.
    </p>
    <br>
    <div *ngIf="showBuyNumber">
      <app-reminder-number-buyer
        (onSuccess)="numberBought()">
      </app-reminder-number-buyer>
    </div>
    <br>
    <div class="text-center" *ngIf="!showBuyNumber">
      <dx-button
        id="cancel"
        type="normal"
        [text]="'Never Mind'"
        style="margin-right: 8px;"
        (onClick)="YesNoConfirmResult(false)">
      </dx-button>
      <dx-button
        id="save"
        type="default"
        [text]="'Subscribe Now'"
        icon="fal fa-check"
        (onClick)="YesNoConfirmResult(true)">
      </dx-button>
    </div>
  </div>
</dx-popup>
