import {BaseModel} from './BaseModel';

export class Link extends BaseModel {
  public Id: string;
  public Name: string;
  public Description: string;
  public LinkUrl: string;
  public LinkPassword: string;
  public SourceId: string;
  public SourceType: string;

  constructor(obj?: object) {
    super(obj);
    this.routeRoot = 'links';
    if (obj == null) {
      this.Id = '';
    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);
  }
}
