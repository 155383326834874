import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RekognitionService {

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  compareFaces(data): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/facialrekognition/perform`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error || 'Server Error')),);
  }

  compareFacesForDemo(data): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/facialrekognition/perform/demo`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error || 'Server Error')),);
  }

}
