import { IgoogleReview } from './Igoogle-review';
import { BaseModel } from './BaseModel';

export class GoogleReview extends BaseModel implements IgoogleReview {
  // tslint:disable:variable-name
  AgencyId: string;
  CustomerId: string;
  PersonId: string;
  SentTime: Date;
  Status: 'Failed' | 'Pending' | 'Sent';


  loadData(data) {
    this.fillFromObject(data, true);
  }
}
