
import {map, catchError} from 'rxjs/operators';
import {throwError,  Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {Phone} from '../models/phones';
import {HttpClient} from '@angular/common/http';
@Injectable()
export class PhoneService {

  private postData: any;
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  post(phone: any): Observable<any> {

    return this.http.post(`${this.settings.getBaseUrl()}/phones`, phone).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  get(id): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/phone/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getForSource(id: number, sourceType: string): Observable<Phone[] | any> {
    return this.http.get(`${this.settings.getBaseUrl()}/phone/bysource/${id}/${sourceType}`).pipe(
      map((res: Observable<Phone[]>) => this.getArrayFromJson(res)),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getDefaultForSource(id: number, sourceType: string): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/phone/defaultforsource/${id}/${sourceType}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getArrayFromJson(response: Observable<Phone[]>) {
    response.subscribe(sc => {
      const phones = new Array<Phone>();

      for (let _i = 0; _i < sc.length; _i++) {
        phones.push(new Phone(length[_i]));

      }
      return phones;
    });
  }


}
