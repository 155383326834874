import { Action, createReducer, on } from '@ngrx/store';
import { Message } from '../../models/message';
import { loadMessages } from '../actions/messages.actions';

export const messageFeatureKey = 'messages';

export interface MessageState {
  messages: Message;
}

export const initialState: MessageState = {
  messages: null,
};

export const messageReducer = createReducer(
  initialState,
  on(loadMessages, (state, { messages }) => ({...state, messages: { ...messages }})),
);

export function reducer(state: MessageState | undefined, action: Action) {
  return messageReducer(state, action);
}
