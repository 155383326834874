import {BaseModel} from './BaseModel';

export class Note  extends BaseModel{
  public Id: string;
  public User: string;
  public Subject: string;
  public Text: string;
  public Date: any;
  public SourceId: any;
  public SourceType: any;
  public NoteStamp: any;

  constructor(obj?: object)
  {
    super(obj);
    this.routeRoot = 'notes';
    this.propertiesToIgnoreForCheckChanges.push('User');

   if (obj == null) {
      this.Id = '';

    }


  }

  public loadData(note: any) {
    this.fillFromObject(note, true);
  }
}
