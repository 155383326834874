import { Address } from './address';
import { BaseModel } from './BaseModel';

// tslint:disable:variable-name
export class DatamartRequest extends BaseModel {

    // public FullName: string;
    public FirstName: string;
    public LastName: string;
    public ReferenceAlias: string;
    public FirstRecord: string;
    public RecordCount: string;
    public StreetAddress: string;
    public Address: Address[] = [];
    public City: string;
    public State: string;
    public ZipCode: string;
    public SocialSecurityNumber: string;
    public DateOfBirth: string;
    public PhoneNumber: string;
    public Email: string;
    public NotificationEmail: string;
    public PlateNumber: string;
    public NoSSN = false;
    public SSNAlternative: any;
    public DriverLicense: string;

    constructor(obj?: object) {
        super(obj);

        if (obj == null) {
        }

    }
}
