import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum ExportType {
  CSV = 'csv',
  EXCEL = 'excel',
}

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent implements OnInit {

  public readonly ExportType = ExportType;

  constructor(public dialogRef: MatDialogRef<ExportDialogComponent>) { }

  ngOnInit(): void {
  }

}
