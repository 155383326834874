import {BaseModel} from './BaseModel';
import {Checkin} from './checkin';
import {StringUtilities} from '../shared/StringUtilities';
import getCurrentDateString = StringUtilities.getCurrentDateString;

export class CheckInSchedule extends BaseModel {

  Id: any;
  DefendantId: any;
  StartDate: any;
  EndDate: any;
  Period: string;
  IntervalType: string;
  IntervalCount: number;
  CustomerId: number;
  CheckInScheduleDayOne: number;
  CheckInScheduleDayTwo: number;
  CheckInIntervalDaysOfWeek: string;
  MaxCheckInDistance: number;
  details: Checkin[];

  constructor(obj?: object) {
    super(obj);
    this.childrenNames = ['details'];
    this.routeRoot = 'checkinschedule';
    this.propertiesToIgnoreForCheckChanges.push('details');

    if (obj == null) {

      this.StartDate = getCurrentDateString();
      this.DefendantId = -1;
      this.EndDate = getCurrentDateString();
      this.IntervalCount = 1;
      this.CheckInScheduleDayOne = 1;
      this.details = new Array<Checkin>();
      this.MaxCheckInDistance = 60;

    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);

    if (data.details && data.details.data) {
      this.details.length = 0;
      data.details.data.forEach(detail => {
        const checkin = new Checkin();
        checkin.loadData(detail);
        this.details.push(checkin);
      });
    }
  }

}
