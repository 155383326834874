<div *ngIf="
  (searchKeyword !== undefined
  && searchKeyword !== null
  && searchResults !== undefined
  && searchResults !== null
  && searchKeyword !== ''
  && searchResults.length !== 0)">
  <div class="text-right" *ngIf="selectMode">
    <button mat-icon-button (click)="hideSearch()">
      <i class="fal fa-times"></i>
    </button>
  </div>

  <dx-list
    class="mat-elevation-z3"
    [dataSource]="searchData"
    [grouped]="true"
    groupTemplate="group"
    itemTemplate="item">
    <div *dxTemplate="let data of 'group'">
      <div *ngIf="data.key.toString().indexOf('NORESULT') > -1; else valid_results">
        <i class="fal fa-users" aria-hidden="true"></i>
        Try Something Else
      </div>
      <ng-template #valid_results>
        <i class="fal fa-users" aria-hidden="true"></i>
        {{ data.key.substring(data.key.indexOf('_') + 1) }}
        ({{ searchCounts[data.key.substring(data.key.indexOf('_') + 1)] }})
      </ng-template>
    </div>

    <div *dxTemplate="let data of 'item'">
      <div class="results-row">
        <span class="results-content"
          (mousedown)="openRecord(data)">
          <span class="text-danger"
            *ngIf="data.Source.toString().indexOf('ERROR') > -1 || data.Source.toString().indexOf('NORESULT') > -1">
            {{ data.ErrorMessage }}<button mat-icon-button class='error-message' (click)="handleAdvancedSearchClick()"><i class="fal fa-search-plus"></i></button>
          </span>
          <i class="fas fa-sync switch-icon" *ngIf='!!data.CustomerId && data.CustomerId !== activeCustomerId'></i>
          <img *ngIf="data.ProfilePicture !== undefined && data.ProfilePicture !== null"
            class="profile-thumbnail"
            style="margin-right: 10px;"
            src="{{ data.ProfilePicture }}" />

          <span *ngIf="data.Source.toString().indexOf('DEFENDANT') > -1">
            <strong>{{ data.FirstLast }}</strong> &middot;
            <small class="text-muted">{{ data.Dob | date: 'MM/dd/yyyy' }}</small>
          </span>

          <span *ngIf="data.Source.toString().indexOf('RECEIPT') > -1">
            <strong>{{ data.FirstLast }}</strong> &middot;
            <small class="text-muted">{{ data.Dob | date: 'MM/dd/yyyy' }}</small>
          </span>

          <span *ngIf="data.Source.toString().indexOf('COLLECTIONS') > -1">
              <strong>{{ data.FullName }}</strong> &middot;
              <small>{{data.InvoiceReference}}</small>&middot;
              <small class="text-muted">{{ (data.InvoiceOriginalAmount || 0) | currency }} ({{ data.InvoiceDate | date: 'MM/dd/yyyy' }})</small> &middot;
              <strong>{{ data.Status }}</strong>
          </span>

          <span *ngIf="data.Source.toString().indexOf('CONTACT') > -1">
            <strong>{{data.FullName}}</strong> &middot;
            <small class="text-muted">{{ data.Dob | date: 'MM/dd/yyyy' }}</small> |
            <strong>
              {{ data.DefendantName  }}
            </strong>
          </span>

          <span *ngIf="data.Source.toString().indexOf('POWER') > -1">
            <label class="control-label">Power</label>
            {{ data.PowerNumber }}
            <label class="control-label" *ngIf="data.DefendantName != null">Defendant</label>
            {{ data.DefendantName }}
          </span>

          <span *ngIf="data.Source.toString().indexOf('BOND') > -1">
            <label class="control-label">{{ data.CaseNumber ? 'Case Number' : 'File Number' }}</label>
            {{ data.CaseNumber || data.FileNumber }}
            <label class="control-label">Defendant</label>
            {{ data.DefendantName }}
          </span>
        </span>
        <span class="results-actions" *ngIf="!selectMode && (data.Source.toString().indexOf('DEFENDANT') > -1 || (data.Source.toString().indexOf('BOND') > -1 && data.TransactionId) || (data.Source.toString().indexOf('CONTACT') > -1 && data.TransactionId) || data.Source.toString().indexOf('RECEIPT') > -1)">
          <button (click)="openRecord(data)" mat-icon-button matTooltip="Open Record">
            <i class="fas fa-clipboard-user fa-fw fa-lg"></i>
          </button>
          <button (click)="receivePayment(data)" mat-icon-button matTooltip="Take Payment">
            <i class="fas fa-usd-circle fa-fw fa-lg"></i>
          </button>
          <button (click)="recordCheckIn(data)" mat-icon-button matTooltip="Check In">
            <i class="fas fa-user-check fa-fw fa-lg"></i>
          </button>
        </span>

        <span class="results-actions" *ngIf="!selectMode &&data.Source.toString().indexOf('POWER') > -1">
          <button (click)="openRecord(data)" mat-icon-button matTooltip="Open Record">
            <i class="fas fa-clipboard-user fa-fw fa-lg"></i>
          </button>
          <button (click)="receivePayment(data)" mat-icon-button matTooltip="Take Payment">
            <i class="fas fa-usd-circle fa-fw fa-lg"></i>
          </button>
          <button (click)="recordCheckIn(data)" mat-icon-button matTooltip="Check In">
            <i class="fas fa-user-check fa-fw fa-lg"></i>
          </button>
        </span>
      </div>
    </div>
  </dx-list>
<!--  <div class='extended-search-box' *ngIf='searchKeyword !== "recent"'>-->
<!--    <mat-checkbox-->
<!--      class='extended-check-box'-->
<!--      [(ngModel)]="extendedSearch"-->
<!--      (change)='fireExtendedSearch($event)'-->
<!--      color='primary'-->
<!--    >-->
<!--      Extended Defendant Search-->
<!--    </mat-checkbox>-->
<!--  </div>-->
</div>
