<mat-card class="mat-elevation-z3">
  <mat-card-title>Advanced Search</mat-card-title>
  <mat-card-content>
    <form fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutAlign.xs="space-around stretch" fxLayoutGap="16px">
        <div>
          <label class='control-label'></label>
          <dx-select-box
            [dataSource]='moduleOptions'
            [(value)]="searchRequest.searchModule"
            label='Module'
            valueExpr="value"
            displayExpr="name"
            (valueChange)='handleModuleChange($event)'
          ></dx-select-box>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutAlign.xs="space-around stretch" fxLayoutGap="16px">

        <div>
          <label class='control-label'>Search By</label>
          <dx-select-box
            [dataSource]='searchOptions[searchRequest.searchModule]'
            [(value)]="searchRequest.searchBy"
            label='Search By'
            valueExpr="value"
            displayExpr="name"
          ></dx-select-box>
        </div>

        <ng-container *ngIf='searchRequest.searchBy !== "dob"; else dobSearch'>
          <div>
            <label class='control-label'>Search For</label>
            <dx-text-box
              [(value)]='searchRequest.searchValue'
            ></dx-text-box>
          </div>
        </ng-container>
        <ng-template #dobSearch>
          <div>
          <label class='control-label'>Search Value</label>
          <dx-date-box
            [(value)]="searchRequest.searchValue"
            type="date"
            [max]='maxDate'
            invalidDateMessage="Please enter a valid date of birth"
            [displayFormat]="dateFormatter"
            dateSerializationFormat="yyyy-MM-dd"
            [openOnFieldClick]="openDobPickerOnFocus">
          </dx-date-box>
          </div>
        </ng-template>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions align='end'>
    <button mat-stroked-button color='primary' (click)='closeSearch.emit(false)'>Cancel</button>
    <button mat-raised-button color="primary" [disabled]='loading' type='submit' (click)='handleSearch()'>Search</button>
  </mat-card-actions>
</mat-card>
