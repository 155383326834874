import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { DuplicateListItemDialogComponent } from './duplicate-list-item-dialog/duplicate-list-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable, Subscriber } from 'rxjs';
import { get } from 'lodash';
import { WarningDialogComponent } from '../../../components/controls/warning-dialog/warning-dialog.component';

export class BaseList extends UnsubscribeOnDestroyAdapter {

  fromLookup: boolean = false;
  text: string = '';
  gridLoading = false;
  
  constructor(public dialog: MatDialog) {
    super();
  }

  /**
   * isDuplicate is used to check if the values to be inserted are duplicates of the values already in the CustomStore.
   * @param type: The type of List item. This is used in the warning message if any duplicates are found.
   * @param values: The proposed values that will be inserted into the CustomStore.
   * @param dataGrid: The current DxDataGrid to insert into
   * @param keys: A list of keys that should be checked for duplicates.
   * @param allowed: Are duplicate valued allowed?
   * @param title: Title of error message shown if duplicates are not allowed.
   * @param message: Message shown if duplicates are not allowed.
   */
  public isDuplicate(
    type: string,
    values: any,
    dataGrid: DxDataGridComponent,
    keys: string[] = ['Name'],
    allowed: boolean = true,
    title?: string | null,
    message?: string | null,
  ): Observable<boolean> {
    const items = dataGrid.instance.getDataSource().items();
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      const duplicates = items.some(item => {
        let dupCount = 0;
        for (const key of keys) {
          if ((get(item, key) && get(item, key).toLowerCase()) === (get(values, key) && get(values, key).toLowerCase())) {
            // We need to check the Id to make sure that we aren't updating
            if ((get(item, 'Id') && get(item, 'Id')) !== (get(values, 'Id') && get(values, 'Id'))) {
              dupCount++;
            }
          }
        }
        return dupCount === keys.length;
      });
      if (duplicates) {
        if (allowed) {
          this.subs.sink = this.showConfirmationDialog(type, get(values, keys[0])).subscribe((response: boolean) => {
            subscriber.next(response);
            subscriber.complete();
          });
        } else {
          this.dialog.open(WarningDialogComponent, {
            data: { title: title, message: message },
            maxWidth: '500px',
          });
          subscriber.next(true);
          subscriber.complete();
        }
      } else {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  /**
   * isDuplicateInList is used in the lookup component to check for existing values in a list on the fly.
   * @param type: The type of List item. This is used in the warning message if any duplicates are found.
   * @param value: The proposed value that will be inserted into the List.
   * @param items: The list of items to check for duplicate.
   * @param allowed: Whether duplicates are allowed in the list. Defaults to true;
   * @param title: The title of the warning dialog shown to user if duplicate is found and allowed is false.
   * @param message: The message shown to the user if duplicate is found and allowed is false.
   */
  public isDuplicateInList(
    type: string,
    value: string,
    items: any[],
    allowed: boolean = true,
    title?: string | null,
    message?: string | null,
  ): Observable<boolean> {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      try {
        const duplicates = items.some(item => item.Text.toLowerCase() === value.toLowerCase());
        if (duplicates) {
          if (allowed) {
            // this.subs.sink = this.showConfirmationDialog(type, value).subscribe((response: boolean) => {
              subscriber.next(true);
              subscriber.complete();
            // });
          } else {
            this.dialog.open(WarningDialogComponent, {
              data: { title: title, message: message },
              maxWidth: '500px',
            });
            subscriber.next(true);
            subscriber.complete();
          }
        } else {
          subscriber.next(false);
          subscriber.complete();
        }
      } catch (exception) {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private showConfirmationDialog(type: string, value: string): Observable<boolean> {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      const dialogRef = this.dialog.open(DuplicateListItemDialogComponent, {
        data: { type, adding: value },
      });
      this.subs.sink = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          subscriber.next(false);
          subscriber.complete();
        } else {
          subscriber.next(true);
          subscriber.complete();
        }
      });
    });
  }

  /**
   * Used to check if we want to show the add popup, will become true when we open lists from a lookup component
  */
  public isFromLookup() {
    return this.fromLookup;
  }


  /**
   * Just used to reset the the properties fromLookup and text to default after user closes popup
  */
  public resetAddFromPopup() {
    this.fromLookup = false;
    this.text = '';
  }

}
