import { Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DuplicateListItemDialogData {
  type: string;
  adding: string;
}

@Component({
  selector: 'app-duplicate-list-item-dialog',
  templateUrl: './duplicate-list-item-dialog.component.html',
  styleUrls: ['./duplicate-list-item-dialog.component.scss'],
})
export class DuplicateListItemDialogComponent implements OnInit, OnDestroy {

  private element: HTMLElement;

  constructor(
    public dialogRef: MatDialogRef<DuplicateListItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DuplicateListItemDialogData,
    private viewContainerRef: ViewContainerRef,
  ) { }

  public ngOnInit(): void {
    this.element = this.viewContainerRef.element.nativeElement;
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1505';
  }

  public ngOnDestroy(): void {
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1000';
  }

}
