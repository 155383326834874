<div class="defendantwatch-prompt" *ngIf="showPrompt$ | async">
    <button mat-button color="primary" (click)="openDefendantWatch()">
        <span>Get notified of defendant arrests nearly anywhere! Click here.</span>
    </button>
    <div class="dont-show-again-wrapper">
        <a (click)="dontShowDefendantWatchPopUp()">
            Don't Show Again
        </a>
    </div>
</div>

<button *ngIf="promptSource=='DefendantWatch'" mat-raised-button color="primary" (click)='openDefendantWatch()'>
  Open Defendant Watch Settings
</button>
