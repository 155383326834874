<h2 mat-dialog-title>Reminder Settings</h2>
<mat-dialog-content class="adjust-height">
    <label class='control-label'>Select Time</label>
    <dx-select-box
      [disabled]="isLoading"
      [dataSource]="timeSlots"
      displayExpr="Text"
      valueExpr="Id"
      [(value)]="systemConfig.ReminderTime"
      [readOnly]="false">
    </dx-select-box>
</mat-dialog-content>
<div
style="margin-top:2rem;"
class="actions-bottom"
fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px"
fxLayout.xs="column" fxLayoutAlign.xs="space-between stretch" fxLayoutGap.xs="16px"
>
    <button [disabled]="isLoading" (click)="saveConfig()" mat-raised-button color="primary"
    >
        Save
    </button>
    <button [disabled]="isLoading" mat-stroked-button [mat-dialog-close]="false" color="primary"
    >
        Cancel
    </button>
</div>
