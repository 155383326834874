import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {Employer} from '../models/employer';
import {Vehicle} from '../models/vehicle';
import {Person} from '../models/person';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()
export class BondImportService {
  private headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Accept', 'application/json');

  public keyBindings = {
    UniqueDefTransId: {
      friendlyName: 'UniqueDefTransId',
      for: 'UNIQUENESS',
    },
    UniqueDefId: {
      friendlyName: 'UniqueDefId',
      for: 'UNIQUENESS',
    },
    'Defendant First Name': {
      friendlyName: 'Defendant First Name',
      key: 'First',
      isRequired: true,
      isRequiredForDefWatch: true,
      for: 'DEFENDANT',
    },
    'Defendant Last Name': {
      friendlyName: 'Defendant Last Name',
      key: 'Last',
      isRequired: true,
      isRequiredForDefWatch: true,
      for: 'DEFENDANT',
    },
    'Defendant DOB': {
      friendlyName: 'Defendant DOB',
      key: 'Dob',
      isRequired: true,
      isRequiredForDefWatch: true,
      for: 'DEFENDANT',
      type: 'DATE',
    },
    'Defendant SSN': {
      friendlyName: 'Defendant SSN',
      key: 'Ssn',
      isRequired: false,
      for: 'DEFENDANT',
    },
    'Defendant Address': {
      friendlyName: 'Defendant Address',
      key: 'AddressLine1',
      isRequired: false,
      for: 'DEFENDANT.PERSONS.ADDRESSES',
    },
    'Defendant City': {
      friendlyName: 'Defendant City',
      key: 'City',
      isRequired: false,
      for: 'DEFENDANT.PERSONS.ADDRESSES',
    },
    'Defendant County': {
      friendlyName: 'Defendant County',
      key: 'County',
      isRequired: false,
      for: 'DEFENDANT.PERSONS.ADDRESSES',
    },
    'Defendant State': {
      friendlyName: 'Defendant State',
      key: 'StateCode',
      isRequired: false,
      for: 'DEFENDANT.PERSONS.ADDRESSES',
    },
    'Defendant Zip': {
      friendlyName: 'Defendant Zip',
      key: 'Zip',
      isRequired: false,
      for: 'DEFENDANT.PERSONS.ADDRESSES',
    },
    'Defendant Mobile Number': {
      friendlyName: 'Defendant Mobile Number',
      key: 'Number',
      isRequired: false,
      for: 'DEFENDANT.PERSONS.PHONES',
    },
    'Defendant Email': {
      friendlyName: 'Defendant Email',
      key: 'Email',
      isRequired: false,
      for: 'DEFENDANT.PERSONS.EMAILS',
    },
    'Posted Date': {
      friendlyName: 'Posted Date',
      key: 'PostedDate',
      isRequired: true,
      for: 'TRANSACTIONS.PARENT',
      type: 'DATE',
    },
    Status: {
      friendlyName: 'Status',
      key: 'Status',
      isRequired: false,
      for: 'TRANSACTIONS.PARENT',
    },
    'Bond Amount': {
      friendlyName: 'Bond Amount',
      key: 'Amount',
      isRequired: true,
      for: 'TRANSACTIONS.BONDS',
    },
    'Case Number': {
      friendlyName: 'Case Number',
      key: 'CaseNumber',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS',
    },
    'Bond County': {
      friendlyName: 'Bond County',
      key: 'County',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS',
    },
    'Bond State': {
      friendlyName: 'Bond State',
      key: 'StateCode',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS',
    },
    'Bond Charge': {
      friendlyName: 'Bond Charge',
      key: 'Charge',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS',
    },
    'Forfeiture Date': {
      friendlyName: 'Forfeiture Date',
      key: 'ForfeitureDate',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS',
      type: 'DATE',
    },
    'Exoneration Date': {
      friendlyName: 'Exoneration Date',
      key: 'DischargeOccurrenceDate',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS',
      type: 'DATE',
    },
    'Indemnitor First Name': {
      friendlyName: 'Indemnitor First Name',
      key: 'First',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS',
    },
    'Indemnitor Last Name': {
      friendlyName: 'Indemnitor Last Name',
      key: 'Last',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS',
    },
    'Indemnitor DOB': {
      friendlyName: 'Indemnitor DOB',
      key: 'Dob',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS',
      type: 'DATE',
    },
    'Indemnitor SSN': {
      friendlyName: 'Indemnitor SSN',
      key: 'Ssn',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS',
    },
    'Indemnitor Address': {
      friendlyName: 'Indemnitor Address',
      key: 'AddressLine1',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS.ADDRESSES',
    },
    'Indemnitor City': {
      friendlyName: 'Indemnitor City',
      key: 'City',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS.ADDRESSES',
    },
    'Indemnitor County': {
      friendlyName: 'Indemnitor County',
      key: 'County',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS.ADDRESSES',
    },
    'Indemnitor State': {
      friendlyName: 'Indemnitor State',
      key: 'StateCode',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS.ADDRESSES',
    },
    'Indemnitor Zip': {
      friendlyName: 'Indemnitor Zip',
      key: 'Zip',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS.ADDRESSES',
    },
    'Indemnitor Phone': {
      friendlyName: 'Indemnitor Phone',
      key: 'Number',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS.PHONES',
    },
    'Indemnitor Email': {
      friendlyName: 'Indemnitor Email',
      key: 'Email',
      isRequired: false,
      for: 'TRANSACTIONS.CONTACTS.PERSONS.EMAILS',
    },
    Note: {
      friendlyName: 'Note',
      isRequired: false,
      key: 'Text',
      for: 'TRANSACTIONS.NOTES',
    },
    'Cash Collateral Balance': {
      friendlyName: 'Cash Collateral Balance',
      isRequired: false,
      key: 'Value',
      for: 'TRANSACTIONS.COLLATERALS',
    },
    'Court Date': {
      friendlyName: 'Court Date',
      isRequired: false,
      key: 'CourtDates',
      for: 'TRANSACTIONS.BONDS.COURTDATES',
      type: 'DATE',
    },
    'Court Time': {
      friendlyName: 'Court Time',
      isRequired: false,
      key: 'CourtDates',
      for: 'TRANSACTIONS.BONDS.COURTDATES',
      type: 'TIME',
    },
    'Court Name': {
      friendlyName: 'Court Name',
      isRequired: false,
      key: 'Name',
      for: 'TRANSACTIONS.BONDS.COURTDATES',
    },
    Balance: {
      friendlyName: 'Balance',
      isRequired: false,
      key: 'Amount',
      for: 'TRANSACTIONS.BONDS.FEES',
    },
    Agency: {
      friendlyName: 'Agency',
      key: 'Name',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS.AGENCIES',
    },
    Agent: {
      friendlyName: 'Agent',
      key: 'FullName',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS.AGENTS.PERSONS',
    },
    Jail: {
      friendlyName: 'Jail',
      key: 'Name',
      isRequired: false,
      for: 'TRANSACTIONS.BONDS.JAILS',
    },
  };


  constructor(private http: HttpClient) {
  }

  public submitBondImportData(data) {
    // console.log('submitting to bond import', data);
    return this.http.post(`${environment.baseApi}/bondimport`, data, {headers: this.headers}).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  public getRequiredKeys(forDefWatch = false) {
    const requiredKeys = [];
    for (const key in this.keyBindings) {

      if (
        (this.keyBindings[key].hasOwnProperty('isRequired') &&
          this.keyBindings[key].isRequired && !forDefWatch) || (this.keyBindings[key].hasOwnProperty('isRequiredForDefWatch') &&
        this.keyBindings[key].isRequiredForDefWatch && forDefWatch)
      ) {
        requiredKeys.push(this.keyBindings[key]);
      }
    }
    return requiredKeys;
  }

  // Check if the column is of type DATE
  public isTypeDate(friendlyName) {
    return (
      !!this.keyBindings[friendlyName] &&
      this.keyBindings[friendlyName].hasOwnProperty('type') &&
      this.keyBindings[friendlyName]['type'] === 'DATE'
    );
  }

  // Check if the column is of type TIME
  public isTypeTime(friendlyName) {
    return (
      !!this.keyBindings[friendlyName] &&
      this.keyBindings[friendlyName].hasOwnProperty('type') &&
      this.keyBindings[friendlyName]['type'] === 'TIME'
    );
  }

  // Default contact object
  public getContactObject() {
    const obj = {};
    obj['Id'] = '';
    obj['person'] = new Person();
    obj['vehicles'] = new Vehicle();
    obj['employers'] = new Employer();
    obj['routeRoot'] = 'contacts';
    return obj;
  }

  // Default collateral object
  public getCollateralObject() {
    const obj = {};
    obj['Id'] = '';
    obj['addresses'] = [];
    obj['routeRoot'] = 'collaterals';
    obj['Status'] = 'Open';
    obj['Type'] = 'Cash';
    obj['currentState'] = '.valid.added.dirty';
    return obj;
  }

  // Default note object
  public getNoteObject() {
    const obj = {};
    obj['Id'] = '';
    obj['routeRoot'] = 'notes';
    obj['Subject'] = 'Import Note';
    obj['SourceType'] = 'DefendantTransactions';
    obj['currentState'] = '.valid.added.dirty';
    return obj;
  }

  // Default court object
  public getCourtObject() {
    const obj = {};
    obj['ClientNotified'] = false;
    obj['routeRoot'] = 'courts';
    return obj;
  }

  // Default court object
  public getJailObject() {
    const obj = {};
    obj['ClientNotified'] = false;
    obj['routeRoot'] = 'jails';
    return obj;
  }

  // Default address object
  public getAddressObject() {
    const obj = {};
    obj['IsDefault'] = true;
    obj['IsBad'] = false;
    obj['IsPhysical'] = false;
    obj['routeRoot'] = 'addresses';
    return obj;
  }

  // Default phone object
  public getPhoneObject() {
    const obj = {};
    obj['IsDefault'] = true;
    obj['Number'] = '';
    obj['PhoneType'] = '';
    obj['routeRoot'] = 'phones';
    return obj;
  }

  // Default email object
  public getEmailObject() {
    const obj = {};
    obj['IsDefault'] = true;
    obj['Email'] = '';
    obj['EmailType'] = '';
    obj['SourceType'] = 'Persons';
    obj['routeRoot'] = 'emails';
    return obj;
  }

  // Get fee object
  public getFeeObject() {
    const obj = {};
    obj['FeeId'] = '';
    obj['Description'] = 'Bond Premium';
    obj['BondId'] = '';
    obj['TransactionId'] = '';
    obj['Amount'] = '';
    obj['currentState'] = '.valid';
    obj['Date'] = new Date().toISOString().slice(0, 10);
    return obj;
  }

  // Get agent object
  public getAgentObject() {
    let obj = {};
    obj = {
      CommissionUseGraduations: false,
      person: {
        data: {
          Id: '',
          isAdded: true,
          phones: {data: []},
          emails: {data: []},
          First: '',
          Last: '',
        },
      },
      Inactive: false,
      bufGraduatedRates: [],
    };
    return obj;
  }

  // Get agent object
  public getAgencyObject() {
    let obj = {};
    obj = {
      Name: '',
    };
    return obj;
  }

}
