import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from './navigation.component';
import { UserService } from '../../../services/user.service';
import { BillingService } from '../../../services/billing.service';
import { SystemConfigService } from '../../../services/systemconfig.service';
import { StorageService } from '../../../services/storage.service';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from '../../../shared/shared.module';
import { CustomExpansionToggleDirective } from './custom-expansion-toggle.directive';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { NavFilterPipe } from '../../../shared/nav-filter.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    NavigationComponent,
    CustomExpansionToggleDirective,
    NavFilterPipe,
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatListModule,
    MatTooltipModule,
    MatExpansionModule,
    SharedModule,
    DxScrollViewModule,
    FontAwesomeModule,
  ],
  exports: [NavigationComponent],
  providers: [
    BillingService,
    UserService,
    SystemConfigService,
    MatExpansionModule,
    StorageService,
  ],
})
export class NavigationModule { }
