import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DynamicScriptService {

  private scripts: DynamicScripts = {
    Elavon: {src: environment.elavonUrl, loaded: false, status: '', nameSpace: 'PayWithConverge'},
    AuthDotNet: {src: environment.authDotNetUrl, loaded: false, status: '', nameSpace: 'PayWithConverge'},
    Clover: {src: environment.cloverSdkUrl, loaded: false, status: '', nameSpace: 'Clover'},
    GoogleTranslate: {src: environment.google.translate.url, loaded: false, status: '', nameSpace: 'GoogleTranslate'},
    AmericanSpirit: {src: environment.americanSpiritUrl, loaded: false, status: '', nameSpace: 'CollectJS'},
  };

  constructor(@Inject(DOCUMENT) private document: Document) { }

  /**
   * The load method takes the name of the script that you want to add to the page and adds it to the header tag. The Observable
   * will fire once the script has completed loading. If the script had already been loaded on the page the Observable will
   * fire immediately.
   * @param name The name of the script to load. This comes from the scripts attribute above.
   */
  load(name: string): Observable<DynamicScript> {
    return this.loadScript(name);
  }

  /**
   * The loadWithToken method takes the name of the script that you want to add to the page and adds it to the header tag. The Observable
   * will fire once the script has completed loading. If the script had already been loaded on the page the Observable will
   * fire immediately.
   * @param name The name of the script to load. This comes from the scripts attribute above.
   * @param token The token used to set the data-tokenization-key attribute on the script tag.
   */
  loadWithToken(name: string, tokens: DynamicScriptToken[]): Observable<DynamicScript> {
    return this.loadScript(name, tokens);
  }

  private loadScript(name: string, tokens:  DynamicScriptToken[] = []): Observable<DynamicScript> {
    return new Observable<DynamicScript>(subscriber => {
      // If the script has already been loaded just return the Script
      if (this.scripts[name].loaded) {
        subscriber.next(this.scripts[name]);
      } else {
        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        for (const token of tokens) {
          script.setAttribute(token.key, token.value);
        }
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          this.scripts[name].status = 'Loaded';
          subscriber.next(this.scripts[name]);
        };
        script.onerror = (error: any) => {
          this.scripts[name].loaded = false;
          this.scripts[name].status = error;
          subscriber.error(this.scripts[name]);
        };
        this.document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

}

export interface DynamicScript {
  src: string;
  nameSpace: string;
  loaded: boolean;
  status: string;
}

export interface DynamicScripts {
  [script: string]: DynamicScript;
}

export interface DynamicScriptToken {
  key: string;
  value: string;
}
