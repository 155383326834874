import { Component, Inject, OnInit } from '@angular/core';
import { StringUtilities } from '../../../../shared/StringUtilities';
import { UnsubscribeOnDestroyAdapter } from '../../../../common/UnsubscribeOnDestroy';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DefendantWatchService } from '../../../../services/defendantwatch.service';

export interface DefendantExonerationData {
  defendantId: string;
}

@Component({
  selector: 'app-defendant-exoneration',
  templateUrl: './defendant-exoneration.component.html',
  styleUrls: ['./defendant-exoneration.component.scss'],
})
export class DefendantExonerationComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  exonerationDate: any;
  dateFormatter = StringUtilities.dateFormatter;
  saveButtonColor: 'primary' | '' = 'primary';
  busySaving = false;

  constructor(
    public dialogRef: MatDialogRef<DefendantExonerationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DefendantExonerationData,
    private defendantWatchService: DefendantWatchService,
  ) {
    super();
    this.checkDate = this.checkDate.bind(this);
  }

  ngOnInit(): void {
  }

  checkDate(e): boolean {
    if (moment(e.value, 'YYYY-MM-DD').isAfter()) {
      this.toggleSave(true);
      return false;
    }
    this.toggleSave(false);
    return true;
  }

  toggleSave(value: boolean) {
    this.busySaving = value;
    this.saveButtonColor = value ? '' : 'primary';
  }

  saveAndClose() {
    try {
      this.toggleSave(true);
      const dateToSave = moment(this.exonerationDate).format('YYYY-MM-DD');
      this.subs.sink = this.defendantWatchService.exonerateDefendant(this.data.defendantId, dateToSave).subscribe(response => {
        this.dialogRef.close(true);
      });
    } catch (exception) {
      console.error('Unexpected error', exception);
      this.toggleSave(false);
      this.dialogRef.close(false);
    }
  }
}
