<div mat-dialog-title class="dialog-title">
  <h2>Export</h2>
  <button class='close-icon' mat-icon-button mat-dialog-close>
    <i class="fal fa-times fa-2x"></i>
  </button>
</div>
<div class='button-container'>
  <button mat-stroked-button color="primary" [mat-dialog-close]='ExportType.CSV'>CSV <i class="fal fa-file-csv icon-spacer"></i></button>
  <button mat-raised-button color="primary" [mat-dialog-close]='ExportType.EXCEL'>Excel <i class="fal fa-file-excel icon-spacer"></i></button>
</div>
