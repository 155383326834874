import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { BlankComponent } from './blank.component';
import { BasicComponent } from './basic.component';
import { BlankCaptiraComponent } from './blank-captira.component';

import { NavigationModule } from '../navigation/navigation.module';
import { TopnavbarModule } from '../topnavbar/topnavbar.module';

import { MobileCaptiraComponent } from './mobile-captira.component';

import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FlexLayoutModule } from '@angular/flex-layout';
import {
  GatewayRegistrationBannerComponent,
} from '../../../views/collections/gateway-registration-banner/gateway-registration-banner.component';
import { AdviceBannerComponent } from '../advice-banner/advice-banner.component';
import { DefendantManagerReminderAdviceComponent } from '../defendant-manager-reminder-advice/defendant-manager-reminder-advice.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DefendantPreviewModule } from '../../../defendants/defendant-preview/defendant-preview.module';
import { DxScrollViewModule } from 'devextreme-angular';
import { QuickActionModule } from '../../../views/defendant/quick-action/quick-action.module';

@NgModule({
  declarations: [
    BlankComponent,
    BasicComponent,
    BlankCaptiraComponent,
    MobileCaptiraComponent,
    GatewayRegistrationBannerComponent,
    AdviceBannerComponent,
    DefendantManagerReminderAdviceComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule,
    TopnavbarModule,
    MatSidenavModule,
    MatCardModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    DefendantPreviewModule,
    DxScrollViewModule,
    QuickActionModule,
  ],
  exports: [
    BlankComponent,
    BasicComponent,
    BlankCaptiraComponent,
    MobileCaptiraComponent,
  ],
})
export class LayoutsModule {
}
