import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Share } from '../models/share';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiUtilities } from '../shared/ApiUtilities';
import { PortoResponse } from '../models/porto-response';

@Injectable({
  providedIn: 'root',
})
export class SharesService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(private http: HttpClient,
  ) {
  }

  public SaveEsignSharesAndSendEmail(shares: Share[], documentId: string): Observable<Share[]> {
    return this.http.post(`${environment.baseApi}/shares/esign/${documentId}`, {Shares: shares}, {headers: this.headers}).pipe(
      map((res: PortoResponse<Share[]>) => res.data),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  public getShareById(id): Observable<Share> {
    return this.http.get<PortoResponse<Share>>(`${environment.baseApi}/shares/${id}`, {headers: this.headers}).pipe(
      map(res => res.data),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),
    );
  }

  public getShareByEmailOrMobileAndPasscode(shareId: string, emailOrMobile: string, passcode: number): Observable<Share> {
    return this.http.get(`${environment.baseApi}/shares/esign/${shareId}/${emailOrMobile}/${passcode}`, {headers: this.headers}).pipe(
      map((res: PortoResponse<Share[]>) => {
        if (res.data.length === 1) {
          return res.data[0];
        } else {
          return null;
        }
      }),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }
}
