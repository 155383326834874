import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventService } from '../../../services/event.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';


export interface FormGroupDialogData {
  inDialog: boolean;
}

@Component({
  selector: 'app-form-groups-dialog',
  templateUrl: './form-groups-dialog.component.html',
  styleUrls: ['./form-groups-dialog.component.scss'],
})
export class FormGroupsDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  currentSelectedId = '';
  private element: HTMLElement;
  constructor(
    public dialogRef: MatDialogRef<FormGroupsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormGroupDialogData,
    private eventService: EventService,
    private viewContainerRef: ViewContainerRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.element = this.viewContainerRef.element.nativeElement;
    this.element.parentElement.style.overflow = 'hidden';
    // Subscribe to event when a new form group is created so that when mat dialog closes we pass this newly created id to form component
    this.subs.sink = this.eventService.onFormGroupCreatedId$.subscribe((response: string) => {
      this.currentSelectedId = response;
    });
  }

}
