import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, publishReplay, refCount, share, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { AppConstants } from '../shared/AppConstants';
import BILLING_STATUS_KEY = AppConstants.BILLING_STATUS_KEY;
import BILLING_LAST_STATUS_CHECK_TIME = AppConstants.BILLING_LAST_STATUS_CHECK_TIME;
import TRIAL_DAYS_REMAINING = AppConstants.TRIAL_DAYS_REMAINING;

export interface Products {
  [AppConstants.PRODUCT_NAME_CREDIT_REPORTS]: boolean;
  [AppConstants.PRODUCT_NAME_DATAMART_ACCESS]: boolean;
  [AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER]: boolean;
  [AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER_PLUS]: boolean;
  [AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM]: boolean;
  [AppConstants.PRODUCT_NAME_DEFENDANT_WATCH]: boolean;
  [AppConstants.PRODUCT_NAME_MVS_NETWORK_ACCESS]: boolean;
  [AppConstants.PRODUCT_NAME_TEXT_MESSAGING]: boolean;
  [AppConstants.PRODUCT_NAME_WEBSITE_BONDING]: boolean;
  [AppConstants.PRODUCT_NAME_VEHICLE_WATCH]: boolean;
  [AppConstants.PRODUCT_NAME_COLLECTIONS]: boolean;
  [AppConstants.PRODUCT_NAME_GOOGLE_REVIEW]: boolean;
  [AppConstants.PRODUCT_NAME_ESIGN]: boolean;
  collections_details?: ChargifyComponent;
}

export interface ChargifyComponent {
  id: number;
  name: string;
  kind: string;
  unit_name: string;
  currency: string;
  allocated_quantity: number;
  pricing_scheme: string;
  component_id: number;
  component_handle: string;
  allow_fractional_quantities: boolean;
  subscription_id: number;
  recurring: boolean;
  description: string;
  archived_at: Date;
  price_point_id: number;
  price_point_handle: string;
  price_point_type: string;
  price_point_name: string;
  use_site_exchange_rate: boolean;
  product_family_id: number;
  product_family_handle: string;
  display_on_hosted_page: boolean;
  created_at: Date;
  updated_at: Date;
}

@Injectable()
export class BillingService {

  private cacheTimestamp: number;

  set newProductCancelOldProducts(val: boolean) {
    localStorage.setItem('billing.newProductCancelOldProducts', val.toString());
  }

  get newProductCancelOldProducts(): boolean {
    const returnVal = localStorage.getItem('billing.newProductCancelOldProducts');
    if (returnVal) {
      return JSON.parse(returnVal);
    }
  }

  get signupProduct() {
    if (!this._signupProduct) {
      const storedSignupProduct = sessionStorage.getItem('signupProduct');
      if (storedSignupProduct) {
        this._signupProduct = storedSignupProduct;
      }
    }
    return this._signupProduct;
  }

  set signupProduct(prod: string) {
    this._signupProduct = prod;
    sessionStorage.setItem('signupProduct', prod);
  }

  get externalCompanyId(): string {
    if (!this._externalCompanyId) {
      const storedSignupToken = sessionStorage.getItem('externalCompanyId');
      if (storedSignupToken) {
        this._externalCompanyId = storedSignupToken;
      }
    }
    return this._externalCompanyId;
  }

  set externalCompanyId(token: string) {
    this._externalCompanyId = token;
    sessionStorage.setItem('externalCompanyId', token);
  }

  get signupToken(): string {
    if (!this._signupToken) {
      const storedSignupToken = sessionStorage.getItem('signupToken');
      if (storedSignupToken) {
        this._signupToken = storedSignupToken;
      }
    }
    return this._signupToken;
  }

  set signupToken(token: string) {
    this._signupToken = token;
    sessionStorage.setItem('signupToken', token);
  }

  get collectionQty() {
    if (!this._collectionQty) {
      const qty = sessionStorage.getItem('collectionQty');
      if (qty) {
        this._collectionQty = parseInt(qty, 10);
      } else {
        this.collectionQty = 10;
      }
    }
    return this._collectionQty;
  }

  set collectionQty(prod: number) {
    this._collectionQty = prod;
    sessionStorage.setItem('collectionQty', prod.toString());
  }

  get collectionsPlanSelected() {
    if (!this._collectionsPlanSelected) {
      const storedVal = sessionStorage.getItem('collectionsPlanSelected');
      if (storedVal) {
        this._collectionsPlanSelected = (storedVal.toUpperCase() === 'TRUE');
      }
    }
    return this._collectionsPlanSelected;
  }

  set collectionsPlanSelected(val: boolean) {
    this._collectionsPlanSelected = val;
    sessionStorage.setItem('collectionsPlanSelected', val.toString().toUpperCase());
  }

  get subscriptionCost() {
    if (!this._subscriptionCost) {
      const cost = sessionStorage.getItem('subscriptionCost');
      if (cost) {
        this._subscriptionCost = parseInt(cost, 10);
      }
    }
    return this._subscriptionCost;
  }

  set subscriptionCost(prod: number) {
    this._subscriptionCost = prod;
    sessionStorage.setItem('subscriptionCost', prod.toString());
  }

  get returnUrl(): string {
    if (!this._returnUrl) {
      const returnUrl = sessionStorage.getItem('returnUrl');
      if (returnUrl) {
        this._returnUrl = returnUrl;
      }
    }
    return this._returnUrl;
  }

  set returnUrl(value: string) {
    this._returnUrl = value;
    sessionStorage.setItem('returnUrl', value);
  }

  public trialDaysRemaining = 0;
  public buyNowPageMessage = '';
  public refreshMinutes = 60; // means if queried for status, check again if results are more than an hour old
  public subscribedProducts: any;
  public signupSource = '';

  private headers = new HttpHeaders().set('Content-Type', 'application/json').append('Accept', 'application/json');
  private _signupProduct = '';
  private getProductSubscription$: (Observable<any> | null);
  private getStatus$: Observable<any>;
  private customFieldValue$: Observable<any>;
  private _collectionQty: number;
  private _subscriptionCost = 0;
  private _collectionsPlanSelected = false;
  private _signupToken: string;
  private _externalCompanyId: string;
  private _returnUrl: string;

  constructor(private http: HttpClient) {
  }

  AccountInGoodStanding(): Observable<boolean> {
    const diffDate = new Date().getTime() - this.refreshMinutes * 60 * 1000; // milliseconds
    if (!this.GetLastResultTime() || this.GetLastResultTime().getTime() < diffDate) {
      return this.CheckBillingStatus(false);
    } else {
      return of(true);
    }

  }

  GetLastResultTime(): Date {
    if (sessionStorage.getItem(BILLING_LAST_STATUS_CHECK_TIME)) {
      return new Date(sessionStorage.getItem(BILLING_LAST_STATUS_CHECK_TIME));
    }
    return new Date('January 1, 2001');
  }

  ClearBillingStatus() {
    sessionStorage.removeItem(BILLING_STATUS_KEY);
  }

  ClearSubscribedProducts() {
    this.subscribedProducts = null;
  }

  CheckBillingStatus(doRedirect: boolean): Observable<boolean> {
    return this.GetCustomerBillingStatusString().pipe(
      mergeMap(res => {
        // console.log(`Billing status from BillingService.CheckBillingStatus: ${res}`);
        sessionStorage.setItem(BILLING_STATUS_KEY, res);
        switch (res) {
          case 'PAST_DUE':
            // Allowed access, but will be prompted (from app.component.ts)
            this.SetLastResultTime(new Date());
            return of(true);
          case 'TRIALING':
            // Make sure user is aware of length remaining in trial
            // TODO: Any potential funny business with this nested observable?
            return this.GetTrialDaysRemaining().pipe(
              map(days => {
                // console.log(`Trial Days Remaining: ${days}`);
                this.trialDaysRemaining = days;
                if (this.trialDaysRemaining <= 0) {
                  this.SetLastResultTime(new Date());
                }
                // this is a bit kludgy, but if status returns TRIALING and there are 0 days remaining,
                //  this should indicate a free product like defendant manager where the customer
                //  has no credit card on file, but account is really ACTIVE.  We must treat as TRIALING
                //  so that customer cannot access any premium services without credit card
                // Basically, any account in TRIALING status is good to go here
                return true;
              }));
          case 'TRIAL_ENDED':
            // must be redirected to buy now with indication trial has ended
            this.buyNowPageMessage = 'Your trial has ended.';
            this.SetLastResultTime(new Date());
            return of(false);
          case 'CANCELED':
          case 'EXPIRED':
          case 'SUSPENDED':
          case 'UNPAID':
            // must be redirected to reactivate account
            sessionStorage.setItem('reactivating', 'true');
            this.SetLastResultTime(new Date());
            return of(false);
          case 'FAILED_TO_CREATE':
            // not really sure when this would occur..
            this.buyNowPageMessage = 'There was an issue creating your account.';
            this.SetLastResultTime(new Date());
            return of(false);
          case 'ACTIVE':
          case 'ON_HOLD': // TODO: do we treat 'on_hold' as live?
          case 'ASSESSING':
          case 'PENDING':
          case 'PAUSED': // this means Captira's account with Chargify is in arrears, unexpected
          default:
            // this is gravy, do nothing
            this.SetLastResultTime(new Date());
            return of(true);
        }
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  GetCustomerBillingStatusString(forceUpdate = false): Observable<string> {
    // console.log('In billing service function: GetCustomerBillingStatusString');

    // if last time checked was more than RefreshMinutes minutes ago, check again
    if (this.getStatus$) {
      return this.getStatus$;
    }
    const diffDate = new Date().getTime() - this.refreshMinutes * 60 * 1000; // milliseconds
    if (forceUpdate) {
      this.ClearBillingStatus();
    }
    if ((!this.GetLastResultTime() || this.GetLastResultTime().getTime() < diffDate) && sessionStorage.getItem(BILLING_STATUS_KEY)) {
      this.getStatus$ = null;
      return of(sessionStorage.getItem(BILLING_STATUS_KEY));
    }
    this.getStatus$ = this.http.get(`${environment.baseApi}/customers/subscription/status`, {headers: this.headers}).pipe(
      share(),
      map((res: any) => {
        this.getStatus$ = null;
        if (!res.state) {
          return '';
        }
        return res.state.toUpperCase();
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
    return this.getStatus$;
  }

  GetTrialDaysRemaining(): Observable<number> {
    return this.http.get(`${environment.baseApi}/customers/subscription/trialdaysremaining`, {headers: this.headers}).pipe(
      map((res: any) => {
        return res.days;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  GetSubscriptionCancellationMessage(): Observable<string> {
    return this.http
      .get(`${environment.baseApi}/subscription/current/cancellationreason`, {headers: this.headers})
      .pipe(
        map(cancellationResult => {
          let returnVal = '';
          if (cancellationResult['cancellation_message'] != null) {
            returnVal = cancellationResult['cancellation_message'];
          }
          return returnVal;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  GetCreditCardUpdateDetails(): any {
    let checkoutUrl = environment.checkoutUrls.captira;
    if (window.location.hostname.includes('ti3.co')) {
      checkoutUrl = environment.checkoutUrls.ti3;
    }
    const url = `${environment.baseApi}/customers/checkout/prep/${encodeURIComponent(checkoutUrl)}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      map(res => {
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  UpdatePromoCodeOnSubscription(promoCode: string, prorate = false) {
    // this may actually be a promo code or a price level, it needs to be mapped to a price level name
    this.GetValidPromoCodes()
      .subscribe(promos => {
        // console.log(`billing promo codes returned: ${JSON.stringify(promos)}`);

        let priceLevel = promos.data
          .find(val => val.Name.toUpperCase() === promoCode.toUpperCase() || val.PriceLevelName.toUpperCase() === promoCode.toUpperCase());
        if (priceLevel) {
          priceLevel = priceLevel.PriceLevelName;
        } else {
          // if PromoCode is not found in the valid promos, then it is probably a Chargify coupon
          priceLevel = promoCode;
        }
        // console.log(`valid price level determined: ${priceLevel}`);
        const url = `${environment.baseApi}/subscriptions/current/applypricelevel/${priceLevel.toUpperCase()}`;
        return this.http.post(url, {'prorate': String(prorate)}, {headers: this.headers}).pipe(
          catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')))
          .subscribe();
      });
  }

  GetValidPromoCodes(): Observable<any> {
    return this.http.get(`${environment.baseApi}/billingpromocodes`, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  CancelAccount(): Observable<any> {
    return this.http.delete(`${environment.baseApi}/subscriptions/current`, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  ReactivateAccount(): Observable<any> {
    return this.http.put(`${environment.baseApi}/subscriptions/current/reactivate`, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  PrepareForFirstBilling(componentsToAdd: any): Observable<any> {
    const url = `${environment.baseApi}/subscriptions/current/prepareforfirstbilling`;
    return this.http.post(url, JSON.stringify(componentsToAdd), {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  IsSubscribedToProduct(productName: string, forceRefresh: boolean = false): Observable<boolean> {
    if (forceRefresh) {
      this.subscribedProducts = [];
    }
    const products = this.GetSubscribedProducts(forceRefresh);
    return products.pipe(
      take(1),
      map(prods => {
        return this.subscribedProducts[productName];
      }),
    );
  }

  GetSubscribedProducts(forceRefresh: boolean = false): Observable<Products> {
    // all this tomfoolery below allows multiple simultaneous calls to this method to
// use the same subscription.
    if (this.getProductSubscription$) {
      return this.getProductSubscription$;
    } else if (this.subscribedProducts === undefined || !this.subscribedProducts || this.subscribedProducts.length === 0 || forceRefresh || !this.cacheTimestamp || (Date.now() - this.cacheTimestamp) > this.refreshMinutes * 60 * 1000) {
      this.getProductSubscription$ = this.http.get(`${environment.baseApi}/subscription/current/products`, {headers: this.headers})
        .pipe(
          map(result => {
            this.subscribedProducts = result;
            this.getProductSubscription$ = null;
            this.cacheTimestamp = Date.now();
            return result;
          }),
          catchError(error => {
            this.getProductSubscription$ = null;
            return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
          }),
          publishReplay(),
          refCount());
      return this.getProductSubscription$;
    } else {
      return of(this.subscribedProducts);
    }
  }

  GetSubscribedProductsClientAuth(customerId) {
    return this.http.get(`${environment.baseApi}/subscriptioninternal/${customerId}/current/products`, {headers: this.headers}).pipe(
      share(),
      map(result => {
        return result;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
    );
  }


  GetAllProducts() {
    return [
      AppConstants.PRODUCT_NAME_DEFENDANT_WATCH,
      AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
      AppConstants.PRODUCT_NAME_MVS_NETWORK_ACCESS,
      AppConstants.PRODUCT_NAME_WEBSITE_BONDING,
      AppConstants.PRODUCT_NAME_VEHICLE_WATCH,
      AppConstants.PRODUCT_NAME_TEXT_MESSAGING,
      AppConstants.PRODUCT_NAME_CREDIT_REPORTS,
      AppConstants.PRODUCT_NAME_DATAMART_ACCESS,
      AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER,
      AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER_PLUS,
      AppConstants.PRODUCT_NAME_COLLECTIONS,
    ];
  }

  ProductNameToUserFriendly(productName) {
    switch (productName) {
      case AppConstants.PRODUCT_NAME_DEFENDANT_WATCH:
        return 'Defendant Watch';
      case AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM:
        return 'Bail Management';
      case AppConstants.PRODUCT_NAME_MVS_NETWORK_ACCESS:
        return 'MVS';
      case AppConstants.PRODUCT_NAME_WEBSITE_BONDING:
        return 'Website Bonding';
      case AppConstants.PRODUCT_NAME_VEHICLE_WATCH:
        return 'Vehicle Watch';
      case AppConstants.PRODUCT_NAME_TEXT_MESSAGING:
        return 'Text Messaging';
      case AppConstants.PRODUCT_NAME_CREDIT_REPORTS:
        return 'Credit Reports';
      case AppConstants.PRODUCT_NAME_DATAMART_ACCESS:
        return 'Background Reports';
      case AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER:
        return 'Defendant Manager';
      case AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER_PLUS:
        return 'Defendant Manager Plus';
      case AppConstants.PRODUCT_NAME_COLLECTIONS:
        return 'Carol Collect';
      default:
        return '';
    }
  }

  ChangeCollectionSubscription(service, quantity): Observable<any> {
    return this.http.post(`${environment.baseApi}/subscription/current/services/${service}`, quantity, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  SubscribeCustomerToService(service): Observable<any> {
    const data = {
      quantity: 0,
    };
    if (service === AppConstants.PRODUCT_NAME_COLLECTIONS) {
      data.quantity = this.collectionQty;
    }
    return this.http.post(`${environment.baseApi}/subscription/current/services/${service}`, data, {headers: this.headers}).pipe(
      map((res) => {
        this.subscribedProducts[service] = true;
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  UnsubscribeCustomerFromService(service): Observable<any> {
    return this.http.delete(`${environment.baseApi}/subscription/current/services/${service}`, {headers: this.headers})
      .pipe(
        map(res => {
          this.subscribedProducts[service] = false;
          return res;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  ValidateCoupon(couponName): Observable<any> {
    return this.http.get(`${environment.baseApi}/validatecoupon/${couponName}`, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  GetCouponDiscountInCents(couponName, services: any, priceLevel = 'GEN99'): Observable<any> {
    const payload = {
      'products': services,
      'price_level': priceLevel,
    };
    return this.http.post(`${environment.baseApi}/subscription/current/couponpreview/${couponName}`,
      JSON.stringify(payload),
      {headers: this.headers}).pipe(
      map(result => {
        // sample result at https://reference.chargify.com/v1/subscriptions-preview/create-subscription-preview
        // return result.subscription_preview.next_billing_manifest.total_discount_in_cents;
        // console.log('coupon preview results', result);
        return result;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  GetSmsUsage() {
    return this.http.get(`${environment.baseApi}/notificationsms?limit=0`, {headers: this.headers})
      .pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  GetCustomFieldValue(fieldName: string): Observable<string> {
    if (this.customFieldValue$) {
      return this.customFieldValue$;
    }
    const url = `${environment.baseApi}/subscription/current/customfields/${fieldName}`;
    this.customFieldValue$ = this.http.get(url, {headers: this.headers}).pipe(
      map(res => {
        // console.log('get custom field value', fieldName, res);
        this.customFieldValue$ = null;
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
    return this.customFieldValue$;
  }

  CustomerHasPaymentOnRecord(): Observable<boolean> {
    return this.http.get(`${environment.baseApi}/subscription/current/paymentprofiles`, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
      map(profiles => {
        // console.log('received payment profiles: ', profiles);
        // console.log('profiles.hasOwnProperty(payment_profile)', profiles.hasOwnProperty('payment_profile'));
        return profiles.hasOwnProperty('payment_profile');
      }));
  }

// get allocated quantity
  GetAllocatedQuantity(serviceName): Observable<number> {
    return this.http.get(`${environment.baseApi}/subscription/current/allocation/${serviceName}`, {headers: this.headers}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  ShouldRouteToProductSelection(): Observable<boolean> {
    const isAlreadySubscribed$ = this.IsSubscribedToProduct(this.signupProduct);
    const checkOnBillingStatus$ = this.GetSubscriptionCancellationMessage().pipe(
      switchMap(cancellationMessage => {
        return this.GetCustomerBillingStatusString()
          .pipe(
            map(status => {
              switch (status) {
                case 'TRIAL_ENDED':
                  this.newProductCancelOldProducts = true;
                  return true;
                case 'CANCELED':
                case 'EXPIRED':
                case 'SUSPENDED':
                case 'UNPAID':
                  // if client cancelled for dunning, do not do redirect
                  if (cancellationMessage.toString().toUpperCase().indexOf('DUNNING') >= 0) {
                    this.newProductCancelOldProducts = false;
                    return false;
                  } else {
                    this.newProductCancelOldProducts = true;
                    return true;
                  }
                case 'ACTIVE':
                case 'ON_HOLD':
                case 'ASSESSING':
                case 'PENDING':
                case 'PAUSED':
                  // if account is active, redirect but don't offer to unsubscribe (just add new)
                  this.newProductCancelOldProducts = false;
                  return true;
                default:
                  return false;
              }
            }));
      }),
    );
    return forkJoin([isAlreadySubscribed$, checkOnBillingStatus$]).pipe(
      map(([isSubbed, statusCheck]) => {
        if (isSubbed) {
          // if already subscribed, do not send user to product selection
          // also, do not cancel old products
          this.newProductCancelOldProducts = false;
          return false;
        } else {
          // if user is not already subscribed, rely on status check
          return statusCheck;
        }
      }),
    );
  }

  GetProductsToCancel(): Observable<Array<string>> {
    if (this.newProductCancelOldProducts) {
      return this.GetSubscribedProducts()
        .pipe(
          map(prods => {
            const productsToCancel: Array<string> = [];
            for (const productName of this.GetAllProducts()) {
              if (prods[productName] === true && prods[productName] !== this.signupProduct) {
                productsToCancel.push(productName);
              }
            }
            return productsToCancel;
          }));
    }
    return of(new Array<string>());
  }

  getBillingStatus(): Observable<any> {
    return this.http.get(`${environment.baseApi}/getaccountstatus`, { headers: this.headers })
      .pipe(
        map(res => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
      );
  }

  AccountTrialStatus(): Observable<boolean> {
    const diffDate = new Date().getTime() - this.refreshMinutes * 60 * 1000;
    if (!this.GetLastResultTime() || this.GetLastResultTime().getTime() < diffDate) {
      if (sessionStorage.getItem(TRIAL_DAYS_REMAINING)) {
        if (parseInt(sessionStorage.getItem(TRIAL_DAYS_REMAINING) || '0', 10) <= 0) {
          return of(false);
        } else {
          return of(true);
        }
      } else {
        return this.getBillingStatus().pipe(map((res) => {
          this.trialDaysRemaining = res.days;
          if (this.trialDaysRemaining <= 0) {
            return false;
          } else {
            return true;
          }
        }), catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
      }
    } else {
      return of(true);
    }
  }

  getBondCount(): Observable<number> {
    return this.http.get<BondCountResponse>(`${environment.baseApi}/bond/count`)
      .pipe(
        map(res => res.count),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
      );
  }

  private SetLastResultTime(date: Date) {
    // sessionStorage.setItem(BILLING_LAST_STATUS_CHECK_TIME, date.toJSON());
  }
}

export interface BondCountResponse {
  count: number;
}

export interface CapAccount {
  CustomerId: string;
  DocumentStoreAccessKeyId: string;
  DocumentStoreBucketName: string;
  Name: string;
  V1Id: string;
  UserId: string;
}
