import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-background-sign-up',
  templateUrl: './background-sign-up.component.html',
  styleUrls: ['./background-sign-up.component.scss']
})
export class BackgroundSignUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
