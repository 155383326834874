import { BaseModel } from './BaseModel';
import { Phone } from './phones';
import { Address } from './address';
import { Email } from './email';
import { SocialNetwork } from './social-networks';
import { Employer } from './employer';
import { Vehicle } from './vehicle';
import { ObjectUtilities } from '../shared/ObjectUtilities';
import { GoogleReview } from './google-review';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;


export class Person extends BaseModel {

  // tslint:disable:variable-name
  public Id: any;
  public Alias: any;
  public First: any;
  public Middle: any;
  public Last: any;
  public MaidenName: any;
  public Children: any;
  public Schools: any;
  public ArrestHistory: any;
  public PlaceOfBirth: any;
  public AlienNumber: any;
  public Dob: any;
  public Ssn: any;
  public FullName: any;
  public TitleId: any;
  public Comment: any;
  public ProfilePicture: any;
  public ProfilePictureFile: any;
  public Citizen: boolean;
  public CitizenshipStatus: any;
  public Nationality: any;
  public Height: any;
  public Weight: any;
  public Gender: any;
  public Race: any;
  public EyeColor: any;
  public HairColor: any;
  public IdentifyingMarks: any;
  public LastCreditCheckDate: any;
  public LastCreditCheckUserId: any;
  public AdiOutgoingExclude: boolean;
  public RedFlagged: boolean;
  public RedFlaggedComment: any;
  public AdiTransferOnForfeiture: boolean;
  public AdiAllowCheckIn: boolean;
  public AdiPlayCourtDates: boolean;
  public AdiSettingsOverride: boolean;
  public AdiTransferIfInCollections: boolean;
  public AdiTransferOnPastDue: boolean;
  public Dl: any;
  public DlStateCode: any;
  public phones: Phone[];
  public addresses: Address[];
  public emails: Email[];
  public socialnetworks: SocialNetwork[];
  public employers: Employer[];
  public vehicles: Vehicle[];
  public data: any;
  public ResidenceType: any;
  public IncludeInGoogleReview: boolean;
  public review: GoogleReview;
  public Age: any;
  // public CitizenDuration: string;
  // public MilitaryService: string;
  // public MilitaryActive: boolean;

  private _HeightFt: any;
  private _HeightIn: any;

  constructor(obj?: object) {

    super(obj);
    this.childrenNames = ['phones', 'addresses', 'emails', 'socialnetworks', 'employers', 'vehicles', 'review'];
    this.propertiesToIgnoreForCheckChanges.push('_HeightFt', '_HeightIn','Age');
    this.routeRoot = 'persons';

    if (obj == null) {

      this.First = '';
      this.Last = '';
      this.Ssn = '';
      this.Citizen = true;
      // this.MilitaryActive = false;
      this.AdiAllowCheckIn = true;
      this.AdiOutgoingExclude = false;
      this.AdiPlayCourtDates = true;
      this.AdiSettingsOverride = false;
      this.AdiTransferIfInCollections = true;
      this.AdiTransferOnForfeiture = true;
      this.AdiTransferOnPastDue = true;
      this.RedFlagged = false;
      this.phones = [];
      this.emails = [];
      this.addresses = [];
      this.socialnetworks = [];
      this.employers = [];
      this.vehicles = [];
      this.review = new GoogleReview();
    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);
    this.Ssn = this.Ssn || '';
    // // console.log('person.loaddata', 'person loaded');
    // // console.log('data.phones.data:', data.phones.data);
    // // console.log('data.addresses.data:', data.addresses.data);
    // // console.log('data.emails.data:', data.emails.data);
    // // console.log('data.socialnetworks.data:', data.socialnetworks.data);
    // // console.log('data.vehicles.data:', data.vehicles.data);
    // // console.log('data.employers.data:', data.employers.data);

    if (!isNullUndefinedOrEmpty(this.Height)) {
      this._HeightFt = this.Height.split(',')[0];
      this._HeightIn = this.Height.split(',')[1];
    }
    //   return splitHeight;

    const mainThis = this;
    this.phones = [];
    this.phones.length = 0;
    this.emails = [];
    this.emails.length = 0;
    this.addresses = [];
    this.addresses.length = 0;
    this.socialnetworks = [];
    this.socialnetworks.length = 0;
    this.employers = [];
    this.employers.length = 0;
    this.vehicles = [];
    this.vehicles.length = 0;

    if (data.phones && data.phones.data !== null && data.phones.data.length > 0) {
      data.phones.data.forEach(
        (phones) => {
          const newPhone = new Phone();
          newPhone.fillFromObject(phones);
          mainThis.phones.push(newPhone);
          // console.log('person.loaddata', 'phone loaded');
        });
    }

    if (data.addresses && data.addresses.data !== null && data.addresses.data.length > 0) {
      data.addresses.data.forEach(
        (addresses) => {
          const newAddress = new Address();
          newAddress.fillFromObject(addresses);
          mainThis.addresses.push(newAddress);
          // console.log('person.loaddata', 'address loaded');
        });
    }

    if (data.emails && data.emails.data !== null && data.emails.data.length > 0) {
      data.emails.data.forEach(
        (emails) => {
          const newEmail = new Email();
          newEmail.fillFromObject(emails);
          mainThis.emails.push(newEmail);
          // console.log('person.loaddata', 'email loaded');
        });
    }

    if (data.socialnetworks && data.socialnetworks.data !== null && data.socialnetworks.data.length > 0) {
      data.socialnetworks.data.forEach(
        (socialnetworks) => {
          const newSocialNetwork = new SocialNetwork();
          newSocialNetwork.fillFromObject(socialnetworks);
          mainThis.socialnetworks.push(newSocialNetwork);
          // console.log('person.loaddata', 'social network loaded');
        });
    }

    if (data.vehicles && data.vehicles.data !== null && data.vehicles.data.length > 0) {
      data.vehicles.data.forEach(
        (vehicles) => {
          const newVehicle = new Vehicle();
          newVehicle.fillFromObject(vehicles);
          mainThis.vehicles.push(newVehicle);
          // console.log('person.loaddata', 'vehicle loaded');
        });
    }

    if (data.employers && data.employers.data !== null && data.employers.data.length > 0) {
      data.employers.data.forEach(
        (employers) => {
          const newEmployer = new Employer();
          newEmployer.loadData(employers);
          mainThis.employers.push(newEmployer);
          // console.log('person.loaddata', 'employer loaded');
        });
    }

    if (data.review && data.review.data) {
      this.review.loadData(data.review.data);
    }

    this.getAge();

  }

  // public getHeightFeetAndInches() {
  //   const splitHeight = (this.Height === null) ? null : this.Height.split(' ');
  //   return splitHeight;
  // }

  public getFullName() {

    return (((this.First || '').trim()) + ' ' + ((this.Last || '').trim())).trim();
  }

  // public setHeightFromFeetAndInches(feet, inches) {
  //   if ((inches === null || inches === '') && (feet === null || feet === '')) {
  //     this.Height = '';
  //   } else {
  //     if (feet !== null && feet !== '') {
  //       this.Height = feet;
  //     }
  //     if (inches !== null && inches !== '') {
  //       this.Height = this.Height + ' ' + inches;
  //     }
  //   }
  // }

  set HeightFt(theHeight: string) {
    this._HeightFt = theHeight;
    if (!isNullUndefinedOrEmpty(this._HeightFt)) {
      this.Height = this._HeightFt + ',' + (isNullUndefinedOrEmpty(this._HeightIn) ? '' : this._HeightIn);
    }
  }

  get HeightFt(): string {
    return this._HeightFt;
  }

  set HeightIn(theHeight: string) {
    this._HeightIn = theHeight;
    if (!isNullUndefinedOrEmpty(this._HeightFt)) {
      this.Height = (isNullUndefinedOrEmpty(this._HeightFt) ? '' : this._HeightFt) + ',' + this._HeightIn;
    }
  }

  get HeightIn(): string {
    return this._HeightIn;
  }

  public checkForChanges(): boolean {
    let isDirty = false;

    // Check if person is new (Id is not set)
    if (!this.Id) {
      this.isAdded = true;
      isDirty = true;
    }

    // Check if the person object itself has changes
    isDirty = super.checkForChanges() ? true : isDirty;

    // Check changes in addresses
    for (const address of this.addresses) {
      if (!address.Id) {
        address.isAdded = true;
        isDirty = true;
      }
      isDirty = address.checkForChanges() ? true : isDirty;
    }

    // Check changes in phones
    for (const phone of this.phones) {
      if (!phone.Id) {
        phone.isAdded = true;
        isDirty = true;
      }
      isDirty = phone.checkForChanges() ? true : isDirty;
    }

    // Check changes in emails
    for (const email of this.emails) {
      if (!email.Id) {
        email.isAdded = true;
        isDirty = true;
      }
      isDirty = email.checkForChanges() ? true : isDirty;
    }

    // Check changes in social networks
    for (const socialNetwork of this.socialnetworks) {
      if (!socialNetwork.Id) {
        socialNetwork.isAdded = true;
        isDirty = true;
      }
      isDirty = socialNetwork.checkForChanges() ? true : isDirty;
    }

    // Check changes in employers
    for (const employer of this.employers) {
      if (!employer.Id) {
        employer.isAdded = true;
        isDirty = true;
      }
      isDirty = employer.checkForChanges() ? true : isDirty;

      // Check changes in employer's addresses
      for (const address of employer.addresses) {
        if (!address.Id) {
          address.isAdded = true;
          isDirty = true;
        }
        isDirty = address.checkForChanges() ? true : isDirty;
      }

      // Check changes in employer's phones
      for (const phone of employer.phones) {
        if (!phone.Id) {
          phone.isAdded = true;
          isDirty = true;
        }
        isDirty = phone.checkForChanges() ? true : isDirty;
      }
    }

    // Check changes in vehicles
    for (const vehicle of this.vehicles) {
      if (!vehicle.Id) {
        vehicle.isAdded = true;
        isDirty = true;
      }
      isDirty = vehicle.checkForChanges() ? true : isDirty;
    }

    return isDirty;
  }

  public getAge() {
    if (!this.Dob) this.Age = 0;
    const birthDate = new Date(this.Dob);
    const diff = Date.now() - birthDate.getTime();
    const ageDate = new Date(diff);
    this.Age = Math.abs(ageDate.getUTCFullYear() - 1970);
  }

}


