<div class='wrapper wrapper-content animated fadeIn' style='margin-top: 30px;'>
  <h4>Step 1: Select any Form Group and then scroll down</h4>
  <span>Adding individual forms to a group makes it easier than selecting individual forms one at a time in future.</span>

  <dx-data-grid
    #formGroupsGrid
    id='formGroupsGrid'
    keyExpr='Id'
    (onRowInserted)='onRowInserted($event)'
    (onSelectionChanged)='onFormGroupSelected($event)'
    (onCellPrepared)='onCellPrepared($event)'
    (onRowUpdated)='onRowUpdated($event)'
    (onRowRemoving)='onRowRemoving($event)'
    [dataSource]='formGroupsDataSource'
    [rowAlternationEnabled]='false'
    [height]='300'
    [selectedRowKeys]='[]'
    rowAlternationEnabled='true'
    (onToolbarPreparing)='onToolbarPreparing($event)'
    noDataText='No form group found. Click + above to add'
  >
    <dxo-editing mode='row' [allowUpdating]='true' [allowDeleting]='true' [allowAdding]='true'></dxo-editing>
    <dxo-search-panel [visible]='true' placeholder='Search'></dxo-search-panel>
    <dxo-selection mode='single'></dxo-selection>
    <dxi-column dataField='Name' sortOrder='asc' caption='Name' width='40%'>
      <dxi-validation-rule type='required' message='Name is required'></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField='Description' caption='Description'>
      <dxi-validation-rule type='required' message='Description is required'></dxi-validation-rule>
    </dxi-column>

    <dxo-editing
      mode='popup'
      [allowUpdating]='true' [allowAdding]='true' [allowDeleting]='true'>
      <dxo-popup
        title='{{editTitle}}'
        [showTitle]='true'
        width="40%"
        height="40%"
        [position]="{ my: 'center', at: 'center', of: popupWindow }">
      </dxo-popup>
    </dxo-editing>

    <dxo-load-panel
      [enabled]='true'>
    </dxo-load-panel>
    <dxo-search-panel
      [visible]='true'
      [width]='300'
      [placeholder]="'Search'">
    </dxo-search-panel>
  </dx-data-grid>

  <div [hidden]='!formGroupsGrid.selectedRowKeys.length' style='margin-top: 30px;' >
    <h4 style='margin-bottom: 0px'>Step 2: Select forms to be included in the above selected Group (auto save)</h4>
    <dx-data-grid
      #reportsGrid
      id='reportsGrid'
      keyExpr='ReportId'
      [dataSource]='formGroupFormsDataSource'
      [height]='400'
      [rowAlternationEnabled]='true'
      (onSelectionChanged)='onFormSelected($event)'
    >
      <dxo-paging [pageSize]='15'></dxo-paging>
      <dxo-search-panel [visible]='true' placeholder='Search'></dxo-search-panel>
      <dxo-selection mode='multiple' [allowSelectAll]='false'></dxo-selection>
      <dxi-column dataField='ReportName' caption='Name'></dxi-column>
      <dxi-column dataField='ReportDescription' caption='Description'></dxi-column>
    </dx-data-grid>

  </div>
  <div class='btn-wrapper' *ngIf='!inDialog' >
    <button mat-raised-button color="primary" (click)='openForms()'>continue to forms</button>
  </div>

</div>

<dx-load-panel
  #loadPanel
  [closeOnOutsideClick]='false'
  shadingColor='rgba(0,0,0,0.4)'
  [(visible)]='loadIndicatorVisible'
  [message]='busyMessage'
  [showIndicator]='true'
  [showPane]='true'
  [shading]='true'
  [position]="{ of: '#reportsGrid' }"
>
</dx-load-panel>
