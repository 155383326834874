import {map, catchError} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {ApiUtilities} from '../shared/ApiUtilities';
import * as moment from 'moment';

@Injectable()
export class BondService {

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getByTransactionId(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/bond/transaction/${id}?include=power`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getFeeForImport(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/import/fee`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getByDefendantId(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/findbondbydefid/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/bonds/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  fixStatusDates(statusDate: any, postedDate: any) {
    let sd = moment.utc(statusDate).format('YYYY-MM-DD'),
        pd = moment.utc(postedDate).format('YYYY-MM-DD');
    return (sd < pd);
  }

  updateCourtDate(id: any, data: any): Observable<any> {
    return this.http.patch(`${this.settings.getBaseUrl()}/courtdates/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  updateTransactionCourtDates(data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/courtdates`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getFilteredBonds(startDate: any, endDate: any, state: string, surety: string, agency: string, agent: string) {
    const query = this.getQueryString(startDate, endDate, state, surety, agency, agent);
    return this.http.get(`${this.settings.getBaseUrl()}/searchbonds?${query}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  updateMultiple(data: any): Observable<any> {
    return this.http.patch(`${this.settings.getBaseUrl()}/bonds/update/multiple`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  private getQueryString(startDate: any, endDate: any, state: string, surety: string, agency: string, agent: string) {
    let result = startDate ? `StartDate=${encodeURIComponent(moment.utc(startDate).format('YYYY-MM-DD'))}` : '';
    result = endDate ? result.concat(`${result.length > 0 ? '&' : ''}EndDate=${encodeURIComponent(moment.utc(endDate).format('YYYY-MM-DD'))}`) : result;
    result = state ? result.concat(`${result.length > 0 ? '&' : ''}StateCode=${state}`) : result;
    result = surety ? result.concat(`${result.length > 0 ? '&' : ''}SuretyId=${surety}`) : result;
    result = agency ? result.concat(`${result.length > 0 ? '&' : ''}AgencyId=${agency}`) : result;
    result = agent ? result.concat(`${result.length > 0 ? '&' : ''}AgentId=${agent}`) : result;
    return result;
  }
}
