import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from './AppConstants';
import { RecentDefendant } from '../views/dashboard/panels/recent-defendants/recent-defendants.component';
import { RelatedTransaction } from '../models/related-transaction';

@Pipe({
  name: 'mugshot',
})
export class MugshotPipe implements PipeTransform {

  placeHolderMugshotMaleBase64 = AppConstants.DEFAULT_MALE_MUGSHOT;
  placeHolderMugshotFemaleBase64 = AppConstants.DEFAULT_FEMALE_MUGSHOT;

  /**
   * The MugshotPipe takes a RecentDefenant record and returns the defendant Mugshot URL.
   * If the defendant doesn't have a ProfilePicture set then it will return the default female mugshot for Female defenants
   * or the default Male mugshot for all others.
   * @param defendant The current RecentDefendant or RelatedTransaction record.
   * @param key: The key on the object which contains the profile picture url. Defaults to ProfilePicture.
   */
  transform(defendant: RecentDefendant | RelatedTransaction, key: string = 'ProfilePicture'): string {
    return defendant[key] ? defendant[key] :
      (!defendant.Gender || defendant.Gender.toUpperCase() !== 'F') ?
        this.placeHolderMugshotMaleBase64 : this.placeHolderMugshotFemaleBase64;
  }

}
