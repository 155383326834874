import { DefendantWatchComponent } from '../../views/defendant/defendant-watch/defendant-watch.component';
import { take } from 'rxjs/operators';
import { AfterViewInit, Component, ComponentFactoryResolver, Input, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BondStatusUpdaterComponent } from './bond-status-updater/bond-status-updater.component';
import { DefendantService } from '../../services/defendant.service';
import { Defendant } from '../../models/defendant';
import { CourtDateSchedulerComponent } from './court-date-scheduler/court-date-scheduler.component';
import * as moment from 'moment';
import { EventService } from '../../services/event.service';
import { DatamartComponent } from '../../views/datamart/datamart.component';
import { DefendantFormsComponent } from '../../views/defendant-forms/defendant-forms.component';
import { AppConstants } from '../../shared/AppConstants';
import { LocationHistoryComponent } from '../../views/location-history/location-history.component';
import { AdiCallLogComponent } from '../../views/adi-call-log/adi-call-log.component';
import { AdisettingsComponent } from '../../views/adisettings/adisettings.component';
import { WebsiteBondingAccessComponent } from '../../views/website-bonding-access/website-bonding-access.component';
import { VehicleWatchComponent } from '../../views/vehicle-watch/vehicle-watch.component';
import { CommunicationsCenterComponent } from '../../views/communications-center/communications-center.component';
import { DefendantAuditHistoryComponent } from '../../views/defendant-audit-history/defendant-audit-history.component';
import { IDynamicPopupComponent } from '../../components/common/dynamic-popup/dynamic-popup.component';
import { DefendantNotesPopupComponent } from '../../views/defendant/defendantnotes/defendantnotespopup/defendantnotespopup.component';
import { CheckinComponent } from '../../views/checkin/checkin.component';
import { MobileCheckinAppComponent } from '../../views/mobile-checkin-app/mobile-checkin-app.component';
import { PaymentComponent } from '../../views/common/payment/payment.component';
import { LookupService } from '../../services/app-lookup.service';
import { Address } from '../../models/address';
import { Phone } from '../../models/phones';
import { Email } from '../../models/email';
import { RouteDataService } from '../../services/routedata.service';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../common/notify/notify.service';
import { PdfViewerComponent } from '../../components/common/pdf-viewer/pdf-viewer.component';
import { FieldValues } from '../../models/reportcompilationrequest';
import { UserService } from '../../services/user.service';
import { CheckInSchedule } from '../../models/checkinschedule';
import { Store } from '@ngrx/store';
import { loadMessages } from '../../state/actions/messages.actions';
import { Observable } from 'rxjs';
import { Bail } from '../../models/bail';
import { addDefendant } from '../../state/actions/defendants.actions';
import { AddDefendantWarningComponent } from '../../components/controls/add-defendant-warning/add-defendant-warning.component';
import { Contact } from '../../models/contact';
import { DocumentDialogComponent } from '../../views/defendant/document/document-dialog/document-dialog.component';
import { StorageService } from '../../services/storage.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { FullNamePipe } from '../../shared/full-name.pipe';
import { TransactionService } from '../../services/transaction.service';
import { PersonUpdateDialogComponent, PersonUpdateType } from '../person-update-dialog/person-update-dialog.component';
import { TransactionStatusUpdaterComponent } from '../transaction-status-updater/transaction-status-updater.component';
import { ContactStatusUpdaterComponent } from '../contact-status-updater/contact-status-updater.component';
import { QuickAction, QuickActionPreviewEvent } from '../../views/defendant/quick-actions-preview/quick-actions-preview.component';
import { ReportType } from '../../services/datamart.service';
import { MugshotComponent } from '../../views/common/mugshot/mugshot.component';
import { ContactService } from './../../services/contact.service';
import { DefendantNotesDialogComponent } from '../../views/defendant/defendantnotes/defendant-notes-dialog/defendant-notes-dialog.component';
import { Note } from './../../models/note';

@Component({
  selector: 'app-defendant-preview',
  templateUrl: './defendant-preview.component.html',
  styleUrls: ['./defendant-preview.component.scss'],
  entryComponents: [
    DefendantAuditHistoryComponent,
    CheckinComponent,
    DefendantFormsComponent,
    DefendantWatchComponent,
    VehicleWatchComponent,
    CommunicationsCenterComponent,
    MobileCheckinAppComponent,
    AdiCallLogComponent,
    AdisettingsComponent,
    DatamartComponent,
    WebsiteBondingAccessComponent,
    DefendantNotesPopupComponent,
    LocationHistoryComponent,
  ],
})
export class DefendantPreviewComponent extends UnsubscribeOnDestroyAdapter implements AfterViewInit {

  @ViewChild(MugshotComponent, {static: false} ) mugshotComponent: MugshotComponent;
  @ViewChild('vcRef', {read: ViewContainerRef}) vcRef: ViewContainerRef;
  @ViewChild('tooltip') tooltip: any;

  @Input() defendantId: string;
  @Input() slideOut = false;
  @Input() mugShot = false;
  recentPayment: any;
  upcomingPayment: any;
  defendantLoading = true;
  transactionsLoading = true;
  messagesLoading = true;
  defendant: Defendant = new Defendant();

  showFiller = false;
  showEdit: number;
  nextCourt: string;
  courtName = '';
  setFor = '';
  previousCheckin: string;
  nextCheckin: string;
  messageTypes: any = [];
  messages: any = [];
  indemnitors: any = [];
  lastNote: string;
  noteTransaction: string;
  defaultPhone: Phone;
  defaultEmail: Email;
  defaultAddress: Address;

  popupVisible: any;
  bondStatusPopupVisible: boolean;
  popupTitle: any;
  popupWidth = 'auto';
  popupMinHeight = 'auto';
  popupMaxHeight = 'auto';
  popupMaxWidth = 'auto';
  dynamicPopupClass: Type<IDynamicPopupComponent> | null;
  dynamicPopupData = [];
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  dynamicPopupMaxHeight = AppConstants.DEFAULTPOPUPCONFIG.MAXHEIGHT;
  dynamicPopupMaxWidth = AppConstants.DEFAULTPOPUPCONFIG.MAXWIDTH;
  dynamicPopupDragEnabled = AppConstants.DEFAULTPOPUPCONFIG.DRAGENABLED;

  isLimitedUser = false;
  tooltipText = '';

  upcomingCollateralPayment: any;

  checkInSchedule: CheckInSchedule;
  defendantLoad: boolean;
  btnFullProfileDisabled: boolean;
  position: any = { my: 'center', at: 'center', of: 'window' };
  height = '80vh'; // default value of dx-popup

  editingMobile = false;
  editingEmail = false;
  editingAddress = false;
  savingData = false;

  isExtraSmall: Observable<BreakpointState>;
  isMobile: boolean;
  statuses: {Id: 'Prospect' | 'Client' | 'Dead', Text: 'Prospect' | 'Client' | 'Dead Lead/Declined'}[];
  paymentSum = 0;
  totalLiability = 0;
  protected readonly PersonUpdateType = PersonUpdateType;
  private isDefendantWatcherComponent = false;
  private isEditing = false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private defendantService: DefendantService,
    private transactionService: TransactionService,
    private eventService: EventService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private lookupService: LookupService,
    private routeDataService: RouteDataService,
    private router: Router,
    private notify: NotifyService,
    private userService: UserService,
    private storageService: StorageService,
    private store: Store<{messages, defendant}>,
    private readonly breakpointObserver: BreakpointObserver,
    private fullNamePipe: FullNamePipe,
    private contactService: ContactService
  ) {
    super();
    this.messageTypes = this.lookupService.getLookupStatic('DefendantMessageTypes');
    this.btnFullProfileDisabled = true;
  }

  ngAfterViewInit() {

    this.isExtraSmall = this.breakpointObserver.observe([Breakpoints.XSmall]);
    this.isMobile = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
    // reset state for defendant info
    this.store.dispatch(addDefendant({ defendant: new Defendant(), section: null }));
    this.subs.sink = this.userService.current().subscribe(res => {
      this.isLimitedUser = res.data && res.data.LimitedVisibility;
    });
    this.subs.sink = this.eventService.quickActionEvent$.subscribe(item => {
      this.onSelected(item);
    });
    this.subs.sink = this.eventService.globalPaymentEvent$.subscribe(() => {
      this.btnFullProfileDisabled = true;
      this.loadData();
    });
    this.subs.sink = this.eventService.defendantLoadEvent$.subscribe(() => {
      this.loadData(true);
    });
    this.subs.sink = this.route.paramMap.subscribe(params => {
      if (!this.slideOut) {
        this.defendantId = params.get('id');
      }
      this.loadData(true);
    });
      this.loadData(true);

    // don't 'sink' this one - we want this to complete even if user navigates away
    this.subs.sink = this.lookupService.getBulkLookup(['agencies', 'agents', 'states', 'attorneys', 'jails', 'sureties', 'agencies',
      'courtdatereasons', 'recoveryagents', 'filedrates', 'dispositions', 'chargeclasses', 'charges', 'advertisingsources', 'courts'])
      .subscribe(() => {}, (error => {
        console.error(error);
      }));

  }

  loadData(clearphoto= false) {

    if (!!this.mugshotComponent && clearphoto) {
    //  this.mugshotComponent.reInit();

    }
    this.statuses = this.lookupService.getLookupStatic('ClientStatus');
      this.setDefaults();
      if (this.defendantId) {
        // let's first see if we have a version saved to show the user rather than just make them wait.
        const cache = sessionStorage.getItem(`PreviewDefendantData${this.defendantId}`);
        if (!!cache) {
          const cacheDef = JSON.parse(cache);
          this.defendantLoading = false;
          this.transactionsLoading = false;
          this.messagesLoading = false;
          this.defendant.LoadData(cacheDef.data);
          this.setPersonalUI(this.defendant);
          this.setUI(this.defendant);
        } else {
          this.defendantLoading = true;
          this.transactionsLoading = true;
          this.messagesLoading = true;
        }
        // reload details whether we have cached version or not
        this.subs.sink = this.defendantService
          .getDefendantPersonalById(this.defendantId).pipe(
            take(1))
          .subscribe(
            previewDef => {
              this.defendant.LoadData(previewDef.data);
              this.defendantLoading = false;
              this.setPersonalUI(this.defendant);
              this.subs.sink = this.defendantService
                .getFullDefendantById(this.defendantId, null, null,
                  'person.phones,person.phones.exclusions,person.employers,person.addresses,person.employers.phones,person.emails,person.vehicles,person.socialnetworks,transactions.bonds.courtdates,checkinhistory,checkinschedule,' +
                  'transactions,transactions.agent,transactions.documents,transactions.documents.shares,transactions.documents.version,transactions.agent.person,transactions.bonds,transactions.bonds.power,' +
                  'transaction.application,transactions.collaterals,transactions.collaterals.cashcollateralpaymentschedule,transactions.collaterals.addresses,transactions.notes,' +
                  'transactions.paymentschedule,transaction.paymentschedule.details,accountingdetails,calendaritems,transactions.contacts.person.socialnetworks,' +
                  'transactions.contacts,transactions.contacts.person,transactions.contacts.person.emails,' +
                  'transactions.contacts.person.phones,transactions.contacts.person.addresses,transactions.contacts.person.employers,' +
                  'transactions.contacts.person.vehicles',
                ).pipe(
                  take(1))
                .subscribe(
                  fullDef => {
                    if (fullDef.data.transactions.data && fullDef.data.transactions.data.length === 0 ) {
                      if (this.isLimitedUser) {
                        this.notify.toast('You do not have access to this file. See your administrator.', 'error');
                      } else {
                        this.notify.toast('Error loading defendant. Please refresh your browser.', 'error');
                      }
                    }

                    try {
                      // Remove the meta data to save space
                      if (fullDef.meta !== 'undefined') {
                        delete fullDef.meta;
                      }
                      sessionStorage.setItem(`PreviewDefendantData${this.defendantId}`, JSON.stringify(fullDef));
                    } catch (err) {
                      try {

                        // Remove all instances of preview defendant key and store fresh item
                        this.storageService.removeItemByPrefix('PreviewDefendantData', 'session');
                        sessionStorage.setItem(`PreviewDefendantData${this.defendantId}`, JSON.stringify(fullDef));
                      } catch (exception) {
                        // Don't do anything here other than log. We don't want to stop the user from continuing when
                        // just because we can't save to cache
                        console.error(exception);
                      }
                    }

                    this.defendant.LoadData(fullDef.data);
                    this.defendantLoading = false;
                    this.transactionsLoading = false;
                    this.setUI(this.defendant);
                    this.defendantService.pushRecentDefendant(
                      this.defendant.Id,
                      this.defendant.person.getFullName(),
                      this.defendant.person.Dob,
                      this.defendant.person.Gender,
                      this.defendant.person.ProfilePicture);
                    this.updateSessionData();
                    this.subs.sink = this.defendantService
                      .getDefendantMessages(this.defendantId).pipe(
                        take(1))
                      .subscribe((messages: any) => {
                        this.defendant.messages = messages.data;
                        fullDef.data.messages = messages;
                        this.store.dispatch(loadMessages({ messages: messages.data }));

                        try {
                          sessionStorage.setItem(`PreviewDefendantData${this.defendantId}`, JSON.stringify(fullDef));
                        } catch (err) {
                          this.storageService.removeItemByPrefix('PreviewDefendantData', 'session');
                          sessionStorage.setItem(`PreviewDefendantData${this.defendantId}`, JSON.stringify(fullDef));
                        }

                        this.messagesLoading = false;
                        this.getMessages(this.defendant);
                      });

                      this.subs.sink = this.getTransactionSummary(this.defendant.transactions).subscribe(count => {
                        if (count === this.defendant.transactions.length) {
                          this.store.dispatch(addDefendant({ defendant: this.defendant, section: null }));
                          this.btnFullProfileDisabled = false;
                        }
                      });
                  });
            });
      }

  }

  getTransactionSummary(transactions: Bail[]): Observable<number> {
    return new Observable<number>(obs => {
      let count = 0;
      transactions.forEach(trans => {
        this.subs.sink = this.defendantService
          .getTransactionArSummary(trans.Id)
          .subscribe(arSummaryData => {
            // need to massage the data so it looks as expected for this method
            //  since it is also called from within the full load method for that object..
            const arSummaryForLoad = {
              arsummary: arSummaryData.data,
            };
            trans.loadTransactionArSummary(arSummaryForLoad);
            count++;
            obs.next(count);
            if (count === transactions.length) {
              obs.complete();
            }
          });
      });
    });
  }
  setPersonalUI(def: Defendant) {
    this.getDefaultPhone(def);
    this.getDefaultEmail(def);
    this.getDefaultAddress(def);
  }

  setUI(def: Defendant) {
    this.getPaymentDetails(def);
    this.getCollateralPaymentDetails(def);
    this.getCheckins(def);
    this.getCourtDateDetails(def);
    this.getIndemnitors(def);
    this.getNotes(def);
    this.getTotalLiability(def);
  }

  getIndemnitors(def: Defendant) {
    this.indemnitors = [];
    def.transactions.forEach(trans => {
      trans.contacts.forEach(contact => {
        if (contact.Type === 'Indemnitor') {
          this.indemnitors.push(contact);
        }
      });
    });
  }

  getNotes(def: Defendant) {
    this.lastNote = 'No notes';
    let maxDate = moment('2001-01-01');
    def.transactions.forEach(trans => {
      trans.notes.forEach(note => {
        if (moment(note.NoteStamp) > maxDate) {
          this.lastNote = `Last Note: ${note.Text || note.Subject || ''}`;
          this.noteTransaction = trans.Id;
          maxDate = moment(note.NoteStamp);
        }
      });
    });
  }

  getDefaultPhone(def: Defendant) {
    def.person.phones.forEach(phone => {
      if (phone.IsDefault) {
        this.defaultPhone = phone;
      }
    });
  }

  getDefaultEmail(def: Defendant) {
    def.person.emails.forEach(email => {
      if (email.IsDefault) {
        this.defaultEmail = email;
      }
    });
  }

  getDefaultAddress(def: Defendant) {
    def.person.addresses.forEach(address => {
      if (address.IsDefault) {
        this.defaultAddress = address;
      }
    });
  }

  getPaymentDetails(def: Defendant) {
    let latestPaymentId = '';
    let mostRecentDate = moment().subtract(20, 'y');
    def.accountingdetails.forEach(payment => {
      if (payment.Description.toUpperCase() === 'PAYMENT') {
        const transDate = moment(payment.TransactionDate);
        if (transDate >= mostRecentDate && payment.Amount > 0) {
          mostRecentDate = transDate;
          this.recentPayment = payment;
          latestPaymentId = payment.PaymentId;
        }
      }
    });

    // Extract the payment sum
    this.paymentSum = def.accountingdetails
    .filter(payment => payment.Description.toUpperCase() === 'PAYMENT' && payment.PaymentId === latestPaymentId)
    .reduce((sum, payment) => {
      return sum + payment.Amount;
    }, 0);


    let upcomingDate = moment().add(50, 'y');
    const today = moment();
    def.transactions.forEach(trans => {
      if (!!trans.paymentschedule && !!trans.paymentschedule.details) {
        trans.paymentschedule.details.forEach(payment => {
          const dueDate = moment(payment.DueDate);
          if (dueDate.isAfter(mostRecentDate, 'day') && dueDate < upcomingDate && dueDate.isSameOrAfter(today, 'day')) {
            upcomingDate = dueDate;
            this.upcomingPayment = payment;
          }
        });
      }
    });
  }

  getCollateralPaymentDetails(def: Defendant) {
    let upcomingDate = moment('2100-01-01');
    def.transactions.forEach(trans => {
      trans.collaterals.forEach((collateral) => {
        if (collateral.cashcollateralpaymentschedule) {
          collateral.cashcollateralpaymentschedule.details.forEach((details) => {
            if ((moment(details.ScheduledDate) < upcomingDate) && moment(details.ScheduledDate) > moment()) {
              upcomingDate = moment(details.ScheduledDate);
              this.upcomingCollateralPayment = details;
            }
          });
        }
      });
    });
  }

  getCheckins(def: Defendant) {
    this.nextCheckin = 'No scheduled check ins';
    if (!!def.checkinschedule) {
      this.checkInSchedule = def.checkinschedule;
      // get next check in from schedule - lowest date that is today or after
      let minDate = moment('2050-01-01');
      def.checkinschedule.details
        .filter(checkin => moment(checkin.ScheduledDate) > moment().startOf('day'))
        .forEach(checkin => {
          if (moment(checkin.ScheduledDate) < minDate) {
            minDate = moment(checkin.ScheduledDate);
            this.nextCheckin = `Upcoming: ${minDate.format('ddd M/D/YY')}`;
          }
        });
    }

    this.previousCheckin = 'No past check ins';
    if (!!def.checkinhistory && def.checkinhistory.length > 0) {
      let maxDate = moment('2001-01-01');
      def.checkinhistory
        .filter(hist => moment(hist.Date) < moment().startOf('day').add(1, 'days'))
        .forEach(hist => {
          if (moment(hist.Date) > maxDate) {
            maxDate = moment(hist.Date);
            this.previousCheckin = `Latest: ${maxDate.format('ddd M/D/YY')}`;
          }
        });
    }
  }

  getCourtDateDetails(def: Defendant) {
    // get earliest court date that is after today
    let minCourtDate = moment('2050-01-01');
    this.nextCourt = '';
    def.transactions.forEach(trans => {
      trans.bonds.forEach(bond => {
        bond.courtdates.forEach(courtDate => {
          if (moment(courtDate.Date) >= moment().startOf('day') && moment(courtDate.Date) < minCourtDate) {
            minCourtDate = moment(courtDate.Date);
            this.nextCourt = `Next Court Date: ${minCourtDate.format('ddd M/D/YY h:mm A')}`;
            courtDate.loadData(courtDate.data);
            this.courtName = courtDate.Court?.Name;
            this.setFor = courtDate.CourtDateReason?.Name;
          }
        });
      });
    });
  }

  getMessages(def: Defendant) {
    let message = '';
    this.messages = [];
    if (!!def.messages) {
      this.messageTypes.forEach(type => {
        if (def.messages[type.Id] != null && def.messages[type.Id] !== 0) {
          switch (type.Id) {
            case 'ActiveForfeitureBondCount':
              message =
                'Defendant has ' +
                def.messages[type.Id] +
                ' active forfeiture' +
                (def.messages[type.Id] > 1 ? 's.' : '.');
              break;

            case 'OpenBondCount':
              message =
                'Defendant has ' +
                def.messages[type.Id] +
                ' open bond' +
                (def.messages[type.Id] > 1 ? 's.' : '.');
              break;

            case 'PastForfeitureBondCount':
              message =
                'Defendant has ' +
                def.messages[type.Id] +
                ' previous forfeiture' +
                (def.messages[type.Id] > 1 ? 's.' : '.');
              break;

            default:
              message = def.messages[type.Id];
              break;
          }
          this.messages.push({
            message: message,
            class: type.BadgeClass,
            alertClass: type.AlertClass,
          });
          if (type.showToast) {
            this.notify.queueSnackBar(message, type.ToastType);
          }
        }
      });
    }
  }

  openAddress(addr: Address) {
    const navUrl = 'https://maps.google.com?q=' + addr.singleLineAddress().replace(' ', '+');
    window.open(navUrl, '_blank');
  }

  // TODO: straight up copied from Defendant Component :,(

  onSelected(action: QuickAction<QuickActionPreviewEvent>) {
    this.position = { my: 'center', at: 'center', of: 'window' };
    this.height = '80vh';
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      this.popupWidth = 'auto';
      this.popupMinHeight = 'auto';
      this.popupMaxHeight = 'auto';
      if (action.value === QuickActionPreviewEvent.DOCUMENTS) {
       this.addDocuments();
      } else {
        let componentFactory: any;
        this.popupTitle = '';
        this.dynamicPopupVisible = false;

        switch (action.value) {
          case QuickActionPreviewEvent.NOTE:
            this.addNote();
            return;
          case QuickActionPreviewEvent.AUDITHISTORY:
            this.openAuditHistory();
            return;
          case QuickActionPreviewEvent.DATAMART:
            this.popupTitle = 'Arrests, Backgrounds & Credit';
            this.popupVisible = true;
            this.popupMaxWidth = 'auto';
            this.popupMaxHeight = '650px';
            componentFactory = this.componentFactoryResolver.resolveComponentFactory<DatamartComponent>(
              DatamartComponent,
            );
            break;

          case QuickActionPreviewEvent.FORMS:
            this.popupTitle = 'PDF Forms';
            this.popupVisible = true;
            this.position = { my: 'top', at: 'top', offset: '0 8' };
            this.height = '98vh';
            componentFactory = this.componentFactoryResolver.resolveComponentFactory<DefendantFormsComponent>(
              DefendantFormsComponent,
            );
            break;
          case QuickActionPreviewEvent.CHECKIN:
            this.popupTitle =
              'Check In History - ' + this.defendant.person.getFullName();
            this.popupVisible = true;
            componentFactory = this.componentFactoryResolver.resolveComponentFactory<LocationHistoryComponent>(
              LocationHistoryComponent,
            );
            this.popupWidth = '95%';
            this.popupMinHeight = '90%';
            this.popupMaxHeight = '100%';
            break;
          case QuickActionPreviewEvent.DEFENDANTWATCH:
            this.popupTitle = action.text;
            this.popupMaxWidth = '30%';
            this.height = '30%';
            this.popupVisible = true;
            componentFactory = this.componentFactoryResolver.resolveComponentFactory<DefendantWatchComponent>(
              DefendantWatchComponent,
            );
            break;
          case QuickActionPreviewEvent.RAPSHEET:
            this.openRapSheet();
            break;
          default:
            return;
        }
        setTimeout(() => {
          this.vcRef.clear();
          const ref = this.vcRef.createComponent(componentFactory);
          (<any>ref.instance).defendantId = this.defendantId;
          /**
           * @requires  Defendant to fetch the id from
           */
          switch (action.value) {
            case QuickActionPreviewEvent.DEFENDANTWATCH:
              (<DefendantWatchComponent>ref.instance).model = this.defendant;
              // Set this so that the close window doesn't launch its closing events
              // The result is unpredictable (without some investigation)
              this.isDefendantWatcherComponent = true;
              break;
            case QuickActionPreviewEvent.DATAMART:
              // Set the defendant
              (<DatamartComponent>ref.instance).defendant = this.defendant;
              (<DatamartComponent>ref.instance).doNotPopulateFromRecord = false;
              switch (action.text) {
                case 'Arrest History':
                  (<DatamartComponent>ref.instance).selectedReportType = ReportType.Incarceration;
                  break;
                case 'Background Checks':
                  (<DatamartComponent>ref.instance).selectedReportType = ReportType.General;
                  break;
                case 'Credit & FICO Check':
                  (<DatamartComponent>ref.instance).selectedReportType = ReportType.Credit;
                  break;
              }
              break;
            case QuickActionPreviewEvent.FORMS:
              (<DefendantFormsComponent>ref.instance).defendant = this.defendant;
              (<DefendantFormsComponent>ref.instance).contacts = [];
              (<DefendantFormsComponent>ref.instance).inDialog = true;
              const transactionId = this.defendant.transactions[this.defendant.transactions.length - 1].Id;
              this.subs.sink = this.defendantService.getDefendantContactsByDefId(this.defendant.Id).subscribe(contacts => {
                for (const transaction of this.defendant.transactions) {
                  if (transaction.Id === transactionId) {
                    for (const contact of contacts) {
                      if (contact.TransactionId === transaction.Id) {
                        const _contact = new Contact();
                        _contact.loadData(contact);
                        transaction.contacts.push(_contact);
                        if (_contact.Type === 'Indemnitor') {
                          (<DefendantFormsComponent>ref.instance).contacts.push(_contact);
                        }
                      }
                    }
                  }
                }
              });
              break;
            case QuickActionPreviewEvent.CHECKIN:
              (<LocationHistoryComponent>ref.instance).defendantModel = this.defendant;
              (<LocationHistoryComponent>ref.instance).showCheckinHistory = true;
              break;
          }
          (<any>ref.instance).inDynamicPopup = true;
          (<any>ref.instance).hideHeader = true;
        }, 100);
      }
    }
  }

  openAuditHistory() {
    this.dynamicPopupClass = DefendantAuditHistoryComponent;
    this.dynamicPopupTitle = 'Audit History';
    this.dynamicPopupData = [];
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = this.defendantId;
    this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = this.defendant.person.getFullName();
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;
  }

  dynamicPopupHidden() {
    this.dynamicPopupMaxHeight = AppConstants.DEFAULTPOPUPCONFIG.MAXHEIGHT;
    this.dynamicPopupMaxWidth = AppConstants.DEFAULTPOPUPCONFIG.MAXWIDTH;
    this.dynamicPopupDragEnabled = AppConstants.DEFAULTPOPUPCONFIG.DRAGENABLED;
    // Don't fire off if modal-focus is equal to these objects
    if (this.dynamicPopupClass !== DefendantAuditHistoryComponent && !this.isDefendantWatcherComponent) {
      this.btnFullProfileDisabled = true; // this is to handle adding taking payments and court dates on the defendant preview
      this.loadData();
    }
    this.isDefendantWatcherComponent = false;
    this.dynamicPopupClass = null;
  }

  recordCheckin() {
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      this.dynamicPopupClass = CheckinComponent;
      this.dynamicPopupData = [];
      this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = this.defendantId;
      this.dynamicPopupData[AppConstants.CHECK_IN_SCHEDULE] = this.checkInSchedule;
      this.dynamicPopupData[
        AppConstants.DEFENDANT_NAME_KEY
        ] = this.defendant.person.getFullName();

      this.dynamicPopupTitle = 'Record Check In';
      this.dynamicPopupVisible = true;
      this.dynamicPopupShowTitle = true;
    }
  }

  takePayment() {
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      this.dynamicPopupClass = PaymentComponent;
      this.dynamicPopupData = [];
      this.dynamicPopupTitle = 'Take Payment';
      this.dynamicPopupData['DownPaymentAmount'] = 0;
      this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = this.defendantId;
      this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = this.defendant.person.getFullName();
      this.dynamicPopupShowTitle = true;
      this.dynamicPopupVisible = true;
    }
  }

  addNote() {
    const config: MatDialogConfig = {
      data: {
        defendant: this.defendant,
      },
      width: 'calc(100% - 50%)',
      hasBackdrop: false,
      panelClass: 'draggable-dialog',
    };
    if (this.isMobile) {
      config.width = '100%';
      config.height = '100%';
    }
    const dialog = this.dialog.open(DefendantNotesDialogComponent, config);
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        dialog.updateSize('100vw', '100vh');
      } else {
        dialog.updateSize('calc(100% - 50%)', '');
      }
    });
    const closeSub = dialog.afterClosed().subscribe((notes: any) => {
      const updatedNotesArr : Note[] = [];
      notes.forEach((note: any) => {
        const updatedNote = new Note();
        updatedNote.loadData(note);
        updatedNote.User = note?.user?.data?.identity?.data?.FirstName + " " + note?.user?.data?.identity?.data?.LastName;
        updatedNotesArr.push(updatedNote);
      });

      this.defendant.transactions[this.defendant.transactions.length - 1].notes = updatedNotesArr;
      this.store.dispatch(addDefendant({ defendant: this.defendant, section: null }));
      this.getNotes(this.defendant);
      smallDialogSubscription.unsubscribe();
      closeSub.unsubscribe();
    });
  }

  addDocuments() {
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      const config: MatDialogConfig = {
        data: {
          defendant: this.defendant,
        },
        width: 'calc(100% - 50px)',
      };
      if (this.isMobile) {
        config.width = '100%';
        config.height = '100%';
      }
      const dialog = this.dialog.open(DocumentDialogComponent, config);
      const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
        if (size.matches) {
          dialog.updateSize('100vw', '100vh');
        } else {
          dialog.updateSize('calc(100% - 50px)', '');
        }
      });
      const closeSub = dialog.afterClosed().subscribe(() => {
        smallDialogSubscription.unsubscribe();
        closeSub.unsubscribe();
      });
    }
  }

  openBondStatus(action: string, bondId: string, defendant: Defendant): void {
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      const config: MatDialogConfig = {
        width: '35%',
        height: action === 'exonerate' ? '52%' : '45%',
        panelClass: 'bond-status-dialog',
        hasBackdrop: true,
        data: { action, bondId, defendant },
      };
      if (this.isMobile) {
        config.width = '100%';
        config.height = '100%';
      }
      const dialogRef = this.dialog.open(BondStatusUpdaterComponent, config);

      this.subs.sink = dialogRef.afterClosed().subscribe(event => {
        this.btnFullProfileDisabled = true;
        this.loadData();
      });
    }
  }

  addCourtDate(defendant: Defendant): void {
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      let componentFactory: any;
      this.popupTitle = 'Add Court Date';
      this.popupMaxHeight = '65%';
      this.dynamicPopupVisible = false;
      this.popupVisible = true;
      componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        CourtDateSchedulerComponent,
      );
      setTimeout(() => {
        this.vcRef.clear();
        const ref = this.vcRef.createComponent(componentFactory);
        (<any>ref.instance).defendant = this.defendant;
        this.subs.sink = (<any>ref.instance).close.subscribe(event => {
          this.loadData();
          this.popupVisible = false;
        });
      }, 100);
    }
  }

  updateSessionData() {
    this.routeDataService.setValue(AppConstants.DEFENDANT_ID_KEY, this.defendant.Id);
    this.routeDataService.setValue(AppConstants.DEFENDANT_NAME_KEY, this.defendant.person.FullName);
    if (this.defendant.transactions && this.defendant.transactions.length > 0) {
      this.routeDataService.setValue(AppConstants.TRANSACTION_ID_KEY,
        this.defendant.transactions[this.defendant.transactions.length - 1].Id,
      );
    }
  }

  indemnitorInfo(indemnitor) {
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      this.router.navigate(['defendant', this.defendant.Id, 'full'],
        { queryParams: { anchor: 'contacts', contactId: indemnitor.Id, transactionId: indemnitor.TransactionId } });
    }
  }

  navigateToLastNote() {
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      this.router.navigate(['defendant', this.defendant.Id, 'full'],
        { queryParams: { anchor: 'notes', transactionId: this.noteTransaction } });
      this.eventService.closeSlideout();
    }
  }

  powerInfo(bond) {
    if (this.defendantId === 'demo') {
      this.loadDemoWarning();
    } else {
      this.router.navigate(['defendant', this.defendant.Id, 'full'],
        { queryParams: { anchor: 'powers', bondId: bond.Id, transactionId: bond.TransactionId, slideout:this.slideOut } });
      this.eventService.closeSlideout();
    }
  }

  openRapSheet() {
    this.dynamicPopupClass = PdfViewerComponent;
    this.dynamicPopupTitle = 'Print Rap Sheet';

    const fieldValue = new FieldValues();
    fieldValue.field = 'DefendantID';
    fieldValue.hashedValues = [this.defendant.Id];

    this.dynamicPopupData = [];
    this.dynamicPopupData['reportCategory'] = 'Rap Sheet';
    this.dynamicPopupData['reportFieldValues'] = fieldValue;
    this.dynamicPopupData['objectTypeName'] = 'rap sheet';
    this.dynamicPopupData['sourceType'] = 'Defendant';
    this.dynamicPopupData['sourceId'] = this.defendant.Id;
    this.dynamicPopupData['objectName'] = 'Defendant Rap sheet';
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;
  }

  showChargesTooltip(event, showTooltip, bond) {
    if (this.tooltip) {
      if (showTooltip && bond.Charge) {
        this.tooltipText = bond.Charge;
        this.tooltip.instance.option('target', event.target);
        this.tooltip.instance.show();
      } else {
        this.tooltip.instance.hide();
      }
    }
  }

  onFullProfileClick(event: MouseEvent) {
    if (this.defendantId === 'demo') {
      event.preventDefault();
      event.stopPropagation();
      this.loadDemoWarning();
    } else {
      this.eventService.closeSlideout();

       this.router.navigate(['defendant', this.defendant.Id, 'full'],
        { queryParams: { slideout:this.slideOut } });
    }
  }

  public handleNeedsPaperwork(checked: boolean, index: number): void {
    if (!checked) {
      this.defendant.transactions[index].NeedsPaperwork = checked;
      this.updateTransaction(this.defendant.transactions[index])
        .subscribe(result => {
          this.notify.toast('Updated and marked as complete', 'success');
        }, error => {
          this.notify.toast('Error marking as complete', 'error');
        });
    }
  }

  public updateInfo(type: PersonUpdateType) {
    if (this.isEditing) {
      return;
    } else {
      this.isEditing = true;
      const dialogRef = this.dialog.open(PersonUpdateDialogComponent, {
        data: {
          type,
          person: this.defendant.person,
        },
        minWidth: '30vw',
        minHeight: '30vh',
      });
      this.subs.sink = dialogRef.afterClosed().subscribe(person => {
        this.isEditing = false;
        if (!!person) {
          this.defendant.person.loadData(person);
          this.setPersonalUI(this.defendant);
        }
      });
    }
  }

  public handleStatusUpdate(index: number): void {
    let status = this.defendant.transactions[index].Status;
    const config: MatDialogConfig = {
      width: '35%',
      height: '45%',
      panelClass: 'bond-status-dialog',
      hasBackdrop: true,
      data: {status: status}
    };
    if (this.isMobile) {
      config.width = '100%';
      config.height = '100%';
    }
    const dialogRef = this.dialog.open(TransactionStatusUpdaterComponent, config);
    this.subs.sink = dialogRef.afterClosed().subscribe(event => {
      if(event === 'cancel') {
        this.defendant.transactions[index].Status = 'Prospect';
        return;
      }

      if (event || status === 'Dead') {  // Incase of dead status, we want to bypass this if as reason can be blank
        if(status === 'Client') {
          this.defendant.transactions[index].PostedDate = event;
        } else if(status === 'Dead') {
          this.defendant.transactions[index].DeadReason = event;
        }

        // Show contact popup
        if(this.defendant.transactions[index].contacts.length > 0 && !this.defendant.transactions[index].contacts[0].Type) {
          const config: MatDialogConfig = {
            width: '35%',
            height: '45%',
            panelClass: 'bond-status-dialog',
            hasBackdrop: true,
            data: {contact: this.defendant.transactions[index].contacts[0]}
          };

          if (this.isMobile) {
            config.width = '100%';
            config.height = '100%';
          }

          const dialogRef = this.dialog.open(ContactStatusUpdaterComponent, config);
          this.subs.sink = dialogRef.afterClosed().subscribe((res: any) => {
            if(res === 'cancel') {
              this.onUpdateTransaction(index, status, event, false);
              return;
            } else {
              this.defendant.transactions[index].contacts[0] = res;
              this.onUpdateTransaction(index, status, event, true);
            }
          })
        } else {
          this.onUpdateTransaction(index, status, event, false);
        }
      } else {
        this.defendant.transactions[index].Status = 'Prospect';
        return;
      }
    });
  }

  onUpdateTransaction(index: number, status: string, event: string, updateContact = false) {
    this.updateTransaction(this.defendant.transactions[index]).subscribe(() => {
      this.defendant.transactions[index]['orig_Status'] = status;

      if(status === 'Dead') {
        this.defendant.transactions[index]['orig_DeadReason'] = event;
      } else if(status === 'Client') {
        this.defendant.transactions[index]['orig_PostedDate'] = event;
      }

      this.notify.toast('Status updated', 'success');

      if(updateContact) {
        this.notify.toast('Updating contact...');
        this.subs.sink = this.contactService.updateContact(this.defendant.transactions[index].contacts[0]).subscribe(() => {
          this.notify.toast('Contact updated.');
        })
      }

    }, error => {
      this.notify.toast('Error updating status', 'error');
    });
  }

  navigateToApplicationPage(transactionId) {
    const customerId = localStorage.getItem('activeCustomerId');
    let url = '';
    if (customerId) {
       url = this.router.serializeUrl(
        this.router.createUrlTree([`/bail/application/${customerId}/${transactionId}`]),
      );
    } else {
      return;
    }
    if (url !== '') {
      window.open(url, '_blank');
    }
  }

  private toggleSave(type: 'success' | 'error') {
    this.savingData = false;
    this.editingMobile = false;
    this.editingEmail = false;
    this.editingAddress = false;
    this.notify.toast('Update Complete', type);
  }

  private updateTransaction(transaction: Bail): Observable<boolean> {
    this.transactionsLoading = true;
    return new Observable(sub => {
      this.transactionService.updateExistingTransaction(transaction.Id, transaction, 'checkinschedule,checkinhistory', true)
        .subscribe((res) => {
          sub.next(true);
          this.transactionsLoading = false;
          // Create object that is acceptable the getcheckins fn
          if(res.data.checkinschedule && res.data.checkinhistory && res.data.checkinhistory.data.length > 0) {
            let def = new Defendant();
            def.checkinhistory = res.data.checkinhistory.data;
            def.checkinschedule = res.data.checkinschedule.data;
            def.checkinschedule.details = res.data.checkinschedule.data.details.data;
            this.checkInSchedule = def.checkinschedule;
            this.getCheckins(def);
          }
        }, error => {
          sub.error(false);
          this.transactionsLoading = false;
        });
    });
  }

  private loadDemoWarning() {
    let link = localStorage.getItem('NewDefendantMode');
    if (!link || link === 'undefined') {
      link = '/defendant/wizard';
    }
    this.dialog.open(AddDefendantWarningComponent, {
      data: {
        title: 'Add a Defendant',
        message: `Oops! This defendant is just a demo. Add a Defendant\n
                    to get started and see the full profile.`,
        link,
      },
    });
  }

  getTotalLiability(def: Defendant) {
    this.totalLiability = 0;
    def.transactions.forEach(trans => {
      trans.bonds.forEach(bond => {
        this.totalLiability += bond.Amount;
      });
    });
  }

  private setDefaults() {
    this.defaultPhone = null;
    this.defaultEmail = null;
    this.defaultAddress = null;
    this.recentPayment = null;
    this.upcomingPayment = null;
    this.upcomingCollateralPayment = null;
    this.lastNote = null;
    this.btnFullProfileDisabled = true;
    this.defendant.transactions = [];
  }

}
