import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, ViewChild, SimpleChanges } from '@angular/core';
import { LookupService } from '../../../../services/app-lookup.service';
import { Bond } from '../../../../models/bond';
import * as moment from 'moment';
import { RouteDataService } from '../../../../services/routedata.service';
import { DxDateBoxComponent } from 'devextreme-angular';
import { StringUtilities } from '../../../../shared/StringUtilities';

@Component({
  selector: 'app-satisfy-by-date',
  templateUrl: './satisfy-by-date.component.html',
  styleUrls: ['./satisfy-by-date.component.scss']
})
export class SatisfyByDateComponent implements OnInit {
  @ViewChild('satisfyDateBox', { static: false }) satisfyDateBox: DxDateBoxComponent
  @Input() model: Bond;
  _forfeitDate: string;
  @Input() set forfeitDate(value: string) {
    this._forfeitDate = value;
    if (this.satisfyDateBox && (!this.checkCalculateSatisfyByDate)) {
      this.satisfyDateBox.value = moment(this._forfeitDate).add(this.calculateSatisfyByDateDays, 'days').format('YYYY-MM-DD');
    }
  };

  bond: Bond;
  calculatedSatisfyByDate = moment();
  @Output() closeSetSatisfyByDatePopup = new EventEmitter<void>();
  calculateSatisfyByDateDays = 60;
  checkCalculateSatisfyByDate: boolean = true;
  dateFormatter = StringUtilities.dateFormatter;

  constructor(private cdr: ChangeDetectorRef, private sessionService: RouteDataService) { }

  ngOnInit() {
    if (this.model) {
      this.bond = this.model;
    } else {
      this.bond = new Bond();
    }

    if (
      this.sessionService.getObject('systemConfig') != null &&
      this.sessionService.getObject('systemConfig').CalculateSatisfyByDate
    ) {
      this.checkCalculateSatisfyByDate = true;
    } else {
      this.checkCalculateSatisfyByDate = false;
    }

    // Check if we have any satisfyByDateDays var in localstorage
    const days = localStorage.getItem('satisfyByDateDays');
    if (!!days && days !== 'undefined' && !isNaN(JSON.parse(days))) {
      // If it is present, then we assign this and add it to forfeit date.
      this.calculateSatisfyByDateDays = Number(days);
    }
  }

  ngAfterViewInit() {
    if (!this.checkCalculateSatisfyByDate) {
      this.satisfyDateBox.value = moment(this._forfeitDate).add(this.calculateSatisfyByDateDays, 'days').format('YYYY-MM-DD');
    }
  }

  setSatisfyByDate() {
    this.cdr.detectChanges();
    this.calculateSatisfyByDate();
    localStorage.setItem('satisfyByDateDays', this.calculateSatisfyByDateDays.toString());
    this.bond.ForfeitureSatisfyByDate = this.calculatedSatisfyByDate.format('YYYY-MM-DD');
    this.closeSetSatisfyByDatePopup.emit()
  }

  setSatisfyByOnlyDate() {
    this.cdr.detectChanges();
    this.bond.ForfeitureSatisfyByDate = this.satisfyDateBox.value;
    // Calculate the satisy by date days
    this.calculateSatisfyByDateDays = moment(this.satisfyDateBox.value).diff(this._forfeitDate, 'days');
    localStorage.setItem('satisfyByDateDays', this.calculateSatisfyByDateDays.toString());
    this.closeSetSatisfyByDatePopup.emit();
  }

  calculateSatisfyByDate() {
    this.calculatedSatisfyByDate = moment(this._forfeitDate).add(this.calculateSatisfyByDateDays, 'days');
  }
}
