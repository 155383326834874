import { forkJoin, Observable, of, throwError, Subject } from 'rxjs';
import { catchError, map, share, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { Document } from '../models/document';
import { ObjectUtilities } from '../shared/ObjectUtilities';
import { EventService } from './event.service';
import Compressor from 'compressorjs';
import { FileUtilities } from '../shared/FileUtilities';
import { environment } from '../../environments/environment';
import { PortoResponse } from '../models/porto-response';
import { GetObjectRequest, ListObjectVersionsOutput, ObjectVersion } from 'aws-sdk/clients/s3';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;
import { Share } from '../models/share';
import { ShareStatus } from '../models/share';
import { forEach } from 'lodash';

declare var AWS: any;

@Injectable()
export class DocumentService {

  // the company name (lowercase and nospaces) will be used as bucketname
  // the hashed companyid will be used to create a default user to use for this company
  s3Ep = new AWS.Endpoint('s3.wasabisys.com');


  currentUserAccessKeyId = localStorage.getItem('documentKeyId');
  currentUserDocumentRoot = localStorage.getItem('documentRoot');
  currentUserSecretAccessKey = '';

  documentCache = {};

  newDocumentAdded = new Subject<Document>();

  constructor(private http: HttpClient, private settings: SettingsService, private eventService: EventService) {
  }

  initSettings() {
    this.currentUserAccessKeyId = localStorage.getItem('documentKeyId');
    this.currentUserDocumentRoot = localStorage.getItem('documentRoot');
    this.currentUserSecretAccessKey = '';
  }

  init(accessKey: string, documentRoot: string) {
    this.currentUserAccessKeyId = accessKey;
    this.currentUserDocumentRoot = documentRoot;
  }

  addDefendantProfilePic(theFile): Observable<any> {
    return new Observable((obs) => {

      const self = this;
      let file: any;

      if (theFile === undefined) {
        // EM - we expect the fle to be undefined as part of a normal defendant save with no picture update
        obs.next({data: undefined, success: true});
        return;
      } else if (theFile.value !== undefined && theFile.value.length > 0) {
        file = theFile.value[0];
      } else {
        file = theFile;
      }

      if (file && file.name) {
        this.initDocument(file.name)
          .pipe(take(1))
          .subscribe((resp: any) => {
            if (resp.data.Key.Key !== '') {

              const s3 = new AWS.S3({
                endpoint: this.s3Ep,
                accessKeyId: this.currentUserAccessKeyId,
                secretAccessKey: resp.data.Key.Key,
              });

              const params = {
                Bucket: resp.data.Key.Root,
                Key: this.generateUniqueFileName(file.name), // file.name,
                Body: file,
                ACL: 'public-read',
              };

              s3.upload(params, function (s3Err, data) {
                if (s3Err) {
                  obs.error({data: s3Err, success: false});
                } else {
                  obs.next({data: data, success: true});
                }
              });
            }
          }, initErr => {
            obs.error({data: initErr, success: false});
          });
      }
    });
  }

  addCheckinProfilePic(theFile, id, code): Observable<any> {
    this.eventService.componentBusy(true);
    return new Observable((obs) => {
      const self = this;
      let file: any;
      if (theFile === undefined) {
        // EM - we expect the fle to be undefined as part of a normal defendant save with no picture update
        self.eventService.componentBusy(false);
        obs.next({data: undefined, success: true});
        return;
      } else if (theFile.value !== undefined && theFile.value.length > 0) {
        file = theFile.value[0];
      } else {
        file = theFile;
      }
      const compressor = new Compressor(file, {
        quality: 0.4,
        checkOrientation: true,
        success(result) {
          // console.log('compression result: ', result);
          self.initCheckinDocument(file.name, id, code)
            .pipe(take(1))
            .subscribe((resp: any) => {
              self.eventService.componentBusy(true);
              if (resp.data.Key.Key !== '') {
                const s3 = new AWS.S3({
                  endpoint: self.s3Ep,
                  accessKeyId: self.currentUserAccessKeyId,
                  secretAccessKey: resp.data.Key.Key,
                });
                const params = {
                  Bucket: resp.data.Key.Root,
                  Key: self.generateUniqueFileName(file.name), // file.name,
                  Body: result,
                  ACL: 'public-read',
                };
                s3.upload(params, function (s3Err, data) {
                  if (s3Err) {
                    self.eventService.componentBusy(false);
                    obs.error({data: s3Err, success: false});
                  } else {
                    self.eventService.componentBusy(false);
                    obs.next({data: data, success: true});
                  }
                });
              }
            }, initErr => {
              self.eventService.componentBusy(false);
              obs.error({data: initErr, success: false});
            });
        },
        error(err) {
          obs.error({data: err.message, success: false});
        },
      });
    });
  }

  processDocumentsBatch(docs): Observable<any> {
    const docsObservable: Observable<Response>[] = [];
    docs.forEach(theDoc => {
      if (theDoc.isAdded) {
        docsObservable.push(this.addDocument(theDoc));
      } else {
        docsObservable.push(this.removeDocument(theDoc.Id));
      }
    });
    return forkJoin(docsObservable);
  }

  // Upload Document to wasabi w3 server via documents table
  addDocument(theDocument, internal = false, shares: Share[] = []): Observable<any> {
    return new Observable((obs) => {
      const self = this;
      let file: any;
      if (theDocument.File && theDocument.File.value !== undefined && theDocument.File.value.length > 0) {
        file = theDocument.File.value[0];
      } else if (theDocument.File === undefined) {
        throwError('No file attach to document object');
      } else {
        file = theDocument.File;
      }
      let custId = '-1';
      if (theDocument.hasOwnProperty('CustomerId')) {
        custId = theDocument.CustomerId;
      }
      if (file && file.name) {
        this.initDocument(file.name, internal, custId)
          .pipe(take(1))
          .subscribe((resp: any) => {
            if (resp.data.Key.Key !== '') {
              const s3 = new AWS.S3({
                endpoint: this.s3Ep,
                accessKeyId: this.currentUserAccessKeyId,
                secretAccessKey: resp.data.Key.Key,
              });
              const params = {
                Bucket: resp.data.Key.Root,
                Key: this.generateUniqueFileName(file.name), // file.name,
                Body: file,
              };

              s3.upload(params, function (s3Err, data) {
                if (s3Err) {
                  obs.error({data: s3Err, success: false});
                } else {
                  theDocument.DocumentLink = params.Key;
                  self.createNewDocument(theDocument, internal).pipe(take(1)).subscribe((resp2: any) => {
                    resp2.tempKey = theDocument.tempKey;
                    resp2.data.shares = shares.map(share => {
                      share.Status = ShareStatus.pending;
                      return share;
                    });
                    resp2.data.File = [];
                    self.newDocumentAdded.next(resp2.data);
                    obs.next({data: resp2, success: true});
                  }, createErr => {
                    obs.error({data: createErr, success: false});
                  });
                }
              });
            }
          }, initErr => {
            obs.error({data: initErr, success: false});
          });
      }
    });
  }

  /**
   * getObjectVersions takes a document id and returns an s3 ObjectVersionResponse
   * @param id
   */
  getObjectVersions(id: string): Observable<ListObjectVersionsOutput> {
    return new Observable((obs) => {
      this.getDocumentInfoById(id)
        .pipe(take(1))
        .subscribe((resp: any) => {
          const document = new Document(resp.data);
          if (resp.data.Key.Key !== '') {
            const s3 = new AWS.S3({
              endpoint: this.s3Ep,
              accessKeyId: this.currentUserAccessKeyId,
              secretAccessKey: document.Key.Key,
            });
            const params = {
              Bucket: document.Key.Root,
              Prefix: document.DocumentLink,
            };
            s3.listObjectVersions(params, function (err, data: ListObjectVersionsOutput) {
              if (err) {
                obs.error({error: err, success: false});
              } else {
                obs.next(data);
              }
            });
          }
        }, fetchErr => {
          obs.error({error: fetchErr, success: false});
        });
    });
  }

  // Upload company logo as a document, to be used in Collections Reminder Email
  uploadPublicDocument(theDocument, internal = false): Observable<any> {
    return new Observable((obs) => {
      const self = this;
      let file: any;
      if (theDocument.File && theDocument.File.value !== undefined && theDocument.File.value.length > 0) {
        file = theDocument.File.value[0];
      } else if (theDocument.File === undefined) {
        throwError('No file attach to document object');
      } else {
        file = theDocument.File;
      }

      if (file && file.name) {
        this.initDocument(file.name)
          .pipe(take(1))
          .subscribe((resp: any) => {
            if (resp.data.Key.Key !== '') {
              const s3 = new AWS.S3({
                endpoint: this.s3Ep,
                accessKeyId: this.currentUserAccessKeyId,
                secretAccessKey: resp.data.Key.Key,
              });
              const params = {
                'Bucket': resp.data.Key.Root,
                'Key': this.generateUniqueFileName(file.name), // file.name,
                'Body': file,
                'Content-Type': file.type,
                'ACL': 'public-read',
                'Content-Disposition': 'inline',
              };

              s3.upload(params, function (s3Err, data) {
                if (s3Err) {
                  obs.error({data: s3Err, success: false});
                } else {
                  theDocument.DocumentLink = params.Key;
                  self.createNewDocument(theDocument, internal)
                    .pipe(take(1))
                    .subscribe((resp2: any) => {
                      resp2.tempKey = theDocument.tempKey;
                      obs.next({data: resp2, success: true});
                    }, createErr => {
                      obs.error({data: createErr, success: false});
                    });
                }
              });
            }
          }, initErr => {
            obs.error({data: initErr, success: false});
          });
      }
    });
  }

  // upload directly to wasabi (not interaction with document table our side
  addDocumentDirect(theFile): Observable<any> {
    return new Observable((obs) => {
      const self = this;
      let file: any;
      if (theFile.value !== undefined && theFile.value.length > 0) {
        file = theFile.value[0];
      } else {
        file = theFile;
      }
      if (file && file.name) {
        this.initDocument(file.name)
          .pipe(take(1))
          .subscribe((resp: any) => {
            if (resp.data.Key.Key !== '') {
              const s3 = new AWS.S3({
                endpoint: this.s3Ep,
                accessKeyId: this.currentUserAccessKeyId,
                secretAccessKey: resp.data.Key.Key,
              });
              const params = {
                Bucket: resp.data.Key.Root,
                Key: this.generateUniqueFileName(file.name), // file.name,
                Body: file,
              };
              s3.upload(params, function (s3Err, data) {
                if (s3Err) {
                  obs.error({data: s3Err, success: false});
                } else {
                  obs.next({data: data, success: true});
                }
              });
            }
          }, initErr => {
            obs.error({data: initErr, success: false});
          });
      }
    });
  }

  retrieveDocument(id, external = false, version?: string): Observable<any> {
    if (external) {
      return new Observable((obs) => {
        this.getDocumentById(id)
          .pipe(take(1))
          .subscribe((doc: Document) => {
            const document = new Document(doc);
            if (doc.Key.Key !== '') {
              this.currentUserAccessKeyId = document.Key.Access;
              this.currentUserSecretAccessKey = document.Key.Key;
              const s3 = new AWS.S3({
                endpoint: this.s3Ep,
                accessKeyId: document.Key.Access,
                secretAccessKey: document.Key.Key,
              });
              const params = {
                Bucket: document.Key.Root,
                Key: document.DocumentLink,
              };
              s3.getObject(params, function(err, file) {
                if (err) {
                  obs.error({ error: err, success: false });
                } else {
                  document.File = file;
                  obs.next({ document: document, success: false });
                }
              });
            }
          }, fetchErr => {
            obs.error({ error: fetchErr, success: false });
          });
      });
    } else {
      return new Observable((obs) => {
        this.getDocumentInfoById(id, external)
          .pipe(take(1))
          .subscribe((resp: any) => {
            const document = new Document(resp.data);
            if (resp.data.Key.Key !== '') {
              const s3 = new AWS.S3({
                endpoint: this.s3Ep,
                accessKeyId: document.Key.Access,
                secretAccessKey: document.Key.Key,
              });
              const params: GetObjectRequest = {
                Bucket: document.Key.Root,
                Key: document.DocumentLink,
              };
              // if (document.Type === 'application/pdf') {
                s3.listObjectVersions({ Bucket: document.Key.Root, Prefix: document.DocumentLink }, (err, data) => {
                  if (err) {
                    obs.error({ error: err, success: false });
                  } else {
                    const versions = data.Versions;
                    if (versions && versions.length > 1) {
                      // Sort versions by LastModified date in descending order
                      versions.sort((a, b) => new Date(b.LastModified).getTime() - new Date(a.LastModified).getTime());
                      // Get the most recent version
                      params.VersionId = versions[0].VersionId;
                      this.getObjectByVersion(versions[0]).subscribe((pdfbase64) => {
                        obs.next({ pdfbase64, success: false, document });
                      }, error => {
                        obs.error({ error, success: false });
                      });
                    } else {
                      const s32 = new AWS.S3({
                        endpoint: this.s3Ep,
                        accessKeyId: document.Key.Access,
                        secretAccessKey: document.Key.Key,
                      });
                      s32.getObject(params, (error, file) => {
                        if (error) {
                          obs.error({ error, success: false });
                        } else {
                          document.File = file;
                          obs.next({ document: document, success: false });
                        }
                      });
                    }
                  }
                });
              // } else {
              //   s3.getObject(params, function(err, file) {
              //     if (err) {
              //       obs.error({ error: err, success: false });
              //     } else {
              //       document.File = file;
              //       obs.next({ document: document, success: false });
              //     }
              //   });
              // }
            }
          }, fetchErr => {
            obs.error({ error: fetchErr, success: false });
          });
      });
    }
  }

  retrieveDocumentForPdfViewer(id: string): Observable<any> {
    return this.retrieveDocument(id, true)
      .pipe(map((doc) => {
        // this returns a PDF file, not a report response..
        return {
          pdfBase64: FileUtilities.binaryToBase64(doc?.document?.File?.Body),
          pdfBinary: doc?.document?.File?.Body,
          doc: doc,
        };
      }));
  }

  retrieveDocumentDirect(fileName): Observable<any> {
    return new Observable((obs) => {
      this.initDocument(fileName)
        .pipe(take(1))
        .subscribe((resp: any) => {
          if (resp.data.Key.Key !== '') {
            const s3 = new AWS.S3({
              endpoint: this.s3Ep,
              accessKeyId: this.currentUserAccessKeyId,
              secretAccessKey: resp.data.Key.Key,
            });
            const params = {
              Bucket: resp.data.Key.Root,
              Key: fileName,
            };
            s3.getObject(params, function (err, file) {
              if (err) {
                obs.error({error: err, success: false});
              } else {
                obs.next({file: file, success: false});
              }
            });
          }
        }, initErr => {
          obs.error({data: initErr, success: false});
        });
    });
  }

  // Remove Document from wasabi w3 server
  removeDocument(id, internal = false): Observable<any> {
    const self = this;
    return new Observable((obs) => {
      this.getDocumentInfoById(id, internal)
        .pipe(take(1))
        .subscribe((resp: any) => {
          if (resp.data.Key.Key !== '') {
            const s3 = new AWS.S3({
              endpoint: this.s3Ep,
              accessKeyId: this.currentUserAccessKeyId,
              secretAccessKey: resp.data.Key.Key,
            });
            const params = {
              Bucket: resp.data.Key.Root,
              Key: resp.data.DocumentLink,
            };
            if (!isNullUndefinedOrEmpty(resp.data.DocumentLink)) {
              s3.deleteObject(params, function (err, data) {
                if (err) {
                  self.deleteDocumentById(id, internal).pipe(take(1)).subscribe(delResp => {
                      obs.error({data: resp, success: true});
                    },
                    delErr => {
                      obs.error({data: delErr, success: false});
                    });
                } else {
                  self.deleteDocumentById(id, internal).pipe(take(1)).subscribe(delResp => {
                      obs.next({data: resp, success: true});
                      obs.complete();
                    },
                    delErr => {
                      obs.error({data: delErr, success: false});
                    });
                }
              });
            } else {
              // em no s3 link, so be it, we just delete it fom db then
              self.deleteDocumentById(id, internal).pipe(take(1)).subscribe(delResp => {
                  obs.next({data: resp, success: true});
                  obs.complete();
                },
                delErr => {
                  obs.error({data: delErr, success: false});
                });
            }
          }

        }, fetchErr => {
          obs.error({error: fetchErr, success: false});
        });
    });

  }

  removeDocumentDirect(fileName): Observable<any> {
    return new Observable((obs) => {
      this.initDocument(fileName)
        .pipe(take(1))
        .subscribe((resp: any) => {
          if (resp.data.Key.Key !== '') {
            const s3 = new AWS.S3({
              endpoint: this.s3Ep,
              accessKeyId: this.currentUserAccessKeyId,
              secretAccessKey: resp.data.Key.Key,
            });
            const params = {
              Bucket: resp.data.Key.Root,
              Key: fileName,
            };
            s3.deleteObject(params, function (err, data) {
              if (err) {
                obs.error({data: err, success: false});
              } else {
                obs.next({data: resp, success: true});
              }
            });
          }
        }, initErr => {
          obs.error({data: initErr, success: false});
        });
    });
  }

  generateUniqueFileName(fileName: string) {
    // let prefix a datestamp t make filename unique and add some value to the name itself
    fileName = !!fileName ? fileName : 'document';
    const pos = !!fileName && fileName.lastIndexOf('.');
    if (pos > 0) {
      return fileName.substring(0, pos) + '_' +
        moment().format('YYYYMMDDHHmmSS') + (Math.floor(Math.random() * 1000000) + 1) + fileName.substring(pos);
    } else {
      return fileName + '_' + moment().format('YYYYMMDDHHmmSS') + (Math.floor(Math.random() * 1000000) + 1);
    }
  }

  getDocumentInfoById(id, internal = false) {
    if (internal) {
      return this.http.get(`${this.settings.getBaseUrl()}/internaldocuments/${id}/${this.currentUserAccessKeyId}`).pipe(
        map((res: any) => res),
        catchError(error => throwError(error || 'Server Error')));
    } else {
      if (!!this.documentCache[id]) {
       return of(this.documentCache[id]);
      } else {
        return this.http.get(`${this.settings.getBaseUrl()}/documents/${id}/${this.currentUserAccessKeyId}`).pipe(
          map((res: any) => {
            this.documentCache[id] = res;
            return res;
          }),
          catchError(error => throwError(error || 'Server Error')));
      }
    }
  }

  getDocumentById(id: string): Observable<Document> {
    return this.http.get<PortoResponse<Document>>(`${this.settings.getBaseUrl()}/document/${id}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(error || 'Server Error')));
  }

  initCheckinDocument(fileName, checkInId, checkInCode) { // used for anonymous uploads via checkin
    const postData = {
      fileName: fileName,
      id: checkInId,
      code: checkInCode,
    };
    return this.http.post(`${this.settings.getBaseUrl()}/documents/checkin/new/${this.currentUserAccessKeyId}`, postData).pipe(
      map((res: any) => res),
      catchError(error => throwError(error || 'Server Error')));
  }

  initDocument(fileName, internal = false, customerId: string = '-1') {
    const postData = {
      fileName: fileName,
      CustomerId: customerId,
    };
    if (internal) {
      return this.http.post(`${this.settings.getBaseUrl()}/internaldocuments/new/${this.currentUserAccessKeyId}`, postData).pipe(
        map((res: any) => res),
        catchError(error => throwError(error || 'Server Error')));
    } else {
      return this.http.post(`${this.settings.getBaseUrl()}/documents/new/${this.currentUserAccessKeyId}`, postData).pipe(
        map((res: any) => res),
        catchError(error => throwError(error || 'Server Error')));
    }
  }

  createNewDocument(document: Document, internal = false) {
    delete document.File;
    if (internal) {
      return this.http.post(`${this.settings.getBaseUrl()}/internaldocuments?include=shares`, document).pipe(
        map((res: any) => res),
        catchError(error => throwError(error || 'Server Error')));
    } else {
      return this.http.post(`${this.settings.getBaseUrl()}/documents?include=shares`, document).pipe(
        map((res: any) => res),
        catchError(error => throwError(error || 'Server Error')));
    }
  }

  updateDocument(document: Document) {
    delete document.File;
    return this.http.put(`${this.settings.getBaseUrl()}/documents/${document.Id}`, document).pipe(
      map((res: any) => res),
      catchError(error => throwError(error || 'Server Error')));
  }

  deleteDocumentById(id, internal = false) {
    if (internal) {
      return this.http.delete(`${this.settings.getBaseUrl()}/internaldocuments/${id}`).pipe(
        map((res: any) => res),
        catchError(error => throwError(error || 'Server Error')));
    } else {
      return this.http.delete(`${this.settings.getBaseUrl()}/documents/${id}`).pipe(
        map((res: any) => res),
        catchError(error => throwError(error || 'Server Error')));
    }
  }

  uploadAdminDocument(file: File) {
      return new Observable((obs) => {
        const self = this;
        if (file && file.name) {
          const s3 = new AWS.S3({
            endpoint: this.s3Ep,
            accessKeyId: environment.wasabiAccessKey,
            secretAccessKey: environment.wasabiSecretKey,
          });
          const params = {
            Bucket: environment.wasabiBucketName,
            Key: this.generateUniqueFileName(file.name), // file.name,
            Body: file,
            ACL: 'public-read',
          };
          s3.upload(params, function (s3Err, data) {
            if (s3Err) {
              obs.error({ data: s3Err, success: false });
            } else {
              obs.next({ data: data, success: true });
            }
          });
        }
      });
  }

  getObjectByVersion(version: ObjectVersion, key = ''): Observable<string> {
    return this.http.post<{object: string}>(`${this.settings.getBaseUrl()}/document/version/${version.VersionId}`, {name: version.Key || key})
      .pipe(
        map((res: any) => res.object),
        catchError(error => throwError(error || 'Server Error')),
      );
  }
}
