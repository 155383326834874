import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/address';
import { Phone } from '../models/phones';

@Pipe({
  name: 'defaultAddress',
})
export class DefaultAddressPipe implements PipeTransform {

  transform(id: string, addresses: Address[], addressType: 'default' | 'W' | 'H' = 'default'): Address {
    return addresses.reduce((previous, current) =>
      (addressType === 'default' ?
        current.IsDefault && current.SourceId === id :
        current.AddressType === addressType && current.SourceId === id)
          ? current : previous,
      new Address());
  }
}
