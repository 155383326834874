import { Action, createReducer, on } from '@ngrx/store';
import { addDefendant } from '../actions/defendants.actions';
import { Defendant } from '../../models/defendant';

export const defendantsFeatureKey = 'defendants';

export interface DefendantState {
  defendant: Defendant;
  section: string;
}

export const initialState: DefendantState = {
  defendant: new Defendant(),
  section: 'personal',
};

const defendantReducer = createReducer(
  initialState,
  on(addDefendant, (state, {defendant, section}) => ({section, defendant: {...defendant}})),
);

export function reducer(state: DefendantState | undefined, action: Action) {
  return defendantReducer(state, action);
}
