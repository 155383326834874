import { createAction, props } from '@ngrx/store';

export enum QuickLinksActionTypes {
  SelectItem = '[QuickLinks] Select Item',
}

export interface QuickLinkStateItem {
  viewName: string;
  dateRangeFilter: string;
  startDateFilter: string;
  endDateFilter: any;
  agencyFilter: any;
  agentFilter: any;
  suretyFilter: any;
}

interface QuickLinksProps {
  items: QuickLinkStateItem[];
}

export const selectQuickLinks = createAction(
  QuickLinksActionTypes.SelectItem,
  props<QuickLinksProps>(),
  );
