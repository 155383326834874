import {BaseModel} from './BaseModel';

export class Checkin extends BaseModel {

  Id: any;
  ScheduleId: any;
  DefendantId: any;
  Date: any;
  ScheduledDate: any;
  Comment: any;
  Missed: boolean;
  CheckedIn: boolean;
  MarkedMissedStamp: any;
  CustomerId: number;
  LastAdiCallDate: any;
  LastAdiCallResult: string;

  constructor(obj?: object) {
    super(obj);

    if (obj == null) {

      this.ScheduleId = -1;
      this.Date = new Date();

    }
  }
  public loadData(data) {
    this.fillFromObject(data, true);
  }

  }
