import { take } from 'rxjs/operators';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LookupService } from '../../../services/app-lookup.service';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { CacheService } from '../../../services/cache.service';
import { AppConstants } from '../../../shared/AppConstants';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-usstate-lu',
  templateUrl: './usstate.component.html',
  styleUrls: ['./usstate.component.css'],
})
export class UsstateLookupComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  @Input() stateCode: string;
  @Output() stateCodeChange = new EventEmitter<string>();
  @Output() selectionChanged = new EventEmitter<any>();
  @Input() viewIndex: any;
  @Input() setDefault: boolean;
  @Input() fieldsDisabled = false;
  @Input() selectedFromWizard = false;
  @Input() placeholderVisible = true;
  @ViewChild('ussatelu', { static: true }) stateLookup: DxSelectBoxComponent;
  private _PlaceHolderText = '';

  states: any;
  isLoading = false;
  noEvents = false;

  @Input()
  set PlaceHolderText(PlaceHolderText: string) {
    this._PlaceHolderText = PlaceHolderText;
  };

  get PlaceHolderText(): string {
    return this._PlaceHolderText;
  };


  constructor(private lookupService: LookupService, private cache: CacheService) { super();}

  loadStates() {
    this.isLoading = true;
    // em - i am caching states. states are rather static and this lookup
    // hits the api a gazillion times trigger the rate limiting on apatio (getting error too many requests)
    let tmpStates = localStorage.getItem('statesLookup');
    if (!tmpStates) {
      this.subs.sink = this.lookupService.getLookup('states').pipe(take(1)).subscribe(st => {
          this.states = st.data.sort((a, b) => {
            if (a.Text > b.Text) {
              return 1;
            } else if (a.Text < b.Text) {
              return -1;
            }
            return 0;
          });
          localStorage.setItem('statesLookup', JSON.stringify(this.states));
          this.isLoading = false;
        }
        ,
        error => {
        }
      );
    } else {
      this.states = JSON.parse(tmpStates);
      this.isLoading = false;
    }
}

  ngOnInit() {
    this.loadStates();
  }

  ngAfterViewInit() {
    if (!this.viewIndex) {
      this.viewIndex = 0;
    }

    if (this.selectedFromWizard && !this.stateCode) {
      this.stateCode = localStorage.getItem(AppConstants.WIZARD_DEFAULT_STATE) || '';
    }

    if ((!this.selectedFromWizard && !!this.setDefault) || (this.selectedFromWizard && !this.stateCode)) {
      // console.log('Set default state: in usstate component!');

      if (!!this.cache.get('default:StateCode')) {
        this.stateCode = this.cache.get('default:StateCode').toString();
      }
      if (!!this.cache.get('default:AgencyId')) {
        this.lookupService.getSingleLookUp('agencies', this.cache.get('default:AgencyId').toString()).then(resp => {
          // console.log('Single agency response: ', resp);
          if (!!resp.data && resp.data.PhysicalStateCode) {
            this.stateCode = resp.data.PhysicalStateCode;
          } else {
            // console.log('No state found for agency, not setting default state!');
          }
        }).catch(error => {
          console.error(error);
        });
      } else {
        // console.log('No agency ID set, not setting default state!');
      }
    }
  }

  onValueChanged(e) {
    if (!e.value || !!e.value && e.previousValue !== e.value) {
      this.stateCode = e.value;
      if (this.selectedFromWizard) {
        localStorage.setItem(AppConstants.WIZARD_DEFAULT_STATE, this.stateCode);
        localStorage.setItem(AppConstants.WIZARD_DEFAULT_COUNTY, '');
      }
      if (!this.noEvents) {
        this.stateCodeChange.emit(e.value);
        this.selectionChanged.emit({id: e.value, index: this.viewIndex});
      }
    }
  }

  focusIn(event) {
    setTimeout(function() {
      if (
        !(event.component.option('opened'))) {
        event.component.open();
      }
    }, 200);
  }
}
