import { BaseModel } from './BaseModel';
import { Share } from './share';

export interface PdfField {
  fieldName: string;
  fieldValue: any;
}

export class PdfSignatureImageBindRequest extends BaseModel {

  public pdfBase64: string;
  public signatureImageBase64: string;
  public share: Share;

  constructor(obj?: object) {
    super(obj);

    if (obj == null) {
    }

  }

}
