import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { IDynamicPopupComponent } from '../../components/common/dynamic-popup/dynamic-popup.component';
import { Subject } from 'rxjs';
import { Contact } from '../../models/contact';
import { Email } from '../../models/email';
import { Phone } from '../../models/phones';
import { Address } from '../../models/address';
import { AppConstants } from '../../shared/AppConstants';
import { ContactService } from '../../services/contact.service';
import { DxValidationGroupComponent, DxFormComponent } from 'devextreme-angular';
import AppEditorLengths from '../../shared/AppEditorLengths';
import { NotifyService } from '../../common/notify/notify.service';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-defendant-payer-form',
  templateUrl: './defendant-payer-form.component.html',
  styleUrls: ['./defendant-payer-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefendantPayerFormComponent extends UnsubscribeOnDestroyAdapter implements OnInit, IDynamicPopupComponent {

  @ViewChild('valGroup') valGroup: DxValidationGroupComponent;
  @ViewChild('payerForm') payerForm: DxFormComponent;

  dynamicData: any;
  onClose = new Subject<any>();
  inDynamicPopup = false;

  saveButtonsDisabled = false;
  contact: Contact = new Contact();
  contentReady = false;

  phoneMask = AppConstants.PHONE_MASK;
  phonePattern: any = AppConstants.PHONE_PATTERN;
  phoneRules: any = AppConstants.PHONE_RULES;

  errorTitle = 'There were errors';
  errorData: any;
  errorVisible = false;
  firstNameLength = AppEditorLengths.PersonFirstName;
  lastNameLength = AppEditorLengths.PersonLastName;
  middleNameLength = AppEditorLengths.PersonMiddleName;

  constructor(
    private contactService: ContactService,
    private notifyService: NotifyService,
    private eventService: EventService,
  ) {
    super();
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    this.contact = new Contact();
    this.contact.Type = 'Reference';
    this.contact['TransactionId'] = this.dynamicData['transactionId'];
    this.contact['DefendantId'] = this.dynamicData['sourceId'];
    this.contact.person.isAdded = true;
    this.contact.person.emails.push(new Email());
    this.contact.person.emails[0].isAdded = true;
    this.contact.person.emails[0].IsDefault = true;
    this.contact.person.phones.push(new Phone());
    this.contact.person.phones[0].isAdded = true;
    this.contact.person.phones[0].IsDefault = true;
    this.contentReady = true;
  }

  onSave(e) {
    if (this.payerForm.instance.validate().isValid) {
      this.saveButtonsDisabled = true;
      this.subs.sink = this.contactService.createPayerContact(this.contact).subscribe(payer => {
        this.saveButtonsDisabled = false;
        this.popToast('Saved');
        this.eventService.onPayerLoaded(payer);
        this.onClose.next(`defendant-payer:${payer.PersonId}`);
      }, err => {
        this.errorData = err;
        this.saveButtonsDisabled = false;
      });
    }
  }

  onCancel(e) {
    this.onClose.next(false);
  }

  popToast(message) {
    this.notifyService.toast(message, 'success');
  }

}
