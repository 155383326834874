import { createFeatureSelector, createSelector } from '@ngrx/store';
import { messageFeatureKey, MessageState } from '../reducers/message.reducer';

export const selectMessagesFeature = createFeatureSelector<MessageState>(messageFeatureKey);
export const selectFeature = (state: MessageState) => state.messages;

export const selectMessages = createSelector(
  selectMessagesFeature,
  selectFeature,
);
