<dx-popup
  [class]="popupClass"
  [width]="width"
  [height]="height"
  [showTitle]="showTitle"
  [title]="title"
  [dragEnabled]="dragEnabled"
  [closeOnOutsideClick]="closeOnOutsideClick"
  [(visible)]="visible"
  [shading]="shading"
  [minWidth]="200"
  [minHeight]="200"
  [resizeEnabled]="resizeEnabled"
>
  <div *dxTemplate="let data of 'title'">
    <h2 style="margin-bottom: 20px;"><span *ngIf="validationMessages && validationMessages.length > 0">There Were Validation Errors</span>
      <span *ngIf="errorMessages && errorMessages.length > 0">There's An Issue</span>
      <span *ngIf="authorizationMessages && authorizationMessages.length > 0">Need Permission</span>
      <div style="float:right;margin-right: 5px;">
        <dx-button icon="fal fa-times" (onClick)="closeClick($event)"></dx-button>
      </div>
    </h2>
  </div>
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="authorizationMessages && authorizationMessages.length > 0">
      <div>

          <div *ngFor="let authorization of authorizationMessages" fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="8px">
            <i class="fal fa-unlock-alt red-icon"></i>
            <div mat-line>{{authorization.detail}}</div>
          </div>
          <br />
          <div>Contact your administrator to give you the necessary access.</div>
      </div>
    </div>
    <div *ngIf="validationMessages && validationMessages.length > 0">
      <div>
        <div *ngFor="let validation of validationMessages" fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="8px">
          <i class="fal fal fa-check orange-icon"></i>
          <div mat-line>{{validation.detail}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="errorMessages && errorMessages.length > 0">
      <div>
        <div *ngFor="let err of errorMessages" fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="8px">
          <i class="fal fa-exclamation red-icon"></i>
          <div mat-line>{{err.detail}}</div>
        </div>
        <br />
        <div *ngIf="helpLink"><a [href]="helpLink" target="_blank" rel="noopener noreferrer">More Information</a></div>
      </div>
    </div>
  </div>
</dx-popup>
