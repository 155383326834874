import {Pipe, PipeTransform} from '@angular/core';
import {ObjectUtilities} from './ObjectUtilities';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;


@Pipe({
  name: 'formatTimeAmPm'
})
export class TimePipe implements PipeTransform {
  transform(time: any): any {
    if(isNullUndefinedOrEmpty(time))
    {
      return '';
    }
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'pm' : 'am';
    min = (min + '').length === 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length === 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }
}
