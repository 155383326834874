import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';

import {Observable} from 'rxjs';
export interface CanLeave {
  canDeactivate: () => Observable<any> | Promise<any> | Boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuardService implements CanDeactivate<any> {
  canDeactivate(component: CanLeave) : any {
    if (component.canDeactivate) {
      return component.canDeactivate();
    }
    return true;
  }
}
