import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers';
import { QuickLinksState, quickLinksFeatureKey } from '../reducers/quick-links.reducer';
import { QuickLinkStateItem } from '../actions/quick-links.actions';

export const selectQuickLinksFeature = createFeatureSelector<QuickLinksState>(quickLinksFeatureKey);

export interface QuickLinks {
  items: QuickLinkStateItem[];
}

export const selectQuickLinksItems = createSelector(
  selectQuickLinksFeature,
  (state: QuickLinksState) => state,
);
