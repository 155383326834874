import { AfterViewInit, Component, ElementRef, Inject, Type, ViewChild } from '@angular/core';
import { Defendant } from '../../../models/defendant';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Bail } from '../../../models/bail';
import { LookupService } from '../../../services/app-lookup.service';
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as htmlToPdfmake from 'html-to-pdfmake';
import { PdfViewerComponent } from '../../../components/common/pdf-viewer/pdf-viewer.component';
import { IDynamicPopupComponent } from '../../../components/common/dynamic-popup/dynamic-popup.component';
import { Observable } from 'rxjs';
import {UnsubscribeOnDestroyAdapter} from '../../../common/UnsubscribeOnDestroy';
import { CourtLookupComponent } from '../../common/court/court.component';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

export interface RapSheetData {
  defendant: Defendant;
}

@Component({
  selector: 'app-defendant-rap-sheet',
  templateUrl: './defendant-rap-sheet.component.html',
  styleUrls: ['./defendant-rap-sheet.component.scss'],
})
export class DefendantRapSheetComponent extends UnsubscribeOnDestroyAdapter implements AfterViewInit {
  @ViewChild('rapSheetContainer', { static: false }) elRef: ElementRef;
  currentDate: Date;
  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData: any = {};
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  rapSheetLoaded = false;
  base64Data: string;

  defendantHasProfilePic: boolean;

  profilePictureURL: string;
  defendantOtherPhone: string;
  defendantEmployerName: string;

  noEmp: any;
  noCollateralAddress: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RapSheetData,
    public lookupService: LookupService,
    public dialogRef: MatDialogRef<DefendantRapSheetComponent>,
  ) {
    super();
    this.currentDate = new Date();
    this.setDefendantDefaultPhone(data.defendant);
    this.setAgencyName(data.defendant.transactions);
    this.setAdvertisingSource(data.defendant.transactions);
    this.setJailName(data.defendant.transactions);
    this.setDefendantEmployer(data.defendant);
    this.setPremiumRate(data.defendant.transactions);
    this.setAttorneyName(data.defendant.transactions);
    this.setDispositionName(data.defendant.transactions);
    this.setCourtName(data.defendant.transactions);
    this.setCourtSetFor(data.defendant.transactions);
    this.setRewrittenPowerNumber(data.defendant.transactions);
  }

  ngAfterViewInit(): void {
    this.subs.sink = this.fetchImageURL(this.data.defendant.person.ProfilePicture).subscribe(() => {
      const pdfRapSheet = this.elRef.nativeElement;
      const html = htmlToPdfmake(pdfRapSheet.innerHTML, {
        imagesByReference: this.defendantHasProfilePic,
        tableAutoSize: true,
      });
      let documentDefinition = {};
      if (this.defendantHasProfilePic) {
        documentDefinition = {
          content: html.content,
          images: html.images,
        };
      } else {
        documentDefinition = {
          content: html,
        };
      }
      const pdf = pdfMake.createPdf(documentDefinition);
      pdf.getBase64(data => {
        this.base64Data = data;
        this.dynamicPopupData.esignable = false;
        this.dynamicPopupClass = PdfViewerComponent;
        this.dynamicPopupTitle = 'Print Rap Sheet';
        this.dynamicPopupData = [];
        this.dynamicPopupData.esignable = false;
        this.dynamicPopupData.isSmartFormPDF = true;
        this.dynamicPopupData.pdfObservable = true;
        this.dynamicPopupData.PDFBase64String = this.base64Data;
        this.dynamicPopupData.objectName = 'Defendant Rap sheet';
        this.dynamicPopupVisible = true;
        this.dynamicPopupShowTitle = true;
        this.rapSheetLoaded = true;
      });
    });
  }

  fetchImageURL(url: string): Observable<boolean> {
    return new Observable<boolean>(obs => {
      if (!url || !url.startsWith('https://s3.wasabisys.com')) {
        this.defendantHasProfilePic = false;
        obs.next(true);
      } else {
        this.defendantHasProfilePic = true;
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url + '?q=123';
        img.onload = (event: any) => {
          const i = event.target;
          const MAX_WIDTH = 400;
          const MAX_HEIGHT = 300;
          let width = i.width;
          let height = i.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(i, 0, 0, width, height);
          this.profilePictureURL = canvas.toDataURL('image/png');
          setTimeout(() => {
            obs.next(true);
          }, 500);
        };
      }
    });
  }

  dynamicPopupHidden() {
    this.dialogRef.close();
  }

  setRapSheetProperty(transactions: Bail[], lookupType: string, prop: string) {
    transactions.forEach(t => {
      this.lookupService.getLookupPromise(lookupType).then(resp => {
        switch (lookupType) {
          // don't need to iterate through bonds
          case 'agencies':
          case 'advertisingsources':
          case 'jails': {

            break;
          }
          // need to iterate through bonds sub object
          case 'filedrates':
          case 'attorneys':
          case 'dispositions': {
            break;
          }
          // courts and courtdatereasons
          case 'courts':
          case 'courtdatereasons': {
            break;
          }
        }
      });
    });
  }

  setAgencyName(transactions: Bail[]) {
    transactions.forEach(t => {
      this.lookupService.getLookupPromise('agencies').then(resp => {
        const agency = resp.data.filter(a => a.Id === t.AgencyId);
        if (agency.length === 1) {
          t.AgencyName = agency[0].Text;
        } else {
          t.AgencyName = '';
        }
      });
    });
  }

  setAdvertisingSource(transactions: Bail[]) {
    transactions.forEach(t => {
      this.lookupService.getLookupPromise('advertisingsources').then(resp => {
        const advertisingSource = resp.data.filter(s => s.Id === t.SourceId);
        if (advertisingSource.length === 1) {
          t.SourceName = advertisingSource[0].Text;
        } else {
          t.SourceName = '';
        }
      });
    });
  }

  setJailName(transactions: Bail[]) {
    transactions.forEach(t => {
      this.lookupService.getLookupPromise('jails').then(resp => {
        const jailNames = resp.data.filter(j => j.Id === t.JailId);
        if (jailNames.length === 1) {
          t.JailName = jailNames[0].Text;
        } else {
          t.JailName = '';
        }
      });
    });
  }

  setPremiumRate(transactions: Bail[]) {
    transactions.forEach(t => {
      const bonds = t.bonds;
      bonds.forEach(b => {
        this.lookupService.getLookupPromise('filedrates').then(resp => {
          const rate = resp.data.filter(r => r.Id === b.RateId);
          if (rate.length === 1) {
            b.RateName = rate[0].Text;
          } else {
            b.RateName = '';
          }
        });
      });
    });
  }

  setAttorneyName(transactions: Bail[]) {
    transactions.forEach(t => {
      const bonds = t.bonds;
      bonds.forEach(b => {
        this.lookupService.getLookupPromise('attorneys').then(resp => {
          const attorney = resp.data.filter(a => a.Id === b.AttorneyId);
          if (attorney.length === 1) {
            b.AttorneyName = attorney[0].Text;
          } else {
            b.AttorneyName = '';
          }
        });
      });
    });
  }

  setDispositionName(transactions: Bail[]) {
    transactions.forEach(t => {
      const bonds = t.bonds;
      bonds.forEach(b => {
        this.lookupService.getLookupPromise('dispositions').then(resp => {
          const disposition = resp.data.filter(d => d.Id === b.DispositionId);
          if (disposition.length === 1) {
            b.DispositionName = disposition[0].Text;
          } else {
            b.DispositionName = '';
          }
        });
      });
    });
  }

  setCourtName(transactions: Bail[]) {
    const today = moment();
    transactions.forEach(t => {
      const bonds = t.bonds;
      bonds.forEach(b => {
        const courtDates = b.courtdates;
        for (let i = 0; i < courtDates.length; i++) {
          if (courtDates[i].BondId === b.Id) {
            if (moment(courtDates[i].Date).isSameOrAfter(today)) {
              b.CourtRoom = courtDates[i].CourtRoom;
              b.SettingDateTime = courtDates[i].Date;
              const courtBondId = courtDates[i].CourtId;
              this.lookupService.getLookupPromise('courts').then(resp => {
                const court = resp.data.filter(c => c.Id === courtBondId);
                if (court.length === 1) {
                  b.CourtName = court[0].Text;
                } else {
                  b.CourtName = '';
                }
              });
              break;
            }
          }
        }
      });
    });
  }

  setCourtSetFor(transactions: Bail[]) {
    transactions.forEach(t => {
      const bonds = t.bonds;
      bonds.forEach(b => {
        const courtDates = b.courtdates;
        courtDates.forEach(cd => {
          if (cd.BondId === b.Id) {
            const reasonId = cd.ReasonId;
            this.lookupService.getLookupPromise('courtdatereasons').then(resp => {
              const setFor = resp.data.filter(c => c.Id === reasonId);
              if (setFor.length === 1) {
                b.SetFor = setFor[0].Text;
              } else {
                b.SetFor = '';
              }
            });
          }
        });
      });
    });
  }

  setRewrittenPowerNumber(transactions: Bail[]) {
    transactions.forEach(t => {
      const bonds = t.bonds;
      bonds.forEach(b => {
        const bondId = b.Id;
        const bondPower = b.PowerNumber;
        if (b.Rewrite) {
          const rewrittenBondId = b.RewrittenBondId;
          for (let i = 0; i < bonds.length; i++) {
            if (bonds[i].Id === rewrittenBondId) {
              b.RewrittenPowerNumber = bonds[i].PowerNumber;
              break;
            }
          }
        }
      });
    });
  }

  setDefendantDefaultPhone(def: Defendant) {
    def.person.phones.forEach(phone => {
      if (!phone.IsDefault && phone.PhoneType !== 'W') {
        this.defendantOtherPhone = phone.Number;
      }
    });
  }

  setDefendantEmployer(def: Defendant) {
    def.person.employers.forEach(emp => {
      if (emp.IsDefault) {
        this.defendantEmployerName = emp.Name;
      }
    });
  }

}
