import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../services/admin.service';
import * as moment from 'moment';
import { GridService } from '../../../services/grid.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from '../../../../environments/environment';
import {UnsubscribeOnDestroyAdapter} from '../../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild('dataGridSub', { static: true }) dataGridSub: DxDataGridComponent;
  @ViewChild('dataGridUsers', { static: true }) dataGridUsers: DxDataGridComponent;

  customerId:any;
  details:any = {
    customer: {},
    subscription: [],
    users: []
  };
  activeDisplay:string = "";
  promoCode:string = "n/a";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private gridService: GridService,
  ) { super(); }

  ngOnInit() {
    this.loadCustomerInfo();
  }

  loadCustomerInfo() {
    // console.log("Loading customer...");
    this.subs.sink = this.route.paramMap.subscribe(params => {
      // console.log("Customer Id: ", params.get('customerId'));
      this.customerId = params.get('customerId');
      if (this.customerId) {
        this.subs.sink = this.adminService
        .getCustomerDetails(this.customerId)
        .subscribe((response) => {
          this.details = response;
          this.prepareCustomerData();
          // console.log("Customer info: ", this.details);
        });
      }
    });
  }

  backToCustomers(){
    this.router.navigateByUrl("/admin/customers");
  }

  prepareCustomerData(){
    let auxViewSub = [];
    let subLabels:any = [];

    subLabels = this.adminService.getAllProductLabels();

    // tds stands for this.details.subscription, named as tds to abbreviate
    let tds = this.details.subscription;
    // console.log("TDS before format: ", tds);

    // tdu stands for this.details.users
    let tdu = this.details.users;

    this.promoCode = (tds.price_level_name == "" ? "n/a" : tds.price_level_name);

    this.details.customer.Created_At = this.modifyDate(this.details.customer.Created_At);
    this.details.customer.Updated_At = this.modifyDate(this.details.customer.Updated_At);

    if (this.details.customer.state) {
      this.activeDisplay = this.details.customer.state.charAt(0).toUpperCase() + this.details.customer.state.substring(1);
    }
        // Reformat the subscriptions to be displayed prettier in Datagrid
    for(let sub in tds){
      if(tds[sub] && sub != "price_level_name"){
        auxViewSub.push({ label: subLabels[sub] });
      }
    }

    for(let user in tdu){
      tdu[user]["FullName"] = `${tdu[user].identity.FirstName} ${tdu[user].identity.LastName}`;
    }

    this.details.users = tdu;
    this.details.subscription = auxViewSub;
  }

  parseBoolToLang(flag:Boolean){
    return (flag ? "Yes" : "No");
  }

  modifyDate(date: any) {
    date = moment(date).format('YYYY-MM-DD HH:mm:ss');
    return date;
  }

  onToolbarPreparing(e:any, dataGrid:any, file:string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            let fileName = `CustomersDetails`;

            if(file == "Subscribed Products"){
                fileName = `CustomerList_${this.details.customer.Name.replace(/\s/g,'')}`;
            }

            if(file == "Users"){
              fileName = `Customers${file.replace(/\s/g,'')}_${this.details.customer.Name.replace(/\s/g,'')}`;
            }

            this.gridService.exportGrid(dataGrid, fileName);
          }
        }
      }
    );

    toolbarItems.unshift(
      {
        location: 'before',
        template: `<strong>${file}</strong>`
      }
    );
  }

  redirectToChargify(id:any = ""){
    let url = "";

    if(environment.environmentName.toUpperCase() === 'PROD'){
      url = "https://captira.chargify.com/subscriptions/" + id.toString();
    }else{
      url = 'https://captira-test.chargify.com/subscriptions/' + id.toString();
    }

    window.open(url, "_blank");
  }

}
