import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';

class ElementScroll {
  el: HTMLElement;
  scroll: number;
}

class ScrollStoreConfig {
  componentContext: any;
  router: Router;
  route: ActivatedRoute;
  storage: {
    [key: string]: ElementScroll
  };
}

const routeReuseEvent = new Subject<any>();
export const routeReuseEvent$ = routeReuseEvent.asObservable();

export const routeReuseTrigger = (route: string) => {
  routeReuseEvent.next({ route });
}

export class ScrollStoreProvider {
  public config: ScrollStoreConfig = <ScrollStoreConfig>{};

  constructor(options: {
    compContext: any,
    router: Router,
    route: ActivatedRoute,
  }) {
    this.config.componentContext = options.compContext;
    this.config.router = options.router;
    this.config.route = options.route;
    this.config.storage = {};

    // subscribe to NavigationEnd
    if (this.config.router) {
      this.config.router.events.subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd
          && this.config.route && this.config.componentContext
          instanceof (this.config.route.component as Function)) {
          setTimeout(() => {
            this.restoreAll();
          })
        }
      }, error => console.error(error));
    }

  }

  public restoreAll() {
    for (const storage in this.config.storage) {
      if (Object.prototype.hasOwnProperty.call(this.config.storage, storage)) {
        const data = this.config.storage[storage];
        if (data.el) {
          data.el.onscroll = null;
        }
      }
    }
  }

  public getStoredData(key: string) {
    return this.config.storage[key];
  }

  public handleScroll(key: string, el: HTMLElement) {
    const providerRef = this;
    if (el) {
      el.onscroll = (() => {
        providerRef.storeScroll(key, el);
      })
    }
  }

  private storeScroll(key: string, el: HTMLElement) {
    if (el.scrollTop != 0) {
      this.config.storage[key] = <ElementScroll>{
        el: el,
        scroll: el.scrollTop,
      };
    }
  }

}