import { map } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { GridService } from '../../services/grid.service';
import { DatamartService } from '../../services/datamart.service';
import { EventService } from '../../services/event.service';
import * as moment from 'moment';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { SsnAlternative } from '../datamart/datamart.component';
import * as XLSX from 'xlsx';
import { Address } from 'app/models/address';

@Component({
  selector: 'app-incarceration-history-report',
  templateUrl: './incarceration-history-report.component.html',
  styleUrls: ['./incarceration-history-report.component.css'],
})
export class IncarcerationHistoryReportComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  @Input('FirstName')
  set FirstName(value) {
    this.firstName = value;
    this.clearResult();
  }

  get FirstName() {
    return this.firstName;
  }

  @Input('LastName')
  set LastName(value) {
    this.lastName = value;
    this.clearResult();
  }

  get LastName() {
    return this.lastName;
  }

  @Input('Dob')
  set Dob(value) {
    this.dob = value;
    this.dobForDisplay = this.formatDateWithoutTimeIfMidnight(value);
    this.clearResult();
  }

  get Dob() {
    return this.dob;
  }

  @Input('Ssn')
  set Ssn(value) {
    this.ssn = value;
    this.clearResult();
  }

  get Ssn() {
    return this.ssn;
  }

  @Input('DriverLicense')
  set DriverLicense(value) {
    this.driverlicense = value;
    this.clearResult();
  }

  get DriverLicense() {
    return this.driverlicense;
  }

  @Input('Address')
  set Address(value: Address) {
    this.address = value;
    this.clearResult();
  }

  get Address(): Address {
    return this.address;
  }

  @Input('ZipCode')
  set ZipCode(value) {
    this.zipcode = value;
    this.clearResult();
  }

  get ZipCode() {
    return this.zipcode;
  }

  @Input('HistoricResultId')
  set HistoricResultId(value) {
    this.historicResultId = value;
    this.clearResult();
  }

  get HistoricResultId() {
    return this.historicResultId;
  }

  @Input() noSSNAlternativeIsChecked = false;
  @Input() ssnAlternativeName: SsnAlternative;

  set Working(value: boolean) {
    this.working = value;
    if (this.eventService) {
      this.eventService.componentBusy(value);
    }
  }

  get Working() {
    return this.working;
  }

  firstName;
  lastName;
  dob = '';
  dobForDisplay = '';
  ssn = '';
  driverlicense = '';
  address: Address;
  zipcode = '';
  historyData = [];
  exportFileName = 'Incarceration_History_Report';
  errorMessage = '';
  resultReady = false;
  working = false;
  historicResultId;


  constructor(private gridService: GridService,
              private datamartService: DatamartService,
              private eventService: EventService) {
    super();
  }

  ngOnInit() {
    // if (!this.Working) {
    //   // console.log('initializing incarceration history report from ngOnInit()');
    //   this.initialize();
    // }
  }

  clearResult() {
    this.historyData = [];
    this.errorMessage = '';
    this.resultReady = false;
  }

  setErrorMessage(message: string): void {
    this.errorMessage = message;
    this.resultReady = true;
    this.Working = false;
  }

  initialize() {
    this.clearResult();
    this.Working = true;
    // either an historic id or values for a new search must be available
    if (!this.HistoricResultId) {
      if (!this.firstName || !this.lastName) {
        this.setErrorMessage('Not enough data provided. Must supply first and last name. Please try again.');
        return;
      }
      if (this.noSSNAlternativeIsChecked) {
        switch (this.ssnAlternativeName) {
          case SsnAlternative.ZipCode:
            if (!this.zipcode) {
              this.setErrorMessage('Not enough data provided. Must supply zip code. Please try again.');
              return;
            }
            break;
          case SsnAlternative.Address:
            if (!this.address.AddressLine1) {
              this.setErrorMessage('Not enough data provided. Must supply address. Please try again.');
            }
            break;
          case SsnAlternative.DriverLicense:
            if (!this.driverlicense) {
              this.setErrorMessage('Not enough data provided. Must supply Drivers License. Please try again.');
            }
            break;
        }
      } else {
        if (!this.ssn) {
          this.setErrorMessage('Not enough data provided. Must supply SSN. Please try again.');
          return;
        }
      }
    }
    this.subs.sink = this.getDataFromService().subscribe(result => {
      this.prepareDataSource(result);
      this.Working = false;
    }, error => {
      console.error(error);
      this.errorMessage = 'An unknown error occurred retrieving results.  Please try again.';
      this.resultReady = true;
      this.Working = false;
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {

            const excelData = [[
                'Name',
                `${this.firstName} ${this.lastName}`,
                'DOB',
                this.dobForDisplay,
            ]];

            const gridData = this.dataGrid.instance.getDataSource().items();
            const gridHeaders = Object.keys(gridData[0]);
            excelData.push([]);

            excelData.push(gridHeaders);

            gridData.forEach(row => {
              const formattedRow = Object.values(row);
              // @ts-ignore
              excelData.push(formattedRow);
            });

            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            this.exportFileName = `IncarcerationHistory_${this.firstName}_${this.lastName}`;
            XLSX.writeFile(wb, `${this.exportFileName}.xlsx`);

          },
        },
      },
    );
  }

  getDataFromService() {
    if (this.HistoricResultId) {
      // console.log('this.HistoricResultId', this.HistoricResultId);
      const self = this;
      return this.datamartService.getIncarcerationHistorySearchHistoricResult(this.HistoricResultId).pipe(
        map((result: any) => {
          result = result.data;
          self.firstName = result.First;
          self.lastName = result.Last;
          self.dob = result.Dob;
          self.ssn = result.Ssn;
          self.driverlicense = result.DriverLicense;
          self.address = result.Address;
          self.zipcode = result.ZipCode;
          return JSON.parse(result.ResultJson);
        }));
    } else {
      return this.datamartService.getApprissPersonSearch(this.FirstName, this.LastName, this.Dob, this.Ssn, this.DriverLicense, this.Address, this.ZipCode);
    }
  }

  prepareDataSource(data) {
    this.resultReady = false;
    // lets first make sure it conforms to expectation
    // console.log('Appriss Person Search Data', data);
    this.historyData = [];
    if (data.hasOwnProperty('personMatches')
      && data.personMatches.length > 0
      && data.personMatches[0].hasOwnProperty('documents')
      && data.personMatches[0].documents.length > 0) {
      for (const doc of data.personMatches[0].documents) {
        let charges = '';
        let behavior = '';
        let ncic = '';
        if (doc.hasOwnProperty('charges')) {
          for (const charge of doc.charges) {
            if (charge.hasOwnProperty('code') && charge.hasOwnProperty('mappedDescription')) {
              if (!!charge.mappedDescription || charge.mappedDescription != 'null') {
                if (charges !== '') {
                  charges = charges + ', ';
                }
                if (charge.cleanDescription) {
                  charges = charges + charge.cleanDescription;
                  if (charge.code) {
                    charges += ' (code ' + charge.code + ')';
                  }
                } else {
                  if (charge.code) {
                    charges += ' (code ' + charge.code + ')';
                  }
                }
                if (behavior !== '' && charge.behavior) {
                  behavior = behavior + ', ';
                }
                if (charge.behavior) {
                  behavior += charge.behavior;
                }
                if (ncic !== '' && charge.ncicDescription) {
                  ncic = ncic + ', ';
                }
                if (charge.ncicDescription) {
                  ncic += charge.ncicDescription;
                }
              }
            }
          }
        }
        const newRow = {
          Type: doc.type,
          ArrestDate: doc.arrestDate ? this.formatDateWithoutTimeIfMidnight(doc.arrestDate) : '',
          BookingDate: doc.bookingDate ? this.formatDateWithoutTimeIfMidnight(doc.bookingDate) : '',
          ReleaseDate: doc.releaseDate ? this.formatDateWithoutTimeIfMidnight(doc.releaseDate) : '',
          Charges: charges,
          Facility: '',
          City: '',
          State: '',
          Phone: '',
          Behavior: behavior,
          NCIC: ncic,
        };
        if (doc.hasOwnProperty('source')) {
          newRow.Facility = doc.source.description;
          if (doc.source.hasOwnProperty('address')) {
            newRow.City = doc.source.address.city;
            newRow.State = doc.source.address.stateOrProvince
          }
          newRow.Phone = doc.source.phoneNumber;
        }
        this.historyData.push(newRow);
      }

    } else {
      this.errorMessage = 'No incarceration history found for this individual.';
    }
    this.resultReady = true;
  }

  formatDateWithoutTimeIfMidnight(t): string {

    if (!t) {
      return '(not provided)';
    }
    // console.log('time to format without time if midnight', t);
    let val = '';
    const dt = moment(t);
    if (dt.hours() === 0 && dt.minutes() === 0 && dt.seconds() === 0) {
      val = dt.format('M/DD/YYYY');
    } else {
      val = dt.format('M/DD/YYYY h:mm A');
    }
    // console.log('after formatting', val);
    return val;
  }
}
