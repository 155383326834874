<dx-toolbar class="{{ navigationAlign? navigationAlign : ''}}">
  <dxi-item>
    <dx-button
    [disabled]="prevDisabled"
      type="default"
      (onClick)="previousItem()"
      icon="fal fa-chevron-left">
    </dx-button>
  </dxi-item>
  <dxi-item>
    <dx-button
      type="default"
      [disabled]="fieldsDisabled"
      (onClick)="addCourtDate()"
      icon="fal fa-plus">
    </dx-button>
  </dxi-item>
  <dxi-item>
    <dx-button
    [disabled]="nextDisabled"
      type="default"
      (onClick)="nextItem()"
      icon="fal fa-chevron-right">
    </dx-button>
  </dxi-item>
  <dxi-item>
    <dx-button
      hint='Preview Court Date Reminder'
      [disabled]='activeCourtCount === 0'
      type='default'
      (onClick)='print(courtView.selectedIndex)'
      icon='fal fa-print'
    ></dx-button>
  </dxi-item>
  <dxi-item
    [visible]="activeCourtCount > 0"
    class="trash-item">
    <dx-button
      type="danger"
      [disabled]="fieldsDisabled"
      (onClick)="removeItem()"
      icon="fal fa-trash">
    </dx-button>
  </dxi-item>
</dx-toolbar>
<div *ngIf="activeCourtCount > 0" class="pagination-current-pg" fxLayout="row" fxLayoutAlign="center center">
  <p class="text-muted">Record {{ displayIndex }} of {{ activeCourtCount }}</p>
</div>
<app-reminder-prompt promptSource="CourtDate"></app-reminder-prompt>

<dx-multi-view
  #courtView
  noDataText='No court date info. Click + above to add new'
  [dataSource]='courtDates'
  [selectedIndex]='0'
  [loop]='false'
  class='no-border'
  [animationEnabled]='true'
  (onContentReady)='onContentReady()'
  [hidden]='count === 0'
>
  <div *dxTemplate="let courtDate of 'item'">
    <div class='multiview-item' style='margin-top:12px;'>
      <p></p>
      <form class='form-horizontal'>
        <div class='form-group'>
          <label class='col-sm-4 control-label'>Court</label>
          <div class='col-sm-6'>
            <app-lookup
              [lookupSource]="'Courts'"
              [lookupType]="'courts'"
              [filter]='county'
              [filterKey]="'County'"
              lookupTypeUserFriendly='Court'
              [(selectedValue)]='courtDate.CourtId'
              [fieldsDisabled]='fieldsDisabled'
              (selectedValueChange)='onCourtDateChange($event)'
              [placeholderVisible]='placeholderVisible'
            ></app-lookup>
          </div>
        </div>
        <div class='form-group'>
          <label class='col-sm-4 control-label'>Court Room</label>
          <div class='col-sm-6'>
            <dx-text-box
              [readOnly]='fieldsDisabled'
              [(value)]='courtDate.CourtRoom'
              [placeholder]="placeholderVisible ? 'Court Room' : ''"
              [id]='setCourtRoomId'
              (onValueChanged)='onCourtDateChange($event)'
              [maxLength]='courtRoomLength'
            >
              <dx-validator>
                <dxi-validation-rule type='stringLength' [max]='courtRoomLength'
                                     message='Maximum length is {{courtRoomLength}}'></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <div class='form-group'>
          <label class='col-sm-4 control-label'>Date</label>
          <div class='col-sm-6'>
            <dx-date-box
              [pickerType]='pickerType'
              [showAnalogClock]='false'
              [(value)]='courtDate.Date'
              [showClearButton]='true'
              type='datetime'
              dateSerializationFormat='yyyy-MM-ddTHH:mm:ss'
              width='100%'
              [displayFormat]='dateTimeFormatter'
              [readOnly]='fieldsDisabled'
              (onValueChanged)='onCourtDateChange($event)'
            >
            </dx-date-box>
          </div>
        </div>

        <div class='form-group'>
          <label class='col-sm-4 control-label'>Set For</label>
          <div class='col-sm-6'>
            <app-lookup
              [lookupSource]="'CourtDateReasons'"
              [lookupType]="'courtdatereasons'"
              lookupTypeUserFriendly='Set For'
              [(selectedValue)]='courtDate.ReasonId'
              [fieldsDisabled]='fieldsDisabled'
              (selectedValueChange)='onCourtDateChange($event)'
              [placeholderVisible]='placeholderVisible'
            ></app-lookup>
          </div>
        </div>

        <div class='form-group'>
          <label class='col-sm-4 control-label'>Client Notified</label>
          <div class='col-sm-1 checkbox-form-col-div'>
            <dx-check-box
              [readOnly]='fieldsDisabled'
              [(value)]='courtDate.ClientNotified'
              (onValueChanged)='clientNotifiedChanged($event, courtView.selectedIndex)'
            ></dx-check-box>
          </div>
        </div>
        <div class='form-group' [hidden]='!courtDate.ClientNotified'>
          <label class='col-sm-4 control-label'>Notified On</label>
          <div class='col-sm-6'>
            <dx-date-box
              [(value)]='courtDate.NotifyDate'
              type='date'
              dateSerializationFormat='yyyy-MM-dd'
              [displayFormat]='dateFormatter'
              [placeholder]="placeholderVisible ? 'Notified On' : ''"
              [readOnly]='fieldsDisabled'
              (onValueChanged)='onCourtDateChange($event)'
            ></dx-date-box>
          </div>
        </div>
        <div class='form-group' [hidden]='!courtDate.ClientNotified'>
          <label class='col-sm-4 control-label'>How Notified</label>
          <div class='col-sm-6'>
            <dx-text-box
              [(value)]='courtDate.HowNotified'
              [readOnly]='fieldsDisabled'
              [placeholder]="placeholderVisible ? 'How Notified' : ''"
              (onValueChanged)='onCourtDateChange($event)'
              [maxLength]='howNotifiedLength'
            >
              <dx-validator>
                <dxi-validation-rule type='stringLength' [max]='howNotifiedLength'
                                     message='Maximum length is {{howNotifiedLength}}'></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <div class='form-group' [hidden]='!courtDate.ClientNotified'>
          <label class='col-sm-4 control-label'>Notified By</label>
          <div class='col-sm-6'>
            <app-lookup
              [lookupSource]="'Users'"
              [lookupType]="'users'"
              lookupTypeUserFriendly='Notified By'
              placeholder='Notified By'
              [(selectedValue)]='courtDate.NotifiedByUserId'
              [fieldsDisabled]='fieldsDisabled'
              (selectedValueChange)='onCourtDateChange($event)'
              [allowAddCustomValue]='false'
            ></app-lookup>
          </div>
        </div>
        <div class='form-group'>
          <label class='col-sm-4 control-label'>Comment</label>
          <div class='col-sm-6'>
            <dx-text-area
              [(value)]='courtDate.Comment'
              [placeholder]="placeholderVisible ? 'Comment' : ''"
              [readOnly]='fieldsDisabled'
              (onValueChanged)='onCourtDateChange($event)'
            >
              <dx-validator>
                <dxi-validation-rule type='stringLength' [max]='100'
                                     message='Maximum length is 100'></dxi-validation-rule>
              </dx-validator>
            </dx-text-area>
          </div>
        </div>
      </form>
    </div>
  </div>
</dx-multi-view>

<app-dynamic-popup
  #dynamicPrintPopup
  [popupComponent]='dynamicPopupClass'
  [popupComponentData]='dynamicPopupData'
  [popupComponentTitle]='dynamicPopupTitle'
  [popupComponentVisible]='dynamicPopupVisible'
  [popupShowTitle]='dynamicPopupShowTitle'
  (onPopupHidden)='onPopupHidden()'
></app-dynamic-popup>
