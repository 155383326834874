import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MugshotPipe } from './mugshot.pipe';
import { CameltosentencePipe } from './cameltosentence.pipe';
import { PhonePipe } from './phone.pipe';
import { PhoneMaskPipe } from './phone-mask.pipe';
import { SsnMaskPipe } from './ssn-mask.pipe';
import { TimePipe } from './time.pipe';
import { SafePipe } from './safe.pipe';
import { DateToZuluPipe } from './date-to-zulu.pipe';
import { FullNamePipe } from './full-name.pipe';
import { FullLastnameNamePipe } from './full-lastname-name.pipe';
import { FriendlyDatePipe } from './friendly-date.pipe';
import { DefaultAddressPipe } from './default-address.pipe';
import { DefaultPhonePipe } from './defeault-phone.pipe';
import { CalendarDatePipe } from './calendar-date.pipe';
import { ConsoleLogPipe } from './console-log.pipe';


@NgModule({
  declarations: [
    MugshotPipe,
    CameltosentencePipe,
    PhonePipe,
    PhoneMaskPipe,
    SsnMaskPipe,
    TimePipe,
    SafePipe,
    DateToZuluPipe,
    FullNamePipe,
    FullLastnameNamePipe,
    FriendlyDatePipe,
    DefaultAddressPipe,
    DefaultPhonePipe,
    CalendarDatePipe,
    ConsoleLogPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MugshotPipe,
    CameltosentencePipe,
    PhonePipe,
    PhoneMaskPipe,
    SsnMaskPipe,
    TimePipe,
    SafePipe,
    DateToZuluPipe,
    FullNamePipe,
    FullLastnameNamePipe,
    FriendlyDatePipe,
    DefaultAddressPipe,
    DefaultPhonePipe,
    CalendarDatePipe,
    ConsoleLogPipe,
  ],
  providers: [  // If we want to call the pipe from code we need to add the pipe to the providers array or we get an injection error
    MugshotPipe,
    CameltosentencePipe,
    PhonePipe,
    PhoneMaskPipe,
    SsnMaskPipe,
    TimePipe,
    SafePipe,
    DateToZuluPipe,
    FullNamePipe,
    FullLastnameNamePipe,
    FriendlyDatePipe,
  ],
})
export class PipesModule { }
