<div class="reminder-prompt" *ngIf="showPrompt$ | async">
    <button mat-button color="primary" (click)="openRemindersSettings()">
        <span>Save time by enabling Reminders! Click here.</span>
    </button>
    <div class="dont-show-again-wrapper">
        <a (click)="dontShowRemindersPopUp()">
            Don't Show Again
        </a>
    </div>
</div>
