import {catchError, map} from 'rxjs/operators';
import {throwError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()
export class ProspectService {

  constructor(private http: HttpClient , private settings: SettingsService    )
  {

  }

  public getGrid(): Observable<any>  {
    const postData =  {};
    return this.http.get(`${this.settings.getBaseUrl()}/prospects/grid/all`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);

  }
}
