<div class="form-group">
  <div class="col-xs-12" style="margin: 0 auto;" *ngIf='showUpload'>
    <dx-file-uploader
      accept="pdf,doc,xls,xlsx"
      [multiple]="true"
      [(value)]="tempDoc.File"
      selectButtonText="Select a file"
      labelText="or drop a file here"
      uploadUrl="https://api-dev.captira.com/v1/documents"
      uploadMode="useForm"
      readyToUploadMessage=""
      [showFileList]="false"
      (onValueChanged)="docUploaded($event)"
      (onUploadError)="docUploadError($event)"
      [disabled]="fieldsDisabled"
      >
    </dx-file-uploader>
  </div>

  <div class="col-xs-12">
    <dx-data-grid
      #grdDocument
      class="fileUploadGrid"
      id="grdDocument"
      [dataSource]="documents"
      keyExpr="Id"
      width="100%"
      rowAlternationEnabled="true"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      (onEditingStart)="onEditingStart($event)"
      (onCellPrepared)='onCellPrepared($event)'
      (onRowRemoving)="onRowRemoving($event)"
      [columnAutoWidth]="true"
      noDataText="No Documents added"
      [columnHidingEnabled]="true">

      <dxo-editing
        mode="popup"
        [allowUpdating]="!fieldsDisabled"
        [allowAdding]="false"
        [allowDeleting]="!fieldsDisabled">
      </dxo-editing>
      <!-- <dxi-column caption="Private" [visible]="!columnVisibilityMap.includes('Private')" dataField="Private" dataType="boolean"></dxi-column> -->
      <dxi-column caption="File Name" [visible]="true" dataField="Name" dataType="string" cellTemplate="downloadLink" [hidingPriority]="6">
        <div *dxTemplate="let data of 'downloadLink'">
          <a appDebounceClick *ngIf="data.data.Id" role="button" (click)="downloadDocument($event, data.data, data.data.shares)">
            {{data.value}}
          </a>
          <a appDebounceClick *ngIf="!data.data.Id" role="button" [href]="data.data.File | safe">
            {{data.value}}
          </a>
        </div>
      </dxi-column>
      <dxi-column caption="File Type" [visible]="!columnVisibilityMap.includes('Type')" dataField="Type" dataType="string" [hidingPriority]="4" cellTemplate='mimeTypeTemplate'>
        <div *dxTemplate="let data of 'mimeTypeTemplate'">
          {{data.value | mimeToFileType}}
        </div>
      </dxi-column>
      <dxi-column caption="Size" [visible]="!columnVisibilityMap.includes('SizeText')" dataField="SizeText" dataType="integer" [hidingPriority]="3"></dxi-column>
      <dxi-column caption="Modified Date" [visible]="!columnVisibilityMap.includes('ModifiedDate')" dataField="ModifiedDate" dataType="date" format="shortDate" [hidingPriority]="2"></dxi-column>
      <dxi-column caption="Comment" [visible]="!columnVisibilityMap.includes('Comment')" dataField="Comment" dataType="string" [hidingPriority]="1"></dxi-column>
      <dxi-column caption='eSign Status' [visible]="!columnVisibilityMap.includes('SigningStatus')" dataField='SigningStatus' cellTemplate='statusCellTemplate' [hidingPriority]='5'>
        <div *dxTemplate="let data of 'statusCellTemplate'">
          <app-document-status-badge
            [matTooltip]='getShareTooltip(data.data.shares)'
            style='cursor: pointer;'
            [matMenuTriggerFor]="statusMenu" [matMenuTriggerData]="{shares: data.data.shares}"
            *ngIf='!!data.value'
            [share]='data.data'
            key='SigningStatus'
          ></app-document-status-badge>
        </div>
      </dxi-column>
      <dxi-column [visible]='false' dataField='shares'></dxi-column>
      <!--<dxi-column caption="Actions" [visible]="true" cellTemplate="editBtn"></dxi-column>-->
      <dxi-column caption="Created Date" [visible]='false' dataField='Created_At' dataType='datetime'></dxi-column>
      <dxo-selection
        mode="single"
        allowSelectAll="false"
        [deferred]="false"
      ></dxo-selection>
      <dxi-column type='buttons'>
        <dxi-button appDebounceClick icon='fal fa-history' [onClick]='getHistory' hint='View the document versions'></dxi-button>
        <dxi-button appDebounceClick name="edit" [visible]="showEditButton" icon='fal fa-pen'></dxi-button>
        <dxi-button appDebounceClick name="delete" [visible]="showDeleteButton" icon='fal fa-trash'></dxi-button>
      </dxi-column>

    </dx-data-grid>
  </div>
</div>

<dx-popup
  #popupEdit
  id='popupEdit'
  class='popup'
  [showTitle]='true'
  title='Edit Document Information'
  [dragEnabled]='true'
  [closeOnOutsideClick]='false'
  [(visible)]='editVisible'
  height='auto'
  [resizeEnabled]='true'
  [minHeight]="'75%'"
  [maxHeight]="'100%'"
  [width]="'50%'"
  (onHiding)='cancelDocumentEdit($event)'
>
  <div *dxTemplate="let data of 'content'"  id="dvEdit">
    <dx-scroll-view
      #scrlEdit
      [useNative]="false">
      <div *ngIf="currentRowReady">
        <dx-form #frmEdit id="frmEdit" [formData]="currentRow" [colCount]="1">
          <dxi-item [visible]="true" dataField="Comment" editorType="dxTextArea" [colSpan]="1" [editorOptions]="{ readOnly: fieldsDisabled, maxLength: 256 }"></dxi-item>
        </dx-form>
      </div>
      <div class="btn-container">
        <button
          class="btn-sticky"
          mat-raised-button
          color="primary"
          [disabled]="saveButtonDisabled"
          (click)="saveDocumentEdit($event)">
          SAVE
        </button>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<mat-menu #statusMenu>
    <ng-template matMenuContent let-shares="shares">
      <div
        mat-menu-item
        *ngFor='let share of shares'
      >
        <span fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap='16px'>
          {{share.Name}}
          <span class='spacer'></span>
          <app-document-status-badge
            [share]='share'
          ></app-document-status-badge>
        </span>
      </div>
    </ng-template>
</mat-menu>
