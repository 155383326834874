import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('fader', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class LoaderComponent implements OnInit {
  randomNumber: number;
  randomPhrases: Array<string> = [
    'Preparing database request',
    'Formatting report',
    'Querying cloud servers',
    'Accessing database records',
    'Preparing PDF file',
    'API request processing',
    'Parsing API response',
    'Mapping database fields',
    'Collating pages...',
    'Prepping editable fields',
    'Acroforms generating',
    'Flattening image layers',
    'Verifying document security',
    'Thanks for your patience',
    'Downloading vectors',
    'Calculating page layouts',
  ];

  constructor() {
    setInterval(() => {
      this.randomPhrase();
    }, 5000);
  }

  ngOnInit() { }

  randomPhrase() {
    this.randomNumber = Math.floor(Math.random() * this.randomPhrases.length);
  }
}
