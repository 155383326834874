import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ReportService} from '../../../services/report.service';
import {isNullOrUndefined} from 'util';
import {DxDropDownBoxComponent, DxSelectBoxComponent} from 'devextreme-angular';
import {UnsubscribeOnDestroyAdapter} from '../../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-reportpicker',
  templateUrl: './reportpicker.component.html',
  styleUrls: ['./reportpicker.component.css']
})
export class ReportpickerComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {

  @Input() reportType: any;
  reportTypes = [];
  @Output() onReportSelected = new EventEmitter<any>();
  @Output() onReportsLoaded = new EventEmitter<number>();
  showDropdown = false;
  selectedReportId: any;
  reportTypeObservable: any;

  constructor(private reportService: ReportService) {
    super();
    // console.log('hello from report picker constructor');
  }

  ngOnInit() {

    this.loadReports();

  }

  ngOnDestroy() {
    if (!isNullOrUndefined(this.reportTypeObservable)) {
      this.reportTypeObservable.unsubscribe();
    }
  }

  cacheLastSelection() {
    localStorage.setItem(this.reportType + 'LastSelection', this.selectedReportId);
  }

  getLastSelectionFromCache() {
    const lastSelection = localStorage.getItem(this.reportType + 'LastSelection');
    if (!isNullOrUndefined(lastSelection) && lastSelection !== '') {
      if ((isNullOrUndefined(this.selectedReportId) || this.selectedReportId === '') ||
        (!isNullOrUndefined(this.selectedReportId && this.selectedReportId !== lastSelection))) {
        this.selectedReportId = lastSelection;
        this.reportSelected(null);
      }
    } else {
      if (this.reportTypes.length > 0) {
        let simpleReportId = null;
        this.reportTypes.forEach(report => {
            if(report.ReportName && report.ReportName.includes("Simple")) {
                simpleReportId = report.ReportId;
            }
        })

        if(simpleReportId) {
          // console.log("report picker selecting simple report");
          this.selectedReportId = simpleReportId;
        } else {
          // console.log("report picker selecting report 0");
          this.selectedReportId = this.reportTypes[0].ReportId;
        }

        this.reportSelected(null);
      }
    }
  }

  initializeReportsAfterLoad() {
    // console.log('report picker reporttypes', this.reportTypes);
    if (!isNullOrUndefined(this.reportTypes)) {
      this.showDropdown = (this.reportTypes.length > 1);
      this.getLastSelectionFromCache();
    }

  }

  loadReports() {
    this.subs.sink = this.reportTypeObservable = this.reportService.getReportsByCategory(this.reportType).subscribe(rf => {
      // console.log('returned report formats', rf);
      this.reportTypes.length = 0;
      if (!isNullOrUndefined(rf) && !isNullOrUndefined(rf.data) && rf.data.length > 0) {
        this.reportTypes = this.reportTypes.concat(rf.data);
        // console.log('merged report formats', this.reportTypes);
        this.initializeReportsAfterLoad();
      }
      this.onReportsLoaded.emit(this.reportTypes.length);
    }, error => {
      // todo: what to do with an error here?
      // console.log('report picker load error', error);
    });

  }

  reportSelected(e) {
    if (!isNullOrUndefined(this.selectedReportId)) {
      // console.log("reportpicker emitting selectedReportId", this.selectedReportId);
      this.onReportSelected.emit(this.selectedReportId);
      this.cacheLastSelection();
    }

  }
}
