<dx-validation-group #defendantValidationGroup>
  <mat-card>
    <dx-tab-panel
      #tabPanel
      [dataSource]="tabs"
      [selectedIndex]="selectedTabIndex"
      [animationEnabled]="true"
      [swipeEnabled]="false"
      [showNavButtons]="true">
      <div *dxTemplate="let tab of 'title'">
        <span>{{tab.text}}</span>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabDefendant'">
        <app-defendant-personal
          [model]="defendantModel"
          [fieldsDisabled]="!editingEnabled"
          (profilePicDeleted)="refreshMugshot(true)">
          <div class="tab-body"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.xs="column"
            fxLayoutGap="16px">
            <div *ngIf="messages.length > 0" [class]="messages[0].class" style="max-width: 50%;white-space: revert;">
              {{ messages[0].message }}
            </div>
            <a (click)="tabPanel.instance.option('selectedIndex', 6)" *ngIf="messages.length > 1">
              <span [class]="messages[1].class">{{ messages.length - 1 }} more...</span>
            </a>
          </div>
        </app-defendant-personal>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabBackground'">
        <app-background
          [model]="defendantModel"
          [fieldsDisabled]="!editingEnabled">
        </app-background>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabExtended'">
        <app-extendedinfo
          [userInPlusMode]="subscribedToDefendantManager"
          [model]="defendantModel"
          [fieldsDisabled]="!editingEnabled">
        </app-extendedinfo>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabPriorArrest'">
        <app-arresthistory
          [userInPlusMode]="subscribedToDefendantManager"
          [model]="defendantModel"
          [fieldsDisabled]="!editingEnabled">
        </app-arresthistory>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabDefendantWatch'">
        <app-defendant-watch
          [model]="defendantModel"
          [fieldsDisabled]="!editingEnabled">
        </app-defendant-watch>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabMobileVerfication'">
        <app-defendant-mobile-verification
          [model]="defendantModel">
        </app-defendant-mobile-verification>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabDefendantReminders'">
        <app-sms-history
          [model]="defendantModel">
        </app-sms-history>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabDefendantMessages'">
        <app-messages [messages]="messages"></app-messages>
      </div>

      <div class="tab-body" *dxTemplate="let data of 'tabDefendantMonitoring'">
        <app-defendant-monitoring [defendant]="defendantModel"></app-defendant-monitoring>
      </div>
    </dx-tab-panel>
  </mat-card>

  <mat-card>
    <app-bail
      #TransactionControl
      [defendant]="defendantModel"
      [userInPlusMode]="subscribedToDefendantManager"
      [model]="defendantModel.transactions"
      [lawSuits]="defendantModel.lawsuits"
      [notes]="defendantModel.notes"
      [defendantId]="defendantModel.Id"
      [calendarItems]="defendantModel.calendaritems"
      [accountingDetails]="defendantModel.accountingdetails"
      (needSave)="onSaveDefendantFromChild($event, defendantValidationGroup)"
      (loadAccountingData) = "loadSavedData($event)"
      [fieldsDisabled]="!editingEnabled"
      [anchorToComponent]="anchorToComponent"
      [anchorInformation]="anchorInformation"
      [(bondCount)]='bondCount'
      [collateralForSchedule]="collateralToSelectForSchedule">
    </app-bail>

    <dx-validation-summary></dx-validation-summary>
  </mat-card>

  <app-bottombar enableShadow="true">
    <a mat-stroked-button color="primary" (click)="backToSummary()">
      <i class="icon-spacer fas fa-chevron-left"></i><span>Back <span class='hidden-xs'>to Summary</span></span>
    </a>
    <span style="flex: auto 1 1;"></span>
    <button
      id="savebydatebtn"
      [disabled]="saveButtonDisabled"
      mat-raised-button
      color="primary"
      (click)="saveDefendant(null, defendantValidationGroup, false, true)">
      <span class='save-button-content'>
        Save <span class='hidden-xs'>Defendant</span>
      </span>
      <i class="icon-spacer fas fa-save"></i>
    </button>
  </app-bottombar>
</dx-validation-group>

<app-errorpopup
  [height]="'300'"
  [width]="'400'"
  [resizeEnabled]="true"
  [closeOnOutsideClick]="false"
  [title]="errorTitle"
  [shading]="false"
  [data]="errorData"
  [visible]="errorVisible"
  [routeToGoToAfterDisplay]="''">
</app-errorpopup>

<dx-popup
  class="popup-component"
  [height]="height"
  [maxHeight]="popupMaxHeight"
  [maxWidth]="popupMaxWidth"
  [position]="position"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="popupVisible"
  [title]="popupTitle">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
      #scrollView
      [showScrollbar]="'always'"
      id="scrollview">
      <ng-template #vcRef></ng-template>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-yes-no-confirmation
  (onResult)="onDefendantDeleteResult($event)"
  [confirmationMessage]="'Are you sure you want to delete this defendant?'"
  [confirmationMessageImportant]="confirmationMessageImportant"
  [titleText]="'Delete'"
  [(showConfirmation)]="showDefendantYesNo"
  [closeOnOutsideClick]="false"
  [showCloseButton]="false">
</app-yes-no-confirmation>


<app-dynamic-popup
  [dragEnabled]="dynamicPopupDragEnabled"
  [maxHeight]="dynamicPopupMaxHeight"
  [maxWidth]="dynamicPopupMaxWidth"
  [popupComponent]="dynamicPopupClass"
  [popupComponentData]="dynamicPopupData"
  [popupComponentTitle]="dynamicPopupTitle"
  [showCloseButton]="true"
  [popupComponentVisible]="dynamicPopupVisible"
  [popupShowTitle]="dynamicPopupShowTitle"
  (onPopupHidden)="dynamicPopupHidden($event)">
</app-dynamic-popup>
