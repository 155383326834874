import { Component, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';
import { grow } from '../../../app.animations';

@Component({
  selector: 'app-smooth-height',
  templateUrl: './smooth-height.component.html',
  styleUrls: ['./smooth-height.component.scss'],
  animations: [grow],
})
export class SmoothHeightComponent implements OnChanges {

  @Input() trigger: boolean;

  startHeight: number;

  constructor(private element: ElementRef) { }

  @HostBinding('@grow')
  get grow() {
    return {value: this.trigger, params: {startHeight: this.startHeight}};
  }

  ngOnChanges(): void {
    this.setStartHeight();
  }

  setStartHeight(): void {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

}
