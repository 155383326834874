import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CheckInScheduleService } from '../../services/checkinschedule.service';
import { CheckInSchedule } from '../../models/checkinschedule';
import ArrayStore from 'devextreme/data/array_store';
import { AppConstants } from '../../shared/AppConstants';
import { ButtonbarComponent } from '../../components/common/buttonbar/buttonbar.component';
import { Checkin } from '../../models/checkin';
import { DxDataGridComponent, DxListComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { StringUtilities } from '../../shared/StringUtilities';
import { IDynamicPopupComponent } from '../../components/common/dynamic-popup/dynamic-popup.component';
import { Subject } from 'rxjs';
import { ObjectUtilities } from '../../shared/ObjectUtilities';
import { FieldValues } from '../../models/reportcompilationrequest';
import { PdfViewerComponent } from '../../components/common/pdf-viewer/pdf-viewer.component';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { AdiSettingsService } from '../../services/adisettings.service';
import { RouteDataService } from '../../services/routedata.service';
import { NotifyService } from '../../common/notify/notify.service';
import getCurrentDateString = StringUtilities.getCurrentDateString;
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;
import HEADER_TOP_MARGIN_IN_POPUP = AppConstants.HEADER_TOP_MARGIN_IN_POPUP;
import HEADER_TOP_MARGIN = AppConstants.HEADER_TOP_MARGIN;

@Component({
  selector: 'app-checkinschedule',
  templateUrl: './checkinschedule.component.html',
  styleUrls: ['./checkinschedule.component.scss'],
  entryComponents: [PdfViewerComponent],
})
export class CheckinscheduleComponent extends UnsubscribeOnDestroyAdapter implements IDynamicPopupComponent, AfterViewInit {
  @ViewChild('navButtonBar', { static: true }) buttonBar: ButtonbarComponent;
  @ViewChild('scheduleGrid', { static: true }) scheduleGrid: DxDataGridComponent;
  @ViewChild('valGroup', { static: true }) valGroup: DxValidationGroupComponent;
  @ViewChild('startDateBox') startDateBox: DxValidationGroupComponent;
  @ViewChild(DxListComponent, { static: false }) listBox;

  sourceId: any;
  parentId: any;

  dynamicPopupClass: any;
  dynamicPopupData: any;
  dynamicPopupTitle: any;
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;

  justCreated = false;
  saveText = 'Save';
  saveAndCloseButtonWidth = 136;
  saveAndCloseDivWidth = '150px';

  dynamicData: any;
  onClose = new Subject<boolean>();
  navKey = AppConstants.DEFENDANT_ID_KEY;
  parentNavKey = AppConstants.DEFENDANT_ID_KEY;
  routeKey = 'Defendant';
  gotSourceObjectFromNav = false;
  gotSourceIdFromNav = false;
  computedTopMargin = HEADER_TOP_MARGIN;

  creating = false;
  schedule: CheckInSchedule;
  scheduleDetails: Checkin[];

  checkInSchedulePeriods: any;
  checkInScheduleIntervals: any;

  errorTitle = 'There were errors';
  errorData: any[];
  errorVisible = false;

  loadIndicatorVisible = false;
  saveButtonDisabled = false;
  isGPSCheckinSubscribed = false;

  defendantName = '';

  noDefendantRoute = '/dashboard';

  changingDayTwoEnabled = false;
  checkInDayTwoEnabled = false;

  otherActions = [];
  daysOfWeek: any;
  daysOfWeekNoNumber = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  selectedDaysOfWeek: string[] = [];
  public daysOfMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  showDefendantSearch = false;

  selectedNavRoute = '';

  min = getCurrentDateString();

  inDynamicPopup = false;

  scheduleLoaded = false;

  constructor(
    private scheduleService: CheckInScheduleService,
    private cdr: ChangeDetectorRef,
    private adi: AdiSettingsService,
    private routeDataService: RouteDataService,
    private notifyService: NotifyService,
  ) {
    super();
    this.onSave = this.onSave.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onPrint = this.onPrint.bind(this);
    this.print = this.print.bind(this);
    this.schedule = new CheckInSchedule();
    this.subs.sink = this.adi.get().subscribe(config => {
      this.isGPSCheckinSubscribed = config.data[0].automaticnotificationsettings.data.CheckinGpsEnabled;
    });
  }

  onDefendantSelected(e) {
    // console.log('ondefendantselected', e);
    this.loadForSelectedDefendant(e.DefendantId, e.DefendantName);
  }

  loadForSelectedDefendant(defendantId, defendantName) {
    this.sourceId = defendantId;
    this.parentId = defendantId;
    this.defendantName = defendantName;
    this.showDefendantSearch = false;
    this.loadSchedule();
  }

  setupSelectedCheckInDaysOfWeek() {
    // console.log('setting up selected days of the week');

    if (this.schedule.CheckInIntervalDaysOfWeek) {
      const selectedDays = this.schedule.CheckInIntervalDaysOfWeek.split(',');

      if (selectedDays && selectedDays.length > 0) {
        this.selectedDaysOfWeek = selectedDays.map(this.getNameFromDayOfWeek);
      }
    }
  }

  setupCheckInPeriodsAndInterval() {
    // console.log('setting up periods and interval types');
    this.checkInSchedulePeriods = new ArrayStore({
      key: 'name', data: AppConstants.CHECK_IN_PERIODS,
    });

    this.checkInScheduleIntervals = new ArrayStore({
      key: 'name', data: AppConstants.CHECK_IN_INTERVALS,
    });

    this.daysOfWeek = new ArrayStore({
      key: 'id', data: AppConstants.DAYS_OF_THE_WEEK,
    });
  }

  startDateChanged(val) {
    // console.log('changed date', val);
    if (val.value && val.value !== '') {
      const newDate = new Date(val.value);
      this.schedule.CheckInScheduleDayOne = newDate.getDate();
      this.cdr.detectChanges();
    }
  }

  dayTwoEnabledToggled(e: any) {
    this.checkInDayTwoEnabled = e.value;
  }

  checkInScheduleIntervalTypeValid = (options: any) => {
    // console.log('validating type');
    if (this.schedule.Period === 'Every' &&
      (options.value == null || options.value === '')) {
      options.rule.message = 'Interval is required';
      return false;
    }

    return true;
  }

  getNameFromDayOfWeek(day: any) {
    let returnValue = null;

    switch (day) {
      case '1':
        returnValue = 'Sunday';
        break;
      case '2':
        returnValue = 'Monday';
        break;
      case '3':
        returnValue = 'Tuesday';
        break;
      case '4':
        returnValue = 'Wednesday';
        break;
      case '5':
        returnValue = 'Thursday';
        break;
      case '6':
        returnValue = 'Friday';
        break;
      case '7':
        returnValue = 'Saturday';
        break;
    }

    return returnValue;
  }

  getDayOfWeekFromName(name: any) {

    let returnValue = null;

    switch (name) {
      case 'Sunday':
        returnValue = 1;
        break;
      case 'Monday':
        returnValue = 2;
        break;
      case 'Tuesday':
        returnValue = 3;
        break;
      case 'Wednesday':
        returnValue = 4;
        break;
      case 'Thursday':
        returnValue = 5;
        break;
      case 'Friday':
        returnValue = 6;
        break;
      case 'Saturday':
        returnValue = 7;
        break;

    }
    return returnValue;
  }

  checkInScheduleIntervalCountValid = (options: any) => {
    // console.log('validating count');
    // console.log(options.value);

    if (this.schedule.Period === 'Every' &&
      (options.value == null || options.value <= 0 || options.value > 999)) {
      options.rule.message = 'Count is required';
      return false;
    }

    return true;
  }

  checkInScheduleDayOneValid = (options: any) => {
    // console.log('validating day one');
    // console.log(options);
    // console.log(this.schedule.Period);

    if (this.schedule.Period === 'Monthly' &&
      (options.value == null || options.value < 1 || options.value > 31)) {

      // console.log('bad day one');
      options.rule.message = 'First day of month is required';
      return false;
    }

    // console.log('good day one');
    return true;
  }

  checkInScheduleDayTwoValid = (options: any) => {
    // console.log('validating day two');
    // console.log(this.schedule.Period);

    if (this.schedule.Period === 'Monthly' &&
      this.checkInDayTwoEnabled &&
      (options.value == null || options.value < 1 || options.value > 31)) {
      options.rule.message = 'Second day of month is required';
      return false;
    }

    return true;
  }

  validateMiles = (options: any) => {
    if (!options.value) {
      options.rule.message = 'Enter a number';
      return false;
    }
    if (!Number.isInteger(options.value)) {
      options.rule.message = 'Please enter a whole number';
      return false;
    }
    return true;
  }

  checkInScheduleDaysOfTheWeekValid = (options: any) => {
    // console.log('validating days of the week');
    const period = this.schedule.Period;
    if ((period === 'Weekly' || period === 'Bi-Weekly') && (this.selectedDaysOfWeek == null || this.selectedDaysOfWeek.length === 0)) {
      options.rule.message = 'Days of the week are required.';
      return false;
    }

    return true;
  }

  getSchedule(data) {
    // console.log('getschedule data', data);
    const schedule = data;
    const details = data.details.data;

    delete schedule.details;
    this.schedule = new CheckInSchedule(schedule);
    this.creating = (!this.schedule || isNullUndefinedOrEmpty(this.schedule.Id));
    this.saveText = (this.creating) ? 'Generate Schedule' : 'Save';
    this.saveAndCloseButtonWidth = (this.creating) ? 161 : 136;
    this.saveAndCloseDivWidth = (this.creating) ? '175px' : '150px';

    if (details && details && details.length > 0) {
      const newThis = this;
      newThis.schedule.details = new Array<Checkin>();
      details.forEach(function (psd) {
        newThis.schedule.details.push(new Checkin(psd));
      });
      this.scheduleDetails = this.schedule.details;
      if (!!this.scheduleGrid && !!this.scheduleGrid.dataSource) {
        this.scheduleGrid.dataSource = this.schedule.details;
      }
      // console.log('get schedule details', this.schedule.details);
    }

  }

  onOptionChanged(e) {
    if (e.name === 'validationError' && (new Date(e.component.option('text')).valueOf() === new Date(this.schedule.StartDate).valueOf())) {
      setTimeout(function () {
        // console.log('now removing the invalid class');
        e.element.classList.remove('dx-invalid');
        e.component.option('isValid', true);
      }, 10);
    } else if (e.name === 'isValid' && (new Date(e.component.option('text')).valueOf() === new Date(this.schedule.StartDate).valueOf())) {
      e.component.option('isValid', true);
    }
  }

  loadSchedule() {
    // console.log('in load schedule, sourceId: ', this.sourceId);
    this.scheduleLoaded = false;
    this.subs.sink = this.scheduleService.getByDefendantId(this.sourceId).subscribe(sc => {

      // console.log('defid', sc['data']['DefendantId']);
      /*
            // console.log('sc2', sc);
            // console.log('sc data', sc['data']);
            // console.log('sc data details', sc['data'].details);
            // console.log('sc.data details data', sc['data'].details['data']);
      */

      this.creating = (!sc.data || !sc.data.Id);
      this.cdr.detectChanges();
      // console.log('creating', this.creating, this.schedule);

      if (sc && sc.data && sc.data.details && sc.data.details.data.length > 0 && sc.data.DefendantId !== null) {
        this.getSchedule(sc.data);

        // console.log('start d', new Date(this.schedule.StartDate));
        // console.log('end d', new Date(this.schedule.EndDate));

        /*        if (new Date(this.schedule.StartDate) < this.min) {
                  this.schedule.StartDate = this.min;
                  // console.log('set start date to min', this.min);
                }*/

        const startD = new Date(this.schedule.StartDate);

        if (new Date(this.schedule.EndDate) <= startD) {
          startD.setFullYear(startD.getFullYear() + 2);
          // console.log('setting end date', startD);
          this.schedule.EndDate = startD;
        }

        this.scheduleDetails = this.schedule.details;
        // console.log('loaded check in schedule');
      } else {
        this.creating = true;
      }
      this.saveText = (this.creating) ? 'Generate Schedule' : 'Save';
      this.saveAndCloseButtonWidth = (this.creating) ? 161 : 136;
      this.saveAndCloseDivWidth = (this.creating) ? '175px' : '150px';
      if (!this.justCreated && this.creating) {
        this.justCreated = true;
      }
      this.loadOtherActions();

      // console.log('this.schedule.DefendantId < 0', this.schedule.DefendantId);
      if (this.schedule.DefendantId < 0) {
        this.schedule.DefendantId = this.sourceId;
      }

      this.setupSelectedCheckInDaysOfWeek();
      // console.log('loaded schedule', this.schedule);
      this.scheduleLoaded = true;
    }, error => {
      this.scheduleLoaded = false;
      this.errorData = error;
    });
  }

  popToast(msg) {
    this.notifyService.toast(msg, "success");
  }

  handleError(error) {
    this.saveButtonDisabled = false;
    this.errorData = error;
  }

  preSave() {

    if (this.selectedDaysOfWeek.length > 0) {
      this.schedule.CheckInIntervalDaysOfWeek = this.selectedDaysOfWeek.map(this.getDayOfWeekFromName).join(',');
    }

    this.schedule.checkForChanges();

  }

  postSave(showToast = true, afterSave = null) {
    if (showToast) {
      this.popToast('Saved');
    }
    this.saveButtonDisabled = false;

    // console.log('after save in post save', afterSave);
    if (!!this.dynamicData && afterSave === null && !this.justCreated) {
      this.onClose.next(true);
    } else {
      if (!!afterSave) {
        afterSave();
      } else {
        this.scheduleGrid.instance.refresh();
        this.buttonBar.navigate();
      }
      this.justCreated = false;
    }
  }

  onSave(e, valGroup, afterSave = null) {
    // console.log('in form submit', 'after save', afterSave, valGroup.instance.validate());
    let skipDateValidation = false;
    const rule = valGroup.instance.validate().brokenRules.filter(brkRule => brkRule.message === 'Value is out of range')[0];
    if (!!rule) {
      skipDateValidation = new Date(this.startDateBox.instance.option('text')).valueOf() === new Date(this.schedule.StartDate).valueOf();
    }
    // console.log('onSave: skipDateValidation: ', skipDateValidation, rule);
    if (valGroup.instance.validate().isValid || (valGroup.instance.validate().brokenRules.length === 1 && skipDateValidation)) {
      this.preSave();
      this.saveButtonDisabled = true;

      // console.log('dirty schedule', this.schedule.isDirty);

      if (!this.schedule.isDirty) {

        this.postSave(true, afterSave);
        return;
      }

      const savingToastText = (this.creating) ? 'Generating Schedule...' : 'Saving...';
      this.popToast(savingToastText);
      this.schedule.EndDate = this.schedule.EndDate.replace(/\//g, '-');
      this.schedule.StartDate = this.schedule.StartDate.replace(/\//g, '-');
      if (this.schedule.Id !== undefined && this.schedule.Id !== null) {
        this.subs.sink = this.scheduleService.save(this.schedule.Id, this.schedule).subscribe(sc => {
          // console.log('saved schedule', sc);
          this.getSchedule(sc.data);
          this.postSave(true, afterSave);
        }, error => {
          this.handleError(error);
        });
      } else {
        // console.log('creating', this.schedule);
        this.subs.sink = this.scheduleService.create(this.schedule).subscribe(sc => {
          // console.log('saved schedule', sc);
          this.getSchedule(sc.data);
          this.postSave(true, afterSave);
        }, error => {
          this.handleError(error);
        });
      }

    }

  }

  cancel() {
    if (!!this.dynamicData) {
      this.onClose.next(false);
    } else {
      this.buttonBar.routeForCancel();
    }
  }

  onDelete(e) {
    if (this.schedule.Id !== null) {

      // console.log('deleting payment schedule');
      this.popToast('Deleting');
      this.subs.sink = this.scheduleService.delete(this.schedule.Id).subscribe(sc => {
        this.saveButtonDisabled = true;
        this.popToast('Deleted');
        this.buttonBar.cancel();
      }, error => {
        this.handleError(error);
      });
    } else {
      this.buttonBar.cancel();
    }
  }

  onPopupHidden() {
    // alert('hiding popup from checkin component!');
    this.dynamicPopupClass = null;
  }

  ngAfterViewInit() {
    if (!!this.dynamicData) {
      this.routeKey = '';
      this.computedTopMargin = HEADER_TOP_MARGIN_IN_POPUP;
      if (this.dynamicData.hasOwnProperty(AppConstants.DEFENDANT_ID_KEY) && this.dynamicData[AppConstants.DEFENDANT_ID_KEY]) {
        this.loadForSelectedDefendant(this.dynamicData[AppConstants.DEFENDANT_ID_KEY], this.dynamicData[AppConstants.DEFENDANT_NAME_KEY]);
      }
    } else {
      const defendantId = this.routeDataService.getValue(AppConstants.DEFENDANT_ID_KEY);
      if (defendantId) {
        this.gotSourceIdFromNav = true;
        this.sourceId = defendantId;
        this.parentId = defendantId;
        // console.log('ids', this.parentId, this.sourceId);
        this.loadSchedule();
      } else {
        // console.log('showing defendant search');
        if (!this.dynamicData) {
          this.creating = true;
          this.cdr.detectChanges();
          this.showDefendantSearch = true;
        }
      }
      const defendantName = this.routeDataService.getValue(AppConstants.DEFENDANT_NAME_KEY);
      if (defendantName) {
        this.defendantName = defendantName;
      }
    }
    this.setupCheckInPeriodsAndInterval();
  }

  loadOtherActions() {
    if (this.otherActions.length > 0) {
      this.otherActions.length = 0;
    }

    if (!this.creating) {
      this.otherActions.push({text: 'Delete', icon: 'fal fa-trash-alt fa-2x', type: 'normal', value: this.onDelete});
    }
    this.otherActions.push({text: 'Preview', icon: 'fal fa-print fa-2x', type: 'normal', value: this.onPrint});

  }

  print() {
    // console.log('previewing');
    const fieldValue = new FieldValues();
    fieldValue.field = 'DefendantID';
    fieldValue.hashedValues = [this.sourceId];
    // console.log('field value', fieldValue);

    this.dynamicPopupData = [];
    // console.log('dynamic popup data', this.dynamicPopupData);
    this.dynamicPopupData.reportCategory = 'Check In Reminder';
    this.dynamicPopupData.reportFieldValues = fieldValue;
    this.dynamicPopupData.objectTypeName = 'check in reminder';
    this.dynamicPopupData.sourceType = 'Defendants';
    this.dynamicPopupData.sourceId = this.sourceId;
    this.dynamicPopupData.objectName = 'Check In Reminder ' + this.defendantName;
    // console.log('dynamic popup data', this.dynamicPopupData);
    this.dynamicPopupClass = null;
    this.dynamicPopupData.esignable = false;
    this.dynamicPopupClass = PdfViewerComponent;

  }

  onPrint() {
    this.onSave(null, this.valGroup, this.print);
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.exportGrid();
          },
        },
      },
    );


  }

  exportGrid() {
    // const exportAll = this.scheduleGrid.instance.totalCount() !== this.scheduleGrid.instance.getSelectedRowKeys().length;
    this.scheduleGrid.export.fileName = 'CheckInSchedule';
    this.scheduleGrid.instance.exportToExcel(false);
  }

  // -------------------------------------------------------------------------------------------------- //

  onListSelectionChanged(e) {
    // console.log('onListSelectionChanged:', e.component._selection.options.selectedItemKeys);
    this.updateSelection(this.listBox && this.listBox.instance);
  }

  updateSelection(listBox) {
    // console.log('updateSelection', listBox);
    if (!listBox) { return; }

    if (!this.selectedDaysOfWeek) {
      listBox.unselectAll();
    }

    if (this.selectedDaysOfWeek) {
      this.selectedDaysOfWeek.forEach(((value) => {
        listBox.selectItem(value);
      }));
    }
  }

}


