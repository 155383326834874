import {catchError, map, tap} from 'rxjs/operators';

import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {ApiUtilities} from '../shared/ApiUtilities';

import {DatamartRequest} from '../models/datamartrequest';
import {DebtorDetailForPersonSearch} from './debtorDetailForPersonSearch';
import {PersonSearchPhoneAndEmailResult} from './personSearchPhoneAndEmailResult';
import { Address } from '../models/address';

export enum ReportType {
  General = 1,
  Credit,
  RealEstate,
  Incarceration,
  Social,
  InstantId,
  LicensePlate
}

@Injectable()
export class DatamartService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  public getResponseJson(queueId): Observable<any> {

    // console.log(`requesting ${this.settings.getBaseUrl()}/datamart/responsejson/${queueId}..`);
    return this.http.get(`${this.settings.getBaseUrl()}/datamart/responsejson/${queueId}`, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getResponsePdf(queueId): Observable<any> {
    // console.log(`requesting ${this.settings.getBaseUrl()}/datamart/responsepdf/${queueId}..`);
    return this.http.get(`${this.settings.getBaseUrl()}/datamart/responsepdf/${queueId}`, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  /**
   * Example Response from API
   * {
   *   "BpsSearchResponse": {
   *     "@xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
   *     "@xmlns:xsd": "http://www.w3.org/2001/XMLSchema",
   *     "SubjectTotalCount": "0",
   *     "Records": {
   *       "Record": {
   *         "UniqueId": "G7569982894932508086",
   *         "Age": "37",
   *         "Name": {
   *           "First": "Brandon",
   *           "Middle": "D",
   *           "Last": "Young"
   *         },
   *         "Address": {
   *           "StreetNumber": "11",
   *           "StreetName": "Fremont",
   *           "StreetSuffix": "St",
   *           "UnitDesignation": "APT",
   *           "UnitNumber": "408",
   *           "State": "NY",
   *           "Zip5": "12078",
   *           "Zip4": "3235",
   *           "County": "Fulton"
   *         },
   *         "DOB": {
   *           "Year": "1986",
   *           "Month": "6",
   *           "Day": "23"
   *         },
   *         "SSNInfo": null
   *       }
   *     }
   *   }
   * }
   * @param datamartRequest
   */
  public postGeneralInitialInquiry(datamartRequest: DatamartRequest): Observable<any> {

    // console.log(`posting general initial with `, datamartRequest);
    return this.http.post(`${this.settings.getBaseUrl()}/datamart/general/initial`, datamartRequest, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postGeneralIndividualInquiry(datamartRequest: DatamartRequest): Observable<any> {

    // console.log(`posting general individual with `, datamartRequest);
    return this.http.post(`${this.settings.getBaseUrl()}/datamart/general/individual`, datamartRequest, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postCreditInquiry(datamartRequest: DatamartRequest): Observable<any> {

    // console.log(`posting credit with `, datamartRequest);
    return this.http.post(`${this.settings.getBaseUrl()}/datamart/credit`, datamartRequest, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postSocialInquiry(datamartRequest: DatamartRequest): Observable<any> {

    // console.log(`posting social with `, datamartRequest);
    return this.http.post(`${this.settings.getBaseUrl()}/datamart/social`, datamartRequest, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postRealEstateInquiry(datamartRequest: DatamartRequest): Observable<any> {

    // console.log(`posting real estate with `, datamartRequest);
    return this.http.post(`${this.settings.getBaseUrl()}/datamart/realestate`, datamartRequest, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postLicensePlateInquiry(datamartRequest: DatamartRequest): Observable<any> {

    // console.log(`posting license plate with `, datamartRequest);
    return this.http.post(`${this.settings.getBaseUrl()}/datamart/licenseplate`, datamartRequest, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postInstantIdInquiry(datamartRequest: DatamartRequest): Observable<any> {

    // console.log(`posting instant id with `, datamartRequest);
    return this.http.post(`${this.settings.getBaseUrl()}/datamart/instantid`, datamartRequest, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postIncarcerationInquiry(datamartRequest: DatamartRequest): Observable<any> {
    // console.log(`posting incarceration with `, datamartRequest);
    return this.http.post(`${this.settings.getBaseUrl()}/datamart/incarceration`, datamartRequest, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postReportInquiry(reportType: ReportType, datamartRequest: DatamartRequest): Observable<any> {
    if (reportType === ReportType.Credit) {
      return this.postCreditInquiry(datamartRequest);
    } else if (reportType === ReportType.Incarceration) {
      return this.postIncarcerationInquiry(datamartRequest);
    } else if (reportType === ReportType.InstantId) {
      return this.postInstantIdInquiry(datamartRequest);
    } else if (reportType === ReportType.LicensePlate) {
      return this.postLicensePlateInquiry(datamartRequest);
    } else if (reportType === ReportType.RealEstate) {
      return this.postRealEstateInquiry(datamartRequest);
    } else if (reportType === ReportType.Social) {
      return this.postSocialInquiry(datamartRequest);
    } else {
      return null;
    }
  }

  public getReportTypeList() {
    return [
      {id: ReportType.Incarceration, name: 'Arrest History ($4.00)', QueueRequestType: 'RiskCheckNowPersonSearch'},
      {id: ReportType.General, name: 'Background Check ($4.75)', QueueRequestType: 'GeneralInitialQuery'},
      {id: ReportType.Credit, name: 'Credit & FICO Check ($9.95)', QueueRequestType: 'CreditInitialQuery'},
      // {id: ReportType.RealEstate, name: 'Real Estate Report ($12.00)', QueueRequestType: 'CoreLogicGeoCoreInitialQuery'},
      // {id: ReportType.InstantId, name: 'Instant ID ($1.00)', QueueRequestType: 'LexisNexisInstantId'},
      // {id: ReportType.Social, name: 'Social Network Search'},
      // {id: ReportType.LicensePlate, name: 'License Plate Locator'},
    ];
  }

  public getHistoryByType(searchType: ReportType, billable: boolean = true) {
    const queueRequestType = this.getReportTypeList().find(i => i.id === searchType).QueueRequestType;
    if (queueRequestType === 'RiskCheckNowPersonSearch') {
      // as of 12/22/2018, incarceration history searches are handled differently
      const $url = `${this.settings.getBaseUrl()}/incarcerationhistorysearchasdatamart`;
      return this.http.get($url, {headers: this.headers}).pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
    } else {
      const $url = `${this.settings.getBaseUrl()}/datamartsearchhistory?search=QueueRequestType:${queueRequestType};QueueBillable:${billable ? '1' : '0'}&searchJoin=and`;
      return this.http.get($url, {headers: this.headers}).pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
    }

  }

  public getApprissPersonSearch(first: string, last: string, dob: string, ssn: string, driverlicense: string, address: Address, zipcode: string) {
    const payload = {
      First: first,
      Last: last,
      Dob: dob,
      Ssn: ssn,
      DriverLicense: driverlicense,
      Address: address,
      ZipCode: zipcode,
    };
    return this.http.post(`${this.settings.getBaseUrl()}/incarcerationhistorysearches/new`, payload, {headers: this.headers}).pipe(
      tap(result => {
        // console.log('DatamartService.getApprissPersonSearch payload:', payload);
        // console.log('DatamartService.getApprissPersonSearch result:', result);
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  public getIncarcerationHistorySearchHistoricResult(Id) {
    return this.http.get(`${this.settings.getBaseUrl()}/incarcerationhistorysearches/${Id}`, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getNewPhoneAndEmailFromPersonSearch(data: DebtorDetailForPersonSearch) {
    return this.http.post(`${this.settings.getBaseUrl()}/personsearchesphoneemail`, data, {headers: this.headers}).pipe(
      map((res: PersonSearchPhoneAndEmailResult) => {
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }
}

