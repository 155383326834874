<dx-box direction="row" width="100%">
  <dxi-item ratio="4">
    <dx-text-box
      #txEmailRef
      [placeholder]="placeholderVisible ? 'email@example.com' : ''"
      [(value)]="defaultEmail"
      [isValid]="isValid"
      (onValueChanged)="onValueChanged($event)"
      (onFocusOut)="onFocusOut($event)"
      [readOnly]="readOnlyField"
      [disabled]="inputsDisabled"
      [inputAttr]='{id: randomId}'
    >
      <dx-validator>
        <dxi-validation-rule
          type="email"
          message="Please enter a valid email"
        ></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </dxi-item>
  <dxi-item tabindex="-1" ratio="0" baseSize="2">
    <div style="width:2px;"></div>
  </dxi-item>
  <dxi-item tabindex="-1" ratio="0" baseSize="40" *ngIf='singleEmail === false'>
    <button
      tabindex="-1"
      type="button"
      mat-icon-button
      (click)="addNewEmail()">
      <span *ngIf="emailCount > 0" class="badge badge-primary" tabindex="-1">
        {{ emailCount }}
      </span>
      <i tabindex="-1" *ngIf="emailCount == 0" class="fal fa-window-restore"></i>
    </button>
  </dxi-item>
  <dxi-item [tabIndex]="-1" ratio="0" baseSize="40" [visible]='showPossibleEmail'>
    <a tabIndex="-1" mat-icon-button [@fader]='showPossibleEmail' [href]="possibleEmailLink" target="_blank">
      <i tabIndex="-1" style="color: rgb(0, 150, 136);"  class="fal fa-search"></i>
    </a>
  </dxi-item>
</dx-box>

<dx-popup
  class="email"
  [showTitle]="true"
  title="All Emails"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="emailListPopup"
>
  <div *dxTemplate="let data of 'content'">
      <dx-data-grid
        id="emailGrid"
        [dataSource]="emails"
        (onCellPrepared)="onCellPrepared($event)"
        (onInitNewRow)="onInitNewRow($event)"
        (onRowUpdating)="onRowUpdating($event)"
        (onRowRemoving)="onRowRemoving($event)"
        (onRowInserted)="onRowInserted($event)"
        (onRowInserting)="onRowInserting($event)"
        (onRowValidating)="onRowValidating($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        (onEditingStart)="onEditingStart($event)"
        keyExpr="Id"
        noDataText="No records. Click + above to add"
        [columnAutoWidth]="true"
      >
        <dxo-paging [enabled]="true"></dxo-paging>
        <dxo-editing
          [allowAdding]="!readOnlyField"
          [allowUpdating]="!readOnlyField"
          [allowDeleting]="!readOnlyField"
          mode="popup"
          [texts]="{
            saveRowChanges: 'OK',
            cancelRowChanges: 'CANCEL'
          }"
        >
          <dxo-form [customizeItem]="customizeItem">
          </dxo-form>
        </dxo-editing>
        <dxi-column dataField="EmailType" caption="Email Type">
          <dxo-lookup
            [dataSource]="emailTypes"
            displayExpr="Text"
            valueExpr="Id"
          ></dxo-lookup>
          <dxi-validation-rule
            type="required"
            *ngIf="validationEnabled"
          ></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="Email">
          <dxi-validation-rule
            type="required"
            *ngIf="validationEnabled"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="email"
            *ngIf="validationEnabled"
            message="Please enter a valid email"
          ></dxi-validation-rule>
        </dxi-column>
        <dxi-column
          dataField="IsDefault"
          caption="Default"
          dataType="boolean"
          [setCellValue]="setCellValue"
        ></dxi-column>
      </dx-data-grid>

      <div class="buttonbar-fixed" fxLayout="row" fxLayoutAlign="center center">
        <dx-button
          id="ok"
          type="default"
          [width]="136"
          text="Ok"
          icon="fal fa-check"
          (click)="saveGrid($event)"
        ></dx-button>
      </div>
  </div>
</dx-popup>
