import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/overlay';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, filter, map, take, takeUntil, tap } from 'rxjs/operators';

export interface SnackBarQueueItem {
  message: string;
  beingDispatched: boolean;
  configParams?: any;
}

@Injectable({
  providedIn: 'root',
})
export class NotifyService implements OnDestroy {
  public snackbarSubject = new Subject<any>();
  public snackbarState = this.snackbarSubject.asObservable();

  private readonly snackBarQueue = new BehaviorSubject<SnackBarQueueItem[]>([]);
  private readonly snackBarQueue$ = this.snackBarQueue.asObservable();
  private readonly ngDestroy = new Subject();

  constructor(private snackBar: MatSnackBar) {
    this.snackBarQueue$
     .pipe(
       filter(queue => queue.length > 0 && !queue[0].beingDispatched),
       tap(() => {
         const updatedQueue = this.snackBarQueue.value;
         updatedQueue[0].beingDispatched = true;
         this.snackBarQueue.next(updatedQueue);
       }),
       map(queue => queue[0]),
       takeUntil(this.ngDestroy))
     .subscribe(snackBarItem => this.showSnackbar(snackBarItem.message, snackBarItem.configParams));
  }

  toast(
    message: string,
    type?: 'error' | 'success',
    action?: string,
    duration: number = 6000,
    customClass?: any,
    position: any = {
      vertical: 'top',
      horizontal: 'right',
    },
    // cb?: CallableFunction,
  ) {
    const toastClasses = (type === 'error')
      ? ['cap-toast', 'mat-toolbar', 'mat-warn', 'center']
      : ['cap-toast', 'mat-toolbar', 'mat-primary', 'center'];
    // const snack =
    if (!!customClass) {
      toastClasses.push(customClass);
    }

    const snackBar = this.snackBar.open(message, (action || 'X'), {
      panelClass: toastClasses,
      duration: (!!duration || duration != 0 ? duration : undefined),
      verticalPosition: position.vertical,
      horizontalPosition: position.horizontal,
    });

    return snackBar;
    // TODO: Callbacks should be doable, but getting errors with implementation
    // if (cb) {
    //   snack.afterDismissed()
    //   .subscribe(() => {
    //     // console.log('callback called', cb);
    //     cb.apply(cb);
    //   });
    // }
  }

  customToast<T, D>(
    component: ComponentType<T>,
    type?: 'error' | 'success',
    data?: D | null,
    duration: number = 6000,
    customClass?: any,
    position: any = {
      vertical: 'top',
      horizontal: 'right',
    },
  ) {
    const toastClasses = (type === 'error') ?
      ['cap-toast', 'mat-toolbar', 'mat-warn', 'center'] :
      ['cap-toast', 'mat-toolbar', 'mat-primary', 'center'];

    if (!!customClass) {
      toastClasses.push(customClass);
    }
    const config: MatSnackBarConfig = {
      data: data,
      panelClass: toastClasses,
      duration: duration,
      verticalPosition: position.vertical,
      horizontalPosition: position.horizontal,
    };

    this.snackBar.openFromComponent(component, config);
  }

  public ngOnDestroy() {
    this.snackBarQueue.next([]);
    this.snackBarQueue.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public queueSnackBar(message: string, configParams?: any) {
    let shouldQueue = true;
    this.snackBarQueue.value.forEach(item => {
      if (item.message == message) {
        shouldQueue = false;
        return;
      }
    });

    if (shouldQueue) {
      this.snackBarQueue.next(
        this.snackBarQueue.value.concat([{ message, configParams, beingDispatched: false }]),
      );
    }
  }

  private showSnackbar(
    message: string,
    type?: 'error' | 'success',
    duration: number = 6000,
    customClass?: any,
    position: any = {
      vertical: 'top',
      horizontal: 'right',
    },
    action?: any,
  ) {
    const toastClasses = (type === 'error') ?
      ['cap-toast', 'mat-toolbar', 'mat-warn', 'center'] :
      ['cap-toast', 'mat-toolbar', 'mat-primary', 'center'];

    if (!!customClass) {
      toastClasses.push(customClass);
    }
    const configParams: MatSnackBarConfig = {
      panelClass: toastClasses,
      duration: duration,
      verticalPosition: position.vertical,
      horizontalPosition: position.horizontal,
    };
    this.removeDismissedSnackBar(
      this.snackBar.open(message, 'X', configParams).afterDismissed(),
    );
  }

  /* Remove dismissed snack bar from queue and triggers another one to appear */
  private removeDismissedSnackBar(dismissed: Observable<any>) {
    dismissed
      .pipe(
        delay(1000),
        take(1))
      .subscribe(() => {
        const updatedQueue = this.snackBarQueue.value;
        if (updatedQueue[0] && updatedQueue[0].beingDispatched) {
          updatedQueue.shift();
        }
        this.snackBarQueue.next(updatedQueue);
      });
  }
}
