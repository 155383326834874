import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectVersionList } from 'aws-sdk/clients/s3';
import { DocumentVersion } from '../../../../models/document-version';

/**
 * The DocumentVersionData interface represents the data that is passed to the DocumentVersionComponent.
 * The type is used to differentiate between the two types of document versions that are supported.
 * wasabi (depreciated): The document version history is stored in Wasabi. This is no longer used,
 * and we have moved to storing it ourselves so we can track a description of what changed
 * captira: The document version history is stored in Captira.
 */
export interface DocumentVersionData {
  type: 'wasabi' | 'captira';
  versions: ObjectVersionList | DocumentVersion[];
  name: string;
}

@Component({
  selector: 'app-document-version',
  templateUrl: './document-version.component.html',
  styleUrls: ['./document-version.component.scss'],
})
export class DocumentVersionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DocumentVersionData) { }

  ngOnInit(): void {
  }

}
