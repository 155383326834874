<dx-box direction="row" [width]="controlWidth">
  <dxi-item ratio="3">
    <dx-text-box
      #txNumber
      id="txNumber"
      [mask]="phoneMask"
      maxLength="20"
      mode="tel"
      maskInvalidMessage="Please enter a valid phone number"
      [useMaskedValue]="false"
      [(value)]="defaultNumber"
      [isValid]="isValid"
      [inputAttr]='{id: randomId}'
      maskChar="_"
      class="masked-input"
      (onValueChanged)="onValueChanged($event)"
      (onFocusOut)="onFocusOut($event)"
      [readOnly]="readOnlyField"
      [disabled]="inputsDisabled">
      <dx-validator></dx-validator>
    </dx-text-box>
  </dxi-item>
  <dxi-item tabindex="-1" ratio="0" baseSize="2">
    <div style="width:2px;"></div>
  </dxi-item>
  <dxi-item tabindex="-1" ratio="0" baseSize="40" style="flex-direction: row;" *ngIf='singlePhone === false'>
    <button tabindex="-1" mat-icon-button type="button" (click)="addNewPhone($event)">
      <span
        *ngIf="phoneCount > 0"
        class="badge badge-primary">
        {{ phoneCount }}
      </span>
      <i tabindex="-1" *ngIf="phoneCount == 0" class="fal fa-window-restore"></i>
    </button>
    <span style="margin-left: 0.8rem; margin-top: 1.25rem;">
      <i tabindex="-1" *ngIf="isDefaultNumberBad" class="fas fa-exclamation-triangle text-danger"></i>
    </span>
  </dxi-item>
</dx-box>

<dx-popup
  class="popup"
  [showTitle]="true"
  title="Phone"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="phoneNumberListPopup">
  <div *dxTemplate="let data of 'content'">
      <dx-data-grid
        id="phoneGrid"
        [dataSource]="phones"
        (onCellPrepared)="onCellPrepared($event)"
        (onInitNewRow)="onInitNewRow($event)"
        (onEditorPreparing)="onEditorPreparing($event)"
        (onRowRemoving)="onRowRemoving($event)"
        (onRowInserted)="onRowInserted($event)"
        (onRowInserting)="onRowInserting($event)"
        (onRowUpdating)="onRowUpdating($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        (onEditingStart)="onEditingStart($event)"
        [columnAutoWidth]="true"
        keyExpr="Id"
        noDataText="No records. Click + above to add"
      >
        <dxo-scrolling
          mode="virtual"
          [showScrollbar]="'always'"
        >
        </dxo-scrolling>
        <dxo-editing
          [allowAdding]="!readOnlyField"
          [allowUpdating]="!readOnlyField"
          [allowDeleting]="!readOnlyField"
          mode="popup"
          [texts]="{
            saveRowChanges: 'OK',
            cancelRowChanges: 'CANCEL'
          }"
        >
          <dxo-form [customizeItem]="customizeItem">

          </dxo-form>
        </dxo-editing>

        <dxi-column
          dataField="PhoneType"
          caption="Phone Type"
          dataType="string"
        >
          <dxo-lookup
            [dataSource]="phoneTypes"
            displayExpr="Text"
            valueExpr="Id"
          ></dxo-lookup>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column
          dataField="Number"
          caption="Number"
          dataType="string"
          [showEditorAlways]="true"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column
          dataField="IsDefault"
          caption="Default"
          dataType="boolean"
          [setCellValue]="setCellValue"
        ></dxi-column>
        <dxi-column
          dataField="IsBad"
          caption="Is Bad"
          dataType="boolean"
        ></dxi-column>
        <dxi-column
          caption="Do Not Contact"
          cellTemplate='doNotContactTemplate'
          dataField="DoNotContact"
          [visible]='showDoNotContact'
          dataType="boolean"
        >
          <div *dxTemplate="let item of 'doNotContactTemplate'">
            <dx-check-box
              [value]='item.data.DoNotContact'
              (onValueChanged)="toggleDoNotContact($event, item)"
            >
            </dx-check-box>
          </div>
        </dxi-column>
      </dx-data-grid>

      <div class="buttonbar-fixed" fxLayout="row" fxLayoutAlign="center center">
        <dx-button
          id="ok"
          type="default"
          [width]="136"
          text="Ok"
          icon="fal fa-check"
          (click)="saveGrid($event)"
        ></dx-button>
      </div>
  </div>
</dx-popup>

<dx-popup
  class="popup"
  [width]="300"
  [height]="200"
  [showTitle]="true"
  [title]="testCallTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="testCallPopup"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view #scrollView id="scrollview">
      <div class="ibox float-e-margins">
        <div class="text-center p-md">
          <div style="margin-bottom: 10px;">
            <a [attr.href]="dialTelNo()">
              <i
                class="btn btn-default fal fa-phone fa-2x"
                style="margin-right: 8px;font-size:30px"
              ></i
            ></a>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
