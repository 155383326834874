import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentComponent } from './document.component';
import { DxDataGridModule, DxFileUploaderModule, DxFormModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonbarModule } from '../../../components/common/buttonbar/buttonbar.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DocumentStatusBadgeComponent } from './document-status-badge/document-status-badge.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../../../shared/pipes.module';
import { MimeToFileTypePipe } from '../../../shared/mime-to-file-type.pipe';
import { DebounceClickModule } from '../../../directives/debounce-click/debounce-click.module';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    DocumentComponent,
    DocumentStatusBadgeComponent,
    MimeToFileTypePipe,
  ],
  imports: [
    CommonModule,
    DxFileUploaderModule,
    DxDataGridModule,
    DxPopupModule,
    MatMenuModule,
    DxFormModule,
    ButtonbarModule,
    DxScrollViewModule,
    FlexLayoutModule,
    MatTooltipModule,
    PipesModule,
    DebounceClickModule,
    MatButtonModule,
  ],
  exports: [
    DocumentComponent,
  ],
})
export class DocumentModule {
}
