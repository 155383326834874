import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SmartResolverService {

  firstNameFields = [
    'firstname',
    'fname',
    'f_name',
  ];

  lastNameFields = [
    'lastname',
    'lname',
    'l_name',
    'surname',
  ];

  invoiceDateFields = [
    'invoicedate',
    'inv date',
    'invdate',
  ];

  invoiceReferenceFields = [
    'invoicereference',
    'invoiceref',
    'invoice ref',
    'invoicenumber',
    'invoice number',
    'invoicenum',
    'invoice num',
    'invnumber',
    'inv number',
    'invnum',
    'inv num',
    'invoice',
  ];

  invoiceAmountFields = [
    'invoiceamount',
    'inv amount',
    'invamount',
    'inv amt',
    'invamt',
    'balance',
    'bal',
    'balance due',
    'bal due',
    'baldue',
    'balancedue',
    'amount',
    'amt',
    'amount due',
    'amtdue',
    'amountdue',
    'debt',
    'owing',
    'outstanding',
  ];

  mobileFields = [
    'telephone',
    'tel',
    'phone',
  ];

  companyNameFields = [
    'companyname',
    'company',
    'business name',
    'businessname',
    'business',
  ];

  addressLine1Fields = [
    'address',
    'address1',
    'add',
    'add1',
  ];

  addressLine2Fields = [
    'address2',
    'add2',
  ];

  stateFields = [
    'statecode',
    'state',
  ];

  zipFields = [
    'zip code',
    'zipcode',
  ];

  contactFirstNameFields = [
    'contact first name',
    'contactfirstname',
    'contactfname',
    'contactf_name',
    'relation first name',
    'relationfirstname',
    'relationfname',
    'relationf_name',
  ];

  contactLastNameFields = [
    'contact last name',
    'contactlastname',
    'contactlname',
    'contactl_name',
    'relation last name',
    'relationlastname',
    'relationlname',
    'relationl_name',
  ];

  contactMobileFields = [
    'contactmobile',
    'contact phone',
    'contactphone',
    'contact telephone',
    'contacttelephone',
    'contact tel',
    'contacttel',
    'relation mobile',
    'relationmobile',
    'relation phone',
    'relationphone',
  ];

  contactEmailFields = [
    'contactemail',
    'relation email',
    'relationemail',
  ];

  notesFields = [
    'note',
    'memo',
  ];

  fullNameFields = [
    'full name',
    'fullname',
  ];

  originalCustomerIdFields = [
    'customer.Id',
    'customer_id',
    'cust.id',
    'cust_id',
    'client.id',
    'client_id',
    'custid',
    'clientid',
    'customerid',
    'customer id',
  ];

  originalInvoiceIdFields = [
    'invoice.id',
    'invoice_id',
    'inv.id',
    'inv_id',
  ];

  contactSsn = [
    'contact1ssn'
  ]

  ssn = [
    'ssn'
  ]

  descriptionFields = [
    'description',
    'debt description',
    'debt_description'
  ]

  constructor() { }

  resolveHeader(data: { t: string, v: string, r: string, h: string, w: string}) {
    if (!data) {
      return data;
    }
    if (this.firstNameFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('First Name');
    }
    if (this.lastNameFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Last Name');
    }
    if (this.invoiceDateFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Invoice Date');
    }
    if (this.invoiceReferenceFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Invoice Reference');
    }
    if (this.invoiceAmountFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Invoice Amount Due');
    }
    if (this.mobileFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Mobile');
    }
    if (this.companyNameFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Company Name');
    }
    if (this.addressLine1Fields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Address Line 1');
    }
    if (this.addressLine2Fields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Address Line 2');
    }
    if (this.stateFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('State Code');
    }
    if (this.zipFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Zip');
    }
    if (this.contactFirstNameFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Contact First Name');
    }
    if (this.contactLastNameFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Contact Last Name');
    }
    if (this.contactEmailFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Contact Email');
    }
    if (this.notesFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Notes');
    }
    if (this.fullNameFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Name');
    }
    if (this.originalCustomerIdFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Customer Id');
    }
    if (this.originalInvoiceIdFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow('Invoice Id');
    }
    if (this.contactSsn.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow("Contact Ssn");
    }
    if (this.ssn.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow("Ssn");
    }
    if (this.descriptionFields.indexOf(data.v.toLowerCase()) > -1) {
      data = this.buildRow("Description");
    }

    return data;
  }

  private buildRow(value: string): { t: string, v: string, r: string, h: string, w: string} {
    return { t: 's', v: value, r: `<t>${value}</t>`, h: value, w: value };
  }
}
