import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {SettingsService} from './settings.service';
import * as localforage from 'localforage';
import { Observable } from 'rxjs';

@Injectable()
export class CacheService {

  expiryTimeSuffix = ':et';
  expireAfterTime: number = 10 * 60; // 10 min by default
  // insert in array to enable lookups caching, remove to disable, all lookups listed below
  // 'sureties', 'agencies', 'prefixes', 'devices', 'types', 'activties', 'filedrates', 'users',
  // 'states', 'cities', 'cc/gateways', 'mastersureties', 'masterprefixes', 'customermastersureties',
  // 'customermasterprefixes', 'agents'
  //
  // All grids:
  // agents, prospects, users, prospects, companylicenses, advertisingsources, agencies, agents, attorneys,
  // chargeclasses, charges, courtdatereasons, courts, cc, dispositions, fees, filedrates, jails, links,
  // paymentmethods, paymenttypes, prefixes, prospectdeclinedreasons, recoveryagents, sureties, transferagencies

  // allowedLookup: any = ['advertisingsources', 'agencies', 'charges', 'prefixes', 'devices', 'filedrates', 'agents'];
  // allowedGrid: any = ['advertisingsources', 'agencies', 'charges', 'prefixes', 'devices', 'filedrates', 'agents'];
  allowedLookup: any = ['agents', 'prospects', 'users', 'states', 'prospects', 'companylicenses', 'advertisingsources',
                      'agencies', 'agents', 'attorneys', 'chargeclasses', 'charges', 'courtdatereasons', 'courts', 'cc',
                      'dispositions', 'fees', 'filedrates', 'jails', 'links', 'paymentmethods', 'paymenttypes', 'prefixes',
                      'prospectdeclinedreasons', 'recoveryagents', 'sureties', 'transferagencies', 'masterprefixes', 'mastersureties',
                      'customermastersureties', 'customermasterprefixes', 'riskassessment'];

  allowedGrid: any = [];

  isIDBSupported = false;

  private postData: any;

  constructor(private http: HttpClient, private settings: SettingsService) {
    this.checkIDBSupport();
  }

  async checkIDBSupport() {
    try {
      const key = 'TESTKEY';
      await localforage.setItem(key, key);
      await localforage.removeItem(key);
      this.isIDBSupported = true; // todo set back to false to disable caching
    } catch (e) {
      this.isIDBSupported = false;
    }
  }

  isSupported() {
    try {
      const key = 'TESTKEY';
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true ; // todo set back to false to disable caching
    } catch (e) {
      return false;
    }
  }

  exists(key: any) {
    return localStorage.getItem(key) !== null;
  }

  isExpired(key: any) {
    const cachedTime = localStorage.getItem(key + this.expiryTimeSuffix);
    if (!!cachedTime) {
      const age = ((Date.now() - parseInt(cachedTime, 10)) / 1000);
      if (age > this.expireAfterTime) { // remove data if expired
        localStorage.removeItem(key);
        localStorage.removeItem(key + this.expiryTimeSuffix);
      }
      return (age > this.expireAfterTime);
    } else { // may be stored without expiry key, if data is not found that will be handles from get method
      return false;
    }
  }

  /**
   * Sets a value in storage
   * @param key storage reference
   * @param value value to be stored
   * @param expiry false, prevent expiry check for this key
   */
  set(key: any, value: any, expiry = true) {
    if (!key) { // Additional check: not to process anything if no key passed
      return;
    }
    if (this.isSupported()) {
      this.remove(key);
      try {
        localStorage.setItem(key, value.toString());
        if (expiry) {
          localStorage.setItem(key + this.expiryTimeSuffix, Date.now().toString());
        } else {
          // etc - Expire time custom
          localStorage.setItem(`${key}${this.expiryTimeSuffix}c`, Date.now().toString());
        }
      } catch (e) {
        console.error('There is issue while saving cache, probably memory full!', e);
        const totalUsed = Object.keys(window.localStorage).map((itemKey) => {
          return localStorage[itemKey].length;
        }).reduce((a, b) => {
          return a + b;
        });
      }
    }
  }

  get(key, reset = false) {
    if (!this.isSupported()) { return false; } // If localStorage is not supported then get data from API
    if (reset) { this.remove(key); return null; } // Remove key from localStorage and return null to load data from API
    if (this.isExpired(key)) { return false; } // If data is stored more than expiry time then get it from API
    const data = localStorage.getItem(key);
    if (!!data) {
      return data;
    } else {
      return null;
    }
  }

  remove(key: string) {
    if (this.isSupported()) {
      localStorage.removeItem(key);
      localStorage.removeItem(key + this.expiryTimeSuffix);
    }
  }

  removeByViewName(viewname) {
    const key = `${viewname}/grid/all?limit=0`;
    this.remove(key);
  }

  removeByLookupName(lookupName) {
    const url = `${lookupName}/lookup/all?limit=0`;
    // Check if this lookup has extra data such as searchfilter and includes in cache
    const searchData  = this.get(`${lookupName}:searchData`);
    if (!!searchData) {
      const url2 = `${lookupName}/lookup/all?limit=50&search=${searchData}`;
      this.remove(url2);
    }

    const includes = this.get(`${lookupName}:includes`);
    if (!!includes) {
      const url3 = `lookups/byname/${lookupName}/all?limit=0${includes}`;
      const url4 = `${lookupName}/lookup/all?limit=0${includes}`;
      this.remove(url3);
      this.remove(url4);
    }
    this.remove(url);
  }

  getLookup(lookupName, key, resetCache = false) {
    if (this.allowedLookup.indexOf(lookupName) >= 0) {
      const data = this.get(key, resetCache);
      return data;
    } else {
      console.warn(`Caching not enabled for lookup: ${lookupName}.`);
    }
  }

  setLookup(lookupName, key, value) {
    if (this.allowedLookup.indexOf(lookupName) >= 0) {
      const data = this.set(key, value);
      return data;
    } else {
      console.warn(`Caching not enabled for lookup: ${lookupName}.`);
    }
  }

  getGrid(gridName, key, resetCache = false) {
    return '';
    // // console.log('CACHE: Getting Grid cache for ', gridName, ' allowed: ', this.allowedGrid.indexOf(gridName));
    // if (this.allowedGrid.indexOf(gridName) >= 0) {
    //   let data = this.get(key, resetCache);
    //   return data;
    // } else {
    //   console.warn(`Caching not enabled for grid: ${gridName}.`);
    //   return '';
    // }
  }

  setGrid(gridName, key, value) {
    value = '';
    // // console.log('CACHE: Setting Grid cache for ', gridName, ' allowed: ', this.allowedGrid.indexOf(gridName));
    // if (this.allowedGrid.indexOf(gridName) >= 0) {
    //   let data = this.set(key, value);
    //   return data;
    // } else {
    //   console.warn(`Caching not enabled for grid: ${gridName}.`);
    //   return '';
    // }
  }

  removeallLookup() { // remove all lookup from cache on add/update of any data from list setup UI
    for (let i = 0; i < this.allowedLookup.length; i++) {
      this.remove(this.allowedLookup[i] + '/lookup/all?limit=0');
    }
  }

  // Generate grid report key: in case of filter data attached for the report
  generateGridReportKey(filterData: any) {
    let filterKey = '';
    if (filterData) {
      filterData.forEach(function (obj) {
        for (const objKey in obj) {
          if (obj.hasOwnProperty(objKey)) {
            filterKey = filterKey + objKey + ':' + obj[objKey] + '&';
          }
        }
      });
    }
    return filterKey;
  }



  async isExpiredInIDB(key: any) {
    const cachedTime = <string>(await localforage.getItem(key + this.expiryTimeSuffix));
    if (!!cachedTime) {
      const age = ((Date.now() - parseInt(cachedTime, 10)) / 1000);
      if (age > this.expireAfterTime) { // remove data if expired
        await localforage.removeItem(key);
        await localforage.removeItem(key + this.expiryTimeSuffix);
      }
      return (age > this.expireAfterTime);
    } else { // may be stored without expiry key, if data is not found that will be handles from get method
      return false;
    }
  }

  async getFromIDB(key: string, reset = false) {
    if (!this.isIDBSupported) {
      // If Indexed DB is not supported, return data from localStorage
      const localData = this.get(key, reset);
      if (typeof(localData) === 'string') {
        return JSON.parse(localData);
      } else {
        return localData;
      }
    }
    const isExpired = await this.isExpiredInIDB(key);
    if (isExpired) { return false; } // If data is stored more than expiry time then get it from API
    const data = await localforage.getItem(key);
    if (!!data) {
      return data;
    } else {
      return null;
    }
  }

  async setInIDB(key: any, value: any, expiry = true) {
    // console.log('IDB: Setting IDB data for key with expiry key: ', key);
    if (!key) { // Additional check: not to process anything if no key passed
      // console.log('Key is missing while setting cache, not processing anything!');
      return;
    }
    if (this.isIDBSupported) {
      try {
        await localforage.setItem(key, value);
        if (expiry) {
          await localforage.setItem(key + this.expiryTimeSuffix, Date.now().toString());
        }
      } catch (e) {
        console.error('There is issue while saving IDB, probably memory full!', key, value, e);
        const cachedKeys = await localforage.keys();
        let totalUsed = 0;
        for (let i = 0; i < cachedKeys.length; i++) {
          const data = await localforage.getItem(cachedKeys[i]);
          totalUsed += JSON.stringify(data || '').length;
        }
        console.log('Total IDB storage used: ', totalUsed);
      }
    } else {
      // If Indexed DB is not supported, save data in localStorage
      this.set(key, value, expiry);
    }
  }

  async removeFromIDB(key: string) {
    if (!this.isIDBSupported) { return false; } // If Indexed DB is not supported then get data from API
    if (this.isSupported()) {
      await localforage.removeItem(key);
      await localforage.removeItem(key + this.expiryTimeSuffix);
    }
  }

  async removeDb() {
    if (this.isIDBSupported) {
      const customers = await localforage.getItem('customersDataGrid');
      await localforage.clear();
      if (!!customers) {
        await localforage.setItem('customersDataGrid', customers);
      }
    }
  }

  /**
   * This is used when switching between accounts. We need to wipe out all cached local storage values except for ones used
   * for authentication and account settings.
   * @param keysToPreserve A list of properties to keep. We keep securityToken, refreshToken, activeCustomerId,
   * userId, and validCustomers
   */
  clearAllExcept(keysToPreserve: string[] = ['documentKeyId', 'documentRoot', 'identityId', 'userEmail', 'securityToken', 'refreshToken', 'activeCustomerId', 'userId', 'validCustomers']): Observable<void> {
    return new Observable<void>((subscriber) => {
      const save: {key: string, value: string | null}[] = [];

      for (const key of keysToPreserve) {
        save.push({key, value: localStorage[key]});
      }
      localStorage.clear();

      for (const [index, item] of save.entries()) {
        if (!!item.value) {
          localStorage[item.key] = item.value;
        }
        if (index + 1 === save.length) {
          subscriber.complete();
        }
      }
    });
  }

  clearSessionStorage() {
    sessionStorage.clear();
  }
}
