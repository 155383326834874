import { Action, createReducer, on } from '@ngrx/store';
import { selectReportItem } from '../actions/report-items.actions';


export const reportItemsFeatureKey = 'reportItems';

export interface ReportItemState {
	itemType: any,
	itemId: any,
	url: any,
	selectedReport: any,
	scrollPosition: any,
	pageOptions: any
}

const lastSelectedReport = localStorage.getItem('lastSelectedReport') ? JSON.parse(localStorage.getItem('lastSelectedReport')) : {};

export const initialState: ReportItemState = {
	itemType: 'any',
	itemId: null,
	url: '/dashboard',
	selectedReport: lastSelectedReport,
	scrollPosition: { x: 0, y: 0 },
	pageOptions: { pageIndex: 0 }
};


const reportItemReducer = createReducer(
  initialState,
  on(selectReportItem, (state, {itemType, itemId, url, selectedReport, scrollPosition, pageOptions}) => ({ ...state, itemType, itemId, url, selectedReport, scrollPosition, pageOptions }))
)

export function reducer(state: ReportItemState | undefined, action: Action) {
  return reportItemReducer(state, action);
}