<div >
  <mat-card-title class='btn'
    style="margin-bottom: 0;font-size:24px"
    [ngStyle]="{'padding': (selectedCategoryId) ? '0' : '15px' }">
    {{ caption }}
  </mat-card-title>
  <div fxFlex></div>
  <button mat-icon-button aria-label="Refresh" (click)="doRefresh()"
          *ngIf="!selectedCategoryId">
    <mat-icon fontSet="fal" fontIcon="fa-sync">
    </mat-icon>
  </button>
</div>
<mat-card-content>
  <mat-nav-list style="padding: 0;" *ngIf="!selectedCategoryId">
    <mat-list-item
      (click)="listSelection(category)"
      *ngFor="let category of categories">
      <span>{{ category.text }}</span>
      <span style="display: flex; flex-grow: 1;"></span>
      <span class="badge badge-primary" *ngIf="!category.loading && category.count > 0">
        {{ category.count }}
      </span>
    </mat-list-item>
  </mat-nav-list>
  <div *ngIf='!selectedCategoryId' class='activity-button'>
    <a mat-stroked-button color='primary' routerLink='/defendant/active'>
      Agency Activity ⇀
    </a>
  </div>
  <div fxLayout="row"
       fxLayout.xs="column"
       [fxLayout.sm]="smFilterLayout"
       [fxLayout.md]="smFilterLayout"
       fxLayoutAlign="start center"
       fxLayoutGap="15px"
       *ngIf="selectedCategoryId">

    <div *ngIf="displayConfig.backToQuickLinks">
      <button
        mat-button
        color="primary"
        (click)="returnToList()">
        <i class="fal fa-chevron-left icon-spacer"></i>
        Back to Quick Links
      </button>
    </div>
    <dx-select-box *ngIf="showSortByDateFilter"
                   [dataSource]="sortByDateFilters"
                   displayExpr="Text"
                   valueExpr="Id"
                   [(value)]="sortByDateFilter"
                   [showClearButton]="false"
                   (onValueChanged)="dropdownChanged($event)">
    </dx-select-box>
    <dx-select-box
        #dateType
        [hidden]="!showDatesFilter"
        [items]="dateSourceType"
        placeholder="Select Date"
        [(value)]="dateRangeFilter"
        (onValueChanged)="dateSelectorChanged($event)"
        >
    </dx-select-box>

    <dx-select-box *ngIf="showSuretyFilter"
                   [dataSource]='sureties'
                   displayExpr="Text"
                   placeholder="Surety"
                   valueExpr="Id"
                   [(value)]="suretyFilter"
                   (onValueChanged)="dropdownChanged($event)">
    </dx-select-box>

    <dx-select-box *ngIf="showAgencyAgentFilter"
                   [dataSource]="agencies"
                   displayExpr="Text"
                   placeholder="Agency"
                   valueExpr="Id"
                   [(value)]="agencyFilter"
                   (onValueChanged)="dropdownChanged($event)">
    </dx-select-box>

    <dx-select-box *ngIf="showAgencyAgentFilter"
                   [dataSource]="agents"
                   displayExpr="Text"
                   placeholder="Agent"
                   valueExpr="Id"
                   [(value)]="agentFilter"
                   (onValueChanged)="dropdownChanged($event)">
    </dx-select-box>

    <span *ngIf="showDatesFilter && dateType.value == 'Custom Range'" fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column">
      <dx-date-box
        type="date"
        [(value)]="startDateFilter"
        placeholder="Select Start Date">
      </dx-date-box>
      <dx-date-box
        (onEnterKey)="applyFilters()"
        type="date"
        [(value)]="endDateFilter"
        placeholder="Select End Date">
      </dx-date-box>

      <button
        mat-raised-button
        color="primary"
        (click)="applyFilters()">
        Apply
      </button>
    </span>

    <div class='form-group' *ngIf='showPastDueFilters'>
      <label class='control-label'>Minimum Days</label>
      <dx-number-box
        placeholder='Minimum Days'
        [min]='1'
        [max]='3650'
        [(value)]='minDaysFilter'
      ></dx-number-box>
    </div>
    <div class='form-group' *ngIf='showPastDueFilters'>
      <label class='control-label'>Maximum Days</label>
      <dx-number-box
        placeholder='Maximum Days'
        [min]='1'
        [max]='3650'
        [(value)]='maxDaysFilter'
      ></dx-number-box>
    </div>
    <div class='form-group' *ngIf='showPastDueFilters'>
      <label class='control-label'>Minimum Balance</label>
      <dx-number-box
        *ngIf='showPastDueFilters'
        placeholder='Minimum Balance'
        [format]="{type: 'currency', precision: 2}"
        [(value)]='minBalance'
      ></dx-number-box>
    </div>
    <div class='form-group' *ngIf='showPastDueFilters'>
      <label class='control-label'>Maximum Balance</label>
      <dx-number-box
        *ngIf='showPastDueFilters'
        placeholder='Maximum Balance'
        [format]="{type: 'currency', precision: 2}"
        [(value)]='maxBalance'
      ></dx-number-box>
    </div>
    <div *ngIf='showPastDueFilters'>
      <button
        mat-raised-button
        color="primary"
        (click)="applyChanges()">
        Apply
      </button>
    </div>
  </div>

  <dx-data-grid
    *ngIf="selectedCategoryId"
    [dataSource]="gridData"
    [(columns)]="columns"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onCellPrepared)='onCellPrepared($event)'
    (dataSourceChange)="onDataSourceChange()"
    width="100%">
    <dxo-selection [mode]="selectionMode"></dxo-selection>
    <dxo-search-panel [visible]="true" placeholder="Search"></dxo-search-panel>

    <div *dxTemplate="let data of 'defendantNameTemplate'">
      <a href="/defendant/{{data.data.defendantID || data.data.DefendantId || data.data.defendantId ||data.data.DefendantID}}" role="button" [class.defendant-v1]='!!data.data.V1Id' class="defendanturl"
         (click)="showDefendant($event, data.data.defendantID || data.data.DefendantId || data.data.defendantId || data.data.DefendantID)">
        <span
          id="{{ data.data.defendantID || data.data.DefendantId || data.data.defendantId ||data.data.DefendantID }}">
          {{data.value}}
        </span>
      </a>
    </div>


    <div *dxTemplate="let data of 'ssnCellTemplateGrid'">
      {{data.value | ssnMask : 'compact'}}
    </div>
    <div *dxTemplate="let data of 'clickToCheckinTemplate'">
      <a [hidden]="(data.data['Checked In'] == true) || (data.data.Missed == true)" role="button" id="{{data.data.DefendantID}}:{{data.data.Defendant}}"
         (click)="addCheckin(data.data.DefendantID, data.data.Defendant, data.rowIndex)">{{data.value}}</a>
    </div>
    <div *dxTemplate="let data of 'clickToAddNoteTemplate'">
      <a role="button" id="{{data.data.DefendantID}}:{{data.data.Defendant}}"
         (click)="addNote(data.data.DefendantID, data.data.Defendant)">{{data.value}}</a>
    </div>
    <div *dxTemplate="let data of 'notifiedCourtDateCheckbox'">
      <dx-check-box [disabled]="data.value" [(value)]="data.value" (onValueChanged)="onChangeCourtDateNotified(data)"></dx-check-box>
    </div>
    <dxo-summary [(totalItems)]="summaryColumns"></dxo-summary>

  </dx-data-grid>
  <dx-tooltip
    position="top"
  >
    <div *dxTemplate="let data = data of 'content'">
      Balances from 2015 and prior shown on defendant file.
    </div>
  </dx-tooltip>
</mat-card-content>

<app-dynamic-popup [dragEnabled]="dynamicPopupDragEnabled"
                   [maxHeight]="dynamicPopupMaxHeight"
                   [maxWidth]="dynamicPopupMaxWidth"
                   [popupComponent]="dynamicPopupClass" [popupComponentData]="dynamicPopupData"
                   [popupComponentTitle]="dynamicPopupTitle" [popupComponentVisible]="dynamicPopupVisible"
                   [popupShowTitle]="dynamicPopupShowTitle" (onPopupHidden)="dynamicPopupHidden($event)"></app-dynamic-popup>
