import * as FileSaver from 'file-saver';

export namespace FileUtilities {
  export function dataUrlToArrayBuffer(dataUrl: string) {
    dataUrl = dataUrl.replace(/^data\:([^\;]+)\;base64,/gim, '');
    var binaryString = atob(dataUrl);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  export function base64ToDataUrl(
    mediaType: string,
    base64Data: string
  ): string {
    let dataUrl = `data:${mediaType};base64,${base64Data}`;

    return dataUrl;
  }

  export function base64ToBinary(base64Data: string): Uint8Array {
    var binaryString = atob(base64Data);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  export function binaryToBase64(buffer: any) {
    var binString = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binString += String.fromCharCode(bytes[i]);
    }
    return btoa(binString);
  }

  export function base64ToBlob(mediaType: string, base64Data: string): Blob {
    var binaryString = atob(base64Data);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], {type: mediaType});
  }

  //this is a bit hinky
  export function downloadBlob(blobUrl: string, filename: string) {
    let a = document.createElement('a');
    if (!a.click) {
      throw new Error('DownloadManager: "a.click()" is not supported.');
    }
    a.href = blobUrl;
    a.target = '_parent';
    // Use a.download if available. This increases the likelihood that
    // the file is downloaded instead of opened by another PDF plugin.
    if ('download' in a) {
      a.download = filename;
    }
    // <a> must be in the document for IE and recent Firefox versions,
    // otherwise .click() is ignored.
    (document.body || document.documentElement).appendChild(a);
    a.click();
    a.remove();
  }

  export function saveBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    window.location.href = url;

    const reader = new FileReader();
    reader.onloadend = function () {
      const dataUrl = reader.result;
      if (typeof dataUrl === 'string') {
        window.location.href = dataUrl;
      } else {
        if (dataUrl) {
          window.location.href = dataUrl.toString();
        }
      }

    };
    reader.readAsDataURL(blob);
  }

  export function saveBlobAs(blob: Blob, filename: string) {
    FileSaver.saveAs(blob, filename);
  }

  export function blobToFile(blob: any, filename: string): File {
    blob.lastModifiedDate = new Date();
    blob.name = filename;
    return blob;
  }

  export function arrayBufferToBase64String(buffer: any) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  //Translates canvas to orient image upright based on EXIF orientation
  export function transformCoordinates(
    canvas: HTMLCanvasElement,
    orientation: number
  ) {
    let ctx = canvas.getContext('2d');

    let width = canvas.width;
    let height = canvas.height;
    var styleWidth = canvas.style.width;
    var styleHeight = canvas.style.height;

    if (!orientation || orientation > 8) {
      return;
    }

    if (orientation > 4) {
      canvas.width = height;
      canvas.height = width;
      canvas.style.width = styleHeight;
      canvas.style.height = styleWidth;
    }

    switch (orientation) {
      case 2:
        // horizontal flip
        ctx.translate(width, 0);
        ctx.scale(-1, 1);
        break;
      case 3:
        // 180° rotate left
        ctx.translate(width, height);
        // ctx.rotate(Math.PI);
        break;
      case 4:
        // vertical flip
        ctx.translate(0, height);
        ctx.scale(1, -1);
        break;
      case 5:
        // vertical flip + 90 rotate right
        // ctx.rotate(0.5 * Math.PI);
        ctx.scale(1, -1);
        break;
      case 6:
        // 90° rotate right
        // We don't need to rotate under this orientation
        // ctx.rotate(0.5 * Math.PI);
        ctx.translate(0, -height);
        break;
      case 7:
        // horizontal flip + 90 rotate right
        // ctx.rotate(0.5 * Math.PI);
        ctx.translate(width, -height);
        ctx.scale(-1, 1);
        break;
      case 8:
        // 90° rotate left
        // ctx.rotate(-0.5 * Math.PI);
        ctx.translate(-width, 0);
        break;
    }
  }

  export function FileSizeText(bytes): string {

    if (bytes < 1024) {
      return bytes + ' B';
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(1) + ' KB';
    } else if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(2) + ' MB';
    } else {
      return (bytes / 1073741824).toFixed(3) + ' GB';
    }
  }
}
