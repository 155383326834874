import { BaseModel } from './BaseModel';
import { Employer } from './employer';
import { Vehicle } from './vehicle';
import { Person } from './person';

export class Contact extends BaseModel {
  // tslint:disable:variable-name
  public Id: string;
  person: Person;
  public Type: any;
  public Relation: any;
  public Identifier: string;

  public BankName: string;
  public BankAccountType: string;
  public BankAccountNumber: string;
  public MonthlyIncome: number;
  public ReferenceForId: string;
  public TransactionId: string;

  // todo: have to get from here to person.height
  public HeightFt: string;
  public HeightIn: string;
  employers: Employer;
  vehicles: Vehicle;
  refId: string | number;
  refForId: any;
  FullName: string;
  PersonId: string;

  constructor(obj?: object) {
    super(obj);
    this.childrenNames = ['person'];
    this.routeRoot = 'contacts';
    this.propertiesToIgnoreForCheckChanges.push('HeightFt', 'HeightIn', 'refId', 'refForId', 'Identifier', 'FullName');

    if (obj == null) {
      this.Id = '';
      this.person = new Person();
    }


  }

  public loadData(data) {
    this.fillFromObject(data, true);
    // console.log('contact.loaddata', 'contact loaded');
    this.person.loadData(data.person.data);
    // console.log('contact.loaddata', 'person loaded');

    // const splitHeight = this.person.getHeightFeetAndInches();
    // if (splitHeight !== null && splitHeight.length === 2) {
    //   this.HeightFt = splitHeight[0];
    //   this.HeightIn = splitHeight[1];
    // }
  }

  public enableGoogleReview(): boolean {
    return this.Type === 'Indemnitor';
  }

  public checkForChanges(object: any = this): boolean {
    let isDirty = false;

    if (object instanceof Contact) {
      if (super.checkForChanges && super.checkForChanges()) {
        isDirty = true;
        object.isDirty = true;
      }
    } else if (object.checkForChanges && object.checkForChanges()) {
      isDirty = true;
      object.isDirty = true;
    }

    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const child = object[key];

        if (typeof child === 'object' && child !== null) {
          if (this.checkForChanges(child)) {
            isDirty = true;
            object.isDirty = true;
          }
        }
      }
    }

    return isDirty;
  }
}
