import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { Defendant } from '../../models/defendant';
// import {DefendantService} from '../../services/defendant.service';
// import {BondService} from '../../services/bondservice.service';
// import {GridService} from '../../services/grid.service';
// import {TransactionInvoiceService} from '../../services/transasctioninvoice.service';
import { LookupService } from '../../services/app-lookup.service';

import { BondImportService } from '../../services/bond-import.service';
import { ImportBond } from '../../models/import-bond';
import { EventService } from '../../services/event.service';
import * as moment from 'moment';
import notify from 'devextreme/ui/notify';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-importbond',
  templateUrl: './importbond.component.html',
  styleUrls: ['./importbond.component.scss'],
})
export class ImportbondComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() inPopup: boolean;
  @Input() showImportPopupVar = false;
  @Input() forDefendantWatch = false;
  @Output() onImportPopupHidden = new EventEmitter();
  @ViewChild('importActiveBond') importActiveBond: DxFileUploaderComponent;
  existing = {
    agencies: {
      Id: [],
      Name: [],
    },
    agents: {
      Id: [],
      Name: [],
    },
    courts: {
      Id: [],
      Name: [],
    },
    jails: {
      Id: [],
      Name: [],
    },
    // sureties: this.existingProp,
    // prefixes: this.existingProp,
    // powers: this.existingProp,
  };

  importedData = [];
  requiredKeys = [];
  allKeys = [];
  criticalList = {
    missingColumns: [],
    requiredKeys: [],
  };
  warningList = [];
  isBusy = false;
  reportSummary = '';
  recordToProcess = [];
  showSummary = false;
  showImport = false;
  importErrorType = '';
  summaryIconClass = '';
  loaderMessage = 'Processing file for errors…';
  importDetails = {
    rowStatus: {},
    requiredIndexes: [],
    postedDateIndex: -1,
    uniqueDefIndexes: [],
    uniqueDefTransIndexes: [],
    errorType: '',
    transIdIndexMap: {},
    requiredIndexesType: [],
  }; // will have a complete record of errors and other details of rows
  requiredKeysName = [];
  showReset = false;
  summaryReportList = [];
  importedDefs = [];
  importedDefsTransacs = [];
  apiJSON = {};
  defendant: Defendant;
  savedToServer = {
    defendant: [],
    bonds: [],
  };
  accordionData = [];
  totalProgress = 0;
  apiResults = null;
  importBondData = [];
  importBondObjectMapFromFriendlyName = {
    DefendantFirstName: 'Defendant First Name',
    DefendantLastName: 'Defendant Last Name',
    DefendantDob: 'Defendant DOB',
    DefendantSsn: 'Defendant SSN',
    DefendantAddress: 'Defendant Address',
    DefendantCity: 'Defendant City',
    DefendantState: 'Defendant State',
    DefendantZip: 'Defendant Zip',
    DefendantMobileNumber: 'Defendant Mobile Number',
    DefendantEmail: 'Defendant Email',
    Agency: 'Agency',
    Agent: 'Agent',
    Jail: 'Jail',
    PostedDate: 'Posted Date',
    BondAmount: 'Bond Amount',
    CaseNumber: 'Case Number',
    BondCharge: 'Bond Charge',
    ForfeitureDate: 'Forfeiture Date',
    ExonerationDate: 'Exoneration Date',
    BondStateCode: 'Bond State Code',
    BondCounty: 'Bond County',
    PowerNumber: 'Power Number',
    Surety: 'Surety',
    CourtName: 'Court Name',
    CourtDate: 'Court Date',
    CourtTime: 'Court Time',
    Balance: 'Balance',
    IndemnitorFirstName: 'Indemnitor First Name',
    IndemnitorLastName: 'Indemnitor Last Name',
    IndemnitorDob: 'Indemnitor DOB',
    IndemnitorSsn: 'Indemnitor SSN',
    IndemnitorAddress: 'Indemnitor Address',
    IndemnitorCity: 'Indemnitor City',
    IndemnitorState: 'Indemnitor State',
    IndemnitorZip: 'Indemnitor Zip',
    IndemnitorPhone: 'Indemnitor Phone',
    IndemnitorEmail: 'Indemnitor Email',
    CashCollateralBalance: 'Cash Collateral Balance',
    Note: 'Note',
  };

  constructor( // EM - removing unused services
    // private defendantService: DefendantService,
    // private bondService: BondService,
    // private invoiceService: TransactionInvoiceService,
    // private gridService: GridService,
    private bondImportService: BondImportService,
    private eventService: EventService,
    private lookupService: LookupService,
    private router: Router,
  ) {
    super();
  }

  // tslint:disable-next-line: no-empty
  ngOnInit() {
  }

  // Handle reset and import again button click, reset everything, all array and all report data etc.
  resetImport() {
    this.allKeys = [];
    this.accordionData = [];
    this.importActiveBond.instance.reset();
    this.reportSummary = '';
    this.showSummary = false;
    this.showImport = false;
    this.isBusy = false;
    this.showReset = false;
    this.totalProgress = 0;
    this.importedData = [];
    this.requiredKeys = [];
    this.importErrorType = '';
    this.criticalList = {
      missingColumns: [],
      requiredKeys: [],
    };
    this.warningList = [];
    this.recordToProcess = [];
    this.summaryIconClass = '';
    this.loaderMessage = 'Processing file for errors…';
    this.importDetails = {
      rowStatus: {},
      requiredIndexes: [],
      postedDateIndex: -1,
      uniqueDefIndexes: [],
      uniqueDefTransIndexes: [],
      errorType: '',
      transIdIndexMap: {},
      requiredIndexesType: [],
    };
    this.apiResults = null;
    this.requiredKeysName = [];
    this.summaryReportList = [];
    this.importedDefs = [];
    this.importedDefsTransacs = [];
    this.apiJSON = {};
    this.savedToServer = {
      defendant: [],
      bonds: [],
    };
    this.existing = {
      agencies: {
        Id: [],
        Name: [],
      },
      agents: {
        Id: [],
        Name: [],
      },
      courts: {
        Id: [],
        Name: [],
      },
      jails: {
        Id: [],
        Name: [],
      },
      // sureties: this.existingProp,
      // prefixes: this.existingProp,
      // powers: this.existingProp,
    };
    this.importBondData = [];
  }

  // Handle reset and import again button click, reset everything, all array and all report data etc.
  // resetImport() {
  //   this.importActiveBond.instance.reset();
  //   this.reportSummary = '';
  //   this.showSummary = false;
  //   this.showImport = false;
  //   this.isBusy = false;
  //   this.showReset = false;
  //   this.totalProgress = 0;
  //   this.importedData = [];
  //   this.requiredKeys = [];
  //   this.criticalList = {
  //     missingColumns: [],
  //     requiredKeys: [],
  //   };
  //   this.warningList = [];
  //   this.recordToProcess = [];
  //   this.summaryIconClass = '';
  //   this.loaderMessage = 'Processing file for errors…';
  //   this.importDetails = {
  //     rowStatus: {},
  //     requiredIndexes: [],
  //     postedDateIndex: -1,
  //     uniqueDefIndexes: [],
  //     uniqueDefTransIndexes: [],
  //     errorType: '',
  //     transIdIndexMap: {},
  //   };
  //   this.requiredKeysName = [];
  //   this.summaryReportList = [];
  //   this.importedDefs = [];
  //   this.importedDefsTransacs = [];
  //   this.apiJSON = {};
  //   this.savedToServer = {
  //     defendant: [],
  //     bonds: [],
  //   };
  //   this.existing = {
  //     agencies: {
  //       Id: [],
  //       Name: [],
  //     },
  //     agents: {
  //       Id: [],
  //       Name: [],
  //     },
  //     courts: {
  //       Id: [],
  //       Name: [],
  //     },
  //     jails: {
  //       Id: [],
  //       Name: [],
  //     },
  //     // sureties: this.existingProp,
  //     // prefixes: this.existingProp,
  //     // powers: this.existingProp,
  //   };
  // }

  // Handle import popup hidden event
  // Trigger another event for parent component toc perform anything after popup close.
  hidden(e) {
    this.resetImport();
    this.showImportPopupVar = false;
    this.onImportPopupHidden.emit(false);
  }

  onUpload(e) {
    this.isBusy = true;
    // console.log('Upload Started:', e);

    if (!!e && !!e.value && e.value.length > 0) {
      const reader = new FileReader();
      reader.readAsText(e.value[0]);
      reader.onload = this.loadHandler.bind(this);
      reader.onerror = this.errorHandler;

    }
  }

  errorHandler(event) {
    if (event.target.error.name === 'NotReadableError') {
      console.error('Unable to read file!');
    } else {
      console.error(event);
    }
  }

  // once this finishes, json is created, waiting for foreign keys
  loadHandler(event) {
    const csv: any = event.target.result;
    const csvRows = csv.split(/\r\n|\n/);
    // Regex will split newline and return carriage values to sanitize empty rows
    let rowCounter = 0;

    if (csvRows.length > 10000) {
      // Limit to 10k max rows to prevent any memory issue => move to CONSTANT file for configuration
      console.error('File too large; Exceeds 10k line limit!');
      return;
    }

    csvRows.forEach(row => {
      this.sanitizeData(row, rowCounter);
      rowCounter++;
    });
    this.validateRequiredKeys();
    // console.log('allKeys', this.allKeys);
    this.convertImportDataToArrayOfObjects();
    this.summaryRecords();
    // console.log('this.importBondData', this.importBondData);
    this.showImport = true;

    this.isBusy = false;

  }

  // validate each csv row for valid data and push to this.importedData
  sanitizeData(row, rowCount) {
    // Skip empty rows
    if (!!row) {
      const newRow = [];
      if (rowCount === 0) {
        let colCount = 0; // Track the column indexes
        this.importDetails.uniqueDefIndexes = [];
        this.importDetails.uniqueDefTransIndexes = [];

        // Web Scraper will sanitize data and remove commas
        // Otherwise the may cause an issue from unfiltered sources
        row.split(',').forEach(col => {
          // Trim the header in case there is a space before or after
          col = col.trim();
          newRow.push(col);

          this.indexHeader(col, colCount);

          colCount++;
        });

        newRow.unshift('UniqueDefTransId');
        newRow.unshift('UniqueDefId');

        this.allKeys.unshift('UniqueDefTransId');
        this.allKeys.unshift('UniqueDefId');
      } else {
        let uniqueDefId = '';

        let uniqueDefTransId = '';

        const csvPattern = new RegExp(/(\,|\r?\n|\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^"\,\r\n]*))/gi);

        let arrMatches = null;
        while (arrMatches = csvPattern.exec(row)) {
          // Handle embedded commas
          if (arrMatches[ 2 ]) {
            newRow.push(arrMatches[ 2 ].replace(new RegExp( '\"\"', 'g' ), '\"'));
          } else {
            newRow.push(arrMatches[ 3 ]);
          }
        }

        this.importDetails.uniqueDefIndexes.forEach(index => {
          uniqueDefId += newRow[this.importDetails.uniqueDefIndexes[index]];
          uniqueDefId.replace(/ /g, '');
        });

        this.importDetails.uniqueDefTransIndexes.forEach(index => {
          uniqueDefTransId +=
            newRow[this.importDetails.uniqueDefTransIndexes[index]];
          uniqueDefTransId.replace(/ /g, '');
        });

        newRow.unshift(uniqueDefTransId.replace(/\//g, ''));
        newRow.unshift(uniqueDefId.replace(/\//g, ''));
      }

      this.importedData.push(newRow);
      // // console.log('Row:', rowCount);
    }
  }

  indexHeader(col, colCount) {
    // // console.log(col, colCount);
    switch (col) {
      case 'Defendant First Name':
        this.importDetails.uniqueDefIndexes.push(colCount);
        this.importDetails.uniqueDefTransIndexes.push(colCount);
        break;

      case 'Defendant Last Name':
        this.importDetails.uniqueDefIndexes.push(colCount);
        this.importDetails.uniqueDefTransIndexes.push(colCount);
        break;

      case 'Defendant DOB':
        this.importDetails.uniqueDefIndexes.push(colCount);
        this.importDetails.uniqueDefTransIndexes.push(colCount);
        break;

      case 'Defendant SSN':
        this.importDetails.uniqueDefIndexes.push(colCount);
        this.importDetails.uniqueDefTransIndexes.push(colCount);
        break;

      case 'Posted Date':
        this.importDetails.uniqueDefTransIndexes.push(colCount);
        break;
      default:
        break;
    }
    // Keep an array of all the keys in csv so we can reference index of row
    this.allKeys.push(col);
  }

  convertImportDataToArrayOfObjects() {
    for (let i = 1; i < this.importedData.length; i++) {
      const newImportBond = new ImportBond();
      newImportBond.DefendantFirstName =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantFirstName)];
      newImportBond.DefendantLastName =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantLastName)];
      newImportBond.DefendantDob = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantDob)];
      newImportBond.DefendantSsn = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantSsn)];
      newImportBond.DefendantAddress =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantAddress)];
      newImportBond.DefendantCity = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantCity)];
      newImportBond.DefendantState = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantState)];
      newImportBond.DefendantZip = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantZip)];
      newImportBond.DefendantMobileNumber =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantMobileNumber)];
      if (newImportBond.DefendantMobileNumber) {
        newImportBond.DefendantMobileNumber =
          newImportBond.DefendantMobileNumber.replace(/[-\(\).+ ]/g, '').replace('+1', '').replace('.', '').trim();
      }
      newImportBond.DefendantEmail = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.DefendantEmail)];
      newImportBond.Agency = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.Agency)];
      newImportBond.Agent = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.Agent)];
      newImportBond.Jail = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.Jail)];
      newImportBond.PostedDate = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.PostedDate)];
      newImportBond.BondAmount = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.BondAmount)];
      newImportBond.CaseNumber = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.CaseNumber)];
      newImportBond.BondCharge = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.BondCharge)];
      newImportBond.ForfeitureDate = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.ForfeitureDate)];
      newImportBond.ExonerationDate = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.ExonerationDate)];
      newImportBond.BondStateCode = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.BondStateCode)];
      newImportBond.BondCounty = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.BondCounty)];
      newImportBond.PowerNumber = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.PowerNumber)];
      newImportBond.Surety = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.Surety)];
      newImportBond.CourtName = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.CourtName)];
      newImportBond.CourtDate = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.CourtDate)];
      newImportBond.CourtTime = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.CourtTime)];
      newImportBond.Balance = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.Balance)];
      newImportBond.IndemnitorFirstName =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorFirstName)];
      newImportBond.IndemnitorLastName =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorLastName)];
      newImportBond.IndemnitorDob = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorDob)];
      newImportBond.IndemnitorSsn =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorSsn)];
      newImportBond.IndemnitorAddress =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorAddress)];
      newImportBond.IndemnitorCity = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorCity)];
      newImportBond.IndemnitorState = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorState)];
      newImportBond.IndemnitorZip = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorZip)];
      newImportBond.IndemnitorPhone = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorPhone)];
      if (newImportBond.IndemnitorPhone) {
        newImportBond.IndemnitorPhone =
          newImportBond.IndemnitorPhone.replace(/[-\(\).+ ]/g, '').replace('+1', '').replace('.', '').trim();
      }
      newImportBond.IndemnitorEmail = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.IndemnitorEmail)];
      newImportBond.CashCollateralBalance =
        this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.CashCollateralBalance)];
      newImportBond.Note = this.importedData[i][this.allKeys.indexOf(this.importBondObjectMapFromFriendlyName.Note)];

      this.importBondData.push(newImportBond);
    }
  }

  // Get list of all required keys from configuration file: this.bondImportService.ts
  // Check if the first row of file has all the friendly name present that are required,
  // Push this key as requiredIndex, will be used to verify if each rows has value present for these key at least
  // Else mark this error as critical and no record will be imported in this case.
  // Push the error in criticalList JSON with key: missingColumns (used to display more detailed error)
  validateRequiredKeys() {
    this.requiredKeys = this.bondImportService.getRequiredKeys(this.forDefendantWatch);
    this.importDetails.requiredIndexes = []; // Collect index of required fields
    this.importDetails.requiredIndexesType = [];

    // // console.log('Required keys:', this.requiredKeys);

    this.requiredKeys.forEach(req => {
      this.requiredKeysName.push(req.friendlyName);

      if (this.importedData[0].indexOf(req.friendlyName) > -1) {
        this.importDetails.requiredIndexes.push(this.importedData[0].indexOf(req.friendlyName));
        this.importDetails.requiredIndexesType.push(req.type ? req.type : '');
      } else {
        this.importDetails.errorType = 'critical';
        if (!this.criticalList.missingColumns) {
          this.criticalList.missingColumns = [];
        }
        this.criticalList.missingColumns.push(req.friendlyName);

      }
      if (req.friendlyName === 'Posted Date') {
        this.importDetails.postedDateIndex = this.importedData[0].indexOf(
          req.friendlyName,
        );
      }
    });

    // Match if the required field has values
    if (this.importDetails.errorType !== 'critical') {
      let criticalErrors = false;
      // console.table('Required Indexes:', this.importDetails['requiredIndexes']);
      // // console.log('Imported data:', this.importedData);
      for (let i = 1; i < this.importedData.length; i++) {
        // Starting from i=1 as first row has column names
        criticalErrors = false;

        for (let j = 0; j < this.importDetails.requiredIndexes.length; j++) {
          if (!this.importedData[i][this.importDetails.requiredIndexes[j]]) {
            this.importDetails.errorType = 'warning';
            if (!this.criticalList.requiredKeys) {
              this.criticalList.requiredKeys = [];
            }
            this.criticalList.requiredKeys.push(this.requiredKeys[j].friendlyName);
          } else {
            // OK, so there is something in that field, but is it valid?
            if (this.importDetails.requiredIndexesType[j]) {
              switch (this.importDetails.requiredIndexesType[j]) {
                case 'DATE':
                  const validFormats = ['M/D/YY', 'M/D/YYYY', 'MM/D/YY', 'MM/DD/YY', 'M/DD/YY', 'MM/D/YYYY', 'MM/DD/YYYY', 'M/DD/YYYY',
                    'M-D-YY', 'M-D-YYYY', 'MM-D-YY', 'MM-DD-YY', 'M-DD-YY', 'MM-D-YYYY', 'MM-DD-YYYY', 'M-DD-YYYY',
                  ];
                  const dateToCheck = moment(this.importedData[i][this.importDetails.requiredIndexes[j]], validFormats, true);
                  if (dateToCheck == null || !dateToCheck.isValid()) {
                    this.importDetails.errorType = 'warning';
                    criticalErrors = true;
                    if (!this.criticalList.requiredKeys) {
                      this.criticalList.requiredKeys = [];
                    }
                    this.criticalList.requiredKeys.push(this.requiredKeys[j].friendlyName);
                  }
                  break;
                case 'TIME':
                  // pfft whatever
                  break;
              }
            }
          }
        }

        if (!criticalErrors) {
          this.recordToProcess.push(this.importedData[i]);
        }
      }
    }
  }

  // Set error report type to handle further import actions
  // Critical error will not import anything (if column is missing in sheet)
  // Critical error will not import rows with status isCritical true, all other rows will be imported
  // Warning error will be shown only and bonds will be imported
  setImportErrorType() {
    let errorType = '';
    if (
      this.importDetails.errorType === 'warning' &&
      (!!this.warningList && Object.keys(this.warningList).length > 0)
      || (!!this.criticalList && Object.keys(this.criticalList.requiredKeys).length > 0)) {
      errorType = 'warning';
    }
    if (
      this.importDetails.errorType === 'critical' &&
      !!this.criticalList &&
      Object.keys(this.criticalList).length > 0
    ) {
      errorType = 'critical';
    }
    return errorType;
  }

  // Set summary for critical errors
  // Add missing column and missing data in required keys for any row details
  setCriticalErrorDetails() {
    console.error(
      'Critical errors in setCriticalErrorDetails:',
      this.criticalList,
    );
    if (!!this.criticalList.missingColumns && this.criticalList.missingColumns.length > 0) {
      console.error('Missing column in import sheet!');
      this.importActiveBond.instance.reset();
      this.showImport = false;
      this.summaryReportList.push(`${this.criticalList.missingColumns.join(', ')} column is required and missing in uploaded file.`);
    }

    if (!!this.criticalList.requiredKeys && this.criticalList.requiredKeys.length > 0) {
      console.error('Missing data for required fields in some rows!');
      this.summaryReportList.push(
        `Missing or invalid data for required fields: ${this.criticalList.requiredKeys
          .filter((v, i, a) => a.indexOf(v) === i).join(', ')}.`);
      this.summaryReportList.push(`Some records may not be imported.`);

    }
  }

  summaryRecords() {
    const self: this = this;
    // // console.log('Generating import report!', self.importedData, 'Processed data:', this.recordToProcess);
    self.showSummary = true;
    self.showImport = true;
    self.showReset = true;
    self.summaryReportList = [];
    self.importErrorType = this.setImportErrorType();

    switch (self.importErrorType) {
      case 'warning':
        console.error('Warning errors:', self.warningList);
        self.reportSummary = 'Important errors in import file:';
        this.setCriticalErrorDetails();
        self.summaryIconClass = 'fal fa-info-circle';
        break;
      case 'critical':
        console.error('Critical errors:', self.criticalList);
        self.reportSummary = 'Critical errors in import file:';
        self.summaryIconClass = 'fal fa-times-circle';
        this.setCriticalErrorDetails();
        break;
      default:
        self.reportSummary = 'File validated successfully!';
        self.summaryIconClass = 'fal fa-check-circle';
    }
    this.isBusy = false;
  }

  // Start processing collected data from file in two steps
  // 1. Create defendant and wait for response
  // 2. Updated values from response to bonds from file and update defendant (or save bonds)
  startUploadToApi() {
    this.eventService.componentBusy(true);
    this.isBusy = true;
    const dataToSend = {
      importBondData: this.importBondData,
      forDefWatch: this.forDefendantWatch,
    };
    this.subs.sink = this.bondImportService.submitBondImportData(dataToSend).subscribe(res => {
      // console.log('import results:', res);
      this.apiResults = res;
      this.setupAccordionData();
      this.eventService.componentBusy(false);
      this.lookupService.clearCaching();
      this.isBusy = false;
    }, error1 => {
      this.eventService.componentBusy(false);
      this.isBusy = false;
      console.error(error1);
    });


  }

  getItemKeys (item) {
    return Object.keys(item);
  }

  setupAccordionData() {
    this.accordionData = [];
    this.apiResults.Feedback.details.Created.title = 'Created';
    this.accordionData.push(this.apiResults.Feedback.details.Created);
    this.apiResults.Feedback.details.Existed.title = 'Existed';
    this.accordionData.push(this.apiResults.Feedback.details.Existed);
    this.apiResults.Feedback.details.Failed.title = 'Failed';
    this.accordionData.push(this.apiResults.Feedback.details.Failed);
  }

  finishAndClose() {
    if (this.inPopup) {
      this.showImportPopupVar = false;
    } else {
      this.router.navigateByUrl('/defendantmanager/defendants');
      notify('Import Successful!', 'success');
    }
  }

  // Show processing as a percent for progress bar
  format(value) {
    // console.log('Converting value:', value);
    if (parseInt(value, NaN) === 1) {
      return 'All bonds have been imported successfully!';
    } else {
      return 'Processing upload: ' + value * 100 + '%';
    }
  }
}
