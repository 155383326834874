
import {of, throwError, Observable } from 'rxjs';
import {map, catchError, first, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';
import {Preferences} from '../models/preferences';
import {CacheService} from './cache.service';

@Injectable()
export class PreferencesService {

  constructor(private http: HttpClient, private settings: SettingsService, private cache: CacheService) {}

  getPreferences(id: string, fresh = false): Observable<any> {
    const key = `preferences/user/${id}`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const data = this.cache.get(key);
    if (!!data) {
      return of(JSON.parse(data));
    } else {
       return this.http.get(url).pipe(
         map((res: any) => {
           this.cache.set(key, JSON.stringify(res));
           return res;
         }),
         catchError(error => throwError(error)),
       );
    }
  }

  savePreferences(preferences: Preferences): Observable<any> {
    const key = `preferences/user/${preferences.Id}`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    return this.http.patch(url, preferences).pipe(
      map((res: any) => {
        this.cache.set(key, JSON.stringify(res));
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
    );
  }
}
