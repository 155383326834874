import {Component, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {PdfObservableItem} from '../components/common/pdf-viewer/pdf-viewer.component';

export interface ReportPopoutData {
  // should be something besides -1: that value is used to signify a 'default' instance
  id: number;
  modalName?: string;
  reportObservable?: Observable<any>;
  pdfDownloadObservables?: Array<PdfObservableItem>;
  reportCategory?: string;
  reportFieldValues?: any;
  objectTypeName?: string;
  objectName?: string;
  sourceType?: string;
  sourceId?: string;
  isSmartFormPDF?: any;
  base64String?: string;
  pdfPurpose?: string;
  coordinateData?: any;
  PDFBase64String?: string;
  reportName?: string;

  windowInstance?: any;
  outlet?: any;
  componentInstance?: any;
  parentWindowInstance?: any;
}

export const REPORT_POPOUT_DATA = new InjectionToken<ReportPopoutData>
(
  'POPOUT_MODAL_DATA',
  {
    factory: () => {
      const dummy: ReportPopoutData = {id: -1};
      return dummy;
    },
  });

export enum PopoutModalName {
  'pdfViewer' = 'PDF_VIEWER',
}

