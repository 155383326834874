import {catchError, map} from 'rxjs/operators';
import { throwError, Observable, Subject } from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';
import {CacheService} from './cache.service';
import { SendApplicationData } from '../views/prospects/send-application/send-application.component';
import { PeerToPeerLink } from './../models/PeerToPeerLink';
import {PortoResponse} from '../models/porto-response';

export interface SendPdfByMailData extends SendApplicationData {
  pdfs: string[];
}

@Injectable()
export class CustomerService {
  defaultEmailWasChanged: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient,
              private settings: SettingsService,
              private cache: CacheService) {
  }

  getCurrent(): Observable<any> {
    // This is a call to get all, but only the current customer will be in scope..
    return this.http.get(`${this.settings.getBaseUrl()}/customers`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  doSetup(masterSuretyId: any, stateCode: string) {
    if (masterSuretyId === '-1') {
      // this is for the option of choosing no surety
      // can't send unencrypted -1 to the API here, need to send empty string
      masterSuretyId = '';
    }
    return this.http.post(`${this.settings.getBaseUrl()}/customersetup`, {'MasterSuretyId': masterSuretyId, 'State': stateCode}).pipe(
      map((res: any) => {
        // console.log(`doSetup result: ${JSON.stringify(res)}`);
        this.cache.removeallLookup();
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  doSetupForMvs() {
    return this.doSetupGeneric('customersetupformvs');
  }

  doSetupForDefMgr() {
    return this.doSetupGeneric('customersetupfordefendantmanager');
  }

  doSetupForCollections() {
    return this.doSetupGeneric('customersetupforcollections');
  }

  doSetupGeneric(route) {
    return this.http.post(`${this.settings.getBaseUrl()}/${route}`, {'nothing': 'nothingneeded...yet'}).pipe(
      map((res: any) => {
        // console.log(`doSetupFor ${route} result: ${JSON.stringify(res)}`);
        this.cache.removeallLookup();
        return res;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  updateEmail(data : any) {
    return this.http.put(`${this.settings.getBaseUrl()}/updatecustomeremail`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  toggleNoInteraction(data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/togglenointeraction/${data.id}`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  logCustomerOnboardingAction(data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/logcustomeronboarding`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  getFinalOnboardingSetting(): Observable<any> {
    // This is a call to get all, but only the current customer will be in scope..
    return this.http.get(`${this.settings.getBaseUrl()}/getfinalonboardingsetting`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getCollectionOnboardingDiscounts(): Observable<any> {
    // This is a call to get all, but only the current customer will be in scope..
    return this.http.get(`${this.settings.getBaseUrl()}/customer/getcollectiononboardingdiscounts`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  updateFinalOnboardingSetting(data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/updatefinalonboardingsetting`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  updateCollectionOnboardingDiscounts(data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/customer/updatecollectiononboardingdiscounts`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  doItForMeSupport(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/doitformesupport`, {}).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  saveApplicationFieldSettings(settings: any) {
    return this.http.post(`${this.settings.getBaseUrl()}/saveapplicationfieldsettings`, settings).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }


  getApplicationFieldSettings(): Observable<any> {
    // This is a call to get all, but only the current customer will be in scope..
    return this.http.get(`${this.settings.getBaseUrl()}/getapplicationfieldsettings`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getExternalApplicationFieldSettings(customerId: string|null, transactionId: any): Observable<any> {
    // This is a call to get all, but only the current customer will be in scope..
    return this.http.get(`${this.settings.getBaseUrl()}/getexternalapplicationfieldsettings/${customerId}?transactionId=${transactionId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  sendPdfsByMail(data: SendPdfByMailData) {
    return this.http.post(`${this.settings.getBaseUrl()}/sendpdfsbymail`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
    }));
  }

  sendApplicationSms(data: any) {
    return this.http.post(`${this.settings.getBaseUrl()}/sendapplicationbysms`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
    }));
  }

  verifyCaptcha(token: string) {
    return this.http.post(`${this.settings.getBaseUrl()}/customer/verify-captcha`, {token}).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
    }));
  }

  getCarolMetrics(filters = {}) {
     // This is a call to get all, but only the current customer will be in scope..
     filters = JSON.stringify(filters);
     return this.http.get(`${this.settings.getBaseUrl()}/customer/get-carol-metrics?filters=${filters}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  sendReminderPausedNotification() {
    return this.http.post(`${this.settings.getBaseUrl()}/customer/send-reminder-paused-notification`, {}).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
    }));
  }

  clearDncEntries(phone: string) {
    return this.http.post(`${this.settings.getBaseUrl()}/customer/clear-dnc-entries`, {phone}).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
    }));
  }

  savePeerToPeer(data: any) {
    return this.http.post(`${this.settings.getBaseUrl()}/customer/create-peer-to-peer`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
    }));
  }
  
  getPeerToPeer() : Observable<PeerToPeerLink[]> {
    // This is a call to get all, but only the current customer will be in scope..
    return this.http.get<PortoResponse<PeerToPeerLink[]>>(`${this.settings.getBaseUrl()}/customer/get-peer-to-peer`).pipe(
      map((res) => res.data),
      catchError(error => throwError(error.error || 'Server Error')));
  }
}
