import { BaseModel } from './BaseModel';
import { Bond } from './bond';
import { Collateral } from './collateral';
import { Contact } from './contact';
import { Note } from './note';
import { Document } from './document';
import { TransactionARSummary } from './transactionarsummary';
import { StringUtilities } from '../shared/StringUtilities';
import * as moment from 'moment';
import { Address } from './address';
import { ObjectUtilities } from '../shared/ObjectUtilities';
import { Agent } from './agent';
import { PaymentSchedule } from './paymentschedule';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;
import formatCurrency = StringUtilities.formatCurrency;
import {Share} from './share';

// tslint:disable:variable-name
export class Bail extends BaseModel {
  public Id: string;
  public notes: Note[];

  AgencyName: string;
  AgencyId: string;
  AgentId: string;
  AssignedTo: string;
  Office: string;
  SourceId: string;
  SourceName: string;
  JailId: string;
  JailName: string;
  agent: Agent;
  PostedDate: any;
  ReleasedDate: any;
  Identifier: string;
  Status: string;
  StatusText: string;
  ArrestDate: string;
  CallDate: string;
  BookingIdentifier: string;

  Balance: number;

  IsAtCollections: boolean;

  bonds: Bond[];
  contacts: Contact[];
  collaterals: Collateral[];
  documents: Document[];
  application: Share;
  arsummary: TransactionARSummary;
  paymentschedule: PaymentSchedule;
  SellingAgentId: string;
  IntakeAgentId: string;
  PostingAgentId: string;

  canEditDatePosted: boolean;
  canEditPowers: boolean;
  SendStatement: boolean;
  MoneyAvailable: number;
  Probability: string;
  data: any;
  canEditData: boolean;
  NeedsPaperwork: boolean;
  Jurisdiction: string;
  DeadReason: string;

  constructor(obj?: object) {
    super(obj);

    this.childrenNames = ['bonds', 'contacts', 'collaterals', 'notes', 'documents', 'arsummary', 'paymentschedule', 'agent', 'application'];
    this.propertiesToIgnoreForCheckChanges.push('canEditDatePosted', 'canEditPowers', 'Balance', 'AssignedTo', 'Office', 'canEditData', 'show', 'buttonLabel', 'AgencyName', 'SourceName', 'JailName', 'StatusText');

    if (obj == null) {
      this.Id = '';
      this.IsAtCollections = false;
      this.SendStatement = false;
      this.bonds = [];
      this.contacts = [];
      this.collaterals = [];
      this.documents = [];
      this.notes = [];
      this.initDatePosted();
      this.canEditDatePosted = true;
      this.canEditPowers = true;
      this.AgencyId = '';
      this.AgentId = '';
      this.AssignedTo = '';
      this.Office = '';
      this.SourceId = '';
      this.JailId = '';

      this.routeRoot = 'transactions';

      this.canEditData = false;
      this.NeedsPaperwork = false;
      this.paymentschedule = new PaymentSchedule();
      this.agent = new Agent();
    }


  }

  public loadData(data) {
    this.fillFromObject(data, true);
    const mainThis = this;
    this.canEditData = false;

    this.loadTransactionArSummary(data);

    if (!!data.bonds && data.bonds.data !== null && data.bonds.data.length > 0) {
      data.bonds.data.forEach(
        (bond) => {
          const newBond = new Bond();
          newBond.loadData(bond);

          mainThis.bonds.push(newBond);
        });
    }

    if (!!data.collaterals && data.collaterals.data !== null && data.collaterals.data.length > 0) {
      data.collaterals.data.forEach(
        (collaterals) => {
          const newCollateral = new Collateral();
          newCollateral.fillFromObject(collaterals, true);
          newCollateral.loadData(collaterals);
          if (collaterals.addresses != null && !isNullUndefinedOrEmpty(collaterals.addresses.data)) {
            collaterals.addresses.data.forEach(
              (addresses) => {
                const newAddress = new Address();
                newAddress.fillFromObject(addresses);
                newCollateral.addresses.push(newAddress);

              });
          }

          mainThis.collaterals.push(newCollateral);
        });
    }


    if (data.notes && data.notes.data !== null && data.notes.data.length > 0) {
      data.notes.data.forEach(
        (notes) => {
          const newNote = new Note();
          newNote.fillFromObject(notes);
          mainThis.notes.push(newNote);
        });
    }


    if (data.contacts && data.contacts.data !== null && data.contacts.data.length > 0) {
      data.contacts.data.forEach(
        (contacts) => {
          const newContact = new Contact();
          newContact.loadData(contacts);
          mainThis.contacts.push(newContact);
        });
    }

    if (data.documents && data.documents.data !== null && data.documents.data.length > 0) {
      data.documents.data.forEach(
        (documents) => {
          const newDocument = new Document();
          newDocument.loadData(documents);
          mainThis.documents.push(newDocument);
        });
    }

    if (!!data.paymentschedule
      && !!data.paymentschedule.data) {
      const paySched = new PaymentSchedule();
      paySched.loadData(data.paymentschedule.data);
      this.paymentschedule = paySched;
    }

    if (!!data.application
      && !!data.application.data) {
      const application = new Share();
      application.loadData(data.application.data);
      this.application = application;
    }


    if (!!data.agent && !!data.agent.data) {
      this.agent = new Agent();
      this.agent.loadData(data.agent.data);
    }
    this.canEditData = true;

  }

  initDatePosted() {
    if (this.Status === 'Client' && this.PostedDate == null) {

      this.PostedDate = StringUtilities.getCurrentDateString();
    } else if (this.Status === 'Dead' || this.Status === 'Prospect') {
      this.canEditDatePosted = false;
      this.canEditPowers = false;
      this.PostedDate = null;
    }
  }

  isEditable() {
    return (this.Status === 'Client');
  }


  public getOwners(otherOwners: any[]) {

    let owners: any = [];
    if (this.contacts.length > 0) {
      const contactResult = this.contacts.filter(item => item.Type === 'Indemnitor' && !item.isDeleted);
      contactResult.forEach(function (ct) {
        const owner = {
          'Id': ct.Id ? ct.Id : ct.refId,
          'Text': ct.person.First + ' ' + ct.person.Last,
          'Type': 'Indemnitor',
          'refId': ct.refId,
        };
        owners.push(owner);
      });
    }
    if (otherOwners !== undefined && otherOwners.length > 0) {
      owners = owners.concat(otherOwners);
    }

    return owners;
  }

  public getBondTotal(): number {
    let total = 0.0;

    if (this.bonds.length > 0) {
      total = this.bonds.map(item => item.Amount).reduce((prev, next) => prev + next);
    }

    return total;

  }

  public getGrossPremium(): number {
    let gross = 0.0;
    if (this.bonds.length > 0) {
      gross = this.bonds.map(item => item.Gross).reduce((prev, next) => prev + next);
    }
    return gross;
  }

  public getCollateralTotal(): number {
    let total = 0.0;
    if (this.collaterals.length > 0) {
      total = this.collaterals.map(item => item.Value).reduce((prev, next) => prev + next);
    }

    return total;

  }

  getTransactionLineSummary() {
    const liability = this.getBondTotal();
    let liabilityString = '';
    if (liability != null) {
      liabilityString = 'Liability: $' + formatCurrency(liability);

    }
    const postedDate = moment(this.PostedDate).format('MM/DD/YYYY');
    const strSummary = this.Status + ': ' + postedDate + ' ' + liabilityString;
    return strSummary;
  }

  public loadTransactionArSummary(data) {
    // console.log('loadTransactionArSummary data', data);
    if (data.hasOwnProperty('arsummary') && !!data.arsummary && data.arsummary.TotalBalance !== null) {
      this.arsummary = new TransactionARSummary(data.arsummary);
      this.Balance = this.arsummary.TotalBalance;
    }
  }
}
