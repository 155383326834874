import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-defendant-manager-reminder-advice',
  templateUrl: './defendant-manager-reminder-advice.component.html',
  styleUrls: ['./defendant-manager-reminder-advice.component.scss'],
})
export class DefendantManagerReminderAdviceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
