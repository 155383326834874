import {BaseModel} from './BaseModel';

export class OnceOffCampaign extends BaseModel {
  // tslint:disable:variable-name
  public ReminderTypeId: any;
  public CallOutForId: any;
  public TemplateId: any;
  public AudienceOptionId: any ;
  public MessageTemplateOptionId: any;
  public DefendantIds: any;
  public DefendantData: any;
  public FilterIds: any;
  public MessageText: string;
  public IncludeAllNumbers: boolean;
  public MessageTypeId: any;
  public OnceOffParams: any;
  public ReminderSource: any;
  public CustomNumber: any;
  public IncludeIndemnitors: boolean;

  constructor(obj?: object) {
    super(obj);
    if (obj == null) {
     this.DefendantIds = [];
     this.IncludeAllNumbers = false;
    }
  }
}
