import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Note } from '../../../../models/note';
import { StringUtilities } from '../../../../shared/StringUtilities';
import { Subject } from 'rxjs';
import { IDynamicPopupComponent } from '../../../../components/common/dynamic-popup/dynamic-popup.component';
import { AppConstants } from '../../../../shared/AppConstants';
import { ButtonbarComponent } from '../../../../components/common/buttonbar/buttonbar.component';
import { NotesService } from '../../../../services/notes.service';
import { DxTextAreaComponent } from 'devextreme-angular';
import { first, take } from 'rxjs/operators';
import { UnsubscribeOnDestroyAdapter } from '../../../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../../../common/notify/notify.service';


@Component({
  selector: 'app-defendantnotespopup',
  templateUrl: './defendantnotespopup.component.html',
  styleUrls: ['./defendantnotespopup.component.scss'],
  providers: [NotesService],
})
export class DefendantNotesPopupComponent extends UnsubscribeOnDestroyAdapter implements OnInit, IDynamicPopupComponent {

  @ViewChild('navButtonBar', { static: true }) buttonBar: ButtonbarComponent;
  @ViewChild('textArea', { static: true }) textArea: DxTextAreaComponent;


  newNote = new Note();
  noteForDefendantNameString: string;

  dynamicData: any;
  onClose = new Subject<boolean>();
  inDynamicPopup = false;

  loadIndicatorVisible = false;
  saveButtonDisabled = false;
  selectedNavRoute = '';

  errorTitle = 'There were errors';
  errorData: any[];
  errorVisible = false;
  notes: Note[];

  constructor(
    private noteService: NotesService,
    private notifyService: NotifyService
  ) {
    super();
    this.onSave = this.onSave.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  ngOnInit() {
    this.newNote.NoteStamp = StringUtilities.getCurrentDateTimeString();
    this.newNote.User = localStorage.getItem('userName');
    this.newNote.Subject = '';

    if (this.dynamicData != null) {
      this.inDynamicPopup = true;
      if (this.dynamicData[AppConstants.TRANSACTION_ID_KEY]) {
        this.newNote.SourceType = 'DefendantTransactions';
        this.newNote.SourceId = this.dynamicData[AppConstants.TRANSACTION_ID_KEY];
        if (this.dynamicData['Notes']) {
          this.notes = this.dynamicData['Notes'];
        }
        if (this.dynamicData[AppConstants.DEFENDANT_NAME_KEY]) {
          this.noteForDefendantNameString = `New Note for ${this.dynamicData[AppConstants.DEFENDANT_NAME_KEY]}`;
        }
      } else if (this.dynamicData[AppConstants.DEFENDANT_ID_KEY]) {
        this.newNote.SourceType = 'DefendantTransactions';
        this.newNote.SourceId = this.dynamicData[AppConstants.DEFENDANT_ID_KEY];
        if (this.dynamicData['Notes']) {
          this.notes = this.dynamicData['Notes'];
        }
        if (this.dynamicData[AppConstants.DEFENDANT_NAME_KEY]) {
          this.noteForDefendantNameString = `New Note for ${this.dynamicData[AppConstants.DEFENDANT_NAME_KEY]}`;
        }
      } else {
        this.errorData = [{ status_code: -1, detail: 'Defendant information (first name, last name, and date of birth) is required before you are able to add a note.' }]
      }
    }
  }

  onSave(e) {
    this.saveButtonDisabled = true;
    this.popToast('Saving ...');
    if (this.dynamicData[AppConstants.TRANSACTION_ID_KEY]) {
      this.subs.sink = this.noteService.createTransactionNote(this.newNote)
        .pipe(
          take(1),
        ).subscribe((sc) => {
          if (this.notes) {
            this.newNote.Id = sc.data.Id;
            this.notes.push(this.newNote);
          }
          this.postSave();
        }, (error) => {
          this.handleError(error);
        });
    } else {
      this.subs.sink = this.noteService.createNoteByDefendantId(this.newNote).pipe(take(1),).subscribe((sc) => {
        this.postSave();
      }, (error) => {
        this.handleError(error);
      });
    }
  }

  cancel() {
    if (this.inDynamicPopup) {
      this.onClose.next(true);
    } else {
      this.buttonBar.routeForCancel();
    }
  }

  popToast(msg) {
    this.notifyService.toast(msg, "success");
  }


  handleError(error) {
    this.saveButtonDisabled = false;
    this.errorData = error;
  }

  postSave(showToast = true) {
    if (showToast) {
      this.popToast('Saved');
    }
    this.saveButtonDisabled = false;
    if (this.inDynamicPopup) {
      this.onClose.next(true);
    } else {
      this.buttonBar.navigate();
    }
  }
}
