import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { ButtonbarComponent } from '../../components/common/buttonbar/buttonbar.component';
import { StringUtilities } from '../../shared/StringUtilities';
import { AppConstants } from '../../shared/AppConstants';
import { CheckInScheduleService } from '../../services/checkinschedule.service';
import { IDynamicPopupComponent } from '../../components/common/dynamic-popup/dynamic-popup.component';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { SearchboxComponent } from '../../components/common/searchbox/searchbox.component';
import { CheckinscheduleComponent } from '../checkinschedule/checkinschedule.component';
import { EventService } from '../../services/event.service';
import * as moment from 'moment';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../common/notify/notify.service';
import { CheckInSchedule } from '../../models/checkinschedule';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.css'],
})
export class CheckinComponent extends UnsubscribeOnDestroyAdapter implements OnInit, IDynamicPopupComponent {

  @ViewChild('navButtonBar', { static: true }) buttonBar: ButtonbarComponent;
  @ViewChild('valGroup', { static: true }) valGroup: DxValidationGroupComponent;
  @ViewChild(SearchboxComponent) searchBox: SearchboxComponent;
  @ViewChild('checkinHistoryGrid') checkinHistoryGrid: DxDataGridComponent;

  @Input() defendantModel: any;
  @Input() showCheckinHistory: any;

  comment = '';
  checkinDate = StringUtilities.getCurrentDateString();
  sourceId: any;
  parentId: any;
  defendantName: any;
  maxLength = 2000;

  errorTitle = 'There were errors';
  errorData: any[];
  errorVisible = false;

  loadIndicatorVisible = false;
  saveButtonDisabled = false;
  selectedNavRoute = '';
  showDefendantSearch = false;
  noDefendantRoute = '/dashboard';

  dynamicData: any;
  onClose = new Subject<boolean>();
  inDynamicPopup = false;
  selectedDefendantName: string;
  dateFormatter = StringUtilities.dateFormatter;
  existingCheckin: any;

  checkinSchedule: CheckInSchedule;
  defendantId: string;
  checkinHistoryGridData: any = [];
  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData      = [];
  dynamicPopupTitle     = '';
  dynamicPopupVisible   = false;
  dynamicPopupShowTitle = false;
  addEditCheckinText    = 'Add Check In schedule';
  onSaveFn: any;
  cancelFn: any;

  constructor(
    private checkInService: CheckInScheduleService,
    private eventService: EventService,
    public router: Router,
    private notifyService: NotifyService,
  ) {
    super();
    this.onSaveFn = this.onSave.bind(this);
    this.cancelFn = this.cancel.bind(this);
  }

  onNavIdChange(val) {
    if (!isNullOrUndefined(this.dynamicData)) {
      return;
    } else {
      const idKey = val['key'];
      const value = val[idKey];
      // console.log('onNavIdChange', val);
      switch (idKey) {
        case AppConstants.DEFENDANT_ID_KEY:
          if (value) {
            this.sourceId = value;
            this.parentId = value;
          } else {
            this.showDefendantSearch = true;
            // this.errorData = [{status_code: -1, detail: 'No defendant selected. Select a defendant first.'}]
          }
          // console.log('checkin defendant id', this.sourceId);
          break;
        case AppConstants.DEFENDANT_NAME_KEY:
          if (value !== '') {
            this.defendantName = value;
          }
          break;
      }
    }
  }

  onDefendantSelected(e) {
    // console.log('ondefendantselected', e);
    this.sourceId = e.DefendantId;
    this.parentId = e.DefendantId;
    this.defendantName = e.DefendantName;
    this.loadCheckinGridData();
  }

  popToast(msg) {
    this.notifyService.toast(msg, "success");
  }

  handleError(error) {
    this.saveButtonDisabled = false;
    this.errorData = error;
  }

  postSave(showToast = true) {
    if (showToast) {
      this.popToast('Saved');
    }
    this.saveButtonDisabled = false;
    this.defendantName = '';
    this.sourceId = '';
    this.parentId = '';
    this.defendantId = '';
    this.comment = '';
    this.checkinDate = StringUtilities.getCurrentDateString();
    if (this.searchBox) {
      this.searchBox.resetResult();
    }
    this.valGroup.instance.reset();

    if (this.inDynamicPopup) {
      // console.log(`onClose in checkin popup`);
      this.onClose.next(true);
    } else {
      this.buttonBar.navigate();
    }

  }

  onSave(e, valGroup) {
    if (valGroup.instance.validate().isValid) {
      this.saveButtonDisabled = true;
      this.popToast('Saving ...');
      if (!!this.existingCheckin) {
        this.existingCheckin['ScheduleDate'] = this.checkinDate;
        this.existingCheckin.Comment = this.comment;
        this.subs.sink = this.checkInService.updateCheckin(this.existingCheckin.Id, this.existingCheckin).subscribe(res => {
          this.postSave();
        }, error => {
          this.handleError(error);
        });
      } else {
        this.subs.sink = this.checkInService.recordCheckIn(this.sourceId, this.checkinDate, this.comment).subscribe(sc => {
          if (!!sc.data.ScheduleId) {
            this.postSave();
          } else {
            notify('Please create a Check In schedule first.', 'error');
            this.editCheckInSchedule();
            return;
          }
        }, error => {
          this.handleError(error);
        });
      }
    }
  }

  cancel() {
    if (this.inDynamicPopup) {
      this.onClose.next(false);
    } else {
      this.buttonBar.routeForCancel();
    }
  }


  ngOnInit() {
    // console.log(`dynamic data at check-in launch: ${this.dynamicData}`);
    // console.log(`in dynamic popup at check-in launch: ${this.inDynamicPopup}`);
    this.showDefendantSearch = true;
    if (!!this.dynamicData && AppConstants.DEFENDANT_ID_KEY in this.dynamicData) {
      this.selectedDefendantName = this.dynamicData.DefendantName;
      if (this.dynamicData[AppConstants.DEFENDANT_ID_KEY]) {
        this.showDefendantSearch = false;
        this.sourceId = this.dynamicData[AppConstants.DEFENDANT_ID_KEY];
        this.parentId = this.dynamicData[AppConstants.DEFENDANT_ID_KEY];
        if (this.dynamicData[AppConstants.DEFENDANT_NAME_KEY]) {
          this.defendantName = this.dynamicData[AppConstants.DEFENDANT_NAME_KEY];
        }
        if (!!this.dynamicData['ExistingCheckin']) {
          this.existingCheckin = this.dynamicData['ExistingCheckin'];
          this.checkinDate = this.dynamicData['ExistingCheckin'].ScheduledDate;
          this.comment = this.dynamicData['ExistingCheckin'].Comment;
        }
      }
      let boolHasCheckinSchedule = false;
      if (AppConstants.CHECK_IN_SCHEDULE in this.dynamicData) {
        const checkInSchedule = this.dynamicData[AppConstants.CHECK_IN_SCHEDULE];
        boolHasCheckinSchedule = checkInSchedule.DefendantId !== -1;
        if (boolHasCheckinSchedule) {
          this.checkinSchedule = checkInSchedule;
        }
      }
      if (!boolHasCheckinSchedule) {
        this.loadCheckinGridData();
      }
    }
  }

  // Load check-in schedule grid data
  loadCheckinGridData() {

    if (!this.defendantId) {
      this.defendantId = this.dynamicData?.[AppConstants.DEFENDANT_ID_KEY] ?? this.sourceId;
    }

    if (this.defendantId) {
      this.eventService.componentBusy(true);
      this.subs.sink = this.checkInService.getByDefendantId(this.defendantId).subscribe(resp => {
        // console.log('Check in record response: ', resp);
        if (resp.data && resp.data.details && resp.data.details.data.length > 0 ) {
          this.addEditCheckinText = 'Edit check in schedule';
          this.checkinSchedule = resp.data;
          this.checkinHistoryGridData = resp.data.details.data.filter(detail => moment(detail.ScheduledDate).isBefore(moment()) );
        } else {
          this.checkinHistoryGridData = [];
        }
        // console.log('checkin history grid', this.checkinHistoryGridData);
        this.eventService.componentBusy(false);
      }, error => {
        this.eventService.componentBusy(false);
        notify('An error occurred loading check in history, please try again', 'error');
        console.error(error);
      });
    }
  }

  // Add/Edit check-in schedule
  editCheckInSchedule() {
    // console.log('editing check in schedule', this.checkinSchedule);
    this.dynamicPopupData = [];
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = this.defendantId;
    // this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = this.defendantModel.person.getFullName();
    this.dynamicPopupTitle = ((isNullOrUndefined(this.checkinSchedule) || isNullOrUndefined(this.checkinSchedule.Id)) ?
      'Generate' : 'Edit') + ' Check In Schedule';
    this.dynamicPopupClass = CheckinscheduleComponent;
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;
  }

  // Handle dynamic popup hidden action
  dynamicPopupHidden(e) {
    this.loadCheckinGridData();
    this.dynamicPopupClass     = null;
    this.dynamicPopupData      = [];
    this.dynamicPopupTitle     = '';
    this.dynamicPopupVisible   = false;
    this.dynamicPopupShowTitle = false;
  }

   onCheckinGridToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.checkinHistoryGrid.export.fileName = 'Checkin History';
            this.checkinHistoryGrid.instance.exportToExcel(false);
          },
        },
      },
    );
  }

  navigateOnMap(data) {
    const navUrl = 'https://maps.google.com?q=' + data.Latitude + ',' + data.Longitude;
    window.open(navUrl, '_blank');
  }

}
