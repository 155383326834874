<label style="position: relative;" (mouseover)="onMouseOver()" (mouseleave)="onMouseLeave()">
  <label for="picFile">
     <img src="{{ this.imageDataUrl || imageFallback }}"
     [ngClass]="preview ? 'profile-lg2' : 'profile-xl'"
     #imagePreview
     />
     
     <i *ngIf="!preview" class="fas fa-camera fa-2x camera-button mat-elevation-z1"></i>
  </label>
  <i *ngIf="canDeleteMugshot && showDeleteButton && imageDataUrl" class="fas fa-trash fa-2x camera-button mat-elevation-z1 delete-button"
     (click)="showMugShowDeleteYesNo = true;"
  >
  </i>
</label>
<input #picFile
       *ngIf="!preview"
       id="picFile"
       type="file"
       accept="image/*"
       [attr.capture]="cameraMode"
       (change)="imageChange($event)" />


<app-yes-no-confirmation
     [confirmationMessage]="'Are you sure you want to delete this mugshot?'"
     [(showConfirmation)]="showMugShowDeleteYesNo"
     [closeOnOutsideClick]="false"
     [showCloseButton]="false"
     [maxHeight]="'156px'"
     [maxWidth]="'320px'"
     [showTitle]="false"
     [yesWidth]="90"
     [noWidth]="90"
     (onResult)="onMugshotDelete($event)"
>
</app-yes-no-confirmation>