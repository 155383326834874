import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { Payment } from '../models/payment';
import { PortoResponse } from '../models/porto-response';

@Injectable()
export class PaymentService {

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getById(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/payment/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }


  getForPaymentUI(defendantId: any, paymentId: any): Observable<Payment> {
    const data = {'DefendantId': defendantId, 'PaymentId': paymentId};
    return this.http.put<PortoResponse<Payment>>(`${this.settings.getBaseUrl()}/payment/forpaymentui`, data).pipe(
      map((res: PortoResponse<Payment> ) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getForPublicPayment(defendantId: string): Observable<Payment> {
    return this.http.get<PortoResponse<any>>(`${this.settings.getBaseUrl()}/payment/public/${defendantId}`)
      .pipe(
        map(res => res.data),
        map(res => {
          res.details = res.details.data;
          return res;
        }),
      );
  }

  getPaymentStatus(paymentScheduleDetailId: string): Observable<Payment> {
    return this.http.get<Payment>(`${this.settings.getBaseUrl()}/payment/public/status/${paymentScheduleDetailId}`)
      .pipe(
        map(res => res),
      );
  }

  save(paymentId: any, payment: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/payment/${paymentId}`, JSON.stringify(payment)).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  create(payment: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/payment`, payment).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  createForPublicPayment(payment: Payment, paymentScheduleDetailId: string, defendantId: string, name: string, isRequestPayment: boolean = false): Observable<Payment> {
    return this.http.post<PortoResponse<Payment>>(`${this.settings.getBaseUrl()}/payment/public`, {
      ...payment,
      PaymentScheduleDetailId: paymentScheduleDetailId,
      DefendantId: defendantId,
      DefendantFullName: name,
      IsRequestPayment: isRequestPayment,
    })
      .pipe(
        map(res => res.data),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
      );
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/payment/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  savePaymentMethodToken(data: any) {
    return this.http.post(`${this.settings.getBaseUrl()}/paymentmethodtokens`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  savePaymentMethodTokenClientAuth(data: any) {
    return this.http.post(`${this.settings.getBaseUrl()}/paymentmethodtokens/internal`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getPaymentDetail(paymentDetailId: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/payments/details/${paymentDetailId}`).pipe(
     map((res: any) => res),
     catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getPaymentDetailsForDef(defendantId: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/paymentdetails/${defendantId}`).pipe(
     map((res: any) => res),
     catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  assignpayment(paymentId: any, data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/assignpayment/${paymentId}`, JSON.stringify(data)).pipe(
    map((res: any) => res),
    catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  updatePaymentDetail(paymentId: any, data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/payments/details/${paymentId}`, JSON.stringify(data)).pipe(
    map((res: any) => res),
    catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

}
