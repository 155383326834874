import { take } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DxAccordionComponent, DxButtonComponent, DxContextMenuComponent, DxDataGridComponent } from 'devextreme-angular';
import { isArray, isNullOrUndefined } from 'util';
import Guid from 'devextreme/core/guid';
import { LookupService } from '../../../services/app-lookup.service';
import { Address } from '../../../models/address';
import CustomStore from 'devextreme/data/custom_store';
import { GridService } from '../../../services/grid.service';
import { AppConstants } from '../../../shared/AppConstants';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import * as parseAddress from 'parse-address';
import AutocompleteOptions = google.maps.places.AutocompleteOptions;

@Component({
  selector: 'app-smartaddress',
  templateUrl: './smartaddress.component.html',
  styleUrls: ['./smartaddress.component.scss'],
})
export class SmartaddressComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild(DxContextMenuComponent) contextMenu: DxContextMenuComponent;
  @ViewChild(DxAccordionComponent, { static: true }) addressAccordion: DxAccordionComponent;
  @ViewChild(DxButtonComponent) addressEditButton: DxButtonComponent;
  @ViewChild('mapcomplete') mapSearch: ElementRef;

  @Input() expanded: boolean;
  @Input() sourceType: string;
  @Input() hideDefault: boolean;
  @Input() hideBad: boolean;
  @Input() hideCaption: boolean;
  @Input() controlId: string;
  @Input() defaultMailTypePhysical: boolean;
  @Input() labelCss: string;
  @Input() containerStyle: string;
  @Input() model: Address[];
  @Output() modelChange = new EventEmitter<any>();
  @Input() singleAddressMode = false;
  @Input() inputDisabled = false;
  smartAddressListPopup: any;
  @Input() readOnlyField = false;
  @Input() clickDisabled = false;

  @Input() AddressCity: any;
  @Input() AddressState: any;
  @Input() AddressZip: any;
  @Input() selectedFromWizard = false;
  @Input() placeholderVisible = true;

  @Input() showMapIcon = true;
  @Output() addressChange = new EventEmitter<any>();
  @Input() fromExternal = false;
  @Input() isInvalid = false;

  contextMenuItems = ['Manual Edit', 'Show Map'];
  addressRetrieved = false;
  tmpCounty = '';
  tmpState = '';
  loadingVisible: boolean;
  addressLoadingvisible: boolean;
  showFieldsIcon = 'fal fa-plus';
  editText = 'Show Fields';
  options: AutocompleteOptions = {
    types: ['address'],
    componentRestrictions: { country: ['US', 'PR'] },
    fields: ['address_components', 'formatted_address', 'place_id'],
  };
  randomId =  new Guid().toString();

  // _addresses: any = [];
  showingFullEdit = false;
  accordionIndex = -1;
  addressContainerId: any;
  defaultIsPhysical = true;
  states: any = [];
  isStatesLoading = false;

  counties: any;
  filteredCounties: any = [];
  filteredCountyStore: CustomStore;
  isCountiesLoading = false;

  AddressTypes: any;
  addressCount = 0;
  _defaultAddress: Address;
  isDefaultAddressBad : boolean = false;

  panels = ['panel1'];

  onSaveFn: any;
  onCancelFn: any;
  confirmAddressOption = false;

  street: any;
  street_number: any;
  subpremise: any;
  city: any;
  state: any;
  district: any;
  country: any;
  postal_code: any;
  lat: any;
  lng: any;
  adr_address: any;
  name: any;
  place_id: any;
  types: any;
  url: any;
  vicinity: any;
  photos: any;
  CountryCodes: any;

  constructor(
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private gridService: GridService) {
    super();

    this.hideCaption = false;
    this.loadingVisible = false;

    this.onSaveFn = this.saveGrid.bind(this);
    this.onCancelFn = this.closePopup.bind(this);

    this.states = [];
    this.counties = [];
    this.loadStates();

    this.AddressTypes = lookupService.getLookupStatic('AddressTypes');

  }

  getDefaultAddressSingleLine() {
    if (!!this._defaultAddress) {
      let formattedAddress = '';

      if (this._defaultAddress.AddressLine1 && this._defaultAddress.AddressLine1 !== '') {
        formattedAddress = this._defaultAddress.AddressLine1;
      }

      if (this._defaultAddress.AddressLine2 && this._defaultAddress.AddressLine2 !== '') {
        formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + this._defaultAddress.AddressLine2;
      }

      if (this._defaultAddress.City && this._defaultAddress.City !== '') {
        formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + this._defaultAddress.City;
      }

      if (this._defaultAddress.StateCode && this._defaultAddress.StateCode !== '') {
        formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + this._defaultAddress.StateCode;
      }

      if (this._defaultAddress.Zip && this._defaultAddress.Zip !== '') {
        formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + this._defaultAddress.Zip;
      }
      return formattedAddress;
    }
  }

  get defaultAddress(): Address {
    // console.log('Address component: get defaultAddress(): this.model', this.model);
    if (!!this.model && isArray(this.model)) {
      // console.log('finding default addresses this.model', this.model);
      const filteredAddresses = this.model.filter(item => !item.isDeleted);
      if (filteredAddresses.length > 0) {
        this.addressCount = filteredAddresses.length;
        const defaultAddresses = filteredAddresses.filter(item => item.IsDefault === true);
        // console.log('finding default addresses defaultaddresses found', defaultAddresses);
        // was doing a comparison to true but the value will be 0 or 1. bool comparison was failing fk 20180202
        if (defaultAddresses.length > 0) {
          this._defaultAddress = defaultAddresses[0];
          this.isDefaultAddressBad = defaultAddresses[0].IsBad;

          // console.log('finding default addresses defaultaddresses returned', this._defaultAddress);
          return this._defaultAddress;
        } else {
          this._defaultAddress = new Address();
          return this._defaultAddress;
        }
      } else {
        if (this._defaultAddress === undefined) {
          this._defaultAddress = new Address();
        }
      }
    }
    // console.log('finding default addresses defaultaddresses returned', this._defaultAddress);
    // console.log('finding default addresses defaultaddresses returned', this._defaultAddress);
    return this._defaultAddress;
  }


  set defaultAddress(value: Address) {
    this._defaultAddress = value;
    if (value !== undefined) {
      this.updateDefaultAddress(value);
    } else {
      this._defaultAddress = new Address();
    }
  }


  getAddress(place: any) {
    // console.log('Address component: Calling function getAddress', place);
    this.addressLoadingvisible = true;
    this.cdr.detectChanges();

    this.defaultAddress.AddressLine1 = '';
    this.defaultAddress.AddressLine2 = '';
    this.defaultAddress.AddressType = 'H';
    this.defaultAddress.City = '';

    for (const ind in place.address_components) {
      if (place.address_components.hasOwnProperty(ind)) {
        const placeProp = place.address_components[ind];
        switch (placeProp.types[0]) {
          case 'street_number': {
            this.defaultAddress.AddressLine1 = placeProp.short_name;
            break;
          }
          case 'route': {
            this.defaultAddress.AddressLine1 += ' ' + placeProp.long_name;
            break;
          }
          case 'locality': {
            this.defaultAddress.City = placeProp.long_name;
            break;
          }
          case 'sublocality': {
            if (isNullOrUndefined(this.defaultAddress.City)) {
              this.defaultAddress.City = placeProp.long_name;
            }
            break;
          }
          case 'administrative_area_level_1': {
            this.defaultAddress.StateCode = placeProp.short_name;
            break;
          }
          case 'administrative_area_level_2': {
            this.defaultAddress.County = placeProp.short_name.toString().replace(' County', '');
            break;
          }
          case 'postal_code': {
            this.defaultAddress.Zip = placeProp.short_name;
            break;
          }
          case 'administrative_area_level_3': {
            if (this.defaultAddress.City.length === 0) {
              this.defaultAddress.City = placeProp.long_name;
            }
            break;
          }
        }
      }
    }
    // now lets load the lookups for the given addresses
    // lets check if the selected google addres info is not already loaded in our lists, if not then trigger the loads
    // this is use-full for when ading google addresses in the same state/county etc
    this.filteredCounties = this.filterCounties(this._defaultAddress.StateCode);
    if (this.filteredCounties.length === 0) {
      this.isCountiesLoading = true;
      this.cdr.detectChanges();
    } else {
      this.isCountiesLoading = false;
      this.cdr.detectChanges();
    }

    this.addressLoadingvisible = false;
    this.cdr.detectChanges();


    this.updateDefaultAddress(this._defaultAddress);
  }

  ngOnInit() {
    // console.log('Address component: Calling function ngOnInit');
    if (isNullOrUndefined(this.controlId)) {
      this.controlId = 'smartaddress';
    }

    this.addressContainerId = { of: `#${this.controlId}` };

    if (!isNullOrUndefined(this.expanded) && this.expanded === true) {
      this.accordionIndex = 0;
    } else {
      this.accordionIndex = -1;
    }

    if (isNullOrUndefined(this.labelCss)) {
      this.labelCss = 'col-md-3 control-label';
    } else {
      this.labelCss = this.labelCss + ' control-label';
    }

    if (isNullOrUndefined(this.containerStyle)) {
      this.containerStyle = '';
    }

    if (isNullOrUndefined(this.model)) {
      this.model = [];
    }

    // this.addresses = this.model;

    if (isNullOrUndefined(this.defaultMailTypePhysical)) {
      this.defaultIsPhysical = true;
    } else {
      this.defaultIsPhysical = this.defaultMailTypePhysical;
    }

    this._defaultAddress = new Address();
  }

  resetForm() {
    // console.log('Address component: Calling function resetForm');
    if (!isNullOrUndefined(this.model)) {
      this.model.length = 0;
      this.addressCount = 0;
      this._defaultAddress = new Address();
      this.modelChange.emit(this.model);
    }
  }

  saveGrid(close) {
    // console.log('Address component: Calling function saveGrid');
    this.dataGrid.instance.saveEditData();
    if (close) {
      this.smartAddressListPopup = false;
    }
    this.modelChange.emit(this.model);
    this.addressChange.emit(this.model);
  }

  onCellStateChange = (newData, value, currentRowData) => {
    newData.StateCode = value;
  }

  onCellCityChange = (newData, value, currentRowData) => {
    newData.City = value;
  }

  onCellZipCodeChange = (newData, value, currentRowData) => {
    newData.Zip = value;
  }

  fullAddressEdit() {
    // console.log('Address component: Calling function fullAddressEdit');
    if (this.showingFullEdit) {
      this.addressAccordion.visible = false;
      this.editText = 'Show Fields';
      this.showFieldsIcon = 'fal fa-plus';
    } else {
      this.addressAccordion.visible = true;
      this.addressAccordion.instance.expandItem(0);
      this.editText = 'Hide Fields';
      this.showFieldsIcon = 'fal fa-minus';
    }

    this.showingFullEdit = !this.showingFullEdit;
  }

  titleClick(e) {
    let proceed = false;
    e.event.target.classList.forEach(item => {
      if (item === 'dx-accordion-item-title') {
        proceed = true;
      }
    });

    if (!proceed) {
      e.event.stopPropagation();
    }
  }

  onChangeDefault = (newData, value, currentRowData) => {
    this.toggleDefault(currentRowData.Id);
    if (!!currentRowData.Id && value) {
      this.setDefaultAddress(currentRowData.Id);
    } else {
      return false;
    }
  }

  setDefaultAddress(id) {
    const address = this.model.filter(item => !item.isDeleted && item.Id === id);
    if (address.length) {
      // console.log('setting default address to ', address);
      address[0].IsDefault = true;
    } else {
      return false;
    }
  }

  loadStates() {
    this.isStatesLoading = true;
    this.filteredCounties.length = 0;
    if (this.states.length === 0) {
      let st  = this.lookupService.getLookupStatic('states');
      this.states = st.sort((a : any, b : any) => {
        if (a.Text > b.Text) {
          return 1;
        } else if (a.Text < b.Text) {
          return -1;
        }
        return 0;
      });
      this.isStatesLoading = false;
    } else {
      this.isStatesLoading = false;
    }
  }

  showLoadingGrid(text) {
    // console.log('Address component: Calling function showLoadingGrid');
    if (this.dataGrid !== undefined) {
      this.dataGrid.instance.beginCustomLoading(text);
    }
  }

  hideLoadingGrid() {
    // console.log('Address component: Calling function hideLoadingGrid');
    if (this.dataGrid !== undefined) {
      this.dataGrid.instance.endCustomLoading();
    }
  }

  sort(obj) {
    // console.log('Address component: Calling function sort');
    return obj.sort((a, b) => {
      if (a.Text > b.Text) {
        return 1;
      } else if (a.Text < b.Text) {
        return -1;
      }
      return 0;
    });
  }

  filterCounties(pState) {
    // console.log('Address component: Calling function filterCounties');
    return this.counties.filter(item => item.StateCode === pState).sort((a, b) => {
      if (a.Text > b.Text) {
        return 1;
      } else if (a.Text < b.Text) {
        return -1;
      }
      return 0;
    });
  }

  initNewAddress(addr: any) {
    // console.log('Address component: Calling function initNewAddress');
    const address = new Address();
    if (!!addr) {
      address.AddressLine1 = addr.AddressLine1;
      address.AddressLine2 = addr.AddressLine2;
      address.City = addr.City;
      address.County = addr.County;
      address.StateCode = addr.StateCode;
      address.Zip = addr.Zip;
      address.IsBad = false;
      address.IsDefault = true;
      address.IsPhysical = this.defaultIsPhysical;
      address.AddressType = addr.AddressType;
      address.Id = new Guid().toString();
      address.isAdded = true;
    }
    return address;
  }


  // methods for address form lookups
  countyChanged(data) {
    this._defaultAddress.isDirty = true;
    this._defaultAddress.County = data.value;
    this.updateDefaultAddress(this.defaultAddress);
  }

  stateChanged(data) {
    this._defaultAddress.isDirty = true;
    this._defaultAddress.StateCode = data.value;
    this.defaultAddress.StateCode = data.value;
    this.defaultAddress.County = '';
    this.filteredCounties.length = 0;
    this.updateDefaultAddress(this.defaultAddress);
  }

  cityChanged(data) {
    // console.log('Address component: Calling function cityChanged');
    this._defaultAddress.isDirty = true;
    this.updateDefaultAddress(this.defaultAddress);
  }

  // END -- methods for address form lookups

  showAddressOnMap() {
    // console.log('Address component: Calling function showAddressOnMap');
    const navUrl = 'https://maps.google.com?q=' + this.getDefaultAddressSingleLine().replace(' ', '+');
    window.open(navUrl, '_blank');
  }

  showCurrentAddressOnMap(e) {
    // console.log('Address component: Calling function showCurrentAddressOnMap');
    const cAddress = new Address();
    cAddress.fillFromObject(e.data);
    const singleLine = cAddress.singleLineAddress();
    const navurl = 'https://maps.google.com?q=' + singleLine.replace(' ', '+');
    window.open(navurl, '_blank');
  }

  addressListPopup(e) {
    // console.log('addresses: ', this.model);
    this.smartAddressListPopup = true;
  }

  cancelAddress() {
    // console.log('Address component: Calling function cancelAddress');
    this.dataGrid.instance.cancelEditData();
    // console.log('in cancelAddress defaultAddress: ', this.defaultAddress);
    const filteredAddresses = this.model.filter(item => item.IsDefault && !item.isDeleted);
    // console.log('defaultAddress length: ', filteredAddresses.length);
    if (filteredAddresses.length === 0) {
      // console.log('total addresses length: ', this.model.length);
      if (this.model.length >= 1) {
        // console.log('Setting first address as defaultAddress!');
        this.model[0].IsDefault = true;
      }
    } else if (filteredAddresses.length > 0) {
      this.model.forEach(function (address) {
        address.currentState += '.dirty';
        address.isDirty = true;
        address.IsDefault = false;
      });
      filteredAddresses[0].IsDefault = true;
      // console.log('found multiple default addresses', this.model);
    }
  }

  closePopup(e) {
    this.smartAddressListPopup = false;
  }

  // update default addresss from grid
  updateDefaultAddress(newAddress) {
    // console.log('Address component: Calling function updateDefaultAddress');
    if (newAddress !== '' && !newAddress.isAdded) {
      newAddress.isDirty = true;
    }

    if (this.model === undefined) {
      this.model = [];
    }

    if (this.model.length > 0) {
      const filteredAddresses = this.model.filter(item => item.IsDefault && !item.isDeleted);
      if (filteredAddresses.length !== 0) {
        filteredAddresses[0] = newAddress;
      }
    } else {
      if (newAddress !== '') {
        this.model.push(this.initNewAddress(newAddress));
      } else {
        this._defaultAddress = new Address();
      }
    }
    // console.log('after updating default address, this.model', this.model);
    this.addressChange.emit(true);
    this.filterActiveRows();
  }


  filterActiveRows() {
    if (this.dataGrid !== undefined) {
      this.dataGrid.instance.filter(['!', ['isDeleted', '=', true]]);
      this.dataGrid.instance.refresh();
    }
  }

  toggleDefault(newDefaultKey) {
    // console.log('Address component: Calling function toggleDefault');
    const filteredAddresses = this.model.filter(item => !item.isDeleted && item.IsDefault);
    // console.log(filteredAddresses);
    if (filteredAddresses.length > 0) {
      filteredAddresses.forEach((o) => {
        o.IsDefault = false;
      });
    }
  }

  onCellPrepared(e) {
    this.gridService.updateGridIcons(e);
  }

  onEditorPreparing(e) {
    if (e.dataField === 'AddressLine1' || e.dataField === 'AddressLine2') {
      e.editorElement.maxLength = 128;
    }
  }

  onInitNewRow(e) {
    // console.log('Address component: Calling function onInitNewRow', e);
    const activeAddresses = this.model.filter(item => !item.isDeleted);
    const defaultAddresses = activeAddresses.filter(item => item.IsDefault);

    e.data.Id = new Guid().toString();
    e.data.IsBad = false;
    e.data.isDeleted = false;
    e.data.IsPhysical = this.defaultIsPhysical;
    e.data.AddressType = 'H';
    e.data.isAdded = true;
    if (this.selectedFromWizard) {
      e.data.StateCode = localStorage.getItem(AppConstants.WIZARD_DEFAULT_STATE);
      e.data.County = localStorage.getItem(AppConstants.WIZARD_DEFAULT_COUNTY);
    }

    e.data.IsDefault = defaultAddresses.length === 0;

    this.filterActiveRows();
    // console.log('Address component: onInitNewRow: this.model', this.model);
  }

  onRowInserted(e) {
    // console.log('Address component: Calling function onRowInserted');
    this.filterActiveRows();
    // console.log('Address component: onRowInserted: this.model', this.model);
  }

  onRowInserting(e) {
    // console.log('Address component: Calling function onRowInserting');
    if (e.data.IsDefault === true) {
      this.toggleDefault(e.key);
    }
    const newAddress = new Address(e.data);
    this.model.push(newAddress);
    this.dataGrid.instance.cancelEditData();
    this.filterActiveRows();
    e.cancel = true;
    // console.log('Address component: onRowInserting: this.model', this.model);
  }

  onRowUpdating(e) {
    if (e.newData.IsDefault === true) {
      this.toggleDefault(e.key);
    }
    e.newData.isDirty = true;
    const activeAddresses = this.model.filter(item => !item.isDeleted);
    const defaultAddresses = activeAddresses.filter(item => !item.IsDefault);
    this.addressCount = defaultAddresses.length;

    if (defaultAddresses.length === 0) {
      this._defaultAddress = new Address();
    }

    this.filterActiveRows();
    // console.log('Address component: onRowUpdating: this.model', this.model);
  }

  removeAddress(id) {
    // console.log('Address component: Calling function removeAddress: ', id);
    let i = this.model.length;
    // console.log('Total addresses: ', this.model.length);
    while (i--) {
      // console.log('Processing address: ', this.model[i]);
      if (this.model[i].Id === id) {
        // console.log('Address found to remove!');
        this.model.splice(i, 1);
      }
    }
    // console.log('Total updated addresses: ', this.model.length);
  }

  onRowRemoving(e) {
    // console.log('Address component: Calling function onRowRemoving: ', e);

    e.data.isDeleted = true;
    e.data.isDirty = false;

    const searchResult = this.model.find(item => item.Id === e.key);
    searchResult.isDeleted = true;
    if (!!e.data.isAdded) { // If this one is newly added then delete action should remove it permanently
      this.removeAddress(e.data.Id);
    }
    searchResult.isAdded = false;

    const activeAddresses = this.model.filter(item => !item.isDeleted);
    const defaultAddresses = activeAddresses.filter(item => !item.IsDefault);
    this.addressCount = defaultAddresses.length;

    if (defaultAddresses.length === 0) {
      this._defaultAddress = new Address();
    }

    this.filterActiveRows();
    e.cancel = true;
    // console.log('Address component: onRowRemoving: this.model', this.model);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(`Address info changed: `, changes);
    if (changes.model !== undefined && changes.model.currentValue !== undefined) {
      this.model = changes.model.currentValue;

      // #1040 contact address getting copied if adding more than one contact in defendant wizard
      if (!!this.model) {
        this.addressCount = this.model.length;
        if (this.addressCount > 0) {
          this.defaultAddress = this.model[0];
        } else {
          this.updateDefaultAddress('');
        }
      }
    }
  }

  addressItemChanged(e) {
    if (e.value !== undefined) {
      if (this._defaultAddress !== undefined) {
        this._defaultAddress.isDirty = true;
        this.updateDefaultAddress(this._defaultAddress);
      }
    } else {
      if (e.component.option('text') !== undefined && e.component.option('text') !== '') {
        this._defaultAddress.isDirty = true;
        this.updateDefaultAddress(this._defaultAddress);
      }
    }
  }

  addressExists(address) {
    // console.log('Address component: Calling function addressExists: this.model', this.model);
    let addressExists = false;
    if (!!address) {
      for (let i = 0; i < this.model.length; i++) {
        const checkAddr = new Address();
        checkAddr.fillFromObject(this.model[i]);
        // console.log('Processing address: ', (checkAddr.singleLineAddress()));
        if (checkAddr.singleLineAddress() === address.singleLineAddress()) {
          // console.log('Address found!');
          addressExists = true;
        }
      }
    }
    return addressExists;
  }

  addressChanged(e) {
    if (!!e.target.value) {
      let addNew = false;
      let address = this.model.find(a => a.IsDefault);
      if (address) {
        this.splitAddressParts(address, e.target.value);
      } else {
        addNew = true;
      }
      if (addNew) {
        address = new Address();
        this.splitAddressParts(address, e.target.value);
        address.IsDefault = true;
        address.isAdded = true;
        address.isValid = true;
        address.IsPhysical = this.defaultIsPhysical;
        address.Id = new Guid().toString();
        // console.log('New address: ', address);
        if (!this.addressExists(address)) {
          this.model.push(address);
        }
        this.toggleDefault('');
        this.model[this.model.length - 1].IsDefault = true;
      }
    }
  }
  splitAddressParts(address: Address, addressValue: any) {
    const parsedAddr = parseAddress.parseLocation(addressValue);
    // Check the other three fields here
    parsedAddr.number = !!parsedAddr.number ? parsedAddr.number : '';
    parsedAddr.street = !!parsedAddr.street ? parsedAddr.street : '';
    parsedAddr.type = !!parsedAddr.type ? parsedAddr.type : '';
    parsedAddr.sec_unit_num = !!parsedAddr.sec_unit_num ? parsedAddr.sec_unit_num : '';
    parsedAddr.sec_unit_type = !!parsedAddr.sec_unit_type ? parsedAddr.sec_unit_type : '';

    if (!!parsedAddr.prefix) {
      address.AddressLine1 = parsedAddr.number + ' ' + parsedAddr.prefix + ' ' + parsedAddr.street + ' ' + parsedAddr.type;
    } else {
      address.AddressLine1 = parsedAddr.number + ' ' + parsedAddr.street + ' ' + parsedAddr.type;
    }
    if (!!parsedAddr.city) {
      address.City = parsedAddr.city;
    } else {
      address.City = '';
    }
    if (!!parsedAddr.state) {
      address.StateCode = parsedAddr.state.toUpperCase();
    } else {
      address.StateCode = '';
    }
    if (!!parsedAddr.zip) {
      address.Zip = parsedAddr.zip;
    } else {
      address.Zip = '';
    }

    if(!!parsedAddr.sec_unit_num || !!parsedAddr.sec_unit_type) {
      if(!!parsedAddr.sec_unit_type) {
        address.AddressLine2 = parsedAddr.sec_unit_type + ' ' + parsedAddr.sec_unit_num;
      } else {
        address.AddressLine2 = parsedAddr.sec_unit_num;
      }
    }
  }

  onItemRendered(e) {
    if (e.itemIndex === 0) {
      e.itemElement.find('#mapcomplete').prop('autocomplete', 'new-google-address');
      // console.log('item rendered', e.itemElement.find('#mapcomplete'));
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach(function (item) {
      item.location = 'after';
      item.showText = 'always';
      // console.log(item);
      if (item.name === 'addRowButton') {
        item.options.text = 'Add New Address';
        item.options.icon = 'fal fa-plus';
        item.options.type = 'default';
        item.location = 'before';
      }
    },
    );

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.gridService.exportGrid(this.dataGrid, 'Addresses');
          },
        },
      },
    );
  }

  // Get the index of default number from the phones array and return the index value
  getDefaultAddressIndex() {
    return this.model.indexOf(this.defaultAddress);
  }

  // Delete the default number and remove the same from the default
  deleteAndRemoveFromDefault() {
    const defaultAddressIndex = this.getDefaultAddressIndex();
    if (defaultAddressIndex > -1) {
      this.model[defaultAddressIndex].IsDefault = false;
      this.model[defaultAddressIndex].isDeleted = true;
      this.model[defaultAddressIndex].isDirty = true;
      this.filterActiveRows();
      this.updateAddressCount();

      if(this.addressCount > 0) {
        const filteredAddresses = this.model.filter(item => !item.isDeleted && !item.IsDefault && !item.IsBad);
        if(filteredAddresses.length > 0) {
          filteredAddresses[0].IsDefault = true;
        }
      }
    }
  }

  // update phone count, call anytime to update count number after the phone editor
  updateAddressCount() {
    this.addressCount = this.model.filter(item => !item.isDeleted).length;
  }
  
  onFocusOut(e) {
    if (!e.target.value.trim('')) { // #
      this.deleteAndRemoveFromDefault();
    }
  }
}
