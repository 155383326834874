import {BaseModel} from './BaseModel';
import * as moment from 'moment';

export class CashCollateralPaymentScheduleDetail extends BaseModel {

  Id: number;
  CashCollateralPaymentScheduleId: number;
  ScheduledDate: any;
  ScheduledAmount: number;
  PaidDate: any;
  PaidAmount: number;
  ReceiptNumber: string;

  constructor(obj?: object) {
    super(obj);

    if (obj == null) {
      this.CashCollateralPaymentScheduleId = -1;
      this.ScheduledDate = moment().format('YYYY-MM-DD');
      this.ScheduledAmount = 0.0;
    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);
  }
}
