import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateService implements OnDestroy {

  private history = [];
  private subscription: Subscription;

  constructor(private router: Router) {}

  loadRouting() {
    this.subscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects.split('#')[0]];
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 1] || '/dashboard';
  }
}
