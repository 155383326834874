import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {PortoResponse} from '../models/porto-response';
import {catchError, map} from 'rxjs/operators';
import {SettingsService} from './settings.service';
import {AffiliateLink} from '../models/affiliatelink';

@Injectable()
export class AffiliateService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  getAffiliateLinksBySource(source: string, fromExternal: boolean = false): Observable<AffiliateLink[]> {
    if(!fromExternal) {
      return this.http.get<PortoResponse<AffiliateLink[]>>(`${this.settings.getBaseUrl()}/affiliatelinks/${source}`).pipe(
        map((res) => res.data),
        catchError(error => throwError(error.error || 'Server Error')));
      } else {
      return this.http.get<PortoResponse<AffiliateLink[]>>(`${this.settings.getBaseUrl()}/affiliatelinks-external/${source}`).pipe(
        map((res) => res.data),
        catchError(error => throwError(error.error || 'Server Error')));
    }
  }

  saveAffiliateLinkClick(data: AffiliateLink, fromExternal = false): Observable<any> {
    if(!fromExternal) {
      return this.http.post<any>(`${this.settings.getBaseUrl()}/affiliatelinks/save-click`, data).pipe(
        map((response) => {
          return response;
        }),
        catchError(error => throwError(error.error || 'Server Error')),
      );
    } else {
      return this.http.post<any>(`${this.settings.getBaseUrl()}/affiliatelinks-external/save-click`, data).pipe(
        map((response) => {
          return response;
        }),
        catchError(error => throwError(error.error || 'Server Error')),
      );
    }
  }
}
