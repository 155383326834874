import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss'],
})
export class UpsellComponent implements OnInit {

  constructor(
    public sbRef: MatSnackBarRef<UpsellComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

}
