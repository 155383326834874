import { combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import { filter, pairwise, take } from 'rxjs/operators';
import { ActivatedRoute, Event, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Component, ComponentFactoryResolver, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { EventService } from '../../services/event.service';
import { DefendantService } from '../../services/defendant.service';
import { Defendant } from '../../models/defendant';
import { LookupService } from '../../services/app-lookup.service';
import { GenericsaverService } from '../../services/genericsaver.service';
import { DefendantSaver } from '../../shared/defendant-saver';
import { RouteDataService } from '../../services/routedata.service';
import { AppConstants } from '../../shared/AppConstants';
import { BailComponent } from './bail/bail.component';
import { SystemConfigService } from '../../services/systemconfig.service';
import { DocumentService } from '../../services/document.service';
import { ContactService } from '../../services/contact.service';
import { DefendantwatchComponent } from '../common/defendantwatch/defendantwatch.component';
import { CommunicationsCenterComponent } from '../communications-center/communications-center.component';
import { WebsiteBondingAccessComponent } from '../website-bonding-access/website-bonding-access.component';
import { DefendantAuditHistoryComponent } from '../defendant-audit-history/defendant-audit-history.component';
import { DefendantFormsComponent } from '../defendant-forms/defendant-forms.component';
import { AdisettingsComponent } from '../adisettings/adisettings.component';
import { MobileCheckinAppComponent } from '../mobile-checkin-app/mobile-checkin-app.component';
import { AdiCallLogComponent } from '../adi-call-log/adi-call-log.component';
import { DatamartComponent } from '../datamart/datamart.component';
import { CheckinComponent } from '../checkin/checkin.component';
import { VehicleWatchComponent } from '../vehicle-watch/vehicle-watch.component';
import { IDynamicPopupComponent } from '../../components/common/dynamic-popup/dynamic-popup.component';
import Guid from 'devextreme/core/guid';
import { DefendantNotesPopupComponent } from './defendantnotes/defendantnotespopup/defendantnotespopup.component';
import { BillingService } from '../../services/billing.service';
import { DxTabPanelComponent, DxToolbarComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { LocationHistoryComponent } from '../location-history/location-history.component';
import { UserService } from '../../services/user.service';
import { AccountingComponent } from './accounting/accounting.component';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { CanLeave } from '../../services/can-deactivate.service';
import { BondService } from '../../services/bondservice.service';
import { NotifyService } from '../../common/notify/notify.service';
import * as moment from 'moment';
import { PdfViewerComponent } from '../../components/common/pdf-viewer/pdf-viewer.component';
import { Store } from '@ngrx/store';
import { selectMessages } from '../../state/selectors/message.selectors';
import { loadMessages } from '../../state/actions/messages.actions';
import { selectDefendant } from '../../state/selectors/defendant.selectors';
import { Contact } from '../../models/contact';
import { Document } from '../../models/document';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DefendantRapSheetComponent } from './defendant-rap-sheet/defendant-rap-sheet.component';
import { QuickActionEvent } from './quick-actions/quick-actions.component';
import { ReportType } from '../../services/datamart.service';
import { Location } from '@angular/common';
import { QuickAction } from './quick-actions-preview/quick-actions-preview.component';
import { SmsHistoryComponent } from '../adiv2/common/reminderhistory/sms-history/sms-history.component';
import { ConfirmationComponent } from '../../components/controls/confirmation/confirmation.component';
import { DefendantNotesDialogComponent } from './defendantnotes/defendant-notes-dialog/defendant-notes-dialog.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DefendantPersonalComponent } from './defendantpersonal/defendantpersonal.component';
import {Note} from '../../models/note';


@Component({
  selector: 'app-defendant',
  templateUrl: './defendant.component.html',
  styleUrls: ['./defendant.component.css'],
  entryComponents: [
    DefendantAuditHistoryComponent,
    CheckinComponent,
    DefendantFormsComponent,
    DefendantwatchComponent,
    VehicleWatchComponent,
    CommunicationsCenterComponent,
    MobileCheckinAppComponent,
    AdiCallLogComponent,
    AdisettingsComponent,
    DatamartComponent,
    WebsiteBondingAccessComponent,
    DefendantNotesPopupComponent,
    LocationHistoryComponent,
    PdfViewerComponent,
  ],
})
export class DefendantComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy, CanLeave {
  @ViewChild('TransactionControl', { static: true }) transactionControl: BailComponent;
  @ViewChild('vcRef', { read: ViewContainerRef }) vcRef: ViewContainerRef;
  @ViewChild('actionToolbar') actionToolbar: DxToolbarComponent;
  @ViewChild('tabPanel', { static: true }) tabPanel: DxTabPanelComponent;
  @ViewChild('defendantValidationGroup', { static: false }) defendantValidationGroup: DxValidationGroupComponent;
  @ViewChild(SmsHistoryComponent, { static: false }) smsHistoryComponent: SmsHistoryComponent;
  @ViewChild(DefendantPersonalComponent) defendantPersonalComponent: DefendantPersonalComponent;

  // @Output() plusMode = new EventEmitter<boolean>();
  subscribedToDefendantManager: boolean;
  subscribedToBailManagement: boolean;

  customerId: any;
  tabs: DefendantTab[];
  defendantModel: Defendant;
  defendantId: string;
  saveButtonDisabled = false;

  defendantHasChanges = false;
  methodToCallAfterSave: any;
  // error variables
  errorTitle = 'There were errors';
  errorData: any[];
  errorVisible = false;
  selectedNavRoute = '';
  popupVisible: any;
  popupTitle: any;
  popupWidth = 'auto';
  popupMinHeight = 'auto';
  popupMaxHeight = 'auto';
  popupMaxWidth = 'auto';
  // other variables
  systemConfig: any;
  showDefendantYesNo = false;

  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData = [];
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  dynamicPopupMaxHeight = AppConstants.DEFAULTPOPUPCONFIG.MAXHEIGHT;
  dynamicPopupMaxWidth = AppConstants.DEFAULTPOPUPCONFIG.MAXWIDTH;
  dynamicPopupDragEnabled = AppConstants.DEFAULTPOPUPCONFIG.DRAGENABLED;
  showSubscribeConfirmation = false;

  messageTypes: any = [];
  messages: any = [];

  editingEnabled = false;
  anchorToComponent: string;
  anchorInformation: any = {};

  // staticDefendantModel = new Defendant();
  defendantInit: Boolean = false;
  showLeavingYesNo: Boolean = false;
  leavingPath = '';
  lastScrollPosition: any;
  hasScrolled = false;
  bondWarningsFlag = false;
  selectedTabIndex = 0;
  afterSaveMethodData: any;
  afterSaveMethodType: any;
  collateralToSelectForSchedule: any;
  confirmationMessageImportant = '';
  position: any = { my: 'center', at: 'center', of: 'window' }
  height: string = '80vh'; // default value of dx-popup
  inSlideOut= false;
  openCheckinHistory= false;

  isMobile: boolean;
  isExtraSmall: Observable<BreakpointState>;

  disableCanDeactivate: boolean = false;
  bondCount: number;

  saveCompleted$ = new Subject<void>();

  constructor(
    private eventService: EventService,
    private router: Router,
    private route: ActivatedRoute,
    private defendantService: DefendantService,
    private lookupService: LookupService,
    private location: Location,
    private saverService: GenericsaverService,
    private routeDataService: RouteDataService,
    private configService: SystemConfigService,
    private documentService: DocumentService,
    private billingService: BillingService,
    private contactService: ContactService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private userService: UserService,
    private bondService: BondService,
    private notification: NotifyService,
    private store: Store<{ messages }>,
    private dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.eventService.componentBusy(true);
    this.defendantModel = new Defendant();
    this.subs.sink = this.billingService.getBondCount().subscribe(count => {
      this.bondCount = count;
    });
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        // console.log('previous url', events[0].urlAfterRedirects);
        // console.log('current url', events[1].urlAfterRedirects);
      });
    this.tabs = [
      { text: 'Personal', icon: 'user', template: 'tabDefendant', disabled: false },
      { text: 'Background', icon: 'user', template: 'tabBackground', disabled: false },
      { text: 'Extended', icon: 'user', template: 'tabExtended', disabled: false },
      { text: 'Prior Arrests', icon: 'user', template: 'tabPriorArrest', disabled: false },
      { text: 'Defendant Watch', icon: 'user', template: 'tabDefendantWatch', disabled: false },
      { text: 'Messages', icon: 'user', template: 'tabDefendantReminders', disabled: true },
      { text: 'Alerts', icon: 'user', template: 'tabDefendantMessages', disabled: false },
      { text: 'Monitoring', icon: 'user', template: 'tabDefendantMonitoring', disabled: false },
    ];

    this.subs.sink = this.billingService
      .IsSubscribedToProduct(AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER)
      .subscribe((response: boolean) => {
        this.subscribedToDefendantManager = response;
      });

    this.messageTypes = this.lookupService.getLookupStatic('DefendantMessageTypes');

    this.subs.sink = this.eventService.disableSaveEvent$.subscribe((event: boolean) => {
      this.disableSave(event);
    });
    this.eventService.openCheckinHistory$.subscribe(() => {
      this.openCheckinHistory = true;
    });
    this.subs.sink = this.eventService.switchAccountCalled$.subscribe(() => {
      this.canSwitchAccount().subscribe(result => {
          if (result) {
            this.eventService.emitSwitchAccount();
          }
        });
    });
  }

  onSelected(action: QuickAction<QuickActionEvent>) {
    this.position = { my: 'center', at: 'center', of: 'window' };
    this.height = '80vh';
    this.popupWidth = 'auto';
    this.popupMinHeight = 'auto';
    this.popupMaxHeight = 'auto';
    let transactionId = this.defendantModel.transactions[0].Id;
    if (action.text !== 'Defendant') {
      let componentFactory: any;
      this.popupTitle = '';
      this.dynamicPopupVisible = false;

      switch (action.value) {
        case QuickActionEvent.AUDITHISTORY:
          this.openAuditHistory();
          return;
        case QuickActionEvent.DATAMART:
          this.popupTitle = 'Arrests, Backgrounds & Credit';
          this.popupMaxHeight = '650px';
          this.popupVisible = true;
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            DatamartComponent,
          );
          break;
        case QuickActionEvent.FORMS:
            transactionId = this.routeDataService.getValue(AppConstants.TRANSACTION_ID_KEY);
            this.popupTitle = 'Populate PDF Forms';
            this.popupVisible = true;
            this.position = { my: 'top', at: 'top', offset: '0 8' };
            this.height = '98vh';
            componentFactory = this.componentFactoryResolver.resolveComponentFactory(
              DefendantFormsComponent,
            );
            break;
        case QuickActionEvent.NOTE:
          this.addNote();
          return;
        case QuickActionEvent.CHECKIN:
          this.popupTitle =
            'Check In History - ' + this.defendantModel.person.getFullName();
          this.popupVisible = true;
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            LocationHistoryComponent,
          );
          this.popupWidth = '95%';
          this.popupMinHeight = '90%';
          this.popupMaxHeight = '100%';
          break;
        case QuickActionEvent.DELETE:
          // ADP 20220217 Check transaction count to setup message
          if (this.defendantModel.accountingdetails.length > 0) {
            this.confirmationMessageImportant = 'Deleting this defendant will also delete all payments!';
          } else {
            this.confirmationMessageImportant = '';
          }
          this.showDefendantYesNo = true;
          return;
        case QuickActionEvent.RAPSHEET:
          this.openRapSheet();
          return;
        case QuickActionEvent.DOCUMENTS:
          if (this.defendantPersonalComponent) {
            this.defendantPersonalComponent.addDocuments();
          }
          return;
      }
      setTimeout(() => {
        if (!!this.vcRef) {
          this.vcRef.clear();
        }
        const ref = this.vcRef.createComponent(componentFactory);
        (<any>ref.instance).defendantId = this.defendantModel.Id;
        const contacts: Contact[] = [];
        if (action.text === 'Forms') {
          // Set the defendant
          (<any>ref.instance).defendant = this.defendantModel;
          (<any>ref.instance).inDialog = true;
          this.defendantModel.transactions.map(transaction => {
            if (transactionId === transaction.Id) {
              transaction.contacts.map(contact => {
                if (contact.Type === 'Indemnitor') {
                  contacts.push(contact);
                }
              });
            }
          });
          (<any>ref.instance).contacts = contacts;
        }
        (<any>ref.instance).inDynamicPopup = true;
        (<any>ref.instance).hideHeader = true;
        if (action.text === 'Check In History' || action.text === 'Check-In History' || action.text === 'Check Ins') {
          (<any>ref.instance).defendantModel = this.defendantModel;
          (<any>ref.instance).showCheckinHistory = true;
        }

        if (action.value === QuickActionEvent.DATAMART) {
          // Set the defendant
          (<any>ref.instance).defendant = this.defendantModel;
          (<any>ref.instance).doNotPopulateFromRecord = false;
          switch (action.text) {
            case 'Arrest History':
              (<any>ref.instance).selectedReportType = ReportType.Incarceration;
              break;
            case 'Background Checks':
              (<any>ref.instance).selectedReportType = ReportType.General;
              break;
            case 'Credit & FICO Check':
              (<any>ref.instance).selectedReportType = ReportType.Credit;
              break;
          }
        }
      }, 100);
    }
  }

  dynamicPopupHidden($event) {
    this.dynamicPopupMaxHeight = AppConstants.DEFAULTPOPUPCONFIG.MAXHEIGHT;
    this.dynamicPopupMaxWidth = AppConstants.DEFAULTPOPUPCONFIG.MAXWIDTH;
    this.dynamicPopupDragEnabled = AppConstants.DEFAULTPOPUPCONFIG.DRAGENABLED;
    this.dynamicPopupClass = null;
  }

  openAuditHistory() {
    this.dynamicPopupClass = DefendantAuditHistoryComponent;
    this.dynamicPopupTitle = 'Audit History';
    this.dynamicPopupData = [];
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = this.defendantId;
    this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = this.defendantModel.person.getFullName();
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;
  }

  openRapSheet() {
    // console.log(this.defendantModel);
    this.dialog.open(DefendantRapSheetComponent, {
      data: {
        defendant: this.defendantModel,
      },
      panelClass: 'rap-sheet-dialog',
      hasBackdrop: true,
      height: '16%',
      width: '10%',
    });

    // this.dynamicPopupClass = PdfViewerComponent;
    // this.dynamicPopupTitle = 'Print Rap Sheet';
    //
    // const fieldValue = new FieldValues();
    // fieldValue.field = 'DefendantID';
    // fieldValue.hashedValues = [this.defendantModel.Id];
    //
    // this.dynamicPopupData = [];
    // this.dynamicPopupData['reportCategory'] = 'Rap Sheet';
    // this.dynamicPopupData['reportFieldValues'] = fieldValue;
    // this.dynamicPopupData['objectTypeName'] = 'rap sheet';
    // this.dynamicPopupData['sourceType'] = 'Defendant';
    // this.dynamicPopupData['sourceId'] = this.defendantModel.Id;
    // this.dynamicPopupData['objectName'] = 'Defendant Rap sheet';
    // this.dynamicPopupVisible = true;
    // this.dynamicPopupShowTitle = true;
  }

  addNote() {
    const config: MatDialogConfig = {
      data: {
        defendant: this.defendantModel,
      },
      width: 'calc(100% - 50%)',
      hasBackdrop: false,
      panelClass: 'draggable-dialog',
      disableClose: true,
    };
    if (this.isMobile) {
      config.width = '100%';
      config.height = '100%';
    }
    const dialog = this.dialog.open(DefendantNotesDialogComponent, config);
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        dialog.updateSize('100vw', '100vh');
      } else {
        dialog.updateSize('calc(100% - 50%)', '');
      }
    });


    const closeSub = dialog.afterClosed().subscribe((notes: any) => {
      const updatedNotesArr : Note[] = [];
      notes.forEach(note => {
        const updatedNote = new Note();
        updatedNote.loadData(note);
        updatedNote.User = note?.user?.data?.identity?.data?.FirstName + " " + note?.user?.data?.identity?.data?.LastName;
        updatedNotesArr.push(updatedNote);
      });

      this.defendantModel.transactions[this.defendantModel.transactions.length - 1].notes = updatedNotesArr;
      this.eventService.emitNotesWereUpdated();

      smallDialogSubscription.unsubscribe();
      closeSub.unsubscribe();
    });
  }

  openRecordCheckIn() {
    this.dynamicPopupClass = CheckinComponent;
    this.dynamicPopupData = [];
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = this.defendantId;
    this.dynamicPopupData[
      AppConstants.DEFENDANT_NAME_KEY
      ] = this.defendantModel.person.getFullName();

    this.dynamicPopupTitle = 'Record Check In';
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;
  }

  canDeactivate(): Promise<any> | Boolean {

    if (this.disableCanDeactivate) {
      return true; // Skip the guard and allow navigation
    }

    if (this.checkDefendantChanges()) {
      // Show the popup
      const confirmationDialog = this.dialog.open(ConfirmationComponent, {
        data: {
          title: 'Save Changes',
          message: 'Do you want to save your changes?',
          yesText: 'Yes',
          noText: 'No',
          showCloseIcon: true,
        },
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '600px',
      });

      return new Promise((resolve, reject) => {
        this.subs.sink = confirmationDialog.afterClosed().subscribe(res => {
          // Special case where the click the X icon, have to send a string 'cancel' because the no button by default sends back false
          if (res === 'cancel') {
            resolve(false);
            return;
          }

          if (!res) {
            resolve(true);
          } else {
            if (!!this.leavingPath) {
              this.saveDefendant(null, this.defendantValidationGroup, false, true)
              resolve(false);
            }
          }
        });
      });
    } else {
      return true;
    }
  }

  canSwitchAccount(): Observable<boolean> {
    return new Observable(observer => {
      if (this.disableCanDeactivate) {
        observer.next(true); // Skip the guard and allow navigation
        observer.complete();
        return;
      }
  
      if (this.checkDefendantChanges()) {
        // Show the confirmation popup
        const confirmationDialog = this.dialog.open(ConfirmationComponent, {
          data: {
            title: 'Save Changes',
            message: 'Do you want to save your changes?',
            yesText: 'Yes',
            noText: 'No',
            showCloseIcon: true,
          },
          disableClose: true,
          hasBackdrop: true,
          maxWidth: '600px',
        });
  
        this.subs.sink = confirmationDialog.afterClosed().subscribe(res => {
          if (res === 'cancel') {
            observer.next(false); // User clicked 'X'
            observer.complete();
            return;
          }
  
          if (res) {
            this.saveDefendant(null, this.defendantValidationGroup, false, true);
              this.saveCompleted$.pipe(take(1)).subscribe(() => {
                observer.next(true);
                observer.complete();
                return;
            });
          }else{
            observer.next(true); // User clicked 'No'
            observer.complete();
            return;
          }
        });
      } else {
        observer.next(true); // No changes, allow navigation
        observer.complete();
      }
    });
  }
  
  openCheckinPopup() {
      const checkinAction: QuickAction<QuickActionEvent> = {
        value: QuickActionEvent.CHECKIN,
        text: 'Check In History',
        icon: 'fal fa-check-square'
      };

      this.eventService.defAction(checkinAction);
  }

  ngOnInit() {
    this.subs.sink = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.leavingPath = event.url;
      }
    });
    this.isExtraSmall = this.breakpointObserver.observe([Breakpoints.XSmall]);
    this.isMobile = this.breakpointObserver.isMatched([Breakpoints.XSmall]);

    this.subs.sink = this.eventService.defActionEvent$.subscribe(item => {
      switch (item.value) {
        case QuickActionEvent.FORMS:
          if (this.checkDefendantChanges()) {
            this.saveDefendant(null, this.defendantValidationGroup, false, false);
            // Todo: This could cause a problem if the save hasn't completed before the form runs, but since the
            // user has to pick the forms to run it should be okay.
            this.onSelected(item);
          } else {
            this.onSelected(item);
          }
          break;
        default:
          this.onSelected(item);
      }
    });
    // check if id has been passed
    this.subs.sink = this.route.paramMap.subscribe(params => {
      this.defendantId = params.get('id');
      // Check if there is a request to refresh the defendant
      // (direct links to full profile will not update defendant state)
      this.subs.sink = this.route.queryParamMap.subscribe(params => {
        const reload = params.get('refresh') === '1';
        if (this.defendantId) {
          this.loadDefendantData(this.defendantId, reload);
        }
      });
    });

    this.subs.sink = this.route.queryParams.subscribe(params => {
      if (!!params.anchor) {
        switch (params.anchor) {
          case 'documents':
            this.anchorToComponent = 'DocumentComponent';
            break;
          case 'contacts':
            this.anchorToComponent = 'ContactComponent';
            this.anchorInformation = {
              contactId: params.contactId,
              transactionId: params.transactionId,
            };
            break;
          case 'powers':
            this.anchorToComponent = 'BondComponent';
            this.anchorInformation = {
              bondId: params.bondId,
              transactionId: params.transactionId,
            };
            break;
          case 'notes':
            this.anchorToComponent = 'NotesComponent';
            this.anchorInformation = {
              transactionId: params.transactionId,
            };
            break;
        }
      }
      if(!!params.slideout){
        this.inSlideOut = params.slideout === 'true';
      }
    });

    this.eventService.menuChanged('def');

    this.subs.sink = this.billingService
      .IsSubscribedToProduct(AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM)
      .subscribe((response: boolean) => {
        this.subscribedToBailManagement = response;
      });

    // Contact addedd subcscription
    this.subs.sink = this.contactService.onContactAdded.subscribe(() => {
      this.checkContactValidation(this.defendantValidationGroup);
    })

    this.subs.sink = this.eventService.setDefForAcctRefresh$.subscribe((res) => {
      if(this.defendantId === res.defendantId) {
        this.subs.sink = this.defendantService
        .getDefendantAccountDetails(this.defendantId).pipe(
          take(1))
        .subscribe(
          def => {
            this.defendantModel.loadAccountingData(def.data);
            this.loadDefendantMessages(this.defendantId);
            this.eventService.emitRefreshAccountingSummary(res.transactionId);
          },
          error => {
            console.error('load defendant error', error);
          },
        );
      }
    });
  }


  public loadSavedData(accountingComponent: AccountingComponent) {
    if (this.defendantId) {
      this.loadDefendantAccountDetails(this.defendantId, accountingComponent);
    }
  }

  toggleDefendantTabs(tabNames: string[], state: boolean = null) {
    // tabs - Tabs that will be toggled
    // state - The boolean state that will be set for 'disabled' key

    this.tabs.forEach(item => {
      if (tabNames.includes(item.text.toLowerCase())) {
        if (state == null) {
          item.disabled = !item.disabled;
        } else {
          item.disabled = state;
        }
      }
    });

    this.tabPanel.items = this.tabs;

    if(this.openCheckinHistory){
      this.openCheckinPopup();
    }
  }

  ngOnDestroy() {
    this.eventService.menuChanged('nodef');
    this.lookupService.clearCaching();
    super.ngOnDestroy();
  }

  /**
   * @description Refreshes the defendants messages from the state or the API
   * @param defendantId [string|null] If null, the user will be pulled via API
   * @param refresh [boolean] If set to true, the user messages will ignore state and force reload
   */
  loadDefendantMessages(defendantId, refresh: boolean = false) {
    // check to see if state is set, and if so, pull the messages out of state (via ngrx)
    // otherwise, call the api to get the defendant messages
    this.subs.sink = this.store.select(selectMessages).pipe(take(1)).subscribe((state) => {
      if (!!state && !refresh) {
        this.defendantModel.messages = state;
        this.getMessages();
      } else {
        if(!!this.smsHistoryComponent){
          this.smsHistoryComponent.getContacts();
        }
        this.subs.sink = this.defendantService
          .getDefendantMessages(defendantId)
          .subscribe((messages: any) => {
            this.defendantModel.messages = messages.data;
            this.store.dispatch(loadMessages({ messages: messages.data }));
            this.getMessages();
          });
      }
    });
  }

  loadDefendantAccountDetails(defendantId, accountingComponent: AccountingComponent) {
    this.subs.sink = this.defendantService
      .getDefendantAccountDetails(defendantId).pipe(
        take(1))
      .subscribe(
        def => {
          this.defendantModel.loadAccountingData(def.data);
          this.loadDefendantMessages(defendantId);
          if (accountingComponent != null) {
            accountingComponent.loadData();
          } else if (this.transactionControl.BondControl.accountingComponent) {
            this.transactionControl.BondControl.accountingComponent.loadData();
          }
        },
        error => {
          console.error('load defendant error', error);
        },
      );
  }

  getContactsAndDocuments(defendant: any): Observable<number> {
    let count = 0;
    return new Observable(obs => {
      obs.next(++count);
      this.subs.sink = this.defendantService.getDefendantDocumentsByDefId(defendant.Id).subscribe(documents => {
        // iterate through documents and update the defendant object so that it includes the documents
        documents.forEach(document => {
          defendant.transactions.forEach(trans => {
            if (trans.Id === document.SourceId && document.SourceType === 'DefendantTransactions') {
              if (!('documents' in trans)) {
                trans.documents = [];
              }
              const documentIndex = trans.documents.findIndex((doc) => doc.Id === document.Id);
              if (documentIndex === -1) {
                const _document = new Document();
                _document.loadData(document);
                trans.documents.push(_document);
              }
            }
          });
        });
        obs.next(++count);
      }, () => {
        obs.next(++count);
      });
    });
  }

  fillModelFromFromState(defdata: Defendant) {
    this.editingEnabled = false;
    this.defendantModel.loadFromStore(defdata);
    this.eventService.contentLoaded('defendant');
    this.subs.sink = this.userService.current().subscribe(res => {
      if (res.data) {
        if (res.data.LimitedVisibility) {
          if (this.defendantModel.transactions.length === 0) {
            this.notification.toast('You do not have access to this file. See your administrator.', 'error');
          }
          this.subs.sink = this.configService.get().subscribe(config => {
            this.systemConfig = config.data[0];
            this.editingEnabled = this.systemConfig.AllowLimitedUsersToEdit;
            if (!this.editingEnabled) {
              const transactions = this.defendantModel.transactions;
              for (let i = 0; i < transactions.length; i++) {
                if ((!!transactions[i].AgencyId
                    && res.data.LimitAgencyIds
                    && res.data.LimitAgencyIds.includes(transactions[i].AgencyId))
                  || (!!transactions[i].AgentId
                    && ((res.data.LimitAgentIds && res.data.LimitAgentIds.includes(transactions[i].AgentId))
                      || res.data.DefaultAgentId === transactions[i].AgentId))
                  || res.data.Id === this.defendantModel.CreatedByUserId) {
                  this.editingEnabled = true;
                  break;
                } else {
                  this.editingEnabled = false;
                }
              }
            }
          });
        } else {
          this.editingEnabled = true;
        }
      }
    })
  }

  /**
   * @description Load the defendant data into component from either the state or the API
   * @param defendantId [string | null] Should be the defendant id or null
   * @param refresh [bool]  This will tell the method to reload from API, even if id is present
   *                        Refresh is required when route is coming directly to the defendant page
   *                        without first landing on the defendant preview page
   */
  loadDefendantData(defendantId, refresh: boolean = false) {
    this.subs.sink = this.store.select(selectDefendant).pipe(take(1)).subscribe(defendant => {
      if (!!defendant.Id && !refresh) {
        this.toggleDefendantTabs(['messages'], false);
        // load the defendant data from state
        // at this point, we need to get the defendant's contact info and documents
        this.subs.sink = this.getContactsAndDocuments(defendant).subscribe((count) => {
          if (count === 2) {
            this.fillModelFromFromState(defendant);
            this.updateSessionData();
            this.eventService.componentBusy(false);
            this.loadDefendantMessages(defendant.Id);
            this.defendantService.pushRecentDefendant(
              defendant.Id,
              defendant.person.FullName,
              defendant.person.Dob,
              defendant.person.Gender,
              defendant.person.ProfilePicture,
            );
            this.eventService.changePageTitle(defendant.person.FullName);
            this.subs.sink = this.route.paramMap.subscribe(params => {
              const tabIndex = params.get('tab');
              if (tabIndex) {
                this.selectedTabIndex = Number(tabIndex);
              }
            });
            this.refreshMugshot();
          }
        });
      } else {
        // call the api to get the defendant info
        this.subs.sink = this.defendantService
          .getFullDefendantById(defendantId).pipe(
            take(1))
          .subscribe(
            def => {
              this.fillModelFromRetrievedDefendantData(def.data);
              this.updateSessionData();
              this.eventService.componentBusy(false);
              // Pass the force refresh state because this method pulls from state
              this.loadDefendantMessages(defendantId, refresh);
              this.defendantService.pushRecentDefendant(
                def.data.Id,
                def.data.person.data.FullName,
                def.data.person.data.Dob,
                def.data.person.data.Gender,
                def.data.person.data.ProfilePicture,
              );
              this.eventService.changePageTitle(def.data.person.data.FullName);
              this.toggleDefendantTabs(['messages'], false);
              this.subs.sink = this.route.paramMap.subscribe(params => {
                if(!!this.smsHistoryComponent){
                  // Clear the sms-history's dropdown.
                  this.smsHistoryComponent.contactDropdown.value = null;
                }
                const tabIndex = params.get('tab');
                if (tabIndex) {
                  this.selectedTabIndex = Number(tabIndex);
                }
              });
              this.refreshMugshot();
            },
            error => {
              console.error('load defendant error', error);
              this.eventService.componentBusy(false);
            },
          );
      }
    });
  }

  getMessages() {
    let message = '';
    this.messages = [];
    this.messageTypes.forEach(type => {
      if (
        !!this.defendantModel.messages && this.defendantModel.messages[type.Id] != null &&
        this.defendantModel.messages[type.Id] !== 0
      ) {
        switch (type.Id) {
          case 'ActiveForfeitureBondCount':
            message =
              'Defendant has ' +
              this.defendantModel.messages[type.Id] +
              ' active forfeiture' +
              (this.defendantModel.messages[type.Id] > 1 ? 's.' : '.');
            break;

          case 'OpenBondCount':
            message =
              'Defendant has ' +
              this.defendantModel.messages[type.Id] +
              ' open bond' +
              (this.defendantModel.messages[type.Id] > 1 ? 's.' : '.');
            break;

          case 'PastForfeitureBondCount':
            message =
              'Defendant has ' +
              this.defendantModel.messages[type.Id] +
              ' previous forfeiture' +
              (this.defendantModel.messages[type.Id] > 1 ? 's.' : '.');
            break;

          default:
            message = this.defendantModel.messages[type.Id];
            break;
        }
        this.messages.push({
          message: message,
          class: type.BadgeClass,
          alertClass: type.AlertClass,
        });
      }
    });
  }

  updateSessionData() {
    this.routeDataService.setValue(
      AppConstants.DEFENDANT_ID_KEY,
      this.defendantModel.Id,
    );
    this.routeDataService.setValue(
      AppConstants.DEFENDANT_NAME_KEY,
      this.defendantModel.person.FullName,
    );
    if (
      this.defendantModel.transactions &&
      this.defendantModel.transactions.length > 0
    ) {
      this.routeDataService.setValue(
        AppConstants.TRANSACTION_ID_KEY,
        this.defendantModel.transactions[0].Id,
      );
    }
  }

  fillModelFromRetrievedDefendantData(defData) {
    this.editingEnabled = false;

    this.defendantModel.LoadData(defData);

    this.eventService.contentLoaded('defendant');
    this.subs.sink = this.userService.current().subscribe(res => {
      if (res.data) {
        if (res.data.LimitedVisibility) {
          if (this.defendantModel.transactions.length === 0) {
            this.notification.toast('You do not have access to this file. See your administrator.', 'error');
          }
          this.subs.sink = this.configService.get().subscribe(config => {
            this.systemConfig = config.data[0];
            this.editingEnabled = this.systemConfig.AllowLimitedUsersToEdit;
            if (!this.editingEnabled) {
              const transactions = this.defendantModel.transactions;
              for (let i = 0; i < transactions.length; i++) {
                if ((!!transactions[i].AgencyId
                    && res.data.LimitAgencyIds
                    && res.data.LimitAgencyIds.includes(transactions[i].AgencyId))
                  || (!!transactions[i].AgentId
                    && ((res.data.LimitAgentIds && res.data.LimitAgentIds.includes(transactions[i].AgentId))
                      || res.data.DefaultAgentId === transactions[i].AgentId))
                  || res.data.Id === this.defendantModel.CreatedByUserId) {
                  this.editingEnabled = true;
                  break;
                } else {
                  this.editingEnabled = false;
                }
              }
            }
          });
        } else {
          this.editingEnabled = true;
        }
      }
    });
  }

  preSave() {
    const self = this;
    const body = document.getElementById('main-content-body');
    window.addEventListener('scroll', () => {
      self.hasScrolled = true;
      if (body.scrollTop > this.lastScrollPosition) {
        this.lastScrollPosition = body.scrollTop;
      }
    }, true);
    this.lastScrollPosition = body.scrollTop;
    this.transactionControl.endChildEdit();
    this.defendantHasChanges = this.checkDefendantChanges();
    this.saveButtonDisabled = true;
    this.notification.toast('Saving');
  }

  disableSave(value: boolean) {
    this.saveButtonDisabled = value;
  }

  checkDefendantChanges() {
    const changeChecker = new DefendantSaver();
    changeChecker.checkForChanges(this.defendantModel);
    return changeChecker.haveChanges;
  }

  checkIfDefendantsHasChanged(staticObject: any, current: any) {
    // Cleaning objects before compare
    try {
      // Using for instead forEach to increase performance
      for (const tKey in current.transactions) {
        if (current.transactions.hasOwnProperty(tKey)) {
          if (staticObject.transactions[tKey].AgencyId === '') {
            staticObject.transactions[tKey].AgencyId = current.transactions[tKey].AgencyId;
          }
          for (const bKey in current.transactions[tKey].bonds) {
            if (current.transactions[tKey].bonds.hasOwnProperty(bKey)) {
              current.transactions[tKey].bonds[bKey].cacheObjects = [];
            }
          }

          for (const cKey in current.transactions[tKey].contacts) {
            if (current.transactions[tKey].contacts.hasOwnProperty(cKey)) {

              staticObject.transactions[tKey].contacts[cKey].ReferenceForId = (
                !(!!current.transactions[tKey].contacts[cKey].ReferenceForId) &&
                !(!!staticObject.transactions[tKey].contacts[cKey].ReferenceForId) ?
                  current.transactions[tKey].contacts[cKey].ReferenceForId
                  : staticObject.transactions[tKey].contacts[cKey].ReferenceForId
              );

              staticObject.transactions[tKey].contacts[cKey].refId = current.transactions[tKey].contacts[cKey].refId;
              staticObject.transactions[tKey].contacts[cKey].refForId = current.transactions[tKey].contacts[cKey].refForId;
            }
          }

        }
      }

      // Children review
      staticObject.person.phones = this.childrenCurrentStateFlag(staticObject.person.phones);
      staticObject.person.emails = this.childrenCurrentStateFlag(staticObject.person.emails);
      staticObject.person.addresses = this.childrenCurrentStateFlag(staticObject.person.addresses);

      const auxStaticString = JSON.stringify(staticObject), auxCurrentString = JSON.stringify(current);

      return !(auxStaticString === auxCurrentString);
    } catch (e) {
      return true;
    }
  }

  childrenCurrentStateFlag(list: any) {
    list.forEach((item, index) => {
      if (item.currentState === '.valid') {
        item.currentState = '.valid.dirty';
      }
    });

    return list;
  }

  postSave(showToast = true, leave = false) {
    if (!this.hasScrolled) {
      setTimeout(() => {
        document.getElementById('main-content-body').scrollTop = this.lastScrollPosition;
        // tslint:disable-next-line:no-empty
        window.removeEventListener('scroll', () => {
        }, true);
      }, 500);
    }
    this.hasScrolled = false;
    this.saveButtonDisabled = false;
    this.editingEnabled = true;
    this.eventService.defendantDetailsSavingStatusTrigger(false);

    if (showToast && !this.bondWarningsFlag) {
      this.notification.toast('Saved');
    }

    this.collateralToSelectForSchedule = null;
    if (this.afterSaveMethodData) {
      if (this.afterSaveMethodType === 'CollateralPaymentSchedule') {
        this.defendantModel.transactions.forEach(transaction => {
          if (!this.collateralToSelectForSchedule) {
            this.collateralToSelectForSchedule = transaction.collaterals.find(collateral =>
              collateral.ReceiptNumber === this.afterSaveMethodData.collateralReceipt);
          }
        });
      }
    }
    this.afterSaveMethodData = null;
    this.afterSaveMethodType = null;
    this.refreshMugshot();

    if(!!this.leavingPath && showToast) {
      let pathToNavigate = this.leavingPath;
      this.leavingPath = '';
      this.router.navigateByUrl(pathToNavigate);
    }

    setTimeout(() => {
      this.saveCompleted$.next(); // Notify listeners that saving is done
    }, 2000);
  }

  handleError(error) {
    this.saveButtonDisabled = false;
    this.errorData = error;
  }

  popToast(msg) {
    this.notification.toast(msg, "success");
  }

  triggerMethodAfterSave(changed: boolean) {
    // console.log('In function triggerMethodAfterSave in defendant component!');
    this.methodToCallAfterSave(changed);
    this.methodToCallAfterSave = null;
    this.afterSaveMethodData = null;
    this.afterSaveMethodType = null;
  }

  checkGoogleReview(): void {
    if (this.defendantModel.person.IncludeInGoogleReview) {
      // Get the last transaction (just taking the last element in array)
      const lastTransaction = this.defendantModel.transactions[this.defendantModel.transactions.length - 1];
      const lastTransactionContacts = lastTransaction.contacts;
      for (const contact of lastTransactionContacts) {
        contact.person.IncludeInGoogleReview = contact.person.phones.length > 0 && contact.enableGoogleReview();
      }
    } else {
      for (const transaction of this.defendantModel.transactions) {
        for (const contact of transaction.contacts) {
          contact.person.IncludeInGoogleReview = false;
        }
      }
    }
  }

  checkContactValidation(valGroup) {
    const validationResult = valGroup.instance.validate();
    if (!validationResult.isValid) {
      this.contactService.onContactAddErrors.next(true);
      // lets just pop a toaster as well to show the rules that failed validation.
      const validationErrors = validationResult.brokenRules.map(x => x.message).join("\n");

      this.notification.toast(`Please check these validation errors:\n${validationErrors}`,
        'error', 'OK', 0, 'saving-failed-snack', { vertical: 'bottom', horizontal: 'right' });
    } else {
      this.contactService.onContactAddErrors.next(false);
    }
  }

  onSaveDefendantFromChild(e, valGroup) {
    this.collateralToSelectForSchedule = null;
    this.afterSaveMethodData = e.Data;
    this.afterSaveMethodType = e.Type;
    if (e.Method != null) {
      this.methodToCallAfterSave = e.Method;
    }
    this.saveDefendant(e, valGroup);
  }

  saveDefendant(e, valGroup, leave = false, reviewBondsDates = false) {
    this.editingEnabled = false;
    const validationResult = valGroup.instance.validate();

    if (validationResult.isValid) {
      // This is causing issues because we don't really have a way to tell if it is a new transaction
      // On the full profile. Commenting out for now, but Todo: We need to remove in the future
      // this.checkGoogleReview();
      this.preSave();
      this.anchorToComponent = null;
      this.anchorInformation = null;
      if (this.defendantHasChanges) {
        // attempt to save profile pic if applicable
        this.subs.sink = this.documentService
          .addDefendantProfilePic(this.defendantModel.person.ProfilePictureFile).pipe(
            take(1))
          .subscribe(picResp => {
            if (picResp.data !== undefined) {
              this.defendantModel.person.ProfilePicture = picResp.data.Location; // em - the s3 file location
              delete this.defendantModel.person.ProfilePictureFile;
            }

            this.defendantService.pushRecentDefendant(
              this.defendantModel.Id,
              this.defendantModel.person.FullName,
              this.defendantModel.person.Dob,
              this.defendantModel.person.Gender,
              this.defendantModel.person.ProfilePicture,
            );

            // em this is a complex scenario. the defendant logic api side is deleting th doc before we get to the steps thereafter,
            // so we have to process the doc deletes before saving defendant

            const observableQueue = [];

            this.defendantModel.transactions.forEach(tran => {
              tran.documents.forEach(doc => {
                doc.tempKey = new Guid().toString();
                if (doc.isAdded) {
                  observableQueue.push(this.documentService.addDocument(doc));
                } else if (doc.isDeleted) {
                  observableQueue.push(this.documentService.removeDocument(doc.Id));
                }
              });

              if (reviewBondsDates) {
                let displayError = `Saving... `;
                let bondWarnings = 0;
                tran.bonds.forEach((bond, index) => {
                  if (bond.DischargeOccurrenceDate != null) {
                    if (this.bondService.fixStatusDates(bond.DischargeOccurrenceDate, tran.PostedDate)) {
                      // Don't fix the 140 line lint here, if you do that, it will not be displayed correctly on snackbar
                      displayError += `\nOn bond ${(index + 1)}, Exoneration date date ${moment.utc(bond.DischargeOccurrenceDate).format('MM/DD/YYYY')} is before posted date ${moment.utc(tran.PostedDate).format('MM/DD/YYYY')}.`;
                      bondWarnings++;
                    }
                  } else if (bond.ForfeitureDate != null) {
                    if (this.bondService.fixStatusDates(bond.ForfeitureDate, tran.PostedDate)) {
                      displayError += `\nOn bond ${(index + 1)}, Forfeiture date ${moment.utc(bond.ForfeitureDate).format('MM/DD/YYYY')} is before posted date ${moment.utc(tran.PostedDate).format('MM/DD/YYYY')}.`;
                      bondWarnings++;
                    }
                  }
                });

                if (bondWarnings > 0) {

                  this.bondWarningsFlag = true;
                  const snackBarRef = this.notification.toast(`${displayError}\n\n You should correct this and resave the file.`,
                    'error', 'OK', 0, 'saving-failed-snack', { vertical: 'bottom', horizontal: 'right' });

                  const subscription = snackBarRef.afterDismissed().subscribe(() => {
                    this.bondWarningsFlag = false;
                    subscription.unsubscribe();

                    if (!this.saveButtonDisabled) {
                      this.notification.toast('Saved');
                    }
                  });
                }
              }
            });

            if (observableQueue.length === 0) {
              const dummyObs = new Observable(obs => {
                obs.next();
              });
              observableQueue.push(dummyObs);
            }
            this.subs.sink = observableCombineLatest(observableQueue).subscribe((docResp: any) => {
              // lets find the deleted and remove them from the client model collections seeing as the api does not need to
              // process them anymore
              this.defendantModel.transactions.forEach(tran => {
                tran.documents.forEach((doc, idx, obj) => {
                  if (doc.isAdded) {
                    doc.Id = docResp.find(
                      (itm: any) => {
                        return itm.data.tempKey === doc.tempKey;
                      },
                    ).data.data.Id;
                    doc.isAdded = false;
                  } else if (doc.isDeleted) {
                    doc.isDeleted = false;
                  }
                });
              });

              // lets find the additions and add them to the relevant client collections
              // let auxDefendant = new Defendant();
              this.subs.sink = this.defendantService
                .saveDefendant(this.defendantId, this.defendantModel, null, null, true).pipe(
                  take(1))
                .subscribe(
                  savedDefendant => {
                    // auxDefendant.LoadData(savedDefendant.data);
                    this.loadSavedData(null);
                    if (this.methodToCallAfterSave == null) {
                      this.fillModelFromRetrievedDefendantData(savedDefendant.data);
                      this.updateSessionData();
                      this.loadDefendantMessages(this.defendantId);
                      this.defendantService.pushRecentDefendant(
                        savedDefendant.data.Id,
                        savedDefendant.data.person.data.FullName,
                        savedDefendant.data.person.data.Dob,
                        savedDefendant.data.person.data.Gender,
                        savedDefendant.data.person.data.ProfilePicture,
                      );
                      this.postSave(true, leave);
                    } else {
                      this.defendantModel.loadFromServer(savedDefendant.data);
                      this.postSave(false, leave);
                      this.triggerMethodAfterSave(true);
                    }
                  },
                  error => {
                    this.handleError(error);
                    this.postSave(false);
                  },
                );
            }, error => {
              this.notification.toast('Error saving documents. Please try again!', 'error');
            });
          }, error => {
            this.notification.toast('Error saving profile picture. Please try again!', 'error');
          });
      } else {
        if (this.methodToCallAfterSave == null) {
          this.postSave(true, leave);
        } else {
          this.postSave(false, leave);
          this.triggerMethodAfterSave(false);
        }
      }
    } else {
      this.editingEnabled = true;
      // lets just pop a toaster as well to show the rules that failed validation.
      const validationErrors = validationResult.brokenRules.map(x => x.message).join('\n');

      this.notification.toast(`Please check these validation errors:\n${validationErrors}`,
        'error', 'OK', 0, 'saving-failed-snack', { vertical: 'bottom', horizontal: 'right' });

    }
  }

  onDefendantDeleteResult(e) {
    if (e) {
      const idToDelete = this.routeDataService.getValue(
        AppConstants.DEFENDANT_ID_KEY,
      );
      this.notification.toast('Deleting...', 'success');
      if (idToDelete != null) {
        this.subs.sink = this.defendantService
          .delete(idToDelete).pipe(
            take(1))
          .subscribe(
            d => {
              this.defendantService.removeDefendantFromRecentDefendants(
                idToDelete,
              );
              this.notification.toast('Deleted.');
              this.disableCanDeactivate = true;
              this.router.navigateByUrl('/dashboard');
            },
            error => {
              // Remove this error message as its conflicting with the base.service.ts file
              // this.notification.toast('An error occurred. Unable to delete.', 'error');
            },
          );
      } else {
        this.notification.toast('No defendant selected');
      }
    }
  }

  backToSummary() {

    if (this.inSlideOut) {
      this.router.navigate(['/reporting']);
      this.eventService.openslideOut(this.defendantId);
    } else {
      this.router.navigate(['/defendant', this.defendantId]);
    }
  }

  refreshMugshot(shouldRemove = false){
    this.defendantModel.transactions.forEach(trans => {
      trans.documents = trans.documents.filter(doc => doc.Name);


      if(shouldRemove) {
        this.defendantModel.person.ProfilePicture = null;
        delete this.defendantModel.person.ProfilePictureFile;
      }

      if(!!this.defendantModel.person.ProfilePicture && !shouldRemove){
        const document = new Document();
        document.File = this.defendantModel.person.ProfilePicture;
        document.Name = 'Mugshot';
        trans.documents.push(document);
      }
    });
  }

}

export interface DefendantTab {
  text: string;
  icon: string;
  template: string;
  disabled: boolean;
}
