import { BaseModel } from './BaseModel';

// tslint:disable:variable-name
export class NotificationTemplate extends BaseModel {
  public Id: '';
  public TemplateName: string;
  public TemplateType: string;
  public MessageTypeId: string;
  public MessageTypeSource: 'bail' | 'defmngr' | 'collections';
  public EmailMessage: string;
  public EmailSubject: string;
  public wordphrases: any;
  public Message: string;
  public Fields: string;

  constructor(obj?: object) {
    super(obj);

    if (obj == null) {
      this.Id = '';
    }
  }
}
