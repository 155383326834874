<dx-validation-group #valGroup>
  <div class="form-group">
    <div class="col-md-4" *ngIf="reminderNumbers.length > 0">
      <label class="control-label">Reminder Phone Number</label>
    </div>

    <div class="col-md-4" *ngIf="reminderNumbers.length > 0">
      <dx-text-box
        [value]="reminderNumbers[0].Number"
        [mask]="phoneMask"
        mode="tel"
        readOnly="true">
      </dx-text-box>
    </div>

    <div
      class="{{ !!inPopup ? 'col-md-6' : 'col-md-4' }}"
      style="display: flex;justify-content: center; width: 100%;"
      *ngIf="reminderNumbers.length <= 0">
      <dx-text-box
        [readOnly]="false"
        [placeholder]="!inPopup ? 'Enter 3 Digit Area Code' : 'Search Area Code'"
        [(value)]="areacode"
        [width]="inPopup ? '60%' : '100%'"
        [maxLength]="3"
        #reminderNumber>
        <dx-validator>
          <dxi-validation-rule
            type="required"
            message="Area Code is required."
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <button
        style="margin-left: 8px;"
        *ngIf="!reminderNumbers[0]"
        mat-raised-button
        color="primary"
        (click)="searchByAreaCode($event)"
        [disabled]="activateButtonDisabled"
      >
        {{ activateButtonDisabled ? 'Activating...' : 'Search' }}
      </button>
    </div>

    <div class="col-md-12" style="margin-top: 10px;">
      <dx-data-grid
        class="no-border"
        #dataGrid
        [columnAutoWidth]="true"
        [dataSource]="phoneNumbers"
        rowAlternationEnabled="true"
        [columnAutoWidth]="true"
        noDataText="No phone numbers found for this area code."
        [loadPanel]="{
          enabled: true,
          showIndicator: true,
          showPane: true,
          text: 'Loading...'
        }"
        [visible]="gridVisible"
        (onRowPrepared)="onRowPrepared($event)"
      >
        <dxi-column
          caption="Number"
          [visible]="true"
          dataField="FriendlyName"
        ></dxi-column>
        <dxi-column
          caption=""
          [visible]="true"
          cellTemplate="activateTemplate"
          alignment="center">
          <div *dxTemplate="let data of 'activateTemplate'">
            <button
              mat-raised-button
              color="primary"
              (click)="activatePhone(data.data)"
              [disabled]="activateButtonDisabled"
            >
              Activate
            </button>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>
</dx-validation-group>
