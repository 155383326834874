<button mat-icon-button
  [matMenuTriggerFor]="messagemenu" (menuOpened)="onMenuOpened($event)"
  matTooltip="{{ unreadMessageCount || 'No' }} Unread {{isBail === true ? 'Notifications' : 'Text Replies'}}"
  matTooltipPosition="below">
  <i class="fas fa-envelope fa-lg"></i>
  <span class="badge counter" *ngIf="unreadMessageCount > 0">{{unreadMessageCount}}</span>
</button>


<mat-menu #messagemenu="matMenu" xPosition="before" yPosition="below">

  <mat-nav-list style="padding-top: 0; max-height:80vh; overflow: hidden scroll;"  infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="100"
    [scrollWindow]="false"
  >

    <a mat-list-item class="text-muted" *ngIf="((messageData.length > 0 && isBail || isDefendantManager) || isTextMessaging)" (click)="navigateToReminders()">
      <p matLine class="text-center">See All</p>
    </a>

    <mat-list-item class="text-muted icon-spacer" *ngIf="messageData.length == 0">
      <i class="fas fa-comment-slash"></i>
      &nbsp;No {{isBail === true ? 'notifications' : 'text replies'}} available.
    </mat-list-item>

    <mat-list-item
      style="min-width:250px;"
      class="text-reply"
      *ngFor="let message of messageData"
      matTooltip="{{ message.MessageText }}"
    >

      <a mat-list-item (click)="message.MessageSourceId && navDefendant(message.MessageSourceId,message, message.MessageType)">
        <h3 matLine>{{ message.Sender }}</h3>
        <p matLine class="text-muted">{{ message.MessageText }}</p>
        <span class="small" matLine>{{ message.SentAgo }}</span>
      </a>

      <mat-icon
        class="col-sm-1"

        *ngIf="message.Date_Read; else unread_template"
        matListIcon
        matTooltip="Read By {{ message.ReadByUserFullName }} on {{ message.Date_Read | date}}">
        <i class="fa fa-envelope-open read" ></i>
      </mat-icon>

      <ng-template #unread_template>
        <mat-icon class="col-sm-1"  style="z-index:2000;" matListIcon (click)="flagAsRead(message,$event)" matTooltip="Mark As Read">
          <i class="fa fa-envelope unread" alt="Mark As Read"></i>
        </mat-icon>
      </ng-template>

      <mat-icon
        class="col-sm-1"
        style="z-index:2000;"
        *ngIf="!message.DNC && message.Number"
        matListIcon
        (click)="confirmAddDNC($event,message)"
        matTooltip="Add To DNC">
        <i class="fal fa-comment-slash unread" alt="Add To DNC"></i>
      </mat-icon>

    </mat-list-item>

  </mat-nav-list>

</mat-menu>

<app-yes-no-confirmation
  [showTitle]="true"
  titleText="Confirm Action"
  [confirmationMessage]="confirmationMessage"
  [(showConfirmation)]="showDNCConfirmationPopup"
  (onResult)="YesNoConfirmResult($event)"
  >
</app-yes-no-confirmation>
