import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { CacheService } from './cache.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SystemManagerService {

  constructor(private http: HttpClient, private settings: SettingsService, private cache: CacheService) {
  }

  getCompany(userId: string): Observable<any> {
    const key = 'company';
    const cache = this.cache.get(key);
    if (cache) {
      return of(JSON.parse(cache));
    } else {
      return this.http.get(`${this.settings.getBaseUrl()}/customers/user/${userId}?include=addresses,phones`).pipe(
        map((res: any) => {
          this.cache.set(key, JSON.stringify(res));
          return res;
        }),
        catchError(error => throwError(error.error || 'Server Error')));
    }
  }

  getCompanyNoCache(userId: string): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/customers/user/${userId}?include=addresses,phones`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  saveCompany(company: any): Observable<any> {
    const key = `systemConfigs`;
    this.cache.remove(key);
    this.cache.remove('company');
    return this.http.patch(`${this.settings.getBaseUrl()}/customers/${company.Id}`, company).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getUserLookup(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/users/lookup/all?limit=0`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  // Get Payment range config for customers
  getPaymentRangeConfig(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/paymentranges`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  // Get Payment range config for customers through customerId, for public pages without login case
  getPaymentRangeConfigByCustomerClientAuth(customerId): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/customerpaymentranges/${customerId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  // Update Payment range config for customers
  setPaymentRangeConfig(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/paymentranges`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

}
