import {map, catchError} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';
import { Person } from '../models/person';
import { PortoResponse } from '../models/porto-response';

@Injectable()
export class PersonsService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  get(id): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/persons/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  createPerson(data: object): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/persons`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  updatePersonForCheckin(id, data): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/checkin/person/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  updatePersonInfo(person: Person, includes?: string): Observable<Person> {
    let url = `${this.settings.getBaseUrl()}/persons/${person.Id}`;
    if (!!includes) {
      url += `?include=${includes}`;
    }
    return this.http.put<PortoResponse<Person>>(url, person)
      .pipe(
        map(res => res.data),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Eerror')),
      );
  }

  removeMugshot(id: string) {
    return this.http.delete(`${this.settings.getBaseUrl()}/mugshot/persons/${id}`).pipe(
    map((res: any) => res),
    catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }
}
