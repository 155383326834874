<h2 mat-dialog-title>Customize Your Dropdown Lists</h2>
<div mat-dialog-content>
    <app-lists
      [inputSource]="data.inputSource"
      [fromLookup]="data.fromLookup"
      [text]="data.text"
      [lookupType]="data.lookupType"
    ></app-lists>
</div>
<div mat-dialog-actions align="center">
    <button [mat-dialog-close]="addedData" mat-stroked-button color="primary">
        Close
    </button>
</div>
