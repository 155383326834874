<mat-card>
  <mat-card-title class="mat-card-title">
      What's New Services
  </mat-card-title>

  <dx-data-grid
    #dataGrid
    [dataSource]="services"
    width="100%"
    noDataText="No records. Click + above to add"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onEditingStart)="onEditingStart($event)"
  >

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true">
      <dxo-popup
        title="New Service"
        [showTitle]="true"
        [width]="700">
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="ServiceName">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="LearnMoreLink">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="Description" editorType="dxTextArea">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="Active"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Image" [colCount]="2" [colSpan]="2">
          <dxi-item [colSpan]="2" dataField="ImageLink" [label]="{visible: false}"></dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>
    
    <dxi-column caption="Service Name" dataField="ServiceName"></dxi-column>
    <dxi-column caption="Learn More Link" dataField="LearnMoreLink" cellTemplate="linkCellTemplate"></dxi-column>
    <dxi-column caption="Description" dataField="Description"></dxi-column>
    <dxi-column caption="Active" dataField="Active" dataType="boolean" cssClass="inline-checkbox"></dxi-column>
    <dxi-column dataField="ImageLink" caption="Image" cellTemplate="imageCellTemplate" editCellTemplate="editCellTemplate"></dxi-column>

    <div *dxTemplate="let data of 'linkCellTemplate'">
      <a target="_blank" [href]="data.value">{{data.value}}</a>
    </div>
    <div *dxTemplate="let data of 'imageCellTemplate'">
      <img class="img-thumbnail uploaded-grid-image" [src]="data.value"/>
    </div>
    <div *dxTemplate="let data of 'editCellTemplate'">
      <img class="img-thumbnail uploaded-image" [src]="data.value" *ngIf="data.value" />
      <dx-file-uploader
        accept="image/*"
        uploadMode="useForm"
        [multiple]="false"
        (onValueChanged)="docUploaded($event)">
      </dx-file-uploader>
    </div>

  </dx-data-grid>
</mat-card>

