import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {YesNoConfirmationComponent} from './yes-no-confirmation.component';
import {
  DxButtonModule,
  DxPopupModule,
} from 'devextreme-angular';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    DxPopupModule, DxButtonModule, DxPopupModule  ],
  declarations: [YesNoConfirmationComponent],
  exports: [YesNoConfirmationComponent]
})
export class YesNoConfirmationModule {
}
