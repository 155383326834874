import { Pipe, PipeTransform } from '@angular/core';
import { NavPagesLink } from '../components/common/navigation/navigation.component';

@Pipe({
  name: 'navFilter',
})
export class NavFilterPipe implements PipeTransform {

  /**
   * The NavFilterPipe is used to filter NavPagesLinks that have subscribed set to
   * true. This is necessary because you can't have multiple template bindings on one element.
   * @param pages: The NavPagesLinks to filter on.
   */
  transform(pages: NavPagesLink[]): NavPagesLink[] {
    return pages.filter(page => page.subscribed);
  }

}
