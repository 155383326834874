import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Phone } from '../../../models/phones';
import Guid from 'devextreme/core/guid';
import { LookupService } from '../../../services/app-lookup.service';
import { DxDataGridComponent, DxTextBoxComponent } from 'devextreme-angular';
import { formatNumber } from 'libphonenumber-js';
import { AppConstants } from '../../../shared/AppConstants';
import { ObjectUtilities } from '../../../shared/ObjectUtilities';
import { GridService } from '../../../services/grid.service';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;
import { ReminderService } from '../../../services/reminder.service';
import { NotifyService } from '../../../common/notify/notify.service';


@Component({
  selector: 'app-phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class PhoneListComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild('txNumber', {static: true}) txNumber: DxTextBoxComponent;
  @Input() width: any;
  @Input() model: Phone[];
  @Output() modelChange = new EventEmitter<any>();
  @Output() phoneUpdated = new EventEmitter<any>();
  @Input() readOnlyField = false;
  @Input() inputsDisabled = false;
  @Input() singlePhone = false;
  @Input() isValid = true;
  @Input() showDoNotContact = false;
  @Input() shouldFocus = false;

  phoneMask = AppConstants.PHONE_MASK;
  phonePattern: any = AppConstants.PHONE_PATTERN;
  phoneRules: any = AppConstants.PHONE_RULES;
  controlWidth: any;
  phoneNumberListPopup: any;
  phones: Phone[];
  smsPopup: any;
  smsTemplate: any;
  phoneTypes: any;
  phoneCount: number;
  phoneRule: any;
  testCallPopup: any;
  _defaultNumber: string;
  isDefaultNumberBad = false;
  testCallTitle: string;
  canDialNumber = false;
  onSaveFn: any;
  onCancelFn: any;
  isNewRow = true;
  randomId =  new Guid().toString();

  get defaultNumber(): string {

    if (!isNullUndefinedOrEmpty(this.phones)) {
      const filteredNumbers = Array.isArray(this.phones) ? this.phones.filter(item => !item.isDeleted) : [];
      if (filteredNumbers.length > 0) {
        this.phoneCount = filteredNumbers.length;
        const defaultNumbers = filteredNumbers.filter(item => item.IsDefault === true);

        if (defaultNumbers.length > 0) {
          this._defaultNumber = defaultNumbers[0].Number;
          this.isDefaultNumberBad = defaultNumbers[0].IsBad;
          return this._defaultNumber;
        }
      } else {
        this._defaultNumber = '';
        return this._defaultNumber;
      }
    }
  }

  set defaultNumber(value: string) {
    if (value !== undefined && value !== '') {
      this._defaultNumber = value;
      this.updateDefaultNumber(value);
    } else {
      this._defaultNumber = '';
    }
  }

  constructor(
    private lookupService: LookupService,
    private gridService: GridService,
    private reminderService: ReminderService,
    private notify: NotifyService,
  ) {
    this.testCallPopup = false;
    this.controlWidth = '100%';
    this.phoneCount = 0;
    this.phoneTypes = this.lookupService.getLookupStatic('PhoneTypes');
    this.phoneRule = {'X': /[02-9]?/};
  }

  // update phone count, call anytime to update count number after the phone editor
  updatePhoneCount() {
    this.phoneCount = this.phones.filter(item => !item.isDeleted).length;
  }

  // Get the index of default number from the phones array and return the index value
  getDefaultNumberIndex() {
    return this.phones.map(phoneNumber => {
      return phoneNumber.Number;
    }).indexOf(this.defaultNumber);
  }

  // Delete the default number and remove the same from the default
  deleteAndRemoveFromDefault() {
    const defaultNumberIndex = this.getDefaultNumberIndex();
    if (defaultNumberIndex > -1) {
      this.phones[defaultNumberIndex].IsDefault = false;
      this.phones[defaultNumberIndex].isDeleted = true;
      this.phones[defaultNumberIndex].isDirty = true;
      this.filterActiveRows();
      this.updatePhoneCount();

      if(this.phoneCount > 0) {
        const filteredPhones = this.phones.filter(item => !item.isDeleted && !item.IsDefault && !item.IsBad);
        if(filteredPhones.length > 0) {
          filteredPhones[0].IsDefault = true;
        }
      }
    }
  }

  onFocusOut(e) {
    if (!e.component._value.trim('')) { // #
      this.deleteAndRemoveFromDefault();
    }

    // EM - man, what a silly workaround simply because empty string does not pass validation
    setTimeout(function () {
      const value = e.component.option('value');
      if (value !== undefined && !value.length) {
        e.component.option('isValid', true);
      }
    });
  }

  ngOnInit() {
    if (this.model == null) {
      this.model = [];
    }
    this.phones = this.model;
    if (this.showDoNotContact) {
      for (const phone of this.phones) {
        phone.DoNotContact = phone?.exclusions?.data?.length > 0 || false;
      }
    }
    if (this.width == null) {
      this.controlWidth = this.width;
    }
  }

  ngAfterViewInit(): void {
    if(this.shouldFocus) {
      if(!!this.txNumber) {
        this.txNumber.instance.focus();
      }
    }
  }

  onValueChanged(e) {
    if (e.value === undefined || e.value.length === 0) {
      e.component.option('isValid', true);
      this.canDialNumber = false;
    } else if (e.value.length === 10 && window.screen.width < 768) {
      this.canDialNumber = true;
    }
    this.phoneUpdated.emit(e.value);
  }

  filterActiveRows() {
    if (this.dataGrid !== undefined) {
      this.dataGrid.instance.filter(['!', ['isDeleted', '=', true]]);
      this.dataGrid.instance.refresh();
    }
  }

  initNewPhone(phoneNumber: string) {
    const phone = new Phone();
    phone.Number = phoneNumber;
    phone.IsDefault = true;
    phone.IsBad = false;
    phone.PhoneType = 'M';
    phone.DoNotContact = false;
    phone.isDeleted = false;
    phone.isAdded = true;
    phone.Id = new Guid().toString();
    return phone;
  }

  // Reset default phone from all existing phones available
  resetDefaultPhone() {
    for (let i = 0; i < this.phones.length; i++) {
      this.phones[i].IsDefault = false;
    }
    this.filterActiveRows();
  }

  // Set any number to default by passing the Number
  setDefaultNumber(id) {
    const phone = this.phones.filter(item => !item.isDeleted && item.Id === id);
    if (phone.length) {
      phone[0].IsDefault = true;
    } else {
      return false;
    }
  }

  setCellValue = (newData, value, currentRowData) => {
    if (!currentRowData.Number) { // Do not handle default if number is not filled in input box
      newData.IsDefault = false;
      this.filterActiveRows();
      return true;
    }
    this.resetDefaultPhone();
    if (!!currentRowData.Id && value) {
      this.setDefaultNumber(currentRowData.Id);
    }

    this.filterActiveRows();
  };

  toggleDefault() {
    //  var qry = this.phones.createQuery();
    const filteredNumbers = this.phones.filter(item => !item.isDeleted && item.IsDefault);
    if (filteredNumbers.length > 0) {
      filteredNumbers.forEach((o) => {
        o.IsDefault = false;
      });
    }
    this.filterActiveRows();
  }


  saveGrid(close) {
    if (this.validateRows()) {
      this.dataGrid.instance.saveEditData();
      if (close) {
        this.setDefaultBeforeSaving();
        this.phoneNumberListPopup = false;
      }
    }
    this.phoneUpdated.emit(this.phones);
  }

  validateRows() {

    this.dataGrid.instance.getVisibleRows().forEach(
      item => {
        if (item.isEditing) {

          if (item.values[2] == null) {
            return false;
          }
        }
      },
    );

    return true;
  }

  setDefaultBeforeSaving() {
    const fltPhones = this.phones.filter(phone => phone.IsDefault && !phone.isDeleted);
    if (fltPhones.length > 0) {
      this.resetDefaultPhone();
      fltPhones[0].IsDefault = true;
    } else {
      const mobile = this.phones.filter(phone => phone.PhoneType === 'M' && !phone.isDeleted && !phone.IsBad)[0];
      if (!!mobile) {
        mobile.IsDefault = true;
      } else if (!!(this.phones.filter(phone => phone.PhoneType === 'H' && !phone.isDeleted && !phone.IsBad)[0])) {
        this.phones.filter(phone => phone.PhoneType === 'H' && !phone.isDeleted && !phone.IsBad)[0].IsDefault = true;
      } else if (!!(this.phones.filter(phone => phone.PhoneType === 'W' && !phone.isDeleted && !phone.IsBad)[0])) {
        this.phones.filter(phone => phone.PhoneType === 'W' && !phone.isDeleted && !phone.IsBad)[0].IsDefault = true;
      } else if (!!(this.phones.filter(phone => phone.PhoneType === 'F' && !phone.isDeleted && !phone.IsBad)[0])) {
        this.phones.filter(phone => phone.PhoneType === 'F' && !phone.isDeleted && !phone.IsBad)[0].IsDefault = true;
      }
    }
  }

  addNewPhone(e) {
    this.phoneNumberListPopup = true;
  }

  updateDefaultNumber(phoneNumber: string) {
    if (this.phones.length > 0) {
      const filteredNumbers = this.phones.filter(item => item.IsDefault && !item.isDeleted);
      if (filteredNumbers.length === 0) {
        this.phones.push(this.initNewPhone(phoneNumber));
      } else {
        filteredNumbers[0].Number = phoneNumber;
        filteredNumbers[0].isDirty = true;
      }
    } else {
      if (phoneNumber !== undefined && phoneNumber !== '') {
        this.phones.push(this.initNewPhone(phoneNumber));
      }
    }
    this.filterActiveRows();
  }

  makeTestCall(e) {
    this.testCallTitle = 'Test ' + formatNumber(this.defaultNumber, 'US', 'National');
    this.testCallPopup = true;
  }

  dialTelNo() {
    return 'tel:' + this.defaultNumber;
  }

  closePopup(navRoute) {  // Hide all Popup
    this.smsPopup = false;
    this.smsTemplate = false;
    this.smsTemplate = false;
    this.phoneNumberListPopup = false;
  }

  onCellPrepared(e) {
    this.gridService.updateGridIcons(e);
  }

  onEditorPreparing(e) {
    const fields = [
      {'name': 'Number', 'maxLength': 20},
    ];


    const masks = [
      {'name': 'Number', 'mask': this.phoneMask},
    ];

    if (e.parentType === 'dataRow') {
      const result = fields.find(x => x.name === e.dataField);
      if (result !== undefined) {
        e.editorOptions.inputAttr.maxLength = result.maxLength;
        e.editorOptions.maxLength = result.maxLength;
      }

      const mask = masks.find(x => x.name === e.dataField);
      if (mask !== undefined && e.editorElement) {
        e.editorOptions.mask = mask.mask;
      }

      // this mess is to hide isDefault and isBad for freshly added numbers,
      // requiring user to reopen the number to set these values
      if (!!e.dataField
        && (e.dataField === 'IsDefault' || e.dataField === 'IsBad')
        && !!e.row.data
        && typeof (e.row.data.Id) === 'string'
        && e.row.data.Number == null) {
        e.visible = false;
      }

    }
  }

  onInitNewRow(e) {
    const activeNumbers = this.phones.filter(item => !item.isDeleted);
    const defaultNumbers = activeNumbers.filter(item => item.IsDefault);

    e.data.Id = new Guid().toString();
    e.data.IsBad = false;
    e.data.isDeleted = false;
    e.data.DoNotContact = false;
    e.data.isAdded = true;
    e.data.IsDefault = false;
    e.data.PhoneType = 'M';
    this.isNewRow = true;
    this.filterActiveRows();
  }

  onRowInserted(e) {
    this.filterActiveRows();
  }

  onEditingStart(e) {
    this.isNewRow = false;
  }

  onRowInserting(e) {
    const newPhone = new Phone(e.data);
    const check = this.phones.find(x => x.Number === newPhone.Number);
    if (check) {
      e.cancel = true;
      this.dataGrid.instance.cancelEditData();
      return;
    }
    this.phones.push(newPhone);
    this.dataGrid.instance.cancelEditData();
    this.filterActiveRows();
    e.cancel = true;
  }

  onRowUpdating(e) {
    if (e.newData.Number) {
      const check = this.phones.find(x => x.Number === e.newData.Number);
      if(check) {
        e.cancel = true;
        this.dataGrid.instance.cancelEditData();
      }
    }
  }
  onRowRemoving(e) {
    e.data.isDeleted = true;
    e.data.isDirty = false;
    const searchResult = this.phones.find(item => item.Id === e.key);
    searchResult.isDeleted = true;

    const activeNumbers = this.phones.filter(item => !item.isDeleted);
    const defaultNumbers = activeNumbers.filter(item => !item.IsDefault);
    this.phoneCount = activeNumbers.length;

    if (defaultNumbers.length === 0) {
      this._defaultNumber = '';
      this.canDialNumber = false;
      this.setNumberValid();
    }

    this.filterActiveRows();
    e.cancel = true;
  }

  setNumberValid() {
    setTimeout(function () {
      if (this.txNumber !== undefined) {
        this.txNumber.isValid = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.model !== undefined && changes.model.currentValue !== undefined) {
      this.phones = changes.model.currentValue;
      if (this.showDoNotContact) {
        for (const phone of this.phones) {
          phone.DoNotContact = phone?.exclusions?.data?.length > 0 || false;
        }
      }
    }
  }

  resetForm() {

    if (this.phones != null) {
      this.phones.length = 0;
      this._defaultNumber = '';
      this.phoneCount = 0;
      this.modelChange.emit(this.phones);
      this.canDialNumber = false;
    }
  }

  toggleDoNotContact(event, data) {
    if (event.value) {
      const exclusionData = {
        data : {
          Number: data.data.Number,
        },
      };
      this.reminderService.addReminderExclusion(exclusionData).subscribe(res => {
        this.phones.map(phone => {

          if (phone.Id === data.data.Id) {
            phone.DoNotContact = true;
            phone.exclusions.data.push(res.data);
          }
        });
        this.notify.toast('Number added to Do Not Contact', 'success');
      });
    } else {
      if (!!data?.data?.exclusions?.data[0]?.Id) {
        this.gridService.deleteGridRow('reminderexclusions', data?.data?.exclusions?.data[0]?.Id).then(resp => {
          this.phones.map(phone => {
            if (phone.Id === data.data.Id) {
              phone.DoNotContact = false;
              phone.exclusions.data = [];
            }
          });
          this.notify.toast('Number removed from Do Not Contact', 'success');
        }, error => {
          this.notify.toast('Error removing number from Do Not Contact', 'error');
        });
      } else {
        this.notify.toast('Error removing number from Do Not Contact', 'error');
      }
    }
    console.log(data, event);
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach(function (item) {
        item.location = 'after';
        item.showText = 'always';
        if (item.name === 'addRowButton') {
          item.options.text = 'Add New Phone';
          item.options.icon = 'fal fa-plus';
          item.options.type = 'default';
          item.location = 'before';
        }
      },
    );

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.gridService.exportGrid(this.dataGrid, 'Phones');
          },
        },
      },
    );
  }

  customizeItem = (item) => {
    if ((item.dataField === 'IsDefault' || item.dataField === 'IsBad' || item.dataField === 'DoNotContact') && this.isNewRow) {
      item.visible = false;
    }
  }
}
