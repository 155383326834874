import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { CalendarUtilities } from '../shared/calendar-utilities';
import { CacheService } from './cache.service';
import { Lookup } from '../models/lookup';
import { PortoResponse } from '../models/porto-response';
import { ApiUtilities } from '../shared/ApiUtilities';
import {AppConstants} from '../shared/AppConstants';

@Injectable()
export class LookupService {
  dataWasAdded = new Subject<any>();
  lookupObjects: any = [];
  lastLookupObject: any;

  private postData: any;

  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(private http: HttpClient, private settings: SettingsService, private cache: CacheService) {
  }

  getLookup(
    lookupName: string,
    filterActiveIds: any = [],
    lookupSource?: string,
    includes?: string,
    resetCache?: boolean,
    customerId?: any,
    fromExternal?: boolean,
  ): Observable<any> {

    let customerIdParam = '';
    if (fromExternal === true) {
      customerIdParam = `&lookuptype=${lookupName}&customerId=${customerId}`;
      lookupName = 'external';
      customerId = null; // need to do this otherwise it is going in wrong api, only for outside api call, won't impact internal app
    }

    if (resetCache != null) {
      this.lookupObjects = this.lookupObjects.filter(item => item.name !== lookupName);
    }

    const foundLookup = this.lookupObjects.find(item => item.name === lookupName);

    // console.log('Getting lookup for lookupName: ', lookupName, ' lookupSource: ', lookupSource, ' foundLookup: ', foundLookup);

    if (includes != null) {
      includes = '&include=' + includes;
      this.cache.remove(`${lookupName}:includes`);
      this.cache.set(`${lookupName}:includes`, `${includes}`, false);
    } else {
      includes = '';
    }

    // if customerId is present, assume we're using the internal
    // (client auth) endpoint (not available for all lists!)
    let customerIdArg = '';
    let internalArg = '';
    if (customerId != null) {
      customerIdArg = `bycustomer/${customerId}/`;
      internalArg = 'internal/';
    }
    const key = lookupSource != null ?
      `${internalArg}${customerIdArg}lookups/byname/${lookupName}/all?limit=0${includes}` :
      `${lookupName}/${internalArg}${customerIdArg}lookup/all?limit=0${includes}${customerIdParam}`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    // // console.log('lookup', lookupName, key);
    let data: any = this.cache.getLookup(lookupName, key, resetCache);
    // // console.log('data from cache', data);
    if (!!data) {
      data = JSON.parse(data);
      if (filterActiveIds && filterActiveIds.length > 0) {
        data.data = data.data.filter(item => !item.Inactive || filterActiveIds.includes(item.Id));
      } else {
        data.data = data.data.filter(item => !item.Inactive);
      }
      return of(data); // Return cache data as observables
    } else { // Data not found in localStorage get it from API and set back to localStorage
      const lookupObject = this.http.get(url).pipe(
        map((res: any) => res),
        publishReplay(1),
        refCount(),
        catchError(error => throwError(error.error || 'Server Error')));
      lookupObject.pipe(take(1)).subscribe(resp => {
        this.cache.setLookup(lookupName, key, JSON.stringify(resp));
        if (filterActiveIds && filterActiveIds.length > 0) {
          resp.data = resp.data.filter(item => !item.Inactive || filterActiveIds.includes(item.Id));
        } else {
          resp.data = resp.data.filter(item => !item.Inactive);
        }
      });
      this.lastLookupObject = lookupObject;
      this.lookupObjects.push({'name': lookupName, 'lookup': lookupObject});
    }

    // }
    // else {
    //   this.lastLookupObject = foundLookup.lookup;
    // }
    return this.lastLookupObject;

  }

  clearCaching() {
    this.lookupObjects.length = 0;
  }

  // EM - to use filtered lookups, you must have implemented apiato search filtering at the API level for the endpoint
  getFilteredLookup(
    lookupName: string,
    filterField: string,
    filterValue: string,
    includes?: string,
    resetCache?: boolean,
  ): Observable<any> {
    // console.log('Getting lookup for lookupName: ', lookupName, ' filterField: ', filterField, ' filterValue: ', filterValue);

    if (resetCache != null) {
      this.lookupObjects = this.lookupObjects.filter(item => item.name !== lookupName);
    }

    if (includes != null) {
      includes = '&include=' + includes;
      this.cache.remove(`${lookupName}:includes`);
      this.cache.set(`${lookupName}:includes`, `${includes}`, false);
    } else {
      includes = '';
    }

    const key = `${lookupName}/lookup/all?limit=50&search=${filterField}:${filterValue}${includes}`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const data = ''; // this.cache.getLookup(lookupName, key); will be enabled after observables stability
    if (!!data) {
      return of(JSON.parse(data)); // Return cache data as observables
    } else {
      const filteredData = this.http.get(url).pipe(
        map((res: any) => res),
        publishReplay(1),
        refCount(),
        catchError(error => throwError(error.error || 'Server Error')) );
      filteredData.pipe(take(1)).subscribe();
      return filteredData;
    }
  }
  getLookupPromise(lookupName: string): Promise<any> {
    const key = `${lookupName}/lookup/all?limit=0`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const data = this.cache.getLookup(lookupName, key);
    if (!!data) {
      return of(JSON.parse(data)).toPromise(); // Return cache data as observables
    } else { // Data not found in localStorage get it from API and set back to localStorage
      return this.http.get(url)
        .toPromise().then(response => {
          this.cache.setLookup(lookupName, key, JSON.stringify(response));
          return response;
        })
        .catch(error => {
          throw new Error('Data Loading Error');
        });
    }
  }
  getLookupP(lookupName: string): Promise<any> {
    // console.log('Getting data from getLookupP for lookupName: ', lookupName);
    const key = `${lookupName}/lookup/all?limit=0`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const data = ''; // this.cache.getLookup(lookupName, key); will be enabled after observables stability
    if (!!data) {
      return of(JSON.parse(data)).toPromise(); // Return cache data as observables
    } else { // Data not found in localStorage get it from API and set back to localStorage
      return this.http.get(url)
        .toPromise().then(response => {
          // this.cache.setLookup(lookupName, key, JSON.stringify(response));
          return response;
        })
        .catch(error => {
          throw new Error('Data Loading Error');
        });
    }
  }

  getSingleLookUp(lookupName: string, id: string): Promise<any> {
    // console.log('About to get single lookup data for: ', lookupName, ' for id: ', id);
    if (!!id) {
      const key = `${this.settings.getBaseUrl()}/${lookupName}/${id}`;
      const data = ''; // this.cache.get(key); will be enabled after observables stability
      if (!!data) {
        return of(data).toPromise(); // Return cache data as observables
      } else { // Data not found in localStorage get it from API and set back to localStorage
        return this.http.get(key)
          .toPromise().then(response => {
            // this.cache.set(key, JSON.stringify(response));
            return response;
          })
          .catch(error => {
            throw new Error('Data Loading Error');
          });
      }
    }
  }

  // generic lookup Data Update
  updateLookupNewValue(lookupType: string, formData: any, lookupSource: string): Observable<any> {
    const postData = {
      LookupName: formData.Text,
      LookupCode: lookupType,
      LookupSource: lookupSource,
    };
    const updateData = this.http.post(`${this.settings.getBaseUrl()}/lookups`, postData).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
    this.cache.removeByLookupName(lookupType); // Remove cache data for this lookup with search filter and includes (if any)
    return updateData;
  }

  getLookupStatic(lookupName: string): any {
    switch (lookupName) {
      case 'Genders' : {
        return [{'Id': 'F', 'Text': 'Female'}, {'Id': 'M', 'Text': 'Male'}, {'Id': 'UN', 'Text': 'Unknown'}, {
          'Id': 'O',
          'Text': 'Other',
        }];
      }
      // Races taken from NIH: https://grants.nih.gov/grants/guide/notice-files/not-od-15-089.html
      case 'Races' : {
        return [{'Id': 'Asian', 'Text': 'Asian'},
          {'Id': 'Black', 'Text': 'Black'},
          {'Id': 'Hispanic', 'Text': 'Hispanic'},
          {'Id': 'Native American', 'Text': 'Native American'},
          {'Id': 'Pacific Islander', 'Text': 'Pacific Islander'},
          {'Id': 'White', 'Text': 'White'},
          {'Id': 'Unknown', 'Text': 'Unknown'}];
      }
      case 'HeightsFt' : {
        return [{'Id': '3\'', 'Text': '3\''}, {'Id': '4\'', 'Text': '4\''}, {'Id': '5\'', 'Text': '5\''}, {
          'Id': '6\'',
          'Text': '6\'',
        }, {'Id': '7\'', 'Text': '7\''}];
      }
      case 'HeightsIn' : {
        return [
          {'Id': '0\"', 'Text': '0\"'},
          {'Id': '1\"', 'Text': '1\"'},
          {'Id': '2\"', 'Text': '2\"'},
          {'Id': '3\"', 'Text': '3\"'},
          {'Id': '4\"', 'Text': '4\"'},
          {'Id': '5\"', 'Text': '5\"'},
          {'Id': '6\"', 'Text': '6\"'},
          {'Id': '7\"', 'Text': '7\"'},
          {'Id': '8\"', 'Text': '8\"'},
          {'Id': '9\"', 'Text': '9\"'},
          {'Id': '10\"', 'Text': '10\"'},
          {'Id': '11\"', 'Text': '11\"'},
        ];
      }
      case 'EyeColors' : {
        return [{'Id': 'Blue', 'Text': 'Blue'},
          {'Id': 'Brown', 'Text': 'Brown'},
          {'Id': 'Grey', 'Text': 'Grey'},
          {'Id': 'Green', 'Text': 'Green'},
          {'Id': 'Hazel', 'Text': 'Hazel'},
          {'Id': 'Other', 'Text': 'Other'},
          {'Id': 'UN', 'Text': 'Unknown'},
        ];
      }
      case 'HairColors' : {
        return [{'Id': 'Blue', 'Text': 'Blue'},
          {'Id': 'Bald', 'Text': 'Bald'},
          {'Id': 'Black', 'Text': 'Black'},
          {'Id': 'Blond', 'Text': 'Blond'},
          {'Id': 'Brown', 'Text': 'Brown'},
          {'Id': 'Green', 'Text': 'Green'},
          {'Id': 'Grey', 'Text': 'Grey'},
          {'Id': 'Orange', 'Text': 'Orange'},
          {'Id': 'Red', 'Text': 'Red'},
          {'Id': 'Shaved', 'Text': 'Shaved'},
          {'Id': 'White', 'Text': 'White'},
          {'Id': 'Yellow', 'Text': 'Yellow'},
          {'Id': 'Other', 'Text': 'Other'},
          {'Id': 'Unknown', 'Text': 'Unknown'},
        ];
      }
      case 'ClientStatus' : {
        return [{'Id': 'Prospect', 'Text': 'Prospect'}, {'Id': 'Client', 'Text': 'Client'}, {
          'Id': 'Dead',
          'Text': 'Dead Lead/Declined',
        }];
      }
      case 'NoSSNAlternates' : {
        return [
          {Id: 'DriverLicense', Text: 'Drivers License'},
          {Id: 'Address', Text: 'Address'},
          {Id: 'ZipCode', Text: 'Zip Code'},
        ];
      }
      case 'ContactTypes' : {
        return [{Id: 'Indemnitor', Text: 'Indemnitor'}, {
          Id: 'Reference',
          Text: 'Reference',
        }, {Id: 'Indemnitor Reference', Text: 'Indemnitor Reference'}];
      }
      case 'Relations' : {
        return [{Id: 'Aunt', Text: 'Aunt'},
          {Id: 'Boyfriend', Text: 'Boyfriend'},
          {Id: 'Brother', Text: 'Brother'},
          {Id: 'Cousin', Text: 'Cousin'},
          {Id: 'Daughter', Text: 'Daughter'},
          {Id: 'Employer', Text: 'Employer'},
          {Id: 'Father', Text: 'Father'},
          {Id: 'Fiance', Text: 'Fiance'},
          {Id: 'Friend', Text: 'Friend'},
          {Id: 'Girlfriend', Text: 'Girlfriend'},
          {Id: 'Granddaughter', Text: 'Granddaughter'},
          {Id: 'Grandfather', Text: 'Grandfather'},
          {Id: 'Grandmother', Text: 'Grandmother'},
          {Id: 'Grandson', Text: 'Grandson'},
          {Id: 'Husband', Text: 'Husband'},
          {Id: 'Mother', Text: 'Mother'},
          {Id: 'Nephew', Text: 'Nephew'},
          {Id: 'Niece', Text: 'Niece'},
          {Id: 'Other', Text: 'Other'},
          {Id: 'Partner', Text: 'Partner'},
          {Id: 'Sister', Text: 'Sister'},
          {Id: 'Son', Text: 'Son'},
          {Id: 'Spouse', Text: 'Spouse'},
          {Id: 'Uncle', Text: 'Uncle'},
          {Id: 'Wife', Text: 'Wife'},
          {Id: 'Accounts', Text: 'Accounts'},
          {Id: 'Owner', Text: 'Owner'},
        ]; // TODO - check if this should be db lookup
      }
      case 'Relations_Bail' : {
        return [{Id: 'Aunt', Text: 'Aunt'},
          {Id: 'Boyfriend', Text: 'Boyfriend'},
          {Id: 'Brother', Text: 'Brother'},
          {Id: 'Cousin', Text: 'Cousin'},
          {Id: 'Daughter', Text: 'Daughter'},
          {Id: 'Employer', Text: 'Employer'},
          {Id: 'Father', Text: 'Father'},
          {Id: 'Fiance', Text: 'Fiance'},
          {Id: 'Friend', Text: 'Friend'},
          {Id: 'Girlfriend', Text: 'Girlfriend'},
          {Id: 'Granddaughter', Text: 'Granddaughter'},
          {Id: 'Grandfather', Text: 'Grandfather'},
          {Id: 'Grandmother', Text: 'Grandmother'},
          {Id: 'Grandson', Text: 'Grandson'},
          {Id: 'Mother', Text: 'Mother'},
          {Id: 'Nephew', Text: 'Nephew'},
          {Id: 'Niece', Text: 'Niece'},
          {Id: 'Other', Text: 'Other'},
          {Id: 'Sister', Text: 'Sister'},
          {Id: 'Son', Text: 'Son'},
          {Id: 'Spouse', Text: 'Spouse'},
          {Id: 'Uncle', Text: 'Uncle'}]; // TODO - check if this should be db lookup
      }
      case 'CollateralTypes' : {
        return [{Id: 'Cash', Text: 'Cash'},
          {Id: 'Indemnity Agreement', Text: 'Indemnity Agreement'},
          {Id: 'Mobile Home', Text: 'Mobile Home'},
          {Id: 'Real Property', Text: 'Real Property'},
          {Id: 'Signature', Text: 'Signature'},
          {Id: 'Vehicle', Text: 'Vehicle'},
          {Id: 'Promissory Note', Text: 'Promissory Note'},
          {Id: 'Other', Text: 'Other'},
          {Id: 'Credit Card', Text: 'Credit Card'},
          {Id: 'Check', Text: 'Check'},
          {Id: 'Money Order', Text: 'Money Order'}]; // TODO - check if this should be db lookup
      }
      case 'CollateralStatuses' : {
        return [{Id: 'Open', Text: 'Open'}, {Id: 'Closed', Text: 'Closed'}]; // TODO - check if this should be db lookup
      }
      case 'LawSuitTypes' : {
        return [{Id: 'Civil Suit', Text: 'Civil Suit'}, {Id: 'Criminal', Text: 'Criminal'}, {
          Id: 'Small Claims',
          Text: 'Small Claims',
        }]; // TODO - check if this should be db lookup
      }
      case 'SetFor' : {
        return [{Id: 'Trial', Text: 'Trial'}, {Id: 'Arraignment', Text: 'Arraignment'}]; // TODO - check if this should be db lookup
      }
      case 'CalendarItemTypes' : {
        return CalendarUtilities.getCalendarEntryTypes();
      }
      case 'AddressTypes': {
        return [{Id: 'H', Text: 'Home'}, {Id: 'W', Text: 'Work'}];
      }
      case 'PhoneTypes' : {
        return [{Id: 'H', Text: 'Home'}, {Id: 'M', Text: 'Mobile'}, {Id: 'W', Text: 'Work'}, {Id: 'F', Text: 'Fax'}];
      }
      case 'EmailTypes': {
        return [{Id: 'P', Text: 'Personal'}, {Id: 'W', Text: 'Work'}, {Id: 'O', Text: 'Other'}];
      }
      case 'SocialNetworkTypes': {
        return [{Id: 'Facebook', Text: 'Facebook', System: true, PlaceHolder: 'facebook.com/name url'},
          {Id: 'Twitter', Text: 'Twitter', System: true, PlaceHolder: '@twittername'},
          {Id: 'Instagram', Text: 'Instagram', System: true, PlaceHolder: 'Instagram.com/username'},
          {Id: 'LinkedIn', Text: 'LinkedIn', System: true, PlaceHolder: 'LinkedIn URL'},
          {Id: 'Other', Text: 'Other'}];
      }
      case 'AdiAuthenticationTypes' : {
        return [{Id: 'ADI ID', Text: 'ADI ID'}, {Id: 'DOBSSN', Text: 'Date of Birth/SSN'}, {
          Id: 'SSNYEAROB',
          Text: 'Year of Birth/SSN',
        }];
      }
      case 'AdiNotificationTypes_DNU' : {
        return [{Id: 'CourtDate', Text: 'Court Date'}, {Id: 'Payment', Text: 'PaymentReminder'}, {
          Id: 'PastDue',
          Text: 'Past Due',
        }, {Id: 'MissedCheckin', Text: 'Missed Check Ins'}];
      } // TODO - need to take this out later as i have added tables to handle this
      case 'reminderChannels' : {
        return [
          {Id: 'SMS', Text: 'SMS'},
          // , {Id: 'EMAIL', Text: 'Email'}
          // , {Id: 'AO', Text: 'ADI Calling Only'}
        ];
      }
      case 'SalesReportsOptions' : {
        return [
          {Id: 'Edit', Text: 'Edit an existing sales report'},
          {Id: 'Delete', Text: 'Delete an existing sales report'},
          {Id: 'Reprint', Text: 'Reprint an existing sales report'},
          {Id: 'Create', Text: 'Create a new sales report'},
        ];
      }
      case 'DischargeReportsOptions' : {
        return [
          {Id: 'Edit', Text: 'Edit an existing exoneration report'},
          {Id: 'Delete', Text: 'Delete an existing exoneration report'},
          {Id: 'Reprint', Text: 'Reprint an existing exoneration report'},
          {Id: 'Create', Text: 'Create a new exoneration report'},
        ];
      }
      case 'paymentMethods': {
        return [{Id: 'Check', Text: 'Check'}, {Id: 'CC', Text: 'Credit Card'}, {
          Id: 'MO',
          Text: 'Money Order',
        }, {Id: 'Cash', Text: 'Cash'}];
      }

      case 'campaignTypes' : {
        return [{Id: 'All', Text: 'All'}, {Id: 'Court', Text: 'Court Date'}, {
          Id: 'PastDue',
          Text: 'Past Due',
        }, {Id: 'Payment', Text: 'Payment Reminder'}, {Id: 'MissedCheckin', Text: 'Missed Check In'},
          {Id: 'Spoof', Text: 'Spoof'}];
      }

      case 'transferTypes' : {
        return [{Id: 'Posting', Text: 'Posting'}, {Id: 'Requesting', Text: 'Requesting'}];
      }

      case 'netBasis' : {
        return [{Id: 'Liability', Text: 'Liability'}, {Id: 'Gross', Text: 'Gross'}];
      }

      case 'bufBasis' : {
        return [{Id: 'Liability', Text: 'Liability'}, {Id: 'Gross', Text: 'Gross'}];
      }

      case 'repeats' : {
        return [
          {Id: 'None', Text: 'None'},
          {Id: 'Daily', Text: 'Daily'},
          {Id: 'Weekly', Text: 'Weekly'},
          {Id: 'Bi-Weekly', Text: 'Bi-Weekly'},
          {Id: 'Monthly', Text: 'Monthly'},
        ];
      }

      case 'events' : {
        return [{Id: 'Check-In', Text: 'Check-In'}, {Id: 'Drug-Test', Text: 'Drug Test'}, {
          Id: 'Payment-Due',
          Text: 'Payment Due',
        }, {Id: 'Court-Date', Text: 'Court Date'}];
      }
      case 'AdiDaysBefore' : {
        return [{Id: '0', Text: 'On the day'},
          {Id: '1', Text: '1 Day'},
          {Id: '2', Text: '2 Days'},
          {Id: '3', Text: '3 Days'},
          {Id: '4', Text: '4 Days'},
          {Id: '5', Text: '5 Days'},
          {Id: '6', Text: '6 Days'},
          {Id: '7', Text: '7 Days'},

        ];
      }
      case 'reminderIntervalFrequencies': {
        return [{Id: 'days', Text: 'Days'},
          {Id: 'weeks', Text: 'Weeks'},
        ];
      }
      case 'LeadScores': {
        return [
          {Id: 'Execution Pending', text: 'Execution Pending', class: 'badge badge-info', value: 'Execution Pending'},
          {Id: 'Hot', text: 'Hot', class: 'badge badge-unassigned', value: 'Hot'},
          {Id: 'Warm', text: 'Warm', class: 'badge badge-warning', value: 'Warm'},
          {Id: 'Cold', text: 'Cold', class: 'badge badge-pending', value: 'Cold'},
          {Id: 'Unassigned', text: 'Unassigned', class: 'badge badge-success', value: 'Unassigned'},
        ];
      }

      case 'DefendantMessageTypes': {
        return [
          {
            Id: 'RedFlagMessage',
            BadgeClass: 'badge alert-danger',
            AlertClass: 'alert alert-danger',
            Priority: 1,
            ToastType: 'error',
            showToast: true,
          },
          {
            Id: 'UrgentNoticeMessage',
            BadgeClass: 'badge alert-danger',
            AlertClass: 'alert alert-danger',
            Priority: 2,
            ToastType: 'error',
            showToast: true,
          },
          {
            Id: 'AtCollectionsMessage',
            BadgeClass: 'badge alert-danger',
            AlertClass: 'alert alert-danger',
            Priority: 3,
            ToastType: 'error',
            showToast: true,
          },
          {
            Id: 'ActiveForfeitureBondCount',
            BadgeClass: 'badge alert-danger',
            AlertClass: 'alert alert-danger',
            Priority: 4,
            ToastType: 'error',
            showToast: true,
          },
          {
            Id: 'OverdueBalanceMessage',
            BadgeClass: 'badge alert-warning',
            AlertClass: 'alert alert-warning',
            Priority: 5,
            ToastType: 'error',
            showToast: false,
          },
          {
            Id: 'PastForfeitureBondCount',
            BadgeClass: 'badge alert-warning',
            AlertClass: 'alert alert-warning',
            Priority: 6,
            ToastType: 'error',
            showToast: false,
          },
          {
            Id: 'AdjustmentsMessage',
            BadgeClass: 'badge alert-warning',
            AlertClass: 'alert alert-warning',
            Priority: 7,
            ToastType: 'error',
            showToast: false,
          },
          {
            Id: 'InJailMessage',
            BadgeClass: 'badge alert-info',
            AlertClass: 'alert alert-info',
            Priority: 8,
            ToastType: 'info',
            showToast: false,
          },
          {
            Id: 'OpenBondCount',
            BadgeClass: 'badge alert-info',
            AlertClass: 'alert alert-info',
            Priority: 9,
            ToastType: 'info',
            showToast: false,
          },
        ];
      }

      case 'paymentgateways': {
        return [
          {
            Id: 'Stripe', Text: 'Stripe', AllowCustom: false, CustomFields: [
              { Id: 'publishable_key', Text: 'Publishable Key' },
              { Id: 'secret_key', Text: 'Secret Key' },
            ], IsPeerToPeer: false, logoName: 'Stripe', SubText: 'Credit Card', isExpanded: false, MobileNumber: '', Username: '', ApiKey: '', TransactionId: '', IsConnected: false, IsDisabled: false,
          },
          {
            Id: 'Paypal', Text: 'PayPal', AllowCustom: false, CustomFields: [
              { Id: 'client_id', Text: 'Client ID' },
              { Id: 'secret', Text: 'Secret' },
            ], IsPeerToPeer: false, logoName: 'PayPal', SubText: 'Credit Card | Paypal', isExpanded: false, MobileNumber: '', Username: '', ApiKey: '', TransactionId: '', IsConnected: false, IsDisabled: false,
          },
          {Id: 'Authorize.net', Text: 'Authorize.net', AllowCustom: false, IsPeerToPeer: false, logoName: 'Auth.net', SubText: 'Credit Card', isExpanded: false, MobileNumber: '', Username: '', ApiKey: '', TransactionId: '', IsConnected: false, IsDisabled: false,},
          { Id: 'Nuvei', Text: 'Nuvei', AllowCustom: false, IsPeerToPeer: false, logoName: 'Nuvei', SubText: 'Credit Card', isExpanded: false, MobileNumber: '', Username: '', ApiKey: '', TransactionId: '', IsConnected: false, IsDisabled: false,},
        ];

      }

      case 'peerToPeerLinks': {
        return [
          {Id: 'CashApp', Text: 'Cash App', IsPeerToPeer: true, logoName: 'CashApp', SubText: 'Digital Wallet', isExpanded: false, MobileNumber: '', Username: '', ApiKey: '', TransactionId: '', IsConnected: false, IsDisabled: false,},
          {Id: 'Venmo', Text: 'Venmo', IsPeerToPeer: true, logoName: 'Venmo', SubText: 'Digital Wallet', isExpanded: false, MobileNumber: '', Username: '', ApiKey: '', TransactionId: '', IsConnected: false, IsDisabled: false,},
          {Id: 'Zelle', Text: 'Zelle', IsPeerToPeer: true, logoName: 'Zelle', SubText: 'Digital Wallet', isExpanded: false, MobileNumber: '', Username: '', ApiKey: '', TransactionId: '', IsConnected: false, IsDisabled: false,},
        ];

      }

      case 'industries': {
        return [
          {Id: 'Accounting', Text: 'Accounting'},
          {Id: 'Administration & Office Support', Text: 'Administration & Office Support'},
          {Id: 'Advertising & Media', Text: 'Advertising & Media'},
          {Id: 'Bail, Probation and Justice', Text: 'Bail, Probation and Justice'},
          {Id: 'Banking & Financial Services', Text: 'Banking & Financial Services'},
          {Id: 'Construction', Text: 'Construction'},
          {Id: 'Education & Training', Text: 'Education & Training'},
          {Id: 'Government', Text: 'Government'},
          {Id: 'Healthcare & Medical', Text: 'Healthcare & Medical'},
          {Id: 'Hospitality & Tourism', Text: 'Hospitality & Tourism'},
          {Id: 'Information & Communication Technology', Text: 'Information & Communication Technology'},
          {Id: 'Insurance & Legal', Text: 'Insurance & Legal'},
          {Id: 'Manufacturing, Transport & Logistics', Text: 'Manufacturing, Transport & Logistics'},
          {Id: 'Sales, Marketing & Communications', Text: 'Sales, Marketing & Communications'},
          {Id: 'Mining, Resources & Energy', Text: 'Mining, Resources & Energy'},
          {Id: 'Professional Services', Text: 'Professional Services'},
          {Id: 'Real Estate & Property', Text: 'Real Estate & Property'},
          {Id: 'Retail & Consumer Products', Text: 'Retail & Consumer Products'},
          {Id: 'Other', Text: 'Other'},
        ];

      }

      case 'ResidenceTypes': {
        return [
          {Id: 'Own', Text: 'Own'},
          {Id: 'Rent', Text: 'Rent'},
        ];
      }

      case 'states': {
        return [
          {
            "object": "State",
            "Id": "AK",
            "Text": "Alaska"
          },
          {
            "object": "State",
            "Id": "AL",
            "Text": "Alabama"
          },
          {
            "object": "State",
            "Id": "AR",
            "Text": "Arkansas"
          },
          {
            "object": "State",
            "Id": "AZ",
            "Text": "Arizona"
          },
          {
            "object": "State",
            "Id": "CA",
            "Text": "California"
          },
          {
            "object": "State",
            "Id": "CO",
            "Text": "Colorado"
          },
          {
            "object": "State",
            "Id": "CT",
            "Text": "Connecticut"
          },
          {
            "object": "State",
            "Id": "DC",
            "Text": "District of Columbia"
          },
          {
            "object": "State",
            "Id": "DE",
            "Text": "Delaware"
          },
          {
            "object": "State",
            "Id": "FL",
            "Text": "Florida"
          },
          {
            "object": "State",
            "Id": "GA",
            "Text": "Georgia"
          },
          {
            "object": "State",
            "Id": "HI",
            "Text": "Hawaii"
          },
          {
            "object": "State",
            "Id": "IA",
            "Text": "Iowa"
          },
          {
            "object": "State",
            "Id": "ID",
            "Text": "Idaho"
          },
          {
            "object": "State",
            "Id": "IL",
            "Text": "Illinois"
          },
          {
            "object": "State",
            "Id": "IN",
            "Text": "Indiana"
          },
          {
            "object": "State",
            "Id": "KS",
            "Text": "Kansas"
          },
          {
            "object": "State",
            "Id": "KY",
            "Text": "Kentucky"
          },
          {
            "object": "State",
            "Id": "LA",
            "Text": "Louisiana"
          },
          {
            "object": "State",
            "Id": "MA",
            "Text": "Massachusetts"
          },
          {
            "object": "State",
            "Id": "MD",
            "Text": "Maryland"
          },
          {
            "object": "State",
            "Id": "ME",
            "Text": "Maine"
          },
          {
            "object": "State",
            "Id": "MI",
            "Text": "Michigan"
          },
          {
            "object": "State",
            "Id": "MN",
            "Text": "Minnesota"
          },
          {
            "object": "State",
            "Id": "MO",
            "Text": "Missouri"
          },
          {
            "object": "State",
            "Id": "MS",
            "Text": "Mississippi"
          },
          {
            "object": "State",
            "Id": "MT",
            "Text": "Montana"
          },
          {
            "object": "State",
            "Id": "NC",
            "Text": "North Carolina"
          },
          {
            "object": "State",
            "Id": "ND",
            "Text": "North Dakota"
          },
          {
            "object": "State",
            "Id": "NE",
            "Text": "Nebraska"
          },
          {
            "object": "State",
            "Id": "NH",
            "Text": "New Hampshire"
          },
          {
            "object": "State",
            "Id": "NJ",
            "Text": "New Jersey"
          },
          {
            "object": "State",
            "Id": "NM",
            "Text": "New Mexico"
          },
          {
            "object": "State",
            "Id": "NV",
            "Text": "Nevada"
          },
          {
            "object": "State",
            "Id": "NY",
            "Text": "New York"
          },
          {
            "object": "State",
            "Id": "OH",
            "Text": "Ohio"
          },
          {
            "object": "State",
            "Id": "OK",
            "Text": "Oklahoma"
          },
          {
            "object": "State",
            "Id": "OR",
            "Text": "Oregon"
          },
          {
            "object": "State",
            "Id": "PA",
            "Text": "Pennsylvania"
          },
          {
            "object": "State",
            "Id": "RI",
            "Text": "Rhode Island"
          },
          {
            "object": "State",
            "Id": "SC",
            "Text": "South Carolina"
          },
          {
            "object": "State",
            "Id": "SD",
            "Text": "South Dakota"
          },
          {
            "object": "State",
            "Id": "TN",
            "Text": "Tennessee"
          },
          {
            "object": "State",
            "Id": "TX",
            "Text": "Texas"
          },
          {
            "object": "State",
            "Id": "UT",
            "Text": "Utah"
          },
          {
            "object": "State",
            "Id": "VA",
            "Text": "Virginia"
          },
          {
            "object": "State",
            "Id": "VT",
            "Text": "Vermont"
          },
          {
            "object": "State",
            "Id": "WA",
            "Text": "Washington"
          },
          {
            "object": "State",
            "Id": "WI",
            "Text": "Wisconsin"
          },
          {
            "object": "State",
            "Id": "WV",
            "Text": "West Virginia"
          },
          {
            "object": "State",
            "Id": "WY",
            "Text": "Wyoming"
          },
        ];
      }

      case 'TimeSlots': {
        return AppConstants.TIMESLOTS;
      }

      case 'defendantFields' : {
        return  {
          FirstName: {name: 'First Name', visible: true, required: true, group: 'defendant', isDefault: true},
          MiddleName: {name: 'Middle Name', visible: false, required: false, group: 'defendant', isDefault: false},
          LastName: {name: 'Last Name', visible: true, required: true, group: 'defendant', isDefault: true},
          Dob: {name: 'DOB', visible: true, required: true, group: 'defendant', isDefault: true},
          Alias: {name: 'Alias Name', visible: false, required: false, group: 'defendant', isDefault: false},
          Ssn: {name: 'SSN', visible: true, required: false, group: 'defendant', isDefault: false},
          Phone: {name: 'Mobile Phone', visible: true, required: true, group: 'defendant', isDefault: true},
          Email: {name: 'Email', visible: true, required: false, group: 'defendant', isDefault: false},
          Address: {name: 'Address', visible: true, required: false, group: 'defendant', isDefault: false},
          RentOrOwn: {name: 'Rent or Own', visible: true, required: false, group: 'defendant', isDefault: false},
          Gender: {name: 'Gender', visible: true, required: false, group: 'defendant', isDefault: false},
          DL: {name: 'DL #', visible: true, required: false, group: 'defendant', isDefault: false},
          DLState: {name: 'DL State', visible: true, required: false, group: 'defendant', isDefault: false},
          Race: {name: 'Race', visible: true, required: false, group: 'defendant', isDefault: false},
          Height: {name: 'Height', visible: true, required: false, group: 'defendant', isDefault: false},
          Weight: {name: 'Weight', visible: true, required: false, group: 'defendant', isDefault: false},
          EyeColor: {name: 'Eye Color', visible: true, required: false, group: 'defendant', isDefault: false},
          HairColor: {name: 'Hair Color', visible: true, required: false, group: 'defendant', isDefault: false},
          IdMarks: {name: 'ID Marks', visible: false, required: false, group: 'defendant', isDefault: false},
          Citizen: {name: 'Citizen', visible: false, required: false, group: 'defendant', isDefault: false},
          Employer: {name: 'Employer', visible: true, required: false, group: 'defendant', isDefault: false},
          EmployerAddress: {name: 'Employer Address', visible: true, required: false, group: 'defendant', isDefault: false},
          EmployerPhone: {name: 'Employer Phone', visible: true, required: false, group: 'defendant', isDefault: false},
          Occupation: {name: 'Occupation', visible: true, required: false, group: 'defendant', isDefault: false},
          EmploymentLength: {name: 'Employment Length', visible: true, required: false, group: 'defendant', isDefault: false},
          Supervisor: {name: 'Supervisor', visible: true, required: false, group: 'defendant', isDefault: false},
        };
      }

      case 'transactionFields': {
        return {
          Source: {name: 'Source', visible: false, required: false, group: 'transaction', isDefault: false},
          Jail: {name: 'Jail', visible: true, required: false, group: 'transaction', isDefault: false},
          BookingId: {name: 'Booking ID', visible: true, required: false, group: 'transaction', isDefault: false},
        };
      }

      case 'bondFields': {
        return {
          BondAmount: {name: 'Bond Amount', visible: true, required: false, group: 'bond', isDefault: false},
          EnterCharge: {name: 'Enter Charge(s)', visible: true, required: false, group: 'bond', isDefault: false},
          Downpayment: {name: 'How much can you pay now if approved?', visible: true, required: false, group: 'bond', isDefault: false},
          CollateralValue: {name: 'Available Cash & Collateral', visible: true, required: false, group: 'bond', isDefault: false},
          ArrestDate: {name: 'Arrest Date', visible: true, required: false, group: 'bond', isDefault: false},
        };
      }

      case 'contactFields': {
        return  {
          Type: {name: 'Type', visible: true, required: false, group: 'contact', isDefault: false},
          ResidenceFor: {name: 'Residence For', visible: true, required: false, group: 'contact', isDefault: false},
          Relation: {name: 'Relation', visible: true, required: false, group: 'contact', isDefault: false},
          FirstName: {name: 'First Name', visible: true, required: true, group: 'contact', isDefault: true},
          MiddleName: {name: 'Middle Name', visible: false, required: false, group: 'contact', isDefault: false},
          LastName: {name: 'Last Name', visible: true, required: true, group: 'contact', isDefault: true},
          Dob: {name: 'DOB', visible: true, required: true, group: 'contact', isDefault: true},
          Alias: {name: 'Alias Name', visible: false, required: false, group: 'contact', isDefault: false},
          Ssn: {name: 'SSN', visible: true, required: false, group: 'contact', isDefault: false},
          Phone: {name: 'Phone', visible: true, required: true, group: 'contact', isDefault: true},
          Email: {name: 'Email', visible: true, required: false, group: 'contact', isDefault: false},
          Address: {name: 'Address', visible: true, required: false, group: 'contact', isDefault: false},
          Gender: {name: 'Gender', visible: true, required: false, group: 'contact', isDefault: false},
          DL: {name: 'DL #', visible: true, required: false, group: 'contact', isDefault: false},
          DLState: {name: 'DL State', visible: true, required: false, group: 'contact', isDefault: false},
          Race: {name: 'Race', visible: true, required: false, group: 'contact', isDefault: false},
          Height: {name: 'Height', visible: true, required: false, group: 'contact', isDefault: false},
          Weight: {name: 'Weight', visible: true, required: false, group: 'contact', isDefault: false},
          EyeColor: {name: 'Eye Color', visible: true, required: false, group: 'contact', isDefault: false},
          HairColor: {name: 'Hair Color', visible: true, required: false, group: 'contact', isDefault: false},
          IdMarks: {name: 'ID Marks', visible: false, required: false, group: 'contact', isDefault: false},
          Employer: {name: 'Employer', visible: true, required: false, group: 'contact', isDefault: false},
          EmployerAddress: {name: 'Employer Address', visible: true, required: false, group: 'contact', isDefault: false},
          EmployerPhone: {name: 'Employer Phone', visible: true, required: false, group: 'contact', isDefault: false},
          Occupation: {name: 'Occupation', visible: true, required: false, group: 'contact', isDefault: false},
          EmploymentLength: {name: 'Employment Length', visible: true, required: false, group: 'contact', isDefault: false},
          Supervisor: {name: 'Supervisor', visible: false, required: false, group: 'contact', isDefault: false},
          VehicleYear: {name: 'Vehicle Year', visible: false, required: false, group: 'contact', isDefault: false},
          Make: {name: 'Make', visible: false, required: false, group: 'contact', isDefault: false},
          Model: {name: 'Model', visible: false, required: false, group: 'contact', isDefault: false},
          Color: {name: 'Color', visible: false, required: false, group: 'contact', isDefault: false},
          Plate: {name: 'Plate #', visible: false, required: false, group: 'contact', isDefault: false},
        };
      }

      case 'MilitaryServiceTypes': {
        return [
          {Id: 'CoastGuard', Text: 'Coast Guard'},
          {Id: 'Army', Text: 'Army'},
          {Id: 'Navy', Text: 'Navy'},
          {Id: 'Marine', Text: 'Marine'},
          {Id: 'AirForce', Text: 'Air Force'},
          {Id: 'SpaceForce', Text: 'Space Force'},
        ];
      }

      case 'Jurisdiction': {
        return [
          {Id: 'State', Text: 'State'},
          {Id: 'Federal', Text: 'Federal'},
          {Id: 'Tribal', Text: 'Tribal'},
          {Id: 'Immigration', Text: 'Immigration'},
          {Id: 'Other', Text: 'Other'},
        ];
      }
    }
  }

  getBulkLookup(lookupNames: any): Observable<any> {
    return new Observable<any>(obs => {
      // let get only lookups not cached and not expired
      for (let i = lookupNames.length - 1; i >= 0; i--) {
        const key = `${lookupNames[i]}/lookup/all?limit=0`;
        if (this.cache.exists(key) && !this.cache.isExpired(key)) {
          lookupNames.splice(i, 1);
        }
      }
      if (lookupNames.length > 0) {
        const url = `${this.settings.getBaseUrl()}/bulklookups?limit=0`;
        const postData = {
          lookupNames: lookupNames,
        };

        this.http.post(url, postData).pipe(
          map((res: any) => res),
          publishReplay(1),
          refCount(),
          catchError(error => throwError(error.error || 'Server Error'))).subscribe(resp => {
          lookupNames.forEach(lookup => {
            const key = `${lookup}/lookup/all?limit=0`;
            this.cache.setLookup(lookup, key, JSON.stringify(resp.data[lookup]));
          });
          obs.next();
        });

      } else {
        obs.next();
      }
    });


  }

  getDetailedLookup(lookupCode: string): Promise<any> {
    return this.http.get<PortoResponse<Lookup[]>>(`${this.settings.getBaseUrl()}/lookup/detailed/${lookupCode}?limit=0&orderBy=LookupName`)
      .toPromise<PortoResponse<Lookup[]>>()
      .then(resp => {
        return {
          data: resp.data,
          totalCount: resp.data.length,
        };
      })
      .catch(error => throwError(ApiUtilities.getErrorMessage(error, true)));
  }

  updateLookup(id: string, lookup: Lookup): Observable<Lookup> {
    return this.http.patch(`${this.settings.getBaseUrl()}/lookups/${id}`, lookup)
      .pipe(
        map((res: Lookup) => {
          this.cache.removeByLookupName(lookup.LookupCode);
          return res;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),
      );
  }

  createLookup(type, values: Lookup): Promise<any> {
    return this.http.post<PortoResponse<Lookup>>(`${this.settings.getBaseUrl()}/lookups`, { ...values, LookupSource: 'Lookups' })
      .toPromise<PortoResponse<Lookup>>()
      .then(({ data }) => {
        this.cache.removeByLookupName(type);
        return {data};
      })
      .catch(error => throwError(ApiUtilities.getErrorMessage(error, true)));
  }

  deleteLookup(id: string, type: string): Promise<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/lookups/${id}`)
      .toPromise()
      .then(() => this.cache.removeByLookupName(type))
      .catch(error => throwError(ApiUtilities.getErrorMessage(error, true)));
  }
}
