<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
  {{data.message}}
  <app-reminder-number-buyer
    (onSuccess)="numberBought()"
    ></app-reminder-number-buyer>
</mat-dialog-content>
<mat-dialog-actions style="margin-top: 8px;" align="end">
  <button mat-stroked-button color="primary" [mat-dialog-close]="false" cdkFocusRegionStart>{{noText}}</button>
  <button mat-raised-button color="primary" [disabled]="buttonsDisabled" [mat-dialog-close]="!buttonsDisabled" cdkFocusInitial cdkFocusRegionEnd>{{yesText}}</button>
</mat-dialog-actions>
