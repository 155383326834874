<div class='fixActionRow'>
<h1 mat-dialog-title>Change Transaction Status</h1>
  <div mat-dialog-content *ngIf="data.status === 'Client'">
    <label class='col-sm-4 control-label required'>
      Posted Date
    </label>
    <div class='col-sm-4'>
      <dx-date-box
        type='date'
        appMobileFocus
        [(value)]='postedDate'
        invalidDateMessage='Please enter a valid date'
        dateSerializationFormat='yyyy-MM-dd'
        [displayFormat]='dateFormatter'
        [max]='maxDate'
      >
        <dx-validator>
          <dxi-validation-rule
            type='required'
            message='Please enter a Posted Date'>
          </dxi-validation-rule>
        </dx-validator>
      </dx-date-box>
    </div>
  </div>
  <div mat-dialog-content *ngIf="data.status === 'Dead'">
    <label class='col-sm-4 control-label'>
      Reason
    </label>
    <div class='col-sm-6' style="margin-top:-0.5rem;">
      <dx-select-box
        #deadReason
        [dataSource]="prospectDeclineReasons"
        displayExpr="Text"
        valueExpr="Text"
        [(value)]="markProspectsDeadReason"
        placeholder="Prospect Declined reason">
      </dx-select-box>
    </div>
  </div>
  <div class='spacer'></div>

  <div mat-dialog-actions align='end'>
    <button mat-stroked-button [mat-dialog-close]="'cancel'">Cancel</button>
    <button mat-raised-button
            color='primary'
            (click)='saveAndClose(data.status)'
            type='submit'>
      Save
    </button>
  </div>
</div>
