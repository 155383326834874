import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../../services/settings.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WhatsNewService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService) {
  }

  loadServices(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/whatsnewservices`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  save(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/whatsnewservices`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  update(id: string, data: any): Observable<any> {
    return this.http.patch(`${this.settings.getBaseUrl()}/whatsnewservices/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/whatsnewservices/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }
}
