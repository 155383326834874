import {BaseModel} from './BaseModel';

export class Vehicle  extends BaseModel{
  public Id: string;
  public Year: number;
  public Make: string ;
  public Model: string ;
  public Color: string ;
  public Tag: string ;
  public TagState: string;
  public IsDefault: boolean;

  constructor(obj?: object)
  {
    super(obj);
    this.routeRoot = 'vehicles';


   if (obj == null) {
      this.Id = '';
      this.IsDefault = false;

    }


  }
}
