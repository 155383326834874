<div mat-dialog-content style="position:relative;">
    <button mat-icon-button class="close-icon" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <div>
        <h1 class="h4" style="font-weight:bold; margin-bottom: 2rem; text-align:center">Looking for Help?</h1>
        <div style="text-align: center;">
            For complex questions please contact support or alternatively let us get your setup done for you.
        </div>
    </div>
</div>
<mat-dialog-actions align="center">
    <button [disabled]="disabled" (click)="navigateToContactPage()" mat-raised-button>Contact Support</button>
    <button [disabled]="disabled" (click)="sendMailToSupport()" color='primary' mat-raised-button>Do it for me</button>
</mat-dialog-actions>