
import {catchError, map} from 'rxjs/operators';

import {throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {LookupItem} from '../models/lookup-item';
import {ApiUtilities} from '../shared/ApiUtilities';
import {CacheService} from './cache.service';

@Injectable()

export class MobileVerificationService {

  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  key: string = 'mobiledeviceregistrations';

  constructor(private http: HttpClient, private settings: SettingsService, private cache: CacheService) { }

  newDevice(data): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/${this.key}`;
    let response = this.http.post(url, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
    return response;
  }

  remove(id, data): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/${this.key}/${id}/remove`;
    let response = this.http.put(url, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
    return response;
  }

  getGridData(): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/${this.key}?limit=0`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

  locationHistory(data): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/${this.key}/${data}/history`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

  delete(data): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/${this.key}/${data}`;
    let response = this.http.delete(url).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

  findDeviceByDefendant(data): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/finddevicebydefendant`
    let response = this.http.post(url, data).pipe(
      map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }),);
    return response;
  }

}
