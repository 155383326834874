import {map, catchError} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiUtilities} from '../shared/ApiUtilities';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {Surety} from '../models/surety';

@Injectable()
export class SuretyService {

  constructor(private http: HttpClient,
              private settings: SettingsService) {
  }

  getById(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/sureties/${id}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  update(surety: any) {

    // console.log('surety service update', surety);
    return this.http.patch(`${this.settings.getBaseUrl()}/sureties/${surety.data.Id}`, JSON.stringify(surety)).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getSuretyFromPowerId(Id: any) {
    return this.http.get(`${this.settings.getBaseUrl()}/sureties/bypower/${Id}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

}
