<div *ngIf='userInTrial'>
  <div class='text-center'>
    This feature is not available in a trial account.
    <button role='button'
            mat-raised-button
            color='primary'
            (click)='getStartedNow()'>
      Get started now!
    </button>
  </div>
</div>
<div *ngIf='!userInTrial' id='multiview'>
  <dx-multi-view #multiview [dataSource]='stepsForm' [selectedIndex]='0' [loop]='false' [animationEnabled]='true'>
    <div *dxTemplate="let step of 'item'; let itemIndex = index">
      <div class='multiview-item'>
        <div class='ibox float-e-margins' *ngIf='itemIndex == 0'>
          <div class='ibox-content no-border height-900'>
            <div id='vertical-timeline' class='vertical-container light-timeline'>
              <form name='frmDefendant' class='form-horizontal' (submit)='onFormSubmit($event)'>
                <div class='vertical-timeline-block' *ngIf='!lockedToReportType'>
                  <div class='vertical-timeline-content'>

                    <div class='form-group'>
                      <label class='col-sm-3 control-label'>Report Types</label>
                      <div class='col-sm-8'>
                        <dx-select-box
                          #reportType
                          [dataSource]='reportTypeList'
                          [(value)]='selectedReportType'
                          [showClearButton]='false'
                          displayExpr='name'
                          valueExpr='id'
                        ></dx-select-box>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-xs-3 col-offset-3"></div>
                    <div  fxLayout="row" >
                    <div class="col-xs-3 col-offset-3">
                      <a href="https://www.captira.com/pages/pricing-bail-management#open" target="_blank">Pricing ⇀</a>
                    </div>
                   <p class='notice'  *ngIf='selectedReportType == ReportType.General' >
                     Background checks are currently paused awaiting updated vendor credentials.
                   </p>
                   <p class='notice'  *ngIf='selectedReportType == ReportType.Credit' >
                     Credit checks are currently paused due to a low level of demand and usage for this product.
                   </p>
                  </div>
                  </div>

                </div>
                <div class='vertical-timeline-block'>
                  <div class='vertical-timeline-content'>
                    <!-- ReportType.General -->
                    <div class='group-1' *ngIf='selectedReportType == ReportType.General'>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>First Name</label>
                        <div class='col-sm-8'>
                          <dx-drop-down-box
                            *ngIf='persons.length > 1'
                            [(value)]='datamartRequest.FirstName'
                            [showClearButton]='true'
                            [dataSource]='persons'
                            [acceptCustomValue]='true'
                            (onValueChanged)='onValueChanged($event)'
                            displayExpr='first'
                            valueExpr='first'
                          >
                            <div *dxTemplate="let data of 'content'">
                              <dx-list
                                [dataSource]='persons'
                                (onItemClick)='changeDefendant($event)'
                              >
                              </dx-list>
                            </div>
                            <dx-validator>
                              <dxi-validation-rule
                                type='required'
                                message='First Name is required'
                              ></dxi-validation-rule>
                            </dx-validator>
                          </dx-drop-down-box>
                          <dx-text-box
                            *ngIf='persons.length == 0 || persons.length == 1'
                            [(value)]='datamartRequest.FirstName'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='First Name is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>Last Name</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.LastName'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='Last Name is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>DOB</label>
                        <div class='col-sm-4'>
                          <dx-date-box type='date' [(value)]='datamartRequest.DateOfBirth'
                                       dateSerializationFormat='yyyy-MM-dd'
                                       [displayFormat]='dateFormatter'>
                          </dx-date-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>Social Security Number</label>
                        <div class='col-sm-8'>
                          <dx-text-box class='_lr-hide' [(value)]='datamartRequest.SocialSecurityNumber'
                                       mask='000-00-0000'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='Social Security Number is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>


                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Address</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.StreetAddress'>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-1 col-sm-offset-2 control-label'>City</label>
                        <div class='col-sm-2'>
                          <dx-text-box [(value)]='datamartRequest.City' maxlength='255' autocomplete='city'>
                          </dx-text-box>
                        </div>
                        <div class='col-sm-1 control-label'>State</div>
                        <div class='col-sm-2'>
                          <dx-select-box [(value)]='datamartRequest.State' [dataSource]='stateList'
                                         autocomplete='state' valueExpr='Text' displayExpr='Text'>
                          </dx-select-box>
                        </div>
                        <div class='col-sm-1 control-label'>Zip</div>
                        <div class='col-sm-2'>
                          <dx-text-box [(value)]='datamartRequest.ZipCode' maxlength='5' autcomplete='zip'>
                          </dx-text-box>
                        </div>
                      </div>
                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Phone Number</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.PhoneNumber' mask='(000) 000-0000'>
                          </dx-text-box>
                        </div>
                      </div>
                      <div class='row'>
                        <div class='col-sm-3'></div>
                        <div class='col-sm-8'>
                          <dx-validation-summary style='margin-bottom: 10px'></dx-validation-summary>
                        </div>
                      </div>

                    </div>

                    <!-- ReportType.Credit -->
                    <div class='group-2' *ngIf='selectedReportType == ReportType.Credit'>
                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>First Name</label>
                        <div class='col-sm-8'>
                          <dx-drop-down-box
                            *ngIf='persons.length > 1'
                            [(value)]='datamartRequest.FirstName'
                            [showClearButton]='true'
                            [dataSource]='persons'
                            [acceptCustomValue]='true'
                            (onValueChanged)='onValueChanged($event)'
                            displayExpr='first'
                            valueExpr='first'
                          >
                            <div *dxTemplate="let data of 'content'">
                              <dx-list
                                [dataSource]='persons'
                                (onItemClick)='changeDefendant($event)'
                              >
                              </dx-list>
                            </div>
                            <dx-validator>
                              <dxi-validation-rule
                                type='required'
                                message='First Name is required'
                              ></dxi-validation-rule>
                            </dx-validator>
                          </dx-drop-down-box>
                          <dx-text-box
                            *ngIf='persons.length == 0 || persons.length == 1'
                            [(value)]='datamartRequest.FirstName'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='First Name is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>Last Name</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.LastName'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='Last Name is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>Social Security Number</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.SocialSecurityNumber' mask='000-00-0000'>
                            <dx-validator>
                              <dxi-validation-rule type='required' message='SSN is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>
                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Address</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.StreetAddress'>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-1 col-sm-offset-2 control-label'>City</label>
                        <div class='col-sm-2'>
                          <dx-text-box [(value)]='datamartRequest.City' maxlength='255' autocomplete='city'>
                          </dx-text-box>
                        </div>
                        <label class='col-sm-1 control-label'>State</label>
                        <div class='col-sm-2'>
                          <dx-select-box [(value)]='datamartRequest.State' [dataSource]='stateList'
                                         autocomplete='state'
                                         valueExpr='Text' displayExpr='Text'>
                          </dx-select-box>
                        </div>
                        <label class='col-sm-1 control-label'>Zip</label>
                        <div class='col-sm-2'>
                          <dx-text-box [(value)]='datamartRequest.ZipCode' maxlength='5' autcomplete='zip'>
                          </dx-text-box>
                        </div>
                      </div>
                      <div class='row'>
                        <div class='col-sm-3'></div>
                        <div class='col-sm-8'>
                          <dx-validation-summary style='margin-bottom: 10px'></dx-validation-summary>
                        </div>
                      </div>
                    </div>

                    <!-- ReportType.Social -->
                    <div class='group-3' *ngIf='selectedReportType == ReportType.Social'>
                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Email</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.Email'>
                            <dx-validator>
                              <dxi-validation-rule type='required' message='Email is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>
                      <div class='row'>
                        <div class='col-sm-3'></div>
                        <div class='col-sm-8'>
                          <dx-validation-summary style='margin-bottom: 10px'></dx-validation-summary>
                        </div>
                      </div>
                    </div>

                    <!-- ReportType.RealEstate -->
                    <div class='group-4' *ngIf='selectedReportType == ReportType.RealEstate'>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Address</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.StreetAddress'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='Street Address is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-1 col-sm-offset-2 control-label'>City</label>
                        <div class='col-sm-2'>
                          <dx-text-box [(value)]='datamartRequest.City' maxlength='255' autocomplete='city'>
                            <dx-validator>
                              <dxi-validation-rule type='required' message='City is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                        <label class='col-sm-1 control-label'>State</label>
                        <div class='col-sm-2'>
                          <dx-select-box [(value)]='datamartRequest.State' [dataSource]='stateList'
                                         autocomplete='state'
                                         valueExpr='Text' displayExpr='Text'>
                            <dx-validator>
                              <dxi-validation-rule type='required' message='State is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <label class='col-sm-1 control-label'>Zip</label>
                        <div class='col-sm-2'>
                          <dx-text-box  [(value)]='datamartRequest.ZipCode' maxlength='5' autcomplete='zip'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='Zip Code is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>
                      <div class='row'>
                        <div class='col-sm-3'></div>
                        <div class='col-sm-8'>
                          <dx-validation-summary style='margin-bottom: 10px'></dx-validation-summary>
                        </div>
                      </div>
                    </div>

                    <!-- ReportType.LicensePlate -->
                    <div class='group-5' *ngIf='selectedReportType == ReportType.LicensePlate'>
                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Plate Number</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.PlateNumber'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='Plate Number is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='row'>
                        <div class='col-sm-3'></div>
                        <div class='col-sm-8'>
                          <dx-validation-summary style='margin-bottom: 10px'></dx-validation-summary>
                        </div>
                      </div>
                    </div>

                    <!-- ReportType.InstantId -->
                    <div class='group-6' *ngIf='selectedReportType == ReportType.InstantId'>
                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>First Name</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.FirstName'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='First Name is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Last Name</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.LastName'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='Last Name is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>DOB</label>
                        <div class='col-sm-4'>
                          <dx-date-box type='date' [(value)]='datamartRequest.DateOfBirth'
                                       dateSerializationFormat='yyyy-MM-dd'
                                       [displayFormat]='dateFormatter'>
                          </dx-date-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Social Security Number</label>
                        <div class='col-sm-8'>
                          <dx-text-box class='_lr-hide' [(value)]='datamartRequest.SocialSecurityNumber'
                                       mask='000-00-0000'>
                          </dx-text-box>
                        </div>
                      </div>


                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Address</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.StreetAddress'>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-1 col-sm-offset-2 control-label'>City</label>
                        <div class='col-sm-2'>
                          <dx-text-box [(value)]='datamartRequest.City' maxlength='255' autocomplete='city'>
                          </dx-text-box>
                        </div>
                        <label class='col-sm-1 control-label'>State</label>
                        <div class='col-sm-2'>
                          <dx-select-box [(value)]='datamartRequest.State' [dataSource]='stateList'
                                         autocomplete='state'
                                         valueExpr='Text' displayExpr='Text'>
                            <dx-validator>
                              <dxi-validation-rule type='required' message='State is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <label class='col-sm-1 control-label'>Zip</label>
                        <div class='col-sm-2'>
                          <dx-text-box  [(value)]='datamartRequest.ZipCode' maxlength='5' autcomplete='zip'>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>Phone Number</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.PhoneNumber' mask='(000) 000-0000'>
                          </dx-text-box>
                        </div>
                      </div>
                      <div class='row'>
                        <div class='col-sm-3'></div>
                        <div class='col-sm-8'>
                          <dx-validation-summary style='margin-bottom: 10px'></dx-validation-summary>
                        </div>
                      </div>
                    </div>

                    <!-- ReportType.Incarceration -->
                    <div class='group-7' *ngIf='selectedReportType == ReportType.Incarceration'>
                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>First Name</label>
                        <div class='col-sm-8'>
                          <dx-drop-down-box
                            *ngIf='persons.length > 1'
                            [(value)]='datamartRequest.FirstName'
                            [showClearButton]='true'
                            [dataSource]='persons'
                            [acceptCustomValue]='true'
                            (onValueChanged)='onValueChanged($event)'
                            displayExpr='first'
                            valueExpr='first'
                          >
                            <div *dxTemplate="let data of 'content'">
                              <dx-list
                                [dataSource]='persons'
                                (onItemClick)='changeDefendant($event)'
                              >
                              </dx-list>
                            </div>
                            <dx-validator>
                              <dxi-validation-rule
                                type='required'
                                message='First Name is required'
                              ></dxi-validation-rule>
                            </dx-validator>
                          </dx-drop-down-box>
                          <dx-text-box
                            *ngIf='persons.length == 0 || persons.length == 1'
                            [(value)]='datamartRequest.FirstName'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='First Name is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>Last Name</label>
                        <div class='col-sm-8'>
                          <dx-text-box [(value)]='datamartRequest.LastName'>
                            <dx-validator>
                              <dxi-validation-rule type='required'
                                                   message='Last Name is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label required'>DOB</label>
                        <div class='col-sm-4'>
                          <dx-date-box type='date' [(value)]='datamartRequest.DateOfBirth'
                                       dateSerializationFormat='yyyy-MM-dd'
                                       [displayFormat]='dateFormatter'>
                            <dx-validator>
                              <dxi-validation-rule type='required' message='DOB is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-date-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label' [ngClass]="!datamartRequest.SSNAlternative ? 'required' : ''">Social Security Number</label>
                        <div class='col-sm-8'>
                          <dx-text-box class='_lr-hide' [(value)]='datamartRequest.SocialSecurityNumber'
                                       mask='000-00-0000'>
                            <dx-validator>
                              <dxi-validation-rule *ngIf='!datamartRequest.SSNAlternative' type='required' message='Ssn is required'></dxi-validation-rule>
                            </dx-validator>
                          </dx-text-box>
                        </div>
                      </div>

                      <div class='form-group'>
                        <label class='col-sm-3 control-label'>No SSN?</label>
                        <div class='col-sm-8'>
                          <dx-check-box [(value)]='datamartRequest.NoSSN' class='checkbox'
                                        (onValueChanged)='noSSNChanged($event)'>
                          </dx-check-box>
                        </div>
                      </div>

                      <div *ngIf='noSSNisChecked'>
                        <div class='form-group'>
                          <label class='col-sm-3 control-label'></label>
                          <div class='col-sm-8'>
                            <dx-select-box
                              #additionalInfo
                              appInputFocus
                              tabindex='27'
                              [dataSource]='noSSNAlternatives'
                              [(value)]='datamartRequest.SSNAlternative'
                              [showClearButton]='true'
                              displayExpr='Text'
                              valueExpr='Text' (onSelectionChanged)='onOptionChanged($event)'>
                            </dx-select-box>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="noSSNAlternativeIsChecked && ssnAlternativeName === SsnAlternative.DriverLicense">
                        <div class='form-group'>
                          <label class='col-sm-3 control-label required'>Driver License</label>
                          <div class='col-sm-8'>
                            <dx-text-box [(value)]='datamartRequest.DriverLicense'>
                              <dx-validator>
                                <dxi-validation-rule type='required'
                                                     message='Driver License is required'></dxi-validation-rule>
                              </dx-validator>
                            </dx-text-box>
                            <small>This may return lower quality results</small>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="noSSNAlternativeIsChecked && ssnAlternativeName === SsnAlternative.Address">
                        <div class='form-group'>
                          <label class='col-sm-3 control-label required'>Address</label>
                          <div class='col-sm-8'>
                            <app-smartaddress
                              #smartAddress
                              [(model)]='datamartRequest.Address'
                              [singleAddressMode]='true'
                              AddressCity='txCity'
                              AddressState='txState'
                              AddressZip='txZip'
                              [placeholderVisible]='false'
                            ></app-smartaddress>
                            <small>This may return lower quality results</small>

                          </div>

                        </div>
                      </div>

                      <div *ngIf="noSSNAlternativeIsChecked && ssnAlternativeName === SsnAlternative.ZipCode">
                        <div class='form-group'>
                          <label class='col-sm-3 control-label required'>Zip Code</label>
                          <div class='col-sm-8'>
                            <dx-text-box [(value)]='datamartRequest.ZipCode'>
                              <dx-validator>
                                <dxi-validation-rule type='required'
                                                     message='Zip Code is required'></dxi-validation-rule>
                              </dx-validator>
                            </dx-text-box>
                            <small>This may return lower quality results</small>
                          </div>
                        </div>
                      </div>

                      <div class='row'>
                        <div class='col-sm-3'></div>
                        <div class='col-sm-8'>
                          <dx-validation-summary style='margin-bottom: 10px'></dx-validation-summary>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>

                <div [fxLayout]="'row'" fxLayoutAlign='center center'>
                  <dx-button
                    type='default'
                    [disabled]='searchDisabled || selectedReportType === ReportType.General || selectedReportType === ReportType.Credit'
                    [useSubmitBehavior]='true'
                    text='{{submitButtonText}}'>
                  </dx-button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class='ibox float-e-margins' *ngIf='itemIndex == 1'>
          <div class='ibox-content no-border'>
            <dx-data-grid id='searchResultsGrid' height='50%' [dataSource]='searchResultsDatasource'
                          [remoteOperations]='true' [columnAutoWidth]='true'
                          rowAlternationEnabled='true' (onToolbarPreparing)='onToolbarPreparing($event)'
            >
              <dxo-scrolling mode='infinite'></dxo-scrolling>
              <dxi-column caption='object' [visible]='false' dataField='object'>
              </dxi-column>
              <dxi-column caption='Id' [visible]='false' dataField='id'></dxi-column>
              <dxi-column caption='Name' [visible]='true' dataField='name'></dxi-column>
              <dxi-column caption='Age' [visible]='true' dataField='age'></dxi-column>
              <dxi-column caption='Sex' [visible]='true' dataField='gender'></dxi-column>
              <dxi-column caption='Date of Birth' [visible]='true' dataField='dateOfBirth'></dxi-column>
              <dxi-column caption='SSN' [visible]='true' dataField='ssn'></dxi-column>
              <dxi-column caption='Address' [visible]='true' dataField='address'></dxi-column>
              <dxi-column caption='Phone' [visible]='true' dataField='phone'></dxi-column>
              <dxi-column caption='Actions' [visible]='true'
                          cellTemplate='editBtn'>
                <div *dxTemplate="let data of 'editBtn'">
                  <dx-button (onClick)='getGeneralIndividualReport(data)' text='Get Full Report'
                             icon='fal fa-file-excel'></dx-button>
                </div>
              </dxi-column>

            </dx-data-grid>
            <div [fxLayout]="'row'" fxLayoutAlign='center center'>
              <dx-button type='default' (onClick)='backToSearch(itemIndex)'
                         text='Back to Search'></dx-button>
            </div>
          </div>
        </div>

      </div>
    </div>

  </dx-multi-view>
  <small class='header-arrest-history'>Important: Background Checks are subject to Terms of Use and coverage, including use
    hereof is restricted to use by commercial enterprises for legitimate purposes only and shall not be used for
    purposes under the FCRA Act, including but not limited to not to be used for decisions of credit or
    employment. By Clicking Search above, you warrant acknowledgement of adherence to the Terms and this notice of use.
  </small>
</div>

<app-dynamic-popup [popupComponent]='dynamicPopupClass' [addPopupClass]='false' [popupComponentData]='dynamicPopupData'
                   [popupComponentTitle]='dynamicPopupTitle'
                   [popupComponentVisible]='dynamicPopupVisible' [popupShowTitle]='dynamicPopupShowTitle'
                   (onPopupHidden)='onDynamicPopupHidden()'></app-dynamic-popup>


<dx-popup class='popup' [width]='600' [height]='600' [showTitle]='true' title='License Plate Locator'
          [dragEnabled]='false'
          [closeOnOutsideClick]='false' [(visible)]='plateNumberPopup'>

  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view #scrollView id='scrollview' [scrollByContent]='scrollByContent' [scrollByThumb]='scrollByThumb'
                    [showScrollbar]="'always'"
                    [bounceEnabled]='pullDown'>
      <div class='ibox float-e-margins'>
        <div class='list-container'>
          <dx-list height='100%'>
            <dxi-item *ngFor='let item of SearchResult'>
              <div class='product'>
                <img src='' />
                <div>demo@example.com</div>
              </div>
            </dxi-item>
          </dx-list>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  class='popup'
  [width]="'90%'"
  [height]="'90%'"
  [showTitle]='true'
  title='Arrest History Search Results'
  [dragEnabled]='false'
  [closeOnOutsideClick]='false'
  (onHiding)="arrestHistoryResultHidden($event)"
  [(visible)]='incarcerationHistoryVisible'>
  <div *dxTemplate="let data of 'content'">
    <app-incarceration-history-report
      [FirstName]='incarcerationHistoryFirst'
      [LastName]='incarcerationHistoryLast'
      [Dob]='incarcerationHistoryDob'
      [Ssn]='incarcerationHistorySsn'
      [DriverLicense]='incarcerationHistoryDriverLicense'
      [Address]='incarcerationHistoryAddress'
      [ZipCode]='incarcerationHistoryZipCode'
      [noSSNAlternativeIsChecked]='noSSNAlternativeIsChecked'
      [ssnAlternativeName]='ssnAlternativeName'
    ></app-incarceration-history-report>
  </div>
</dx-popup>

