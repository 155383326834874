import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, publishReplay, refCount } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { EventService } from './event.service';
import { CacheService } from './cache.service';
import { PortoResponse } from '../models/porto-response';
import { RecentDefendant } from '../views/dashboard/panels/recent-defendants/recent-defendants.component';
import { Contact } from '../models/contact';
import { Document } from '../models/document';
import * as moment from 'moment';
import { BondCountResponse } from './billing.service';

export interface ExistingDefendant {
  DefendantId: string;
  Dob: string;
  Name: string;
  PersonId: string;
  PostedDate: string;
  Ssn: string;
  TransactionId: string;
  Status: 'Client' | 'Prospect' | 'Dead';
  IncludeInDefendantWatch: boolean;
  person: any;
}

export interface ApplicationExistingDefendant extends ExistingDefendant {
  ProfilePicture: string;
  Gender: string;
  CallDate: Date;
}

@Injectable()
export class DefendantService {

  private getDefendantMessages$: (Observable<any> | null);
  private getDefendantMessagesId: string;

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private eventService: EventService,
    private cache: CacheService) {
  }

  getAll(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/defendants`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  getAllAutoCompleteData(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/search/autocomplete/*`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.json().error || 'Server Error')));
  }

  search(query): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/defendants/search/${query}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.json().error || 'Server Error')));
  }

  getTransactionArSummary(transactionId: any): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/defendants/transactionarsummary/${transactionId}`;
    if (transactionId === 'demo') {
      url = `${window.location.origin}/assets/data/demo-defendant-transactions.json`;
    }
    return this.http.get(url).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getTransactionBalance(transactionId: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/defendants/transactionbalance/${transactionId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getDefendantArSummary(defendantId: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/defendants/arsummary/${defendantId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getAllRelatedPersons(defendantId: any, transactionId: any): Observable<PersonRelated[]> {
    const url = !!transactionId ? `${this.settings.getBaseUrl()}/persons/bydefendant/${defendantId}/${transactionId}` : `${this.settings.getBaseUrl()}/persons/bydefendant/${defendantId}`;
    return this.http.get<PortoResponse<PersonRelated[]>>(url).pipe(
      map((res: PortoResponse<PersonRelated[]>) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  delete(defendantId: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/defendants/${defendantId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getAllDeletedDefendants(query): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/defendants/forrecovery/${query}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }


  recoverDeletedDefendant(defendantIds: any): Observable<any> {
    const $ids = {'ids': defendantIds};

    // console.log('recover request')
    return this.http.post(`${this.settings.getBaseUrl()}/defendants/undelete`, $ids).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  searchForDefendantForMerge(search: any): Observable<any> {
    // console.log('recover request')
    search = search.trim().replace(' ', '%25');
    return this.http.get(`${this.settings.getBaseUrl()}/defendants/merge/search?search=${search}&limit=0`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  mergeDefendants(mergeId: any, keepId: any): Observable<any> {
    // console.log('recover request')
    return this.http.post(`${this.settings.getBaseUrl()}/defendants/merge/${mergeId}/${keepId}`, null).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  markProspectsDead(data: any): Observable<any> {
    // console.log('recover request')
    return this.http.put(`${this.settings.getBaseUrl()}/defendant/prospects/markdead`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getDefendantPersonalById(id: any): Observable<any> {
    this.eventService.componentBusy(true);
    const includes = 'person.phones,person.emails,person.addresses,person.review';
    let url = `${this.settings.getBaseUrl()}/defendants/${id}?include=${includes}`;
    if (id === 'demo') {
      url = `${window.location.origin}/assets/data/demo-defendant-person.json`;
    }
    const data = this.cache.get(url);
    if (!!data) {
      this.eventService.componentBusy(false);
      return of(JSON.parse(data));
    } else {
      return this.http.get(url).pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(ApiUtilities.getErrorMessage(error, true));
        }),
        finalize(() => {
          this.eventService.componentBusy(false);
        }));
    }
  }

  getDefendantById(id: any): Observable<any> {
    this.eventService.componentBusy(true);
    const includes = 'person.phones,person.emails,transactions,transactions.notes';
    const url = `${this.settings.getBaseUrl()}/defendants/${id}?includes=${includes}`;
    const data = this.cache.get(url);
    if (!!data) {
      this.eventService.componentBusy(false);
      return of(JSON.parse(data)); // Return cache data as observables
    } else {
      return this.http.get(url).pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(ApiUtilities.getErrorMessage(error, true));
        }));
    }
  }

  getDefendantContactsByDefId(id: any): Observable<Contact[]> {
    return this.http.get<PortoResponse<Contact[]>>(`${this.settings.getBaseUrl()}/defendant/contact/${id}`).pipe(
      map((res) => res.data),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError(error => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true));
      }));
  }
  getDefendantDocumentsByDefId(id: any): Observable<Document[]> {
    return this.http.get<PortoResponse<Document[]>>(`${this.settings.getBaseUrl()}/defendant/document/${id}?include=shares,version`).pipe(
      map((res) => res.data),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError(error => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true));
      }));
  }
  getFullDefendantById(id: any, transactionId?: any, fromWizard?: boolean, customIncludes?: any): Observable<any> {
    fromWizard = !!fromWizard;
    const transactionAnd = transactionId == null ? '' : '&transactionId=' + transactionId;
    const includes = customIncludes ? `include=` + customIncludes : `include=checkinhistory,checkinschedule,transactions,transactions.bonds,transactions.bonds.power,\
    transactions.bonds.courtdates,transactions.collaterals,transactions.collaterals.addresses,lawsuits,\
    transactions.contacts,person.phones,person.phones.exclusions,person.addresses,person.socialnetworks,person.emails,person.employers,\
    person.employers.addresses,person.employers.phones,transactions.notes,transactions.paymentschedule,\
    transaction.paymentschedule.details,transactions.documents,transactions.documents.shares,transactions.documents.version,calendaritems,transactions.contacts.person,\
    transactions.contacts.person.addresses,transactions.contacts.person.phones,transactions.contacts.person.emails,\
    transactions.contacts.person.socialnetworks,person.vehicles,transactions.contacts.person.vehicles,\
    accountingdetails,transactions.contacts.person.employers,transactions.contacts.person.employers.addresses,\
    transactions.contacts.person.employers.phones,transactions.agent.person,reminderexclusions,person.review,transactions.contacts.person.review`;

    this.eventService.componentBusy(true);
    let url = `${this.settings.getBaseUrl()}/defendants/${id}?${includes}${transactionAnd}&fromWizard=${fromWizard}`;
    let key = `${this.settings.getBaseUrl()}/defendants/${id}`;
    if (id === 'demo') {
      url = `${window.location.origin}/assets/data/demo-defendant.json`;
      key = `${window.location.origin}/assets/data/demo-defendant.json`;
    }
    const data = this.cache.get(key);
    if (!!data) {
      this.eventService.componentBusy(false);
      return of(JSON.parse(data)); // Return cache data as observables
    } else {
      return this.http.get(url).pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(ApiUtilities.getErrorMessage(error, true));
        }));
    }
  }

  getDefendantAccountDetails(id: any, transactionId?: any, fromWizard?: boolean): Observable<any> {

    fromWizard = !!fromWizard;
    const transactionAnd = transactionId == null ? '' : '&transactionId=' + transactionId;
    const includes = 'include=accountingdetails';

    // this.eventService.componentBusy(true);
    const url = `${this.settings.getBaseUrl()}/defendants/${id}?${includes}${transactionAnd}&fromWizard=${fromWizard}`;
    const key = `${this.settings.getBaseUrl()}/defendants/${id}`;
    const data = this.cache.get(key);
    if (!!data) {
      // this.eventService.componentBusy(false);
      return of(JSON.parse(data)); // Return cache data as observables
    } else {
      return this.http.get(url).pipe(
        map((res: any) => res),
        finalize(() => {
          // this.eventService.componentBusy(false);
        }),
        catchError(error => {
          // this.eventService.componentBusy(false);
          return throwError(ApiUtilities.getErrorMessage(error, true));
        }));
    }
  }

  getTransactionLookup(id: any): Observable<any> {

    const includes = 'include=transactions';
    return this.http.get(`${this.settings.getBaseUrl()}/defendants/${id}?${includes}`).pipe(
      map((res: any) => {
        const returnVal: any[] = [];
        // console.log(res.data);
        for (const trans of res.data.transactions.data) {
          const postedDateFormatted = trans.PostedDate ?
            moment(trans.PostedDate, 'YYYY-MM-DD[T]HH:mm:ss').format('MM/DD/YYYY') :
            '(none)';
          returnVal.push({
            'Text': `Bond Date: ${postedDateFormatted}`,
            'Id': trans.Id,
          });
        }
        return returnVal;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  saveDefendant(id: any, data: any, fromWizard?: boolean, transactionId?: any, createCheckIn = false): Observable<any> {
    data.fromWizard = fromWizard != null;
    data.createCheckIn = createCheckIn;
    this.eventService.componentBusy(true);
    const transactionAnd = transactionId == null ? '' : '&transactionId=' + transactionId;
    const includes = 'include=checkinschedule,transactions,transactions.bonds,transactions.bonds.power,transactions.bonds.courtdates,transactions.collaterals,lawsuits,transactions.contacts,person.phones,person.addresses,person.socialnetworks,person.emails,person.employers,person.employers.addresses,person.employers.phones,transactions.notes,transactions.paymentschedule,transactions.paymentschedule.details,transaction.paymentschedule.details,transactions.documents,calendaritems,transactions.contacts.person,transactions.contacts.person.addresses,transactions.contacts.person.phones,transactions.contacts.person.emails,transactions.contacts.person.socialnetworks,person.vehicles,transactions.contacts.person.vehicles,accountingdetails,transactions.contacts.person.employers,transactions.contacts.person.employers.addresses,transactions.contacts.person.employers.phones,transactions.collaterals.addresses';
    return this.http.put(`${this.settings.getBaseUrl()}/defendants/updatewithchildren/${id}?${includes}${transactionAnd}`, data).pipe(
      map((res: any) => {
        delete data.fromWizard;
        delete data.createCheckIn;
        return res;
      }),
      finalize(() => {
        this.eventService.componentBusy(false);
        const key = `${this.settings.getBaseUrl()}/defendants/${id}`;
        this.cache.remove(key);
      }),
      catchError((error) => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true));
      }),
    );
  }

  /**
   * Save defendant with optional list of fields to remove from
   * the includes relationship.
   *
   * @param id - defendant Id
   * @param data - payload to PUT
   * @param doNotInclude - array of includes to remove from request
   * @param fromWizard - if this is coming from the defendant wizard.
   * @param transactionId - the current transaction Id
   */
  saveDefendantOptional(id: any, data: any, doNotInclude: string[] = [], fromWizard?: boolean, transactionId?: any): Observable<any> {
    data.fromWizard = fromWizard != null;
    const transactionAnd = transactionId == null ? '' : '&transactionId=' + transactionId;
    let masterIncludes = 'checkinschedule,transactions,transactions.bonds,transactions.bonds.power,transactions.bonds.courtdates,transactions.collaterals,lawsuits,transactions.contacts,person.phones,person.addresses,person.socialnetworks,person.emails,person.employers,person.employers.addresses,person.employers.phones,transactions.notes,transactions.paymentschedule,transaction.paymentschedule.details,transactions.documents,calendaritems,transactions.contacts.person,transactions.contacts.person.addresses,transactions.contacts.person.phones,transactions.contacts.person.emails,transactions.contacts.person.socialnetworks,person.vehicles,transactions.contacts.person.vehicles,accountingdetails,transactions.contacts.person.employers,transactions.contacts.person.employers.addresses,transactions.contacts.person.employers.phones,transactions.collaterals.addresses';
    const includesCollection = masterIncludes.split(',');
    if (doNotInclude.length) {
      doNotInclude.forEach(include => {
        const indexToRemove = includesCollection.findIndex(collItem => {
          return collItem === include;
        });
        includesCollection.splice(indexToRemove, 1);
      });
      masterIncludes = includesCollection.join(',');
    }

    return (
      this.http.put(`${this.settings.getBaseUrl()}/defendants/updatewithchildren/${id}?include=${masterIncludes}${transactionAnd}`, data)
      .pipe(
        map((res: any) => {
          delete data.fromWizard;
          return res;
        }),
        finalize(() => {
          this.eventService.componentBusy(false);
          const key = `${this.settings.getBaseUrl()}/defendants/${id}`;
          this.cache.remove(key);
        }), catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(ApiUtilities.getErrorMessage(error, true));
        }))
    );
  }

  removeDefendantFromRecentDefendants(id: any) {
    const recentDef = this.getRecentDefendants();
    if (recentDef != null && recentDef.length > 0) {
      const newRecentDef = recentDef.filter(rd => rd.Id !== id);
      localStorage.setItem('recentDefendants', JSON.stringify(newRecentDef));
    }
  }

  pushRecentDefendant(id: any, name: string, dob: string, gender: string, profilePictureUrl: string) {
    let recentDef = this.getRecentDefendants();
    if (!recentDef) {
      recentDef = [{
        'Id': id,
        'Name': name,
        'DOB': dob,
        'Gender': gender,
        'ProfilePicture': profilePictureUrl,
      }];
    } else {
      // if Id is found, remove it
      for (let i = 0; i < recentDef.length; i++) {
        if (recentDef[i].Id === id) {
          recentDef.splice(i, 1);
        }
      }

      // trim to 9
      if (recentDef.length > 9) {
        recentDef = recentDef.splice(9, recentDef.length - 9);
      }

      // push new one on the front
      recentDef.unshift({
        'Id': id,
        'Name': name,
        'DOB': dob,
        'Gender': gender,
        'ProfilePicture': profilePictureUrl,
      });
    }
    localStorage.setItem('recentDefendants', JSON.stringify(recentDef));
  }

  getRecentDefendants(howMany?: number): RecentDefendant[] | null {
    let recentDefString: string = localStorage.getItem('recentDefendants'),
      recentDef: RecentDefendant[];
    if (recentDefString) {
      recentDef = JSON.parse(recentDefString);
      if (!howMany) {
        howMany = recentDef.length;
      }
      recentDef = recentDef.slice(0, howMany);
    }
    return recentDef;
  }

  initNewDefendant(data): Observable<any> {
    // console.log('create defendant request');
    // console.log(data, 'initNewDefendant Data');
    return this.http.post(`${this.settings.getBaseUrl()}/defendants`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getDefendantAudit(id: any): Observable<any> {

    // console.log('get defendant audit')
    return this.http.get(`${this.settings.getBaseUrl()}/audits/defendant/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  checkForExistingDefendantOrTransaction(data: any, includes: String = ''): Observable<PortoResponse<ExistingDefendant[]>> {
    return this.http.post(`${this.settings.getBaseUrl()}/checkforexistingdefendantortransaction?include=${includes}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  /**
   * This is only used when searching for existing defendants from the generic bail application. It is different because it doesn't
   * use the dob like the other existing defendant route
   * @param first the first name of the defendant
   * @param last the last name of the defendant
   */
  findExistingDefendants(first: string, last: string): Observable<ApplicationExistingDefendant[]> {
    return this.http.post<PortoResponse<ApplicationExistingDefendant[]>>(`${this.settings.getBaseUrl()}/find-existing-defendant`, { first, last }).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  checkForExistingDefendantByPhone(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/checkforexistingdefendantbyphone`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getReminderHistory(id: any): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/reminders/defendant/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));

  }

  saveDefendantWithNoIncludes(id: any, data: any): Observable<any> {
    // console.log('save defendant without includes request, data: ', data);
    return this.http.put(`${this.settings.getBaseUrl()}/defendants/${id}`, data).pipe(
      map((res: any) => res),
      finalize(() => {
        const key = `${this.settings.getBaseUrl()}/defendants/${id}`;
        this.cache.remove(key);
      }),
      catchError((error) => {
          return throwError(ApiUtilities.getErrorMessage(error, true));
      }));
  }

  getDefendantViewForDefMgrById(defendantId) {
    return this.http.get(`${this.settings.getBaseUrl()}/defendantsview/${defendantId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  getDefendantMessages(defendantId) {
    // console.log('getting defendant messages');
    // if we had previously subscribed, but for a different defendant, cancel the old one
    if (this.getDefendantMessages$ != null && this.getDefendantMessagesId !== defendantId) {
      this.getDefendantMessages$ = null;
      this.getDefendantMessagesId = null;
    }
    // now we know that the observable is either null or it is for this defendant
    if (this.getDefendantMessages$ != null) {
      return this.getDefendantMessages$;
    } else {
      this.getDefendantMessagesId = defendantId;
      let url = `${this.settings.getBaseUrl()}/defendantmessages/${defendantId}`;
      if (defendantId === 'demo') {
        url = `${window.location.origin}/assets/data/demo-defendant-messages.json`;
      }
      this.getDefendantMessages$ = this.http.get(url).pipe(
        map((res: any) => {
          this.getDefendantMessages$ = null;
          this.getDefendantMessagesId = null;
          return res;
        }),
        catchError(error => throwError(error.error || 'Server Error')),
        publishReplay(),
        refCount());
      return this.getDefendantMessages$;
    }
  }

  getPaymentMethodTokens(defendantId) {
    return this.http.get(`${this.settings.getBaseUrl()}/paymentmethodtokens?defendantId=${defendantId}&limit=0`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  initNewDefendantExternal(data : any, customerId : any): Observable<any> {
    // console.log('create defendant request');
    // console.log(data, 'initNewDefendant Data');
    return this.http.post(`${this.settings.getBaseUrl()}/external/defendants/${customerId}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  saveDefendantExternal(id: any, data: any, fromWizard?: boolean, transactionId?: any, customerId?: any, partialSave=false): Observable<any> {
    let includes = 'person.addresses,person.vehicles,person.employers.addresses,person.emails,person.phones,transactions.bonds,transactions.notes,transactions.collaterals,transactions.contacts.person.addresses,transactions.contacts.person.employers.addresses,transactions.contacts.person.phones,transactions.contacts.person.emails,transactions.contacts.person.vehicles';

    this.eventService.componentBusy(true);
    const transactionAnd = transactionId == null ? '' : '&transactionId=' + transactionId;
    return this.http.put(`${this.settings.getBaseUrl()}/external/defendants/updatewithchildren/${id}?include=${includes}&partialSave=${partialSave}&customerId=${customerId}&fromWizard=true${transactionAnd}`, data).pipe(
      map((res: any) => {
        delete data.fromWizard;
        return res;
      }),
      finalize(() => {
        this.eventService.componentBusy(false);
        const key = `${this.settings.getBaseUrl()}/defendants/${id}`;
        this.cache.remove(key);
      }),
      catchError((error) => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true));
      }),
    );
  }

  getDefendantDocumentsByTransactionIdExternal(customerId: any, transactionId: any, documentReqId: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/external/transaction/document/${customerId}/${transactionId}/${documentReqId}`).pipe(
      map((res) => res),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError(error => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true));
      }));
  }

  sendPaymentRequest(data: any, type: 'sms' | 'email') {
     // console.log('create defendant request');
    // console.log(data, 'initNewDefendant Data');
    return this.http.post(`${this.settings.getBaseUrl()}/defendants/request-payment/${data.Id}?type=${type}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getBondCount(customerId: string): Observable<number> {
    return this.http.get<BondCountResponse>(`${this.settings.getBaseUrl()}/bond/count/${customerId}`).pipe(
      map((res: any) => res.count),
      catchError(error => throwError(error.json().error || 'Server Error')));
  }
}

export interface PersonRelated {
  PersonId: string;
  FullName: string;
  First?: string;
  Last?: string;
  DefendantId?: string;
  DefendantFirst?: string;
  DefendantLast?: string;
  RecordType?: 'Defendant' | 'Contact';
  Email?: string;
  Phone?: string;
  Address1?: string;
  City?: string;
  State?: string;
  Zip?: string;
  CustomerId?: string;
}
