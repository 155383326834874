import {BaseModel} from './BaseModel';
import {CalendarItem} from './calendaritem';

export class CalendarItemType  extends BaseModel{
  text: string;
  color: string;
  type: string;


  constructor(obj?: object)
  {

    super(obj);

    if (obj == null) {

    }
  }
}
