import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxFileUploaderComponent, DxFormComponent } from 'devextreme-angular';
import { Document } from '../../../models/document';
import { DocumentService } from '../../../services/document.service';
import { FileUtilities } from '../../../shared/FileUtilities';
import { StringUtilities } from '../../../shared/StringUtilities';
import { GridService } from '../../../services/grid.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { MatDialog } from '@angular/material/dialog';
import { DocumentVersionComponent } from './document-version/document-version.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { NotifyService } from '../../../common/notify/notify.service';
import { Share, ShareStatus } from '../../../models/share';
import { DocumentVersion } from '../../../models/document-version';
import { ObjectVersionList } from 'aws-sdk/clients/s3';
import { Defendant } from '../../../models/defendant';
import { Contact } from '../../../models/contact';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges {

  @ViewChild('grdDocument', {static: true}) grdDocument: DxDataGridComponent;
  @ViewChild('frmEdit') frmEdit: DxFormComponent;

  @ViewChild(DxFileUploaderComponent, {static: true}) fileUpload: DxFileUploaderComponent;
  @Input() model: Document[];
  @Input() columnVisibilityMap: string[] = [];
  @Input() defendantInfo: any = [];
  @Input() fieldsDisabled = false;
  @Input() fromExternal = false;
  @Input() showUpload = true;
  @Input() defendant: Defendant;
  @Input() contacts: Contact[] = [];
  @Output() modelChange = new EventEmitter<Document[]>();

  @Output() onSave = new EventEmitter<boolean>();

  panels: any;
  documents: Document[];
  view = '';
  docPopup: boolean;
  tempDoc: Document;
  editVisible = false;
  popupWindow: any;
  currentRow: any = {};
  currentRowKey: any;
  currentRowReady = false;
  editMode: string;
  saveButtonDisabled = false;
  contactInfo: any = {};
  downloading = false;

  constructor(
    private documentService: DocumentService,
    private gridService: GridService,
    private dialog: MatDialog,
    private notify: NotifyService,
    private eventService: EventService,
  ) {
    super();
    this.popupWindow = window;
    this.editMode = 'popup';
    this.panels = ['Document Information'];
    this.documents = [];
    this.docPopup = false;
    this.tempDoc = new Document();
    this.getHistory = this.getHistory.bind(this);
    this.cancelDocumentEdit = this.cancelDocumentEdit.bind(this);
    this.saveDocumentEdit = this.saveDocumentEdit.bind(this);
  }

  ngOnInit() {
    this.subs.sink = this.eventService.pdfAttached$.subscribe((document: Document) => {
      if (!!document) {
        const _document = new Document();
        _document.loadData(document);
        _document.isAdded = false;
        this.documents.push(_document);
      }
    });
    this.reInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('document info changed: ', changes.model);
    if (changes.model !== undefined && changes.model.currentValue !== undefined) {
      // this.sortGrid(changes.model.currentValue);
      this.documents = changes.model.currentValue;
    }
  }

  sortGrid(documents: Document[]): void {
    documents.sort((a: Document, b: Document): number => {
      if (!!a.SigningStatus && !!b.SigningStatus) {
        if (a.Created_At > b.Created_At) {
          return -1;
        } else {
          return 1;
        }
      } else if (!!a.SigningStatus) {
        return -1;
      } else if (!!b.SigningStatus) {
        return 1;
      } else {
        if (a.Created_At >= b.Created_At) {
          return -1;
        } else {
          return 1;
        }
      }
    });
  }

  public reInit() {
    if (this.model !== null) {
      // this.sortGrid(this.model);
      this.documents = this.model;
    }
  }

  public reset() {
    this.model = [];
    this.reInit();
  }

  titleClick(e) {
    if (e.jQueryEvent.target.type === undefined) {
      e.jQueryEvent.stopPropagation();
    }
  }

  downloadDocument(e, data, shares = []) {
    this.grdDocument.instance.beginCustomLoading('');
    if (this.downloading) {
      this.grdDocument.instance.endCustomLoading();
      return;
    }
    this.downloading = true;
    if (data.isAdded) {
      const blob = new Blob([data.File.Body], { type: data.Type });
      FileUtilities.saveBlobAs(blob, data.Name);
      this.downloading = false;
      this.grdDocument.instance.endCustomLoading();
    } else {
      this.subs.sink = this.documentService.retrieveDocument(data.Id).pipe(take(1)).subscribe(resp => {
        if (!!resp.document && resp.document?.Type !== 'application/pdf') {
          this.handleDocumentDownload(resp.document, shares);
        } else {
          if (resp.pdfbase64) {
            const dialogRef = this.dialog.open(DocumentViewerComponent, {
              data: {
                pdfbase64: FileUtilities.base64ToDataUrl('application/pdf', resp.pdfbase64),
                shares: shares,
                document: resp.document,
                defendant: this.defendant,
                contacts: this.contacts,
              },
              minWidth: '100%',
              minHeight: '100%',
              panelClass: 'document-viewer',
            });
            this.subs.sink = dialogRef.afterClosed().subscribe(result => {
              this.downloading = false;
              this.grdDocument.instance.endCustomLoading();
              this.refreshGrid();
            });
          } else if (resp.document) {
            this.handleDocumentDownload(resp.document, shares);
          }
        }
      }, error => {
        this.notify.toast('Error downloading document', 'error');
        this.downloading = false;
        this.grdDocument.instance.endCustomLoading();
      });
    }
  }

  handleDocumentDownload(document: Document, shares: Share[]): void {
    if (document.Type === 'application/pdf') {
      const dialogRef = this.dialog.open(DocumentViewerComponent, {
        data: { document: document, shares: shares, contacts: this.contacts },
        width: '100%',
        height: '100%',
        panelClass: 'document-viewer',
      });
      this.subs.sink = dialogRef.afterClosed().subscribe(result => {
        this.downloading = false;
        this.grdDocument.instance.endCustomLoading();
        this.refreshGrid();
      });
    } else {
      const blob = new Blob([document.File.Body], { type: document.Type });
      FileUtilities.saveBlobAs(blob, document.Name);
      this.grdDocument.instance.endCustomLoading();
      this.downloading = false;
    }
  }

  onCellPrepared({ rowType, column, data, cellElement }) {
    // only show history button for eSign documents
    if (rowType === 'data' && column.command === 'edit') {
      if (!data.SigningStatus && data.version?.length === 0) {
        cellElement.querySelector('.fa-history').remove();
      }
    }
  }

  getHistory({ row }) {
    if (row.data.version.length === 0) {
      this.subs.sink = this.documentService.getObjectVersions(row.data.Id)
        .pipe(take(1))
        .subscribe(obj => {
          if (!!obj.Versions) {
            obj.Versions.sort((a, b) => {
              return a.LastModified < b.LastModified ? -1 : a.LastModified > b.LastModified ? 1 : 0;
            });
            this.showVersion(obj.Versions, row.data, 'wasabi');
          }
        });
    } else {
      this.showVersion(row.data.version, row.data, 'captira');
    }
  }

  /**
   * Displays the version history of a document.
   * @param version - The list of document versions.
   * @param document - The document.
   * @param type - The type of the document storage ('captira' or 'wasabi').
   */
  showVersion(version: ObjectVersionList | DocumentVersion, document: Document, type: 'captira' | 'wasabi') {
    const dialogRef = this.dialog.open(DocumentVersionComponent, {
      data: {
        versions: version,
        name: document.Name,
        type: type,
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe(ver => {
      this.showPdfVersion(ver, document);
    });
  }

  /**
   * Displays the PDF version of the document in a viewer dialog.
   * @param version - The version of the document to display.
   * @param document - The document.
   */
  showPdfVersion(version, document: Document) {
    if (version) {
      this.subs.sink = this.documentService.getObjectByVersion(version, version.Key || document.DocumentLink)
        .pipe(take(1)).subscribe((pdfbase64) => {
          if (pdfbase64) {
            this.dialog.open(DocumentViewerComponent, {
              data: {
                pdfbase64: FileUtilities.base64ToDataUrl('application/pdf', pdfbase64),
                shares: document.shares,
                contacts: this.contacts,
              },
              minWidth: '100%',
              minHeight: '100%',
              panelClass: 'document-viewer',
            });
          }
        });
    }
  }

  docUploaded(e) {
    const self = this;
    if (e !== null && e.value !== null && e.value.length > 0) {
      const files = e.value;
      files.forEach(function (file) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          // this.preferences.ProfilePicData = fileReader.result;
          const document = new Document();
          document.isAdded = true;
          document.ModifiedDate = StringUtilities.getCurrentDateTimeString();
          document.Type = file.type;
          document.Size = file.size;
          document.SizeText = FileUtilities.FileSizeText(document.Size);
          document.Name = file.name;
          document.File = file;
          document.File.Body = new Uint8Array(fileReader.result as ArrayBuffer);
          document.Key = Date.now();
          self.documents.push(document);

        };
        fileReader.onloadend = () => {
          self.modelChange.emit(self.documents);
        };
        fileReader.readAsArrayBuffer(file);
      });
      this.refreshGrid();
    }
  }

  docUploadError(e) {
    const document = new Document();
    document.isAdded = true;

    document.ModifiedDate = StringUtilities.getCurrentDateTimeString();
    document.Type = e.file.type;
    document.Size = e.file[0].size;
    document.SizeText = FileUtilities.FileSizeText(document.Size);
    document.Name = e.file[0].name;

    this.documents.push(document);

    this.refreshGrid();
    this.modelChange.emit(this.documents);
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach(function (item) {
      item.location = 'center';

    });

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.gridService.exportGrid(this.grdDocument, 'DocumentsExport');
          },
        },
      },
    );
  }

  refreshGrid() {
    this.grdDocument.instance.refresh();
  }

  saveGrid(e) {
    this.grdDocument.instance.saveEditData();
  }

  exportGrid() {
    const exportAll = this.grdDocument.instance.totalCount() !== this.grdDocument.instance.getSelectedRowKeys().length;
    this.grdDocument.instance.exportToExcel(exportAll);
  }

  deleteGrid() {
    this.grdDocument.instance.getSelectedRowKeys().forEach(
      (key) => {
        this.grdDocument.instance.deleteRow(this.grdDocument.instance.getRowIndexByKey(key));
      });
  }

  onEditingStart(e) {
    this.setCurrentRowData(e);

    setTimeout(() => {
      this.currentRowReady = true;
    }, 500);

    e.cancel = true;
    if (this.frmEdit !== undefined && this.frmEdit.instance !== undefined) {
      this.frmEdit.instance.option('formData', this.currentRow);
    }
    if (!this.editVisible) {
      this.editVisible = true;
    }
  }

  setCurrentRowData(e) {
    this.currentRow = {};
    this.currentRow = Object.assign({}, e.data); // we need to create a disconnected copy of the data for the form
    this.currentRowKey = e.data.key;
    if (this.currentRowKey === undefined) {
      this.currentRowKey = e.data.Id;
    }
    // console.log('setCurrentRowData: e: ', this.currentRow, this.currentRowKey);
  }

  onRowRemoving(e) {
    if (e.data.Id !== '') { // document has already been saved to db
      e.data.isDeleted = true;
      e.data.isDirty = false;
      this.modelChange.emit(this.documents);
      e.cancel = true;
      this.filterActiveRows();
      this.onSave.emit(true);
    } else {
      e.data.isDeleted = true;
      e.data.isDirty = false;
      e.cancel = true;
      this.filterActiveRows();
    }
  }

  cancelDocumentEdit(e) {
    // console.log('Calling function cancelDocumentEdit in document component!');
    this.grdDocument.instance.cancelEditData();
    if (this.frmEdit !== undefined && this.frmEdit.instance !== undefined) {
      this.frmEdit.instance.option('formData', {});
      this.frmEdit.instance.resetValues();
    }
    this.editVisible = false;
  }

  filterActiveRows() {
    if (this.grdDocument !== undefined) {

      this.grdDocument.instance.filter(['!', ['isDeleted', '=', true]]);
      this.grdDocument.instance.refresh();
    }
  }

  saveDocumentEdit(e) {
    // console.log('in saveDocumentEdit e', e);
    this.saveButtonDisabled = true;
    // console.log('this.currentRow.Id: ', this.currentRow.Id);
    if (this.currentRow.Id === '') {
      // need to add some defaults here to avoid api errors
      this.currentRow.isAdded = true;
    } else {
      this.currentRow.isDirty = true;
    }

    const tmpDoc = this.documents.filter(document => {
      if (document.Id !== '') {
        return document.Id === this.currentRowKey || document.Key === this.currentRowKey;
      } else {
        return document.Key === this.currentRow.Key;
      }
    })[0];
    tmpDoc.Comment = this.currentRow.Comment;
    tmpDoc.Private = this.currentRow.Private;
    tmpDoc.isDirty = this.currentRow.isDirty;
    // console.log('match found in saveDocumentEdit:', tmpDoc, this.currentRow.Private);
    this.modelChange.emit(this.documents);
    this.saveButtonDisabled = false;
    this.frmEdit.instance.option('formData', {});
    this.frmEdit.instance.resetValues();
    this.editVisible = false;
    this.grdDocument.instance.refresh();
  }

  getShareTooltip(shares: Share[] = []) {
    let message;
    return shares.map(share => {
      switch (share.Status) {
        case ShareStatus.complete:
          message = 'signed';
          break;
        case ShareStatus.new:
        case ShareStatus.pending:
          message = 'not signed';
          break;
      }
      return `${share.Name} ${message}`;
    }).join(', ');
  }

  showEditButton = (e) => {
    return e.row.data.Name !== 'Mugshot';
  }

  showDeleteButton = (e) => {
    return e.row.data.Name !== 'Mugshot';
  }

}
