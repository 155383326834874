import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '../../shared/AppConstants';
import { NotifyService } from '../../common/notify/notify.service';
import { ReminderService } from '../../services/reminder.service';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '../../services/dialog.service';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmSetupPopupComponent} from '../confirm-setup-popup/confirm-setup-popup.component';
import {OnboardingSupportPopupComponent} from '../onboarding-support-popup/onboarding-support-popup.component';
import { CustomerService } from './../../services/customer.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { EventService } from './../../services/event.service';
import {DxNumberBoxComponent} from 'devextreme-angular';
@Component({
  selector: 'app-onboarding-dialog',
  templateUrl: './onboarding-dialog.component.html',
  styleUrls: ['./onboarding-dialog.component.scss'],
})
export class OnboardingDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  selectedIndex: number;
  pages: any;
  phoneMask = AppConstants.PHONE_MASK;
  phonePattern: any = AppConstants.PHONE_PATTERN;
  phoneRules: any = AppConstants.PHONE_RULES;
  mobile: string;
  canDialNumber: boolean;
  showVideoPopup = false;
  sentMessage: Boolean = false;

  safeUrl: any;
  videoUrl = 'https://www.youtube.com/embed/A5nPrCfzZXA?autoplay=1';
  shortVideoUrl = 'https://www.youtube.com/embed/8rGrtmTpIaE?autoplay=1';
  isMobile = false;
  textSent = false;
  discountValue = 0;
  paymentOffers: any = [];
  disabled = false;
  @ViewChild('discountValueRef') discountValueRef: DxNumberBoxComponent;

  constructor(
    private sanitizer: DomSanitizer,
    private notifyService: NotifyService,
    private reminderService: ReminderService,
    private router: Router,
    private dialogRef: MatDialogRef<OnboardingDialogComponent>,
    private dialog: MatDialog,
    private customerService: CustomerService,
    private breakpointObserver: BreakpointObserver,
    private eventService: EventService
  ) {
    super();
    this.safeUrl = sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    this.pages = [
      {title: '', name: 'Demo Text', description: ''},
      {title: '', name: 'Step 2', description: ''},
      {title: '', name: 'Step 3', description: ''},
      {title: '', name: 'Step 4', description: ''},
      {title: '', name: 'Step 5', description: ''},
      {title: '', name: 'Step 6', description: ''},
    ];
    this.selectedIndex = 0;
    this.mobile = localStorage.getItem('mobileNumber');
    this.isMobile = breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  ngOnInit(): void {
    this.eventService.closeOnboardingToastFn();
    let actionName = "";
    let actionStep = 0;
    let description = "";
    if(this.selectedIndex == 0) {
      actionName = "testsms";
      actionStep = 1;
      description = "Test sms"
    }

    if(actionName)
      this.subs.sink = this.customerService.logCustomerOnboardingAction({actionName, actionStep, description}).subscribe(res => {});

    this.subs.sink = this.customerService.getCollectionOnboardingDiscounts().subscribe(res => {
      this.paymentOffers = res.discounts;
      this.paymentOffers.forEach((discount : any) => {
        if(discount.Name === 'First Reminder') {
          if(discount.Percent !== 0) {
            this.discountValue = discount.Percent / 100;
          }
        }
      })
    });
  }

  onValueChanged(e) {
    if (e.value === undefined || e.value.length === 0) {
      e.component.option('isValid', true);
      this.canDialNumber = false;
    } else if (e.value.length === 10 && window.screen.width < 768) {
      this.canDialNumber = true;
    }
  }


  nextPage(): void {
    this.textSent = false;
    this.selectedIndex = (this.selectedIndex + 1) % this.pages.length;
    let actionName = "";
    let actionStep = 0;
    let description = "";

    if(this.selectedIndex === 1) {
      actionName = "howitworks";
      actionStep = 2;
      description = "How it works"
    }

    if(this.selectedIndex === 3) {
      actionName = "demovideo"
      actionStep = 3;
      description = "Demo video"
    }

    if(this.selectedIndex === 4) {
      actionName = "discountscreen";
      actionStep = 4;
      description = "Discount Screen Menu"
    }

    if(this.selectedIndex === 5) {
      actionName = "basicsetup";
      actionStep = 5;
      description = "Basic Setup menu"
    }

    if(actionName)
      this.subs.sink = this.customerService.logCustomerOnboardingAction({actionName, actionStep, description}).subscribe(res => {
        if(actionStep === 5) {
          let systemConfig: any = localStorage.getItem('systemConfigs')!;
          if(systemConfig) {
            systemConfig = JSON.parse(systemConfig);
            systemConfig.data[0].CarolOnboardingStatus = 1;
            localStorage.setItem('systemConfigs', JSON.stringify(systemConfig));
          }
        }
      });
  }


  previousPage(): void {
    this.textSent = false;
    this.selectedIndex === 0 ? this.selectedIndex = this.pages.length - 1 : this.selectedIndex--;
  }

  onFocusOut(e) {
    // EM - man, what a silly workaround simply because empty string does not pass validation
    setTimeout(function () {
      const value = e.component.option('value');
      if (value !== undefined && !value.length) {
        e.component.option('isValid', true);
      }
    });
  }

  textDemoThing() {
    this.textSent = false;
    if (this.mobile && this.mobile.replace('null', '') !== '') {
      this.sentMessage = true;
      this.notifyService.toast(`Sending Demo Text to ${this.formatPhoneNumber(this.mobile)}`);
      const messageText = AppConstants.getDemoText(`${window.location.origin}/collection-details/demo`);
      this.subs.sink = this.reminderService.sendTestSms(this.mobile, messageText).subscribe(() => {
        this.textSent = true;
      });
    }
  }

  formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return null;
  }

  showSetupPopup(type: string) {
    const setupPopup = this.dialog.open(ConfirmSetupPopupComponent, {
      width: this.isMobile ? '100%' : '25%',
      height: type === 'basic' ? '35%': '28%',
      data: {
        type,
      }
    });

    this.subs.sink = setupPopup.afterClosed().subscribe((check) => {
      if(check) {
        this.dialogRef.close();
        if(type === 'basic') {
          this.router.navigateByUrl('/collections/accounts/new');;
        } else {
          // Navigate to custom wizard
          this.router.navigate(['/collections/companies'], {queryParams: {fromWizard: true}})
        }
      }
    });

  }

  openSupportPopup() {
    let actionName = "";
    let actionStep = 0;
    let description = "";
    actionName = "basiccompletehelp";
    actionStep = 5;
    description = "basic complete help";

    if(actionName)
      this.subs.sink = this.customerService.logCustomerOnboardingAction({actionName, actionStep, description}).subscribe(res => {});

    const supportPopup = this.dialog.open(OnboardingSupportPopupComponent, {
      width: this.isMobile ? '100%' : '25%',
      height: '30%'
    });
  }

  closeOnboardingDialog() {
    let actionName = "";
    let actionStep = 0;
    let description = "";

    if(this.selectedIndex === 5) {
      actionName = "basiccompleteclosed";
      actionStep = 5;
      description = "basic complete closed";

      if(actionName) {
        this.subs.sink = this.customerService.logCustomerOnboardingAction({actionName, actionStep, description}).subscribe(res => {
          this.dialogRef.close();
        });
      }
    } else {
      this.dialogRef.close();
    }
  }

  moveToStep(step: number) {
    this.selectedIndex = step;
  }

  updateDiscounts() {
    if((this.discountValue * 100) < 0 || (this.discountValue * 100) > 100) {
      this.notifyService.toast('Please enter a value between 0 and 100', 'error');
      return;
    }
    this.notifyService.toast('Saving...');
    this.disabled = true;
    this.subs.sink = this.customerService.updateCollectionOnboardingDiscounts({discountValue: this.discountValue * 100}).subscribe((res : any) => {
      this.disabled = false;
      this.notifyService.toast('Discounts set successfully.');
    }, err => {
      console.log(err);
      this.disabled = false;
    });
  }

  showVideo(num: 1 | 2) {
    switch (num) {
      case 1:
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.shortVideoUrl);
        break;
      case 2:
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
        break;
    }
    this.showVideoPopup = true;
  }

}
