<mat-card
  [class.mat-elevation-z0]="router.url !== '/payment'"
  id="parentContainer"
>
    <dx-validation-group #valGroup>
    <div class="row" id="paymentHeader">
      <app-transactionar
        id="arSummary"
        #arSummary
        [sourceId]="transactionId"
        [beginningSummary]="summary">
      </app-transactionar>
    </div>
    <div class="row" *ngIf="showDefendantSearch && !paymentProcessed">
      <div class="col-sm-3 text-right">
        <label class="control-label">Find Defendant</label>
      </div>
      <div class="col-sm-6">
        <app-searchbox
          #searchbox
          [selectMode]="true"
          [includeDefPowers]="true"
          (onRecordSelected)="onDefendantSelected($event)"
          [placeHolderText]="'Enter Name or Power Number to Search'"
          [selectedDefendant]="selectedDefendantName"
          [disabled]="inputDisabled"
        ></app-searchbox>
      </div>
    </div>

    <div class="row" *ngIf="!showDefendantSearch || paymentProcessed">
      <div class="col-sm-3 payment-label">
        <label class="control-label">Defendant</label>
      </div>
      <div class="col-sm-6">
        <dx-text-box disabled="true" [value]="defendantName"> </dx-text-box>
      </div>
    </div>

    <div class="row" *ngIf="showPowers">
      <div class="col-sm-3 payment-label">
        <label class="control-label">Powers</label>
      </div>
      <div class="col-sm-6 powers">
        <dx-text-box disabled="true" [value]="powers"> </dx-text-box>
      </div>
    </div>

      <div class="row" [hidden]="paymentGatewayFrameVisible">
        <div class="col-md-3 payment-label">
          <label class="control-label">Amount</label>
        </div>
        <div class="col-md-6">
          <dx-number-box
            #txAmount
            [format]="{ type: 'currency', precision: 2 }"
            [disabled]="paymentProcessed || inputDisabled"
            [(value)]="totalAmount"
            (onChange)="paymentDataChanged()"
          >
            <dx-validator>
              <dxi-validation-rule
                type="range"
                [min]="0.01"
                message="Please enter an amount > 0"
                reevaluate="true"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="required"
                message="Please enter an amount > 0"
                reevaluate="true"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="custom"
                [validationCallback]="validateAmount"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
      </div>

    <div class="row">
      <div class="col-md-3 payment-label">
        <label class="control-label">Date</label>
      </div>
      <div class="col-md-6">
        <dx-date-box
          dateSerializationFormat="yyyy-MM-dd"
          [displayFormat]="dateFormatter"
          [disabled]=" paymentProcessed || paymentGatewayFrameVisible || inputDisabled"
          [(value)]="payment.Date"
          (onChange)="paymentDataChanged()"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Please enter a date"
            ></dxi-validation-rule>
          </dx-validator>
        </dx-date-box>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 payment-label">
        <label class="control-label">Payment Class</label>
      </div>
      <div class="col-md-6">
        <app-lookup
        #sourceLookup
        lookupTypeUserFriendly="Payment Class"
        lookupType="paymentclass"
        lookupSource="PaymentClass"
        [(selectedValue)]="payment.PaymentClassId"
        >
        </app-lookup>
      </div>
    </div>

    <div class="row">
      <div
        class="col-md-3 payment-label"
        *ngIf="paymentType === 'Payment' || paymentType === 'Refund'"
      >
        <label class="control-label">Payment Method</label>
      </div>
      <div
        [ngClass]="{'col-md-6': (!paymentMethodIsCreditCard || (paymentMethodIsCreditCard && gatewayDetails)), 'col-md-4': (paymentMethodIsCreditCard && !gatewayDetails)}"
        *ngIf="paymentType === 'Payment' || paymentType === 'Refund'"
      >
        <dx-select-box
          #paymentMethodSelect
          [disabled]="paymentProcessed || paymentGatewayFrameVisible || inputDisabled"
          [(value)]="payment.PaymentMethodId"
          [dataSource]="paymentMethods"
          displayExpr="Text"
          valueExpr="Id"
          (onValueChanged)="paymentMethodChanged($event)"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Please select a method"
            ></dxi-validation-rule>
          </dx-validator>
          <div *dxTemplate="let data of 'dropDownButton'">
            <dx-load-indicator [visible]="isLoadingPmtList"></dx-load-indicator>
            <div
              class="dx-dropdowneditor-icon"
              [hidden]="isLoadingPmtList"
            ></div>
          </div>
        </dx-select-box>
      </div>
      <a class="col-md-2 link-pointer" (click)="activateCCProcessing()"
         *ngIf="paymentMethodIsCreditCard && showActivateProcessing && !payment.Id">
        *Activate Processing?
      </a>
    </div>

    <div class='row' *ngIf='paymentMethodIsCreditCard && !showActivateProcessing'>
      <div class='col-md-3 payment-label'>
        <label for='gatewaySelect' class='control-label'>Payment Gateway</label>
      </div>
      <div class='col-md-6'>
        <dx-select-box
          id='gatewaySelect'
          [dataSource]='gateways'
          displayExpr='Name'
          [value]='gatewayDetails'
          [acceptCustomValue]='false'
          [disabled]='paymentProcessed || paymentGatewayFrameVisible || inputDisabled'
          (onValueChanged)='paymentGatewayChanged($event)'
        ></dx-select-box>
      </div>
    </div>

    <div class="row" [hidden]="paymentGatewayFrameVisible">
      <div class="col-md-3 payment-label">
        <label class="control-label"
               *ngIf="paymentType === 'Payment'"
               matTooltip="Person making the payment, will populate list with defendant and contacts">
          Received From
        </label>
        <label class="control-label" *ngIf="paymentType === 'Refund'">
          Paid To
        </label>
      </div>
      <div
        class="col-md-6"
        *ngIf="paymentType === 'Payment' || paymentType === 'Refund'"
      >
        <dx-select-box
          [disabled]="paymentProcessed || inputDisabled"
          [(value)]="payment.ReceivedFromPersonId"
          [dataSource]="people"
          displayExpr="FullName"
          valueExpr="PersonId"
          #ddlPeople
          (onValueChanged)="onPersonChanged($event)"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Please select Received From Person."
              *ngIf="paymentMethodIsCreditCard && !!gatewayDetails"
            ></dxi-validation-rule>
          </dx-validator>
          <div *dxTemplate="let data of 'dropDownButton'">
            <dx-load-indicator
              [visible]="isLoadingPeopleList"
            ></dx-load-indicator>
            <div
              class="dx-dropdowneditor-icon"
              [hidden]="isLoadingPeopleList"
            ></div>
          </div>
        </dx-select-box>
      </div>
    </div>
    <div class="row" [hidden]="paymentGatewayFrameVisible">
      <div class="col-md-3 payment-label" *ngIf="paymentType == 'Payment'">
        <label class="control-label">Reference Number</label>
      </div>
      <div class="col-md-6" *ngIf="paymentType == 'Payment'">
        <dx-text-box
          [(value)]="payment.ReferenceNumber"
          maxlength="128"
          [disabled]="inputDisabled"
          (onChange)="paymentDataChanged()"
        >
        </dx-text-box>
      </div>
    </div>

    <div class="row" [hidden]="paymentGatewayFrameVisible">
      <div class="col-md-3 payment-label" *ngIf="paymentType == 'Payment'">
        <label class="control-label">Office Received At</label>
      </div>
      <div class="col-md-6" *ngIf="paymentType == 'Payment'">
        <app-lookup
          #ddlAgencies
          lookupTypeUserFriendly="Agency"
          lookupType="agencies"
          lookupSource="Agencies"
          [allowAddCustomValue]="false"
          [allowDuplicates]="false"
          [useDefaultValue]="false"
          [fillSingleValue]="true"
          [forceCustomValueToList]="false"
          [(selectedValue)]="payment.ReceiveAtAgencyId"
          (selectedValueChange)="paymentDataChanged()"
          [fieldsDisabled]="inputDisabled"
        ></app-lookup>
      </div>
    </div>

    <div class="row" [hidden]="paymentGatewayFrameVisible">
      <div class="col-md-3 payment-label" *ngIf="paymentType == 'Payment'">
        <label class="control-label"
               matTooltip="Person taking the payment, will populate list with your users">
          Received By</label>
      </div>
      <div class="col-md-6" *ngIf="paymentType == 'Payment'">
        <app-lookup
          #receivedByUserLookup
          lookupTypeUserFriendly="User"
          lookupType="users"
          lookupSource="Users"
          [allowDuplicates]="false"
          [useDefaultValue]="false"
          [fillSingleValue]="true"
          [forceCustomValueToList]="false"
          [(selectedValue)]="payment.ReceivedByUserId"
          (selectedValueChange)="receivedByChanged()"
          [fieldsDisabled]="inputDisabled"
        ></app-lookup>
      </div>
    </div>
    <div class="row" [hidden]="paymentGatewayFrameVisible">
      <div class="col-md-3 payment-label">
        <label class="control-label">Comment</label>
      </div>
      <div class="col-md-6">
        <dx-text-box
          [(value)]="payment.Comment"
          maxlength="1000"
          [disabled]="inputDisabled"
          (onChange)="paymentDataChanged()"
        >
        </dx-text-box>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 payment-label">
        <label class="control-label">Receipt Number</label>
      </div>
      <div class="col-md-6">
        <dx-text-box
          [(value)]="payment.ReceiptNumber"
          maxlength="128"
          [disabled]="inputDisabled"
          (onChange)="paymentDataChanged()"
        >
        </dx-text-box>
      </div>
    </div>

    <div class="row">
      <div *ngIf="paymentProcessed" class="col-md-3 payment-label">
        <label class="control-label">Processor Status</label>
      </div>
      <div *ngIf="paymentProcessed" class="col-md-6">
        <label class="control-label">{{ ccApprovalCode }}</label>
      </div>
    </div>

    <!-- Save -->
    <dx-validation-summary #validationSummary></dx-validation-summary>

    <iframe
      marginwidth="0"
      marginheight="0"
      frameborder="0"
      data-private
      name="processPaymentFrame"
      #processPaymentFrame
      id="processPaymentFrame"
      *ngIf="paymentGatewayFrameVisible"
      [src]="paymentGatewayUrl | safe"
      (load)="onPaymentUrlChanged(processPaymentFrame)">
    </iframe>

    <app-first-data
      [gatewayId]='gatewayDetails.Id'
      *ngIf='showPayeezy'
      [amount]='totalAmount'
      [fpLogin]='gatewayDetails.GatewayCredential'
      [payerInfo]='payerInfo'
      [transactionHistory]='transactionHistory'
      (paymentComplete)='paymentComplete($event)'
      (paymentError)='paymentError($event)'
      [payment]='payment'
    >
    </app-first-data>

      <app-clover
        *ngIf='showClover'
        [amount]='totalAmount'
        [gateway]='gatewayDetails'
        [payment]='payment'
        [transactionHistory]='transactionHistory'
        (paymentComplete)='paymentComplete($event)'
        (paymentError)='paymentError($event)'
      >
      </app-clover>

      <app-american-spirit
        *ngIf='showASP'
        [amount]='totalAmount'
        [payment]='payment'
        [gateway]="gatewayDetails"
        [transactionHistory]="transactionHistory"
        (paymentComplete)="paymentComplete($event)"
        (paymentError)='paymentError($event)'
      ></app-american-spirit>

  </dx-validation-group>
</mat-card>
<app-buttonbar
  [navId]="sourceId"
  [routeKey]="routeKey"
  [saveButtonsDisabled]="saveButtonDisabled"
  [valGroup]="valGroup"
  #navButtonBar
  [saveMethod]="onSave"
  [cancelMethod]="cancel"
  [useCancelButton]="dynamicData !== undefined && dynamicData !== null"
  [otherActions]="otherActions"
  [allButtonsDisabled]="saveButtonDisabled"
  [showOtherActionsAsButtons]="true"
  [saveAndCloseText]="saveButtonText"
  [saveAndCloseButtonWidth]="saveButtonWidth"
  [saveAndCloseDivWidth]="saveButtonDivWidth"
  class="buttonbarfixed"
></app-buttonbar>

<app-errorpopup
  [height]="'300'"
  [width]="'400'"
  [resizeEnabled]="true"
  [closeOnOutsideClick]="false"
  [title]="errorTitle"
  [shading]="false"
  [data]="errorData"
  [visible]="errorVisible"
  [routeToGoToAfterDisplay]="errorUrl"
></app-errorpopup>
<app-dynamic-popup
  #dynamicPrintPopup
  [popupComponent]="dynamicPopupClass"
  [popupComponentData]="dynamicPopupData"
  [popupComponentTitle]="dynamicPopupTitle"
  [popupComponentVisible]="dynamicPopupVisible"
  [popupShowTitle]="dynamicPopupShowTitle"
  (onPopupHidden)="onPopupHidden($event)"
  [showCloseButton]="true"
  [popupAllowCloseOnOutsideClick]="false"
></app-dynamic-popup>
<dx-popup
  class="popup-component select-transaction-popup"
  [height]="300"
  [width]="500"
  [showTitle]="true"
  title="Pay on Which Transaction?"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="invoiceSelectionPopup"
>
  <div *dxTemplate="let data of 'content'">
    <div
      fxLayout="column"
      fxLayoutAlign="start center"
    >
      <ng-container *ngIf="transactions != null && transactions.length > 0">
        <div *ngFor="let item of transactions" [style.width]="'400px'" style="margin:0 15px 15px;">
          <dx-button
            *ngIf="item.show"
            [text]="item.buttonLabel"
            [type]="'warning'"
            [icon]=""
            [height]="36"
            (onClick)="setTransactionId(item)"
            [width]="'400px'"
            [matTooltip]="item.buttonLabel"
          ></dx-button>
        </div>
      </ng-container>
    </div>
  </div>
</dx-popup>

<app-yes-no-confirmation
  [showTitle]="false"
  [(showConfirmation)]="showYesNoConfirmation"
  showCloseButton="false"
  (onResult)="YesNoConfirmResult($event)"
  [confirmationMessage]="yesNoConfirmMessage"
  noText="Cancel"
  yesText="Delete"
  closeOnOutsideClick="false">
</app-yes-no-confirmation>

<dx-load-panel
  [(visible)]="componentLoading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="false"
  #loader
  [position]="{ of: '#non-print-container' }"
>
</dx-load-panel>
