import { createAction, props } from '@ngrx/store';
import { Message } from '../../models/message';

export enum MessageActionTypes {
  LoadMessages = '[Messages] Load Messages',
}

export const loadMessages = createAction(
  MessageActionTypes.LoadMessages,
  props<{ messages: Message }>(),
);
