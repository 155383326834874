import {catchError, map} from 'rxjs/operators';
import {throwError, Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {Address} from '../models/address';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()
export class AddressService {

  private postData: any;
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getAll(): Observable<any> {
    const postData = {};

    return this.http.get(`${this.settings.getBaseUrl()}/addresses`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);

  }

  get(id): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/address/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getForSource(id: number, sourceType: string) {
    return this.http.get(`${this.settings.getBaseUrl()}/address/bysource/${id}/${sourceType}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getDefaultForSource(id: number, sourceType: string): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/address/defaultforsource/${id}/${sourceType}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }


  search(): Observable<any> {
    const postData = {};

    return this.http.get(`${this.settings.getBaseUrl()}/addresses`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);

  }

}
