import { BaseModel } from './BaseModel';

export class DefendantAccountingDetails  extends BaseModel {
  // tslint:disable:variable-name
  Id: any;
  TransactionId: any;
  BondId: any;
  BondAmount: number;
  PowerNumber: any;
  Description: any;
  TransactionDate: any;
  Amount: number;
  PaymentId: any;
  Created_At: any;
  PaymentDetailId: any;
  PaymentMethod: string;

  constructor(obj?: object)
  {
    super(obj);

    if (obj == null) {
      this.Id = '';
      this.TransactionId = '';
      this.BondId = '';
      this.BondAmount = 0;
      this.PowerNumber = '';
      this.Description = '';
      this.TransactionDate = '';
      this.Amount = 0;
      this.PaymentDetailId = '';
    }
  }

}
