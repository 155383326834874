<section class='dialog-container'>
  <h2 mat-dialog-title>Update {{data.type | titlecase}}</h2>
  <div mat-dialog-content>
    <app-phone-list
      style='display:block;width: 80%'
      [showDoNotContact]='true'
      *ngIf='data.type === personUpdateType.MOBILE'
      [(model)]='data.person.phones'
    ></app-phone-list>
    <app-email-list
      style='display:block;width: 80%'
      *ngIf='data.type === personUpdateType.EMAIL'
      fxFill
      [(model)]='data.person.emails'
    ></app-email-list>
    <app-smartaddress
      style='display:block;width: 80%'
      *ngIf='data.type === personUpdateType.ADDRESS'
      [(model)]='data.person.addresses'
    ></app-smartaddress>
  </div>
  <mat-dialog-actions align='end'>
    <button mat-stroked-button [mat-dialog-close]='false' color='primary'>CANCEL</button>
    <button mat-raised-button (click)='saveInfo(data.person)' color='primary'>SAVE</button>
  </mat-dialog-actions>
</section>
