import { catchError, map } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { PortoResponse } from '../models/porto-response';
import { SearchResult } from '../models/search-result';
import { RelatedTransaction } from '../models/related-transaction';


export interface AdvancedSearchRequest {
  searchModule: 'defendant' | 'contact' | 'inventory' | 'payment';
  searchBy: string;
  searchValue: string;
}

@Injectable()
export class SearchService {
  currentSearch$: Observable<SearchResult[]>;
  currentRelatedSearch: RelatedTransaction[];
  lastSearch: string;
  lastRelatedSearch: string;

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getAllSearchData(
    query: string,
    defendantsOnly: boolean = false,
    hasCollections: boolean = false,
    hasBail: boolean = false,
    deleted: boolean = false,
    extended: boolean = false,
  ): Observable<SearchResult[]> {
    query = query.trim().replace(' ', '%25'); // make sure we handle <partial first partial last term>

    if (!!this.currentSearch$ && this.lastSearch === query) {
      return this.currentSearch$;
    } else {
      this.lastSearch = query;
      this.currentSearch$ = this.http.get<PortoResponse<SearchResult[]>>(`${this.settings.getBaseUrl()}/search?search=${query}&deleted=${deleted}&limit=0&bail=${hasBail}&collections=${hasCollections}&extended=${extended}`).pipe(
        map((res: any) => {
          this.currentSearch$ = null;
          return res.data;
        }),
        catchError(error => {
          throw error;
        }));
      return this.currentSearch$;
    }
  }

  getAdvancedSearch(search: AdvancedSearchRequest): Observable<SearchResult[]> {
    return this.http.post<PortoResponse<SearchResult[]>>(`${this.settings.getBaseUrl()}/search/advanced`, search).pipe(
      map((res: PortoResponse<SearchResult[]>) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),
    );
  }

  // Get collection search results
  getCollectionSearchResults (keyword: any): Observable<any> {
    // console.log('In search service: function: getCollectionSearchResults, keyword: ', keyword);
    return this.http.post(`${this.settings.getBaseUrl()}/collectionsearch`, {keyword: keyword}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  /**
   * Get all related transactions for a contact.
   * @param first (String): The first name of the contact.
   * @param last (string): The last name of the contact.
   */
  getRelatedTransactions(first: string, last: string): Observable<RelatedTransaction[]> {
    if (`${first}:${last}` === this.lastRelatedSearch) {
      return of(this.currentRelatedSearch);
    } else {
      this.lastRelatedSearch = `${first}:${last}`;
    }
    const params: HttpParams = new HttpParams()
      .set('first', first)
      .set('last', last);
    return this.http.get<PortoResponse<RelatedTransaction[]>>(`${this.settings.getBaseUrl()}/search/related/contact`, {params})
      .pipe(
        map(response => {
          this.currentRelatedSearch = response.data;
          return response.data;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),
      );
  }


}
