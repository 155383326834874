import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { CustomerUser } from '../../../models/customer-user';
import { NotifyService } from '../../../common/notify/notify.service';
import { AuthService } from '../../../services/auth.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-customer-users',
  templateUrl: './customer-users.component.html',
  styleUrls: ['./customer-users.component.scss'],
})
export class CustomerUsersComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  public dataSource: CustomerUser[];
  public displayedColumns: string[] = [
    'Name',
    'CustomerId',
    'V1Id',
    'Type',
    'Email',
    'Actions',
  ];
  public loading = false;

  constructor(
    private adminService: AdminService,
    private notifyService: NotifyService,
    private authService: AuthService,

  ) {
    super();
  }

  ngOnInit(): void {
  }

  applyFilter(filterValue: string) {
    this.loading = true;
    this.adminService.findUserByEmail(filterValue).subscribe((users) => {
      this.dataSource = users;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  loginAsCustomer(customerId: string) {
    this.loading = true;
    this.subs.sink = this.adminService.loginAsCustomer(customerId).subscribe(() => {
      this.authService.Logout();
    }, (err) => {
      this.loading = false;
      this.notifyService.toast('Failed to login as customer', 'error');
      console.error(err);
    });
  }

}
