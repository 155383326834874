import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { BadDataUpdate } from './badDataUpdate';
import {CollectionReminder} from '../models/collectionreminders';
import {PortoResponse} from '../models/porto-response';
@Injectable()
export class DebtorService {

  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  // Create collection (debtors) accounts
  create(data): Observable<any> {
    // console.log('Debtor create data in service: ', data);
    return this.http.post(`${this.settings.getBaseUrl()}/debtors`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error)),);
  }

  // Create collection (debtors) accounts
  createBulk(data, importType = '', scheduleDay = ''): Observable<any> {
    // console.log('Debtor create data in service: ', data);
    return this.http.post(`${this.settings.getBaseUrl()}/debtor/bulk?importType=${importType}&scheduleDay=${scheduleDay}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error)),);
  }

  // Get collection (debtors) account filtered with status (ACTIVE or CLOSED)
  getAccounts(status: string): Observable<any> {
    // console.log('Debtor getAccounts in service for status: ', status);
    return this.http.get(`${this.settings.getBaseUrl()}/debtors/status/${status}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Get all collection (debtors) accounts
  getAllAccounts(): Observable<any> {
    // console.log('DebtorService.getAllAccounts');
    return this.http.get(`${this.settings.getBaseUrl()}/debtors`)
      .pipe(map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }
  // Get all collection (debtors) accounts paginated
  getAllAccountsPaginated(params: HttpParams): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/debtors`, {params})
      .pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  // Get all collection (debtors) accounts paginated
  exportAccounts(params: HttpParams): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/debtors/export/all`, {params, responseType: 'arraybuffer'});
  }

  exportCommsAudit(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/debtorcommsaudit`, {responseType: 'arraybuffer'});
  }
  // Get all collection (debtors) accounts
  getAllAccountsWithInclude(includes: string): Observable<any> {
    // console.log('DebtorService.getAllAccounts');
    return this.http.get(`${this.settings.getBaseUrl()}/debtors?include=${includes}`)
      .pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getAllAccountsForExport(): Observable<any> {
    // console.log('In function getAllAccounts in debtor service!');
    return this.http.get(`${this.settings.getBaseUrl()}/export/debtor`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  // Get a single account with Id
  getSingleAccount(id: string, includes: string = null): Observable<any> {
    // console.log('In function getSingleAccount in debtor service, for id: ', id);
    let url = `${this.settings.getBaseUrl()}/debtors/${id}`;
    if (includes) {
      url = url + '?include=' + includes;
    }
    return this.http.get(url).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  getSingleAccountClientAuth(id: string, includes: string = null, paymentId = null): Observable<any> {
    // console.log('In function getSingleAccount in debtor service, for id: ', id);
    let url = `${this.settings.getBaseUrl()}/debtorsinternal/${id}`;
    if (paymentId) {
      url = `${this.settings.getBaseUrl()}/debtorsinternalbypaymentid/${paymentId}`;
    }
    if (includes) {
      url = url + '?include=' + includes;
    }
    return this.http.get(url).pipe(
      tap((res) => {
        // console.log('getSingleAccountClientAuth res', res);
      }),
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Create activity for debtor
  // Required params: {CollectionAccountsId, Type, Value}
  createActivity(data: any): Observable<any> {
    // console.log('In function createActivity: Debtor service: create data: ', data);
    return this.http.post(`${this.settings.getBaseUrl()}/debtoractivity`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error)),);
  }

  // Update imported data from CSV and after changes from customer
  updateImportedRecords(data): Observable<any> {
    // console.log('In function updateImportedRecords: Debtor service: update data: ', data);
    return this.http.post(`${this.settings.getBaseUrl()}/updaterecords`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error)),);
  }

  updateFilteredRecords(reason: string, params: HttpParams): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/debtors/update/filtered`, {reason}, {params})
      .pipe(
        map((res: any) => res),
        catchError(error => throwError(error)),
      );
  }

  // Get debtor activities
  getDebtorActivities(id: string): Observable<any> {
    // console.log('In function getDebtorActivities in debtor service, for id: ', id);
    return this.http.get(`${this.settings.getBaseUrl()}/debtoractivities/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Save payment schedules
  savePaymentSchedules(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/collectionpaymentschedules`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getAllPaymentSchedules(): Observable<any> {
    // console.log('DebtorService.getAllPaymentSchedules');
    return this.http.get(`${this.settings.getBaseUrl()}/collectionpaymentschedules`)
      .pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  // Get payment schedule of debtor
  getPaymentScheduleClientAuth(debtorId) {
    return this.http.get(`${this.settings.getBaseUrl()}/collectionpaymentschedules/debtor/${debtorId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Close debtor account
  closeDebtorFile(data) {
    return this.http.post(`${this.settings.getBaseUrl()}/debtor/changefile`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Open debtor account
  openDebtorFile(data) {
    return this.http.post(`${this.settings.getBaseUrl()}/debtor/changefile`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Add note to debtor account
  addNote(data) {
    return this.http.post(`${this.settings.getBaseUrl()}/collectionsnotes`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Get notes of debtor account
  getDebtorNotes(id: string): Observable<any> {
    // console.log('In function getDebtorNotes in debtor service, for id: ', id);
    return this.http.get(`${this.settings.getBaseUrl()}/debtornotes/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Get invoice of debtor account
  getDebtorInvoices(id: string): Observable<any> {
    // console.log('In function getDebtorInvoices in debtor service, for id: ', id);
    return this.http.get(`${this.settings.getBaseUrl()}/debtorinvoices/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Get debtor documents
  getDebtorDocuments(id: string): Observable<any> {
    // console.log('In function getDebtorDocuments in debtor service, for id: ', id);
    return this.http.get(`${this.settings.getBaseUrl()}/debtordocuments/${id}`).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Update debtor account with children
  updateDebtorWithChildren(data: any): Observable<any> {
    // console.log('In function updateDebtorWithChildren in debtor service, for data: ', data);
    return this.http.put(`${this.settings.getBaseUrl()}/updatedebtorwithchildren/${data.Id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Update a payment schedule of a debtor
  updatePaymentSchedule(data: any): Observable<any> {
    // console.log('In function updatePaymentSchedule in debtor service, for data: ', data);
    return this.http.patch(`${this.settings.getBaseUrl()}/collectionpaymentschedulesdetails/${data.Id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Skip a scheduled payment of a debtor
  skipPayment(data: any): Observable<any> {
    // console.log('In function skipPayment in debtor service, for Id: ', data);
    return this.http.post(`${this.settings.getBaseUrl()}/skipcollectionpaymentscheduledetails/${data.Id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Create counter offer by Debtor from public payment page
  createOfferClientAuth(data: any) {
    // console.log('In function createOffer in debtor service, for data: ', data);
    return this.http.post(`${this.settings.getBaseUrl()}/counteroffers`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Create counter offer by Debtor from public payment page
  getCounterOffers() {
    // console.log('In function getCounterOffers in debtor service');
    return this.http.get(`${this.settings.getBaseUrl()}/counteroffers`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Update counter offer request: Approve or Reject from Customer listing
  updateCounterOffer(Id: string, data: any) {
    // console.log('In function updateCounterOffer in debtor service for Id: ', Id, ' data: ', data);
    return this.http.put(`${this.settings.getBaseUrl()}/counteroffers/${Id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Get dashboard summary notifications details
  getDashboardSummary() {
    // console.log('In function getDashboardSummary in debtor service');
    return this.http.get(`${this.settings.getBaseUrl()}/dashboardsummary`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Create invoice claim proof by Debtor from public page
  claimInvoice(data: any) {
    // console.log('In function claimInvoice in debtor service, for data: ', data);
    return this.http.post(`${this.settings.getBaseUrl()}/invoiceclaim`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Get invoice claims fro a customer
  getInvoiceClaims() {
    // console.log('In function getInvoiceClaims in debtor service!');
    return this.http.get(`${this.settings.getBaseUrl()}/invoiceclaim`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Update invoice claims request: Approve or Reject from Customer listing
  updateInvoiceClaims(Id: string, data: any) {
    // console.log('In function updateInvoiceClaims in debtor service for Id: ', Id, ' data: ', data);
    return this.http.put(`${this.settings.getBaseUrl()}/collectioninvoiceclaims/${Id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  // Get Payment range config for for public page using debtor id
  getPaymentRangeConfigByDebtorId(debtorId): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/paymentrangesbydebtor/${debtorId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  // Get Payment range config for customers
  getPaymentRangeConfig(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/paymentranges`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  savePaymentSchedulesClientAuth(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/collectionpaymentschedules/internal`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getBadData(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/collectionbaddata`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  updateBadData(data: BadDataUpdate): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/collectionbaddata`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  updateBadDataBulk(data: Array<BadDataUpdate>): Observable<any> {
    const dataToSend = {
      bulkUpdateData: data,
    };
    return this.http.post(`${this.settings.getBaseUrl()}/collectionbaddatabulk`, JSON.stringify(dataToSend)).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  createPaymentMonitorLog(data: any): Observable<any> {

    return this.http.post(`${this.settings.getBaseUrl()}/paymentmonitors`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  payStripeInvoice(invoiceId: string, gatewayToken: string): Observable<boolean> {
    return this.http.post<string>(`${this.settings.getBaseUrl()}/stripe/invoice/pay/${invoiceId}`, {
      gateway_token: gatewayToken,
    }).pipe(
      map((response) => {
        return response === 'paid';
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  fixBadData(): Observable<any> {
    return this.http.post<string>(`${this.settings.getBaseUrl()}/collection/fixbaddata`, {}).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  fixSelectedBadRecords(records : any): Observable<any> {
    return this.http.post<string>(`${this.settings.getBaseUrl()}/collection/fixselectedbaddata`, records).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  sendArbitrationMail(debtorId: string): Observable<any> {
    return this.http.post<string>(`${this.settings.getBaseUrl()}/debtorarbitrationmail/${debtorId}`, {}).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  saveDebtorAction(debtorId: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.settings.getBaseUrl()}/logdebtoraction/${debtorId}`, data).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  getCaseNumber(data: any): Observable<any> {
    return this.http.get<any>(`${this.settings.getBaseUrl()}/getcasenumber/${data.debtorId}`, {}).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  generateCaseNumber(data: any): Observable<any> {
    return this.http.post<any>(`${this.settings.getBaseUrl()}/generatecasenumber/${data.debtorId}`, {}).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  updateDebtorCaseInfo(data: any, debtorId : string, customerId: string, userType: string|null, reminderType: string|null): Observable<any> {
    return this.http.put<any>(`${this.settings.getBaseUrl()}/updatedebtorcaseinfo/${debtorId}`, {
      person: data,
      customerId,
      userType,
      reminderType
    }).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  updateDebtorCaseEmail(debtorId: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.settings.getBaseUrl()}/updatedebtorcaseemail/${debtorId}`, data).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }
  
  getStripePaymentAndBgInfo(customerId): Observable<any> {
    return this.http.get<any>(`${this.settings.getBaseUrl()}/getstripepaymentandbginfo/${customerId}`).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
      );
    }

  lockStripeId(customerId : string): Observable<any> {
    return this.http.put<any>(`${this.settings.getBaseUrl()}/lockstripeid/${customerId}`, {}).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  getCollectionReminderHistory(id: any): Observable<CollectionReminder[]> {
    return this.http.get<PortoResponse<CollectionReminder[]>>(`${this.settings.getBaseUrl()}/reminders/collection/${id}`).pipe(
      map((res) => res.data),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  createFriendsUrl(data : {debtorId: string, customerId: string}): Observable<any> {
    return this.http.post<any>(`${this.settings.getBaseUrl()}/debtor/generate-friends-link`, data).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }

  getDebtorAmounts(id: any): any {
    return this.http.get<any>(`${this.settings.getBaseUrl()}/debtors/get-debtor-amount/${id}`).pipe(
      map((res) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  sendPeerToPeerMailToCustomer(data: any) {
    return this.http.post<any>(`${this.settings.getBaseUrl()}/debtor/sendpeertopeermail/${data.CustomerId}`, data).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => throwError(error.error || 'Server Error')),
    );
  }
}


