import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { DatamartService, ReportType } from '../../services/datamart.service';
import { GridService } from '../../services/grid.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { PdfViewerComponent } from '../../components/common/pdf-viewer/pdf-viewer.component';
import { IDynamicPopupComponent } from '../../components/common/dynamic-popup/dynamic-popup.component';
import { DatamartComponent } from './datamart.component';
import { IncarcerationHistoryReportComponent } from '../incarceration-history-report/incarceration-history-report.component';
import {UnsubscribeOnDestroyAdapter} from '../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-datamart-history',
  templateUrl: './datamart-history.component.html',
  styleUrls: ['./datamart-history.component.css'],
  entryComponents: [PdfViewerComponent],
})

// as of 12/22/2018 this only supports incarceration history search, but could be expanded to allow for all.
export class DatamartHistoryComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild(IncarcerationHistoryReportComponent) incarcerationHistory: IncarcerationHistoryReportComponent;
  @Input() searchType: ReportType;

  reportTypeList: any;
  searchHistory: any;
  friendlySearchName: string;

  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData = [];
  dynamicPopupTitle = 'DataMart Search';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;

  incarcerationHistoryVisible = false;
  incarcerationHistoryId: any;

  constructor(private datamartService: DatamartService,
              private gridService: GridService) {
                super();
    this.reportTypeList = this.datamartService.getReportTypeList();
  }

  ngOnInit() {
    this.friendlySearchName = this.reportTypeList.find(i => i.id === this.searchType).name;
    this.subs.sink = this.datamartService
    .getHistoryByType(this.searchType)
    .subscribe((history: any) => {
      // console.log('GetHistoryByTypeReturn', history);
      this.searchHistory = history.data;
    });
  }

  OpenHistory(data) {
    if (data.data.QueueRequestType === '55') {
      this.incarcerationHistoryId = data.data.QueueId;
      this.incarcerationHistoryVisible = true;
      if (this.incarcerationHistory) {
        this.incarcerationHistory.initialize();
      }
    } else {
      this.dynamicPopupData = [];
      // console.log('getting history for data', data);
      this.dynamicPopupShowTitle = false; // need to do this in order not show green weirdness
      this.dynamicPopupData['pdfObservable'] = this.datamartService.getResponsePdf(data.data.QueueId);
      this.dynamicPopupData['esignable'] = false;
      this.dynamicPopupClass = PdfViewerComponent;
    }
  }

  DoSearch() {
    this.dynamicPopupData = [];
    this.dynamicPopupData['lockedToReportType'] = ReportType.Incarceration;
    this.dynamicPopupData['doNotPopulateFromRecord'] = true;
    this.dynamicPopupData['submitButtonText'] = 'Search';
    this.dynamicPopupShowTitle = true;
    this.dynamicPopupTitle = 'Search For Arrest History';
    this.dynamicPopupClass = DatamartComponent;
    this.dynamicPopupVisible = true;
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    const buttonText = `New ${this.friendlySearchName}`;
    toolbarItems.forEach(function (item) {
        item.location = 'after';
        // console.log(item);
        item.showText = 'always';
        if (item.name === 'addRowButton') {
          item.location = 'before';
          item.options.text = buttonText;
          item.options.icon = 'fal fa-plus';
        }
        if (item.name === 'exportButton') {
          item.options.text = 'Export';
          item.options.icon = 'fal fa-file-excel fa-1x';
        }
      },
    );

    toolbarItems.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-plus fa-1x',
          type: 'default',
          text: 'New Arrest History Search - $4.00',
          onClick: () => {
            this.DoSearch();
          },
        },
      },
    );

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.gridService.exportGrid(this.dataGrid, `${this.friendlySearchName}`);
          },
        },
      },
    );
  }

  onDynamicPopupHidden() {
    this.dynamicPopupClass = null;
  }
}
