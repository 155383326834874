import { BaseModel } from './BaseModel';
import { Share } from './share';
import { DocumentVersion, Version } from './document-version';

// tslint:disable:variable-name
export class Document extends BaseModel {
  public Id: string;
  public Comment: string;
  public DocumentLink: string;
  public Private: boolean;
  public Status: string;
  public Type: string;
  public Size: number;
  public SizeText: string;
  public Name: any;
  public File: any;
  public Key: any;

  public SourceId: any;
  public SourceType: any;
  public Created_At: Date;

  public tempKey: any;
  public ModifiedDate: any;

  public CustomerId: string;

  public SigningStatus: string;

  public shares: Share[];
  public version: DocumentVersion[];

  constructor(obj?: object) {
    super(obj);
    this.File = [];
    this.routeRoot = 'documents';
    this.childrenNames = ['shares', 'version'];
    this.propertiesToIgnoreForCheckChanges.push('File');
    this.propertiesToIgnoreForCheckChanges.push('tempKey');

    if (obj == null) {
      this.Id = '';
      this.Private = false;
      this.Key = {};
      this.shares = [];
      this.version = [];
    }
  }

  loadData(data): void {
    this.fillFromObject(data, true);
    if (!!data.shares && !!data.shares.data && data.shares.data.length > 0) {
      for (const share of data.shares.data) {
        const _share = new Share();
        _share.fillFromObject(share, true);
        this.shares.push(_share);
      }
    }
    if (!!data.version && !!data.version.data && data.version.data.length > 0) {
      for (const version of data.version.data) {
        const _version = new Version();
        _version.fillFromObject(version, true);
        this.version.push(_version);
      }
    }
  }
}
