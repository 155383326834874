import { Component, OnInit, ViewChild } from '@angular/core';
import { WhatsNewService } from '../services/whats-new.service';
import { WhatsNewServiceModel } from '../../../models/whats-new-service-model';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { DocumentService } from '../../../services/document.service';
import { FileUtilities } from '../../../shared/FileUtilities';
import { NotifyService } from '../../../common/notify/notify.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss'],
})
export class WhatsNewComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild('serviceForm') serviceForm: DxFormComponent;
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  services: any[] = [];
  popupTitle: string;
  currentRow: WhatsNewServiceModel | null;
  popupVisible = false;
  currentRowImageFile: File | null;

  constructor(
    private whatsNewService: WhatsNewService,
    private documentService: DocumentService,
    private notify: NotifyService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadServices();
  }

  loadServices() {
    this.subs.sink = this.whatsNewService.loadServices().subscribe((res: any) => {
      this.services = res.data;
    });
  }

  docUploaded(e: any) {
    const self = this;
    if (e !== null && e.value !== null && e.value.length > 0) {
      const files = e.value;
      files.forEach(function (file: File) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          // console.log('file loaded', file);
        };
        fileReader.onloadend = () => {
          // console.log('file loaded end', file);
          self.currentRowImageFile = file;
        };
        fileReader.readAsArrayBuffer(file);
      });
    }
  }

  onRowInserting(e: any) {
    e.cancel = true;
    this.dataGrid.instance.cancelEditData();
    if (!this.currentRowImageFile) {
      this.notify.toast('Please upload an image', 'error');
      return;
    }
    this.uploadImage(e.data);
  }

  uploadImage(serviceData: any) {
    console.log('uploadImage', this.currentRowImageFile);
    this.dataGrid.instance.beginCustomLoading('Uploading image...');
    this.subs.sink = this.documentService.uploadAdminDocument(<File>this.currentRowImageFile).subscribe((res: any) => {
      // console.log('uploaded image', res);
      const file: File = <File>this.currentRowImageFile;
      serviceData.ImageLink = res.data.Location;
      serviceData.ImageName = file.name;
      serviceData.ImageSize = file.size;
      serviceData.ImageSizeText = FileUtilities.FileSizeText(file.size);
      serviceData.ImageType = file.type;
      this.currentRowImageFile = null;
      this.saveService(serviceData);
    });
  }

  saveService(serviceData: any) {
    // console.log('saveService', serviceData);
    if (serviceData.Id) {
      this.subs.sink = this.whatsNewService.update(serviceData.Id, serviceData).subscribe(res => {
        this.loadServices();
        this.dataGrid.instance.endCustomLoading();
      }, err => {
        // console.error('save service err', err);
        this.dataGrid.instance.endCustomLoading();
      });
    } else {
      this.subs.sink = this.whatsNewService.save(serviceData).subscribe(res => {
        // console.log('saved service', res);
        this.services.push(res.data);
        this.dataGrid.instance.endCustomLoading();
      }, err => {
        console.error('save service err', err);
        this.dataGrid.instance.endCustomLoading();
      });
    }
  }

  onInitNewRow(e: any) {
    e.data.Active = true;
  }

  onRowUpdating(e: any) {
    // console.log('onRowUpdating', e);
    e.cancel = true;
    this.dataGrid.instance.cancelEditData();
    if (this.currentRowImageFile) {
      // console.log('uploading image first');
      this.uploadImage({ ...e.newData, Id: e.oldData.Id });
    } else {
      this.saveService({ ...e.newData, Id: e.oldData.Id });
    }
  }

  onRowRemoving(e: any) {
    e.cancel = true;
    this.subs.sink = this.whatsNewService.delete(e.data.Id).subscribe(res => {
      // console.log('deleted service', res);
      this.services = this.services.filter(entry => entry.Id !== e.data.Id);
    }, err => {
      console.error('delete service err', err);
    });
  }

  onEditingStart(e) {
    this.currentRow = e.data;
  }

}
