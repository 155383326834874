import {catchError, map, share} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PermissionService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  getAllRoles(): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/roles?limit=0`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  updateRole(Id: any, data: any): Observable<any>
  {   return this.http.patch(`${this.settings.getBaseUrl()}/roles/${Id}`, data).pipe(
    map((res: any) => res),
    catchError(error => throwError(error.error || 'Server Error')),);

  }

  addRole( data: any): Observable<any>
  {   return this.http.post(`${this.settings.getBaseUrl()}/roles`, data).pipe(
    map((res: any) => res),
    catchError(error => throwError(error.error || 'Server Error')),);

  }

  deleteRole( Id: any): Observable<any>
  {
    return this.http.delete(`${this.settings.getBaseUrl()}/roles/${Id}`).pipe(
    map((res: any) => res),
    catchError(error => throwError(error.error || 'Server Error')),);

  }

  getAllPermissions(): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/permissions?limit=0`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getPermissionsByRole(Id): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/permissions/roles/${Id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  syncRolePermissions(roleId, permissionIds: string[]): Observable<any> {
    let postData={
      role_id : roleId,
      permission_ids: permissionIds
    }
    return this.http.post(`${this.settings.getBaseUrl()}/permissions/sync`, postData).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getPermissionsByUser(id): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/permissions/user/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),
      share(),
    );
  }

  syncUserPermissions(userId, permissionIds: string[]): Observable<any> {
    const postData = {
      user_id : userId,
      permission_ids: permissionIds,
    };
    return this.http.post(`${this.settings.getBaseUrl()}/permissions/user/sync`, postData).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

}
