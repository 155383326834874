import {BaseModel} from './BaseModel';
import {isNullOrUndefined} from 'util';

export class Lawsuit extends BaseModel {
  public Id: string;
  public Identifier: string;
  public ExoneratedDate: any;
  public ReceivedDate: any;
  public FiledDate: any;
  public Type: string;

  public Plaintiff1: string;
  public Plaintiff2: string;
  public PlaintiffAttorneyId: string;

  public Defendant1: string;
  public Defendant2: string;
  public Defendant3: string;
  public DefenseAttornyId: string;

  public Amount: number;
  public TotalCost: number;
  public JudgementCost: number;
  public AttorneyCost: number;
  public OtherCost1: number;
  public OtherCost2: number;
  public PaidBy: any;
  public PaidDate: any;
  public CheckNumber: string;


  constructor(obj?: object) {
    super(obj);
    this.routeRoot = 'lawsuits';
    if (obj == null) {
      this.Id = '';

      this.checkForNullCosts();
    }


  }

  private checkForNullCosts() {
    if (isNullOrUndefined(this.JudgementCost)) {
      this.JudgementCost = 0;
    }
    if (isNullOrUndefined(this.AttorneyCost)) {
      this.AttorneyCost = 0;
    }
    if (isNullOrUndefined(this.OtherCost2)) {
      this.OtherCost2 = 0;
    }
    if (isNullOrUndefined(this.OtherCost1)) {
      this.OtherCost1 = 0;
    }
    if (isNullOrUndefined(this.TotalCost)) {
      this.TotalCost = 0;
    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);
    this.checkForNullCosts();
  }
}
