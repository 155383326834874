<dx-autocomplete
  (onFocusIn)="onSearchBoxFocusIn($event)"
  (onFocusOut)="onSearchBoxFocusOut($event)"
  (onSelectionChanged)="onSearchBoxSelectionChanged($event)"
  [placeholder]="placeHolderText"
  [value]="selectedDefendant"
  [showClearButton]="showClearButton"
  (onValueChanged)="onQueryChange($event)"
  [disabled]="disabled"
>
</dx-autocomplete>

<div class="text-center" style="margin-top: 16px;">
  <dx-load-indicator
    [visible]="
      showLoadIndicator &&
      isLoading &&
      searchKeyword !== undefined &&
      searchKeyword !== null &&
      searchKeyword !== '' &&
      searchKeyword !== 'recent'
    ">
  </dx-load-indicator>
</div>

<app-searchresults
  [style]="searchResultStyle"
  [templateClass]="'search-result-inline'"
  #SearchBoxResults
  id="SearchBoxResults"
  [selectMode]="selectMode"
  (onItemSelected)="onItemSelected($event)"
  [searchResults]="searchResults"
  [searchKeyword]="searchKeyword"
  (onSearchHidden)="hideSearch()">
</app-searchresults>

<div class="text-danger" *ngIf="searchKeyword && searchResults.length === 0 && !isLoading">
  No Records Found! Try another term?
</div>
