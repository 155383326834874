<div class="pdf-viewer-root">
  <div class="pdf-viewer-header">
    <dx-toolbar class="toolbar-report">
      <!--          <dxi-item location="before" locateInMenu="never">-->
      <!--            <dx-button stylingMode="text" [visible]="this.sourceType === 'DefendantTransactions' && !pdfLoaded" (onClick)="openNewAppTab()">-->
      <!--              <div *dxTemplate="let data of 'content'">-->
      <!--                <span class='dx-button-text'>Keep Working</span>-->
      <!--                <i class="fal fa-external-link fa-1x" style="margin-left: 8px;"></i>-->
      <!--              </div>-->
      <!--            </dx-button>-->
      <!--          </dxi-item>-->
      <dxi-item [visible]="pdfLoaded && !fileDownloadError" location="before" locateInMenu="never">
        <div class="toolbar-label">
          <span class="page-number dx-button-text">Page&nbsp;</span>
          <span>{{currentPageNumber}} / {{totalPageNumber}}</span>
        </div>
      </dxi-item>
      <dxi-item [visible]="false" [disabled]="!pdfLoaded" location="before">
        <dx-button (onClick)="emailPdf()">
          <div *dxTemplate="let data of 'content'">
            <span class="dx-icon fal fa-envelope mr-5"></span>
            <span class='dx-button-text'>Email</span>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [visible]="false" [disabled]="!pdfLoaded" location="before">
        <dx-button (onClick)="searchPdf()">
          <div *dxTemplate="let data of 'content'">
            <span class="dx-icon fal fa-search mr-5"></span>
            <span class='dx-button-text'>Find</span>
          </div>
        </dx-button>
      </dxi-item>
      <!--
      <dxi-item location="center" locateInMenu="never">
          <div class="toolbar-label">
              <b>{{title}}</b>
          </div>
      </dxi-item>
      -->
      <dxi-item [visible]="multipleObservables" [disabled]="fileDownloading" location="center" locateInMenu="never">
        <dx-select-box #observableSelectBox
                       [dataSource]="pdfDownloadObservables$"
                       displayExpr="name"
                       valueExpr="id"
                       (onValueChanged)="onObservableChanged($event)"
        >
          <dxo-drop-down-options
            [minWidth]="300"
          ></dxo-drop-down-options>
        </dx-select-box>
      </dxi-item>
      <dxi-item [disabled]="!pdfLoaded" [visible]="allowEsign" location="after">
        <dx-button stylingMode="text" (onClick)="showESignPopup()">
          <div *dxTemplate="let data of 'content'">
            <span class="dx-icon fal fa-signature mr-8"></span>
            <span class='dx-button-text'>eSign</span>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [disabled]="!pdfLoaded" [visible]="!allowEsign && esignEnabled" location="after">
        <dx-button stylingMode="text" (onClick)="esignProcessInitiate()">
          <div *dxTemplate="let data of 'content'">
            <span class="dx-icon fal fa-signature mr-8"></span>
            <span class='dx-button-text'>eSign</span>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item *ngIf='categoricalReportList' location="after" [visible]="categoricalReportList" locateInMenu="never">
        <app-reportpicker
          id="appReportPicker"
          [reportType]="reportCategory"
          (onReportSelected)="onReportSelected($event)"
          (onReportsLoaded)="onReportListLoaded($event)">
        </app-reportpicker>
      </dxi-item>
      <dxi-item [disabled]="!pdfLoaded" [visible]='isDocumentViewer && allowEdits' location="after" locateInMenu="never">
        <dx-button stylingMode="text" (onClick)="saveChanges()">
          <div *dxTemplate="let data of 'content'">
            <span class="dx-icon fal fa-save mr-5"></span>
            <span class='dx-button-text'>Save</span>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [disabled]="!pdfLoaded" location="after" locateInMenu="never">
        <dx-button stylingMode="text" (onClick)="printPdf()">
          <div *dxTemplate="let data of 'content'">
            <span class="dx-icon fal fa-print mr-5"></span>
            <span class='dx-button-text'>Print</span>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [disabled]="!pdfLoaded" location="after" locateInMenu="never">
        <dx-button stylingMode="text" (onClick)="downloadPdf()">
          <div *dxTemplate="let data of 'content'">
            <span class="dx-icon fal fa-download mr-5"></span>
            <span class='dx-button-text'>Download</span>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [visible]="associatedRecord" [disabled]="!pdfLoaded" location="after" locateInMenu="never">
        <dx-button stylingMode="text" (onClick)="attachPdf()" id="attach-button">
          <div *dxTemplate="let data of 'content'">
            <span class="dx-icon fal fa-paperclip mr-5"></span>
            <span class='dx-button-text'>Attach</span>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [disabled]="!pdfLoaded" location="after" locateInMenu="never">
        <dx-button stylingMode="text" (onClick)="zoomInPdf()">
          <div *dxTemplate="let data of 'content'">
            <i class="dx-icon fal fa-search-plus"></i>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [disabled]="!pdfLoaded" location="after" locateInMenu="never">
        <dx-button stylingMode="text" (onClick)="zoomOutPdf()">
          <div *dxTemplate="let data of 'content'">
            <i class="dx-icon fal fa-search-minus"></i>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [disabled]="!pdfLoaded" location="after" locateInMenu="never">
        <dx-button stylingMode="text" (onClick)="rotatePdf()">
          <div *dxTemplate="let data of 'content'">
            <i class="dx-icon fal fa-redo"></i>
          </div>
        </dx-button>
      </dxi-item>
      <dxi-item [visible]="isPopup" location="after">
        <dx-button stylingMode="text" [elementAttr]="{class: 'maximize-button'}" (click)="toggleMaximizeWindow()"
                   [icon]="maximizeIcon"></dx-button>
        <dx-button stylingMode="text" (onClick)="onCloseButton()" icon="fal fa-times"></dx-button>
      </dxi-item>
    </dx-toolbar>
  </div>
  <div style="text-align: center; position: absolute; top: 150px; text-transform: none !important; z-index: 10000; width: 100%">
    <dx-button text="Please click here to continue working while we get your forms →"
                type="primary"
               [visible]="this.sourceType === 'DefendantTransactions' && !pdfLoaded && !fileDownloadError"
               (onClick)="openNewAppTab()"
    >
    </dx-button>
  </div>
  <div #pdfViewerContainer id="pdf-viewer-container" class="pdf-viewer-container" [ngStyle]="{'min-height': dynamicLoadingHeight - 30, 'height': fileDownloading ? '100vh' : 'auto' }" (scroll)='onScroll.emit($event)'>
    <app-loader class="custom-pdf-loader" *ngIf="fileDownloading"></app-loader>
    <div *ngIf="!fileDownloading && !pdfLoading && !pdfLoaded" class="download-content">
      <h3 style="margin-top: 1em">
        No {{objectTypeName}} selected.
      </h3>
    </div>
    <div *ngIf="fileDownloadError" class="download-content">
      <h3 style="margin-top: 1em">
        Oops, we are unable to generate the {{objectTypeName}} for you.
      </h3>
      <div>
        <button mat-button style="margin-top: 1em" (click)="showErrorMessage = !showErrorMessage">Details</button>
        <button mat-raised-button style="margin-top: 1em;margin-left:1em;" color="primary" *ngIf="showBlankButton" (click)="runBlankForm()">Generate Blank Form</button>
        <div style="margin-top: 1em" class="error" *ngIf="showErrorMessage">
          {{errorMessage}}
        </div>
      </div>
    </div>
    <div #pdfViewer id="pdf-viewer" class="pdfViewer" style="position:relative;"></div>
    <div *ngIf="pdfPurpose === 'eSign Position'">
      <div ngDraggable
           (endOffset)="onMoveEnd($event)"
           class="singButton"
           id="singButton"
           title="Drag me on signature position"
           [style.left.px]="esignXCoordinate ? esignXCoordinate : '0'"
           [style.top.px]="esignYCoordinate ? esignYCoordinate : '0'">
        <div *ngIf="!!esignX && !!esignY" class="fontSize">
          {{esignX}}%, {{esignY}}px
        </div>
        <div *ngIf="!esignX && !esignY" class="fontSize">
          {{esignXPercent}}%, {{esignYPercent}}px
        </div>
      </div>
    </div>
  </div>
  <div id="pdf-print-container" class="pdf-print-container"></div>
  <dx-popup
    class="popup"
    [width]="300"
    [height]="250"
    [dragEnabled]="false"
    [showCloseButton]="false"
    [showTitle]="false"
    [closeOnOutsideClick]="true"
    [(visible)]="viewerPopupVisible">
    <div *dxTemplate="let data of 'content'">
      <div class="viewer-popup-content">
        <dx-load-indicator class="center-indicator">
        </dx-load-indicator>
        <h3>{{viewerPopupMessage}}</h3>
      </div>
    </div>
  </dx-popup>
  <app-yes-no-confirmation
    [showTitle]="true"
    [titleText]="confirmationTitle"
    [(showConfirmation)]="confirming"
    [showCloseButton]="false"
    (onResult)="onConfirmResult($event)"
    [confirmationMessage]="confirmationMessage">
  </app-yes-no-confirmation>
  <dx-popup
    class="popup"
    [width]="300"
    [height]="250"
    [dragEnabled]="false"
    [showCloseButton]="false"
    [showTitle]="false"
    [closeOnOutsideClick]="true"
    [visible]="esignAfterAttachPopupVisible"
    >
<p>Document is prepared. Set up signers?</p>
    <button
      mat-raised-button
      (click)="esignGoToSecureDocs()">
      Add signer
      <i class="fal a-external-link fa-1x"></i>
    </button>
  </dx-popup>

  <dx-popup
    class="popup"
    [width]="800"
    [dragEnabled]="false"
    [showCloseButton]="true"
    [showTitle]="true"
    title="eSign"
    [closeOnOutsideClick]="true"
    [disabled]="esignIsBusy"
    [(visible)]="esignSharesPopupVisible">
    <div *dxTemplate="let data of 'content'">
      <div class="whole-container">
        <dx-scroll-view class="scroller">
          <div class="popup-content">
            <div *ngFor="let share of esignShares;let indexOfElement = index;">
              <mat-card class="card">
                <mat-card-title>
                  Signer #{{indexOfElement + 1}}
                </mat-card-title>
                <mat-card-content class="card-content">
                  <div class="row">
                    <div class="col-md-4 col-sm-12 mtop-mobile-8">
                      <dx-text-box
                        [(value)]="share.Name"
                        placeholder="Signer Name">
                      </dx-text-box>
                    </div>
                    <div class="col-md-4 col-sm-12 mtop-mobile-8">
                      <dx-select-box
                        [(value)]="share.EmailOrMobile"
                        [dataSource]='share.contactInfo'
                        [acceptCustomValue]="true"
                        [searchEnabled]="true"
                        [showClearButton]="false"
                        placeholder="Email Or Mobile"
                      ></dx-select-box>
                    </div>
                    <div class="col-md-4 col-sm-12 mtop-mobile-8">
                      <dx-select-box
                        [dataSource]="roles"
                        valueExpr='name'
                        displayExpr='name'
                        [(value)]="share.Role"
                        [acceptCustomValue]="false"
                        [searchEnabled]="false"
                        [showClearButton]="false"
                        placeholder="Signer Role">
                      </dx-select-box>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 col-md-offset-8 col-xs-12 mtop8">
                      <button
                        mat-stroked-button
                        color="error"
                        [disabled]="esignIsBusy"
                        (click)="esignDeleteShare(indexOfElement)"
                      >
                        Remove Signer
                      </button>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="row">
              <button class="col-md-4 col-md-offset-4 col-xs-12"
                      mat-stroked-button
                      color="primary"
                      [disabled]="esignIsBusy"
                      (click)="esignAddShare()">
                <i class="fal fa-plus"></i>
                Add Another Signer
              </button>
            </div>
          </div>
        </dx-scroll-view>
        <div class="fixed-bottom">
          <app-buttonbar
            [saveAndCloseText]="'Send for Signature'"
            [allButtonsDisabled]='esignShares.length === 0'
            [saveMethod]="attachDocAndSend"
          ></app-buttonbar>
        </div>
      </div>
      <div class="spinner-container" *ngIf="isSendingEsign">
        <mat-spinner class="center-spinner"></mat-spinner>
      </div>
    </div>
  </dx-popup>
</div>

