import { Directive, HostListener } from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';

@Directive({
  selector: '[appSelectFocus]',
})

export class SelectfocusDirective {

  selectBox: any;
  constructor(selectBox: DxSelectBoxComponent) {
    this.selectBox = selectBox;
  }

  @HostListener('change', ['$event']) onChange() {
    this.handleFocusOut();
  }

  handleFocusOut() {
    const selectBoxInstance = this.selectBox.instance;
    const isLookup = selectBoxInstance.element().getAttribute('isLookup');
    const acceptCustomValue = selectBoxInstance.option('acceptCustomValue');
    let textEntered = '';
    if (isLookup) {
      textEntered = selectBoxInstance.option('text');
    } else {
      textEntered = selectBoxInstance.option('selectedItem');
    }

    if (!textEntered || typeof textEntered !== 'string') {
      return;
    }

    const lowerCaseText = textEntered.toLowerCase();
    const displayExpr = selectBoxInstance.option('displayExpr');
    const valueExpr = selectBoxInstance.option('valueExpr');
    const dataSource = selectBoxInstance.option('dataSource');

    const matchingItems = dataSource.filter(item => {
      const lowerCaseValue = item[displayExpr].toLowerCase();
      return acceptCustomValue && isLookup
        ? lowerCaseValue === lowerCaseText
        : lowerCaseValue.includes(lowerCaseText);
    });

    if (matchingItems.length === 1) {
      selectBoxInstance.option('value', matchingItems[0][valueExpr]);
    } else if (matchingItems.length > 1) {
      let found = false;
      // If we have multiple matches and one is an exact match pick that one
      for (const item of matchingItems) {
        if (item[displayExpr].toLowerCase() === lowerCaseText) {
          selectBoxInstance.option('value', item[valueExpr]);
          found = true;
          break;
        }
      }
      // If we didn't find an exact match, select the first one
      if (!found) {
        selectBoxInstance.option('value', matchingItems[0][valueExpr]);
      }
    } else {
      // This stops it from setting undefined which causes an issue in some cases
      selectBoxInstance.option('value', null);
    }
  }
}
