<h1 mat-dialog-title>Form Groups</h1>
<mat-dialog-content style="height: calc(100% - 96px);max-height: 100vh;">
  <app-form-groups
    [inDialog]='true'
  ></app-form-groups>
</mat-dialog-content>
<mat-dialog-actions align='end'>
  <button mat-stroked-button color='primary' [mat-dialog-close]='currentSelectedId'>CANCEL</button>
  <button mat-raised-button color='primary' [mat-dialog-close]='currentSelectedId'>SAVE</button>
</mat-dialog-actions>
