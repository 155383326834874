import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-sign-up',
  templateUrl: './credit-sign-up.component.html',
  styleUrls: ['./credit-sign-up.component.scss']
})
export class CreditSignUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
