<app-dynamic-popup
  [popupComponent]="dynamicPopupClass"
  [popupComponentData]="dynamicPopupData"
  [popupComponentTitle]="dynamicPopupTitle"
  [showCloseButton]="true"
  [popupComponentVisible]="dynamicPopupVisible"
  [popupShowTitle]="dynamicPopupShowTitle"
  [addPopupClass]="false"
  (onPopupHidden)="dynamicPopupHidden()">
</app-dynamic-popup>
<div fxLayout='row' fxLayoutAlign='center center'>
  <mat-spinner
    *ngIf='!rapSheetLoaded'
    strokeWidth='5'
  ></mat-spinner>
</div>
<span #rapSheetContainer style='visibility: hidden'>
<h2 mat-dialog-title>Defendant Profile Detail</h2>
<mat-dialog-content>
  <table style='font-size:12px;'>
    <tr style='border:none;'>
      <td style='border:none; margin-bottom:5px;'>Agent Name: {{ this.data.defendant.transactions[0]?.agent?.person?.FullName }}</td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; margin-bottom:5px;'>Date: {{ currentDate | date: 'MM/dd/yyyy' }}</td>
    </tr>
  </table>
  <div style='font-weight:bold; text-decoration:underline;'>Defendant Information</div>
  <table style='font-size:12px;'>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right; width:15%;'>Name</td>
      <td style='border:none; width:20%;'>{{ data.defendant.person.FullName }}</td>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right; width:15%;'>Race</td>
      <td style='border:none; width:20%;'>{{ data.defendant.person.Race }}</td>
      <td style='border:none; width:30%;' rowspan='10'>
        <img *ngIf='defendantHasProfilePic' src='{{ profilePictureURL }}' crossorigin='anonymous' alt='' style='height:200px; width:150px; object-fit:cover;' />
      </td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Alias</td>
      <td style='border:none;'>{{ data.defendant.person.Alias }}</td>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Gender</td>
      <td style='border:none;'>{{ data.defendant.person.Gender }}</td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Address</td>
      <td style='border:none;'>
        {{ (data.defendant.person.Id | defaultAddress: data.defendant.person.addresses).AddressLine1 }}
        {{ (data.defendant.person.Id | defaultAddress: data.defendant.person.addresses).City }}
        {{ (data.defendant.person.Id | defaultAddress: data.defendant.person.addresses).StateCode }}
        {{ (data.defendant.person.Id | defaultAddress: data.defendant.person.addresses).Zip }}
      </td>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Height</td>
      <td style='border:none;'>{{ data.defendant.person.Height }}</td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-size:12px; font-weight:bold; margin-right:5px; text-align:right;'>Home Phone</td>
      <td style='border:none;'>{{ data.defendant.person | defaultPhone | phone }}</td>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Weight</td>
      <td style='border:none;'>{{ data.defendant.person.Weight }}</td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Other Phone</td>
      <td style='border:none;'>{{ this.defendantOtherPhone | phone }}</td>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Eye Color</td>
      <td style='border:none;'>{{ data.defendant.person.EyeColor }}</td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Employer</td>
      <td style='border:none;'>{{ this.defendantEmployerName }}</td>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Hair Color</td>
      <td style='border:none;'>{{ data.defendant.person.HairColor }}</td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Work Phone</td>
      <td style='border:none;'>{{ data.defendant.person | defaultPhone: 'W' | phone }}</td>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Identifying Marks</td>
      <td style='border:none;'>{{ data.defendant.person.IdentifyingMarks }}</td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>SSN</td>
      <td style='border:none;'>{{ data.defendant.person.Ssn }}</td>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>DOB</td>
      <td style='border:none;'>{{ data.defendant.person.Dob | date: 'MM/dd/yyyy' }}</td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Drivers Lic.</td>
      <td style='border:none;'>{{ data.defendant.person.Dl }}</td>
      <td style='border:none;'></td>
      <td style='border:none;'></td>
    </tr>
    <tr style='border:none;'>
      <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>DL State</td>
      <td style='border:none;'>{{ data.defendant.person.DlStateCode }}</td>
      <td style='border:none;'></td>
      <td style='border:none;'></td>
    </tr>
  </table>
  <hr />
  <div *ngFor="let t of this.data.defendant.transactions; let i = index">
    <div style='font-weight:bold; text-decoration: underline;'>Arrest History: {{ i + 1 }}</div>
    <table style='font-size:12px;'>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Agency</td>
        <td style='border:none;'>{{ t.AgencyName }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Agent</td>
        <td style='border:none;'>{{ t.agent.person.FullName }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Posted Date</td>
        <td style='border:none;'>{{ t.PostedDate | date: 'MM/dd/yyyy' }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Call Date</td>
        <td style='border:none;'>{{ t.CallDate | date: 'MM/dd/yyyy' }}</td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Status</td>
        <td style='border:none;'>{{ t.Status }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Arrest Date</td>
        <td style='border:none;'>{{ t.ArrestDate | date: 'MM/dd/yyyy' }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Money Available</td>
        <td style='border:none;'>{{ t.MoneyAvailable }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Identifier</td>
        <td style='border:none;'>{{ t.Identifier }}</td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Source</td>
        <td style='border:none;'>{{ t.SourceName }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Facility</td>
        <td style='border:none;'>{{ t.JailName }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Booking Identifier</td>
        <td style='border:none;'>{{ t.BookingIdentifier }}</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Notes</td>
        <td style='border:none;' colspan='7'>
          <span *ngFor='let n of t.notes'>
              {{ n.NoteStamp | date: 'MM/dd/yyyy' }} - {{ n.Subject + ' - ' + n.Text }}
          </span>
        </td>
      </tr>
    </table>
    <hr />
    <div style='font-weight:bold; text-decoration:underline;'>Bond Data</div>
    <table style='font-size:12px;' *ngFor='let b of t.bonds'>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Bond Status</td>
        <td style='border:none;'>{{ b.Status }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Jurisdiction</td>
        <td style='border:none;'>{{ b.StateCode }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Disposition</td>
        <td style='border:none;'>{{ b.DispositionName }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Exonerated</td>
        <td style='border:none;'>{{ b.DischargeOccurrenceDate | date: 'MM/dd/yyyy' }}</td>
      </tr>
      <tr>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Power</td>
        <td style='border:none;'>{{ b.PowerNumber }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>City</td>
        <td style='border:none;'>{{ b.City }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Attorney</td>
        <td style='border:none;'>{{ b.AttorneyName }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Forfeited</td>
        <td style='border:none;'>{{ b.ForfeitureDate | date:'MM/dd/yyyy' }}</td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Amount</td>
        <td style='border:none;'>{{ b.Amount | currency:'USD' }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Court</td>
        <td style='border:none;'>{{ b.CourtName }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Premium Rate</td>
        <td style='border:none;'>{{ b.RateName }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'></td>
        <td style='border:none;'></td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Charge</td>
        <td style='border:none;'>{{ b.Charge }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Court Room</td>
        <td style='border:none;'>{{ b.CourtRoom }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Rewrite</td>
        <td style='border:none;'>{{ b.RewrittenPowerNumber }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'></td>
        <td style='border:none;'></td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Case #</td>
        <td style='border:none;'>{{ b.CaseNumber }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Setting</td>
        <td style='border:none;'>{{ b.SettingDateTime | date:'short' }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Set For</td>
        <td style='border:none;'>{{ b.SetFor }}</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
    </table>
    <hr />
    <div style='font-weight: bold; text-decoration: underline;'>Contact Information</div>
    <table style='font-size:12px;' *ngFor='let c of t.contacts'>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Last Name</td>
        <td style='border:none;'>{{ c.person.Last }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>First Name</td>
        <td style='border:none;'>{{ c.person.First }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Middle Name</td>
        <td style='border:none;'>{{ c.person.Middle }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Type</td>
        <td style='border:none;'>{{ c.Type }}</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Address</td>
        <td style='border:none;'>{{ (c.person.Id | defaultAddress: c.person.addresses).AddressLine1 }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>City</td>
        <td style='border:none;'>{{ (c.person.Id | defaultAddress: c.person.addresses).City }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>State</td>
        <td style='border:none;'>{{ (c.person.Id | defaultAddress: c.person.addresses).StateCode }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Zip</td>
        <td style='border:none;'>{{ (c.person.Id | defaultAddress: c.person.addresses).Zip }}</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>DOB</td>
        <td style='border:none;'>{{ c.person.Dob | date: 'MM/dd/yyyy' }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>SSN</td>
        <td style='border:none;'>{{ c.person.Ssn }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>DL #</td>
        <td style='border:none;'>{{ c.person.Dl }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>DL State</td>
        <td style='border:none;'>{{ c.person.DlStateCode }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Relation</td>
        <td style='border:none;'>{{ c.Relation }}</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
      <tr>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Home Phone</td>
        <td style='border:none;'>{{ c.person | defaultPhone | phone }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Work Phone</td>
        <td style='border:none;'>{{ c.person | defaultPhone: 'W'  | phone }}</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
      <span *ngIf='c.person.employers.length > 0; else noEmp'>
        <span style='border:none;' *ngFor='let emp of c.person.employers'>
          <tr>
            <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Employer</td>
            <td style='border:none;'><span *ngIf='emp.IsDefault'>{{ emp.Name }}</span></td>
            <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp Phone</td>
            <td style='border:none;'><span *ngIf='emp.IsDefault'>{{ emp | defaultPhone | phone }}</span></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
          </tr>
          <tr>
            <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp Address</td>
            <td style='border:none;'><span *ngIf='emp.IsDefault'>{{ (emp.Id | defaultAddress: emp.addresses).AddressLine1 }}</span></td>
            <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp City</td>
            <td style='border:none;'><span *ngIf='emp.IsDefault'>{{ (emp.Id | defaultAddress: emp.addresses).City }}</span></td>
            <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp State</td>
            <td style='border:none;'><span *ngIf='emp.IsDefault'>{{ (emp.Id | defaultAddress: emp.addresses).StateCode }}</span></td>
            <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp Zip</td>
            <td style='border:none;'><span *ngIf='emp.IsDefault'>{{ (emp.Id | defaultAddress: emp.addresses).Zip }}</span></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
            <td style='border:none;'></td>
          </tr>
        </span>
      </span>
      <ng-template #noEmp>
        <tr style='border:none;'>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Employer</td>
          <td style='border:none;'></td>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp Phone</td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
        </tr>
        <tr style='border:none;'>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp Address</td>
          <td style='border:none;'></td>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp City</td>
          <td style='border:none;'></td>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp State</td>
          <td style='border:none;'></td>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Emp Zip</td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
        </tr>
      </ng-template>
    </table>
    <hr />
    <div style='font-weight: bold; text-decoration: underline;'>Collateral Information</div>
    <table style='font-size:12px;' *ngFor='let co of t.collaterals'>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Type</td>
        <td style='border:none;'>{{ co.Type }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Status</td>
        <td style='border:none;'>{{ co.Status }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Description</td>
        <td style='border:none;'>{{ co.Description }}</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Make</td>
        <td style='border:none;'>{{ co.Make }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Value</td>
        <td style='border:none;'>{{ co.Value | currency: 'USD' }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Model</td>
        <td style='border:none;'>{{ co.Model }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Year</td>
        <td style='border:none;'>{{ co.Year }}</td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Color</td>
        <td style='border:none;'>{{ co.Color }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>VIN</td>
        <td style='border:none;'>{{ co.Vin }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Plate</td>
        <td style='border:none;'>{{ co.Plate }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Decal</td>
        <td style='border:none;'>{{ co.Decal }}</td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Location</td>
        <td style='border:none;'>{{ co.Location }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Legal</td>
        <td style='border:none;'>{{ co.Legal }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Parcel</td>
        <td style='border:none;'>{{ co.Parcel }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Release</td>
        <td style='border:none;'>{{ co.Release }}</td>
      </tr>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Recorded</td>
        <td style='border:none;'>{{ co.Recorded | date: 'MM/dd/yyyy' }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Returned</td>
        <td style='border:none;'>{{ co.Returned | date: 'MM/dd/yyyy' }}</td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Comment</td>
        <td style='border:none;'>{{ co.Comment }}</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
      <span *ngIf='co.addresses.length > 0; else noCollateralAddress'>
        <tr style='border:none;' *ngFor='let coAddr of co.addresses'>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Address</td>
          <td style='border:none;'>{{ coAddr.AddressLine1 }}</td>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>State</td>
          <td style='border:none;'>{{ coAddr.StateCode }}</td>
          <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>County</td>
          <td style='border:none;'>{{ coAddr.County }}</td>
          <td style='border:none;'></td>
          <td style='border:none;'></td>
        </tr>
      </span>
      <ng-template #noCollateralAddress>
      <tr style='border:none;'>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>Address</td>
        <td style='border:none;'></td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>State</td>
        <td style='border:none;'></td>
        <td style='border:none; font-weight:bold; margin-right:5px; text-align:right;'>County</td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
      </tr>
      </ng-template>
    </table>
  </div>
</mat-dialog-content>
</span>
