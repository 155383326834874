import {BaseModel} from './BaseModel';

export class ImportBond extends BaseModel {
  public DefendantFirstName: string;
  public DefendantLastName: string;
  public DefendantDob: string;
  public DefendantSsn: string;
  public DefendantAddress: string;
  public DefendantCity: string;
  public DefendantState: string;
  public DefendantZip: string;
  public DefendantMobileNumber: string;
  public DefendantEmail: string;
  public Agency: string;
  public Agent: string;
  public Jail: string;
  public PostedDate: string;
  public BondAmount: string;
  public CaseNumber: string;
  public BondCharge: string;
  public ForfeitureDate: string;
  public ExonerationDate: string;
  public BondStateCode: string;
  public BondCounty: string;
  public PowerNumber: string;
  public Surety: string;
  public CourtName: string;
  public CourtDate: string;
  public CourtTime: string;
  public Balance: string;
  public IndemnitorFirstName: string;
  public IndemnitorLastName: string;
  public IndemnitorDob: string;
  public IndemnitorSsn: string;
  public IndemnitorAddress: string;
  public IndemnitorCity: string;
  public IndemnitorState: string;
  public IndemnitorZip: string;
  public IndemnitorPhone: string;
  public IndemnitorEmail: string;
  public CashCollateralBalance: string;
  public Note: string;

  constructor(obj?: object) {
    super(obj);
  }

}
