import {BaseModel} from './BaseModel';

export class Preferences extends BaseModel {

  // tslint:disable:variable-name
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MobileNumber: string;
  ProfilePic: any;
  ProfilePicData: any;
  Enable2FA: boolean;

  constructor(obj?: object) {
    super(obj);
    if (obj == null) {

    }
  }
}
