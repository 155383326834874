import { BaseModel } from "./BaseModel";

export class FieldValues {
    public field: string;
    public values: Array<string>;
    //ids or other values that need to be unhashed at api
    public hashedValues: Array<string>;
}

export class ReportCompilationRequest extends BaseModel {

    constructor(obj?: object) {
        super(obj);

        if (obj == null) {
        }

    }

    public requestedReportIds: Array<any>;
    public filterExpression: any;

    //for app defined linkage of reports that are not defendant forms
    public fieldValues: Array<FieldValues>;

    //for ReportTypeSub1 = RetailDefendantTransaction (defendant forms)
    // will be resolved against the ReportFilterExpression
    public defendantIds: Array<any>;
    public transactionIds: Array<any>;
    public bondIds: Array<any>;
    public contactIds: Array<any>;
    public collateralIds: Array<any>;
    public pathName : string;

}
