import { BaseModel } from './BaseModel';
import { Document } from './document';

export interface PdfField {
    fieldName: string;
    fieldValue: any;
}

export class PdfBindRequest extends BaseModel {

    constructor(obj?: object) {
        super(obj);

        if (obj == null) {
        }

    }

    public pdfBase64: string;
    public pdfFields: Array<PdfField>;
    public document: Document;

}
