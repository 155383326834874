import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Defendant } from '../../../../models/defendant';
import { NotesService } from '../../../../services/notes.service';
import { Note } from '../../../../models/note';
import { GridService } from '../../../../services/grid.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { UnsubscribeOnDestroyAdapter } from '../../../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../../../common/notify/notify.service';
import { StringUtilities } from '../../../../shared/StringUtilities';

export interface NotesDialogData {
  defendant: Defendant;
}

@Component({
  selector: 'app-defendant-notes-dialog',
  templateUrl: './defendant-notes-dialog.component.html',
  styleUrls: ['./defendant-notes-dialog.component.scss'],
})
export class DefendantNotesDialogComponent extends UnsubscribeOnDestroyAdapter implements AfterViewInit {
  @ViewChild('noteGrid') notesGrid: DxDataGridComponent;

  note = new Note();
  isDisabled = true;
  notes: Note[] = [];
  popupTitle = 'Edit Note';

  constructor(
    public dialogRef: MatDialogRef<DefendantNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotesDialogData,
    private noteService: NotesService,
    private gridService: GridService,
    private notify: NotifyService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData() {
    this.notesGrid.instance.beginCustomLoading('loading');
    this.noteService.getNotesByDefendantId(this.data.defendant.Id).subscribe(notes => {
      this.notesGrid.instance.endCustomLoading();
      this.isDisabled = false;
      this.notes = notes;
    }, error => {
      this.isDisabled = false;
      this.notesGrid.instance.endCustomLoading();
    });
  }

  handleComplete(event: boolean) {
    this.dialogRef.close(this.notes);
  }

  handleSave() {

  }

  onToolbarPreparing({ toolbarOptions }) {
    const toolbarItems = toolbarOptions.items;

    toolbarItems.forEach((item) => {
        item.location = 'after';
        item.showText = 'always';
        if (item.name === 'addRowButton') {
          item.options.text = 'Add New Note';
          item.options.icon = 'fal fa-plus';
          item.options.type = 'default';
          item.location = 'before';
        }
      },
    );

  }

  onCellPrepared(e) {
    this.gridService.updateGridIcons(e);
  }

  handleInitNewRow() {
    this.popupTitle = 'Add Note';
  }

  handleEditingStart() {
    this.popupTitle = 'Edit Note';
  }

  handleRowInserted(event: any) {
    this.isDisabled = true;
    this.notesGrid.instance.beginCustomLoading('loading');
    const note = {
      Subject: event.data.Subject,
      SourceId: this.data.defendant.transactions[this.data.defendant.transactions.length - 1].Id,
      SourceType: 'DefendantTransactions',
      Text: event.data.Text,
      NoteStamp: StringUtilities.getCurrentDateTimeString(),
    };
    this.subs.sink = this.noteService.createTransactionNote(note).subscribe((resp) => {
      this.notify.toast('Note created successfully');
      this.loadData();
    }, error => {
      this.notify.toast('Error adding note!', 'error');
      this.notesGrid.instance.endCustomLoading();
      this.isDisabled = false;
    });
  }

  handleRowUpdated(event: any) {
    this.isDisabled = true;
    this.notesGrid.instance.beginCustomLoading('loading');
    this.subs.sink = this.noteService.updateTransactionNote(event.data, event.data.Id).subscribe(() => {
      this.notify.toast('Note updated successfully');
      this.isDisabled = false;
      this.notesGrid.instance.endCustomLoading();
    }, error => {
      this.notify.toast('Error updating note!', 'error');
      this.notesGrid.instance.endCustomLoading();
      this.isDisabled = false;
    });
  }

  handleRowRemoved(event: any) {
    this.isDisabled = true;
    this.notesGrid.instance.beginCustomLoading('loading');
    this.subs.sink = this.noteService.deleteNote(event.data.Id).subscribe(() => {
      this.notify.toast('Note removed successfully');
      this.isDisabled = false;
      this.notesGrid.instance.endCustomLoading();
    }, error => {
      this.notify.toast('Error removing note!', 'error');
      this.notesGrid.instance.endCustomLoading();
      this.isDisabled = false;
    });
  }
}
