import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {PermissionService} from '../services/permission.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private permService: PermissionService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.getToken()) {
      // next we need to validate the token claims and check if the user has access to this specific functionality
      return true;
    } else {
      // this.router.navigate(['login']);
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }
}
