<div mat-dialog-content style="position:relative;">
    <button mat-icon-button class="close-icon" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <div *ngIf="data.type === 'basic'">
        <h1 class="h4 header-style">Quick Intro to Adding Invoices</h1>
        <div style="text-align: center;">
           <p><b>Single Invoices</b> - manually input invoice data one at a time for Carol</p>
           <p><b>Multiple Invoices</b> - use the importer to bulk import many invoices at a time</p>
        </div>
    </div>
    <div *ngIf="data.type === 'custom'">
        <h1 class="h4 header-style">Custom Setup</h1>
        <div style="text-align: center;">
            You're going to exit set-up up wizard and work through three easy, custom set-up steps.
        </div>
    </div>
</div>
<mat-dialog-actions align="center">
    <button [disabled]="disabled" mat-raised-button  (click)="navigateToPage()">Continue</button>
</mat-dialog-actions>