import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Directive({
  selector: '[appMobileFocus]',
})
export class MobileFocusDirective {

  private _element: Element;
  private readonly isSmallScreen: boolean;

  constructor(
    private element: ElementRef,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.isSmallScreen = breakpointObserver.isMatched('(max-width: 600px)');
    this._element = element.nativeElement;
  }

  @HostBinding('style.scroll-margin-top') get scrollMargin() {
    return '72px';
  }

  @HostListener('focusin')
  onElementFocus() {
    if (this.isSmallScreen) {
      this._element.scrollIntoView(true);
    }
  }

}
