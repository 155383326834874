import { take } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AdiSettings } from '../../../models/adi-settings';
import { LookupService } from '../../../services/app-lookup.service';
import { DxSelectBoxComponent, DxTextAreaComponent, DxTextBoxComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { QuickLinksComponent } from '../../dashboard/panels/quick-links/quick-links.component';
import { OnceOffCampaign } from '../../../models/onceoffcampaign';
import { ReminderService } from '../../../services/reminder.service';
import { ObjectUtilities } from '../../../shared/ObjectUtilities';
import { EventService } from '../../../services/event.service';
import { ApiUtilities } from '../../../shared/ApiUtilities';
import { GridUtilities } from '../../../shared/GridUtilities';
import notify from 'devextreme/ui/notify';
import { AppConstants } from '../../../shared/AppConstants';
import { BillingService } from '../../../services/billing.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;

@Component({
  selector: 'app-adi-onceoff',
  templateUrl: './adi-onceoff.component.html',
  styleUrls: ['./adi-onceoff.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdiOnceoffComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() model: AdiSettings;
  @Input() appSource = '';

  @ViewChild('quickLinks') quickLinks: QuickLinksComponent;
  @ViewChild('ddlTemplateType') ddlTemplateType: DxSelectBoxComponent;
  @ViewChild('txMessage') txMessage: DxTextAreaComponent;
  @ViewChild('txNumberPreview') txNumberPreview: DxTextBoxComponent;
  @ViewChild('txMessagePreview') txMessagePreview: DxTextBoxComponent;
  @ViewChild('previewValGroup') previewValGroup: DxValidationGroupComponent;
  @ViewChild('dxFields') dxFields: DxSelectBoxComponent;
  @ViewChild('ddlAudience', { static: true }) ddlAudience: DxSelectBoxComponent;

  phoneMask = AppConstants.PHONE_MASK;
  adiSettings: AdiSettings;
  quickLinksExpanded = true;

  notificationTemplates: any;
  messageText = '';
  selectedMessageTemplate: any;
  selectedReminderType: any;
  selectedMessageType: any = {};
  selectedAudienceOption: any;
  selectedmessageTemplateOption: any;
  selectedMessageTypeOption: any;
  lastCurserPos: any;
  errorTitle = 'There were errors';
  errorData: any[];
  errorVisible = false;
  busySending = false;
  includeAllNumbers = false;
  includeIndemnitors = false;
  strMessageContent: string;

  quickLinkSize = '200px';

  reminderChannels: any;
  messageTypeOptions = [];
  messageAudienceOptions = [];
  notificationTypes = [];
  notificationTypesFrom = [];
  showTemplates = false;
  paramPopupVisible = false;

  paramCourtMinDays = 7;
  paramPaymentReminderMinDays = 7;
  paramCheckinMinDays = 7;
  paramPastDueMinDays = 7;
  paramPastDueMinBalance = 10.0;
  paramMissedCheckinMinDays = 7;
  txMessageTextlength = 0;
  popupTestMessageVisible = false;
  fieldsVisible = false;

  fields: any;
  fieldMasks: any;

  showSubscribeConfirmation = false;
  showBuyNumber = false;
  numberPurchased = false;

  customNumber: string;

  constructor(
    private lookupService: LookupService,
    private reminderService: ReminderService,
    private eventService: EventService,
    private billingService: BillingService,
  ) {
    super();
    this.selectedAudienceOption = '1';
  }

  ngOnInit() {
    if (this.appSource === '') {
      this.appSource = 'bail';
    }

    this.adiSettings = new AdiSettings();
    this.loadLookups();
  }

  toggleFields() {
    this.fieldsVisible = true;
  }

  startCampaign(e) {
    if (
      this.selectedMessageTypeOption === '3' &&
      (this.selectedAudienceOption === '1' ||
        this.selectedAudienceOption === '2')
    ) {
      this.paramPopupVisible = true;
    } else {
      this.sendReminders();
    }
  }

  sendReminders() {
    this.subs.sink = this.billingService
      .IsSubscribedToProduct(AppConstants.PRODUCT_NAME_TEXT_MESSAGING)
      .subscribe(isSubbed => {
        if (!isSubbed) {
          this.showSubscribeConfirmation = true;
        } else {
          const oCampaign = new OnceOffCampaign();
          oCampaign.AudienceOptionId = this.selectedAudienceOption;
          if (this.selectedMessageTemplate !== undefined) {
            oCampaign.TemplateId = this.selectedMessageTemplate.Id;
          } else {
            oCampaign.TemplateId = '';
          }
          oCampaign.MessageTemplateOptionId = this.selectedmessageTemplateOption;
          oCampaign.CallOutForId =
            this.selectedMessageType.Code === undefined
              ? ''
              : this.selectedMessageType.Code;
          oCampaign.ReminderTypeId = 'SMS';
          oCampaign.MessageText = isNullUndefinedOrEmpty(this.txMessage)
            ? ''
            : this.txMessage.value;
          oCampaign.IncludeAllNumbers = this.includeAllNumbers;
          oCampaign.IncludeIndemnitors = this.includeIndemnitors;
          oCampaign.MessageTypeId = this.selectedMessageTypeOption;
          oCampaign.OnceOffParams = {
            paramCourtMinDays: this.paramCourtMinDays,
            paramPaymentReminderMinDays: this.paramPaymentReminderMinDays,
            paramCheckinMinDays: this.paramCheckinMinDays,
            paramPastDueMinDays: this.paramPastDueMinDays,
            paramPastDueMinBalance: this.paramPastDueMinBalance,
            paramMissedCheckinMinDays: this.paramMissedCheckinMinDays,
          };
          oCampaign.ReminderSource = this.appSource;
          if (
            this.selectedAudienceOption === '3' ||
            this.selectedAudienceOption === '4' ||
            this.selectedAudienceOption === '7'
          ) {
            this.subs.sink = this.quickLinks
              .getSelectedRows().pipe(
                take(1))
              .subscribe(data => {
                oCampaign.DefendantIds = data.map(item => item.DefendantID);
                oCampaign.DefendantData = data;
                oCampaign.FilterIds = data.map(item => item.FilterId);
                // console.log(oCampaign);
              });
          }
          if (this.selectedAudienceOption === '6') {
            oCampaign.CustomNumber = this.customNumber;
          }
          this.subs.sink = this.reminderService
            .sendBulkReminders(oCampaign)
            .subscribe((resp: any) => {
              this.eventService.showToasterWithDuration(
                'success',
                resp.Message,
                10000,
              );
              // console.log(resp);
            });
        }
      });
  }

  quickLinksResize(size: number | boolean) {
    if (size < 12) {
      this.quickLinksExpanded = true;
      this.quickLinkSize = '100%';
    } else {
      this.quickLinksExpanded = false;
      this.quickLinkSize = '200px';
    }
  }

  loadLookups() {
    if (this.appSource === 'bail') {
      this.messageTypeOptions = [
        { Id: '1', Text: 'Write my own', IsDefault: true },
        { Id: '3', Text: 'Use an existing standard message' },
      ];

      this.selectedMessageTypeOption = '1';
      this.messageAudienceOptions = [
        { Id: '1', Text: 'All Active Defendants', type: 'd' },
        {
          Id: '2',
          Text: 'All Active Defendants and their Indemnitors',
          type: 'di',
        },
        { Id: '3', Text: 'Specific Defendants', type: 'd' },
        {
          Id: '4',
          Text: 'Specific Defendants and their Indemnitors',
          type: 'di',
        },
        {
          Id: '7',
          Text: 'Past Due Defendants',
          type: 'di',
        },
        { Id: '5', Text: 'All Prospects', type: 'p' },
        { Id: '6', Text: 'Custom', type: 'c' },
      ];
    } else if (this.appSource === 'defmngr') {
      this.messageTypeOptions = [
        { Id: '1', Text: 'Write my own', IsDefault: true },
      ];

      this.selectedMessageTypeOption = '1';
      this.messageAudienceOptions = [
        { Id: '1', Text: 'All Active Defendants', type: 'd' },
        {
          Id: '2',
          Text: 'All Active Defendants and their Indemnitors',
          type: 'di',
        },
      ];
    }

    this.selectedmessageTemplateOption = '2';

    this.reminderChannels = this.lookupService.getLookupStatic(
      'reminderChannels',
    );

    this.subs.sink = this.lookupService
      .getFilteredLookup(
        'notificationtemplates',
        'MessageTypeSource',
        this.appSource,
        null,
        true,
      ).pipe(
        take(1))
      .subscribe(resp => {
        this.notificationTemplates = resp.data;
      });

      this.subs.sink = this.lookupService
      .getFilteredLookup(
        'notificationtypes',
        'NotificationTypeSource',
        this.appSource,
        'fields',
        true,
      ).pipe(
      take(1))
      .subscribe(resp => {
        this.notificationTypes = resp.data;
        this.notificationTypesFrom = resp.data.filter(x => x.Code !== 'Custom');
      });
  }

  onMessageTemplateChanged(e) {
    this.selectedMessageTemplate = this.notificationTemplates.find(
      item => item.Id === e.value,
    );
    this.selectedMessageType = this.notificationTypes.find(
      item => item.Id === this.selectedMessageTemplate.MessageTypeId,
    );
    if (this.txMessage) {
      this.txMessage.value = this.selectedMessageTemplate.Message;
      this.txMessageTextlength = this.txMessage.text.length;
    }

    if (this.selectedMessageType == null) {
      this.selectedAudienceOption = '1';
      this.setQuickLinksFilter('dashboardactivedefendants');
      return;
    }

    this.ddlAudience.instance.option('dataSource', this.messageAudienceOptions);

    switch (this.selectedMessageType.Code) {
      case 'Custom': {
        this.setQuickLinksFilter('dashboardactivedefendants');
        break;
      }
      case 'CourtDate': {
        this.selectedAudienceOption = '3';
        this.setQuickLinksFilter('dashboardcourtdates');
        break;
      }
      case 'PaymentReminder': {
        this.selectedAudienceOption = '3';
        this.setQuickLinksFilter('dashboardpaymentsdue');
        break;
      }
      case 'PastDue': {
        this.selectedAudienceOption = '3';
        this.setQuickLinksFilter('dashboardpaymentsdue');
        break;
      }
      case 'MissedCheckin': {
        this.selectedAudienceOption = '3';
        this.setQuickLinksFilter('dashboardcheckinsdue');
        break;
      }
      case 'StdCheckin': {
        this.selectedAudienceOption = '3';
        const tmpMessageAudienceOptions = this.messageAudienceOptions.filter(
          x => x.type === 'd',
        );
        this.ddlAudience.instance.option(
          'dataSource',
          tmpMessageAudienceOptions,
        );
        this.setQuickLinksFilter('dashboardcheckinsdue');
        break;
      }
      case 'GpsCheckin': {
        this.selectedAudienceOption = '3';
        const tmpMessageAudienceOptions = this.messageAudienceOptions.filter(
          x => x.type === 'd',
        );
        this.ddlAudience.instance.option(
          'dataSource',
          tmpMessageAudienceOptions,
        );
        this.setQuickLinksFilter('dashboardcheckinsdue');
        break;
      }
    }
  }

  setQuickLinksFilter(filter) {
    if (this.quickLinks === undefined) {
      setTimeout(() => {
        this.quickLinks.filterCategories(filter);
      }, 3000);
    } else {
      this.quickLinks.filterCategories(filter);
    }
  }

  onMessageTypeChanged(e) {
    if (e.value === '3') {
      this.showTemplates = false;
      this.selectedmessageTemplateOption = '2';
    } else {
      this.showTemplates = false;
      this.selectedmessageTemplateOption = '2';
    }
  }

  onReminderTypeChanged(e) {
    this.selectedReminderType = this.reminderChannels.find(
      item => item.Id === e.value,
    );
  }

  onCalloutForChanged(e) {
    this.selectedMessageType = this.notificationTypes.find(
      item => item.Code === e.value,
    );
    switch (this.selectedMessageType.Code) {
      case 'CourtDate': {
        this.quickLinks.filterCategories('dashboardcourtdates');
        break;
      }
      case 'PaymentReminder': {
        this.quickLinks.filterCategories('dashboardpaymentsdue');
        break;
      }
      case 'PastDue': {
        this.quickLinks.filterCategories('dashboardpaymentsdue');
        break;
      }
      case 'MissedCheckin': {
        this.quickLinks.filterCategories('dashboardcheckinsdue');
        break;
      }
      case 'StdCheckin': {
        this.quickLinks.filterCategories('dashboardcheckinsdue');
        break;
      }
      case 'All': {
        this.quickLinks.filterCategories('');
        break;
      }
    }
  }

  setReminderParams() {
    this.paramPopupVisible = false;
    this.sendReminders();
  }

  onTemplateKeyUp(e) {
    this.txMessageTextlength = this.txMessage.text.length;
  }

  sendTestMessage() {
    if (this.previewValGroup.instance.validate().isValid) {
      this.busySending = true;
      const tmpNumber = this.txNumberPreview.value;

      this.subs.sink = this.reminderService
        .sendTestSms(tmpNumber, this.txMessagePreview.text)
        .subscribe(
          resp => {
            this.showResult('success', 'Test message sent');
            this.busySending = false;

            this.popupTestMessageVisible = false;
          },
          smsErr => {
            if (smsErr.data != null || smsErr.message != null) {
              this.showResult('error', smsErr.data == null ? smsErr.message : smsErr.data.message);
            }
            this.showError(smsErr);
            this.busySending = false;
            this.popupTestMessageVisible = false;
          },
        );
    }
  }

  showSendTestMessage() {
    this.popupTestMessageVisible = true;
  }

  showResult(status: string, message: string) {
    const type = status === 'error' ? 'error' : 'success';
    const text = message;
    notify(text, type);
  }

  showError(errorInfo: any) {
    if (this.errorData == null) {
      this.errorData = [];
    } else {
      this.errorData.length = 0;
    }

    if (errorInfo == null) {
      this.errorData = [];
    } else {
      if (errorInfo.message != null) {
        this.errorData = this.errorData.concat(errorInfo);
      } else if (errorInfo.error == null) {
        this.errorData = this.errorData.concat(
          ApiUtilities.getErrorMessage(errorInfo, true),
        );
      } else {
        if (errorInfo.error.errors == null) {
          this.errorData = this.errorData.concat(
            ApiUtilities.getErrorMessage(errorInfo, true),
          );
        } else {
          this.errorData = this.errorData.concat(
            GridUtilities.getErrorItems(errorInfo.error.errors),
          );
        }
      }
    }

    this.errorVisible = true;
  }

  checkNumberValid(options: any) {
    if (options.value == null || options.value === '') {
      options.rule.message = 'Enter a number';
      return false;
    }
    return true;
  }

  onMessageTypeForChanged(e) {
    this.selectedMessageType = this.notificationTypes.find(
      item => item.Id === e.value,
    );
    this.fields = this.selectedMessageType.fields.data;
  }

  addFieldToTemplateMessage(e) {
    if (this.strMessageContent === undefined) {
      this.strMessageContent = this.messageText;
    }
    if (this.lastCurserPos === undefined) {
      this.lastCurserPos = this.strMessageContent.length;
    }
    const strNewMessageContent = [
      this.strMessageContent.slice(0, this.lastCurserPos),
      ' {' + e + '}',
      this.strMessageContent.slice(this.lastCurserPos),
    ].join('');
    this.messageText = strNewMessageContent;
  }

  onBlurMessageText(e) {
    this.strMessageContent = this.messageText;

    this.lastCurserPos = e.element.querySelector('textarea').selectionStart;
  }

  YesNoConfirmResult(e) {
    if (e) {
      if (this.showBuyNumber && this.numberPurchased) {
        // console.log('subscribing to text messaging');
        notify('Subscribing to Reminder Service, please wait...');
        this.subs.sink = this.billingService
          .SubscribeCustomerToService(AppConstants.PRODUCT_NAME_TEXT_MESSAGING)
          .subscribe(
            () => {
              this.subs.sink = this.billingService
                .IsSubscribedToProduct(
                  AppConstants.PRODUCT_NAME_TEXT_MESSAGING,
                  true,
                )
                .subscribe(
                  isSubbed => {
                    if (isSubbed) {
                      notify(
                        'You are now subscribed to Reminder Services!  Saving...',
                        'success',
                      );
                      this.showSubscribeConfirmation = false;
                      this.sendReminders();
                    } else {
                      notify(
                        'An error occurred while subscribing, please try again.',
                      );
                    }
                  },
                  error => {
                    notify(
                      'An error occurred while subscribing, please try again.',
                    );
                  });
            },
            error => {
              notify('An error occurred while subscribing, please try again.');
            },
          );
      } else if (!this.showBuyNumber) {
        this.showBuyNumber = true;
      }
    } else {
      this.showSubscribeConfirmation = false;
    }
  }

  numberBought() {
    this.numberPurchased = true;
    this.YesNoConfirmResult(true);
  }

  subscribePopupHidden() {
    this.showBuyNumber = false;
  }
}
