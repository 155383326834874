import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ReportService } from '../../services/report.service';
import { FormgroupsService } from '../../services/formgroups.service';
import { EventService } from '../../services/event.service';
import { GridService } from '../../services/grid.service';
import { NotifyService } from '../../common/notify/notify.service';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-form-groups',
  templateUrl: './form-groups.component.html',
  styleUrls: ['./form-groups.component.css'],
})
export class FormGroupsComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ViewChild('reportsGrid', { static: true }) formsGrid: DxDataGridComponent;
  @ViewChild('formGroupsGrid', { static: true }) formGroupsGrid: DxDataGridComponent;

  @Input() tabGroup: MatTabGroup;
  @Input() inDialog = false;

  formGroupsDataSource: any = [];
  formGroupFormsDataSource: any = [];
  selectedReports: any = [];
  slectedFormGroup: any = {};
  loadIndicatorVisible = false;
  busyMessage = 'Loading forms...';
  editMode: string;
  editTitle = 'Form Group Information';
  reloadFormsOfFormGroup = true;
  popupWindow: any;
  reportsToExclude = ['Rap Sheet'];
  formGroupClick = false;


  constructor(
    private reportService: ReportService,
    private formGroupService: FormgroupsService,
    private eventService: EventService,
    private gridService: GridService,
    private notifyService: NotifyService,
  ) {
    super();
    this.editMode = 'popup';
    this.popupWindow = window;
  }

  // Pre select reports on form group change
  selectForms() {
    this.loadIndicatorVisible = true;
    this.formsGrid.instance.deselectAll();
    let selectedForms = [];
    selectedForms = this.selectedReports.map(formData => {
      return formData.ReportId;
    });
    this.formsGrid.instance.selectRows(selectedForms, true);
    this.loadIndicatorVisible = false;
  }

  // Load all forms from database
  loadReports() {
    this.subs.sink = this.reportService.getReports(true).subscribe((response: any) => {
      this.formGroupFormsDataSource = response.data.filter(
        report => report.ReportTypeSub1 === 'RetailDefendantTransaction' && !this.reportsToExclude.includes(report.ReportCategory),
      );
    });
  }

  // Load all existing Form Groups
  loadFormGroups(selectFormGroupId = null) {
    this.subs.sink = this.formGroupService.getAllFormGroups().subscribe((response: any) => {
      // // console.log('All form groups:', response.data);
      this.formGroupsDataSource = response.data;
      this.formGroupsGrid.dataSource = [];
      this.formGroupsGrid.dataSource = response.data;
      if (selectFormGroupId) {
        this.selectFormGroup(selectFormGroupId);
      }
    });
  }

  ngOnInit() {
    this.loadFormGroups();
    this.loadReports();
  }

  // Handle on form group select event
  onFormGroupSelected(event: any) {
    // // console.log('Inside onFormGroupSelected function event: ', event);
    this.slectedFormGroup = event.selectedRowsData[0];
    if (this.slectedFormGroup && this.reloadFormsOfFormGroup) { // In case of delete rows gets selected
      this.subs.sink = this.formGroupService.getAllForms(event.selectedRowsData[0].Id).subscribe((response: any) => {
        // // console.log('get all forms response: ', response);
        if (response.data) {
          this.formGroupClick = true;
          this.selectedReports = response.data.reports.data;
          this.selectForms();
          this.formGroupClick = false;
        } else {
          // // console.log('No forms inside this form group!');
        }
      });
    }
    this.reloadFormsOfFormGroup = true;
  }

  // Handle the check/uncheck selection of forms
  onFormSelected(event: any) {
    // formGroupClick variable is used to differentiate that a form group was clicked or the checkbox of data grid with forms was directly clicked
    // This is required because on form group click also, this event gets triggered and below condition evaluates to true if only one form was saved
    // // console.log('In function onFormSelected, event', event, event.currentSelectedRowKeys, event.currentDeselectedRowKeys);
    if (event.currentSelectedRowKeys.length === 1 && !this.formGroupClick) { // Check event, == 1 to handle manual selection
      // // console.log('In check event, calling addFormToFormGroup!');
      this.subs.sink = this.formGroupService
        .addFormToFormGroup({
          ReportId: event.currentSelectedRowKeys[0],
          FormGroupId: this.slectedFormGroup.Id,
        }).subscribe(response => {
            this.notifyService.toast('Saved successfully!', 'success');
            this.eventService.emitfromGroupUpdated(true);

        });
    }
    if (event.currentDeselectedRowKeys.length === 1 && !this.formGroupClick) { // Uncheck event, == 1 to handle manual selection
      // // console.log(
      //   'In un-check event, calling removeFormFromFormGroup!',
      //   event,
      //   event.currentDeselectedRowKeys, event.currentDeselectedRowKeys[0]
      // );
      this.subs.sink = this.formGroupService
        .removeFormFromFormGroup({
          ReportId: event.currentDeselectedRowKeys[0],
          FormGroupId: this.slectedFormGroup.Id,
        }).subscribe(response => {
          // // console.log('Form removed response: ', response);
          this.notifyService.toast('Saved successfully!', 'success');
          this.eventService.emitfromGroupUpdated(true);
        });
    }
  }

  // Handle the newly added row auto select
  selectFormGroup(selectFormGroupId) {
    // // console.log('In function selectFormGroup: selectFormGroupId: ', selectFormGroupId);
    this.formGroupsGrid.instance.deselectAll();
    this.formGroupsGrid.instance.selectRows([selectFormGroupId], true);
    this.reloadFormsOfFormGroup = false;
  }

  // Handle new row add event
  onRowInserted(e) {
    // // console.log('In function onRowInserted: ', e);
    this.subs.sink = this.formGroupService
      .createFormGroup({
        Name: e.data.Name,
        Description: e.data.Description,
      }).subscribe((response: any) => {
        // // console.log('Form Group created: ', response);
        if (response.data) { // Emit form group update event for other components
          this.eventService.emitfromGroupUpdated(true);
          this.loadFormGroups(response.data.Id);
          this.reloadFormsOfFormGroup = false;
          // We emit another event passing the mat dialog the created id
          this.eventService.emitFormGroupIdCreated(response.data.Id);
        }
      });
  }

  // Handle form group grid row update
  onRowUpdated(e) {
    // // console.log('In function onRowUpdated: ', e);
    this.subs.sink = this.formGroupService.update(e.data, e.key).subscribe(response => {
      // // console.log('Form Group updated: ', response);
      this.eventService.emitfromGroupUpdated(true);
      this.loadFormGroups();
    });
  }

  // Handle row remove event
  onRowRemoving(e) {
    // // console.log('In function onRowRemoving: ', e.data);
    if (e.data.Id) {
      this.subs.sink = this.formGroupService.deleteFormGroup(e.data.Id).subscribe(response => {
        // // console.log('deleteFormGroup response: ', response);
        this.eventService.emitfromGroupUpdated(false);
        this.loadFormGroups();
      });
    } else {
      console.error('Problem in getting ID of selected row!');
    }
  }

  // Handle styling of edit and delete text and change it as icon
  onCellPrepared(e) {
    this.gridService.updateGridIcons(e);
  }

  // Handle delete grid action
  deleteGrid() {
    this.formsGrid.instance.getSelectedRowKeys().forEach(
      (key) => {
        this.formsGrid.instance.deleteRow(this.formsGrid.instance.getRowIndexByKey(key));
      });
  }

  // Handle add buttons, and actions etc while generating form group grid
  onToolbarPreparing({ toolbarOptions }) {
    const toolbarItems = toolbarOptions.items;

    toolbarItems.forEach(function(item) {
        item.location = 'after';
        item.showText = 'always';
        // // console.log(item);
        if (item.name === 'addRowButton') {
          item.options.text = 'Add Form Group';
          item.options.icon = 'fal fa-plus';
          item.location = 'before';
          item.options.type = 'default';
        }
      },
    );

    if (this.editMode === 'batch') {
      toolbarItems.unshift(
        {
          location: 'center',
          widget: 'dxButton',
          locateInMenu: 'auto',
          class: 'dx-datagrid-toolbar-button',
          options: {
            icon: 'fal fa-trash fa-1x',
            hint: 'Delete',
            onClick: () => {
              // this.DeleteGrid.bind(this);
              this.deleteGrid();
            },
          },
        },
      );
    }
  }
  openForms() {
      this.tabGroup.selectedIndex = 0;
  }
  addFormGroup(){
    this.formGroupsGrid.instance.addRow();
  }
}
