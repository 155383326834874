<button
  mat-raised-button
  color="primary"
  [matMenuTriggerFor]="menu">
  <i class="fas fa-user icon-spacer"></i>
  Quick Actions
</button>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let item of defendantMenu"
    (click)="selectedItem(item)">
    <i class="{{ item.icon }} icon-spacer text-primary"></i>
    <span>{{ item.text }}</span>
  </button>
</mat-menu>
