import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private closePopup = new Subject<string>();
  private maximizePopup = new Subject<boolean>();
  private changePopupComponent = new Subject<any>();

  closePopup$ = this.closePopup.asObservable();
  maximizePopup$ = this.maximizePopup.asObservable();
  changePopupComponent$ = this.changePopupComponent.asObservable();

  closeCurrentPopup(closeCurrentPopup: any) {
    this.closePopup.next(closeCurrentPopup);
  }

  toggleMaximizePopup(shouldMaximize: boolean) {
    this.maximizePopup.next(shouldMaximize);
  }

  sendChangePopupComponent(sendChangePopupComponent: any){
    this.changePopupComponent.next(sendChangePopupComponent);
  }

  constructor() { }
}
