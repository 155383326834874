import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { ApiUtilities } from '../shared/ApiUtilities';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CollectionPaymentService {

  private headers = new Headers(ApiUtilities.DEFAULT_HEADERS);

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  list(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/collectionpayments`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  create(data: any, isFriends = false): Observable<any> {
    if(!!isFriends) {
      data.IsFriends = true;
    } else {
      data.IsFriends = false;
    }

    return this.http.post(`${this.settings.getBaseUrl()}/collectionpayments`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  createInternal(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/internalcollectionpayments`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  update(id: any, data: any): Observable<any> {
    return this.http.patch(`${this.settings.getBaseUrl()}/collectionpayments/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  find(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/collectionpayments/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getByDebtor(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/collectionpaymentsbydebtor/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  processTestPayments(postData: any): Observable<any> {
    return this.http
      .post(
        `${this.settings.getBaseUrl()}/collectionpayments/processtest`, postData)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  updateManualUpdate(Id : string, ManualUpdate: boolean): Observable<any> {
    return this.http
      .put(
        `${this.settings.getBaseUrl()}/collectionpayments/updatemanualupdate/${Id}`, {ManualUpdate})
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }
}
