import {map, catchError} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from '../../../services/settings.service';
import { HttpClient } from '@angular/common/http';
import { EventService } from '../../../services/event.service';
import { CacheService } from '../../../services/cache.service';
import { CustomerUser } from '../../../models/customer-user';
import { PortoResponse } from '../../../models/porto-response';

@Injectable()
export class AdminService {

  private postData: any;
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private eventService: EventService,
    private cache: CacheService) {
  }

  getAllProductLabels() {
    let productLabels: any = [];

        productLabels['CREDITREPORTS'] = 'Credit Reports';
        productLabels['DATAMARTACCESS'] = 'Datamart Access';
        productLabels['DEFENDANTMANAGER'] = 'Defendant Manager';
        productLabels['DEFENDANTMANAGERPLUS'] = 'Defendant Manager Plus';
        productLabels['BAILMANAGEMENT'] = 'Bail Management';
        productLabels['DEFENDANTWATCH'] = 'Defendant Watch';
        productLabels['MVSNETWORKACCESS'] = 'MVS Network Access';
        productLabels['TEXTMESSAGING'] = 'Text Messaging';
        productLabels['WEBSITEBONDING'] = 'Website Bonding';
        productLabels['VEHICLEWATCHMONITORING'] = 'Vehicle Watch Monitoring';
        productLabels['COLLECTIONS'] = 'Collections';

    return productLabels;
  }

  getAllCustomers(): Observable<any> {
    const postData = {};

    return this.http.get(`${this.settings.getBaseUrl()}/customersforadmin`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  getAllCustomersProducts(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/admin/getallcustomersproducts`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  getSubscription(Id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/subscription/${Id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  loginAsCustomer(CustomerId: any = '', staffFlag = false) {
    const postData = {
      staffFlag,
      CustomerId,
    };

    return this.http.put(`${this.settings.getBaseUrl()}/admin/loginascustomer`, postData).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  getCustomerDetails(customerId: any ) {
    return this.http.get(`${this.settings.getBaseUrl()}/admin/customerdetails/${customerId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  getCustomerSubscribedProducts(customerId: any, chargifySubscriptionId: any = '', currentState: any = '') {
    let subscribedProductsURL = `${this.settings.getBaseUrl()}/admin/customer/subscribedproducts/${customerId}`;

    if (chargifySubscriptionId !== '' && currentState !== '') {
        subscribedProductsURL =
                `${this.settings.getBaseUrl()}/admin/customer/subscribedproducts/${customerId}/${chargifySubscriptionId}/${currentState}`;
    }

    return this.http
      .get(subscribedProductsURL).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')));
  }

  findUserByEmail(email: string): Observable<CustomerUser[]> {
    return this.http.get<PortoResponse<CustomerUser[]>>(`${this.settings.getBaseUrl()}/users/email/${email}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(error.error || 'Server Error')));
  }

}
