import {BaseModel} from './BaseModel';
import {PaymentScheduleDetail} from './paymentscheduledetail';
import {Note} from './note';

export class PaymentSchedule extends BaseModel {

  Id: any;
  TransactionId: any;
  StartDate: any;
  Amount: number;
  Frequency: string;
  CustomerId: any;
  TotalAmount: number;
  Recurring: boolean;

  details: PaymentScheduleDetail[];

  constructor(obj?: object) {
    super(obj);

    this.childrenNames = ['details'];

    if (obj == null || (Array.isArray(obj) && obj.length === 0)) {
      this.TransactionId = null;
      this.StartDate = new Date();
      this.Amount = 0.0;
      this.TotalAmount = 0.0;
      this.Frequency = 'Weekly';
      this.Recurring = false;
    }
    this.details = [];
  }

  public loadData(data) {
    const mainThis = this;
    this.fillFromObject(data, true);

    // console.log('payment schedule load from data', data);
    if (!!data.details
      && !!data.details.data
      && data.details.data.length > 0) {
      data.details.data.forEach(
        (detail) => {
          const newDetail = new PaymentScheduleDetail();
          newDetail.loadData(detail);
          mainThis.details.push(newDetail);
        });
    }
  }
}
