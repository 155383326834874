<div style="margin: 1rem 0;"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="center center"
  fxLayoutAlign.xs="center stretch"
  fxLayoutGap="15px"
  [class]="{'buttonbar-fixed': isFixedToBottom}"
  >
  <div *ngIf="useCancelButton">
    <dx-button
      fxFill
      [text]="cancelText"
      (onClick)="this.cancel()"
      stylingMode="outlined"
      type="normal">
    </dx-button>
  </div>
  <div *ngIf="saveAndGoVisible && false">
    <dx-button
      fxFill
      id="saveAndGoButton"
      [disabled]="saveButtonsDisabled || allButtonsDisabled"
      [text]="saveAndGoText"
      class="btn btn-primary dropdown-toggle"
      (onClick)="showMenu()">
      <div *dxTemplate="let contentData of 'content'">
        {{ saveAndGoText }}
        <span *ngIf="showSaveAndGoCaret" class="caret"></span>
      </div>
    </dx-button>
    <dx-context-menu
      fxFill
      [disabled]="allButtonsDisabled"
      [closeOnOutsideClick]="true"
      [items]="navItemsForContextMenu"
      (onItemClick)="saveAndGo($event)"
      [(visible)]="showContextMenu"
      [position]="{ at: 'left bottom' }"
      target="#saveAndGoButton">
    </dx-context-menu>
  </div>
  <div
    *ngIf="
      otherActions != null &&
      otherActions.length > 0 &&
      !showOtherActionsAsButtons
    ">
    <dx-button
      fxFill
      id="otherActionsButton"
      text="Other Actions"
      type="warning"
      class="btn dropdown-toggle"
      [disabled]="allButtonsDisabled"
      (onClick)="showOtherActionsMenu()">
      <div *dxTemplate="let contentData of 'content'">
        Other Actions
        <span class="caret"></span>
      </div>
    </dx-button>
    <dx-context-menu
      fxFill
      [disabled]="allButtonsDisabled"
      [closeOnOutsideClick]="true"
      [items]="navItemsForContextMenu"
      (onItemClick)="saveAndGo($event)"
      [(visible)]="showContextMenu"
      [position]="{ at: 'left bottom' }"
      target="#saveAndGoButton">
    </dx-context-menu>
    <dx-context-menu
      fxFill
      [disabled]="allButtonsDisabled"
      [closeOnOutsideClick]="true"
      [items]="otherActions"
      (onItemClick)="takeOtherAction($event)"
      [(visible)]="showOtherActionsContextMenu"
      [position]="{ at: 'left bottom' }"
      target="#otherActionsButton">
    </dx-context-menu>
  </div>

  <ng-container
    *ngIf="
      otherActions != null &&
      otherActions.length > 0 &&
      showOtherActionsAsButtons
    ">
    <div
      *ngFor="let item of otherActions">
      <dx-button
        fxFill
        [text]="item.text"
        [type]="item.type"
        [icon]="item.icon"
        [disabled]="(item.disabled != null ? item.disabled : false ) || allButtonsDisabled"
        [stylingMode]="(item.stylingMode != null ? item.stylingMode : 'outlined')"
        (onClick)="takeOtherAction(item)"
      ></dx-button>
    </div>
  </ng-container>

  <div>
    <dx-button
      fxFill
      id="saveAndClose"
      [disabled]="saveButtonsDisabled || allButtonsDisabled"
      type="default"
      (onClick)="saveAndGo('cancel')"
      [text]="saveAndCloseText"
    >
    </dx-button>
  </div>

  <ng-content></ng-content>

</div>
