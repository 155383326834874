import { NgModule, OnDestroy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopnavbarComponent } from './topnavbar.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { TextrepliesComponent } from '../textreplies/textreplies.component';
import { SearchResultsModule } from '../searchresults/search-results.module';
import { DynamicPopupModule } from '../dynamic-popup/dynamic-popup.module';


import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule, MediaMarshaller } from '@angular/flex-layout';
import { SharedModule } from '../../../shared/shared.module';
import { Observable, Subscription } from 'rxjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxAutocompleteModule } from 'devextreme-angular/ui/autocomplete';

@NgModule({
  declarations: [
    TopnavbarComponent,
    UserProfileComponent,
    TextrepliesComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatListModule,
    MatDividerModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule,
    SearchResultsModule,
    DynamicPopupModule,
    DxAutocompleteModule,
    SharedModule,
    InfiniteScrollModule,
    DxLoadIndicatorModule,
  ],

  exports: [TopnavbarComponent],
})
export class TopnavbarModule implements OnDestroy {

  private mediaSubscription: Subscription;

  public constructor(mediaMarshaller: MediaMarshaller) {
    // hack until resolve: https://github.com/angular/flex-layout/issues/1201
    // @ts-ignore
    this.mediaSubscription = mediaMarshaller.subject.subscribe((x) => {
      // @ts-ignore
      if (mediaMarshaller.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
        // @ts-ignore
        this.lastValue = [...mediaMarshaller.activatedBreakpoints];
      } else {
        // @ts-ignore
        mediaMarshaller.activatedBreakpoints = [...this.lastValue];
        // @ts-ignore
        mediaMarshaller.hook.collectActivations = () => {};
        // @ts-ignore
        mediaMarshaller.hook.deactivations = [...this.lastValue];
      }
    });
  }

  ngOnDestroy(): void {
    this.mediaSubscription.unsubscribe();
  }
}
