<dx-select-box
	#reportDropdown
	valueExpr="ReportId"
	displayExpr="ReportName"
	[visible]="showDropdown"
	[(value)]="selectedReportId"
	[dataSource]="reportTypes"
	(onValueChanged)="reportSelected($event)"
	[showClearButton]="false"
	[acceptCustomValue]="false"
	[dropDownOptions]="{
		minWidth: 300
	}"
	[elementAttr]="{
		class: 'report-picker'
	}"
  itemTemplate="item">
  <div *dxTemplate="let data of 'item'">
    <div class='dx-item-content dx-list-item-content' style='white-space: break-spaces;padding:0;'>
      {{data.ReportName}}
    </div>
  </div>
</dx-select-box>
