<app-pdf-viewer
  [pdfSrc]='data.pdfbase64 || data.document.File.Body'
  [isPopup]='true'
  (onClose)='handleOnClose($event)'
  [isDocumentViewer]='true'
  [allowEdits]='allowEdits'
  (pageRendered)='handlePageRendered($event)'
  [esignEnabled]='allowEsign'
  [allowEsign]='false'
  [publicInfo]='data.document'
  [defendant]='data.defendant'
  [contacts]='data.contacts'
  (onEsignSent)='onESignSent()'
>
</app-pdf-viewer>
