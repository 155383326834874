<dx-scroll-view>
  <div class="ibox-content">
    <div *ngIf="!resultReady" class="row">
      Please wait...
    </div>
    <div *ngIf="resultReady && errorMessage" class='row'>
      {{errorMessage}}
    </div>
    <div *ngIf="resultReady && !errorMessage" class="row">

      <div class="col-lg-2 col-md-3 col-sm-12">
        <label class="control-label">Name: </label>
        {{firstName}} {{lastName}}
      </div>
      <div class="col-lg-2 col-md-3 col-sm-12">
        <label class="control-label">DOB: </label>
        {{dobForDisplay}}
      </div>
      <div *ngIf="ssn" class="col-lg-2 col-md-3 col-sm-12">
        <label class="control-label">SSN: </label>
        {{ssn}}
      </div>
    </div>
    <div *ngIf="resultReady && !errorMessage" class="row">

      <div class="col-md-12 col-sm-12 col-lg-12">
        <dx-data-grid
          id="gridContainer"
          [dataSource]="historyData"
          width="100%"
          rowAlternationEnabled="true"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          [columnAutoWidth]="true"
          [noDataText]="'No history found'"
          [wordWrapEnabled]="true"
          [columnHidingEnabled]="true"
        >

          <dxo-scrolling mode="standard"></dxo-scrolling>
          <dxi-column caption="Type" [hidingPriority]="0" [visible]="true" dataField="Type"></dxi-column>
          <dxi-column caption="Booking Date" [hidingPriority]="1" [visible]="true" dataField="BookingDate"></dxi-column>
          <dxi-column caption="Release Date" [hidingPriority]="2" [visible]="true" dataField="ReleaseDate"></dxi-column>
          <dxi-column caption="Charges" [hidingPriority]="3" [visible]="true" dataField="Charges"></dxi-column>
          <dxi-column caption="Facility" [hidingPriority]="4" [visible]="true" dataField="Facility"></dxi-column>
          <dxi-column caption="City" [hidingPriority]="5" [visible]="true" dataField="City"></dxi-column>
          <dxi-column caption="State" [hidingPriority]="6" [visible]="true" dataField="State"></dxi-column>
          <dxi-column caption="Facility Phone" [hidingPriority]="7" [visible]="true" dataField="Phone"></dxi-column>
          <dxi-column caption="Behavior" [hidingPriority]="8" [visible]="true" dataField="Behavior"></dxi-column>
          <dxi-column caption="NCIC" [hidingPriority]="9" [visible]="true" dataField="NCIC"></dxi-column>
          <dxo-search-panel
            [visible]="true">
          </dxo-search-panel>
        </dx-data-grid>
      </div>
    </div>
  </div>
</dx-scroll-view>
