import { Pipe, PipeTransform } from '@angular/core';
import { ObjectUtilities } from './ObjectUtilities';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;

@Pipe({
  name: 'cameltosentence'
})
export class CameltosentencePipe implements PipeTransform {

  transform(text: any): any {
    if (isNullUndefinedOrEmpty(text)) {
      return '';
    }

    for (let i = 1; i < text.length; i++) {
      // tslint:disable-next-line: max-line-length
      if (text.charAt(i) === text.charAt(i).toUpperCase() && text.charAt(i) !== ' ' && text.charAt(i - 1) !== text.charAt(i - 1).toUpperCase()) {
        text = text.slice(0, i) + ' ' + text.slice(i);
        i++;
      }
    }
    return text;
  }

}
