import { BaseModel } from './BaseModel';
import { PaymentDetail } from './paymentdetail';

// tslint:disable:variable-name
export class Payment extends BaseModel {

  Id: any;
  Date: any;
  PaymentMethodId: any;
  ReceivedFromPersonId: any;
  ReferenceNumber: string;
  ReceiveAtAgencyId: string;
  ReceivedByUserId: string;
  ReceivedByString: string;
  Comment: string;
  ReceiptNumber: string;
  CreditCardTransactionHistoryId: any;
  PaymentType: any;
  CustomerId: string;
  PaymentClassId: string;
  TransactionId: string;

  details: PaymentDetail[];

  constructor(obj?: object) {
    // console.log('payment constructer', obj);
    super(obj);

    if (obj == null) {
      this.details = new Array<PaymentDetail>();
      this.CreditCardTransactionHistoryId = 0;
      this.ReceiptNumber = '';
      this.CreditCardTransactionHistoryId = null;
      this.PaymentType = 'Payment';
      // todo: should default received by user id. where to get?
    }

  }
}
