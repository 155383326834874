<dx-toast
  class="mat-elevation-z2"
  type="info"
  [width]="320"
  [height]="60"
  [displayTime]="1000000000"
  [closeOnOutsideClick]="false"
  [(visible)]="isToastVisible"
  [position]="{
    my: 'top right',
    at: 'top right',
    of: popupWindow,
    offset: '-10 75'
  }"
>
  <div *dxTemplate="let data of 'content'" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" style="position: relative">
    <strong style="color: whitesmoke">{{ titleLine }}</strong>
    <span>{{ titleSubLine }}</span>
    <span *ngIf="done; else nav_template" fxLayout="row" fxLayoutAlign="space-between start">
      <a *ngIf="backUrl" routerLink="{{ backUrl }}" style="color: whitesmoke">
        <i class="fal fa-chevron-left"></i>
      </a>
      <a (click)="homeAndClose()" style="color: whitesmoke;">
        {{ pageLink }}
      </a>
    </span>

    <ng-template #nav_template>
      <a *ngIf="backUrl" routerLink="{{ backUrl }}" style="color: whitesmoke">
        <i class="fal fa-chevron-left"></i>
      </a>
      <a routerLink="{{ pageUrl }}" style="color: whitesmoke;">
        {{ pageLink }} <i class="far fa-chevron-right" style="margin-left: 1rem;"></i>
      </a>
    </ng-template>
    <a style="position: absolute; top: 1rem; right:1rem; cursor: pointer; color: whitesmoke;" (click)="closeToasted()" class="fal fa-times"></a>
  </div>
</dx-toast>
