import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-yes-no-confirmation',
  templateUrl: './yes-no-confirmation.component.html',
  styleUrls: ['./yes-no-confirmation.component.scss'],
})
export class YesNoConfirmationComponent implements OnInit, OnChanges {
  @Input() showTitle = true;
  @Input() titleText = '';
  @Input() closeOnOutsideClick = false;
  _showConfirmation = false;
  @Input() maxWidth = '400px';
  @Input() maxHeight = '250px';
  @Input() showCloseButton = false;
  @Input() confirmationMessage = '';
  @Input() confirmationMessageImportant = '';
  @Output() onResult = new EventEmitter<boolean>();
  @Input() yesText = 'Yes';
  @Input() noText = 'No';
  @Input() yesWidth = 136;
  @Input() noWidth = 136;
  @Input() messageHasHtml = false;
  @Input() class = '';
  confirmationHtml = '';

  @Input()
  get showConfirmation() {
    return this._showConfirmation;
  }

  set showConfirmation(val) {
    this._showConfirmation = val;
    if (this._showConfirmation && this.messageHasHtml && this.confirmationMessage !== '') {
      // console.log('message', this.confirmationMessage, this.confirmationHtml);
      this.confirmationHtml = this.confirmationMessage;
//      this.confirmationMessage = '';
    }
    this.showConfirmationChange.emit(this._showConfirmation);
  }

  @Output() showConfirmationChange = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
    // console.log('yes no init');
  }

  ngOnChanges() {
    // console.log('yes no on changes', this.showConfirmation);
  }

  cancel() {
    this.onResult.emit(false);
    this.showConfirmation = false;
    // console.log(this.showConfirmation);
  }

  confirm() {
    this.onResult.emit(true);
    this.showConfirmation = false;
    // console.log(this.showConfirmation);
  }

}
