<mat-card>
  <mat-card-header>
    <mat-card-title>Users</mat-card-title>
    <mat-card-subtitle>Search Below to find Users</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form (ngSubmit)='applyFilter(email.value)'  >
      <fieldset class='search-form' fxLayout="column" fxLayoutAlign="space-around start">

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type='text' #email placeholder="Enter a user email">
      </mat-form-field>
      <button type='submit' mat-raised-button color='primary' [disabled]='loading'>Search</button>
      </fieldset>
    </form>
    <mat-spinner strokeWidth="7" color="primary" *ngIf='loading'  fxLayoutAlign="center center"></mat-spinner>
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Customer</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.Name}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.Email}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.Type}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="CustomerId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>CustomerId</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.CustomerId}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="V1Id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>V1Id</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.V1Id}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="loginAsCustomer(element.EncodedCustomerId)" [disabled]="loading"><i class='fa fa-sign-in'></i></button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-row *matNoDataRow>
        No Users found! Search above to find users.
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>
