import {switchMap, filter, take, finalize, catchError, map} from 'rxjs/operators';
import {throwError, Observable, BehaviorSubject, from} from 'rxjs';
import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import {AuthService} from './auth.service';
import {SettingsService} from './settings.service';
import {ActivatedRoute, Router} from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from '../common/UnsubscribeOnDestroy';
import {NotifyService} from '../common/notify/notify.service';

//  Read https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
@Injectable()
export class TokenInterceptor extends UnsubscribeOnDestroyAdapter implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  diagnosticLogging = true;

  constructor(public auth: AuthService,
              private settings: SettingsService,
              private route: ActivatedRoute,
              private notification: NotifyService,
              private router: Router) {
    super();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(this.addToken(req, this.auth.getToken())).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(req, next);
            case 403:
              return this.handle403Error(error, req, next);
            default:
              console.log('Base Service: intercept error', error);
              return throwError(error);
          }
        } else {
          console.log('Base Service: non http error response intercept error', error);
          return throwError(error);
        }
      }));
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    // Added conditional to prevent CORS issues - when no token is present, don't send withCredentials header
    if (this.auth.getToken()) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`,
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
        },
        withCredentials: true,
      });
    }
    // // console.log('Base Service: interceptor request', req);
    return req;
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (this.diagnosticLogging) {
       console.log('Base Service: handle401Error', req);
    }
    if (req.url.indexOf(this.settings.getRefreshUrl()) >= 0) {
      if (this.diagnosticLogging) {
         console.log('Base Service: this 401 request is from refresh, just logging out user (without refresh).', req.url);
      }
      // if we get here from a refresh request, need to log out the user
      // skip refresh to avoid infinite loop of refresh - user will probably just log back in as the same user again anyway..
      return this.logoutUser(true);
    }
    // below some exceptions where we dont want to process the token exception
    if (req.url.indexOf(this.settings.getLoginUrl()) >= 0 || this.router.url.indexOf('/password/reset') >= 0) {
      if (this.diagnosticLogging) {
         console.log('Base Service: this 401 request is from login, do nothing.', req.url);
      }
      // if we get here from a login request, just pass along
      return next.handle(req);
    }
    // if public collections, lets 1st try and get a new client token, as there wont be any refresh token
    if (AuthService.otherData.isPublicPage) {
      if (this.diagnosticLogging) {
         console.log('Base Service: this 401 request is from collections, try get new token.', req.url);
      }

      return this.auth.ClientLogin().pipe(
        switchMap((x) => {
        if (x) {
          return next.handle(this.addToken(req, ''));
        }
        }));
    } else {

      if (this.diagnosticLogging) {
        console.log('Base Service: this.isRefreshingToken', this.isRefreshingToken);
     }
     if (!this.isRefreshingToken) {
       if (this.diagnosticLogging) {
          console.log('Base Service: setting this.isRefreshingToken to true');
       }
       this.isRefreshingToken = true;

       // Reset here so that the following requests wait until the token
       // comes back from the refreshToken call.
       this.tokenSubject.next(null);

       return this.auth.RefreshToken().pipe(
         switchMap((newToken: string) => {
           if (this.diagnosticLogging) {
              console.log('Base Service: results from RefreshToken in baseService.handle401Error', newToken);
           }
           if (newToken) {
             this.isRefreshingToken = false;
             this.tokenSubject.next(newToken);
             return next.handle(this.addToken(req, newToken));
           }
           // If we don't get a new token, we are in trouble so logout.
           if (this.diagnosticLogging) {
              console.log('Base Service: did not receive token on RefreshToken - logging out');
           }
           // skip refresh to avoid infinite loop of refresh - user will probably just log back in as the same user again anyway..
           this.isRefreshingToken = false;
           return this.logoutUser(true);
         }),
         catchError((error) => {
           // If there is an exception calling 'refreshToken', bad news so logout.
           if (this.diagnosticLogging) {
              console.log('Base Service: error on RefreshToken - logging out', error);
           }
           // Only logout if this error actually came from the refresh request.
           if ((error.url && error.url.indexOf(this.settings.getRefreshUrl()) >= 0) || !error.url) {
             return this.logoutUser(true, true);
           }
         }),
         finalize(() => {
           this.isRefreshingToken = false;
         }));
     } else {
       if (this.diagnosticLogging) {
          console.log('Base Service: returning this.tokenSubject (filtered and mapped)', this.tokenSubject);
       }
       return this.tokenSubject.pipe(
         filter(token => token != null),
         take(1),
         switchMap(token => {
           return next.handle(this.addToken(req, token));
         }));
     }

    }

  }

  handle400Error(error) {

    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      // skipping app refresh on logout - the likelihood here is that he will be logging back in right away
      return this.logoutUser(false);
    }

    return throwError(error);
  }

  logoutUser(skipRefresh = false, checkForReturnUrl = false) {
    if (checkForReturnUrl) {
      let returnUrl = '';
      this.route.queryParams.pipe(
        take(1),
      ).subscribe(params => {
        returnUrl = params.returnUrl;
        this.auth.Logout(false, skipRefresh, returnUrl);
      });
    } else {
      this.auth.Logout(false, skipRefresh);
    }

    // Route to the login page (implementation up to you)
    return throwError('');
  }

  handle403Error(error, req, next) {
    const message = error.error.message.replace('Unauthorized::', '');
    this.notification.toast(`You need permission for that. ${message}. See your administrator.`,
        'error', 'OK', 0, 'saving-failed-snack', {vertical: 'top', horizontal: 'right'});
    return throwError('');
  }
}


