import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonbarComponent } from '../../../components/common/buttonbar/buttonbar.component';
import { LookupService } from '../../../services/app-lookup.service';
import { DefendantWatchService } from '../../../services/defendantwatch.service';
import { DefendantWatchSettings } from '../../../models/defendantwatchsettings';
import { environment } from '../../../../environments/environment';
import { DxDataGridComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { StringUtilities } from '../../../shared/StringUtilities';
import { BillingService } from '../../../services/billing.service';
import { AppConstants } from '../../../shared/AppConstants';
import { DefendantService, ExistingDefendant } from '../../../services/defendant.service';
import { ReportType } from '../../../services/datamart.service';
import { GridService } from '../../../services/grid.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../../common/notify/notify.service';
import { MatDialog } from '@angular/material/dialog';
import { DefendantExonerationComponent } from './defendant-exoneration/defendant-exoneration.component';
import { map } from 'rxjs/operators';
import { EventService } from '../../../services/event.service';
import { SystemConfigService } from '../../../services/systemconfig.service';
import { SystemConfig } from '../../../models/systemconfig';
import { MatSelectionListChange } from '@angular/material/list';
import AppEditorLengths from '../../../shared/AppEditorLengths';
import CustomStore from 'devextreme/data/custom_store';
import { PaginationService } from '../../../services/pagination.service';
import { HttpParams } from '@angular/common/http';
import { PortoResponse } from '../../../models/porto-response';
import * as moment from 'moment';
import { DefendantWatch } from '../../../models/defendant-watch';
import { Observable } from 'rxjs';
import { ConfirmationComponent } from '../../../components/controls/confirmation/confirmation.component';
import { AdminService } from '../../../services/admin.service';
import { QuickActionPreviewEvent } from '../../defendant/quick-actions-preview/quick-actions-preview.component';
import { AlertResult } from '../../../models/alert-result';


// tslint:disable:variable-name
class DefendantToAdd {
  public First: any;
  public Last: any;
  public Dob: any;
  public Ssn: any;
  public IncludeInDefendantWatch: any;
  public createCheckinSchedule: boolean;
  firstNameLength = AppEditorLengths.PersonFirstName;
  lastNameLength = AppEditorLengths.PersonLastName;

  constructor() {
    this.IncludeInDefendantWatch = true;
    this.createCheckinSchedule = false;
  }
}

@Component({
  selector: 'app-defendantwatch',
  templateUrl: './defendantwatch.component.html',
  styleUrls: ['./defendantwatch.component.scss'],
  providers: [DefendantWatchService, CustomerService],
})
export class DefendantwatchComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ViewChild('agenciesGrid') agenciesGrid: DxDataGridComponent;
  @ViewChild('alertsGrid') alertsGrid: DxDataGridComponent;
  @ViewChild('watchesGrid') watchesGrid: DxDataGridComponent;
  @ViewChild('navButtonBar') buttonBar: ButtonbarComponent;
  @ViewChild('valGroup') addWatchValidationGroup: DxValidationGroupComponent;
  @ViewChild('emailValidationGroup') emailValidationGroup: DxValidationGroupComponent;
  @Input() hideHeader = false;
  @Input() inDialog = false;
  @Output() onClose = new EventEmitter<boolean>();
  tabs: any;
  popupVisible: boolean;
  dataSource: any;
  watches: CustomStore;
  isAdmin = false;
  alerts: any;
  agencies: any;
  agents: [];
  settings: DefendantWatchSettings;
  agreementText: string;
  watchBoth = false;
  watchOnlyForf = false;
  saveToastVisible = false;
  detailsVisible = false;
  agreementVisible = false;
  currentDetail: any;
  userAgreedToTerms = false;
  termsLoaded = false;
  userInTrial = false;
  usingBailManagementSystem = true;
  defToAdd: DefendantToAdd;
  addNewVisible = false;
  tabAlertsVisible = true;
  tabWatchesVisible = true;
  dateFormatter = StringUtilities.dateFormatter;
  ReportType = ReportType;
  isSubscribedToBMS = false;
  selectedTabIndex = 0;
  importPopupVisible = false;
  guidedSetupStep: number | 0;
  hideToast = false;
  defendantSelected = false;
  selectedDefendantId: string;
  showChoicePopup = false;
  choiceData: ExistingDefendant[];
  params: HttpParams;
  defData: any = null;
  isEditing = false;
  editedDefendantId: string;
  watch: any;
  otherActions = [];

  constructor(
    private lookupService: LookupService,
    private defendantWatchService: DefendantWatchService,
    private router: Router,
    private customerService: CustomerService,
    private billingService: BillingService,
    private defendantService: DefendantService,
    private gridService: GridService,
    private notifyService: NotifyService,
    private eventService: EventService,
    private systemConfigService: SystemConfigService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private paginationService: PaginationService,
    private adminService: AdminService,
  ) {
    super();

    this.settings = new DefendantWatchSettings();
    this.popupVisible = false;
    this.onSave = this.onSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    if (this.inDialog) {
      this.otherActions = [
        {
          text: 'CLOSE',
          value: () => {
            this.onClose.emit(false);
          },
        },
      ];
    }
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM).subscribe(bms => {
      this.isSubscribedToBMS = bms;
        this.tabs = [
          {text: 'Alerts', icon: 'fal fa-bell', template: 'tabAlerts', visible: this.tabAlertsVisible},
          {text: 'Current Watches', icon: 'fal fa-user', template: 'tabWatches', visible: this.tabWatchesVisible},
          {text: 'Arrest History Search', icon: 'fal fa-history', template: 'tabSearch', visible: this.tabWatchesVisible},
          {text: 'Settings', icon: 'fal fa-cog', template: 'tabDefendant'},
        ];
    });
    this.isAdmin = this.adminService.isAdmin;
  }

  ngOnInit() {
    this.subs.sink = this.route.paramMap.subscribe(params => {
      const tmpTabAction = params.get('tab');
      if (tmpTabAction && tmpTabAction !== '') {
        if (tmpTabAction === 'addwatch') {
          this.addWatch();
          this.showGuidedSetup(2);
        } else {
          this.selectedTabIndex = parseInt(tmpTabAction, 10);
          this.showGuidedSetup(3);
        }
      }
    });

    this.subs.sink = this.eventService.quickActionEvent$.subscribe(item => {
      if (item.value === QuickActionPreviewEvent.ADDWATCH) {
        this.addWatch();
      }
    });

    this.subs.sink = this.billingService.GetCustomerBillingStatusString().subscribe(status => {
      if (status === 'TRIALING') {
        this.userInTrial = true;
      }
    });

    this.subs.sink = this.lookupService.getLookup('agencies').subscribe(resp => {
      this.agencies = resp.data;
    });

    this.lookupService.getLookupP('agents').then(resp => {
      this.agents = resp.data;
    }).catch(error => {
      console.error(error);
    });

    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM).subscribe(isSubscribed => {
      this.usingBailManagementSystem = isSubscribed;
    });
    this.eventService.componentBusy(true);
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_DEFENDANT_WATCH).subscribe(isSubscribed => {
      if (!isSubscribed) {
        this.subs.sink = this.billingService.GetCustomerBillingStatusString().subscribe(status => {
          if (status === 'TRIALING') {
            this.userInTrial = true;
            this.subs.sink = this.showConfirmation(
              'Enter Payment Information',
              `This is a premium service and it looks like we don't have a credit card on file for you. Enter payment information on the following screen and you'll be redirected back here.  Thanks!`,
            ).subscribe(confirm => {
              if (confirm) {
                this.eventService.componentBusy(false);
                this.router.navigate(['/getstarted/checkout'],
                  {
                    queryParams: {
                      'returnUrl': this.router.routerState.snapshot.url,
                      'productids': AppConstants.PRODUCT_NAME_DEFENDANT_WATCH,
                    },
                  });
              } else {
                if (!this.inDialog) {
                  this.eventService.componentBusy(false);
                  this.router.navigate(['/']);
                } else {
                  this.eventService.componentBusy(false);
                  this.onClose.emit(true);
                }
              }
            });
          } else {
            this.subs.sink = this.showConfirmation(
              'Enable Defendant Watch',
              `You are enabling Defendant Watch.
            There is a 5$ monthly charge, as well as a monthly charge for each defendant added.`,
            ).subscribe(confirm => {
              if (confirm) {
                this.subscribeToService();
              } else {
                this.eventService.componentBusy(false);
                if (!this.inDialog) {
                  this.router.navigate(['/']);
                } else {
                  this.onClose.emit(true);
                }
              }
            });
          }
        });
      } else {
        this.eventService.componentBusy(false);
      }
    });

    this.subs.sink = this.defendantWatchService.GetSettings().subscribe(settings => {
      this.settings = settings;
      if (!settings.ClientAgreementDate) {
        // must do initial agreement
        // TODO: how to be sure settings is not empty because of some error?
        this.subs.sink = this.customerService.getCurrent().subscribe(cust => {
          const companyName = cust.data[0].Name;
          const agreeInfo = `${localStorage.getItem('userName')} (${localStorage.getItem('userEmail')})`;
          // need to use this odd mechanism because string.replace() in javascript only replaces the first instance of match
          this.agreementText = environment.defendantWatchTerms.split('[[[TODAYSDATE]]]').join(new Date(Date.now()).toLocaleString());
          this.agreementText = this.agreementText.split('[[[CLIENTNAME]]]').join(companyName);
          this.agreementText = this.agreementText.split('[[[AGREEINFO]]]').join(agreeInfo);
          this.termsLoaded = true;
          if (this.settings.NotificationEmails == null ||  this.settings.NotificationEmails === '') {
            this.settings.NotificationEmails = localStorage.getItem('userEmail');
          }
          this.eventService.quickAction({ value: QuickActionPreviewEvent.HIDEWATCH, text: 'Hide Defendant Watch', icon: 'fal fa-user' });
          this.agreementVisible = true;
          sessionStorage.setItem('userAgreedToTerms', '0');
        });
      } else {
        this.userAgreedToTerms = true;
        sessionStorage.setItem('userAgreedToTerms', '1');
        if (!this.settings.Source) {
          this.eventService.quickAction({ value: QuickActionPreviewEvent.SHOWWATCH, text: 'Show Defendant Watch', icon: 'fal fa-user' });
        }
        this.loadsystemConfig();
      }

      if (this.settings.WatchForfeitures && !this.settings.WatchLiability) {
        this.watchOnlyForf = true;
      } else if (this.settings.WatchLiability) {
        this.watchBoth = true;
      }
      this.refreshTabVisibility();
    });

    this.subs.sink = this.defendantWatchService.GetAllAlerts().subscribe(alerts => {
      // Convert ResultStamp to browser timestamp
      alerts.forEach((alert) => {
        const timeZoneTime = new Date(alert.ResultStamp); // date object automatically gets the timezone
        timeZoneTime.setMinutes(timeZoneTime.getMinutes() - timeZoneTime.getTimezoneOffset());
        alert.ResultStamp = moment(timeZoneTime).format('YYYY-MM-DD hh:mm:ss A');
        if (alert.ResultXML) {
          alert.ResultXML = JSON.parse(<string>alert.ResultXML);
        }
      });

      this.alerts = alerts;
    });

    this.refreshWatches();

    this.subs.sink = this.eventService.guidedSetupEvent$.subscribe(item => {
      if (item.source === 'defwatch') {
        if (item.enabled) {
           this.hideToast = true;
            this.showGuidedSetup(this.guidedSetupStep);
        }
      }
    });
  }

  subscribeToService() {
    this.subs.sink = this.billingService.SubscribeCustomerToService(AppConstants.PRODUCT_NAME_DEFENDANT_WATCH)
      .subscribe(() => {
        this.notifyService.toast('You are now subscribed to Defendant Watch', 'success');
        this.eventService.componentBusy(false);
      }, (error) => {
        this.eventService.componentBusy(false);
        this.notifyService.toast('Error subscribing to Defendant Watch', 'error');
      });
  }

  showConfirmation(title: string, message: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        message: message,
        title: title,
      },
      maxWidth: '500px',
    });
    return dialogRef.afterClosed().pipe();
  }

  refreshTabVisibility() {
    setTimeout(() => {
      if (this.settings.WatchLiability || this.settings.WatchForfeitures) {
        this.tabWatchesVisible = true;
        this.tabAlertsVisible = true;
      } else {
        this.tabWatchesVisible = true;
        this.tabAlertsVisible = true;
      }
    }, 1000);
  }

  deleteWatch(data: DefendantWatch) {
    this.notifyService.toast('Removing...');
    if (data.IncludeInDefendantWatch) {
      // We need to manually remove v1 watches moved to v2
      if (data.SourceUniqueId === '5b49bf45-4a35-42ed-a8b4-c1f22b16321d') {
        this.subs.sink = this.defendantWatchService.removeV1Watch(data.WatchID).subscribe(() => {
          this.notifyService.toast('Watch removed!');
          this.refreshWatches();
        }, error => {
          this.notifyService.toast('Error removing watch', 'error');
        });
      } else {
        this.subs.sink = this.defendantWatchService.RemoveWatch(data.DefendantID).subscribe(() => {
          this.notifyService.toast('Watch removed.  May take some time to disappear.');
          this.refreshWatches();
        });
      }
    } else {
      const dialogRef = this.dialog.open(DefendantExonerationComponent, {
        width: '25%',
        panelClass: 'bond-status-dialog',
        data: {defendantId: data.DefendantID},
      });

      this.subs.sink = dialogRef.afterClosed().subscribe((event: boolean) => {
        if (event) {
          this.notifyService.toast('Watch removed.  May take some time to disappear.');
          this.refreshWatches();
        }
      });
    }
  }

  refreshWatches() {
    this.watches = new CustomStore({
      key: 'DefendantID',
      load: (loadOptions: any) => {
        if (loadOptions && loadOptions.take !== undefined && loadOptions.skip !== undefined) {
          this.watchesGrid.instance.clearSelection();
        }
        const result = new Promise((resolve, reject) => {
          this.params = this.paginationService.buildParams(loadOptions);
          this.defendantWatchService.GetWatchesView(this.params).then(response => {
            return resolve({
              data: response.data,
              totalCount: response.meta.pagination.total,
            });
          });
        });
        return result;
      },
    });
  }

  handleClose() {
    this.onClose.emit(false);
  }

  onSave() {
    const self = this;
    // TODO: 'saving' progress popup?
    if (this.emailValidationGroup && !this.emailValidationGroup.instance.validate().isValid) {
      return;
    }
    self.settings.WatchForfeitures = false;
    self.settings.WatchLiability = false;
    if (self.watchBoth) {
      self.settings.WatchForfeitures = true;
      self.settings.WatchLiability = true;
    }
    if (self.watchOnlyForf) {
      self.settings.WatchLiability = false;
      self.settings.WatchForfeitures = true;
    }
    if (this.billingService.signupToken) {
      this.settings.SourceUniqueId = this.billingService.signupToken;
      this.settings.ExternalCompanyId = this.billingService.externalCompanyId;
    }
    this.subs.sink = self.defendantWatchService.SaveSettings(self.settings).subscribe(result => {
      this.notifyService.toast('Saved', 'success');
      this.settings.Source = result.data.Source;
      this.settings.SourceUniqueId = result.data.SourceUniqueId;
      this.settings.RedirectURI = result.data.RedirectURI;
      self.refreshTabVisibility();
    }, error => {
      console.error(error);
      // TODO: better way to report error?
    });
  }

  advancedPopup() {
    this.popupVisible = true;
  }

  popupShownHandler(e) {
    this.agenciesGrid.instance.selectRows(this.settings.FilterAgencyIDs.split(','), false);
  }

  onlyForfCheckChanged(e) {
    if (e.value === true) {
      this.watchBoth = false;
    }
  }

  liabAndForfCheckChanged(e) {
    if (e.value === true) {
      this.watchOnlyForf = false;
    }
  }

  saveAgencyFilters() {
    const agencyIds = [];
    this.agenciesGrid.instance.getSelectedRowsData().forEach((item) => {
      agencyIds.push(item.Id);
    });
    this.settings.FilterAgencyIDs = agencyIds.toString(); // toString actually creates comma separated list - HANDY!
    this.popupVisible = false;
  }

  calcDef(rowData) {
    return `${rowData.DefendantFirstName} ${rowData.DefendantLastName}`;
  }

  editDef(data) {
    this.router.navigate([`/defendant/${data.DefendantID}`]);
  }

  openDetails(data) {
    this.currentDetail = data;
    this.detailsVisible = true;
  }

  closeDetails() {
    this.detailsVisible = false;
  }

  viewTerms() {
    this.agreementVisible = true;
  }

  agreeToTerms() {
    this.settings.ClientAgreementDate = StringUtilities.getCurrentDateTimeString();
    this.settings.ClientAgreementUserId = localStorage.getItem('userId');
    if (this.settings.NotificationEmails == null || this.settings.NotificationEmails === '') {
      this.settings.NotificationEmails = localStorage.getItem('userEmail');
    }
    this.onSave();
    this.userAgreedToTerms = true;
    this.agreementVisible = false;
    this.eventService.quickAction({ value: QuickActionPreviewEvent.SHOWWATCH, text: 'Show Defendant Watch', icon: 'fal fa-user' });
    this.hideToast = true;
    this.showGuidedSetup(0);
    this.refreshTabVisibility();
  }

  onToolbarPreparingWatches(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-plus',
          type: 'default',
          text: 'Add New Watch',
          disabled: !!this.settings.Source,
          onClick: () => {
            this.addWatch();
          },
        },
      },
      {
        location: 'before',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-upload text-primary',
          type: 'default',
          text: 'Batch Upload',
          disabled: !!this.settings.Source,
          onClick: () => {
            this.showImportPopup();
          },
        },
      },
    );
    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel',
          text: 'Export',
          onClick: () => {
            this.subs.sink = this.defendantWatchService.exportDefendantWatch(this.params).subscribe((data) => {
              PaginationService.downloadFile(data, 'DefendantWatches');
            }, error => {
              this.gridService.exportGrid(this.watches, 'DefendantWatchAlerts.csv');
            });
          },
        },
      },
    );
  }

  onToolbarPreparingAlerts(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.gridService.exportGrid(this.alertsGrid, 'DefendantWatchAlerts.csv');
          },
        },
      },
    );
  }

  addWatch() {
    if (!this.settings.ClientAgreementDate) {
        this.notifyService.toast('Please view and accept the terms and conditions before adding a watch', 'error');
    }
    // for some reason, this doesn't work if not done in setTimeout.  That's devexpress for ya
    setTimeout(() => {
      if (this.addWatchValidationGroup) {
        this.addWatchValidationGroup.instance.reset();
      }
    });

    this.defToAdd = new DefendantToAdd();
    this.addNewVisible = true;
  }

  editWatch(data: DefendantWatch) {
    this.defToAdd = new DefendantToAdd();
    this.defToAdd.First = data.DefendantFirstName;
    this.defToAdd.Last = data.DefendantLastName;
    this.defToAdd.Dob = data.DefendantDOB;
    this.defToAdd.Ssn = data.DefendantSSN;
    this.defData = this.defToAdd;
    this.editedDefendantId = data.DefendantID;
    this.isEditing = true;
    this.addNewVisible = true;
  }

  confirmAddWatch() {
    if (!this.addWatchValidationGroup.instance.validate().isValid) {
      return;
    }

    if (this.isEditing) {
      this.addNewVisible = false;
      const updatedData = {
        DefendantFirstName: this.defToAdd.First,
        DefendantLastName: this.defToAdd.Last,
        DefendantDOB: this.defToAdd.Dob,
        DefendantSSN: this.defToAdd.Ssn,
        defendantId: this.editedDefendantId,
      };
      this.subs.sink = this.defendantWatchService.UpdateWatchByDefendant(updatedData).subscribe(res => {
        this.notifyService.toast('Watch has been edited.');
        this.refreshWatches();
      });
      return;
    }

    this.addNewVisible = false;
    const checkForExistingData = {
      First: this.defToAdd.First,
      Last: this.defToAdd.Last,
      Dob: this.defToAdd.Dob,
      Ssn: this.defToAdd.Ssn,
    };
    this.subs.sink = this.defendantService.checkForExistingDefendantOrTransaction(checkForExistingData)
      .pipe(map(
        (response: PortoResponse<ExistingDefendant[]>) => response.data.filter((defendant: ExistingDefendant) =>
          (defendant.Ssn || this.defToAdd.Ssn) ? defendant.Ssn === this.defToAdd.Ssn : true),
      ))
      .subscribe((defendants: ExistingDefendant[]) => {
        const inActiveDefendants = defendants.filter(defendant => !defendant.IncludeInDefendantWatch);
        if (defendants.length === 0) {
          this.createNewWatch();
        } else if (inActiveDefendants.length === 0) {
          this.notifyService.toast('Duplicate Defendant found', 'error');
        } else {
          this.showChoices(inActiveDefendants);
        }
      });
  }

  selectedDefendant({ options }: MatSelectionListChange) {
    if (options[0].value) {
      this.selectedDefendantId = options[0].value;
      this.defendantSelected = true;
    }
  }

  cancelReactivate() {
    this.showChoicePopup = false;
    this.defendantSelected = false;
  }

  reactivateWatch(defendantId) {
    if (defendantId) {
      this.defendantWatchService.reactivateWatch(defendantId);
      this.notifyService.toast('Watch has been reactivated. May take a few hours to become active.');
      this.showChoicePopup = false;
      this.refreshWatches();
    }
  }

  createNewWatch() {
    this.subs.sink = this.defendantService.initNewDefendant(this.defToAdd).subscribe(() => {
      this.showChoicePopup = false;
      this.notifyService.toast('Watch has been created. May take a few hours to become active.');
      this.refreshWatches();
    });
  }

  showChoices(data: ExistingDefendant[]) {
    // have multiple found results, need to display a way to choose them.
    this.showChoicePopup = true;
    this.choiceData = data;
  }

  getStartedNow() {
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM)
      .subscribe(subscribedToBms => {
        if (subscribedToBms) {
          this.router.navigateByUrl('/getstarted/checkout');
        } else {
          this.billingService.signupProduct = AppConstants.PRODUCT_NAME_DEFENDANT_WATCH;
          this.router.navigateByUrl('/getstarted/checkout');
        }
      });
  }

  calcRemoveCell(data) {
    if (data.IncludeInDefendantWatch) {
      return { ...data, value: 'Remove Watch', edit: 'Edit Watch' };
    } else {
      if (this.usingBailManagementSystem) {
        if (data.RemovingFromDefendantWatch) {
          return { ...data, value: null, edit: null };
        } else {
          return { ...data, value: 'Exonerate To Remove', edit: null };
        }
      } else {
        return { ...data, value: null, edit: null };
      }
    }
  }

  showImportPopup() {
    this.importPopupVisible = true;
  }

  onImportPopupHidden() {
    this.importPopupVisible = false;
    this.refreshWatches();
  }

  checkEmails (emails: any) {
    let isValid = true;
    const emailPattern: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emails.value == null || emails.value === '') {
      emails.rule.message = 'Enter at least one email address';
      return false;
    } else {
      isValid = StringUtilities.validateEmailAddresses(emails.value);
      if (!isValid) {
        emails.rule.message = 'One or more emails are invalid';
      }
    }
    return isValid;
  }

  showGuidedSetup(startAt) {

    switch (startAt) {
        case null:
        case 0 : {
            this.selectedTabIndex = 3;
            this.eventService.saveGuidedSetupProgress(1, 'defendantwatch');
            this.eventService.compLoad({
              'title': 'First Step - Add Email Addresses',
              'pageLink': 'Next - Company Info >',
              'pageUrl': '/system/company',
            });
            break;
          }
        case 2 : {
          this.eventService.saveGuidedSetupProgress(3, 'defendantwatch');
          this.eventService.compLoad({
            'title': 'Add Defendant Details',
            'pageLink': 'Next - See Current Watches >',
            'pageUrl': '/defendantwatch/1',
            'backUrl': '/defendantwatch/3',
          });
          break;
        }
        case 3 : {
          this.eventService.saveGuidedSetupProgress(4, 'defendantwatch');
          this.eventService.compLoad({
            'title': 'Done! Your\'e all set up',
            'pageLink': 'Finish >',
            'pageUrl': '/defendantwatch',
            'backUrl': '/defendantwatch',
            'done': true,
          });
          break;
        }
      }

      this.eventService.viewGuidedSetup('true');

  }

  loadsystemConfig() {

    this.subs.sink = this.systemConfigService.get().subscribe(sc => {
      if (sc && sc.data && sc.data.length > 0) {
        const systemConfig = new SystemConfig(sc.data[0]);

        this.guidedSetupStep = systemConfig.DefendantWatchGuidedSetupStep;
        if (this.guidedSetupStep < 4) {
          if (this.guidedSetupStep == null) {
            this.selectedTabIndex = 3;
          }
        }
      }
    }, error => {
      // console.log('there were errors getting data', error);
    });
  }

  onHiding(e) {
    this.isEditing = false;
    this.eventService.quickAction({ value: QuickActionPreviewEvent.SHOWWATCH, text: 'Show Defendant Watch', icon: 'fal fa-user' });
  }
}
