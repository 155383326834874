import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StringUtilities } from '../../shared/StringUtilities';
import { UnsubscribeOnDestroyAdapter } from './../../common/UnsubscribeOnDestroy';
import { LookupService } from './../../services/app-lookup.service';
import { NotifyService } from '../../../app/common/notify/notify.service';
import { Contact } from './../../models/contact';


export interface ContactStatusUpdaterInterface {
  contact: Contact
}

@Component({
  selector: 'app-contact-status-updater',
  templateUrl: './contact-status-updater.component.html',
  styleUrls: ['./contact-status-updater.component.scss']
})
export class ContactStatusUpdaterComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  dateFormatter = StringUtilities.dateFormatter;
  maxDate = new Date();
  postedDate = StringUtilities.getCurrentDateString();
  prospectDeclineReasons: any = {};
  markProspectsDeadReason: string;
  errorData: any;
  contactTypes: any;

  constructor(
    public dialogRef: MatDialogRef<ContactStatusUpdaterInterface>,
    @Inject(MAT_DIALOG_DATA) public data: ContactStatusUpdaterInterface,
    private lookupService: LookupService,
    private notify: NotifyService,
  ) {
    super();
   }

  ngOnInit(): void {
    this.contactTypes = this.lookupService.getLookupStatic('ContactTypes');
  }

  onValueChanged(event: any) {
    this.data.contact.Type = event.value;
    this.data.contact['orig_Type'] = event.value;
  }
}
