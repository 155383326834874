import {BaseModel} from './BaseModel';
import {Address} from './address';
import {Phone} from './phones';
import {isNullOrUndefined} from 'util';
import {Email} from './email';
import {Vehicle} from './vehicle';
import {Employer} from './employer';
import {SocialNetwork} from './social-networks';

export class Company extends BaseModel {
  public Id: '';
  public Name: string;
  public DefaultContactPerson: string;
  public DefaultContactTitle: string;
  public WebSiteAddress: string;
  public DefaultUserId: number;
  public PhysicalAddressLine1: string;
  public PhysicalAddressline2: string;
  public PhysicalCity: string;
  public PhysicalCounty: string;
  public PhysicalStateCode: string;
  public Zip: string;
  public CustomerId: number;
  public Deleted_At: string;
  public phones: Phone[];
  public addresses: Address[];
  public Logo: any;
  public AddLogoToForms: boolean;
  public GooglePlaceId: string;
  public BondCount: number;
  public V1Id: string;



  constructor(obj?: object) {
    super(obj);
    this.childrenNames = ['phones', 'addresses'];
    this.propertiesToIgnoreForCheckChanges.push('BondCount');
    this.routeRoot = 'customers';
    this.Logo = [];
    this.AddLogoToForms =  false;


    if (obj == null) {
      this.Id = '';
      this.phones = [];

      this.addresses = [];
    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);

    const mainThis = this;
    this.phones.length = 0;
    this.addresses.length = 0;

    if (data.phones.data !== null && data.phones.data.length > 0) {
      data.phones.data.forEach(
        (phones) => {
          let newPhone = new Phone();
          newPhone.fillFromObject(phones);
          mainThis.phones.push(newPhone);

        });
    }

    if (data.addresses.data !== null && data.addresses.data.length > 0) {
      data.addresses.data.forEach(
        (addr) => {
          let newAddress = new Address();
          newAddress.fillFromObject(addr);
          mainThis.addresses.push(newAddress);

        });
    }


  }

}
