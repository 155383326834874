import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {LookupItem} from '../models/lookup-item';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()

export class SmsTemplateMappingService {
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  get(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/smstemplatemappings`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  update(key: any,  data: any): Observable<any> {
    return this.http.patch(`${this.settings.getBaseUrl()}/smstemplatemappings/${key}`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }


  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/smstemplatemappings`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  delete(key: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/smstemplatemappings/${key}`).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }
}
