<mat-card-subtitle>Cost per text - 15 &#162;</mat-card-subtitle>
<mat-card-subtitle>Texts to defendant and indemnitor will go out around midday.<a [href]='learnMoreLink' target='_blank'>Learn more about the service</a></mat-card-subtitle>
<mat-card-content>
  <div class='main-header'>
    <mat-slide-toggle #matSlideToggle [color]="'primary'" [checked]='enabled' (change)='onEnable($event)'>
      Send Google Reviews
    </mat-slide-toggle>
  </div>
  <div class='google-place-lookup'>
    <div class='form-group' fxLayout='column' fxLayoutAlign='center none'>
      <label class='control-label'>Google Review Url</label>
      <app-google-place fxFlex='50' fxFlex.xs='100' [(value)]='company.GooglePlaceId' parent='customer'></app-google-place>
    </div>
  </div>
</mat-card-content>
