<div [id]="controlId" tabindex="-1">
  <dx-accordion
    id="smartAddress"
    [focusStateEnabled]='false'
    [disabled]="clickDisabled"
    [visible]="true"
    tabindex="-1"
    [collapsible]="true"
    [dataSource]="panels"
    [selectedIndex]="accordionIndex"
    (onItemTitleClick)="titleClick($event)">
    <div *dxTemplate="let addressTitle of 'title'" style="margin: 0; position: relative; bottom: 0;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span style="display:flex;width:100%;" [ngClass]="{'custom-invalid': isInvalid}" class="dx-texteditor dx-editor-underlined" tabindex="-1">
        <input
          type="text"
          class="dx-texteditor-input"
          [id]="randomId"
          #mapcomplete
          angularGooglePlace
          [value]="getDefaultAddressSingleLine()"
          [options]="options"
          (setAddress)="getAddress($event)"
          (street_number)="street_number = $event"
          (subpremise)='subpremise = $event'
          (street)="street = $event"
          (city)="city = $event"
          (state)="state = $event"
          (district)="district = $event"
          (country)="country = $event"
          (postal_code)="postal_code = $event"
          (lat)="lat = $event"
          (lng)="lng = $event"
          (adr_address)="adr_address = $event"
          (name)="name = $event"
          (place_id)="place_id = $event"
          (types)="types = $event"
          (url)="url = $event"
          (vicinity)="vicinity = $event"
          (photos)="photos = $event"
          (CountryCodes)="CountryCodes = $event"
          [readonly]="readOnlyField"
          autocomplete="off"
          [disabled]="inputDisabled"
          (focusout)="onFocusOut($event)"
          (change)="addressChanged($event)"
          [matTooltip]="getDefaultAddressSingleLine()"/>
        <button
          style="width: 25px;"
          tabindex="-1"
          type="button"
          mat-icon-button
          matTooltip="Show in Popup"
          (click)="addressListPopup($event)"
          *ngIf="!singleAddressMode">
          <span *ngIf="addressCount > 0" class="badge badge-primary">
            {{addressCount}}
          </span>
          <i *ngIf="addressCount == 0" class="fal fa-window-restore"></i>
        </button>
        <button
          style="width: 25px;"
          tabindex="-1"
          *ngIf="defaultAddress && showMapIcon"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Show on Google Maps (external link)"
          (click)="showAddressOnMap()">
          <i class="fas fa-map-marker"></i>
        </button>
        <span tabindex="-1">
          <i tabindex="-1" *ngIf="isDefaultAddressBad" class="fas fa-exclamation-triangle text-danger"></i>
        </span>
      </span>
    </div>
    <div *dxTemplate="let address of 'item'" [ngStyle]="containerStyle">
      <div class="form-group">
        <label [ngClass]="labelCss">Address</label>
        <div class="col-md-9">
          <dx-text-box
            id="AddressLine1"
            maxLength="128"
            [(value)]="defaultAddress.AddressLine1"
            [placeholder]="placeholderVisible ? 'Or Enter Manually' : ''"
            [readOnly]="readOnlyField"
            (onChange)="addressItemChanged($event)">
          </dx-text-box>
        </div>
      </div>
      <div class="form-group">
        <label [ngClass]="labelCss"></label>
        <div class="col-md-9">
          <dx-text-box
            id="AddressLine2"
            maxLength="128"
            [(value)]="defaultAddress.AddressLine2"
            [readOnly]="readOnlyField"
            (onChange)="addressItemChanged($event)">
          </dx-text-box>
        </div>
      </div>
      <div class="form-group">
        <label [ngClass]="labelCss">City</label>
        <div class="col-md-9">
          <dx-text-box
            [id]="AddressCity"
            maxLength="128"
            [(value)]="defaultAddress.City"
            [readOnly]="readOnlyField"
            (onChange)="addressItemChanged($event)"
            [placeholder]="placeholderVisible ? 'City' : ''">
          </dx-text-box>
        </div>
      </div>
      <div class="form-group">
        <label [ngClass]="labelCss">State</label>
        <div class="col-md-9">
          <dx-select-box
            [dataSource]="states"
            (onValueChanged)="stateChanged($event)"
            [(value)]="defaultAddress.StateCode"
            valueExpr="Id"
            displayExpr="Id"
            [readOnly]="readOnlyField"
            [id]="AddressState">
            <div *dxTemplate="let data of 'dropDownButton'">
              <dx-load-indicator
                [visible]="isStatesLoading">
              </dx-load-indicator>
              <div
                class="dx-dropdowneditor-icon"
                *ngIf="!isStatesLoading">
              </div>
            </div>
          </dx-select-box>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="labelCss">Zip</label>
        <div class="col-sm-7">
          <dx-text-box
            [(value)]="defaultAddress.Zip"
            maxLength="5"
            [id]="AddressZip"
            (onChange)="addressItemChanged($event)"
            [placeholder]="placeholderVisible ? 'Zip Code' : ''"
            [readOnly]="readOnlyField">
          </dx-text-box>
        </div>
      </div>
      <div class="form-group">
        <label [ngClass]="labelCss">Is Physical</label>
        <div class="col-sm-6">
          <dx-box direction="row" width="100%">
            <dxi-item ratio="0" [baseSize]="0">
              <dx-check-box
                [(value)]="defaultAddress.IsPhysical"
                class="checkbox"
                [readOnly]="readOnlyField">
              </dx-check-box>
            </dxi-item>
            <dxi-item ratio="0" [baseSize]="8"></dxi-item>
          </dx-box>
        </div>
      </div>
    </div>
  </dx-accordion>
</div>

<dx-load-panel
    #loader
    [(visible)]="addressLoadingvisible"
    [closeOnOutsideClick]="true"
    [position]="addressContainerId"
    [shading]="false"
    [showIndicator]="true"
    [showPane]="true">
</dx-load-panel>

<dx-popup
  (onHiding)="cancelAddress()"
  [(visible)]="smartAddressListPopup"
  [closeOnOutsideClick]="true"
  [dragEnabled]="false"
  [showTitle]="true"
  class="popup"
  title="Edit Address">

  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view #scrollView id="scrollView">
      <dx-data-grid
          #addressGrid
          (onCellPrepared)="onCellPrepared($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onInitNewRow)="onInitNewRow($event)"
          (onRowInserted)="onRowInserted($event)"
          (onRowInserting)="onRowInserting($event)"
          (onRowRemoving)="onRowRemoving($event)"
          (onRowUpdating)="onRowUpdating($event)"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          [dataSource]="model"
          id="addressGrid"
          keyExpr="Id"
          noDataText="No address information. Click + above to add a new address">
        <dxo-editing
          [allowAdding]="!readOnlyField"
          [allowUpdating]="!readOnlyField"
          [allowDeleting]="!readOnlyField"
          mode="popup"
          [texts]="{
            saveRowChanges: 'OK',
            cancelRowChanges: 'CANCEL'
          }"
        >
        </dxo-editing>

        <dxi-column
          dataField="AddressType"
          caption="Type"
          hidingPriority="0"
          dataType="string"
          alignment="center">
          <dxo-lookup
            [dataSource]="AddressTypes"
            displayExpr="Text"
            valueExpr="Id">
          </dxo-lookup>
        </dxi-column>

        <dxi-column
          dataField="AddressLine1"
          caption="Address 1"
          hidingPriority="9"
          dataType="string"
          alignment="center">
        </dxi-column>

        <dxi-column
          dataField="AddressLine2"
          caption="Address 2"
          hidingPriority="4"
          dataType="string"
          alignment="center">
        </dxi-column>

        <dxi-column
          dataField="City"
          caption="City"
          dataType="string"
          alignment="center"
          hidingPriority="8"
          [setCellValue]="onCellCityChange">
        </dxi-column>
        <dxi-column
          dataField="StateCode"
          caption="State"
          dataType="string"
          alignment="center"
          hidingPriority="6"
          [setCellValue]="onCellStateChange">
          <dxo-lookup
            [dataSource]="states"
            valueExpr="Id"
            displayExpr="Id"
          ></dxo-lookup>
        </dxi-column>

        <dxi-column
          dataField="Zip"
          caption="Zip"
          dataType="string"
          alignment="center"
          hidingPriority="5"
          [setCellValue]="onCellZipCodeChange">
        </dxi-column>
        <dxi-column
          dataField="IsPhysical"
          caption="Physical"
          hidingPriority="3"
          dataType="boolean">
        </dxi-column>
        <dxi-column
          dataField="IsDefault"
          caption="Default"
          dataType="boolean"
          hidingPriority="2">
        </dxi-column>
        <dxi-column
          dataField="IsBad"
          caption="Is Bad"
          hidingPriority="1"
          dataType="boolean">
        </dxi-column>
        <dxi-column
          caption="Map"
          cellTemplate="mapTemplate"
          [formItem]="{visible:false}"
          hidingPriority="7"
          alignment="center"
          [visible]="true">
          <div *dxTemplate="let data of 'mapTemplate'" >
            <a (click)="showCurrentAddressOnMap(data)"
               title="Show On Map"
               style="display:flex; justify-content: center; cursor: pointer; text-decoration: none;">
              <i class="fal fa-map-marker"></i>
            </a>
          </div>
        </dxi-column>

        <div *dxTemplate="let data of 'ctState'">
          <span>{{ data.value }}</span>
        </div>

        <div *dxTemplate="let data of 'ctCounty'">
          <span>{{ data.value }}</span>
        </div>
      </dx-data-grid>

      <div class="text-center" style="margin: 16px 0;">
        <dx-button
          id="ok"
          type="default"
          [width]="136"
          text="Ok"
          icon="fal fa-check"
          (click)="saveGrid($event)">
        </dx-button>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>


