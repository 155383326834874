<div class="profile-content">
  <mat-card class="personal-area">
    <mat-card-content>
      <div *ngIf="!defendantLoading && defendant.person as def; else ghostPersonal">
        <app-mugshot
          [dataUrl]="defendant.person.ProfilePicture"
          [defendantId]="defendant.Id"
          [gender]="defendant.person.Gender"
          [preview]="true"
          [canDeleteMugshot]="false"
        >
        </app-mugshot>
        <h3>
          {{ def.First }} {{ def.Last }}
        </h3>
        <button
          [disabled]="btnFullProfileDisabled"
          mat-raised-button
          [color]="btnFullProfileDisabled ? null : 'primary'"
          (click)='onFullProfileClick($event)'
          class="action-button">
          <span>Full Profile</span><i class="icon-spacer fas fa-user-circle"></i>
        </button>
        <mat-list dense>
          <mat-list-item>
            <a mat-list-icon [routerLink]='["full"]' class='clickable-icon'><i class="fas fa-birthday-cake fa-lg"></i></a>
            <span matLine
                  matTooltip="{{ def.Age + ' years old' }}"
                  matTooltipPosition="above">
              {{ def.Dob | date: 'M/d/yyyy' }}
            </span>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-icon class='clickable-icon' (click)='updateInfo(PersonUpdateType.MOBILE)'><i class="fas fa-phone-alt fa-lg"></i></a>
            <span matLine>
              <div *ngIf="defaultPhone; else noPhone">
                <ng-container>
                  <app-mobile-link type='mobile' [value]='defaultPhone.Number | phone'></app-mobile-link>
                </ng-container>
              </div>
              <ng-template #noPhone>
                <ng-container> (No Phone Number) </ng-container>
              </ng-template>
            </span>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-icon class='clickable-icon' (click)='updateInfo(PersonUpdateType.EMAIL)'><i class="fas fa-envelope fa-lg"></i></a>
            <span matLine>
              <div *ngIf="defaultEmail; else noEmail">
                <ng-container>
                  <app-mobile-link type='email' [value]='defaultEmail.Email'></app-mobile-link>
                </ng-container>
              </div>
              <ng-template #noEmail>
                <ng-container> No email </ng-container>
              </ng-template>
            </span>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-icon class='clickable-icon' (click)='updateInfo(PersonUpdateType.ADDRESS)'><i class="fas fa-home fa-lg"></i></a>
            <span matLine>
              <div *ngIf="defaultAddress; else noAddress">
                <ng-container>
                  <a style="cursor: pointer" (click)="openAddress(defaultAddress)">
                    {{ defaultAddress.AddressLine1 }} <br *ngIf="defaultAddress.AddressLine1">
                    {{ defaultAddress.AddressLine2 }} <br *ngIf="defaultAddress.AddressLine2">
                    {{ defaultAddress.City + ',' }}
                    {{ defaultAddress.StateCode }}
                    {{ defaultAddress.Zip }}
                  </a>
                </ng-container>
              </div>
            </span>
            <ng-template #noAddress> (No Address)</ng-template>
          </mat-list-item>
          <ng-container *ngFor="let indemnitor of indemnitors">
            <mat-list-item *ngIf="indemnitor as indemn">
              <a mat-list-icon class='clickable-icon' (click)='indemnitorInfo(indemn)'><i class="fas fa-user-friends fa-lg" ></i></a>
              <span matLine>
                {{ indemn.person.First }}
                {{ indemn.person.Last }}
                <ng-container *ngIf="indemn.Relation">({{ indemn.Relation }})</ng-container>
              </span>
              <span matLine>
                <ng-container *ngFor="let phone of indemn.person.phones">
                  <ng-container *ngIf="phone.IsDefault"> {{ phone.Number | phone }} </ng-container>
                </ng-container>
              </span>
            </mat-list-item>
          </ng-container>
          <mat-list-item>
            <a mat-list-icon class='clickable-icon' (click)='navigateToLastNote()'><i class="fas fa-sticky-note fa-lg"></i></a>
            <span matLine
                  style="white-space: nowrap"
                  matTooltip="{{ lastNote }}"
                  matTooltipPosition="above">
              <ng-container> {{ lastNote }} </ng-container>
            </span>
          </mat-list-item>
        </mat-list>
        <div fxLayout='column' fxLayoutAlign="space-evenly center" >
          <button mat-button color="primary" (click)="addNote()">
            <span>Notes</span>
            <i mat-list-icon class="icon-spacer fas fa-sticky-note fa-lg fa-flip-vertical"></i>
          </button>
          <button mat-button color="alert" (click)="addDocuments()">
            <span>Documents</span>
            <i mat-list-icon class="icon-spacer fas fa-file-upload fa-lg"></i>
          </button>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card class="scheduling-area">
    <mat-card-title>Schedules</mat-card-title>
    <mat-card-content>
      <!--    <h4>Schedules</h4>-->
      <mat-list class="scheduling-content" role="list" *ngIf="!transactionsLoading; else ghostSchedule">
        <mat-list-item role="listitem" style='display: flex; justify-content: center; margin-bottom: 20px;'>
          <i mat-list-icon class="icon-spacer far fa-calendar fa-lg"></i>
          <h4 matLine><strong>Check Ins</strong></h4>
          <p matLine style='min-width: 223px;'>
            {{previousCheckin}}
            <br>
            {{nextCheckin}}
          </p>
          <div matLine>
            <button mat-button color="primary" (click)="recordCheckin()">
              <span> Record Check In </span>
              <i mat-list-icon class="icon-spacer far fa-check fa-lg"></i>
            </button>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" style='display: flex; justify-content: center;margin-bottom: 20px;'>
          <i mat-list-icon class="icon-spacer fas fa-gavel fa-lg"></i>
          <h4 matLine><strong>Next Court Date</strong></h4>
          <p matLine style='min-width: 223px;'>
            <ng-container *ngIf="nextCourt; else noCourtDate">
              {{ nextCourt }}
              <br>
              <ng-container *ngIf="courtName;">
                {{ courtName }}
              </ng-container>
              <ng-container *ngIf="setFor;">
                <br>
                {{ setFor }}
              </ng-container>
            </ng-container>
            <ng-template #noCourtDate>
              No Upcoming Court Dates
              <br>
              <br>
            </ng-template>
          </p>
          <div matLine>
            <button mat-button color="primary" (click)="addCourtDate(defendant)">
              <span> Schedule Court Date </span>
              <i mat-list-icon class="icon-spacer far fa-clock fa-lg"></i>
            </button>
          </div>

        </mat-list-item>
        <mat-list-item role="listitem" style='display: flex; justify-content: center;'>
          <i mat-list-icon class="icon-spacer far fa-dollar-sign fa-lg"></i>
          <h4 matLine><strong>Payment Schedule</strong></h4>
          <p matLine style='min-width: 223px;'>
            <ng-container *ngIf="recentPayment; else noRecentPayment">
              Latest:
              {{ paymentSum | currency }}
              &mdash;
              {{ recentPayment.TransactionDate | date: 'E M/d/yy' }}
            </ng-container>
            <ng-template #noRecentPayment>
              No Recent Payments
            </ng-template>
            <br>
            <ng-container *ngIf="upcomingPayment; else noUpcomingPayment">
              Upcoming:
              {{ upcomingPayment.Amount | currency }}
              &mdash;
              {{ upcomingPayment.DueDate | date: 'E M/d/yy' }}
            </ng-container>
            <ng-template #noUpcomingPayment>
              No Upcoming Payments
            </ng-template>
            <br>
            <ng-container *ngIf="upcomingCollateralPayment;">
              Next Collateral:
              {{ upcomingCollateralPayment?.ScheduledAmount | currency }}
              &mdash;
              {{ upcomingCollateralPayment?.ScheduledDate | date: 'E M/d/yy' }}
            </ng-container>
          </p>
          <div matLine>
            <button mat-button color="primary" (click)="takePayment()">
              <span> Take Payment </span>
              <i mat-list-icon class="icon-spacer far fa-credit-card fa-lg"></i>
            </button>
          </div>
        </mat-list-item>
      </mat-list>

    </mat-card-content>
  </mat-card>
  <mat-card class="transactions-area">

    <mat-card-title>Bonds</mat-card-title>
    <mat-card-content>
      <!--    <h4>Bonds</h4>-->
      <ng-container>Total Liability: {{totalLiability | currency}} </ng-container>

      <ng-container *ngIf="transactionsLoading">
        <ng-container *ngTemplateOutlet="ghostBonds"></ng-container>
      </ng-container>
      <mat-list *ngIf="!transactionsLoading">
        <mat-list-item class="transaction-detail" *ngFor="let transaction of defendant.transactions; let transIndex = index;">
          <h5 matLine>
            <ng-container *ngIf='transaction.Status != "Dead"'> {{ transaction.PostedDate | date: 'M/d/yyyy' }} </ng-container>
            <ng-container *ngIf='(transaction.Status != "Dead" && !!transaction.Balance)'> &mdash;
              Balance: {{transaction.Balance | currency}} </ng-container>
            <ng-container *ngIf='(transaction.Status != "Dead" && !!transaction.agent)'> &mdash; {{transaction.agent?.person.getFullName()}}</ng-container>
            <ng-container *ngIf="!transactionsLoading && transaction.application && transaction.application.Status === 'COMPLETE'">
              <button (click)="navigateToApplicationPage(transaction.Id)" mat-button color="primary">
                View Application
              </button>
            </ng-container>
            <ng-container *ngIf='transaction.Status === "Dead"'>Dead</ng-container>
            <ng-container *ngIf="transaction.Status === 'Dead' && !!transaction.DeadReason"> &mdash; {{transaction.DeadReason}}</ng-container>
          </h5>
          <div matLine *ngIf='transaction.NeedsPaperwork' style='padding-top:8px;'>
              <mat-checkbox [checked]='transaction.NeedsPaperwork' color='primary' (change)='handleNeedsPaperwork($event.checked, transIndex)' >Needs Paperwork</mat-checkbox>
          </div>
          <div matLine *ngIf='transaction.Status === "Prospect"' style='padding-top:8px;' class='transaction-status'>
            <label class='control-label'>Status</label>
            <dx-select-box
              [dataSource]="statuses"
              displayExpr="Text"
              valueExpr="Id"
              [(value)]="transaction.Status"
              [showClearButton]='false'
              (onValueChanged)="handleStatusUpdate(transIndex)"
              [readOnly]="transactionsLoading">
            </dx-select-box>
          </div>
          <div matLine class="bond-detail" *ngFor="let bond of transaction.bonds">
            <p matLine>
              <span style="color: gray;">
                <span style="margin-right: 8px;"
                      class="label"
                      (mouseover)="showChargesTooltip($event, true, bond)"
                      (mouseout)="showChargesTooltip($event, false, bond)"
                      [class.label-primary]="bond.Status === 'Exonerated'"
                      [class.label-danger]="bond.Status === 'Forfeited'"
                      [class.label-warning]="!bond.Status ||
                    bond.Status === 'Executed' ||
                    bond.Status === 'Set Aside'">
                  {{ bond.Status || 'No Power Attached' }}
                </span>
                <span>
                  <a  class="cursor-pointer"
                      (click)="powerInfo(bond)"
                      (mouseover)="showChargesTooltip($event, true, bond)"
                      (mouseout)="showChargesTooltip($event, false, bond)">
                    {{ bond.power.PowerNumber || 'No Power Number' }}
                  </a>
                </span>
                <span> &mdash; {{ bond.Amount | currency }} </span>
                <span *ngIf="bond.chargeClass"> &mdash; {{bond.chargeClass.data.name}} </span>
                <br fxShow.xs fxHide>
                <button mat-button color="primary"
                        (click)="openBondStatus('exonerate', bond.Id, defendant)"
                        *ngIf="(bond.Status == 'Set Aside' || bond.Status == 'Forfeited' || bond.Status == 'Executed') && transaction.Status !== 'Prospect'"
                        style="margin-right: 8px; margin-left: 20px;">
                  Exonerate
                </button>
                <button mat-button color="primary"
                        (click)="openBondStatus('forfeit', bond.Id, defendant)"
                        *ngIf="(bond.Status == 'Executed') && transaction.Status !== 'Prospect'"
                        style="margin-right: 8px; margin-left: 20px;">
                  Forfeit
                </button>
                <button mat-button color="primary"
                        (click)="openBondStatus('SetAside', bond.Id, defendant)"
                        *ngIf="bond.Status == 'Forfeited'">
                  Set Aside
                </button>
              </span>
              <ng-container *ngIf="!!bond.CaseNumber">
                <br>Case Number: {{ bond.CaseNumber }} </ng-container>
              <ng-container *ngIf="!!bond.Charge">
                <br>Charge: {{ bond.Charge }} </ng-container>
            </p>
          </div>
        </mat-list-item>
      </mat-list>

    </mat-card-content>
  </mat-card>
  <mat-card class="status-area">

    <mat-card-title>Defendant Status</mat-card-title>
    <mat-card-content>
      <!--    <h4>Defendant Status</h4>-->

      <ng-container *ngIf="messagesLoading">
        <ng-container *ngTemplateOutlet="ghostBonds"></ng-container>
      </ng-container>
      <mat-list *ngIf="!messagesLoading">
        <mat-list-item *ngIf="messages.length>0; else noMessages">
          <span class="messages">
          <span *ngFor="let message of messages" [class]="message.class">
            {{message.message}}
          </span>
            </span>
        </mat-list-item>
        <ng-template #noMessages>
          <p>
            (No Defendant Status Indicators)
          </p>
        </ng-template>
      </mat-list>

    </mat-card-content>
  </mat-card>

</div>
<ng-template #ghostPersonal>
  <div style="position: relative; display: flex; flex-direction: column; align-items: center;">
    <div class="mat-card-avatar mat-card-lg-image" style="margin-bottom: 16px;">
      <ngx-skeleton-loader count="1" appearance="circle"
                           [theme]="{'width': '100%', 'height': '100%'}">
      </ngx-skeleton-loader>
    </div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        height: '16px',
        width: '250px'
      }">
    </ngx-skeleton-loader>
    <button
      mat-raised-button
      class="action-button"
      (click)='onFullProfileClick($event)'
      [disabled]="btnFullProfileDisabled"
      [color]="btnFullProfileDisabled ? null : 'primary'"
      style="margin-bottom: 16px;">
      <span>Full Profile</span><i class="icon-spacer fas fa-user-circle"></i>
    </button>
    <ngx-skeleton-loader
      count="3"
      [theme]="{
        height: '40px',
        width: '250px',
        'display': 'flex',
        'flex-direction': 'column',
        'flex': '1'
      }">
    </ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #ghostSchedule>
  <div style="display: flex; flex-wrap: wrap; justify-content: center;">
    <ngx-skeleton-loader
      count="3"
      appearance="circle"
      [theme]="{
        width: '250px',
        height: '72px',
        'border-radius': '10px'
      }">
    </ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #ghostBonds>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      height: '83px',
      width: '100%',
      'display': 'flex',
      'flex-direction': 'column',
      'flex': '1'
    }">
  </ngx-skeleton-loader>
</ng-template>

<dx-popup
  class="popup-component"
  [height]="height"
  [position]="position"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="popupVisible"
  (onHidden)="dynamicPopupHidden()"
  [title]="popupTitle"
  [maxHeight]="popupMaxHeight"
  [maxWidth]="popupMaxWidth">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
        #scrollView
        [showScrollbar]="'always'"
        id="scrollview">
      <ng-template #vcRef></ng-template>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-dynamic-popup
  [dragEnabled]="dynamicPopupDragEnabled"
  [maxHeight]="dynamicPopupMaxHeight"
  [maxWidth]="dynamicPopupMaxWidth"
  [popupComponent]="dynamicPopupClass"
  [popupComponentData]="dynamicPopupData"
  [popupComponentTitle]="dynamicPopupTitle"
  [showCloseButton]="true"
  [popupComponentVisible]="dynamicPopupVisible"
  [popupShowTitle]="dynamicPopupShowTitle"
  [popupAllowCloseOnOutsideClick]='false'
  (onPopupHidden)="dynamicPopupHidden()">
</app-dynamic-popup>

<dx-tooltip #tooltip>
  <div *dxTemplate="let data = data of 'content'">
    {{tooltipText}}
  </div>
</dx-tooltip>


