import {BaseModel} from './BaseModel';
import {Person} from './person';
import {Vehicle} from './vehicle';
import {Employer} from './employer';

export class Agent extends BaseModel {
  Id: any;
  person: Person;

  constructor(obj?: object) {
    super(obj);

    if (obj == null) {

    }

    this.childrenNames = ['person'];
    this.routeRoot = 'agent';

    if (obj == null) {
      this.Id = '';
      this.person = new Person();
    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);
    this.person.loadData(data.person.data);
  }

}
