import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { isNullOrUndefined } from 'util';
import { BillingService } from '../../../services/billing.service';
import { AppConstants } from '../../../shared/AppConstants';
import * as moment from 'moment';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { ReminderService } from '../../../services/reminder.service';
import { UpsellComponent } from '../../../common/notify/custom/upsell/upsell.component';
import { NotifyService } from '../../../common/notify/notify.service';
import { UserMessage } from '../../../models/user-message';
import { EventService } from '../../../services/event.service';
@Component({
  selector: 'app-textreplies',
  templateUrl: './textreplies.component.html',
  styleUrls: ['./textreplies.component.scss'],
})
export class TextrepliesComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  messageData: any[] = [];
  messagePageNum = 1;
  unreadMessageCount: any;
  buttonColor = '';
  subscribedProducts: any;
  isBail = false;
  isDefendantManager = false;
  isCollections = false;
  isTextMessaging = false;
  demoMessageName = 'Demo';
  showDNCConfirmationPopup = false;
  confirmationMessage = '';
  selectedMessage: any;

  @Output() messagesLoaded = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private router: Router,
    private billingService: BillingService,
    private reminderService: ReminderService,
    private notify: NotifyService,
    private eventService: EventService

  ) {
    super();
  }

  ngOnInit() {
    this.getSubscribedProducts();
    this.queryForMessages();
    const frequency = 1000 * 60 * 10;

    setInterval(() => {
      this.queryForMessages(1, true);
    }, frequency);
  }

  queryForMessages(pageNum = this.messagePageNum, isPolling = false) {
    if(this.isCollections){
      this.subs.sink = this.userService
        .getCarolMessages(pageNum)
        .subscribe(resp => {
          this.messagesLoaded.emit(true);
          this.loadMessages(resp, isPolling);
        });
    }else {
      this.subs.sink = this.userService
        .getMessages(pageNum)
        .subscribe(resp => {
          this.messagesLoaded.emit(true);
          this.loadMessages(resp, isPolling);
        });
    }
  }

  loadMessages(messages: UserMessage[], isPolling) {
    if (isPolling) {
      messages.forEach(message => {
        if (!message.Sender) {
          message.Sender = this.demoMessageName;
        }
        if (message.Date_Read) {
          message.Date_Read = new Date(message.Date_Read.replace(/\s/, 'T'));
        }
        const fltMsgIndex = this.messageData.findIndex(msg => msg.Id === message.Id);
        if (fltMsgIndex === -1) {
          this.messageData.unshift(message);
        } else {
          this.messageData[fltMsgIndex] = message;
        }
      });
    } else {
      messages.forEach(message => {
        if (!message.Sender) {
          message.Sender = this.demoMessageName;
        }
        if (message.Date_Read) {
          message.Date_Read = new Date(message.Date_Read.replace(/\s/, 'T'));
        }
      });
      this.messageData = this.messageData.concat(messages);
    }
    this.checkUnreadmessageCount();
  }

  checkUnreadmessageCount() {
    const unreadMessages = this.messageData.filter(
      (x: any) => x.Date_Read == null,
    );
    // We need to update the date format for Safari browser
    unreadMessages.forEach((x: any) => {
      x.Created_At = moment(x.Created_At, 'MM-DD-YYYY HH:mm A').toDate();
      if (x.Date_Read) {
        x.Date_Read = moment(x.Date_Read, 'MM-DD-YYYY HH:mm A').toDate();
      }
    });
    this.unreadMessageCount = unreadMessages.length;
    if (this.unreadMessageCount > 0) {

      // now we need to see if the latest messages is newer than 7 days then make it green
      const lastDate = moment(unreadMessages[0].Created_At);
      const curDate = moment();

      if (curDate.diff(lastDate, 'days') <= 7) {
        this.buttonColor = 'primary';
      } else {
        this.buttonColor = '';
      }

    } else {
      this.buttonColor = '';
    }
  }

  flagAsRead(msg: any, e: any) {
    if (e) {
      e.stopPropagation();
    }
    // this.queryForMessages();
    msg.Date_Read = new Date();
    msg.ReadByUserFullName = localStorage.getItem('userName');
    this.checkUnreadmessageCount();
    if (!!this.messageData && this.messageData.length > 0) {
      const ids = [msg.Id]; // this.messageData
      if (ids.length > 0) {
        this.subs.sink = this.userService.markMessagesAsRead(ids).subscribe();
      }
    }
    this.messageData = this.messageData.filter(message => !message.Date_Read);
  }

  confirmAddDNC(e: any, msg: any) {
    this.confirmationMessage = 'Are you sure you want to add ' + this.formatPhoneNumber(msg.Number) + ' to the DNC list?';
    this.selectedMessage = msg;
    this.showDNCConfirmationPopup = true;
  }

  YesNoConfirmResult(e) {
    if (e) {
      this.addToDNC(e);
    }
  }

  addToDNC(e: any) {
    this.flagAsRead(this.selectedMessage, null);
    const exclusionData = {
      data : {
        Number: this.selectedMessage.Number,
        RequestedBy: this.selectedMessage.Sender,
      },
    };
    this.selectedMessage.DNC = true;
    this.subs.sink = this.reminderService.addReminderExclusion(exclusionData).subscribe();
  }

  formatPhoneNumber(phoneNumberString: any) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  getSubscribedProducts() {
    this.subs.sink = this.billingService.GetSubscribedProducts().subscribe(products => {
      // assumes here that user is either of bail or def mgr - may be other possibilities in the future
      this.subscribedProducts = products;
      if (products[AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM]) {
        this.isBail = true;
      } else if (products[AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER]) {
        this.isDefendantManager = true;
      } else if (products[AppConstants.PRODUCT_NAME_COLLECTIONS]) {
        this.isCollections = true;
      }

      if (products[AppConstants.PRODUCT_NAME_TEXT_MESSAGING]) {
        this.isTextMessaging = true;
      }
    });

  }

  navDefendant(sourceId: any, message: any, type='Defendant') {
    this.flagAsRead(message, null);
    // If this is a demo message then redirect because there is no profile page.
    if (message.Sender === this.demoMessageName) {
      return;
    }
    // assumes here that user is either of bail or def mgr - may be other possibilities in the future
    if (this.subscribedProducts.DEFENDANTMANAGER) {
      const defMngrPath = 'defendantmanager/defendants';
      this.router.navigateByUrl(defMngrPath).then(success => {
        this.router.navigate([ defMngrPath ], { queryParams: { defendantId: sourceId, tab: 2 } });
      });
    } else if (this.subscribedProducts.BAILMANAGEMENT) {
      if(type === 'Prospect') {
        // Create base path
        const prospectPath = ['defendant', 'prospects'];
        // Create base query parameter
        const queryParamList = { queryParams: {} };
        if(message.MessageText.startsWith('Documents')) {
          queryParamList.queryParams = {tid: sourceId, source: 'document'};
        } else {
          queryParamList.queryParams = {tid: sourceId};
        }

        // Navigate to prsopect page
        this.router.navigate(prospectPath, queryParamList);
      } else if(type === 'MissedCheckin'){
        const defaultDefendantPath = ['defendant', sourceId, 'full'];
        const queryParamList = { queryParams: {} };
        queryParamList.queryParams = { refresh: 1 };
        this.router.navigate(defaultDefendantPath, queryParamList).then(() => {
            this.eventService.emitOpenCheckinHistory();
        });
      } else {
        // Create base path
        const defaultDefendantPath = ['defendant', sourceId, 'full'];
        // Create base query parameter
        const queryParamList = { queryParams: {} };

        if (message.MessageText.includes('eSign')) {
          queryParamList.queryParams = { anchor: 'documents' };
        } else {
          // Go to tab
          defaultDefendantPath.push('5');
          // Tell the def profile to refresh since we're not going through the preview page first
          queryParamList.queryParams = { refresh: 1 };
        }
        // Navigate to defendant page
        this.router.navigate(defaultDefendantPath, queryParamList);
      }

    } else if (this.subscribedProducts.COLLECTIONS) {
      this.router.navigate([`collections/accounts`, sourceId], {queryParams: {showmessage: true}});
    }
  }

  onScroll() {
    this.messagePageNum++;
    this.queryForMessages();
  }

  navigateToReminders() {
    const unreadMessages = this.messageData.filter(
      (x: any) => x.Date_Read == null,
    );
    const unreadMessagesIds = unreadMessages.map((msg) => {
      msg.Date_Read = new Date();
      msg.ReadByUserFullName = localStorage.getItem('userName');
      return msg.Id;
    });
    this.checkUnreadmessageCount();
    this.subs.sink = this.userService.markMessagesAsRead(unreadMessagesIds).subscribe();
    if (this.isBail) {
      this.router.navigate(['/adiv2/src/bail'], { queryParams: { index: 3, otherIndex: 3, reminderType: 'Reply' } });
    } else if (this.isDefendantManager) {
      this.router.navigate(['/defendantmanager/reminder-setting/defmngr'],
        { queryParams: { index: 2, otherIndex: 3, reminderType: 'Reply' } });
    }
  }

  onMenuOpened(event: any) {
    if (this.messageData.length === 0 && this.isBail) {
      this.subs.sink = this.billingService.GetSubscribedProducts().subscribe(response => {
        if (response && !response[AppConstants.PRODUCT_NAME_TEXT_MESSAGING]) {
          this.notify.customToast(UpsellComponent, 'success', {
            message: 'To subscribe to automated reminders for integrated texting',
            link: '/adiv2/src/bail',
          }, 6000);
        }
      });
    }
  }
}
