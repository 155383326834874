import { Component, EventEmitter, OnInit, Output, Inject, Input } from '@angular/core';
import { BillingService } from '../../services/billing.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { take } from 'rxjs/operators';
import { AppConstants } from '../../shared/AppConstants';
import { EventService } from '../../services/event.service';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from '../../components/controls/confirmation/confirmation.component';
import { NumberConfirmationComponent } from '../../components/controls/number-confirmation/number-confirmation.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ReminderService } from '../../services/reminder.service';
import { ReminderNumberBuyerComponent } from '../../views/reminder-number-buyer/reminder-number-buyer.component';
import { NotifyService } from '../../common/notify/notify.service';
import { SystemManagerService } from '../../services/systemmanager.service';
import { Store } from '@ngrx/store';
import { ICompany } from '../../models/icompany';
import { loadCompanys } from '../../state/actions/company.actions';
import { selectCompany } from '../../state/selectors/company.selectors';

@Component({
  selector: 'app-google-review-settings',
  templateUrl: './google-review-settings.component.html',
  styleUrls: ['./google-review-settings.component.scss'],
})
export class GoogleReviewSettingsComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Output() saveClicked: EventEmitter<boolean> = new EventEmitter();

  enabled: boolean;
  isBusy: boolean;
  isLoading: boolean;
  toggle: MatSlideToggleChange;
  reminderNumbers: any = [];
  placeId: string;
  company: ICompany;
  learnMoreLink = AppConstants.GOOGLE_REVIEW_LEARNMORE;

  constructor(
    private billingService: BillingService,
    private eventService: EventService,
    private dialog: MatDialog,
    private router: Router,
    private reminderService: ReminderService,
    private notifyService: NotifyService,
    private systemManagerService: SystemManagerService,
    private store: Store<{ company: ICompany }>,
  ) {
    super();
    this.enabled = false;
    this.isBusy = false;
  }

  ngOnInit(): void {
    const userId = localStorage.userId;
    this.store.dispatch(loadCompanys({ userId }));
    this.subs.sink = this.store.select(selectCompany).subscribe(company => {
      this.company = company;
    });
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_GOOGLE_REVIEW, false)
      .subscribe((subbed: boolean) => {
        this.enabled = subbed;
      });
      this.subs.sink = this.eventService.isBusy$.subscribe(item => {
        this.isLoading = item;
      });
  }

  onEnable(toggle: MatSlideToggleChange) {
    this.toggle = toggle;
    this.isBusy = true;
    this.eventService.componentBusy(true);
    // We need to handle enabling and disabling
    if (!this.enabled) {
      // Check that they have a credit card on file before enabling
      this.subs.sink = this.billingService.CustomerHasPaymentOnRecord().subscribe(paymentOnRecord => {
        if (!paymentOnRecord) {
          this.subs.sink = this.showConfirmation(
            'Enter Payment Information',
            `This is a premium service and it looks like we don't have a credit card on file for you. Enter payment information on the following screen and you'll be redirected back here.  Thanks!`,
          ).subscribe(confirm => {
            this.router.navigate(['/getstarted/checkout'],
              {
                queryParams: {
                  'returnUrl': this.router.routerState.snapshot.url,
                  'productids': AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
                },
              });
          });
        } else {
          this.subs.sink = this.showConfirmation(
            'Enable Google Reviews',
            `You are enabling Google Reviews.
            There is a per message cost for each message sent.`,
          ).subscribe(confirm => {
            if (confirm) {
              this.subs.sink = this.getReminderNumber()
                .pipe(take(1))
                .subscribe(hasNumber => {
                  if (hasNumber) {
                    this.subscribeToService();
                  } else {
                    const dialogRef = this.dialog.open(NumberConfirmationComponent, {
                      data: {
                        title: 'Enable Reminders',
                        message: `In order to subscribe to Google Reviews you
                                  need to select a reminder number.`,
                        component: ReminderNumberBuyerComponent,
                      },
                      maxWidth: '50vw',
                    });
                    this.subs.sink = dialogRef.afterClosed().subscribe(accept => {
                        if (accept) {
                          this.subscribeToService();
                        } else {
                          this.toggle.source.checked = false;
                          this.enabled = false;
                          this.eventService.componentBusy(false);
                        }
                    });
                  }
                });
            } else {
              this.toggle.source.checked = false;
              this.enabled = false;
              this.eventService.componentBusy(false);
            }
          });
        }
      });
    } else {
      this.subs.sink = this.showConfirmation(
        'Disable Google Reviews',
        `Are you sure you want to disable Google Reviews?`,
      ).subscribe(confirm => {
       if (confirm) {
         this.subs.sink = this.billingService.UnsubscribeCustomerFromService(AppConstants.PRODUCT_NAME_GOOGLE_REVIEW)
           .pipe(take(1))
           .subscribe(() => {
             this.eventService.componentBusy(false);
             this.enabled = false;
           });
       } else {
         this.toggle.source.checked = true;
         this.enabled = true;
         this.eventService.componentBusy(false);
       }
      });
    }
  }

  subscribeToService() {
    this.subs.sink = this.billingService.SubscribeCustomerToService(AppConstants.PRODUCT_NAME_GOOGLE_REVIEW)
      .subscribe(() => {
        this.enabled = true;
        this.notifyService.toast('You are now subscribed to Google Reviews', 'success');
        this.eventService.componentBusy(false);
      }, (error) => {
        this.enabled = false;
        this.eventService.componentBusy(false);
        this.notifyService.toast('Error subscribing to Google Reviews', 'error');
      });
  }

  showConfirmation(title: string, message: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        message: message,
        title: title,
      },
      maxWidth: '500px',
    });
    return dialogRef.afterClosed().pipe();
  }

  getReminderNumber(): Observable<boolean> {
    return new Observable(sub => {
      this.subs.sink = this.reminderService.getReminderNumbers().subscribe(res => {
        this.reminderNumbers = res.data;
        if (this.reminderNumbers && this.reminderNumbers.length === 0) {
          sub.next(false);
        } else {
          sub.next(true);
        }
      });
    });
  }

  saveSettings() {
    this.eventService.componentBusy(true);
    this.subs.sink = this.systemManagerService.saveCompany(this.company).subscribe(sc => {
      if (sc) {
        this.eventService.componentBusy(false);
        this.saveClicked.emit(this.enabled);
      }
    }, error => {
      this.eventService.componentBusy(false);
      this.notifyService.toast('Error saving settings', 'error');
    });
  }
}
