import { Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

export interface WarningDialogDataInterface {
  message: string;
  title: string;
  component?: ComponentType<Component>;
}

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
})
export class WarningDialogComponent implements OnInit, OnDestroy {

  selectedPortal: ComponentPortal<Component>;

  private element: HTMLElement;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WarningDialogDataInterface,
    private viewContainerRef: ViewContainerRef,
  ) {
    if (data.component) {
      this.selectedPortal = new ComponentPortal(data.component);
    }
  }

  public ngOnInit(): void {
    this.element = this.viewContainerRef.element.nativeElement;
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1503';
  }

  public ngOnDestroy(): void {
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1000';
  }

}
