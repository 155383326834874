import { BaseModel } from './BaseModel';

export class AutomaticNotificationSetting extends BaseModel {
  // tslint:disable:variable-name
  Id: any;
  CustomerId: any;
  Type: any;
  LastRun: any;
  StartTime: any;
  StopTime: any;
  CourtDateDays1: any;
  CourtDateDays2: any;
  PaymentReminderDays1: any;
  PaymentReminderdays2: any;
  PastDueDays: any;
  PastDueMinimumAmount: any;
  PastDueMaximumAmount: any;
  PastDueMinimumDaysPastDue: any;
  PastDueMaximumDaysPastDue: any;
  CreatedByUserId: any;
  Created_At: any;
  UpdatedByUserId: any;
  Updated_At: any;
  DeletedByUserId: any;
  Deleted_At: any;

  CourtDateEnabled: boolean;
  CourtDateIncludeAllNumbers: boolean;
  CourtDateIncludeIndemnitors: boolean;
  CourtDateSkipBadNumbers: boolean;
  CourtDateDay1Enabled: boolean;
  CourtDateDay2Enabled: boolean;
  CourtDateSkipWeekends: boolean;
  CourtDateAcceptReplies: boolean;
  CourtDateFilterReasons: any;

  PaymentReminderEnabled: boolean;
  PaymentReminderIncludeAllNumbers: boolean;
  PaymentReminderIncludeIndemnitors: boolean;
  PaymentReminderSkipBadNumbers: boolean;
  PaymentReminderDay1Enabled: boolean;
  PaymentReminderDay2Enabled: boolean;
  PaymentReminderSkipWeekends: boolean;
  PaymentReminderAcceptReplies: boolean;

  PaymentLinkEnabled: boolean;
  PaymentLinkCustomEnabled: boolean;
  PaymentLinkCustomUrl: string;
  PaymentLinkGateway: string;

  PastDueEnabled: boolean;
  PastDueIncludeAllNumbers: boolean;
  PastDueIncludeIndemnitors: boolean;
  PastDueSkipDuplicateNumbers: boolean;
  PaymentReminderSkipDuplicateNumbers: boolean;
  CourtDateSkipDuplicateNumbers: boolean;
  PastDueSkipBadNumbers: boolean;
  PastDueSkipWeekends: boolean;
  MissedCheckinEnabled: boolean;
  MissedCheckinIncludeAllNumbers: boolean;
  MissedCheckinIncludeIndemnitors: boolean;
  MissedCheckinSkipBadNumbers: boolean;
  MissedCheckinSkipWeekends: boolean;

  CheckinEnabled: boolean;
  CheckinGpsEnabled: boolean;
  CheckinIncludeAllNumbers: boolean;
  CheckinGpsIncludeAllNumbers: boolean;
  CheckInScheduleShowCourtInfo: boolean;
  CheckInScheduleShowAccounting: boolean;

  CheckinIncludeIndemnitors: boolean;
  CheckinSkipBadNumbers: boolean;
  CheckinSkipWeekends: boolean;
  CheckinAcceptReplies: boolean;

  AllowMessageToPlayWithoutKeyPress: boolean;

  CourtDateReminderDeliveryChannels: string;
  MissedCheckinReminderDeliveryChannels: string;
  PaymentReminderDeliveryChannels: string;
  CheckinReminderDeliveryChannels: string;
  CheckinReminderGpsDeliveryChannels: string;

  PastDueReminderDeliveryChannels: string;

  ReminderPhoneNumber: string;
  ApplicationLinkCustomUrl: string;
  ApplicationPaymentLinkCustomEnabled: boolean;
  ApplicationPaymentLinkGateway: number;

  constructor(obj?: object) {
    super(obj);

    this.CourtDateEnabled = false;
    this.CourtDateIncludeAllNumbers = false;
    this.CourtDateIncludeIndemnitors = false;
    this.CourtDateSkipBadNumbers = false;
    this.CourtDateDay1Enabled = false;
    this.CourtDateDay2Enabled = false;
    this.CourtDateSkipWeekends = false;
    this.CourtDateFilterReasons = null;
    this.PaymentReminderEnabled = false;
    this.PaymentReminderIncludeAllNumbers = false;
    this.PaymentReminderIncludeIndemnitors = false;
    this.PaymentReminderSkipBadNumbers = false;
    this.PaymentReminderDay1Enabled = false;
    this.PaymentReminderDay2Enabled = false;
    this.PaymentReminderSkipWeekends = false;
    this.PastDueEnabled = false;
    this.PastDueIncludeAllNumbers = false;
    this.PastDueIncludeIndemnitors = false;
    this.PastDueSkipDuplicateNumbers = false;
    this.PaymentReminderSkipDuplicateNumbers = false;
    this.CourtDateSkipDuplicateNumbers = false;
    this.PastDueSkipBadNumbers = false;
    this.PastDueSkipWeekends = false;
    this.MissedCheckinEnabled = false;
    this.MissedCheckinIncludeAllNumbers = false;
    this.MissedCheckinIncludeIndemnitors = false;
    this.MissedCheckinSkipBadNumbers = false;
    this.MissedCheckinSkipWeekends = false;
    this.AllowMessageToPlayWithoutKeyPress = false;

    this.CheckinAcceptReplies = false;
    this.CourtDateAcceptReplies = false;
    this.PaymentReminderAcceptReplies = false;

  }

  CheckAllSkipWeekends()
  {
    let tot =  0 + (this.MissedCheckinSkipWeekends ? 1 : 0) +
      (this.PastDueSkipWeekends ? 1 : 0 ) +
      (this.CourtDateSkipWeekends ? 1 : 0) +
      (this.PaymentReminderSkipWeekends ? 1 : 0) ;

    if(tot >= 4)
    {
      return true;
    }
    if(tot === 0)
    {
      return false;
    }

    return undefined;
  }

  SetAllSkipWeekends(e)
  {
    this.MissedCheckinSkipWeekends  = e.value;
    this.PastDueSkipWeekends  = e.value;
    this.CourtDateSkipWeekends  = e.value;
    this.PaymentReminderSkipWeekends  = e.value;
  }

  SetAllSkipBadNumbers(e)
  {
    this.MissedCheckinSkipBadNumbers  = e.value;
    this.PastDueSkipBadNumbers  = e.value;
    this.CourtDateSkipBadNumbers  = e.value;
    this.PaymentReminderSkipBadNumbers  = e.value;
  }

  SetAllIncludeIndemnitors(e)
  {
    this.MissedCheckinIncludeIndemnitors  = e.value;
    this.PastDueIncludeIndemnitors  = e.value;
    this.CourtDateIncludeIndemnitors  = e.value;
    this.PaymentReminderIncludeIndemnitors  = e.value;
  }

  SetAllIncludeAllNumbers(e)
  {
    this.MissedCheckinIncludeAllNumbers  = e.value;
    this.PaymentReminderIncludeAllNumbers  = e.value;
    this.CourtDateIncludeAllNumbers  = e.value;
    this.PastDueIncludeAllNumbers  = e.value;
  }

  SetAllCallsEnabled(e)
  {
    this.MissedCheckinEnabled  = e.value;
    this.PastDueEnabled  = e.value;
    this.CourtDateEnabled  = e.value;
    this.PaymentReminderEnabled  = e.value;
  }

  CheckAllSkipBadNumbers()
  {
    let tot =  0 + (this.MissedCheckinSkipBadNumbers ? 1 : 0) +
      (this.PastDueSkipBadNumbers ? 1 : 0 ) +
      (this.CourtDateSkipBadNumbers ? 1 : 0) +
      (this.PaymentReminderSkipBadNumbers ? 1 : 0) ;

    if(tot >= 4)
    {
      return true;
    }
    if(tot === 0)
    {
      return false;
    }

    return undefined;
  }

  CheckAllIncludeIndemnitors()
  {
    let tot =  0 + (this.MissedCheckinIncludeIndemnitors ? 1 : 0) +
      (this.PastDueIncludeIndemnitors ? 1 : 0 ) +
      (this.CourtDateIncludeIndemnitors ? 1 : 0) +
      (this.PaymentReminderIncludeIndemnitors ? 1 : 0) ;

    if(tot >= 4)
    {
      return true;
    }
    if(tot === 0)
    {
      return false;
    }

    return undefined;
  }

  CheckAllIncludeAllNumbers()
  {
    let tot =  0 + (this.MissedCheckinIncludeAllNumbers ? 1 : 0) +
      (this.PaymentReminderIncludeAllNumbers ? 1 : 0 ) +
      (this.CourtDateIncludeAllNumbers ? 1 : 0) +
      (this.PastDueIncludeAllNumbers ? 1 : 0) ;

    if(tot >= 4)
    {
      return true;
    }
    if(tot === 0)
    {
      return false;
    }

    return undefined;
  }

  CheckAllCallsEnabled()
  {
    let tot =  0 + (this.MissedCheckinEnabled ? 1 : 0) +
      (this.PastDueEnabled ? 1 : 0 ) +
      (this.CourtDateEnabled ? 1 : 0) +
      (this.PaymentReminderEnabled ? 1 : 0) ;

    if(tot >= 4)
    {
      return true;
    }
    if(tot === 0)
    {
      return false;
    }

    return undefined;
  }

  CheckAllDay1Enabled()
  {
    let tot =  0 + (this.CourtDateDay1Enabled ? 1 : 0) +
      (this.PaymentReminderDay1Enabled ? 1 : 0 );

    if(tot >= 2)
    {
      return true;
    }
    if(tot === 0)
    {
      return false;
    }

    return undefined;
  }

  CheckAllDay2Enabled()
  {
    let tot =  0 + (this.CourtDateDay2Enabled ? 1 : 0) +
      (this.PaymentReminderDay2Enabled ? 1 : 0 );

    if(tot >= 4)
    {
      return true;
    }
    if(tot === 0)
    {
      return false;
    }

    return undefined;
  }

}
