import {map, catchError} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { ApiUtilities } from '../shared/ApiUtilities';
import { Note } from '../models/note';
import { PortoResponse } from '../models/porto-response';

@Injectable()
export class NotesService {

  constructor(private http: HttpClient,
    private settings: SettingsService) {
  }

  createTransactionNote(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/notes`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  createBulkTransactionNote(data: Note[]): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/bulknotes`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  createBulkNotesByFilter(note: {NoteStamp: string,
    Subject: string,
    Text: string,
    SourceType: string}, params: HttpParams): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/bulknotes/filtered`, JSON.stringify(note), {params}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  updateTransactionNote(data: any, id: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/notes/${id}`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  deleteNote(id: string): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/notes/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  deleteTransactionNote(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/notes/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  createNoteByDefendantId(data: any){
    return this.http.post(`${this.settings.getBaseUrl()}/notesbydefendant`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getNotesByDefendantId(id: string): Observable<Note[]> {
    return this.http.get<PortoResponse<Note[]>>(`${this.settings.getBaseUrl()}/defendant/notes/${id}?include=user`).pipe(
      map((res: PortoResponse<Note[]>) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

}


