import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debug } from '../../../state/reducers';

@Directive({
  selector: '[appCustomExpansionToggle]',
})
export class CustomExpansionToggleDirective implements AfterViewInit, OnDestroy {

  private elem: HTMLElement;
  private sub: Subscription;

  constructor(private elRef: ElementRef) { }

  ngAfterViewInit(): void {
    this.elem = this.elRef.nativeElement.querySelector('.mat-expansion-indicator');

    this.sub = fromEvent(this.elem, 'animationend').subscribe(() => {
      this.elem.classList.remove('openAnimate');
      this.elem.classList.remove('closeAnimate');
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  @HostListener('opened')
  private onOpen() {
    this.elem.classList.add('openAnimate');
  }

  @HostListener('closed')
  private onClose() {
    this.elem.classList.add('closeAnimate');
  }

}
