import { from, Observable, of, throwError } from 'rxjs';

import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { CacheService } from './cache.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import * as sanitize from 'sanitize-filename';
import { PortoResponse } from '../models/porto-response';
import { LaravelResponse } from '../models/laravel-response';
import * as XLSX from 'xlsx';
import { writeFile } from 'xlsx';
import { PaginationService } from './pagination.service';
import { MatDialog } from '@angular/material/dialog';
import { ExportDialogComponent, ExportType } from '../components/controls/export-dialog/export-dialog.component';

@Injectable()
export class GridService {

  private postData: any;
  private apiResponseTimeToCache = 30; // (in seconds)


  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private cache: CacheService,
    private paginationService: PaginationService,
    private dialog: MatDialog,
  ) {
  }

  getGrid(viewname: string): Observable<any> {
    const key = `${viewname}/grid/all?limit=0`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const data = this.cache.getGrid(viewname, key);

    if (!!data) {
      return of(JSON.parse(data)); // Return cache data as observables
    } else { // Data not found in localStorage get it from API and set back to localStorage
      const responseData = this.http.get(url).pipe(
        map((res: any) => res),
        catchError(error => throwError(error.error || 'Server Error')));
      return responseData;
    }
  }

  getGridAsPromise(viewname: string, includes?: string): Promise<any> {
    // console.log('Getting data in getGridAsPromise for viewname: ', viewname);
    const key = `${viewname}/grid/all?limit=0${includes !== undefined ? '&' + includes : ''}`;
    const keyTotalCount = key + 'totalCount';
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const data = this.cache.getGrid(viewname, key);
    const totalCount = this.cache.get(keyTotalCount);
    const self = this;
    // console.log(url, 'gridAsPromise URL');

    if (!!data) {
      const responseData = {
        data: JSON.parse(data),
        totalCount: totalCount || data.length,
      };
      return of(responseData).toPromise(); // Return cache data as observables
    } else { // Data not found in localStorage get it from API and set back to localStorage
      return this.http.get(url).toPromise<any>().then(response => {
        return {
          data: response.data,
          totalCount: totalCount,
        }
      }).catch(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error'));
    }
  }

  getPaginatedGrid(viewName: string, params: HttpParams): Observable<any> {
    // console.log('Getting data in getGridAsPromise for viewname: ', viewname);
    const key = `${viewName}/grid/all`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    return this.http.get(url, {params}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true)))
    );
  }

  exportGridPaginated(id: string, filterData: any, params: HttpParams): Observable<any> {
    const postData = {filters: filterData};
    const key: any = `grid/report/paginated/export/${id}`;
    const url: any = `${this.settings.getBaseUrl()}/${key}`;
    return this.http.put(url, postData, { params, responseType: 'arraybuffer' });
  }

  exportPaginatedGrid(viewName: string, params: HttpParams): Observable<any> {
    const key = `${viewName}/grid/export`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    params = params.set('limit', '0');
    return this.http.get(url, {params, responseType: 'arraybuffer'});
  }

  getGridReport(id: string, filterData: any): Observable<any> {
    // console.log('In grid service function: getGridReport: refresh: ', id, filterData);
    const postData = {filters: filterData};
    const key: any = `grid/report/data/${id}`;
    const url: any = `${this.settings.getBaseUrl()}/${key}`;
    const self: any = this;

    const apicallTime = Date.now();

    if (!!id) {
      const responseData: Observable<any> = this.http.put(url, postData).pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
      return responseData;
    } else {
      const resp = {
        data: [],
      };
      return of(resp);
    }
  }

  getSummary(id: string, params: HttpParams, filters: any[]) {
    const key: any = `grid/summary/${id}`;
    const postData = {filters};
    const url: any = `${this.settings.getBaseUrl()}/${key}`;
    return this.http.post(url, postData, {params}).pipe(
      map( res => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),
    );
  }

  getGridReportPaginated(id: string, filterData: any, params: HttpParams): Observable<LaravelResponse<any>> {
    const postData = {filters: filterData};
    const key: any = `grid/report/paginated/data/${id}`;
    const url: any = `${this.settings.getBaseUrl()}/${key}`;
    if (!!id) {
      const responseData: Observable<LaravelResponse<any>> = this.http.put(url, postData, { params }).pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
      return responseData;
    } else {
      const resp = {
        current_page: 0,
        data: [],
        first_page_url: null,
        from: 0,
        last_page: 0,
        last_page_url: null,
        next_page_url: null,
        path: null,
        per_page: null,
        prev_page_url: null,
        to: 0,
        total: 0,
      };
      return of(resp);
    }
  }


  // Conditional data store to cache if API responded later then apiResponseTimeToCache
  setConditionalCache(startTime: any, endTime: any, cacheKey: any, data: any) {
    const timeDifference: number = (endTime - startTime) / 1000;
    // console.log('Api call start time: ', startTime, ' api end time: ', endTime, ' total time taken: ', timeDifference);
    if (timeDifference > this.apiResponseTimeToCache) {
      // console.log('Report API responded after ' + this.apiResponseTimeToCache + ', seconds so storing data into cache!');
      this.cache.set(cacheKey, JSON.stringify(data));
    } else {
      // console.log('Report API responded before ' + this.apiResponseTimeToCache + ', seconds so not storing data into cache!');
    }
  }

  getGridReport2(terms: Observable<any>, id: string, filterData: any) {
    return terms.pipe(debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.getGrid2Entries(id, filterData)));
  }

  getGrid2Entries(id: string, filterData: any) {
    const postData = {
      filters: filterData,
    };

    return this.http.put(`${this.settings.getBaseUrl()}/grid/report/data/${id}`, postData).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }


  getReportingGridList(limitByPermissions = false): Observable<any> {
    const key = `grids/list/all?limit=0`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const self: any = this;
    return from(this.cache.getFromIDB(key)).pipe(switchMap(data => {
      if (limitByPermissions) {
        return this.getReportPermissionsForCurrentUser().pipe(
          switchMap((perm) => {
            if (!!data) {
              const gridsToReturn: any[] = [];
              for (const grid of data.data) {
                if (perm.data.find((p: any) => p.ObjectId === grid.Id && p.ObjectType === 'Grid Report')) {
                  gridsToReturn.push(grid);
                }
              }
              return of({ 'data': gridsToReturn }); // Return cache data as observables
            } else { // Data not found in localStorage get it from API and set back to localStorage
              return this.http.get(url).pipe(
                map((grids: any) => {
                  const gridsToReturn: any[] = [];
                  for (const grid of grids.data) {
                    if (perm.data.find((p: any) => p.ObjectId === grid.Id && p.ObjectType === 'Grid Report')) {
                      gridsToReturn.push(grid);
                    }
                  }
                  return {
                    'data': gridsToReturn,
                  };
                }),
                catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
            }
          }),
        );
      } else {
        if (!!data) {
          return of(data); // Return cache data as observables
        } else { // Data not found in localStorage get it from API and set back to localStorage
          const response = this.http.get(url).pipe(
            map((res: any) => res),
            catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
          return response;
        }
      }
    }));
  }

  getGridSettings(id: string, expiry = false): Promise<any> {
    const key = `grid/report/settings/${id}`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const self = this;
    return this.cache.getFromIDB(key).then(data => {
      if (!!data) {
        console.log('Response from cache data! getGridSettings', key, data);
        return data; // Return cache data as observables
      } else { // Data not found in localStorage get it from API and set back to localStorage
        return this.http.get(url)
          .toPromise()
          .then(response => {
            self.cache.set(key, JSON.stringify(response), expiry);
            return response;
          })
          .catch(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error'));
      }
    });
  }

  getGridLookupData(reportId: string, id: string, search: string): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/grid/report/lookups/data/${reportId}/${id}/${search}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));

  }


  getEmails(viewname: string, id: any): Promise<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/${viewname}/emails/${id}`)
      .toPromise<any>()
      .then(response => {
        return response;
      })
      .catch(error => {
        throw new Error('Data Loading Error');
      });
  }


  updateGridRow(viewname: string, key: any, data: any): Promise<any> {
    // console.log('Updating from updateGridRow for viewname: ', viewname, ' key: ', key);
    const postData = {
      view: viewname,
      key: key,
      data: data,
    };
    // this.cache.removeByViewName(viewname);
    this.cache.removeallLookup();
    return this.http.patch(`${this.settings.getBaseUrl()}/${viewname}/${key}`, postData).toPromise<any>();
  }


  insertGridRow(viewname: string, data: any): Promise<any> {
    // console.log('Creating grid row from insertGridRow for viewname: ', viewname);
    const postData = {
      view: viewname,
      data: data,
    };
    // this.cache.removeByViewName(viewname);
    this.cache.removeallLookup();
    try {
      return this.http.post(`${this.settings.getBaseUrl()}/${viewname}`, postData).toPromise();
    } catch (e) {
      return throwError(e).toPromise();
    }
  }

  deleteGridRow(viewname: string, key: any, replaceWithId?: any): Promise<any> {
    let params = '';
    if (replaceWithId) {
      params = `?replaceWithId=${replaceWithId}`;
    }
    return this.http.delete(`${this.settings.getBaseUrl()}/${viewname}/${key}${params}`).toPromise<any>();
  }

  exportGrid(gridInstance: any, exportFileName: any) {
    const exportDialog = this.dialog.open(ExportDialogComponent);
    const sub = exportDialog.afterClosed().subscribe((type: ExportType | null) => {
      if (!!type) {
        switch (type) {
          case ExportType.EXCEL:
            gridInstance.export.fileName = sanitize(exportFileName);
            gridInstance.instance.exportToExcel(false);
            break;
          case ExportType.CSV:
            this.exportAsCsv(gridInstance, exportFileName);
            break;
        }
      }
      sub.unsubscribe();
    });
  }

  exportAsCsv(gridInstance: any, exportFileName: any) {
    const columns = gridInstance.columns.map(col => col.name);
    const exportData = gridInstance.instance.getDataSource().items().map(obj =>
      columns.reduce((acc, prop) =>
        obj.hasOwnProperty(prop) ? { ...acc, [prop]: obj[prop] } : acc, {}),
    );
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const csv = XLSX.utils.sheet_to_csv(ws);
    writeFile(wb, `${exportFileName}.csv`);
  }

  updateGridIcons({cellElement, data, rowType, column, row} ) {
    if (rowType === 'data' && column.command === 'edit') {
      const links = cellElement.querySelectorAll('.dx-link');

      links.forEach(link => {
        link.textContent = '';





        link.classList.forEach(item => {
          if (item === 'dx-link-save') {
            link.classList.remove('dx-icon-save');
            link.classList.add('fal', 'fa-save');
          } else if (item === 'dx-link-cancel') {
            link.classList.remove('dx-icon-revert');
            link.classList.add('fal', 'fa-undo');
          }
          if (item === 'dx-link-undelete') {
            link.classList.remove('dx-icon-undo');
            link.classList.add('fal', 'fa-undo');
          }
          if (item === 'dx-link-delete') {
            link.classList.remove('dx-icon-trash');
            link.classList.add('fal', 'fa-trash');
          }
          if (item === 'dx-link-edit') {
            link.classList.remove('dx-icon-edit');
            link.classList.add('fal', 'fa-pen');
          }
        });
      });
    }
  }

  hideEditAndDeleteForSystemItems(e: any) {
    if (e.rowType === 'data' && e.column.command === 'edit') {

      if (!e.data.CustomerId) {
        let editElement = e.cellElement.querySelector('.dx-link-edit');
        editElement.parentNode.removeChild(editElement);
        let deleteElement = e.cellElement.querySelector('.dx-link-delete');
        deleteElement.parentNode.removeChild(deleteElement);
      }
    }
  }

  getRelatedResourceCount(viewname: string, id: any) {
    return this.http.get(`${this.settings.getBaseUrl()}/${viewname}/relatedcount/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }


  getReportPermissionsForUserOrGroup(id: any, type: any): Observable<any> {
    const key = `reportpermissions/${type}/${id}`;
    const cache = this.cache.get(key);
    if (!!cache) {
      return of(JSON.parse(cache));
    } else {
      return this.http.get<PortoResponse<any>>(`${this.settings.getBaseUrl()}/${key}`).pipe(
        map(res => {
          this.cache.set(key, JSON.stringify(res));
          return res;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
    }
  }

  getReportPermissionsForCurrentUser(): Observable<any> {
    const userId = localStorage.getItem('userId');
    return this.getReportPermissionsForUserOrGroup(userId, 'user');
  }
}
