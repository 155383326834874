import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CreditCardGatewayService } from '../../../services/creditcardgateways.service';
import { BillingService } from '../../../services/billing.service';
import { AppConstants } from '../../../shared/AppConstants';
import { EventService } from '../../../services/event.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { SystemConfigService } from '../../../services/systemconfig.service';
import { SystemConfig} from '../../../models/systemconfig';
import {CustomerService} from '../../../services/customer.service';
import { PeerToPeerLink } from './../../../models/PeerToPeerLink';

@Component({
  selector: 'app-nuvei-registration-banner',
  templateUrl: './gateway-registration-banner.component.html',
  styleUrls: ['./gateway-registration-banner.component.scss'],
  animations: [
    trigger('registrationBannerTrigger', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('250ms', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0%)' }),
        animate('150ms', style({ transform: 'translateY(-100%)' })),
      ]),
    ]),
  ],
})
export class GatewayRegistrationBannerComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  gatewayId: any;
  isGatewayDetailsLoaded = false;
  isPeerToPeerDetailsLoaded = false;
  userName: string | null;
  gatewayRegistrationLink: string;
  systemConfig: SystemConfig;
  isReminderPaused = false;
  hasPeerToPeer = false;

  constructor(
    private creditCardGatewayService: CreditCardGatewayService,
    private billingService: BillingService,
    private eventService: EventService,
    private systemConfigService: SystemConfigService,
    private customerService: CustomerService,
  ) {
    super();
    this.gatewayRegistrationLink = AppConstants.GATEWAY_REGISTRATION_LINK;
    // console.log('GatewayRegistrationBannerComponent');
    this.subs.sink = this.billingService.GetSubscribedProducts().subscribe(products => {
      const isCollectionSubscribed =
        products && products[AppConstants.PRODUCT_NAME_COLLECTIONS];

      if (isCollectionSubscribed) {
        this.loadSystemConfig();
        this.loadGatewayDetails();
        this.loadPeerToPeerDetails();
      }
    });
  }

  ngOnInit() {
    this.subs.sink = this.eventService.pauseRemindersUpdated$.subscribe(res => {
      if(res) { 
        this.loadSystemConfig();
      }
    });

    this.subs.sink = this.eventService.paymentGatewayUpdated$.subscribe((res) => {
      if (res) {
        this.loadGatewayDetails();
        this.loadPeerToPeerDetails();
      }
    }); 
  }

  loadSystemConfig() {
    this.subs.sink = this.systemConfigService.get().subscribe(res => {
      this.systemConfig = res.data[0];
      if(this.systemConfig.PauseReminders) {
        this.isReminderPaused = true;
      } else {
        this.isReminderPaused = false;
      }
    }, err => {
      console.log(err);
      this.isReminderPaused = false;
    }); 
  }

  loadGatewayDetails() {
    // EM - refactored this. Now we have more than once gateway, what we really want to find out is which gateway the client has setup and loaded.
    // no need to query the master gateway for this.
    this.subs.sink = this.creditCardGatewayService
      .getClientDefaultGatewayExists()
      .subscribe(res => {
        this.isGatewayDetailsLoaded = true;
        const name = localStorage.getItem('userName');
        this.userName = name.split(' ')[0];
        if (res.data && res.data.length > 0) {
          this.gatewayId = res.data[0].Id;
        }
      });
  }

  loadPeerToPeerDetails() {
    this.subs.sink = this.customerService.getPeerToPeer().subscribe((res: PeerToPeerLink[]) => {
      this.isPeerToPeerDetailsLoaded = true;
      if(res.length > 0) {
        this.hasPeerToPeer = true;
      }
    });
  }
}
