import { catchError, map } from 'rxjs/operators';
import { combineLatest, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { SettingsService } from './settings.service';
import { WhiteLabelService } from './white-label.service';
import { isNullOrUndefined } from 'util';
import { Moment } from 'moment';


export interface QuickLinkRequest {
  ResourceName: string;
  StartDate: Moment;
  EndDate: Moment;
  AgencyIds: string;
  AgentIds: string;
  SuretyIds: string;
  MinDays: number;
  MaxDays: number;
  MinBalance: number;
  MaxBalance: number;
  CountOnly: boolean;
  SortByDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(private http: HttpClient,
              private settings: SettingsService,
              private whiteLabel: WhiteLabelService) {
  }

  getClassicDashboardData(request: QuickLinkRequest): Observable<any> {
    const query = this.getQueryString(request);
    // console.log(`2. query to ${this.settings.getBaseUrl()}/${ResourceName}${query}`);
    return this.http.get(`${this.settings.getBaseUrl()}/${request.ResourceName}${query}`).pipe(
      map((res: any) => {
        if (request.CountOnly) {
          if (!res) {
            return 0;
          }
          return res;
        } else {
          return res.data;
        }
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getClassicDashboardDataAsPromise(request: QuickLinkRequest): Promise<any> {
    const query = this.getQueryString(request);
    return this.http.get(`${this.settings.getBaseUrl()}/${request.ResourceName}${query}`).pipe(
      map((res: any) => {
        if (request.CountOnly) {
          if (!res) {
            return 0;
          }
          return res;
        } else {
          return res.data;
        }
      })).toPromise().then(response => {
        // console.log(response);
        return response;
      })
      .catch(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error'));
  }

  async GetDashboardDataAsPromise(ResourceName): Promise<any> {
    // // console.log(`1. query to ${this.settings.getBaseUrl()}/${ResourceName}`);
    return this.http.get(`${this.settings.getBaseUrl()}/${ResourceName}`).pipe(
      map((res: any) => {
        return res.data;
      })).toPromise().then(response => {
        // // console.log(response);
        return response;
      })
      .catch(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error'));
  }

  // private GetDummyDataAsPromise(ResourceName): Promise<any> {
  //   const dashboardPaymentsByMonth = [{
  //     'MonthYear': 'Aug 2017',
  //     'DownPayment': 4500,
  //     'ArPayment': 4200
  //   },
  //     {
  //       'MonthYear': 'Sep 2017',
  //       'DownPayment': 3500,
  //       'ArPayment': 3200
  //     },
  //     {
  //       'MonthYear': 'Oct 2017',
  //       'DownPayment': 4300,
  //       'ArPayment': 4100
  //     },
  //     {
  //       'MonthYear': 'Nov 2017',
  //       'DownPayment': 4650,
  //       'ArPayment': 4400
  //     },
  //     {
  //       'MonthYear': 'Dec 2017',
  //       'DownPayment': 5100,
  //       'ArPayment': 3400
  //     },
  //     {
  //       'MonthYear': 'Jan 2018',
  //       'DownPayment': 5400,
  //       'ArPayment': 6100
  //     }
  //   ];
  //
  //   const dashboardOpenLiabilitySummary = [{
  //     'Total': 1756999,
  //     'Count': 6543
  //   }];
  //
  //   const dashboardOutstandingArSummary = [{
  //     'Total': 98653,
  //     'Count': 356
  //   }];
  //
  //   const dashboardBondsByAgent = [{
  //     'AgentName': 'Jason Strogg',
  //     'Amount': 45500,
  //     'Count': 12
  //   },
  //     {
  //       'AgentName': 'Bill Herman',
  //       'Amount': 233000,
  //       'Count': 8
  //     },
  //     {
  //       'AgentName': 'Casey Weir',
  //       'Amount': 27600,
  //       'Count': 5
  //     },
  //     {
  //       'AgentName': 'Arther Vant',
  //       'Amount': 171000,
  //       'Count': 17
  //     },
  //   ];
  //
  //
  //   let dataSet: any;
  //   switch (ResourceName) {
  //     case 'dashboardpaymentsbymonth':
  //       dataSet = dashboardPaymentsByMonth;
  //       break;
  //     case 'dashboardopenliabilitysummary':
  //       dataSet = dashboardOpenLiabilitySummary;
  //       break;
  //     case 'dashboardoutstandingarsummary':
  //       dataSet = dashboardOutstandingArSummary;
  //       break;
  //     case 'dashboardbondswrittenbyagent':
  //       dataSet = dashboardBondsByAgent;
  //       break;
  //   }
  //   return Observable.of(dataSet).toPromise().then(data => {
  //     // console.log(data);
  //     return data;
  //   })
  // }

  // loadCalendarItemsView() {
  //   // TODO: this really isn't the right way, should be CustomStore that can filter by date
  //   return this.http.get(`${this.settings.getBaseUrl()}/calendars?limit=0`)
  //     .map((res: any) => {
  //       //// console.log(`calendar result: ${res.data}`);
  //       const calendarItemsToReturn: CalendarItem[] = [];
  //       if (res.data !== null && res.data.length > 0) {
  //
  //         for (const item of res.data) {
  //           const calendarItem = new CalendarItem();
  //           calendarItem.startDate = new Date(item['EventDate']);
  //           calendarItem.endDate = new Date(item['EventDate']);
  //           calendarItem.allDay = (calendarItem.startDate.getHours() < 7);
  //           calendarItem.text = item['EventSubject'];
  //           calendarItem.itemType = item['EventType'];
  //           calendarItemsToReturn.push(calendarItem);
  //         }
  //       }
  //       return calendarItemsToReturn;
  //     })
  //     .catch(error => Observable.throw(ApiUtilities.getErrorMessage(error, true) || 'Server Error'));
  // }

  // Get news API
  GetNews(): Observable<any> {
    // get news with white label condition
    const getNews$ = this.http.get(`${this.settings.getBaseUrl()}/news`);
    const getWhiteLabel$ = this.whiteLabel.getWhiteLabelType();
    return combineLatest(getNews$, getWhiteLabel$, (news, whiteLabel) => {
      const newsToReturn = [];
      if (!isNullOrUndefined(whiteLabel)) { // need to verify this condition to be required or not
        for (let i = 0; i < news['data'].length; i++) {
          // // console.log(news['data'][i].WhiteLabelKey, ' && ', whiteLabel, (news['data'][i].WhiteLabelKey === whiteLabel));
          if (news['data'][i].WhiteLabelKey === whiteLabel) { // #1477 show news only if white label is present
            newsToReturn.push(news['data'][i]);
          }
        }
      }
      return newsToReturn;
    });
  }

  private getQueryString(request: QuickLinkRequest) {

    let result = `?StartDate=${encodeURIComponent(request.StartDate ? request.StartDate.format('YYYY-MM-DD') : '')}`;
    result = result.concat(`&EndDate=${encodeURIComponent(request.EndDate ? request.EndDate.format('YYYY-MM-DD') : '')}`);
    result = result.concat(`&AgencyId=${request.AgencyIds ? request.AgencyIds : ''}`);
    result = result.concat(`&AgentId=${request.AgentIds ? request.AgentIds : ''}`);
    result = result.concat(`&SuretyId=${request.SuretyIds ? request.SuretyIds : ''}`);
    result = result.concat(`&CountOnly=${request.CountOnly}`);
    result = result.concat(`&MinDays=${request.MinDays}`);
    result = result.concat(`&MaxDays=${request.MaxDays}`);
    result = result.concat(`&MinBalance=${request.MinBalance}`);
    result = result.concat(`&MaxBalance=${request.MaxBalance}`);
    if (!!request.SortByDate) {
      result = result.concat(`&SortByDate=${request.SortByDate}`);
    }
    return result;
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
