import { Injectable } from '@angular/core';
import { Defendant } from '../models/defendant';

@Injectable()
export class RouteDataService {
  public sourceData: any;
  public sourceId: any;

  constructor() {
  }

  setValue(key, value) {
    sessionStorage.setItem(key, value);
  }

  getValue(key) {
    return sessionStorage.getItem(key);
  }

  setObject(key, obj: any) {
    try {
      const value = JSON.stringify(obj);
      sessionStorage.setItem(key, value);
    } catch (exception) {
      this.clearSession();
      const value = JSON.stringify(obj);
      sessionStorage.setItem(key, value);
    }
  }

  getObject(key): any {
    const objString = sessionStorage.getItem(key);
    const obj = JSON.parse(objString);
    return obj;
  }

  buildDefShell(defendant: Defendant) {
    const defShell = {
      Id: defendant.Id,
      transactions: [],
    };
    for (const transaction of defendant.transactions) {
      const transShell = {Id: transaction.Id, bonds: [], contacts: [], collaterals: []};
      for (const bond of transaction.bonds) {
        transShell.bonds.push({Id: bond.Id});
      }
      for (const contact of transaction.contacts) {
        transShell.contacts.push({Id: contact.Id});
      }
      for (const collateral of transaction.collaterals) {
        transShell.collaterals.push({Id: collateral.Id});
      }
      defShell.transactions.push(transShell);
    }
    return defShell;
  }
  clearSession() {
    sessionStorage.clear();
  }
}
