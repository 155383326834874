import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Email } from '../../../models/email';
import Guid from 'devextreme/core/guid';
import { LookupService } from '../../../services/app-lookup.service';
import { DxDataGridComponent, DxTextBoxComponent } from 'devextreme-angular';
import { GridService } from '../../../services/grid.service';
import { fader } from '../../../app.animations';
import { Person } from '../../../models/person';
import { Phone } from '../../../models/phones';


@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [fader],
})
export class EmailListComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild('txEmailRef') txEmailRef: DxTextBoxComponent;

  @Input() model: Email[];
  @Output() modelChange = new EventEmitter<any>();
  @Output() emailChange = new EventEmitter<any>();
  @Input() readOnlyField = false;
  @Input() inputsDisabled = false;
  @Input() placeholderVisible = true;
  @Input() showPossibleEmail = false;
  @Input() possibleEmailLink: string;
  @Input() singleEmail = false;
  @Input() isValid = true;

  emailListPopup: any;
  emails: any;
  emailTypes: any;
  emailCount: number;
  validationErrors: any;
  validationEnabled = true;
  randomId =  new Guid().toString();

  _defaultEmail: string;
  isNewRow = true;

  get defaultEmail(): string {
    let filteredEmails;
    if (Array.isArray(this.emails)) {
      filteredEmails = this.emails.filter(item => !item.isDeleted);
    }

    if (filteredEmails && filteredEmails.length > 0) {
      this.emailCount = filteredEmails.length;
      const defaultEmails = filteredEmails.filter(item => item.IsDefault === true);

      if (defaultEmails.length > 0) {
        this._defaultEmail = defaultEmails[0].Email;
        return this._defaultEmail;
      }
    } else {
      this._defaultEmail = '';
      return this._defaultEmail;
    }
  }

  set defaultEmail(value: string) {
    if (value !== undefined && value !== '') {
      this._defaultEmail = value;
      this.updateDefaultEmail(value);
    } else {
      this._defaultEmail = '';
    }
    this.emailChange.emit(value);
  }

  onSaveFn: any;

  constructor(private lookupService: LookupService,
              private gridService: GridService) {
    this.emailCount = 0;
    this.emailTypes = lookupService.getLookupStatic('EmailTypes');
  }

  get emailInputValidState() {
    return this.txEmailRef.isValid;
  }

  get emailInputValue() {
    return this.defaultEmail;
  }

  // Delete the default number and remove the same from the default
  deleteAndRemoveFromDefault() {
    const defaultEmailIndex = this.getDefaultNumberIndex();
    if (defaultEmailIndex > -1) {
      this.emails[defaultEmailIndex].IsDefault = false;
      this.emails[defaultEmailIndex].isDeleted = true;
      this.emails[defaultEmailIndex].isDirty = true;
      this.filterActiveRows();
      this.updateEmailCount();
    }
  }

  // Get the index of default email from the emails array and return the index value
  getDefaultNumberIndex() {
    return this.emails.map(email => {
      return email.Email;
    }).indexOf(this.defaultEmail);
  }

  onFocusOut(e) {
    if (!this.txEmailRef.value.trim()) {
      this.deleteAndRemoveFromDefault();
    }
    // EM - man, what a silly workaround simply because empty string does not pass validation
    setTimeout(function () {
      const value = e.component.option('value');
      if (value !== undefined && !value.length) {
        e.component.option('isValid', true);
      }
    });
  }

  validateGrid() {
    // this.validationEnabled = false;
    // this.validationEnabled = true;
    this.dataGrid.instance.refresh();
    return true;
  }

  ngOnInit() {
    if (this.model == null) {
      this.model = [];
    }
    this.emails = this.model;
  }

  onValueChanged(e) {
    if (e.value === undefined || (e.value && e.value.length === 0)) {
      e.component.option('isValid', true);
    }
    this.emailChange.emit(e.value);
  }

  filterActiveRows() {
    if (this.dataGrid !== undefined) {
      this.dataGrid.instance.filter(['!', ['isDeleted', '=', true]]);
      this.dataGrid.instance.refresh();
    }
  }

  initNewEmail(newEmailAddress: string) {
    const email = new Email();
    email.Email = newEmailAddress;
    email.IsDefault = true;
    email.EmailType = 'P';
    email.isDeleted = false;
    email.isAdded = true;
    email.Id = new Guid().toString();
    return email;
  }

  setCellValue = (newData, value, currentRowData) => {
    // this.dataGrid.instance.saveEditData();
    if (!currentRowData.Email && Object.keys(newData).length) {
      newData.IsDefault = false;
      this.filterActiveRows();
      return true;
    }
    this.toggleDefault(currentRowData.Id);
    if (!!currentRowData.Id && value) {
      this.setDefaultEmail(currentRowData.Id);
    }
  }

  setDefaultEmail(id) {
    const email = []; // this.emails.filter(item => !item.isDeleted && item.Id === id);

    for (let i = 0; i < this.emails.length; i++) {
      if (this.emails[i].Id === id && !this.emails[i].isDeleted) {
        email.push(this.emails[i]);
      }
    }
    if (!!email && email.length > 0 && !!email[0].Email) {
      email[0].IsDefault = true;
    }
  }

  toggleDefault(newDefaultKey) {
    const filteredEmails = this.emails.filter(item => !item.isDeleted && item.IsDefault);
    if (filteredEmails.length > 0) {
      filteredEmails.forEach((o) => {
        o.IsDefault = false;
      });
    }
    this.filterActiveRows();
  }

  saveGrid(close) {
    if (this.validateGrid()) {
      this.dataGrid.instance.saveEditData();
      if (close) {
        this.setDefaultBeforeSaving();
        this.emailListPopup = false;
      }
    }
    this.emailChange.emit(this.emails);
  }

  resetDefaultEmail() {
    for (let i = 0; i < this.emails.length; i++) {
      this.emails[i].IsDefault = false;
    }
    this.filterActiveRows();
  }

  setDefaultBeforeSaving() {
    const fltEmails = this.emails.filter(email => email.IsDefault && !email.isDeleted);
    if (fltEmails.length > 0) {
      this.resetDefaultEmail();
      fltEmails[0].IsDefault = true;
    } else {
      const personal = this.emails.filter(email => email.EmailType === 'P' && !email.isDeleted)[0];
      if (!!personal) {
        personal.IsDefault = true;
      } else if (!!(this.emails.filter(email => email.EmailType === 'W' && !email.isDeleted)[0])) {
        this.emails.filter(email => email.EmailType === 'W' && !email.isDeleted)[0].IsDefault = true;
      } else if (!!(this.emails.filter(email => email.EmailType === 'O' && !email.isDeleted)[0])) {
        this.emails.filter(email => email.EmailType === 'O' && !email.isDeleted)[0].IsDefault = true;
      }
    }
  }

  addNewEmail() {
    this.emailListPopup = true;
  }

  updateDefaultEmail(email: string) {
    if (this.emails.length > 0) {
      const fileredEmails = this.emails.filter(item => item.IsDefault && !item.isDeleted);
      if (fileredEmails.length === 0) {
        this.emails.push(this.initNewEmail(email));
      } else {
        fileredEmails[0].Email = email;
        fileredEmails[0].isDirty = true;
      }
    } else {
      if (email !== undefined && email !== '') {
        this.emails.push(this.initNewEmail(email));
      }
    }
    this.filterActiveRows();
  }

  closePopup(navRoute) {  // Hide all Popup
    this.emailListPopup = false;
  }

  onCellPrepared(e) {
    this.gridService.updateGridIcons(e);
  }


  onInitNewRow(e) {
    // this.validationEnabled = false;
    const activeEmails = this.emails.filter(item => !item.isDeleted);
    const defaultEmails = activeEmails.filter(item => item.IsDefault);

    e.data.Id = new Guid().toString();
    e.data.Email = null;
    e.data.IsDefault = !!(defaultEmails.length === 0 && e.data.Email);
    e.data.IsDeleted = false;
    e.data.EmailType = 'P';
    e.data.isAdded = true;
    this.isNewRow = true;
    this.filterActiveRows();
  }

  onRowInserted(e) {
    this.filterActiveRows();
  }

  onEditingStart(e) {
    this.isNewRow = false;
  }

  onRowValidating(e) {
    const brokenRules = e.brokenRules;
    this.validationErrors = brokenRules.map(function (rule) {
      return rule.message;
    }).join(', ');
  }


  onRowInserting(e) {
    const newEmail = new Email(e.data);
    this.emails.push(newEmail);
    this.dataGrid.instance.cancelEditData();
    this.filterActiveRows();

    e.cancel = true;
  }

  setEmailValid() {
    setTimeout(() => {
      if (this.txEmailRef !== undefined) {
        this.txEmailRef.isValid = true;
      }
    });
  }

  updateEmailCount() {
    this.emailCount = this.emails.filter(item => !item.isDeleted).length;
  }

  onRowUpdating(e) {
    if (e.IsDefault === true) {
      // console.log('onRowUpdating', e);
      this.toggleDefault(e.key);
    }
    e.newData.isDirty = true;

    const activeEmails = this.emails.filter(item => !item.isDeleted);
    const defaultEmails = activeEmails.filter(item => !item.IsDefault);
    this.emailCount = activeEmails.length;

    if (defaultEmails.length === 0) {
      this._defaultEmail = '';
      this.setEmailValid();
    }

    this.filterActiveRows();

  }

  onRowRemoving(e) {
    e.data.isDeleted = true;
    e.data.isDirty = false;
    const searchResult = this.emails.find(item => item.Id === e.key);
    searchResult.isDeleted = true;
    const activeEmails = this.emails.filter(item => !item.isDeleted);
    const defaultEmails = activeEmails.filter(item => !item.IsDefault);
    this.emailCount = activeEmails.length;

    if (defaultEmails.length === 0) {
      this._defaultEmail = '';
      this.setEmailValid();
    }

    this.filterActiveRows();
    e.cancel = true;
  }

  resetForm() {
    if (this.emails != null) {
      this.emails.length = 0;
      this._defaultEmail = '';
      this.emailCount = 0;
      this.modelChange.emit(this.emails);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.currentValue !== undefined) {
      this.emails = changes.model.currentValue;
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach(function (item) {
        item.location = 'after';
        item.showText = 'always';
        if (item.name === 'addRowButton') {
          item.options.text = 'Add New Email';
          item.options.icon = 'fal fa-plus';
          item.options.type = 'default';
          item.location = 'before';
        }
      },
    );

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.gridService.exportGrid(this.dataGrid, 'Email');
          },
        },
      },
    );
  }

  customizeItem = (item) => {
    if ((item.dataField === 'IsDefault' || item.dataField === 'IsBad') && this.isNewRow) {
      item.visible = false;
    }
  }
}
