import {BaseModel} from './BaseModel';

export class PaymentScheduleDetail extends BaseModel {

  Id: number;
  PaymentScheduleId: number;
  DueDate: any;
  Amount: number;
  Archived: boolean;
  LastADICallDate: any;
  LastADICallResult: string;

  constructor(obj?: object) {
    super(obj);

    if (obj == null) {
      this.PaymentScheduleId = -1;
      this.DueDate = new Date();
      this.Amount = 0.0;
      this.Archived = false;
    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);
  }
}
