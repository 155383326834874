<dx-data-grid #dataGrid
              id="dataGrid"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [dataSource]="searchHistory"
              width="100%"
              rowAlternationEnabled="true"
              [columnAutoWidth]="true"
              (onToolbarPreparing)="onToolbarPreparing($event)"
              noDataText="No history found."
              [loadPanel]="{enabled: true, showIndicator: true, showPane: true, text: 'Loading...'}"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 20]"
    [showInfo]="true">
  </dxo-pager>
  <dxo-search-panel [visible]="true" placeholder="Search"></dxo-search-panel>
  <dxi-column dataField="QueueRequestStamp" dataType='date' caption="Date"></dxi-column>
  <dxi-column dataField="Subject" caption="Subject"></dxi-column>
  <dxi-column caption="Actions" name="Actions"
              cellTemplate="openHistoryTemplate">
    <div *dxTemplate="let data of 'openHistoryTemplate'">
      <a role="button" (click)="OpenHistory(data)">View Result</a>
    </div>
  </dxi-column>
</dx-data-grid>

<app-dynamic-popup [popupComponent]="dynamicPopupClass"
                   [popupComponentData]="dynamicPopupData"
                   [popupComponentTitle]="dynamicPopupTitle"
                   [popupComponentVisible]="dynamicPopupVisible"
                   [popupShowTitle]="dynamicPopupShowTitle"
                   [showCloseButton]="true"
                   [popupAllowCloseOnOutsideClick]='false'
                   (onPopupHidden)="onDynamicPopupHidden()"></app-dynamic-popup>

<!--For incarceration history search historic results-->
<dx-popup class="popup" [width]="'90%'" [height]="'90%'" [showTitle]="true" title="Arrest History Search Results"
          [dragEnabled]="false"
          [closeOnOutsideClick]="false" [(visible)]="incarcerationHistoryVisible">

  <div *dxTemplate="let data of 'content'">
    <app-incarceration-history-report #IncarcerationHistory [HistoricResultId]="incarcerationHistoryId"></app-incarceration-history-report>
  </div>
</dx-popup>
