
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from './settings.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accepts': 'application/json'
  })
};

@Injectable()
export class DefendantDataCaptureService {
  private hiddenFields: string[] = [];
  private commonFields: string[];
  private doNotIncludes: string[] = [];
  private employerData: any;
  private resetExpressFields = new Subject<boolean>();
  announceResetExpressFields$ = this.resetExpressFields.asObservable();
  private persistentFieldSource = new Subject<string[]>();
  private persistExistingDefendant = new Subject<boolean>();
  announcePersistExisting$ = this.persistExistingDefendant.asObservable();
  private receiveEmployerData = new Subject<any>();
  announceEmployerData$ = this.receiveEmployerData.asObservable();
  persistentFields$ = this.persistentFieldSource.asObservable();

  constructor(private http: HttpClient, private settings: SettingsService) {
    this.commonFields = [
      'defendant.person.Middle',
      'defendant.person.Alias',
      'defendant.person.Race',
      'defendant.person.Height',
      'defendant.person.Weight',
      'defendant.person.EyeColor',
      'defendant.person.HairColor',
      'defendant.person.Citizen',

      'defendant.person.Employer',

      'defendant.transactions[0].AgencyId'
    ];
  }

  addNewDefendantAnnouncement() {
    this.resetExpressFields.next(true);
  }

  addExistingDefendantAnnouncement(status: boolean = false) {
    this.persistExistingDefendant.next(status);
  }

  setPersonalEmployerData(data) {
    this.receiveEmployerData.next(data);
  }

  getPersonalEmployerData() {
    this.receiveEmployerData.next()
  }

  getCommonFields() {
    return this.commonFields;
  }

  addField(field: any) {
    this.hiddenFields.push(field);
    // localStorage.setItem(field, this.hiddenFields.join(','));
  }

  addDoNotInclude(include: string) {
    this.doNotIncludes.push(include);
  }

  getDoNotIncludes(): string[] {
    return this.doNotIncludes;
  }

  clearDoNotIncludes(): void {
    this.doNotIncludes = [];
  }

  getFields(): any {
    return this.hiddenFields ? this.hiddenFields : [];
  }

  getPersistentFields(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/datacaptures`).pipe(
      map((res: any) => res))
  }

  setPersistentField(fieldId: string) {

  }

}

