import {Injectable} from '@angular/core';

@Injectable()
export class FormatService {

  static formatPhone(value: any): string {
    // console.log('Phone format: ' + value);
    if (value === null || value === '') {
      return '';
    }
    switch (value.length) {
      case 7:
        return value.substr(3, 3) + '-' + value.substr(6, 4);
      case 10:
        return '(' + value.substr(0, 3) + ')' + value.substr(3, 3) + '-' + value.substr(6, 4)
      default:
        return value;
    }
  }
}
