import { take } from 'rxjs/operators';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { DxMultiViewComponent } from 'devextreme-angular';
import { CourtDate } from '../../../models/court-date';
import { LookupService } from '../../../services/app-lookup.service';
import { ObjectUtilities } from '../../../shared/ObjectUtilities';
import { FieldValues } from '../../../models/reportcompilationrequest';
import { PdfViewerComponent } from '../../../components/common/pdf-viewer/pdf-viewer.component';
import { StringUtilities } from '../../../shared/StringUtilities';
import notify from 'devextreme/ui/notify';
import devices from 'devextreme/core/devices';
import AppEditorLengths from '../../../shared/AppEditorLengths';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;

@Component({
  selector: 'app-courtdate',
  templateUrl: './courtdate.component.html',
  styleUrls: ['./courtdate.component.scss'],
  entryComponents: [PdfViewerComponent],
})
export class CourtDateComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(DxMultiViewComponent, { static: true }) courtView: DxMultiViewComponent;
  @Input() model: CourtDate[];
  @Input() transactionId: any;
  @Input() fieldsDisabled = false;
  @Input() setCourtId: any;
  @Input() county: any;
  @Input() setCourtRoomId: any;
  @Output() courtDateChanged = new EventEmitter();
  @Input() addNew = false;
  @Input() placeholderVisible = true;
  @Input() navigationAlign = '';

  courtDates: CourtDate[] = [];
  count: number;
  displayIndex: number;
  dynamicPopupClass: any;
  dynamicPopupData: any;
  dynamicPopupTitle: any;
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  contentReadyCalled = false;

  dateFormatter = StringUtilities.dateFormatter;
  dateTimeFormatter = StringUtilities.dateTimeFormatter;
  pickerType: string;
  courtRoomLength = AppEditorLengths.CourtRoom;
  howNotifiedLength = AppEditorLengths.CourtDateHowNotified;
  prevDisabled = true;
  nextDisabled = true;
  activeCourtCount = 0;

  constructor(
    private lookupService: LookupService,
  ) {
    super();

    const currentDevice = devices.current();
    if (currentDevice.phone && currentDevice.ios) {
      this.pickerType = 'rollers';
    } else {
      this.pickerType = 'calendar';
    }
    this.displayIndex = 0;

  }

  private isNullOrUndefined(value: null | undefined | any): boolean
  {
    return typeof value !== undefined && value !== null;
  }

  ngOnInit() {
    if (this.isNullOrUndefined(this.model)) {
      this.courtDates = this.model;
      this.displayIndex = this.courtDates.length > 0 ? 1 : 0;
      this.activeCourtCount = this.activeCourts().length;
      this.prevNextDisabled('prev');
      this.prevNextDisabled('next');
    }
  }

  ngAfterViewInit(): void {
    if (this.addNew) {
      this.addCourtDate();
    }
  }

  ngOnChanges() {
    if (!!this.model) {
      this.courtDates = this.model;
      this.displayIndex = this.courtDates.length > 0 ? 1 : 0;
      this.activeCourtCount = this.activeCourts().length;
      if(!!this.courtView && !!this.courtView.instance) {
        if(this.displayIndex === 0) {
          this.courtView.instance.option({'dataSource': this.activeCourts()});
        } else {
          this.courtView.instance.option('selectedIndex', 0);
        }
      }
      this.prevNextDisabled('prev');
      this.prevNextDisabled('next');
    } 
  }

  previousItem() {
    let curIndex: number = this.courtView.instance.option('selectedIndex');
    if (this.activeCourtCount === 0) {
      curIndex = 0;
      this.courtView.instance.option('selectedIndex', curIndex);
      this.displayIndex = curIndex;

    } else {
      curIndex--;

      if (curIndex > 0) {
        this.courtView.instance.option('selectedIndex', curIndex);
        this.displayIndex = curIndex + 1;
      } else {
        curIndex = 0;
        this.courtView.instance.option('selectedIndex', curIndex);
        this.displayIndex = curIndex + 1;
      }
    }
    this.prevNextDisabled('prev');
    this.prevNextDisabled('next');
  }

  nextItem() {
    let curIndex: number = this.courtView.instance.option('selectedIndex');
    if (this.activeCourtCount === 0) {
      curIndex = 0;
      this.courtView.instance.option('selectedIndex', curIndex);
      this.displayIndex = curIndex;
    } else {
      curIndex++;
      if (curIndex < this.activeCourtCount) {
        this.courtView.instance.option('selectedIndex', curIndex);
        this.displayIndex = curIndex + 1;
      } else {
        curIndex = this.activeCourtCount;
        this.courtView.instance.option('selectedIndex', curIndex);
        this.displayIndex = curIndex;
      }
    }
    this.prevNextDisabled('prev');
    this.prevNextDisabled('next');
  }

  onContentReady() {
    if (!this.contentReadyCalled) {
      this.contentReadyCalled = true;
    } else {
      this.showLast();
    }
  }

  showLast() {
    this.courtView.instance.option('selectedIndex', this.count - 1);
  }

  print(index: number) {
    if (this.courtDates.length > 0 && !isNullUndefinedOrEmpty(this.courtDates[index].Id) && !this.courtDates[index].isDeleted) {

      const fieldValue = new FieldValues();
      fieldValue.field = 'TransactionId';
      fieldValue.hashedValues = [this.transactionId];

      this.dynamicPopupData = [];
      this.dynamicPopupData['reportCategory'] = 'Court Date Reminder';
      this.dynamicPopupData['reportFieldValues'] = fieldValue;
      this.dynamicPopupData['objectTypeName'] = 'court date reminder';
      this.dynamicPopupData['sourceType'] = 'DefendantTransactions';
      this.dynamicPopupData['sourceId'] = this.transactionId;
      this.dynamicPopupData['objectName'] = 'Court Reminder ' + StringUtilities.getCurrentDateString();
      this.dynamicPopupClass = null;
      this.dynamicPopupData.esignable = false;
      this.dynamicPopupClass = PdfViewerComponent;
    } else {
      notify('Unsaved court date. You should save first.');
    }
  }

  onPopupHidden() {
    this.dynamicPopupClass = null;
  }

  // not touching this as removing this empty stub gives error from other components eg wizard-bond and bond components
  firstItem() {

  }

  addCourtDate() {
    const courtDate = new CourtDate();
    courtDate.isAdded = true;
    this.courtDates.push(courtDate);
    this.courtView.instance.option({'dataSource': this.activeCourts()});
    this.activeCourtCount = this.activeCourts().length;
    this.prevNextDisabled('next');
    this.nextItem();
  }

  clientNotifiedChanged(e, index: number) {
    if (e.value) {
      this.courtDates[index].NotifyDate = StringUtilities.getCurrentDateTimeString();
    }
    this.onCourtDateChange(e);
  }

  removeItem() {
    this.activeCourts()[this.displayIndex - 1].isDeleted = true;
    this.courtView.instance.option({'dataSource': this.activeCourts()});
    this.activeCourtCount = this.activeCourts().length;
    this.prevNextDisabled('prev');
    this.nextItem();
  }

  onCourtDateChange(e: any) {
    this.courtDateChanged.emit(e);
  }

  activeCourts() {
    if (this.courtDates.length > 0) {
      return this.courtDates.filter(item => !item.isDeleted);
    } else {
      return this.courtDates;
    }
  }

  prevNextDisabled(direction: string)
  {
    if (direction === 'prev') {
      if (this.displayIndex <= 1) {
        this.prevDisabled = true;
      } else {
        this.prevDisabled = false;
      }
    } else if (direction === 'next') {
      if (this.displayIndex >= this.activeCourts().length) {
        this.nextDisabled = true;
      } else {
        this.nextDisabled = false;
      }
    }
  }

}
