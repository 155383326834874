import {BaseModel} from './BaseModel';

export class CollectionReminderconfiguration extends BaseModel {

  Id: string;
  Enabled: boolean;
  NotificationTemplateId: string;
  NotificationTypeId: string;
  DeliveryInterval: number;
  SendText: boolean;
  SendEmail: boolean;
  SendVoice: boolean;
  SendPaymentOffer: boolean;
  PaymentOffersId: string;
  SendPaymentLink: boolean;
  CustomerId: string;
  Deleted_At: any;
  Created_At: any;
  Updated_At: any;
  CreatedByUserId: string;
  UpdatedByUserId: string;
  DeletedByUserId: string;
  PaymentOfferPercent = 0;

  constructor(obj?: object) {
    super(obj);
    if (obj == null) {
      this.Id = '';
    }
  }

  public loadData(data) {
    // console.log('In CollectionReminderconfiguration model', data);
    if (data != null) {
      this.fillFromObject(data, true);
      if (!!data.paymentoffers && !!data.paymentoffers.data) {
        this.PaymentOfferPercent = data.paymentoffers.data.Percent;
      }
    }
  }
}
