import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable()
export class SettingsService {
  private baseApi = environment.baseApi; // 'http://192.168.1.244:50100';
  private localbaseApi = 'http://localhost:3000' // Mock server ;
  private sentryDsn = environment.sentryDsn;

  constructor() {
  }

  getLoginUrl(): any {
    return `${this.baseApi}/clients/applications/login`
  }

  getRefreshUrl(): any {
    return `${this.baseApi}/clients/web/admin/refresh`
  }

  getPassportUrl(): any {
    return `${this.baseApi}/oauth/token`
  }

  getLogoutUrl(): any {
    return `${this.baseApi}/logout`
  }

  getBaseUrl(): any {
    return `${this.baseApi}`
  }

  getLocalUrl(): any {
    return `${this.localbaseApi}`
  }

  getPasswordForgotUrl(): any {
    return `${this.baseApi}/password/forgot`
  }

  getPasswordResetUrl(): any {
    return `${this.baseApi}/password/reset`
  }

  getSentryDsn(): any {
    return `${this.sentryDsn}`
  }
}
