
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import {FormItem} from '../models/form-item';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
@Injectable()
export class FormService {

  private postData: any;
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService) {}

  getForm(formName : string): Observable<any>
  {
    // return this.http.get(this.uri).map(this.mapdata).catch(this.error);
    return this.http.get(`${this.settings.getBaseUrl()}/${formName}`).pipe(map((res: any) => res));
  }



}
