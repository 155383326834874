import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonbarComponent } from './buttonbar.component';
import { DxButtonModule, DxContextMenuModule } from 'devextreme-angular';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [ButtonbarComponent],
  imports: [
    CommonModule,
    DxButtonModule,
    DxContextMenuModule,
    FlexLayoutModule,
  ],
  exports: [
    ButtonbarComponent,
  ],
})
export class ButtonbarModule { }
