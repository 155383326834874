import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdvancedSearchRequest, SearchService } from '../../../services/search.service';
import { NotifyService } from '../../../common/notify/notify.service';
import { StringUtilities } from '../../../shared/StringUtilities';
import devices from 'devextreme/core/devices';


@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit {
  @Output() closeSearch = new EventEmitter<boolean>();
  @Output() searchResults = new EventEmitter<any>();
  @Output() onLoading = new EventEmitter<boolean>();

  maxDate = new Date();
  dateFormatter = StringUtilities.dateFormatter;
  openDobPickerOnFocus = !devices.current().phone;

  loading = false;

  moduleOptions = [
    {
      name: 'Defendant',
      value: 'defendant',
    },
    {
      name: 'Contact',
      value: 'contact',
    },
    {
      name: 'Inventory',
      value: 'inventory',
    },
    {
      name: 'Payment',
      value: 'payment',
    },
  ];

  searchOptions = {
    defendant: [
      {
        name: 'Name',
        value: 'name',
      },
      {
        name: 'First Name',
        value: 'firstName',
      },
      {
        name: 'Last Name',
        value: 'lastName',
      },
      {
        name: 'Alias',
        value: 'alias',
      },
      {
        name: 'Dob',
        value: 'dob',
      },
      {
        name: 'Phone',
        value: 'phone',
      },
      {
        name: 'Employer',
        value: 'employer',
      },
      {
        name: 'Address',
        value: 'address',
      },
      {
        name: 'Transaction Identifier',
        value: 'transactionId',
      },
      {
        name: 'Booking Identifier',
        value: 'bookingId',
      },
      {
        name: 'Case Number',
        value: 'caseNumber',
      },
      {
        name: 'File Number',
        value: 'fileNumber',
      },
    ],
    contact: [
      {
        name: 'Name',
        value: 'name',
      },
      {
        name: 'First Name',
        value: 'firstName',
      },
      {
        name: 'Last Name',
        value: 'lastName',
      },
      {
        name: 'Alias',
        value: 'alias',
      },
      {
        name: 'Dob',
        value: 'dob',
      },
      {
        name: 'Phone',
        value: 'phone',
      },
      {
        name: 'Employer',
        value: 'employer',
      },
      {
        name: 'Address',
        value: 'address',
      },
    ],
    inventory: [
      {
        name: 'Power Number',
        value: 'powerNumber',
      },
      {
        name: 'Power Prefix',
        value: 'powerPrefix',
      },
      {
        name: 'Power Suffix',
        value: 'powerSuffix',
      },
    ],
    payment: [
      {
        name: 'Receipt Number',
        value: 'receiptNumber',
      },
    ],
  };

  searchRequest: AdvancedSearchRequest;

  constructor(
    private searchService: SearchService,
    private notify: NotifyService,
  ) {
    this.searchRequest = {
      searchModule: 'inventory',
      searchBy: 'powerNumber',
      searchValue: '',
    };
  }

  ngOnInit(): void {
  }

  handleSearch() {
    this.loading = true;
    this.onLoading.emit(true);
    if (!this.searchRequest.searchValue) {
      this.loading = false;
      return;
    }
    this.searchService.getAdvancedSearch(this.searchRequest).subscribe((results) => {
      // Todo: handle search results
      if (results.length === 0) {
        this.searchResults.emit([{
          Source: 'NORESULT',
          ErrorMessage: 'No Results Found.',
          GroupSource: '-1_NORESULT',
          ShowContextMenu: false,
        }]);
        this.onLoading.emit(false);
        this.closeSearch.emit(false);
      } else {
        const searchResults = [];
        for (const result of results) {
          result.Source = result.Source.toUpperCase();
          result.GroupSource = (result.Source === 'DEFENDANT' ? '0_' : '1_') + result.Source;
          result.ShowContextMenu = false;
          if (!result.ProfilePicture) {
            result.ProfilePicture = '/assets/img/defaultuser-flat.teal.svg';
          }
          result.ButtonId = 'button' + result.Source + result.Id;
          searchResults.push(result);
        }
        this.loading = false;
        this.onLoading.emit(false);
        this.searchResults.emit(searchResults);
        this.closeSearch.emit(false);
      }
    }, error => {
      this.loading = false;
      this.notify.toast('Error searching', 'error');
      this.onLoading.emit(false);
    });
  }

  handleModuleChange(module: 'defendant' | 'contact' | 'inventory' | 'payment') {
    this.searchRequest.searchBy = this.searchOptions[module][0].value;
  }

}
