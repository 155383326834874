<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="15px"
  *ngIf="userInTrial"
>
  <h4>This feature is not available in a trial account</h4>
  <button mat-raised-button color="primary" (click)="getStartedNow()">
    Get started now!
  </button>
</div>
<div *ngIf="!userInTrial">
  <div *ngIf="!userAgreedToTerms">
    <h4 *ngIf="!termsLoaded">Please wait...</h4>
    <div
      *ngIf="termsLoaded"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="15px"
    >
      <p>You must agree to terms of service to activate Defendant Watch</p>
      <dx-button type="default" text="View Terms" (click)="viewTerms()">
      </dx-button>
    </div>
  </div>

  <div *ngIf="userAgreedToTerms">
    <p *ngIf="usingBailManagementSystem && !watchBoth && !watchOnlyForf">
      Get started by checking 'Watch All Forfeitures' below.
    </p>

    <mat-card>
      <dx-tab-panel
        #tabPanel
        [dataSource]="this.tabs"
        [selectedIndex]="selectedTabIndex"
        [animationEnabled]="true"
        [swipeEnabled]="false"
        [showNavButtons]="true"
      >
        <div *dxTemplate="let tab of 'title'">
          <span><i [ngClass]="tab.icon" class="icon-spacer"></i>{{ tab.text }}</span>
        </div>
        <div *dxTemplate="let data of 'tabDefendant'">
          <div class="ibox-content tab-style">
            <div class="tab-with-height">
              <div class="row" *ngIf="usingBailManagementSystem" style="margin-top: 32px;">
                <div class="col-md-6">
                  <dx-check-box
                    [(value)]="watchBoth"
                    text="Watch All Forfeitures and $ Liability Above"
                    (onValueChanged)="liabAndForfCheckChanged($event)"
                  ></dx-check-box>
                </div>
                <div class="col-md-4">
                  <dx-number-box
                    format="#,##0"
                    [(value)]="settings.WatchLiabilityValue"
                  ></dx-number-box>
                </div>
              </div>
              <div class="row" *ngIf="usingBailManagementSystem">
                <div class="col-md-6">
                  <dx-check-box
                    text="Only Watch Forfeitures"
                    [(value)]="watchOnlyForf"
                    (onValueChanged)="onlyForfCheckChanged($event)"
                  ></dx-check-box>
                </div>
              </div>
              <dx-validation-group #emailValidationGroup >
                <div
                  *ngIf="!usingBailManagementSystem && !!settings.Source"
                  class="alert alert-warning"
                  role="alert"
                >
                  Click <a target="_blank" [href]="settings.RedirectURI">here</a> to be taken back to Bailbooks to manage your Defendant Watch rules
                </div>
              <div class="row" style="margin-top: 32px;">
                <div class="col-md-12">
                  <h5>Send real-time alerts to these emails:</h5>
                </div>
                <div class="col-md-12">

                  <dx-text-box
                    placeholder="sample@email.com, another@email.com"
                    [(value)]="settings.NotificationEmails"
                    validationGroup="emailValidationGroup">

                  <dx-validator #emailValidator
                  [elementAttr]="{class: 'hide-error'}">
                    <dxi-validation-rule
                      type="custom"
                      [validationCallback]="checkEmails"
                      [reevaluate]="true" >
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
                </div>

                <div class="col-md-12">
                  <small>Separate additional watch emails above with a comma</small>
                  <dx-validation-summary id="validationSummary" ></dx-validation-summary>
                </div>

              </div>
                <br>
                <div class="row">
                  <div class='col-xs-12'><a
                    *ngIf="usingBailManagementSystem"
                    role="button"
                    (click)="advancedPopup()"
                  >
                    Advanced Settings</a></div>
                </div>
            </dx-validation-group>
              <div class="row" style="margin-top: 32px;">
                <div class='col-xs-12'><a target="_blank" href="https://www.captira.com/pages/defendant-watch-now">Pricing, Coverage and More Information ⇀</a></div>

              </div>
              <div class="row">
                <div class="col-md-12">
                  <app-buttonbar
                    [routeKey]="'GroupsForm'"
                    #navButtonBar
                    [saveMethod]="onSave"
                    [otherActions]=''
                  ></app-buttonbar>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *dxTemplate="let data of 'tabAlerts'">
          <dx-data-grid
            #alertsGrid
            style="margin:15px"
            [dataSource]="alerts"
            (onToolbarPreparing)="onToolbarPreparingAlerts($event)"
          >
            <dxo-search-panel
              placeholder="Search"
              [visible]="true"
            ></dxo-search-panel>
            <dxi-column
              caption="Defendant"
              [calculateCellValue]="calcDef"
              cellTemplate="defendantNameTemplate">
              <div *dxTemplate="let data of 'defendantNameTemplate'">
                <a
                  role="button"
                  (click)="editDef(data.data)"
                  [hidden]="!isSubscribedToBMS"
                >{{ data.value }}</a>
                <span [hidden]="isSubscribedToBMS">{{ data.value }}</span>
              </div>
            </dxi-column>
            <dxi-column
              [visible]="usingBailManagementSystem"
              caption="Agent"
              dataField="AgentId"
              [hidingPriority]="2"
            >
              <dxo-lookup [dataSource]="agents" displayExpr="Text" valueExpr="Id"></dxo-lookup>
            </dxi-column>
            <dxi-column
              dataField="ResultStamp"
              caption="Result Date"
              [hidingPriority]="3"
            ></dxi-column>
            <dxi-column
              dataField="ResultEventType"
              caption="Event"
              [hidingPriority]="1"
            ></dxi-column>
            <dxi-column
              dataField="ResultDescription"
              width="350"
              caption="Description"
              [hidingPriority]="0"
            ></dxi-column>
            <dxi-column
              caption="Details"
              cellTemplate="detailsTemplate"
              [hidingPriority]="3">
              <div *dxTemplate="let data of 'detailsTemplate'">
                <a role="button" (click)="openDetails(data.data)">
                  View Details</a
                >
              </div>
            </dxi-column>

          </dx-data-grid>
        </div>

        <div *dxTemplate="let data of 'tabWatches'">
          <div
            *ngIf="!usingBailManagementSystem && !!settings.Source"
            class="alert alert-warning"
            role="alert"
          >
            Click <a target="_blank" [href]="settings.RedirectURI">here</a> to be taken back to Bailbooks to manage your Defendant Watch rules
          </div>
          <dx-data-grid
            #watchesGrid
            style="margin: 15px;"
            id="gridContainer2"
            [dataSource]="watches"
            (onToolbarPreparing)="onToolbarPreparingWatches($event)"
            [remoteOperations]="true"
          >
            <dxo-paging
              [pageSize]="10"
              [enabled]="true"
            ></dxo-paging>
            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[10, 20, 50, 100]"
              [showInfo]="true"
            ></dxo-pager>
            <dxo-search-panel
              placeholder="Search"
              [searchVisibleColumnsOnly]='true'
              [visible]="true"
            ></dxo-search-panel>
            <dxi-column
              dataField="real_id"
              caption="Sender Id"
              dataType='string'
              [visible]="isAdmin"
              [allowSearch]='false'
            ></dxi-column>
            <dxi-column
              dataField="WatchConfirmTransactionID"
              caption="Appriss Id"
              dataType='string'
              [visible]="isAdmin"
              [allowSearch]='false'
            ></dxi-column>
            <dxi-column
              dataField="DefendantFirstName"
              caption="First"
              [hidingPriority]="2"
            ></dxi-column>
            <dxi-column
              dataField="DefendantLastName"
              caption="Last"
            ></dxi-column>
            <dxi-column
              dataField="DefendantDOB"
              caption="DOB"
              dataType="date"
              [hidingPriority]="1"
            ></dxi-column>
            <dxi-column
              dataField="WatchConfirmStamp"
              caption="Watched Since"
              dataType="date"
              [hidingPriority]="0"
            ></dxi-column>
            <dxi-column
              caption="Actions"
              name="Actions"
              cellTemplate="clickToDeleteTemplate"
              [calculateCellValue]="calcRemoveCell">
              <div *dxTemplate="let data of 'clickToDeleteTemplate'">
                <button mat-button color='primary' (click)="editWatch(data.data)">
                  Edit Watch
                </button>
                <button mat-button color='primary' (click)="deleteWatch(data.data)">
                  {{ data.data.IncludeInDefendantWatch ? 'Remove Watch' : usingBailManagementSystem ? (data.data.RemovingFromDefendantWatch ? '' : 'Exonerate To Remove') : '' }}
                </button>
              </div>
            </dxi-column>

          </dx-data-grid>
        </div>

        <div *dxTemplate="let data of 'tabSearch'">
          <div style="margin: 15px">
            <app-datamart-history [searchType]="ReportType.Incarceration">
            </app-datamart-history>
          </div>
        </div>
      </dx-tab-panel>
    </mat-card>
  </div>

  <!-- Popup for advanced setting -->

  <dx-popup
    class="popup"
    [showTitle]="true"
    title="Advanced Settings"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [(visible)]="popupVisible"
    (onShown)="popupShownHandler($event)"
  >
    <div *dxTemplate="let data of 'content'">
      <div class="scrollview-demo">
        <dx-scroll-view style="height: 100%">
          <div class="light-timeline">
            <div class="vertical-timeline-block">
              <div class="vertical-timeline-content">
                <div class="row margin-tb">
                  <div class="col-md-12">
                    <dx-check-box
                      #checkBox
                      text="Filter Watches By Agency"
                      [(value)]="settings.FilterByAgency"
                    ></dx-check-box>
                  </div>
                </div>
                <hr/>
                <div class="row margin-tb" style="text-align: center;">
                  <dx-data-grid
                    #agenciesGrid
                    id="gridContainer"
                    [dataSource]="agencies"
                    [disabled]="!checkBox.value"
                    keyExpr="Id"
                    rowAlternationEnabled="true"
                    width="100%"
                  >
                    <dxo-scrolling mode="infinite"></dxo-scrolling>

                    <dxi-column caption="Id" [visible]="false" dataField="Id">
                    </dxi-column>

                    <dxi-column
                      caption="Agency Name"
                      [visible]="true"
                      dataField="Text"
                    ></dxi-column>

                    <dxo-search-panel placeholder="Search" [visible]="true">
                    </dxo-search-panel>
                    <dxo-selection
                      mode="multiple"
                      allowSelectAll="true"
                      [deferred]="false"
                      [selectedRowKeys]="settings.FilterAgencyIDs"
                    ></dxo-selection>
                  </dx-data-grid>
                </div>
              </div>
            </div>
            <div class="vertical-timeline-block">
              <div class="vertical-timeline-content">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <dx-button
                      type="default"
                      text="Close"
                      (click)="saveAgencyFilters()"
                    ></dx-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dx-scroll-view>
      </div>
    </div>
  </dx-popup>

  <!-- Popup for details -->

  <dx-popup
    class="popup"
    [showTitle]="true"
    title="Defendant Watch Result Details"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [(visible)]="detailsVisible"
    maxHeight="80vh"
    width="80%"
    maxWidth="960px"
  >
    <div *dxTemplate="let data of 'content'">
      <div class="scrollview-demo" style="height: 100%">
        <dx-scroll-view>
          <div class="light-timeline">
            <div class="vertical-timeline-block">
              <div class="vertical-timeline-content">
                <div>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="control-label">Defendant</label>
                    </div>
                    <div class="col-md-9">
                      {{
                      currentDetail.DefendantFirstName +
                      ' ' +
                      currentDetail.DefendantLastName
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="control-label">DOB</label>
                    </div>
                    <div class="col-md-9">
                      {{ currentDetail.DefendantDOB | date: 'shortDate' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="control-label">Result Date</label>
                    </div>
                    <div class="col-md-9">
                      {{ currentDetail.ResultStamp | date: 'short' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="control-label">Result Type</label>
                    </div>
                    <div class="col-md-9">
                      {{ currentDetail.ResultEventType }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="control-label">Facility</label>
                    </div>
                    <div class="col-md-9">
                      {{
                      currentDetail.ResultFacility +
                      ' ' +
                      currentDetail.ResultState +
                      ' ' +
                      currentDetail.ResultPhone
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <label class="control-label">Description</label>
                    </div>
                    <div class="col-md-9">
                      {{ currentDetail.ResultDescription }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <app-defendant-watch-results *ngIf='currentDetail.ResultXML' [alertData]='currentDetail.ResultXML'></app-defendant-watch-results>
            <br>
            <div class="vertical-timeline-block">
              <div class="vertical-timeline-content">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="button-bar-save-div"
                      style="text-align: center; margin: auto; float:none; width: auto"
                    >
                      <dx-button
                        type="default"
                        text="Close"
                        (click)="closeDetails()"
                      ></dx-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dx-scroll-view>
      </div>
    </div>
  </dx-popup>
</div>

<dx-popup
  class="popup"
  [showTitle]="true"
  title="Defendant Watch Terms and Conditions"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="agreementVisible"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <div class="light-timeline">
        <div class="vertical-timeline-block">
          <div class="vertical-timeline-content">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="button-bar-save-div"
                  style="text-align: center; margin: auto; float:none; width: auto"
                >
                  <dx-button
                    type="default"
                    text="Agree and Continue"
                    (click)="agreeToTerms()"
                  ></dx-button>
                </div>
              </div>
            </div>

            <div class="row margin-tb" [innerHtml]="agreementText"></div>
          </div>
        </div>
        <div class="vertical-timeline-block">
          <div class="vertical-timeline-content">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="button-bar-save-div"
                  style="text-align: center; margin: auto; float:none; width: auto"
                >
                  <dx-button
                    type="default"
                    text="Agree and Continue"
                    (click)="agreeToTerms()"
                  ></dx-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  class="popup"
  [showTitle]="true"
  title="{{ isEditing ? 'Edit Defendant Watch' : 'Add Defendant Watch' }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="addNewVisible"
  maxHeight='350px'
  width='50%'
  maxWidth='960px'
  (onHiding)="onHiding($event)"
  [formData]="defData"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view style="height: 100%">
      <dx-validation-group #valGroup>
        <div
          *ngIf="userInTrial"
          class="button-bar-save-div"
          style="text-align: center; margin: auto; float:none; width: auto"
        >
          <div style="margin: 30px">
            This feature is not available in a trial account.
            <button mat-raised-button color="primary" (click)="getStartedNow()">
              Get started now!
            </button>
          </div>
          <br/>
        </div>
        <div
          *ngIf="!usingBailManagementSystem && !!settings.Source"
          class="alert alert-warning"
          role="alert"
        >
          Click <a target="_blank" [href]="settings.RedirectURI">here</a> to be taken back to Bailbooks to manage your Defendant Watch rules
        </div>
        <div *ngIf="!userInTrial" class="light-timeline">
          <div class="vertical-timeline-block">
            <div class="vertical-timeline-content">
              <div class="row">
                <div class="form-group">
                  <label class="col-sm-3 control-label def-watch-label required">
                    First Name
                  </label>
                  <div class="col-sm-6">
                    <dx-text-box
                      [(value)]="defToAdd.First"
                      id="txFirstname"
                      [maxLength]="defToAdd.firstNameLength"
                    >
                      <dx-validator name="First Name">
                        <dxi-validation-rule
                          type="required"
                        ></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <label class="col-sm-3 control-label def-watch-label required">
                    Last Name
                  </label>
                  <div class="col-sm-6">
                    <dx-text-box
                      [(value)]="defToAdd.Last"
                      [maxLength]="defToAdd.lastNameLength"
                      id="xLastName"
                    >
                      <dx-validator name="Last Name">
                        <dxi-validation-rule
                          type="required"
                        ></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <label class="col-sm-3 control-label def-watch-label required">
                    DOB
                  </label>
                  <div class="col-sm-3">
                    <dx-date-box
                      type="date"
                      [(value)]="defToAdd.Dob"
                      invalidDateMessage="Please enter a valid date"
                      dateSerializationFormat="yyyy-MM-dd"
                      [displayFormat]="dateFormatter"
                    >
                      <dx-validator name="Date of Birth">
                        <dxi-validation-rule
                          type="required"
                        ></dxi-validation-rule>
                      </dx-validator>
                    </dx-date-box>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <label class="col-sm-3 control-label def-watch-label"
                  >Social Security Number</label
                  >
                  <div class="col-sm-3">
                    <app-ssn [(Ssn)]="defToAdd.Ssn" [placeholderVisible]="true"></app-ssn>
                  </div>
                </div>
              </div>
              <dx-validation-summary></dx-validation-summary>
            </div>
          </div>

          <div class="vertical-timeline-block">
            <div class="vertical-timeline-content">
              <div class="row">
                <div style='margin-top:16px;'>
                  <div
                    class="button-bar-save-div"
                    style="text-align: center; margin: auto; float:none; width: auto"
                  >
                    <dx-button
                      type="default"
                      text="Save"
                      [disabled]="!!settings.Source"
                      (click)="confirmAddWatch()"
                    ></dx-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dx-validation-group>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup class="popup" [showTitle]="true" title="Existing Records Found"
          [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" [(visible)]="showChoicePopup">
  <div *dxTemplate="let data of 'content'" fxLayout="column" fxLayoutAlign="space-between center">
    <h4>Choose an existing Watch to reactivate or create a new Defendant.</h4>
    <div fxFill class="defendant-list">
      <mat-selection-list [multiple]="false" (selectionChange)="selectedDefendant($event)">
        <mat-list-option *ngFor="let choice of choiceData" [value]="choice.DefendantId">
          <div mat-line class="mat-subheading-2">{{choice.Name}}</div>
          <div mat-line class="mat-body-1">{{choice.Dob}}</div>
          <div mat-line class="mat-body-1">{{choice.Ssn}}</div>
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="fixed-bottom">
      <div>
        <button mat-button color="primary" (click)="cancelReactivate()">Cancel</button>
      </div>
      <div fxLayoutGap="8px">
        <button mat-stroked-button [color]="defendantSelected ? 'primary' : ''" [disabled]="!defendantSelected" (click)="reactivateWatch(selectedDefendantId)">Reactivate</button>
        <button mat-raised-button color="primary" (click)="createNewWatch()">Create New</button>
      </div>
    </div>
  </div>
</dx-popup>

<app-importbond
  [inPopup]="true"
  [showImportPopupVar]="importPopupVisible"
  [forDefendantWatch]="true"
  (onImportPopupHidden)="onImportPopupHidden()">
</app-importbond>
