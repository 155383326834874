import { createAction, props } from '@ngrx/store';

export enum DefendantActionTypes {
  AddDefendant = '[Defendant] Add Defendant',
}

export const addDefendant = createAction(
  DefendantActionTypes.AddDefendant,
  props<{defendant: any, section: string}>(),
);
