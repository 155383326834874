<div class="tab-body-spacer">

    <mat-tab-group #tabGroup [selectedIndex]='defendant.transactions[transactionIndex].documents.length > 0 ? 0 : 1'>
      <mat-tab label='Active Forms'>
        <app-document
          [(model)]="defendant.transactions[transactionIndex].documents"
          [defendant]='defendant'
          [contacts]='contacts'
          [showUpload]="false"
        ></app-document>
      </mat-tab>
      <mat-tab label='New Forms'>
        <dx-validation-group #valGroup>
          <div class="row" *ngIf="userSelectableDefendant">
            <div class="col-md-12">
              <h4 style ='margin-top: 30px;' >Step 1: Defendant And A Posted Date</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-2 col-lg-4 col-md-5 col-sm-12">
              <div *ngIf="!userSelectableDefendant">
                <dx-text-box
                  [readOnly]="true"
                  [value]="defendantName"

                >

                </dx-text-box>
              </div>
              <div *ngIf="userSelectableDefendant">
                <app-searchbox
                  [selectMode]="true"
                  [defendantsOnly]="true"
                  (onRecordSelected)="onDefendantSelected($event)"
                  [placeHolderText]="'Enter Defendant Name to Print Forms'"
                  [disabled]="generateBlankForms"
                >
                </app-searchbox>
              </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-5 col-sm-12">
              <dx-select-box
                *ngIf="userSelectableDefendant"
                [dataSource]="transactionList"
                displayExpr="Text"
                valueExpr="Id"
                placeholder="Select Posted Date"
                [(value)]="transactionId"
                (onSelectionChanged)="onTransactionChanged($event)"
                [disabled]="generateBlankForms"
              >
              </dx-select-box>
            </div>

          </div>
          <div class="row">
<!--            <div class="col-md-4 col-sm-12" style="font-size: larger">-->
<!--              <dx-check-box-->
<!--                [(value)]="generateBlankForms"-->
<!--                text="generate blank forms"-->
<!--                (onValueChanged)="blankFormsChange($event)"-->
<!--              >-->
<!--              </dx-check-box>-->
<!--            </div>-->
          </div>
          <div class="col-md-12">
            <h4  style ='margin-top: 20px;' >Search Your Forms</h4>
          </div>

          <div class="row">

            <div>
              <button class='row-2' mat-button color="basic" (click)="animateOptions()"> or use a group of forms ⇀ </button>

              <div class="{{ optionsClass }}" style="width:70%;" id="select-box-row">
                <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutGap="15px">
                  <dx-select-box
                    fxFlex="46"
                    fxFlex.xs="100"
                    [@dropDownAnimation]="currentOptionsState"
                    displayExpr="Name"
                    valueExpr="Id"
                    [placeholder]="'Optional: Select form group'"
                    [(dataSource)]="formGroupSource"
                    [(value)]="formGroupValue"
                    (onValueChanged)="formGroupChanged($event)"
                    (mouseenter)="toggleTooltip()"
                    (mouseleave)="toggleTooltip()"
                    id="formGroupSelect">
                  </dx-select-box>

                  <button
                    *ngIf="inDynamicPopup"
                    [@dropDownAnimation]="currentOptionsState"
                    (click)="openFormGroupsPopup()"
                    mat-button
                    color="primary">
                    Manage Form Groups
                  </button>
                  <button
                    [@dropDownAnimation]="currentOptionsState"
                    mat-button
                    color="primary"
                    *ngIf="!inDynamicPopup"
                    (click)="goToFormGroups()"
                  >
                    Manage Form Groups
                  </button>
                </div>
              </div>
            </div>
          </div>


          <dx-data-grid
            #formsGrid
            [dataSource]="formsDataSource"
            keyExpr="ReportId"
            [rowAlternationEnabled]='true'
            (onSelectionChanged)="onSelectionChanged($event)"
            (onToolbarPreparing)='onToolbarPreparing($event)'
            [columnAutoWidth]="true"
            id="formsGrid"
          >
            <dxo-paging [pageSize]="15"></dxo-paging>
            <dxo-pager showPageSizeSelector="true"></dxo-pager>
            <dxo-search-panel [visible]="true" placeholder="Search"></dxo-search-panel>
            <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
            <dxi-column dataField="ReportName" caption="Name" cssClass="report-name"></dxi-column>
            <dxi-column dataField="ReportDescription" caption="Description" cssClass="report-description"></dxi-column>
            <dxi-column caption="Favorite" cssClass="report-favorite" cellTemplate="favoriteTemplate" width="100"
                        alignment='center'>
              <div *dxTemplate="let data of 'favoriteTemplate'">
                <app-report-favorite [report]="data.data"
                                     (changedFavorite)="formChangedAsFavorite($event)"></app-report-favorite>
              </div>
            </dxi-column>
          </dx-data-grid>

        </dx-validation-group>
      </mat-tab>
    </mat-tab-group>
</div>
<div class="btn-container">
  <button
    *ngIf='tabGroup.selectedIndex === 1'
    class="btn-sticky"
    mat-raised-button
    [color]="previewFormsDisabled ? undefined : 'primary'"
    [disabled]="previewFormsDisabled"
    (click)="onActionButtonClick()">
    {{actionButtonText}}
  </button>
  <button
    *ngIf='tabGroup.selectedIndex === 0'
    [disabled]="isDisabled"
    mat-raised-button
    color='primary'
    (click)='handleSave(defendant.transactions)'>
    Save
  </button>
</div>

<app-dynamic-popup
  [popupComponent]="dynamicPopupClass"
  [popupComponentData]="dynamicPopupData"
  [popupComponentTitle]="dynamicPopupTitle"
  [popupComponentVisible]="dynamicPopupVisible"
  [popupShowTitle]="dynamicPopupShowTitle"
  [formsPopupClass]='true'
  (onPopupHidden)="onDynamicPopupHidden()"
  maxHeight="100%"
  maxWidth="100%">
</app-dynamic-popup>

<dx-tooltip
  target="#formGroupSelect"
  [closeOnOutsideClick]="false"
  [visible]="tooltipVisible">
  <div *dxTemplate="let data = data of 'content'">
    <span [hidden]="formGroupSource.length == 0">Select form group</span>
    <span [hidden]="formGroupSource.length > 0">Nothing to Select <span *ngIf="!inDynamicPopup">: Manage groups on Main Tab above</span></span>
  </div>
</dx-tooltip>

