import { from, Observable, of, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { FileUtilities } from '../../../shared/FileUtilities';
import { IDynamicPopupComponent } from '../dynamic-popup/dynamic-popup.component';
import { Document } from '../../../models/document';
import { FieldValues, ReportCompilationRequest } from '../../../models/reportcompilationrequest';
import { DocumentService } from '../../../services/document.service';
import { RouteDataService } from '../../../services/routedata.service';
import { ReportService } from '../../../services/report.service';

import * as pdfjsBuild from '../../../../../vendor/pdfjs-dist/webpack.js';
import * as pdfjsWeb from '../../../../../vendor/pdfjs-dist/web/pdf_viewer.js';

import { StringUtilities } from '../../../shared/StringUtilities';
import { AppConstants } from '../../../shared/AppConstants';
import { PdfBindRequest, PdfField } from '../../../models/pdfbindrequest';
import { EventService } from '../../../services/event.service';
import { PopupService } from '../../../services/popup.service';
import { REPORT_POPOUT_DATA, ReportPopoutData } from '../../../services/popout.tokens';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../../common/notify/notify.service';
import { Router } from '@angular/router';
import { Share } from '../../../models/share';
import { WarningDialogComponent } from '../../controls/warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PdfSignatureImageBindRequest } from '../../../models/pdfsignatureimagebindrequest';
import { Contact } from '../../../models/contact';
import { Defendant } from '../../../models/defendant';
import { Person } from '../../../models/person';
import { SharesService } from '../../../services/shares.service';
import { DocumentAuditService } from '../../../services/document-audit.service';
import { ConfirmationComponent } from '../../controls/confirmation/confirmation.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SignedDocumentAudit } from '../../../models/document-audit';


const PDFJS = {...pdfjsBuild, ...pdfjsWeb};
const DEFAULT_SCALE = 1.0;
const MIN_SCALE = 0.1;
const MAX_SCALE = 10.0;
const DEFAULT_ROTATION = 0;
const CSS_UNITS = 96.0 / 72.0;
const PRINT_RESOLUTION = 150;
const PRINT_UNITS = PRINT_RESOLUTION / 72.0;

const annotationType = {
  TEXT: 1,
  LINK: 2,
  FREETEXT: 3,
  LINE: 4,
  SQUARE: 5,
  CIRCLE: 6,
  POLYGON: 7,
  POLYLINE: 8,
  HIGHLIGHT: 9,
  UNDERLINE: 10,
  SQUIGGLY: 11,
  STRIKEOUT: 12,
  STAMP: 13,
  CARET: 14,
  INK: 15,
  POPUP: 16,
  FILEATTACHMENT: 17,
  SOUND: 18,
  MOVIE: 19,
  WIDGET: 20,
  SCREEN: 21,
  PRINTERMARK: 22,
  TRAPNET: 23,
  WATERMARK: 24,
  THREED: 25,
  REDACT: 26,
};

export const confirmActionType = {
  BINDANDDOWNLOAD: 1,
  BINDANDATTACH: 2,
  BINDANDPRINT: 3,
  BINDANDESIGN: 4,
  BINDSIGNATUREIMAGE: 5,
};

export const postLoadActionType = {
  NONE: 1,
  DOWNLOAD: 2,
  ATTACH: 3,
  PRINT: 4,
  ATTACHFORESIGN: 5,
  GETSIGNATURE: 6,
  AUDIT: 7,
  SENDFORESIGN: 8,
};

export enum PostLoadAction {
  NONE = 1,
  DOWNLOAD = 2,
  ATTACH = 3,
  PRINT = 4,
  ATTACHFORESIGN = 5,
  GETSIGNATURE = 6,
}

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent extends UnsubscribeOnDestroyAdapter implements IDynamicPopupComponent, OnChanges, OnInit, OnDestroy {

  @ViewChild('observableSelectBox', {static: true}) observableSelectBox!: DxSelectBoxComponent;
  @ViewChild('pdfViewerContainer', {static: true}) pdfViewerContainer: any;
  @ViewChild('pdfViewer', {static: true}) pdfViewerElement: ElementRef;

  @Input() allowEsign = false;
  @Input() esignEnabled = false;
  @Input() dynamicData: any;
  @Input() pdfSrc!: string | Uint8Array;
  @Input() isPublic = false;
  @Input() isDocumentViewer = false;
  @Input() allowEdits = false;
  @Input() publicInfo: Document;
  @Input() contacts: Contact[] = [];
  @Input() defendant: Defendant;
  @Input() pdfDownloadObservable$: Observable<any>;
  @Input() isPopup: boolean;

  @Output() onClose: Subject<boolean>;
  @Output() onEsignButtonClick = new EventEmitter<any>();
  @Output() onEsignSent = new EventEmitter<boolean>();
  @Output() onPdfLoaded = new EventEmitter<any>();
  @Output() pageRendered = new EventEmitter<any>();
  @Output() documentLoaded = new EventEmitter<any>();
  @Output() onScroll = new EventEmitter<Event>();
  @Output() onChangesSaved = new EventEmitter<boolean>();

  fileDownloading: boolean;
  dynamicLoadingHeight: any;
  fileDownloadError: boolean;
  errorMessage = '';
  showErrorMessage: boolean;
  showBlankButton: boolean;

  pdfDownloadObservables$: Array<PdfObservableItem>;
  reportCategory: string;
  reportFieldValues: FieldValues[] | FieldValues;
  objectTypeName = '';
  objectName = '';
  sourceType = '';
  sourceId = '';
  associatedRecord: boolean;
  attachingToRecord: boolean;
  multipleObservables: boolean;
  categoricalReportList: boolean;

  title = 'PDF Viewer';

  currentPageNumber!: number;
  totalPageNumber!: number;
  currentScale: number;
  currentRotation: number;

  pdfLoaded: boolean;
  pdfLoading: boolean;
  pdf: any;
  pdfViewer: any;
  pdfEventBus: any;
  pdfFindController: any;
  pdfPrintService: any;

  documentInfo: any;
  metadata: any;
  contentDispositionFilename: any;

  viewerPopupVisible = false;
  viewerPopupMessage = '';
  popupProgressMin = 0;
  popupProgressMax = 1;
  popupProgressValue = 0;

  confirming = false;
  confirmationTitle = 'confirm';
  confirmationMessage = 'confirm';
  confirmAction = 0;
  postLoadAction: number = postLoadActionType.NONE;

  isSmartFormPDF = false;
  inDynamicPopup = false;
  base64String = '';
  pdfPurpose: any;
  esignXCoordinate: any;
  esignYCoordinate: any;
  esignPageNo: any;
  coordinateData: any;
  transformElement: any;
  scrollPosition: any;
  esignX: any;
  esignY: any;
  esignXPercent: any;
  esignYPercent: any;

  maximizeIcon = 'fal fa-expand-alt';
  isWindowMaximized = false;
  esignAfterAttachPopupVisible = false;
  esignDocumentId = '';
  esignIsBusy = false;
  esignSharesPopupVisible = false;
  esignShares: Share[] = [];
  esignSigningRoles: string[] = [];
  roles: SignerRole[] = [];

  isMobile = false;
  validSourceTypes: any = [
    {value: 'DefendantTransactions', text: 'defendant'},
    {value: 'CollectionAccounts', text: 'debtor'},
    {value: 'CollectionInvoiceClaims', text: 'dispute'},
  ];
  reportIds: any[];
  isSendingEsign = false;

  constructor(
    @Inject(REPORT_POPOUT_DATA) public popoutData: ReportPopoutData,
    private element: ElementRef,
    private documentService: DocumentService,
    private routeDataService: RouteDataService,
    public reportService: ReportService,
    private eventService: EventService,
    private popupService: PopupService,
    private notifyService: NotifyService,
    private router: Router,
    private shareService: SharesService,
    private dialog: MatDialog,
    private auditService: DocumentAuditService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.isMobile = breakpointObserver.isMatched(Breakpoints.XSmall);
    this.fileDownloading = false; // start with status indicator
    this.fileDownloadError = false; // and no error
    this.showErrorMessage = false;
    this.showBlankButton = false;
    this.onClose = new Subject<boolean>();
    this.pdfLoaded = false;
    this.pdfLoading = true;
    this.associatedRecord = false;
    this.isPopup = false;
    this.attachingToRecord = false;
    this.multipleObservables = false;
    this.categoricalReportList = false;
    this.reportCategory = '';
    this.reportFieldValues = null;
    this.currentScale = DEFAULT_SCALE;
    if (this.isMobile) {
      this.currentScale = 0.512;
    }
    this.currentRotation = DEFAULT_ROTATION;
    this.runBlankForm = this.runBlankForm.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
    this.esignSaveSharesAndSend = this.esignSaveSharesAndSend.bind(this);
    this.attachDocAndSend = this.attachDocAndSend.bind(this);
    console.log('popoutData', popoutData);

    document.addEventListener('keydown', this.captureTabEvent);
  }

  ngOnInit() {
    // id is set to -1 using the default factory, meaning this wasn't intentionally injected
    if (this.popoutData && this.popoutData.id !== -1) {
      this.pdfDownloadObservable$ = this.popoutData.reportObservable;
      this.pdfDownloadObservables$ = this.popoutData.pdfDownloadObservables;
      this.reportCategory = this.popoutData.reportCategory;
      this.reportFieldValues = this.popoutData.reportFieldValues;
      this.objectTypeName = this.popoutData.objectTypeName;
      this.objectName = this.popoutData.objectName;
      this.sourceType = this.popoutData.sourceType;
      this.sourceId = this.popoutData.sourceId;
      this.isSmartFormPDF = this.popoutData.isSmartFormPDF;
      this.base64String = this.popoutData.PDFBase64String;
      // for validate drag & drop element for esigning purpose and in case of code has been handled !
      this.pdfPurpose = this.popoutData.pdfPurpose ? this.popoutData.pdfPurpose : null;

      this.coordinateData = this.popoutData.coordinateData;
      // Set the spinner loader height and update on resize
      this.setLoaderHeight();
      window.addEventListener('resize', event => {
        this.setLoaderHeight();
      });
    } else if (this.dynamicData) {
      // Wait for dynamic import resolution
      // const PDF = await importPDF;
      // new PDF(this.dynamicData);
      // launched in dynamic popup
      this.isPopup = true;
      if (this.isWindowMaximized) {
        this.popupService.toggleMaximizePopup(this.isWindowMaximized);
      }
      this.pdfDownloadObservable$ = this.dynamicData['pdfObservable'];
      this.pdfDownloadObservables$ = this.dynamicData['pdfObservableItems'];
      this.esignEnabled = this.dynamicData['esignable'];
      this.reportCategory = this.dynamicData['reportCategory'];
      this.reportFieldValues = this.dynamicData['reportFieldValues'];
      this.objectTypeName = this.dynamicData['objectTypeName'];
      this.objectName = this.dynamicData['objectName'];
      this.sourceType = this.dynamicData['sourceType'];
      this.sourceId = this.dynamicData['sourceId'];
      this.isSmartFormPDF = this.dynamicData['isSmartFormPDF'];
      this.base64String = this.dynamicData['PDFBase64String'];
      this.defendant = this.dynamicData['defendant'];
      this.contacts = this.dynamicData['contacts'];
      this.reportIds = this.dynamicData.reportIds;
      // for validate drag & drop element for esigning purpose and in case of code has been handled !
      this.pdfPurpose = this.dynamicData.pdfPurpose ? this.dynamicData.pdfPurpose : null;

      this.coordinateData = this.dynamicData.coordinateData;

      // Set the spinner loader height and update on resize
      this.setLoaderHeight();
      window.addEventListener('resize', event => {
        this.setLoaderHeight();
      });
    } else {
      if (!!this.pdfSrc) {
        this.loadPdf();
      }
    }

    if (this.pdfPurpose && this.pdfPurpose === 'eSign Position') { // Process only if the variable has a value

      if (!!this.coordinateData) {
        this.esignXPercent = this.coordinateData[2] ? Math.round(this.coordinateData[2]) : 0;
        this.esignYPercent = this.coordinateData[3] ? Math.round(this.coordinateData[3]) : 0;
        const pdfViewerElement = this.pdfViewerElement.nativeElement;
        if (this.coordinateData[0] > pdfViewerElement.clientWidth) {
          this.esignXCoordinate = Math.round(
            ((this.coordinateData[2] * pdfViewerElement.clientWidth) / 100) -
            ((((this.coordinateData[0] - 816) / 2) - ((pdfViewerElement.clientWidth - 816) / 2)) / 4));
        } else if (this.coordinateData[0] < pdfViewerElement.clientWidth) {
          this.esignXCoordinate = Math.round(
            ((this.coordinateData[2] * pdfViewerElement.clientWidth) / 100) +
            ((((pdfViewerElement.clientWidth - 816) / 2) - ((this.coordinateData[0] - 816) / 2)) / 4));
        } else {
          this.esignXCoordinate = Math.round(((this.coordinateData[2] * pdfViewerElement.clientWidth) / 100));
        }
        this.esignYCoordinate = Math.round(this.coordinateData[3]);
        this.esignPageNo = this.coordinateData[4];
        const self = this;
        const pdfViewerContainerElement = this.pdfViewerContainer.nativeElement;
        pdfViewerContainerElement.onwheel = function () {
          pdfViewerContainerElement.scrollTop = self.coordinateData[5];
        };
      }
    }

    if (!this.objectTypeName) {
      this.objectTypeName = 'report';
    }

    if (this.reportCategory) {
      if (!this.reportFieldValues) {
        console.error('pdf-viewer reportCategory but no reportFieldValues provided.');
      }
      this.categoricalReportList = true;
    }

    if (this.sourceId && this.sourceType) {
      this.associatedRecord = true;
    }

    if (this.pdfDownloadObservables$ && this.pdfDownloadObservables$.length > 1) {
      this.multipleObservables = true;
    }

    if (!this.pdfDownloadObservable$) {
      if (this.pdfDownloadObservables$ && this.pdfDownloadObservables$[0]) {
        this.pdfDownloadObservable$ = this.pdfDownloadObservables$[0].observable;
        this.observableSelectBox.value = this.pdfDownloadObservables$[0].id;
        this.routeDataService.setValue(
          AppConstants.PDF_VIEWER_ITEM_ID_KEY,
          this.pdfDownloadObservables$[0].id,
        );
      }
    }

    try {
      const pdfViewerContainer = this.element.nativeElement.querySelector(
        '#pdf-viewer-container',
      );

      this.pdfEventBus = new PDFJS.EventBus();

      const pdfOptions: any = {
        container: pdfViewerContainer,
        renderInteractiveForms: true,
        removePageBorders: true,
        eventBus: this.pdfEventBus,
      };
      this.pdfViewer = new PDFJS.PDFViewer(pdfOptions);

      this.pdfFindController = new PDFJS.PDFFindController({
        pdfViewer: this.pdfViewer,
      });
      this.pdfViewer.setFindController(this.pdfFindController);

      this.bindPdfEvents();
    } catch (exception) {
      console.error('error initializing pdfjs viewer', exception);
    }

    if (this.pdfDownloadObservable$) {
      this.subscribeToPdfDownload();
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.captureTabEvent);
  }

  captureTabEvent(e) {
    if(e.keyCode === 9) {
      let input : any = e.target;
      if(input && input.tagName === 'INPUT' || input.tagName === 'input') {
        let nextInput = input.parentElement.nextElementSibling.firstElementChild;
        if(nextInput) {
          nextInput.focus();
        }
      }
    }
  }

  setLoaderHeight() {
    const overlayContent = document.querySelector('.dx-overlay-content');
    const toolbarReport = document.querySelector('.toolbar-report');
    if (!!overlayContent && !!toolbarReport) {
      this.dynamicLoadingHeight = `${(overlayContent.clientHeight - toolbarReport.clientHeight)}px`;
    }
  }

  onMoveEnd(e: any) {
    let pageNo = 1;
    this.esignX = 0;
    this.esignY = 0;
    const pdfViewerElement = this.pdfViewerElement.nativeElement;
    const width = Number(pdfViewerElement.clientWidth);
    const height = Number(pdfViewerElement.clientHeight);
    const scrollPosition = Number(this.pdfViewerContainer.nativeElement.scrollTop);

    if (!!this.esignXCoordinate || !!this.esignYCoordinate) {
      if ((this.esignYCoordinate + e.y) > 1056) {
        for (let i = 1; i <= this.totalPageNumber; i++) {
          if ((this.esignYCoordinate + e.y) > (i * 1056)) {
            pageNo = 1 + i;
          }
        }
      }
      this.esignX = Math.round(Number(((this.esignXCoordinate * 100) / width)) + Number((e.x * 100) / width));
      this.esignY = Math.round(Number(this.esignYCoordinate) + Number(e.y));
    } else {
      this.esignX = Math.round(Number((e.x * 100) / width));
      this.esignY = Math.round(Number(e.y));
    }
    this.eventService.setESignCoordinates(width, height, this.esignX, this.esignY, pageNo, scrollPosition);
  }

  runBlankForm() {
    this.pdfLoaded = false;
    this.fileDownloading = true;
    this.fileDownloadError = false;
    const blankRequest = new ReportCompilationRequest();
    blankRequest.requestedReportIds = this.reportIds;
    this.subs.sink = this.reportService.postReportCompilationRequest(blankRequest).subscribe(this.handleResponse, this.handleError);
  }

  handleResponse(response) {
    this.fileDownloading = false;
    if (response.pdfBase64) {
      this.pdfSrc = FileUtilities.base64ToBinary(response.pdfBase64);
      this.loadPdf();
    } else {
      this.fileDownloadError = true;
      this.errorMessage = `No ${this.objectTypeName} found in response.`;
    }
  }

  handleError(error) {
    this.pdfLoaded = true;
    console.error('error getting pdf', error);
    this.fileDownloading = false;
    this.fileDownloadError = true;
    if (this.objectTypeName === 'forms') {
      this.showBlankButton = true;
    }
    if (error.message) {
      if (error.message.includes('boundReportPdfs.Count == 0')) {
        this.errorMessage = 'Based on your filters, there were no records. Please review. Filters are required.';
      } else {
        const errorResp = JSON.parse(error.message);
        this.errorMessage = errorResp.Message;
      }
    } else {
      // TODO: Standard response structure?
      this.errorMessage = JSON.stringify(error);
    }
  }

  subscribeToPdfDownload() {
    this.fileDownloading = true; // start with status indicator
    this.fileDownloadError = false; // and no error

    this.unloadPdf();

    if (this.isSmartFormPDF) {
      this.fileDownloading = false;
      this.pdfSrc = FileUtilities.base64ToBinary(this.base64String);
      this.loadPdf();
    } else {
      this.subs.sink = this.pdfDownloadObservable$.subscribe(
        response => {
          this.fileDownloading = false;
          this.eventService.reportDownloaded(true);
          if (response.pdfBase64) {
            this.pdfSrc = FileUtilities.base64ToBinary(response.pdfBase64);
            this.loadPdf();
          } else {
            this.fileDownloadError = true;
            this.errorMessage = `No ${this.objectTypeName} found in response.`;
          }
        },
        error => {
          console.error('error getting pdf', error);
          this.fileDownloading = false;
          this.fileDownloadError = true;
          if (error.message) {
            if (error.message.includes('boundReportPdfs.Count == 0')) {
              this.errorMessage = 'No records found.';
            } else {
              const errorResp = JSON.parse(error.message);
              this.errorMessage = errorResp.Message;
            }
          } else {
            // TODO: Standard response structure?
            // message = error;
            this.errorMessage = JSON.stringify(error);
          }
        },
      );
    }
  }

  onReportSelected(reportId) {
    if (!this.fileDownloading) {
      const reportCompilationRequest = new ReportCompilationRequest();
      reportCompilationRequest.requestedReportIds = [reportId];
      if (Array.isArray(this.reportFieldValues)) {
        reportCompilationRequest.fieldValues = this.reportFieldValues;
      } else {
        reportCompilationRequest.fieldValues = [this.reportFieldValues];
      }
      this.pdfDownloadObservable$ = this.reportService.postReportCompilationRequest(
        reportCompilationRequest,
      );
      this.subscribeToPdfDownload();
    } else {
      console.warn('pdf-viewer onReportSelected while fileDownloading');
    }
  }

  onReportListLoaded(e) {
    const pdfViewerElement = this.pdfViewerContainer.nativeElement;
    pdfViewerElement.onwheel = function () {
      e = window.event || e;
      const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
      if (delta > 0) {
        pdfViewerElement.scrollTop -= 50;
      } else {
        pdfViewerElement.scrollTop += 50;
      }
      return false;
    };
  }

  onObservableChanged(e) {

    const selectedObservableItem = this.pdfDownloadObservables$.find(
      observableItem => observableItem.id == e.value,
    );

    this.selectObservableItem(selectedObservableItem);
  }

  selectObservableItem(selectedObservableItem: PdfObservableItem) {
    this.pdfDownloadObservable$ = selectedObservableItem.observable;
    this.routeDataService.setValue(
      AppConstants.PDF_VIEWER_ITEM_ID_KEY,
      selectedObservableItem.id,
    );

    this.subscribeToPdfDownload();
  }

  ngOnChanges(changes: SimpleChanges) {
    // ...
  }

  bindPdfEvents() {

    this.pdfEventBus.on('pagechanging', this.onPdfPageChanging.bind(this));
    this.pdfEventBus.on('pagerendered', this.onPdfPageRendered.bind(this));
    this.pdfEventBus.on('zoomin', this.onPdfZoomIn.bind(this));
    this.pdfEventBus.on('zoomout', this.onPdfZoomOut.bind(this));
    this.pdfEventBus.on(
      'rotationchanging',
      this.onPdfRotationChanging.bind(this),
    );
    this.pdfEventBus.on('scroll', this.onPdfScroll.bind(this));
    this.pdfEventBus.on('pagesinit', this.onPdfPagesInit.bind(this));
    this.pdfEventBus.on('documentloaded', this.onPdfDocumentLoaded.bind(this));

  }

  async onPdfDocumentLoaded(e) {
    this.pdfLoaded = true;
    this.pdfLoading = false;
    if (!this.isPublic) {
      const sigFields = await this.esignGetSigFields();
      if (sigFields.length <= 0) {
        this.esignEnabled = false;
      }
    }
    this.handlePostLoadAction();
    this.documentLoaded.emit(e);
  }

  onPdfPagesInit(e) {
    if (this.isMobile) {
      this.pdfViewer.currentScale = 0.512;
    }
    // let pagesOverview = this.pdfViewer.getPagesOverview();

  }

  onPdfPageChanging(e) {

    this.currentPageNumber = e.pageNumber;

  }

  onPdfPageRendered(e) {
    this.pageRendered.emit(e);
  }

  onPdfZoomIn(e) {
  }

  onPdfZoomOut(e) {
  }

  onPdfRotationChanging(e) {
  }

  onPdfScroll(e) {
  }

  onConfirmResult(e) {
    if (this.confirmAction === confirmActionType.BINDANDDOWNLOAD) {
      if (e) {
        this.subs.sink = this.buildPdfBindRequest().subscribe(
          pdfBindRequest => {
            this.postLoadAction = postLoadActionType.DOWNLOAD;
            if (this.isPublic) {
              this.pdfDownloadObservable$ = this.reportService.postPdfBindRequest(
                pdfBindRequest,
                true,
                this.publicInfo.CustomerId,
              );
            } else {
              this.pdfDownloadObservable$ = this.reportService.postPdfBindRequest(
                pdfBindRequest,
              );
            }
            this.subscribeToPdfDownload();
          },
          error => {
            console.error('pdf-viewer error building pdfBindRequest', error);
          },
        );
      } else {
        this.savePdfAs();
      }
    } else if (this.confirmAction === confirmActionType.BINDANDESIGN) {
      if (e) {
        this.savePdfEdits(postLoadActionType.ATTACHFORESIGN);
      } else {
        this.attachPdfToSource(true);
      }
      this.attachPdf(true);
    } else if (this.confirmAction === confirmActionType.BINDANDATTACH) {
      if (e) {
        this.savePdfEdits(postLoadActionType.ATTACH);
      } else {
        this.attachPdfToSource(false);
      }
    } else if (this.confirmAction === confirmActionType.BINDANDPRINT) {
      this.savePdfEdits(postLoadActionType.PRINT);
      if (e) {
        this.attachPdfToSource(false);
      } else {
        this.renderPdfForPrint(true);
      }
    } else if (this.confirmAction === confirmActionType.BINDSIGNATUREIMAGE) {
      if (e) {
        this.savePdfEdits(postLoadActionType.GETSIGNATURE);
      } else {
        this.onChangesSaved.emit(false);
      }
    }
  }

  saveChanges() {
    if (this.annotationsHaveChanges()) {
      this.savePdfEdits(postLoadActionType.AUDIT);
    }
  }

  savePdfEdits(postLoadAction: PostLoadAction) {
    this.subs.sink = this.buildPdfBindRequest().subscribe(
      pdfBindRequest => {
        this.postLoadAction = postLoadAction;
        if (this.isPublic) {
          this.pdfDownloadObservable$ = this.reportService.postPdfBindRequest(
            pdfBindRequest,
            true,
            this.publicInfo.CustomerId,
          );
        } else {
          this.pdfDownloadObservable$ = this.reportService.postPdfBindRequest(
            pdfBindRequest,
          );
        }
        this.subscribeToPdfDownload();
      },
      error => {
        console.error('pdf-viewer error building pdfBindRequest', error);
      },
    );
  }

  buildPdfBindRequest(): Observable<PdfBindRequest> {
    return from(this.pdf.getData()).pipe(mergeMap(data => {

      const pdfBase64 = FileUtilities.binaryToBase64(data);
      return this.getAnnotationFieldValues().pipe(map(fieldValues => {
        const pdfBindRequest = new PdfBindRequest();
        pdfBindRequest.pdfBase64 = pdfBase64;
        pdfBindRequest.pdfFields = fieldValues;
        pdfBindRequest.document = this.publicInfo;
        return pdfBindRequest;
      }));
    }));
  }

  buildPdfSignatureImageBindRequest(signatureImageBase64: string): Observable<PdfSignatureImageBindRequest> {
    return from(this.pdf.getData()).pipe(mergeMap(data => {
      const pdfBase64 = FileUtilities.binaryToBase64(data);
      const pdfBindRequest = new PdfSignatureImageBindRequest();
      pdfBindRequest.pdfBase64 = pdfBase64;
      pdfBindRequest.signatureImageBase64 = signatureImageBase64;
      return of(pdfBindRequest);
    }));
  }

  getAnnotationFieldValues() {

    const numPages = this.pdf.numPages;

    const fieldValues: PdfField[] = [];

    const getBoundAnnotationsPromises: any[] = [];

    for (let i = 0; i < numPages; i++) {
      getBoundAnnotationsPromises.push(
        this.pdf
          .getPage(i + 1)
          .then(pdfPage => {
            return pdfPage.getAnnotations({intent: 'display'});
          })
          .then(annotations => {
            let annotationsDiv = null;
            if (
              this.pdfViewer &&
              this.pdfViewer._pages[i] &&
              this.pdfViewer._pages[i].annotationLayer
            ) {
              annotationsDiv = this.pdfViewer._pages[i].annotationLayer.div;
            }

            if (annotationsDiv) {
              this.bindPageEditsToAnnotations(annotations, annotationsDiv);
            }

            annotations.forEach(annotation => {
              // only push annotations with defined field name and value
              if (annotation && annotation.fieldName) {
                if (annotation.radioButton) {
                  if (annotation.fieldValue) {
                    // TODO: Proper radioButton support
                    // only push fieldValue of selected radio?
                    const fieldValue = annotation.fieldValue || null;
                    fieldValues.push({
                      fieldName: annotation.fieldName,
                      fieldValue: fieldValue,
                    });
                  }
                } else {
                  fieldValues.push({
                    fieldName: annotation.fieldName,
                    fieldValue: annotation.fieldValue,
                  });
                }
              }
            });

            return true;
          }),
      );
    }

    return from(Promise.all(getBoundAnnotationsPromises)).pipe(map(
      () => {
        return fieldValues;
      },
    ));
  }

  annotationsHaveChanges(): boolean {
    let hasChanges = false;

    try {
      if (this.pdfViewer && this.pdfViewer._pages) {
        this.pdfViewer._pages.forEach(viewerPage => {
          if (viewerPage.annotationLayer && viewerPage.annotationLayer.div) {
            if (
              this.pageAnnotationsHaveChanges(viewerPage.annotationLayer.div)
            ) {
              hasChanges = true;
            }
          }
        });
      }
    } catch (exception) {
      console.error(
        'pdf-viewer error checking annotations for changes',
        exception,
      );
    }

    return hasChanges;
  }

  pageAnnotationsHaveChanges(pageAnnotationLayer: HTMLElement): boolean {
    let hasChanges = false;

    const annotationSections = Array.from(
      pageAnnotationLayer.querySelectorAll('section'),
    );
    annotationSections.forEach(section => {
      const annotationId = section.attributes.getNamedItem('data-annotation-id');

      if (section.classList.contains('textWidgetAnnotation')) {
        const input = section.querySelector('input') || section.querySelector('textarea');


        if (input && input.value != input.defaultValue) {
          hasChanges = true;
          console.warn('pdf-viewer change detected in annotation', input);
        }
      } else if (section.classList.contains('buttonWidgetAnnotation')) {
        if (section.classList.contains('checkBox')) {
          const input = section.querySelector('input');

          if (input && input.checked != input.defaultChecked) {
            hasChanges = true;
            console.warn('pdf-viewer change detected in annotation', input);
          }
        } else if (section.classList.contains('radioButton')) {
          const input = section.querySelector('input');

          if (input && input.checked != input.defaultChecked) {
            hasChanges = true;
            console.warn('pdf-viewer change detected in annotation', input);
          }
        }
      } // else if(section.classList.contains("")) { //TODO: other interactive forms annotation widgets
    });

    return hasChanges;
  }

  loadPdf() {
    this.pdfLoading = true;

    try {
      const loadingTask = PDFJS.getDocument(this.pdfSrc as any);

      loadingTask.then(pdf => {
        this.pdf = pdf;

        this.totalPageNumber = pdf.numPages;
        this.currentPageNumber = 1;

        this.loadPdfMetadata();
        this.pdfViewer.setDocument(this.pdf);

        const pagesPromise = this.pdfViewer.pagesPromise;
        pagesPromise.then(() => {
          this.pdfEventBus.dispatch('documentloaded', {source: this});
          this.onPdfLoaded.emit();
        });

      });
    } catch (exception) {
      console.error('error loading pdf document', exception);
    }
  }

  handlePostLoadAction() {
    if (this.postLoadAction === postLoadActionType.DOWNLOAD) {
      this.postLoadAction = postLoadActionType.NONE;
      this.savePdfAs();
    } else if (this.postLoadAction === postLoadActionType.ATTACH || this.postLoadAction === postLoadActionType.ATTACHFORESIGN) {
      this.attachPdfToSource(this.postLoadAction === postLoadActionType.ATTACHFORESIGN);
      this.postLoadAction = postLoadActionType.NONE;
    } else if (this.postLoadAction === postLoadActionType.PRINT) {
      this.postLoadAction = postLoadActionType.NONE;
      this.renderPdfForPrint(true);
    } else if (this.postLoadAction === postLoadActionType.GETSIGNATURE) {
      this.postLoadAction = postLoadActionType.NONE;
      this.onChangesSaved.emit(true);
    } else if (this.postLoadAction === postLoadActionType.SENDFORESIGN) {
      this.postLoadAction = postLoadActionType.NONE;
      this.esignProcessAfterAttach(this.publicInfo.Id);
    } else if (this.postLoadAction === postLoadActionType.AUDIT) {
      // const audit = new SignedDocumentAudit(this.documentId, this.share.Name, this.share);
      // this.subs.sink = this.auditService.createDocumentAudit(audit).subscribe();
    }
  }

  unloadPdf() {
    this.pdfLoaded = false;
    this.pdf = null;
    this.pdfViewer.setDocument(null);

    this.documentInfo = null;
    this.metadata = null;
    this.contentDispositionFilename = null;

    // pdfEventBus is attached to viewer and can stay
  }

  loadPdfMetadata() {
    try {
      this.pdf
        .getMetadata()
        .then(({info, metadata, contentDispositionFilename}) => {
          this.documentInfo = info;
          this.metadata = metadata;
          this.contentDispositionFilename = contentDispositionFilename;

          let pdfTitle;
          if (metadata && metadata.has('dc:title')) {
            const title = metadata.get('dc:title');
            // Ghostscript sometimes return 'Untitled', sets the title to 'Untitled'
            if (title !== 'Untitled') {
              pdfTitle = title;
            }
          }

          if (!pdfTitle && info && info.Title) {
            pdfTitle = info.Title;
          }

          if (pdfTitle) {
            this.setTitle(
              `${pdfTitle} - ${contentDispositionFilename || document.title}`,
            );
          } else if (contentDispositionFilename) {
            this.setTitle(contentDispositionFilename);
          }

        });
    } catch (exception) {
      console.error('error loading pdf metadata', exception);
    }
  }

  setTitle(title: string) {
    // TODO: Get toolbar item for title and adjust
  }

  // used only in single page view mode
  renderPage(pageNum: number) {
    const container = this.pdfViewerElement.nativeElement;
    const scale = 1.0;

    this.pdf.getPage(pageNum).then(page => {
      try {

        const pdfOptions: any = {
          container: container,
          id: pageNum,
          scale: scale,
          defaultViewport: page.getViewport(scale),
          textLayerFactory: new PDFJS.DefaultTextLayerFactory(),
          annotationLayerFactory: new PDFJS.DefaultAnnotationLayerFactory(),
          renderInteractiveForms: true,
        };

        const pdfPageView = new PDFJS.PDFPageView(pdfOptions);
        pdfPageView.setPdfPage(page);
        pdfPageView.draw();
      } catch (exception) {
        console.error(`error rendering pdf page`, exception);
      }
    });
  }


  bindPageEditsToAnnotations(annotations, annotationsDiv) {
    annotations.forEach(annotation => {
      // annotation.fieldName //This is acroform field name

      if (annotation.annotationType == annotationType.WIDGET) {
        // is interactive form element
        const interactiveSection = annotationsDiv.querySelector(
          '[data-annotation-id="' + annotation.id + '"]',
        );

        if (interactiveSection) {
          if (annotation.fieldType == 'Tx') {
            // found matching text input
            const htmlInput: HTMLInputElement = interactiveSection.querySelector(
              'input',
            ) || interactiveSection.querySelector(
              'textarea',
            );

            if (htmlInput) {
              annotation.fieldValue = htmlInput.value;
            } else {
              // this can happen for signature fields
            }
          } else if (annotation.fieldType == 'Btn') {
            // radio button or check box
            if (annotation.radioButton) {
              // TODO: RadioButton support
              // pdf.js does not appear to understand checkbox groups
              const htmlInput: HTMLInputElement = interactiveSection.querySelector('input');
              if (htmlInput) {
                if (htmlInput.checked) {
                  // input value set to buttonValue in annotationLayer
                  // annotation.fieldValue = htmlInput.value;
                  annotation.fieldValue = htmlInput.value;
                }
              }

            } else if (annotation.checkBox) {
              const htmlInput: HTMLInputElement = interactiveSection.querySelector(
                'input',
              );
              if (htmlInput) {
                annotation.fieldValue = htmlInput.checked ? 'On' : 'Off';
              }
            }
          } else if (annotation.fieldType == 'Ch') {
            // TODO: Choice support
          }
        }
      }
    });
  }

  async renderPdfForPrint(doPrint) {

      const data = await this.pdf.getData();
      const blob = new Blob([data], {type: 'application/pdf'});
      const blobUrl = URL.createObjectURL(blob);

      const iframe =  document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.display = 'none';
      iframe.src = blobUrl;
      iframe.onload = function() {
        setTimeout(function() {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };
  }

  printPdf() {
    const hasChanges = this.annotationsHaveChanges();
    if (this.isPublic && this.publicInfo.SigningStatus === 'COMPLETE') {
      if (hasChanges) {
        this.notifyService.toast('Any changes to the document after signing is complete will not be saved');
      }
     this.renderPdfForPrint(true);
    } else {
      if (hasChanges) {
        this.savePdfEdits(postLoadActionType.PRINT);
      } else {
        this.renderPdfForPrint(true);
      }
    }
  }

  checkChangesAndSign(): boolean {
    const hasChanges = this.annotationsHaveChanges();
    if (hasChanges) {
      this.confirmationMessage = 'Save your edits before signing?';
      this.confirmationTitle = 'Confirm Save';
      this.confirmAction = confirmActionType.BINDSIGNATUREIMAGE;
      this.confirming = true;
      return true;
    }
    return false;
  }

  rotatePdf() {
    this.currentRotation += 90;

    this.pdfViewer.pagesRotation = this.currentRotation;
  }

  searchPdf() {
    // TODO:
  }

  zoomInPdf() {
    let newScale = this.currentScale * 1.2;
    newScale = Math.min(MAX_SCALE, newScale);

    this.pdfViewer.currentScaleValue = newScale;
    this.currentScale = newScale;

    this.pdfEventBus.dispatch('zoomin');
  }

  zoomOutPdf() {
    let newScale = this.currentScale * 0.8;
    newScale = Math.max(MIN_SCALE, newScale);

    this.pdfViewer.currentScaleValue = newScale;
    this.currentScale = newScale;

    this.pdfEventBus.dispatch('zoomout');
  }

  attachPdf(forEsign = false) {
    const hasChanges = this.annotationsHaveChanges();
    if (hasChanges) {
      this.savePdfEdits(forEsign ? postLoadActionType.ATTACHFORESIGN : postLoadActionType.ATTACH);
      // this.confirmationMessage = 'Save your edits? Changes will not update the Defendant\'s record in the system.';
      // this.confirmationTitle = 'Update PDF';
      // this.confirmAction = forEsign ? confirmActionType.BINDANDESIGN : confirmActionType.BINDANDATTACH;
      // this.confirming = true;
    } else {
      this.attachPdfToSource(forEsign);
    }
  }

  attachDocAndSend() {
    this.isSendingEsign = true;
    if (this.isDocumentViewer) {
      const hasChanges = this.annotationsHaveChanges();
      if (hasChanges) {
        this.savePdfEdits(postLoadActionType.SENDFORESIGN);
      } else {
        this.esignProcessAfterAttach(this.publicInfo.Id);
      }
    } else {
      this.attachPdf(true);
    }
  }

  attachPdfToSource(forEsign = false) {

    this.notifyService.toast(forEsign ? 'Preparing document for Esigning, please wait ...' : 'Saving ...', 'success');
    this.pdf.getData().then(data => {
      // data["name"] = this.objectName || this.objectTypeName;
      const blob = new Blob([data], {type: 'application/pdf'});
      const document = new Document();
      document.isAdded = true;
      document.Name = this.objectName || this.objectTypeName;
      document.Type = blob.type;
      document.Size = blob.size;
      document.SizeText = FileUtilities.FileSizeText(blob.size);
      document.ModifiedDate = StringUtilities.formatDateTimeString(new Date());
      document.File = blob;
      document.File.name = this.objectName || this.objectTypeName;
      document.SourceType = this.sourceType;
      document.SourceId = this.sourceId;
      document.SigningStatus = forEsign ? 'NEW' : null;

      this.attachingToRecord = true;

      return this.subs.sink = this.documentService.addDocument(document, false, this.esignShares).subscribe(respData => {
        this.attachingToRecord = false;
        const fltSourceType = this.validSourceTypes.find(sourceType => sourceType.value === this.sourceType);
        if (forEsign) {
          this.notifyService.toast(`Preparing document for Esigning, please wait ...`, 'success');
        } else {
          if (!!fltSourceType) {
            this.notifyService.toast(`PDF attached to your ${fltSourceType.text}.`, 'success');
          } else {
            this.notifyService.toast('PDF attached to your resource in Captira.', 'success');
          }
        }
        this.eventService.emitPdfAttached(respData.data.data);
        if (forEsign) {
          this.esignProcessAfterAttach(respData?.data?.data?.Id);
        }
      }, error => {
        this.attachingToRecord = false;
        console.error('pdf-viewer error attaching pdf', error);
        this.notifyService.toast(`Unable to attach PDF to ${this.sourceType}.`, 'error');
      });
    });
  }

  emailPdf() {
    // TODO: Email service?
  }

  showESignPopup() {
    // handled upstream by secure document component
    this.onEsignButtonClick.emit();
  }

  async esignProcessInitiate() {
    // We need to make sure that this document can be signed.
    const sigFields = await this.esignGetSigFields();
    if (sigFields.length > 0) {
      // let's save the doc
      this.notifyService.toast('Preparing for eSign');
      this.esignShowSharesPopup();
    } else {
      this.notifyService.toast('Oops! This document isn\'t prepared for eSign. Contact Customer Care to have this form configured for eSign', 'error');
    }
  }

  esignProcessAfterAttach(docId) {
    // at this point the PDF is uploaded and attached, let's send the user over to /securedocs route
    // this.router.navigate(['/securedocs'], {queryParams: {i: 1, did: docId}});
    //this.esignAfterAttachPopupVisible = true;
    this.esignDocumentId = docId;
    this.esignSaveSharesAndSend();
    // this.esignShowSharesPopup();
  }

  esignGoToSecureDocs() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/securedocs'], {queryParams: {i: 1, did: this.esignDocumentId}}));
    console.log('open url in new tab', url);
    window.open(url, '_blank');
  }

  openNewAppTab() {
    window.open(window.location.pathname, '_blank');
  }

  downloadPdf() {
    const hasChanges = this.annotationsHaveChanges();
    if (this.isPublic && this.publicInfo.SigningStatus === 'COMPLETE') {
      if (hasChanges) {
        this.notifyService.toast('Any changes to the document after signing is complete will not be saved');
      }
      this.savePdfAs();
    } else {
      if (hasChanges) {
        this.savePdfEdits(postLoadActionType.DOWNLOAD);
      } else {
        this.savePdfAs();
      }
    }
  }

  savePdfAs() {

    /*
    this.pdf.getData().then(data => {
      // console.log('pdf-viewer downloadPdf got data');
      let blob = new Blob([data], {type: 'application/pdf'});

      //let blobUrl = window.URL.createObjectURL(blob);
      //// console.log("downloading pdf bloburl", blobUrl);
      //FileUtilities.downloadBlob(blobUrl, this.contentDispositionFilename);

      FileUtilities.saveBlobAs(
        blob,
        `${this.objectName || this.objectTypeName}.pdf`
      );
    });
    */

    const data = this.pdfSrc;

    const blob = new Blob([data], {type: 'application/pdf'});

    // let blobUrl = window.URL.createObjectURL(blob);
    // // console.log("downloading pdf bloburl", blobUrl);
    // FileUtilities.downloadBlob(blobUrl, this.contentDispositionFilename);

    FileUtilities.saveBlobAs(
      blob,
      `${this.objectName || this.objectTypeName}.pdf`,
    );
  }

  launchPdf() {
    this.pdf.getData().then(data => {
      const blob = new Blob([data], {type: 'application/pdf'});
      const blobUrl = window.URL.createObjectURL(blob);

      const newWindow = window.open(blobUrl, '_blank');
    });
  }

  onTestButton() {
  }

  onCloseButton() {
    // only visible on dynamic popup
    this.onClose.next(true);
  }

  popupProgressFormat(value) {
    return `Preparing: ${value * 100}%...`;
  }

  toggleMaximizeWindow() {
    this.isWindowMaximized = !this.isWindowMaximized;
    if (this.isWindowMaximized) {
      this.maximizeIcon = 'fal fa-compress-alt';
    } else {
      this.maximizeIcon = 'fal fa-expand-alt';
    }

    this.popupService.toggleMaximizePopup(this.isWindowMaximized);
  }

  async esignShowSharesPopup() {
    if (this.esignSigningRoles.length < 1) {
      await this.esignGetSignerRoles();
    }
    if (this.esignShares.length < 1) {
      this.esignAddInitialShare();
    }
    console.log('esign popup showing');
    this.esignSharesPopupVisible = true;
  }

  pullInfoFromPerson(person: Person, role: string): Share {
    const share = new Share();
    share.Name = `${person.First} ${person.Last}`;
    const phone = person.phones.filter(p => p.IsDefault && p.PhoneType === 'M');
    const email = person.emails.filter(e => e.IsDefault);
    share.contactInfo = [];
    // Prefer to use phone over email
    const defaultESignShare = localStorage.getItem('defaultESignShare');
    if (defaultESignShare != 'email' && phone.length > 0) {
      share.Mobile = phone[0].Number;
      share.EmailOrMobile = share.Mobile;
      share.contactInfo.push(phone[0].Number);
      if (email.length > 0) {
        share.contactInfo.push(email[0].Email);
      }
    } else if (email.length > 0) {
      share.Email = email[0].Email;
      share.EmailOrMobile = share.Email;
      share.contactInfo.push(email[0].Email);
      if (phone.length > 0) {
        share.contactInfo.push(phone[0].Number);
      }
    }
    share.Role = role;
    return share;
  }

  esignAddInitialShare() {
    for (const role of this.roles) {
      switch (role.type) {
        case 'Defendant':
          this.esignShares.push(this.pullInfoFromPerson(this.defendant.person, role.name));
          break;
        case 'Indemnitor':
          if (!!this.contacts && this.contacts.length >= role.number) {
            this.esignShares.push(this.pullInfoFromPerson(this.contacts[role.number - 1].person, role.name));
          }
          break;
      }
    }
  }

  esignAddShare() {
    this.esignShares.push(new Share);
  }

  esignDeleteShare(index) {
    this.esignShares.splice(index, 1);
  }

  esignSaveSharesAndSend() {
    this.esignIsBusy = true;
    const duplicates = this.esignShares
      .map(share => share.Role)
      .some((share: string, index: number, arr: string[]) => arr.indexOf(share) !== index);
    if (duplicates) {
      this.notifyService.toast('Duplicate roles assigned. Please fix and re-submit.', 'error');
      this.esignIsBusy = false;
    } else {
      // Check to make sure that the required fields are filled out
      let valid = true;
      let sentinel = 0;
      do {
        const share = this.esignShares[sentinel];
        if (!share.EmailOrMobile) {
          valid = false;
        }
        sentinel++;
      } while (valid === true && sentinel < this.esignShares.length);
      if (valid) {
        this.notifyService.toast('Preparing document and sending emails');
        const duplicateDetails = this.esignShares
          .map(share => share.EmailOrMobile)
          .some((share: string, index: number, arr: string[]) => arr.indexOf(share) !== index);
        if (duplicateDetails) {
          this.isSendingEsign = false;
          const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: {
              title: 'Duplicate Details',
              message: 'Uh oh, It looks like you have duplicate contact details! Is this okay?',
            },
          });
          this.subs.sink = dialogRef.afterClosed().subscribe(okay => {
            if (okay) {
              this.isSendingEsign = true;
              this.saveAndSendShares();
            } else {
              this.esignIsBusy = false;
              return;
            }
          });
        } else {
          this.saveAndSendShares();
        }
      } else {
        this.notifyService.toast('Oops, It looks like your missing contact information for one of your signers', 'error');
        this.esignIsBusy = false;
      }
    }
  }

  saveAndSendShares() {
    this.subs.sink = this.shareService.SaveEsignSharesAndSendEmail(this.esignShares, this.esignDocumentId).subscribe((savedShares: any) => {
      this.esignShares = savedShares;
      this.esignIsBusy = false;
      this.esignSharesPopupVisible = false;
      localStorage.setItem('defaultESignShare', this.esignShares[this.esignShares.length - 1].Email ? 'email' : this.esignShares[this.esignShares.length - 1].Mobile ? 'mobile' : '');
      this.isSendingEsign = false;
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        data: {
          message: `Notifications have been sent to signers.`,
          title: 'eSign Requests Sent',
        },
        maxWidth: '300px',
      });
      this.subs.sink = dialogRef.afterClosed().subscribe(complete => {
        this.onEsignSent.emit(true);
      });
    }, error => {
      console.error(error);
      this.notifyService.toast('Uh oh, an error occurred. Please try again or contact support.', 'error');
      this.isSendingEsign = false;
      this.esignIsBusy = false;
    });
  }

  esignInsertSignatureImage(signatureImageBase64: string, share: Share): Observable<void> {
    return this.buildPdfSignatureImageBindRequest(signatureImageBase64).pipe(
      map(pdfBindRequest => {
        this.postLoadAction = postLoadActionType.NONE;
        this.pdfDownloadObservable$ = this.reportService.postPdfSignatureImageBindRequest(
          pdfBindRequest,
          share,
        );
        this.subscribeToPdfDownload();
      }),
    );
  }

  async esignGetSignerRoles() {
    const sigFields = await this.esignGetSigFields();
    const rolesToShowNumbersFor: string[] = [];
    for (const field of sigFields) {
      // console.log('field', field);
      const parsedFieldName = field.name.split('_')[1];
      const signerType = parsedFieldName.substr(0, parsedFieldName.length - 1);
      // the '+' here is a cast to number
      const signerNumber: number = +parsedFieldName.substr(parsedFieldName.length - 1, 1);
      // if there is no signerNumber greater than 1 for any particular role, do not show the number on that role
      if (signerNumber > 1) {
        rolesToShowNumbersFor.push(signerType);
      }
    }
    for (const field of sigFields) {
      // console.log('field', field);
      const parsedFieldName = field.name.split('_')[1];
      let signerType = parsedFieldName.substr(0, parsedFieldName.length - 1);
      const signerNumber = parseInt(parsedFieldName.substr(parsedFieldName.length - 1, 1), 10);
      let role = signerType;
      // if there is no signerNumber greater than 1 for any particular role, do not show the number on that role
      if (rolesToShowNumbersFor.indexOf(signerType) > -1) {
        role = `${signerType} #${signerNumber}`;
      }
      if (this.esignSigningRoles.indexOf(role) < 0) {
        this.esignSigningRoles.push(role);
        if (signerType.includes('Collateral')) {
          for (const transaction of this.defendant.transactions) {
            if (transaction.Id === this.sourceId) {
              if (signerNumber <= transaction.collaterals.length) {
                signerType = transaction.collaterals[signerNumber - 1].ContactType;
              }
            }
          }
        }
        this.roles.push({
          type: signerType,
          number: signerNumber,
          name: role,
        });
      }
    }
    this.esignSigningRoles = this.esignSigningRoles.sort();
  }

  // field names take form DocuSignSignHere_{SignerType}{SignerNumber}_{SignatureFieldNumber}
  // eg DocuSignSignHere_Indemnitor1_1 is the first signature field for the first Indemnitor type signer
  async esignGetSigFields(): Promise<Array<EsignPdfField>> {
    const result = [];
    const pages = this.pdfViewer.pdfDocument.numPages;
    for (let i = 1; i <= pages; i++) {
      const page = await this.pdfViewer.pdfDocument.getPage(i);
      const annotations = await page.getAnnotations();
      for (let j = 0; j < annotations.length; j++) {
        const element = annotations[j];
        if (element.fieldType === 'Sig') {
          // Field Name
          element.icon = 'fa-light fa-file-signature';
          const field: EsignPdfField = {
            name: element.fieldName,
            element: element,
          };
          result.push(field);
        }
      }
    }
    return result;
  }

}

export interface PdfObservableItem {
  id: string;
  name: string;
  observable: Observable<any>;
}

export interface EsignPdfField {
  name: string;
  element: any;
}

export interface SignerRole {
  type: string;
  number: number;
  name: string;
}
