<h2 mat-dialog-title>Documents</h2>
<div
  mat-dialog-content
  class='full-page-content'
  fxLayout='column'
  fxLayoutAlign='space-around center'
  fxLayoutGap='16px'
  >
  <app-document-uploader
    (onComplete)='handleComplete($event)'
    [(transactions)]='data.defendant.transactions'
    [defendant]='data.defendant'
  ></app-document-uploader>
</div>
<div
  mat-dialog-actions align='end' fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end end" fxLayoutAlign.xs='end stretch' fxLayoutGap='8px'>
  <button [disabled]="isDisabled" mat-stroked-button color='primary' (click)='handleComplete(false)'>Cancel</button>
  <button [disabled]="isDisabled" mat-raised-button color='primary' (click)='handleSave(data.defendant.transactions)'>Save</button>
</div>
