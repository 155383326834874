import {of, Observable} from 'rxjs';
import {share, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AppConstants} from '../shared/AppConstants';
import {BillingService} from './billing.service';


@Injectable()
export class WhiteLabelService {

  private whiteLabelType = 'unset';
  private logoPath: string;
  private logoClass: string;
  private smallLogoPath: string;
  private logoUrl: string;

  private whiteLabelType$: any;

  constructor(private billingService: BillingService) {
  }

  getWhiteLabelType(forceRefresh = false): Observable<string> {
    if (this.whiteLabelType$) {
      return this.whiteLabelType$;
    }
    if (this.whiteLabelType === 'unset' || forceRefresh) {
      // console.log('setting white label type initialization');
      this.whiteLabelType$ = this.billingService.GetCustomFieldValue('Price Level Name').pipe(
        map(priceLevel => {
          // console.log('Custom Field value received', priceLevel);
          switch (priceLevel.toUpperCase()) {
            case 'PALMSPEC':
              this.whiteLabelType = AppConstants.WHITE_LABEL_PALMETTO;
              break;
            default:
              this.whiteLabelType = AppConstants.WHITE_LABEL_NONE;
              break;
          }
          // console.log('return white label type initialization', this.whiteLabelType);
          this.whiteLabelType$ = null;
          return this.whiteLabelType;
        }));
      return this.whiteLabelType$;
    } else {
      // console.log('returning white label from cache', this.whiteLabelType);
      return of(this.whiteLabelType);
    }
  }

  getLogoPath(): Observable<string> {
    this.logoPath = 'assets/img/logo_noball_blue.png';
    if (!localStorage.securityToken) {
      return of(this.logoPath);
    } else {
      return this.getWhiteLabelType()
        .pipe(
          map(whiteLabelType => {
            if (!this.logoPath) {
              switch (whiteLabelType) {
                case AppConstants.WHITE_LABEL_PALMETTO:
                  this.logoPath = 'assets/img/palmetto-logo-tree.png';
                  break;
                default:
                  this.logoPath = 'assets/img/logo_noball_blue.png';
                  break;
              }
            }
            return this.logoPath;
          }));
    }
  }

  getSmallLogoPath(): Observable<string> {
    this.smallLogoPath = 'assets/img/logo.png';
    if (!localStorage.securityToken) {
      return of(this.smallLogoPath);
    } else {
      return this.getWhiteLabelType()
        .pipe(
          map(whiteLabelType => {
            if (!this.smallLogoPath) {
              switch (whiteLabelType) {
                case AppConstants.WHITE_LABEL_PALMETTO:
                  this.smallLogoPath = 'assets/img/logo-palmetto.png';
                  break;
                default:
                  this.smallLogoPath = 'assets/img/logo.png';
                  break;
              }
            }
            return this.smallLogoPath;
          }));
    }
  }

  getLogoUrl(): Observable<string> {
    this.logoUrl = 'https://www.captira.com';
    if (!localStorage.securityToken) {
      return of(this.logoUrl);
    } else {
      return this.getWhiteLabelType()
        .pipe(
          map(whiteLabelType => {
            if (!this.logoUrl) {
              switch (whiteLabelType) {
                case AppConstants.WHITE_LABEL_PALMETTO:
                  this.logoUrl = 'https://www.palmetto-software.com';
                  break;
                default:
                  this.logoUrl = 'https://www.captira.com';
                  break;
              }
            }
            return this.logoUrl;
          }));
    }
  }

  getLogoClass(): Observable<string> {
    this.logoClass = 'logo-login-standard';
    if (!localStorage.securityToken || localStorage.securityTokenType === 'client') {
      return of(this.logoClass);
    } else {
      return this.getWhiteLabelType()
        .pipe(
          map(whiteLabelType => {
            if (!this.logoClass) {
              switch (whiteLabelType) {
                case AppConstants.WHITE_LABEL_PALMETTO:
                  this.logoClass = 'logo-login-palmetto';
                  break;
                default:
                  this.logoClass = 'logo-login-standard';
                  break;
              }
            }
            return this.logoClass;
          }));
    }
  }
}
