import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'friendlyDate',
})
export class FriendlyDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: Date|string|number|null|undefined, ...args: unknown[]): string | null {
    if (value == null || value === '' || value !== value) {
      return null;
    }

    try {
      const date = moment(value);
      const today = moment();
      if (date.isSame(today, 'minute')) {
        return 'Now';
      } else if (date.isSame(today, 'hour')) {
        return date.toNow(true);
      } else if (date.isSame(today, 'day')) {
        return this.datePipe.transform(value, 'h:mm aa');
      } else if (!date.isBefore(today, 'week')) {
        return this.datePipe.transform(value, 'E h:mm aa');
      } else if (date.isSame(today, 'year')) {
        return this.datePipe.transform(value, 'MMM d, h:mm aa');
      } else {
        return this.datePipe.transform(value, 'short');
      }
    } catch (error) {
      throw new Error(`InvalidPipeArgument: '${error.message}' for pipe FriendlyDatePipe`);
    }
  }

}
