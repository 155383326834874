import { Component } from '@angular/core';
import { AppConstants } from '../../../shared/AppConstants';
import { BillingService } from '../../../services/billing.service';
import { EventService } from '../../../services/event.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { QuickAction } from '../quick-actions-preview/quick-actions-preview.component';

export enum QuickActionEvent {
  NOTE = 'AddNote',
  CHECKIN = 'CheckinComponent',
  LOCATIONHISTORY = 'LocationHistoryComponent',
  FORMS = 'DefendantFormsComponent',
  RAPSHEET = 'RapSheetComponent',
  DATAMART = 'BackgroundDatamartComponent',
  AUDITHISTORY = 'DefendantAuditHistoryComponent',
  DELETE = 'Delete',
  DOCUMENTS = 'DocumentComponent',
}

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss'],
})
export class QuickActionsComponent extends UnsubscribeOnDestroyAdapter {
  defendantMenu: QuickAction<QuickActionEvent>[] = [
    {
      value: QuickActionEvent.NOTE,
      text: 'Add Note',
      icon: 'fal fa-sticky-note',
    }, {
      value: QuickActionEvent.CHECKIN,
      text: 'Check Ins',
      icon: 'fal fa-map-marker',
    }, {
      value: QuickActionEvent.FORMS,
      text: 'Forms',
      icon: 'fal fa-print',
    }, {
      value: QuickActionEvent.RAPSHEET,
      text: 'Rap Sheet',
      icon: 'fal fa-print',
    }, {
      value: QuickActionEvent.DATAMART,
      text: 'Arrest History',
      icon: 'fal fa-search',
    }, {
      value: QuickActionEvent.AUDITHISTORY,
      text: 'Audit History',
      icon: 'fal fa-file',
    },
    {
      value: QuickActionEvent.DOCUMENTS,
      text: 'Documents',
      icon: 'fal fa-file',
    },
    {
      value: QuickActionEvent.DELETE,
      text: 'Delete',
      icon: 'fal fa-trash',
    },

    // {
    //   value: 'CheckinComponent',
    //   text: 'Check In',
    //   icon: 'fal fa-check-square',
    // },

  ];

  constructor(
    private billingService: BillingService,
    private eventService: EventService,
  ) {
    super();

    this.subs.sink = this.billingService
      .IsSubscribedToProduct(AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER)
      .subscribe((response: boolean) => {
        if (response === true) {
          this.defendantMenu = [
            {
              value: QuickActionEvent.NOTE,
              text: 'Add Note',
              icon: 'fal fa-file-alt',
            }, {
              value: QuickActionEvent.CHECKIN,
              text: 'Check In',
              icon: 'fal fa-check-square',
            }, {
              value: QuickActionEvent.FORMS,
              text: 'Print Forms',
              icon: 'fal fa-file-alt',
            }, {
              value: QuickActionEvent.RAPSHEET,
              text: 'Print Rap Sheet',
              icon: 'fal fa-print',
            }, {
              value: QuickActionEvent.AUDITHISTORY,
              text: 'Audit History',
              icon: 'fal fa-file',
            }, {
              value: QuickActionEvent.LOCATIONHISTORY,
              text: 'Check-In History',
              icon: 'fal fa-map-marker',
            }, {
              value: QuickActionEvent.DELETE,
              text: 'Delete',
              icon: 'fal fa-trash',
            },
          ];
        }
      });
  }

  selectedItem(action: QuickAction<QuickActionEvent>) {
    this.eventService.defAction(action);
  }
}
