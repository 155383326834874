import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiUtilities} from '../shared/ApiUtilities';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TransactionInvoiceService {

  private headers = new Headers(ApiUtilities.DEFAULT_HEADERS);

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getByTransactionId(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/invoice/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  save(id: any, data: any): Observable<any> {

    return this.http.put(`${this.settings.getBaseUrl()}/invoice/${id}`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  create(id: any, data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/invoice/${id}`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/invoice/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getFee(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/fees/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getAllFees(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/fees`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  deleteFee(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/invoice/fees/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  createFee(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/premiumandfee`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  updateFee(id: any, data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/premiumandfee/${id}`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

}
