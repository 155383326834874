import {BaseModel} from './BaseModel';

export class Email extends BaseModel {
  Id: any;
  SourceId: any;
  SourceType: any;
  Email: any;
  EmailType: any;
  IsDefault: boolean;
  rowId: any;
  public IsBad: boolean;
  public wasCopied: boolean = false;

  constructor(obj?: object) {

    super(obj);
    this.routeRoot = 'emails';

    if (obj == null) {
      this.IsDefault = false;
      this.Email = '';
      this.EmailType = '';
      this.SourceType = 'Persons'; // default to persons as that will be most widely used. individual components will need to override
    }
  }
}
