import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleReviewSettingsComponent } from '../google-review-settings/google-review-settings.component';
import { MatDialogRef } from '@angular/material/dialog';
import { EventService } from '../../services/event.service';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-google-review-setting-dialog',
  templateUrl: './google-review-setting-dialog.component.html',
  styleUrls: ['./google-review-setting-dialog.component.scss'],
})
export class GoogleReviewSettingDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild(GoogleReviewSettingsComponent) settingsComponent: GoogleReviewSettingsComponent;
  showLoading = false;

  constructor(
    private dialogRef: MatDialogRef<GoogleReviewSettingDialogComponent>,
    private eventService: EventService,
  ) {
    super();
    this.subs.sink = this.eventService.isBusy$.subscribe(item => {
      this.showLoading = item;
    });
  }

  ngOnInit(): void {
  }

  saveSettings() {
    this.settingsComponent.saveSettings();
  }

  saveComplete(enabled: boolean) {
    this.dialogRef.close(enabled);
  }

}
