import {Component, OnInit, ViewEncapsulation, Input, ViewChild} from '@angular/core';
import {IDynamicPopupComponent} from '../../components/common/dynamic-popup/dynamic-popup.component';
import {Subject} from 'rxjs';
import {AppConstants} from '../../shared/AppConstants';
import {DefendantService} from '../../services/defendant.service';
import {DxDataGridComponent} from 'devextreme-angular';
import * as moment from 'moment';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-defendant-audit-history',
  templateUrl: './defendant-audit-history.component.html',
  styleUrls: ['./defendant-audit-history.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DefendantAuditHistoryComponent extends UnsubscribeOnDestroyAdapter implements OnInit, IDynamicPopupComponent {

  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;

  dynamicData: any;
  onClose = new Subject<boolean>();
  inDynamicPopup = false;
  exportFileName = 'DefendantAudit';
  defendantName = '';
  @Input() Id: string;

  dataSource: any;

  errorVisible = false;
  errorTitle = 'There were errors';
  errorData: any = [];
  cancelFn = this.cancel.bind(this);

  constructor(private defendantService: DefendantService) {
    super();
  }

  ngOnInit() {
    this.loadDefendantAuditData();
  }

  loadDefendantAuditData() {
    // console.log('defendant audit dynamic data', this.dynamicData);
    // console.log('session defendant id', sessionStorage.getItem(AppConstants.DEFENDANT_ID_KEY));
    if (!!this.dynamicData && this.dynamicData.length > 0) {
      this.inDynamicPopup = true;
    }

    if (this.inDynamicPopup) {
      this.Id = this.dynamicData[AppConstants.DEFENDANT_ID_KEY];
      this.defendantName = this.dynamicData[AppConstants.DEFENDANT_NAME_KEY];
      this.exportFileName = 'Audit_History_' + this.dynamicData[AppConstants.DEFENDANT_NAME_KEY];
    } else {
      this.Id = sessionStorage.getItem(AppConstants.DEFENDANT_ID_KEY);
      this.defendantName = sessionStorage.getItem(AppConstants.DEFENDANT_NAME_KEY);
      this.exportFileName = 'Audit_History_' + sessionStorage.getItem(AppConstants.DEFENDANT_NAME_KEY);
    }

    if (!this.Id) {
      // console.log('def audit no id');
    } else {
      // console.log('def audit id', this.Id);
      setTimeout(() => {
        this.dataGrid.instance.beginCustomLoading('Loading...');
      }, 50);

      this.subs.sink = this.defendantService.getDefendantAudit(this.Id).subscribe(da => {
        this.dataSource = da.data;
        da.data.forEach(item => {
//          // console.log('values', item.AuditSourceId, 'old', JSON.parse(item.OldValues), 'new', JSON.parse(item.NewValues));
          const oldValues = (JSON.parse(item.OldValues));
          const newValues = (JSON.parse(item.NewValues));
          item.ParsedOldValues = this.parseValues(oldValues);
          item.ParsedNewValues = this.parseValues(newValues);
          // console.log('after parsed values', item, 'old', item.ParsedOldValues, 'new', item.ParsedNewValues);
        });
        // console.log('audit data', da.data);
        this.dataGrid.instance.endCustomLoading();
      }, error => {
        this.errorData = error;
        this.dataGrid.instance.endCustomLoading();
        // console.log('audit error', error);
      });
    }
  }

  parseValues(item) {
    // console.log('parseValues', item);
    if (!item || item === []) {
      return '';
    } else {
      let values = '';
      for (const propName in item) {
        if (item.hasOwnProperty(propName)) {
          let value = item[propName];
          if (propName === 'Created_At' || propName === 'Updated_At' || propName === 'Date') {
            value = moment(value, 'YYYY-MM-DD HH:mm:ss').format('M/D/YYYY h:mm a');
          }
          if (values.length > 0) {
            values = values + '<br />';
          }
          values = values + '<span style="color: rgb(149, 149, 149); font-weight:bold;">' + propName + '</span>' + ': ' + value;
        }
      }
      return values;
    }

  }

  cancel() {
    if (this.inDynamicPopup) {
      this.onClose.next(true);
    }
  }

  toggleColumnsForExport(e) {
    // console.log('export', e);
    e.component.beginUpdate();
    e.component.columnOption('ParsedOldValues', 'visible', false);
    e.component.columnOption('ParsedNewValues', 'visible', false);
    e.component.columnOption('OldValues', 'visible', true);
    e.component.columnOption('NewValues', 'visible', true);
    e.component.endUpdate();
  }

  onExported(e) {
    e.component.beginUpdate();
    e.component.columnOption('ParsedOldValues', 'visible', true);
    e.component.columnOption('ParsedNewValues', 'visible', true);
    e.component.columnOption('OldValues', 'visible', false);
    e.component.columnOption('NewValues', 'visible', false);
    e.component.endUpdate();
  }
}
