import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-adi-dialog',
  templateUrl: './adi-dialog.component.html',
  styleUrls: ['./adi-dialog.component.scss'],
})
export class AdiDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AdiDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  handleReminderSave(event: boolean) {
    this.dialogRef.close(event);
  }

}
