  <div class="row" *ngIf="checkCalculateSatisfyByDate">
    <label class="col-sm-4 control-label">Days to Satisfy Forfeiture</label>
    <div class="col-sm-3">
      <dx-number-box
        [(value)]="calculateSatisfyByDateDays"></dx-number-box>
    </div>
    <dx-button [disabled]="!calculateSatisfyByDateDays" type="success" text="Set Satisfy By Date"
                (onClick)="setSatisfyByDate()"></dx-button>
  </div>

  <div class="row" *ngIf="!checkCalculateSatisfyByDate">
    <label class="col-sm-4 control-label">Select Date to Satisfy Forfeiture</label>
    <div class="col-sm-3">
        <dx-date-box
          #satisfyDateBox
          dateSerializationFormat="yyyy-MM-dd"
          [displayFormat]="dateFormatter"
          invalidDateMessage="Invalid Date"
        >
        </dx-date-box>
    </div>
    <dx-button [disabled]="!satisfyDateBox.value" type="success" text="Set Satisfy By Date"
                (onClick)="setSatisfyByOnlyDate()"></dx-button>
  </div>
