import { Injectable, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DxPopupComponent } from 'devextreme-angular';
import { AddDefendantWarningComponent } from '../components/controls/add-defendant-warning/add-defendant-warning.component';
import { UnsubscribeOnDestroyAdapter } from '../common/UnsubscribeOnDestroy';
import { Observable } from 'rxjs';
import { OnboardingDialogComponent } from '../onboarding/onboarding-dialog/onboarding-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService extends UnsubscribeOnDestroyAdapter {
  @ViewChildren(DxPopupComponent) popups: QueryList<DxPopupComponent>;

  private dialogs: any[] = [];

  constructor(
    private dialog: MatDialog,
  ) {
    super();
    this.dialog.afterOpened.subscribe((dialogRef: MatDialogRef<any>) => {
      if (!(dialogRef.componentInstance instanceof AddDefendantWarningComponent)
        && !(dialogRef.componentInstance instanceof OnboardingDialogComponent)) {
        this.dialogs.push(dialogRef);
        this.subs.sink = dialogRef.afterClosed().subscribe(() => {
          this.dialogs.pop();
        });
      }
    });
  }

  add(component: any) {
    const ancestor: HTMLElement = component.element.parentElement;
    // We only want to add popups that are created by us. This method gets fired by DevExpress
    // when it creates things like dxDropDownButtons and dxSelectBoxes. FML
    switch (ancestor.nodeName) {
      case 'DX-SELECT-BOX':
      case 'DX-FILTER-BUILDER':
      case 'DX-DATA-GRID':
      case 'DX-DATE-BOX':
        break;
      case 'DIV':
        const classList = ancestor.classList;
        if (!classList.contains('dx-selectbox') &&
          !classList.contains('dx-textbox') &&
          !classList.contains('dx-dropdownbutton') &&
          !classList.contains('dx-filterbuilder') &&
          !classList.contains('dx-gridbase-container') &&
          !classList.contains('dx-selectbox')) {
          this.dialogs.push(component);
        }
        break;
      default:
        this.dialogs.push(component);
    }
  }

  remove() {
    this.dialogs.pop();
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dialogs.length > 0) {
      const last = this.dialogs[this.dialogs.length - 1];
      if (last instanceof MatDialogRef) {
        last.close();
      } else {
        last.component.instance().hide();
      }
      return false;
    }
    return true;
  }

}

