<div class="main-header">
  <mat-slide-toggle #matSlideToggle [color]="'primary'" [checked]="enabled" (change)="onEnable($event)">Enable Reminders
  </mat-slide-toggle>
  <span
  style="margin-left:1rem; cursor:pointer;"
  (click)="openTimeSlotDialog()"><i style="pointer-events: none;" class="dx-icon fal fa-cog"></i></span>
</div>

<div *ngIf="showPaymentGatewayAlert" class="alert alert-warning" role="alert">
  Payment Processing required for Payment Links
</div>
<dx-data-grid
  [dataSource]="automaticNotificationData"
  [columnAutoWidth]="true"
  [disabled]="!enabled"
  [noDataText]="'Loading...'">
  <dxo-scrolling
    mode="virtual"
    [showScrollbar]="'always'"
  ></dxo-scrolling>
  <dxi-column caption="Id" [visible]="false" dataField="Id"></dxi-column>
  <dxi-column caption="Reminder For" [visible]="false" dataField="ReminderForCode"></dxi-column>
  <dxi-column
    caption="Enable"
    [visible]="true"
    dataField="Enabled"
    dataType="boolean"
    width="100"
    cellTemplate='edtEnable'>
    <div *dxTemplate="let itm of 'edtEnable'">
      <dx-check-box
        [(value)]="itm.data.Enabled"
        (onValueChanged)="toggleReminder($event,itm.data.ReminderForCode)">
      </dx-check-box>
    </div>

  </dxi-column>
  <dxi-column
    caption="Setup Reminders"
    [visible]="true"
    dataField="ReminderFor"
    cellTemplate="reminderDetail">
    <div *dxTemplate="let itm of 'reminderDetail'">
      <div *ngIf="itm.data.ReminderForCode != 'GpsCheckin'">
        {{itm.data.ReminderFor}}
        <span *ngIf="itm.data.ReminderForCode == 'PaymentReminder' || itm.data.ReminderForCode == 'PastDue'">
          <button
            *ngIf="!itm.data.ReminderSettings.PaymentLinkEnabled; else paymentLinkEnabled"
            mat-button
            color="primary"
            (click)="editSettings(itm.data)"
          >
            Enable Payment Links
          </button>
          <ng-template #paymentLinkEnabled>
            + Payment Links
          </ng-template>
          <a href="//www.captira.com/pages/gps-check-in-help#reminder-help"
             target="_blank"
             rel="noopener noreferrer"
             mat-icon-button>
            <i class="fal fa-info-circle"></i>
          </a>
        </span>
      </div>
      <div *ngIf="itm.data.ReminderForCode == 'GpsCheckin'">
        {{itm.data.ReminderFor}}
        <a href="//www.captira.com/pages/gps-check-in-help#reminder-help"
           target="_blank"
           rel="noopener noreferrer"
           mat-icon-button>
          <i class="fal fa-info-circle"></i>
        </a>
      </div>
    </div>

  </dxi-column>
  <dxi-column
    caption="Preview Message"
    [visible]="true"
    width="150"
    cellTemplate="btnPreviewMessage"
    >
    <div *dxTemplate="let itm of 'btnPreviewMessage'">
      <dx-button
        (onClick)="previewMessage($event, itm.data)"
        icon="fal fa-file-alt">
      </dx-button>
    </div>
  </dxi-column>
  <dxi-column
    caption="Send Test"
    [visible]="true"
    width="150"
    cellTemplate="btnSendTest"
    >
  </dxi-column>
  <dxi-column
    caption="Settings"
    [visible]="true"
    width="150"
    cellTemplate="btnSettings"
    >
    <div *dxTemplate="let itm of 'btnSettings'">
      <dx-button
        (onClick)="editSettings(itm.data)"
        icon="fal fa-cog">
      </dx-button>
    </div>
  </dxi-column>
  <dxi-column
    caption="Sent By"
    [visible]="false"
    dataField="Channels"
    cellTemplate="edtChannelTemplate">
    <div *dxTemplate="let itm of 'edtChannelTemplate'">
      <p>{{itm.data.Channels}}</p>
    </div>
  </dxi-column>

  <div *dxTemplate="let itm of 'btnSendTest'">
    <dx-button
      id="btnSendTestMessage"
      (onClick)="showSendTestMessage($event, itm.data)"
      icon="fal fa-share-square">
    </dx-button>
  </div>
</dx-data-grid>

<app-buttonbar
  [routeKey]="''"
  [saveButtonsDisabled]="saveButtonDisabled"
  #navButtonBar
  [saveMethod]="save"
  [selectedRoute]="selectedNavRoute"
  [otherActions]="otherActions"
  [showOtherActionsAsButtons]="true"
  [allButtonsDisabled]="!enabled"
  style="margin-bottom: 10px">
</app-buttonbar>
<br>

<dx-popup
    #previewPopup
    id="previewPopup"
    [showTitle]="true"
    title="Preview Message"
    [closeOnOutsideClick]="true"
    [(visible)]="previewPopupVisible"
    height="auto"
    [width]="'35%'">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <div class="panel panel-primary">
        <h5 class="panel-heading">
          1st Reminder Preview
        </h5>
        <div class="panel-body">
          <p>{{msg1Preview}}</p>
        </div>
      </div>

      <div class="panel panel-primary" *ngIf="msg2Preview !== null">
        <h5 class="panel-heading">
          2nd Reminder Preview
        </h5>
        <div class="panel-body">
          <p>{{msg2Preview}}</p>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
    class="popup-component"
    #edtReminderConfigPopup
    [showTitle]="true"
    [title]="editSettingsPopupCaption"
    [closeOnOutsideClick]="false"
    [(visible)]="editReminderPopupVisible"
    (onHiding)="cancelReminderConfig()">
  <div *dxTemplate="let data of 'content'" id="dvEditReminder">
    <dx-scroll-view>
      <div *ngIf="currentRowReady">
        <div
          *ngIf="(formData.ReminderForCode ==='PaymentReminder' || formData.ReminderForCode ==='PastDue') && !gatewayEnabled && formData.ReminderSettings.PaymentLinkEnabled && !formData.ReminderSettings.PaymentLinkCustomEnabled"
          class="alert alert-warning"
          role="alert"
        >
          Payment Processing required for Payment Links
        </div>
        <dx-validation-group #settingsValGroup>
          <dx-form
              #frmEdit
              id="frmEdit"
              [formData]="formData"
              [colCount]="2"
              validationGroup="valGroup"
              (onFieldDataChanged)="onfieldDataChanged($event, formData)"
              style="max-width: 600px; margin: 0 auto;">
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2" caption="">
              <dxi-item
                [label]="{ text: 'Enable Reminder' }"
                [visible]="true"
                dataField="Enabled"
                editorType="dxCheckBox">
              </dxi-item>

              <dxi-item
                [label]="{ text: 'Send Reminder By' }"
                [visible]="false"
                dataField="ChannelsArray"
                editorType="dxTagBox"
                [editorOptions]="{
                  items: remindersChannels,
                  valueExpr:'Id',
                  displayExpr:'Text'
                }">
              </dxi-item>

              <dxi-item
                [label]="{ text: 'Include Indemnitors' }"
                [visible]="formData.showIndemnitorOption"
                dataField="IncludeIndemnitors"
                editorType="dxCheckBox">
              </dxi-item>

              <dxi-item
                [label]="{ text: 'Text All Defendant\'s Numbers' }"
                [visible]="true"
                dataField="IncludeAllNumbers"
                editorType="dxCheckBox"
                [editorOptions]="{ id:'chkAllNumbers' }">
              </dxi-item>

              <dxi-item
                [label]="{text: 'Exclude Forfeitures'}"
                [visible]="formData.ReminderForCode==='StdCheckin'"
                dataType="boolean"
                dataField="ReminderSettings.ExcludeForfeitures"
                editorType="dxCheckBox">
              </dxi-item>
              <dxi-item itemType="group" [colCount]="2" [colSpan]="1">
                <dxi-item
                  [label]="{ text: 'Enable Payment Links' }"
                  [visible]="formData.ReminderForCode ==='PaymentReminder' || formData.ReminderForCode ==='PastDue'"
                  dataField="ReminderSettings.PaymentLinkEnabled"
                  editorType="dxCheckBox"
                  [editorOptions]="{
                    id:'enblPaymentLinks',
                    onValueChanged: onEnablePaymentLink
                  }">
                </dxi-item>
                <dxi-item
                  itemType="button"
                  horizontalAlignment="left"
                  verticalAlignment="center"
                  [buttonOptions]="{
                    icon: 'fal fa-cog',
                    onClick: openPaymentLinkSettings
                  }"
                  [visible]="(formData.ReminderForCode === 'PaymentReminder' || formData.ReminderForCode === 'PastDue') && formData.ReminderSettings.PaymentLinkEnabled"
                ></dxi-item>
              </dxi-item>

            </dxi-item>
            <dxi-item
              itemType="group"
              [colCount]="2"
              [colSpan]="2"
              caption="Past Due Options"
              [visible]="formData.ReminderForCode === 'PastDue'">
              <dxi-item
                [label]="{ text: 'Minimum Balance ($)' }"
                [visible]="true"
                dataField="MinBalance"
                editorType="dxNumberBox"
                [editorOptions]="{
                  min:1,
                  format: {
                    type: 'currency',
                    precision: 2
                  }
                }">
              </dxi-item>
              <dxi-item
                [label]="{ text: 'Maximum Balance ($)' }"
                [visible]="true"
                dataField="MaxBalance"
                editorType="dxNumberBox"
                [editorOptions]="{
                  min:1,
                  format: {
                    type: 'currency',
                    precision: 2
                  }
                }">
              </dxi-item>

              <dxi-item
                [label]=" {text: 'Minimum Days' }"
                [visible]="true"
                dataField="MinDays"
                editorType="dxNumberBox"
                [editorOptions]="{ min:1 }">
              </dxi-item>
              <dxi-item
                [label]="{ text: 'Maximum Days' }"
                [visible]="true"
                dataField="MaxDays"
                editorType="dxNumberBox"
                [editorOptions]="{ min:1, max: 3650 }">
              </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2" [visible]="formData.ReminderForCode==='CourtDate'">
                <div class="label-dropdown-container">
                  <label for='courtDateDropdown' class='dx-field-item-label-text'>Only send for these Court Date Reasons</label>
                  <dx-drop-down-box
                    id="courtDateDropdown"
                    [value]="filterCourtDateReasons"
                    [dataSource]="courtdatereasons"
                    valueExpr="Id"
                    [showDropDownButton]="true"
                    stylingMode="underlined"
                    displayExpr="Text">
                    <div *dxTemplate="let data of 'content'">
                      <dx-data-grid [dataSource]="courtdatereasons"
                                    [columns]="[
                                      { dataField: 'Text', caption: 'Court Date Reason' },
                                    ]"
                                    [selection]="{ mode: 'multiple' }"
                                    [paging]="{ enabled: true, pageSize: 7 }"
                                    [scrolling]="{ mode: 'infinite' }"
                                    [height]="300"
                                    keyExpr="Id"
                                    [selectedRowKeys]="getSelectedRowKeys()"
                                    (onSelectionChanged)="onCourtDateReasonsSelectionChanged($event)">
                      </dx-data-grid>
                    </div>
                  </dx-drop-down-box>
                </div>
            </dxi-item>

            <dxi-item itemType="group" [colCount]="2" [colSpan]="2" [caption]="reminder1Caption">
              <dxi-item
                  #txReminder1Days
                  [label]="{ text: reminderDaysCaption }"
                  [visible]="formData.CanScheduleReminder"
                  dataField="ReminderConfigurations[0].DeliveryInterval"
                  editorType="dxNumberBox"
                  [editorOptions]="{
                  min:1,
                  showSpinButtons:true,
                  id:'txReminder1Days'
                }">
              </dxi-item>

              <dxi-item
                [label]="{ text: reminderDaysCaption }"
                [colSpan]="2"
                [visible]="!formData.CanScheduleReminder"
                editorType="dxTextBox"
                [disabled]="true"
                [width]="'100%'"
                [editorOptions]="{ value:'On the day of check in event in the calendar'}">
              </dxi-item>

              <dxi-item
                [label]="{ text: '&nbsp;', visible: true }"
                [visible]="formData.CanScheduleReminder"
                dataField="ReminderConfigurations[0].DeliveryFrequency"
                editorType="dxSelectBox"
                [editorOptions]="{
                  items: reminderIntervalFrequencies,
                  valueExpr:'Id',
                  displayExpr:'Text',
                  searchEnabled: false,
                  showClearButton: false
                }">
              </dxi-item>

              <dxi-item
                [label]="{ text: 'Message Template' }"
                [colSpan]="2"
                [visible]="true"
                dataField="ReminderConfigurations[0].NotificationTemplateId"
                editorType="dxSelectBox"
                [editorOptions]="{
                  items: notificationTemplates,
                  valueExpr:'Id',
                  displayExpr:'TemplateName',
                  searchEnabled: false,
                  showClearButton: false
                }">
              </dxi-item>

              <dxi-item
                [label]="{ text: 'Additional Text' }"
                [colSpan]="2"
                [visible]="formData.AllowCustomText"
                dataField="ReminderConfigurations[0].CustomText"
                editorType="dxTextArea">
              </dxi-item>

              <dxi-item [label]="{text: 'Add Identity Verification (Facial Recognition)'}" [colSpan]="1"
                        [visible]="formData.ReminderForCode==='GpsCheckin'"
                        dataField="ReminderSettings.FacialRecognition" dataType="boolean" editorType="dxCheckBox">
              </dxi-item>

              <dxi-item [label]="{text: 'Facial Recognition Threshold'}" [colSpan]="1"
                [visible]="formData.ReminderForCode==='GpsCheckin' && formData.ReminderSettings.FacialRecognition"
                [template]="'sliderTemplate'">
              </dxi-item>
            </dxi-item>

            <dxi-item itemType="group" [colCount]="2" [colSpan]="2" caption="">
              <dxi-item [label]="{text: 'Only send to these Charge Classes'}"
                        [visible]="formData.ReminderForCode==='GpsCheckin'"
                        [editorOptions]="{ onValueChanged : onShowChargeClasses }"
                        dataType="boolean"
                        dataField="sendToChargeClasses"
                        editorType="dxCheckBox">
              </dxi-item>
              <dxi-item [label]="{text: 'Exclude Forfeitures'}"
                        [visible]="formData.ReminderForCode==='GpsCheckin'"
                        dataType="boolean"
                        dataField="ReminderSettings.ExcludeForfeitures"
                        editorType="dxCheckBox">
              </dxi-item>
            </dxi-item>

            <dxi-item dataField="chargeClassList"
                      [colSpan]="2"
                      [label]="{text: 'Choose charge classes'}"
                      [template]="'chargeClassListTemplate'"
                      [visible]="chargeClassDropdownVisible">
            </dxi-item>

            <div *dxTemplate="let data of 'chargeClassListTemplate'">
              <dx-drop-down-box
                [value]="filterChargeClasses"
                [dataSource]="chargeClasses"
                valueExpr="Id"
                displayExpr="Text">
                <div *dxTemplate="let data of 'content'">
                  <dx-data-grid [dataSource]="chargeClasses"
                                [columns]="['Text']"
                                [selection]="{ mode: 'multiple' }"
                                [paging]="{ enabled: true, pageSize: 7 }"
                                [scrolling]="{ mode: 'infinite' }"
                                [height]="300"
                                [(selectedRowKeys)]="filterChargeClassIds"
                                (onSelectionChanged)="onChargeClassSelectionChanged($event)">
                  </dx-data-grid>
                </div>
              </dx-drop-down-box>
            </div>
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2" caption="">
              <dxi-item [label]="{text: 'Show Balance and upcoming payments'}"
                        [visible]="formData.ReminderForCode==='GpsCheckin'"
                        dataType="boolean"
                        dataField="ShowAccounting"
                        editorType="dxCheckBox">
              </dxi-item>

              <dxi-item [label]="{text: 'Show Upcoming Court Information'}"
                        [visible]="formData.ReminderForCode==='GpsCheckin'"
                        dataType="boolean"
                        dataField="ShowCourtInfo"
                        editorType="dxCheckBox">
              </dxi-item>
            </dxi-item>

            <div *dxTemplate="let data of 'sliderTemplate'">
              <dx-slider *ngIf="formData.ReminderForCode==='GpsCheckin' && formData.ReminderSettings.FacialRecognition"
              [step]="5" [min]="50" [max]="100" [(value)]="formData.ReminderSettings.SimilarityThreshold" [rtlEnabled]="false">
                <dxo-label
                [visible]="true"
                [format]="formatSliderLabel"
                position="top"
                ></dxo-label>
                <dxo-tooltip
                  [enabled]="true"
                  [format]="formatSliderLabel"
                  showMode="always"
                  position="bottom"
                ></dxo-tooltip>
              </dx-slider>
            </div>

            <ng-container *ngIf="formData.maxNumberOfReminders > 1">
              <dxi-item itemType="group" [colCount]="2" [colSpan]="2" [caption]="reminder2Caption">
                <dxi-item
                  [label]="{ text: reminderDaysCaption }"
                  [visible]="true"
                  dataField="ReminderConfigurations[1].DeliveryInterval"
                  editorType="dxNumberBox"
                  [width]="'40%'"
                  [editorOptions]="{
                    min:1,
                    showSpinButtons:true
                  }">
                </dxi-item>
                <dxi-item
                  [label]="{ text: '&nbsp;', visible: true }"
                  [visible]="true"
                  dataField="ReminderConfigurations[1].DeliveryFrequency"
                  editorType="dxSelectBox"
                  [width]="'60%'"
                  [editorOptions]="{
                    items: reminderIntervalFrequencies,
                    valueExpr:'Id',
                    displayExpr:'Text',
                    searchEnabled: false,
                    showClearButton: false
                  }">
                </dxi-item>
                <dxi-item
                  [label]="{ text: 'Message Template' }"
                  [colSpan]="2"
                  [visible]="true"
                  dataField="ReminderConfigurations[1].NotificationTemplateId"
                  editorType="dxSelectBox"
                  [editorOptions]="{
                    items: notificationTemplates,
                    valueExpr:'Id',
                    displayExpr:'TemplateName',
                    searchEnabled: false,
                    showClearButton: false
                  }">
                </dxi-item>
                <dxi-item
                  [label]="{ text: 'Additional Text' }"
                  [colSpan]="2"
                  [visible]="formData.AllowCustomText"
                  dataField="ReminderConfigurations[1].CustomText"
                  editorType="dxTextArea">
                </dxi-item>
              </dxi-item>
            </ng-container>

            <dxi-item
              itemType="empty"
              [colSpan]="2"
              [visible]="formData.maxNumberOfReminders <= 1">
            </dxi-item>
          </dx-form>
        </dx-validation-group>

        <div class="text-center" style="margin-bottom: 16px; margin-right: 16px;">
          <button mat-button (click)="previewMessage($event,formData)">
            Preview Message
            <i class="fas fa-sms"></i>
          </button>

          <button
            mat-raised-button
            color="primary"
            (click)="save(formData.ReminderForCode)">
            Save Settings
          </button>
        </div>
      </div>
    </dx-scroll-view>

  </div>
</dx-popup>

<dx-popup
    #messagePopup
    id="messagePopup"
    [showTitle]="true"
    title="Send Test Message"
    [closeOnOutsideClick]="false"
    [(visible)]="popupTestMessageVisible"
    [width]="500"
    [height]="400">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
        #scrlPreview
        id="scrlPreview"
        [useNative]="false">

      <dx-validation-group #previewValGroup>
        <div class="row">
          <div class="col-md-5 text-right">
            <label class="control-label">Mobile Number</label>
          </div>
          <div class="col-md-7">
            <dx-text-box
                #txNumberPreview
                id="txNumberPreview"
                [mask]="phoneMask"
                maxLength="20"
                mode="tel"
                maskInvalidMessage="Please enter a valid phone number"
                [useMaskedValue]="false"
                [(value)]="defaultNumber"
                isValid="true"
                maskChar="_">
              <dx-validator name="Number Required">
                <dxi-validation-rule
                  type="custom"
                  message="Number Required"
                  [validationCallback]="checkNumberValid">
                </dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>

        <dx-radio-group
            #previewOption
            [items]="reminderCountOptions"
            [value]="reminderCountOptions[0]"
            layout="horizontal"
            style="margin: 16px 0;"
            (onValueChanged)="TestMessageOptionChanged($event)">
        </dx-radio-group>

        <dx-text-area
            #txMessagePreview
            id="txMessagePreview"
            placeholder="Message Preview"
            [disabled]="false"
            [(value)]="msgPreview"
            [height]="80"
            stylingMode="outlined">
        </dx-text-area>

        <div class="text-center" style="margin-top: 16px;">
          <button mat-button
                  (click)="createDemoMessage()"
                  style="margin-right: 8px;"
                  [disabled]="busySending"
                  *ngIf="isGpsCheckinDemo">
            <i class="fal fa-portrait"></i>
            Try Check In Demo
          </button>

          <button
            mat-raised-button
            color="primary"
            (click)="sendTestMessage()"
            [disabled]="busySending">
            <i class="fal fa-share-square icon-spacer"></i>
            Send Message
          </button>
        </div>

        <p class="text-center" style="margin-top: 16px;" *ngIf="isGpsCheckinDemo">
          Personalized links are created per event, first click Try Check In Demo before Sending
        </p>
      </dx-validation-group>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [showTitle]="true"
  [title]="'Subscribe to Reminder Service'"
  [closeOnOutsideClick]="false"
  [(visible)]="showSubscribeConfirmation"
  maxWidth="600px"
  [maxHeight]="showBuyNumber ? '550px' : '250px'"
  [showCloseButton]="false"
  (onHidden)="subscribePopupHidden()">
  <div *dxTemplate="let data of 'content'">
    <app-smooth-height [trigger]='showBuyNumber'>
    <div class="text-center" *ngIf="!showBuyNumber">
      You are enabling automated reminders.
      There is a per message cost depending on the type of reminder.
      Click subscribe to continue.
    </div>
    <br>
    <div *ngIf="showBuyNumber">
      <app-reminder-number-buyer [reminderNumbers]="reminderNumbers" (onSuccess)="numberBought()"></app-reminder-number-buyer>
    </div>
    <br>
    <div class="text-center" *ngIf="!showBuyNumber">
      <dx-button
        id="cancel"
        type="normal"
        [text]="'Never Mind'"
        icon=""
        style="margin-right: 8px;"
        [disabled]='confirming'
        (onClick)="YesNoConfirmResult(false)">
      </dx-button>
      <dx-button
        id="save"
        type="default"
        [width]="'200px'"
        [text]="'Subscribe Now'"
        [disabled]="confirming"
        icon="fal fa-check"
        (onClick)="YesNoConfirmResult(true)">
      </dx-button>
    </div>
    </app-smooth-height>
  </div>
</dx-popup>

<dx-popup
  [showTitle]="'true'"
  [title]="'New Event Auto Reminder'"
  [closeOnOutsideClick]="'true'"
  [(visible)]="newReminderVisible"
  width="auto"
  height="auto"
  [showCloseButton]="'true'">
  <form class="page-container" (ngSubmit)="saveReminderType()">
    <mat-form-field class="filled-form">
      <mat-label>New Event Auto Reminder Name (Event Type)</mat-label>
      <input matInput
             placeholder="Template Name"
             #templateName
             [(ngModel)]="newReminderName"
             [disabled]="isBusy"
             name="TemplateName"
             required>
    </mat-form-field>
    <mat-form-field class="filled-form">
      <mat-label>Reminder Template</mat-label>
      <textarea matInput
                matTextareaAutosize
                matAutosizeMinRows="2"
                matAutosizeMaxRows="5"
                placeholder="Text Message Template"
                [(ngModel)]="newReminderText"
                (blur)="getCaretPos(templateBody)"
                [disabled]="isBusy"
                name="Message"
                #templateBody
                required>
      </textarea>
      <mat-hint align="end">{{ templateBody.value.length }} characters</mat-hint>
    </mat-form-field>

    <h6>Message Custom Fields</h6>
    <mat-chip-list>
      <mat-chip *ngFor="let field of newReminderFields"
                (click)="appendMessageTermForNewReminder('{' + field.FriendlyFieldName + '}')"
                [disabled]="isBusy">
        {{ field.FriendlyFieldName }}
      </mat-chip>
    </mat-chip-list>
    <div fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="8px"
      style="margin-top: 8px;">
      <button type="submit"
              mat-raised-button
              color="primary"
              [disabled]="!newReminderName || !newReminderText || isBusy">
        Save New Reminder Type
      </button>
    </div>
  </form>
</dx-popup>

<dx-popup
  [showTitle]="true"
  class="popup-component"
  title="Payment Link Settings"
  [closeOnOutsideClick]="false"
  [(visible)]="paymentLinkSettingsVisible"
  width="50%"
  height="45%"
>
  <div *dxTemplate="let data of 'content'">
    <dx-validation-group #paymentLinkSettingsValGroup>
      <div class="container-fixed">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <label for="payment-link-radio-group" class="dx-field-item-label-text">Payment Link</label>
            <mat-radio-group
              class="payment-link-radio"
              id="payment-link-radio-group"
              color="primary"
              (change)="paymentLinkSettingsOptionSelected($event)"
              aria-label="Payment Link"
              [(ngModel)]="paymentLinkSettings.PaymentLinkCustomEnabled">
              <mat-radio-button [value]="false" class="payment-link-radio-button">
              <span class="radio-label">
                Use system-generated customized link <a *ngIf="!gatewayEnabled" (click)="activateCCProcessing()">(requires Payment Processing setup)</a>
              </span>
              </mat-radio-button>
              <mat-radio-button [value]="true" class="payment-link-radio-button">
              <span class="radio-label">
                Use My Own Generic Payment Link
              </span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class='col-xs-12 col-sm-6' *ngIf="!paymentLinkSettings.PaymentLinkCustomEnabled">
            <label for='gatewaySelect' class='dx-field-item-label-text'>Payment Gateway</label>
            <dx-select-box
              id='gatewaySelect'
              class='pay-link-settings'
              [dataSource]='gateways'
              displayExpr='Name'
              [(value)]='paymentLinkSettings.PaymentLinkGateway'
              (onValueChanged)='paymentLinkSettingsOptionSelected($event)'
              [acceptCustomValue]='false'
              valueExpr='Id'
            ></dx-select-box>
          </div>
          <div class="col-xs-12 col-sm-6" *ngIf="paymentLinkSettings.PaymentLinkCustomEnabled">
            <label for='customUrl' class='dx-field-item-label-text'>Payment Url</label>
            <dx-text-box
              id='customUrl'
              class='pay-link-settings'
              [(value)]="paymentLinkSettings.PaymentLinkCustomUrl"
            >
              <dx-validator>
                <dxi-validation-rule type="custom" [validationCallback]="validateUrlRequired"
                                     message="Valid Url required with custom payment link"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
      </div>
      <div class="buttonbar-fixed" fxLayout="row" fxLayoutAlign="center center">
        <dx-button
          id="ok"
          type="default"
          [width]="136"
          text="Ok"
          icon="fal fa-check"
          (onClick)="closePaymentLinkPopup($event)"
        ></dx-button>
      </div>
    </dx-validation-group>
  </div>
</dx-popup>

<app-yes-no-confirmation
  #enableConfirm
  [showTitle]="true"
  [titleText]="'Continue to Finish Setup'"
  [(showConfirmation)]="showEnterCreditCardPopup"
  [yesText]="'Continue'"
  [noText]="'Nevermind'"
  [showCloseButton]="false"
  (onResult)="sendUserToSignupForDefMgrAndAutomatedReminders($event)"
  [confirmationMessage]="'This is a premium service and it looks like we don\'t have a credit card on file for you. Enter payment information on the following screen and you\'ll be redirected back here.  Thanks!'">
</app-yes-no-confirmation>

<app-yes-no-confirmation
  #disableConfirm
  [showTitle]="true"
  [titleText]="'Disable Reminders'"
  [confirmationMessage]="'Are you sure you want to disable reminders?'"
  [showConfirmation]="showDisableConfirmation"
>
</app-yes-no-confirmation>

<app-errorpopup
  [height]="'300'"
  [width]="'400'"
  [resizeEnabled]="true"
  [closeOnOutsideClick]="false"
  [title]="errorTitle"
  [shading]="false"
  [data]="errorData"
  [visible]="errorVisible"
></app-errorpopup>
