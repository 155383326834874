import {of, throwError,  Observable } from 'rxjs';
import {first, map, catchError, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';
import {CacheService} from './cache.service';

@Injectable()
export class RelatedRoutesService {
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService, private cache: CacheService) {}

  get(): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/relatedroutes`;
    const data = this.cache.get(url);
    if(!!data) {
      return of(JSON.parse(data)); // Return cache data as observables
    } else {
       let response =  this.http.get(url).pipe(
                                map((res: any) => res),
                                catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
      // this.cache.set(url, JSON.stringify(response));
      // response.take(1).subscribe(data => {
      //   this.cache.set(url, JSON.stringify(data));
      // });
    }
  }

  getByKey(key: string) {
    let url = `${this.settings.getBaseUrl()}/relatedroutes/${key}`;
    const data = this.cache.get(url);
    if(!!data) {
      return of(JSON.parse(data)); // Return cache data as observables
    } else {
       let response =  this.http.get(url).pipe(
                                map((res: any) => res),
                                catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
      // response.take(1).subscribe(data => {
      //   this.cache.set(url, JSON.stringify(data));
      // });
      return response;
    }
  }

  save(id: any, data: any): Observable<any> {
    let key = `systemConfigs`;
    let url = `${this.settings.getBaseUrl()}/${key}/${id}`;
    let response = this.http.put(url, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
    response.pipe(take(1)).subscribe(data => {
      this.cache.remove(key);
    });
    return response;
  }
}
