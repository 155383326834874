import {BaseModel} from './BaseModel';
import {Address} from './address';
import {Phone} from './phones';

export class Employer extends BaseModel {
  public Id: string;
  Name: string;
  Occupation: string;
  phones: Phone[];
  addresses: Address[];
  EmploymentLength: number;
  Supervisor: string;
  AnnualSalary: number;
  EmpUnion: string;
  Local: string;
  IsDefault: boolean;

  constructor(obj?: object) {
    super(obj);
    this.childrenNames = ['phones', 'addresses'];
    this.routeRoot = 'employers';

    if (obj == null) {
      this.Id = '';
      this.addresses = [];
      this.phones = [];
      this.IsDefault = false;

    }


  }

  public loadData(data) {
    this.fillFromObject(data, true);
    // console.log('employer.loaddata', 'employer loaded');

    const mainThis = this;
    if (data.phones.data !== null && data.phones.data.length > 0) {
      data.phones.data.forEach(
        (phones) => {
          const newPhone = new Phone();
          newPhone.fillFromObject(phones);
          mainThis.phones.push(newPhone);
          // console.log('employer.loaddata', 'phone loaded');
        });
    }

    if (data.addresses.data !== null && data.addresses.data.length > 0) {
      data.addresses.data.forEach(
        (addresses) => {
          const newAddress = new Address();
          newAddress.fillFromObject(addresses);
          mainThis.addresses.push(newAddress);
          // console.log('employer.loaddata', 'address loaded');
        });
    }
  }
}
