import {BaseModel} from './BaseModel';
import {ObjectUtilities} from '../shared/ObjectUtilities';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;
import {Address} from './address';
import { CashCollateralPaymentSchedule } from './cash-collateral-payment-schedule';

export class Collateral extends BaseModel {
  public Id: string;
  public TransactionId: any;
  public Status: string;
  public Type: string;
  public Description: string;
  public Location: string;
  public Value: number;
  public Release: string;
  public Legal: string;
  public Parcel: string;
  public addresses : Address[] = []; //em to use the adderss comp - however we will always only have one address
  public Recorded: any;
  public Returned: any;
  public Comment: string;
  public Year: string;
  public Make: string;
  public Model: string;
  public Color: string;
  public Vin: string;
  public Plate: string;
  public Decal: string;
  public Serial: string;
  public Method: string;
  public ContactId: string;
  public ContactType: string;
  public SecondContactId: string;
  public SecondContactType: string;
  public HeldBy: string;
  public HeldById: string;
  public ReceiptNumber: string;
  public Zip: string;
  public SeizeDate: any;
  public BondId: string;
  public StateCode: string;
  public County: string;
  public City: string;
  public RecordStatus: string;
  public rowId: any; //used by grid internaly
  Identifier: string;
  public AmountConverted: number;
  cashcollateralpaymentschedule: CashCollateralPaymentSchedule;


  constructor(obj?: object) {
    super(obj);
    this.childrenNames = ['addresses', 'cashcollateralpaymentschedule', 'Identifier'];
    this.routeRoot = 'collaterals';

    if (obj == null) {
      this.Id = '';
      this.addresses = [];
    }



  }

  public loadData(data) {
    const mainThis = this;
    this.fillFromObject(data, true);
    if (!!data.cashcollateralpaymentschedule && !!data.cashcollateralpaymentschedule.data) {
      const newSchedule = new CashCollateralPaymentSchedule();
      newSchedule.loadData(data.cashcollateralpaymentschedule.data);
      mainThis.cashcollateralpaymentschedule = newSchedule;
    }
  }
}
