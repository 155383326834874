<div>
	<div class="dx-fieldset">
    <div class="row">
      <div class="col-lg-3 fit-content">
        <div class="dx-field">
          <dx-select-box
            #personType
            (onSelectionChanged)="onPersonTypeChanged($event)"
            [dataSource]="personTypeList"
            [singleselect]="true"
            displayExpr="text"
            [value]="currentPersonType"
            placeholder="Select a Person Type"
            width="100%"
            valueExpr="value"
          >
          </dx-select-box>
        </div>
      </div>
      <div class="col-lg-3 fit-content">
        <div class="dx-field">
          <dx-select-box
            #dateRange
            (onSelectionChanged)="onDateRangeChanged($event)"
            [dataSource]="dateRangeList"
            [singleselect]="true"
            displayExpr="text"
            [value]="currentDateRange"
            placeholder="Select a Date Range"
            width="100%"
            valueExpr="value"
          >
          </dx-select-box>
        </div>
      </div>
  	</div>
	</div>
	<dx-data-grid
		#logGrid
	  id="logGrid"
	  [dataSource]="reminderlogs"
	  width="100%"
    [wordWrapEnabled]="true"
    [columnHidingEnabled]="true"
	  (onToolbarPreparing)="onToolbarPreparing($event)"
	  noDataText="No log records found."
  >
    <dxo-export [enabled]="false" [customizeExcelCell]="customizeExcelCell" [allowExportSelectedData]="false"></dxo-export>
  <dxi-column caption="Sent To" dataField="FullName" cellTemplate="fullNameTemplate" [hidingPriority]="7">
    <div *dxTemplate="let data of 'fullNameTemplate'">
      <a [routerLink]="data.data.url" [queryParams]="data.data.params" [skipLocationChange]="false">{{data.data.FullName}}</a>
    </div>
  </dxi-column>
    <dxi-column caption="Agency" dataField="AgencyName" [hidingPriority]="6"></dxi-column>
  <dxi-column caption="Person Type" dataField="PersonType" [hidingPriority]="5"></dxi-column>
  <dxi-column caption="From Number" dataType="string" dataField='FromNumber' [cellTemplate]="fromNumberCellTemplate" [hidingPriority]="4">
    <div *dxTemplate="let data of fromNumberCellTemplate">
      {{data.data.FromNumber || '--'}}
    </div>
  </dxi-column>
  <dxi-column caption="To Number" dataType="string" dataField='ToNumber' [cellTemplate]="toNumberCellTemplate" [hidingPriority]="3">
    <div *dxTemplate="let data of toNumberCellTemplate">
      {{data.data.ToNumber || '--'}}
    </div>
  </dxi-column>
  <dxi-column caption="Date" dataField="Created_At" dataType="date" [hidingPriority]="2"></dxi-column>
  <dxi-column caption="Source" dataField="MessageType" [hidingPriority]="1"></dxi-column>
  <dxi-column caption="Message" dataField="Message" [hidingPriority]="0"></dxi-column>
  <dxi-column dataField="Status" cellTemplate="statusCellTemplate" [hidingPriority]="8">
    <div *dxTemplate="let data of 'statusCellTemplate'">
      <span class="badge" [ngClass]="{'badge-red': data.data.Status !== 'delivered'}">{{data.data.Status | titlecase}}</span>
    </div>
  </dxi-column>

	<dxo-search-panel
    [visible]="true"
    [width]="300"
    [placeholder]="'Search'">
  </dxo-search-panel>

	</dx-data-grid>
</div>
