<div class="page-container" *ngIf="notificationTemplates && notificationTypes">
  <small>Templates have been carefully preconfigured.  Please be careful of changes which are made at your own risk.</small>
  <p></p>
  <span *ngIf="appSource === 'collections'">
  <mat-label>Select Reminder Langauge</mat-label>
  <mat-button-toggle-group #language="matButtonToggleGroup" [value]="currentLanguage" [disabled]="isNewTemplate">
    <mat-button-toggle value="en-US" aria-label="English" (change)="selectLanguage($event)">
     <img src="/assets/img/us-flag.png" width="40px"
          height="40px" alt="">
    </mat-button-toggle>
    <mat-button-toggle value="es-MX" aria-label="Spanish" (change)="selectLanguage($event)">
      <img src="/assets/img/mx-flag.png" width="40px"
      height="40px" alt="">
    </mat-button-toggle>
  </mat-button-toggle-group>
  <p></p>
</span>
  <mat-form-field class="filled-form" *ngIf="!isNewTemplate">
    <mat-label>Message</mat-label>
    <mat-select #viewTemplate
      (selectionChange)="selectTemplate(viewTemplate.value)"
      [(ngModel)]="selectedTemplate"
      [disabled]="isBusy">
      <mat-option *ngFor="let template of notificationTemplates" [value]="template">
        {{ template.TemplateName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <form (ngSubmit)="onSaveMessageTemplate()">
    <ng-container *ngTemplateOutlet="smsTemplate"></ng-container>

    <hr>

    <ng-container *ngTemplateOutlet="emailTemplate"></ng-container>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
      <button type="submit"
        mat-raised-button
        [color]="!selectedTemplateCopy.TemplateName || !selectedTemplateCopy.Message || isBusy ? undefined : 'primary'"
        [disabled]="!selectedTemplateCopy.TemplateName || !selectedTemplateCopy.Message || isBusy">
        <i class="fas fa-save icon-spacer"></i>
        Save
      </button>



      <span style="flex: auto 1 1;"></span>
      <button *ngIf="!isNewTemplate" mat-stroked-button type="button"
      color="primary"
      style="margin-bottom: 16px; margin-top: 16px;"
      (click)="addMessageTemplate()"
      fxFlexAlign="start"
      [disabled]="isBusy">
      New Template
      <i class="fas fa-comment-alt-medical icon-spacer"></i>
      </button>

      <button *ngIf="isNewTemplate" mat-stroked-button type="button"
      color="primary"
      style="margin-bottom: 16px; margin-top: 16px;"
      (click)="cancelMessageTemplate()"
      fxFlexAlign="start"
      [disabled]="isBusy">
      Cancel Template
      <i class="fal fa-times-circle icon-spacer"></i>
      </button>

      <button type="button"
        mat-button
        color="warn"
        (click)="onDeleteMessageTemplate()"
        [disabled]="isBusy || isNewTemplate">
        Delete Template
        <i class="fas fa-trash icon-spacer"></i>
      </button>
    </div>
  </form>
</div>

<app-yes-no-confirmation
  [showTitle]="true"
  [titleText]="'Delete Template?'"
  [(showConfirmation)]="confirmingDelete"
  [yesText]="'Delete'"
  [noText]="'Nevermind'"
  [showCloseButton]="false"
  (onResult)="onDeleteResult($event)"
  [confirmationMessage]="confirmationDeleteMessage">
</app-yes-no-confirmation>

<app-yes-no-confirmation
  [showTitle]="true"
  [titleText]="'Change Language?'"
  [(showConfirmation)]="confirmLangaugeChange"
  [yesText]="'Save'"
  [noText]="'Cancel'"
  [showCloseButton]="false"
  (onResult)="applyLanguageSelection($event)"
  [confirmationMessage]="confirmLanguageMessage">
</app-yes-no-confirmation>

<dx-popup
  #messagePopup
  id="messagePopup"
  [showTitle]="true"
  [title]="(testEmail) ? 'Send Test Email' : 'Send Test Message'"
  [closeOnOutsideClick]="false"
  [(visible)]="popupTestMessageVisible"
  [width]="500"
  [height]="400">
  <div *dxTemplate="let data of 'content'">
      <ng-container *ngIf="testEmail; else testingSms">
        <label class="control-label filled-form"
          style="margin-bottom: 16px">
          Test Email Address
          <dx-text-box
            #txEmail
            placeholder="Email"
            valueChangeEvent="keyup">
          </dx-text-box>
        </label>
      </ng-container>
      <ng-template #testingSms>
        <label class="control-label filled-form"
          style="margin-bottom: 16px">
          Mobile Number
          <dx-text-box
            #txNumberPreview
            [mask]="phoneMask"
            maxLength="20"
            mode="tel"
            maskInvalidMessage="Please enter a valid phone number"
            [useMaskedValue]="false"
            maskChar="_">
            <dx-validator name="Number Required">
              <dxi-validation-rule
                type="custom"
                message="Number Required"
                [validationCallback]="checkNumberValid">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </label>
      </ng-template>

      <label class="control-label filled-form">
        Message Preview
        <dx-text-area
          #txMessagePreview
          style="margin-bottom: 16px"
          stylingMode="outlined"
          placeholder="Message Preview"
          [(value)]="(testEmail) ? selectedTemplate.EmailMessage : selectedTemplate.Message">
        </dx-text-area>
      </label>

      <button
        mat-raised-button
        [color]="
          (busySending || (testEmail)
          ? !selectedTemplate.EmailMessage || !txEmail?.text
          : !selectedTemplate.Message || !txNumberPreview?.text)
          ? undefined : 'primary'"
        [disabled]="
          busySending || (testEmail)
          ? !selectedTemplate.EmailMessage || !txEmail?.text
          : !selectedTemplate.Message || !txNumberPreview?.text"
        (click)="sendTestMessage()"
        type="button"
        style="width: 100%;">
        Send Test Message
        <i [class]="(testEmail) ? 'fas fa-envelope icon-spacer': 'fas fa-sms icon-spacer'"></i>
      </button>
  </div>
</dx-popup>

<dx-popup
  [showTitle]="true"
  [title]="'Subscribe to Reminder Service'"
  [closeOnOutsideClick]="false"
  [(visible)]="showSubscribeConfirmation"
  [maxWidth]="'500px'"
  maxHeight="250px"
  [showCloseButton]="false">
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-12">
        <label class="control-label text-center">
          You are enabling automated reminders.
          There is a per message cost depending on the type of reminder.
          Click subscribe to continue.
        </label>
      </div>
    </div>
    <br>
    <div class="text-center">
      <dx-button id="cancel" type="normal" [text]="'Never Mind'" icon=""
                 style="margin-right: 8px;"
                 (onClick)="YesNoConfirmResult(false)"></dx-button>
      <dx-button id="save" type="default" [width]="'200px'" [text]="'Subscribe Now'" icon="fal fa-check"
                 (onClick)="YesNoConfirmResult(true)"></dx-button>
    </div>
  </div>
</dx-popup>

<div class="loader" *ngIf="!notificationTemplates && !notificationTypes">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>

<ng-template #smsTemplate>
  <mat-form-field class="filled-form">
    <mat-label>SMS Message Name</mat-label>
    <input matInput
      placeholder="Text Message Template Name"
      #templateName
      [(ngModel)]="selectedTemplateCopy.TemplateName"
      [disabled]="isBusy"
      required>
  </mat-form-field>

  <mat-form-field class="filled-form">
    <mat-label>Message Type</mat-label>
    <mat-select
      [(ngModel)]="selectedTemplateCopy.MessageTypeId"
      [disabled]="isBusy">
      <mat-option *ngFor="let type of notificationTypes" [value]="type.Id">
        {{ type.Text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="filled-form">
    <mat-label>SMS Message</mat-label>
    <textarea matInput
      matTextareaAutosize
      matAutosizeMinRows="2"
      matAutosizeMaxRows="5"
      (focus)="showFields = 'message'"
      (blur)="getCaretPos(templateBodySms)"
      placeholder="Text Message Template"
      [(ngModel)]="selectedTemplateCopy.Message"
      [disabled]="isBusy"
      #templateBodySms
      required
      maxlength="275">
    </textarea>
    <mat-hint align="end">{{ templateBodySms.value.length }}/275 characters</mat-hint>
  </mat-form-field>
  <ng-container *ngIf="selectedTemplateCopy?.MessageTypeSource === 'collections'; else notCollections">
    <div [@slideInOut] *ngIf="!showFields || showFields === 'message'">
      <h6 *ngIf="notificationTypes">Message Custom Fields</h6>
      <ng-container *ngFor="let type of notificationTypes">
        <mat-chip-list *ngIf="templateCodeWithoutSpaces === type.Code">
          <mat-chip
            *ngFor="let field of type.fields.data"
            (click)="appendMessageTerm('{' + field.Text + '}', 'Message')"
            [disabled]="isBusy">
            {{ field.Text }}
          </mat-chip>
        </mat-chip-list>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #notCollections>
    <h6 *ngIf="defaultType?.fields.data.length">Message Custom Fields</h6>
    <mat-chip-list>
      <mat-chip *ngFor="let field of defaultType?.fields.data"
        (click)="appendMessageTerm('{' + field.Text + '}', 'Message')"
        [disabled]="isBusy">
        {{ field.Text }}
      </mat-chip>
    </mat-chip-list>
  </ng-template>

  <div style="margin: 16px 0;">
    <button type="button"
      mat-stroked-button
      color="primary"
      (click)="showSendTestMessage()"
      [disabled]="isBusy">
      Send Test Message
      <i class="fas fa-sms icon-spacer"></i>
    </button>
  </div>
</ng-template>

<ng-template #emailTemplate>
  <ng-container *ngIf="selectedTemplateCopy?.MessageTypeSource === 'collections'">
    <mat-form-field class="filled-form">
      <mat-label>Email Subject</mat-label>
      <input matInput
        placeholder="Email Subject"
        #emailSubject
        [(ngModel)]="selectedTemplateCopy.EmailSubject"
        [disabled]="isBusy"
        (focus)="showFields = 'subject'"
        required>
    </mat-form-field>
    <div [@slideInOut] *ngIf="showFields === 'subject'" style="margin-bottom: 16px;">
      <h6 *ngIf="notificationTypes">Subject Custom Fields</h6>
      <ng-container *ngFor="let type of notificationTypes">
        <mat-chip-list *ngIf="templateCodeWithoutSpaces === type.Code">
          <mat-chip
            *ngFor="let field of type.fields.data"
            (click)="appendMessageTerm('{' + field.Text + '}', 'EmailSubject')"
            [disabled]="isBusy">
            {{ field.Text }}
          </mat-chip>
        </mat-chip-list>
      </ng-container>
    </div>

    <mat-form-field class="filled-form">
      <mat-label>Email</mat-label>
      <textarea matInput
        matTextareaAutosize
        matAutosizeMinRows="2"
        matAutosizeMaxRows="5"
        (blur)="getCaretPos(templateBodyEmail)"
        placeholder="Email"
        [(ngModel)]="selectedTemplateCopy.EmailMessage"
        [disabled]="isBusy"
        (focus)="showFields = 'email'"
        #templateBodyEmail
        required>
      </textarea>
      <mat-hint align="end">{{ templateBodyEmail.value.length }} characters</mat-hint>
    </mat-form-field>

    <div [@slideInOut] *ngIf="showFields === 'email'">
      <h6 *ngIf="notificationTypes">Email Custom Fields</h6>
      <ng-container *ngFor="let type of notificationTypes">
        <mat-chip-list *ngIf="templateCodeWithoutSpaces === type.Code">
          <mat-chip
            *ngFor="let field of type.fields.data"
            (click)="appendMessageTerm('{' + field.Text + '}', 'EmailMessage')"
            [disabled]="isBusy">
            {{ field.Text }}
          </mat-chip>
        </mat-chip-list>
      </ng-container>
    </div>

    <div style="margin: 16px 0;">
      <button type="button"
        mat-stroked-button
        color="primary"
        (click)="showSendTestMessage(true)"
        [disabled]="isBusy">
        Send Test Email
        <i class="fas fa-envelope icon-spacer"></i>
      </button>
    </div>
  </ng-container>
</ng-template>
