import { Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

export interface NumberConfirmationDataInterface {
  message: string;
  title: string;
  component?: ComponentType<Component>;
}

@Component({
  selector: 'app-number-confirmation',
  templateUrl: './number-confirmation.component.html',
  styleUrls: ['./number-confirmation.component.scss'],
})
export class NumberConfirmationComponent implements OnInit, OnDestroy {

  yesText = 'OK';
  noText = 'CANCEL';
  buttonsDisabled = true;

  private element: HTMLElement;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NumberConfirmationDataInterface,
    private viewContainerRef: ViewContainerRef,
  ) {

  }

  ngOnInit(): void {
    this.element = this.viewContainerRef.element.nativeElement;
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1600';
  }

  ngOnDestroy(): void {
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1000';
  }

  numberBought(): void{
    this.buttonsDisabled = false;
  }

}
