import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';

import { Preferences } from '../../../models/preferences';

import { AuthService } from '../../../services/auth.service';
import { DocumentService } from '../../../services/document.service';
import { PreferencesService } from '../../../services/preferences.service';
import { FileUtilities } from '../../../shared/FileUtilities';
import { BillingService } from '../../../services/billing.service';
import { AppConstants } from '../../../shared/AppConstants';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { EventService } from '../../../services/event.service';
import { CacheService } from '../../../services/cache.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  public currentUser = new Preferences();
  // Todo: This doesn't make any sense. Why are we managing two instances of Preferences when we can just use current user. Please refactor.
  public editedUser = new Preferences();
  public defaultAvatar = '/assets/img/defaultuser-flat.teal.svg';
  public editingProfile = false;
  public sendingReset = false;
  public resetPassword = false;
  public savingProfile = false;
  public uploadedImage: any;
  public subscribedToBail = true;
  // TODO: move to a service..
  public shortcutOptions = [];
  public shortcutDefaults = [
    {
      'optionText': 'Add Defendant',
      'optionIcon': 'fal fa-user-plus',
      'optionUrl': 'defendant-data-capture',
      'optionType': 'pagelink',
      'selected': false,
    }, {
      'optionText': 'Take Payment',
      'optionIcon': 'fal fa-dollar-sign',
      'optionUrl': 'payment',
      'optionType': 'popup',
      'selected': false,
    }, {
      'optionText': 'Check In',
      'optionIcon': 'fal fa-user-clock',
      'optionUrl': 'checkin',
      'optionType': 'popup',
      'selected': false,
    }, {
      'optionText': 'Prospects',
      'optionIcon': 'fal fa-users',
      'optionUrl': 'prospects',
      'optionType': 'pagelink',
      'selected': false,
    }, {
      'optionText': 'Powers',
      'optionIcon': 'fal fa-file-powerpoint',
      'optionUrl': 'inventoryintake',
      'optionType': 'pagelink',
      'selected': false,
    }, {
      'optionText': 'Reporting',
      'optionIcon': 'fal fa-file-chart-line',
      'optionUrl': 'reporting',
      'optionType': 'pagelink',
      'selected': false,
    }
  ];

  @Output() shortcutEvent: EventEmitter<null> = new EventEmitter();

  profileForm: FormGroup;

  constructor(
    private authService: AuthService,
    private docService: DocumentService,
    private prefService: PreferencesService,
    private billingService: BillingService,
    private eventService: EventService,
    private cache: CacheService,
  ) {
    super();
  }

  ngOnInit() {
    this.profileForm = new FormGroup({
      Email: new FormControl('', [ ]),
      FirstName: new FormControl('', [ ]),
      LastName: new FormControl('', [ ]),
      MobileNumber: new FormControl('', [ ]),
      Enable2FA: new FormControl(false, [ ]),
    });
    this.eventService.enable2FASet$.subscribe(enable => {
      this.currentUser.Enable2FA = enable;
      this.editedUser.Enable2FA = enable;
      this.profileForm.patchValue({Enable2FA: enable});
      this.cache.remove(`preferences/user/${this.currentUser.Id}` );
    });

    this.profileForm.controls.Email.disable();

    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM).subscribe(isSubbed => {
      this.subscribedToBail = isSubbed;
    });
    this.getUserPref();
  }

  getUserPref() {
    const userId = localStorage.getItem('identityId');

    if (!!userId) {
      this.subs.sink = this.prefService.getPreferences(userId).subscribe(res => {
        if (res) {
          this.currentUser = new Preferences(res.data);

          if (!this.currentUser.ProfilePic) {
            localStorage.setItem('profilePicData', this.defaultAvatar);
            this.profileForm.patchValue({
              Email: this.currentUser.Email,
              FirstName: this.currentUser.FirstName,
              LastName: this.currentUser.LastName,
              MobileNumber: this.currentUser.MobileNumber,
              Enable2FA: this.currentUser.Enable2FA,
            });
            this.currentUser = new Preferences(res.data);
            this.editedUser = new Preferences(res.data);
            this.currentUser.ProfilePicData = localStorage.getItem('profilePicData');
            this.editedUser.ProfilePicData = localStorage.getItem('profilePicData');
          } else {
            this.subs.sink = this.docService.retrieveDocumentDirect(this.currentUser.ProfilePic)
              .subscribe(resp => {
                if (resp.file) {
                  const base64image = FileUtilities.base64ToDataUrl(
                    'image/jpeg',
                    FileUtilities.arrayBufferToBase64String(resp.file.Body),
                  );
                  localStorage.setItem('profilePicData', base64image);
                  this.currentUser = new Preferences(res.data);
                  this.editedUser = new Preferences(res.data);
                  this.profileForm.patchValue({
                    Email: this.currentUser.Email,
                    FirstName: this.currentUser.FirstName,
                    LastName: this.currentUser.LastName,
                    MobileNumber: this.currentUser.MobileNumber,
                    Enable2FA: this.currentUser.Enable2FA,
                  });
                  this.currentUser.ProfilePicData = base64image;
                  this.editedUser.ProfilePicData = base64image;
                }
              });
          }
        }
      });
    }
  }

  editProfile() {
    this.editingProfile = true;
  }

  uploadProfilePic(event: any) {
    const image = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = () => {
      this.editedUser.ProfilePicData = fileReader.result;
    };

    this.uploadedImage = image;

    fileReader.readAsDataURL(image);

    fileReader.onloadend = (
      (e: any) => {
        // this.uploadedImage = e.target.result;
        this.editedUser.ProfilePicData = e.target.result;
      });
  }

  forgotPassword() {
    this.sendingReset = true;
    this.subs.sink = this.authService.ForgotPassword(localStorage.getItem('userEmail')).subscribe(log => {
        this.resetPassword = true;
      },
      err => {
        console.error(err);
        this.resetPassword = false;
        this.sendingReset = false;
      });
  }

  savePreferences(formGroup: any) {
    this.savingProfile = true;
    this.editedUser.Enable2FA = this.profileForm.controls.Enable2FA.value;

    if (!!this.uploadedImage) {
      this.subs.sink = this.docService.addDocumentDirect(this.uploadedImage).subscribe(resp => {
        if (resp.success) {
          this.currentUser.ProfilePic = resp.data.Key;
          this.editedUser.ProfilePic = resp.data.Key;
          this.doSavePref();
        } else {
          console.error('Unable to upload profile picture.', resp);
        }
      });
    } else {
      this.doSavePref();
    }
  }

  doSavePref() {
    this.subs.sink = this.prefService.savePreferences(this.editedUser).subscribe(res => {
      if (res) {
        this.currentUser = this.editedUser;
        this.editingProfile = false;
        this.savingProfile = false;
        this.getUserPref();
        localStorage.setItem('userName', this.editedUser.FirstName + ' ' + this.editedUser.LastName);
        localStorage.setItem('userEmail', this.editedUser.Email);
      }
    }, err => {
      console.error('error saving preferences', err);
      this.savingProfile = false;
    });
  }

  resetPreferences() {
    // console.log(this.editedUser);
    this.editedUser = new Preferences();
    for (const attr in this.currentUser) {
      if (this.currentUser.hasOwnProperty(attr)) {
        this.editedUser[attr] = this.currentUser[attr];
      }
    }
    this.editingProfile = false;
  }

  logout() {
    this.authService.Logout();
  }
}
