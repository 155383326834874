<dx-data-grid id="gridContainer"
              [dataSource]="dataSource"
              (onExporting)="toggleColumnsForExport($event)"
              (onExported)="onExported($event)"
              [columnAutoWidth]="true"
              [loadPanel]="{enabled: true, showIndicator: true, showPane: true, text: 'Loading...'}">
  <dxo-scrolling
    mode="standard"
    [showScrollbar]="'always'"
  >
  </dxo-scrolling>
  <dxo-export [enabled]="true" [fileName]="exportFileName" [allowExportSelectedData]="true"></dxo-export>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Search"></dxo-search-panel>
  <dxo-selection mode="multiple"></dxo-selection>
  <dxo-group-panel [visible]="'auto'"></dxo-group-panel>

  <dxi-column dataField="AuditStamp" caption="Date" dataType="date" format="M/d/y h:mm a"></dxi-column>
  <dxi-column dataField="AuditSource" caption="Record Type"></dxi-column>
  <dxi-column dataField="AuditType" caption="Event"></dxi-column>
  <dxi-column dataField="AuditUser" caption="User"></dxi-column>
  <dxi-column dataField="OldValues" [visible]="false" caption="Old Values" [encodeHtml]="false"></dxi-column>
  <dxi-column dataField="NewValues" [visible]="false" caption="New Values" [encodeHtml]="false"></dxi-column>
  <dxi-column dataField="ParsedOldValues" caption="Old Values" [encodeHtml]="false" [allowExporting]="false"></dxi-column>
  <dxi-column dataField="ParsedNewValues" caption="New Values" [encodeHtml]="false" [allowExporting]="false"></dxi-column>

</dx-data-grid>

<app-errorpopup [height]="'300'"
                [width]="'400'"
                [resizeEnabled]="true"
                [title]="errorTitle"
                [shading]="false"
                [data]="errorData"
                [visible]="errorVisible"
                [closeOnOutsideClick]="false">
</app-errorpopup>
