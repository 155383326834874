import {BaseModel} from './BaseModel';

export class Address extends BaseModel {
  Id: any;
  public SourceId: string;
  public SourceType: string;
  public AddressLine1: string;
  public AddressLine2: string;
  public City: string;
  public County: string;
  public StateCode: string;
  public Zip: string;

  public Duration: number;
  public AddressType: any;
  public IsPhysical: boolean;
  public IsDefault: boolean;
  public IsBad: boolean;
  public CustomerId: number;
  public Inactive: boolean;
  public rowId: any;
  public wasCopied: boolean = false;

  public singleLineAddress() {
    let formattedAddress = '';

    if (this.AddressLine1 && this.AddressLine1 !== '') {
      formattedAddress = this.AddressLine1;
    }

    if (this.AddressLine2 && this.AddressLine2 !== '') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + this.AddressLine2;
    }

    if (this.City && this.City !== '') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + this.City;
    }

    if (this.StateCode && this.StateCode !== '') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + this.StateCode;
    }

    if (this.Zip && this.Zip !== '') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + this.Zip;
    }

    return formattedAddress;
  }

  constructor(obj?: object) {
    super(obj);

    this.routeRoot = 'addresses';

    if (obj == null) {
      this.IsDefault = false;
      this.IsBad = false;
      this.IsPhysical = true;
      this.AddressLine1 = '';
      this.AddressLine2 = '';
      this.City = '';
      this.StateCode = '';
      this.County = '';
      this.Zip = '';
    }

  }

}
