import { Component, Input, OnInit } from '@angular/core';
import { AppConstants } from '../../shared/AppConstants';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AdiDialogComponent } from '../adiv2/adi-dialog/adi-dialog.component';
import { UserService } from '../../services/user.service';
import { BillingService } from '../../services/billing.service';
import { AdiSettingsService } from '../../services/adisettings.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-reminder-prompt',
  templateUrl: './reminder-prompt.component.html',
  styleUrls: ['./reminder-prompt.component.scss'],
})
export class ReminderPromptComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() promptSource: 'CourtDate' | 'PaymentReminder' | 'Checkin';

  customerHideRemindersPrompt = true;
  user: any;
  showPrompt$: Observable<boolean>;
  isExtraSmall: boolean;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private billingService: BillingService,
    private adi: AdiSettingsService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isExtraSmall = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
    this.shouldShowPrompt();
  }

  shouldShowPrompt() {
    const user$: Observable<boolean> = this.userService.current().pipe(
      map(res => {
        this.user = res.data;
        let hide = true;
        switch (this.promptSource) {
          case 'CourtDate':
            hide = res.data.HideCourtDatePrompt;
            break;
          case 'PaymentReminder':
            hide = res.data.HidePaymentReminderPrompt;
            break;
          case 'Checkin':
            hide = res.data.HideCheckinPrompt;
            break;
        }
        return hide;
      }),
    );
    const adi$: Observable<boolean> = this.adi.get().pipe(
      map(res => {
        const config = res.data[0].automaticnotificationsettings.data;
        let enabled = false;
        switch (this.promptSource) {
          case 'CourtDate':
            enabled = config.CourtDateEnabled;
            break;
          case 'PaymentReminder':
            enabled = config.PaymentReminderEnabled;
            break;
          case 'Checkin':
            enabled = config.CheckinEnabled || config.CheckinGpsEnabled;
            break;
        }
        return enabled;
      }),
    );
    const reminders$: Observable<boolean> = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_TEXT_MESSAGING);
    this.showPrompt$ = combineLatest([
      user$,
      adi$,
      reminders$,
    ]).pipe(
      map(([hidden, enabled, remindersEnabled]) => {
        if (hidden) {
          return false;
        } else {
          // If reminders are globally enabled we need to check the individual reminder type.
          if (remindersEnabled) {
            // If the specific reminder type is enabled we need to hide the upsell
            if (enabled) {
              return false;
            }
          }
          return true;
        }
      }),
    );
  }

  openRemindersSettings() {
    const dialogConfig: MatDialogConfig = {
      width: '85%',
      hasBackdrop: true,
      disableClose: true,
      data: {
        type: this.promptSource,
      },
    };

    if (this.isExtraSmall) {
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
    }

    const dialogRef = this.dialog.open(AdiDialogComponent, dialogConfig);

    dialogRef.beforeClosed().toPromise().then(async () => {
      await this.shouldShowPrompt();
    });

  }

  dontShowRemindersPopUp() {
    this.customerHideRemindersPrompt = true;
    switch (this.promptSource) {
      case 'CourtDate':
        this.user.HideCourtDatePrompt = 1;
        break;
      case 'PaymentReminder':
        this.user.HidePaymentReminderPrompt = 1;
        break;
      case 'Checkin':
        this.user.HideCheckinPrompt = 1;
        break;
    }
    this.userService.updateUser(this.user.Id, this.user)
      .then(() => {
        this.showPrompt$ = of(false);
      });
  }

}
