import {formatNumber} from 'libphonenumber-js';
import * as moment from 'moment';


let formatter = require('devextreme/localization/date.js');

export module StringUtilities {

  export const phoneFormat = '(000) 000-0000';

  export const dateFormatter = {
    parser: function (value) {
      let parts = value.split('/');
      if (3 !== parts.length) {
        return;
      }
      let currentYear = parseInt(new Date().getFullYear().toString().slice(2));
      if (parseInt(parts[2]) > (currentYear + 10)) {
        return new Date(parts[2].length < 3 ? ((parts[2].length < 2) ? Number('190' + parts[2]) : Number('19' + parts[2])) : ((parts[2].length < 4) ? Number('1' + parts[2]) : Number(parts[2])), Number(parts[0]) - 1, Number(parts[1]));
      } else {
        return new Date(parts[2].length < 3 ? ((parts[2].length < 2) ? Number('200' + parts[2]) : Number('20' + parts[2])) : ((parts[2].length < 4) ? Number('2' + parts[2]) : Number(parts[2])), Number(parts[0]) - 1, Number(parts[1]));
      }
    },
    formatter: function (value) {
      return formatter.format(value, 'shortdate');
    }
  };

  export const dateTimeFormatter = {
    parser: function (value) {
      let currentYear = parseInt(new Date().getFullYear().toString().slice(2));
      let parts = value.split('/');
      let year = parts[2];
      if (3 !== parts.length) {
        return;
      }
      if (year.includes(',')) {
        year = year.split(',')[0];
      }
      if (parseInt(year) > (currentYear + 10)) {
        return new Date(year.length < 3 ? ((year.length < 2) ? Number('190' + year) : Number('19' + year)) : ((year.length < 4) ? Number('1' + year) : Number(year)), Number(parts[0]) - 1, Number(parts[1]));
      } else {
        return new Date(year.length < 3 ? ((year.length < 2) ? Number('200' + year) : Number('20' + year)) : ((year.length < 4) ? Number('2' + year) : Number(year)), Number(parts[0]) - 1, Number(parts[1]));
      }
    },
    formatter: function (value) {
      return formatter.format(value, 'shortDateShortTime');
    }
  };

  export function getCurrentDateString() {
    return moment().format('YYYY-MM-DD');
  }

  export function getCurrentDateTimeString() {
    return moment().format('YYYY-MM-DD HH:mm');
  }

  export function formatDateTimeString(date: Date) {
    return (date).toISOString().slice(0, 10) + ' ' + (new Date()).toString().slice(16, 21);
  }

  export function formatDateString(date: Date) {
    return new Date(date).toISOString().slice(0, 10);
  }

  export function formatCurrency(amount: Number, digits = 2) {
    return amount.toLocaleString('en-EN', {'currency': 'USD', 'maximumFractionDigits': digits, 'minimumFractionDigits': digits});
  }

  export function copyToClipboard(val) {

    let selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  // TODO: weak algorithm

  export function getFirstNameFromFullName(FullName: string): string {
    if (FullName.indexOf(',') >= 0) {
      // last, first middle
      return FullName.split(' ').slice(1, 2).join(' ');
    } else {
      // first middle last
      return FullName.split(' ').slice(0, -1).join(' ');
    }
  }

  // TODO: weak algorithm
  export function getLastNameFromFullName(FullName: string): string {
    if (FullName.indexOf(',') >= 0) {
      // last, first middle
      return FullName.split(' ').slice(0, -1).join(' ').substring(0, FullName.indexOf(','));
    } else {
      // first middle last
      return FullName.split(' ').slice(-1).join(' ');
    }
  }

  export function validateEmailAddresses(emails: string): boolean {
    let isValid = true;
    const emailPattern: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const checks = emails.split(',');
      checks.forEach(email => {
        if (email && !email.trim().match(emailPattern)) {
          isValid = false;
        }
      });
      return isValid;
  }
}
