import { catchError, finalize, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { NotificationTemplate } from '../models/notificationtemplate';
import { EventService } from './event.service';
import { ApiUtilities } from '../shared/ApiUtilities';
import { OnceOffCampaign } from '../models/onceoffcampaign';
import {PortoResponse} from '../models/porto-response';
import {CollectionReminder} from '../models/collectionreminders';

@Injectable()
export class ReminderService {
  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private eventService: EventService,
  ) {}

  sendSmsCollection(
    collectionId: string,
    sourceId: string,
    source: string,
    message: string,
    phoneNumber: string,
    sourceType: string
  ): Observable<CreateCollectionReminderResponse> {
    const sms = {
      CollectionId: collectionId,
      Message: message,
      Source: source,
      SourceId: sourceId,
      ToNumber: phoneNumber,
      SourceType: sourceType,
    };

    return this.http
      .post<CreateCollectionReminderResponse>(`${this.settings.getBaseUrl()}/sms/collection`, sms)
      .pipe(
        map((res) => res),
        catchError(error =>
          throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          ),
        ),
      );
  }



  sendSms(
    sourceId: string,
    source: string,
    message: string,
    phoneNumber: string,
    defendantId: string,
  ): Observable<any> {
    const sms = {
      Message: message,
      Source: source,
      SourceId: sourceId,
      ToNumber: phoneNumber,
      DefendantId: defendantId,
    };

    return this.http
      .post(`${this.settings.getBaseUrl()}/sms/person/${sourceId}`, sms)
      .pipe(
        map((res: any) => res),
        catchError(error =>
          throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          ),
        ),
      );
  }

  sendTestSms(mobileNumber: string, message: string): Observable<any> {
    const sms = {
      ToNumber: mobileNumber,
      Message: message,
      Source: 'ReminderTemplate',
      Fake: true,
      Type:'TEXT'
    };

    const ret = this.http
      .post(`${this.settings.getBaseUrl()}/user/sms/test`, sms)
      .pipe(
        map((res: any) => res),
        catchError(error =>
          throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          ),
        ),
      );
    return ret;
  }

  sendTestMail(email: string, message: string, notificationTypeId: string): Observable<any> {
    const emailData = {
      // email: email,
      // Message: message,
      // Source: 'ReminderTemplate',
      // Fake: true,
      // NotificationTypeId: notificationTypeId,
      // Type:'EMAIL'

      Email: email,
      EmailMessage: message,
      EmailSubject: 'Reminder Test',
      Type: 'EMAIL',

    };
    const response = this.http
      .post(`${this.settings.getBaseUrl()}/reminder/sendtestemail`, emailData)
      .pipe(
        map((res: any) => res),
        catchError(error =>
          throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          ),
        ),
      );
    return response;
  }

  sendBulkReminders(reminderInfo: OnceOffCampaign) {
    this.eventService.componentBusy(true);
    const postData = { data: reminderInfo };

    return this.http
      .post(`${this.settings.getBaseUrl()}/reminders/bulk`, postData)
      .pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  addTemplate(data: any): Observable<any> {
    this.eventService.componentBusy(true);

    return this.http
      .post(`${this.settings.getBaseUrl()}/notificationtemplates`, data)
      .pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  deleteTemplate(id: any): Observable<any> {
    this.eventService.componentBusy(true);
    return this.http
      .delete(`${this.settings.getBaseUrl()}/notificationtemplates/${id}`)
      .pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);

          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  updateTemplate(id: any, data: NotificationTemplate): Observable<any> {
    this.eventService.componentBusy(true);
    return this.http
      .patch(`${this.settings.getBaseUrl()}/notificationtemplates/${id}`, data)
      .pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  getReminderConfigurations(): Observable<any> {
    return this.http
      .get(`${this.settings.getBaseUrl()}/reminderconfigurations`)
      .pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  getReminderNumberExclusions(): Observable<any> {
    return this.http
      .get(`${this.settings.getBaseUrl()}/reminderexclusions`)
      .pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  getReminderNumbers(): Observable<any> {
    return this.http
      .get(`${this.settings.getBaseUrl()}/remindernumbers?limit=0`)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  getReminderNumbersByAreaCode(areacode: string): Observable<any> {
    return this.http
      .get(
        `${this.settings.getBaseUrl()}/getnumbersfromareacode?areacode=${areacode}`,
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  activateReminderNumber(data: any): Observable<any> {
    return this.http
      .post(`${this.settings.getBaseUrl()}/remindernumbers/activate`, data)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  createReminderSetting(data: any): Observable<any> {
    return this.http
      .post(`${this.settings.getBaseUrl()}/eventremindersetting`, data)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  updateReminderSetting(id: string, data: any): Observable<any> {
    const url = `${this.settings.getBaseUrl()}/eventremindersettings/${id}`;
    return this.http.patch(url, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(
          ApiUtilities.getErrorMessage(error, true) || 'Server Error',
        );
      }),
    );
  }

  getLogs(filter: string, isCarol: boolean): Observable<any> {
    const product = isCarol ? 'carol' : 'bail';
    const url = `${this.settings.getBaseUrl()}/getallreminderlogs/${product}${filter}`;
    return this.http.get(url).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(
          ApiUtilities.getErrorMessage(error, true) || 'Server Error',
        );
      }),
    );
  }

  // Send test sms and email for Collections
  sendCollectionTestTextOrEmail(data: any) {
    return this.http
      .post(`${this.settings.getBaseUrl()}/reminder/sendtestemail`, data)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  sendCollectionTestReminder(data: any) {
    return this.http
      .post(
        `${this.settings.getBaseUrl()}/reminders/collections/sendtest`,
        data,
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  sendCollectionTestNoInteractionReminder(data: any) {
    return this.http
      .post(
        `${this.settings.getBaseUrl()}/reminders/collections/sendtestnointeraction`,
        data,
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  sendTestCarolWeeklySms(data: any) {
    return this.http
      .post(
        `${this.settings.getBaseUrl()}/reminders/collections/sendtestcarolweeklysms`,
        data,
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  saveNewEventReminderType(name: string, text: string) {
    const dataToSend = { Name: name, Text: text };
    return this.http
      .post(`${this.settings.getBaseUrl()}/eventreminders`, dataToSend)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  addReminderExclusion(data: any): Observable<any> {
    return this.http
      .post(`${this.settings.getBaseUrl()}/reminderexclusions`, data)
      .pipe(
        map((res: any) => res),
        finalize(() => {
        }),
        catchError(error => {
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }

  // when we implement spanish for bail, we need to update this path and pass component as well so we dont mix the two worlds
  getReminderMasterTemplates(component: string) {
    return this.http
    .get(`${this.settings.getBaseUrl()}/notificationmastertemplates?limit=0`)
    .pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(
          ApiUtilities.getErrorMessage(error, true) || 'Server Error',
        );
      }),
    );
  }

  toggleReminderTemplateLanguage(component: string, langauge: string) {
   const data = {
     component: component,
     language: langauge,
   };
    return this.http
    .put(`${this.settings.getBaseUrl()}/notificationmastertemplates`, data)
    .pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(
          ApiUtilities.getErrorMessage(error, true) || 'Server Error',
        );
      }),
    );
  }

  sendManualGpSReminder(data: any): Observable<any> {
    this.eventService.componentBusy(true);
    return this.http
      .post(`${this.settings.getBaseUrl()}/reminder/manualgpsmessage`, data)
      .pipe(
        map((res: any) => res),
        finalize(() => {
          this.eventService.componentBusy(false);
        }),
        catchError(error => {
          this.eventService.componentBusy(false);
          return throwError(
            ApiUtilities.getErrorMessage(error, true) || 'Server Error',
          );
        }),
      );
  }
}

export interface CreateCollectionReminderResponse {
  result: string,
  history: PortoResponse<CollectionReminder[]>,
  status: string,
}