import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Defendant } from '../../../../models/defendant';
import { FormControl, Validators } from '@angular/forms';
import Guid from 'devextreme/core/guid';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { DocumentService } from '../../../../services/document.service';
import { UnsubscribeOnDestroyAdapter } from '../../../../common/UnsubscribeOnDestroy';
import { Bail } from '../../../../models/bail';
import { DefendantService } from '../../../../services/defendant.service';
import { Document } from '../../../../models/document';
import { NotifyService} from '../../../../common/notify/notify.service';
export interface DocumentDialogData {
  defendant: Defendant;
}

interface TransactionSelect {
  name: string;
  id: string;
}

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss'],
})
export class DocumentDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  isDisabled = false;

  constructor(
    public dialogRef: MatDialogRef<DocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentDialogData,
    private documentService: DocumentService,
    private defendantService: DefendantService,
    private notifyService: NotifyService
  ) {
    super();
  }

  ngOnInit(): void {
    // for (const tran of this.data.defendant.transactions) {
    //   tran.documents = [];
    // }
    // this.subs.sink = this.defendantService.getDefendantDocumentsByDefId(this.data.defendant.Id)
    //   .subscribe(documents => {
    //     documents.forEach(document => {
    //       for (const trans of this.data.defendant.transactions) {
    //         if (trans.Id === document.SourceId && document.SourceType === 'DefendantTransactions') {
    //           const _document = new Document();
    //           _document.loadData(document);
    //           trans.documents.push(_document);
    //         }
    //       }
    //     });
    //   });
  }

  handleComplete(event: boolean) {
    this.dialogRef.close(event);
  }

  /**
   * handleSave takes an array of transactions and loops through each
   * transaction's documents array in order to save the documents.
   * @param transactions: An array of bail transactions.
   */
  handleSave(transactions: Bail[]) {
    const observableQueue = [];
    // First we need to prepare the documents by setting the SourceId and SourceType
    for (const transaction of transactions) {
      for (const document of transaction.documents) {
        document.tempKey = new Guid().toString();
        document.SourceType = 'DefendantTransactions';
        document.SourceId = transaction.Id;
        if (document.isAdded) {
          observableQueue.push(this.documentService.addDocument(document));
        } else if (document.isDeleted) {
          observableQueue.push(this.documentService.removeDocument(document.Id));
        }
      }
    }
    if (observableQueue.length <= 1) {
      const dummyObs = new Observable(obs => {
        obs.next();
      });
      observableQueue.push(dummyObs);
    }
    this.notifyService.toast('Saving documents...');
    this.isDisabled = true;
    this.subs.sink = observableCombineLatest(observableQueue).subscribe((docResp: any) => {
      // lets find the deleted and remove them from the client model collections seeing as the api does not need to
      // process them anymore
      for (const transaction of this.data.defendant.transactions) {
        for (const document of transaction.documents) {
          if (document.isAdded) {
            document.Id = docResp.find(
              (itm: any) => {
                return itm.data.tempKey === document.tempKey;
              },
            ).data.data.Id;
            document.isAdded = false;
          } else if (document.isDeleted) {
            document.isDeleted = false;
          }
        }
      }
      this.isDisabled = false;
      this.notifyService.toast('Documents updated successfully.');
      this.handleComplete(true);
    }, err => {
      this.notifyService.toast('Something went wrong upload documents', 'error');
      this.isDisabled = false;
      this.handleComplete(false);
    });
  }

}
