import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiUtilities} from '../shared/ApiUtilities';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';

@Injectable()
export class PrefixService {

  constructor(private http: HttpClient,
              private settings: SettingsService) {
  }

  public getPrefixById(Id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/prefixes/${Id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }
}
