import {BaseModel} from './BaseModel';
import {AdiNumberAssignment} from './adi-number-assignment';
import {AutomaticNotificationSetting} from './automaticnotificationsetting';
import {CollectionAutomaticNotificationSetting} from './collectionautomaticnotificationsetting';
import {isNullOrUndefined} from 'util';
import {Lawsuit} from './lawsuit';
import {Bail} from './bail';
import {DefendantAccountingDetails} from './defendant-accounting-details';
import {CalendarUtilities} from '../shared/calendar-utilities';
import {CalendarItem} from './calendaritem';
import {GraduatedRate} from './graduatedRate';
import {SmsTemplate} from './smstemplate';
import {Reminderconfiguration} from './reminderconfiguration';
import {CollectionReminderconfiguration} from './collectionreminderconfiguration';
import {NotificationTemplate} from './notificationtemplate';

export class AdiSettings extends BaseModel {

  public Id: any;
  public AuthenticationType: any; //y
  public ListCourtDates: boolean; //y
  public AllowCheckIn: boolean; //y
  public ForwardingNumber: any;
  public TransferOnForfeiture: boolean;
  public TransferOnPastDue: boolean;
  public AllowCCPayment: boolean;
  public CheckInThreshold: any;
  public MarkMissedCheckins: boolean;
  public TransferOnIsAtCollections: boolean;
  public CheckInVoiceStamp: boolean;
  public TransferOnFailedLogin: boolean;
  public CalloutShowCompanyPhone: boolean;
  public CalloutAutoPlayMessage: boolean;
  public AllowTransferOnDemand: boolean;
  public CourtAndAccountAfterCheckIn: boolean;
  public TransferOnBadInfo: boolean;
  public AllowCourtDateEntry: boolean;
  public CalloutStartTime: any;
  public CalloutStopTime: any;
  public PhoneNumber: any;
  public CompanyName: any;

  public CourtDateCallsEnabled: any
  public CourtDateCall1Days: any
  public CourtDateCall2Days: any
  public CourtDateIncludeAllNumbers: any
  public CourtDateIncludeIndemnitors: any
  public CourtDateSkipDuplicateNumbers: any
  public CourtDateSkipBadNumbers: any
  public CourtDateSkipWeekends: any

  //virtual values to handle the new 'ALL' option
  public AllCallsEnabled: any
  public AllCall1Days: any
  public AllCall2Days: any
  public AllIncludeAllNumbers: any
  public AllIncludeIndemnitors: any
  public AllSkipDuplicateNumbers: any
  public AllSkipBadNumbers: any
  public AllSkipWeekends: any


  public PaymentReminderCallsEnabled: any
  public PaymentReminderCall1Days: any
  public PaymentReminderCall2Days: any
  public PaymentReminderIncludeAllNumbers: any
  public PaymentReminderIncludeIndemnitors: any
  public PaymentReminderSkipDuplicateNumbers: any
  public PaymentReminderSkipBadNumbers: any
  public PaymentReminderSkipWeekends: any;

  public PastDueCallsEnabled: any
  public PastDueCallDays: any
  public PastDueMinimumAmount: any
  public PastDueMinimumDaysPastDue: any
  public PastDueIncludeAllNumbers: any
  public PastDueIncludeIndemnitors: any
  public PastDueSkipDuplicateNumbers: any
  public PastDueSkipBadNumbers: any
  public PastDueSkipWeekends: any
  public PastDueMaximumDaysPastDue: any
  public PastDueMaximumAmount: any;


  public MissedCheckinEnabled: any
  public MissedCheckinIncludeAllNumbers: any
  public MissedCheckinIncludeIndemnitors: any
  public MissedCheckinSkipBadNumbers: any
  public MissedCheckinSkipDuplicateNumbers: any
  public MissedCheckinSkipWeekends: any
  public MissedCheckinCalls1Days: any
  public MissedCheckinCalls2Weeks: any

  public NumberAssignment: AdiNumberAssignment;
  public AutomaticNotificationSetting: AutomaticNotificationSetting;
  public CollectionAutomaticNotificationSetting: CollectionAutomaticNotificationSetting;
  public SmsTemplates: SmsTemplate[];
  public NotificationTemplates: NotificationTemplate[];
  public ReminderConfigurations: Reminderconfiguration[];
  public CollectionReminderconfiguration: CollectionReminderconfiguration[];

  constructor(obj?: object) {
    super(obj);

    this.routeRoot = 'adisettings';

    if (obj == null) {

      this.ListCourtDates = true;
      this.AllowCheckIn = true;
      this.TransferOnForfeiture = true;
      this.TransferOnPastDue = true;
      this.AllowCCPayment = false;
      this.MarkMissedCheckins = false;
      this.TransferOnPastDue = true;
      this.TransferOnBadInfo = false;
      this.TransferOnFailedLogin = true;
      this.TransferOnIsAtCollections = true;
      this.CheckInVoiceStamp = false;
      this.CalloutShowCompanyPhone = false;
      this.CalloutAutoPlayMessage = false;
      this.AllowTransferOnDemand = true;
      this.CourtAndAccountAfterCheckIn = true;
      this.AllowCourtDateEntry = true;
      this.CalloutStartTime = '08:00';
      this.CalloutStopTime = '17:00'

      this.CourtDateCallsEnabled = false;
      this.CourtDateCall1Days = false;
      this.CourtDateCall2Days = false;
      this.CourtDateIncludeAllNumbers = false;
      this.CourtDateIncludeIndemnitors = false;
      this.CourtDateSkipDuplicateNumbers = false;
      this.CourtDateSkipBadNumbers = false;
      this.CourtDateSkipWeekends = false;

      this.PaymentReminderCallsEnabled = false;
      this.PaymentReminderCall1Days = false;
      this.PaymentReminderCall2Days = false;
      this.PaymentReminderIncludeAllNumbers = false;
      this.PaymentReminderIncludeIndemnitors = false;
      this.PaymentReminderSkipDuplicateNumbers = false;
      this.PaymentReminderSkipBadNumbers = false;
      this.PaymentReminderSkipWeekends = false;

      this.PastDueCallsEnabled = false;
      this.PastDueCallDays = false;
      this.PastDueMinimumAmount = 0;
      this.PastDueMaximumAmount = 0;
      this.PastDueMinimumDaysPastDue = 0;
      this.PastDueMaximumDaysPastDue = 0;
      this.PastDueIncludeAllNumbers = false;
      this.PastDueIncludeIndemnitors = false;
      this.PastDueSkipDuplicateNumbers = false;
      this.PastDueSkipBadNumbers = false;
      this.PastDueSkipWeekends = false;


      this.MissedCheckinEnabled = false;
      this.MissedCheckinIncludeAllNumbers = false;
      this.MissedCheckinIncludeIndemnitors = false;
      this.MissedCheckinSkipBadNumbers = false;
      this.MissedCheckinSkipDuplicateNumbers = false;
      this.MissedCheckinSkipWeekends = false;
      this.MissedCheckinCalls1Days = false;
      this.MissedCheckinCalls2Weeks = false;

      this.AutomaticNotificationSetting = new AutomaticNotificationSetting();
      this.CollectionAutomaticNotificationSetting = new CollectionAutomaticNotificationSetting();
      this.NumberAssignment = new AdiNumberAssignment();
      this.SmsTemplates = [];
      this.ReminderConfigurations = [];
      this.ReminderConfigurations = [];
      this.NotificationTemplates = [];
    } else {
      //  =
      this.loadData(obj);
    }


  }

  public loadData(data) {
    // console.log('adi settings data', data);
    if (data != null) {
      // console.log('Before calling function fillFromObject!');
      this.fillFromObject(data, true);
      //this.AutomaticNotificationSetting.length = 0;
      if (data.automaticnotificationsettings && data.automaticnotificationsettings.data !== null) {
        this.AutomaticNotificationSetting = Object.assign(new AutomaticNotificationSetting(), data.automaticnotificationsettings.data);
      }

      if (data.collectionautomaticnotificationsettings && data.collectionautomaticnotificationsettings.data !== null) {
        // console.log('Inside final check!', data.collectionautomaticnotificationsettings);
        this.CollectionAutomaticNotificationSetting = Object.assign(new CollectionAutomaticNotificationSetting(), data.collectionautomaticnotificationsettings.data);
      }

      if (data.reminderconfigurations && data.reminderconfigurations.data) {
        this.ReminderConfigurations = data.reminderconfigurations.data.map(x => Object.assign(new Reminderconfiguration(), x));
      }

      if (data.collectionreminderconfigurations && data.collectionreminderconfigurations.data !== null) {
        this.CollectionReminderconfiguration = data.collectionreminderconfigurations.data.map(x => {
          const returnObj = new CollectionReminderconfiguration(); // Object.assign(new CollectionReminderconfiguration(), x);
          returnObj.loadData(x);
          return returnObj;
        });
      }

      if (data.notificationtemplates !== undefined && data.notificationtemplates.data !== null) {
        this.NotificationTemplates = data.notificationtemplates.data.map(x => Object.assign(new NotificationTemplate(), x));
      }

      // Collection notifications templates
      if (data.collectionsnotificationtemplates !== undefined && data.collectionsnotificationtemplates.data !== null) {
        this.NotificationTemplates = data.collectionsnotificationtemplates.data.map(x => Object.assign(new NotificationTemplate(), x));
      }
      // console.log('Final adi settings in adiSetting model: ', this);
    }


//    // console.log('defendant.loaddata loaded', this);

  }
}
