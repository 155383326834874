import {catchError, map} from 'rxjs/operators';
import {throwError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()
export class WebsiteBondingSettingsService {

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  get(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/websitebondingsettings`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }


  checkSubDomain(subdomain: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/websitebondingsettings/checksubdomain/${subdomain}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  save( data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/websitebondingsettings`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }


  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/websitebondingsettings`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

}
