import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { routeReuseTrigger } from './scroll-store-provider';

// https://medium.com/javascript-in-plain-english/angular-route-reuse-strategy-b5d40adce841
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private verboseLogging = false;
  private storedRoutes = new Map<string, DetachedRouteHandle>();
  private routesToStore = new Array<string>(
    '/collections/accounts/undeliverable',
    '/defendantmanager/reminder-setting/defmngr',
    '/adiv2/src/bail',
    '/defendant/active',
    '/accounting/accounts_receivable',
    '/reporting',
    '/collections/accounts',
  );

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (this.routesToStore.includes(this.getResolvedUrl(route))) {
      if (this.verboseLogging) {
        // console.log('RouteReuseStrategy: detaching route', this.getResolvedUrl(route));
      }
      return true;
    }
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (this.verboseLogging) {
      console.log('RouteReuseStrategy: storing route', this.getResolvedUrl(route), handle, this.storedRoutes);
    }
    this.storedRoutes.set(this.getResolvedUrl(route), handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.storedRoutes.get(this.getResolvedUrl(route));
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (this.verboseLogging) {
      console.log('RouteReuseStrategy: retrieving route', this.getResolvedUrl(route), route, this.storedRoutes);
    }
    if (!route.routeConfig || (route.routeConfig && route.routeConfig.loadChildren)) {
      return null;
    }
    const retrievedRoute = this.storedRoutes.get(this.getResolvedUrl(route));
    if (retrievedRoute) {
      routeReuseTrigger(this.getResolvedUrl(route));
    }
    return retrievedRoute;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  // returns eg /collections/accounts/undeliverable
  private getResolvedUrl(route: ActivatedRouteSnapshot) {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/')
      .replace('//', '/').replace('//', '/');
  }

  // returns eg /collections/accounts/:id
  private getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
      .filter(v => v.routeConfig)
      .map(v => v.routeConfig!.path)
      .join('/');
  }
}
