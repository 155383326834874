<mat-card>
    <div class="row">
        <div class="col">
            <strong>
                {{ details.customer.Name }} - {{ activeDisplay }}
            </strong> <br/>
            <small>
                <span>Created on: {{ details.customer.Created_At | date:'MM/d/yyyy' }}</span> <br/>
                <span>Promo code: {{ promoCode }}</span>
            </small>
        </div>
    </div>
</mat-card>

<mat-card>
    Chargify ID: <a style="cursor: pointer;" (click)="redirectToChargify(details.customer.ChargifySubscriptionId)" target="_blank" title="Open billing">{{ details.customer.ChargifySubscriptionId }}</a> <br/>

    <dx-data-grid
        #dataGridSub
        [dataSource]="details.subscription"
        [showBorders]="true"
        (onToolbarPreparing)="onToolbarPreparing($event, dataGridSub, 'Subscribed Products')"
    >
        <dxi-column dataField="label" caption="Product"></dxi-column>
    </dx-data-grid>
</mat-card>

<mat-card>
    <dx-data-grid
        #dataGridUsers
        [dataSource]="details.users"
        [showBorders]="true"
        (onToolbarPreparing)="onToolbarPreparing($event, dataGridUsers, 'Users')"
    >
        <dxi-column dataField="Id" caption="ID" alignment="center"></dxi-column>
        <dxi-column dataField="FullName" caption="Full name"></dxi-column>
        <dxi-column dataField="identity.Email" caption="E-mail"></dxi-column>
        <dxi-column dataField="identity.MobileNumber" caption="Phone"></dxi-column>
        <dxi-column dataField="last_login.LastLoginTime" caption="Last Login"></dxi-column>
        <dxi-column dataField="Created_At" caption="Created at"></dxi-column>
    </dx-data-grid>
</mat-card>

<app-bottombar enableShadow="true">
    <a mat-stroked-button color="primary" (click)="backToCustomers()">
      <i class="icon-spacer fas fa-chevron-left"></i><span>Back to customers</span>
    </a>
</app-bottombar>
