import {
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  EventEmitter,
  Output,
  ComponentFactoryResolver,
  ComponentFactory,
  ViewEncapsulation, OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { bannerMessageFade } from '../../../app.animations';

import { DefendantManagerReminderAdviceComponent } from '../defendant-manager-reminder-advice/defendant-manager-reminder-advice.component';
import { EventService } from '../../../services/event.service';

import {UnsubscribeOnDestroyAdapter} from '../../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-advice-banner',
  templateUrl: './advice-banner.component.html',
  styleUrls: ['./advice-banner.component.scss'],
  animations: [
    bannerMessageFade,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AdviceBannerComponent extends UnsubscribeOnDestroyAdapter implements AfterViewInit {
  @ViewChild('messageContent', { read: ViewContainerRef }) content: any;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @Input() render: string;
  @Input() component: any;
  @Input() display: boolean;

  displayedComponent: any;

  constructor(private resolver: ComponentFactoryResolver,
              private eventService: EventService) {
                super();
    // Use this component preferably with small messages to be displayed.
  }

  ngAfterViewInit() {
    // Getting the component that will be rendered.
    // You could pass a component or call some default.

    const main = this;
    const componentToBeRendered = (!!this.component ? this.component : this.callComponent(this.render));
    if (!(!!componentToBeRendered)) {
      return false;
    }

    try {
      this.content.clear();
      const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(componentToBeRendered);
      this.component = this.content.createComponent(factory);
    } catch (e) {
      console.warn('[advice-banner.component] Unable to render the last component given: ', componentToBeRendered);
    }

    this.subs.sink = this.eventService.onAdviceClose$.subscribe(state => {
      main.display = false;
    });
  }

  triggerClose() {
    this.display = false;
    this.onClose.emit(true);
  }

  callComponent(name: string) {
    // Default message components
    let component = null;
    switch (name) {
      case 'defendantmanager-reminder-advice':
        component = DefendantManagerReminderAdviceComponent;
      break;
    }

    return component;
  }

}
