
import {first, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {EventService} from './event.service';
import {environment} from '../../environments/environment';

@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private http: HttpClient,
                private eventService: EventService) {}

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 5 minutes
     */
    public initVersionCheck(url: any, frequency = 1000 * 60 * 5) {
        if (environment.environmentName !== 'local') {
            // console.log('initializing version check', url, frequency);
            setInterval(() => {
                this.checkVersion(url);
            }, frequency);
        }
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url: any) {
        // timestamp these requests to invalidate caches -> Done!
        const main = this;
        url += '?timestamp=' + (new Date().getTime());
        // Debug on local
        // fetch(`http://localhost:8080/version.json?timestamp=${(new Date().getTime())}`)
        // .then(response => response.json())
        // .then(data => {
        //     this.queryVersionHandler(data);
        // }).catch((error) => {
        //     // console.log('Error', error);
        // });

        // realScript
        this.http.get(url).pipe(take(1))
            .subscribe(
                (response: any) => {
                    main.queryVersionHandler(response);
                },
                (err) => {
                    console.error(err, 'Could not get version');
                },
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash: any, newHash: any) {
        // console.log('in function: hasHashChanged: ', 'currentHash', currentHash,
        //             'newHash: ', newHash, ' and current hash check: ', !currentHash);
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        // console.log('in function hasHashChanged: checking condition!');

        return currentHash !== newHash;
    }

    private queryVersionHandler(response: any) {
        // console.log('check version response: ', response, 'currentHash: ', this.currentHash);

        const snackReloadAction = () : void => {
            location.reload(true);
        };

        if (!!response) {
            const hash = response.hash;
            const hashChanged = this.hasHashChanged(this.currentHash, hash);

            // If new version, do something
            if (hashChanged) {

                // console.log('version check response: ', response);

                this.eventService.showSnackbar('New version available!', false, 'SAVE & RELOAD', ['snack-version-class'], snackReloadAction);
                // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                // for an example: location.reload();
                // alert('New version is available!');

            }
            // store the new hash so we wouldn't trigger versionChange again
            // only necessary in case you did not force refresh
            this.currentHash = hash;
            // console.log('current hash changed: ', this.currentHash);
            // code...
        }
    }
}
