<mat-card>
  <mat-card-subtitle class="mat-card-title">
      Currently logged in as customer: {{ loggedOnUser }} <a class="link-pointer" *ngIf="validateLogOutCustomer()" (click)="logoutStaffFromCustomer()" >Log out of Customer</a>
  </mat-card-subtitle>
  <div *ngIf="(productsLoading && productsLoadingValue < 100); then progressProducts else loadProductsButton"></div>
  <ng-template #progressProducts>
    <dx-progress-bar
      style="margin-top: 8px;"
      width="50%"
      [min]="0"
      [max]="100"
      [statusFormat]="formatLoad"
      [value]="productsLoadingValue">
    </dx-progress-bar>
  </ng-template>
  <ng-template #loadProductsButton>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!gridData"
      stylingMode="text"
      type="normal"
      (click)="proceduralCustomersProducts(true)">
      {{ reloadProductsButtonText() }}
    </button>
  </ng-template>

  <dx-data-grid
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
    [dataSource]="gridData"
    width="100%"
    rowAlternationEnabled="true"
    noDataText="Loading..."
    [loadPanel]="{
      enabled: true,
      showIndicator: true,
      showPane: true,
      text: 'Loading...'
    }"
    (onToolbarPreparing)="onToolbarPreparing($event)"
  >

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-search-panel
      [visible]="true"
      placeholder="Search"
    ></dxo-search-panel>
    <dxo-paging [pageSize]="20"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[20, 50, 100]" [showInfo]="true">
    </dxo-pager>

    <dxi-column caption="Login as customer" [cellTemplate]="'customTemplate'" alignment="center">
      <div *dxTemplate="let data of 'customTemplate'">
        <dx-button class="bg-transparent" icon="fa fa-sign-in" (onClick)="updateEmployeeCustomerId(data.data)"
                   [disabled]="validateCurrentId(data.data)">
        </dx-button>
      </div>
    </dxi-column>

    <dxi-column [width]=90 caption="Customer Id" [visible]="true" dataField="CustomerId"></dxi-column>

    <dxi-column caption="Name" [visible]="true" dataField="Name" cellTemplate="nameTemplate">
      <div *dxTemplate="let data of 'nameTemplate'">
        <a style="cursor: pointer;"
           (click)="openCustomerDetails(data.data.Id)" title="Customer details">{{data.data.Name}}</a>
      </div>
    </dxi-column>


    <dxi-column [width]=100 caption="Creation Date" [visible]="true" dataField="Created_At" dataType="date"></dxi-column>

    <dxi-column caption="Owner Full Name" [visible]="true" dataField="OwnerName">
    </dxi-column>

    <dxi-column caption="Email" [visible]="true" dataField="owner.identity.Email">
    </dxi-column>

    <dxi-column caption="Status account" dataField="ChargifySubscriptionState" [customizeText]="capsLetter"></dxi-column>

    <div *ngIf="moreDetailsFlag; then subscribedProducts"></div>
    <ng-template #subscribedProducts><dxi-column caption="Subscribed products" dataField="products"></dxi-column></ng-template>

    <dxi-column [width]=100 caption="Chargify Subscription Id" [visible]="true" dataField="ChargifySubscriptionId" cellTemplate="template">
      <div *dxTemplate="let data of 'template'">
        <a class="link-pointer"
           (click)="redirectToChargify(data.data.ChargifySubscriptionId)"
           title="Open Billing">{{data.data.ChargifySubscriptionId}}</a>
      </div>
    </dxi-column>

    <div *ngIf="moreDetailsFlag; then detailedColumns;"></div>
    <ng-template #detailedColumns>
      <dxi-column caption="Payment gateway" [cellTemplate]="'creditCardTemplate'" dataField="ClientCreditCardGateways.Name" alignment="center"></dxi-column>

      <dxi-column [width]=143 caption="30 day logins" dataField="CustomerActivitySummary.LoginsThisMonth" alignment="center"></dxi-column>

      <dxi-column [width]=132 caption="Active Files" dataField="CustomerActivitySummary.ActiveFiles" alignment="center"></dxi-column>
    </ng-template>

    <div *dxTemplate="let data of 'creditCardTemplate'">
      <div *ngIf="data.data.ClientCreditCardGateways.Name != ''; then gatewayPopoupLink"></div>
      <ng-template #gatewayPopoupLink>
          <a class="link-pointer"
            (click)="openClientCreditCardGatewaysPopup(data.data.ClientCreditCardGateways)"
            title="See gateway details">{{ data.data.ClientCreditCardGateways.Name }}</a>
      </ng-template>
    </div>

    <dxo-summary>
      <dxi-total-item
          column="OwnerName"
          displayFormat="Total: {0}"
          summaryType="count">
      </dxi-total-item>
      <dxi-total-item
          column="CustomerActivitySummary.LoginsThisMonth"
          displayFormat="Total: {0}"
          summaryType="sum">
      </dxi-total-item>
      <dxi-total-item
      column="CustomerActivitySummary.ActiveFiles"
      displayFormat="Total: {0}"
      summaryType="sum">
  </dxi-total-item>
  </dxo-summary>

  </dx-data-grid>
</mat-card>
<app-dynamic-popup
  [popupComponent]="dynamicPopupClass"
  [popupComponentData]="dynamicPopupData"
  [popupComponentTitle]="dynamicPopupTitle"
  [showCloseButton]="true"
  [popupComponentVisible]="dynamicPopupVisible"
  [popupShowTitle]="true"
  (onPopupHidden)="onPopupHidden()"
  [popupAllowCloseOnOutsideClick]="true">
</app-dynamic-popup>

<dx-popup
  class="popup"
  [showTitle]="true"
  title="Client credit card gateway"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="ClientCreditCardGatewaysVisiblePopup"
  maxWidth="350px"
  maxHeight="175px">

  <p>
    Name: {{ clientCreditCard.Name }} <br/>
    Credential: {{ clientCreditCard.GatewayCredential }} <br/>
    Key: {{ clientCreditCard.GatewayKey }}
  </p>
</dx-popup>
