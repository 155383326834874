<div fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="15px"
  style="min-height: 100px;">
  <h4 class="text-muted" *ngIf="isLoading">Loading - Please wait...</h4>
  <div class="text-center" *ngIf="!isLoading">
    <h4 *ngIf="isBeingWatched">
      This defendant is being watched
      <span *ngIf="watch && watch.WatchRequestStamp!=null">
        (since {{watch.WatchRequestStamp}})
      </span> - {{alertsCount || 'No'}} alerts
    </h4>
    <h4 *ngIf="!isBeingWatched">
      This defendant is not being watched.
    </h4>
    <div style="margin-bottom: 15px;">
      <dx-check-box
        text="Manually add to Defendant Watch"
        [(value)]="model.IncludeInDefendantWatch"
        (onValueChanged)="toggleDefWatch($event)"
        [readOnly]="fieldsDisabled">
      </dx-check-box>
    </div>
    <div>
      <app-defendantwatch-prompt promptSource="DefendantWatch"></app-defendantwatch-prompt>
    </div>
  </div>
</div>
