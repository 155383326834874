import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {BillingGuard} from '../guards/billing.guard';
import {AddressService} from '../services/address.service';
import {SystemManagerService} from '../services/systemmanager.service';
import {SystemConfigService} from '../services/systemconfig.service';
import {FormatService} from '../services/format.service';
import {PhoneService} from '../services/phone.service';
import {CheckInScheduleService} from '../services/checkinschedule.service';
import {RouteDataService} from '../services/routedata.service';
import {CreditCardTransactionHistoryService} from '../services/creditcardlog.service';
import {VoidUnvoidService} from '../services/voidunvoid.service';
import {PaymentscheduleService} from '../services/paymentschedule.service';
import {TransactionInvoiceService} from '../services/transasctioninvoice.service';
import {PaymentService} from '../services/payment.service';
import {BondService} from '../services/bondservice.service';
import {CreditCardGatewayService} from '../services/creditcardgateways.service';
import {PersonsService} from '../services/persons.service';
import {EmailService} from '../services/emailservice.service';
import {SearchService} from '../services/search.service';
import {EventService} from '../services/event.service';
import {BillingService} from '../services/billing.service';
import {ProspectService} from '../services/prospect.service';
import {DatamartService} from '../services/datamart.service';
import {ReportService} from '../services/report.service';
import {PowerService} from '../services/power.service';
import {PrefixService} from '../services/prefix.service';
import {InventoryService} from '../services/inventory.service';
import {GenericsaverService} from '../services/genericsaver.service';
import {TransactionService} from '../services/transaction.service';
import {AdiSettingsService} from '../services/adisettings.service';
import {CustomerService} from '../services/customer.service';
import {SalesreportService} from '../services/salesreport.service';
import {PreferencesService} from '../services/preferences.service';
import {DocumentService} from '../services/document.service';
import {CacheService} from '../services/cache.service';
import {DischargereportService} from '../services/dischargereport.service';
import {WebsiteBondingSettingsService} from '../services/website-bonding-settings.service';
import {ReminderService} from '../services/reminder.service';
import {SmsTemplateMappingService} from '../services/smstemplatemapping.service';
import {LocationCheckinService} from '../services/location-checkin-service';
import {DefendantDataCaptureService} from '../services/defendant-data-capture.service';
import {MobileVerificationService} from '../services/mobile-verification.service';
import {ContactService} from '../services/contact.service';
import {DefendantManagerService} from '../services/defendant-manager.service';
import {CalendarService} from '../services/calendar-service.service';
import {DefendantWatchService} from '../services/defendantwatch.service';
import {SuretyService} from '../services/surety.service';
import {WhiteLabelService} from '../services/white-label.service';
import {DummydataService} from '../services/dummydata.service';
import {SmartFormService} from '../services/smart-form.service';
import {FormgroupsService} from '../services/formgroups.service';
import {VersionCheckService} from '../services/version-check.service';
import {BondImportService} from '../services/bond-import.service';
import {RekognitionService} from '../services/rekognition.service';
import {DebtorService} from '../services/debtor.service';
import {NotesService} from '../services/notes.service';
import {PaymentOffersService} from '../services/payment-offers.service';
import {DefmgrAddService} from '../services/defmgr-add.service';
import {LinkService} from '../services/link.service';
import {CollectionPaymentService} from '../services/collection-payment.service';
import {ExcelExportService} from '../services/excel-export.service';
import {PopupService} from '../services/popup.service';
import {StorageService} from '../services/storage.service';
import {RelatedRoutesService} from '../services/relatedRoutes.service';
import {SettingsService} from '../services/settings.service';
import {LookupService} from '../services/app-lookup.service';
import {FormService} from '../services/form.service';
import {AuthService} from '../services/auth.service';
import {PermissionService} from '../services/permission.service';
import {Service} from '../services/custdata.service';
import {DefendantService} from '../services/defendant.service';
import {GridService} from '../services/grid.service';
import {RoutingStateService} from '../services/routing-state.service';
import {PaymentGatewaySettingsService} from '../services/paymentgatewaysettings.service';
import {StateService} from '../services/state.service';
import {CanDeactivateGuardService} from '../services/can-deactivate.service';
import {SmartResolverService} from '../services/smart-resolver.service';
import {RouteReuseStrategy} from '@angular/router';
import {CustomRouteReuseStrategy} from '../shared/custom-route-reuse-strategy';
import {PopoutService} from '../services/popout.service';
import { AffiliateService } from './../services/affiliate.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    RoutingStateService,
    StorageService,
    RelatedRoutesService,
    SettingsService,
    LookupService,
    FormService,
    AuthService,
    PermissionService,
    Service,
    DefendantService,
    GridService,
    BillingGuard,
    AddressService,
    SystemManagerService,
    SystemConfigService,
    FormatService,
    PhoneService,
    CheckInScheduleService,
    RouteDataService,
    CreditCardTransactionHistoryService,
    VoidUnvoidService,
    PaymentscheduleService,
    TransactionInvoiceService,
    PaymentService,
    BondService,
    CreditCardGatewayService,
    PersonsService,
    EmailService,
    SearchService,
    EventService,
    BillingService,
    ProspectService,
    DatamartService,
    ReportService,
    PowerService,
    PrefixService,
    InventoryService,
    GenericsaverService,
    TransactionService,
    AdiSettingsService,
    CustomerService,
    SalesreportService,
    PreferencesService,
    DocumentService,
    CacheService,
    DischargereportService,
    WebsiteBondingSettingsService,
    ReminderService,
    SmsTemplateMappingService,
    LocationCheckinService,
    DefendantDataCaptureService,
    MobileVerificationService,
    ContactService,
    DefendantManagerService,
    CalendarService,
    DefendantWatchService,
    SuretyService,
    WhiteLabelService,
    DummydataService,
    FormgroupsService,
    VersionCheckService,
    BondImportService,
    RekognitionService,
    DebtorService,
    NotesService,
    PaymentOffersService,
    DefmgrAddService,
    LinkService,
    CollectionPaymentService,
    ExcelExportService,
    PopupService,
    DatePipe,
    PaymentGatewaySettingsService,
    StateService,
    SmartResolverService,
    CanDeactivateGuardService,
    AffiliateService,
    PopoutService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
})
export class CoreModule {
}
