<div class="register-nuvei-banner"
  *ngIf="isReminderPaused"
  @registrationBannerTrigger>
  <p class="banner-message">
    You have paused all Reminders and Debtor Communication
  </p>
  <button mat-icon-button (click)="isReminderPaused = false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="register-nuvei-banner"
  *ngIf="!isReminderPaused && isGatewayDetailsLoaded && !gatewayId && isPeerToPeerDetailsLoaded && !hasPeerToPeer"
  @registrationBannerTrigger>
  <p class="banner-message">
    Hi {{ userName || 'there' }}, setup
    <a [routerLink]="gatewayRegistrationLink">online payments</a>
    to allow clients to pay with 1-click!
  </p>
  <button mat-icon-button (click)="isGatewayDetailsLoaded = false">
    <mat-icon>close</mat-icon>
  </button>
</div>
