import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObjectUtilities } from '../../../shared/ObjectUtilities';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;

@Component({
  selector: 'app-ssn',
  templateUrl: './ssn.component.html',
  styleUrls: ['./ssn.component.css']
})
export class SsnComponent implements OnInit {
  _ssn: string;

  @Input()
  get Ssn() {
    return this._ssn;
  }

  set Ssn(val) {
    this._ssn = val;
    this.SsnChange.emit(this._ssn);
  }
  @Output() SsnChange = new EventEmitter<string>();
  @Input() readOnlyField = false;
  @Input() inputDisabled = false;
  @Input() placeholderVisible = true;
  @Input() maskInvalidMessage = "Social Security Number is invalid."
  @Input() patternInvalidMessage = "Social Security Number is invalid.";
  @Output() ssnUpdated = new EventEmitter<string>();

  ssnRules: any = {X: /[02-9]/};
  ssnPattern: any = /^(\s*|\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/;
  constructor() {
  }

  ngOnInit() {
  }

  checkValue(e: any) {
    // EM - man, what a silly workaround simply because empty string does not pass validation
    setTimeout(function () {

      const text = e.component.option('text');
      let value = e.component.option('value');
//      // console.log('ssn', text, value, e.component.___maskText, isNullUndefinedOrEmpty(value))
      if (isNullUndefinedOrEmpty(value)) {
  //      // console.log('ssn null')
        e.component.option('isValid', true);
      } else {
        if (text === e.component.___maskText && value.length) {
   //       // console.log('ssn length', value, value.length);
          value = '';
          e.component.option('value', value);
        }
        if (!value.length) {
   //       // console.log('ssn no length', value, value.length);
          e.component.option('isValid', text === e.component.___maskText);
        } else {
   //       // console.log('ssn length', value, value.length);
        }
   //     // console.log('ssn length outside inside if chain', value, value.length);
      }
   //   // console.log('ssn length outside if chain', value, value.length);

    });
  }

  onFocusOut(e: any) {
    this.checkValue(e);

  }

  onInitialized(e: any) {
    // console.log('onssn initialized', e);
    setTimeout(function () {
      e.component.___maskText = e.component.option('text');
    });
  }

  onValueChanged(e: any) {
    if (e.event != null && !!e.value) {
      this.checkValue(e);
      // console.log('onssn valuechanged', e);
      this.ssnUpdated.emit(e.value);
    }
  }

}
