<!-- Main view/routes wrapper-->
<!--<div class="welcome-container" *ngIf="showWelcome" [@fadeInFromBottomOutFromTop]="'in'">-->
<!--  <h1 class="welcome-animation">Welcome</h1>-->
<!--</div>-->
<div id="non-print-container">
  <div class="page-contents animation-wrapper" [@routeAnimations]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet" (activate)="onActiveEvent($event)"></router-outlet>
  </div>

  <app-yes-no-confirmation
    [showTitle]="true"
    [titleText]="YesNoConfirmationTitle"
    [(showConfirmation)]="YesNoConfirmationShow"
    [showCloseButton]="YesNoConfirmationShowClose" (onResult)="YesNoConfirmResult($event)"
    [confirmationMessage]="YesNoConfirmMessage"
    [noText]="YesNoNoText" [yesText]="YesNoYesText"
    [closeOnOutsideClick]="YesNoCloseOnOutsideClick"
    [yesWidth]="YesNoYesButtonWidth"
    [maxWidth]="YesNoMaxWidth">
  </app-yes-no-confirmation>

  <app-guided-setup></app-guided-setup>
</div>

<div id="app-print-container"></div>
