import { createFeatureSelector, createSelector } from '@ngrx/store';
import { companyFeatureKey, CompanyState } from '../reducers/company.reducer';
import { ICompany } from '../../models/icompany';

export const selectCompanyFeature = createFeatureSelector<CompanyState>(companyFeatureKey);
export const selectFeature = (state: CompanyState) => state.company;
export const selectGooglePlace = (company: ICompany) => company?.GooglePlaceId;

export const selectCompany = createSelector(
  selectCompanyFeature,
  selectFeature,
);

export const selectGooglePlaceId = createSelector(
  selectCompany,
  selectGooglePlace,
)
