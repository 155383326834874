import {map, catchError} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {ApiUtilities} from '../shared/ApiUtilities';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import { RequestDocumentData } from '../views/prospects/request-document/request-document.component';

@Injectable()
export class TransactionService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  getNewTransaction(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/transactions`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  getExistingTransaction(id: string | number, includes?: string): Observable<any> {
    includes = includes || '';
    
    if(!!includes) {
      includes = `?include=${includes}`;
    }
    return this.http.get(`${this.settings.getBaseUrl()}/transactions/${id}${includes}`).pipe(
      map( (res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  updateExistingTransaction(id: string, data: any, includes?: string, createCheckIn = false): Observable<any> {
    includes = includes || '';
    
    if(!!includes) {
      includes = `?include=${includes}`;
    }

    data.createCheckIn = createCheckIn;
    if (id == null) {
      return throwError(new Error('Transaction id is undefined'));
    } else {
      return this.http.put(`${this.settings.getBaseUrl()}/transactions/${id}${includes}`, data).pipe(
        map( (res: any) => {
          delete data.createCheckIn;
          return res;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
    }
  }

  getDefendantFromTransactionId(id: string | number, customerId: any): Observable<any> {
    // Have to set fromWizard to true to spoof condition, other getting user null error
    // See defendantTransformer in backend api,fn includeTransactions
    return this.http.get(`${this.settings.getBaseUrl()}/transactions/getdefendantfromtransactionid/${id}?
    include=person.addresses,person.vehicles,person.employers.addresses,person.emails,person.phones,transactions.bonds,transactions.notes,transactions.collaterals,transactions.contacts.person.addresses,transactions.contacts.person.employers.addresses,transactions.contacts.person.phones,transactions.contacts.person.emails,transactions.contacts.person.vehicles,
    &customerId=${customerId}&fromWizard=true`).pipe(
      map( (res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  documentRequest(data: RequestDocumentData): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/transactions/document-request`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  getTransactionTrackingDetails(id: string | number) : Observable<any> {
    // Have to set fromWizard to true to spoof condition, other getting user null error
    // See defendantTransformer in backend api,fn includeTransactions
    return this.http.get(`${this.settings.getBaseUrl()}/transactions/tracking-details/${id}`).pipe(
      map( (res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  createTransactionTrackingEvent(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/transactions/tracking-details/${data.SourceId}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }

  deleteTransaction(id: string) : Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/transactions/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),);
  }
}
