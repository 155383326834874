<app-routedata (onNavIdChange)="onNavIdChange($event)"></app-routedata>
<mat-card [class.mat-elevation-z0]="router.url !== '/checkin'">
<!--  <mat-tab-group [selectedIndex]="!showCheckinHistory ? 0 : 1">-->
<!--    <mat-tab label="Record Check In">-->
      <div style="width: 98%;">
        <dx-validation-group #valGroup>
          <div class="row" *ngIf="showDefendantSearch">
            <div class="col-sm-4 text-right">
              <label class="control-label">Defendant</label>
            </div>
            <div class="col-sm-4">
              <app-searchbox
                [selectMode]="true"
                [defendantsOnly]="true"
                (onRecordSelected)="onDefendantSelected($event)"
                placeHolderText="Search for defendant"
                [selectedDefendant]="">
              </app-searchbox>
            </div>
          </div>
          <div class="row" *ngIf="!showDefendantSearch">
            <div class="col-sm-4 text-right">
              <label class="control-label">Defendant</label>
            </div>
            <div class="col-sm-4">
              <dx-text-box [readOnly]="true" [value]="defendantName">
                <dx-validator name="Defendant">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 text-right">
              <label class="control-label">Check In Date</label>
            </div>
            <div class="col-sm-4">
              <dx-date-box
                [(value)]="checkinDate"
                dateSerializationFormat="yyyy-MM-dd"
                [displayFormat]="dateFormatter"
                type="date">
                <dx-validator>
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dx-validator>
              </dx-date-box>
            </div>
          </div>

          <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-4 text-right">
              <label class="control-label">Comment</label>
            </div>
            <div class="col-sm-4">
              <dx-text-area [maxLength]="maxLength" [(value)]="comment"></dx-text-area>
            </div>
          </div>

          <app-reminder-prompt promptSource="Checkin"></app-reminder-prompt>

          <app-buttonbar
            [routeKey]="'Defendant'"
            [saveAndGoVisible]="false"
            [saveButtonsDisabled]="saveButtonDisabled"
            #navButtonBar
            [saveMethod]="onSaveFn"
            [(selectedRoute)]="selectedNavRoute"
            [valGroup]="valGroup"
            [useCancelButton]="dynamicData !== undefined && dynamicData !== null"
            [cancelMethod]="cancelFn">
          </app-buttonbar>
        </dx-validation-group>
      </div>
<!--    </mat-tab>-->
<!--    <mat-tab label="Check In History">-->
<!--      <div>-->
<!--        <dx-button-->
<!--          type="default"-->
<!--          text="{{ addEditCheckinText }}"-->
<!--          (click)="editCheckInSchedule($event)">-->
<!--        </dx-button>-->
<!--      </div>-->
<!--      <dx-data-grid-->
<!--        #checkinHistoryGrid-->
<!--        id="checkinHistoryGrid"-->
<!--        [allowColumnResizing]="true"-->
<!--        [columnAutoWidth]="true"-->
<!--        [dataSource]="checkinHistoryGridData"-->
<!--        rowAlternationEnabled="true"-->
<!--        [columnAutoWidth]="true"-->
<!--        [loadPanel]="{-->
<!--          enabled: true,-->
<!--          showIndicator: true,-->
<!--          showPane: true,-->
<!--          text: 'Loading...'-->
<!--        }"-->
<!--        noDataText="No Checkin history is available for this defendant"-->
<!--        (onToolbarPreparing)="onCheckinGridToolbarPreparing($event)">-->
<!--        <dxi-column-->
<!--          caption="Date"-->
<!--          [visible]="true"-->
<!--          dataField="ScheduledDate"-->
<!--          dataType="date">-->
<!--        </dxi-column>-->
<!--        <dxi-column-->
<!--          caption="Checked In"-->
<!--          [visible]="true"-->
<!--          dataField="CheckedIn"-->
<!--          dataType="boolean">-->
<!--        </dxi-column>-->
<!--        <dxi-column-->
<!--          caption="Missed"-->
<!--          [visible]="true"-->
<!--          dataField="Missed"-->
<!--          dataType="boolean">-->
<!--        </dxi-column>-->
<!--        <dxi-column-->
<!--          caption="Comment"-->
<!--          [visible]="true"-->
<!--          dataField="Comment">-->
<!--        </dxi-column>-->
<!--        <dxi-column-->
<!--          caption="Photo"-->
<!--          [visible]="true"-->
<!--          dataField="PictureUrl"-->
<!--          cellTemplate="photoTemplate">-->
<!--        </dxi-column>-->
<!--        <dxi-column-->
<!--          caption="Location"-->
<!--          [visible]="true"-->
<!--          dataField="Latitude"-->
<!--          cellTemplate="locationTemplate">-->
<!--        </dxi-column>-->
<!--        <dxi-column-->
<!--          caption="Action"-->
<!--          [visible]="true"-->
<!--          cellTemplate="actionTemplate">-->
<!--        </dxi-column>-->
<!--  -->
<!--        <div *dxTemplate="let data of 'photoTemplate'">-->
<!--          <a *ngIf="data.value" href="{{ data.value }}" target="_blank">-->
<!--            <img src="{{ data.value }}" style="display:block; width:25px; height:25px;"/>-->
<!--          </a>-->
<!--          <span *ngIf="!data.value">(none)</span>-->
<!--        </div>-->
<!--  -->
<!--        <div *dxTemplate="let data of 'actionTemplate'">-->
<!--          <a (click)="recordCheckIn(data.data)">Record Check In</a>-->
<!--        </div>-->
<!--  -->
<!--        <div *dxTemplate="let data of 'locationTemplate'">-->
<!--          <a [hidden]="!data.data.Latitude" (click)="navigateOnMap(data.data)">-->
<!--            {{ data.data.Latitude }}, {{ data.data.Longitude }}-->
<!--          </a>-->
<!--        </div>-->
<!--  -->
<!--        <dxo-editing-->
<!--          [allowUpdating]="false"-->
<!--          [allowAdding]="false"-->
<!--          [allowDeleting]="false">-->
<!--        </dxo-editing>-->
<!--      </dx-data-grid>-->
<!--    </mat-tab>-->
<!--  </mat-tab-group>-->
</mat-card>


<app-errorpopup
  [routeToGoToAfterDisplay]="noDefendantRoute"
  [height]="'300'"
  [width]="'400'"
  [resizeEnabled]="true"
  [closeOnOutsideClick]="false"
  [title]="errorTitle"
  [shading]="false"
  [data]="errorData"
  [(visible)]="errorVisible">
</app-errorpopup>

<app-dynamic-popup
  [popupComponent]="dynamicPopupClass"
  [popupComponentData]="dynamicPopupData"
  [popupComponentTitle]="dynamicPopupTitle"
  [showCloseButton]="true"
  [popupComponentVisible]="dynamicPopupVisible"
  [popupShowTitle]="dynamicPopupShowTitle"
  (onPopupHidden)="dynamicPopupHidden($event)">
</app-dynamic-popup>
