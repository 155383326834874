import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {ApiUtilities} from '../shared/ApiUtilities';
import { SalesReport } from '../models/sales-report';
import { PortoResponse } from '../models/porto-response';
import { DischargeReport } from '../models/discharge-report';

@Injectable()
export class DischargereportService {

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getBondsForDischargeReport(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/dischargereports/getpowers`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getReportList(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/dischargereports`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getReport(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/dischargereports/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getBankersReports(): Observable<DischargeReport[]> {
    return this.http.get<PortoResponse<DischargeReport[]>>(`${this.settings.getBaseUrl()}/bankers/dischargereports`).pipe(
      map((res: PortoResponse<DischargeReport[]>) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  sendExonToBankers(reportId: string): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/bankers/exoneration/${reportId}`, {}).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/dischargereports/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getEarliestDateForReport(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/dischargereports/earliestdate/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }
  save( id: any, data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/dischargereports/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/dischargereports`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }
  getExport(id: any, type: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/dischargereports/exports/${id}/${type}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

}
