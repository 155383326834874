<div *ngIf="contentReady">
	<dx-validation-group #valGroup>
  	<dx-form
      #payerForm
      id="payerForm"
      [formData]="contact"
      [colCount]="2"
      validationGroup="valGroup"
      [showColonAfterLabel]="false">
      <dxi-item itemType="group" [colCount]="1">
        <dxi-item
          [visible]="true"
          dataField="person.First"
          [editorOptions]="{ maxLength: lastNameLength }"
          editorType="dxTextBox"
          [label]="{ text: 'First Name' }">
          <dxi-validation-rule
            type="required"
            message="First Name required">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
          [visible]="true"
          dataField="person.Middle"
          editorType="dxTextBox"
          [editorOptions]="{ maxLength: middleNameLength }"
          [label]="{ text: 'Middle Name' }">
        </dxi-item>
        <dxi-item
          [visible]="true"
          dataField="person.Last"
          editorType="dxTextBox"
          [editorOptions]="{ maxLength: lastNameLength }"
          [label]="{ text: 'Last Name' }">
          <dxi-validation-rule
            type="required"
            message="Last Name required">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
          [visible]="true"
          dataField="person.emails[0].Email"
          [label]="{ text: 'Email' }">
          <dxi-validation-rule
            type="email"
            message="Please enter a valid email">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
          [visible]="true"
          dataField="person.phones[0].Number"
          [label]="{ text: 'Phone' }"
          editorType="dxTextBox"
          [editorOptions]="{
            maskRules: phoneRules,
            mask: phoneMask,
            mode: 'tel',
            maskInvalidMessage: 'Please enter a valid phone number'
          }">
          <dxi-validation-rule
            type="pattern"
            [pattern]="phonePattern"
            message="Please enter a valid phone number">
          </dxi-validation-rule>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="1">
      	<!-- <dxi-item
          [visible]="true"
          dataField="Primary"
          [label]="{ text: 'Make Payer Primary Payer' }"
          editorType="dxCheckBox">
        </dxi-item> -->
        <dxi-item
          [visible]="true"
          dataField="person.addresses"
          [label]="{ text: 'Address' }"
          [template]="'addressTemplate'">
        </dxi-item>
        <div *dxTemplate="let data of 'addressTemplate'">
	      	<label class="dx-field-item-label dx-field-item-label-location-top">
	      		<span class="dx-field-item-label-text">Address</span>
	      	</label>
	      	<div>
	      		<app-smartaddress
	          	sourceType="Person"
							[singleAddressMode]="true"
							[(model)]="contact.person.addresses">
						</app-smartaddress>
	      	</div>
        </div>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="2" [template]="'edtButtonBar'"></dxi-item>

      <div *dxTemplate="let itm of 'edtButtonBar'">
		  	<app-buttonbar #navButtonBar
		  		[saveMethod]="onSave"
		  		[saveButtonsDisabled]="saveButtonsDisabled"
          [useCancelButton]="inDynamicPopup"
          [cancelMethod]="onCancel"
          [valGroup]="valGroup"
        >
        </app-buttonbar>
      </div>

    </dx-form>
	</dx-validation-group>
</div>

<app-errorpopup
  [height]="'300'"
  [width]="'400'"
  [resizeEnabled]="true"
  [closeOnOutsideClick]="false"
  [title]="errorTitle"
  [shading]="false"
  [data]="errorData"
  [(visible)]="errorVisible">
</app-errorpopup>
