<div *ngIf="transactions.length > 1" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs='space-around center' fxLayoutGap='16px'>
  <label class='control-label'>Select Posted Date</label>
  <dx-select-box
    [dataSource]="transactionList"
    displayExpr="Text"
    valueExpr="Id"
    placeholder="Select Posted Date"
    [formControl]='transactionControl'
  ></dx-select-box>
</div>
<app-document
  [(model)]="transactions[transactionControl.value].documents"
  [defendant]='defendant'
  [contacts]='transactions[transactionControl.value].contacts'
></app-document>
