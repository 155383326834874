import { map, switchMap } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Type,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { DefendantService } from '../../../services/defendant.service';
import { SearchService } from '../../../services/search.service';
import { CheckinComponent } from '../../../views/checkin/checkin.component';
import { DynamicPopupComponent, IDynamicPopupComponent } from '../dynamic-popup/dynamic-popup.component';
import DataSource from 'devextreme/data/data_source';
import { isNullOrUndefined } from 'util';
import { AppConstants } from '../../../shared/AppConstants';
import { SearchresultsComponent } from '../searchresults/searchresults.component';
import { environment } from '../../../../environments/environment';
import * as LogRocket from 'logrocket';
import { PaymentComponent } from '../../../views/common/payment/payment.component';
import { UserService } from '../../../services/user.service';
import { BillingService } from '../../../services/billing.service';
import { WhiteLabelService } from '../../../services/white-label.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { EventService } from '../../../services/event.service';
import {UnsubscribeOnDestroyAdapter} from '../../../common/UnsubscribeOnDestroy';
import { DxoButtonOptions } from 'devextreme-angular/ui/nested/base/button-options';

@Component({
  selector: 'topnavbar',
  templateUrl: 'topnavbar.component.html',
  styleUrls: ['topnavbar.component.scss'],
  providers: [UserService, WhiteLabelService],
  entryComponents: [
    DynamicPopupComponent,
  ],
})
export class TopnavbarComponent extends UnsubscribeOnDestroyAdapter implements AfterViewInit {

  @ViewChild('TopNavBarSearchResults')
  searchResultsComponent: SearchresultsComponent;

  @Output() sideNavEvent: EventEmitter<null> = new EventEmitter();
  @Output() contentLoaded: EventEmitter<boolean> = new EventEmitter();

  search = [];
  searchResults = [];
  searchMode = false;
  searchingState = false;
  searchData: DataSource;
  searchKeyword;
  $search: Subscription;

  showDebugButton = false;
  popupVisible: any;
  popupTitle: any;
  searchTimerHandle: any;
  hasBailManagement = false;

  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData = [];
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = true;

  subscribedProducts: any;
  appConstants = AppConstants;
  logoPath = 'assets/img/logo.png';
  logoUrl = '/dashboard';
  isCollectionSubscribed: boolean = false;
  isCustomer = true;
  mobileQuery: MediaQueryList;
  doneLoading = false;
  searchText = '';
  shortcutOptions = [
    {
      optionText: 'Add Defendant',
      optionColor: 'primary',
      optionIcon: 'fa-user-plus',
      optionClicked: 'defendant',
      conditions: AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
    }, {
      optionText: 'Take Payment',
      optionIcon: 'fa-dollar-sign',
      optionClicked: 'payment',
      conditions: AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
    }, {
      optionText: 'Check In',
      optionIcon: 'fa-check-square',
      optionClicked: 'checkin',
      conditions: AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM,
    },
  ];
  extendedSearch = false;

  advancedSearchButton = {
    icon: 'fal fa-sliders',
    stylingMode: 'text',
    onClick: () => {
      // Open advanced search
    },
  };

  advancedSearchVisible = false;

  private _mobileQueryListener: () => void;

  constructor(
    private router: Router,
    private searchService: SearchService,
    private _el: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private defendantService: DefendantService,
    private userService: UserService,
    private billingService: BillingService,
    private whiteLabel: WhiteLabelService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private eventService: EventService,
  ) {
    super();
    this.popupVisible = false;

    if (environment.environmentName === 'test') {
      this.showDebugButton = true;
    }

    this.subs.sink = this.userService
    .current()
    .subscribe(resp => {
      if (!!resp.data) {
        this.isCustomer = resp.data.Type != 'Administrator';
      }
    });

    this.subs.sink = this.billingService.GetSubscribedProducts().subscribe(products => {
      this.hasBailManagement = !!products.BAILMANAGEMENT;
      this.subscribedProducts = products;
      // console.log('subscribedProducts response in top navbar: ', this.subscribedProducts);
      this.isCollectionSubscribed = this.subscribedProducts && this.subscribedProducts[AppConstants.PRODUCT_NAME_COLLECTIONS];

      this.subs.sink = this.whiteLabel
        .getSmallLogoPath().pipe(
        map(logoPath => {
          this.logoPath = logoPath;
          // console.log('setting topNavBar.logoPath', logoPath);
        }),
        switchMap(() => {
          return this.whiteLabel.getLogoUrl();
        }),)
        .subscribe(logoUrl => {
          this.logoUrl = logoUrl;
          this.doneLoading = true;
        });
    });

  }

  ngAfterViewInit() {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  @HostListener('document:click', ['$event.target'])
  clickout(ev) {
    // // console.log(ev);
    if (!this._el.nativeElement.contains(ev)) {
      this.searchResults = [];
    }
  }

  toggleSidenav() {
    this.sideNavEvent.emit();
  }

  searchChanged(e) {
    this.searchText = e.selectedItem;
    clearTimeout(this.searchTimerHandle);
    if (!this.hasBailManagement) {
      this.searchKeyword = e.selectedItem;
    } else {
      if (e && e.selectedItem && e.selectedItem.length >= 1) {
        this.searchTimerHandle = setTimeout(() => {
          this.loadSearchData(e.selectedItem);
          this.searchKeyword = e.selectedItem;
          // console.log('onselectionchanged', this.searchKeyword);
        }, 1500);
      }
    }
  }

  searchDefocused() {
      setTimeout(() => {
        if (!this.extendedSearch) {
          this.searchMode = false;
          // This component is a shitshow fml
          // this.searchKeyword = '';
        }
      }, 200);
  }

  queryChanged(e: any) {
    this.searchText = e.value;
    clearTimeout(this.searchTimerHandle);
    if (!this.hasBailManagement && !this.isCollectionSubscribed) {
      this.searchKeyword = e.value;
    } else {
      if (e && e.value && e.value.length >= 1) {
        this.searchTimerHandle = setTimeout(() => {
          this.loadSearchData(e.value);
          this.searchKeyword = e.value;
        }, 1500);
      }
    }
  }

  handleEnter(e: any): void {
    this.searchText = e.event.target.value;
    clearTimeout(this.searchTimerHandle);
    if (!this.hasBailManagement && !this.isCollectionSubscribed) {
      this.searchKeyword = this.searchText;
    } else {
      this.loadSearchData(this.searchText);
      this.searchKeyword = this.searchText;
    }
  }

  clickedOption(option) {
    // console.log(option);
    switch (option) {
      case 'defendant':
        this.goToDefendantWizard();
        break;

      case 'payment':
        this.openTakePayment();
        break;

      case 'checkin':
        this.openRecordCheckIn();
        break;
    }
  }

  navDefendant(id) {
    this.router.navigateByUrl('/defendant/' + id);
  }

  fillSearchFromRecentDefendants() {
    // console.log('searchResults ', this.searchResults);
    // console.log('searchText  ', this.searchText);
    if (this.searchText == '') {
      this.searchResults = [];
      const rd = this.defendantService.getRecentDefendants(5);
      if (rd != null && rd.length > 0) {
        rd.forEach(recentDefendant => {
          const newRD = {
            object: 'Search',
            Id: recentDefendant.Id,
            DefendantId: recentDefendant.Id,
            DefendantName: recentDefendant.Name,
            FirstLast: recentDefendant.Name,
            Dob: recentDefendant.DOB,
            Source: 'RECENT DEFENDANTS',
            GroupSource: '0_RECENT DEFENDANTS',
            ProfilePicture: isNullOrUndefined(recentDefendant.ProfilePicture)
              ? '/assets/img/defaultuser-flat.teal.svg'
              : recentDefendant.ProfilePicture,
            ShowContextMenu: false,
            ButtonId: 'buttonRECENT DEFENDANTS' + recentDefendant.Id,
          };
          this.searchResults.push(newRD);
        });
      }
    }
    this.searchKeyword = 'recent';
    this.searchResultsComponent.setCounts();

  }

  loadSearchData(query: any) {
    this.searchResults = [];

    if (query != null && query !== '') {
      this.searchingState = true;
      this.$search = this.searchService.getAllSearchData(query, false, this.isCollectionSubscribed, this.hasBailManagement, false, this.extendedSearch)
        .subscribe(data => {
          this.search = data;

          const maxResultCount = 1000;

          if (
            this.search != null &&
            this.search.length > maxResultCount
          ) {
            // Too many results, create error result
            let result = {
              Source: 'ERROR',
              ErrorMessage: `More than ${maxResultCount} records found. Please narrow your search or try using Advanced Search.`,
              GroupSource: '-1_ERROR',
              ShowContextMenu: false,
            };
            this.searchResults.push(result);
          } else if (!isNullOrUndefined(this.search) && this.search.length > 0) {
            this.search.forEach(result => {
              result.Source = result.Source.toUpperCase();
              result.GroupSource =
                (result.Source === 'DEFENDANT' ? '0_' : '1_') + result.Source;
              result.ShowContextMenu = false;
              if (isNullOrUndefined(result.ProfilePicture)) {
                result.ProfilePicture = '/assets/img/defaultuser-flat.teal.svg';
              }
              result.ButtonId = 'button' + result.Source + result.Id;
              this.searchResults.push(result);
            });
          } else {
            let result = {
              Source: 'NORESULT',
              ErrorMessage: 'No Results Found. Try Advanced Search.',
              GroupSource: '-1_NORESULT',
              ShowContextMenu: false,
            };
            this.searchResults.push(result);
          }

          this.searchResultsComponent.setCounts();
          this.searchResultsComponent.fillSearchDataSource(this.searchResults);
          this.extendedSearch = false;
          this.$search.unsubscribe();
          this.searchingState = false;
          // console.log('search results', this.searchResults);
      });
    }
  }

  openRecordCheckIn() {
    this.dynamicPopupClass = CheckinComponent;
    this.dynamicPopupTitle = 'Record Check In';
    this.dynamicPopupVisible = true;
  }

  openTakePayment() {
    this.dynamicPopupClass = PaymentComponent;
    this.dynamicPopupTitle = 'Take Payment';
    this.dynamicPopupVisible = true;
  }

  goToDefendantWizard() {
    // console.log('local', localStorage.getItem('NewDefendantMode'));
    const mode = localStorage.getItem('NewDefendantMode');
    if (mode && mode.includes('wizard')) {
      localStorage.setItem('NewDefendantMode', '/defendant/wizard');
    } else {
      localStorage.setItem('NewDefendantMode', '/defendant/data-capture');
    }
    this.router.navigate([localStorage.getItem('NewDefendantMode') || '/defendant/data-capture']);
  }

  dynamicPopupHidden() {
    if (this.dynamicPopupClass === PaymentComponent) {
      this.eventService.globalPayment();
    }
    this.dynamicPopupClass = null;
  }

  onTakePaymentAction(e) {
    this.dynamicPopupClass = PaymentComponent;
    this.dynamicPopupData = [];
    this.dynamicPopupTitle = 'Take Payment';
    this.dynamicPopupData['DownPaymentAmount'] = 0;
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = e.DefendantId;
    this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = e.FullName;
    this.dynamicPopupData['showDefendantSearch'] = true;
    this.dynamicPopupVisible = true;
  }

  onCheckInAction(e) {
    // console.log('on checkin action ', e);
    this.dynamicPopupClass = CheckinComponent;
    this.dynamicPopupData = [];
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = e.DefendantId;
    this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = e.FullName;
    this.dynamicPopupTitle = 'Record Check In';
    this.dynamicPopupVisible = true;
  }

  debugHandler() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', LogRocket.sessionURL);
      e.preventDefault();
      document.removeEventListener('copy', function (){
      });
    });
    document.execCommand('copy');
  }

  onContentLoaded(event: boolean) {
    this.contentLoaded.emit(event);
  }

  /**
   * The handleExtendedSearch method is fired when the extended search checkbox
   * is checked. When this event fires we call the loadSearchData method with the
   * current search term to get new search results including extended information.
   * If the current searchKeyWord is recent we do nothing since this means the user
   * is just showing recent defendants.
   * @param checked - true when the checkbox is checked, false when it isn't
   */
  handleExtendedSearch(checked: boolean) {
    if (checked && this.searchKeyword !== 'recent') {
      this.searchMode = true;
      this.loadSearchData(this.searchKeyword);
    }
  }

  handleAdvancedSearchClick(show: boolean) {
    // We have to empty the searchKeyword to hide the existing search. If not the results can be stuck open
    this.searchKeyword = '';
    this.advancedSearchVisible = !show;
  }

  closeAdvancedSearch(close: boolean) {
    this.advancedSearchVisible = close;
  }

  handleAdvancedSearchResults(results: any) {
    this.searchResults = results;
    this.searchText = 'advanced-search';
    this.searchKeyword = 'advanced-search';
    this.searchResultsComponent.setCounts();
    this.searchResultsComponent.fillSearchDataSource(this.searchResults);
  }

  handleAdvancedSearchLoading(loading: boolean) {
    this.searchingState = loading;
  }

}
