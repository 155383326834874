import { catchError, map, mergeMap } from 'rxjs/operators';

import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { ApiUtilities } from '../shared/ApiUtilities';

import { ReportCompilationRequest } from '../models/reportcompilationrequest';
import { PdfBindRequest } from '../models/pdfbindrequest';

import * as moment from 'moment';
import { PdfSignatureImageBindRequest } from '../models/pdfsignatureimagebindrequest';
import { Share } from '../models/share';
import { PdfReport } from '../models/pdf-report';
import { PortoResponse } from '../models/porto-response';

@Injectable()
export class ReportService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(private http: HttpClient,
              private settings: SettingsService) {
  }

  public getReports(limitByPermissions = false): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/reports?limit=0`, {headers: this.headers}).pipe(
      mergeMap((reports: any) => {
        if (!limitByPermissions) {
          return of(reports);
        }
        return this.getReportPermissionsForCurrentUser().pipe(
          map((perm) => {
            const reportsToReturn = [];
            for (const report of reports.data) {
              if (perm.data.find(p => p.ObjectId === report.ReportId && p.ObjectType === 'Advanced Report')) {
                reportsToReturn.splice(0, 0, report);
              }
            }
            return {
              'data': reportsToReturn,
            };
          }));
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getReport(reportId: number): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/reports/${reportId}`, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getReportMetadata(reportId: number): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/reports/${reportId}/metadata`, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postReportCompilationRequest(request: ReportCompilationRequest): Observable<any> {

    if (request.filterExpression) {
      // strip time from dates
      request.filterExpression = this.stripFilterExpressionDateTimes(request.filterExpression);
    }
    // Append the path name
    request.pathName = window.location.href;

    return this.http.post(`${this.settings.getBaseUrl()}/reports/compilation`, request, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postPdfBindRequest(request: PdfBindRequest, pub = false, customerId?: string): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/reports/pdfbind`;
    if (pub) {
      url += `/${customerId}`;
    }
    return this.http.post(url, request, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public postPdfSignatureImageBindRequest(request: PdfSignatureImageBindRequest, share: Share): Observable<any> {
    request.share = share;
    return this.http.post(`${this.settings.getBaseUrl()}/reports/pdfsignatureimagebind`, request, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getReportsByCategory(category: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/reports?search=ReportCategory:${category}&limit=0`, {headers: this.headers}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getReportsByType(type: any, limitByPermissions = false): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/reports/bytypes/${type}`, {headers: this.headers}).pipe(
      mergeMap((reports: any) => {
        if (!limitByPermissions) {
          return of(reports);
        }
        return this.getReportPermissionsForCurrentUser().pipe(
          map((perm) => {
            const reportsToReturn = [];
            for (const report of reports.data) {
              if (perm.data.find(p => p.ObjectId === report.ReportId && p.ObjectType === 'Advanced Report')) {
                reportsToReturn.splice(0, 0, report);
              }
            }
            return {
              'data': reportsToReturn,
            };
          }));
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }


  public updateMappingsForWebSiteBonding(data: any): Observable<any> {
    const requestData = {mappings: data};
    // console.log('update mappings request data', requestData);
    return this.http.put(`${this.settings.getBaseUrl()}/reports/websitebonding`, requestData).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  public getReportPermissionsForUserOrGroup(id: any, type: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/reportpermissions/${type}/${id}`).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getReportPermissionsForCurrentUser(): Observable<any> {
    const userId = localStorage.getItem('userId');
    return this.getReportPermissionsForUserOrGroup(userId, 'user');
  }

  public updateReportPermissionsForUserOrGroup(id: any, type: any, data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/reportpermissions/${type}/${id}`, data).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public markReportAsFavorite(reportId: String): Observable<any> {
    const data = {
      ReportId: reportId,
    };
    return this.http.post(`${this.settings.getBaseUrl()}/reportfavorites`, data).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public removeReportAsFavorite(reportId: String): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/reportfavorites/${reportId}`).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getAllReportFavorites(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/reportfavorites?limit=0`).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getPdfReports(): Observable<PdfReport[]> {
    return this.http.get<PortoResponse<PdfReport[]>>(`${this.settings.getBaseUrl()}/pdfreports?limit=0`).pipe(
      map(response => response.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public savePdfReports(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/pdfreports`, data).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getSelectedPdfReports(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/selectedpdfreports`).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))));
  }

  public getExternalSelectedPdfReports(customerId: string, requestId: string): Observable<PdfReport[]> {
    return this.http.get<PortoResponse<PdfReport[]>>(`${this.settings.getBaseUrl()}/external-selected-pdf-reports/${customerId}/${requestId}`)
      .pipe(
        map(response => response.data),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true))),
      );
  }

  private stripFilterExpressionDateTimes(group: any) {
    if (Array.isArray(group)) {
      for (let i = 0; i < group.length; i++) {
        if (Array.isArray(group[i])) {
          group[i] = this.stripFilterExpressionDateTimes(group[i]);
        } else if (group[i] instanceof Date) {
          group[i] = moment(group[i]).format('YYYY-MM-DD');
        }
      }
      return group;
    }
  }

}
