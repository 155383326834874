import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class StorageService {
  private storageSub = new Subject<string>();

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next('changed');
  }

  removeItem(key) {
    localStorage.removeItem(key);
    this.storageSub.next('changed');
  }

  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeItemByPrefix(key: string, type : string) :void {
    if(type === 'local') {
      Object.keys(localStorage)
      .filter(x => x.startsWith(key))
      .forEach(x => localStorage.removeItem(x));
    } else {
      Object.keys(sessionStorage)
      .filter(x => x.startsWith(key))
      .forEach(x => sessionStorage.removeItem(x));
    }
  }

  constructor() { }

}
