import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PdfObservableItem } from '../pdf-viewer/pdf-viewer.component';
import { FieldValues } from '../../../models/reportcompilationrequest';
import { Defendant } from '../../../models/defendant';
import { Contact } from '../../../models/contact';
import { PopupService } from '../../../services/popup.service';

export interface PdfDialogData {
  pdfObservable: Observable<any>;
  pdfObservableItems?: Array<PdfObservableItem>;
  esignable: boolean;
  reportCategory?: string;
  reportFieldValues?: FieldValues[] | FieldValues;
  objectTypeName: string;
  objectName: string;
  sourceType: string;
  sourceId: string;
  isSmartFormPDF?: boolean;
  PDFBase64String: string;
  defendant: Defendant;
  contacts: Contact[];
  reportIds: string[];
  pdfPurpose: any;
  coordinateData: any;
}

@Component({
  selector: 'app-pdf-dialog',
  templateUrl: './pdf-dialog.component.html',
  styleUrls: ['./pdf-dialog.component.scss'],
})
export class PdfDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PdfDialogData,
    public dialogRef: MatDialogRef<PdfDialogComponent>,
    public popupService: PopupService,
  ) { }

  ngOnInit(): void {
    this.popupService.maximizePopup$.subscribe(isMaxSize => {
      if (isMaxSize) {
        this.dialogRef.updateSize('100vw', '100vh');
      } else {
        this.dialogRef.updateSize('50vw', '50vh');
      }
    });
  }

  onEsignSent() {
    this.dialogRef.close(true);
  }

  handleCloseButton() {
    this.dialogRef.close();
  }

}
