import { take } from 'rxjs/operators';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Defendant } from '../../../models/defendant';
import { Bond } from '../../../models/bond';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StringUtilities } from '../../../shared/StringUtilities';
import { BondService } from '../../../services/bondservice.service';
import { NotifyService } from '../../../common/notify/notify.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { Bail } from '../../../models/bail';

export interface BondStatusData {
  action: 'Exonerate' | 'Forfeit' | 'SetAside';
  defendant: Defendant;
  bondId: string;
}

@Component({
  selector: 'app-bond-status-updater',
  templateUrl: './bond-status-updater.component.html',
  styleUrls: ['./bond-status-updater.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BondStatusUpdaterComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  inputLabel = 'Exoneration Date';
  selectedBond: Bond;
  transaction: Bail;
  boundField: any = null;
  busySaving = false;
  saveButtonColor = 'primary';
  errorData: any;
  errorVisible = false;
  dateFormatter = StringUtilities.dateFormatter;
  maxDate: Date = new Date();
  calculatedSatisfyByDate = moment();
  calculateSatisfyByDatePopupVisible = false;
  showDisposition = false;


  constructor(
    public dialogRef: MatDialogRef<BondStatusUpdaterComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: BondStatusData,
    private bondService: BondService,
    private notification: NotifyService,
  ) {
    super();
    this.checkDate = this.checkDate.bind(this);
  }

  ngOnInit() {
    this.dialogData.defendant.transactions.forEach(trans => {
      trans.bonds.forEach(bond => {
        if (!this.selectedBond) {
          if (bond.Id === this.dialogData.bondId) {
            this.selectedBond = bond;
            this.transaction = trans;
          }
        }
      });
    });

    switch (this.dialogData.action.toUpperCase().trim()) {
      case 'FORFEIT':
        this.inputLabel = 'Forfeiture Date';
        if (!!this.selectedBond.ForfeitureDate) {
          this.boundField = new Date(this.selectedBond.ForfeitureDate);
        }
        break;
      case 'SETASIDE':
      case 'SET ASIDE':
        this.inputLabel = 'Set Aside Date';
        if (!!this.selectedBond.ForfeitureSetAsideDate) {
          this.boundField = new Date(this.selectedBond.ForfeitureSetAsideDate);
        }
        break;
      default:
        // 'EXONERATE'
        this.inputLabel = 'Exoneration Date';
        this.showDisposition = true;
        if (!!this.selectedBond.DischargeOccurrenceDate) {
          this.boundField = new Date(this.selectedBond.DischargeOccurrenceDate);
        }
        break;
    }
  }

  checkDate(e): boolean {
    if (this.dialogData.action.toUpperCase().trim() === 'EXONERATE') {
      if (moment(e.value, 'YYYY-MM-DD').isAfter()) {
        this.busySaving = true;
        this.saveButtonColor = this.busySaving ? '' : 'primary';
        return false;
      }
    }
    this.busySaving = false;
    this.saveButtonColor = this.busySaving ? '' : 'primary';
    return true;
  }

  saveAndClose() {
    if (!this.boundField) {
      this.notification.toast('Date is required, please try again.', 'error');
    } else {
      try {
        this.busySaving = true;
        this.saveButtonColor = '';
        // console.log(transaction);
        let dateToSave: any;
        const actionDisplayError = (this.dialogData.action.toUpperCase().trim() === 'FORFEIT' ? 'Forfeiture' : (
          this.dialogData.action.toUpperCase().trim() === 'EXONERATE' ? 'Exoneration' : ''
        ));

        if (this.bondService.fixStatusDates(this.boundField, this.transaction.PostedDate)) {
          this.notification.toast(`${actionDisplayError} date ${moment.utc(this.boundField).format('MM/DD/YYYY')}
                                       is before posted date ${moment.utc(this.transaction.PostedDate).format('MM/DD/YYYY')}.
                                       You should correct this and resave the file.`, 'error', undefined, 6000, 'snack-def-preview');
          this.busySaving = false;
          this.saveButtonColor = 'primary';
          return false;
        } else {
          dateToSave = moment(this.boundField).format('YYYY-MM-DD');
        }

        this.selectedBond.currentState = '.valid.dirty';
        switch (this.dialogData.action.toUpperCase().trim()) {
          case 'FORFEIT':
            this.selectedBond.ForfeitureDate = dateToSave;
            break;
          case 'SETASIDE':
          case 'SET ASIDE':
            this.selectedBond.ForfeitureSetAsideDate = dateToSave;
            break;
          default:
            this.selectedBond.DischargeOccurrenceDate = dateToSave;
            break;
        }
        this.updateBond();
      } catch (e) {
        console.error('Unexpected error', e);
        this.busySaving = false;
        this.saveButtonColor = 'primary';
      }
    }
  }

  updateBond() {
    this.subs.sink = this.bondService.update(this.dialogData.bondId, this.selectedBond).pipe(take(1))
      .subscribe(saveBonds => {
        this.busySaving = false;
        this.saveButtonColor = 'primary';
        this.dialogRef.close();
        this.notification.toast('Bond status saved successfully!', 'success');
      },
        error => {
          this.handleError(error);
          // console.log('error saving Bonds', error);
          this.busySaving = false;
          this.saveButtonColor = 'primary';
        });
  }

  handleError(error) {
    this.errorData = error;
    this.errorVisible = true;
  }

  showSatisfyByDatePopup(e) {
    if (this.dialogData.action.toUpperCase().trim() === 'FORFEIT') {
      const dateVal = moment(e.value);
      if (dateVal.isValid()) {
        this.calculateSatisfyByDatePopupVisible = true;
      }
    }
  }

  closeSatisfyByDatePopup() {
    this.calculateSatisfyByDatePopupVisible = false;
  }

}
