import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StateService {

  states = {
    'al': 'AL',
    'ak': 'AK',
    'as': 'AS',
    'az': 'AZ',
    'ar': 'AR',
    'ca': 'CA',
    'co': 'CO',
    'ct': 'CT',
    'de': 'DE',
    'dc': 'DC',
    'fm': 'FM',
    'fl': 'FL',
    'ga': 'GA',
    'gu': 'GU',
    'hi': 'HI',
    'id': 'ID',
    'il': 'IL',
    'in': 'IN',
    'ia': 'IA',
    'ks': 'KS',
    'ky': 'KY',
    'la': 'LA',
    'me': 'ME',
    'mh': 'MH',
    'md': 'MD',
    'ma': 'MA',
    'mi': 'MI',
    'mn': 'MN',
    'ms': 'MS',
    'mo': 'MO',
    'mt': 'MT',
    'ne': 'NE',
    'nv': 'NV',
    'nh': 'NH',
    'nj': 'NJ',
    'nm': 'NM',
    'ny': 'NY',
    'nc': 'NC',
    'nd': 'ND',
    'mp': 'MP',
    'oh': 'OH',
    'ok': 'OK',
    'or': 'OR',
    'pw': 'PW',
    'pa': 'PA',
    'pr': 'PR',
    'ri': 'RI',
    'sc': 'SC',
    'sd': 'SD',
    'tn': 'TN',
    'tx': 'TX',
    'ut': 'UT',
    'vt': 'VT',
    'vi': 'VI',
    'va': 'VA',
    'wa': 'WA',
    'wv': 'WV',
    'wi': 'WI',
    'wy': 'WY',
    'alabama': 'AL',
    'alaska': 'AK',
    'american samoa': 'AS',
    'arizona': 'AZ',
    'arkansas': 'AR',
    'california': 'CA',
    'colorado': 'CO',
    'connecticut': 'CT',
    'delaware': 'DE',
    'district of columbia': 'DC',
    'federated states of micronesia': 'FM',
    'florida': 'FL',
    'georgia': 'GA',
    'guam': 'GU',
    'hawaii': 'HI',
    'idaho': 'ID',
    'illinois': 'IL',
    'indiana': 'IN',
    'iowa': 'IA',
    'kansas': 'KS',
    'kentucky': 'KY',
    'louisiana': 'LA',
    'maine': 'ME',
    'marshall islands': 'MH',
    'maryland': 'MD',
    'massachusetts': 'MA',
    'michigan': 'MI',
    'minnesota': 'MN',
    'mississippi': 'MS',
    'missouri': 'MO',
    'montana': 'MT',
    'nebraska': 'NE',
    'nevada': 'NV',
    'new hampshire': 'NH',
    'new jersey': 'NJ',
    'new mexico': 'NM',
    'new york': 'NY',
    'north carolina': 'NC',
    'north dakota': 'ND',
    'northern mariana islands': 'MP',
    'ohio': 'OH',
    'oklahoma': 'OK',
    'oregon': 'OR',
    'palau': 'PW',
    'pennsylvania': 'PA',
    'puerto rico': 'PR',
    'rhode island': 'RI',
    'south carolina': 'SC',
    'south dakota': 'SD',
    'tennessee': 'TN',
    'texas': 'TX',
    'utah': 'UT',
    'vermont': 'VT',
    'virgin islands': 'VI',
    'virginia': 'VA',
    'washington': 'WA',
    'west virginia': 'WV',
    'wisconsin': 'WI',
    'wyoming': 'WY',
  };

  constructor() { }

  validateState(value) {
    if (this.states.hasOwnProperty(value.toLowerCase())) {
      return this.states[value.toLowerCase()];
    } else {
      return '';
    }
  }
}
