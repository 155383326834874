import { CourtDateReason } from './courtDateReason';
import { Court } from './court';
import {BaseModel} from './BaseModel';

export class CourtDate extends BaseModel {
  Id: any;
  BondId: any;
  CourtId: any;
  CourtRoom: any;
  Date: any;
  Time: any;
  ClientNotified: any;
  NotifyDate: any;
  HowNotified: any;
  NotifiedByUserId: any; //TODO need full username, the field is readonly , pointless loading lookuplist for it
  Comment: any;
  LastAdiCallDate: any;
  LastAdiCallResult: any;
  ReasonId: any;
  CustomerId: any;
  DefendantId: any;
  Court: Court;
  CourtDateReason: CourtDateReason;
  data: any;
  constructor(obj?: object)
  {
    super(obj);
    // super(obj);

    if (obj == null) {
      this.ClientNotified = false;
    }

    
  }

  public loadData(data) {
    this.fillFromObject(data, true);
  }
}
