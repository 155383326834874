import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../services/event.service';
import { VoidUnvoidService } from '../../services/voidunvoid.service';
import {UnsubscribeOnDestroyAdapter} from '../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-guided-setup',
  templateUrl: './guided-setup.component.html',
  styleUrls: ['./guided-setup.component.css'],
})
export class GuidedSetupComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() titleLine: any;
  @Input() pageLink: any;
  @Input() pageUrl: any;
  @Input() backUrl: any;
  @Input() done = false;

  @Input() setupVisible: any;
  @Output() closepopup = new EventEmitter<boolean>();
  @Output() openSetup = new EventEmitter<boolean>();

  popupWindow: any;
  isToastVisible = false;
  titleSubLine: any;

  constructor(
    private eventService: EventService,
    private voidUnvoidService: VoidUnvoidService,
    private router: Router,
  ) {
    super();
    this.popupWindow = window;

    this.subs.sink = this.eventService.currentcomponentLoad$.subscribe(item =>
      this.crrCompLoad(item)
    );

    this.subs.sink = this.eventService.guidedSetupView$.subscribe(res => this.openPopup(res));

    this.subs.sink = this.voidUnvoidService.currentcomponentPopup$.subscribe(item =>
      this.crrCompLoad(item)
    );
  }

  ngOnInit() {}

  openPopup(res: any) {
    this.isToastVisible = res;
  }

  closePopup(e) {
    this.closepopup.emit(true);
  }

  homeAndClose() {
    this.isToastVisible = false;
    this.closepopup.emit(true);
    this.router.navigateByUrl(this.pageUrl);
  }
  closeToasted() {
    this.isToastVisible = false;
  }

  crrCompLoad(item: any) {
    this.titleLine = item.title.split('#br#')[0];
    this.titleSubLine = item.title.split('#br#')[1];
    this.pageLink = item.pageLink;
    this.pageUrl = item.pageUrl;

    if (item.done !== undefined) {
      this.done = true;
    } else {
      this.done = false;
    }
  }
}
