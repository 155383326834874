<dx-scroll-view
      #scrlEdit
      id="scrlEdit"
      [useNative]="false">
  <form>
    <div class="form-group">
      <label class="form-control-label">Which Bond?</label>
    </div>
    <dx-select-box
      [dataSource]="bonds"
      [(value)]="selectedBondViewId"
      displayExpr="Text"
      placeholder="Which Bond?"
      valueExpr="Id"
      (onSelectionChanged)="bondSelectionChanged($event)">
    </dx-select-box>

    <app-courtdate *ngIf="showCourt"
                   #courtDateList
                   [model]="selectedBond.courtdates"
                   [transactionId]="selectedBond.TransactionId"
                   setCourtId="txCourt"
                   setCourtRoomId="txCourtRoom"
                   [addNew]="true"
    ></app-courtdate>
    <div class="form-group" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button
              color="primary"
              type="submit"
              (click)="saveAndClose()"
              *ngIf="!!selectedBond"
              style="display: block;"
              [disabled]="busySaving">
          Save and Close
        </button>
    </div>
  </form>
</dx-scroll-view>


<app-errorpopup
  [height]="'300'"
  [width]="'400'"
  [resizeEnabled]="true"
  [closeOnOutsideClick]="false"
  [title]="'Error occurred when saving'"
  [shading]="false"
  [data]="errorData"
  [visible]="errorVisible"
  [routeToGoToAfterDisplay]="''">
</app-errorpopup>
