import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { SearchService } from '../../../services/search.service';
import { DefendantService } from '../../../services/defendant.service';
import { SearchresultsComponent } from '../searchresults/searchresults.component';
import { DxAutocompleteComponent } from 'devextreme-angular';
import { ObjectUtilities } from '../../../shared/ObjectUtilities';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.css'],
})
export class SearchboxComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild('SearchBoxResults', { static: true }) searchResultsComponent: SearchresultsComponent;
  @ViewChild(DxAutocompleteComponent, { static: true }) searchInput: DxAutocompleteComponent;

  @Input() placeHolderText = '';
  @Input() selectedDefendant = '';
  @Input() selectMode = false;
  @Input() showLoadIndicator = true;
  @Input() showClearButton = false;
  @Output() onRecordSelected = new EventEmitter<any>();
  @Input() searchResultStyle = 'position: absolute; z-index: 10000; top: 56px;';
  searchResults: any[] = [];
  searchData: DataSource;
  searchKeyword: any;
  searchTimerHandle: any;
  search = [];
  isLoading = false;
  blockSearch = false;
  @Input() defendantsOnly = false;
  @Input() includeDefPowers = false;
  @Input() width = '350px';
  @Input() disabled = false;

  constructor(
    private defendantService: DefendantService,
    private searchService: SearchService,
  ) {
    super();
  }

  // tslint:disable-next-line: no-empty
  ngOnInit() { }

  onSearchBoxFocusIn(e: any) {
     // console.log('search box on focus in');
    this.searchTimerHandle = setTimeout(() => {
      this.fillSearchResultsFromRecentDefendants();
    });
  }

  onSearchBoxFocusOut(e: any) {
    if (this.searchKeyword !== 'recent') {
      if ((!this.searchKeyword || this.searchKeyword === '') &&
        (!this.searchResults || this.searchResults.length === 0)) {
        this.hideSearch();
      }
    }
  }

  onQueryChange(e: any) {
    if (isNullUndefinedOrEmpty(e.value)) {
      this.onRecordSelected.emit({DefendantId: null});
    }
    if (!this.blockSearch) {
      clearTimeout(this.searchTimerHandle);
      this.searchTimerHandle = setTimeout(() => {
        this.loadSearchResultData(e.value);
        this.searchKeyword = e.value;
        // console.log('search box onselectionchanged', this.searchKeyword);
      }, 1500);
    }
  }

  onSearchBoxSelectionChanged(e: any) {
    if (!this.blockSearch) {
      clearTimeout(this.searchTimerHandle);
      this.searchTimerHandle = setTimeout(() => {
        this.loadSearchResultData(e.selectedItem);
        this.searchKeyword = e.selectedItem;
        // console.log('search box onselectionchanged', this.searchKeyword);
      }, 1500);
    }
  }


  fillSearchResultsFromRecentDefendants() {
    this.searchResults = [];
    const rd = this.defendantService.getRecentDefendants(5);
    if (rd && rd.length > 0) {
      rd.forEach((recentDefendant: any) => {
        const newRD = {
          object: 'Search',
          Id: recentDefendant.Id,
          DefendantId: recentDefendant.Id,
          DefendantName: recentDefendant.Name,
          FirstLast: recentDefendant.Name,
          Dob: recentDefendant.DOB,
          Source: 'RECENT DEFENDANTS',
          GroupSource: '0_RECENT DEFENDANTS',
          ProfilePicture:
          !recentDefendant.ProfilePicture
            ? '/assets/img/defaultuser-flat.teal.svg'
            : recentDefendant.ProfilePicture,
          ShowContextMenu: false,
          ButtonId: 'buttonRECENT DEFENDANTS' + recentDefendant.Id,
        };
        this.searchResults.push(newRD);

      });
      this.searchKeyword = 'recent';
      this.searchResultsComponent.setCounts();
    }
  }

  loadSearchResultData(query: string) {
    this.isLoading = true;
    this.searchResults = []; // Refresh
    if (query && query !== '') {
      this.subs.sink = this.searchService.getAllSearchData(query, this.defendantsOnly, false, true).pipe(take(1))
        .subscribe(data => {
          this.search = data;
          if (this.search && this.search.length > 0) {
            this.search.forEach((result: any) => {
              result.Source = result.Source.toUpperCase();
              result.GroupSource = ((result.Source === 'DEFENDANT') ? '0_' : '1_') + result.Source;
              result.ShowContextMenu = false;
              if (!result.ProfilePicture) {
                result.ProfilePicture = '/assets/img/defaultuser-flat.teal.svg';
              }
              result.ButtonId = 'button' + result.Source + result.Id;
              if (this.defendantsOnly) {
                if (result.Source === 'DEFENDANT') {
                  this.searchResults.push(result);
                }
              } else if (this.includeDefPowers) {
                if (result.Source === 'DEFENDANT' || (result.Source === 'POWER' && !!result.DefendantId)) {
                  this.searchResults.push(result);
                }
              } else {
                this.searchResults.push(result);
              }
            });
          }
          this.searchResultsComponent.setCounts();
          this.searchResultsComponent.fillSearchDataSource(this.searchResults);
          this.isLoading = false;
          // console.log('search box search results', this.searchResults);

        }, error => {
          // console.log('search error', error);
          this.isLoading = false;
        });
    }
  }

  hideSearch() {
    this.searchKeyword = '';
    this.searchResults = [];
  }

  onItemSelected(e: any) {
    // console.log('onitemselected', e);
    this.onRecordSelected.emit(e);
    this.blockSearch = true;
    this.searchInput.instance.option('value', e.FirstLast);
    this.blockSearch = false;
  }

  resetResult() {
    this.blockSearch = true;
    this.searchInput.instance.option('value', '');
    this.blockSearch = false;
   // console.log('reset search');
  }
}
