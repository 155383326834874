import { Injectable } from '@angular/core';
import DevExpress from 'devextreme';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {

  /**
   * Download file using a pagenated export route.
   * @param resp
   * @param name
   * @throws Error when there is no data returned from the grid
   */
  public static downloadFile(resp, name) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.cssText = 'display: none';
    const blob = new Blob([resp], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  constructor() { }

  public buildParams(loadOptions:  DevExpress.data.LoadOptions): HttpParams {
    let params: HttpParams = new HttpParams();

    // Add Page number
    const pageNum = (loadOptions.skip / loadOptions.take) + 1;
    params = params.set('page', JSON.stringify(pageNum));

    // Add sorting
    if (!!loadOptions.sort) {
      loadOptions.sort.map((value: {desc: boolean, selector: String}, index: number) => {
        const desc = value.desc ? 'desc' : 'asc';
        params = params.set('orderBy', String(value.selector));
        params = params.set('sortedBy', desc);
      });
    }
    // Add customFilter
    if (!!loadOptions.filter) {
      params = params.set('customFilter', JSON.stringify(loadOptions.filter));
    }

    // Load option
    const limit = loadOptions.take || environment.defaultPageNum;
    params = params.set('limit', JSON.stringify(limit));

    return params;
  }

}
