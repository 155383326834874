import { BondService } from './../../../services/bondservice.service';
import { take } from 'rxjs/operators';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Defendant } from '../../../models/defendant';
import { Bond } from '../../../models/bond';
import { CourtDateComponent } from '../../../views/common/courtdate/courtdate.component';
import { DefendantService } from '../../../services/defendant.service';
import notify from 'devextreme/ui/notify';
import { Bail } from '../../../models/bail';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import * as moment from 'moment';


@Component({
  selector: 'app-court-date-scheduler',
  templateUrl: './court-date-scheduler.component.html',
  styleUrls: ['./court-date-scheduler.component.scss'],
})
export class CourtDateSchedulerComponent extends UnsubscribeOnDestroyAdapter implements AfterViewInit {

  @Input() defendant: Defendant;

  @Output() close = new EventEmitter<any>();

  courtDateAddedForBond: Array<Bond> = [];
  selectedBondViewId: any;
  showCourt = false;

  @ViewChild('courtDateList') courtDateList: CourtDateComponent;

  bonds: Array<BondView>;
  selectedBond: Bond = new Bond(); // only initialized to satisfy bindings for court dates control
  errorData: any;
  errorVisible = false;
  busySaving = false;

  constructor(
    private bondService: BondService,
    private defendantService: DefendantService
    ) {
    super();
  }

  // has to be done after view init so that ViewChild can be initialized
  ngAfterViewInit(): void {
    // need to flatten defendant transaction bonds for use in ngFor in template..
    this.bonds = [];
    this.defendant.transactions.forEach(trans => {
      trans.bonds.forEach(bond => {
        if (!bond.DischargeOccurrenceDate) {
          const text = bond.power && bond.power.PowerNumber ? bond.power.PowerNumber :
            `(no power) - ${bond.Amount || 0} - ${moment(trans.PostedDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') || ''}`;
          const bondView: BondView = {Id: bond.Id, Bond: bond, TransactionPostedDate: trans.PostedDate, Text: text};
          this.bonds.push(bondView);
        }
      });
    });
    this.selectedBondViewId = this.bonds[0].Id;
    this.selectedBond = this.bonds[0].Bond;
    this.showCourt = true;
  }

  // The user has chosen which bond's court dates to edit.o
  // We need to find the right bond on the defendant model
  bondSelectionChanged(e) {
    if (!!e.selectedItem) {
      this.selectedBond = e.selectedItem.Bond;
      this.showCourt = true;
    } else {
      // console.log('CourtDateScheduler - no bond found matching BondId', e);
    }
  }

  saveAndClose() {
    // console.log('CourtDateScheduler - selectedBond', this.selectedBond);
    if (!this.selectedBond || !this.selectedBond.Id) {
      notify('Bond is required, please try again');
    } else {
      this.defendant.transactions.forEach((transaction: Bail) => {
        transaction.bonds.forEach((bond: Bond) => {
          if (bond.Id === this.selectedBond.Id) {
            bond.courtdates = Object.assign([], [...bond.courtdates], [...this.selectedBond.courtdates]);
          }
        });
      });
      this.busySaving = true;
      this.selectedBond.currentState = '.valid.dirty';
      this.subs.sink = this.bondService
        .updateTransactionCourtDates(this.defendant.transactions).pipe(
        take(1))
        .subscribe(
          response => {
            // this.defendant.LoadData(savedDefendant.data);
            this.busySaving = false;
            this.close.emit(true);
            // this.dialogRef.close('ok');
            notify('Court date saved successfully!');
          },
          error => {
            this.handleError(error);
            // console.log('error saving defendant', error);
            this.busySaving = false;
          });
    }
  }

  handleError(error) {
    this.errorData = error;
    this.errorVisible = true;
  }
}

interface BondView {
  Id: string;
  Text: any;
  Bond: Bond;
  TransactionPostedDate: any;
}


