<h4 mat-dialog-title>How to Create and Use Your Personal Zoom ID</h4>
<mat-dialog-content>
  <p>
    To create your Zoom Profile and ID click
    <a href="https://zoom.us/profile" target="_blank" rel="noopener noreferrer">here</a>.
  </p>
  <p>
      Once Your Zoom ID is created, type your personal ID in below and click 'Save' for Instant Meetings.
      After saving, simply hit the video button and wait for your defendant to accept the invite.
  </p>
  <p>
    This personal meeting ID can be used on all zoom platforms to begin your meetings.
  </p>
  <mat-form-field style="width: 100%;" floatLabel="never">
    <mat-label>Personal Meeting ID With Password</mat-label>
    <span matPrefix>https://zoom.us/j/</span>
    <input matInput placeholder="Zoom Personal Meeting ID"
      [(ngModel)]="data.zoomLink"
      (input)="sanitizeMeetingId($event.target.value)">
    <mat-hint>Provide <strong>only</strong> the Meeting ID no URL or dashes</mat-hint>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    Cancel
  </button>
  <span style="flex: auto 1 1;"></span>
  <button mat-raised-button
    [color]="data.zoomId ? 'primary' : ''"
    [disabled]="!data.zoomId"
    [mat-dialog-close]="data">
    Save
  </button>
</mat-dialog-actions>
