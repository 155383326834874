import {CalendarItemType} from '../models/calendaritemtype';

export class CalendarUtilities {


  public static getCalendarEntryTypes() {
    const calendarItemTypes: CalendarItemType[] = [];

    const tpHistoricCourtDate = new CalendarItemType();
    tpHistoricCourtDate.color = '#c0c0c0';
    tpHistoricCourtDate.type = 'Historic Court Date';
    calendarItemTypes.push(tpHistoricCourtDate);

    const tpCourtDate = new CalendarItemType();
    tpCourtDate.color = '#ffcc33';
    tpCourtDate.type = 'Current Court Date';
    calendarItemTypes.push(tpCourtDate);

    const tpFCheckin = new CalendarItemType();
    tpFCheckin.color = '#33ccff';
    tpFCheckin.type = 'Check In';
    calendarItemTypes.push(tpFCheckin);

    const tpCCheckin = new CalendarItemType();
    tpCCheckin.color = '#33ccaa';
    tpCCheckin.type = 'Successful Check In';
    calendarItemTypes.push(tpCCheckin);

    const tpMCheckin = new CalendarItemType();
    tpMCheckin.color = '#ff3300';
    tpMCheckin.type = 'Missed Check In';
    calendarItemTypes.push(tpMCheckin);

    const tpAdiNot = new CalendarItemType();
    tpAdiNot.color = '#c0c0c0';
    tpAdiNot.type = 'Successful ADI Notification';
    calendarItemTypes.push(tpAdiNot);

    const tpSmsNot = new CalendarItemType();
    tpSmsNot.color = '#c0c0c0';
    tpSmsNot.type = 'Successful SMS Notification';
    calendarItemTypes.push(tpSmsNot);

    const tpHistoricPayment = new CalendarItemType();
    tpHistoricPayment.color = '#c0c0c0';
    tpHistoricPayment.type = 'Historic Scheduled Payment';
    calendarItemTypes.push(tpHistoricPayment);

    const tpScheduledPayment = new CalendarItemType();
    tpScheduledPayment.color = '#33cc33';
    tpScheduledPayment.type = 'Scheduled Payment';
    calendarItemTypes.push(tpScheduledPayment);

    return calendarItemTypes;
  }
}
