<dx-popup
  [ngClass]="{'popup-component': addPopupClass, 'forms-popup-component': formsPopupClass}"
  [showTitle]="popupShowTitle"
  [title]="popupComponentTitle"
  [dragEnabled]="dragEnabled"
  [closeOnOutsideClick]="popupAllowCloseOnOutsideClick"
  [(visible)]="popupComponentVisible"
  (onHidden)="hidden($event)"
  (onHiding)="hiding($event)"
  [showCloseButton]="showCloseButton"
  [deferRendering] = "true"
  [height]="maxHeight"
  [position]='position'
  [width]="maxWidth"
  [minWidth]="'200px'">

  <div *dxTemplate="let data of 'content'" class="dynamic-popup-container">
   <dx-scroll-view [height]="scrollHeight" [width]="'100%'" showScrollbar="always" id="dxScrollView">
      <ng-template #dynamicInsert>&nbsp;</ng-template>
   </dx-scroll-view>
  </div>
</dx-popup>
