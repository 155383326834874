import {Injectable} from '@angular/core';
import { Subject } from 'rxjs';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
@Injectable()
export class VoidUnvoidService {
  private componentPopup = new Subject<string>();

  currentcomponentPopup$ = this.componentPopup.asObservable();

  compPopLoad(currentcomponentPopup: any){
    this.componentPopup.next(currentcomponentPopup);
  }

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

}
