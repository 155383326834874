import { Pipe, PipeTransform } from '@angular/core';
import { Identifier } from '../models/alert-result';

@Pipe({
  name: 'watchIdentifier',
})
export class WatchIdentifierPipe implements PipeTransform {

  transform(identifier: Identifier): { type: string, value: string } {
    let value = '';
    if (identifier.type === 'DOB' || identifier.type === 'YOB') {
      value = identifier.value || 'N/A';
    } else if (identifier.type === 'NAME' && identifier.value) {
      const name = identifier.value;
      value = `${name.first || ''} ${name.middle || ''} ${name.last || ''}`.trim();
    } else {
      value = identifier.value || 'N/A';
    }
    return { type: identifier.type, value };
  }

}
