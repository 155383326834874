import { animate, group, query, state, style, transition, trigger } from '@angular/animations';

export const bannerMessageFade =
  trigger('registrationBannerTrigger', [
    transition(':enter', [
      style({ transform: 'translateY(-100%)' }),
      animate('250ms', style({ transform: 'translateY(0%)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0%)' }),
      animate('150ms', style({ transform: 'translateY(-100%)' })),
    ]),
  ]);

export const fade =
  trigger('routeAnimations', [
    transition('* <=> *', [
      style({opacity: 0}),
      animate(350, style({opacity: 1})),
    ]),
  ]);

export const fadeInFromBottomOutFromTop =
  trigger('fadeInFromBottomOutFromTop', [
    state('in', style({opacity: 1, transform: 'translateY(0)'})),
    transition(':enter', [
      style({opacity: 0}),
      animate('500ms ease-in'),
    ]),
    transition(':leave', [
      group([
        animate('4000ms ease-out', style({opacity: 0})),
        query('.welcome-animation', [
          animate('1000ms ease-out', style({opacity: 0, transform: 'translateY(-50%)'}))
        ]),
      ])
    ]),
  ]);

export const slideIn =
  trigger('slideIn', [
    transition(':enter', [
      style({
        height: '0px',
        overflow: 'hidden',
      }),
      animate(
        '100ms ease-in-out',
        style({
          height: '*',
          overflow: 'hidden',
        }),
      ),
    ]),
    transition(':leave', [
      style({
        height: '*',
        opacity: 1,
        overflow: 'hidden',
      }),
      group([
        animate(100,
          style({
            height: 0,
          })),
        animate('100ms ease-in-out',
          style({
            opacity: '0',
            overflow: 'hidden',
          })),
      ]),
    ]),
  ]);

export const fader = trigger('fader', [
  state('in', style({opacity: 1})),
  transition(':enter', [
    style({opacity: 0}),
    animate(600 ),
  ]),
  transition(':leave',
    animate(600, style({opacity: 0}))),
]);

export const fadeIn = trigger('fadeIn', [
  state('in', style({opacity: 1})),
  transition(':enter', [
    style({opacity: 0}),
    animate(600 ),
  ]),
]);

export const grow =   trigger('grow', [
  transition('void <=> *', []),
  transition('* <=> *', [
    style({height: '{{startHeight}}px', opacity: 0}),
    animate('.5s ease'),
  ], {params: {startHeight: 0}}),
]);

export const growFromTopLeft = trigger('growFromTopLeft', [
  state('in', style({opacity: 0, width: '0%', height: '0%'})),
  transition(':enter', [
    animate('1s ease-in', style({opacity: 1, width: '100%', height: '100%'})),
  ]),
  transition(':leave',
    animate('1s ease-out', style({opacity: 0,  width: '0%', height: '0%'}))),
]);
