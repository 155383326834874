<mat-toolbar class="mat-elevation-z1" fxLayout="row" fxLayoutAlign="space-between center">
  <span fxLayoutAlign="start center" fxFlex="grow" *ngIf="doneLoading">
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="toggleSidenav()">
      <i class="far fa-bars" aria-label="Side nav toggle icon"></i>
    </button>

    <a [class]="(logoPath == 'assets/img/logo.png') ? 'app-brand' : 'app-whitelabel'"
       [routerLink]="['/dashboard']"
       fxHide.xs>
      <span class="text-uppercase" fxHide.sm>
        {{ (logoPath == 'assets/img/logo.png') ? 'Captira' : 'Palmetto' }}
      </span>
    </a>

    <span class='search-outer'>
      <div class="search-container">
          <dx-autocomplete
            *ngIf="hasBailManagement || isCollectionSubscribed"
            class="searchbar"
            [placeholder]="!searchMode && mobileQuery && mobileQuery.matches ? '' : 'Search by Name'"
            stylingMode="filled"
            (onFocusIn)="searchMode = true; advancedSearchVisible = false; fillSearchFromRecentDefendants()"
            (onFocusOut)="searchDefocused()"
            showClearButton="false"
            (onSelectionChanged)="searchChanged($event)"
            (onValueChanged)="queryChanged($event)"
            (onEnterKey)="handleEnter($event)"
          >
          </dx-autocomplete>
          <button mat-icon-button (click)="handleAdvancedSearchClick(advancedSearchVisible)" class='advanced-search' *ngIf='!searchMode && mobileQuery && mobileQuery.matches ? false : true && hasBailManagement'>
            <i class="fal fa-search-plus" style='font-weight: 400;'></i>
          </button>
          <dx-load-indicator class='defendant-search-loading'
              [visible]="searchingState">
          </dx-load-indicator>
      </div>
      <app-advanced-search
        style="top: 5rem; position: absolute; z-index: 10000;"
        (searchResults)="handleAdvancedSearchResults($event)"
        (onLoading)='handleAdvancedSearchLoading($event)'
        (closeSearch)="closeAdvancedSearch($event)"
        *ngIf='advancedSearchVisible'
      ></app-advanced-search>

      <div>
        <app-searchresults
          style="top: 5rem; position: absolute; z-index: 10000;"
          #TopNavBarSearchResults
          id="TopNavBarSearchResults"
          [searchKeyword]="searchKeyword"
          [searchResults]="searchResults"
          [(extendedSearch)]='extendedSearch'
          (extendedSearchChange)='handleExtendedSearch($event)'
          (openAdvancedSearch)='handleAdvancedSearchClick(false)'
          (onTakePayment)="onTakePaymentAction($event)"
          (onCheckIn)="onCheckInAction($event)">
        </app-searchresults>
      </div>

    </span>
  </span>

  <span fxLayoutAlign="end center" *ngIf="doneLoading" [hidden]="searchMode && mobileQuery && mobileQuery.matches">

    <span *ngFor="let button of shortcutOptions" class="shortcutOptions">
      <button
        mat-button
        [color]="button.optionColor"
        (click)="clickedOption(button.optionClicked)"
        [hidden]="!subscribedProducts[button.conditions]"
        fxHide.lt-md>
        <i class="fas {{ button.optionIcon }} fa-fw fa-lg"></i>
        {{ button.optionText }}
      </button>

      <button
        mat-icon-button
        [color]="button.optionColor"
        (click)="clickedOption(button.optionClicked)"
        [hidden]="!subscribedProducts[button.conditions]"
        matTooltip="{{ button.optionText }}"
        matTooltipPosition="below" fxHide.gt-sm>
        <i class="fas {{ button.optionIcon }} fa-fw fa-lg"></i>
      </button>
    </span>

    <app-textreplies *ngIf="subscribedProducts.BAILMANAGEMENT || subscribedProducts.COLLECTIONS || subscribedProducts.DEFENDANTMANAGER" (messagesLoaded)="onContentLoaded($event)"></app-textreplies>

    <button
      mat-icon-button
      *ngIf="showDebugButton"
      (click)="debugHandler()"
      matTooltip="Copy Debug URL"
      matTooltipPosition="below">
      <i class="fas fa-bug fa-lg"></i>
    </button>

    <app-user-profile></app-user-profile>
  </span>
</mat-toolbar>

<app-dynamic-popup
  [popupComponent]="dynamicPopupClass"
  [popupComponentData]="dynamicPopupData"
  [popupComponentTitle]="dynamicPopupTitle"
  [popupComponentVisible]="dynamicPopupVisible"
  (onPopupHidden)="dynamicPopupHidden()"
  [showCloseButton]="true"
  [popupShowTitle]="dynamicPopupShowTitle">
</app-dynamic-popup>
