import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-mobile-link',
  templateUrl: './mobile-link.component.html',
  styleUrls: ['./mobile-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileLinkComponent implements OnInit {

  @Input() type: 'mobile' | 'email';
  @Input() value: string;
  isMobile: boolean;

  link: string;
  constructor(
    breakpointObserver: BreakpointObserver,
  ) {
    this.isMobile = breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  ngOnInit(): void {
    if (this.type === 'mobile') {
      this.link = `tel:${this.value}`;
    } else {
      this.link = `mailto:${this.value}`;
    }
  }

}
