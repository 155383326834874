import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneListComponent } from './phone-list.component';
import {
  DxBoxModule,
  DxButtonModule, DxCheckBoxModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [PhoneListComponent],
  imports: [
    CommonModule,
    DxBoxModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxButtonModule,
    FlexLayoutModule,
    DxScrollViewModule,
    MatButtonModule,
    DxValidatorModule,
    DxCheckBoxModule,
  ],
  exports: [PhoneListComponent],
})
export class PhoneListModule { }
