import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 * Pipe to append The 'Z' (Zulu char) to some valid date string and to format date
 *
 * @export
 * @class DateToZuluPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'dateToZulu',
})
export class DateToZuluPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) { /* ... */ }
  /**
   * @param  {any} dateValue
   * @param  {string='mediumDate'} format
   */
  transform(dateValue: any, format: string = 'mediumDate'): any {
    let auxDate = null;
    try {
      dateValue += 'Z';
      auxDate = this.datePipe.transform(dateValue, format);
      return auxDate;
    } catch (e) {
      console.error('DateToZuluPipe ', e);
      return dateValue;
    }
  }
}
