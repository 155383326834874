import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ZoomConfigComponent } from '../defendant-manager/zoom-config/zoom-config.component';
import { Link } from '../../models/link';
import { Defendant } from '../../models/defendant';
import { Person } from '../../models/person';
import { Phone } from '../../models/phones';
import { LinkService } from '../../services/link.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReminderService } from '../../services/reminder.service';
import { UserService } from '../../services/user.service';
import { EventService } from '../../services/event.service';
import {UnsubscribeOnDestroyAdapter} from '../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-defendant-zoom-config',
  templateUrl: './defendant-zoom-config.component.html',
  styleUrls: ['./defendant-zoom-config.component.scss'],
  entryComponents: [ZoomConfigComponent],
})
export class DefendantZoomConfigComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges {

  @Input() defendant: any;
  @Input() source: string = 'bail';

  zoomLink: Link;
  zoomId: string;
  zoomPassword: string;
  sendingSms: boolean;
  user: any;

  constructor(
    public snackBar: MatSnackBar,
    private reminderService: ReminderService,
    private userService: UserService,
    public dialog: MatDialog,
    private linkService: LinkService,
    private eventService: EventService,
  ) {
    super();
    this.checkListsForZoom(localStorage.getItem('userId'));
    this.subs.sink = this.userService.current().subscribe((res) => {
      // console.log('res', res.data.identity.data);
      this.user = res.data.identity.data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.defendant) {
      if (this.source === 'defendantmanager') {
        this.defendant = new Defendant(this.defendant.data);
        this.defendant.person = new Person(this.defendant.person.data);
        if (!!changes.defendant.currentValue.data && !!changes.defendant.currentValue.data.person) {
          const phones = changes.defendant.currentValue.data.person.data.phones.data;
          if (phones.length > 0) {
            this.defendant.person.phones = [];
            phones.forEach((phone: any) => {
              this.defendant.person.phones.push(new Phone(phone));
            });
          }
        }
      }
    }
  }

  // tslint:disable-next-line: no-empty
  ngOnInit(): void {
  }

  launchZoomDialog(): void {
    // console.log('launching zoom dialog', this.zoomId, this.zoomPassword);

    const dialogRef = this.dialog.open(ZoomConfigComponent, {
      width: '500px',
      // panelClass: 'dx-container-hack',
      data: {
        zoomId: this.zoomId,
        zoomPassword: this.zoomPassword || '',
        zoomLink: !!this.zoomId ? (!!this.zoomPassword ? `${this.zoomId}?pwd=${this.zoomPassword}` : this.zoomId) : '',
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result);
        if (!result.zoomPassword || (result.zoomPassword && result.zoomPassword.length === 0)) {
          this.eventService.showToaster('error', 'Please enter Zoom ID along with password like (ID?pwd=password)');
          return;
        }

        this.zoomLink.LinkUrl = result.zoomId;
        this.zoomLink.LinkPassword = result.zoomPassword;
        if (this.zoomId) {
          this.subs.sink = this.linkService.updateLink(this.zoomLink).subscribe(res => {
            // console.log(res);
          });
        } else {
          this.zoomLink.SourceType = 'Users';
          this.zoomLink.Name = 'ZoomCode';
          this.zoomLink.LinkUrl = result.zoomId;
          this.zoomLink.LinkPassword = result.zoomPassword;
          this.subs.sink = this.linkService.createLink(this.zoomLink).subscribe(res => {
            // console.log(res);
          });
        }
        this.zoomId = result.zoomId;
        this.zoomPassword = result.zoomPassword;
      } else {
        // there's a case where closing the dialog by not saving will return undefined, even if it has value in input
        // really, we should just call the user api or whatever if no result
        // getting the ID right from the dialog was just a quick and easy hack for prototyping in meantime
        // dealers choice from here.
        this.checkListsForZoom(localStorage.getItem('userId'));
      }
    });
  }

  checkListsForZoom(id: string) {
    this.subs.sink = this.linkService.getLinkByUser(id).subscribe((res) => {
      this.zoomLink = new Link();
      if (res.data) {
        const zoomLists = res.data.filter((link: Link) => link.Name === 'ZoomCode');
        if (zoomLists.length) {
          this.zoomLink.loadData(zoomLists[0]);
          this.zoomId = this.zoomLink.LinkUrl;
          this.zoomPassword = this.zoomLink.LinkPassword;
        }
      }
    });
  }

  sendSms(): void {
    if (!this.zoomPassword || (this.zoomPassword && this.zoomPassword.length === 0)) {
      this.eventService.showToaster('error', 'Please update Zoom ID along with password like (ID?pwd=password)');
      return;
    }

    this.sendingSms = true;
    this.snackBar.open(
      `Attempting to send a text message to ${this.defendant.person.First}...`,
      'Okay',
      {duration: 5000},
    );

    const sampleMessage =
      `${this.user.FirstName + ' ' + this.user.LastName}` +
      ` is inviting you to join a video/voice meeting.` +
      ` Click here: https://zoom.us/j/${this.zoomId}?pwd=${this.zoomPassword || ''}`;

    this.subs.sink = this.reminderService.sendSms(
      this.defendant.Id,
      'DirectMessage',
      sampleMessage,
      this.defendant.person.phones[0].Number,
      this.defendant.Id,
    ).subscribe(resp => {
      this.launchZoomSession();
      this.sendingSms = false;
    }, smsErr => {
      this.snackBar.open(
        'Something went wrong while sending your meeting request. Please try again.',
        '', {duration: 5000},
      );
      this.sendingSms = false;
    });
  }

  launchZoomSession(): void {
    if (!this.zoomPassword || (this.zoomPassword && this.zoomPassword.length === 0)) {
      this.eventService.showToaster('error', 'Please update Zoom ID along with password like (ID?pwd=password)');
      return;
    }

    const snackbarRef = this.snackBar.open(
      'The defendant has been notified via SMS text message to join the session. Launching your Zoom session now.',
      'Okay',
      {duration: 5000},
    );

    this.subs.sink = snackbarRef.afterDismissed().subscribe(() => {
      const popup = window.open(`https://zoom.us/j/${this.zoomId}?pwd=${this.zoomPassword || ''}`);

      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        this.subs.sink = this.snackBar.open(
          'Your defendant was successfully notified to join the meeting. ' +
          'However, your browser blocked the new window from opening. ' +
          'Please manually join the meeting or click join to begin.',
          'Join',
          {duration: 15000},
        ).onAction().subscribe(() => {
          window.open(`https://zoom.us/j/${this.zoomId}?pwd=${this.zoomPassword || ''}`);
        });
      }
    });
  }

  openZoom() {
    if (!this.zoomPassword || (this.zoomPassword && this.zoomPassword.length === 0)) {
      this.eventService.showToaster('error', 'Please update Zoom ID along with password like (ID?pwd=password)');
      return;
    }

    window.open(`https://zoom.us/j/${this.zoomId}?pwd=${this.zoomPassword || ''}`, '_blank');
  }

}
