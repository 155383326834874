import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()
export class PaymentOffersService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
  ) {
  }

  // Get payment offers of the customer
  getPaymentOffers(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/customer/paymentoffers`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  // Update payment offers for a customer
  updatePaymentOffers(data: any): Observable<any> {
    // console.log('In function updatePaymentOffers: Payment Offers service: update data: ', data);
    return this.http.post(`${this.settings.getBaseUrl()}/updatepaymentoffers`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(error)));
  }

  getPaymentOfferByDebtorId(debtorId): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/internaldebtors/${debtorId}/currentpaymentoffer`).pipe(
      map((res: any) => res.hasOwnProperty('data') ? res.data : undefined),
      catchError(error => throwError(error)));
  }

}
