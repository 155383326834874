<div
  fxLayout="row"
  fxLayoutAlign="space-around start"
  fxLayout.xs="column"
  fxLayoutAlign.xs="center center"
  class="form-horizontal"
  >
  <div class="text-center">
    <app-mugshot
      [dataUrl]="defendant.person.ProfilePicture"
      [defendantId]="defendant.Id"
      [gender]="defendant.person.Gender"
      (onChanged)="profilePicChanged($event)"
      (onDeleted)="onProfilePicDeleted()">
    </app-mugshot>
    <ng-content></ng-content>
    <app-defendant-zoom-config [defendant]="defendant" ></app-defendant-zoom-config>
    <div fxLayout='column' fxLayoutAlign="space-evenly center" >
      <button mat-button color="primary" (click)="addNote()">
        <span>Notes</span>
        <i mat-list-icon class="icon-spacer fas fa-sticky-note fa-lg fa-flip-vertical"></i>
      </button>
      <button mat-button color="alert" (click)="addDocuments()">
        <span>Documents</span>
        <i mat-list-icon class="icon-spacer fas fa-file-upload fa-lg"></i>
      </button>
    </div>
  </div>

  <div fxFlex="50%" fxFlex.xs="100%" fxFlexFill>
    <div class="form-group">
      <label class="col-sm-3 control-label">First Name</label>
      <div class="col-sm-6">
        <dx-text-box
          [(value)]="defendant.person.First"
          id="txFirstName"
          maxLength="80"
          [readOnly]="fieldsDisabled"
          [maxLength]="firstNameLength">
          <dx-validator name="Defendant First">
            <dxi-validation-rule type="required">
            </dxi-validation-rule></dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Middle Name</label>
      <div class="col-sm-6">
        <dx-text-box
          [(value)]="defendant.person.Middle"
          maxLength="80"
          id="txMiddleName"
          [readOnly]="fieldsDisabled"
          [maxLength]="middleNameLength">
        </dx-text-box>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Last Name</label>
      <div class="col-sm-6">
        <dx-text-box
          [(value)]="defendant.person.Last"
          maxLength="80"
          id="txLastName"
          [readOnly]="fieldsDisabled"
          [maxLength]="lastNameLength">
          <dx-validator name="Defendant First">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Alias Name</label>
      <div class="col-sm-6">
        <dx-text-box
          [(value)]="defendant.person.Alias"
          maxLength="120"
          id="txAliasName"
          [readOnly]="fieldsDisabled">
        </dx-text-box>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">DOB</label>
      <div class="col-sm-6">
        <dx-date-box
          [(value)]="defendant.person.Dob"
          (onValueChanged)="onDobChange($event)"
          type="date"
          invalidDateMessage="Please enter a valid date"
          id="txDOB"
          dateSerializationFormat="yyyy-MM-dd"
          [displayFormat]="dateFormatter"
          [readOnly]="fieldsDisabled"
          [openOnFieldClick]="openDobPickerOnFocus"
          [matTooltip]="defendant.person.Age + ' years old'"
          matTooltipPosition="above">
        </dx-date-box>
      </div>
    </div>

    <div [hidden]="!moreVisible">
      <div class="form-group">
        <label class="col-sm-3 control-label">
          SSN
        </label>
        <div class="col-sm-6">
          <app-ssn
            id="txSSN"
            [(Ssn)]="defendant.person.Ssn"
            [readOnlyField]="fieldsDisabled"
            [placeholderVisible]="false">
          </app-ssn>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Address</label>
        <div class="col-sm-6">
          <app-smartaddress
            id="txAddress"
            [(model)]="defendant.person.addresses"
            [readOnlyField]="fieldsDisabled"
            AddressCity="txCity"
            AddressState="txState"
            AddressZip="txZip"
            [placeholderVisible]="false">
          </app-smartaddress>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Rent or Own</label>
        <div class="col-sm-6">
          <dx-select-box
            appSelectFocus
            id="txResidenceType"
            [dataSource]="residenceTypes"
            displayExpr="Text"
            valueExpr="Id"
            [(value)]="defendant.person.ResidenceType"
            [readOnly]="fieldsDisabled">
          </dx-select-box>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Phone</label>
        <div class="col-sm-6">
          <app-phone-list
            [showDoNotContact]="true"
            id="txHomeTelephone"
            [(model)]="defendant.person.phones"
            [readOnlyField]="fieldsDisabled"
            (phoneUpdated)="onPhoneChanged($event)"
          ></app-phone-list>
        </div>
      </div>
      <div class='form-group google-review' *ngIf='defendant.person.phones.length > 0'>
        <label class='col-sm-3 control-label'>
          Send Google Review Request
        </label>
        <div class='col-sm-6' style='display: flex;align-items: baseline'>
          <mat-checkbox
            [(checked)]='defendant.person.IncludeInGoogleReview'
            (change)='handleChangeReview($event.checked)'
            color='primary'
            [disabled]='!!defendant.person.review?.Status'
          >
          </mat-checkbox>
          <div style='margin-left: 24px; margin-top: -4px'>15 &cent; per text</div>
          <a mat-icon-button color='primary' tabindex='-1' (click)='openGoogleReview()'>
            <i class='fal fa-cog' tabindex='-1'></i>
          </a>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Email</label>
        <div class="col-sm-6" fxLayout="row nowrap">
          <app-email-list
            id="txEmail"
            fxFill
            [(model)]="defendant.person.emails"
            [readOnlyField]="fieldsDisabled"
          ></app-email-list>
          <a [@fader] [href]="possibleEmailLink" target="_blank" *ngIf="showPossibleEmail" fxFlexAlign="center">
            <i style="color: rgb(0, 150, 136);cursor: pointer;" class="fal fa-search"></i>
          </a>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Gender</label>
        <div class="col-sm-6">
          <dx-select-box
            appSelectFocus
            id="txGender"
            [dataSource]="genders"
            displayExpr="Text"
            valueExpr="Id"
            [(value)]="defendant.person.Gender"
            [readOnly]="fieldsDisabled">
          </dx-select-box>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">DL #</label>
        <div class="col-sm-6">
          <dx-text-box
            [(value)]="defendant.person.Dl"
            maxLength="20"
            id="txDL"
            [readOnly]="fieldsDisabled">
          </dx-text-box>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">DL State</label>

        <div class="col-sm-6">
          <app-usstate-lu
            id="txState"
            [(stateCode)]="defendant.person.DlStateCode"
            [fieldsDisabled]="fieldsDisabled"
          ></app-usstate-lu>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Race</label>
        <div class="col-sm-6">
          <dx-select-box
            appSelectFocus
            id="txRace"
            [dataSource]="races"
            displayExpr="Text"
            valueExpr="Id"
            [(value)]="defendant.person.Race"
            [readOnly]="fieldsDisabled">
          </dx-select-box>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Height</label>
        <div class="col-sm-6" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <dx-select-box
            appSelectFocus
            [dataSource]="heightsFt"
            id="txHeight"
            displayExpr="Text"
            valueExpr="Id"
            placeholder="ft"
            [(value)]="defendant.person.HeightFt"
            [readOnly]="fieldsDisabled">
          </dx-select-box>
          <dx-select-box
            appSelectFocus
            [dataSource]="heightsIn"
            displayExpr="Text"
            placeholder="in"
            valueExpr="Id"
            [(value)]="defendant.person.HeightIn"
            [readOnly]="fieldsDisabled">
          </dx-select-box>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Weight</label>
        <div class="col-sm-6">
          <dx-text-box
            [(value)]="defendant.person.Weight"
            maxLength="10"
            id="txWeight"
            [readOnly]="fieldsDisabled">
          </dx-text-box>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Eye Color</label>
        <div class="col-sm-6">
          <dx-select-box
            appSelectFocus
            id="txEyeColor"
            [dataSource]="eyeColors"
            displayExpr="Text"
            valueExpr="Id"
            [(value)]="defendant.person.EyeColor"
            [readOnly]="fieldsDisabled">
          </dx-select-box>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">Hair Color</label>
        <div class="col-sm-6">
          <dx-select-box
            appSelectFocus
            id="txHairColor"
            [dataSource]="hairColors"
            displayExpr="Text"
            valueExpr="Id"
            [(value)]="defendant.person.HairColor"
            [readOnly]="fieldsDisabled">
          </dx-select-box>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 control-label">ID Marks</label>
        <div class="col-sm-6">
          <dx-text-box
            [(value)]="defendant.person.IdentifyingMarks">
          </dx-text-box>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button
          mat-button
          color="primary"
          (click)="showMore($event)">
          {{ showMoreText }}
        </button>
      </div>
    </div>
  </div>
</div>
