import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CustomersComponent } from './customers/customers.component';
import { AdminService } from './services/admin.service';
import { AdminRoutingModule } from './admin-routing.module';
import { SubscriptionComponent } from './subscription/subscription.component';
import {DynamicPopupModule} from '../../components/common/dynamic-popup/dynamic-popup.module';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { SharedModule } from '../../shared/shared.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxProgressBarModule } from 'devextreme-angular/ui/progress-bar';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { WhatsNewService } from './services/whats-new.service';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { FeaturesModule } from './features/features.module';
import { CustomerUsersComponent } from './customer-users/customer-users.component';




@NgModule({
  imports: [
    AdminRoutingModule,
    SharedModule,
    DynamicPopupModule,
    DxDataGridModule,
    DxButtonModule,
    DxPopupModule,
    DxProgressBarModule,
    DxFileUploaderModule,
    FeaturesModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    CustomersComponent,
    SubscriptionComponent,
    CustomerDetailsComponent,
    WhatsNewComponent,
    CustomerUsersComponent,
  ],
  providers: [AdminService, WhatsNewService],
})
export class AdminModule { }
