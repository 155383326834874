import {catchError, map} from 'rxjs/operators';
import {throwError, Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';
import { SalesReport } from '../models/sales-report';
import { PortoResponse } from '../models/porto-response';

@Injectable()

export class SalesreportService {
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(private http: HttpClient, private settings: SettingsService) {
  }


  getBondsForSalesReport(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/salesreports/getpowers`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getReportList(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/salesreports`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getBankersReports(): Observable<SalesReport[]> {
    return this.http.get<PortoResponse<SalesReport[]>>(`${this.settings.getBaseUrl()}/bankers/salesreports`).pipe(
      map((res: PortoResponse<SalesReport[]>) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getReport(id: any, includes: any = 'payments'): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/salesreports/${id}?include=${includes}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/salesreports/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getEarliestDateForReport(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/salesreports/earliestdate/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getFirstPowerForReport(id: any): Observable<any>{
    return this.http.get(`${this.settings.getBaseUrl()}/salesreports/firstpower/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }
  save( id: any, data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/salesreports/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/salesreports`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }
  getExport(id: any, type: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/salesreports/exports/${id}/${type}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  sendSalesToBankers(reportId: string): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/bankers/sales/${reportId}`, {}).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  sendExonToBankers(reportId: string): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/bankers/exoneration/${reportId}`, {}).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }
}
