import {Component, Input, OnInit, QueryList, ViewChild, ViewChildren, Type} from '@angular/core';
import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {formatNumber} from 'libphonenumber-js';
import {DxDataGridComponent} from 'devextreme-angular';
import {MobileVerificationService} from '../../services/mobile-verification.service';
import {EventService} from '../../services/event.service';
import notify from 'devextreme/ui/notify';
import {ObjectUtilities} from '../../shared/ObjectUtilities';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;
import {CheckInScheduleService} from '../../services/checkinschedule.service';
import {StringUtilities} from '../../shared/StringUtilities';
import {IDynamicPopupComponent} from '../../components/common/dynamic-popup/dynamic-popup.component';
import {CheckinComponent} from '../checkin/checkin.component';
import {AppConstants} from '../../shared/AppConstants';
import {CheckinscheduleComponent} from '../checkinschedule/checkinschedule.component';
import {BillingService} from '../../services/billing.service';
import {UnsubscribeOnDestroyAdapter} from '../../common/UnsubscribeOnDestroy';
import {GridService} from '../../services/grid.service';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';

@Component({
  selector: 'app-location-history',
  templateUrl: './location-history.component.html',
  styleUrls: ['./location-history.component.scss']
})
export class LocationHistoryComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild('locationHistoryGrid') locationHistoryGrid: DxDataGridComponent;
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChildren(MapMarker) markers: QueryList<MapMarker>;
  @ViewChildren(MapInfoWindow) infoWindows: QueryList<MapInfoWindow>;
  @ViewChild('checkinHistoryGrid') checkinHistoryGrid: DxDataGridComponent;

  @Input('currentDevice')
  set currentDevice(value) {
    this._currentDevice = value;
    this.doInit();
  }

  get currentDevice() {
    return this._currentDevice;
  }


  @Input('defendantId')
  set defendantId(value: boolean) {
    this._defendantId = value;
    this.doInit();
  }

  get defendantId() {
    return this._defendantId;
  }

  @Input() defendantModel: any;
  @Input() showCheckinHistory = false;

  _currentDevice;
  _defendantId;
  dateFormatter = StringUtilities.dateFormatter;
  dateFilters: any = [];
  location: any;
  historyRangeStartDateFilter: any;
  historyRangeEndDateFilter: any;
  showRadius = false;
  historyStartDateFilter: any;
  historyEndDateFilter: any;
  dateFilter = '';
  dateRangeFiltersVisible = false;
  openedMarkers: any = [];

  mvsHistoryData: any = [];
  transformedMvsHistoryData: any = [];
  mvsMarkers: any;
  filteredMvsLocationData: any;
  mvsHistoryReady = false;

  checkinHistoryData: any = [];
  transformedCheckinHistoryData: any = [];
  checkinMarkers: any;
  filteredCheckinLocationData: any = [];
  checkinHistoryReady = false;

  locationHistoryDataCombined: any = [];

  showCheckinPhotoColumn = false;
  subscribedToReminders = false;

  colIndex = {
    Location: 0,
    LocationTimestamp: 1,
    Accuracy: 2,
    PictureUrl: 3,
  };
  colHidingPriority = {
    Accuracy: 0,
    PictureUrl: 1,
    Location: 2,
    LocationTimestamp: 3,
  };

  checkinHistoryGridData: any = [];
  checkinSchedule: any;

  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData = [];
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  settingsUrl = '';

  constructor(private mobileVerificationService: MobileVerificationService,
              private eventService: EventService,
              private checkinService: CheckInScheduleService,
              private billingService: BillingService,
              private gridService: GridService) {
    super();

    this.dateFilters = [
      {text: 'This Week', value: 'This Week'},
      {text: 'This Month', value: 'This Month'},
      {text: 'Last Month', value: 'Last Month'},
      {text: 'Date Range', value: 'Date Range'},
    ];
    this.subs.sink = billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_TEXT_MESSAGING).subscribe(isSubbed => {
      this.subscribedToReminders = isSubbed;
      if (!this.subscribedToReminders) {
        this.subs.sink = billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM).subscribe(resp => {
          if (resp) {
            this.settingsUrl = '/adiv2/src/bail';
          } else {
            this.subs.sink = billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER).subscribe(resp => {
              if (resp) {
                this.settingsUrl = '/defendantmanager/reminder-setting/defmngr';
              }
            });
          }
        });
      }
    });
  }

  ngOnInit() {
    // this.doInit();
  }

  doInit() {

    this.eventService.componentBusy(true);
    this.locationHistoryDataCombined = [];
    this.mvsHistoryReady = false;
    this.checkinHistoryReady = false;


    // For MVS
    this.mvsHistoryData = [];
    this.mvsMarkers = [];


    if (this.currentDevice) {
      if (this.currentDevice.LocationHistory && this.currentDevice.LocationHistory.length > 0) {
        this.mvsHistoryData = this.currentDevice.LocationHistory;
        this.filteredMvsLocationData = this.mvsHistoryData;
        this.getMapMarkersMvs();
        setTimeout(() => {
          this.mvsHistoryReady = true;
        }, 500);

        // this.locationHistoryPopupVisible = true;
      } else {
        this.subs.sink = this.mobileVerificationService.locationHistory(this.currentDevice.Id).subscribe(res => {
          if (res.data) {
            this.mvsHistoryData = res.data.LocationHistory;
            this.filteredMvsLocationData = this.mvsHistoryData;
            this.getMapMarkersMvs();
            this.getTransformedCombinedDataForGrid('MVS');
            setTimeout(() => {
              this.mvsHistoryReady = true;
              this.eventService.componentBusy(false);
            }, 500);
          }
        }, error => {
          this.eventService.componentBusy(false);
          notify('An error occurred loading MVS history, please try again', 'error');
          console.error(error);
        });
      }
      this.colIndex = {
        Location: 1,
        LocationTimestamp: 2,
        Accuracy: 3,
        PictureUrl: 4,
      };
      this.colHidingPriority = {
        Accuracy: 0,
        PictureUrl: 1,
        Location: 2,
        LocationTimestamp: 3,
      };
    } else {
      this.mvsHistoryReady = true;
    }

    // For GPS Check-ins
    this.checkinHistoryData = [];
    this.checkinMarkers = [];


    if (this.defendantId) {
      this.showCheckinPhotoColumn = true;
      this.eventService.componentBusy(true);
      this.subs.sink = this.checkinService.getLocationCheckinHistory(this.defendantId).subscribe(resp => {
        // console.log('location checkin history', resp);
        this.checkinHistoryData = resp.data;
        this.filteredCheckinLocationData = this.checkinHistoryData;
        this.getMapMarkersCheckins();
        this.getTransformedCombinedDataForGrid('CHECKIN');
        setTimeout(() => {
          this.checkinHistoryReady = true;
          this.eventService.componentBusy(false);
        }, 500);

      }, error => {
        this.eventService.componentBusy(false);
        notify('An error occurred loading check in history, please try again', 'error');
        console.error(error);
      });

      this.colIndex = {
        LocationTimestamp: 1,
        PictureUrl: 2,
        Location: 3,
        Accuracy: 4,
      };
      this.colHidingPriority = {
        Accuracy: 0,
        Location: 1,
        PictureUrl: 2,
        LocationTimestamp: 3,
      };
    } else {
      this.checkinHistoryReady = true;
    }

    // For Checkin History Grid
    this.loadCheckinGridData();
  }

  loadCheckinGridData() {
    if (this.defendantId) {
      this.eventService.componentBusy(true);
      this.subs.sink = this.checkinService.getByDefendantId(this.defendantId).subscribe(resp => {
        if (resp.data && resp.data.details && resp.data.details.data.length > 0) {
          this.checkinSchedule = resp.data;
          this.checkinHistoryGridData = resp.data.details.data.filter(detail => moment(detail.ScheduledDate).isBefore(moment()));
          this.checkinHistoryGridData = this.checkinHistoryGridData.sort((a, b) => {
            if (moment(a.ScheduledDate).isAfter(moment(b.ScheduledDate))) {
              return -1;
            }
            if (moment(a.ScheduledDate).isBefore(moment(b.ScheduledDate))) {
              return 1;
            }
            return 0;
          });
          // console.log('checkin history grid data', this.checkinHistoryGridData);
        } else {
          this.checkinHistoryGridData = [];
        }
        this.eventService.componentBusy(false);
      }, error => {
        this.eventService.componentBusy(false);
        notify('An error occurred loading check-in history, please try again', 'error');
        console.error(error);
      });
    }
  }

  clearTransformedCombinedData() {
    this.locationHistoryDataCombined = [];
  }

  getTransformedCombinedDataForGrid(type: string) {

    switch (type.toUpperCase()) {
      case 'MVS':
        this.transformedMvsHistoryData = [];
        for (const obj of this.filteredMvsLocationData) {
          if (!isNullUndefinedOrEmpty(obj.LocationAddr)) {
            obj.Address = this.locationAddress(obj.LocationAddr);
            this.transformedMvsHistoryData.push({
              Address: obj.Address,
              LocationTimestamp: obj.LocationTimestamp,
              Accuracy: obj.Accuracy,
              Latitude: obj.LocationAddr.GEOCode.Latitude,
              Longitude: obj.LocationAddr.GEOCode.Longitude,
            });
          }
        }
        this.locationHistoryDataCombined = this.locationHistoryDataCombined.concat(this.transformedMvsHistoryData);
        // console.log('this.locationHistoryDataCombined', this.locationHistoryDataCombined);
        break;
      case 'CHECKIN':
        this.transformedCheckinHistoryData = [];
        for (const obj of this.filteredCheckinLocationData) {
          if (!isNullUndefinedOrEmpty(obj.Lat)) {
            this.transformedCheckinHistoryData.push({
              Address: `${parseFloat(obj.Lat)}, ${parseFloat(obj.Lng)}`,
              LocationTimestamp: obj.DateLinkOpened,
              Accuracy: obj.LocationAccuracy,
              Latitude: obj.Lat,
              Longitude: obj.Lng,
              PictureUrl: obj.PictureUrl,
            });
          }
        }
        this.locationHistoryDataCombined = this.locationHistoryDataCombined.concat(this.transformedCheckinHistoryData);
        // console.log('this.locationHistoryDataCombined', this.locationHistoryDataCombined);
        break;
    }
  }

  validFilter(filter) {
    return filter !== 'All' && filter !== '' && !isNullOrUndefined(filter);
  }

  onLocationToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: '',
          onClick: () => {
            this.exportLocationGrid();
          },
        },
      },
    );
  }

  exportLocationGrid() {
    this.gridService.exportGrid(this.locationHistoryGrid, `Location History for ${this.currentDevice.DefendantName}(${this.currentDevice.PhoneNumber})`);
  }

  locationAddress(location) {
    let formattedAddress = '';
    if (location.Addr1 && location.Addr1 !== 'null') {
      formattedAddress += location.Addr1;
    }
    if (location.Addr2 && location.Addr2 !== 'null') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' + location.Addr2 : location.Addr2);
    }
    if (location.Apt && location.Apt !== 'null') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' + location.Apt : location.Apt);
    }
    if (location.Building && location.Building !== 'null') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' + location.Building : location.Building);
    }
    if (location.StreetName && location.StreetName !== 'null') {
      formattedAddress += location.StreetNum + ' ' + location.StreetName;
    }
    if (location.City && location.City !== 'null') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' + location.City : location.City);
    }
    if (location.County && location.County !== 'null') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' + location.County : location.County);
    }
    if (location.StateProv && location.StateProv !== 'null') {
      formattedAddress += (formattedAddress.length > 0 ? ', ' + location.StateProv : location.StateProv);
    }
    return formattedAddress;
  }

  getMapMarkersMvs() {
    let obj, id = 0;
    this.mvsMarkers = [];
    // console.log('getMapMarkersMvs - this.filteredMvsLocationData', this.filteredMvsLocationData);
    for (obj of this.filteredMvsLocationData) {
      if (!isNullUndefinedOrEmpty(obj.LocationAddr)) {
        this.mvsMarkers.push({
          id: id,
          latitude: parseFloat(obj.LocationAddr.GEOCode.Latitude),
          longitude: parseFloat(obj.LocationAddr.GEOCode.Longitude),
          text: obj.Address,
          radius: parseFloat(obj.Accuracy.split(' ')[0]) * 1609.34,
          address: obj.Address,
          time: moment(obj.LocationTimestamp).format('l LT')
        });
        id += 1;
      }
    }
    // console.log('getMapMarkersMvs - this.mvsMarkers', this.mvsMarkers);
    if (this.mvsMarkers.length > 0) {
      this.location = this.mvsMarkers[this.mvsMarkers.length - 1];
    }
  }

  getMapMarkersCheckins() {
    let obj, id = 0;
    this.checkinMarkers = [];
    let iconUrl = '';
    for (obj of this.filteredCheckinLocationData) {

      if (!isNullUndefinedOrEmpty(obj.Lat)) {
        switch (obj.FacialRecognitionStatus) {
          case 'Pass':
            iconUrl = 'https://chart.googleapis.com/chart?chst=d_text_outline&chld=00B995|40|l||b|x';
            break;
          case 'Fail':
            iconUrl = 'https://chart.googleapis.com/chart?chst=d_text_outline&chld=D54950|40|l||b|x';
            break;
          case 'Baseline':
            iconUrl = '/assets/img/circle-minus.png';
            break;

          default:
            iconUrl = 'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=|00B995|';
            break;
        }

        this.checkinMarkers.push({
          id: id,
          latitude: parseFloat(obj.Lat),
          longitude: parseFloat(obj.Lng),
          text: '(none)',
          imageUrl: obj.ImageUrl,
          radius: obj.LocationAccuracy, // parseFloat(obj.Accuracy.split(' ')[0]) / 0.00062137,
          address: `${parseFloat(obj.Lat)}, ${parseFloat(obj.Lng)}`, // obj.Address,
          time: moment(obj.ScheduleDate).format('l LT'),
          iconUrl: iconUrl,
        });
        id += 1;
      }
      if (this.checkinMarkers.length > 0) {
        this.location = this.mvsMarkers[this.mvsMarkers.length - 1];
      }
    }

    // console.log('getMapMarkersCheckins - this.checkinMarkers', this.checkinMarkers);
    if (this.checkinMarkers.length > 0) {
      this.location = this.checkinMarkers[this.checkinMarkers.length - 1];
    }
  }

  getDateFilters() {
    let obj;
    this.historyRangeStartDateFilter = null;
    this.historyRangeEndDateFilter = null;
    for (obj of this.locationHistoryDataCombined) {
      if (isNullUndefinedOrEmpty(this.historyRangeStartDateFilter)) {
        this.historyRangeStartDateFilter = obj.LocationTimestamp;
      } else {
        if (new Date(this.historyRangeStartDateFilter) > new Date(obj.LocationTimestamp)) {
          this.historyRangeStartDateFilter = obj.LocationTimestamp;
        }
      }
      if (isNullUndefinedOrEmpty(this.historyRangeEndDateFilter)) {
        this.historyRangeEndDateFilter = obj.LocationTimestamp;
      } else {
        if (new Date(this.historyRangeEndDateFilter) < new Date(obj.LocationTimestamp)) {
          this.historyRangeEndDateFilter = obj.LocationTimestamp;
        }
      }
    }
  }

  onLocationPopupDateSelected(e) {
    this.dateFilter = e.value;
    this.dateRangeFiltersVisible = false;
    switch (this.dateFilter) {
      case 'This Week':
        this.historyStartDateFilter = moment().startOf('week');
        this.historyEndDateFilter = moment().endOf('week');
        break;

      case 'This Month':
        this.historyStartDateFilter = moment().startOf('month');
        this.historyEndDateFilter = moment().endOf('month');
        break;

      case 'Last Month':
        this.historyStartDateFilter = moment().startOf('month').subtract(1, 'month');
        this.historyEndDateFilter = moment().endOf('month').subtract(1, 'month');
        break;

      case 'Date Range':
        this.dateRangeFiltersVisible = true;
        this.getDateFilters();
        this.historyStartDateFilter = moment(this.historyRangeStartDateFilter).startOf('day');
        this.historyEndDateFilter = moment(this.historyRangeEndDateFilter).endOf('day');
        break;

      default:
        this.filteredMvsLocationData = this.mvsHistoryData;
        this.filteredCheckinLocationData = this.checkinHistoryData;
        this.getMapMarkersMvs();
        this.getMapMarkersCheckins();
        this.getTransformedCombinedDataForGrid('MVS');
        this.getTransformedCombinedDataForGrid('CHECKIN');
        this.locationHistoryGrid.instance.clearFilter();
        break;
    }
    if (!isNullOrUndefined(this.dateFilter)) {
      this.applyHistoryDateFilters();
    }
  }

  applyHistoryDateFilters() {
    // console.log('filtering for dates', this.historyStartDateFilter, this.historyEndDateFilter);
    this.clearTransformedCombinedData();
    this.locationHistoryGrid.instance.filter(item => {
      return moment(item.LocationTimestamp).isBetween(this.historyStartDateFilter, this.historyEndDateFilter);
    });
    this.filteredMvsLocationData = this.mvsHistoryData.filter(
      item => moment(item.LocationTimestamp).isBetween(this.historyStartDateFilter, this.historyEndDateFilter));
    this.filteredCheckinLocationData = this.checkinHistoryData.filter(
      item => moment(item.DateLinkOpened).isBetween(this.historyStartDateFilter, this.historyEndDateFilter));
    this.getMapMarkersMvs();
    this.getMapMarkersCheckins();
    this.getTransformedCombinedDataForGrid('MVS');
    this.getTransformedCombinedDataForGrid('CHECKIN');
  }

  applyDateRangeFilters(e, type) {
    if (type === 'start') {
      this.historyRangeStartDateFilter = e.value;
      this.historyStartDateFilter = this.historyRangeStartDateFilter;
    } else {
      this.historyRangeEndDateFilter = e.value;
      this.historyEndDateFilter = this.historyRangeEndDateFilter;
    }
    this.applyHistoryDateFilters();
  }

  formatPhone(Data): any {
    if (!Data.PhoneNumber) {
      return '';
    }
    return formatNumber(Data.PhoneNumber, 'US', 'National');
  }

  onHistoryRowClick(e) {
    const latitude = e.data.Latitude; // e.data.LocationAddr.GEOCode.Latitude;
    const longitude = e.data.Longitude; // e.data.LocationAddr.GEOCode.Longitude;
    // console.log('onHistoryRowClick this.mvsMarkers', this.mvsMarkers);
    // console.log('onHistoryRowClick this.checkinMarkers', this.checkinMarkers);
    // console.log('lat and long for clicked row', latitude, longitude);
    let thisMarker: any;
    if (this.currentDevice) {
      thisMarker = this.mvsMarkers.filter(marker => marker.latitude == latitude && marker.longitude == longitude);
    } else {
      thisMarker = this.checkinMarkers.filter(marker => marker.latitude == latitude && marker.longitude == longitude);
    }
      
    this.markers.forEach((marker: MapMarker) => {
      if (marker.getPosition().lat() == thisMarker[0].latitude && marker.getPosition().lng() == thisMarker[0].longitude) {
        this.onMarkerClick(marker, e.dataIndex);
      }
    });
  }

  onMarkerClick(marker: MapMarker, windowIndex: number) {
    this.infoWindows.forEach(infoWindow => infoWindow.close());
    let curIdx = 0;
    this.infoWindows.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        this.map.panTo(marker.getPosition());
        curIdx++;
      } else {
        curIdx++;
      }
    });
  }

  onHistoryGridContentReady(e) {
    if (window.innerHeight >= 980) {
      e.component.option('paging.pageSize', 13);
    } else if (window.innerHeight >= 900) {
      e.component.option('paging.pageSize', 10);
    } else {
      e.component.option('paging.pageSize', 5);
    }
  }

  googleLocation(e) {
    // let navUrl = 'https://maps.google.com?q=' + e.LocationAddr.GEOCode.Latitude+','+e.LocationAddr.GEOCode.Longitude;
    const navUrl = 'https://maps.google.com?q=' + e.Address.replace(',', '+').replace(' - ', '+');
    window.open(navUrl, '_blank');
  }

  onCheckinGridToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.checkinHistoryGrid.export.fileName = 'Checkin History';
            this.checkinHistoryGrid.instance.exportToExcel(false);
          },
        },
      },
    );
  }

  recordCheckIn(data) {
    this.dynamicPopupData = [];
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = this.defendantId || this.defendantModel.Id;
    this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = this.defendantModel.person.getFullName();
    this.dynamicPopupTitle = 'Record Check In';

    if (!!data) {
      this.dynamicPopupTitle = 'Edit Check In';
      this.dynamicPopupData['ExistingCheckin'] = data;
    }

    this.dynamicPopupClass = CheckinComponent;
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;
  }

  editCheckInSchedule(data) {
    // console.log('editing check in schedule', data, this.checkinSchedule, this.defendantId, this.defendantModel.Id);
    this.dynamicPopupData = [];
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = this.defendantId || this.defendantModel.Id;
    this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = this.defendantModel.person.getFullName();
    this.dynamicPopupTitle = 'Edit Check In Schedule';
    this.dynamicPopupClass = CheckinscheduleComponent;
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;
  }

  navigateOnMap(data) {
    const navUrl = 'https://maps.google.com?q=' + data.Latitude + ',' + data.Longitude;
    window.open(navUrl, '_blank');
  }

  dynamicPopupHidden(e) {
    this.loadCheckinGridData();
    this.dynamicPopupClass = null;
    this.dynamicPopupData = [];
    this.dynamicPopupTitle = '';
    this.dynamicPopupVisible = false;
    this.dynamicPopupShowTitle = false;
  }

}
