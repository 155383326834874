import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';
import { PermGuard } from '../../guards/perm.guard';
import { BillingGuard } from '../../guards/billing.guard';
import { BasicComponent } from '../../components/common/layouts/basic.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { FeaturesComponent } from './features/features.component';
import { CustomerUsersComponent } from './customer-users/customer-users.component';

const routes: Routes = [

  {
    path: 'admin',
    component: BasicComponent,
    canActivate: [AuthGuard, PermGuard, BillingGuard],
    children: [
      {
        path: 'customers',
        component: CustomersComponent,
        data: {
          pageTitle: 'Customers',
          pageSubtext: '',
        },
      },
      {
        path: 'customer-details/:customerId',
        component: CustomerDetailsComponent,
        data: {
          pageTitle: 'Customer details',
          pageSubtext: '',
          animation: 'fadeCustomerDetails',
        },
      },
      {
        path: 'users',
        component: CustomerUsersComponent,
        data: {
          pageTitle: 'Users',
          pageSubtext: '',
          animation: 'fadeCustomerDetails',
        },
      },
      {
        path: 'whats-new',
        component: WhatsNewComponent,
        data: {
          pageTitle: 'What\'s new',
          pageSubtext: '',
        },
      },
      {
        path: 'features',
        component: FeaturesComponent,
        data: {
          pageTitle: 'Features',
          pageSubtext: 'Enable & Disable Features',
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  declarations: [],
})
export class AdminRoutingModule { }
