import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { CreditCardTransactionOptions } from '../models/creditcardtransactionoptions';
import { PortoResponse } from '../models/porto-response';
import { GatewayDetails } from '../models/gateway-details';
import { PaymentMethodToken } from '../modes/payment-method-token';
import { UnsubscribeOnDestroyAdapter } from '../common/UnsubscribeOnDestroy';
import { CloverResponse } from '../models/clover-response';

@Injectable()
export class CreditCardGatewayService extends UnsubscribeOnDestroyAdapter {

  private masterGateway = null;
  private clientGateways: GatewayDetails[];


  constructor(private http: HttpClient, private settings: SettingsService) {
    super();
  }

  getClientGateways(): Observable<GatewayDetails[]> {
    if (this.clientGateways) {
      return of(this.clientGateways);
    } else {
      return this.http.get<PortoResponse<GatewayDetails[]>>(`${this.settings.getBaseUrl()}/cc/clientgateways?include=creditcardgateways`)
        .pipe(
          map((res: PortoResponse<GatewayDetails[]>) => res.data),
          map((gateways: GatewayDetails[]) => {
            gateways.map((gateway: any) => { gateway.creditcardgateways = gateway.creditcardgateways.data; });
            this.clientGateways = gateways;
            return this.clientGateways;
          }),
          catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
        );
    }
  }

  getClientGatewaysInternal(customerId: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/ccinternal/clientgateways/${customerId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getMasterGateway(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/cc/gateways/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getMasterGatewayByType(type: any): Observable<PortoResponse<GatewayDetails>> {
    if (this.masterGateway) {
      return of(this.masterGateway);
    } else {
      return this.http.get(`${this.settings.getBaseUrl()}/cc/gateways/type/${type}`).pipe(
        map((res: any) => {
          this.masterGateway = res;
          return res;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
    }
  }

  // uses client auth
  getMasterGatewayByTypeInternal(type: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/internal/cc/gateways/type/${type}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  processTransaction(options: CreditCardTransactionOptions): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/cc/process`, options).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  createClientGateway(data: any): Observable<any> {
    this.clientGateways = null;
    return this.http.post(`${this.settings.getBaseUrl()}/cc/clientgateways`, {data: data}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  updateClientGateway(id: any, data: any): Observable<any> {
    this.clientGateways = null;
    return this.http.patch(`${this.settings.getBaseUrl()}/cc/clientgateways/${id}`, {data: data}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  /**
   * Registers a Clover gateway for a merchant and grabs an api token using the values returned from the Oauth2 flow
   * @param merchantId: This ID uniquely identifies the Clover merchant account that has been authorized to use your app.
   * @param employeeId: This ID uniquely identifies the owner account associated with the merchant business.
   * @param state: To ensure that merchants are logging in to a legitimate redirect URL from your app, you can set the state parameter
   * with any string value. In this case, if the Clover server response also includes the same state parameter value, this ensures that
   * (1) a legitimate request has been made by your app, and (2) the Clover server has redirected the merchant to your app in response
   * to the legitimate request.
   * @param clientId: This ID uniquely identifies your app on the Clover App Market. This parameter confirms that your app is participating
   * in the OAuth 2.0 flow and that the code parameter value is for the specified client_id parameter value.
   * @param code: With this authorization code, the Clover server confirms that your request for merchant data has been authorized by the
   * merchant. You can use this code to further negotiate with the Clover server for an API token.
   */
  registerCloverGateway(merchantId, employeeId, state, clientId, code): Observable<GatewayDetails> {
    return this.http.post<PortoResponse<GatewayDetails>>(`${this.settings.getBaseUrl()}/gateway/register/clover`, {
      merchantId, employeeId, state, clientId, code,
    }).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getClientGatewayByPaymentMethod(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/cc/clientgatewaybypaymentmethod/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getClientDefaultGatewayExists(customerId = '', isInternal = false): Observable<any> {
    let epUri = `${this.settings.getBaseUrl()}/cc/clientgatewayexists`;
    if (isInternal) {
      epUri = `${this.settings.getBaseUrl()}/cc/internal/clientgatewayexists/${customerId}`;
    }

    return this.http.get(epUri).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  clientHasGateway(customerId = '', isInternal = false): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      this.subs.sink = this.getClientDefaultGatewayExists().subscribe(res => {
        if (res.data && res.data.length > 0) {
          const gateway = res.data;
          this.subs.sink = this.getClientGatewayByPaymentMethod(gateway[0].CreditCardGatewayId).subscribe(result => {
            subscriber.next(!!result.data[0]);
          });
        } else {
          subscriber.next(false);
        }
      });
    });
  }

  getClientDefaultGateway(customerId = ''): Observable<PortoResponse<GatewayDetails[]>> {
    const apiRoute = customerId === '' ? 'defaultclientgateway' : `defaultclientgatewayinternal/${customerId}`;

    return this.http.get(`${this.settings.getBaseUrl()}/cc/${apiRoute}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  getGatewayCredential(id: string): Observable<string> {
    return this.http.get<PortoResponse<GatewayDetails>>(`${this.settings.getBaseUrl()}/cc/gateway/credential/${id}`).pipe(
      map((res: PortoResponse<GatewayDetails>) => res.data.GatewayCredential),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  getGatewayRegistrationUrl(gatewayName: string, industry: string) {
    return this.http.get(`${this.settings.getBaseUrl()}/cc/registrationurl/${gatewayName}/${industry}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  validateGatewayRegistrationFromUrl(gatewayName: string, stateToken: string, accessCode: string, industry = 'Other') {
    this.clientGateways = null;
    return this.http.put(`${this.settings.getBaseUrl()}/cc/registrationurl/${gatewayName}`, {
      code: accessCode,
      state: stateToken,
      industry: industry,
    }).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  // Below functions are used stipe and other gateways
  createPaymentIntent(customerId: string, debtorId: string, paymentAmount: number, invoiceRef: string, isInternal = false, paymentDetailId = '', fromFriends = false) {
    let epUri = `${this.settings.getBaseUrl()}/cc/paymentintent/${customerId}`;
    if (isInternal) {
      epUri = `${this.settings.getBaseUrl()}/cc/internal/paymentintent/${customerId}`;
    }
    return this.http.post(epUri, {amount: paymentAmount, debtorId: debtorId, invoice: invoiceRef, planDetailId: paymentDetailId, fromFriends,}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  createSetupIntent(customerId: string, debtorId: string, isInternal = false) {
    let epUri = `${this.settings.getBaseUrl()}/cc/setupintent/${customerId}`;
    if (isInternal) {
      epUri = `${this.settings.getBaseUrl()}/cc/internal/setupintent/${customerId}`;
    }
    return this.http.post(epUri, {debtorId: debtorId}).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  generateGatewayHash(id: string, amount: number): Observable<PayeezyHash> {
    const url = `${this.settings.getBaseUrl()}/cc/payeezy/hash/${id}/${amount}`;
    return this.http.get(url)
      .pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
      );
  }

  generateGatewayHashPublic(id: string, amount: number): Observable<PayeezyHash> {
    const url = `${this.settings.getBaseUrl()}/public/cc/payeezy/hash/${id}/${amount}`;
    return this.http.get(url)
      .pipe(
        map((res: any) => res),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),
      );
  }

  /**
   * The getElavonSessionToken method sends a request to the api to get a session token from Elavon.
   * @param id: The id of the Client Credit Card Gateway
   * @param person: The id of the received by person
   * @param amount: The payment amount
   */
  getElavonSessionToken(id: string, person: string, amount: number, client = true): Observable<string> {
    let url;
    if (client) {
      url = `${this.settings.getBaseUrl()}/elavon/token/${id}`;
    } else {
      url = `${this.settings.getBaseUrl()}/elavon/private/token/${id}`;
    }
    return this.http.post<TokenResponse>(url, {person, amount}).pipe(
      map(res => res.token),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  /**
   * The getElavonV1SessionToken method sends a request to the api to get a session token from Elavon.
   * This method should only be called when the page is called from v1.
   * @param merchantId: The merchants unique account Id from Elavon.
   * @param employeeId: The employee Id allowed to make api calls on behalf of merchant.
   * @param terminalPin: The pin number of the terminal that will be used to process the transasction.
   * @param amount: The amount of the payment.
   * @param first: The first name of person making the payment.
   * @param last: The last name of the person making the payment.
   */
  getElavonV1SessionToken({ merchantId, employeeId, terminalPin, amount, first, last }: ElavonV1TokenPayload): Observable<string> {
    const url = `${this.settings.getBaseUrl()}/elavon/v1/token`;
    return this.http.post<TokenResponse>(url, { merchantId, employeeId, terminalPin, amount, first, last }).pipe(
      map(res => res.token),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  getAuthDotNetToken(id: string, accountId: string, amount: number, appUrl: string, external = true, isFriends = false): Observable<string> {
    let url = `${this.settings.getBaseUrl()}/authdotnet/token`;
    if (!external) {
      url += `/internal`;
    }
    url += `/${id}`;
    return this.http.post<TokenResponse>(url, {accountId, amount, appUrl, isFriends}).pipe(
      map(res => res.token),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  getAuthDotNetCustomerPage(
    gatewayId: string,
    accountId: string,
    appUrl: string,
    transactionId: string,
    cardType: string,
    cardNumber: string,
  ): Observable<PaymentMethodToken> {
    const url = `${this.settings.getBaseUrl()}/authdotnet/customer/${gatewayId}`;
    return this.http.post<PortoResponse<PaymentMethodToken>>(url, {accountId, appUrl, transactionId, cardType, cardNumber}).pipe(
      map(res => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  verifyGateway(data: any): Observable<any> {
    const url = `${this.settings.getBaseUrl()}/creditcard/verify`;
    return this.http.post(url, data).pipe(
      map(res => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  createCloverPayment(apiKey: string, amount: number, token: string, indempot: string): Observable<CloverResponse> {
    return this.http.post<CloverResponse>(`${this.settings.getBaseUrl()}/gateway/charge/clover`, {
      apiKey, amount, token, indempot,
    }).pipe(
      map(res => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  createASPPayment(amount: number, token: string, gatewayId: string, personId: string): Observable<ASPResponse> {
    return this.http.post<ASPResponse>(`${this.settings.getBaseUrl()}/gateway/charge/asp`, {amount, token, gatewayId, personId})
    .pipe(
      map(res => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  getClientGatewayById(id: string): Observable<GatewayDetails> {
    return this.http.get<PortoResponse<GatewayDetails>>(`${this.settings.getBaseUrl()}/cc/gateway/${id}`).pipe(
      map(res => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error))),
    );
  }

  resolveASPResponseCodes(code: string): string {
    const codes: { [key: string]: string } = {
      '100': 'Transaction was approved.',
      '200': 'Transaction was declined by processor.',
      '201': 'Do not honor.',
      '202': 'Insufficient funds.',
      '203': 'Over limit.',
      '204': 'Transaction not allowed.',
      '220': 'Incorrect payment information.',
      '221': 'No such card issuer.',
      '222': 'No card number on file with issuer.',
      '223': 'Expired card.',
      '224': 'Invalid expiration date.',
      '225': 'Invalid card security code.',
      '226': 'Invalid PIN.',
      '240': 'Call issuer for further information.',
      '250': 'Pick up card.',
      '251': 'Lost card.',
      '252': 'Stolen card.',
      '253': 'Fraudulent card.',
      '260': 'Declined with further instructions available. (See response text)',
      '261': 'Declined-Stop all recurring payments.',
      '262': 'Declined-Stop this recurring program.',
      '263': 'Declined-Update cardholder data available.',
      '264': 'Declined-Retry in a few days.',
      '300': 'Transaction was rejected by gateway.',
      '400': 'Transaction error returned by processor.',
      '410': 'Invalid merchant configuration.',
      '411': 'Merchant account is inactive.',
      '420': 'Communication error.',
      '421': 'Communication error with issuer.',
      '430': 'Duplicate transaction at processor.',
      '440': 'Processor format error.',
      '441': 'Invalid transaction information.',
      '460': 'Processor feature not available.',
      '461': 'Unsupported card type.',
    };
    return codes[code];
  }

}

export interface TokenResponse {
  token: string;
}

export interface PayeezyHash {
  hash: string;
  sequence: string;
  timestamp: string;
  currency: string;
}

export interface ElavonV1TokenPayload {
  merchantId: string;
  employeeId: string;
  terminalPin: string;
  amount: string;
  first: string;
  last: string;
}

export interface ASPResponse {
  authcode: string;
  avsresponse: string;
  cvvresponse: string;
  orderid: string;
  response: 1 | 3;
  response_code: string;
  responsetext: 'SUCCESS' | 'Authentication Failed';
  transactionid: string;
  type: string;
}
