import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
// services
import { TokenInterceptor } from './services/base.service';
// guards
import { AuthGuard } from './guards/auth.guard';
import { PermGuard } from './guards/perm.guard';
import { BillingGuard } from './guards/billing.guard';

import { environment } from '../environments/environment';

import { MomentModule } from 'ngx-moment';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
// App views
import { LayoutsModule } from './components/common/layouts/layouts.module';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';

import { TopnavbarModule } from './components/common/topnavbar/topnavbar.module';
import { AngularDraggableModule } from 'angular2-draggable';

import { AdminModule } from './views/admin/admin.module';
import { NgxMaskModule } from 'ngx-mask';
import { DevCacheInterceptor } from './services/dev.cache.interceptor';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { GuidedSetupComponent } from './views/guided-setup/guided-setup.component';
import { MetaReducer, StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { metaReducers, reducers, State } from './state/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { Integrations } from '@sentry/tracing';
import { createErrorHandler, init, routingInstrumentation, TraceService } from '@sentry/angular';
import * as LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { DxToastModule } from 'devextreme-angular/ui/toast';

import { version } from '../../package.json';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { Dedupe } from '@sentry/integrations';
import { PipesModule } from './shared/pipes.module';
import { CardResponseComponent } from './views/card-response/card-response.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


const logrocketMiddleware = createNgrxMiddleware(LogRocket, {});

export function getMetaReducers(): MetaReducer<State>[] {
  return metaReducers.concat([logrocketMiddleware]);
}

init({
  dsn: environment.sentryDsn || undefined,
  // if the event is something we want to send to sentry, then return event
  // otherwise, if it's something that we don't care about, then return null
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error === 'ignore') {
      return null;
    } else if (event.exception.values[0].value === 'Non-Error exception captured with keys: error, success') {
      return null;
    } else if (event.exception.values[0].value === 'Non-Error exception captured with keys: message') {
      return null;
    } else if (event.exception.values[0].value === 'Non-Error exception captured with keys: Cannot read properties of undefined reading endsWith') {
      return null;
    } else {
      return event;
    }
  },
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'api2.captira.local',
        'apiato-c2-dev.captira.com',
        'apiato-c2-test.captira.com',
        'apiato-c2-prod.captira.com',
      ],
      routingInstrumentation: routingInstrumentation,
      beforeNavigate: (context) => {
        const path = window.location.pathname.split('/');
        const letterAndNumbers = /^[0-9a-zA-Z]+$/;
        for (let i = 0; i < path.length; i++) {
          if (path[i].length === 16 && path[i].match(letterAndNumbers)) {
            path[i] = path[i].replace(/[a-z0-9]{16}/g, ':id');
          }
        }
        const name = path.join('/');
        return {
          ...context,
          name: name,
        };
      },
    }),
    new Dedupe(),
  ],
  tracesSampleRate: 0.1,
  release: version,
});

export function getWindow() {
  // // console.log('returing window', window);
  return window;
}

@NgModule({
  declarations: [
    AppComponent,
    GuidedSetupComponent,
    CardResponseComponent,
  ],
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DynamicFormModule,
    // AngularGooglePlaceModule,
    // SharedloadModule,
    // DefendantPreviewModule,
    // add for test drag item
    AngularDraggableModule,

    // Modules
    LayoutsModule,
    RouterModule.forRoot(ROUTES, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'}),
    // see https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2
    TopnavbarModule,
    MomentModule,
    // ListsModule,
    // LookupsModule,
    // MatNativeDateModule,
    AdminModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    CoreModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([]),
    DxToastModule,
    PipesModule,
    FontAwesomeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorLoggerInterceptor, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: DevCacheInterceptor, multi: true},
    {provide: ErrorHandler, useValue: createErrorHandler()},
    {provide: TraceService, deps: [Router]},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {closeOnNavigation: false}},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [TraceService],
      multi: true,
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers,
    },
    AuthGuard,
    PermGuard,
    BillingGuard,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
}
