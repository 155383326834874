import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from './../../../common/UnsubscribeOnDestroy';
import { PopupService } from '../../../services/popup.service';
import { DialogService } from '../../../services/dialog.service';
import { AppConstants } from '../../../shared/AppConstants';

export interface IDynamicPopupComponent {
  onClose: Subject<boolean>;
  dynamicData: any;
  inDynamicPopup: boolean;
}

@Component({
  selector: 'app-dynamic-popup',
  templateUrl: './dynamic-popup.component.html',
  styleUrls: ['./dynamic-popup.component.scss'],
})
export class DynamicPopupComponent extends UnsubscribeOnDestroyAdapter {

  @Output() onPopupHidden = new EventEmitter();
  @Output() onPopupHiding = new EventEmitter();
  @Input() popupShowTitle = false;
  @Input() popupComponentTitle: any;
  @Input() popupComponentVisible = false;
  @Input() popupAllowCloseOnOutsideClick = true;
  @Input() showCloseButton = false;
  @Input() maxHeight = AppConstants.DEFAULTPOPUPCONFIG.MAXHEIGHT;
  @Input() maxWidth = AppConstants.DEFAULTPOPUPCONFIG.MAXWIDTH;
  @Input() scrollHeight = '100%';
  @Input() addPopupClass = true;
  @Input() formsPopupClass = false;
  @Input() dragEnabled = AppConstants.DEFAULTPOPUPCONFIG.DRAGENABLED;
  _component: Type<IDynamicPopupComponent> | undefined;


  @Input()
  set popupComponent(component: Type<IDynamicPopupComponent>) {
    if (!!component) {
      this._component = component;
      this.initPopup();
    }
  }

  @Input() popupComponentData: any;

  @ViewChild('dynamicInsert', { read: ViewContainerRef }) dynamicInsert: ViewContainerRef;

  position = { my: 'center', at: 'center', of: 'window' };

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private popupService: PopupService,
    private dialogService: DialogService,
  ) {
    super();
  }

  initPopup() {
    if (!!this._component) {
      this.popupComponentVisible = true;
      const factory = this.componentFactoryResolver.resolveComponentFactory(this._component);
      setTimeout(() => {
        if (!!this.dynamicInsert) {
          this.dynamicInsert.clear();
        }
        if (!this.popupComponentData) {
          this.popupComponentData = [];
        }

        if (!!this.dynamicInsert) {
          const createdComponent = this.dynamicInsert.createComponent(factory, 0);
          createdComponent.instance.dynamicData = this.popupComponentData;
          createdComponent.instance.inDynamicPopup = true;
          this.subs.sink = createdComponent.instance.onClose.subscribe(oc => {
            this.onDynamicControlClose(oc);
          });
        }
      }, 150);

      this.subs.sink = this.popupService.maximizePopup$.subscribe(shouldMaximize => {
        if (shouldMaximize) {
          this.maxHeight = '100%';
          this.maxWidth = '100%';
        } else {
          this.maxHeight = '80%';
          this.maxWidth = '80%';
        }
      });
    }
  }

  onDynamicControlClose(e: any) {
    this.hidden(e);
  }

  hiding(e: any) {
    this.onPopupHiding.emit(e);
  }

  hidden(e: any) {
    this.popupService.closeCurrentPopup(true);
    this.dialogService.remove();
    this.popupComponentVisible = false;
    if (!!this.dynamicInsert) {
      this.dynamicInsert.clear();
    }
    this._component = undefined;
    // we are expecting true or false. if not set explicitly by client use true
    if (e !== true && e !== false) {
      if (typeof (e) === 'string' && (e.includes('defendant-payer') || e.includes('collection-payer'))) {
        this.onPopupHidden.emit(e);
      } else if (this.popupComponentData.sourceType === 'DefendantPayerForm') {
        this.onPopupHidden.emit('defendant-payer:');
      }
      this.onPopupHidden.emit(false);
    } else {
      this.onPopupHidden.emit(e);
    }
  }
}
