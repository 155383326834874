import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Contact } from '../models/contact';
import { QuickAction, QuickActionPreviewEvent } from '../views/defendant/quick-actions-preview/quick-actions-preview.component';
import { QuickActionEvent } from '../views/defendant/quick-actions/quick-actions.component';
import { Document } from '../models/document';

@Injectable()
export class EventService {
  private pageTitleChangedEvent = new Subject<string>();
  private pageSubTextChangedEvent = new Subject<string>();
  private defActionEvent = new Subject<QuickAction<QuickActionEvent>>();
  private quickActionEvent = new Subject<QuickAction<QuickActionPreviewEvent>>();
  private gridEditModeEvent = new Subject<string>();
  private gridSaveEvent = new Subject<string>();
  private mnuModeEvent = new Subject<string>();
  private contentLoadedEvent = new Subject<string>();
  private componentLoad = new Subject<string>();
  private guidedSetupView = new Subject<string>();
  private closeWelcomePopup = new Subject<string>();
  private isBusy = new Subject<boolean>();
  private showSnack = new Subject<any>();
  private showToast = new Subject<any>();
  private isAddNewPdfEvent = new Subject<boolean>();
  private dataChanged = new Subject<any>();
  private isAddNewBundleEvent = new Subject<boolean>();
  private onfromGroupUpdated = new Subject<boolean>();
  private onFormGroupCreatedId = new Subject<string>();
  private setESignCoordinatesEvent = new Subject<any>();
  private reloadDebtorDetails = new Subject<any>();
  private collectionAccounts = new Subject<any>();
  private reloadCollectionPlan = new Subject<any>();
  private onSubscribedProductsChange = new Subject<any>();
  private onAccountSwitch =  new Subject<any>();
  private onAdviceClose = new Subject<boolean>();
  private defendantDetailsSavingStatusSubject = new Subject<any>();
  private triggerSaveDefendantFullProfile = new Subject<boolean>();
  private collectionReminders = new Subject<boolean>();
  private disableSave = new Subject<boolean>();
  private pageLoad = new Subject<boolean>();
  private guidedSetupViewSaveStep = new Subject<any>();
  private guidedSetupEvent = new Subject<any>();
  private reloadRecentDefendant = new Subject();
  private globalPaymentEvent = new Subject();
  private paymentGatewayUpdated = new Subject();
  private downloadFile = new Subject<void>();
  private payerLoaded = new Subject<Contact>();
  private closeOnboardingToast = new Subject<Boolean>();
  private slideOut = new Subject<string>();
  private defendantLoad = new Subject<string>();
  private closeSlide  = new Subject<string>();
  private mugShot  = new Subject<string>();
  private pauseRemindersUpdated = new Subject();
  private reportReady = new Subject<boolean>();
  private makePaidFalse = new Subject<void>();
  private buttonToggle = new Subject<boolean>();
  private openCheckinHistory = new Subject<boolean>();
  private customerV1ArchiveToggle = new Subject<{type: 'user' | 'global', value: boolean}>();
  private customerAccount = new Subject<string>();
  private deletePayment = new Subject<boolean>();
  private refreshCollateral = new Subject<void>();
  private setDefForAcctRefresh = new Subject<{defendantId: string, transactionId: string}>();
  private refreshAccountingSummary = new Subject<string>();
  private defaultCollateralSet = new Subject<void>();
  private enable2FASet = new Subject<boolean>();
  private pdfAttached = new Subject<Document>();
  private notesWereUpdated = new Subject<void>();
  private switchAccount = new Subject<void>();
  private switchAccountCalled = new Subject<void>();

  pageLoadEvent$ = this.pageLoad.asObservable();
  disableSaveEvent$ = this.disableSave.asObservable();
  pageTitleChangedEvent$ = this.pageTitleChangedEvent.asObservable();
  pageSubtextChangedEvent$ = this.pageSubTextChangedEvent.asObservable();
  defActionEvent$ = this.defActionEvent.asObservable();
  quickActionEvent$ = this.quickActionEvent.asObservable();
  gridEditMode$ = this.gridEditModeEvent.asObservable();
  gridSave$ = this.gridSaveEvent.asObservable();
  mnuMode$ = this.mnuModeEvent.asObservable();
  contentLoaded$ = this.contentLoadedEvent.asObservable();
  currentcomponentLoad$ = this.componentLoad.asObservable();
  guidedSetupView$ = this.guidedSetupView.asObservable();
  closeWelcomePopup$ = this.closeWelcomePopup.asObservable();
  isBusy$ = this.isBusy.asObservable();
  showSnack$ = this.showSnack.asObservable();
  showToast$ = this.showToast.asObservable();
  isAddNewPdf$ = this.isAddNewPdfEvent.asObservable();
  dataChanged$ = this.dataChanged.asObservable();
  collectionAccounts$ = this.collectionAccounts.asObservable();
  collectionReminders$ = this.collectionReminders.asObservable();
  reloadDebtorDetails$ = this.reloadDebtorDetails.asObservable();
  isAddNewBundle$ = this.isAddNewBundleEvent.asObservable();
  onfromGroupUpdated$ = this.onfromGroupUpdated.asObservable();
  onFormGroupCreatedId$ = this.onFormGroupCreatedId.asObservable();
  setESignCoordinatesEvent$ = this.setESignCoordinatesEvent.asObservable();
  reloadCollectionPlan$ = this.reloadCollectionPlan.asObservable();
  onSubscribedProductChange$ = this.onSubscribedProductsChange.asObservable();
  onAdviceClose$ = this.onAdviceClose.asObservable();
  globalPaymentEvent$ = this.globalPaymentEvent.asObservable();
  slideOutEvent$ = this.slideOut.asObservable();
  defendantLoadEvent$ = this.defendantLoad.asObservable();
  closeSlideEvent$ = this.closeSlide.asObservable();
  mugShotEvent$ = this.mugShot.asObservable();
  customerAccount$ = this.customerAccount.asObservable();

  defendantDetailsSavingStatusSubject$ = this.defendantDetailsSavingStatusSubject.asObservable();
  triggerSaveDefendantFullProfile$ = this.triggerSaveDefendantFullProfile.asObservable();

  guidedSetupViewSaveStep$ = this.guidedSetupViewSaveStep.asObservable();
  guidedSetupEvent$ = this.guidedSetupEvent.asObservable();
  reloadRecentDefendant$ = this.reloadRecentDefendant.asObservable();
  paymentGatewayUpdated$ = this.paymentGatewayUpdated.asObservable();
  downloadFileEvent$ = this.downloadFile.asObservable();
  payerLoaded$ = this.payerLoaded.asObservable();
  closeOnboardingToast$ = this.closeOnboardingToast.asObservable();
  pauseRemindersUpdated$ = this.pauseRemindersUpdated.asObservable();
  reportReady$ = this.reportReady.asObservable();
  buttonToggle$ = this.buttonToggle.asObservable();
  makePaidFalse$ = this.makePaidFalse.asObservable();
  openCheckinHistory$ = this.openCheckinHistory.asObservable();
  customerV1ArchiveToggle$ = this.customerV1ArchiveToggle.asObservable();
  deletePayment$ = this.deletePayment.asObservable();
  refreshCollateral$ = this.refreshCollateral.asObservable();
  setDefForAcctRefresh$ = this.setDefForAcctRefresh.asObservable();
  refreshAccountingSummary$ = this.refreshAccountingSummary.asObservable();
  defaultCollateralSet$ = this.defaultCollateralSet.asObservable();
  onAccountSwitch$ = this.onAccountSwitch.asObservable();
  enable2FASet$ = this.enable2FASet.asObservable();
  pdfAttached$ = this.pdfAttached.asObservable();
  notesWereUpdated$ = this.notesWereUpdated.asObservable();
  switchAccount$ = this.switchAccount.asObservable();
  switchAccountCalled$ = this.switchAccountCalled.asObservable();

  initializeLoading(value: boolean) {
    this.pageLoad.next(value);
  }
  openslideOut(defendantId: string){
    this.slideOut.next(defendantId);
  }
  mugShotopne(){
    this.mugShot.next();
  }
  closeSlideout(){
    this.closeSlide.next();
  }
  defendantloadData(){
    this.defendantLoad.next();
  }

  completeLoading() {
    this.pageLoad.complete();
  }

  disableSaving(value: boolean) {
    this.disableSave.next(value);
  }

  changeSettlementDiscounts() {
    this.collectionReminders.next(true);
  }

  fireSaveDefendantFullProfile(state = true) {
    this.triggerSaveDefendantFullProfile.next(state);
  }

  changePageTitle(pageTitle: string) {
    this.pageTitleChangedEvent.next(pageTitle);
  }

  changePageSubText(subText: string) {
    this.pageSubTextChangedEvent.next(subText);
  }

  defAction(defAction: QuickAction<QuickActionEvent>) {
    this.defActionEvent.next(defAction);
  }

  quickAction(quickAction: QuickAction<QuickActionPreviewEvent>) {
    this.quickActionEvent.next(quickAction);
  }

  emitOpenCheckinHistory() {
    this.openCheckinHistory.next();
  }

  changeGridEditMode(editMode: string) {
    this.gridEditModeEvent.next(editMode);
  }

  menuChanged(mnuMode: string) {
    this.mnuModeEvent.next(mnuMode);
  }

  contentLoaded(contentType: string) {
    this.contentLoadedEvent.next(contentType);
  }

  dataHasChanged(data) {
    this.dataChanged.next(data);
  }

  collectionAccountHasChanged(data) {

    this.collectionAccounts.next(data);
  }

  debtorDetailsUpdated(data) {
    this.reloadDebtorDetails.next(data);
  }

  reloadActiveCollectionPlan(data) {
    this.reloadCollectionPlan.next(data);
  }

  subscribedProductsChange() {
    // console.log('subscribedProductsChange in EventService');
    this.onSubscribedProductsChange.next();
  }
  accountSwitch() {
    this.onAccountSwitch.next();
  }
  componentBusy(busy: boolean) {
    this.isBusy.next(busy);
  }

  showSnackbar(message: string, duration: boolean | number, actionLabel: string = 'X', panelClass: any = [], onAction: () => any) {
    this.showSnack.next({ message, duration: (!!duration ? duration : undefined), actionLabel, panelClass, onAction });
  }



  showToaster(type, message, preventDismiss = false) {
    this.showToast.next({ toastType: type, toastMessage: message, preventDismiss: preventDismiss });
  }

  showToasterWithDuration(type, message, duration) {
    this.showToast.next({ toastType: type, toastMessage: message, displayTime: duration });
  }

  closeOnboardingToastFn() {
     this.closeOnboardingToast.next(true);
  }

  compLoad(currentcomponentLoad: any) {
    this.componentLoad.next(currentcomponentLoad);
  }

  viewGuidedSetup(setupVisible: any) {
    this.guidedSetupView.next(setupVisible);
  }

  handleWelcomePopupClose(message: any) {
    this.closeWelcomePopup.next(message);
  }

  gridSaved(gridSave: string) {
    this.gridSaveEvent.next(gridSave);
  }

  isAddNewPdf(isAdded: boolean) {
    this.isAddNewPdfEvent.next(isAdded);
  }

  isAddNewBundle(isAdded: boolean) {
    this.isAddNewBundleEvent.next(isAdded);
  }

  // Emit form group update event in case of add or remove a form group
  emitfromGroupUpdated(update: boolean) {
    this.onfromGroupUpdated.next(update);
  }

  // This will get emit to the mat dialog which contains the save and cancel button. The current created id will be assigned
  emitFormGroupIdCreated(id : string) {
    this.onFormGroupCreatedId.next(id);
  }

  setESignCoordinates(width, height, X, Y, pageNo, scrollTop) {
    this.setESignCoordinatesEvent.next(
      {
        pdfViewWidth: width,
        pdfHeight: height,
        signX: X,
        signY: Y,
        signPageNo: pageNo,
        scrollPosition: scrollTop,
      });
  }

  emitAdviceClose(state: boolean) {
    this.onAdviceClose.next(state);
  }

  defendantDetailsSavingStatusTrigger(state: boolean) {
    this.defendantDetailsSavingStatusSubject.next(state);
  }

  saveGuidedSetupProgress(stepNumber: any, productName: string = '') {
    this.guidedSetupViewSaveStep.next({ step: stepNumber, product: productName });
  }

  toggleGuidedSetup(source: string = '', enabled: boolean) {
    this.guidedSetupEvent.next({ source: source, enabled: enabled });
  }

  reloadRecentDefendants(): void {
    this.reloadRecentDefendant.next();
  }

  globalPayment(): void {
    this.globalPaymentEvent.next();
  }

  paymentGatewayUpdate(): void {
    this.paymentGatewayUpdated.next(true);
  }

  pauseRemindersUpdate() : void {
    this.pauseRemindersUpdated.next(true);
  }

  requestDownloadFile(): void {
    this.downloadFile.next();
  }

  onPayerLoaded(payer: Contact): void {
    this.payerLoaded.next(payer);
  }

  reportDownloaded(status: boolean): void {
    this.reportReady.next(status);
  }

  makePaidFalseFn(): void {
    this.makePaidFalse.next();
  }

  toggleButon(value: boolean) {
    this.buttonToggle.next(value);
  }

  customerV1ArchiveToggled(v1archiveObj : {type: 'user' | 'global', value: boolean}) {
    this.customerV1ArchiveToggle.next(v1archiveObj);
  }

  customerAccountChange(customerId: string) {
    this.customerAccount.next(customerId);
  }

  emitDeletePayment(): void {
    this.deletePayment.next();
  }

  emitRefreshCollateral(): void {
    this.refreshCollateral.next();
  }

  emitSetDefForAcctRefresh(defendantId: string, transactionId: string) {
    this.setDefForAcctRefresh.next({defendantId, transactionId});
  }

  emitRefreshAccountingSummary(transactionId: string) {
    this.refreshAccountingSummary.next(transactionId);
  }

  emitDefaultCollateralSet(): void {
    this.defaultCollateralSet.next();
  }

  emitEnable2FASet(value: boolean) {
    this.enable2FASet.next(value);
  }

  emitPdfAttached(document: Document) {
    this.pdfAttached.next(document);
  }

  emitNotesWereUpdated() {
    this.notesWereUpdated.next();
  }

  emitSwitchAccount() {
    this.switchAccount.next();
  }

  emitSwitchAccountCalled() {
    this.switchAccountCalled.next();
  }
  
}
