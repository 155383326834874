import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { ApiUtilities } from '../shared/ApiUtilities';
import { CacheService } from './cache.service';
import { CalendarItem } from '../models/calendaritem';
import { EventService } from './event.service';

@Injectable()
export class CalendarService {

  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(private http: HttpClient,
              private cache: CacheService,
              private settings: SettingsService,
              private eventService: EventService) {
  }

  LoadForDataSource(start, end, defId = -1): Observable<any> {
    let key = `calendardataservice/${start}/${end}/x`;
    if (defId > 0) {
      key = `${key}/${defId}`;
    }
    let url = `${this.settings.getBaseUrl()}/${key}`;
    const data = this.cache.get(key);
    if (!!data) {
      // // console.log('getting data from cache for calendar')
      return of(JSON.parse(data));
    } else {
      // // console.log('data not found in cache for calendar')

      let response = this.http.get(url).pipe(
        map(res => {
          const calendarItemsToReturn: CalendarItem[] = [];
          if (res['data'] !== null && res['data'].length > 0) {
            for (const item of res['data']) {
              const calendarItem = new CalendarItem();
              calendarItem.startDate = new Date(item['EventDate']);
              calendarItem.endDate = new Date(item['EventDate']);
              calendarItem.allDay = (calendarItem.startDate.getHours() < 7);
              calendarItem.text = item['EventSubject'];
              calendarItem.itemType = item['EventType'];
              calendarItem.Id = item['Id'];
              calendarItem.SourceType = item['SourceType'];
              calendarItem.SourceId = item['SourceId'];
              calendarItemsToReturn.push(calendarItem);
            }
          }
          this.cache.set(key, JSON.stringify(calendarItemsToReturn));
          return calendarItemsToReturn;
        }),
        catchError(error => {
          // // console.log('calendaritem i am inside', error);
          return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
        }));
      return response;
    }
  }

  GetEventTypes(): Observable<any> {
    this.eventService.componentBusy(true);
    let url = `${this.settings.getBaseUrl()}/eventtypes?limit=0`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError(error => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
    return response;
  }

  GetEventTypesWithReminderSettings(): Observable<any> {
    this.eventService.componentBusy(true);
    let url = `${this.settings.getBaseUrl()}/eventtypes?limit=0&include=reminderSettings`;
    let response = this.http.get(url).pipe(
      map((res: any) => res),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError(error => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
    return response;
  }

  AddEvent(data): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/events`;
    let response = this.http.post(url, data).pipe(
      map((res: any) => res),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
    return response;
  }

  SaveReminderSetup(data): Observable<any>
  {
    this.eventService.componentBusy(true);
    let url = `${this.settings.getBaseUrl()}/eventremindersettings`;
    let response = this.http.post(url, data).pipe(
      map((res: any) => res),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError(error => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
    return response;
  }

  DeleteEvent(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/events/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  GetEventById(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/events/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  UpdateEvent(id: any, data: any): Observable<any> {
    let url = `${this.settings.getBaseUrl()}/events/${id}`;
    let response = this.http.patch(url, data).pipe(
      map((res: any) => res),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
    return response;
  }

}
