<h2 class='verify-title'>Verification</h2>
<p>A message with a verification code has been sent to your email. Please enter the code to continue.</p>
<form fxLayout='column' fxLayoutAlign='space-between stretch' fxLayoutGap="8px" (ngSubmit)="verifyCode()">
  <mat-form-field appearance='outline'>
    <mat-label>6 digit code</mat-label>
    <input type='text' matInput [formControl]='verificationCode' />
    <mat-error *ngIf='verificationCode.invalid'>
      Please enter a valid 6 digit code
    </mat-error>
  </mat-form-field>
  <mat-error class='error-message' *ngIf='!!error'>{{error}}</mat-error>
  <button type='button' [disabled]='isLoading' (click)='resendCode()' mat-stroked-button color='primary'>RESEND CODE</button>
  <button [disabled]='isLoading' type='submit' mat-raised-button color='primary'>VERIFY CODE</button>
</form>
<div *ngIf="isLoading" class="overlay">
  <mat-spinner
    *ngIf="isLoading"
    style="display: inline-block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
  </mat-spinner>
</div>
