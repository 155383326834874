import { catchError, map } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { Injectable, Type } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { Phone } from '../models/phones';
import Guid from 'devextreme/core/guid';
import { Address } from '../models/address';
import { RelatedTransaction } from '../models/related-transaction';
import { Contact } from '../models/contact';
import { PortoResponse } from '../models/porto-response';
import {Email} from '../models/email';

@Injectable()
export class ContactService {
  onContactAdded = new Subject<void>();
  onContactAddErrors = new Subject<boolean>();

  constructor(private http: HttpClient, private settings: SettingsService) { }

  createContact(contactData: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/contacts`, contactData).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  updateContact(contactData: Contact): Observable<Contact> {
    return this.http.put<PortoResponse<Contact>>(`${this.settings.getBaseUrl()}/contacts/${contactData.Id}`, contactData).pipe(
      map((res: PortoResponse<Contact>) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  createPayerContact(contactData: any): Observable<Contact> {
    return this.http.post<PortoResponse<Contact>>(`${this.settings.getBaseUrl()}/payercontact?include=person,person.phones,person.emails,person.addresses`, contactData).pipe(
      map((res: PortoResponse<Contact>) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  initNewPhone(phoneNumber: string) {
    const phone = new Phone();
    phone.Number = phoneNumber;
    phone.IsDefault = true;
    phone.IsBad = false;
    phone.PhoneType = 'M';
    phone.isDeleted = false;
    phone.isAdded = true;
    phone.Id = new Guid().toString();
    return phone;
  }

  initNewEmail(emailAddr: string) {
    const email = new Email();
    email.Email = emailAddr;
    email.IsDefault = true;
    email.IsBad = false;
    email.EmailType = 'P';
    email.isDeleted = false;
    email.isAdded = true;
    email.Id = new Guid().toString();
    return email;
  }

  initNewAddress(addr: any) {
    // console.log('Address component: Calling function initNewAddress');
    const address = new Address();
    if (!!addr) {
      address.AddressLine1 = addr.AddressLine1;
      address.AddressLine2 = addr.AddressLine2;
      address.City = addr.City;
      address.County = addr.County;
      address.StateCode = addr.StateCode;
      address.Zip = addr.Zip;
      address.IsBad = false;
      address.IsDefault = true;
      address.IsPhysical = true;
      address.AddressType = addr.AddressType;
      address.Id = new Guid().toString();
      address.isAdded = true;
    }
    return address;
  }

  copyContact(copiedContact: RelatedTransaction, contact: Contact) {
    // copy the gender
    contact.person.Gender = copiedContact.Gender;

    // Remove the contact phones which were copied so that they can be replaced if another record is copied
    contact.person.phones = contact.person.phones.filter((phone: Phone) => !phone.wasCopied);

    // Remove the contact phones which were copied so that they can be replaced if another record is copied
    contact.person.emails = contact.person.emails.filter((email: Email) => !email.wasCopied);

    // copy phone
    // Reset all defaults
    if (copiedContact.Number != null) {
      for (let i = 0; i < contact.person.phones.length; i++) {
        contact.person.phones[i].IsDefault = false;
      }

      const phone = this.initNewPhone(copiedContact.Number);
      phone.wasCopied = true;
      contact.person.phones.push(phone);
    }

    // Reset all defaults
    if (copiedContact.Email != null) {
      for (let i = 0; i < contact.person.emails.length; i++) {
        contact.person.emails[i].IsDefault = false;
      }

      const email = this.initNewEmail(copiedContact.Email);
      email.wasCopied = true;
      contact.person.emails.push(email);
    }
    // copy address

    // Copy the ssn
    contact.person.Ssn = copiedContact.Ssn;
    
    // Copy the dob
    contact.person.Dob = copiedContact.Dob

    // Reset all defaults
    // Remove the contact addresses which were copied so that they can be replaced if another record is copied
    contact.person.addresses = contact.person.addresses.filter((address: Address) => !address.wasCopied);

    if (copiedContact.Address != null || copiedContact.Address2 != null || copiedContact.City != null || copiedContact.Zip != null || copiedContact.StateCode != null) {
      for (let i = 0; i < contact.person.addresses.length; i++) {
        contact.person.addresses[i].IsDefault = false;
      }

      // init new addr
      const address = this.initNewAddress({AddressLine1: copiedContact.Address, AddressLine2: copiedContact.Address2, City: copiedContact.City, Zip: copiedContact.Zip,
      StateCode: copiedContact.StateCode});
      address.wasCopied = true;
      contact.person.addresses.push(address);
    }

    return contact;
  }
}
