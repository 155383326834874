import { take } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PaymentService } from '../../../services/payment.service';
import { DefendantService, PersonRelated } from '../../../services/defendant.service';
import { LookupService } from '../../../services/app-lookup.service';
import { BondArSummary } from '../../../models/bondarsummary';
import { Payment } from '../../../models/payment';
import { DxNumberBoxComponent, DxSelectBoxComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { ButtonbarComponent } from '../../../components/common/buttonbar/buttonbar.component';
import { AppConstants } from '../../../shared/AppConstants';
import { CreditCardResults } from '../../../models/creditcardresults';
import { CreditCardTransactionHistoryService } from '../../../services/creditcardlog.service';
import { PaymentDetail } from '../../../models/paymentdetail';
import { StringUtilities } from '../../../shared/StringUtilities';
import { ActivatedRoute, Router } from '@angular/router';
import { IDynamicPopupComponent } from '../../../components/common/dynamic-popup/dynamic-popup.component';
import { Observable, Subject } from 'rxjs';
import { PdfViewerComponent } from '../../../components/common/pdf-viewer/pdf-viewer.component';
import { ObjectUtilities } from '../../../shared/ObjectUtilities';
import { FieldValues } from '../../../models/reportcompilationrequest';
import { CreditCardGatewayService } from '../../../services/creditcardgateways.service';
import { DefendantPayerFormComponent } from '../../defendant-payer-form/defendant-payer-form.component';
import * as sha512 from 'js-sha512';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { PaymentAssignComponent } from '../../defendant/payment-assign/payment-assign.component';
import { EventService } from '../../../services/event.service';
import { PopupService } from '../../../services/popup.service';
import { LookupComponent } from '../lookup/lookup.component';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import * as Sentry from '@sentry/browser';

import { TransactionARComponent } from '../../transactionar/transactionar.component';
import { TransactionARSummary } from '../../../models/transactionarsummary';
import { UserService } from '../../../services/user.service';
import { SearchboxComponent } from '../../../components/common/searchbox/searchbox.component';
import { GatewayDetails } from '../../../models/gateway-details';
import { TransactionHistory } from '../../../models/creditcardtransactionhistory';
import { MatDialog } from '@angular/material/dialog';
import { NotifyService } from '../../../common/notify/notify.service';
import { DynamicScript, DynamicScriptService } from '../../../services/dynamic-script.service';
import { ElavonResponse } from '../../../models/elavon-response';
import { Converge } from '../../../models/converge';
import { Defendant } from '../../../models/defendant';
import { DefendantAccountingDetails } from '../../../models/defendant-accounting-details';
import isNullUndefinedOrEmpty = ObjectUtilities.isNullUndefinedOrEmpty;
import HEADER_TOP_MARGIN = AppConstants.HEADER_TOP_MARGIN;
import HEADER_TOP_MARGIN_IN_POPUP = AppConstants.HEADER_TOP_MARGIN_IN_POPUP;
import NO_INFORMATION = AppConstants.NO_INFORMATION;
import PAYMENTGATEWAY = AppConstants.PAYMENTGATEWAY;

declare const PayWithConverge: Converge;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  entryComponents: [PdfViewerComponent, DefendantPayerFormComponent, PaymentAssignComponent],
})
export class PaymentComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy, IDynamicPopupComponent {

  @ViewChild('navButtonBar', {static: true}) buttonBar: ButtonbarComponent;
  @ViewChild(SearchboxComponent) searchbox: SearchboxComponent;
  // @ViewChild('ccControl') ccControl: CrediftcardComponent;
  @ViewChild('arSummary') arSummary: TransactionARComponent;
  @ViewChild('valGroup', { static: true }) valGroup: DxValidationGroupComponent;
  @ViewChild('paymentMethodSelect') paymentMethodSelect: DxSelectBoxComponent;
  @ViewChild('ddlPeople') ddlPeople: DxSelectBoxComponent;
  @ViewChild('ddlAgencies') ddlAgencies: LookupComponent;
  @ViewChild('txAmount', { static: true }) txAmount: DxNumberBoxComponent;
  @ViewChild('processPaymentForm') processPaymentForm: any;
  @ViewChild('processPaymentFrame') processPaymentFrame: any;
  @ViewChild('receivedByUserLookup') receivedByUserLookup: LookupComponent;
  @ViewChild('validationSummary') validationSummary: any;
  @ViewChild('vcRef', {read: ViewContainerRef}) vcRef: ViewContainerRef;
  @Input() paymentType = 'Payment';
  summary: TransactionARSummary;

  dynamicData: any;
  onClose = new Subject<boolean>();
  showDefendantSearch = false;
  computedTopMargin = HEADER_TOP_MARGIN;
  noInformation = NO_INFORMATION;

  dynamicPopupClass: any;
  dynamicPopupData: any;
  dynamicPopupTitle: any;
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  paymentMethods = [];
  agencies = [];
  paymentClasses = [];
  bonds = new Array<BondArSummary>();
  people: PersonRelated[] = [];
  payerInfo: PersonRelated;
  routeKey = 'Payment';
  afterErrorRoute = '/dashboard';

  ccApprovalCode = '';

  navKey = AppConstants.PAYMENT_ID_KEY;
  parentNavKey = AppConstants.DEFENDANT_ID_KEY;

  payment = new Payment();

  defendantName: string;
  parentId: any; // defendant id
  sourceId: any; // payment id

  loadIndicatorVisible = false;
  saveButtonDisabled = true;
  inputDisabled = false;
  isLoadingOfficeList = false;
  isLoadingPeopleList = false;
  isLoadingPmtList = false;
  errorTitle = 'There were errors';
  errorData: any[];
  errorVisible = false;

  creating = false;

  maxPaymentDate = new Date();
  maximumAmount = 0.1;
  totalAmount = 0;
  originalPaymentAmount = 0;
  totalAmountChangeTriggeredDuringRowValidate = false;
  otherActions = [];
  paymentMethodIsCreditCard = false;
  showCreditCardEntry = false;
  paymentProcessed = false;
  processingEnabled = true;
  amountValidationMessage = 'Please enter an amount greater than $0 and less than total balance';
  showTransactionSelectPopup: boolean;

  navChanged: any;
  // inDynamicPopup = false;
  showDetails = false;
  showDetailsButtonText = 'Manually Allocate Details';
  detailAmountColumnName = 'Payment Amount';
  paymentTypeDisplay = '';
  inDynamicPopup = false;
  selectedDefendantName: string;
  dateFormatter = StringUtilities.dateFormatter;

  gatewayDetails: GatewayDetails;
  gateways: GatewayDetails[];
  showActivateProcessing = false;
  saveButtonText = 'Save';
  saveButtonWidth = 136;
  saveButtonDivWidth = '170px';
  paymentGatewayUrl = environment.nuveiPaymentsUrl;
  paymentGatewayData: any = {};
  paymentGatewayFrameVisible = false;
  paymentGatewayParams: URLSearchParams;
  invoiceSelectionPopup = false;
  transactions = [];
  accountingDetails: DefendantAccountingDetails[] = [];
  transactionId = '';
  isPrint = false;
  totalPaidTransactions = 0;
  nonZeroTransactionId = '';
  balanceLeft = 0;

  showYesNoConfirmation = false;
  yesNoConfirmMessage = 'Refunds must be processed through your merchant provider if payment is deleted. Continue to Delete?';
  errorUrl: any;

  debugDefendant: any;

  componentLoading = false;
  afterSaveAction: any;
  lastPaymentDetails: any;
  printing = false;
  defArSummary: any;
  transactionsToShow: any = [];
  showPayeezy = false;
  showClover = false;
  showASP = false;

  isPaymentDirty = false;
  firstChanges = {
    paymentMethod: false,
    person: false,
    receivedBy: false,
    paymentAmount: false,
  };
  transactionHistory: TransactionHistory;

  powers: any;
  showPowers = false;

  constructor(
    private payService: PaymentService,
    private defService: DefendantService,
    private lookupService: LookupService,
    private ccHistoryService: CreditCardTransactionHistoryService,
    public router: Router,
    private route: ActivatedRoute,
    private creditCardGatewayService: CreditCardGatewayService,
    private eventService: EventService,
    private popupService: PopupService,
    private userService: UserService,
    public dialog: MatDialog,
    public notifyService: NotifyService,
    public scriptService: DynamicScriptService,
  ) {
    super();
    this.onPrint = this.onPrint.bind(this);
    this.print = this.print.bind(this);
    this.openTransactionSelectPopup = this.openTransactionSelectPopup.bind(this);
    this.saveAndNewPayment = this.saveAndNewPayment.bind(this);
    this.addNewPaymentAfterSave = this.addNewPaymentAfterSave.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
    this.cancel = this.cancel.bind(this);
    this.validateAmount = this.validateAmount.bind(this);
  }

  ngOnInit() {
    this.setMaxPaymentDate();
    this.loadGatewayDetails();
    if (this.dynamicData && 'PaymentType' in this.dynamicData) {
      this.paymentType = this.dynamicData.PaymentType;
    }
    if (this.dynamicData && 'Summary' in this.dynamicData) {
      this.summary = this.dynamicData.Summary;
    }
    const self = this;
    this.subs.sink = this.popupService.closePopup$.subscribe(event => {
      if (event) {
        if (!!self.processPaymentFrame) {
          self.processPaymentFrame.nativeElement.remove();
        }
      }
    });
    this.errorUrl = this.router.url;
    // console.error('Error Url', this.errorUrl);

    this.getDataForLoad();

    switch (this.paymentType) {
      case 'Refund':
        this.detailAmountColumnName = 'Refund Amount';
        this.paymentTypeDisplay = 'Refund';
        break;
      case 'Adjustment':
        this.detailAmountColumnName = 'Write-off Amount';
        this.paymentTypeDisplay = 'Write Off';
        break;
      case 'Payment':
        this.detailAmountColumnName = 'Payment Amount';
        this.paymentTypeDisplay = 'Payment';
        break;
    }
  }

  openTransactionSelectPopup() {
    this.invoiceSelectionPopup = true;
  }

  onPopupHidden(e) {
    if (typeof (e) === 'string' && e.includes('defendant-payer')) {
      if (e.split(':')[1].length > 0) {
        this.loadPeople(e.split(':')[1]);
      } else {
        this.ddlPeople.instance.option('value', null);
      }
    } else {
      if (this.printing) {
        this.onClose.next(true);
      }
    }
    this.dynamicPopupClass = null;
  }

  setMaxPaymentDate() {
    const startDate = new Date();
    this.maxPaymentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  }

  onNavIdChange(val) {
    const idKey = val.key;
    const value = val.idKey;
    switch (idKey) {
      case AppConstants.DEFENDANT_ID_KEY:
        if (value != null && value !== '') {
          this.parentId = value;
        }
        break;
//      case AppConstants.PAYMENT_ID_KEY:
//        break;
      case AppConstants.DEFENDANT_NAME_KEY:
        if (value !== '' && value !== null) {
          this.defendantName = value;
        }
        break;
    }

  }

  paymentMethodChanged(e) {
    if (this.payment != null && e.value !== '' &&
      ((e.previousValue !== undefined && e.value !== e.previousValue) || e.previousValue === undefined && e.value !== '')) {
      const paymentMethod = this.paymentMethods.filter(x => x.Id === this.payment.PaymentMethodId)[0];
      if (!!paymentMethod) {
        this.paymentMethodIsCreditCard = paymentMethod.PaymentType === 'Credit Card' ||
          paymentMethod.Text === 'Credit Card' || paymentMethod.Text === 'ACH';
        this.showCreditCardEntry = (!this.paymentProcessed &&
          this.paymentMethodIsCreditCard &&
          this.payment !== null &&
          (this.payment.CreditCardTransactionHistoryId == null ||
            this.payment.CreditCardTransactionHistoryId <= 0));
      }
      this.loadOtherActions();
      this.toggleButtons(false);
      if (this.firstChanges.paymentMethod) {
        this.isPaymentDirty = true;
      }
      this.firstChanges.paymentMethod = true;
    }
  }

  setMaximumAmount(detail: PaymentDetail) {
    this.maximumAmount += +detail.Balance;
    // If editing a payment we need to add payment amount to get the true maximum amount
    if (!!this.dynamicData && AppConstants.PAYMENT_ID_KEY in this.dynamicData) {
      // only add amount if the balance is not over paid
      this.maximumAmount += +detail.Amount;
    }
  }

  getExistingPayment(defendantId: string, paymentId: string) {
    this.subs.sink = this.payService.getForPaymentUI(defendantId, paymentId)
      .subscribe(payment => {
        this.getPayment(payment, false, false, true);
      });
  }

  calcAmounts() {
    console.log('calcAmounts this.payment',this.payment);
    console.log('calcAmounts this.payment.details',this.payment.details);
    if (this.payment && this.payment.details.length > 0) {
      this.maximumAmount = 0;
      this.removeDuplicatePayments();
      if (this.paymentType === 'Refund' && !!this.transactionId && !this.sourceId) {
        const payments = this.accountingDetails.filter(detail => {
          return !!detail.PaymentId && detail.Amount > 0 && (detail.TransactionId === this.transactionId || !detail.TransactionId);
        });
        if (payments.length === 0) {
          this.notifyService.toast('No payments available for refund', 'error');
        }
      } else {
        this.payment.details.forEach((detail) => {
          // Load total amount for existing payments
          if (this.payment.Id) {
            this.totalAmount += parseFloat(detail.Amount.toString());
            console.log('this.detail',detail);
            console.log('this.totalAmount',this.totalAmount);
          }
          if (!this.showDefendantSearch) {
            // We only care about the current transaction
            if ((!!this.dynamicData && this.dynamicData[AppConstants.TRANSACTION_ID_KEY] === detail.TransactionId) || detail.TransactionId === this.transactionId) {
              // If editing a payment we need to add payment amount to get the true maximum amount
              this.setMaximumAmount(detail);
            }
          } else {
            if (this.transactionId) {
              if (this.transactionId === detail.TransactionId) {
                // If it is a refund we don't care about the balance.
                if (this.paymentType === 'Refund' && this.showDefendantSearch) {
                  this.maximumAmount += +detail.Amount;
                  this.totalAmount += +detail.Amount;
                } else if (this.paymentType === 'Refund') {
                  this.subs.sink = this.arSummary.dataLoaded.subscribe((summary: TransactionARSummary) => {
                    this.maximumAmount = +summary.TotalPayments;
                  });
                } else {
                  this.maximumAmount += +detail.Balance;
                }
              }
            } else {
              this.maximumAmount += +detail.Balance;
            }
          }
        });
      }

      if (this.dynamicData && 'DownPaymentAmount' in this.dynamicData && this.dynamicData.DownPaymentAmount > 0) {
        this.totalAmount = this.dynamicData.DownPaymentAmount;
        console.log('this.totalAmount 2',this.totalAmount);
      }
    }
  }

  loadData(preserveLastPaymentData = false) {
    this.subs.sink = this.loadPayment(preserveLastPaymentData).subscribe(resp => {
      this.loadDefTransactions();
      this.loadPaymentMethods();
      if (!preserveLastPaymentData) {
        // this.loadAgencies();
        this.loadPeople();
      }
      // if the defendant search box is not shown, then set focus on the amount field
      if (!this.showDefendantSearch) {
        this.txAmount.instance.focus();
      } else {
        if (!!this.defendantName) {
          this.txAmount.instance.focus();
          this.componentLoading = false;
        }
      }
    });
  }

  loadDefTransactions() {
    const id = this.parentId;
    this.transactions = [];
    this.accountingDetails = [];
    if (this.dynamicData && this.dynamicData[AppConstants.TRANSACTION_ID_KEY] && this.dynamicData[AppConstants.TRANSACTIONS]) {
      this.transactions.push(this.dynamicData[AppConstants.TRANSACTIONS]);
      this.accountingDetails = this.dynamicData[AppConstants.ACCOUNTING_DETAILS];
      this.loadOtherActions();
      this.subs.sink = this.calculateTransactionBalance().subscribe(count => {
        if (count === this.transactions.length) {
          this.determineTransactionAction();
          this.enableActionButtons();
        }
      });
    } else {
      this.subs.sink = this.defService.getFullDefendantById(id, null, false, 'transactions,transactions.bonds,accountingdetails')
        .pipe(take(1))
        .subscribe(def => {
          const defendant = new Defendant();
          defendant.loadFromServer(def.data);
          this.transactions = defendant.transactions;
          this.loadOtherActions();
          this.accountingDetails = def.data.accountingdetails.data;
          if (this.dynamicData && this.dynamicData[AppConstants.TRANSACTION_ID_KEY]) {
            this.transactionId = this.dynamicData[AppConstants.TRANSACTION_ID_KEY];
          }
          this.subs.sink = this.calculateTransactionBalance().subscribe(count => {
            if (count === this.transactions.length) {
              this.determineTransactionAction();
              this.enableActionButtons();
            }
          });
        });
    }
  }

  calculateTransactionBalance() {
    return new Observable<number>(obs => {
      let count = 0;
      this.totalPaidTransactions = 0;
      for (let i = 0; i < this.transactions.length; i++) {
        // this.transactions[i].isSelected = false;
        const balance = this.balanceLeft = this.getTransactionBalance(this.accountingDetails, this.transactions[i].Id);
        if (this.paymentType === 'Refund') {
          const hasPayments = this.accountingDetails.filter(detail => detail.TransactionId === this.transactions[i].Id &&
            !!detail.PaymentId && detail.Amount > 0 && detail.Description === 'Payment');
          if (hasPayments.length > 0) {
            this.transactions[i].show = true;
            this.nonZeroTransactionId = this.transactions[i].Id;
            this.totalPaidTransactions++;
          } else {
            this.transactions[i].show = false;
          }
        } else if(this.paymentType === 'Payment') { // adjustments and payments
          if (balance > 0) {
            this.transactions[i].show = true;
            this.nonZeroTransactionId = this.transactions[i].Id;
            this.totalPaidTransactions++;
          } else {
            this.transactions[i].show = false;
          }
        } else {
          this.transactions[i].show = true;
          if(balance > 0) {
            this.nonZeroTransactionId = this.transactions[i].Id;
            this.totalPaidTransactions++;
          }
        }

        let bondString: string;
        if (this.transactions[i].bonds.length === 1) {
          bondString = '1 Bond';
        } else {
          bondString = this.transactions[i].bonds.length + ' Bonds';
        }
        const postedDate = moment(this.transactions[i].PostedDate).format('MM/DD/YYYY');
        const hasPowers = this.transactions[i].bonds.some(bond => bond?.power?.PowerNumber);

        if(hasPowers){
          this.transactions[i].buttonLabel = postedDate + ' - Balance $' + balance + (this.transactions[i]?.bonds?.map(bond => bond?.power?.PowerNumber).join(', ') ? ' - ' + this.transactions[i]?.bonds?.map(bond => bond?.power?.PowerNumber).join(', ') : '');
        }else{
          this.transactions[i].buttonLabel = postedDate + ' - '
            + bondString + ' - Balance $' + balance;
        }

        count++;
        obs.next(count);
        if (count === this.transactions.length) {
          obs.complete();
        }

      }
    });
  }

  enableActionButtons() {
    this.saveButtonDisabled = false;
    this.componentLoading = false;
    this.toggleButtons(false);
  }

  getTransactionBalance(details: DefendantAccountingDetails[], transactionId: string): number {
    return details.reduce(
      (balance, detail) => {
        if (detail.TransactionId === transactionId) {
          if (!detail.PaymentId) {
            return balance + detail.Amount;
          } else {
            return balance - detail.Amount;
          }
        }
        return balance;
      },
      0);
  }

  determineTransactionAction() {
    if (this.paymentType === 'Refund' || this.paymentType === 'Adjustment') {
      if (this.totalPaidTransactions === 1) {
        this.transactionId = this.nonZeroTransactionId;
      } else {
        if (!this.sourceId) {
          if (this.transactions.length > 1) {
            this.openTransactionSelectPopup();
          } else {
            // 11/3/25 - We want to be careful here, for now we will only set the default transaction id for adjustment and not refunds
            if(this.paymentType === 'Refund') {
              this.setTransactionId({Id: this.nonZeroTransactionId});
            } else {
              this.setTransactionId({Id: this.nonZeroTransactionId || this.transactions[0].Id});
            }
          }
        }
      }
    } else {
      if (this.dynamicData && this.dynamicData[AppConstants.TRANSACTION_ID_KEY]) {
        this.transactionId = this.dynamicData[AppConstants.TRANSACTION_ID_KEY];
      } else {
        if (!!this.transactionId) {
         this.setTransactionId({Id: this.transactionId});
        } else {
          if (this.totalPaidTransactions >= 2 && this.balanceLeft > 0) {
            this.openTransactionSelectPopup();
          } else {
            this.setTransactionId({ Id: (this.nonZeroTransactionId || this.transactions[0].Id) });
          }
        }
      }
      this.setPowers();
    }
  }

  loadRelatedHistory() {
    if (this.payment.CreditCardTransactionHistoryId != null && this.payment.CreditCardTransactionHistoryId !== '') {
      this.subs.sink = this.ccHistoryService.getById(this.payment.CreditCardTransactionHistoryId).subscribe(history => {
        if (history.AuthCode != null && history.AuthCode !== '') {
          this.ccApprovalCode = history.Status === 'Success' ? history.AuthCode : history.Status;
          this.paymentType === 'Refund' ? this.paymentProcessed = false : this.paymentProcessed = true;
          if (this.gateways && this.gateways.length > 0) {
            this.gatewayDetails = this.gateways.find((gateway: GatewayDetails) => gateway.Id === history.ClientCreditCardGatewayId);
          }
          // this.saveButtonDisabled = true;
        }
      }, error => {
        this.errorData = error;
      });
    }
  }

  loadPeople(personId?) {
    this.isLoadingPeopleList = true;
    this.subs.sink = this.defService.getAllRelatedPersons(this.parentId, this.transactionId).subscribe((sc: PersonRelated[]) => {
      this.people = sc;
      // this.ddlPeople.instance.option('dataSource', this.people);
      if (this.people.length === 1) {
        this.payment.ReceivedFromPersonId = this.people[0].PersonId;
      }
      if (!!personId) {
        this.payment.ReceivedFromPersonId = personId;
        if (this.ddlPeople) {
          this.ddlPeople.instance.option('value', personId);
        }
      }
      this.people.push({ PersonId: 'add_new', FullName: 'ADD NEW' });
      this.isLoadingPeopleList = false;
    }, error => {
      this.isLoadingPeopleList = false;
      this.errorData = error;
    });
  }

  getPayment(data: any, afterCreating?: any, preserveLastPaymentData?: boolean, refund = false) {
    if (refund && this.showDefendantSearch) {
      this.payment.Id = '';
      this.paymentProcessed = false;
      const details = data.details.data;
      if (details && details.length > 0) {
        this.payment.details = new Array<PaymentDetail>();
        details.forEach((detail) => {
          this.payment.details.push(detail);
        });
      }
      this.payment.PaymentMethodId = data.PaymentMethodId;
      this.payment.ReceiveAtAgencyId = data.ReceiveAtAgencyId;
      this.payment.ReceivedByString = data.ReceivedByString;
      this.payment.ReceivedFromPersonId = data.ReceivedFromPersonId;
      this.payment.CreditCardTransactionHistoryId = data.CreditCardTransactionHistoryId;
      this.loadRelatedHistory();
    } else {
      const payment = data;
      const details = data.details.data;
      delete payment.details;
      this.payment = new Payment(payment);
      this.payment.PaymentType = this.paymentType;
      if (preserveLastPaymentData && this.lastPaymentDetails) {
        this.payment.ReceiveAtAgencyId = this.lastPaymentDetails.ReceiveAtAgencyId;
        this.payment.Date = this.lastPaymentDetails.Date;
        this.payment.ReceivedByUserId = this.lastPaymentDetails.ReceivedByUserId;
        this.payment.ReceivedByString = this.lastPaymentDetails.ReceivedByString;
        this.payment.ReceivedFromPersonId = this.lastPaymentDetails.ReceivedFromPersonId;
        this.payment.PaymentMethodId = this.lastPaymentDetails.PaymentMethodId;
      }
      this.paymentProcessed = !isNullUndefinedOrEmpty(this.payment.CreditCardTransactionHistoryId);

      if (!this.payment.ReceivedByUserId && !!this.payment.ReceivedByString) {
        // This means the user typed a custom value when this payment was saved.
        // Need to insert this custom value into the dropdown with a faked id.
        this.receivedByUserLookup.addItemToList(this.payment.ReceivedByString);
      }
      if (this.dynamicData && 'DownPaymentPostedDate' in this.dynamicData) {
        this.payment.Date = this.dynamicData.DownPaymentPostedDate;
      }
      if (this.payment.Date === null || this.payment.Date === undefined) {
        this.payment.Date = StringUtilities.getCurrentDateString();
      }

      this.payment.ReceiptNumber = data.ReceiptNumber;


      this.creating = (this.payment.Id == null || this.payment.Id === '');

      if (!!this.payment.Id) {
        this.sourceId = this.payment.Id;
      }

      if (details && details.length > 0) {
        const newThis = this;
        newThis.payment.details = new Array<PaymentDetail>();
        details.forEach(function(psd) {
          newThis.payment.details.push(psd);
        });

      }
    }
    if(!preserveLastPaymentData){
      this.calcAmounts();
    }

    if (!this.creating) {
      this.loadRelatedHistory();
    }

    if (this.payment.ReceiveAtAgencyId == null && this.agencies != null && this.agencies.length === 1) {
      this.payment.ReceiveAtAgencyId = this.agencies[0].Id;
    }
    if (this.payment.ReceiptNumber !== '' && afterCreating) {

      // this.loadData();
      const pmtDetails = this.payment.details;
      this.valGroup.instance.reset();
      setTimeout(() => {
        this.payment = new Payment();
        this.payment.details = pmtDetails;
        this.payment.Date = StringUtilities.getCurrentDateString();
        this.payment.PaymentType = this.paymentType;
        this.checkForDefaultPaymentMethod();
        // this.totalAmount = 0;
        // this.txAmount.instance.option('value', '0');
        this.txAmount.instance.option('isValid', true);
        this.creating = true;
      }, 1000);

    }

  }

  checkForDefaultPaymentMethod() {
    if (isNullUndefinedOrEmpty(this.payment.PaymentMethodId) && !isNullUndefinedOrEmpty(this.paymentMethods)) {
      this.payment.PaymentMethodId = this.paymentMethods[0].Id;
      // Todo look into this code? Does paymentMethods get returned in a specific order
      this.paymentMethodIsCreditCard =
        ((this.paymentMethods[0].PaymentType === 'Credit Card') ||
          (this.paymentMethods[0].Text === 'Credit Card'));
      this.loadOtherActions();
    }
  }

  loadPayment(preserveLastPaymentData = false): Observable<any> {
    return new Observable<any>(obs => {
      this.subs.sink = this.payService.getForPaymentUI(this.parentId, this.sourceId).subscribe(payment => {
        obs.next();
        obs.complete();
        this.getPayment(payment, false, preserveLastPaymentData);
        this.checkForDefaultPaymentMethod();
        if (this.txAmount && this.txAmount.instance) {
          setTimeout(() => {
            this.txAmount.instance.option('isValid', true);
            this.validationSummary.instance.option('items', []);
          }, 100);
        }
      }, error => {
        obs.error();
        obs.complete();
        this.errorData = error;
        this.buttonBar.cancel();
      });
    });
  }

  loadPaymentMethods() {
    this.isLoadingPmtList = true;
    this.subs.sink = this.lookupService.getLookup('paymentmethods', [this.payment.PaymentMethodId]).subscribe(sc => {
        this.paymentMethods = sc.data;
        this.checkForDefaultPaymentMethod();
        this.isLoadingPmtList = false;
      }
      , error => {
        this.isLoadingPmtList = false;
        this.errorData = error;
      });
  }

  preSave() {
    // this.payment.checkForChanges();
  }

  postSave(afterSave = null) {
    this.popToast('Saved', 'success');
    // this.saveButtonDisabled = false;
    if (this.dynamicData != null && afterSave === null) {
      this.paymentGatewayUrl = '';
      this.eventService.emitSetDefForAcctRefresh(this.parentId, this.transactionId);
      this.onClose.next(true);
    } else {
      if (afterSave != null) {
        if (this.paymentType === 'Payment') {
          this.arSummary.mySummary.TotalBalance -= this.totalAmount;
          this.arSummary.mySummary.TotalReceived += this.totalAmount;
        } else if (this.paymentType === 'Refund') {
          this.arSummary.mySummary.TotalBalance = parseFloat(this.arSummary.mySummary.TotalBalance.toString()) + this.totalAmount;
          this.arSummary.mySummary.TotalReceived -= this.totalAmount;
        } else {
          // write-off (adjustment)
          this.arSummary.mySummary.TotalBalance = parseFloat(this.arSummary.mySummary.TotalBalance.toString()) - this.totalAmount;
        }
        this.afterSaveAction = null;
        afterSave();
      } else {
        this.buttonBar.navigate();
        if (this.payment.CreditCardTransactionHistoryId != null && this.payment.CreditCardTransactionHistoryId !== '') {
          this.loadRelatedHistory();
        }
      }
    }
  }

  popToast(msg, type) {
    this.notifyService.toast(msg, type);
  }

  doSave(afterSave: any = null) {
    this.popToast('Saving ...', 'success');
    this.payment.PaymentType = this.paymentType;
    // if the receivedByUserId is a guid, it's not a real user, blank this out.
    if (!!this.payment.ReceivedByUserId && this.payment.ReceivedByUserId.length > 20) {
      this.payment.ReceivedByUserId = null;
    }
    let preservePreviousPayment = false;
    if (!afterSave || (!!afterSave && afterSave.name.includes('addNewPaymentAfterSave'))) {
      preservePreviousPayment = true;
    }
    if (!this.creating) {
      if (!!this.transactionId && !this.payment.TransactionId) {
        this.payment.TransactionId = this.transactionId;
      }
      this.subs.sink = this.payService.save(this.payment.Id, this.payment).subscribe(sc => {
        this.getPayment(sc.data, false, preservePreviousPayment);
        this.postSave(afterSave);
      }, error => {
        this.errorData = error;
        this.saveButtonDisabled = false;
      });
    } else {
      if (!!this.transactionId && !this.payment.TransactionId) {
        this.payment.TransactionId = this.transactionId;
      }
      this.subs.sink = this.payService.create(this.payment).subscribe(sc => {
        if (this.paymentType === 'Payment') {
          this.getPayment(sc.data, false, preservePreviousPayment);
        }
        this.postSave(afterSave);
      }, error => {
        this.errorData = error;
        this.saveButtonDisabled = false;
        this.inputDisabled = true;
      });

    }
  }

  onCCApproved(val: CreditCardResults) {
    this.payment.CreditCardTransactionHistoryId = val.Id;
    this.popToast('Credit card approved: ' + val.AuthCode, 'success');
    this.doSave();
    this.showCreditCardEntry = false;
  }

  onCCDeclined(val) {
    this.errorData = [{ status_code: -1, detail: 'Payment declined: ' + val }];
    this.saveButtonDisabled = false;
  }

  onCCError(val) {
    this.errorData = val;
    this.saveButtonDisabled = false;
  }

  /**
   * This method validates the payment amount field. It returns true if the
   * amount is valid and false if the amount is greater than the current
   * transactions balance. This happens since we currently have no way of
   * assigning amounts to multiple transactions at once. If there is no balance
   * then we allow whatever amount they enter.
   * @param value: The value being passed from dxNumberBox
   * @param rule: The rule being checked
   */
  validateAmount({ value, rule }): boolean {
    if (+(this.maximumAmount.toFixed(2)) > 0 && value > +(this.maximumAmount.toFixed(2)) && this.paymentType !== 'Payment') {
      rule.message = `Amount cannot exceed balance of $${this.maximumAmount.toFixed(2)}`;
      return false;
    }

    return true;
  }

  validateAmounts() {
    try {
      const total = this.payment.details.reduce((acc: number, { Amount }) => acc + Amount, 0);
      if (this.totalAmount !== total) {
        throw new Error(`Payment amount doesn't match aggregate of payment details`);
      }
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('Payment component', 'details');
        scope.setExtra('payment', this.payment);
        scope.setExtra('defendant', this.debugDefendant);
        scope.setExtra('currentUser', JSON.parse(localStorage.getItem('currentUser') || '{}'));
        Sentry.captureException(error);
      });
    }
  }

  /**
   * Calculates the payment details for the given transaction.
   * @param amount: The amount of the payment.
   * @param details: The payment details for the defendant. (May include details for different transactions)
   * @param transactionId: The transaction Id of the current transaction.
   * @param sourceId: This is an optional parameter that represents the current PaymentId. This signifies that we are editing an existing
   * transaction.
   * @return: The updated array of Payment Details.
   */
  calculatePaymentDetails(
    amount: number,
    details: PaymentDetail[],
    transactionId: string,
    sourceId: string,
  ): PaymentDetail[] {
    details.sort((a, b) => Number(a.Balance) - Number(b.Balance));
    for (let i = details.length - 1; i >= 0; i--) {
      if (details[i].TransactionId === transactionId) {
        // If we are working with existing Payment we need to reset the Balance before we calculate
        if (!!sourceId && !this.creating) {
          details[i].Balance = Number(details[i].Amount) + Number(details[i].Balance);
        }
        amount = this.setAmount(details[i], amount);
      }
    }
    // For extra amount, this allows user to overpay the first time.
    if(amount > 0 && details.length > 0) {
      // Get the last detail
      let currentDetails = details.filter(detail => detail.TransactionId === transactionId);
      if(currentDetails.length > 0) {
        let lastDetail = currentDetails[currentDetails.length -1];
        if(lastDetail.Amount === +lastDetail.Balance) {
          lastDetail.Amount = lastDetail.Amount + amount;
        }
      }
    }

    return details;
  }

  /**
   * The removeDuplicatePayments method is used to remove records from the payment details if they have duplicate BondId. The BondId for
   * PaymentDetails should be unique.
   */
  removeDuplicatePayments() {
    if (!!this.dynamicData && AppConstants.PAYMENT_ID_KEY in this.dynamicData) {
      this.payment.details = this.payment.details.reduce((accumulator, current) => {
        const bondId = current.BondId;
        const found = accumulator.find(detail => detail.BondId === bondId);
        if (found) {
          found.Amount = Number(found.Amount) + Number(current.Amount);
          if (this.dynamicData.PaymentDetailId === current.Id) {
            found.Id = current.Id;
          }
        } else {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
    }
  }

  onSave(e, valGroup, afterSave: any = null) {
    if (valGroup.instance.validate().isValid) {
      this.saveButtonDisabled = true;
      this.inputDisabled = true;
      if (!!afterSave && afterSave.name.includes('addNewPaymentAfterSave')) {
        this.lastPaymentDetails = JSON.parse(JSON.stringify(this.payment));
      }
      if (this.dynamicData == null && !afterSave && (this.paymentType === 'Refund' || this.paymentType === 'Adjustment')) {
        afterSave = this.resetForm;
      }
      this.toggleButtons(true);
      if (this.transactionId) {
        // We need to check if dynamicData is undefined in case we are coming from write off
        if (!this.dynamicData) {
          this.dynamicData = {};
          this.dynamicData.noDynamicData = true;
        }
        this.calculatePaymentDetails(
          this.totalAmount,
          this.payment.details,
          this.dynamicData[AppConstants.TRANSACTION_ID_KEY] || this.transactionId,
          this.sourceId,
        );
        this.validateAmounts();


        if (this.paymentType !== 'Refund' && this.paymentMethodIsCreditCard && !this.paymentProcessed && !this.payment.Id && !!this.gatewayDetails) {
          this.doCCPayment();
        } else {
          this.doSave(afterSave);
        }
      } else {
        this.inputDisabled = false;
        this.saveButtonDisabled = false;
        if (this.transactions.length > 1) {
          this.openTransactionSelectPopup();
        } else {
          this.setTransactionId({ Id: (this.nonZeroTransactionId || this.transactions[0].Id) });
          this.doSave(afterSave);
        }
      }
    }
  }

  setTransactionId(e) {
    this.transactionId = e.Id;
    this.invoiceSelectionPopup = false;
    this.payment.TransactionId = e.Id;
    this.calcAmounts();
  }

  setPowers(){
    const filteredTransaction = this.transactions.find(transaction => transaction.Id === this.transactionId);
    const powerNumbers = filteredTransaction?.bonds?.map((bond) => bond?.power?.PowerNumber).filter(Boolean).join(', ') || '';
    if (powerNumbers !== '') {
      this.showPowers = true;
      this.powers = powerNumbers;
    }
  }

  savePayment(e) {

    this.transactionId = e.Id;

    if (!!this.sourceId) {

      const newThis = this;
      let leftOver = newThis.totalAmount;
      this.payment.details.forEach(function(detail) {

        if (detail.TransactionId === e.Id) {
          leftOver = newThis.setAmount(detail, leftOver);
        } else {
          detail.Amount = 0;
        }


      });

      newThis.totalAmount = 0;
      this.payment.details.forEach(function(detail) {
        newThis.totalAmount += detail.Amount;
        newThis.maximumAmount += +detail.Balance;
      });
      this.invoiceSelectionPopup = false;

    } else {

      const newThis = this;
      let leftOver = newThis.totalAmount;
      this.payment.details.forEach(function(detail) {

        if (detail.TransactionId === e.Id) {
          leftOver = newThis.setAmount(detail, leftOver);
        }

      });
      if (leftOver > 0) {
        newThis.totalAmount = +(newThis.totalAmount - leftOver).toFixed(2);
      }

      // this.preSave();
      this.saveButtonDisabled = true;

      if (this.paymentMethodIsCreditCard &&
        !this.paymentProcessed && !this.payment.Id && !!this.gatewayDetails) {
        this.doCCPayment();
      } else {
        this.doSave(this.afterSaveAction);
      }
      this.invoiceSelectionPopup = false;
    }

  }


  onDelete() {
    if (this.payment.Id && this.payment.Id !== '') {
      if (!isNullUndefinedOrEmpty(this.payment.CreditCardTransactionHistoryId)) {
        this.showYesNoConfirmation = true;
      } else {
        this.reversePayment();
      }
    } else {
      this.buttonBar.cancel();
    }
  }

  reversePayment() {
    const reversing = (this.paymentProcessed) ? ' Credit card transaction will be reversed.' : '';
    this.popToast('Deleting ...' + reversing, 'success');
    this.subs.sink = this.payService.delete(this.payment.Id).subscribe(sc => {
      this.onClose.next(true);
      this.saveButtonDisabled = true;
      this.popToast('Deleted', 'success');
    }, error => {
      this.errorData = error;
    });
  }

  /**
   * The setAmount method applies a payment amount to a given payment detail and
   * returns the left over amount.
   * @param detail: The PaymentDetail to apply the payment against.
   * @param amount: The amount of money to apply towards the PaymentDetail.
   * @return the left over amount after the payment is applied. This is usually
   * 0 except when the Balance on the detail is more than the amount.
   */
  setAmount(detail: PaymentDetail, amount: number): number {
    let leftOver = 0;
    if (amount === 0) {
      detail.Amount = 0;
    } else if (detail.Balance > amount) {
      detail.Amount = +amount.toFixed(2);
    } else if (detail.Balance <= 0) {
      detail.Amount = +amount.toFixed(2);
    } else {
      detail.Amount = +detail.Balance;
      leftOver = amount - detail.Balance;
    }
    return leftOver;
  }

  getDataForLoad() {
    if (!this.dynamicData || !(AppConstants.DEFENDANT_ID_KEY in this.dynamicData)) {
      this.showDefendantSearch = true;
    } else {
      if (!!this.dynamicData) {
        this.selectedDefendantName = this.dynamicData.DefendantName;
        if (this.dynamicData.showDefendantSearch) {
          this.showDefendantSearch = this.dynamicData.showDefendantSearch;
        }

        this.computedTopMargin = HEADER_TOP_MARGIN_IN_POPUP;
        this.routeKey = '';
        this.afterErrorRoute = '';
        this.parentId = this.dynamicData[AppConstants.DEFENDANT_ID_KEY];
        this.defendantName = this.dynamicData[AppConstants.DEFENDANT_NAME_KEY];
        this.totalAmount = this.dynamicData.DownPaymentAmount;
        if (this.dynamicData[AppConstants.PAYMENT_ID_KEY] != null) {
          this.sourceId = this.dynamicData[AppConstants.PAYMENT_ID_KEY];
        }
        if (this.parentId == null) {
          this.showDefendantSearch = true;
//        this.errorData = [{status_code: -1, detail: 'No defendant selected. Select a defendant first.'}]
        } else {
          this.loadData();
        }
      } else {
        this.checkRouteForId();
      }

      // this.loadDefTransactions();
    }
    this.loadOtherActions();

  }

  onDefendantSelected(e: any) {
    if (!e.DefendantId) {
      return;
    }
    this.componentLoading = true;
    this.debugDefendant = e;
    this.parentId = e.DefendantId;
    this.sourceId = '';
    this.defendantName = e.DefendantName;
    this.selectedDefendantName = e.DefendantName;
    this.showDefendantSearch = false;
    this.transactionId = e.TransactionId;
    this.loadData();
  }

  checkRouteForId() {
    this.subs.sink = this.route.paramMap.subscribe(params => {
      this.sourceId = params.get('id');
      this.parentId = sessionStorage.getItem(AppConstants.DEFENDANT_ID_KEY);
      this.defendantName = sessionStorage.getItem(AppConstants.DEFENDANT_NAME_KEY);

      if (this.parentId != null) {
        this.loadData();
        if (this.payment == null) {
          this.payment = new Payment();
          this.payment.PaymentType = this.paymentType;
        }
      } else {
        this.showDefendantSearch = true;
      }
    });
  }

  cancel() {
    if (!!this.dynamicData && !this.dynamicData.noDynamicData) {
      this.onClose.next(false);
    } else {
      this.buttonBar.routeForCancel();
    }
  }


  print() {
    const fieldValue = new FieldValues();
    fieldValue.field = 'GLTransactionID';
    fieldValue.hashedValues = [this.payment.Id];
    const transactionIdValue = new FieldValues();
    transactionIdValue.field = 'TransactionID';
    transactionIdValue.hashedValues = [this.transactionId];

    const reportCategory = this.paymentType + ' Receipt';
    const objectTypeName = this.paymentType.toLowerCase() + ' receipt';
    const objectName = reportCategory + ' ' + this.defendantName;

    this.dynamicPopupData = [];
    this.dynamicPopupData.reportCategory = reportCategory;
    this.dynamicPopupData.reportFieldValues = [fieldValue];
    if (this.paymentType.toLowerCase() === 'payment') {
      this.dynamicPopupData.reportFieldValues.push(transactionIdValue);
    }
    this.dynamicPopupData.esignable = false;
    this.dynamicPopupData.objectTypeName = objectTypeName;
    this.dynamicPopupData.sourceType = 'Defendants';
    this.dynamicPopupData.sourceId = this.parentId;
    this.dynamicPopupData.objectName = objectName;
    this.dynamicPopupClass = PdfViewerComponent;
    this.printing = true;
  }

  printReceipt() {
    if(this.creating) {
      this.onSave(null, this.valGroup, this.print);
      this.isPrint = true;
    } else {
      if (this.isPaymentDirty) {
        this.onSave(null, this.valGroup, this.print);
        this.isPrint = true;
      } else {
        this.print();
        this.isPrint = true;
      }
    }
  }

  onPrint() {
    this.subs.sink = this.userService.current().subscribe((res) => {
      if (res.data.LimitedVisibility) {
        const editPaymentPermission = res.data.permissions.data.find((permission: any) => permission.name === 'edit payments');
        if (!editPaymentPermission) {
          this.print();
          this.isPrint = true;
        } else {
          this.printReceipt();
        }
      } else {
        this.printReceipt();
      }
    });
  }

  loadOtherActions() {
    if (this.otherActions.length > 0) {
      this.otherActions.length = 0;
    }
    if (!isNullUndefinedOrEmpty(this.sourceId)) {
      this.otherActions.push({ text: 'Delete', icon: 'fal fa-trash-alt fa-2x', value: this.onDelete, disabled: false });
    }
    if (this.paymentType === 'Payment') {
      if (this.paymentMethodIsCreditCard && !this.paymentProcessed && !this.payment.Id && !!this.gatewayDetails) {
        this.saveButtonText = 'Enter Details';
        this.saveButtonWidth = 266;
        this.saveButtonDivWidth = '170px';
      } else {
        let saveAndPrintText = this.sourceId ? 'Print' : 'Save & Print';
        this.otherActions.push({ text: saveAndPrintText, icon: 'fal fa-print fa-2x', value: this.onPrint, disabled: false });
        this.otherActions.push({ text: 'Save and Take Another', value: this.saveAndNewPayment, disabled: false });
        this.saveButtonWidth = 136;
        this.saveButtonText = 'Save';
        this.saveButtonDivWidth = '150px';
      }
    }
    if (this.paymentType === 'Refund' && this.showDefendantSearch) {
      this.otherActions.push({ text: 'Save and Refund Another', value: this.saveAndNewPayment, disabled: true });
    }
    if (!!this.sourceId && this.transactions.length > 1) {
      this.otherActions.push({ text: 'Allocate', value: this.openTransactionSelectPopup });
    }
    // if ((this.dynamicData &&
    //         (AppConstants.DEFENDANT_ID_KEY in this.dynamicData) &&
    //         isNullUndefinedOrEmpty(this.dynamicData.BondId)) && !this.paymentProcessed) {
    //   this.otherActions.push({text: 'Allocate Payment',  value: this.allocatePaymentAction});
    // }
  }

  ngOnDestroy() {

    if (this.navChanged) {
      this.navChanged.unsubscribe();
    }
  }

  toggleButtons(state: boolean): void {
    this.toggleSaveAndPrintButton(state);
    this.toggleDeletePaymentButton(state);
    this.toggleSaveAndAddAnotherButton(state);
  }

  toggleSaveAndPrintButton(state: boolean) {
    const savePrintButtonIndex = this.otherActions.findIndex((action) => {
      return action?.text.includes('Print');
    });

    if (savePrintButtonIndex > -1) {
      this.otherActions[savePrintButtonIndex].disabled = (state != null ? state : !this.otherActions[savePrintButtonIndex].disabled);
    }
  }

  toggleDeletePaymentButton(state: boolean): void {
    const deleteButtonIndex = this.otherActions.findIndex((action) => {
      return action?.text === 'Delete';
    });

    if (deleteButtonIndex > -1) {
      this.otherActions[deleteButtonIndex].disabled = state;
    }
  }

  toggleSaveAndAddAnotherButton(state: boolean) {
    const saveAndAddAnotherButtonIndex = this.otherActions.findIndex((action) => {
      return action?.text === 'Save and Take Another';
    });

    if (saveAndAddAnotherButtonIndex > -1) {
      const disabled = this.otherActions[saveAndAddAnotherButtonIndex].disabled;
      this.otherActions[saveAndAddAnotherButtonIndex].disabled = (state != null ? state : !disabled);
    }

  }

  loadGatewayDetails() {
    this.subs.sink = this.creditCardGatewayService.getClientGateways().subscribe((result: GatewayDetails[]) => {
      if (result.length > 0) {
        this.gateways = result;
        if (result.length === 1) {
          this.gatewayDetails = result[0];
        }
      } else {
        this.showActivateProcessing = true;
      }
    });
  }

  paymentGatewayChanged({ value }) {
    this.gatewayDetails = value;
    switch (this.gatewayDetails?.Name) {
      case PAYMENTGATEWAY.ELAVON:
        this.subs.sink = this.scriptService.load('Elavon').subscribe((script: DynamicScript) => {
        }, error => {
          this.errorTitle = 'Error Contacting Elavon';
          this.errorData = error;
          this.errorVisible = true;
        });
        break;
    }
    this.loadOtherActions();
  }

  doCCPayment() {
    if (!!this.gatewayDetails) {
      const self = this;
      self.saveButtonDisabled = true;
      this.toggleButtons(true);

      const payer = this.people.find(person => person.PersonId === this.payment.ReceivedFromPersonId);
      this.payerInfo = payer;
      const transactionHistory = {
        Amount: this.totalAmount,
        TransactionDate: moment().format('YYYY-MM-DD'),
        ClientCreditCardGatewayId: this.gatewayDetails.Id,
        PaidByPersonId: payer.PersonId,
        TransactionType: 'Payment',
      };
      this.subs.sink = this.ccHistoryService.create(transactionHistory).subscribe(transactionHistoryResult => {
        this.transactionHistory = transactionHistoryResult.data;
        this.payment.CreditCardTransactionHistoryId = transactionHistoryResult.data.Id;
        let origin = window.location.origin;
        origin = origin.includes('localhost') ? 'https://dev.captira.com' : origin;
        switch (this.gatewayDetails.creditcardgateways.Name) {
          case PAYMENTGATEWAY.NUVEI:
            this.paymentGatewayData = {
              ORDERID: moment().format('X'),
              DATETIME: moment().format('DD-MM-YYYY:HH:MM:ss:SSS'),
              RECEIPTPAGEURL: `${origin}/payment-response`,
              VALIDATIONURL: environment.nuveiPaymentValidationUrl,
              AMOUNT: this.totalAmount.toFixed(2),
              CARDHOLDERNAME: payer.FullName,
              EMAIL: payer.Email,
              PHONE: payer.Phone,
              DEFENDANTID: this.parentId,
              TRANSACTIONID: !!this.dynamicData ?
                (!!this.dynamicData.TransactionId ? this.dynamicData.TransactionId : this.transactionId)
                : this.transactions[0].Id,
              PAYMENTMETHODID: this.payment.PaymentMethodId,
              CONTACTID: payer.PersonId,
              SECURECARDMERCHANTREF: `${payer.PersonId}_${moment().format('X')}`,
              TRANSACTIONHISTORYID: this.payment.CreditCardTransactionHistoryId,
              PAYMENTCOMMENT: this.payment.Comment,
              DEFENDANT: payer.FullName,
            };

            let hashString = `${this.gatewayDetails.GatewayToken}:`;
            hashString += `${this.paymentGatewayData.ORDERID}:`;
            hashString += `${this.paymentGatewayData.AMOUNT}:`;
            hashString += `${this.paymentGatewayData.DATETIME}:`;
            hashString += `${this.paymentGatewayData.RECEIPTPAGEURL}:`;
            hashString += `${this.paymentGatewayData.VALIDATIONURL}:`;
            hashString += `${this.gatewayDetails.GatewayKey}`;

            this.paymentGatewayData.HASH = sha512.sha512(hashString);
            self.paymentGatewayUrl = environment.nuveiPaymentsUrl;

            self.paymentGatewayUrl += `?TERMINALID=${this.gatewayDetails.GatewayToken}`;
            self.paymentGatewayUrl += `&ORDERID=${this.paymentGatewayData.ORDERID}`;
            self.paymentGatewayUrl += `&CURRENCY=USD&AMOUNT=${this.paymentGatewayData.AMOUNT}`;
            self.paymentGatewayUrl += `&DATETIME=${this.paymentGatewayData.DATETIME}`;
            self.paymentGatewayUrl += `&RECEIPTPAGEURL=${this.paymentGatewayData.RECEIPTPAGEURL}`;
            self.paymentGatewayUrl += `&VALIDATIONURL=${this.paymentGatewayData.VALIDATIONURL}`;
            self.paymentGatewayUrl += `&INIFRAME=Y&SECURECARDMERCHANTREF=${this.paymentGatewayData.SECURECARDMERCHANTREF}`;
            self.paymentGatewayUrl += `&CARDHOLDERNAME=${this.paymentGatewayData.CARDHOLDERNAME}`;
            self.paymentGatewayUrl += `&DEFENDANT=${this.paymentGatewayData.DEFENDANT}`;
            self.paymentGatewayUrl += `&DEFENDANTID=${this.paymentGatewayData.DEFENDANTID}`;
            self.paymentGatewayUrl += `&TRANSACTIONID=${this.paymentGatewayData.TRANSACTIONID}`;
            self.paymentGatewayUrl += `&CONTACTID=${this.paymentGatewayData.CONTACTID}`;
            self.paymentGatewayUrl += `&PAYMENTMETHODID=${this.paymentGatewayData.PAYMENTMETHODID}`;
            self.paymentGatewayUrl += `&TRANSACTIONHISTORYID=${this.paymentGatewayData.TRANSACTIONHISTORYID}`;
            self.paymentGatewayUrl += `&PAYMENTCOMMENT=${this.paymentGatewayData.PAYMENTCOMMENT}`;
            self.paymentGatewayUrl += `&HASH=${this.paymentGatewayData.HASH}`;
            self.paymentGatewayFrameVisible = true;
            break;
          case PAYMENTGATEWAY.PAYEEZY:
            this.showPayeezy = true;
            break;
          case PAYMENTGATEWAY.ELAVON:
            this.subs.sink = this.creditCardGatewayService.getElavonSessionToken(
              this.gatewayDetails.Id,
              this.payment.ReceivedFromPersonId,
              this.totalAmount,
              false,
            ).subscribe((token: string) => {
              this.openLightBox(token);
            });
            break;
          case PAYMENTGATEWAY.CLOVER:
            this.showClover = true;
            break;
          case PAYMENTGATEWAY.ASP:
            this.showASP = true;
        }
      }, error => {
        this.errorData = error;
        this.saveButtonDisabled = false;
        this.inputDisabled = false;
      });
    } else {
      this.popToast('Please activate the credit card processing.', false);
    }
  }

  openLightBox(token: string) {
    const paymentFields = {
      ssl_txn_auth_token: token,
    };
    const callback = {
      onError: (error) => {
        this.errorData = error;
        this.errorTitle = 'Error Connecting to Elavon';
        this.errorVisible = true;
        this.inputDisabled = false;
      },
      onCancelled: () => {
        this.eventService.componentBusy(false);
        this.inputDisabled = false;
      },
      onDeclined: (response) => {
        this.errorData = response;
        this.errorTitle = 'Payment Declined';
        this.errorVisible = true;
      },
      onApproval: (response: ElavonResponse) => {
        this.transactionHistory.Id = this.payment.CreditCardTransactionHistoryId;
        this.transactionHistory.AccountLastFour = response.ssl_card_number.substr(response.ssl_card_number.length - 4);
        this.transactionHistory.CreditCardTransactionId = response.ssl_txn_id;
        this.transactionHistory.Successful = true;
        this.transactionHistory.Reason = response.ssl_result_message;
        this.transactionHistory.AuthCode = response.ssl_approval_code;
        this.transactionHistory.TransactionData = response.ssl_approval_code;
        this.transactionHistory.Status = 'Success';
        this.paymentComplete(this.transactionHistory);
      },
    };
    PayWithConverge.open(paymentFields, callback);
  }

  onPersonChanged(e) {
    if (this.firstChanges.person) {
      this.isPaymentDirty = true;
    }
    this.firstChanges.paymentMethod = true;
    if (e.value === 'add_new') {
      this.dynamicPopupClass = DefendantPayerFormComponent;
      this.dynamicPopupData = [];
      this.dynamicPopupData.sourceType = 'DefendantPayerForm';
      this.dynamicPopupData.sourceId = this.parentId;
      this.dynamicPopupData.transactionId = this.dynamicData.TransactionId;
      this.dynamicPopupTitle = 'Payer';
      this.ddlPeople.instance.option('value', null);
      this.dynamicPopupShowTitle = true;
    }
  }

  onPaymentUrlChanged(frame: HTMLIFrameElement) {
    let path;
    try {
      path =  frame.contentWindow.location.pathname;
    } catch (ex) {
      // This is left blank on purpose because this error gets thrown when we access the pathname when the iframe is nuvei payment
      // and not the payment response.
    }
    if (path === '/payment-response') {
      this.paymentGatewayParams = new URLSearchParams(frame.contentWindow.location.search.split('?')[1]);
      if (this.paymentGatewayParams.get('RESPONSECODE') !== 'A' && this.paymentGatewayParams.get('RESPONSECODE') !== 'E') {
        this.paymentGatewayFrameVisible = false;
        this.paymentProcessed = false;
        this.saveButtonDisabled = false;
        this.errorData = [
          { message: `Payment failed due to ${this.paymentGatewayParams.get('RESPONSETEXT')}. Please process the payment again.` },
        ];
      } else {
        this.eventService.componentBusy(true);
        this.ccApprovalCode = this.paymentGatewayParams.get('UNIQUEREF');
        if (this.paymentGatewayParams.get('ISSTORED') === 'true') {
          const paymentMethodToken = {
            GatewayToken: this.paymentGatewayParams.get('SECURECARDMERCHANTREF'),
            PersonId: this.paymentGatewayParams.get('CONTACTID'),
            SourceId: this.paymentGatewayParams.get('DEFENDANTID'),
            SourceType: 'Defendant',
            CardExpiry: this.paymentGatewayParams.get('CARDTYPE').includes('ACH') ? '' : this.paymentGatewayParams.get('CARDEXPIRY'),
            CardNumber: this.paymentGatewayParams.get('CARDNUMBER'),
            CardReference: this.paymentGatewayParams.get('CARDREFERENCE'),
            CardType: this.paymentGatewayParams.get('CARDTYPE'),
            PaymentMethodType: this.paymentGatewayParams.get('CARDTYPE').includes('ACH') ? 'ACH' : 'Credit Card',
            AccountType: this.paymentGatewayParams.get('CARDTYPE').includes('ACH') ? this.paymentGatewayParams.get('ACCOUNT_TYPE') : '',
          };
          this.subs.sink = this.payService.savePaymentMethodToken(paymentMethodToken).subscribe(res => {
          }, err => {
            console.error('error saving paymentMethodToken:', err);
          });
        }
        const transactionHistory = {
          Id: this.payment.CreditCardTransactionHistoryId,
          AccountLastFour: this.paymentGatewayParams.get('CARDNUMBER').substr(this.paymentGatewayParams.get('CARDNUMBER').length - 4),
          CreditCardTransactionId: this.paymentGatewayParams.get('ORDERID'),
          Successful: true,
          Reason: this.paymentGatewayParams.get('RESPONSETEXT'),
          AuthCode: this.paymentGatewayParams.get('APPROVALCODE'),
          TransactionData: this.ccApprovalCode,
          Status: this.paymentGatewayParams.get('RESPONSECODE') === 'A' ? 'Success' : 'Pending',
        };
        this.subs.sink = this.ccHistoryService.update(transactionHistory.Id, transactionHistory).subscribe(transactionHistoryResult => {

          this.payment.PaymentMethodId = this.paymentGatewayParams.get('CARDTYPE').includes('ACH') ?
            this.paymentMethods.find(method => method.Text === 'ACH').Id :
            this.paymentMethods.find(method => method.Text === 'Credit Card').Id;

          this.subs.sink = this.payService.create(this.payment).subscribe(sc => {
            this.getPayment(sc.data, false);
            this.eventService.componentBusy(false);
            this.paymentGatewayFrameVisible = false;
            this.paymentProcessed = true;
            this.saveButtonDisabled = false;
            this.popToast('Payment Successful!', 'success');
            this.loadOtherActions();
            this.postSave();
          }, error => {
            this.errorData = error;
            this.saveButtonDisabled = false;
            this.eventService.componentBusy(false);
            this.paymentGatewayFrameVisible = false;
            this.paymentProcessed = true;
          });
        }, e => {
          console.error('error saving transaction history:', e);
        });
      }
    }
  }

  paymentComplete(event: TransactionHistory) {
    this.subs.sink = this.ccHistoryService.update(event.Id, event).subscribe(transactionHistoryResult => {
      this.payment.PaymentMethodId = this.paymentMethods.find(method => method.Text === 'Credit Card').Id;

      this.subs.sink = this.payService.create(this.payment).subscribe(sc => {
        this.getPayment(sc.data, false);
        this.eventService.componentBusy(false);
        this.showPayeezy = false;
        this.paymentProcessed = true;
        this.saveButtonDisabled = false;
        this.popToast('Payment Successful!', 'success');
        this.loadOtherActions();
        this.postSave();
      }, error => {
        this.errorData = error;
        this.saveButtonDisabled = false;
        this.eventService.componentBusy(false);
        this.paymentGatewayFrameVisible = false;
        this.paymentProcessed = true;
      });
    }, e => {
      console.error('error saving transaction history:', e);
    });
  }

  paymentError(event: string) {
    this.popToast(event, 'error');
  }

  activateCCProcessing() {
    this.eventService.showSnackbar('Open payment processing application?', false,
      'START', ['open-nuvei-snack'], this.openPaymentProcessing);
  }

  openPaymentProcessing() {
    window.open(AppConstants.NUVEI_APPLICATION, '_blank');
  }

  YesNoConfirmResult(e) {
    if (e) {
      this.reversePayment();
    }
  }

  receivedByChanged() {
    if (!!this.receivedByUserLookup && !!this.receivedByUserLookup?.selectedObject && this.receivedByUserLookup.selectedObject.Text) {
      this.payment.ReceivedByString = this.receivedByUserLookup.selectedObject.Text;

      if (this.firstChanges.receivedBy) {
        this.isPaymentDirty = true;
      }
      this.firstChanges.paymentMethod = true;
    }
  }

  saveAndNewPayment() {
    this.onSave(null, this.valGroup, this.addNewPaymentAfterSave);
  }

  addNewPaymentAfterSave() {
    this.inputDisabled = false;
    this.valGroup.instance.reset();
    this.sourceId = null;
    this.loadData(true);
  }

  resetForm() {
    this.inputDisabled = false;
    if (this.paymentType === 'Payment') {
      this.ddlAgencies.fieldsDisabled = false;
      this.receivedByUserLookup.fieldsDisabled = false;
    }
    this.valGroup.instance.reset();
    this.sourceId = null;
    this.selectedDefendantName = null;
    if(!!this.searchbox) {
      this.searchbox.resetResult();
    }
    this.transactionId = null;
    this.arSummary.sourceId = null;
  }

  paymentDataChanged() {
    if(this.firstChanges.paymentAmount) {
      this.isPaymentDirty = true;
    }
    this.firstChanges.paymentAmount = true;
  }
}
