import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { GridService } from '../../../services/grid.service';
import { ReminderService } from '../../../services/reminder.service';
import { AppConstants } from '../../../shared/AppConstants';
import { BillingService } from '../../../services/billing.service';


@Component({
  selector: 'app-reminder-logs',
  templateUrl: './reminder-logs.component.html',
  styleUrls: ['./reminder-logs.component.scss'],
})
export class ReminderLogsComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild('logGrid', { static: true }) logGrid: DxDataGridComponent;

  @Input() currentSourceType: any;

  reminderlogs: any = [];
  personTypeList: any = [{text: 'Defendants', value: 'Defendant'}, {text: 'Contacts', value: 'Contact'}];
  dateRangeList: any =  [{text: 'Show All', value: null}, {text: 'Last 30 Days', value: 30}, {text: 'Last 7 Days', value: 7}];
  currentDateRange: any;
  currentPersonType: any = '';
  filter = '';
  personTypes = {
    Contact: (item) => {
      item.url = `/defendant/${item.DefendantId}/full`;
      item.params = {
        anchor: 'contacts',
        contactId: item.ContactId,
        transactionId: item.TransactionId,
      };
    },
    Defendant: (item) => {
      item.url = `/defendant/${item.DefendantId}`;
      item.params = {};
    },
  };

  fromNumberCellTemplate = 'FromNumberCellTemplate';
  toNumberCellTemplate = 'ToNumberCellTemplate';

  constructor(private gridService: GridService,
              private reminderService: ReminderService,
              private billingService: BillingService) {
    super();
    this.customizeExcelCell = this.customizeExcelCell.bind(this);
  }

  ngOnInit() {
    this.loadFilters();
    this.generateFilter();
  }

  // Load filter for person type and date range
  loadFilters() {
    this.currentDateRange = this.dateRangeList[1].value;
  }

  // Load reminder logs on first load of component
  loadReminderLogs() {
    this.toggleButtonLoading(true);
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_COLLECTIONS, false)
      .subscribe(res => {
        this.subs.sink = this.reminderService.getLogs(this.filter, res).subscribe(response => {
          if (response.data) {
            for ( const item of response.data) {
              this.personTypes[item.PersonType](item);
            }
            this.reminderlogs = response.data;
          } else {
            console.error('Error while getting Reminder History Logs!');
          }
          this.toggleButtonLoading(false);
        });
      });
  }

  // Create filter to call API
  generateFilter() {
    this.filter = '';
    if (this.currentDateRange && this.currentPersonType) {
      this.filter = '?days=' + this.currentDateRange + '&persontype=' + this.currentPersonType;
    }

    if (this.currentDateRange && !this.currentPersonType) {
      this.filter = '?days=' + this.currentDateRange;
    }

    if (!this.currentDateRange && this.currentPersonType) {
      this.filter = '?persontype=' + this.currentPersonType;
    }

    if (this.currentSourceType) {
      this.filter += ((this.filter.length > 0 ? '&' : '?') + `messagetype=${this.currentSourceType}`);
    }
  }

  // Handle selection change for person type
  onPersonTypeChanged (e: any) {
    // // console.log('In reminder log component function onPersonTypeChanged', e, e.selectedItem);
    if (e.selectedItem && e.selectedItem.value) {
      this.currentPersonType = e.selectedItem.value;
    } else {
      this.currentPersonType = null;
    }
    this.generateFilter();
    this.loadReminderLogs();
  }

  // Handle selection change for date range
  onDateRangeChanged (e: any) {
    // // console.log('In reminder log component function onDateRangeChanged', e, e.selectedItem);
    if (e.selectedItem && e.selectedItem.value) {
      this.currentDateRange = e.selectedItem.value;
    } else {
      this.currentDateRange = null;
    }
    this.generateFilter();
    this.loadReminderLogs();
  }

  toggleButtonLoading(loading = false) {
    try {
      const refreshButton = document.getElementById('refreshGrid-button');
      const spanButton = refreshButton.querySelector('span.dx-button-text') as HTMLElement;

      if (loading) {
        refreshButton.classList.add('refreshing-button');
        spanButton.innerText = 'REFRESHING...';
      } else {
        refreshButton.classList.remove('refreshing-button');
        spanButton.innerText = 'REFRESH NOW';
      }
    } catch (e) {
      // console.log('Button not rendered yet, failing silenty', e);
    }
  }

  handleRefresh(e) {
      const button = (!!e.element[0] ? e.element[0] : e.element);
      if (!button.classList.contains('refreshing-button')) {
        this.loadReminderLogs();
      }
  }

  customizeExcelCell(options) {
    if (options.gridCell.rowType === 'data') {
      if (options.gridCell.column.cellTemplate === this.fromNumberCellTemplate) {
        options.value = options.gridCell.data.FromNumber || '--';
      } else if (options.gridCell.column.cellTemplate === this.toNumberCellTemplate) {
        options.value = options.gridCell.data.ToNumber || '--';
      }
    }
  }

  onToolbarPreparing({ toolbarOptions }) {
    const toolbarItems = toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'left',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          icon: `refresh`,
          hint: 'Refresh',
          text: 'Refresh now',
          onClick: this.handleRefresh.bind(this),
          elementAttr: { id: 'refreshGrid-button', class: 'refresh-button'},
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.gridService.exportGrid(this.logGrid, 'ReminderLogs');
          },
        },
      },
    );
  }

}
