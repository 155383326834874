import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookupComponent } from './lookup.component';
import { SelectfocusDirective } from '../../../../app/directives/select-focus.directive';
import { DxButtonModule, DxLoadIndicatorModule, DxPopupModule, DxSelectBoxModule, DxValidatorModule } from 'devextreme-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [LookupComponent, SelectfocusDirective],
  imports: [
    CommonModule,
    DxSelectBoxModule,
    DxPopupModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxValidatorModule,
    FlexLayoutModule,
    MatButtonModule,
    RouterModule,
  ],
  exports: [LookupComponent, SelectfocusDirective],
})
export class LookupModule { }
