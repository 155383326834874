import {BaseModel} from './BaseModel';
import { Time } from '@angular/common';

// This will be used to handle additional setting with each reminder types which is not Generic and not to be included in all reminders
export class CollectionAutomaticNotificationSetting extends BaseModel {

  Id: any;
  CustomerId: any;
  Type: any;
  LastRun: any;
  StartTime: any;
  StopTime: any;

  // First Reminder
  FirstReminder: any;
  FirstReminderEnabled: boolean;
  FirstReminderDeliveryChannels: string;

  // Second Reminder
  SecondReminder: any;
  SecondReminderEnabled: boolean;
  SecondReminderDeliveryChannels: string;

  // Third Reminder
  ThirdReminder: any;
  ThirdReminderEnabled: boolean;
  ThirdReminderDeliveryChannels: string;

  // Fourth Reminder
  FourthReminder: any;
  FourthReminderEnabled: boolean;
  FourthReminderDeliveryChannels: string;

  // Legal Reminder
  LegalReminder: any;
  LegalReminderEnabled: boolean;
  LegalReminderDeliveryChannels: string;

  ReminderPhoneNumber: string;

  CreatedByUserId: any;
  Created_At: any;
  UpdatedByUserId: any;
  Updated_At: any;
  DeletedByUserId: any;
  Deleted_At: any;
  SendNoInteractionReminder: boolean;


  constructor(obj?: object) {
    super(obj);

    this.FirstReminderEnabled = true;
  }


}
