
import {catchError, map} from 'rxjs/operators';

import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiUtilities} from '../shared/ApiUtilities';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {Link} from '../models/link';

@Injectable()
export class LinkService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
  ) {
  }

  createLink(link: Link): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/links`, JSON.stringify({ data: link })).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getLink(linkId: string): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/links/${linkId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getLinkByUser(linkId: string): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/links/byuser/${linkId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getLinkByDefendant(linkId: string): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/links/bydefendant/${linkId}`).pipe(
      map((res: any) => res),
      catchError((error) => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')
      }),);
  }

  updateLink(link: Link): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/links/${link.Id}`, JSON.stringify({ data: link })).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  deleteLink(linkId: string): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/links/${linkId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }
}
