import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { DefendantService } from '../../../services/defendant.service';
import { Router, ActivatedRoute } from '@angular/router';
import ArrayStore from 'devextreme/data/array_store';
import { Observable, of, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../../services/auth.service';
import { CapAccount } from '../../../services/billing.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DialogService } from '../../../services/dialog.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../../controls/notification-dialog/notification-dialog.component';
import { EventService } from '../../../services/event.service';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';
import { DefendantComponent } from '../../../views/defendant/defendant.component';
import { take } from 'rxjs/operators';

export interface SearchResult {
  CaseNumber: string;
  ContactId: string;
  DefendantId: string;
  DefendantName: string;
  Dob: string;
  F1: string;
  F2: string;
  F3: string;
  F4: string;
  FileNumber: string;
  FirstLast: string;
  FullName: string;
  Gender: string;
  Id: string;
  InvoiceDate: string;
  InvoiceOriginalAmount: string;
  InvoiceReference: string;
  Phones: string;
  PowerNumber: string;
  ProfilePicture: string;
  Source: 'RECENT DEFENDANTS' | 'CONTACT' | 'DEFENDANT' | 'POWER' | 'ERROR' | 'NORESULT' | 'COLLECTIONS' | 'RECEIPT' | 'BOND';
  Status: string;
  TransactionId: string;
  V1Id: string;
  CustomerId: string;
  object: 'Search';
}

@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.scss'],
})
export class SearchresultsComponent extends UnsubscribeOnDestroyAdapter implements OnChanges {
  @Input() searchResults: SearchResult[] = [];
  @Input() searchKeyword: any;
  @Input() selectMode = false;
  @Output() onSearchHidden = new EventEmitter<any>();
  @Output() onItemSelected = new EventEmitter<any>();
  @Output() onTakePayment = new EventEmitter<any>();
  @Output() onCheckIn = new EventEmitter<any>();
  @Input() templateClass: string;

  @Input() extendedSearch = false;
  @Output() extendedSearchChange = new EventEmitter<boolean>();
  @Output() openAdvancedSearch = new EventEmitter<any>();

  searchData: DataSource;
  searchError: string | null;
  searchCounts: any = [];
  defendantSearchActions: any[] = [];
  powerSearchActions: any[] = [];
  public activeCustomerId: string;
  private accounts: CapAccount[];
  account: CapAccount;

  constructor(
    private router: Router,
    private defendantService: DefendantService,
    private authService: AuthService,
    private eventService: EventService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
  ) {
    super();
    this.searchCounts['RECENT DEFENDANTS'] = 0;
    this.searchCounts.CONTACT = 0;
    this.searchCounts.DEFENDANT = 0;
    this.searchCounts.POWER = 0;
    this.searchCounts.COLLECTIONS = 0;
    this.searchCounts.RECEIPTS = 0;
    this.searchCounts.BOND = 0;

    this.defendantSearchActions = [
      {text: 'Open'},
      {text: 'Take Payment'},
      {text: 'Check In'},
    ];

    this.powerSearchActions = [{text: 'Open'}];
    this.activeCustomerId = this.authService.getActiveCustomerId();
    this.accounts = this.authService.getValidAccounts();
    this.subs.sink = this.eventService.onAccountSwitch$.subscribe(() => {
      this.activeCustomerId = this.authService.getActiveCustomerId();
      this.accounts = this.authService.getValidAccounts();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // // console.log(changes);
    this.fillSearchDataSource(this.searchResults);
  }

  setCounts() {
    //  // console.log('set counts', this.searchResults);
    if (!!this.searchResults) {
      this.searchCounts['RECENT DEFENDANTS'] = this.searchResults.filter(
        (x: any) => x.Source === 'RECENT DEFENDANTS',
      ).length;
      this.searchCounts.CONTACT = this.searchResults.filter(
        (x: any) => x.Source === 'CONTACT',
      ).length;
      this.searchCounts.DEFENDANT = this.searchResults.filter(
        (x: any) => x.Source === 'DEFENDANT',
      ).length;
      this.searchCounts.POWER = this.searchResults.filter(
        (x: any) => x.Source === 'POWER',
      ).length;
      this.searchCounts.ERROR = this.searchResults.filter(
        (x: any) => x.Source === 'ERROR',
      ).length;
      this.searchCounts.NORESULT = this.searchResults.filter(
        (x: any) => x.Source === 'NORESULT',
      ).length;
      this.searchCounts.COLLECTIONS = this.searchResults.filter(
        (x: any) => x.Source === 'COLLECTIONS',
      ).length;
      this.searchCounts.RECEIPT = this.searchResults.filter(
        (x: any) => x.Source === 'RECEIPT',
      ).length;
      this.searchCounts.BOND = this.searchResults.filter(
        (x: any) => x.Source === 'BOND',
      ).length;
    }
  }

  fillSearchDataSource(data: any) {
    // // console.log("fillsearchdatasource", data);
    this.setCounts();
    this.searchData = new DataSource({
      store: new ArrayStore({
        data: data,
        key: 'Id',
      }),
      group: 'GroupSource',
    });
  }

  hideSearch() {
    // console.log('hiding search');
    this.searchKeyword = '';
    this.searchResults = [];
    this.onSearchHidden.emit(null);
  }

  itemClicked(e: any, data: any) {
    switch (e.itemData.text) {
      case 'Open':
        this.openRecord(data);
        break;
      case 'Take Payment':
        this.receivePayment(data);
        break;
      case 'Check In':
        this.recordCheckIn(data);
        break;
    }

    this.hideSearch();
  }

  openRecord(e: SearchResult) {
    if (this.selectMode) {
      this.onItemSelected.emit(e);
      this.hideSearch();
    } else {
      switch (e.Source.substring(e.Source.indexOf('_') + 1)) {
        case 'DEFENDANT':
        case 'RECEIPT':
        case 'BOND':
          this.switchAccount(e.CustomerId).subscribe((dialogRef) => {
            this.dialogService.remove();
            this.router.navigateByUrl('/defendant/' + e.DefendantId).then(() => {
              if (dialogRef) {
                dialogRef.close();
              }
            });
          }, () => {
            this.router.navigateByUrl('/defendant/' + e.DefendantId).then(() => {
             window.location.reload();
            });
         });
          this.hideSearch();
          break;
        case 'RECENT DEFENDANTS':
          this.router.navigateByUrl('/defendant/' + e.DefendantId);
          this.hideSearch();
          break;
        case 'POWER':
          if (e.DefendantId) {
            this.switchAccount(e.CustomerId).subscribe((dialogRef) => {
              this.dialogService.remove();
              this.router.navigateByUrl('/defendant/' + e.DefendantId).then(() => {
                if (dialogRef) {
                  dialogRef.close();
                }
              });
            }, () => {
                this.router.navigateByUrl('/defendant/' + e.DefendantId).then(() => {
                  window.location.reload();
                });
              });
          } else {
            this.switchAccount(e.CustomerId).subscribe((dialogRef) => {
              this.dialogService.remove();
              this.router.navigateByUrl('/power/' + e.Id).then(() => {
                if (dialogRef) {
                  dialogRef.close();
                }
              });
            }, () => {
                this.router.navigateByUrl('/power/' + e.Id).then(() => {
                  window.location.reload();
                });
              });
          }
          this.hideSearch();
          break;
        case 'AGENT':
          break;
        case 'CONTACT':
          if (!e.TransactionId) {
            this.router.navigateByUrl('/collections/accounts/' + e.DefendantId);
          } else {
            this.switchAccount(e.CustomerId).subscribe((dialogRef) => {
              this.dialogService.remove();
              this.router.navigateByUrl('/defendant/' + e.DefendantId).then(() => {
                if (dialogRef) {
                  dialogRef.close();
                }
              });
            }, () => {
              this.router.navigateByUrl('/defendant/' + e.DefendantId).then(() => {
                window.location.reload();
              });
            });
          }
          this.hideSearch();
          break;
        case 'COLLECTIONS':
          this.switchAccount(e.CustomerId).subscribe((dialogRef) => {
            this.dialogService.remove();
            this.router.navigateByUrl('/collections/accounts' + e.DefendantId).then(() => {
              if (dialogRef) {
                dialogRef.close();
              }
            });
            }, () => {
              this.router.navigateByUrl('/collections/accounts' + e.DefendantId).then(() => {
                window.location.reload();
              });
          });
          this.hideSearch();
          break;
        case 'NORESULT':
          this.openAdvancedSearch.emit(false);
          this.hideSearch();
          break;
      }
    }
  }

  switchAccount(customerId: string): Observable<MatDialogRef<NotificationDialogComponent>> {
    return new Observable<MatDialogRef<NotificationDialogComponent>>((observer) => {
      if (customerId !== this.activeCustomerId) {
        const account = this.accounts.find(a => a.CustomerId === customerId);
        if (account) {

          let activeSnapshot = this.route.snapshot;
          while (activeSnapshot.firstChild) {
            activeSnapshot = activeSnapshot.firstChild;
          }

          if (activeSnapshot.component === DefendantComponent) {
            this.account = account;
            this.eventService.switchAccount$.pipe(take(1)).subscribe(() => {
              this.performAccountSwitch(this.account)
              .then((dialogRef) => {
                observer.next(dialogRef);
                observer.complete();
              })
              .catch((error) => {
                observer.error(error);
              });
            });
            this.eventService.emitSwitchAccountCalled();
          } else {
            this.performAccountSwitch(account)
              .then((dialogRef) => {
                observer.next(dialogRef);
                observer.complete();
              })
              .catch((error) => {
                observer.error(error);
              });
          }
        } else {
          observer.next();
        }
      } else {
        observer.next();
      }
    });
  }

  recordCheckIn(e: any) {
    this.defendantService.pushRecentDefendant(
      e.DefendantId,
      e.DefendantName,
      e.Dob,
      e.Gender,
      e.ProfilePicture,
    );
    this.hideSearch();
    this.onCheckIn.emit(e);
  }

  receivePayment(e: any) {
    this.defendantService.pushRecentDefendant(
      e.DefendantId,
      e.DefendantName,
      e.Dob,
      e.Gender,
      e.ProfilePicture,
    );
    this.hideSearch();
    this.onTakePayment.emit(e);
  }

  fireExtendedSearch(change: MatCheckboxChange) {
    this.extendedSearchChange.emit(change.checked);
  }

  handleAdvancedSearchClick() {

  }

  performAccountSwitch(account: CapAccount): Promise<MatDialogRef<NotificationDialogComponent>> {
    return new Promise((resolve, reject) => {
      this.authService.switchAccount(account)
        .then((dialogRef) => {
          resolve(dialogRef);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
