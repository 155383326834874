import { Component, Input, OnInit } from '@angular/core';
import { Share } from '../../../../models/share';

@Component({
  selector: 'app-document-status-badge',
  templateUrl: './document-status-badge.component.html',
  styleUrls: ['./document-status-badge.component.scss'],
})
export class DocumentStatusBadgeComponent implements OnInit {

  @Input() share: Document | Share;
  @Input() key = 'Status';

  constructor() { }

  ngOnInit(): void {
  }

}
