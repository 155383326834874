import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { formatNumber } from 'libphonenumber-js';
import { DxDataGridComponent, DxListComponent, DxTooltipComponent } from 'devextreme-angular';
import * as moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { Router } from '@angular/router';
import { LookupService } from '../../../../services/app-lookup.service';
import { DashboardService, QuickLinkRequest } from '../../../../services/dashboard-service.service';
import { DashboardCache } from '../../../../models/dashboard-cache';
import { IDynamicPopupComponent } from '../../../../components/common/dynamic-popup/dynamic-popup.component';
import { CheckinComponent } from '../../../checkin/checkin.component';
import { AppConstants } from '../../../../shared/AppConstants';
import { DefendantNotesPopupComponent } from '../../../defendant/defendantnotes/defendantnotespopup/defendantnotespopup.component';
import { GridService } from '../../../../services/grid.service';
import { EventService } from '../../../../services/event.service';
import { UnsubscribeOnDestroyAdapter } from '../../../../common/UnsubscribeOnDestroy';
import { BondService } from '../../../../services/bondservice.service';
import { Store } from '@ngrx/store';
import { selectQuickLinks } from '../../../../state/actions/quick-links.actions';
import { selectQuickLinksItems } from '../../../../state/selectors/quick-links.selectors';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Moment } from 'moment';
import { CacheService } from '../../../../services/cache.service';

export interface QuickLinkCategory {
  text: QuickLinkTypes;
  value: string;
  count: number;
  loading: boolean;
}

export enum QuickLinkTypes {
  ActiveBonds = 'Active Bonds',
  PaymentsDue = 'Payments Due',
  CheckinsDue = 'Checkins Due',
  CourtAppearances = 'Court Appearances',
  ActiveDefendants = 'Active Defendants',
  OpenInvoices = 'Open Invoices',
  NeedsPaperwork = 'Needs Paperwork',
  Prospects = 'Prospects',
  OpenForfeitures = 'Open Forfeitures',
  ForfeituresDue = 'Forfeitures Due',
  NewForfeitures = 'New Forfeitures',
  NewBonds = 'New Bonds',
  DischargedBonds = 'Discharged Bonds',
  PastDue = 'Past Due',
}

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickLinksComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterContentInit, AfterViewInit {

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild(DxListComponent) viewList: DxListComponent;
  @ViewChild(DxTooltipComponent, { static: true }) tooltip: DxTooltipComponent;

  @Input() horizonallyCollapsible: boolean;
  @Input() constrainSize: boolean;
  @Output() resizeRequest: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() caption = 'Quick Links';
  @Input() horizontallyCollapsed: boolean;
  @Input() sourceView: 'quicklinks' | 'reminders' | 'activeDefendants';
  @Input() presetCategory = null;
  @Input() isUserLimited = false;
  @Input() allowSelection: any;

  @Input() loadCounts = true; // set false when working locally to prevent suicidal thoughts.
  gridData: any = {};
  errorData: any;
  agencies: any;
  agents: any;
  sureties: any;
  dateSourceType: any;
  categories: QuickLinkCategory[];
  showMoreText: any;
  moreVisible: boolean;
  now: Date = new Date();
  dateRangeFilter = 'Today';
  startDateFilter = moment();
  endDateFilter = moment();
  agencyFilter: any;
  agentFilter: any;
  suretyFilter: any;
  minDaysFilter = 1;
  maxDaysFilter = 365;
  minBalance = 50;
  maxBalance = 999999;

  columns: any;
  currentlySelected: QuickLinkCategory = {text: QuickLinkTypes.PaymentsDue, value: 'dashboardpaymentsdue', count: 0, loading: true};
  showDatesFilter = true;
  showSuretyFilter = true;
  showAgencyAgentFilter = true;
  currentList: any;
  selectedCategoryId = '';
  sessionCache = new DashboardCache();

  displayConfig = {
    backToQuickLinks: true,
  };

  lastUpdatedCheckinRowIndex: any;

  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData = [];
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  selectionMode = '';
  smFilterLayout = 'row';
  summaryColumns: any;
  isMobile: boolean;
  defendantId: string;
  dynamicPopupMaxHeight = AppConstants.DEFAULTPOPUPCONFIG.MAXHEIGHT;
  dynamicPopupMaxWidth = AppConstants.DEFAULTPOPUPCONFIG.MAXWIDTH;
  dynamicPopupDragEnabled = AppConstants.DEFAULTPOPUPCONFIG.DRAGENABLED;

  sortByDateFilter: any;
  sortByDateFilters = [{ Text: 'Posted Date', Id: 'PostedDate' }, { Text: 'Created Date', Id: 'CreatedAt' }];
  showSortByDateFilter = false;
  showPastDueFilters = false;

  constructor(
    private lookupService: LookupService,
    private dashboardservice: DashboardService,
    private router: Router,
    private gridService: GridService,
    private eventService: EventService,
    private bondService: BondService,
    private store: Store<{quickLinks: any}>,
    private readonly breakpointObserver: BreakpointObserver,
    private cacheService: CacheService,
  ) {
    super();
    this.loadFromSessionCache();
    this.selectionMode = 'none';
    this.showMoreText = 'More Filters';
    this.moreVisible = false;
    this.dateSourceType = [
      'Today',
      'Yesterday',
      'Tomorrow',
      'This Week',
      'This Month',
      'Next 30 Days',
      'Custom Range',
    ];

    // Subscribe event to handle welcome screen popup close event
    this.subs.sink = this.eventService.closeWelcomePopup$.subscribe(res => this.handleCloseWelcomePopup(res));
    this.isMobile = this.breakpointObserver.isMatched([Breakpoints.XSmall]);
    this.gridData.store = new CustomStore(
      {
        load: async (loadOptions: any) => {
          const selectedText = this.currentlySelected.text;
          const selectedValue = this.currentlySelected.value;
          await this.loadFromSessionCache();
          if (this.sessionCache && this.sessionCache[`griddata_${selectedValue}`] !== undefined) {
            return of(this.sessionCache[`griddata_${selectedValue}`]).toPromise();
          } else {
            const request: QuickLinkRequest = {
              ResourceName: selectedValue,
              StartDate: this.startDateFilter,
              EndDate: this.endDateFilter,
              AgencyIds: this.agencyFilter,
              AgentIds: this.agentFilter,
              SuretyIds: this.suretyFilter,
              MinDays: this.minDaysFilter,
              MaxDays: this.maxDaysFilter,
              MinBalance: this.minBalance,
              MaxBalance: this.maxBalance,
              CountOnly: false,
              SortByDate: this.sortByDateFilter,
            };
            const result = this.dashboardservice.getClassicDashboardDataAsPromise(request)
              .catch(err => {
                this.errorData = err;
              });

            result.then(value => {
              this.setColumnsByType(selectedText || QuickLinkTypes.PaymentsDue);
              this.sessionCache[`griddata_${selectedValue}`] = value;
              this.saveToSessionCache();
            });
            return result;
          }
        },
      });
  }

  async ngOnInit() {
    this.subs.sink = this.eventService.onAccountSwitch$.subscribe(() => {
      this.doRefresh();
    });
    this.loadCategories();

    const cacheLoaded = await this.loadFromSessionCache();
    if (!cacheLoaded) {
      this.loadLookupsData();
      this.dateRangeFilter = 'Today';
    }

    await this.loadCategoryCounts();

    if (this.allowSelection != null) {
      if (this.allowSelection) {
        this.selectionMode = 'multiple';
      } else {
        this.selectionMode = 'none';
      }
    }

    this.sortByDateFilter = this.sortByDateFilters[0].Id;
  }

  ngAfterViewInit() {
    if (this.sourceView === 'reminders' || this.sourceView === 'activeDefendants') {
      this.doRefresh();
    }
  }

  // Handle welcome wizard close event #1143 load agency and agents
  handleCloseWelcomePopup(response) {
    this.agencies = null;
    this.agents = null;
    this.sureties = null;
    this.loadLookupsData();
  }

  ngAfterContentInit() {
    this.loadGridData();
  }

  preselectCategory() {
    try {
      const selectedCategory = this.categories.filter(category => {
        return category.value === this.presetCategory;
      });

      this.currentlySelected = selectedCategory[0];
      this.caption = this.currentlySelected.text;

      this.resizeRequest.emit(true);
      this.setFilterVisibilityByType(this.currentlySelected.text);

      if (this.currentlySelected.value) {
        this.subs.sink = this.store.select(selectQuickLinksItems).pipe(take(1)).subscribe((data: any) => {
          const currentItem = data.items ? data.items.find((item: any) => item.viewName === this.currentlySelected.value) : null;
          if (currentItem) {
            this.dateRangeFilter = currentItem.dateRangeFilter;
            this.startDateFilter = currentItem.startDateFilter;
            this.endDateFilter = currentItem.endDateFilter;
            this.agencyFilter = currentItem.agencyFilter;
            this.agentFilter = currentItem.agentFilter;
            this.suretyFilter = currentItem.suretyFilter;
          }
          this.selectedCategoryId = this.currentlySelected.value;
          this.loadGridData();
        });
      }
    } catch (e) {
      // Silent fail...
    }
  }

  loadCategories() {
    if (this.sourceView === 'reminders') {
      this.categories = [
        {text: QuickLinkTypes.PaymentsDue, value: 'dashboardpaymentsdue', count: 0, loading: true},
        {text: QuickLinkTypes.CheckinsDue, value: 'dashboardcheckinsdue', count: 0, loading: true},
        {text: QuickLinkTypes.CourtAppearances, value: 'dashboardcourtdates', count: 0, loading: true},
        {text: QuickLinkTypes.ActiveDefendants, value: 'dashboardactivedefendants', count: 0, loading: true},
        {text: QuickLinkTypes.OpenInvoices, value: 'dashboardopeninvoices', count: 0, loading: true},
        {text: QuickLinkTypes.PastDue, value: 'dashboardpastdue', count: 0, loading: true},
      ];

      this.preselectCategory();
      this.displayConfig.backToQuickLinks = false;
      this.selectionMode = 'multiple';

    } else {
      this.categories = [
        {text: QuickLinkTypes.ActiveBonds, value: 'dashboardactivebonds', count: 0, loading: true},
        {text: QuickLinkTypes.NeedsPaperwork, value: 'dashboardneedspaperwork', count: 0, loading: true},
        {text: QuickLinkTypes.Prospects, value: 'dashboardprospects', count: 0, loading: true},
        {text: QuickLinkTypes.PaymentsDue, value: 'dashboardpaymentsdue', count: 0, loading: true},
        {text: QuickLinkTypes.CheckinsDue, value: 'dashboardcheckinsdue', count: 0, loading: true},
        {text: QuickLinkTypes.CourtAppearances, value: 'dashboardcourtdates', count: 0, loading: true},
        {text: QuickLinkTypes.OpenForfeitures, value: 'dashboardopenforfeitures', count: 0, loading: true},
        {text: QuickLinkTypes.ForfeituresDue, value: 'dashboarddueforfeitures', count: 0, loading: true},
        {text: QuickLinkTypes.NewForfeitures, value: 'dashboardnewforfeitures', count: 0, loading: true},
        {text: QuickLinkTypes.NewBonds, value: 'dashboardnewbonds', count: 0, loading: true},
        {text: QuickLinkTypes.DischargedBonds, value: 'dashboarddischargedbonds', count: 0, loading: true},
        {text: QuickLinkTypes.OpenInvoices, value: 'dashboardopeninvoices', count: 0, loading: true},
      ];
      this.preselectCategory();
      if (this.sourceView === 'activeDefendants') {
        this.displayConfig.backToQuickLinks = false;
      }
    }
  }

  async loadCategoryCounts() {
    // await this.loadFromSessionCache();
    // Check the cache before loading the data from the DB
    for (const category of this.categories) {

      category.loading = true;

      if (!this.loadCounts) {
        category.count = 999;
        category.loading = false;
      } else {

        if (this.sessionCache && this.sessionCache[`count_${category.value}`] !== undefined) {

          category.count = this.sessionCache[`count_${category.value}`];
          category.loading = false;

        } else {

          // Get the correct category dates from the store
          // this.subs.sink = this.store.select(selectQuickLinksItems).pipe(take(1)).subscribe((data: any) => {
          //   const currentItem = data.items ? data.items.find((item: any) => item.viewName === category.value) : null;
          //   if (currentItem) {
          //     this.dateRangeFilter = currentItem.dateRangeFilter;
          //     this.startDateFilter = currentItem.startDateFilter;
          //     this.endDateFilter = currentItem.endDateFilter;
          //     this.agencyFilter = currentItem.agencyFilter;
          //     this.agentFilter = currentItem.agentFilter;
          //     this.suretyFilter = currentItem.suretyFilter;
          //   } else {
          //     this.dateRangeFilter = 'Today';
          //     this.startDateFilter = moment();
          //     this.endDateFilter = moment();
          //     this.agencyFilter = null;
          //     this.agentFilter = null;
          //     this.suretyFilter = null;
          //   }
          // });
          const request: QuickLinkRequest = {
            ResourceName: category.value,
            StartDate: this.startDateFilter,
            EndDate: this.endDateFilter,
            AgencyIds: this.agencyFilter,
            AgentIds: this.agentFilter,
            SuretyIds: this.suretyFilter,
            MinDays: this.minDaysFilter,
            MaxDays: this.maxDaysFilter,
            MinBalance: this.minBalance,
            MaxBalance: this.maxBalance,
            CountOnly: true,
            SortByDate: this.sortByDateFilter,
          };
          this.subs.sink = this.dashboardservice.getClassicDashboardData(request).pipe(take(1)).subscribe(cnt => {
            category.count = cnt;
            category.loading = false;
            this.sessionCache[`count_${category.value}`] = cnt;
            this.saveToSessionCache();
          });
        }
      }
    }
  }

  listSelection(category: QuickLinkCategory) {
    this.currentlySelected = category;
    this.caption = this.currentlySelected.text;

    if (this.sourceView !== 'reminders') {
      if (this.currentlySelected.text === QuickLinkTypes.Prospects) {
        this.router.navigate(['defendant', 'prospects']);
      } else {
        this.router.navigate(['dashboard', this.currentlySelected.value]);
      }
    }
  }

  returnToList() {
    this.currentlySelected = null;
    this.selectedCategoryId = '';
    this.resizeRequest.emit(false);
    this.caption = 'Quick Links';
  }

  loadGridData() {
    if (!this.currentlySelected) {
      return;
    }
    if (this.sourceView !== 'reminders' && this.sourceView !== 'quicklinks' && this.sourceView !== 'activeDefendants') {
      return;
    }
    this.setColumnsByType(this.currentlySelected.text);
    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.refresh();
    }
    // this.saveToSessionCache();
  }

  getSelectedRows(): Observable<any> {
    return new Observable<any>(obs => {
      if (this.dataGrid) {
        const rows = this.dataGrid.instance.getSelectedRowKeys();
        obs.next(rows);
      }
    });
  }

  applyChanges() {
    this.sessionCache[`griddata_${this.currentlySelected.value}`] = undefined;
    this.loadGridData();
  }

  async loadLookupsData() {
    await this.loadFromSessionCache();
    if (!this.sureties) {
      this.subs.sink = this.lookupService.getLookup('sureties').pipe(take(1)).subscribe(data => {
        this.sureties = data.data;
        this.saveToSessionCache();
      }, error => {
        this.errorData = error;
      });
    }

    if (!this.agencies) {
      this.subs.sink = this.lookupService.getLookup('agencies').pipe(take(1)).subscribe(data => {
        this.agencies = data.data;
        this.saveToSessionCache();
      }, error => {
        this.errorData = error;
      });
    }

    if (!this.agents) {
      this.subs.sink = this.lookupService.getLookup('agents').pipe(take(1)).subscribe(data => {
        this.agents = data.data;
        this.saveToSessionCache();
      }, error => {
        this.errorData = error;
      });
    }
  }

  async applyFilters() {
    if (this.dateRangeFilter === 'Custom Range') {
      this.endDateFilter = moment(this.endDateFilter);
      this.startDateFilter = moment(this.startDateFilter);
    }
    this.saveFiltersToStore();
    this.loadCategoryCounts();
    if (this.currentlySelected) {
      this.sessionCache[`griddata_${this.currentlySelected.value}`] = undefined; // clear the grid data cache for this category
    }
    await this.saveToSessionCache();
    this.loadGridData();
  }

  saveFiltersToStore() {
    if (this.currentlySelected) {
      this.subs.sink = this.store.select(selectQuickLinksItems).pipe(take(1)).subscribe((data: any) => {
        const currentItem = data.items ? data.items.find((item: any) => item.viewName === this.currentlySelected.value) : null;
        if (currentItem) {
          currentItem.dateRangeFilter = this.dateRangeFilter;
          currentItem.endDateFilter = this.endDateFilter;
          currentItem.startDateFilter = this.startDateFilter;
          currentItem.agencyFilter = this.agencyFilter;
          currentItem.agentFilter = this.agentFilter;
          currentItem.suretyFilter = this.suretyFilter;
          currentItem.sortByDateFilter = this.sortByDateFilter;
        } else {
          if (!data.items) {
            data.items = [];
          }
          data.items.push({
            viewName: this.currentlySelected.value,
            dateRangeFilter: this.dateRangeFilter,
            endDateFilter: this.endDateFilter,
            startDateFilter: this.startDateFilter,
            agencyFilter: this.agencyFilter,
            agentFilter: this.agentFilter,
            suretyFilter: this.suretyFilter,
            sortByDateFilter: this.sortByDateFilter,
          });
        }
        this.store.dispatch(selectQuickLinks(data));
      });
    }
  }

  showMore(e: { preventDefault: () => void; }) {
    e.preventDefault();
    this.moreVisible = !this.moreVisible;
    if (this.moreVisible) {
      this.showMoreText = 'Less Filters';
    } else {
      this.showMoreText = 'More Filters';
    }
  }

  setColumnsByType(item: QuickLinkTypes) {
    if (!this.currentlySelected || this.currentlySelected?.text !== item) {
      return;
    }
    if (this.dataGrid) {
      this.dataGrid.instance.repaint();
    }
    // this.dataGrid.instance.repaintRows;
    this.columns = [];

    this.currentList = item;
    switch (item) {
      case QuickLinkTypes.PastDue:
        this.columns = [
          {dataField: 'DefendantFullName', 'cellTemplate': 'defendantNameTemplate'},
          {dataField: 'DefendantPhone', calculateCellValue: this.formatPhone},
          {dataField: 'DefendantCurrentBalance',  dataType: 'number', alignment: 'left', format: {type: 'currency', precision: 2}},
          {dataField: 'DefendantPastDueBalance',  dataType: 'number', alignment: 'left', format: {type: 'currency', precision: 2}},
          {dataField: 'DefendantMaxDaysLate'},
        ];
        break;
      case QuickLinkTypes.PaymentsDue:
        if (this.sourceView === 'reminders') {
          this.columns = [
            {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
            {dataField: 'Due Date', dataType: 'date', format: 'shortDate'},
            {dataField: 'Amount', dataType: 'number', alignment: 'left', format: {type: 'currency', precision: 2}},
            {dataField: 'PaidInAdvance', alignment: 'left', format: {type: 'currency', precision: 2}},
            {dataField: 'FilterId', 'visible': false},
          ];
          this.summaryColumns = [
            {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
            {column: 'Amount', summaryType: 'sum', displayFormat: 'Total Amount: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {column: 'PaidInAdvance', summaryType: 'sum', displayFormat: 'Total Paid In Advance: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
          ];
        } else {
          this.columns = [
            {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate', width: '300px'},
            {dataField: 'Due Date', dataType: 'date', format: 'shortDate', width: '300px'},
            {dataField: 'Amount', dataType: 'number', alignment: 'left', format: {type: 'currency', precision: 2}, width: '300px'},
            {dataField: 'PaidInAdvance', alignment: 'left', format: {type: 'currency', precision: 2}, width: '300px'},
            {dataField: 'FilterId', 'visible': false},
            {dataField: 'V1Id', 'visible': false},
          ];
          this.summaryColumns = [
            {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
            {column: 'Amount', summaryType: 'sum', displayFormat: 'Total Amount: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {column: 'PaidInAdvance', summaryType: 'sum', displayFormat: 'Total Paid In Advance: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
          ];
        }
        break;
      case QuickLinkTypes.CheckinsDue:
        if (this.sourceView === 'reminders') {
          this.columns = [
            {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
            {dataField: 'Due Date', dataType: 'date', format: 'shortDate'},
            {dataField: 'Checked In', 'alignment': 'center', dataType: 'boolean'},
            {dataField: 'Missed', 'alignment': 'center', dataType: 'boolean'},
            {dataField: 'Actual Date', dataType: 'date', format: 'shortDate'},
            {dataField: 'Phone', calculateCellValue: this.formatPhone},
            {dataField: 'DOB', dataType: 'date', format: 'shortDate'},
            {dataField: 'FilterId', 'visible': false},
          ];
          this.summaryColumns = [
            {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
          ];
        } else {
          this.columns = [
            {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
            {dataField: 'Due Date', dataType: 'date', format: 'shortDate'},
            {dataField: 'Checked In', 'alignment': 'center', dataType: 'boolean'},
            {dataField: 'Missed', 'alignment': 'center', dataType: 'boolean'},
            // adi columns fields commmented out
            // {dataField: 'VoiceStampFilePath'},
            // {dataField: 'VoiceStampFileName'},
            {dataField: 'Actual Date', dataType: 'date', format: 'shortDate'},
            // {dataField: 'Voice Stamp'},
            {dataField: 'Record Check In', 'cellTemplate': 'clickToCheckinTemplate'},
            {dataField: 'Address'},
            {dataField: 'Phone', calculateCellValue: this.formatPhone},
            {dataField: 'Next Court Date', dataType: 'date', format: 'shortDate'},
            {dataField: 'Next Court Time'},
            {dataField: 'Next Court'},
            {dataField: 'Next Court Room'},
            // {dataField: 'Current Balance', 'alignment': 'right', format: {type: 'currency', precision: 2}},
            // {dataField: 'Total Balance', 'alignment': 'right', format: {type: 'currency', precision: 2}},
            {dataField: 'Add Note', 'cellTemplate': 'clickToAddNoteTemplate'},
            {dataField: 'DOB', dataType: 'date', format: 'shortDate'},
            {dataField: 'Last Court Date', dataType: 'date', format: 'shortDate'},
            {dataField: 'FilterId', 'visible': false},
            {dataField: 'V1Id', 'visible': false},
          ];
          this.summaryColumns = [
            {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
            {column: 'Current Balance', summaryType: 'sum', displayFormat: 'Total Current Balance: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {column: 'Total Balance', summaryType: 'sum', displayFormat: 'Total Balance: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
          ];
        }
        break;
      case QuickLinkTypes.CourtAppearances:
        if (this.sourceView === 'reminders') {
          this.columns = [
            {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
            {dataField: 'Power'},
            {dataField: 'Setting', dataType: 'date', format: 'shortDate'},
            {dataField: 'Time'},
            {dataField: 'Court'},
            {dataField: 'Notified', dataType: 'boolean', cellTemplate: 'notifiedCourtDateCheckbox'},
            {dataField: 'Notified Date', dataType: 'date', format: 'shortDate'},
            {dataField: 'Court Room', 'alignment': 'center'},
            {dataField: 'Set For'},
            {dataField: 'Bond Amount', 'alignment': 'right', format: {type: 'currency', precision: 2}},
            {dataField: 'Defendant Phone', calculateCellValue: this.formatPhone},
            {dataField: 'FilterId', 'visible': false},
          ];
          this.summaryColumns = [
            {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
            {column: 'Bond Amount', summaryType: 'sum', displayFormat: 'Total Bond Amount: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
          ];
        } else {
          this.columns = [
            {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
            {dataField: 'Power'},
            {dataField: 'Setting', dataType: 'date', format: 'shortDate'},
            {dataField: 'Time'},
            {dataField: 'Court'},
            {dataField: 'Notified', dataType: 'boolean', cellTemplate: 'notifiedCourtDateCheckbox'},
            {dataField: 'Notified Date', dataType: 'date', format: 'shortDate'},
            {dataField: 'Court Room', 'alignment': 'center'},
            {dataField: 'Case Number'},
            {dataField: 'Set For'},
            {dataField: 'Bond Amount', 'alignment': 'right', format: {type: 'currency', precision: 2}},
            {dataField: 'Booking ID'},
            {dataField: 'Court Phone', calculateCellValue: this.formatPhone},
            {dataField: 'Defendant Phone', calculateCellValue: this.formatPhone},
            {caption: 'Indemnitor Phone', dataField: 'IndemnitorPhones', calculateCellValue: this.formatPhone},
            {dataField: 'FilterId', 'visible': false},
            {dataField: 'V1Id', 'visible': false},
          ];
          this.summaryColumns = [
            {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
            {column: 'Bond Amount', summaryType: 'sum', displayFormat: 'Total Bond Amount: {0}', skipEmptyValues: true, valueFormat: {type: 'currency', precision: 2}},
          ];
        }
        break;
      case QuickLinkTypes.ForfeituresDue:
        this.columns = [
          {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
          {dataField: 'Power'},
          {dataField: 'Bond Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'Bond Amount', 'alignment': 'right', format: {type: 'currency', precision: 2}},
          {dataField: 'Forfeiture Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'Satisfy By Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'Agent'},
          {dataField: 'Days Remaining', 'alignment': 'center'},
          {dataField: 'Status'},
          {dataField: 'V1Id', 'visible': false},
        ];
        this.summaryColumns = [
          {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
          {column: 'Bond Amount', summaryType: 'sum', displayFormat: 'Total Bond Amount: {0}', skipEmptyValues: true, valueFormat: {type: 'currency', precision: 2}},
        ];
        break;
      case QuickLinkTypes.OpenForfeitures:
        this.columns = [
          {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
          {dataField: 'Power'},
          {dataField: 'Bond Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'Bond Amount', 'alignment': 'right', format: {type: 'currency', precision: 2}},
          {dataField: 'Forfeiture Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'Satisfy By Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'Days Remaining', 'alignment': 'center'},
          {dataField: 'Status'},
          {dataField: 'Sub Status'},
          {dataField: 'Suite Number', 'caption': 'Suite#'},
          {dataField: 'Summary Judgement Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'V1Id', 'visible': false},
        ];
        this.summaryColumns = [
          {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
          {column: 'Bond Amount', summaryType: 'sum', displayFormat: 'Total Bond Amount: {0}', skipEmptyValues: true, valueFormat: {type: 'currency', precision: 2}},
        ];
        break;
      case QuickLinkTypes.NewForfeitures:
        this.columns = [
          {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
          {dataField: 'Power'},
          {dataField: 'Bond Amount', 'alignment': 'right', format: {type: 'currency', precision: 2}},
          {dataField: 'Forfeiture Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'Forfeiture Entered', dataType: 'date', format: 'shortDate'},
          {dataField: 'V1Id', 'visible': false},
        ];
        this.summaryColumns = [
          {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
          {column: 'Bond Amount', summaryType: 'sum', displayFormat: 'Total Bond Amount: {0}', skipEmptyValues: true, valueFormat: {type: 'currency', precision: 2}},
        ];
        break;
      case QuickLinkTypes.NewBonds:
        this.columns = [
          {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
          {dataField: 'Power'},
          {dataField: 'Bond Amount', 'alignment': 'right', format: {type: 'currency', precision: 2}},
          {dataField: 'Bond Date', dataType: 'date', format: 'shortDate'},
          {dataField: 'V1Id', 'visible': false},
        ];
        this.summaryColumns = [
          {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
          {column: 'Bond Amount', summaryType: 'sum', displayFormat: 'Total Bond Amount: {0}', skipEmptyValues: true, valueFormat: {type: 'currency', precision: 2}},
        ];
        break;
      case QuickLinkTypes.DischargedBonds:
        this.columns = [
          {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
          {dataField: 'Power'},
          {dataField: 'Bond Amount', 'alignment': 'right', format: {type: 'currency', precision: 2}},
          {dataField: 'Discharge Date', dataType: 'date', format: 'shortDate', sortOrder: 'desc'},
          {dataField: 'PowerId', 'caption': 'Power ID', visible: false},
          {dataField: 'Agency'},
          {dataField: 'Agent'},
          {dataField: 'V1Id', 'visible': false},
        ];
        this.summaryColumns = [
          {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
          {column: 'Bond Amount', summaryType: 'sum', displayFormat: 'Total Bond Amount: {0}', skipEmptyValues: true, valueFormat: {type: 'currency', precision: 2}},
        ];
        break;
      case QuickLinkTypes.ActiveBonds:
        this.columns = [
          {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
          {dataField: 'DOB', 'caption': 'DOB', dataType: 'date', format: 'shortDate'},
          {dataField: 'Ssn', 'caption': 'SSN', 'cellTemplate': 'ssnCellTemplateGrid'},
          {dataField: 'PowerNumber'},
          {dataField: 'Posted', dataType: 'date', format: 'shortDate', sortOrder: 'desc'},
          {dataField: 'Status'},
          {dataField: 'Amount', 'alignment': 'right', format: {type: 'currency', precision: 2}},
          {dataField: 'Case #'},
          {dataField: 'Charge'},
          { dataField: 'Booking Number' },
          { dataField: 'Arrest Date', dataType: 'date', format: 'shortDate' },
          { dataField: 'Next Court', dataType: 'date', format: 'shortDateShortTime' },
          {dataField: 'Class'},
          {dataField: 'Court'},
          {dataField: 'State'},
          {dataField: 'County'},
          {dataField: 'Setting', 'caption': 'Setting', dataType: 'date', format: 'shortDate'},
          {dataField: 'Mugshot', 'caption': 'Has Mugshot'},
          {dataField: 'Sub Status'},
          {dataField: 'V1Id', 'visible': false},
        ];
        this.summaryColumns = [
          {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
          {column: 'Amount', summaryType: 'sum', displayFormat: 'Total Amount: {0}', skipEmptyValues: true,
            valueFormat: {type: 'currency', precision: 2}},
        ];
        break;
      case QuickLinkTypes.ActiveDefendants:
        if (this.sourceView === 'reminders') {
          this.columns = [
            {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
            {dataField: 'DOB', 'caption': 'DOB', dataType: 'date', format: 'shortDate'},
            {dataField: 'Ssn', 'caption': 'SSN', 'cellTemplate': 'ssnCellTemplateGrid'},
            {dataField: 'Bond Count', 'alignment': 'left', dataType: 'number'},
            {dataField: 'Bond Total', 'alignment': 'left', dataType: 'number', format: { type: 'currency', precision: 2 },
            },
            {dataField: 'Current Balance Amt', 'alignment': 'left', dataType: 'number', format: {type: 'currency', precision: 2}},
            {dataField: 'Past Due Amt', 'alignment': 'left', dataType: 'number', format: {type: 'currency', precision: 2}},
            {dataField: 'Total Balance Amt', 'alignment': 'left', dataType: 'number', format: {type: 'currency', precision: 2}},
            {dataField: 'Has Mugshot', calculateCellValue: this.parseMugshot },
            {dataField: 'V1Id', 'visible': false},
          ];
          this.summaryColumns = [
            {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
            {column: 'Bond Count', summaryType: 'sum', displayFormat: 'Total Bond Count: {0}', skipEmptyValues: true},
            {column: 'Bond Total', summaryType: 'sum', displayFormat: 'Total Bonds Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {column: 'Current Balance Amt', summaryType: 'sum', displayFormat: 'Total Current Balance Amt: {0}',
              skipEmptyValues: true, valueFormat: {type: 'currency', precision: 2}},
            {column: 'Past Due Amt', summaryType: 'sum', displayFormat: 'Total Past Due Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {column: 'Total Balance Amt', summaryType: 'sum', displayFormat: 'Total Balances Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
          ];
        } else {
          this.columns = [
            {dataField: 'Defendant', cellTemplate: 'defendantNameTemplate', summaryType: 'count', displayFormat: 'Total Count: {0}',
              skipEmptyValues: true},
            {dataField: 'DOB', 'caption': 'DOB', dataType: 'date', format: 'shortDate'},
            {dataField: 'Bond Count', alignment: 'left', summaryType: 'sum', displayFormat: 'Total Bond Count: {0}',
              skipEmptyValues: true},
            {dataField: 'Bond Total', alignment: 'left', dataType: 'number', format: { type: 'currency', precision: 2 },
              summaryType: 'sum', displayFormat: 'Total Bonds Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {dataField: 'Current Balance Amt', alignment: 'left', dataType: 'number', format: {type: 'currency', precision: 2},
              summaryType: 'sum', displayFormat: 'Total Current Balance Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {dataField: 'Past Due Amt', alignment: 'left', dataType: 'number', format: {type: 'currency', precision: 2},
              summaryType: 'sum', displayFormat: 'Total Past Due Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {dataField: 'Total Balance Amt', alignment: 'left', dataType: 'number', format: {type: 'currency', precision: 2},
              summaryType: 'sum', displayFormat: 'Total Balances Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {dataField: 'Has Mugshot', alignment: 'left', calculateCellValue: this.parseMugshot },
            {dataField: 'V1Id', 'visible': false},
          ];
          this.summaryColumns = [
            {column: 'Defendant', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
            {column: 'Bond Count', summaryType: 'sum', displayFormat: 'Total Bond Count: {0}', skipEmptyValues: true},
            {column: 'Bond Total', summaryType: 'sum', displayFormat: 'Total Bonds Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {column: 'Current Balance Amt', summaryType: 'sum', displayFormat: 'Total Current Balance Amt: {0}',
              skipEmptyValues: true, valueFormat: {type: 'currency', precision: 2}},
            {column: 'Past Due Amt', summaryType: 'sum', displayFormat: 'Total Past Due Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
            {column: 'Total Balance Amt', summaryType: 'sum', displayFormat: 'Total Balances Amt: {0}', skipEmptyValues: true,
              valueFormat: {type: 'currency', precision: 2}},
          ];
        }
        break;
      case QuickLinkTypes.Prospects:
        this.columns = [
          {dataField: 'Defendant', alignment: 'left', dataType: 'string', cellTemplate: 'defendantNameTemplate'},
          {dataField: 'DefendantId', alignment: 'left', dataType: 'string', visible: false},
          {dataField: 'CallDate', alignment: 'left', dataType: 'date', format: 'shortDate'},
          {dataField: 'Probability', caption: 'Lead Score', alignment: 'left', dataType: 'string'},
          {dataField: 'TransactionAmount', caption: 'Bond Amount', alignment: 'left', dataType: 'string'},
          {dataField: 'AvailableMoney', caption: '$ Available', alignment: 'left', dataType: 'number'},
          {dataField: 'Charges', alignment: 'left', dataType: 'string'},
          {dataField: 'Facility', alignment: 'left', dataType: 'string'},
          {dataField: 'BookingIdentifier', caption: 'Booking No.', alignment: 'left', dataType: 'string'},
          {dataField: 'CreatedBy', caption: 'By', alignment: 'left', dataType: 'string'},
          {dataField: 'AgencyName', caption: 'Agency', alignment: 'left', dataType: 'string'},
          {dataField: 'V1Id', 'visible': false},
        ];
        break;
      case QuickLinkTypes.NeedsPaperwork:
        this.columns = [
          {dataField: 'Defendant', alignment: 'left', dataType: 'string', cellTemplate: 'defendantNameTemplate'},
          {dataField: 'DefendantId', alignment: 'left', dataType: 'string', visible: false},
          {dataField: 'Status', alignment: 'left', dataType: 'string'},
          {dataField: 'Posted Date', alignment: 'left', dataType: 'date', format: 'shortDate'},
          {dataField: 'DOB', alignment: 'left', dataType: 'string'},
          {dataField: 'V1Id', 'visible': false},
        ];
        break;
      case QuickLinkTypes.OpenInvoices:
        this.columns = [
          {dataField: 'Invoice Date', alignment: 'left', dataType: 'date', format: 'shortDate'},
          {dataField: 'Invoice Amt', alignment: 'left', dataType: 'number', format: {type: 'currency', precision: 2}},
          {dataField: 'Defendant', 'cellTemplate': 'defendantNameTemplate'},
          {dataField: 'Powers', alignment: 'left'},
          {dataField: 'Last Pmt Date', alignment: 'left', dataType: 'date', format: 'shortDate'},
          {dataField: 'Next Pmt Date', alignment: 'left', dataType: 'date', format: 'shortDate'},
          {dataField: 'Past Due', alignment: 'left', format: {type: 'currency', precision: 2}},
          {dataField: 'DefendantID', visible: false},
          {dataField: 'TransactionID', visible: false},
          {dataField: 'FilterId', visible: false},
          {dataField: 'ActiveID', visible: false},
          {dataField: 'Open Bonds', alignment: 'left'},
          {dataField: 'Urgent Notice', alignment: 'left', visible: false},
          {dataField: 'Current Balance', alignment: 'left', dataType: 'number', format: {type: 'currency', precision: 2}},
          {dataField: 'V1Id', 'visible': false},
          {dataField: 'Agent', alignment: 'left'},
        ];
        this.summaryColumns = [
          {column: 'Invoice Date', summaryType: 'count', displayFormat: 'Total Count: {0}', skipEmptyValues: true},
          {column: 'Open Bonds', summaryType: 'sum', displayFormat: 'Total Open Bonds: {0}', skipEmptyValues: true},
          {column: 'Invoice Amount', summaryType: 'sum', displayFormat: 'Total Invoice Amount: {0}', skipEmptyValues: true,
            valueFormat: {type: 'currency', precision: 2}},
          {column: 'Past Due', summaryType: 'sum', displayFormat: 'Total Past Due: {0}', skipEmptyValues: true,
            valueFormat: {type: 'currency', precision: 2}},
          {column: 'Current Balance', summaryType: 'sum', displayFormat: 'Total Current Balance: {0}', skipEmptyValues: true,
            valueFormat: {type: 'currency', precision: 2}},
        ];
        break;
    }
    if (this.dataGrid) {
      this.dataGrid.instance.repaint();
    }
  }

  parseBondTotalToNumber(data: any) {
    let auxBondTotal = data['Bond Total'];
    if (typeof data['Bond Total'] === 'string') {
      auxBondTotal = data['Bond Total'].replace(/[$,]/g, '');
      auxBondTotal = Number(auxBondTotal);
    }

    return auxBondTotal;
  }

  parseMugshot(data: any) {
    return (!!data.Mugshot ? 'Yes' : 'No' );
  }

  formatPhone(data): any {
    const column = this as any;

    if (data.Phone && column.dataField === 'Phone') {
      return formatNumber(data.Phone, 'US', 'National');
    }
    if (data['Defendant Phone'] && column.dataField === 'Defendant Phone') {
      return formatNumber(data['Defendant Phone'], 'US', 'National');
    }
    if (data.DefendantPhone && column.dataField === 'DefendantPhone') {
      return formatNumber(data.DefendantPhone, 'US', 'National');
    }

    if (data.IndemnitorPhones && column.dataField === 'IndemnitorPhones') {
      return data.IndemnitorPhones.split(',').map(contactNumber => {
        return formatNumber(contactNumber, 'US', 'National');
      }).join(', ');
    }

    if (data['Court Phone'] && column.dataField === 'Court Phone') {
      return formatNumber(data['Court Phone'], 'US', 'National');
    }
    return '';
  }

  setFilterVisibilityByType(item: QuickLinkTypes) {
    this.showDatesFilter = true;
    this.showAgencyAgentFilter = true;
    this.showSuretyFilter = true;
    this.showPastDueFilters = false;
    switch (item) {
      case QuickLinkTypes.PastDue:
        this.showDatesFilter = false;
        this.showSuretyFilter = false;
        this.showSortByDateFilter = false;
        this.showAgencyAgentFilter = false;
        this.showPastDueFilters = true;
        break;
      case QuickLinkTypes.PaymentsDue:
        this.showSuretyFilter = false;
        break;
      case QuickLinkTypes.CheckinsDue:
        this.showSuretyFilter = false;
        break;
      case QuickLinkTypes.NewBonds:
        this.showSortByDateFilter = true;
        break;
      case QuickLinkTypes.CourtAppearances:
      case QuickLinkTypes.ForfeituresDue:
      case QuickLinkTypes.NewForfeitures:
      case QuickLinkTypes.DischargedBonds:
        // all filters available
        break;
      case QuickLinkTypes.ActiveBonds:
      case QuickLinkTypes.OpenForfeitures:
        this.showDatesFilter = false;
        break;
      case QuickLinkTypes.ActiveDefendants:
        this.showDatesFilter = false;
        this.showSuretyFilter = false;
        this.showAgencyAgentFilter = false;
        break;
      case QuickLinkTypes.OpenInvoices:
        this.showDatesFilter = false;
        this.showSuretyFilter = false;
        break;
      case QuickLinkTypes.Prospects:
        this.showSuretyFilter = false;
        break;
      case QuickLinkTypes.NeedsPaperwork:
        this.showDatesFilter = false;
        this.showSuretyFilter = false;
        break;
    }

    if (this.dateRangeFilter === 'Custom Range') {
      this.smFilterLayout = 'column';
    } else {
      this.smFilterLayout = 'row';
    }
  }

  dateSelectorChanged(e) {
    this.smFilterLayout = 'row';
    switch (this.dateRangeFilter) {
      case 'Today':
        this.startDateFilter = moment();
        this.endDateFilter = moment();
        this.applyFilters();
        break;
      case 'Yesterday':
        this.startDateFilter = moment().subtract(1, 'days');
        this.endDateFilter = moment().subtract(1, 'days');
        this.applyFilters();
        break;
      case 'Tomorrow':
        this.startDateFilter = moment().add(1, 'days');
        this.endDateFilter = moment().add(1, 'days');
        this.applyFilters();
        break;
      case 'This Week':
        this.startDateFilter = moment().startOf('week');
        this.endDateFilter = moment().endOf('week');
        this.applyFilters();
        break;
      case 'This Month':
        this.startDateFilter = moment().startOf('month');
        this.endDateFilter = moment().endOf('month');
        this.applyFilters();
        break;
      case 'Next 30 Days':
        this.startDateFilter = moment();
        this.endDateFilter = moment().add(30, 'days');
        this.applyFilters();
        break;
      case 'Custom Range':
        this.smFilterLayout = 'column';
        // do nothing here, the user will choose
        break;
      default:
        this.startDateFilter = null;
        this.endDateFilter = null;
        this.applyFilters();
    }
  }

  dropdownChanged(event) {
    this.applyFilters();
  }

  showDefendant(e, defendantId) {
    e.preventDefault();
    if (!this.isMobile ) {
     // this.router.navigate([`/defendant/${defendantId}`]);
       this.eventService.openslideOut(defendantId);
    } else {
      this.router.navigate([`/defendant/${defendantId}`]);
    }

  }

  addCheckin(defendantID, defendant, rowIndex) {
    this.dynamicPopupClass = CheckinComponent;
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = defendantID;
    this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = defendant;
    this.dynamicPopupTitle = 'Record Check In';
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;

    this.lastUpdatedCheckinRowIndex = rowIndex;
  }

  addNote(defendantId, defendant) {
    this.dynamicPopupMaxHeight = AppConstants.ADDNOTEPOPUPCONFIG.MAXHEIGHT;
    this.dynamicPopupMaxWidth = AppConstants.ADDNOTEPOPUPCONFIG.MAXWIDTH;
    this.dynamicPopupDragEnabled = AppConstants.ADDNOTEPOPUPCONFIG.DRAGENABLED;
    this.dynamicPopupClass = DefendantNotesPopupComponent;
    this.dynamicPopupData[AppConstants.DEFENDANT_ID_KEY] = defendantId;
    this.dynamicPopupData[AppConstants.DEFENDANT_NAME_KEY] = defendant;
    this.dynamicPopupTitle = 'Record Note';
    this.dynamicPopupVisible = true;
    this.dynamicPopupShowTitle = true;
  }

  async saveToSessionCache(): Promise<void> {
    this.sessionCache.agencyFilterValue = this.agencyFilter;
    this.sessionCache.agentFilterValue = this.agentFilter;
    this.sessionCache.suretyFilterValue = this.suretyFilter;
    this.sessionCache.categorySelection = this.currentlySelected;
    this.sessionCache.dateSelectionValue = this.dateRangeFilter;
    this.sessionCache.startDateValue = this.startDateFilter ? this.startDateFilter.toISOString() : null;
    this.sessionCache.endDateValue = this.endDateFilter ? this.endDateFilter.toISOString() : null;
    this.sessionCache.quickLinksCollapsed = this.horizontallyCollapsed;
    this.sessionCache.sureties = this.sureties;
    this.sessionCache.agencies = this.agencies;
    this.sessionCache.agents = this.agents;
    this.sessionCache.sortByDateFilterValue = this.sortByDateFilter;
    try {
      await this.cacheService.setInIDB('dashboardCache', this.sessionCache);
    } catch (e) {
      console.error('Error saving to IndexedDB', e);
    }
  }

  async loadFromSessionCache(): Promise<boolean> {
    try {
      const cache = await this.cacheService.getFromIDB('dashboardCache');
      if (cache) {
        this.sessionCache = cache;
        this.sureties = this.sessionCache.sureties;
        this.agencies = this.sessionCache.agencies;
        this.agents = this.sessionCache.agents;
        // this.currentlySelected = this.sessionCache.categorySelection;
        this.dateRangeFilter = this.sessionCache.dateSelectionValue;
        this.startDateFilter = this.sessionCache.startDateValue ? moment(this.sessionCache.startDateValue) : null;
        this.endDateFilter = this.sessionCache.endDateValue ? moment(this.sessionCache.endDateValue) : null;
        this.agencyFilter = this.sessionCache.agencyFilterValue;
        this.agentFilter = this.sessionCache.agentFilterValue;
        this.suretyFilter = this.sessionCache.suretyFilterValue;
        return Promise.resolve(true);
      }
    } catch (e) {
      console.error('Error loading from IndexedDB:', e);
    }
    this.sessionCache = new DashboardCache();
    return Promise.resolve(false);
  }

  dynamicPopupHidden(e) {
    this.dynamicPopupMaxHeight = AppConstants.DEFAULTPOPUPCONFIG.MAXHEIGHT;
    this.dynamicPopupMaxWidth = AppConstants.DEFAULTPOPUPCONFIG.MAXWIDTH;
    this.dynamicPopupDragEnabled = AppConstants.DEFAULTPOPUPCONFIG.DRAGENABLED;
    if (this.selectedCategoryId === 'dashboardcheckinsdue' && e === true && this.lastUpdatedCheckinRowIndex != null) {
        this.dataGrid.instance.getVisibleRows()[this.lastUpdatedCheckinRowIndex].data['Checked In'] = 1;
        this.lastUpdatedCheckinRowIndex = null;
        this.dataGrid.instance.refresh();
    }

    this.dynamicPopupClass = null;
    this.loadGridData();
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        class: 'dx-datagrid-toolbar-button',
        options: {
          icon: 'fal fa-file-excel fa-1x',
          hint: 'Export',
          text: 'Export',
          onClick: () => {
            this.gridService.exportGrid(this.dataGrid, 'DashboardExport - ' + this.currentList);
          },
        },
      },
    );
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.value === 'Dated Debt') {
      e.cellElement.onmouseenter = () => {
        this.tooltip.instance.show(e.cellElement);
      };

      e.cellElement.onmouseleave = () => {
        this.tooltip.instance.hide();
      };
    }
  }

  onDataSourceChange() {
    this.setColumnsByType(this.currentlySelected.text);
  }

  doRefresh() {
    this.cacheService.removeFromIDB('dashboardCache');
    const surety = this.suretyFilter;
    const date = this.dateRangeFilter;
    const start = this.startDateFilter;
    const end = this.endDateFilter;
    const agency = this.agencyFilter;
    const agent = this.agentFilter;

    this.loadFromSessionCache();
    this.sureties = null;
    this.agencies = null;
    this.agents = null;

    this.loadLookupsData();
    this.suretyFilter = surety;
    this.dateRangeFilter = date;
    this.startDateFilter = start;
    this.endDateFilter = end;
    this.agencyFilter = agency;
    this.agentFilter = agent;
    this.loadCategoryCounts();
    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.refresh();
    }
  }

  filterCategories(filterText) {
    this.selectedCategoryId = filterText;
  }

  onChangeCourtDateNotified(e) {
    e.data['Notified Date'] = new Date();
    e.data.Notified = true;
    this.subs.sink = this.bondService.updateCourtDate(e.data.FilterId, {ClientNotified: true, NotifyDate: moment().format('YYYY-MM-DD HH:mm:ss')}).subscribe();
  }

}
