import { catchError, finalize, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { AdiSettings } from '../models/adi-settings';
import { PortoResponse } from '../models/porto-response';

@Injectable()
export class AdiSettingsService {
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  constructor(private http: HttpClient,
              private settings: SettingsService) {
  }

  get(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/adisettings?include=automaticnotificationsettings,reminderconfigurations`).pipe(
      map((res: any) => res),
      catchError((error) => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  getCollectionsReminders(): Observable<any> {
    // console.log('In adi setting service function getCollectionsReminders!');
    const includes = `collectionautomaticnotificationsettings,collectionreminderconfigurations,collectionsnotificationtemplates,collectionreminderconfigurations.paymentoffers`;
    // const includes = `collectionautomaticnotificationsettings,collectionreminderconfigurations,collectionsnotificationtemplates`;
    return this.http.get(`${this.settings.getBaseUrl()}/collectionsadisettings?include=${includes}`).pipe(
      map((res: any) => res),
      finalize(() => {
      }),
      catchError((error) => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  getReminderConfigByDebtorIdInternal(debtorId): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/internalcollectionreminderconfigbydebtor/${debtorId}`).pipe(
      map((res: any) => res.hasOwnProperty('data') ? res.data : undefined),
      catchError(error => throwError(error)));
  }

  save(data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/adisettings`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  saveCollectionReminders(data: any): Observable<any> {
    const includes = `collectionautomaticnotificationsettings,collectionreminderconfigurations,collectionsnotificationtemplates,collectionreminderconfigurations.paymentoffers`;
    return this.http.put(`${this.settings.getBaseUrl()}/collectionsadisettings?include=${includes}`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }


  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/adisettings`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

  getCollectionInvoiceSettings(customerId : string): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/collectioninvoicesettings/${customerId}`).pipe(
      map((res: any) => res),
      catchError((error) => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
  }

}
