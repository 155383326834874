<dx-popup [attr.class]="'popup '+class" [showTitle]="showTitle" [title]="titleText"
          [dragEnabled]="false" [closeOnOutsideClick]="closeOnOutsideClick" [(visible)]="_showConfirmation" [maxWidth]="maxWidth" [maxHeight]="maxHeight"
          [showCloseButton]="showCloseButton">
  <div *dxTemplate="let data of 'content'">
    <div class="ibox-content">
      <div class="row">
      <div class="col-md-12 text-center" *ngIf="!messageHasHtml"><label class="control-label">{{confirmationMessage}}</label><br *ngIf="confirmationMessageImportant !== ''"/><label class="control-labelImportant" *ngIf="confirmationMessageImportant !== ''">{{confirmationMessageImportant}}</label></div>
      <div class="col-md-12" *ngIf="messageHasHtml"><label class="control-label" style="text-align: center;" [innerHtml]="confirmationHtml"></label></div>
    </div>
    </div>
    <br>
    <div class="ibox-content text-center p-md">
      <dx-button id="cancel" type="normal" [width]="noWidth" [text]="noText" icon=""
                 (onClick)="cancel()"></dx-button>
      <dx-button style="margin-left: 20px" id="save" type="default" [width]="yesWidth" [text]="yesText" icon="fal fa-check"
                 (onClick)="confirm()"></dx-button>
    </div>
  </div>
</dx-popup>

