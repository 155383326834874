import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StringUtilities } from '../../shared/StringUtilities';
import { UnsubscribeOnDestroyAdapter } from './../../common/UnsubscribeOnDestroy';
import { LookupService } from './../../services/app-lookup.service';
import { NotifyService } from '../../../app/common/notify/notify.service';


export interface TransactionStatusUpdaterInterface {
  status: string
}

@Component({
  selector: 'app-transaction-status-updater',
  templateUrl: './transaction-status-updater.component.html',
  styleUrls: ['./transaction-status-updater.component.scss'],
})
export class TransactionStatusUpdaterComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  dateFormatter = StringUtilities.dateFormatter;
  maxDate = new Date();
  postedDate = StringUtilities.getCurrentDateString();
  prospectDeclineReasons: any = {};
  markProspectsDeadReason: string;
  errorData: any;

  constructor(
    public dialogRef: MatDialogRef<TransactionStatusUpdaterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionStatusUpdaterInterface,
    private lookupService: LookupService,
    private notify: NotifyService,
  ) {
    super();
   }

  ngOnInit(): void {
    this.subs.sink = this.lookupService.getLookup('prospectdeclinedreasons').subscribe(resp => {
      // console.log('prospectdeclinedreasons', resp.data);
      this.prospectDeclineReasons = resp.data;
    }, error => {
      this.errorData = error;
    });
  }

  saveAndClose(status: string) {
    if(status === 'Client') {
      this.dialogRef.close(this.postedDate);
    } else if(status === 'Dead') {
      this.dialogRef.close(this.markProspectsDeadReason);
    }
  }

}
