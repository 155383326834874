<button mat-button [matMenuTriggerFor]="usermenu" fxHide.lt-md>
  <img class="profile-thumbnail icon-spacer" [src]="currentUser.ProfilePicData || defaultAvatar"/>
  <span fxHide.sm>{{ currentUser.FirstName || 'loading...' }}</span>
</button>
<button mat-icon-button matTooltip="{{ currentUser.FirstName || 'loading...' }}" matTooltipPosition="below"
  [matMenuTriggerFor]="usermenu" fxHide.gt-sm>
  <img class="profile-thumbnail" [src]="currentUser.ProfilePicData || defaultAvatar" />
</button>

<mat-menu #usermenu="matMenu" autoFocus="false" (closed)="resetPreferences()">
  <ng-template matMenuContent>
    <mat-list-item (click)="$event.stopPropagation()">

      <div class="user-settings-dropdown">

        <span *ngIf="!editingProfile">
          <div class="edit-user-settings">
            <img class="profile-md" [src]="currentUser.ProfilePicData || defaultAvatar" alt="" />
            <button mat-icon-button matTooltip="Edit Profile" matTooltipPosition="left" (click)="editProfile()">
              <i class="fas fa-pencil-alt"></i>
            </button>
          </div>
          <h3>{{ currentUser.FirstName + ' ' + currentUser.LastName || 'Unknown Name' }}</h3>
          <p>{{ currentUser.Email || 'Unknown Email Address' }}</p>
        </span>

        <span *ngIf="editingProfile">
          <button class="close-btn" mat-icon-button matTooltip="Close" matTooltipPosition="below"
                  (click)="resetPreferences()">
            <i class="fal fa-times"></i>
          </button>
          <form [formGroup]="profileForm" (ngSubmit)="savePreferences()" (keydown.tab)="$event.stopPropagation()">
            <div class="edit-user-settings">

              <img class="profile-md" [src]="editedUser.ProfilePicData || defaultAvatar" alt="" />

              <input style="display: none" type="file" accept="image/*" #uploader (change)="uploadProfilePic($event)"/>

              <button mat-icon-button matTooltip="New Picture" matTooltipPosition="left" type="button"
                      (click)="uploader.click()">
                <i class="fas fa-camera"></i>
              </button>

            </div>

            <div>
              <mat-form-field>
                <input matInput type="text" formControlName="FirstName" name="FirstName" [(ngModel)]="editedUser.FirstName"
                       placeholder="First Name"/>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="text" formControlName="LastName" name="LastName" [(ngModel)]="editedUser.LastName" placeholder="Last Name"/>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="email" formControlName="Email" name="Email" [(ngModel)]="editedUser.Email" placeholder="Email" disabled="true" />
              </mat-form-field>
              <mat-form-field>
                <input matInput type="tel" formControlName="MobileNumber" name="MobileNumber" [(ngModel)]="editedUser.MobileNumber"
                       placeholder="Phone Number"/>
              </mat-form-field>
              <mat-checkbox color='primary' formControlName='Enable2FA' name='Enable2FA' [checked]="editedUser.Enable2FA">Enable Email 2FA</mat-checkbox>

              <div class="passwordreset">
                <button mat-button color="primary" *ngIf="!resetPassword" (click)="forgotPassword()"
                        [disabled]="sendingReset">
                  <span *ngIf="!sendingReset">Reset Password</span>
                  <i class="fas fa-key icon-spacer" *ngIf="!sendingReset"></i>

                  <span *ngIf="sendingReset">Sending Email</span>
                  <i class="fas fa-circle-notch fa-spin" *ngIf="sendingReset"></i>
                </button>
                <p *ngIf="resetPassword">
                  Please check your email!
                  <i class="far fa-envelope-open"></i>
                </p>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-around center">
                <button type="submit" mat-raised-button color="primary" [disabled]="savingProfile">
                  Save
                </button>
                <button type="reset" mat-button [disabled]="savingProfile" (click)="resetPreferences()">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </span>

      </div>
    </mat-list-item>
  </ng-template>
  <span *ngIf="!editingProfile">
    <mat-divider></mat-divider>

    <mat-list style="padding-top: 0;">
      <a mat-list-item *ngIf="subscribedToBail"
        href="//captira.zendesk.com/"
        target="_blank"
        rel="noopener noreferrer">
        <i class="far fa-question-circle fa-fw"></i><span>Help</span>
      </a>
      <a mat-list-item href="//captira.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
        <i class="far fa-comment-dots fa-fw"></i><span>Feedback</span>
      </a>
      <a mat-list-item [routerLink] (click)="logout()" class="text-danger">
        <i class="far fa-power-off fa-fw"></i><span>Log Out</span>
      </a>
    </mat-list>
  </span>
</mat-menu>
