import { Person } from '../models/person';
import { Defendant } from '../models/defendant';
import { Note } from '../models/note';

export class DefendantSaver {

  errors = [];
  completionPercentage = 0;
  public goodResult = false;
  mainThis = this;
  public haveChanges = false;

  constructor() {
  }

  // isIdOk(id) {
  //   id = parseInt(id, 10);
  //   return (isNaN(id));
  //
  //
  //   // if(isNullOrUndefined(id))
  //   // {
  //   //   return false;
  //   // }
  //   // else
  //   // {
  //   //   id = parseInt(id, 10);
  //   //   return (isNaN(id));
  //   // }
  //   //return true
  //
  // }

  isExisting(id: string): boolean {
    if (id == null || id === '') {
      return false;
    } else {
      return true;
    }
  }

  public checkForChanges(def: Defendant) {
    const localThis = this;
    // console.log('defendant has changes begin', localThis.haveChanges);

    localThis.haveChanges = def.checkForChanges() ? true : localThis.haveChanges;

    def.lawsuits.forEach((lawsuit) => {
      if (lawsuit.Id == null) {
        lawsuit.isAdded = true;
        localThis.haveChanges = true;
      }
      localThis.haveChanges = lawsuit.checkForChanges() ? true : localThis.haveChanges;
    });
    localThis.haveChanges = def.person.checkForChanges() ? true : localThis.haveChanges;

    // console.log('transaction has changes begin', localThis.haveChanges);

    def.transactions.forEach((transaction) => {
      if (transaction.Id == null || transaction.Id === '') {
        transaction.isAdded = true;
        localThis.haveChanges = true;
      }

      // console.log('transaction is deleted', transaction.Id, transaction.isDeleted);
      localThis.haveChanges = transaction.checkForChanges() ? true : localThis.haveChanges;
      // console.log('after transaction checkforchanges', transaction.Id, transaction.isDeleted);

      transaction.collaterals.forEach((child) => {
        if (!this.isExisting(child.Id)) {
          //// console.log('removing col id', child.Id);
          child.Id = null;
        }
        if (child.Id == null || child.Id === '') {
          child.isAdded = true;
        }
        localThis.haveChanges = child.checkForChanges() ? true : localThis.haveChanges;
      });

      // console.log('defendant has changes after collaterals', localThis.haveChanges);

      transaction.notes.forEach((child) => {
        if (!(child instanceof Note)) {
          child = new Note(child);
          child.isAdded = true;
          localThis.haveChanges = true;
        }
        if (child.Id == null || child.Id === '') {
          child.isAdded = true;
          localThis.haveChanges = true;
        }

        if (!child.SourceId) {
          child.SourceId = transaction.Id;
        }
        if (!child.SourceType) {
          child.SourceType = 'DefendantTransactions';
        }
      
        localThis.haveChanges = child.checkForChanges() ? true : localThis.haveChanges;
//        // console.log('note', child);
      });

      // console.log('defendant has changes after notes', localThis.haveChanges);

      transaction.documents = transaction.documents.filter(doc => doc.Name !== 'Mugshot');
      transaction.documents.forEach((child) => {
        child.SourceId = transaction.Id;
        child.SourceType = 'DefendantTransactions';
        // Check for mugshot, we don't want to trigger changes for mugshot
        let doNotCall = !child.Id && child.Name === 'Mugshot';

        // If a document is uploaded from the document section
        if(!doNotCall) {
          if (!child.Id) {
            child.isAdded = true;
            localThis.haveChanges = true;
          }

          localThis.haveChanges = child.checkForChanges() ? true : localThis.haveChanges;
        }
      });

      // console.log('defendant has changes after documents', localThis.haveChanges);

      transaction.contacts.forEach((child) => {
        if (!this.isExisting(child.Id)) {
          // console.log('removing col id', child.Id);
          child.Id = null;
        }
        if (child.Id == null || child.Id === '') {
          child.isAdded = true;
        }
        localThis.haveChanges = child.checkForChanges() ? true : localThis.haveChanges;

        if (child.person.Id == null) {
          child.person.isAdded = true;
          localThis.haveChanges = true;
        }
      });

      // console.log('defendant has changes after contacts', localThis.haveChanges);

      transaction.bonds.forEach((child) => {
        if (!this.isExisting(child.Id)) {
          // console.log('removing col id', child.Id);
          child.Id = null;
        }

        if (transaction.Id !== null && (child.TransactionId == null || child.TransactionId === '')) {
          child.TransactionId = transaction.Id;
        }
        if (child.Id == null || child.Id === '') {
          child.isAdded = true;
          localThis.haveChanges = true;
        }
//        // console.log('bond', child);
        localThis.haveChanges = child.checkForChanges() ? true : localThis.haveChanges;
//        // console.log('bond after checkforchanges', child);

        child.courtdates.forEach((courtdate) => {
  //        // console.log('checking court date', courtdate);
          if (child.Id == null || child.Id === '') {
            child.isAdded = true;
            localThis.haveChanges = true;
          }
          localThis.haveChanges = courtdate.checkForChanges() ? true : localThis.haveChanges;

        });
      });

      // console.log('defendant has changes after bonds', localThis.haveChanges);

    });

    // console.log('transaction has changes end', localThis.haveChanges);


  }

}

