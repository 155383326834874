import {catchError, map} from 'rxjs/operators';
import {throwError,  Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {ApiUtilities} from '../shared/ApiUtilities';
import {CheckInSchedule} from '../models/checkinschedule';
import {HttpClient} from '@angular/common/http';
import {PaymentSchedule} from '../models/paymentschedule';

@Injectable()
export class CheckInScheduleService {
  private headers = new Headers(ApiUtilities.DEFAULT_HEADERS);

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getById(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/checkinschedule/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getByDefendantId(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/checkinschedulebydefendant/${id}?include=details`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  save(id: any, data: any): Observable<any> {
/*    // console.log('save id', id);
    // console.log('save data', data);
    // console.log('save json data', JSON.stringify(data));*/

    return this.http.put(`${this.settings.getBaseUrl()}/checkinschedule/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/checkinschedule`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/checkinschedule/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  recordCheckIn(defendantId, checkInDate, comment): Observable<any> {

    const checkin = {'DefendantId': defendantId, 'CheckInDate': checkInDate, 'Comment': comment};
    // console.log('record checkin request', checkin)

    return this.http.put(`${this.settings.getBaseUrl()}/checkinschedules/checkin/record`, checkin).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getLocationCheckinHistory(defendantId): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/checkins/history/defendant/${defendantId}?limit=0`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  deleteCheckIn(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/checkins/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  findCheckinById(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/checkins/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  updateCheckin(id: any, data: any): Observable<any> {
    return this.http.patch(`${this.settings.getBaseUrl()}/checkins/${id}`, data).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  createCheckin(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/checkins`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

}
