import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppConstants } from '../../shared/AppConstants';
import { ReminderService } from '../../services/reminder.service';
import { DxDataGridComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../common/notify/notify.service';


@Component({
  selector: 'app-reminder-number-buyer',
  templateUrl: './reminder-number-buyer.component.html',
  styleUrls: ['./reminder-number-buyer.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class ReminderNumberBuyerComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() inPopup = true;
  @Input() reminderNumbers: any = [];
  @ViewChild('valGroup', { static: true }) valGroup: DxValidationGroupComponent;
  @ViewChild(DxDataGridComponent, { static: true }) phoneGrid: DxDataGridComponent;
  @Output() onSuccess = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();

  phoneMask = AppConstants.PHONE_MASK;
  phonePattern: any = AppConstants.PHONE_PATTERN;
  phoneRules: any = AppConstants.PHONE_RULES;
  phoneNumbers: any = [];
  areacode = '';
  gridVisible = false;
  activateButtonDisabled = false;

  constructor(
    private reminderService: ReminderService,
    private notifyService: NotifyService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.reminderNumbers.length === 0) {
      this.subs.sink = this.reminderService.getReminderNumbers().subscribe(res => {
        this.reminderNumbers = res.data;
        if (this.reminderNumbers !== undefined && this.reminderNumbers.length > 0) {
          this.onSuccess.emit(true);
        }
      });
    }
  }

  searchByAreaCode(e) {
    if (this.valGroup.instance.validate().isValid) {
      this.phoneNumbers = [];
      if (!this.gridVisible) {
        this.phoneGrid.instance.beginCustomLoading('Loading...');
      }
      this.subs.sink = this.reminderService.getReminderNumbersByAreaCode(this.areacode).subscribe(res => {
        if (res && res.length > 0) {
          this.phoneNumbers = res;
        }
        this.phoneGrid.instance.endCustomLoading();
        this.gridVisible = true;
      }, error => {
        console.error('error getting numbers', error);
      });
    }
  }

  activatePhone(e) {
    this.activateButtonDisabled = true;
    const data = {'Number': e.PhoneNumber};
    this.gridVisible = false;
    this.subs.sink = this.reminderService.activateReminderNumber(data).subscribe(res => {
      this.onSuccess.emit(true);
      this.reminderNumbers.push(res.data);
      this.activateButtonDisabled = false;
      this.notifyService.toast('Activated');
    }, error => {
      this.onError.emit(error);
      this.activateButtonDisabled = false;
      this.gridVisible = true;
    });
  }

  onRowPrepared(e) {
    if (e.rowType === 'header') {
      e.rowElement.style.display = 'none';
    }
  }

}
