import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AutofocusDirective } from '../directives/autofocus.directive';
import { SingleselectDirective } from '../directives/singleselect.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { MomentModule } from 'ngx-moment';
import { MugshotComponent } from '../views/common/mugshot/mugshot.component';
import { CarolComponent } from '../views/collections/carol/carol.component';
import { RouterModule } from '@angular/router';
import { YesNoConfirmationModule } from '../components/controls/yes-no-confirmation/yes-no-confirmation.module';
import { ToastComponent } from '../components/common/toast/toast.component';
import { SearchResultsModule } from '../components/common/searchresults/search-results.module';
import { BottombarComponent } from '../components/common/bottombar/bottombar.component';
import { AngularGooglePlaceModule } from '../components/common/angular-google-place';
import { NgxStripeModule } from 'ngx-stripe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NuveiCardSetupComponent } from '../views/common/creditcard/nuvei-card-setup/nuvei-card-setup.component';
import { WarningDialogComponent } from '../components/controls/warning-dialog/warning-dialog.component';
import { UserMngrComponent } from '../views/defendant-manager/user-mngr/user-mngr.component';
import { CountyLookupComponent } from '../views/common/county/county.component';
import { CityLookupComponent } from '../views/common/city/city.component';
import { RateLookupComponent } from '../views/common/rate/rate.component';
import { UsstateLookupComponent } from '../views/common/usstate/usstate.component';
import { DefendantnotesComponent } from '../views/defendant/defendantnotes/defendantnotes.component';
import { SsnComponent } from '../components/common/ssn/ssn.component';
import { EmailListComponent } from '../views/common/email-list/email-list.component';
import { EmployerComponent } from '../views/common/employer/employer.component';
import { VehicleComponent } from '../views/common/vehicle/vehicle.component';
import { PowersComponent } from '../views/common/powers/powers.component';
import { NewPowerComponent } from '../views/new-power/new-power.component';
import { AdiTemplatesComponent } from '../views/adiv2/adi-templates/adi-templates.component';
import { DonotcontactComponent } from '../views/adiv2/donotcontact/donotcontact.component';
import { DynamicPopupModule } from '../components/common/dynamic-popup/dynamic-popup.module';
import { CompanybillingComponent } from '../views/system/forms/companybilling/companybilling.component';
import { DefendantZoomConfigComponent } from '../views/defendant-zoom-config/defendant-zoom-config.component';
import { CustomTableToXlsxComponent } from '../components/common/custom-table-to-xlsx/custom-table-to-xlsx.component';
import { LocationHistoryComponent } from '../views/location-history/location-history.component';
import { DefendantMonitoringComponent } from '../views/defendant-monitoring/defendant-monitoring.component';
import { UsersComponent } from '../views/system/forms/users/users.component';
import { RiskassessmentlistComponent } from '../views/system/lists/riskassessmentlist/riskassessmentlist.component';
import { ZoomConfigComponent } from '../views/defendant-manager/zoom-config/zoom-config.component';
import { DefendantNotesPopupComponent } from '../views/defendant/defendantnotes/defendantnotespopup/defendantnotespopup.component';
import { PdfViewerComponent } from '../components/common/pdf-viewer/pdf-viewer.component';
import { ReportpickerComponent } from '../views/common/reportpicker/reportpicker.component';
import { LoaderComponent } from '../components/common/loader/loader.component';
import { OnboardingModule } from '../onboarding/onboarding.module';
import { PdfDownloaderComponent } from '../components/common/pdf-downloader/pdf-downloader.component';
import { DeleteListItemPopupComponent } from '../views/system/lists/delete-list-item-popup/delete-list-item-popup.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxProgressBarModule } from 'devextreme-angular/ui/progress-bar';
import { DxToastModule } from 'devextreme-angular/ui/toast';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxBoxModule } from 'devextreme-angular/ui/box';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxMultiViewModule } from 'devextreme-angular/ui/multi-view';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { MobileFocusDirective } from '../directives/mobile-focus.directive';
import { StickyPaginationDirective } from '../directives/sticky-pagination.directive';
import { ToggleComponent } from '../components/controls/toggle/toggle.component';
import { UserAuditComponent } from '../views/system/forms/users/user-audit/user-audit.component';
import { ButtonbarModule } from '../components/common/buttonbar/buttonbar.module';
import { PipesModule } from './pipes.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { FileNameDirective } from '../directives/file-name.directive';
import { DocumentVersionComponent } from '../views/defendant/document/document-version/document-version.component';
import { FileSizePipe } from './file-size.pipe';
import { DocumentViewerComponent } from '../views/defendant/document/document-viewer/document-viewer.component';
import { DocumentDialogComponent } from '../views/defendant/document/document-dialog/document-dialog.component';
import { DocumentUploaderComponent } from '../views/defendant/document/document-uploader/document-uploader.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ErrorpopupModule } from '../components/controls/errorpopup/errorpopup.module';
import { PhoneListModule } from '../views/common/phone-list/phone-list.module';
import { LookupModule } from '../views/common/lookup/lookup.module';
import { DebounceClickModule } from '../directives/debounce-click/debounce-click.module';
import { CarolMessagePipePipe } from '../carol-message-pipe.pipe';
import { PortalModule } from '@angular/cdk/portal';
import { SmartaddressModule } from '../views/common/smartaddress/smartaddress.module';
import { DocumentModule } from '../views/defendant/document/document.module';
import { InputFocusModule } from './../directives/input-focus/input-focus.module';
import { CollectionMetricsComponent } from '../views/collections/collection-metrics/collection-metrics.component';
import { ToggleSlideModule } from '../components/controls/toggle-slide/toggle-slide.module';
import { PdfDialogComponent } from '../components/common/pdf-dialog/pdf-dialog.component';
import { DefendantNotesDialogComponent } from '../views/defendant/defendantnotes/defendant-notes-dialog/defendant-notes-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StickyHeaderDirective } from '../directives/sticky-header.directive';
import { WatchIdentifierPipe } from './watch-identifier.pipe';

@NgModule({
  declarations: [
    PdfDownloaderComponent,
    LoaderComponent,
    ReportpickerComponent,
    PdfViewerComponent,
    DefendantNotesPopupComponent,
    ZoomConfigComponent,
    RiskassessmentlistComponent,
    UsersComponent,
    UserMngrComponent,
    DefendantMonitoringComponent,
    LocationHistoryComponent,
    CustomTableToXlsxComponent,
    DefendantZoomConfigComponent,
    CompanybillingComponent,
    DonotcontactComponent,
    AdiTemplatesComponent,
    NewPowerComponent,
    PowersComponent,
    VehicleComponent,
    EmployerComponent,
    EmailListComponent,
    SsnComponent,
    DefendantnotesComponent,
    UsstateLookupComponent,
    RateLookupComponent,
    CityLookupComponent,
    CountyLookupComponent,
    UserMngrComponent,
    BottombarComponent,
    AutofocusDirective,
    SingleselectDirective,
    MugshotComponent,
    CarolComponent,
    ToastComponent,
    NuveiCardSetupComponent,
    WarningDialogComponent,
    DeleteListItemPopupComponent,
    MobileFocusDirective,
    StickyPaginationDirective,
    ToggleComponent,
    UserAuditComponent,
    FileNameDirective,
    DocumentVersionComponent,
    FileSizePipe,
    DocumentViewerComponent,
    DocumentDialogComponent,
    DocumentUploaderComponent,
    CarolMessagePipePipe,
    CollectionMetricsComponent,
    PdfDialogComponent,
    DefendantNotesDialogComponent,
    StickyHeaderDirective,
    WatchIdentifierPipe,
  ],
  imports: [
    YesNoConfirmationModule,
    SmartaddressModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatProgressSpinnerModule,
    RouterModule,
    AngularGooglePlaceModule,
    NgxStripeModule.forRoot(),
    DynamicPopupModule,
    OnboardingModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DxPopupModule,
    DxTextAreaModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxScrollViewModule,
    DxNumberBoxModule,
    DxValidatorModule,
    DxTabPanelModule,
    DxTextBoxModule,
    DxLoadIndicatorModule,
    DxValidationGroupModule,
    DxRadioGroupModule,
    DxFormModule,
    DxProgressBarModule,
    DxFileUploaderModule,
    DxBoxModule,
    DxToolbarModule,
    DxMultiViewModule,
    DxToastModule,
    DxLoadPanelModule,
    DxDateBoxModule,
    DxAccordionModule,
    DxTooltipModule,
    DxDropDownBoxModule,
    DxContextMenuModule,
    ButtonbarModule,
    PipesModule,
    AngularDraggableModule,
    GoogleMapsModule,
    ErrorpopupModule,
    PhoneListModule,
    LookupModule,
    DebounceClickModule,
    PortalModule,
    DocumentModule,
    InputFocusModule,
    ToggleSlideModule,
    DragDropModule,
  ],
  exports: [
    PdfDownloaderComponent,
    LoaderComponent,
    ReportpickerComponent,
    PdfViewerComponent,
    DefendantNotesPopupComponent,
    ZoomConfigComponent,
    RiskassessmentlistComponent,
    UserMngrComponent,
    UsersComponent,
    DefendantMonitoringComponent,
    LocationHistoryComponent,
    CustomTableToXlsxComponent,
    DefendantZoomConfigComponent,
    CompanybillingComponent,
    AdiTemplatesComponent,
    DonotcontactComponent,
    NewPowerComponent,
    PowersComponent,
    VehicleComponent,
    EmployerComponent,
    EmailListComponent,
    SsnComponent,
    DefendantnotesComponent,
    UsstateLookupComponent,
    RateLookupComponent,
    CityLookupComponent,
    CountyLookupComponent,
    UserMngrComponent,
    BottombarComponent,
    SearchResultsModule,
    CommonModule,
    AutofocusDirective,
    SingleselectDirective,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MomentModule,
    MugshotComponent,
    CarolComponent,
    YesNoConfirmationModule,
    ToastComponent,
    NuveiCardSetupComponent,
    DeleteListItemPopupComponent,
    MobileFocusDirective,
    StickyPaginationDirective,
    ToggleComponent,
    FileNameDirective,
    DocumentUploaderComponent,
    DocumentModule,
    SmartaddressModule,
    InputFocusModule,
    StickyHeaderDirective,
    WatchIdentifierPipe,
  ],
  providers: [CurrencyPipe],
})
export class SharedModule { }
