import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingDialogComponent } from './onboarding-dialog/onboarding-dialog.component';
import { MaterialModule } from '../material.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { DxAccordionModule, DxMultiViewModule, DxNumberBoxModule, DxPopupModule, DxSwitchModule, DxTemplateModule, DxTextBoxModule, DxTooltipModule } from 'devextreme-angular';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ConfirmSetupPopupComponent } from './confirm-setup-popup/confirm-setup-popup.component';
import { OnboardingSupportPopupComponent } from './onboarding-support-popup/onboarding-support-popup.component';
import { FinalizePopupComponent } from './finalize-popup/finalize-popup.component';
import { OnboardingToastComponent } from './onboarding-toast/onboarding-toast.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';


@NgModule({
  declarations: [OnboardingDialogComponent, ConfirmSetupPopupComponent, OnboardingSupportPopupComponent, FinalizePopupComponent, OnboardingToastComponent],
  imports: [
    CommonModule,
    MaterialModule,
    DxTemplateModule,
    DxTextBoxModule,
    MatIconModule,
    DxPopupModule,
    DxMultiViewModule,
    RouterModule,
    DxAccordionModule,
    DxSwitchModule,
    DxNumberBoxModule,
    DxTooltipModule,
    MatSnackBarModule
  ],
  exports: [
    OnboardingDialogComponent
  ],
})
export class OnboardingModule { }
