import { NgModule } from '@angular/core';
import { DefendantPreviewComponent } from './defendant-preview.component';
import { DynamicPopupModule } from '../../components/common/dynamic-popup/dynamic-popup.module';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { SharedModule } from '../../shared/shared.module';
import { MobileLinkModule } from '../../components/common/mobile-link/mobile-link.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PipesModule } from '../../shared/pipes.module';
import { DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTooltipModule } from 'devextreme-angular';
import { RouterModule } from '@angular/router';
// import {CommonModule} from '@angular/common';
// import {RouterModule} from '@angular/router';
// import {FormsModule} from '@angular/forms';

// import {MatButtonModule} from '@angular/material/button';
// import {MatIconModule} from '@angular/material/icon';
// import {MatCardModule} from '@angular/material/card';
// import {MatListModule} from '@angular/material/list';
// import {MatMenuModule} from '@angular/material/menu';
// import {MatInputModule} from '@angular/material/input';
// import {MatFormFieldModule} from '@angular/material/form-field';
// import {MatDatepickerModule} from '@angular/material/datepicker';
// import {MatNativeDateModule} from '@angular/material'
// import {MatCheckboxModule} from '@angular/material/checkbox';
// import {MatDialogModule} from '@angular/material/dialog';
// import {MatSelectModule} from '@angular/material/select';
// import {MatChipsModule} from '@angular/material/chips';
// import {MatDividerModule} from '@angular/material/divider';

// import {SharedloadModule} from '../../components/sharedload/sharedload.module';
// import { PhonePipe } from '../../shared/phone.pipe';

// import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';

// import {DefendantPreviewComponent} from './defendant-preview.component';

// import {BondStatusUpdaterComponent} from './bond-status-updater/bond-status-updater.component';
// import {DxPopupModule, DxScrollViewModule} from 'devextreme-angular';
// import {CourtDateSchedulerComponent} from './court-date-scheduler/court-date-scheduler.component';
// import {FlexLayoutModule} from '@angular/flex-layout';
// import {DynamicPopupModule} from '../../components/common/dynamic-popup/dynamic-popup.module';

@NgModule({

  imports: [
    DynamicPopupModule,
    PipesModule,
    MatCardModule,
    MatListModule,
    SharedModule,
    MobileLinkModule,
    NgxSkeletonLoaderModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTooltipModule,
    DxSelectBoxModule,
    RouterModule,
    // CommonModule,
    // RouterModule,
    // MatButtonModule,
    // MatIconModule,
    // MatCardModule,
    // MatListModule,
    // MatMenuModule,
    // MatInputModule,
    // MatFormFieldModule,
    // MatDatepickerModule,
    // MatNativeDateModule,
    // MatCheckboxModule,
    // MatDialogModule,
    // MatSelectModule,
    // // SharedloadModule,
    // FormsModule,
    // MatChipsModule,
    // MatDividerModule,
    // NgxSkeletonLoaderModule,
    // DxPopupModule,
    // DxScrollViewModule,
    // FlexLayoutModule,
    // DynamicPopupModule,
  ],
  declarations: [
    // PhonePipe,
    DefendantPreviewComponent,
    // BondStatusUpdaterComponent,
    // CourtDateSchedulerComponent,
  ],
  exports: [
     DefendantPreviewComponent,
  ],
 })
export class DefendantPreviewModule {
}
