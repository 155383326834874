import { Component, EventEmitter, Input, OnInit, Output, Type, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ReportService } from '../../services/report.service';
import { AppConstants } from '../../shared/AppConstants';
import { DefendantService } from '../../services/defendant.service';
import { RouteDataService } from '../../services/routedata.service';
import { FormgroupsService } from '../../services/formgroups.service';
import { EventService } from '../../services/event.service';

import { Defendant } from '../../models/defendant';

import { ReportCompilationRequest } from '../../models/reportcompilationrequest';

import { combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';

import { IDynamicPopupComponent } from '../../components/common/dynamic-popup/dynamic-popup.component';
import { PdfDownloaderComponent } from '../../components/common/pdf-downloader/pdf-downloader.component';
import { PdfViewerComponent } from '../../components/common/pdf-viewer/pdf-viewer.component';

import { UnsubscribeOnDestroyAdapter } from '../../common/UnsubscribeOnDestroy';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BillingService } from '../../services/billing.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroupsDialogComponent } from '../reporting/form-groups-dialog/form-groups-dialog.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { PdfDialogComponent, PdfDialogData } from '../../components/common/pdf-dialog/pdf-dialog.component';
import { ConfirmationComponent } from '../../components/controls/confirmation/confirmation.component';
import { Contact } from '../../models/contact';
import { Bail } from '../../models/bail';
import Guid from 'devextreme/core/guid';
import { DocumentService } from '../../services/document.service';
import { NotifyService } from '../../common/notify/notify.service';

@Component({
  selector: 'app-defendant-forms',
  templateUrl: './defendant-forms.component.html',
  styleUrls: ['./defendant-forms.component.scss'],
  entryComponents: [PdfDownloaderComponent, PdfViewerComponent],
  animations: [
    trigger('dropDownAnimation', [
      state('initial', style(
        {transform: 'translateY(-100%)', height: '0px'},
      )),
      state('final', style(
        {transform: 'translateY(0%)', height: '50px'},
      )),
      transition('initial=>final', animate('100ms')),
      transition('final=>initial', animate('100ms')),
    ]),
  ],
})
export class DefendantFormsComponent extends UnsubscribeOnDestroyAdapter implements OnInit, IDynamicPopupComponent {

  @Input() userSelectableDefendant = false;
  @ViewChild('formsGrid', {static: true}) dataGrid: DxDataGridComponent;

  @Output() showFormGroups = new EventEmitter<boolean>();
  transactionIndex = 0;
  defendant: Defendant;
  defendantId: any;
  defendantName: any;
  contacts: Contact[];
  transactionId: any;
  transactionList: any[] = [];
  formGroupSource: any[] = [];
  selectedReports: any[] = [];
  currentSelectdFormGroup: any = null;
  inDialog = false;

  dynamicData: any;
  onClose = new Subject<boolean>();

  actionButtonText = 'Select defendant and forms';

  formsDataSource: any;
  selectedReportIds: any[] = [];

  dynamicPopupClass: Type<IDynamicPopupComponent>;
  dynamicPopupData: PdfDialogData;
  dynamicPopupTitle = '';
  dynamicPopupVisible = false;
  dynamicPopupShowTitle = false;
  inDynamicPopup = false;
  tooltipVisible = false;

  reportsToExclude = ['Rap Sheet'];
  optionsClass = 'close-options';
  currentOptionsState = 'initial';

  generateBlankForms = false;

  subscribedToEsign: boolean;
  isExtraSmall: boolean;
  formGroupValue = ''; // this will be used to set value which we get from mat dialog
  isDisabled = false;

  constructor(
    private defendantService: DefendantService,
    private reportService: ReportService,
    private routeDataService: RouteDataService,
    private formGroupService: FormgroupsService,
    private eventService: EventService,
    private billingService: BillingService,
    private dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver,
    private documentService: DocumentService,
    private notifyService: NotifyService,
  ) {
    super();
    this.isExtraSmall = breakpointObserver.isMatched(Breakpoints.XSmall);
    // TODO: use input parameters to set context (print/set online bonding/etc)
  }

  get previewFormsDisabled(): boolean {
    return !((!!this.defendantId && !!this.transactionId && this.dataGrid.selectedRowKeys.length !== 0) || this.generateBlankForms);
  }


  // Pre-select reports on form group change
  selectForms() {
    this.dataGrid.instance.deselectAll();
    let selectedForms: any[] = [];
    selectedForms = this.selectedReports.map(formData => {
      return formData.ReportId;
    });

    if(selectedForms.length > 0) {
      this.selectedReportIds = selectedForms;
      this.dataGrid.instance.selectRows(selectedForms, true);
      // Get the selected forms from the list
      let selectedFormArr: any[] = [];

      // Created the selected form array
      selectedForms.forEach((selectedForm) => {
        const fltReport = this.formsDataSource.find(report => report.ReportId === selectedForm);
        if (!!fltReport) {
          selectedFormArr.push(fltReport);
        }
      });

      // Sort these
      selectedFormArr.sort((a, b) => {
        if (a.ReportName >= b.ReportName) {
          return -1;
        } else {
          return 1;
        }
      })

      // Now filter out the selected forms from the main source
      this.formsDataSource = this.formsDataSource.filter(element => !selectedFormArr.includes(element));

      // Now move the selected form group in front of the main source array so they are on top
      selectedFormArr.forEach((selectedForm) => {
        this.formsDataSource.unshift(selectedForm);
      })
    }
  }

  // Get all form groups
  getAllFormGroups(reloadFormsOfFormGroup = false) {
    this.subs.sink = this.formGroupService.getAllFormGroups().subscribe((response: any) => {
      this.formGroupSource = response.data;
      if (this.currentSelectdFormGroup && reloadFormsOfFormGroup) {
        this.getFormsOfFormGroupsAndPerformSelect(this.currentSelectdFormGroup);
      } else { // In case of delete form group from Form Group tab
        this.dataGrid.instance.deselectAll();
      }
    });
  }

  // Get all forms inside a form group and make them selected
  getFormsOfFormGroupsAndPerformSelect(formGroupId) {
    this.subs.sink = this.formGroupService.getAllForms(formGroupId).subscribe((response: any) => {
      this.selectedReports = response.data.reports.data;
      // When a new form group is selected we first sort by favourites so that after new selected form group are favourites
      this.sortForms();

      // new we call the selection
      this.selectForms();
    });
  }

  // Handle form group change event
  formGroupChanged(event) {
    if (event.value) { // Option selected
      this.currentSelectdFormGroup = event.value;
      this.getFormsOfFormGroupsAndPerformSelect(event.value);
    } else { // Selection has been removed
      this.dataGrid.instance.deselectAll();
      // Resort incase there are favourites
      this.sortForms();
    }
  }

  openFormGroupsPopup() {
    const dialogConfig: MatDialogConfig = {};
    if (this.isExtraSmall) {
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
    } else {
      dialogConfig.width = '80%';
      dialogConfig.height = '98vh';
    }
    dialogConfig.data = {
      inDialog: this.inDialog,
    };
    const formGroupsDialog = this.dialog.open(FormGroupsDialogComponent, dialogConfig);
    this.subs.sink = formGroupsDialog.afterClosed().subscribe(currentId => {
      if(currentId && currentId !== '') {
        this.dataGrid.instance.deselectAll();
        // Assign the newly created form id, this will select the form group in select box
        this.formGroupValue = currentId;
      }
    })
  }

  ngOnInit() {
    if (!!this.dynamicData && 'contacts' in this.dynamicData) {
      this.contacts = this.dynamicData.contacts;
    }
    if (!!this.dynamicData && 'defendant' in this.dynamicData) {
      this.defendant = this.dynamicData.defendant;
    }
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_ESIGN).subscribe(subscribed => {
      this.subscribedToEsign = subscribed;
    });
    if (!this.userSelectableDefendant) {
      this.defendantId = this.routeDataService.getValue(
        AppConstants.DEFENDANT_ID_KEY,
      );
      if (!this.defendant?.Id) {
        this.defendant = this.routeDataService.getObject(
          AppConstants.DEFENDANT_KEY,
        );
      }
      this.defendantName = this.routeDataService.getValue(
        AppConstants.DEFENDANT_NAME_KEY,
      );
      this.transactionId = this.routeDataService.getValue(
        AppConstants.TRANSACTION_ID_KEY,
      );
      this.actionButtonText = 'Select Forms to Preview';
    } else {
      this.actionButtonText = this.getActionButtonText(
        this.generateBlankForms,
        this.defendantId,
        this.transactionId,
        this.dataGrid.selectedRowKeys?.length || 0);
    }
    // We need to grab the index of the transactionId
    if (!!this.transactionId) {
      for (let i = 0; i < this.defendant.transactions.length; i++) {
        if (this.defendant.transactions[i].Id === this.transactionId) {
          this.transactionIndex = i;
          break;
        }
      }
    }

    // Subscribe to event when form group added or removed
    this.subs.sink = this.eventService.onfromGroupUpdated$.subscribe(response => {
      this.getAllFormGroups(response);
    });

    this.getAllFormGroups();

    this.subs.sink = this.reportService.getReports(true).subscribe(response => {

      this.formsDataSource = response.data.filter(
        report => report.ReportTypeSub1 === 'RetailDefendantTransaction' && !this.reportsToExclude.includes(report.ReportCategory),
      );
      this.setFavorites();
    });
  }

  setFavorites() {
    const self = this;
    this.subs.sink = this.reportService.getAllReportFavorites().subscribe(response => {
      if (response.data) {
        response.data.forEach((favorite) => {
          const fltReport = self.formsDataSource.find(report => report.ReportId === favorite.ReportId);
          if (!!fltReport) {
            fltReport.IsFavorite = true;
          }
        });
        this.sortForms();
      }
    });
  }

  sortForms() {
    this.formsDataSource.sort((a, b) => {
      if (a.IsFavorite && b.IsFavorite) {
        if (a.ReportName >= b.ReportName) {
          return 1;
        } else {
          return -1;
        }
      } else if (a.IsFavorite) {
        return -1;
      } else if (b.IsFavorite) {
        return 1;
      } else {
        if (a.ReportName >= b.ReportName) {
          return 1;
        } else {
          return -1;
        }
      }
    });
  }

  onSelectionChanged(event: any) {
    let duplicateDoc = false;
    this.selectedReportIds = event.selectedRowsData.map(report => report.ReportId);
    for (const reportId of event.currentSelectedRowKeys) {
      for (const report of event.selectedRowsData) {
        if (report.ReportId === reportId) {
          for (const doc of this.defendant.transactions[this.transactionIndex].documents) {
            if (report.ReportName === doc.Name && !doc.isDeleted) {
              duplicateDoc = true;
              break;
            }
          }
        }
      }
    }

    if (duplicateDoc) {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        data: {
          title: 'Duplicate Document',
          message: `This form has already been included in this transaction. Do you want to create a 2nd copy?`,
        },
        maxWidth: '500px',
      });
      this.subs.sink = dialogRef.afterClosed().subscribe(accept => {
        if (!accept) {
          this.dataGrid.instance.deselectRows(event.currentSelectedRowKeys);
          this.selectedReportIds = this.selectedReportIds.filter(id => !event.selectedRowKeys.includes(id));
        }
      });
    }

    this.actionButtonText = this.getActionButtonText(
      this.generateBlankForms,
      this.defendantId,
      this.transactionId,
      this.dataGrid.selectedRowKeys?.length || 0);
  }

  onToolbarPreparing({ toolbarOptions }) {
    const toolbarItems = toolbarOptions.items;

    const searchPanel = toolbarItems.find(item => item.name === 'searchPanel');

    if (searchPanel) {
      searchPanel.location = 'after';
      searchPanel.alignment = 'start';
    }
  }
  onDynamicPopupHidden() {
    this.dynamicPopupClass = null;
  }

  async onActionButtonClick() {
    if (!this.selectedReportIds || this.selectedReportIds.length < 1) {
      return;
    }

    // TODO: branch based on context

    const compilationRequest = new ReportCompilationRequest();

    compilationRequest.requestedReportIds = this.selectedReportIds;
    compilationRequest.defendantIds = [this.defendantId];

    if (this.defendant?.transactions) {
      compilationRequest.transactionIds = [];
      this.defendant.transactions.forEach(transaction => {
        if (transaction.Id === this.transactionId) {
          compilationRequest.transactionIds.push(transaction.Id);

          // bonds
          if (transaction.bonds) {
            compilationRequest.bondIds = [];

            transaction.bonds.forEach(bond => {
              compilationRequest.bondIds.push(bond.Id);
            });
          }

          // contacts
          if (transaction.contacts) {
            compilationRequest.contactIds = [];

            transaction.contacts.forEach(contact => {
              compilationRequest.contactIds.push(contact.Id);
            });
          }

          // collaterals
          if (transaction.collaterals) {
            compilationRequest.collateralIds = [];

            transaction.collaterals.forEach(collateral => {
              compilationRequest.collateralIds.push(collateral.Id);
            });
          }
        }
      });
    }

    let objectName, objectTypeName;
    if (this.selectedReportIds.length > 1) {
      const firstSelectedReport = this.formsDataSource.find(report => report.ReportId === this.selectedReportIds[0]);
      objectName = `${firstSelectedReport.ReportName} and others`;
      const selectedReports = this.formsDataSource.filter(report =>  this.selectedReportIds.includes(report.ReportId));
      objectTypeName = selectedReports.flatMap(r => r.ReportType).some(r => r.toLowerCase().includes('form')) ? 'forms' : 'report';
    } else {
      const selectedReport = this.formsDataSource.find(report => report.ReportId === this.selectedReportIds[0]);
      objectName = selectedReport.ReportName;
      objectTypeName = selectedReport.ReportType.toLowerCase().includes('forms') ? 'forms' : 'report';
    }
    this.dynamicPopupData = {
      PDFBase64String: '', coordinateData: undefined, pdfPurpose: undefined,
      pdfObservable: this.reportService.postReportCompilationRequest(compilationRequest),
      objectName: objectName,
      objectTypeName: objectTypeName,
      sourceType: 'DefendantTransactions',
      sourceId: this.transactionId,
      reportIds: this.selectedReportIds,
      contacts: this.contacts,
      defendant: this.defendant,
      esignable: this.subscribedToEsign,
    };
    const dialogRef = this.dialog.open(PdfDialogComponent, {
      data: this.dynamicPopupData,
      panelClass: 'document-viewer',
    });

    this.subs.sink = dialogRef.afterClosed().subscribe(output => {

    });

    // abandoned code from https://github.com/Captira/captira2-ui/issues/3374
    // const popoutOptions: ReportPopoutData = {id: null};
    // popoutOptions.reportObservable = this.reportService.postReportCompilationRequest(compilationRequest);
    //
    // if (this.selectedReportIds.length > 1) {
    //   const firstSelectedReport = this.formsDataSource.find(report => report.ReportId === this.selectedReportIds[0]);
    //   popoutOptions.objectName = `${firstSelectedReport.ReportName} and others`;
    // } else {
    //   const selectedReport = this.formsDataSource.find(report => report.ReportId === this.selectedReportIds[0]);
    //   popoutOptions.objectName = selectedReport.ReportName;
    // }
    //
    // popoutOptions.objectTypeName = 'forms';
    // popoutOptions.sourceType = 'DefendantTransactions';
    // popoutOptions.sourceId = this.transactionId;
    //
    // this.popoutService.openReportPopout(
    //   this.reportService.postReportCompilationRequest(compilationRequest),
    //   compilationRequest.requestedReportIds.join(),
    //   popoutOptions);
  }

  onDefendantSelected(e) {
    this.defendantId = e.DefendantId;
    this.defendantName = e.DefendantName;
    this.subs.sink = this.defendantService.getTransactionLookup(this.defendantId).subscribe(lookup => {
      this.transactionList = lookup;
      if (this.transactionList.length === 1) {
        this.transactionId = this.transactionList[0].Id;
      }
    });
    this.actionButtonText = this.getActionButtonText(
      e.newValue,
      this.defendantId,
      this.transactionId,
      this.dataGrid.selectedRowKeys?.length || 0);
  }

  onTransactionChanged(e) {
    if (this.userSelectableDefendant && this.defendantId && this.transactionId) {
      this.subs.sink = this.defendantService.getFullDefendantById(this.defendantId, this.transactionId).subscribe(def => {
        this.defendant = new Defendant();
        this.defendant.LoadData(def.data);

        // TODO: onDefendantSelected???

      });
    }
  }

  formChangedAsFavorite(event) {
    const report = this.formsDataSource.find(data => data.ReportId === event.ReportId);
    if (report) {
      report.IsFavorite = event.IsFavorite;
      this.sortForms();
    }
  }

  toggleTooltip() {
    this.tooltipVisible = !this.tooltipVisible;
  }

  goToFormGroups() {
    this.showFormGroups.emit(true);
  }

  animateOptions() {
    this.currentOptionsState = this.currentOptionsState === 'initial' ? 'final' : 'initial';
    if (this.optionsClass === 'open-options') {
      this.optionsClass = 'close-options';
    } else {
      this.optionsClass = 'open-options';
    }
  }

  getActionButtonText(blankForms: boolean, defendantId, transactionId, numSelectedReports) {
    if (!!blankForms) {
      if (numSelectedReports > 0) {
        return 'Preview Blank Forms Now';
      } else {
        return 'Select Defendant and Forms';
      }
    } else if (numSelectedReports < 1) {
      return 'Select Defendant and Forms';
    } else if (!transactionId) {
      return 'Select Defendant and Posted Date to Preview Forms';
    } else if (!defendantId) {
      return 'Select Defendant to Preview Forms';
    } else {
      return 'Preview Forms Now';
    }
  }

  blankFormsChange(e) {
    this.actionButtonText = this.getActionButtonText(
      e.value,
      this.defendantId,
      this.transactionId,
      this.dataGrid.selectedRowKeys?.length || 0);
  }

  handleSave(transactions: Bail[]) {
    this.isDisabled = true;
    const observableQueue = [];
    // First we need to prepare the documents by setting the SourceId and SourceType
    for (const transaction of transactions) {
      for (const document of transaction.documents) {
        document.tempKey = new Guid().toString();
        document.SourceType = 'DefendantTransactions';
        document.SourceId = transaction.Id;
        if (document.isAdded) {
          observableQueue.push(this.documentService.addDocument(document));
        } else if (document.isDeleted) {
          observableQueue.push(this.documentService.removeDocument(document.Id));
        }
      }
    }
    if (observableQueue.length <= 1) {
      const dummyObs = new Observable(obs => {
        obs.next();
      });
      observableQueue.push(dummyObs);
    }
    this.notifyService.toast('Saving documents...');
    this.subs.sink = observableCombineLatest(observableQueue).subscribe((docResp: any) => {
      // lets find the deleted and remove them from the client model collections seeing as the api does not need to
      // process them anymore
      for (const transaction of this.defendant.transactions) {
        for (const document of transaction.documents) {
          if (document.isAdded) {
            document.Id = docResp.find(
              (itm: any) => {
                return itm.data.tempKey === document.tempKey;
              },
            ).data.data.Id;
            document.isAdded = false;
          } else if (document.isDeleted) {
            document.isDeleted = false;
          }
        }
      }
      this.isDisabled = false;
      this.notifyService.toast('Documents updated successfully.');
    }, err => {
      this.notifyService.toast('Something went wrong upload documents', 'error');
      this.isDisabled = false;
    });
  }

}
