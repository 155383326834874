import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from '../../components/common/angular-google-place';
import { SystemManagerService } from '../../services/systemmanager.service';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectGooglePlaceId } from '../../state/selectors/company.selectors';
import { loadCompanys } from '../../state/actions/company.actions';
import { map } from 'rxjs/operators';
import { ICompany } from '../../models/icompany';

@Component({
  selector: 'app-google-place',
  templateUrl: './google-place.component.html',
  styleUrls: ['./google-place.component.scss'],
})
export class GooglePlaceComponent implements OnInit {

  @Input() value: string;
  @Input() parent: 'customer' | 'agency';
  @Input() inputDisabled = false;
  @Output() valueChange = new EventEmitter<string>();

  options = { types: ['establishment'], componentRestrictions: { country: ['US', 'PR'] } };
  reviewUrl = 'https://search.google.com/local/writereview?placeid=';
  placeId$: Observable<string>;
  street_number: any;
  street: any;
  city: any;
  state: any;
  district: any;
  country: any;
  postal_code: any;
  lat: any;
  lng: any;
  adr_address: any;
  name: any;
  place_id: any;
  types: any;
  url: any;
  vicinity: any;
  photos: any;
  CountryCodes: any;
  readOnlyField: any;

  constructor(
    private systemManagerService: SystemManagerService,
    private store: Store<{ company: ICompany }>,
  ) { }

  ngOnInit(): void {
    if (this.parent === 'customer') {
      const userId = localStorage.userId;
      this.store.dispatch(loadCompanys({ userId }));
      this.placeId$ = this.store.select(selectGooglePlaceId)
        .pipe(map(placeId => {
          if (!placeId) {
            return '';
          } else {
            this.valueChange.emit(placeId);
            return `${this.reviewUrl}${placeId}`;
          }
        }));
    } else {
      if (this.value) {
        this.placeId$ = of(`${this.reviewUrl}${this.value}`);
      }
    }
  }

  getAddress(place: Address) {
    this.placeId$ = new Observable<string>(observer => {
      observer.next(`${this.reviewUrl}${place.place_id}`);
      this.valueChange.emit(place.place_id);
    });
  }

}
