import { Observable, of, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { ApiUtilities } from '../shared/ApiUtilities';
import { CacheService } from './cache.service';
import { AppConstants } from '../shared/AppConstants';
import { SystemConfig } from '../models/systemconfig';
import { PortoResponse } from '../models/porto-response';
import { RouteDataService } from './routedata.service';

@Injectable()

export class SystemConfigService {
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  private getSysConfig: Observable<any>;

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private cache: CacheService,
    private sessionService: RouteDataService,
  ) {
  }

  get(): Observable<any> {
    if (this.getSysConfig) {
      return this.getSysConfig;
    }
    const key = `systemConfigs`;
    let url = `${this.settings.getBaseUrl()}/${key}`;

    const data = this.cache.get(key);
    if (!!data) {
      this.getSysConfig = null;
      return of(JSON.parse(data)); // Return cache data as observables
    } else {
      this.getSysConfig = this.http.get(url).pipe(
        map((res: any) => {
          this.getSysConfig = null;
          this.cache.set(key, JSON.stringify(res));
          return res;
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
      return this.getSysConfig;
    }
  }

  getCollectionsPublicPageVersion(debtorId: string): Observable<any> {
    const key = `systemconfigpublic/bycustomer/${debtorId}`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const data = this.cache.get(key);
    if (!!data) {
      let cfg = JSON.parse(data);
      if (cfg) {
        if (cfg.data[0].CollectionPublicPageVersion == null || cfg.CollectionPublicPageVersion === '') {
          cfg.CollectionPublicPageVersion = AppConstants.COLLECTION_PUBLIC_PAGE_TYPE_FIRM;
        }
        if (cfg.data[0].CollectionRequirePaymentOnCounterOffer == null || cfg.CollectionRequirePaymentOnCounterOffer === '') {
          cfg.CollectionRequirePaymentOnCounterOffer = false;
        }
        return of(cfg);
      }
    } else {
      return this.http.get(url).pipe(
        map((cfg: any) => {
          if (cfg) {
            cfg = cfg.data[0];
            if (cfg.CollectionPublicPageVersion == null || cfg.CollectionPublicPageVersion === '') {
              cfg.CollectionPublicPageVersion = AppConstants.COLLECTION_PUBLIC_PAGE_TYPE_FIRM;
            }
            if (cfg.CollectionRequirePaymentOnCounterOffer == null || cfg.CollectionRequirePaymentOnCounterOffer === '') {
              cfg.CollectionRequirePaymentOnCounterOffer = false;
            }
            return cfg;
          }
        }),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
    }
  }


  save(data: any): Observable<any> {
    const key = `systemConfigs`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    return this.http.put(url, data).pipe(
      map((res: any) => {
        let obj = {data: [res.data], meta: res.meta};
        this.getSysConfig = null;
        this.cache.set(key, JSON.stringify(obj));
        this.sessionService.setObject('systemConfig', res.data);
        return res;
      }),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
    // response.pipe(take(1)).subscribe(data => {
    //   this.cache.remove(key);
    // });
  }


  create(data: any): Observable<any> {
    const key = `systemConfigs`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const response = this.http.post(url, data).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
    response.pipe(take(1)).subscribe(data => {
      this.cache.remove(key);
    });
    return response;
  }

  expireCache() {
    const key = `systemConfigs`;
    this.cache.remove(key);
  }

  findByCustomerInternal(customerId: any): Observable<any> {
    const key = `systemconfig/c/${customerId}`;
    const url = `${this.settings.getBaseUrl()}/${key}`;
    const response = this.http.get(url).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }));
    return response;
  }

  saveGuidedStepStatus(data: any) {

    this.get().subscribe(resp => {

      const key = `systemConfigAttribute`;
      const url = `${this.settings.getBaseUrl()}/${key}`;

      const curConfig = resp.data[0];
      const keys = Object.keys(data);
      // if the current saved value  = to the ignore value, then don't save it

      if (curConfig[keys[0]] && parseInt(curConfig[keys[0]], 10) > parseInt(data[keys[0]], 10)) {
        return;
      }
      curConfig[keys[0]] = data[keys[0]];
      resp.data[0] = curConfig;
      this.cache.set('systemConfigs', JSON.stringify(resp));
      data.Id = curConfig.Id;


    this.http.put(url, data).pipe(
      map((res: any) => {
        this.cache.remove(key);
        return res;
      }),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      })).subscribe();
    });
  }

  updateSystemConfigAttribute(config: SystemConfig): Observable<SystemConfig> {
    const url = `${this.settings.getBaseUrl()}/systemConfigAttribute`;
    return this.http.put<PortoResponse<SystemConfig>>(url, config).pipe(
      map(res => res.data),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),
    );
  }

}
