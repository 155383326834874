import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ApiUtilities } from '../shared/ApiUtilities';


@Injectable()
export class PaymentGatewaySettingsService {

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getGatewayRequiredSettings (id: any): Observable<any> {
    return new Observable<any>(obs => {});

    //this.http.get(`${this.settings.getBaseUrl()}/payment/${id}`).pipe(
    //  map((res: any) => res),
    //  catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }


}
