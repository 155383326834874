import {BaseModel} from './BaseModel';

export class Power extends BaseModel {

  Id: string;
  PrefixId: number;
  PowerPrefix: string;
  Powersuffix: number;
  PowerNumber: string;
  Forfeited: boolean;
  VoidDate: any;
  AgencyId: number;
  AgentId: number;
  InventoryId: number;
  ExpirationDate: any;
  CreatedDate: any;
  IssueDate: any;
  Status: string;
  ExecutionId: any;
  DischargeId: any;
  Void: boolean;
  VoidUserId: number;
  AgentReportDate: any;
  AgentReportId: any;
  VoidReason: string;
  CustomerId: number;

  constructor(obj?: object) {

    super(obj);

    if (obj == null) {
      this.Id = '';
    }
  }
}
