import { first, take } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdiSettings } from '../../models/adi-settings';
import { ActivatedRoute, Router } from '@angular/router';
import { AdiSettingsService } from '../../services/adisettings.service';
import { CustomerService } from '../../services/customer.service';
import { BillingService } from '../../services/billing.service';
import { AppConstants } from '../../shared/AppConstants';
import {UnsubscribeOnDestroyAdapter} from '../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-adiv2',
  templateUrl: './adiv2.component.html',
  styleUrls: ['./adiv2.component.scss'],
})
export class Adiv2Component extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() public appSource: 'bail' | 'defmngr' | 'collections';
  @Input() showTabs = false;
  @Input() inDialog = false;
  @Output() onSave = new EventEmitter<boolean>();
  tabs: any;
  exclusionsPopupVisible = false;
  errorVisible = false;
  errorData: any;
  errorTitle = '';
  adiSettings = new AdiSettings();
  busyMessage = '';
  loadIndicatorVisible = false;
  activeTabIndex = 0;
  userInTrial = false;
  selectedOtherIndex = 0;
  reminderType: any;

  constructor(
    private router: Router,
    private adiSettingsService: AdiSettingsService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private billingService: BillingService,
  ) {
    super();

    this.tabs = [
      {
        text: 'Auto Reminders',
        icon: 'fal fa-clock',
        template: 'tabAutomated',
      },
      {
        text: 'How It Works',
        icon: 'fal fa-question-circle',
        template: 'tabIntro',
      },
      {
        text: 'One Time Messages',
        template: 'tabOneTime',
      },
      { text: 'More', icon: '', template: 'tabOther' },
    ];

    this.subs.sink = this.billingService
      .GetCustomerBillingStatusString()
      .pipe(take(1))
      .subscribe(status => {
        if (status === 'TRIALING') {
          this.userInTrial = true;
        }
      });
  }

  ngOnInit() {
    this.subs.sink = this.route.paramMap.subscribe(params => {
      this.appSource = params.get('src') as 'bail' | 'defmngr' | 'collections';
      this.activeTabIndex = +params.get('index');
      this.showTabs = true;
    });

    this.subs.sink = this.route.queryParams.subscribe(params => {
      this.activeTabIndex = this.activeTabIndex || params.index;
      this.selectedOtherIndex = params.otherIndex || 0;
      this.reminderType = params.reminderType;
    });
  }

  onTemplateChanges(e: any) {
    this.cdr.detectChanges();
  }

  getStartedNow() {
    if (this.appSource && this.appSource === 'defmngr') {
      this.router.navigateByUrl(
        `/getstarted/checkout?productids=${AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER}`,
      );
    } else {
      this.router.navigateByUrl('/getstarted/checkout');
    }
  }

  handleOnSave(event: boolean) {
    if (this.inDialog) {
      this.onSave.emit(event);
    }
  }
}
