import { catchError, flatMap, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { DefendantWatchSettings } from '../models/defendantwatchsettings';
import { SettingsService } from './settings.service';
import { ApiUtilities } from '../shared/ApiUtilities';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DefendantService } from './defendant.service';
import { BillingService } from './billing.service';
import { AppConstants } from '../shared/AppConstants';
import { DefendantWatch } from '../models/defendant-watch';
import { PortoResponse } from '../models/porto-response';
import { WatchResult } from '../models/watch-result';

@Injectable()
export class DefendantWatchService {

  constructor(private settings: SettingsService,
              private http: HttpClient,
              private defService: DefendantService,
              private billingService: BillingService) {
  }

  GetSettings(): Observable<DefendantWatchSettings> {
    return this.http.get(`${this.settings.getBaseUrl()}/defendantwatchaccountsettings`).pipe(
      map((res: any) => {
        let settings: DefendantWatchSettings = new DefendantWatchSettings();
        if (res && res.data && res.data.length > 0) {
          settings = new DefendantWatchSettings(res.data[0]);
        }
        return settings;
      }),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  SaveSettings(dwSettings: DefendantWatchSettings): Observable<any> {
    // console.log('saving dw settings');
    // console.log(dwSettings);
    // if user is not subscribed to BMS (ie, using standalone services, always set both on since these options
    // will not be available in the UI
    return this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM).pipe(
      flatMap(isSubbed => {
        if (!isSubbed) {
          // console.log('setting defaults on for dw account');
          dwSettings.WatchForfeitures = true;
          dwSettings.WatchLiability = true;
        }
        if (!dwSettings.SettingsID) {
          // console.log('new dw settings');
          // new
          return this.http.post(`${this.settings.getBaseUrl()}/defendantwatchaccountsettings`, dwSettings).pipe(
            map((res: any) => res),
            catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
        } else {
          // console.log('existing dw settings', dwSettings);
          // existing
          return this.http.put(`${this.settings.getBaseUrl()}/defendantwatchaccountsettings/${dwSettings.SettingsID}`, dwSettings).pipe(
            map((res: any) => res),
            catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
        }
      })
    );
  }

  GetWatches(): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/defendantwatches`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  GetWatchesView(params: HttpParams): Promise<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/defendantwatchesview`, { params }).toPromise().then(response => {
      return response;
    }).catch(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error'));
  }

  GetAllAlerts(): Observable<WatchResult[]> {
    return this.http.get<PortoResponse<WatchResult[]>>(`${this.settings.getBaseUrl()}/defendantwatchresults`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  RemoveWatch(defendantId: any): Observable<any> {
    // all we can do to remove a watch is set the IncludeInDefendantWatch bit to 0.
    // This of course assumes the defendant does not otherwise meet the criteria to be included in def watch
    // For standalone defendant watch - this is ok since there will never be any bonds with liability or forfeitures...
    const updateDefData = {
      Id: defendantId,
      IncludeInDefendantWatch: 0,
      RemovingFromDefendantWatch: true,
    };
    return this.defService.saveDefendantWithNoIncludes(defendantId, updateDefData);
  }

  removeV1Watch(watchId: string): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/defendant-watch/v1/remove/${watchId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  reactivateWatch(defendantId: string): Observable<any> {
    const updateDefData = {
      Id: defendantId,
      IncludeInDefendantWatch: 1,
    };
    return this.defService.saveDefendantWithNoIncludes(defendantId, updateDefData);
  }

  GetWatchByDefendant(defendantId: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/getwatchbydefendantid?defendant_id=${defendantId}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  GetAlertsCountByDefendant(defendantId: any): Observable<any> {
     return this.http.get(`${this.settings.getBaseUrl()}/getalertscountbydefendant?defendant_id=${defendantId}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  exonerateDefendant(defendantId: string, date: string) {
    return this.http.patch(`${this.settings.getBaseUrl()}/defendant/exonerate/${defendantId}`, {date}).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }

  exportDefendantWatch(params: HttpParams) {
    return this.http.get(`${this.settings.getBaseUrl()}/exportwatches`, {params, responseType: 'arraybuffer'}).pipe(
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  UpdateWatchByDefendant(data: any): Observable<any> {
    return this.http.put(`${this.settings.getBaseUrl()}/updatewatchbydefendantid`, data)
      .pipe(map((res: any) => {return res;}),
        catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));
  }
}
