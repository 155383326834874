import { Component, ViewChild } from '@angular/core';
import { EventService } from '../../../services/event.service';
import { QuickActionEvent } from '../quick-actions/quick-actions.component';
import { MatMenu } from '@angular/material/menu';

export enum QuickActionPreviewEvent {
  NOTE = 'AddNote',
  FORMS = 'DefendantFormsComponent',
  CHECKIN = 'LocationHistoryComponent',
  DEFENDANTWATCH = 'DefendantWatchComponent',
  DATAMART = 'BackgroundDatamartComponent',
  AUDITHISTORY = 'DefendantAuditHistoryComponent',
  DOCUMENTS = 'DocumentComponent',
  RAPSHEET = 'RapSheetComponent',
  ADDWATCH = 'AddWatch',
  HIDEWATCH = 'HideWatch',
  SHOWWATCH = 'ShowWatch',
}

export interface QuickAction<T> {
  value: T;
  text: string;
  icon: string;
}


@Component({
  selector: 'app-quick-actions-preview',
  templateUrl: './quick-actions-preview.component.html',
  styleUrls: ['./quick-actions-preview.component.scss'],
})
export class QuickActionsPreviewComponent {
  @ViewChild('menu') menu: MatMenu;

  defendantMenu: QuickAction<QuickActionPreviewEvent>[] = [
    {
      value: QuickActionPreviewEvent.NOTE,
      text: 'Add Note',
      icon: 'fal fa-sticky-note',
    },
    {
      value: QuickActionPreviewEvent.CHECKIN,
      text: 'Check Ins',
      icon: 'fal fa-map-marker',
    },
    {
      value: QuickActionPreviewEvent.FORMS,
      text: 'Forms',
      icon: 'fal fa-print',
    },
    {
      value: QuickActionPreviewEvent.DATAMART,
      text: 'Arrest History',
      icon: 'fal fa-search',
    },
    {
      value: QuickActionPreviewEvent.AUDITHISTORY,
      text: 'Audit History',
      icon: 'fal fa-file',
    },
    {
      value: QuickActionPreviewEvent.DEFENDANTWATCH,
      text: 'Defendant Watch',
      icon: 'fal fa-user',
    },
    {
      value: QuickActionPreviewEvent.DOCUMENTS,
      text: 'Documents',
      icon: 'fal fa-file',
    },
  ];

  closed = true;

  constructor(private eventService: EventService) {
  }

  /**
   * @description Pass the button attributes into observer
   */
  selectedItem(action: QuickAction<QuickActionPreviewEvent>) {
    this.eventService.quickAction(action);
  }
}
