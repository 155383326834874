import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mimeToFileType',
})
export class MimeToFileTypePipe implements PipeTransform {

  private mimeTypes: { [key: string]: string } = {
    'image/jpeg': 'JPEG',
    'image/png': 'PNG',
    'image/gif': 'GIF',
    'image/svg+xml': 'SVG',
    'image/webp': 'WebP',
    'application/pdf': 'PDF',
    'application/msword': 'Word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word',
    'application/vnd.ms-excel': 'Excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
    'application/vnd.ms-powerpoint': 'PowerPoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint',
    'text/plain': 'Plain Text',
    'text/csv': 'CSV',
    'application/zip': 'ZIP',
    'application/x-rar-compressed': 'RAR',
    'audio/mpeg': 'MP3',
    'audio/wav': 'WAV',
    'video/mp4': 'MP4',
    'video/x-msvideo': 'AVI',
    'video/webm': 'WebM',
    'application/json': 'JSON',
    'application/javascript': 'JavaScript',
    'text/html': 'HTML',
    'text/css': 'CSS',
    'application/xml': 'XML',
  };

  transform(type: string): string {
    return this.mimeTypes[type] || 'Unknown File Type';
  }

}
