import {Component, OnInit, Input, HostBinding, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss'],
})
export class BottombarComponent implements OnInit, OnDestroy {
  @Input() enableShadow: boolean;
  @HostBinding('class.shadow') shadow: boolean;

  ngOnInit() {
    if (this.enableShadow) {
      this.shadow = true;
    }
    window.addEventListener('scroll', this.scrollState, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollState, true);
  }

  scrollState = (event: any): void => {

    if (!this.enableShadow) {
      this.shadow = false;
      return;
    }

    if (!!event.target.lastElementChild) {
      const pos = event.target.scrollTop + (event.target.offsetHeight - event.target.lastElementChild.offsetTop);
      const max = event.target.lastElementChild.scrollHeight;

      this.shadow = pos === max;
    }
  }
}
