
export module ObjectUtilities {

  export function mergeObjects(source, target) {
    for (const propName in source) {
      target[propName] = source[propName];
    }
  }

  export function isNullUndefinedOrEmpty(source, array = false) {
      return source == null || source.length ===  0;
  }

}
