
<div class="row wrapper border-bottom white-bg page-heading" *ngIf="!inDynamicPopup">
  <h2>Add Note</h2>
  View Past notes in the transaction detail at the bottom of screen
</div>

<div class="wrapper wrapper-content animated fadeIn">
  <div class="ibox float-e-margins">
    <div class="ibox-content" style="border-top: none">
      <form>
      <div id="vertical-timeline" class="vertical-container light-timeline">
        <div class="vertical-timeline-block">
          <div class="vertical-timeline-content">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-4 text-right">
                  <label class="control-label">Subject</label>
                </div>

                <div class="col-sm-8">
                  <dx-text-box autofocus [(value)]="newNote.Subject" [maxLength]="50"> </dx-text-box>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 text-right">
                <label class="control-label">Note Text</label>
              </div>
              <div class="col-sm-8">
                <dx-text-area  #textArea [height]="90" [(value)]="newNote.Text"></dx-text-area>
              </div>
            </div>
            <!-- <div class="row">
              <div class="form-group">
                <div class="col-md-2">
                  <label class="control-label">Note Text</label>
                </div>
                <div class="col-md-6">
                  <dx-text-area [height]="90" [(value)]="newNote.Text">
                  </dx-text-area>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="vertical-timeline-block">
          <div class="vertical-timeline-content">
            <app-buttonbar [routeKey]="'Defendant'" [saveAndGoVisible]="false"
              [saveButtonsDisabled]="saveButtonDisabled" #navButtonBar [saveMethod]="onSave"
              [selectedRoute]="selectedNavRoute" [cancelMethod]="cancel"
              [useCancelButton]="dynamicData !== undefined && dynamicData !== null"></app-buttonbar>
          </div>
        </div>
      </div>
      </form>
    </div>

  </div>
</div>

<app-errorpopup [height]="'300'" [width]="'400'" [resizeEnabled]="true" [closeOnOutsideClick]="false"
  [title]="errorTitle" [shading]="false" [data]="errorData" [visible]="errorVisible"></app-errorpopup>
