import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Document } from '../../../../models/document';
import { PdfViewerComponent } from '../../../../components/common/pdf-viewer/pdf-viewer.component';
import { Share } from '../../../../models/share';
import { PopupService } from '../../../../services/popup.service';
import { Defendant } from '../../../../models/defendant';
import { Contact } from '../../../../models/contact';

export interface DocumentViewerData {
  document: Document;
  pdfbase64: string;
  shares: Share[];
  defendant: Defendant;
  contacts: Contact[];
}

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {

  @ViewChild(PdfViewerComponent) appPdfViewer: PdfViewerComponent;

  public allowEsign: boolean;
  public allowEdits: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DocumentViewerData,
    public dialogRef: MatDialogRef<DocumentViewerComponent>,
    public popupService: PopupService,
    private elRef: ElementRef,
    private ngZone: NgZone,
  ) {
    this.allowEsign = false;
    this.allowEdits = true;
  }

  ngOnInit(): void {
    if (this.data.shares.length > 0) {
      this.allowEsign = false;
    } else {
      this.allowEsign = true;
    }
    this.popupService.maximizePopup$.subscribe(isMaxSize => {
      if (isMaxSize) {
        this.dialogRef.updateSize('100vw', '100vh');
      } else {
        this.dialogRef.updateSize('calc(100% - 50px)', '');
      }
    });
  }

  handleOnClose(event: boolean) {
    this.dialogRef.close();
  }

  async handlePageRendered(event: any) {
    // We need to lock the editable fields if this is an eSign document.
    if (this.data.shares.length > 0) {
      const complete = this.data.shares.filter(share => share.Status === 'COMPLETE').length;
      if (complete >= 1) {
        this.allowEdits = false;
        await this.ngZone.runOutsideAngular(async () => {
          await this.lockFields(event.pageNumber);
        });
      }
    }
  }

  async lockFields(pageNumber: number) {
    if (!!this.appPdfViewer) {
      const page = await this.appPdfViewer.pdfViewer.pdfDocument.getPage(pageNumber);
      const annotations = await page.getAnnotations();
      for (const annotation of annotations) {
        if (annotation.fieldType !== 'Sig') {
          const parent = this.elRef.nativeElement.querySelector(`[data-annotation-id="${annotation.id}"]`);
          const input = parent?.firstChild;
          if (!!input) {
            input.disabled = true;
          }
        }
      }
    }
  }

  onESignSent() {
    this.dialogRef.close(true);
  }

}
