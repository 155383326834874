import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdminService {

  private _isAdmin: boolean;

  constructor() {
    this._isAdmin = false;
  }

  set isAdmin(value: boolean) {
    this._isAdmin = value;
  }

  get isAdmin(): boolean {
    return this._isAdmin || false;
  }
}
