import {BaseModel} from './BaseModel';

export class AdiNumberAssignment extends BaseModel {

  Id: any;
  PhoneNumber: any;

  constructor(obj?: object) {
    super(obj);
  }
}
