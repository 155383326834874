import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(value: string): string {
    try {
      const names = value.split(', ');
      return `${names[1]} ${names[0]}`;
    } catch (e) {
      return name;
    }
  }

}
