import { BaseModel } from './BaseModel';
import { Power } from './power';
import { CourtDate } from './court-date';
import { isNullOrUndefined } from 'util';

export class Bond extends BaseModel {

  power: Power;
  courtdates: CourtDate[];

  Id: string;
  TransactionId: string;
  Identifier: string;

  Amount: number;
  CaseNumber: string;
  FileNumber: string;
  Charges: string[];
  Charge: string;
  StateCode: string;
  County: string;
  City: string;
  StatusId: string;
  RecordStatus: string;
  DispositionId: string;
  DispositionName: string;
  AttorneyId: string;
  AttorneyName: string;
  ChargeClassId: string;
  SetFor: string;
  ForfeitureDate: any;
  ForfeitureMailingDate: any;
  ForfeitureExpirationDate: any;
  ForfeitureReassumeDate: any;
  ForfeitureExtendDate: any;
  ForfeitureCustodyDate: any;
  DischargeOccurrenceDate: any;
  DischargeRecordedDate: any;
  Rewrite: boolean;
  Transfer: boolean;
  PowerId: string;
  RateId: any;
  RateName: string;
  RewrittenBondId: string;
  RewrittenPowerNumber: string;
  ForfeitureSuitNumber: string;
  ForfeiturePostMarkDate: any;
  ForfeitureNoticeDate: any;
  ForfeitureSatisfyByDate: any;
  ForfeitureShowCauseDate: any;
  ForfeitureExtendCount: number;
  ForfeitureDefaultJudgementDate: any;
  ForfeitureSummaryJudgementDate: any;
  ForfeitureSummaryJudgementDueDate: any;
  ForfeitureSummaryJudgementPaidDate: any;
  ForfeitureSummarySuitNumber: string;
  SettingDateTime: any;
  SettingTime: any;

  ForfeitureSetAsideDate: any;
  ForfeitureRemissionPaidAmount: number;
  ForfeitureRemissionPaidBy: any;

  ForfeiturePaid: boolean;
  ForfeitureJailId: string;
  ForfeitureProjectedPayOffDate: any;
  ForfeitureProjectedPayOffAmount: number;
  ForfeitureActualPayOffDate: any;
  ForfeitureActualPayOffAmount: number;
  SurrenderReleaseDate: any;
  SurrenderCustodyDate: any;
  EverForfeited: any;
  SurrenderJailId: string;

  //ExonerationDate: any;
  DocumentReceivedDate: any;
  Split: boolean;
  SplitAmount: number;
  SplitNotes: string;
  Status: string;

  TransferRole: string;
  TransferFee: number;
  TransferAgencyId: any;
  TransferFeeCollected: number;
  TransferComment: any;
  ForfeitureRemissionReceivedDate: any;
  ForfeitureRemissionFiledDate: any;
  AssignmentDate: any;

  Override: boolean;
  Net: number;
  Buf: number;
  Commission: number;

  RecoveryAgentId: string;
  AgentCommissionPaid: any;
  AgentCommissionPaidDate: any;
  LastAdiCallDate: any;
  LastAdiCallResult: string;
  RecoveryAssignmentDate: any;
  CourtId: number;
  CourtName: string;
  CourtRoom: string;
  Gross: number;
  PowerNumber: any;
  FacilityId: string;
  public rowId: any; //used by grid internaly

  constructor(obj?: object) {

    super(obj);
    this.childrenNames = ['courtdates', 'power'];
    this.routeRoot = 'bonds';
    this.propertiesToIgnoreForCheckChanges.push('PowerNumber', 'Charges', 'Power', 'power', 'recordstatus', 'RecordStatus', 'RateName', 'AttorneyName', 'DispositionName', 'RewrittenPowerNumber', 'CourtRoom', 'CourtName', 'Identifier');

    // this.PowerNumber='12345';
    // if (this.power != null) {
    //   this.PowerNumber = this.power.PowerNumber;
    // } else {
    //   this.PowerNumber = this.Amount;
    // }

    if (obj == null) {
      this.Rewrite = false;
      this.Transfer = false;
      this.Split = false;
      this.Override = false;
      this.Charges = [];
      this.courtdates = [];
      this.Amount = null;
      this.CaseNumber = '';
      this.FileNumber = '';
      this.Charge = '';
      this.StateCode = '';
      this.County = '';
      this.City = '';
      this.StatusId = '';
      this.RecordStatus = '';
      this.Id = '';

    }
  }

  public loadData(data) {
    this.fillFromObject(data, true);
    const mainThis = this;

    // // console.log('bond data', data);
    // // console.log('rate on bond', this.RateId);
    if (data.courtdates.data !== null && data.courtdates.data.length > 0) {
      data.courtdates.data.forEach(
        (courtDate) => {
          const newCourtDate = new CourtDate();
          newCourtDate.fillFromObject(courtDate);
          mainThis.courtdates.push(newCourtDate);
          // console.log('bond.loaddata', 'court date loaded');
        });
    }

    if (!isNullOrUndefined(data.power) && data.power.data !== null) {
      this.power = new Power(data.power.data);
      // console.log('my power', this.power);

      this.PowerNumber = (isNullOrUndefined(this.power)) ? '' : this.power.PowerNumber;
    } else {
      this.power = new Power();
    }
  }

}
