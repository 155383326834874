import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ssnMask'
})
export class SsnMaskPipe implements PipeTransform {

  transform(value: any, compact?: any): any {
    if (value === null || value === undefined || value === '') {
      return value;
    } else {
      const lastFour = value.toString().substr(5, 4);
      if(compact !== undefined && compact === 'compact')
      {
        return '*-' + lastFour;
      }
      else {
        return '***-**-' + lastFour;
      }
    }
  }

}
