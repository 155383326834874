import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { IDocumentAudit } from '../models/document-audit';
import { PortoResponse } from '../models/porto-response';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentAuditService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
  ) { }

  public createDocumentAudit(audit: IDocumentAudit): Observable<IDocumentAudit> {
    return this.http.post<PortoResponse<IDocumentAudit>>(`${this.settings.getBaseUrl()}/document/audit`, audit).pipe(
      map(response => response.data),
      catchError(error => throwError(error.error)),
    );
  }
}
