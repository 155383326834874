import { BaseModel } from './BaseModel';

export class TransactionARSummary extends BaseModel {
  CustomerId: number;
  Id: number;
  PostedDate: any;
  DefendantId: number;
  BondFees: number;
  MiscFees: number;
  TotalFees: number;
  TotalPayments: number;
  TotalRefunds: number;
  TotalAdjustments: number;
  TotalBalance: number;
  CurrentBalance: number;
  PastDueBalance: number;
  PaidInAdvance: number;
  DaysLate: number;
  FeeAmountList: string;
  PaymentDates: string;
  PaymentAmounts: string;
  NextScheduledPaymentDate: any;
  NextScheduledPaymentAmount: number;
  FirstScheduledPaymentDate: any;
  LastScheduledPaymentDate: any;

  TotalReceived: number;

  constructor(obj?: object) {

    super(obj);

    if (obj == null) {
      this.Id = -1;
      this.BondFees = 0;
      this.MiscFees = 0;
      this.TotalFees = 0;
      this.TotalPayments = 0;
      this.TotalRefunds = 0;
      this.TotalAdjustments = 0;
      this.TotalBalance = 0;
      this.CurrentBalance = 0;
      this.PastDueBalance = 0;
      this.PaidInAdvance = 0;
      this.DaysLate = 0;
      this.FeeAmountList = '';
      this.PaymentAmounts = '';
      this.PaymentDates = '';
      this.NextScheduledPaymentAmount = 0;
      this.TotalReceived = 0;
    }
  }
}
