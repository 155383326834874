import {map, catchError, finalize} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {ApiUtilities} from '../shared/ApiUtilities';
import {EventService} from './event.service';
import { PublicCheckIn } from '../models/public-check-in';
import { PortoResponse } from '../models/porto-response';

@Injectable()
export class LocationCheckinService {

  checkinDue = false;

  constructor(private http: HttpClient,
              private settings: SettingsService, private eventService: EventService) {
  }

  public doCheckin(id, code, checkinData): Observable<any> {
    this.eventService.componentBusy(true);
    return this.http.put(`${this.settings.getBaseUrl()}/checkins/${id}/${code}`, checkinData).pipe(
      map((res: any) => res),
      finalize(() => {
        this.eventService.componentBusy(false);
      }),
      catchError((error) => {
        this.eventService.componentBusy(false);
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')

      }),);
  }


  public getCheckInInfo(id, code): Observable<PublicCheckIn> {
    return this.http.get<PortoResponse<PublicCheckIn>>(`${this.settings.getBaseUrl()}/checkins/${id}/${code}`).pipe(
      map((res: any) => res.data),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  public getEventTypeByReminderCode(code, customerId): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/eventtypebyremindercode?code=${code}&customerId=${customerId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  public getDefendantById(id): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/checkin/defendant/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

}
