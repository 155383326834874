import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { RelatedRoutesService } from '../../../services/relatedRoutes.service';
import { RouteDataService } from '../../../services/routedata.service';
import { AppConstants } from '../../../shared/AppConstants';
import { isNullOrUndefined } from 'util';
import { UnsubscribeOnDestroyAdapter } from '../../../common/UnsubscribeOnDestroy';


@Component({
  selector: 'app-buttonbar',
  templateUrl: './buttonbar.component.html',
  styleUrls: ['./buttonbar.component.scss'],
})
export class ButtonbarComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  defaultSaveAndGoText = 'Save & Go To';

  @Input() saveText = 'Save & Close';
  @Input() saveAndCloseText = 'Save';
  @Input() saveAndGoText = this.defaultSaveAndGoText;
  @Input() cancelText = 'Cancel';
  @Input() navItemsForContextMenu: any[];
  @Input() navItemsForRouting: any[];
  @Input() saveMethod: (e, val) => any;
  @Input() cancelMethod: (e) => any = null;
  @Input() selectedRoute: string;
  @Input() valGroup: DxValidationGroupComponent;
  @Input() saveButtonsDisabled = false;
  @Input() cancelRoute = '/dashboard';
  @Input() routeKey = '';
  @Input() navId: any;
  @Input() navKey: string;
  @Input() navObject: any;
  @Input() DefendantName: string;
  @Input() saveAndGoVisible = true;
  @Input() parentNavId: any;
  @Input() parentNavKey: string;
  @Input() useCancelButton = false;
  @Input() saveAndCloseButtonWidth = 136;
  @Input() saveAndCloseDivWidth = '150px';
  @Input() otherActions = [];
  @Input() showOtherActionsAsButtons = false;
  @Input() CustomCss = '';
  @Input() isFixedToBottom = false;
  @Input() allButtonsDisabled = false;

  otherActionsForContextMenu = [];
  showContextMenu = false;
  showOtherActionsContextMenu = false;
  showSaveAndGoCaret = true;

  showMenu() {
    if (this.navItemsForRouting === undefined || this.navItemsForRouting === null || this.navItemsForRouting.length === 0) {
      this.saveMethod(false, this.valGroup);
    } else {
      this.showContextMenu = true;
    }
  }

  showOtherActionsMenu() {
    this.showOtherActionsContextMenu = true;
  }

  getRouteName(relatedRoute) {
    return '{' + 'text:\'' + relatedRoute.Name + '\'}';
  }

  toggleSaveAndGoForNoRoutes() {
    if (this.navItemsForRouting === undefined || this.navItemsForRouting === null || this.navItemsForRouting.length === 0) {
      if (this.saveAndGoText === this.defaultSaveAndGoText) {
        this.saveAndGoText = 'Save';
      }
      this.showSaveAndGoCaret = false;
    }
  }

  loadRoutes() {
    this.toggleSaveAndGoForNoRoutes();
  }

  cancel() {
    if (!isNullOrUndefined(this.cancelMethod)) {
      this.cancelMethod(this.cancelRoute);
    } else {
      if (this.cancelRoute !== '') {
        this.routeForCancel();
      }
    }
  }

  routeForCancel() {
    this.router.navigateByUrl(this.cancelRoute);

  }

  navigate(overrideroute = '') {
    // console.log('overrideroute', overrideroute);
    if (overrideroute != null && overrideroute !== '') {
      this.router.navigateByUrl(overrideroute);
    } else {
      if (this.selectedRoute && this.selectedRoute !== '') {
        /*
                        // console.log('buttonbar: navigate: selected route', this.selectedRoute);
                        // console.log('buttonbar: navigate: button bar id', this.navId);
                        // console.log('buttonbar: navigate: button bar parent id', this.parentNavId);
        */

        if (this.navId && this.navKey !== '') {
          // console.log('buttonbar: navigate: setting nav', this.navKey);
          // console.log('buttonbar: navigate: setting id', this.navId);
          this.routeDataService.setValue(this.navKey, this.navId);
        }
        if (this.parentNavId && this.parentNavKey !== '') {
          // console.log('buttonbar: navigate: setting parent nav', this.parentNavKey);
          // console.log('buttonbar: navigate: setting parent id', this.parentNavId);
          this.routeDataService.setValue(this.parentNavKey, this.parentNavId);
        }
//        // console.log('buttonbar: navigate: defendant name in button bar', this.DefendantName);
        this.routeDataService.setValue(AppConstants.DEFENDANT_NAME_KEY, this.DefendantName);

        this.routeDataService.sourceData = this.navObject;
        this.router.navigateByUrl(this.selectedRoute);
      }
    }
  }

  setRoute(e) {

    if (e != null && e.itemData != null && e.itemData.text != null) {
      const foundRoutes = this.navItemsForRouting.filter(x => x.Name === e.itemData.text);
      // console.log(foundRoutes);
      if (foundRoutes != null && foundRoutes.length > 0) {
        // console.log('Found route: ' + foundRoutes[0].Route);
        this.selectedRoute = foundRoutes[0].Route;
        return;
      }

      this.selectedRoute = '';

    }
  }

  saveAndGo(e) {

    this.setRoute(e)
    this.showContextMenu = false;
    // console.log('e', e);
    // console.log('iscancel', (e === 'cancel'));
    const fromClose = (isNullOrUndefined(e === 'cancel')) ? false : (e === 'cancel');
    this.saveMethod(fromClose, this.valGroup);
  }

  takeOtherAction(e) {
    // console.log('take other action', e);
    if (!isNullOrUndefined(e.itemData) && !isNullOrUndefined(e.itemData.value)) {
      e.itemData.value();
    } else if (!isNullOrUndefined(e.value)) {
      e.value();
    }
  }

  constructor(private router: Router, private relatedRouteService: RelatedRoutesService, private routeDataService: RouteDataService) {
    super();
  }

  ngOnInit() {
    if (this.CustomCss === '') {
      this.CustomCss = 'button-bar';
    }
    this.loadRoutes();
  }

}
