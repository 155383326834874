import { BaseModel } from './BaseModel';
import { AppConstants } from '../shared/AppConstants';
import { Company } from './company';

export enum SignatureVerification {
  NONE = 0,
  SELFIE = 1,
  LICENCE = 2,
  BOTH = 3,
}

export class SystemConfig extends BaseModel {

  // tslint:disable:variable-name
  public Id: any;
  public CustomerId: any;

  // Basic
  public NameCapitalization: string;
  public AutomaticallyMarkMissedCheckins: boolean;
  public MissedCheckinThreshold: number;
  public AddFeeWhenAutomaticallyMarkingCheckinsMissed: boolean;
  public AutomaticMissedCheckinFeeId: any;
  public NumberOfDaysForDownPayment: number;

  // Global Checkin Schedule
  public EnableGlobalCheckInScheduleGeneration: boolean;
  public CheckInSchedulePeriod: string;
  public CheckInScheduleIntervalType: string;
  public CheckInScheduleIntervalCount: number;
  public CheckInScheduleDayOne: number;
  public CheckInScheduleDayTwo: number;
  public CheckInIntervalDaysofWeek: string;

  // Defaults
  public DefaultCollateral: string;
  public PaymentReceiptSequence: number;
  public PaymentReceiptSequencePrefix: string;
  public TransactionIdentifierSequence: number;
  public TransactionIdentifierSequencePrefix: string;
  public CollateralReceiptSequence: number;
  public CollateralReceiptSequencePrefix: string;
  public CalculateSatisfyByDate: any;

  // Advanced
  public UseExecutionDateForContract: boolean;
  public HideProspectsInDefendantSearch: boolean;
  public AllowLimitedUsersToViewDetails: boolean;
  public AllowLimitedUsersToEdit: boolean;
  public AllowLimitedUsersToViewSearchResult: boolean;
  public AllowLimitedUserToManageInventory: boolean;
  public AllowLimitedUserToReportSales: boolean;
  public IncludeMinimumInRewriteGross: boolean;
  public CourtDateNotification: boolean;
  public ClientUsesCHICExport: boolean;
  public AllowCollateralBondSpecification: boolean;
  public DefaultCollateralToZero: boolean;
  public ShowLawsuits: boolean;
  public AddLogoToForms: boolean;
  public Logo: any;
  public ClientCallSheetURL: string;
  public MicrobiltUserId: string;
  public MicrobiltPassword: string;
  public WelcomeSetupMvsCompleteByUserId: any;
  public WelcomeSetupMvsCompleted_At: any;
  public WelcomeSetupDefMgrCompleteByUserId: any;
  public WelcomeSetupDefMgrCompleted_At: any;
  public WelcomeSetupCollectionsCompleteByUserId: any;
  public WelcomeSetupCollectionsCompleted_At: any;
  public CollectionPaymentPlanAmountOverride: boolean;
  public CollectionPublicPageVersion: string;
  public CollectionRequirePaymentOnCounterOffer: boolean;
  public CollectionGuidedSetupStep: number;
  public CollectionPublicPageMaxOpenDays: number;
  public CollectionPublicPageMaxDaysMessage: string;
  public CollectionPublicPageExpiryEnabled: boolean;
  public DefendantWatchGuidedSetupStep: number;
  public CollectionReminderTemplateLanguage: string;
  public CollectionAutoFixBadData: boolean;
  public SignatureVerification: SignatureVerification;
  public SignatureVerificationRequired: boolean;
  public ReminderTime: string;
  public ConvertedFromTimezone: string;
  public CarolOnboardingStatus: number;
  public CarolOnboardingToast: number;
  public AttachedPdfs: string | null;
  public ApplicationCaptcha: boolean;
  public ApplicationPhotoRequired: boolean;
  public ApplicationPhotoType: SignatureVerification;
  public ShowFormOptions: boolean;
  public ApplicationEmails: string;
  public PauseReminders: boolean;
  public CarolSummarySms: boolean;
  public CarolSummarySmsDay: string;
  public ToggleV1Archive: boolean;
  public customer: Company;

  //Defendant
  public City: string;
  public StateCode: string;
  public County: string;
  public RateId: any;
  // This is only available from public pages because it gets returned from the api
  public BondCount: number;

  constructor(obj?: object) {
    super(obj);
    this.childrenNames = ['customer'];
    this.propertiesToIgnoreForCheckChanges.push('BondCount');
    if (obj == null) {
      this.isDirty = true;
      this.DefaultCollateralToZero = false;
      this.AddFeeWhenAutomaticallyMarkingCheckinsMissed = false;
      this.AllowCollateralBondSpecification = false;
      this.ClientUsesCHICExport = false;
      this.CourtDateNotification = false;
      this.NumberOfDaysForDownPayment = 3;
      this.AutomaticMissedCheckinFeeId = null;
      this.IncludeMinimumInRewriteGross = false;
      this.MissedCheckinThreshold = 0;
      this.AutomaticallyMarkMissedCheckins = true;
      this.UseExecutionDateForContract = false;
      this.PaymentReceiptSequence = 1000;
      this.PaymentReceiptSequencePrefix = 'PMT';
      this.TransactionIdentifierSequence = 1000;
      this.TransactionIdentifierSequencePrefix = 'TRN';
      this.CollateralReceiptSequence = 1000;
      this.CollateralReceiptSequencePrefix = 'COL';
      this.DefaultCollateral = '';
      this.NameCapitalization = 'None';
      this.HideProspectsInDefendantSearch = false;
      this.AllowLimitedUsersToViewDetails = false;
      this.AllowLimitedUsersToEdit = false;
      this.AllowLimitedUsersToViewSearchResult = false;
      this.AllowLimitedUserToManageInventory = false;
      this.AllowLimitedUserToReportSales = false;
      this.EnableGlobalCheckInScheduleGeneration = false;
      this.CheckInScheduleDayOne = 1;
      this.ShowLawsuits = false;
      this.AddLogoToForms = false;
      this.Logo = [];
      this.CollectionPaymentPlanAmountOverride = false;
      this.CollectionPublicPageVersion = AppConstants.COLLECTION_PUBLIC_PAGE_TYPE_FIRM;
      this.CollectionRequirePaymentOnCounterOffer = false;
      this.CollectionGuidedSetupStep = 0;
      this.CollectionPublicPageMaxOpenDays = 60;
      this.CollectionPublicPageMaxDaysMessage = '';
      this.CollectionPublicPageExpiryEnabled = false;
      this.DefendantWatchGuidedSetupStep = 0;
      this.CollectionReminderTemplateLanguage = 'en-US';
      this.CollectionAutoFixBadData = false;
      this.SignatureVerification = SignatureVerification.SELFIE;
    }

  }

  public loadData(data) {
    if(data != null) {
      this.fillFromObject(data, true);
      if(!this.customer) {
        this.customer = new Company();
        this.customer.loadData(data.customer.data);
      }
    }
  }
}
