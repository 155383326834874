import { Component, OnInit } from '@angular/core';
import {LookupService} from '../../../../services/app-lookup.service';
import { UnsubscribeOnDestroyAdapter } from './../../../../common/UnsubscribeOnDestroy';
import {ReminderService} from '../../../../services/reminder.service';
import {SystemConfigService} from '../../../../services/systemconfig.service';
import {SystemConfig} from '../../../../models/systemconfig';
import {MatDialogRef} from '@angular/material/dialog';
import {NotifyService} from '../../../../common/notify/notify.service';
import * as moment from 'moment';

@Component({
  selector: 'app-configure-reminder',
  templateUrl: './configure-reminder.component.html',
  styleUrls: ['./configure-reminder.component.scss']
})
export class ConfigureReminderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  timeSlots: any;
  systemConfig: SystemConfig;
  isLoading = true;

  constructor(
    private notifyService: NotifyService,
    private lookupService: LookupService,
    private systemConfigService: SystemConfigService,
    private dialogRef: MatDialogRef<ConfigureReminderComponent>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.timeSlots = this.lookupService.getLookupStatic('TimeSlots');
    this.systemConfig = new SystemConfig();
    // Default time.
    this.systemConfig.ReminderTime = '12:00:00';
    this.subs.sink = this.systemConfigService.get().subscribe(res => {
      this.isLoading = false;
      this.systemConfig = res.data[0];
      const currentDate = moment().format('YYYY-MM-DD');
      const dateTimeInUTC = `${currentDate}T${res.data[0].ReminderTime}Z`;
      this.systemConfig.ReminderTime = moment(dateTimeInUTC).local().format('hA');
    }, err => {
      console.log(err);
      this.isLoading = false;
    });

  }

  saveConfig() {
    // Get the timezone
    this.systemConfig.ConvertedFromTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.isLoading = true;
    this.subs.sink = this.systemConfigService.save(this.systemConfig).subscribe(res => {
      this.isLoading = false;
      this.notifyService.toast('Reminder Settings Saved Successfully.', 'success');
      this.dialogRef.close();
    }, err => {
      this.isLoading = false;
      console.log(err);
    })
  }
}
