<h2 mat-dialog-title class='dialog-title'>
  {{data.title}}
  <!-- Do not change the mat-dialog-close return of this without checking places where this value is used -->
  <button *ngIf="showCloseIcon" class='close-icon' mat-icon-button aria-label='close dialog' [mat-dialog-close]="'cancel'">
    <i class='fal fa-times'></i>
  </button>
</h2>
<mat-dialog-content class="mat-typography">
  {{data.message}}
  <ng-template *ngIf='!!selectedPortal' [cdkPortalOutlet]='selectedPortal'></ng-template>
</mat-dialog-content>
<mat-dialog-actions style="margin-top: 8px;" align="end">
  <button mat-stroked-button color="primary" [mat-dialog-close]="false" cdkFocusRegionStart>{{noText}}</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial cdkFocusRegionEnd>{{yesText}}</button>
</mat-dialog-actions>
