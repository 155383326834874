<div fxLayout="column" fxLayoutAlign="end start">
  <mat-form-field appearance='standard' floatLabel='always' class='form-wrapper'>
    <input
      type='text'
      id='google-place-lookup'
      matInput
      angularGooglePlace
      placeholder='Find your business on Google'
      [options]='options'
      [value]='placeId$ | async'
      (setAddress)='getAddress($event)'
      (street_number)='street_number = $event'
      (street)='street = $event'
      (city)='city = $event'
      (state)='state = $event'
      (district)='district = $event'
      (country)='country = $event'
      (postal_code)='postal_code = $event'
      (lat)='lat = $event'
      (lng)='lng = $event'
      (adr_address)='adr_address = $event'
      (name)='name = $event'
      (place_id)='place_id = $event'
      (types)='types = $event'
      (url)='url = $event'
      (vicinity)='vicinity = $event'
      (photos)='photos = $event'
      (CountryCodes)='CountryCodes = $event'
      [readonly]='readOnlyField'
      autocomplete='off'
      [disabled]='inputDisabled'
    />
  </mat-form-field>
</div>
