<ng-container *ngIf="inPopup; else notInPopup">
  <dx-popup
    (onHidden)="hidden($event)"
    [(visible)]="showImportPopupVar"
    title="{{ forDefendantWatch ? 'Upload Defendants to Watch' : 'Active Bonds Import'}}">
    <div *dxTemplate="let data of 'content'">
      <dx-scroll-view>
      <ng-container *ngTemplateOutlet="importer"></ng-container>
      </dx-scroll-view>
    </div>
  </dx-popup>
</ng-container>
<ng-template #notInPopup>
  <mat-card>
    <ng-container *ngTemplateOutlet="importer"></ng-container>
  </mat-card>
</ng-template>

<ng-template #importer>
  <div class="row">
    <div fxLayout="column" fxLayoutAlign="center center" class="file-importer bg-muted">
      <dx-file-uploader
        #importActiveBond
        style="max-width: 450px; width: 100%; margin: 64px auto auto auto;"
        (onValueChanged)="onUpload($event)"
        [disabled]="isBusy"
        [visible]="!showImport"
        accept=".csv"
        labelText="or drop file here (CSV format)"
        uploadFailedMessage="File has been uploaded, a result will be displayed after processing."
        uploadMode="useForm">
      </dx-file-uploader>

      <div *ngIf="!showImport && forDefendantWatch" class="text-center" style="margin-bottom: 32px; margin-top: 32px;">
        <a href="/assets/doc/sample_defendant_watch_import.csv"
           target="_self" download>
          <i class="fal fa-file-excel fa-1x" style="color: #2B9A47; text-decoration: none;"></i>
          &nbsp;<small>Download Defendant Watch Import Template</small>
        </a>
      </div>

      <div *ngIf="showImport" class="row flexible">
        <div class="col-md-4 text-center">
          <i class="fas fa-file-check fa-4x text-primary"></i>
        </div>

        <div class="col-md-8">
          <div *ngIf="totalProgress <= 0">
            <i class="{{ summaryIconClass }} icon-spacer"></i>
            <span>{{ reportSummary }}</span>
            <ng-container *ngIf="apiResults && forDefendantWatch">
              <br/>
              <i class="fal fa-check-circle icon-spacer"></i>
              <span>Created {{apiResults.Feedback.details.Created.Defendants}} Watches</span>
            </ng-container>
            <ng-container *ngIf="apiResults && forDefendantWatch && apiResults.Feedback.details.Existed.Defendants>0">
              <br/>
              <i class="fal fa-exclamation-circle icon-spacer"></i>
              <span>{{apiResults.Feedback.details.Existed.Defendants}} already exist</span>
            </ng-container>
            <ng-container *ngIf="apiResults && forDefendantWatch && apiResults.Feedback.details.Failed.Defendants>0">
              <br/>
              <i class="fal fa-exclamation-circle icon-spacer"></i>
              <span>{{apiResults.Feedback.details.Existed.Defendants}} files had errors.</span>
            </ng-container>
            <ul>
              <li *ngFor="let report of summaryReportList">{{ report }}</li>
            </ul>
          </div>

          <div
            *ngIf="recordToProcess.length > 0 && totalProgress <= 0  && importErrorType != 'critical' && !apiResults">
            <dx-button
              (onClick)="startUploadToApi()"
              [disabled]="isBusy"
              text="{{isBusy?'Please wait...':'Upload Data'}}"
              type="default"
            >
            </dx-button>
          </div>
          <div *ngIf="apiResults">
            <dx-button
              (onClick)="finishAndClose()"
              text="Finish"
              type="default"
            >
            </dx-button>
          </div>
          <div *ngIf="apiResults && forDefendantWatch" style="margin-top: 16px;">
            <span><small>Please note it may take a few hours for the watches to show up.</small></span>
          </div>

          <dx-progress-bar
            #progressBar
            [class.complete]="progressBar.value >= 100"
            [max]="100"
            [min]="0"
            [statusFormat]="format"
            [value]="totalProgress"
            [visible]="totalProgress > 0"
            id="progress-bar-status"
          >
          </dx-progress-bar>

          <div *ngIf="totalProgress >= 100">
            <dx-button
              (onClick)="hidden($event)"
              text="Finished"
              type="default"
            ></dx-button>
          </div>
        </div>
      </div>
      <!-- /*ngIf showImport -->
    </div>
  </div>
  <!-- Great for debugging... otherwise leave commented or delete. -->
  <div *ngIf="apiResults && !forDefendantWatch" class="row">
    <div class="col-md-6 col-md-offset-3">
      <hr class="hr-line-dashed"/>

      <h4>Results:</h4>

      Created {{apiResults.Feedback.details.Created.Bonds}} Records.
      <br>
      <br>
      <dx-accordion [dataSource]="accordionData" itemTemplate="item" [collapsible]="true" [animationDuration]="300">
        <div *dxTemplate="let detail of 'title'">
          <h5>{{detail.title }}</h5>
        </div>
        <div *dxTemplate="let itemData of 'item'">
          <p *ngFor="let key of getItemKeys(itemData)">
            {{key}}: {{itemData[key]}}
          </p>
        </div>
      </dx-accordion>

    </div>
  </div>
</ng-template>
