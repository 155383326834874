<div class='leftside-nav'>
  <dx-scroll-view
    class='custom-sidenav-scroll'
    [height]='scrollViewHeight'>
    <mat-nav-list  fxLayout="column" fxFlex>
      <mat-accordion [displayMode]="'flat'" *ngIf='accounts.length'>
        <div>
          <mat-expansion-panel
            appCustomExpansionToggle
            class='mat-elevation-z0'
          >
            <mat-expansion-panel-header [@.disabled]='true' [expandedHeight]="'48px'">
              <mat-panel-title>
                <span>{{account.Name}}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor='let account of accounts'>
              <a *ngIf='account.CustomerId != activeCustomerId' mat-list-item (click)='switchAccount(account)'>
                <span>{{account.Name}}</span>
              </a>
            </div>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
      <mat-accordion [displayMode]="'flat'">
        <div *ngFor='let navItem of navList'>
          <a mat-list-item
             [routerLink]='navItem.link'
             routerLinkActive='active'
             [routerLinkActiveOptions]='{exact: true}'
             *ngIf='navItem.pages.length === 0 && navItem.conditions && navItem.subscribed'>
            <i class='{{navItem.icon}} fa-fw'></i>
            <span>{{ navItem.heading }}</span>
          </a>

          <mat-expansion-panel
            appCustomExpansionToggle
            class='mat-elevation-z0'
            routerLinkActive='in'
            [expanded]='rla.isActive'
            #rla='routerLinkActive'
            *ngIf='navItem.pages.length > 0 && navItem.conditions && navItem.subscribed'>
            <mat-expansion-panel-header [@.disabled]='true' [expandedHeight]="'48px'">
              <mat-panel-title>
                <i class='{{navItem.icon}} fa-fw mt-2' style='font-style: normal;font-weight: 500;color: rgb(167, 177, 194);top: 40%;'>
                  <fa-layers *ngIf='navItem.heading == "Version 1"' [fixedWidth]='true'>
                    <fa-layers-text content='V1' [inverse]='true'></fa-layers-text>
                  </fa-layers>
                </i>
                <span>{{ navItem.heading }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item
               [routerLink]='navPage.link'
               [queryParams]='navPage.queryParam'
               routerLinkActive='active'
               *ngFor='let navPage of navItem.pages | navFilter'>
              <span>{{ navPage.title }}</span>
              <fa-layers *ngIf='navPage.beta' [fixedWidth]='true'>
                <fa-layers-text content='BETA' [inverse]='true' [transform]='"shrink-6 up-6 right-1"'
                                style='font-weight:900'></fa-layers-text>
              </fa-layers>
            </a>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
      <!-- Help section -->
      <mat-accordion [displayMode]="'flat'" *ngIf='isBail'>
        <div>
          <mat-expansion-panel
            appCustomExpansionToggle
            class='mat-elevation-z0'
            >
            <mat-expansion-panel-header [@.disabled]='true' [expandedHeight]="'48px'">
              <mat-panel-title>
                <i class='far fa-question-circle fa-fw mt-2'></i>
                <span>Help</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a *ngIf='isBail' href='//captira.zendesk.com' target='_blank' mat-list-item>
              <span>Articles</span>
            </a>
            <a href='//captira.zendesk.com/hc/en-us/requests/new' target='_blank' mat-list-item>
              <span>Create A Ticket</span>
            </a>
            <a mat-list-item *ngIf='isBail && !limitedUserMenuVisible' (click)="eventService.viewGuidedSetup('true')">
              <span>Guided Setup</span>
            </a>
            <a href='//captira.com/pages/contact-us' target='_blank' mat-list-item>
              <span>Contact Us</span>
            </a>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </mat-nav-list>

  </dx-scroll-view>
</div>
