import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class DefmgrAddService {

  clicked: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  clickedAdd() {
    // console.log('clicked');
    this.clicked.emit(true);
  };
}
