import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Bail } from '../../../../models/bail';
import { FormControl, Validators } from '@angular/forms';
import Guid from 'devextreme/core/guid';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from '../../../../common/UnsubscribeOnDestroy';
import { DocumentService } from '../../../../services/document.service';
import { Defendant } from '../../../../models/defendant';

@Component({
  selector: 'app-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss'],
})
export class DocumentUploaderComponent extends UnsubscribeOnDestroyAdapter implements AfterViewInit {

  @Input() transactions: Bail[];
  @Input() defendant: Defendant;
  @Output() transactionsChange = new EventEmitter<Bail[]>();

  @Output() onComplete = new EventEmitter<boolean>();

  transactionList = [];
  index = 0;
  public transactionControl = new FormControl(0);

  constructor(private documentService: DocumentService) {
    super();
  }

  ngAfterViewInit(): void {
    this.transactionControl = new FormControl(0, this.transactions.length === 1 ? Validators.required : null);
    for (const [i, transaction] of this.transactions.entries()) {
      this.transactionList.push({
        Id: i,
        Text: `Posted Date: ${transaction.PostedDate}`,
      });
    }
  }

  handleCancel() {
    this.onComplete.emit(false);
  }

  handleSave() {
    const observableQueue = [];
    for (const transaction of this.transactions) {
      transaction.documents.forEach(doc => {
        doc.tempKey = new Guid().toString();
        doc.SourceType = 'DefendantTransactions';
        doc.SourceId = transaction.Id;
        if (doc.isAdded) {
          observableQueue.push(this.documentService.addDocument(doc));
        } else if (doc.isDeleted) {
          observableQueue.push(this.documentService.removeDocument(doc.Id));
        }
      });
    }
    if (observableQueue.length === 0) {
      const dummyObs = new Observable(obs => {
        obs.next();
      });
      observableQueue.push(dummyObs);
    }
    this.subs.sink = observableCombineLatest(observableQueue).subscribe((docResp: any) => {
      // lets find the deleted and remove them from the client model collections seeing as the api does not need to
      // process them anymore
      this.transactions.forEach(tran => {
        tran.documents.forEach((doc, idx, obj) => {
          if (doc.isAdded) {
            doc.Id = docResp.find(
              (itm: any) => {
                return itm.data.tempKey === doc.tempKey;
              },
            ).data.data.Id;
            doc.isAdded = false;
          } else if (doc.isDeleted) {
            doc.isDeleted = false;
          }
        });
      });
    });
    this.onComplete.emit(true);
  }

}
