import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicPopupComponent} from './dynamic-popup.component';
import {
  DxAutocompleteModule,
  DxBoxModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxListModule,
  DxMenuModule,
  DxPopupModule,
  DxScrollViewModule,
  DxToolbarModule
} from 'devextreme-angular';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    DxPopupModule, DxToolbarModule, DxListModule, DxDataGridModule, DxBoxModule, DxButtonModule, DxAutocompleteModule,
    DxMenuModule, DxPopupModule, DxScrollViewModule, DxCheckBoxModule, DxScrollViewModule
  ],
  declarations: [DynamicPopupComponent],
  exports: [DynamicPopupComponent]
})
export class DynamicPopupModule {
}
