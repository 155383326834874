import { Component, OnInit } from '@angular/core';
import { CustomerService } from './../../services/customer.service';
import { UnsubscribeOnDestroyAdapter } from './../../common/UnsubscribeOnDestroy';
import {NotifyService} from '../../common/notify/notify.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-onboarding-support-popup',
  templateUrl: './onboarding-support-popup.component.html',
  styleUrls: ['./onboarding-support-popup.component.scss']
})
export class OnboardingSupportPopupComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  disabled = false;

  constructor(private customerService: CustomerService, private notify: NotifyService, private dialogRef: MatDialogRef<OnboardingSupportPopupComponent>) { super() }

  ngOnInit(): void {
  }

  navigateToContactPage() {
    window.open('https://www.captira.com/pages/contact-us', '_blank');
  }

  sendMailToSupport() {
    this.disabled = true;
    this.customerService.doItForMeSupport({}).subscribe(res => {
      this.disabled = false;
      this.notify.toast('Customer support mailed successfully. They will get back to you.');
      this.dialogRef.close();
    }, err => {
      this.disabled = false
    });
  }
}
