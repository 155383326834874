<h5 class="center-text">This list is for your manually added restrictions.  Opt-out keywords are automatically handled by service provider.</h5>
<dx-data-grid
  id="gridData"
  [dataSource]="gridData"
  keyExpr="Id"
  noDataText="No records. Click + above to add"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onDataErrorOccurred)="onDataErrorOccurred()"
  [remoteOperations]="true"
  [columnHidingEnabled]="true">
  <dxo-search-panel [visible]="true" [placeholder]="'Search'">
  </dxo-search-panel>
  <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
  <dxo-pager
    [showInfo]="true"
    [showPageSizeSelector]="true"
    [visible]="true"
    [allowedPageSizes]="[5, 10, 25, 50]"
  ></dxo-pager>
  <dxo-editing
    mode="popup"
    [allowUpdating]="true"
    [allowAdding]="true"
    [allowDeleting]="true">
    <dxo-popup
      title="Number Exclusions"
      [showTitle]="true"
      [width]="420"
      [height]="250"
      [position]="{
        my: 'center',
        at: 'center',
        of: popupWindow }">
    </dxo-popup>
  </dxo-editing>

  <dxo-selection mode="single"></dxo-selection>

  <dxi-column
    dataField="Number"
    caption="Number"
    dataType="string"
    cellTemplate="numberTemplate"
    [hidingPriority]="2">
    <div *dxTemplate="let data of 'numberTemplate'">
      {{ data.value | phone }}
    </div>
  </dxi-column>
  <dxi-column
    dataField="RequestedBy"
    readonly
    caption="Requested By"
    dataType="string"
    [formItem]="{visible: false}"
    [hidingPriority]="1">
  </dxi-column>

</dx-data-grid>

<app-errorpopup
  [height]="'300'"
  [width]="'400'"
  [closeOnOutsideClick]="false"
  [title]="errorTitle"
  [shading]="false"
  [data]="errorData"
  [(visible)]="errorVisible"
  [routeToGoToAfterDisplay]="''">
</app-errorpopup>
