import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { CustomerService } from './../../services/customer.service';
import { UnsubscribeOnDestroyAdapter } from './../../common/UnsubscribeOnDestroy';
import { NotifyService } from '../../common/notify/notify.service';

@Component({
  selector: 'app-confirm-setup-popup',
  templateUrl: './confirm-setup-popup.component.html',
  styleUrls: ['./confirm-setup-popup.component.scss']
})
export class ConfirmSetupPopupComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  disabled = false;

  constructor(private dialogRef: MatDialogRef<ConfirmSetupPopupComponent>, @Inject(MAT_DIALOG_DATA) public data : any,
  private customerService: CustomerService, private notify: NotifyService) { super(); }

  ngOnInit(): void {
  }

  navigateToPage() {
    let actionName = '';
    let actionStep = 0;
    let description = '';

    if(this.data.type === 'basic') {
      actionName = "basiccomplete";
      actionStep = 5;
      description = "basic complete";
    } else {
      actionName = "movetocustomsetup";
      actionStep = 5;
      description = "basic complete complete, user navigated to custom setup";
    }

    this.notify.toast('Loading...', 'success');
    this.disabled = true;
    this.subs.sink = this.customerService.logCustomerOnboardingAction({actionName, actionStep, description}).subscribe(res => {
      this.disabled = false;
      this.dialogRef.close(true);
    });
  }
}
