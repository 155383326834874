import { Action, createReducer, on } from '@ngrx/store';
import { ICompany } from '../../models/icompany';
import { loadCompanysSuccess } from '../actions/company.actions';


export const companyFeatureKey = 'company';

export interface CompanyState {
  company: ICompany;
}

export const initialState: CompanyState = {
  company: {
    Id: null,
    Name: null,
    DefaultContactPerson: null,
    DefaultContactTitle: null,
    WebSiteAddress: null,
    DefaultUserId: null,
    PhysicalAddressLine1: null,
    PhysicalAddressline2: null,
    PhysicalCity: null,
    PhysicalCounty: null,
    PhysicalStateCode: null,
    Zip: null,
    CustomerId: null,
    Deleted_At: null,
    phones: null,
    addresses: null,
    Logo: null,
    AddLogoToForms: null,
    GooglePlaceId: '',
  },
};


export const companyReducer = createReducer(
  initialState,
  on(loadCompanysSuccess, (state, { company }) => ({...state, company: { ...company }})),
);

export function reducer(state: CompanyState | undefined, action: Action) {
  return companyReducer(state, action);
}

