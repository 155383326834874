import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()
export class InventoryService {

  constructor(private http: HttpClient, private settings: SettingsService) {

  }

  getInventoryToBeCreated(data: any): Observable<any> {
    // console.log('stringified data', JSON.stringify(data));
    return this.http.post(`${this.settings.getBaseUrl()}/getinventorytobecreated`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  createAssignInventory(data: any): Observable<any> {
    // console.log('stringified data', JSON.stringify(data));
    return this.http.post(`${this.settings.getBaseUrl()}/inventory`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  deleteInventory(data: any): Observable<any> {
    // console.log('stringified data', JSON.stringify(data));

    return this.http.post(`${this.settings.getBaseUrl()}/inventory/delete`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  // use for getting data for receipts, etc.
  findInventoryById(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/inventory/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getAssignmentHistory(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/assignment/history/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

}
