<mat-tab-group [selectedIndex]="!showCheckinHistory ? 0 : 1">
  <mat-tab label="Location History">
    <div class="map-bg-image map-bg-container" *ngIf="!subscribedToReminders">
<!--      <a [routerLink]="[ settingsUrl]"-->
<!--         [queryParams]="{ popup: 'testmessage' }"-->
<!--         mat-raised-button-->
<!--         style="margin-right: 8px">-->
<!--        Test Message-->
<!--      </a>-->
      <a href="{{settingsUrl}}" target="_blank" rel="noopener noreferrer" mat-raised-button
         color="primary" class="map-bg-vertical-center">
        Reminder Settings
      </a>
    </div>
    <div *ngIf="subscribedToReminders && checkinHistoryReady && mvsHistoryReady && locationHistoryDataCombined.length > 0">
      <div *ngIf="currentDevice" class="row">
        <div class="col-sm-6">
          <strong class="text-muted">
            Defendant
          </strong>
          {{currentDevice.DefendantName}}
        </div>
        <div class="col-sm-6">
          <strong class="text-muted">
            Phone Number
          </strong>
          {{formatPhone(currentDevice)}}
        </div>
      </div>

      <div class="row">
        <form class="form-inline">
          <div class="col-md-4 form-group">
            <div class="dx-fieldset">
              <div class="dx-field">
                <dx-select-box
                  [dataSource]="dateFilters"
                  displayExpr="text"
                  valueExpr="value"
                  [value]="dateFilter"
                  (onValueChanged)="onLocationPopupDateSelected($event)"
                  placeholder="All Dates"
                >
                </dx-select-box>
              </div>
            </div>
          </div>
          <div class="col-md-4 form-group" *ngIf="dateRangeFiltersVisible">
            <div class="dx-fieldset">
              <div class="dx-field">
                <dx-date-box type="date" dateSerializationFormat="yyyy-MM-dd" width="100%"
                             [displayFormat]="dateFormatter"
                             [value]="historyRangeStartDateFilter"
                             (onValueChanged)="applyDateRangeFilters($event, 'start')"></dx-date-box>
              </div>
            </div>
          </div>
          <div class="col-md-4 form-group" *ngIf="dateRangeFiltersVisible">
            <div class="dx-fieldset">
              <div class="dx-field">
                <dx-date-box type="date" dateSerializationFormat="yyyy-MM-dd" width="100%"
                             [displayFormat]="dateFormatter"
                             [value]="historyRangeEndDateFilter"
                             (onValueChanged)="applyDateRangeFilters($event, 'end')"></dx-date-box>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="row">
        <div class="col-md-4">
          <dx-data-grid #locationHistoryGrid
                        id="locationHistoryGrid"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [dataSource]="locationHistoryDataCombined"
                        width="100%"
                        height="100%"
                        rowAlternationEnabled="true"
                        [loadPanel]="{enabled: true, showIndicator: true, showPane: true, text: 'Loading...'}"
                        noDataText="No history is available for this Number"
                        (onToolbarPreparing)="onLocationToolbarPreparing($event)"
                        (onRowClick)="onHistoryRowClick($event)"
                        (onContentReady)="onHistoryGridContentReady($event)"
          >
            <dxo-paging [pageSize]="5"></dxo-paging>
            <dxi-column caption="Location" [visible]="true" dataField="Address" [visibleIndex]="colIndex.Location"
                        [hidingPriority]="colHidingPriority.Location"></dxi-column>
            <dxi-column caption="Time" [visible]="true" dataField="LocationTimestamp" dataType="datetime"
                        editorOptions="{dateSerializationFormat: 'yyyy-MM-ddTHH:mm'}"
                        [hidingPriority]="colHidingPriority.LocationTimestamp"
                        [visibleIndex]="colIndex.LocationTimestamp"></dxi-column>
            <dxi-column caption="Accuracy" [visible]="true" dataField="Accuracy"
                        [hidingPriority]="colHidingPriority.Accuracy" [alignment]="'center'"
                        [visibleIndex]="colIndex.Accuracy"></dxi-column>
            <dxi-column caption="Check In Photo" [visible]="showCheckinPhotoColumn" dataField="PictureUrl"
                        [hidingPriority]="colHidingPriority.PictureUrl" cellTemplate="photoLinkTemplate"
                        [alignment]="'center'" [visibleIndex]="colIndex.PictureUrl"></dxi-column>
            <dxo-editing [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
            <div *dxTemplate="let data of 'photoLinkTemplate'">
              <a *ngIf="data.data.PictureUrl" href="{{data.data.PictureUrl}}" target="_blank">
                <img src="{{data.data.PictureUrl}}"
                     style="display:block; width:25px; height:25px; margin-left: auto; margin-right: auto"/>
              </a>
              <span *ngIf="!data.data.PictureUrl">(none)</span>
            </div>
          </dx-data-grid>
          <span *ngIf="currentDevice" class="location-text">Any missing days for monitored devices may be a result of no service, phone powered off, phone otherwise unreachable or on airplane mode.</span>
        </div>

        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12 form-group" style="text-align: right;">
              <dx-check-box
                text="Show Accuracy"
                [(value)]="showRadius">
              </dx-check-box>
            </div>
          </div>
          <google-map
            [center]="{lat: location?.latitude, lng: location?.longitude}"
            [zoom]="10"
            id="history-map"
            *ngIf="locationHistoryDataCombined.length > 0"
          >
            <map-marker
              #marker="mapMarker"
              *ngFor="let m of mvsMarkers; let i = index"
              [position]="{lat: m.latitude, lng: m.longitude}"
              [options]="{icon: {url: 'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=|00B995|'}}"
              [clickable]="true"
              (mapClick)="onMarkerClick(marker, i)"
            >
              <map-info-window #infoWindow>{{m.address}} ({{m.time}})</map-info-window>
            </map-marker>
            <map-marker
              #marker="mapMarker"
              *ngFor="let m of checkinMarkers; let i = index"
              [position]="{lat: m.latitude, lng: m.longitude}"
              [options]="{icon: {url: m.iconUrl}}"
              [clickable]="true"
              (mapClick)="onMarkerClick(marker, i)"
            >
              <map-info-window #infoWindow>{{m.address}} ({{m.time}})</map-info-window>
            </map-marker>
            <ng-container *ngIf="showRadius">
              <map-circle
                *ngFor="let m of mvsMarkers"
                [center]="{lat: m.latitude, lng: m.longitude}"
                [radius]="m.radius"
                [options]="{fillColor: 'blue'}"
              ></map-circle>
              <map-circle
                *ngFor="let m of checkinMarkers"
                [center]="{lat: m.latitude, lng: m.longitude}"
                [radius]="m.radius"
                [options]="{fillColor: 'blue'}"
              ></map-circle>
            </ng-container>
          </google-map>
        </div>
      </div>
    </div>


    <div
      *ngIf="mvsHistoryReady && checkinHistoryReady && locationHistoryDataCombined.length <= 0 && subscribedToReminders">
      <div class="ibox float-e-margins">
        <div class="ibox-content">
          <br>
          <span
            style="text-align: center; margin: auto; float:none; width: auto"> No history is available for this file. </span>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Check In History" *ngIf="showCheckinHistory">
    <div class="row col-md-12 ibox-content">
      <div class="col-md-12 mtb-8">
        <dx-button type="default" text="Record Check In" (click)="recordCheckIn()"></dx-button>
        <dx-button type="normal" text="Edit Check In Schedule" (click)="editCheckInSchedule($event)" class="ml-8"></dx-button>
      </div>

      <div class="col-md-12">
        <dx-data-grid #checkinHistoryGrid
                      id="checkinHistoryGrid"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [dataSource]="checkinHistoryGridData"
                      width="100%"
                      height="100%"
                      rowAlternationEnabled="true"
                      [loadPanel]="{enabled: true, showIndicator: true, showPane: true, text: 'Loading...'}"
                      noDataText="No Check In history is available for this defendant"
                      (onToolbarPreparing)="onCheckinGridToolbarPreparing($event)"
        >
          <dxi-column caption="Date" [visible]="true" dataField="ScheduledDate" dataType="date"
                      width="110"></dxi-column>
          <dxi-column caption="Checked In" [visible]="true" dataField="CheckedIn" width="100"
                      dataType="boolean"></dxi-column>
          <dxi-column caption="Missed" [visible]="true" dataField="Missed" width="75" dataType="boolean"></dxi-column>
          <dxi-column caption="Comment" [visible]="true" dataField="Comment" width="350"></dxi-column>
          <dxi-column caption="Photo" [visible]="true" dataField="PictureUrl" cellTemplate="photoTemplate"></dxi-column>
          <dxi-column caption="Location" [visible]="true" dataField="Latitude"
                      cellTemplate="locationTemplate"></dxi-column>
          <!--          <dxi-column caption="Action" [visible]="true" cellTemplate="actionTemplate"></dxi-column>-->

          <div *dxTemplate="let data of 'photoTemplate'">
            <a *ngIf="data.value" href="{{data.value}}" target="_blank">
              <img src="{{data.value}}" style="display:block; width:25px; height:25px;"/>
            </a>
            <span *ngIf="!data.value">(none)</span>
          </div>

          <div *dxTemplate="let data of 'actionTemplate'">
            <a (click)="recordCheckIn(data.data)" [hidden]="data.data.CheckedIn || data.data.Missed">Record Check In</a>
          </div>

          <div *dxTemplate="let data of 'locationTemplate'">
            <a [hidden]="!data.data.Latitude" (click)="navigateOnMap(data.data)">{{data.data.Latitude}}
              ,{{data.data.Longitude}}</a>
          </div>

          <dxo-editing [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>

        </dx-data-grid>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<app-dynamic-popup [popupComponent]="dynamicPopupClass" [popupComponentData]="dynamicPopupData"
                   [popupComponentTitle]="dynamicPopupTitle" [showCloseButton]="true"
                   [popupComponentVisible]="dynamicPopupVisible" [popupShowTitle]="dynamicPopupShowTitle"
                   (onPopupHidden)="dynamicPopupHidden($event)">
</app-dynamic-popup>
