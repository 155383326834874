import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BillingService } from '../services/billing.service';
import { AppConstants } from '../shared/AppConstants';

import { UserService } from '../services/user.service';
import { NotifyService } from '../common/notify/notify.service';
import { AdminService } from '../services/admin.service';

export interface Dictionary {
  [index: string]: any;
}

@Injectable()
export class BillingGuard implements CanActivate {

  allowedRoutesByProduct: Array<Dictionary>;
  defaultRoutesByProduct: any;
  allowedProductsByRoute: any;
  allowedProductsByWildcardRoute: any;

  isAdmin = false;

  constructor(
    private billingService: BillingService,
    private router: Router,
    private userService: UserService,
    private notifyService: NotifyService,
    private adminService: AdminService,
  ) {

    this.allowedRoutesByProduct = [];
    this.defaultRoutesByProduct = [];

    this.allowedRoutesByProduct[AppConstants.PRODUCT_NAME_DEFENDANT_WATCH] =
      ['/system/company',
        '/system/users',
        '/serviceshome',
        '/defendantwatch'];

    this.allowedRoutesByProduct[AppConstants.PRODUCT_NAME_MVS_NETWORK_ACCESS] =
      ['/mobile-verification',
        '/system/company',
        '/system/users',
        '/serviceshome'];

    this.allowedRoutesByProduct[AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER] =
      ['/defendantmanager/summary',
        '/defendantmanager/defendants',
        '/defendantmanager/calendars',
        '/defendantmanager/reminder-setting*',
        '/defendantmanager/companies',
        '/defendantmanager/users',
        '/defendantmanager/riskassessments',
        '/defendantmanager/mergedefendant',
        '/defendantmanager/bulk-import',
        '/defendantmanager/reporting',
      ];

    // this is in addition to the above.. since user must subscribed to def mgr to get plus
    this.allowedRoutesByProduct[AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER_PLUS] =
      ['/defendant/*',
        '/reporting',
        '/defendantmanager/data-capture'];

    this.defaultRoutesByProduct[AppConstants.ADMIN_EMPLOYEE_DEFAULT] = '/admin/customers';
    this.defaultRoutesByProduct[AppConstants.PRODUCT_NAME_DEFENDANT_WATCH] = '/defendantwatch';
    this.defaultRoutesByProduct[AppConstants.PRODUCT_NAME_MVS_NETWORK_ACCESS] = '/mobile-verification';
    this.defaultRoutesByProduct[AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM] = '/dashboard';
    this.defaultRoutesByProduct[AppConstants.PRODUCT_NAME_DEFENDANT_MANAGER] = '/defendantmanager/defendants';
    this.defaultRoutesByProduct[AppConstants.PRODUCT_NAME_COLLECTIONS] = '/collections/summary';
    this.defaultRoutesByProduct[AppConstants.PRODUCT_NAME_DATAMART_ACCESS] = '/background-checks';

    // Collections subscribed users
    this.allowedRoutesByProduct[AppConstants.PRODUCT_NAME_COLLECTIONS] = [
      '/collections/summary',
      '/collections/collection-data',
      '/collections/accounts*',
      '/collections/accounts/*',
      '/collections/calendars',
      '/collections/reminder-setting*',
      '/collections/companies',
      '/collections/companies/*',
      '/collections/users',
      '/collections/settlements',
      '/collections/requests*',
      '/collections/requests/*',
      '/collections/undeliverable',
    ];

    this.buildArrayByRoute();
  }
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | any> | Promise<boolean | any> | boolean {

    // if (!(environment.environmentName.toUpperCase() === 'LOCAL' || environment.environmentName.toUpperCase() === 'TEST' || environment.environmentName.toUpperCase() === 'PROD')) {
    //   return true;
    // }

    // if payment details are not there for collections then redirect to checkout via collection plans
    this.billingService.GetSubscribedProducts().subscribe(products => {
      if (products[AppConstants.PRODUCT_NAME_COLLECTIONS]) {
        this.billingService.CustomerHasPaymentOnRecord().subscribe(paymentOnRecord => {
          if (!paymentOnRecord) {
            this.billingService.signupProduct = AppConstants.PRODUCT_NAME_COLLECTIONS;
            this.router.navigate(['/collection-plans']);
          }
        });
      }
    });

    const userType = this.getUserType().pipe(
      mergeMap(() => {
        return this.billingService.AccountInGoodStanding().pipe(
          mergeMap(result => {
            if (result === false && !this.isAdmin) {
              if (sessionStorage.getItem('reactivating') === 'true') {
                // console.log('In billing guard: IF')
                this.router.navigate(['/getstarted/checkout']);
              } else {
                // console.log('In billing guard: ELSE')
                this.billingService.GetCustomerBillingStatusString().subscribe(status => {
                  // console.log('Response of GetCustomerBillingStatusString: in billing guard: status: ', status);
                  if (status === 'TRIAL_ENDED') {
                    this.router.navigate(['/getstarted/checkout']);
                  }
                });
              }
            } else if (result == null) {
              // Result came back null which probably means server error.
              //  We should probably let the user pass in order be resilient
              //  to errors..
              // TODO: revisit
             // console.log('Account in good standing result came back null?  Setting to true for resilience to server/provider errors.');
              return of(true);
            } else {
              if (this.isAdmin && localStorage.getItem('userEmail').indexOf('captira.com') !== -1 && result === false && state.url.indexOf('/dashboard') !== -1) {
                this.notifyService.toast(
                  'Customer Needs to Update Billing',
                  'error',
                  'OK',
                  0,
                  '',
                  {
                    'vertical': 'top',
                    'horizontal': 'center',
                  },
                );
              }
              // now need to check what product they are using and if this route is allowed for that product
              return this.billingService.GetSubscribedProducts().pipe(
                map((productSubscription) => {
                  if (productSubscription[AppConstants.PRODUCT_NAME_BAIL_MANAGEMENT_SYSTEM] || state.url.indexOf('/developer') === 0) {
                    // don't filter routes for Bail management.  At least, not yet
                    // also allow any routes under /developer
                    return true;
                  } else {


                    // If the user is employee, will have access to any route
                    if (this.isAdmin && state.url.includes('admin')) {
                      return true;
                    }

                    // find products by route
                    // if none of the products are subscribed, then go to the default for the first subscribed product foun

                    // console.log(`allowed products by route for ${state.url}`, this.allowedProductsByRoute[state.url]);
                    if (this.allowedProductsByRoute[state.url]) {
                      for (const allowedProduct of this.allowedProductsByRoute[state.url]) {
                        // console.log(`product is subscribed? ${allowedProduct}`, productSubscription[allowedProduct]);
                        if (productSubscription[allowedProduct]) {
                          return true;
                        }
                      }
                    }
                    // if execution reaches this point, need to check if route matches a wildcard
                    for (const key in this.allowedProductsByWildcardRoute) {
                      // if route matches a wildcard
                      if (state.url.indexOf(key) >= 0) {
                        // for each product allowed for this wildcard
                        for (const allowedProduct of this.allowedProductsByWildcardRoute[key]) {
                          // console.log(`wildcard route search`);
                          // if user is subscribed to any product, allow activation
                          if (productSubscription[allowedProduct]) {
                            return true;
                          }
                        }
                      }
                    }

                    // if execution reaches this point, then none of the subscribed products are allowed for this route
                    console.error(`Route disallowed for subscription: ${state.url}`);
                    for (const productName of this.billingService.GetAllProducts()) {
                      if (this.defaultRoutesByProduct[productName] && productSubscription[productName]) {
                        // console.log('navigating to default route for subscription', this.defaultRoutesByProduct[productName]);
                        this.router.navigate([this.defaultRoutesByProduct[productName]]);
                      } else if (this.isAdmin) {
                        this.router.navigate([this.defaultRoutesByProduct['ADMINEMPLOYEE']]);
                      }
                    }

                    // if execution reaches this point, it seems there are no allowed routes?
                  }
                }),
                catchError(error => of(error)),
              );
            }
          }));
      }),
    );
    return userType;
  }

  private getUserType(): Observable<boolean | any> {
    return new Observable(obs => {
      this.userService
        .current()
        .subscribe(resp => {
          if (!!resp.data) {
            this.isAdmin = resp.data.Type === 'Administrator';
          } else {
            this.isAdmin = false;
          }
          this.adminService.isAdmin = this.isAdmin;
          obs.next();
          obs.complete();
        });
    });
  }

  private buildArrayByRoute() {
    this.allowedProductsByRoute = [];
    this.allowedProductsByWildcardRoute = [];
    for (const product of this.billingService.GetAllProducts()) {
      // console.log('product of GetAllProducts', product, this.allowedRoutesByProduct[product]);
      if (this.allowedRoutesByProduct[product]) {
        for (const route of this.allowedRoutesByProduct[product]) {
          if (route.toString().indexOf('*') >= 0) {
            // this route allows for wildcard
            const baseWildcard = route.toString().replace('*', '');
            if (this.allowedProductsByWildcardRoute[baseWildcard]) {
              (<Array<string>>this.allowedProductsByWildcardRoute[baseWildcard]).push(product);
            } else {
              this.allowedProductsByWildcardRoute[baseWildcard] = [product];
            }
          }
          if (this.allowedProductsByRoute[route]) {
            (<Array<string>>this.allowedProductsByRoute[route]).push(product);
          } else {
            this.allowedProductsByRoute[route] = [product];
          }
        }
      }
    }
    // console.log('allowedProductsByRoute', this.allowedProductsByRoute);

  }

}
