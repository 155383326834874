<!-- Top navigation -->
<topnavbar (sideNavEvent)="nav.toggle()" (contentLoaded)="onContentLoaded($event)"></topnavbar>
<mat-sidenav-container  autosize [hasBackdrop]='false'>
  <!-- Left sidenav -->
  <mat-sidenav #nav
               id='nav'
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [attr.role]="mobileQuery.matches ? 'dialog' : 'navigation'"
    [opened]="!(mobileQuery.matches)"
    [fixedTopGap]="mobileQuery.matches ? '56' : '64'"
    fixedInViewport="true">
    <app-navigation></app-navigation>
  </mat-sidenav>

  <mat-sidenav #slideOut id='slideOut' class="example-container" position='end' >


    <div class='sidenave-header'>
      <div class='quick-action'>
      <app-quick-actions-preview >


      </app-quick-actions-preview>
      </div>
      <button mat-icon-button (click)="slideOut.close()" class='close_button'>
        <i class="fal fa-times"></i>
      </button>
    </div>

    <dx-scroll-view
      #scrollView
      [showScrollbar]="'always'"
      id="scrollview">
      <ng-template #vcRef></ng-template>
      <mat-spinner class='spinner' color='primary' *ngIf='!showpreview'></mat-spinner>
    <app-defendant-preview
      *ngIf='showpreview'
      [slideOut]='true'
      [defendantId]='defendantId'>

    </app-defendant-preview>
    </dx-scroll-view>
  </mat-sidenav>
  <!-- Main Content -->
  <mat-sidenav-content class='slideout-open' #content id="main-content-body" >
    <!-- id topPage placed here so we have something to reference top of page when window.scrollTo doesn't work -->
    <app-nuvei-registration-banner id="topPage"></app-nuvei-registration-banner>

    <!-- Generic message banner -->
    <app-advice-banner *ngFor="let message of displayAdviceBanners" [display]='message.display' [render]="message.render"></app-advice-banner>

    <div class="animation-wrapper" [@routeAnimations]="prepareRoute(outlet)">
      <div *ngIf="pageTitle" class="page-title endless-constellation" [ngClass]="{'make-sticky' : isFullProfile}">
        <div [ngClass]="{'added-subhead': pageSubtext}">
          <h1>{{ pageTitle }}</h1> <h2 *ngIf='accounts.length > 1'> - {{account.Name}}</h2>
          <p *ngIf="pageSubtext">{{ pageSubtext }}</p>
        </div>
        <ng-container *ngComponentOutlet="pageActions"></ng-container>
      </div>

      <div class="page-contents">
        <div class="overlay" *ngIf="showLoading">
          <div class="center" *ngIf="showLoading">
            <mat-spinner strokeWidth="7" style="margin:0 auto;"></mat-spinner>
          </div>
        </div>
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>



</mat-sidenav-container>
