import {catchError, map} from 'rxjs/operators';
import {throwError,  Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {ApiUtilities} from '../shared/ApiUtilities';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PaymentscheduleService {

  private headers = new Headers(ApiUtilities.DEFAULT_HEADERS);

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getById(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/paymentschedule/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getByTransactionId(id: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/paymentschedulebytransactionid/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getMinDateAndMaxValue(transactionId: any): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/paymentschedule/getgenerationparams/${transactionId}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  save(id: any, data: any): Observable<any> {
    // console.log('save id', id);
    // console.log('save data', data);
    // console.log('save json data', JSON.stringify(data));

    return this.http.put(`${this.settings.getBaseUrl()}/paymentschedule/${id}`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${this.settings.getBaseUrl()}/paymentschedule`, JSON.stringify(data)).pipe(
      map((res: any) => res),
      catchError(error => {
        return throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error');
      }),);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.settings.getBaseUrl()}/paymentschedule/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }
}
