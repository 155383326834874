import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {ApiUtilities} from '../shared/ApiUtilities';

@Injectable()
export class EmailService {

  private postData: any;
  private headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private settings: SettingsService) {
  }

  getAll(): Observable<any> {
    const postData = {};

    return this.http.get(`${this.settings.getBaseUrl()}/emailes`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);

  }

  get(id): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/email/${id}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }

  getForSource(id: number, sourceType: string) {
    return this.http.get(`${this.settings.getBaseUrl()}/email/bysource/${id}/${sourceType}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')),);
  }

  getDefaultForSource(id: number, sourceType: string): Observable<any> {
    return this.http.get(`${this.settings.getBaseUrl()}/email/defaultforsource/${id}/${sourceType}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);
  }


  search(): Observable<any> {
    const postData = {};

    return this.http.get(`${this.settings.getBaseUrl()}/emailes`).pipe(
      map((res: any) => res),
      catchError(error => throwError(error.error || 'Server Error')),);

  }

  updateLinkClickedStatusForEmailClientAuth(id: string, cid: string, sourceType: string, isContact: string): Observable<any> {
    if(isContact === '1')
    {
      isContact = '/1';
    }
    return this.http.get(`${this.settings.getBaseUrl()}/updatelinkclickedstatus/${id}/${cid}${isContact}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));

  }

  updateLinkClickedStatusForSmsClientAuth(id: string, cid: string, sourceType: string): Observable<any> {

    return this.http.get(`${this.settings.getBaseUrl()}/updatelinkclickedstatussms/${id}/${cid}`).pipe(
      map((res: any) => res),
      catchError(error => throwError(ApiUtilities.getErrorMessage(error, true) || 'Server Error')));

  }
}
