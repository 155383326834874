import { createAction, props } from '@ngrx/store';

export enum ReportItemActionTypes {
  SelectItem = '[ReportItem] Select Item',
}

interface reportItemProps {
	itemType: any,
	itemId: any,
	url: any,
	selectedReport: any,
	scrollPosition: any,
	pageOptions: any
}

export const selectReportItem = createAction(
  ReportItemActionTypes.SelectItem,
  props<reportItemProps>()
);
