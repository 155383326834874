<div class='fixActionRow'>
    <h1 mat-dialog-title>Contact Type</h1>
        <div mat-dialog-content>
        <label class='col-sm-4 control-label'>
          {{data.contact.person.FullName}}
        </label>
        
        <div class='col-sm-6' style="margin-top:-0.5rem;">
          <dx-select-box
            [dataSource]="contactTypes"
            displayExpr="Text"
            valueExpr="Text"
            placeholder="Contact Type"
            (onValueChanged)="onValueChanged($event)"
            >
          </dx-select-box>
        </div>
      </div>
      <div class='spacer'></div>
    
      <div mat-dialog-actions align='end'>
        <button mat-stroked-button [mat-dialog-close]="'cancel'">Cancel</button>
        <button mat-raised-button
                color='primary'
                [mat-dialog-close]="data.contact"
                type='submit'>
          Save
        </button>
      </div>
</div>
    