<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
  <dx-select-box
    appSelectFocus
    #lookup
    fxFlexFill
    (onFocusIn)='focusIn($event)'
    [isValid]="isValid"
    [dataSource]="lookupData"
    [displayExpr]="displayExpr"
    [valueExpr]="valueExpr"
    [placeholder]="placeholderVisible ? placeHolder : ''"
    [acceptCustomValue]="allowAddCustomValue"
    (onCustomItemCreating)="addCustomItem($event)"
    [(value)]="selectedValue"
    (onValueChanged)="valueChanged($event)"
    [readOnly]="fieldsDisabled"
    [disabled]="fieldsDisabled"
    [id]="setIdForSmartForms"
    [inputAttr]='{id: randomId}'
    isLookup="true">
    <div *dxTemplate="let data of 'dropDownButton'">
      <dx-load-indicator [visible]="isLoading"></dx-load-indicator>
      <div class="dx-dropdowneditor-icon" [hidden]="isLoading"></div>
    </div>
    <dx-validator>
      <dxi-validation-rule
        type="custom"
        [validationCallback]="validateLookup"
        message="Required">
      </dxi-validation-rule>
    </dx-validator>
  </dx-select-box>

  <span tabindex="-1" *ngIf="lookupType == 'jails' && !!selectedObject && !hideIcon">
    <button type='button' tabindex="-1" mat-icon-button (click)="google()">
      <i tabindex="-1" class="fal fa-external-link-square"></i>
    </button>
  </span>
</div>
<!-- TODO: When you remove the jails lookup, adjust the prospect screen css -->
<span tabindex='-1' *ngIf='fromExternal === false && (lookupType == "jails" || lookupType == "chargeclasses" || lookupType == "advertisingsources" || lookupType == "courts" || lookupType == "attorneys")'>
    <a (click)='navigateToList()' class='list-cleanup'>Cleanup your list</a>
</span>

<dx-popup
  class="popup"
  [showTitle]="true"
  title="Are You Sure?"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="showConfirmation"
  [maxWidth]="450"
  [maxHeight]="300"
  [showCloseButton]="false">
  <div class="text-center" *dxTemplate="let data of 'content'">
    <h4>{{ confirmationMessage }}</h4>

    <div style="margin: 15px 0;">
      <dx-button
        style="margin-right:2.5rem;"
        id="cancel"
        type="normal"
        [width]="85"
        text="No"
        icon=""
        (onClick)="cancel()">
      </dx-button>
      <dx-button
        id="save"
        type="default"
        [width]="85"
        text="Yes"
        icon=""
        (onClick)="addItemToList()">
      </dx-button>
    </div>

    <div class="row text-center">
      <a style="cursor:pointer;" (click)="navigateToList()">
        <i class="fal fa-info-circle fa-1x"></i>
      </a>
      <h5>Maintain your lists under System Manager > Dropdown Lists</h5>
    </div>
  </div>
</dx-popup>
