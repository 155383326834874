import { createAction, props } from '@ngrx/store';
import { ICompany } from '../../models/icompany';

export enum CompanyActionTypes {
  LoadCompanies = '[Company] Load Companies',
  LoadCompaniesSuccess = '[Company] Load Companies Success',
  LoadCompaniesFailure = '[Company] Load Companies Failure',
}

export const loadCompanys = createAction(
  CompanyActionTypes.LoadCompanies,
  props<{ userId: string }>(),
);

export const loadCompanysSuccess = createAction(
  CompanyActionTypes.LoadCompaniesSuccess,
  props<{ company: ICompany }>(),
);

export const loadCompanysFailure = createAction(
  CompanyActionTypes.LoadCompaniesFailure,
  props<{ error: any }>(),
);
