import { Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { UnsubscribeOnDestroyAdapter } from './../../../../common/UnsubscribeOnDestroy';
import { LookupService } from './../../../../services/app-lookup.service';

export interface ListPopupData {
  inputSource: string;
  fromLookup: boolean;
  text: string;
  lookupType: string;
}

@Component({
  selector: 'app-listspopup',
  templateUrl: './listspopup.component.html',
  styleUrls: ['./listspopup.component.scss'],
})
export class ListspopupComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {

  addedData: any = null;

  private element: HTMLElement;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ListPopupData,
    public lookupService: LookupService,
    private dialogRef: MatDialogRef<ListspopupComponent>,
    private viewContainerRef: ViewContainerRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.element = this.viewContainerRef.element.nativeElement;
    // this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1503';
    this.subs.sink = this.lookupService.dataWasAdded.subscribe(res => {
      if (res) {
        if (res.Id) {
          this.addedData = res;
        } else {
          this.addedData = null;
        }
        this.dialogRef.close(this.addedData);
      } else {
        this.dialogRef.close(null);
      }
    });
  }

  public ngOnDestroy(): void {
    this.element.parentElement.parentElement.parentElement.parentElement.style.zIndex = '1000';
  }
}



