import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Defendant } from '../../../models/defendant';
import { DefendantWatchService } from '../../../services/defendantwatch.service';
import { DefendantService } from '../../../services/defendant.service';
import { NotifyService } from '../../../common/notify/notify.service';
import * as moment from 'moment';
import {UnsubscribeOnDestroyAdapter} from '../../../common/UnsubscribeOnDestroy';
import { DialogService } from '../../../services/dialog.service';
import { Router } from '@angular/router';
import { BillingService } from '../../../services/billing.service';
import { AppConstants } from '../../../shared/AppConstants';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '../../../components/controls/confirmation/confirmation.component';
import { WarningDialogComponent } from '../../../components/controls/warning-dialog/warning-dialog.component';

@Component({
  selector: 'app-defendant-watch',
  templateUrl: './defendant-watch.component.html',
  styleUrls: ['./defendant-watch.component.css']
})
export class DefendantWatchComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges {

  @Input() model: Defendant;
  @Input() fieldsDisabled = false;
  isLoading: boolean = true;
  watch: any;
  isBeingWatched: boolean = false;
  alertsCount: number = 0;
  errorData: any;

  constructor(
    private defendantWatchService: DefendantWatchService,
    private defendantService: DefendantService,
    private notifyService: NotifyService,
    private dialogService: DialogService,
    private router: Router,
    private billingService: BillingService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.defendantWatchService.GetWatchByDefendant(this.model.Id).subscribe(res => {
      console.log('def watch', res);
      if (res.length > 0) {
        this.watch = res[0];
        if (this.watch.WatchRequestStamp != null) {
          this.watch.WatchRequestStamp = moment(this.watch.WatchRequestStamp, 'YYYY-MM-DD HH:mm').format('M/D/YY, hh:mm A');
        }
        this.isBeingWatched = true;
      } else {
        this.isBeingWatched = false;
      }
      this.isLoading = false;
    });

    this.subs.sink = this.defendantWatchService.GetAlertsCountByDefendant(this.model.Id).subscribe(res => {
      // console.log('def watch alerts count', res);
      this.alertsCount = res;
    });
  }

  ngOnChanges() {
    // console.log('view changed for def watch', this.model);
  }

  toggleDefWatch(e) {
    this.subs.sink = this.billingService.IsSubscribedToProduct(AppConstants.PRODUCT_NAME_DEFENDANT_WATCH).subscribe(isSubscribed => {
      if (!isSubscribed) {
        const dialogRef = this.dialog.open(WarningDialogComponent, {
          data: {
            title: 'Subscribe to Defendant Watch',
            message: 'Defendant Watch must be enabled to add a defendant, click below to Open Defendant Watch Settings and subscribe!',
          },
          maxWidth: '500px',
        });
        this.model.IncludeInDefendantWatch = false;
      } else {
        this.subs.sink = this.defendantService.saveDefendantWithNoIncludes(this.model.Id, this.model).subscribe(res => {
          // console.log('saved defendant', res);
          this.popToast('Saved');
          this.isBeingWatched = this.model.IncludeInDefendantWatch;
        }, error => {
          this.errorData = error;
        });
      }
    });
  }

  popToast(message) {
    this.notifyService.toast(message, 'success');
  }

  goToDefWatchSettings() {
    this.dialogService.remove();
    this.router.navigate(['defendantwatch']);
  }

}
