import { take } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LookupService } from '../../../services/app-lookup.service';
import { DxSelectBoxComponent, DxTextBoxComponent } from 'devextreme-angular';
import { ReminderService } from '../../../services/reminder.service';
import { ApiUtilities } from '../../../shared/ApiUtilities';
import { NotificationTemplate } from '../../../models/notificationtemplate';
import { GridUtilities } from '../../../shared/GridUtilities';
import { NotifyService } from '../../../common/notify/notify.service';
import { isNullOrUndefined } from 'util';
import { AppConstants } from '../../../shared/AppConstants';
import { BillingService } from '../../../services/billing.service';
import { EventService } from '../../../services/event.service';
import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { SystemConfigService } from '../../../services/systemconfig.service';
import { SystemConfig } from '../../../models/systemconfig';
import { RouteDataService } from '../../../services/routedata.service';
import { CacheService } from '../../../services/cache.service';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import {UnsubscribeOnDestroyAdapter} from '../../../common/UnsubscribeOnDestroy';

@Component({
  selector: 'app-adi-templates',
  templateUrl: './adi-templates.component.html',
  styleUrls: ['./adi-templates.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0, overflow: 'hidden' })),
      transition(':leave', [
        style({ height: '*', opacity: 1, overflow: 'hidden' }),

        group([
          animate(300, style({ height: 0 })),
          animate('100ms ease-in-out', style({ opacity: '0', overflow: 'hidden' })),
        ]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0, overflow: 'hidden' }),

        group([
          animate(300, style({ height: '*' })),
          animate('100ms ease-in-out', style({ opacity: '1', overflow: 'hidden' })),
        ]),
      ]),
    ]),
  ],
})
export class AdiTemplatesComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ViewChild('dxTemplates') dxTemplates: DxSelectBoxComponent;
  @ViewChild('txEmail') txEmail: DxTextBoxComponent;
  @ViewChild('txNumberPreview') txNumberPreview: DxTextBoxComponent;
  @ViewChild('txMessagePreview') txMessagePreview: DxTextBoxComponent;
  @ViewChild('language') languageButtons: MatButtonToggleGroup;
  @Input() appSource: 'bail' | 'defmngr' | 'collections' = 'bail';

  notificationTemplates: any;
  selectedTemplate: any;
  selectedTemplateCopy: NotificationTemplate;

  notificationTypes: any;
  defaultType: any;
  staticFields: any = [];
  noCheckinFields: any = [];

  caretPos: number;

  isBusy: boolean;
  errorData: any[];
  errorVisible: boolean;
  selectedMessageTypeNew: any;

  confirmationMessage: string;
  showAddNew: boolean;
  newItemtext: string;
  popupTestMessageVisible: boolean;
  confirmingDelete: boolean;
  confirmationDeleteMessage: string;

  confirmLangaugeChange: boolean;
  confirmLanguageMessage: string;

  busySending: boolean;
  showSubscribeConfirmation: boolean;

  phoneMask = AppConstants.PHONE_MASK;
  phonePattern: any = AppConstants.PHONE_PATTERN;
  phoneRules: any = AppConstants.PHONE_RULES;
  isNewTemplate = false;

  templateCodeWithoutSpaces: string;
  showFields: string;
  testEmail: boolean;
  currentLanguage: string;
  newLanguage: string;
  languageTemplates: any;

  constructor(
    private lookupService: LookupService,
    private reminderService: ReminderService,
    private billingService: BillingService,
    private eventService: EventService,
    private notify: NotifyService,
    private systemConfigService: SystemConfigService,
    private cacheService: CacheService,
  ) {
    super();
  }

  ngOnInit() {
    this.loadLookups();
    this.loadSystemConfig();
  }

  loadLookups() {
    this.selectedTemplate = {};
    this.selectedTemplateCopy = new NotificationTemplate();

    this.subs.sink = this.lookupService
      .getFilteredLookup(
        'notificationtemplates',
        'MessageTypeSource',
        this.appSource,
        null,
        false,
      )
      .subscribe((resp: any) => {
        if (resp.data.length === 0) {
          this.notificationTemplates = {
            Object: 'NotificationTemplate',
            Id: '',
            TemplateName: 'None',
            Message: 'No Message',
          };
          return;
        }
        this.notificationTemplates = resp.data;

        const defaultTemplate = this.notificationTemplates.find(
          (x: any) => x.IsDefault,
        );
        if (defaultTemplate !== undefined) {
          this.selectedTemplate = defaultTemplate;
          this.selectedTemplateCopy = { ...defaultTemplate };
          this.caretPos = this.selectedTemplateCopy.Message.length;
          this.templateCodeWithoutSpaces = this.selectedTemplateCopy.TemplateName.replace(/\s/g, '');
        } else {
          this.caretPos = 0;
        }

        this.isBusy = false;
      });

      this.subs.sink = this.lookupService
      .getFilteredLookup(
        'notificationtypes',
        'NotificationTypeSource',
        this.appSource,
        'fields',
        false,
      )
      .subscribe(resp => {
        this.notificationTypes = resp.data;
        if (this.appSource === 'bail') {
          this.defaultType = this.notificationTypes.find(
            (item: any) => item.Code === 'Custom',
          );
        } else if (this.appSource === 'collections') {
          this.defaultType = this.notificationTypes.find(
            (item: any) => item.Code === 'FirstReminder',
          );
        } else {
          this.defaultType = this.notificationTypes.find(
            (item: any) => item.Code === 'Event',
          );
        }

        this.staticFields  = this.defaultType?.fields.data;
        this.noCheckinFields = this.defaultType?.fields.data.filter((item: any) => {
          return item.Text !== 'CheckinUrl';
        });

        if (this.defaultType) {
          this.defaultType.fields.data = this.noCheckinFields;
        }


        this.isBusy = false;
      });
  }

  loadSystemConfig() {
    // get system config and save to session storage for use through the app
    this.subs.sink = this.systemConfigService.get().subscribe(
      sc => {
        if (sc && sc.data && sc.data.length > 0) {
          const systemConfig = new SystemConfig(sc.data[0]);
          this.cacheService.set('systemConfig', JSON.stringify(systemConfig));
          this.currentLanguage = systemConfig.CollectionReminderTemplateLanguage;
        } else {
          const systemConfig = new SystemConfig();
          this.cacheService.set('systemConfig', JSON.stringify(systemConfig));
          this.currentLanguage = systemConfig.CollectionReminderTemplateLanguage;
        }
        if (!this.currentLanguage) {
          this.currentLanguage = 'en-US';
        }
      },
      error => {
        const systemConfig = new SystemConfig();
        this.cacheService.set('systemConfig',  JSON.stringify(systemConfig));
      },
    );
  }

  onSaveMessageTemplate() {
    this.isBusy = true;
    if (isNullOrUndefined(this.selectedTemplateCopy.Id)) {
      this.showResult(
        'warning',
        'Please select the template you want to save first',
      );
      return;
    }

    let canContinue = true;
    if (this.selectedTemplateCopy.EmailMessage
      && this.selectedTemplateCopy.wordphrases
      && this.selectedTemplateCopy.wordphrases.data.length > 0
    ) {
      this.selectedTemplateCopy.wordphrases.data.forEach((ph) => {
        if (this.selectedTemplateCopy.EmailMessage.toLowerCase().indexOf(ph.Phrase.toLowerCase()) >= 0
          || this.selectedTemplateCopy.Message.toLowerCase().indexOf(ph.Phrase.toLowerCase()) >= 0
        ) {
          this.showResult('error', 'Carol requires debtors to interact via the software');
          this.isBusy = false;
          canContinue = false;
          return;
        }

      });
    }
    if (!canContinue) {
      return;
    }
    if (this.selectedTemplateCopy.Id !== '') {
      this.subs.sink = this.reminderService
        .updateTemplate(this.selectedTemplateCopy.Id, this.selectedTemplateCopy)
        .pipe(take(1))
        .subscribe(
          resp => {
            this.showResult('success', 'Template Saved');
            this.loadLookups();
          },
          err => {
            this.showResult('error', 'Error Saving Template');
            this.showError(err);
            this.isBusy = false;
          },
        );
    } else {
      this.subs.sink = this.reminderService
        .addTemplate(this.selectedTemplateCopy)
        .pipe(take(1))
        .subscribe(
          resp => {
            this.showResult('success', 'Template Saved');
            this.selectedTemplate = {};
            this.selectedTemplateCopy = new NotificationTemplate();
            this.loadLookups();
            this.isNewTemplate = false;
          },
          err => {
            this.showResult('error', 'Error Saving Template');
            this.showError(err);
            this.isBusy = false;
          },
        );
    }

    this.eventService.dataHasChanged(true);
  }

  onDeleteResult(e: any) {
    if (e) {
      this.isBusy = true;
      if (this.selectedTemplateCopy.Id === '') {
        this.notificationTemplates = this.notificationTemplates.filter(
          (x: any) => x.TemplateName !== this.selectedTemplateCopy.TemplateName,
        );
        if (this.notificationTemplates.length > 0) {
          this.selectedTemplateCopy = this.notificationTemplates[0];
          this.isBusy = false;
        }
      } else {
        this.subs.sink = this.reminderService
          .deleteTemplate(this.selectedTemplateCopy.Id)
          .pipe(take(1))
          .subscribe(
            resp => {
              this.showResult('success', 'Template Deleted');
              this.loadLookups();
            },
            err => {
              this.showResult('error', 'Error Deleting Template');
              this.showError(err);
              this.isBusy = false;
            },
          );
      }
    }
  }

  onDeleteMessageTemplate() {
    if (
      !isNullOrUndefined(this.selectedTemplateCopy) &&
      !isNullOrUndefined(this.selectedTemplateCopy.TemplateName)
    ) {
      this.confirmingDelete = true;
      this.confirmationDeleteMessage =
        'You are about to delete [' +
        this.selectedTemplateCopy.TemplateName +
        '], are you sure?';
    }
  }

  confirmTemplateLangaugeChange() {
      this.confirmLangaugeChange = true;
      this.confirmLanguageMessage =
        'You are about to change the message language. This will revert any modifications made to the default system reminders, are you sure?';
  }

  addMessageTemplate() {
    this.selectedTemplateCopy = new NotificationTemplate();
    this.selectedTemplateCopy.MessageTypeSource = this.appSource;
    this.isNewTemplate = true;

    this.defaultType.fields.data = this.staticFields;
  }

  cancelMessageTemplate() {
    this.isNewTemplate = false;
    this.selectTemplate(this.notificationTemplates[0]);
  }

  showResult(status: string, message: string) {
    const type = status === 'error' ? 'error' : 'success';
    const text = message;
    this.notify.toast(text, type);
  }

  showError(errorInfo: any) {
    if (isNullOrUndefined(this.errorData)) {
      this.errorData = [];
    } else {
      this.errorData.length = 0;
    }

    if (isNullOrUndefined(errorInfo)) {
      this.errorData = [];
    } else {
      if (!isNullOrUndefined(errorInfo.message)) {
        this.errorData = this.errorData.concat(errorInfo);
      } else if (isNullOrUndefined(errorInfo.error)) {
        this.errorData = this.errorData.concat(
          ApiUtilities.getErrorMessage(errorInfo, true),
        );
      } else {
        if (isNullOrUndefined(errorInfo.error.errors)) {
          this.errorData = this.errorData.concat(
            ApiUtilities.getErrorMessage(errorInfo, true),
          );
        } else {
          this.errorData = this.errorData.concat(
            GridUtilities.getErrorItems(errorInfo.error.errors),
          );
        }
      }
    }
    this.errorVisible = true;
  }

  addNewTemplate(data: any) {
    if (data.text === '') {
      return;
    }
    this.newItemtext = data.text;
    this.confirmationMessage =
      data.text + ' will be added to the list. Continue?';
    this.showAddNew = true;
  }

  confirmAddTemplate(e: any) {
    if (
      isNullOrUndefined(this.selectedTemplateCopy.Message) ||
      this.selectedTemplateCopy.Message === ''
    ) {
      this.selectedTemplateCopy.Message = 'No Message Text';
    }

    this.selectedTemplateCopy.TemplateType = this.selectedMessageTypeNew.Code;
    this.selectedTemplateCopy.MessageTypeId = this.selectedMessageTypeNew.Id;
    this.selectedTemplateCopy.MessageTypeSource = this.appSource;

    this.subs.sink = this.reminderService
      .addTemplate(this.selectedTemplateCopy)
      .pipe(take(1))
      .subscribe(
        resp => {
          this.showResult('success', 'Template Added');

          this.selectedTemplate = {};
          this.selectedTemplateCopy = new NotificationTemplate();
          this.loadLookups();

          this.showAddNew = false;
          this.newItemtext = '';
        },
        err => {
          this.showResult('error', 'Error Saving Template');
          this.showError(err);
          this.isBusy = false;
        },
      );
  }

  sendTestMessage() {
    this.busySending = true;
      if (this.testEmail) {
        this.subs.sink = this.reminderService.sendTestMail(
          this.txEmail.text,
          this.txMessagePreview.text,
          this.selectedTemplateCopy.MessageTypeId,
        ).subscribe(resp => {
          this.notify.toast('Test email sent!', 'success');
          this.busySending = false;
          this.popupTestMessageVisible = false;
        }, smsErr => {
          this.showError(smsErr);
          this.busySending = false;
          this.popupTestMessageVisible = false;
        });
      } else {
        this.subs.sink = this.reminderService
          .sendTestSms(this.txNumberPreview.text, this.txMessagePreview.text)
          .subscribe(
            resp => {
              this.notify.toast('Test text message sent!', 'success');
              this.busySending = false;
              this.popupTestMessageVisible = false;
            },
            smsErr => {
              if (smsErr.data != null || smsErr.message != null) {
                this.showResult('error', smsErr.data == null ? smsErr.message : smsErr.data.message);
              }
              this.showError(smsErr);
              this.busySending = false;
              this.popupTestMessageVisible = false;
            },
          );
      }
  }

  showSendTestMessage(isEmail?: boolean) {
    (isEmail) ? this.testEmail = true : this.testEmail = false;

    this.popupTestMessageVisible = true;
  }

  checkNumberValid = (options: any) => {
    if (options.value == null || options.value === '') {
      options.rule.message = 'Enter a number';
      return false;
    }
    return true;
  }

  YesNoConfirmResult(e: any) {
    if (e) {
      // console.log('subscribing to text messaging');
      this.notify.toast('Subscribing to Reminder Service, please wait...');
      this.subs.sink = this.billingService
        .SubscribeCustomerToService(AppConstants.PRODUCT_NAME_TEXT_MESSAGING)
        .subscribe(
          () => {
            this.subs.sink = this.billingService
              .IsSubscribedToProduct(
                AppConstants.PRODUCT_NAME_TEXT_MESSAGING,
                true,
              )
              .subscribe(
                isSubbed => {
                  if (isSubbed) {
                    this.notify.toast(
                      'You are now subscribed to Reminder Services!  Continuing...',
                      'success',
                    );
                    this.showSubscribeConfirmation = false;
                    this.popupTestMessageVisible = true;
                  } else {
                    this.notify.toast(
                      'An error occurred while subscribing, please try again.',
                      'error',
                    );
                  }
                },
                error => {
                  this.notify.toast(
                    'An error occurred while subscribing, please try again.',
                    'error',
                  );
                },
              );
          },
          error => {
            this.notify.toast('An error occurred while subscribing, please try again.', 'error');
          },
        );
    } else {
      this.showSubscribeConfirmation = false;
    }
  }

  selectTemplate(template: any) {
    this.selectedTemplateCopy = { ...template };
    this.templateCodeWithoutSpaces = template.TemplateName.replace(/\s/g, '');

    if (this.templateCodeWithoutSpaces === 'CheckIn' || this.templateCodeWithoutSpaces === 'GPSCheckIn') {
      this.defaultType.fields.data = this.staticFields;
    } else {
      this.defaultType.fields.data = this.noCheckinFields;
    }
  }

  getCaretPos(oField: any) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      this.caretPos = oField.selectionStart;
    }
  }

  appendMessageTerm(term: string, model: string) {
    if (this.isBusy) {
      return false;
    }

    if (this.caretPos === undefined) {
      this.selectedTemplateCopy[model] =
        this.selectedTemplateCopy[model].trim() + ' ' + term;
    } else {
      this.selectedTemplateCopy[model] = [
        this.selectedTemplateCopy[model].slice(0, this.caretPos),
        term,
        this.selectedTemplateCopy[model].slice(this.caretPos),
      ].join('');
      this.caretPos = this.caretPos + term.length;
    }
  }

  selectLanguage(e) {
    this.newLanguage = e.value;
      if (this.currentLanguage !== this.newLanguage) {
        this.confirmTemplateLangaugeChange();
      }
    }

  applyLanguageSelection(e: any) {
    const tmpCurLangauge = this.currentLanguage;
    if (e) {
      this.showResult('success', 'Updating your language setting');
      this.isBusy = true;
      this.subs.sink = this.reminderService.toggleReminderTemplateLanguage(AppConstants.PRODUCT_NAME_COLLECTIONS, this.newLanguage)
        .subscribe(resp => {
          this.isBusy = false;
        });
      let configString: any;
      configString = this.cacheService.get('systemConfig', false);
      const systemConfig = JSON.parse(configString);
      systemConfig.CollectionReminderTemplateLanguage = this.newLanguage;
      this.isBusy = true;
      this.subs.sink = this.systemConfigService.save(systemConfig).subscribe(resp => {
        this.isBusy = false;
      });
      // this.sessionService.setObject('systemConfig');
      this.currentLanguage = this.newLanguage;
      if (!this.languageTemplates) {
        this.isBusy = true;
        this.subs.sink = this.reminderService.getReminderMasterTemplates(AppConstants.PRODUCT_NAME_COLLECTIONS).subscribe(resp => {
          this.languageTemplates = resp.data;
          this.isBusy = false;
          this.confirmLanguageSelection();
        });
      } else {
        this.confirmLanguageSelection();
      }
    } else {
      // === 'en-US' ? 'es-MX' : 'en-US' ; // this is a bit daft, the only way I can get the toggle to 'unselect' the button again is to
      // re-assign the langauge variable value
      this.currentLanguage = tmpCurLangauge;
      this.languageButtons.value = this.currentLanguage;
    }
  }

  confirmLanguageSelection() {

    const templates = this.languageTemplates.filter(x => x.LangCode === this.newLanguage);
    console.log(templates);
    templates.forEach(tmp => {
      const updateTemplate = this.notificationTemplates.find(x => x.TemplateName === tmp.TemplateName);
      updateTemplate.Message = tmp.Sms;
      updateTemplate.EmailSubject = tmp.EmailSubject;
      updateTemplate.EmailMessage = tmp.EmailBody;
      if (this.selectedTemplateCopy.TemplateName === tmp.TemplateName) {
        this.selectedTemplateCopy.Message = tmp.Sms;
        this.selectedTemplateCopy.EmailSubject = tmp.EmailSubject;
        this.selectedTemplateCopy.EmailMessage = tmp.EmailBody;
      }
    });
  }
}
