import { Routes } from '@angular/router';
import { CardResponseComponent } from './views/card-response/card-response.component';

export const ROUTES: Routes = [
  {
    path: 'location',
    loadChildren: () => import('app/views/location-checkin/location-checkin.module').then(m => m.LocationCheckinModule),
    data: {
      animation: 'fadeLocation',
    },
  },
  {
    path: 'login',
    loadChildren: () => import('app/views/login/login.module').then(m => m.LoginModule),
    data: {
      animation: 'fadeLogInBlank',
    },
  },
  {
    path: 'getstarted',
    loadChildren: () => import('app/views/checkout/checkout.module').then(m => m.CheckoutModule),
    data: {
      animation: 'fadeGetstarted',
    },
  },
  {
    path: 'register',
    loadChildren: () => import('app/views/register/register.module').then(m => m.RegisterModule),
    data: {
      animation: 'fadeRegisterBlank',
    },
  },
  {
    path: 'password',
    loadChildren: () => import('app/views/password/password.module').then(m => m.PasswordModule),
    data: {
      animation: 'fadePassword',
    },
  },
  {
    path: 'defendantmanager',
    loadChildren: () => import('app/defendant-manager/defendant-manager.module').then(m => m.DefendantManagerModule),
    data: {
      animation: 'fadeLocation',
    },
  },
  {
    path: 'collection-details',
    loadChildren: () =>
        import('app/views/public-pages/collections-public-details/collections-public-details.module')
        .then(m => m.CollectionsPublicDetailsModule),
    data: {
      animation: 'fadeCollectionDetails',
    },
  },
  {
    path: 'collection-friends-link',
    loadChildren: () =>
        import('app/views/public-pages/friends-link/friends-link.module')
        .then(m => m.FriendsLinkModule),
  },
  {
    path: 'collection-counter-offer',
    loadChildren: () =>
        import('app/views/public-pages/collections-public-counter-offer/collections-public-counter-offer.module')
        .then(m => m.CollectionsPublicCounterOfferModule),
    data: {
      animation: 'fadeCollectionCounterOffer',
    },
  },
  {
    path: 'collection-claim-invoice',
    loadChildren: () =>
        import('app/views/public-pages/collections-public-claim-invoice/collections-public-claim-invoice.module')
        .then(m => m.CollectionsPublicClaimInvoiceModule),
    data: {
      animation: 'fadeClaimInvoice',
    },
  },
  {
    path: 'securedocs',
    loadChildren: () =>
      import('app/views/secure-documents/secure-documents.module')
        .then(m => m.SecureDocumentsModule),
    data: {
      animation: 'fadeSecureDocs',
    },
  },
  {
    path: 'not-found',
    loadChildren: () => import('app/components/common/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    data: {
      animation: 'fadeNotFound',
    },
  },
  {
    path: 'payment-response',
    loadChildren: () => import('app/views/payment-response/payment-response.module').then(m => m.PaymentResponseModule),
    data: {
      animation: 'fadePaymentResponse',
    },
  },
  {
    path: 'developer',
    loadChildren: () => import('app/views/developer/developer-module.module').then(m => m.DeveloperModuleModule),
  },
  {
    path: 'quickbooks',
    loadChildren: () => import('app/quickbooks/quickbooks.module').then(m => m.QuickbooksModule),
  },
  {
    path: 'smart-form',
    loadChildren: () => import('app/smart-forms/smart-forms.module').then(m => m.SmartFormsModule),
  },
  {
    path: 'e-signed-smart-forms',
    loadChildren: () => import('app/e-sign/e-sign.module').then(m => m.ESignModule),
  },
  {
    path: 'bail',
    loadChildren: () => import('app/public-bail/public-bail.module').then(m => m.PublicBailModule),
    data: {
      animation: 'fadeBail',
    },
  },
  {
    path: 'gateway-registration',
    loadChildren: () => import('app/gateway-registration/gateway-registration.module').then(m => m.GatewayRegistrationModule),
    data: {
      animation: 'fadeBail',
    },
  },
  {
    path: 'card-response',
    component: CardResponseComponent,
    data: {
      animation: 'fadeClaimInvoice',
    },
  },
  {
    path: '',
    loadChildren: () => import('app/global/global.module').then(m => m.GlobalModule),
  },

];
