import {BaseModel} from './BaseModel';

export class CalendarItem extends BaseModel {
// EM - do not modify this naming. DX requires it in this casing
  public text: string;
  public startDate: any;
  public endDate: any;
  public allDay: boolean;
  public itemType: any; // the entry type ie Checkin Due, Payment etc
  public color: string;
  public Id: string;
  public SourceId: string;
  public SourceType: string;
  public AssignedTo: string;
  public always: any;
  public DefendantName: string;
  public shouldEditOrDelete: boolean;
  public Location: string;
  constructor(obj?: object) {

    super(obj);

    if (obj == null) {
      this.allDay = false;
      //TODO - Setup constructor to init values during implementation
    }
  }
}
