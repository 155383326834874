import { BaseModel } from './BaseModel';

export interface DocumentVersion {
  Id: string;
  DocumentId: string;
  VersionId: number;
  ChangeDescription: string;
  Created_At: Date;
  Updated_At: Date;
  Deleted_At: Date;
  CustomerId: string;
}

export class Version extends BaseModel implements DocumentVersion {
  // tslint:disable:variable-name
  public Id: string;
  public DocumentId: string;
  public VersionId: number;
  public ChangeDescription: string;
  public Created_At: Date;
  public Updated_At: Date;
  public Deleted_At: Date;
  public CustomerId: string;

  constructor(obj?: object) {
    super(obj);
  }

  public loadData(data) {
    this.fillFromObject(data, true);
  }
}
