<div mat-dialog-title class="dialog-title">
  <h2>Captira Google Review</h2>
  <button class='close-icon' mat-icon-button [mat-dialog-close]='settings.enabled'>
    <i class="fal fa-times fa-2x"></i>
  </button>
</div>
<div class="dialog-overlay" *ngIf="showLoading">
  <div class="dialog-center" *ngIf="showLoading">
    <mat-spinner strokeWidth="7" style="margin:0 auto;"></mat-spinner>
  </div>
</div>
<app-google-review-settings #settings (saveClicked)='saveComplete($event)'></app-google-review-settings>
<mat-dialog-actions align='end'>
    <button mat-raised-button [color]="!settings.company.GooglePlaceId ? undefined : 'primary'"
            [disabled]='!settings.company.GooglePlaceId' class='save-button' (click)='saveSettings()'>
      SAVE
    </button>
</mat-dialog-actions>
