import * as Sentry from '@sentry/browser';

export class XhrResponse {
  progress: number;
  loaded: number;
  total: number;
  response: any;
}

export module ApiUtilities {

  export const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  export function urlEncode(obj: Object) {

    const urlSearchParams = new URLSearchParams();
    for (const key in obj) {
      urlSearchParams.append(key, obj[key]);
    }

    return urlSearchParams.toString();
  }

  function getResult(data: any, returnObject: boolean = false) {
    if (returnObject) {
      if (data instanceof Array) {
        return data;
      } else {
        return [{detail: data}];
      }
    } else {
      return data;
    }
  }

  function getAuthorizationResult(data: any) {
    const message = data.error.message.replace('Unauthorized::', '');
    const authError = {status_code: 403, message: 'You are not authorized', details: message};
    return authError;
  }

  // Send error to Sentry
  function reportSentry (error) {
    // console.log('About to capture message for Sentry: ', typeof(error));
    if (error) {
      try {
        let parsedData = JSON.stringify(error);
        // console.log('Sending message to sentry!');
        Sentry.captureMessage(parsedData);
      } catch (e) {
        console.error('Unable to send report on Sentry: ', e);
      }
    }
  }

  export function getErrorMessage(response: any, returnObject: boolean = false): any {
    // // console.log(`ApiUtilities getErrorMessage for ${JSON.stringify(response)}, typeof ${typeof response}`);
    // reportSentry(response); // Report to Sentry (COMMENTED: now using from interceptor (responseInterceptorService.ts) for all failed responses)

    let data: any;
    if (response != null) {

      if (typeof response === 'string' && response !== '') {
        try {
          data = JSON.parse(response);
        } catch (e) {
          // TODO: Logging?
          return response;
        }
      } else {
        data = response;
      }

      if (data.status != null) {
        // do handling specific to status codes here
        if (data.status === 0) { // Connection Failed?
          // return getResult('Failed to connect to server. Check your connection and try again.', returnObject);
          return 'ignore';
        } else if (data.status === 401) { // Unauthorized
          // return getResult('You are not authorized to perform the requested operation. Try logging in again.', returnObject);
          return 'ignore';
        } else if (data.status === 403) { // Forbidden
          // console.log('auth error', data);
          return getAuthorizationResult(data);
          // return getResult('You do not have the required permissions to perform the requested operation. Contact your account administrator.', returnObject);
        } else if (data.status === 404 && data.error == null) { // Forbidden
          // return getResult('Not found. Contact Customer Care.', returnObject);
          return 'ignore';
        } else if (data.status === 500 && data._body) { // Internal Server Error
          // const errorMessage = JSON.parse(data._body).message ? 'Message: ' + JSON.parse(data._body).message : '';
          // return getResult('An unexpected error occurred while processing your request. Please file a support ticket. ' + errorMessage, returnObject);
          return 'ignore';
        }
      }

      let body: any;
      if (data._body != null) {
        if (typeof data._body === 'string') {
          body = JSON.parse(data._body);
        } else {
          body = data._body;
        }
      } else {
        body = data;
      }

      if (body.error != null && returnObject) {
        return body.error;
      } else {
        let result = '';
        let validationMessages = '';
        let errorMessages = '';
        if (body.error && body.error.errors && body.error.errors.length > 0) {
          for (let i = 0; i < body.errors.length; i++) {
            const err = body.errors[i];
            if (err.status === 422) {
              validationMessages += (validationMessages.length > 0) ? '<br/>' : '' + err.detail;
            } else {
              errorMessages += (errorMessages.length > 0) ? '<br/>' : '' + err.detail;
            }
          }

          if (validationMessages.length > 0) {
            result += 'There were validation errors:<br/><br/>' + validationMessages;
          }

          if (errorMessages.length > 0) {
            result += 'There were errors:<br/><br/>' + errorMessages;
          }
          return getResult(result, returnObject);
        } else if (body.error_description != null) {
          return getResult(body.error_description, returnObject);
        } else if (body.Message != null) {
          return getResult(body.Message, returnObject);
        } else if (body.error != null) {
          return getResult(body.error, returnObject);
        } else if (body.code != null) {
          return getResult('code: ' + body.code, returnObject);
        } else {
          if (Object.keys(body).length === 0) {
            // return getResult('An unhandled exception occurred. Please file a support ticket.', returnObject);
            return 'ignore';
          } else {
            return JSON.stringify(body);
          }
        }

      }
    } else {
      return getResult('Unknown error. Check your connection and try again.', returnObject);
    }

  }
}
