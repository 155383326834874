<div class="fixActionRow">
  <div mat-dialog-content class="full-page-content">
    <button mat-icon-button class="close-icon" (click)="closeOnboardingDialog()">
      <mat-icon>close</mat-icon>
    </button>

    <dx-multi-view
      #onboardingView
      id="onboardingView"
      [dataSource]="pages"
      [selectedIndex]="selectedIndex"
      [loop]="true"
      [height]="'100%'"
      [swipeEnabled]="true"
      [animationEnabled]="true">
      <div *dxTemplate="let step of 'item'; let itemIndex = index">

        <div  *ngIf="itemIndex == 0" class="full-page-content">
          <div class="onboarding-content" fxLayout="column" fxLayoutAlign="space-around center">
            <div fxLayout="row">
              <span style="margin-left: 24px;"><i class="fal fa-mobile fa-4x" ></i></span><i class="far fa-comment-lines fa-2x" style="margin-left: 8px;"></i>
            </div>
            <p>Carol sends escalating reminder text and emails to your customers to pay their invoices</p>
            <p>Try it out, send yourself a test SMS</p>

            <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="8px" >
              <p>Send test to</p>
              <div class="text-actions" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
                <dx-text-box
                  #txNumber
                  id="txNumber"
                  [mask]="phoneMask"
                  maxLength="20"
                  mode="tel"
                  maskInvalidMessage="Please enter a valid phone number"
                  [useMaskedValue]="false"
                  [(value)]="mobile"
                  isValid="true"
                  maskChar="_"
                  class="masked-input"
                  (onValueChanged)="onValueChanged($event)"
                  (onFocusOut)="onFocusOut($event)"
                ></dx-text-box>
                <button class="text-button" mat-stroked-button color="primary" (click)="textDemoThing()">Send</button>
                <span style="font-size:1rem;" *ngIf="textSent">Test SMS Sent</span>
              </div>
            </div>
          </div>
        </div>

        <div  *ngIf="itemIndex == 1" class="full-page-content">
          <div class="onboarding-content" fxLayout="column" fxLayoutAlign="space-evenly center">
            <h1 class="h4">How It Works</h1>
            <div style="margin-top:-6rem;">
              <p style="font-weight:bold;">Add Invoices</p>
              <p>Once you complete the basic setup you can manually add invoices one at a time or bulk upload invoices from an Excel spreadsheet, Quickbooks and other systems.</p>
            </div>
          </div>
        </div>

        <div  *ngIf="itemIndex == 2" class="full-page-content">
          <div class="onboarding-content" fxLayout="column" fxLayoutAlign="space-evenly center">
            <h1 class="h4">How It Works</h1>
            <div style="margin-top:-6rem;">
              <p style="font-weight:bold;">Activate Payments (Optional)</p>
              <p>
                Carol will ask your debtors to call you.
              </p>
              <p>
                Select Venmo, CashApp or Zelle to take manual payments.
              </p>
              <p>
                Or connect an online payment provider (Stripe, Paypal etc) and payments can be made online and go directly into your account.
              </p>
            </div>
          </div>
        </div>


        <div  *ngIf="itemIndex == 3" class="full-page-content">
          <div class="onboarding-content" fxLayout="column" fxLayoutAlign="center center">
            <h1 class="h4" style="margin-bottom:6rem;">How It Works</h1>
            <button mat-icon-button (click)="showVideo(1)" class="video-link">
              <i class="fas fa-play-circle fa-4x" style="color: red;">
              </i>
            </button>
            <a (click)='showVideo(2)'>A shorter summary video</a>
            <p>Click to watch a video and you can always return here later.</p>
          </div>
        </div>

        <div  *ngIf="itemIndex == 4" class="full-page-content">
          <div class="onboarding-content" fxLayout="column" fxLayoutAlign="space-around center">
            <h1 class="h4">Offer Discounts to </h1>
            <h1 class="h4" style="margin-top:-5rem; margin-bottom:4rem;">Incentivize Payments</h1>
            <p style="margin-bottom:6rem;">We suggest 20% to 30%</p>
            <div  fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
              <dx-number-box
              #discountValueRef
              class="discountValue"
              width="40%"
              format="percent"
              [(value)]="discountValue"
              >
              </dx-number-box>
              <button [disabled]="disabled" class="text-button" mat-stroked-button color="accent" (click)="updateDiscounts()">Save</button>
            </div>
          </div>
        </div>

        <div  *ngIf="itemIndex == 5" class="full-page-content">
          <div class="onboarding-content" fxLayout="column" fxLayoutAlign="start center">
            <h4 style="margin-top:10rem; font-weight:bold;" class="adjust-final-slide">You're now set up and ready to go.</h4>
            <p class="adjust-final-slide">
              Exit to optional custom setup for more company settings, discount tiers and adding online payment
            </p>
            <div class='adjust-final-slide' fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <button (click)="closeOnboardingDialog()" style="margin-bottom: 1.5rem;" mat-raised-button>Close</button>
              <button (click)="showSetupPopup('custom')" style="margin-bottom: 3rem;" mat-raised-button>Custom Setup</button>
            </div>
            <p style="margin-bottom:2rem;">Add your first invoice. Why not try it on yourself for the debtor experience?</p>
            <button (click)="showSetupPopup('basic')" style="margin-bottom: 1.5rem;" mat-raised-button color="primary">
              + Add Invoice
            </button>
          </div>
        </div>
      </div>
    </dx-multi-view>

  </div>
  <div class="spacer"></div>
  <div *ngIf="selectedIndex !== pages.length - 1" fxLayout="row" fxLayoutAlign="center center" style="margin-bottom:4rem; margin-left: 2rem;">
    <button (click)="moveToStep(0)" class="slider-button" [ngClass]="{'slider-button-filled' : selectedIndex === 0}"></button>
    <button (click)="moveToStep(1)" class="slider-button" [ngClass]="{'slider-button-filled' : selectedIndex === 1}"></button>
    <button (click)="moveToStep(2)" class="slider-button" [ngClass]="{'slider-button-filled' : selectedIndex === 2}"></button>
    <button (click)="moveToStep(3)" class="slider-button" [ngClass]="{'slider-button-filled' : selectedIndex === 3}"></button>
    <button (click)="moveToStep(4)" class="slider-button" [ngClass]="{'slider-button-filled' : selectedIndex === 4}"></button>
  </div>
  <mat-dialog-actions align="end" style="margin-bottom:1rem;">
    <button [disabled]="disabled" *ngIf="selectedIndex !== pages.length - 1" mat-raised-button mat-dialog-close>Close</button>
    <div fxFlex></div>
    <button [disabled]="disabled" style="margin-right:1rem;" *ngIf="selectedIndex !== 0" mat-raised-button (click)="previousPage()">Back</button>
    <button [disabled]="disabled" *ngIf="selectedIndex !== pages.length - 1" mat-raised-button (click)="nextPage()">Next</button>
    <span *ngIf="selectedIndex === pages.length - 1">
      <i (click)="openSupportPopup()" style='cursor:pointer; font-weight:bold;' class="fal fa-question"></i>
    </span>
  </mat-dialog-actions>
</div>

<dx-popup
  [showTitle]="true"
  title="Getting Started"
  [closeOnOutsideClick]="true"
  [(visible)]="showVideoPopup"
  width="90%"
  height="90%"
  [showCloseButton]="true">

        <iframe *ngIf="showVideoPopup" width="100%" height="100%" [src]="safeUrl" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</dx-popup>

