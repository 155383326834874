import { Directive, AfterViewInit, ElementRef,NgModule, Host, Optional, Input } from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';

@Directive({
  selector: "[singleselect]"
})
export class SingleselectDirective implements AfterViewInit {

	private select = true;
  @Input() simpleValue = false;

	sb:any;
  constructor(@Optional() @Host() sb: DxSelectBoxComponent) { 
  	this.sb = sb;

  }

  ngOnInit() {

  }

  ngAfterViewInit()
  { 
    if(this.select)    
      {
        setTimeout(() => {
              let dataValue = this.sb.instance.option("dataSource");
              if(dataValue !== undefined && dataValue.length === 1)
              {
         
                if(this.simpleValue)
                {
                  this.sb.instance.option('selectedItem', dataValue[0].Text);
                  this.sb.instance.option('value', dataValue[0].Id);
                }
                else
                {
                  this.sb.instance.option('selectedItem', dataValue[0]);
                  this.sb.instance.option('value', dataValue[0]);
                }
				 
				  
              }
            }, 6000);
      }
  }

  @Input() set singleselect(condition: boolean)
  {
    this.select = condition !== false;
  }

}
